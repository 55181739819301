/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  Input,
  InputAdornment,
  InputLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import CustomButton from 'components/buttons/CustomButton'
import { SearchOutlined } from '@mui/icons-material'
import { StyledTableCell, StyledTableRow } from '../components/table.styles'
import MoreMenu from 'components/view/MoreMenu'
import FormBoxType from './FormBoxType'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { style } from '@mui/system'
import { putStatusPortfolioBoxes, getPortfolioBoxes, putPortfolioBoxes } from 'services/portfolio/PortfolioService'
import { toast } from 'react-toastify'

interface modalView {
  open: boolean
  close: () => void
}

const ModalBoxType = (props: modalView) => {
  const { open, close } = props

  const [openAddModal, setOpenAddModal] = useState<boolean>(false)
  const [openEditableModal, setOpenModalEditable] = useState<boolean>(false)
  const [rowEditModal, setRowEditModal] = useState<boolean>(false)
  const [page, setPage] = useState(0)

  const [originalData, setOriginalData] = React.useState<any[]>([])
  const [data, setData] = React.useState<any[]>([])

  const queryClient = useQueryClient()

  const portfolioReq = useQuery(['portfolio', null],
    async () => {
      return getPortfolioBoxes()
    },
    {
      onSuccess: (data) => {
        setData(data)
        setOriginalData(data)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  // const portfolioEditReq = useQuery(['portfolioEdit', null],
  //   async () => {
  //     console.log('to edit', boxEdit)
  //     return putPortfolioBoxes(boxEdit)
  //   },
  //   {
  //     onSuccess: (data) => {
  //       toast.success('Editado com sucesso')
  //     },
  //     onError: (e) => {
  //       toast.error('Não foi possível realizar essa ação')
  //     },
  //     refetchOnWindowFocus: false,
  //     cacheTime: 1000 * 60 * 10,
  //     enabled: false
  //   }
  // )

  const { mutate: mutateStatus, isLoading: isLoadingDelete } = useMutation(putStatusPortfolioBoxes, {
    onSuccess: data => {
      portfolioReq.refetch()
    },
    onError: (e: any) => {
      toast.error('Não foi possível realizar essa alteração')
    },
    onSettled: () => {
      queryClient.invalidateQueries('boxes')
    }
  })

  // const portfolioDeleteReq = useQuery(['portfolioDelete', boxEdit],
  //   async () => {
  //     console.log('to delete', boxEdit)
  //     return deletePortfolioBoxes(boxEdit)
  //   },
  //   {
  //     onSuccess: (data) => {
  //       toast.success('Excluído com sucesso')
  //     },
  //     onError: (e) => {
  //       toast.error('Não foi possível realizar essa ação')
  //     },
  //     refetchOnWindowFocus: false,
  //     cacheTime: 1000 * 60 * 10,
  //     enabled: false
  //   }
  // )

  const closeModal = () => {
    handleSearch('')
    close()
  }

  const handleCloseAddModal = (changed?: boolean) => {
    setOpenAddModal(false)
    if (changed) {
      portfolioReq.refetch()
    }
  }

  const handleCloseEditModal = (changed?: boolean) => {
    setOpenModalEditable(false)
    if (changed) {
      portfolioReq.refetch()
    }
  }

  const handleEdit = (row) => {
    console.log('handle edit', row)
    setRowEditModal(row)
    setOpenModalEditable(true)
  }

  const handleStatus = (id) => {
    console.log('handle status', id)
    mutateStatus(id)
  }

  const handleSearch = (search) => {
    setPage(0)
    if (search.length > 0) {
      const result = originalData.filter(v => String(v.name).toLocaleUpperCase().includes(String(search).toLocaleUpperCase()))
      setData(result)
    } else {
      setData(originalData)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tipo de caixa
          <Grid
            item
            xs={12}
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '15px'
            }}
          >
            <Button
              variant="outlined"
              style={{ height: '30px' }}
              onClick={() => setOpenAddModal(true)}
            >
              + Nova caixa
            </Button>

            <FormControl variant="standard">
              <InputLabel htmlFor="field-search">Pesquisar</InputLabel>
              <Input
                id="field-search"
                defaultValue=""
                type="search"
                onChange={(event) => handleSearch(event.target.value.trim())}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchOutlined />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {
            (portfolioReq.isFetching)
              ? (
                <Box sx={{ display: 'flex' }}
                  style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
                  <CircularProgress />
                </Box>
              )
              : (portfolioReq.isError)
                ? (
                  <Box sx={{ display: 'flex' }}
                    style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
                    <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
                    <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
                  </Box>
                )
                : (
                  <TableContainer style={{ marginTop: '30px' }}>
                    <Table sx={{ minWidth: '100%' }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Tipo de caixa</StyledTableCell>
                          <StyledTableCell align="left">Status</StyledTableCell>
                          <StyledTableCell align="right">Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.slice(page * 5, page * 5 + 5).map((row) => (
                          <StyledTableRow key={row._id}>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <FormGroup>
                                <FormControlLabel control={<Switch checked={row.isActive} />} label={row.isActive ? 'Ativo' : 'Inativo'} onChange={() => handleStatus(row._id)} />
                              </FormGroup>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <MoreMenu
                                edit={() => handleEdit(row)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <TablePagination
                      rowsPerPageOptions={[5]}
                      rowsPerPage={5}
                      component="div"
                      count={data.length}
                      page={page}
                      onPageChange={(event: unknown, newPage: number) => {
                        setPage(newPage)
                      }}
                    />
                  </TableContainer>
                )
          }
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <CustomButton
            mold="primary"
            type="button"
            onClick={closeModal}
            autoFocus
          >
            Voltar
          </CustomButton>
        </DialogActions>

        <FormBoxType open={openAddModal} close={(changed?: boolean) => handleCloseAddModal(changed)} />
        <FormBoxType open={openEditableModal} row={rowEditModal} editable={openEditableModal} close={(changed?: boolean) => handleCloseEditModal(changed)} />
      </Dialog>

    </>
  )
}

export default ModalBoxType
