
import { styled } from '@mui/material/styles'
import {
  Chip
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

export const Td = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: 73,
    verticalAlign: 'top',
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'left'
  }
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export const Blocked = styled(Chip)(({ theme }) => ({
  borderColor: '#EE207A',
  color: '#EE207A',
  backgroundColor: '#FFDDEC',
  borderRadius: '4px'
}))

export const Unlocked = styled(Chip)(({ theme }) => ({
  borderColor: '#72CE2B',
  color: '#72CE2B',
  backgroundColor: '#EBFFD8',
  borderRadius: '4px'
}))

export const New = styled(Chip)(({ theme }) => ({
  borderColor: '#4060E6',
  color: '#4060E6',
  backgroundColor: '#4060E620',
  borderRadius: '2px'
}))

export const Pending = styled(Chip)(({ theme }) => ({
  borderColor: '#CE6C2B',
  color: '#CE6C2B',
  backgroundColor: '#FFF0D8',
  borderRadius: '2px'
}))

export const Disapproved = styled(Chip)(({ theme }) => ({
  borderColor: '#EE207A',
  color: '#EE207A',
  backgroundColor: '#FFDDEC',
  borderRadius: '2px'
}))

export const Approved = styled(Chip)(({ theme }) => ({
  borderColor: '#72CE2B',
  color: '#72CE2B',
  backgroundColor: '#EBFFD8',
  borderRadius: '2px'
}))
