import { DialogTitle } from '@mui/material'
import IconAndTitleView from 'components/view/IconAndTitleView'
import moment from 'moment'
import {
  CardContentChieldren,
  CardFather,
  DivAlingCard,
  DivAlingItensCard,
  RetangleView
} from 'pages/reportControl/deliveryMap/styles/dialogMap.styles'
import React from 'react'

export const ModalHeadAudit: React.FC<any> = (props) => {
  return (
    <DialogTitle id="alert-dialog-title">
      <DivAlingCard>
        <RetangleView />
        <CardFather>
          <CardContentChieldren>
            <DivAlingItensCard>
              <div style={{ marginRight: '20px' }}>Resumo</div>
              <IconAndTitleView
                icon="person_outline"
                title={`Emitido por: ${
                  props?.data?.client?.seller?.user?.name ?? ''
                }`}
              />
              <IconAndTitleView
                icon="calendar_today"
                title={moment(props?.data?.createdAt).format('DD/MM/YYYY')}
              />
              <IconAndTitleView
                icon="schedule"
                title={moment(props?.data?.createdAt).format('HH:mm')}
              />
              <IconAndTitleView
                icon="person_outline"
                title={`Para: ${props?.data?.client?.name}`}
              />
            </DivAlingItensCard>
          </CardContentChieldren>
        </CardFather>
      </DivAlingCard>
    </DialogTitle>
  )
}
