import { httpLN, httpClient } from '../../config/httpClient'

const maxLimit = 5000

export const postOVByTypeDelayed = async (date: string, clientsIds) => {
  const { data } = await httpLN.post<any>(`order/sellerOrderByTypeAndDelayPaginated?page=0&limit=${maxLimit}&date=${date}`, clientsIds)
  return data
}

export const postOVByType = async (date: string, clientsIds) => {
  const { data } = await httpLN.post<any>(`order/sellerOrderByTypePaginated?page=0&limit=${maxLimit}&date=${date}`, clientsIds)
  return data
}

export const postOVByID = async (date: string, ids: any[]) => {
  const { data } = await httpLN.post<any>(`order/sellerOrderByIDPaginated?page=0&limit=${maxLimit}&date=${date}`, {
    ordersIds: ids
  })
  return data
}

export const getOVByPlatesDelayed = async (date: string) => {
  const { data } = await httpLN.get<any>(`order/orderServiceByPlateAndDelayPaginated?page=0&limit=${maxLimit}&date=${date}`)
  return data
}

export const getOVByCoil = async (date: string) => {
  const { data } = await httpLN.get<any>(`order/orderServiceByCoilPaginated?page=0&limit=${maxLimit}&date=${date}`)
  return data
}

export const getOVByPlate = async (date: string) => {
  const { data } = await httpLN.get<any>(`order/orderServiceByPlatePaginated?page=0&limit=${maxLimit}&date=${date}`)
  return data
}

export const getOVOutOfState = async (date: string) => {
  const { data } = await httpLN.get<any>(`order/sellerOrderOutOfStatePaginated?page=0&limit=${maxLimit}&date=${date}`)
  return data
}

export const getCodLNsKanbam = async () => {
  const { data } = await httpClient.get<any>('company/KANBAN/typeCompany')
  return data
}

export const getCodLNsCarteira = async () => {
  const { data } = await httpClient.get<any>('company/CARTEIRA/typeCompany')
  return data
}

export const getCodesOVsReposition = async () => {
  const { data } = await httpClient.get<any>('itemDeliveryMap/all/reposition')
  return data
}
