import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  TextField
} from '@mui/material'
import { Form, Formik } from 'formik'
import jwtDecode from 'jwt-decode'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import background_login from '../../assets/img/background_login.png'
import logo from '../../assets/img/logo.png'
import sigla from '../../assets/img/Logo_PCE_Branco.png'
import { httpClient } from '../../config/httpClient'
import FirstAccessDialog from './components/FirstAccessDialog'
import PrivacyLabelLogin from './components/PrivacyLabelLogin'
import { _changeLogin } from './slices/login.slice'
import { validationSigninSchema } from './validations/validationSigninSchema'

const GridLogo = styled(Grid)`
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
  @media (max-width: 900px) {
    display: none;
  } ;
`

const LoginPage = () => {
  const [open, setOpen] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const [valuesPassword, setValuesPassword] = useState({
    password: '',
    showPassword: false
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const handleChangePassword = (prop: any) => (event: any) => {
  //   setValuesPassword({ ...valuesPassword, [prop]: event.target.value })
  // }

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword
    })
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const login = async (values: any) => {
    try {
      const response = await httpClient.post('/auth/login', {
        email: values.email,
        password: values.password
      })
      const accessToken: any = jwtDecode(response.data.accessToken)
      const firstAccess = accessToken.isPrimaryAccess

      dispatch(
        _changeLogin({
          tokenData: accessToken,
          accessToken: response.data.accessToken,
          logged: false,
          email: '',
          picture: ''
        })
      )

      const user = await loadUser(values.email)

      localStorage.setItem('user', JSON.stringify(user.data))

      if (firstAccess) {
        dispatch(
          _changeLogin({
            tokenData: accessToken,
            accessToken: response.data.accessToken,
            logged: false,
            email: values.email,
            picture: user.data.url
          })
        )
        handleFirstAccess()
      } else {
        dispatch(
          _changeLogin({
            tokenData: accessToken,
            accessToken: response.data.accessToken,
            logged: true,
            email: values.email,
            picture: user.data.url
          })
        )
        navigate('/home/dashboard')
      }
    } catch (e) {
      setOpenSnack(true)
    }
  }

  const loadUser = async (email: string) => {
    return httpClient.get(`/users/email?param=${email}`)
  }

  const handleClose = (value: string) => {
    setOpen(false)
  }

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  const handleFirstAccess = () => {
    setOpen(true)
  }

  return (
    <div
      style={{
        position: 'absolute',
        height: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        backgroundImage: 'url(' + background_login + ')',
        WebkitBackgroundSize: 'cover'
      }}
    >
      <Container style={{ height: '100%' }}>
        <Grid container spacing={2} style={{ height: '100%' }}>
          <Grid item xs={12} sm={12} md={6} style={{ height: '100%' }}>
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <div
                style={{ fontSize: '42px', color: 'white', fontWeight: 500 }}
              >
                Login
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: 'white',
                  fontWeight: 400,
                  marginBottom: '28px'
                }}
              >
                Insira seu login e senha para acessar o sistema
              </div>
              <Card sx={{ maxWidth: '360px' }}>
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    paddingTop: '60px',
                    paddingBottom: '60px'
                  }}
                >
                  <img
                    style={{
                      width: 'auto',
                      maxWidth: '190px',
                      display: 'flex',
                      alignSelf: 'center'
                    }}
                    src={logo}
                  />
                  <Formik
                    initialValues={{
                      email: '',
                      password: ''
                    }}
                    validationSchema={validationSigninSchema}
                    onSubmit={async (values) => {
                      login(values)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue
                    }) => (
                      <Form style={{ display: 'contents' }}>
                        <TextField
                          value={values.email}
                          type='email'
                          onChange={handleChange('email')}
                          error={Boolean(errors.email && touched.email)}
                          helperText={
                            errors.email && touched.email ? errors.email : null
                          }
                          id="standard-basic"
                          label="Usuário"
                          variant="standard"
                          style={{ color: '#A8A8A8', marginTop: '16px' }}
                        />

                        <FormControl
                          style={{ marginTop: '12px' }}
                          variant="standard"
                        >
                          <InputLabel htmlFor="standard-adornment-password">
                            Senha
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={
                              valuesPassword.showPassword ? 'text' : 'password'
                            }
                            value={values.password}
                            onChange={handleChange('password')}
                            error={Boolean(errors.password && touched.password)}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {valuesPassword.showPassword
                                    ? (
                                      <Visibility />
                                    )
                                    : (
                                      <VisibilityOff />
                                    )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <FormHelperText
                            error={Boolean(errors.password && touched.password)}
                          >
                            {errors.password && touched.password
                              ? errors.password
                              : ''}
                          </FormHelperText>
                        </FormControl>
                        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '22px' }}>
                          <Checkbox style={{ padding: 0 }} />
                          <div style={{ marginLeft: '10px', color: '#8A8A8A' }}>Lembrar de mim</div>
                        </div> */}
                        <Button
                          type="submit"
                          variant="contained"
                          disableElevation
                          style={{
                            backgroundColor: '#4060E6',
                            marginTop: '54px'
                          }}
                        >
                          Login
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  <a
                    style={{
                      fontSize: '14px',
                      textDecoration: 'underline',
                      fontStyle: 'italic',
                      marginTop: '20px',
                      color: '#8C8C8C'
                    }}
                    href="recover"
                  >
                    Esqueceu sua senha?
                  </a>
                </CardContent>
              </Card>
              <div
                style={{
                  fontSize: '12px',
                  color: 'white',
                  fontWeight: 400,
                  marginTop: '18px',
                  marginLeft: '34px'
                }}
              >
                Mnemosine - PCE ©2022 Created by Creathus
              </div>
              <PrivacyLabelLogin />
            </div>
          </Grid>
          <GridLogo item xs={12} md={6}>
            <img
              style={{
                width: 150,
                height: 90,
                display: 'flex',
                marginBottom: '40px'
              }}
              src={sigla}
            />
          </GridLogo>
        </Grid>
      </Container>
      <FirstAccessDialog open={open} onClose={handleClose} />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        message="Email ou senha inválidos"
        key="top center"
      />
    </div>
  )
}

export default LoginPage
