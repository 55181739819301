/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { LoadingTable } from '../../components/LoadingTable'
import { ReportSellerTable } from '../../components/ReportSellerTable'
import { ReportSellerType } from '../../types/reportSeller.type'

interface Props {
  isLoading?: boolean
  data: ReportSellerType[]
  dataLN: any[]
  type: string
}

export const ReportSellerTableContainer: React.FC<Props> = ({
  isLoading,
  data,
  dataLN,
  type
}) => {
  const [parseData, setParseData] = useState<ReportSellerType[]>([])
  const [loading, setLoading] = useState(true)
  console.log('parse', parseData, data)

  useEffect(() => {
    setParseData(
      data.map((item) => {
        let billed
        let billedKg
        let formattedBilled = ''
        let efficiency
        let formattedEfficiency = ''

        // Encontrar dados do LN pelo codeLn
        const findDataLN = dataLN?.filter((lN) => lN?.ordemParceiroCodigo === item?.ies?.codeLn && lN?.type === type)
        console.log('findDataLN', findDataLN)
        // Somar todos os rs do LN
        if (findDataLN) {
          const billedLN = findDataLN?.reduce((acc, dataLN) => {
            return Number(acc) + Number(dataLN?.rs || 0)
          }, 0)
          const billedKgLN = findDataLN?.reduce((acc, dataLN) => {
            return Number(acc) + Number(dataLN?.kg || 0)
          }, 0)

          billed = billedLN
          billedKg = billedKgLN
          formattedBilled = `R$ ${billedLN?.toLocaleString(
            'pt-BR',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )}`

          if (item?.pesoKg) {
            // efficiency = billed / (item?.previsionPrice / 100)
            efficiency = (billedKg * 100) / item.pesoKg // Faturado x 100 / Previsão
            formattedEfficiency = `${Math.round(efficiency)}%`
          }
          // if (item?.pesoKg) {
          //   // efficiency = billed / (item?.previsionPrice / 100)
          //   efficiency = (billed * 100) / item.pesoKg // Faturado x 100 / Previsão
          //   formattedEfficiency = `${Math.round(efficiency)}%`
          // }
        }

        console.log('FIND LN', findDataLN)

        return {
          ...item,
          billed, // Faturado LN
          billedKg,
          formattedBilled, // Faturado LN com marcara
          efficiency,
          formattedEfficiency,
          formattedPrevisionPrice: `R$ ${item?.previsionPrice?.toLocaleString(
            'pt-BR',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )}`,
          formattedPrevisionPriceKg: `R$ ${item?.previsionPriceKg?.toLocaleString(
            'pt-BR',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          )}`,
          // formattedPesoKg: item?.pesoKg
          //   ?.toLocaleString('en-US', {
          //     minimumFractionDigits: 3
          //   })
          //   .replace(/(,)/gi, '')
          formattedPesoKg: item?.pesoKg
            ?.toLocaleString('pt-BR'),
          formattedBilledKg: billedKg
            ?.toLocaleString('pt-BR')
        }
      })
    )
    setLoading(false)
  }, [data, data.length])

  return (
    <>
      {(loading || isLoading) && <LoadingTable />}

      {!loading && !isLoading && <ReportSellerTable data={parseData} />}
    </>
  )
}
