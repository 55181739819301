/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress, Icon, Table } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import { useQuery } from 'react-query'
import moment from 'moment'
import {
  getReportMonthly,
  getReportMonthlyDays,
  getReportMonthlyItem,
  getReportMonthlyLocal,
  getReportMonthlyLocalItem,
  getReportMonthlyStock
} from 'services/report/ReportMonthlyService'
import SelectModules from './SelectModules'
import { formatValue, toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    verticalAlign: 'top'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const BoxStyle = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  marginright: 88px;
`
const IconStyle = styled(Icon)`
  margin-left: 10px;
`

const PerPlates = (props: any) => {
  const [rows, setRows] = React.useState<any[]>([])
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')

  const queryMultiple = () => {
    // requisição externa do LN
    const req1 = useQuery(
      ['plate-query', mes, ano],
      async () => {
        return getReportMonthly(mes, ano)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )
    // requisição banco local
    const req2 = useQuery(
      ['local', mes, ano],
      async () => {
        return getReportMonthlyLocal(mes, ano)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const req3 = useQuery(
      ['plate-query-days', mes, ano],
      async () => {
        return getReportMonthlyDays(mes, ano)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const req4 = useQuery(
      ['plate-query-stock', mes, ano],
      async () => {
        return getReportMonthlyStock(mes, ano)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const req5 = useQuery(
      ['plate-query-item', mes, ano],
      async () => {
        return getReportMonthlyLocalItem(mes, ano)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const req6 = useQuery(
      ['plate-query-item-ln', mes, ano],
      async () => {
        return getReportMonthlyItem(mes, ano)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )
    return [req1, req2, req3, req4, req5, req6]
  }

  const parseTableData = (
    dataClient: any,
    dataRevenues: any,
    dataValue: any,
    dataStock: any
  ) => {
    try {
      // Previsão
      const data: any[] = []
      let paperClientData: any = {}
      if (dataClient?.chapas?.length) {
        paperClientData = dataClient.chapas.reduce((a, b) => ({
          pesoKg: parseInt(a.pesoKg) + parseInt(b.pesoKg),
          previsionPrice:
            parseInt(a.previsionPrice) + parseInt(b.previsionPrice),
          previsionPriceKg:
            Number(a.previsionPriceKg ?? 0) + Number(b.previsionPriceKg ?? 0)
        }))
      }

      // estoque
      const estoque: any[] = []
      dataStock.result.forEach((item) => {
        if (item.tipo === TipoProduto.CHAPA_ALT) {
          item.tipo = TipoProduto.CHAPA
        }
        if (item.tipo === TipoProduto.CHAPA) {
          estoque.push(item)
        }
      })

      // Faturamento
      const paperRevenueDataArray: any[] = []
      dataRevenues.response.forEach((e) => {
        e.dados.forEach((d) => {
          // Papel
          if (d.type === TipoProduto.CHAPA_ALT) {
            d.type = TipoProduto.CHAPA
          }
          if (d.type === TipoProduto.CHAPA) {
            paperRevenueDataArray.push(d)
          }
        })
      })

      const arrayFiltrado: any[] = []
      if (paperRevenueDataArray.length) {
        paperRevenueDataArray.reduce(function (res, value) {
          if (!res[value.periodo]) {
            res[value.periodo] = {
              kg: 0,
              periodo: value.periodo,
              rs: 0,
              rskg: 0,
              type: value.type
            }
            arrayFiltrado.push(res[value.periodo])
          }
          res[value.periodo].kg += value.kg
          res[value.periodo].rs += value.rs
          res[value.periodo].rskg += value.rskg
          return res
        }, {})
      }

      let paperRevenueData: any = {}
      if (paperRevenueDataArray.length) {
        paperRevenueData = arrayFiltrado.reduce((a, b) => ({
          kg: parseFloat(a.kg) + parseFloat(b.kg),
          rs: parseFloat(a.rs) + parseFloat(b.rs),
          rskg: parseFloat(a.rskg) + parseFloat(b.rskg)
        }))
      }

      // Pedidos de clientes
      const dataArrayBox: any[] = []
      dataValue?.response?.forEach((e) => {
        e?.dados?.forEach((d) => {
          // Papel
          if (d.type === TipoProduto.CHAPA_ALT) {
            d.type = TipoProduto.CHAPA
          }
          if (d.type === TipoProduto.CHAPA) {
            dataArrayBox.push(d)
          }
        })
      })

      const arrayFiltradoFat: any[] = []
      if (dataArrayBox.length) {
        dataArrayBox.reduce(function (item, value) {
          if (!item[value.periodo]) {
            item[value.periodo] = {
              kg: 0,
              periodo: value.periodo,
              rs: 0,
              rskg: 0,
              type: value.type
            }
            arrayFiltradoFat.push(item[value.periodo])
          }
          item[value.periodo].kg += value.kg
          item[value.periodo].rs += value.rs
          item[value.periodo].rskg += value.rskg
          return item
        }, {})
      }

      let paperCad: any = {}
      if (dataArrayBox.length) {
        paperCad = arrayFiltradoFat.reduce((a, b) => ({
          kg: parseFloat(a.kg) + parseFloat(b.kg),
          rs: parseFloat(a.rs) + parseFloat(b.rs),
          rskg: parseFloat(a.rskg) + parseFloat(b.rskg)
        }))
      }

      const rsKgPedidos = paperCad.rs / paperCad.kg
      const rskgFaturamento = paperRevenueData.rs / paperRevenueData.kg

      data.push({
        _id: 'chapas',
        product: 'Chapas',
        prevKg: paperClientData.pesoKg ?? 0,
        prevRs: paperClientData.previsionPrice ?? 0,
        prevRsKg: paperClientData.previsionPriceKg ?? 0,
        cadKg: paperCad.kg ?? 0,
        cadRs: paperCad.rs ?? 0,
        cadRsKg:
          Number.isNaN(rsKgPedidos) || !Number.isFinite(rsKgPedidos)
            ? 0
            : rsKgPedidos,
        fatKg: paperRevenueData.kg ?? 0,
        fatRs: paperRevenueData.rs ?? 0,
        fatRsKg:
          Number.isNaN(rskgFaturamento) || !Number.isFinite(rskgFaturamento)
            ? 0
            : rskgFaturamento,
        stock: estoque[0]?.kg ?? 0,
        fob:
          Number.isNaN(rskgFaturamento) || !Number.isFinite(rskgFaturamento)
            ? 0
            : rskgFaturamento - (rskgFaturamento / 100 * 3.4)

      })
      console.warn('OK', data)
      setRows(data)
    } catch (error) {
      console.log('error:', error)
    }
  }

  const [req1, req2, req3, req4, req5, req6] = queryMultiple()

  useEffect(() => {
    if (
      !req1.isLoading &&
      !req2.isLoading &&
      !req3.isLoading &&
      !req4.isLoading
    ) {
      parseTableData(req2.data, req1.data, req3.data, req4.data)
    }
  }, [req1.isLoading, req2.isLoading, req3.isLoading, req4.isLoading])

  return (
    <>
      {(req1.isLoading ||
        req2.isLoading ||
        req3.isLoading ||
        req4.isLoading) && (
          <Box
            sx={{ display: 'flex' }}
            style={{
              justifyContent: 'center',
              marginTop: '124px',
              marginRight: '88px'
            }}
          >
            <CircularProgress />
          </Box>
        )}
      {req1.isError && req2.isError && req3.isError && req4.isError && (
        <BoxStyle>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <IconStyle>signal_wifi_statusbar_connected_no_internet_4</IconStyle>
        </BoxStyle>
      )}

      {!req1.isLoading &&
        !req2.isLoading &&
        !req3.isLoading &&
        !req4.isLoading && (
          <>
            <TableContainer style={{ marginTop: '30px' }} component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Previsão Kg</StyledTableCell>
                    <StyledTableCell align="left">Previsão R$</StyledTableCell>
                    <StyledTableCell align="left">Previsão R$/Kg</StyledTableCell>

                    <StyledTableCell align="left">Cadastro Kg</StyledTableCell>
                    <StyledTableCell align="left">Cadastro R$</StyledTableCell>
                    <StyledTableCell align="left">Cadastro R$/Kg</StyledTableCell>

                    <StyledTableCell align="left">Faturado Kg</StyledTableCell>
                    <StyledTableCell align="left">Faturado R$</StyledTableCell>
                    <StyledTableCell align="left">Faturado R$/Kg</StyledTableCell>

                    <StyledTableCell align="left">R$ FOB</StyledTableCell>
                    <StyledTableCell align="left">Estoque do mês</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell align="left">{formatValue(row.prevKg)}</StyledTableCell>
                      <StyledTableCell align="left">{toBRL(row.prevRs, { noBRL: true })}</StyledTableCell>
                      <StyledTableCell align="left">
                        {
                          toBRL(row.prevRs
                            ? Number(Number(row.prevRs ?? 0) / Number(row.prevKg ?? 1))
                            : 0
                            , { noBRL: true })
                        }
                      </StyledTableCell>

                      <StyledTableCell align="left">{formatValue(row.cadKg)}</StyledTableCell>
                      <StyledTableCell align="left">{toBRL(row.cadRs, { noBRL: true })}</StyledTableCell>
                      <StyledTableCell align="left">{toBRL(row.cadRsKg, { noBRL: true })}</StyledTableCell>

                      <StyledTableCell align="left">{formatValue(row.fatKg)}</StyledTableCell>
                      <StyledTableCell align="left">{toBRL(row.fatRs, { noBRL: true })}</StyledTableCell>
                      <StyledTableCell align="left">{toBRL(row.fatRsKg, { noBRL: true })}</StyledTableCell>

                      <StyledTableCell align="left">{toBRL(row.fob, { noBRL: true })}</StyledTableCell>
                      <StyledTableCell align="left">{formatValue(row.stock)}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <SelectModules
              date={props.date}
              days={props.days}
              prevision={props.prevision.data.chapa}
              type={[TipoProduto.CHAPA, TipoProduto.CHAPA_ALT]}
              req1={req1}
              req2={req2}
              req3={req3}
              req5={req5}
              req6={req6}
            />
          </>
        )}
    </>
  )
}

export default PerPlates
