import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import React from 'react'
import { BreadcrumbsType } from 'types/breadcrumbs/BreadcrumbsType'
import { Container } from './components/Container'
import { GoBack } from './components/GoBack'
import { Header } from './components/Header'
import { StockContainer } from './container/StockContainer'

const breadcrumbs: BreadcrumbsType[] = [
  { text: 'Relatórios', url: '/home/report' },
  { text: 'Relatório Estoque' }
]

const Stock: React.FC = () => {
  return (
    <Container>
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
      <Header title="Relatório Estoque" />
      <StockContainer />
      <GoBack forRoute="/home/report" />
    </Container>
  )
}

export { Stock }
