import React from 'react'
import { CssBaseline } from '@mui/material'
import styled from 'styled-components'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import TableSalesGoals from './TableSalesGoals'

const GridStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const salesGoals: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <CssBaseline />
      <GridStyle>
        <div>
          <BreadcrumbsComponent
            breadcrumbs={[
              { text: 'Gerencial', url: '/home/managementControl' },
              { text: 'Meta de Vendas' }
            ]}
          />
        </div>
      </GridStyle>
      <LightTooltip title='Cadastrar' placement='top' onClick={() => { navigate('/home/managementControl/management/sales-goals-form') }}>
        <ButtonHeader style={{ justifySelf: 'start' }}>
          <AddIcon />
        </ButtonHeader>
      </LightTooltip>

      <TableSalesGoals />
    </>
  )
}

export default salesGoals
