import moment from 'moment'

export function calcularIndiceRefugo(date) {
    const momentDate = moment(date)
    const cutoffDate = moment('01-06-2023', 'DD-MM-YYYY')
    if (momentDate.isBefore(cutoffDate)) {
        return 0.25
    } else {
        return 0.13
    }
}
