/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Add,
  ArrowBack,
  ExpandMore
} from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableContainer, TableHead, TextField } from '@mui/material'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import { onlyNumbersOrVirgula } from 'helpers/stringHelper'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { putBudgetRealized, updateItemBudgetRealized, deleteItemBudgetRealized } from 'services/budgetRealized/BudgetRealizedService'
import { validationSchemaMaterial } from 'types/validation/ValidationMaterial'
import { BudgetRealizedType } from 'types/budgetRealized/BudgetRealizedType'
import NumericInput from 'material-ui-numeric-input'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import MoreMenu from 'components/view/MoreMenu'
import BudgetConfigItemForm from './BugdetConfigItemForm'
import BudgetConfigCreateItemForm from './BudgetConfigCreateItemForm'

function BudgetConfigForm(props: any) {
  const DELETE_ITEM_MUTATION_KEY = 'deleteItemBudgetRealized'
  const [open, setOpen] = useState<boolean>(false)

  const [openEditItem, setOpenEditItem] = React.useState(false)
  const [dataEditItem, setDataEditItem] = React.useState<any>()

  const [openCreateItem, setOpenCreateItem] = React.useState(false)
  const [dataCreateItem, setDataCreateItem] = React.useState<any>()

  const handleBtnCreateClick = async (event) => {
    event.preventDefault()
    setOpenCreateItem(true)
    const dataObj = { data: [], _id: values._id }
    await setDataCreateItem(dataObj)
  }
  const handleSaveItem = (editedItemData) => {
    console.log('editedItemData', editedItemData)
    setFieldValue('categories[0].items', editedItemData)
  }

  const handleCreateItem = (createdItemData) => {
    console.log('createdItemData', createdItemData)
    setFieldValue('categories[0].items', createdItemData)
  }
  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(putBudgetRealized, {
    onSuccess: data => {
      toast.success('Atualizado com sucesso')
      props.close()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível atualizar')
      }
    }
  })

  const initialValues = {
    _id: props.edit?._id,
    month: props.edit?.month || 0,
    year: props.edit?.year || 0,
    refuse: props.edit?.refuse || 0,
    module: props.edit?.module || 5,
    categories: props.edit?.categories ?? [],
    items: props.edit?.categories[0]?.items ?? []
  }

  const createValuesForm = (values) => {
    const categories = values.categories || []
    return {
      month: values.month,
      year: values.year,
      refuse: values.refuse,
      module: values.module,
      categories: [
        {
          type: categories[0]?.type || 'Não definido', // Use optional chaining to safely access properties
          percentage: categories[0]?.percentage || 0,
          launchValue: categories[0]?.launchValue || 0,
          items: categories[0]?.items || []
        }
      ]
    }
  }

  const onSubmit = (values: BudgetRealizedType) => {
    console.log('categories and items submitted')
    console.log('values', values)
    if (props.edit) {
      const valuesForm = {
        ...createValuesForm(values),
        _id: props.edit._id
      }
      mutateEdit(valuesForm)
    } else {
      /* mutateCreate(createValuesForm(values)); */
    }
  }
  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik({
    onSubmit,
    enableReinitialize: true,
    /* validationSchema: validationSchemaMaterial, */
    initialValues
  })

  const getModuleLabel = (module) => {
    switch (module) {
      case 1:
        return 'Administrativo'
      case 2:
        return 'Materiais de PO'
      case 3:
        return 'Materiais de Papel'
      case 4:
        return 'Insumos de Papel'
      default:
        return 'Módulo Desconhecido'
    }
  }
  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: currentYear - 2020 + 1 }, (_, i) => currentYear - i)

  React.useEffect(() => {
    console.log('ok', errors)
  }, [])

  const closeModal = () => setOpen(false)

  const { mutate: mutateDel } = useMutation(DELETE_ITEM_MUTATION_KEY, deleteItemBudgetRealized, {
    onSuccess: (data, variables) => {
      console.log('success', data)
      setFieldValue('categories[0].items', data?.items)
      toast.success('Item deletado com sucesso')
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível deletar o item')
      }
    }
  })

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip title='Voltar' placement='top' onClick={() => { props.close() }}>
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>Editar Categoria</div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px', flex: 1 }}>
        <form autoComplete="off" onSubmit={handleSubmit} >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginRight: '30px',
            marginTop: '20px'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px',
                  width: '100%'
                }}
              >
                <Grid container spacing={2} >
                  {/* Grid para o Tipo select e campo de texto */}
                  <Grid item xs={12} sm={6}>
                    <FormControl required variant="standard" fullWidth sx={{ marginBottom: '16px' }} error={!!(errors.module && touched.module)}>
                      <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id="demo-simple-select"
                        value={values.module}
                        onChange={(e) => { setFieldValue('module', e.target.value) }}
                        defaultValue={initialValues.module}
                        error={Boolean(errors.module && touched.module)}
                        style={{ color: '#A8A8A8' }}
                      >
                        {Array.from({ length: 4 }, (_, i) => i + 1).map((module) => (
                          <MenuItem key={module} value={module} >
                            {getModuleLabel(module)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      required variant="standard" fullWidth sx={{ marginBottom: '16px' }}
                      id="text-field"
                      label="Índice de Refugo (%)"
                      value={values.refuse}
                      type="number"
                      onChange={(e) => { setFieldValue('refuse', e.target.value) }}
                      error={!!(errors.refuse && touched.refuse)}
                      helperText={errors.refuse && touched.refuse ? String(errors.refuse) : ''}
                      style={{ color: '#A8A8A8' }}
                    />
                  </Grid>

                  {/* Grid para o mês e ano */}
                  <Grid item xs={12} sm={6}>
                    <FormControl required variant="standard" fullWidth sx={{ marginBottom: '16px' }} error={!!(errors.month && touched.month)}>
                      <InputLabel id='month-label'>Mês</InputLabel>
                      <Select
                        labelId='month-label'
                        value={values.month}
                        onChange={(e) => { setFieldValue('month', e.target.value) }}
                        error={Boolean(errors.month && touched.month)}
                        style={{ color: '#A8A8A8' }}
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl required variant="standard" fullWidth sx={{ marginBottom: '16px' }} error={!!(errors.year && touched.year)}>
                      <InputLabel id='year-label'>Ano</InputLabel>
                      <Select
                        labelId='year-label'
                        value={values.year}
                        onChange={(e) => { setFieldValue('year', e.target.value) }}
                        error={Boolean(errors.year && touched.year)}
                        style={{ color: '#A8A8A8' }}
                      >
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px',
                  width: '100%'
                }}
              >
                <Grid container spacing={2} sx={{ backgroundColor: '#F8F8F8' }}>
                  {/* Grid para o Tipo select e campo de texto */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required variant="standard" fullWidth sx={{ marginBottom: '16px' }}
                      id="text-field"
                      label="Categoria"
                      value={values.categories[0].type}
                      onChange={(e) => { setFieldValue('categories[0].type', e.target.value) }}
                      style={{ color: '#A8A8A8' }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ backgroundColor: '#F8F8F8' }}>
                  {/* Grid para o Tipo select e campo de texto */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required variant="standard" fullWidth sx={{ marginBottom: '16px' }}
                      id="text-field"
                      label="Previsão de Inflação (%)"
                      value={values.categories[0].percentage}
                      type="number"
                      onChange={(e) => { setFieldValue('categories[0].percentage', e.target.value) }}
                      style={{ color: '#A8A8A8' }}
                    />
                  </Grid>
                  {/* Grid para o Tipo select e campo de texto */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required variant="standard" fullWidth sx={{ marginBottom: '16px' }}
                      id="text-field"
                      label="Valor de Lançamento"
                      value={values.categories[0].launchValue}
                      type="number"
                      onChange={(e) => { setFieldValue('categories[0].launchValue', e.target.value) }}
                      style={{ color: '#A8A8A8' }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px',
                  width: '100%'
                }}
              >
                <Accordion
                  sx={{
                    borderRadius: '4px',
                    padding: '0.5rem 0',
                    boxShadow: '0px 3px 6px #00000029'
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '1.2em',
                    fontWeight: 'bold'
                  }}>
                    <Chip
                      label='Contas Contábeis'
                      variant='outlined'
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                  {true && (
                    <TableContainer elevation={0} component={Paper}>
                      <Can
                        module={MODULES.RELATÓRIOS}
                        permissions={[PERMISSIONS.CREATE, PERMISSIONS.UPDATE]}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ marginBottom: 3, display: 'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: '18px' }}
                        >
                          <Button
                            color="primary"
                            style={{ background: '#4060e6', color: 'white' }}
                            onClick = { handleBtnCreateClick }
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Add fontSize='small' style={{ marginRight: '5px' }} />
                              <span style={{ fontSize: '18px' }}>Item</span>
                            </div>
                          </Button>

                        </Stack>
                      </Can>
                      <Table>
                        <TableHead>
                          <StyledTableRow style={{
                                  display: 'grid',
                                  gridTemplateColumns: '1fr 1fr 1fr 1fr'
                            }}>
                            <StyledTableCell>Contas</StyledTableCell>
                            <StyledTableCell>Descrição</StyledTableCell>
                            <StyledTableCell align='center'>Porcentagem (%)</StyledTableCell>
                            <Can
                              module={MODULES.RELATÓRIOS}
                              permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                            >
                              <StyledTableCell align="right">Ações</StyledTableCell>
                            </Can>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {values.categories[0].items.map((row, i) => (
                            <StyledTableRow
                            key={i}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr 1fr 1fr'
                            }}
                            >
                              <StyledTableCell>{row.account}</StyledTableCell>
                              <StyledTableCell>{row.describeAccount}</StyledTableCell>
                              <StyledTableCell align="center">{row.launchPercentage}</StyledTableCell>
                              <Can
                                module={MODULES.RELATÓRIOS}
                                permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                              >
                                <StyledTableCell align="right">
                                  <MoreMenu
                                    module={MODULES.RELATÓRIOS}
                                    isValidationPermissions
                                    edit = { async () => {
                                      setOpenEditItem(true)
                                      const dataObj = { data: row, _id: values._id }
                                      await setDataEditItem(dataObj)
                                    }}
                                    delete={async () => { mutateDel({ id1: row._id, id2: values._id }) }}
                                  />
                                </StyledTableCell>
                              </Can>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  </AccordionDetails>
                </Accordion>

                {openEditItem &&
                  <GenericDialog open={openEditItem} close={() => { setOpenEditItem(false); setDataEditItem(null) }}
                    content={<BudgetConfigItemForm edit={dataEditItem} close={() => { setOpenEditItem(false); setDataEditItem(null) }} onSave={handleSaveItem} />} />
                }

                {openCreateItem &&
                  <GenericDialog open={openCreateItem} close={() => { setOpenCreateItem(false); setDataCreateItem(null) }}
                    content={<BudgetConfigCreateItemForm edit={dataCreateItem} close={() => { setOpenCreateItem(false); setDataCreateItem(null) }} onSave={handleCreateItem} />} />
                }
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type='button'
              mold='red'
              onClick={() => { props.close() }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold='primary'
              type="submit"
              disabled={isLoadingEdit}
            >
              SALVAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default BudgetConfigForm
