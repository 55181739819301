/* eslint-disable operator-linebreak */
import { Box, CircularProgress, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import moment from 'moment'
import { StyledTableCell, StyledTableRow } from 'pages/purchaseOrder/components/table.styles'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getOrderSales } from 'services/pedido/PedidoService'

interface Props {
  date: string
}
const SalesOrdersTable: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0)
  const date = moment(props.date).format('YYYY/MM/DD')

  const { data, status, refetch, isFetching } = useQuery(
    ['sales-order', date],
    async () => getOrderSales({ page: page, date }),
    {
      refetchOnWindowFocus: false
    }
  )

  console.log(data)

  useEffect(() => {
    refetch()
  }, [page])

  return (
    <>
      {status === 'loading' && (
        <Box
          sx={{
            display: 'flex',
            height: 200,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'success' && (
        <TableContainer component={Paper}>
          {isFetching && (
            <Box display="flex" alignItems="center">
              <CircularProgress size={18} sx={{ marginRight: 1 }} />
              <Typography component="span" variant="body2" color="primary">
                Carregando...
              </Typography>
            </Box>
          )}

          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Nº Ordem</StyledTableCell>
                <StyledTableCell align="left">Data solicitação</StyledTableCell>
                <StyledTableCell align="left">Quantidade solicitada</StyledTableCell>
                <StyledTableCell align="left">Quantidade faturada</StyledTableCell>
                <StyledTableCell align="left">Data faturada</StyledTableCell>
                <StyledTableCell align="left">Item</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'success' && !data?.result?.length && (
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <Box
                      sx={{
                        display: 'flex',
                        height: 200,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography component="span" variant="body2">
                        Nenhuma ordem encontrada
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              )}
              {data?.result?.map((row, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row?.linhaFaturarOV}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(row?.dataEntregaOV).format('DD/MM/YYYY \\à\\s HH:mm')}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {Number(row?.quantidadeSolicitada)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {Number(row?.quantidadeFaturada)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(row?.dataFaturamento).isValid() ? moment(row?.dataFaturamento).format('DD/MM/YYYY \\à\\s HH:mm') : 'Aguardando faturar'}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.item}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20]}
            rowsPerPage={20}
            component="div"
            count={data?.result?.rows ?? 0}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        </TableContainer>
      )}
    </>
  )
}

export default SalesOrdersTable
