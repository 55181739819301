/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { ReactNode } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import {
  ButtonRow,
  DialogStyle,
  DivRow,
  LabelText
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import ModalHead from './ModalHead';

function Section({ title, children }: { title: string, children: ReactNode }) {
  return (
    <>
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <h4>{title}</h4>
      </Grid>
      {children}
    </>
  );
}
interface Props {
  open: boolean
  close: () => void
  data?: any
  bookFinished?: boolean
}

const Solicitation: React.FC<Props> = (props) => {
  const { bookFinished } = props
  console.log(props.data);

  const closeModal = () => props.close();

  return (
    <>
      <DialogStyle
        open={props.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHead title="Solicitação" idPreFT={props?.data?.ft?.preFT?._id} />

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ marginTop: '24px' }}>
              <LabelText>
                Dados da Solicitação -{' '}
                {props?.data?.preFT?.preftbox?._id ? 'Caixa' : 'Chapa'}
              </LabelText>
              <DivRow style={{ marginTop: '-20px' }}>
                <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Numero da FT"
                      value={props?.data?.ft?.itemReference}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Nome da Empresa"
                      value={props?.data?.preFT?.client?.name}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Emitido por"
                      value={props?.data?.preFT?.seller?.user?.name}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Rêferencia"
                      value={
                        props?.data?.preFT?.preftbox?.reference ??
                        props?.data?.preFT?.formulariochapa?.Referencia ??
                        props?.data?.preFT?.preftplate?.Referencia
                      }
                      variant="standard"
                      disabled
                    />
                  </Grid>
                </Grid>
              </DivRow>
            </div>
            <Grid container spacing={2}>
              {props?.data?.preFT?.preftbox?.planningRD && (
                <Section title="Planejamento do P&D">
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="Definicao">Definição</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Definicao"
                        name="Definicao"
                        value={
                          (props?.data?.preFT?.preftbox?.planningRD?.definition
                            ?.justToDo
                            ? 'justToDo'
                            : '') ??
                          (props?.data?.preFT?.preftbox?.planningRD?.definition
                            ?.projectAndDo
                            ? 'projectAndDo'
                            : '') ??
                          (props?.data?.preFT?.preftbox?.planningRD?.definition
                            ?.withSimiliarProject
                            ? 'withSimiliarProject'
                            : '')
                        }
                      >
                        <FormControlLabel
                          value="justToDo"
                          control={<Radio />}
                          label="Item para desenvolver"
                          disabled
                        />
                        <FormControlLabel
                          value="projectAndDo"
                          control={<Radio />}
                          label="Item para Projetar e Desenvolver"
                          disabled
                        />
                        <FormControlLabel
                          value="withSimiliarProject"
                          control={<Radio />}
                          label="Item com projetos similares"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="NovoProduto">Novo Produto</FormLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.basedFTD
                          }
                          disabled
                        />
                      }
                      label="Baseada na FTD"
                    />
                  </Grid>
                  {props?.data?.preFT?.preftbox?.planningRD?.newProduct
                    ?.basedFTD && (
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={
                          props?.data?.preFT?.preftbox?.planningRD?.newProduct
                            ?.basedFTDValue
                        }
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.drawingOrSpecification
                          }
                          disabled
                        />
                      }
                      label="Desenho/Especificação"
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.mechanicalSample
                          }
                          disabled
                        />
                      }
                      label="Amostra Mecânica"
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.productInCustomer
                          }
                          disabled
                        />
                      }
                      label="Produto do Cliente"
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.similarProduct
                          }
                          disabled
                        />
                      }
                      label="Produto semelhante"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={1}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                !!props?.data?.preFT?.preftbox?.planningRD
                                  ?.newProduct?.other?.length
                              }
                              disabled
                            />
                          }
                          label="Outros"
                        />
                      </Grid>
                      {!!props?.data?.preFT?.preftbox?.planningRD?.newProduct
                        ?.other?.length && (
                        <Grid item xs={11}>
                          <TextField
                            type="text"
                            placeholder="Observação"
                            variant="outlined"
                            value={
                              props?.data?.preFT?.preftbox?.planningRD
                                ?.newProduct?.other
                            }
                            autoComplete="off"
                            fullWidth
                            multiline
                            disabled
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Section>
              )}

              {props?.data?.preFT?.preftbox?._id ? (
                <>
                  <Section title="Especificações">
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Composição"
                        value={
                          props?.data?.preFT?.preftbox?.specifications
                            ?.Composicao?.composicao
                        }
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Tipo de caixa"
                        value={
                          props?.data?.preFT?.preftbox?.specifications?.boxType
                            ?.name
                        }
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Cod. Caixa"
                        value={
                          props?.data?.preFT?.preftbox?.specifications
                            ?.codTypebox?.cod
                        }
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            label="Tipo de Onda"
                            variant="standard"
                            value={
                              props?.data?.preFT?.preftbox?.specifications
                                ?.Composicao?.onda
                            }
                            fullWidth
                            disabled
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Papel"
                            variant="standard"
                            value={`${
                              props?.data?.preFT?.preftbox?.specifications
                                ?.Composicao?.papel1
                                ? props?.data?.preFT?.preftbox?.specifications
                                    ?.Composicao?.papel1 + ','
                                : ''
                            }${
                              props?.data?.preFT?.preftbox?.specifications
                                ?.Composicao?.papel2
                                ? props?.data?.preFT?.preftbox?.specifications
                                    ?.Composicao?.papel2 + ','
                                : ''
                            }${
                              props?.data?.preFT?.preftbox?.specifications
                                ?.Composicao?.papel3
                                ? props?.data?.preFT?.preftbox?.specifications
                                    ?.Composicao?.papel3 + ','
                                : ''
                            }${
                              props?.data?.preFT?.preftbox?.specifications
                                ?.Composicao?.papel4
                                ? props?.data?.preFT?.preftbox?.specifications
                                    ?.Composicao?.papel4 + ','
                                : ''
                            }${
                              props?.data?.preFT?.preftbox?.specifications
                                ?.Composicao?.papel5
                                ? props?.data?.preFT?.preftbox?.specifications
                                    ?.Composicao?.papel5 + ','
                                : ''
                            }`.slice(0, -1)}
                            fullWidth
                            disabled
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Nome Comercial"
                            variant="standard"
                            value={
                              props?.data?.preFT?.preftbox?.specifications
                                ?.Composicao?.nomeComercial ?? ''
                            }
                            fullWidth
                            disabled
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Section>

                  <Section title="Dimensões">
                    <Grid item xs={6} md={3}>
                      <TextField
                        type="number"
                        label="Largura Interna"
                        variant="standard"
                        value={
                          props?.data?.preFT?.preftbox?.internalMeasures?.width
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                      +
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        type="number"
                        label="Comprimento Interno"
                        variant="standard"
                        value={
                          props?.data?.preFT?.preftbox?.internalMeasures
                            ?.diameter
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                      +
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        type="number"
                        label="Altura Interna"
                        variant="standard"
                        value={
                          props?.data?.preFT?.preftbox?.internalMeasures?.height
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      md={1}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end'
                        }}
                      >
                        =
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={1}>
                      <TextField
                        type="number"
                        label="Total (GRM)"
                        variant="standard"
                        value={
                          Number(
                            props?.data?.preFT?.preftbox?.internalMeasures
                              ?.diameter ?? 0
                          ) +
                          Number(
                            props?.data?.preFT?.preftbox?.internalMeasures
                              ?.diameter ?? 0
                          ) +
                          Number(
                            props?.data?.preFT?.preftbox?.internalMeasures
                              ?.height ?? 0
                          )
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        type="text"
                        label="Fechamento"
                        variant="standard"
                        value={
                          props?.data?.preFT?.preftbox?.internalMeasures
                            ?.Fechamento?.fechamento
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Section>
                </>
              ) : (
                <>
                  <Section title="Especificações">
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Composição"
                        value={
                          props?.data?.preFT?.formulariochapa?.Composicao
                            ?.composicao ??
                          props?.data?.preFT?.preftplate?.Composicao?.composicao
                        }
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            label="Tipo de Onda"
                            variant="standard"
                            value={
                              props?.data?.preFT?.formulariochapa?.Composicao
                                ?.onda ??
                              props?.data?.preFT?.preftplate?.Composicao?.onda
                            }
                            fullWidth
                            disabled
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Papel"
                            variant="standard"
                            value={`${
                              props?.data?.preFT?.formulariochapa?.Composicao
                                ?.papel1 ||
                              props?.data?.preFT?.preftplate?.Composicao?.papel1
                                ? props?.data?.preFT?.formulariochapa
                                    ?.Composicao?.papel1 ??
                                  props?.data?.preFT?.preftplate?.Composicao
                                    ?.papel1 + ','
                                : ''
                            }${
                              props?.data?.preFT?.formulariochapa?.Composicao
                                ?.papel2 ||
                              props?.data?.preFT?.preftplate?.Composicao?.papel2
                                ? props?.data?.preFT?.formulariochapa
                                    ?.Composicao?.papel2 ??
                                  props?.data?.preFT?.preftplate?.Composicao
                                    ?.papel2 + ','
                                : ''
                            }${
                              props?.data?.preFT?.formulariochapa?.Composicao
                                ?.papel3 ||
                              props?.data?.preFT?.preftplate?.Composicao?.papel3
                                ? props?.data?.preFT?.formulariochapa
                                    ?.Composicao?.papel3 ??
                                  props?.data?.preFT?.preftplate?.Composicao
                                    ?.papel3 + ','
                                : ''
                            }${
                              props?.data?.preFT?.formulariochapa?.Composicao
                                ?.papel4 ||
                              props?.data?.preFT?.preftplate?.Composicao?.papel4
                                ? props?.data?.preFT?.formulariochapa
                                    ?.Composicao?.papel4 ??
                                  props?.data?.preFT?.preftplate?.Composicao
                                    ?.papel1 + ','
                                : ''
                            }${
                              props?.data?.preFT?.formulariochapa?.Composicao
                                ?.papel5 ||
                              props?.data?.preFT?.preftplate?.Composicao?.papel5
                                ? props?.data?.preFT?.formulariochapa
                                    ?.Composicao?.papel5 ??
                                  props?.data?.preFT?.preftplate?.Composicao
                                    ?.papel1 + ','
                                : ''
                            }`.slice(0, -1)}
                            fullWidth
                            disabled
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Nome Comercial"
                            variant="standard"
                            value={
                              props?.data?.preFT?.formulariochapa?.Composicao
                                ?.nomeComercial ??
                              props?.data?.preFT?.preftplate?.Composicao
                                ?.nomeComercial ??
                              ''
                            }
                            fullWidth
                            disabled
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Section>

                  <Section title="Dimensões">
                    <Grid item xs={6} md={3}>
                      <TextField
                        type="number"
                        label="Largura Interna"
                        variant="standard"
                        value={
                          props?.data?.preFT?.formulariochapa?.LarguraInterna ??
                          props?.data?.preFT?.preftplate?.LarguraInterna
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                      +
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        type="number"
                        label="Comprimento Interno"
                        variant="standard"
                        value={
                          props?.data?.preFT?.formulariochapa
                            ?.ComprimentoInterno ??
                          props?.data?.preFT?.preftplate?.ComprimentoInterno
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                      +
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        type="number"
                        label="Altura Interna"
                        variant="standard"
                        value={
                          props?.data?.preFT?.formulariochapa?.AlturaInterna ??
                          props?.data?.preFT?.preftplate?.AlturaInterna
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      md={1}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end'
                        }}
                      >
                        =
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={1}>
                      <TextField
                        type="number"
                        label="Total (GRM)"
                        variant="standard"
                        value={
                          Number(
                            props?.data?.preFT?.formulariochapa
                              ?.LarguraInterna ??
                              props?.data?.preFT?.preftplate?.LarguraInterna ??
                              0
                          ) +
                          Number(
                            props?.data?.preFT?.formulariochapa
                              ?.ComprimentoInterno ??
                              props?.data?.preFT?.preftplate
                                ?.ComprimentoInterno ??
                              0
                          ) +
                          Number(
                            props?.data?.preFT?.formulariochapa
                              ?.AlturaInterna ??
                              props?.data?.preFT?.preftplate?.AlturaInterna ??
                              0
                          )
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        type="text"
                        label="Fechamento"
                        variant="standard"
                        value={
                          props?.data?.preFT?.formulariochapa?.Fechamento
                            ?.fechamento ??
                          props?.data?.preFT?.preftplate?.Fechamento?.fechamento
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Section>
                </>
              )}

              {props?.data?.preFT?.preftbox?._id ? (
                <Section title="Informações Adicionais">
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Paletizado">Paletizado</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Paletizado"
                        name="Paletizadopreftbox"
                        value={
                          props?.data?.preFT?.preftbox?.tiedOrPallet ?? false
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Amarrado">Amarrado</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Amarrado"
                        name="Amarrado"
                        value={props?.data?.preFT?.preftbox?.tied}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="ExigeLaudo">Laudo</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="ExigeLaudo"
                        name="ExigeLaudo"
                        value={props?.data?.preFT?.formulariocaixa?.ExigeLaudo}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Observação"
                      variant="outlined"
                      value={props?.data?.preFT?.preftbox?.extraData}
                      autoComplete="off"
                      fullWidth
                      multiline
                      minRows={3}
                      disabled
                    />
                  </Grid>
                </Section>
              ) : (
                <Section title="Informações Adicionais">
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Paletizado">Paletizado</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Paletizado"
                        name="Paletizado"
                        value={
                          props?.data?.preFT?.formulariochapa?.Paletizado ??
                          props?.data?.preFT?.preftplate?.Paletizado
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Amarrado">Amarrado</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Amarrado"
                        name="Amarrado"
                        value={
                          props?.data?.preFT?.formulariochapa?.Amarrado ??
                          props?.data?.preFT?.preftplate?.Amarrado ??
                          false
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="ExigeLaudo">Laudo</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="ExigeLaudo"
                        name="ExigeLaudo"
                        value={
                          props?.data?.preFT?.formulariochapa?.ExigeLaudo ??
                          props?.data?.preFT?.preftplate?.ExigeLaudo
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Observação"
                      variant="outlined"
                      value={
                        props?.data?.preFT?.formulariochapa?.Obs1 ??
                        props?.data?.preFT?.preftplate?.Obs1
                      }
                      autoComplete="off"
                      fullWidth
                      multiline
                      minRows={3}
                      disabled
                    />
                  </Grid>
                </Section>
              )}

              {props?.data?.preFT?.preftbox?._id && (
                <Section title="Informações Cliente">
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="PaletePadrao">Palete Padrão</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="PaletePadrao"
                        name="PaletePadrao"
                        value={
                          props?.data?.preFT?.formulariocaixa?.PaletePadrao
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    {props?.data?.preFT?.formulariocaixa?.PaletePadrao && (
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <TextField
                            type="number"
                            label="Largura"
                            variant="standard"
                            value={
                              props?.data?.preFT?.formulariocaixa
                                ?.PaletePadraoLargura
                            }
                            autoComplete="off"
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <TextField
                            type="number"
                            label="Comprimento"
                            variant="standard"
                            value={
                              props?.data?.preFT?.formulariocaixa
                                ?.PaletePadraoComprimento
                            }
                            autoComplete="off"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="PaleteReforcado">
                        Palete Reforçado
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="PaleteReforcado"
                        name="PaleteReforcado"
                        value={props?.data?.preFT?.preftbox?.reinforcedPallet}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    {props?.data?.preFT?.preftbox.reinforcedPallet && (
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <TextField
                            type="number"
                            label="Quantidade de fita"
                            variant="standard"
                            value={props?.data?.preFT?.preftbox?.amountTape}
                            autoComplete="off"
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <TextField
                            type="number"
                            label="Quantidade lastro do palete"
                            variant="standard"
                            value={
                              props?.data?.preFT?.preftbox?.amountPalletBallast
                            }
                            autoComplete="off"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="AmarradoPalete">
                        Amarrado no Palete
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="AmarradoPalete"
                        name="AmarradoPalete"
                        value={
                          props?.data?.preFT?.formulariocaixa?.AmarradoPalete
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    {props?.data?.preFT?.formulariocaixa?.AmarradoPalete && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            type="number"
                            label="Qtd Amarrado/palete"
                            variant="standard"
                            value={
                              props?.data?.preFT?.formulariocaixa
                                ?.AmarradoPaleteQtd
                            }
                            autoComplete="off"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Alcas">Alças</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Alcas"
                        name="Alcas"
                        value={props?.data?.preFT?.preftbox?.handles}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    {props?.data?.preFT?.preftbox?.handles && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            type="number"
                            label="Alças por Caixas"
                            variant="standard"
                            value={
                              props?.data?.preFT?.preftbox?.handlesValue
                            }
                            autoComplete="off"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Furo">Furo</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Furo"
                        name="Furo"
                        value={props?.data?.preFT?.preftbox?.hole}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    {props?.data?.preFT?.preftbox?.hole && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            type="number"
                            label="Furos por Caixas"
                            variant="standard"
                            value={
                              props?.data?.preFT?.preftbox?.holeValue
                            }
                            autoComplete="off"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Amostra">Amostra</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Amostra"
                        name="Amostra"
                        value={props?.data?.preFT?.preftbox?.needSample}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    {props?.data?.preFT?.preftbox?.needSample && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            type="number"
                            label="Quantidade de Amostras"
                            variant="standard"
                            value={props?.data?.preFT?.preftbox?.amountSample}
                            autoComplete="off"
                            fullWidth
                            required
                            disabled
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Impressoes">Impressões</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Impressoes"
                        name="Impressoes"
                        value={
                          !!props?.data?.preFT?.preftbox?.internalMeasures
                            ?.prints
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                    {!!props?.data?.preFT?.preftbox?.internalMeasures
                      ?.prints && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            type="number"
                            label="Quantidade de Impressões"
                            variant="standard"
                            value={
                              props?.data?.preFT?.preftbox?.internalMeasures
                                ?.prints
                            }
                            autoComplete="off"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Tara">Tara</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Tara"
                        name="Tara"
                        value={props?.data?.preFT?.formulariocaixa?.Tara}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="Batido">Batido</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Batido"
                        name="Batido"
                        value={props?.data?.preFT?.formulariocaixa?.Batido}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sim"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Não"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Section>
              )}
            </Grid>

            <ButtonRow
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                marginTop: 30
              }}
            >
              <Button
                style={{
                  marginRight: '10px',
                  backgroundColor: '#4060E6',
                  color: '#FFFFFF'
                }}
                variant="contained"
                onClick={() => {
                  props.close();
                }}
              >
                Voltar
              </Button>
            </ButtonRow>
          </DialogContentText>
        </DialogContent>
      </DialogStyle>
    </>
  );
};

export default Solicitation;
