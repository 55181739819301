import * as React from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { Card, CardActionArea, CardContent, CircularProgress, Grid, Icon } from '@mui/material'
import ChartBar from './components/ChartBar'
import ChartPie from './components/ChartPie'
import ChartPieAndPie from './components/ChartPieAndPie'
import DashboardHeader from './components/DashboardHeader'
import { getReportGoals } from 'services/report/ReportGoals'
import { useQuery } from 'react-query'
import { getReportProduction } from 'services/report/ReportProduction'
import { getPrevision, getReport, getReportOrder } from 'services/report/ReportBilling'
import moment from 'moment'
import { TipoProduto } from 'utils/enums'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'

export default function ReportDashboard() {
  const [date, setDate] = React.useState<any>(new Date())
  const [dashData, setDashData] = React.useState<any[]>([])

  const queryMultiple = () => {
    // requisição externa do LN
    const goalsReq = useQuery(['goals', date],
      async () => {
        // meta
        return getReportGoals(date)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const fatReq = useQuery(['faturamento', date],
      async () => {
        // faturamento
        return getReport(moment(date).format('DD/MM/YYYY'), [])
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const productionReq = useQuery(['production', date],
      async () => {
        // produção
        return getReportProduction(date, [])
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const previsionReq = useQuery(['previsions', date],
      async () => {
        // previsão
        return getPrevision(moment(date).format('MM'), moment(date).format('YYYY'))
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const pedidosReq = useQuery(['pedidos', date],
      async () => {
        // pedidos
        return getReportOrder(moment(date).format('DD/MM/YYYY'), [])
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )
    return [goalsReq, fatReq, productionReq, previsionReq, pedidosReq]
  }

  const [goalsReq, fatReq, productionReq, previsionReq, pedidosReq] = queryMultiple()

  const handleDateChange = (d) => {
    setDate(d)
    goalsReq.refetch()
    fatReq.refetch()
    productionReq.refetch()
    previsionReq.refetch()
    pedidosReq.refetch()
  }

  React.useEffect(() => {
    if (!goalsReq.isFetching && !fatReq.isFetching && !productionReq.isFetching && !previsionReq.isFetching && !pedidosReq.isFetching) {
      parseData(goalsReq.data, fatReq.data.response, productionReq.data, previsionReq.data, pedidosReq.data)
    }
  }, [goalsReq.isFetching, fatReq.isFetching, productionReq.isFetching, previsionReq.isFetching, pedidosReq.isFetching])

  // produção: producao e meta
  // faturamento: faturamento
  // carteira:
  // carteira kg = previsão kg,
  // faturado kg = faturamento kg
  const parseData = (goals, faturamento, production, prevision, pedidos) => {
    const data: any[] = []
    console.log('dados pra parsar 1', goals)
    console.log('dados pra parsar 2', faturamento)
    console.log('dados pra parsar 3', production)
    console.log('dados pra parsar 4', prevision)
    console.log('dados pra parsar 5', pedidos)

    const metasCaixa = goals.find(v => v.production ? v.production.name === 'Caixa' : false)
    const metasChapa = goals.find(v => v.production ? v.production.name === 'Chapa' : false)
    const metasPapel = goals.find(v => v.production ? v.production.name === 'Bobina' : false)
    console.log('metas', metasCaixa, metasChapa, metasPapel)

    const caixasDP = {
      dia: 0,
      acumulado: 0
    }

    const chapasDP = {
      dia: 0,
      acumulado: 0
    }

    const papeisDP = {
      dia: 0,
      acumulado: 0
    }

    production.forEach(dp => {
      dp.dados.forEach(dado => {
        if (dp.tipo === '1') {
          if (dado.type === TipoProduto.CAIXA) {
            caixasDP.dia += parseFloat(dado.kg)
          }
          if (dado.type === TipoProduto.CHAPA) {
            chapasDP.dia += parseFloat(dado.kg)
          }
          if (dado.type === TipoProduto.PAPEL) {
            papeisDP.dia += parseFloat(dado.kg)
          }
        } else {
          if (dado.type === TipoProduto.CAIXA) {
            caixasDP.acumulado += parseFloat(dado.kg)
          }
          if (dado.type === TipoProduto.CHAPA) {
            chapasDP.acumulado += parseFloat(dado.kg)
          }
          if (dado.type === TipoProduto.PAPEL) {
            papeisDP.acumulado += parseFloat(dado.kg)
          }
        }
      })
    })

    const caixasFAT = {
      diaKg: 0,
      acumuladoKg: 0,
      diaRs: 0,
      acumuladoRs: 0
    }

    const chapasFAT = {
      diaKg: 0,
      acumuladoKg: 0,
      diaRs: 0,
      acumuladoRs: 0
    }

    const papeisFAT = {
      diaKg: 0,
      acumuladoKg: 0,
      diaRs: 0,
      acumuladoRs: 0
    }

    faturamento.forEach(ft => {
      ft.dados.forEach(dado => {
        if (ft.tipo === '1') {
          if (dado.type === TipoProduto.CAIXA) {
            caixasFAT.diaKg += parseFloat(dado.kg)
            caixasFAT.diaRs += parseFloat(dado.rs)
          }
          if (dado.type === TipoProduto.CHAPA) {
            chapasFAT.diaKg += parseFloat(dado.kg)
            chapasFAT.diaRs += parseFloat(dado.rs)
          }
          if (dado.type === TipoProduto.PAPEL) {
            papeisFAT.diaKg += parseFloat(dado.kg)
            papeisFAT.diaRs += parseFloat(dado.rs)
          }
        } else {
          if (dado.type === TipoProduto.CAIXA) {
            caixasFAT.acumuladoKg += parseFloat(dado.kg)
            caixasFAT.acumuladoRs += parseFloat(dado.rs)
          }
          if (dado.type === TipoProduto.CHAPA) {
            chapasFAT.acumuladoKg += parseFloat(dado.kg)
            chapasFAT.acumuladoRs += parseFloat(dado.rs)
          }
          if (dado.type === TipoProduto.PAPEL) {
            papeisFAT.acumuladoKg += parseFloat(dado.kg)
            papeisFAT.acumuladoRs += parseFloat(dado.rs)
          }
        }
      })
    })

    const caixasPED = {
      diaKg: 0,
      acumuladoKg: 0,
      diaRs: 0,
      acumuladoRs: 0
    }

    const chapasPED = {
      diaKg: 0,
      acumuladoKg: 0,
      diaRs: 0,
      acumuladoRs: 0
    }

    const papeisPED = {
      diaKg: 0,
      acumuladoKg: 0,
      diaRs: 0,
      acumuladoRs: 0
    }

    pedidos.forEach(ped => {
      ped.dados.forEach(dado => {
        if (ped.tipo === '1') {
          if (dado.type === TipoProduto.CAIXA) {
            caixasPED.diaKg += parseFloat(dado.kg)
            caixasPED.diaRs += parseFloat(dado.rs)
          }
          if (dado.type === TipoProduto.CHAPA) {
            chapasPED.diaKg += parseFloat(dado.kg)
            chapasPED.diaRs += parseFloat(dado.rs)
          }
          if (dado.type === TipoProduto.PAPEL) {
            papeisPED.diaKg += parseFloat(dado.kg)
            papeisPED.diaRs += parseFloat(dado.rs)
          }
        } else {
          if (dado.type === TipoProduto.CAIXA) {
            caixasPED.acumuladoKg += parseFloat(dado.kg)
            caixasPED.acumuladoRs += parseFloat(dado.rs)
          }
          if (dado.type === TipoProduto.CHAPA) {
            chapasPED.acumuladoKg += parseFloat(dado.kg)
            chapasPED.acumuladoRs += parseFloat(dado.rs)
          }
          if (dado.type === TipoProduto.PAPEL) {
            papeisPED.acumuladoKg += parseFloat(dado.kg)
            papeisPED.acumuladoRs += parseFloat(dado.rs)
          }
        }
      })
    })

    const caixa = {
      producao: {
        title: 'Produção Caixa',
        serie: [
          {
            title: 'Meta Kg',
            value: metasCaixa ? metasCaixa.previsionKg ?? 0 : 0,
            color: '#4060E6'
          },
          {
            title: 'Realizado Kg',
            value: caixasDP.acumulado,
            color: '#003050'
          }
        ]
      },
      faturadoRs: {
        title: 'Faturado Caixa R$',
        serie: [
          {
            title: 'Meta R$',
            value: prevision.caixa.dados.amountPrice ?? 0,
            fill: '#4060E6'
          },
          {
            title: 'Realizado R$',
            value: caixasFAT.acumuladoRs ?? 0,
            fill: '#1AB1B0'
          }
        ]
      },
      faturadoKg: {
        title: 'Faturado Caixa KG',
        serie: [
          {
            title: 'Meta Kg',
            value: prevision.caixa.dados.amountKg ?? 0,
            fill: '#4060E6'
          },
          {
            title: 'Realizado Kg',
            value: caixasFAT.acumuladoKg ?? 0,
            fill: '#1AB1B0'
          }
        ]
      },
      carteira: {
        title: 'Carteira Caixa',
        serie: [
          {
            title: 'Carteira Kg',
            value: caixasPED.acumuladoKg ?? 0,
            color: '#4060E6'
          },
          {
            title: 'Faturado Kg',
            value: caixasFAT.acumuladoKg ?? 0,
            color: '#003050'
          }
        ]
      }
    }

    const chapa = {
      producao: {
        title: 'Produção Chapa',
        serie: [
          {
            title: 'Meta Kg',
            value: metasChapa ? metasChapa.previsionKg ?? 0 : 0,
            color: '#4060E6'
          },
          {
            title: 'Realizado Kg',
            value: chapasDP.acumulado,
            color: '#003050'
          }
        ]
      },
      faturadoRs: {
        title: 'Faturado Chapa R$',
        serie: [
          {
            title: 'Meta R$',
            value: prevision.chapa.dados.amountPrice ?? 0,
            fill: '#4060E6'
          },
          {
            title: 'Realizado R$',
            value: chapasFAT.acumuladoRs ?? 0,
            fill: '#1AB1B0'
          }
        ]
      },
      faturadoKg: {
        title: 'Faturado Chapa KG',
        serie: [
          {
            title: 'Meta Kg',
            value: prevision.chapa.dados.amountKg ?? 0,
            fill: '#4060E6'
          },
          {
            title: 'Realizado Kg',
            value: chapasFAT.acumuladoKg ?? 0,
            fill: '#1AB1B0'
          }
        ]
      },
      carteira: {
        title: 'Carteira Chapa',
        serie: [
          {
            title: 'Carteira Kg',
            value: chapasPED.acumuladoKg ?? 0,
            color: '#4060E6'
          },
          {
            title: 'Faturado Kg',
            value: chapasFAT.acumuladoKg ?? 0,
            color: '#003050'
          }
        ]
      }
    }

    const papel = {
      producao: {
        title: 'Produção Papel',
        serie: [
          {
            title: 'Meta Kg',
            value: metasPapel ? metasPapel.previsionKg ?? 0 : 0,
            color: '#4060E6'
          },
          {
            title: 'Realizado Kg',
            value: papeisDP.acumulado,
            color: '#003050'
          }
        ]
      },
      faturadoRs: {
        title: 'Faturado Papel R$',
        serie: [
          {
            title: 'Meta R$',
            value: prevision.bobina.dados.amountPrice ?? 0,
            fill: '#4060E6'
          },
          {
            title: 'Realizado R$',
            value: papeisFAT.acumuladoRs ?? 0,
            fill: '#1AB1B0'
          }
        ]
      },
      faturadoKg: {
        title: 'Faturado Papel KG',
        serie: [
          {
            title: 'Meta Kg',
            value: prevision.bobina.dados.amountKg ?? 0,
            fill: '#4060E6'
          },
          {
            title: 'Realizado Kg',
            value: papeisFAT.acumuladoKg ?? 0,
            fill: '#1AB1B0'
          }
        ]
      },
      carteira: {
        title: 'Carteira Papel',
        serie: [
          {
            title: 'Carteira Kg',
            value: papeisPED.acumuladoKg ?? 0,
            color: '#4060E6'
          },
          {
            title: 'Faturado Kg',
            value: papeisFAT.acumuladoKg ?? 0,
            color: '#003050'
          }
        ]
      }
    }

    data.push(caixa)
    data.push(chapa)
    data.push(papel)
    setDashData(data)
  }

  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Relatórios', url: '/home/report' },
          { text: 'Dashboard' }
        ]}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <h4>Dashboard: {moment(date).locale('pt-br').format('MMMM YYYY')}</h4>
        <CssBaseline />
        <DashboardHeader dateSearch={d => handleDateChange(d)} />

        {
          goalsReq.isFetching || previsionReq.isFetching || productionReq.isFetching || fatReq.isFetching || pedidosReq.isFetching
            ? <Box sx={{ display: 'flex' }}
              style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
              <CircularProgress />
            </Box>
            : goalsReq.isError || previsionReq.isError || productionReq.isError || fatReq.isError || pedidosReq.isError
              ? <Box sx={{ display: 'flex' }}
                style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
                <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
                <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
              </Box>
              : <>
                {
                  dashData.map(dash => (
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={12} md={6} lg={3}>
                        <Card>
                          <CardActionArea>
                            <CardContent>
                              <ChartBar month={moment(date).format('MMMM')} title={dash.producao.title} data={dash.producao.serie} />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Card>
                          <CardActionArea>
                            <CardContent>
                              <ChartPie month={moment(date).format('MMMM')} title={dash.faturadoKg.title} data={dash.faturadoKg.serie} />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Card>
                          <CardActionArea>
                            <CardContent>
                              <ChartBar month={moment(date).format('MMMM')} title={dash.carteira.title} data={dash.carteira.serie} />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Card>
                          <CardActionArea>
                            <CardContent>
                              <ChartPieAndPie month={moment(date).format('MMMM')} title={dash.faturadoRs.title} data={dash.faturadoRs.serie} />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    </Grid>
                  ))
                }
              </>
        }

      </Box>
    </>
  )
}
