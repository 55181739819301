import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
// import alertSwalCancel from 'components/Alerts/ModalCancel'
import GenericDialog from 'components/genericDialog/GenericDialog'
import IconAndTitleView from 'components/view/IconAndTitleView'
import StatusView from 'components/view/StatusViewFT'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { putApprovePreFT, putPreFTBudgetData } from 'services/ft/FTService'
import styled from 'styled-components'
import FTDisapprove from './FTDisapprove'
import iconPdf from 'assets/svgs/icon-pdf.svg'

function FTAuditPlate(props: any) {
  console.log('form', props.form)
  const colorPrimary = '#5f7aea'
  const colorWarning = '#ce6c2b'
  const colorError = '#EE207A'
  const colorSuccess = '#72CE2B'
  const color =
    props.form.status === 'NOVO'
      ? colorPrimary
      : props.form.status === 'CORRIGIDO' || props.form.status === 'ANÁLISE'
      ? colorWarning
      : props.form.status === 'APROVADO'
      ? colorSuccess
      : colorError

  const RetangleView = styled.div`
    width: 25px;
    height: 60px;
    background-color: ${color};
  `

  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const [budgetData, setBudgetData] = React.useState<any>({
    minimumLotReference: 1500,
    destinationCity: 'Manaus',
    cliche: false,
    knife: false
  })

  const handleCloseDialog = () => {
    setOpenDialog(false)
    props.closeDialog()
  }

  const handleCancellDialog = () => {
    setOpenDialog(false)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const { refetch: refetchApprove, isFetching: isFetchingApprove } = useQuery(
    'approve-ft-plate',
    async () => {
      return putApprovePreFT(props.form._id)
    },
    {
      onError: (error: any) => {
        console.log(error)
        toast.error(
          error?.response?.data?.message ??
            'Não foi possível realizar essa operação'
        )
      },
      onSuccess: (data) => {
        try {
          console.log('successo', data)
          props.closeDialog()
        } catch (e) {
          toast.error(e?.response?.data?.message)
          console.log('success error', e)
        }
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const { refetch: saveBudgetData, isFetching: isFetchingBudgetData } =
    useQuery(
      'save-ft-box',
      async () => {
        return putPreFTBudgetData(props.form._id, budgetData)
      },
      {
        onError: (error: any) => {
          console.log(error)
          toast.error(
            error?.response?.data?.message ??
              'Não foi possível realizar essa operação'
          )
        },
        onSuccess: (data) => {
          try {
            console.log('successo', data)
            // props.closeDialog()
            refetchApprove()
          } catch (e) {
            toast.error(e?.response?.data?.message)
            console.log('success error', e)
          }
        },
        refetchOnWindowFocus: false,
        enabled: false
      }
    )

  const handleApprove = () => {
    // refetchApprove()
    saveBudgetData()
  }

  if (isFetchingApprove || isFetchingBudgetData) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  console.log('props?.form?.descriptionFail', props?.form?.descriptionFail)

  return (
    <Box>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <RetangleView />
        <Card style={{ display: 'flex', width: '100%', paddingBottom: '0px' }}>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '0px',
              paddingRight: '0px'
            }}
          >
            <div style={{ marginRight: '38px', fontWeight: '600' }}>
              {props.form.status}
            </div>
            <IconAndTitleView icon="person_outline" title="Emitido por: -" />
            <IconAndTitleView
              icon="calendar_today"
              title={moment(props.form.createdAt).format('DD/MM/YYYY')}
            />
            <IconAndTitleView
              icon="schedule"
              title={moment(props.form.createdAt).format('HH:mm')}
            />
            <IconAndTitleView
              icon="assignment_outline"
              title={`FT ${props.form._id.substring(0, 8)}`}
            />
            <IconAndTitleView
              icon="content_paste"
              title={`Produto: ${props.form.type}`}
            />
            <IconAndTitleView icon="person_outline" title="Para: -" />
          </CardContent>
        </Card>
      </div>

      <div style={{ marginTop: '16px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Status da solicitação
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <StatusView
            disabled={props.form.status !== 'NOVO'}
            style={{ marginRight: '14px' }}
            size="small"
            status="NOVO"
          />
          <StatusView
            disabled={props.form.status !== 'CORRIGIDO'}
            style={{ marginRight: '14px' }}
            size="small"
            status="CORRIGIDO"
          />
          <StatusView
            disabled={props.form.status !== 'APROVADO'}
            style={{ marginRight: '14px' }}
            size="small"
            status="APROVADO"
          />
          <StatusView
            disabled={props.form.status !== 'REPROVADO'}
            size="small"
            status="REPROVADO"
          />
        </div>
      </div>

      {!!props?.form?.descriptionFail?.length &&
        props.form.status === 'REPROVADO' && (
          <div
            style={{
              marginTop: '24px',
              backgroundColor: '#ffddec',
              padding: '10px',
              borderRadius: '12px'
            }}
          >
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Motivo da reprovação:
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '5px'
              }}
            >
              <div style={{ fontSize: '16px' }}>
                {props.form.descriptionFail}
              </div>
            </div>
          </div>
        )}

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Dados da Solicitação - Chapa
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Número da FT Amostra"
                variant="standard"
                defaultValue={`FT ${props.form._id.substring(0, 8)}`}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Nome da Empresa"
                variant="standard"
                defaultValue={props.form.client?.name ?? ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Emitido Por"
                variant="standard"
                defaultValue={props.form.collaborator?.name ?? ''}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Referência</div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label=""
                variant="standard"
                defaultValue={
                  props.form?.preftplate?.Referencia ??
                  props.form?.formulariochapa?.Referencia
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Especificação
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Nomenclatura"
                variant="standard"
                defaultValue={
                  props.form?.preftplate?.Composicao?.nomeComercial ??
                  props.form?.formulariochapa?.Composicao?.nomeComercial
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Tipo de onda"
                variant="standard"
                defaultValue={
                  props.form?.preftplate?.Composicao?.onda ??
                  props.form?.formulariochapa?.Composicao?.onda
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Dimensões</div>
        <div
          style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '900px' }}>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Comprimento Interna"
                variant="standard"
                defaultValue={`${
                  props.form?.preftplate?.ComprimentoInterno ??
                  props.form?.formulariochapa?.ComprimentoInterno
                }`}
              />
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                  marginRight={2}
                >
                  +
                </Box>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Largura Interna"
                  variant="standard"
                  defaultValue={
                    props.form?.preftplate?.LarguraInterna ??
                    props.form?.formulariochapa?.LarguraInterna
                  }
                />
                <div style={{ marginInline: 10, marginTop: 15 }}></div>
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                  marginRight={2}
                >
                  +
                </Box>
                <TextField
                  // style={{ marginTop: 15 }}
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Altura Interna"
                  variant="standard"
                  defaultValue={`${
                    props.form?.preftplate?.AlturaInterna ??
                    props.form?.formulariochapa?.AlturaInterna
                  }`}
                />
                <div style={{ marginInline: 10, marginTop: 15 }}>=</div>
                <TextField
                  // style={{ marginTop: 15 }}
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Total (calculo)"
                  variant="standard"
                  defaultValue={
                    Number(
                      props.form?.preftplate?.LarguraInterna ??
                        props.form?.formulariochapa?.LarguraInterna ??
                        0
                    ) +
                    Number(
                      props.form?.preftplate?.ComprimentoInterno ??
                        props.form?.formulariochapa?.ComprimentoInterno ??
                        0
                    ) +
                    Number(
                      props.form?.preftplate?.AlturaInterna ??
                        props.form?.formulariochapa?.AlturaInterna ??
                        0
                    )
                  }
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Fechamento"
                variant="standard"
                defaultValue={
                  props.form?.preftplate?.Fechamento?.fechamento ??
                  props.form?.formulariochapa?.Fechamento?.fechamento
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            id="standard-basic"
            label="Gramatura"
            variant="standard"
            defaultValue={props.form?.preftplate?.grammage ?? 0}
          />
        </Grid>
      </div>
      {/*
      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Quantidade</div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label=""
                variant="standard"
                defaultValue={props.form.qtd ?? 0}
              />
            </Grid>
          </Grid>
        </div>
      </div> */}

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '16px' }}>
          Arquivo Anexado
        </div>

        {!props.form?.attachments?.length && (
          <Typography>Nenhum arquivo anexado</Typography>
        )}

        <Grid container spacing={2}>
          {props?.form?.attachments?.map((item, index) => (
            <Grid key={index} item xs={2}>
              <Stack spacing={1} justifyContent='center' alignItems='center'>
                <Box
                  component="a"
                  href={item?.url}
                  target="_blank"
                  download
                  color="primary"
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                    padding: '4px 8px',
                    borderRadius: '4px',
                    textDecoration: 'none',
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  Baixar arquivo
                </Box>

                {!item?.url?.includes('.pdf') && (
                  <Avatar
                    src={item.url}
                    title="Arquivo"
                    alt={index}
                    sx={{
                      '& img': { objectFit: 'contain' },
                      borderRadius: 1,
                      height: 150,
                      width: '100%'
                    }}
                  />
                )}
                {item?.url?.includes('.pdf') && (
                  <Avatar
                    src={iconPdf}
                    title="Arquivo"
                    alt={index}
                    sx={{
                      '& img': { objectFit: 'contain' },
                      borderRadius: 1,
                      height: 150,
                      width: 100
                    }}
                  />
                )}
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            {props.form?.attachment?.url && (
              <Box
                component="a"
                href={props.form.attachment.url}
                target="_blank"
                download
                color="primary"
                sx={{
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  padding: '4px 8px',
                  borderRadius: '4px',
                  textDecoration: 'none',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                Baixar arquivo
              </Box>
            )}
            {props.form?.attachment?.url &&
              !props.form?.attachment?.url?.includes('.pdf') && (
                <Avatar
                  src={props.form.attachment.url}
                  title="Arquivo"
                  alt="Arquivo anexado"
                  sx={{
                    '& img': { objectFit: 'contain' },
                    borderRadius: 1,
                    height: 150,
                    width: '100%'
                  }}
                />
              )}
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: '24px', pointerEvents: 'none' }}>
        <TextField
          className="w-100"
          label="Dados adicionais"
          autoComplete="no"
          value={props.form.preftplate?.extraData ?? ''}
          multiline
          rows={3}
          disabled={true}
        />
      </div>

      {/* <div style={{ marginTop: '24px', pointerEvents: 'none' }}>
        <div
          style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}
        >
          <div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              marginTop: 20,
              marginBottom: -10
            }}
          >
            Amostra
          </div>
          <Grid container spacing={3} mt={0} style={{ width: '600px' }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox checked={props.form.preftplate?.needSample} />
                }
                label="Sim"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox checked={!props.form.preftplate?.needSample} />
                }
                label="Não"
              />
            </Grid>
          </Grid>
          <TextField
            className="w-100"
            label="Quantidade de amostras"
            autoComplete="no"
            value={props.form.preftplate?.amountSample ?? 0}
            disabled={true}
          />
        </div>
      </div> */}

      <form
        id="entry-data-form"
        style={{
          marginTop: '24px',
          backgroundColor: '#f0f0f0',
          padding: '10px',
          borderRadius: '12px'
        }}
      >
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Dados para preenchimento
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
            <Grid item xs={3}>
              <TextField
                onChange={(e) => {
                  setBudgetData({
                    ...budgetData,
                    destinationCity: e.target.value
                  })
                }}
                value={budgetData.destinationCity}
                autoComplete="off"
                fullWidth
                id="standard-basic"
                label="Cidade destino"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="standard" sx={{ minWidth: 220 }}>
                <InputLabel id="simple-select-standard-label">
                  Lote mínimo (Toneladas)
                </InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  fullWidth
                  value={budgetData.minimumLotReference}
                  onChange={(e) => {
                    setBudgetData({
                      ...budgetData,
                      minimumLotReference: e.target.value
                    })
                  }}
                >
                  <MenuItem value={1500}>Maleta - 1,5 toneladas</MenuItem>
                  <MenuItem value={3000}>Corte vinco - 3 toneladas</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={budgetData.cliche}
                    onChange={(e, v) => {
                      setBudgetData({ ...budgetData, cliche: v })
                    }}
                  />
                }
                label="Clichê polímero"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={budgetData.knife}
                    onChange={(e, v) => {
                      setBudgetData({ ...budgetData, knife: v })
                    }}
                  />
                }
                label="Clichê metálico"
              />
            </Grid>
          </Grid>
        </div>
      </form>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginTop: 30
        }}
      >
        <Button
          style={{
            marginRight: '10px',
            backgroundColor: '#EBEBEB',
            color: '#484848'
          }}
          variant="contained"
          onClick={() => {
            props.closeDialog()
            // alertSwalCancel(() => {
            // })
          }}
        >
          Cancelar
        </Button>
        <Button
          disabled={
            props?.form?.status === 'APROVADO' ||
            props?.form?.status === 'REPROVADO' ||
            props?.form?.status === 'CANCELADO'
          }
          style={{
            marginRight: '10px',
            backgroundColor: '#EE207A',
            color: '#FFFFFF'
          }}
          onClick={() => {
            handleOpenDialog()
          }}
          sx={{ '&.Mui-disabled': { color: '#FFFFFF !important' } }}
          color="error"
          variant="contained"
        >
          Reprovar
        </Button>
        <Button
          disabled={
            props?.form?.status === 'APROVADO' ||
            props?.form?.status === 'REPROVADO' ||
            props?.form?.status === 'CANCELADO'
          }
          style={{
            marginRight: '10px',
            backgroundColor: '#1AB1B0',
            color: '#FFFFFF'
          }}
          sx={{ '&.Mui-disabled': { color: '#FFFFFF !important' } }}
          onClick={() => {
            handleApprove()
          }}
          color="success"
          variant="contained"
        >
          Aprovar
        </Button>
      </div>

      <GenericDialog
        open={openDialog}
        close={() => setOpenDialog(false)}
        content={
          <FTDisapprove
            id={props.form._id}
            cancellDialog={handleCancellDialog}
            closeDialog={handleCloseDialog}
          />
        }
      />
    </Box>
  )
}

export default FTAuditPlate
