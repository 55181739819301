import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import {
  Box,
  Chip,
  CircularProgress,
  Icon,
  TextField
} from '@mui/material'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportBilling, getReportTipoPapel } from 'services/report/ReportBilling'
import TablePMBody from './TablePMBody'
import { getBudgetRealizedMonthAndYearItems } from 'services/budgetRealized/BudgetRealizedService'
import { toast } from 'react-toastify'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

interface Props {
  date: string
}

const TablePMHead: React.FC<Props> = (props) => {
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)
  const cng = localStorage.getItem('changeOR')
  const [rows, setRows] = useState<any[]>([])
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const [valorRow] = useState<any[]>([
    {
      text: 'Previsto',
      value: 1
    },
    {
      text: 'Realizado',
      value: 2
    }
  ])
  const [porcentM, setPorcentM] = useState<number>(61)
  const [porcentT, setPorcentT] = useState<number>(36)
  const [porcentC, setPorcentC] = useState<number>(0)
  const [porcentK, setPorcentK] = useState<number>(3)
  const [porcentB, setPorcentB] = useState<number>(0)
  const [A, setA] = useState<number>(0)
  const [prevValues, setPrevValues] = useState({
    'ton-1': 0,
    'ton-2': 0,
    'ton-3': 0,
    'ton-4': 0,
    'ton-5': 0,
    'ton-6': 0
  })
  const [realValues, setRealValues] = useState({
    'ton-1': 0,
    'ton-2': 0,
    'ton-3': 0,
    'ton-4': 0,
    'ton-5': 0,
    'ton-6': 0
  })
  const [dataReq1, setDataReq1] = useState<any>([])

  const queryMultiple = () => {
    const req1 = useQuery(['sales-report', mes, ano],
      async () => {
        return getReportBilling(mes, ano)
      },
      {
        onSuccess: (data) => {
          console.log('TablePMHead=>req1=>getReportBilling =>', data)
          setDataReq1(data)
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false
      }
    )
    const req2 = useQuery(['papel-report', props.date],
      async () => {
        console.log('data para getReportTipoPapel', props.date)
        return getReportTipoPapel(props.date)
      },
      {
        onSuccess: (data) => {
          console.log('TablePMHead=>req2=>getReportTipoPapel =>', data)
          const tipoDeDado = typeof data
          console.log('Tipo de dado:', tipoDeDado)
          if (Array.isArray(data)) {
            console.log('É um array:', data)
          } else if (typeof data === 'object' && data !== null && Symbol.iterator in data) {
            console.log('É um objeto iterável:', data)
            const arrayData = Array.from(data)
            console.log('Array:', arrayData)
          } else {
            console.log('Não é um objeto iterável:', data)
            const arrayData = Object.values(data)
            console.log('Array personalizado:', arrayData)
            dataHandle(dataReq1, arrayData)
          }
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false
      }
    )
    const req3 = useQuery(['realized', null],
    async () => {
      return getBudgetRealizedMonthAndYearItems(mes, ano)
    },
    {
      onSuccess: (data) => {
        console.log('TablePMHead=>req3=>getBudgetRealizedMonthAndYearItems =>', data)
      },
      onError: (error) => {
        console.log(
          'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
          error
        )
      },
      refetchOnWindowFocus: false
    })
    return [req1, req2, req3]
  }
  const [req1, req2, req3] = queryMultiple()
  const handleInputChange = (event, index) => {
    try {
      localStorage.setItem('changeOR', 'YES')
      const indiceRef = Number(localStorage.getItem('indiceRef'))
      const newValue = parseInt(event.target.value)

      if (!isNaN(newValue)) {
        const defaultRef = A + (A * (indiceRef / 100))
        const tonKey = `ton-${Number(index) + 1}`
        const tonValue = Math.round((defaultRef * (newValue / 100)) * 1000)

        switch (index) {
          case 0:
            setPorcentM(newValue)
            break
          case 1:
            setPorcentT(newValue)
            break
          case 2:
            setPorcentC(newValue)
            break
          case 3:
            setPorcentK(newValue)
            break
          case 4:
            setPorcentB(newValue)
            break
          default:
            break
        }

        setPrevValues(prevValues => ({
          ...prevValues,
          [tonKey]: tonValue
        }))

        setPrevValues(prevValues => {
          const updatedValues = { ...prevValues }
          updatedValues[tonKey] = tonValue
          const values = Object.values(updatedValues)
           // Somar todos os valores, exceto o último
          const sum = values.slice(0, values.length - 1).reduce((acc, curr) => acc + curr, 0)
          return {
            ...prevValues,
            'ton-6': sum
          }
        })
      }
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }
  const dataHandle = (valor, realized) => {
    console.log('realized', realized)
    const data: any[] = []
    let previsionDataPapel: any
    let previsionDataCaixa: any
    let previsionDataChapa: any

    let defaultM: any
    let defaultT: any
    let defaultC: any
    let defaultK: any
    let defaultB: any
    let sumPrev: any

    let defaultRM: any
    let defaultRT: any
    let defaultRC: any
    let defaultRK: any
    let defaultRB: any

    if (realized && realized.length > 0) {
      realized.forEach(item => {
        if (item && Array.isArray(item)) {
          let sumRealized = 0
          item.forEach(innerItem => {
            console.log('innerItem', innerItem)
            switch (innerItem.modified_seak) {
              case 'Miolo':
                setRealValues(realValues => ({
                  ...realValues,
                  'ton-1': Number(innerItem.total)
                }))
                defaultRM = Number(innerItem.total)
                sumRealized += Number(defaultRM)
                break
              case 'Testline':
                setRealValues(realValues => ({
                  ...realValues,
                  'ton-2': Number(innerItem.total)
                }))
                defaultRT = Number(innerItem.total)
                sumRealized += Number(defaultRT)
                break
              case 'Capa':
                setRealValues(realValues => ({
                  ...realValues,
                  'ton-3': Number(innerItem.total)
                }))
                defaultRC = Number(innerItem.total)
                sumRealized += Number(defaultRC)
                break
              case 'Kraft':
                setRealValues(realValues => ({
                  ...realValues,
                  'ton-4': Number(innerItem.total)
                }))
                defaultRK = Number(innerItem.total)
                sumRealized += Number(defaultRK)
                break
              case 'Branco':
                setRealValues(realValues => ({
                  ...realValues,
                  'ton-5': Number(innerItem.total)
                }))
                defaultRB = Number(innerItem.total)
                sumRealized += Number(defaultRB)
                break
              default:
                break
            }
          })
          setRealValues(realValues => ({
            ...realValues,
            'ton-6': Math.round(sumRealized)
          }))
        }
      })
    } else {
      setRealValues({
        'ton-1': 0,
        'ton-2': 0,
        'ton-3': 0,
        'ton-4': 0,
        'ton-5': 0,
        'ton-6': 0
      })
    }

    if (valor?.bobina?.dados && Object.keys(valor.bobina.dados).length) {
      previsionDataPapel = (valor?.bobina?.dados?.amountKg ?? 0) / 1000
      console.log('previsionDataPapel', previsionDataPapel)
    } else {
      previsionDataPapel = 0
      toast.error('Requisição pode ter falhado. Dados Bobina Nulos ou Vazios')
    }
    if (valor?.caixa?.dados && Object.keys(valor?.caixa?.dados).length) {
      previsionDataCaixa = (valor?.caixa?.dados?.amountKg ?? 0) / 1000
      console.log('previsionDataCaixa', previsionDataCaixa)
    } else {
      previsionDataCaixa = 0
      toast.error('Requisição pode ter falhado. Dados Caixa Nulos ou Vazios')
    }
    if (valor?.chapa?.dados && Object.keys(valor?.chapa?.dados).length) {
      previsionDataChapa = (valor?.chapa?.dados?.amountKg ?? 0) / 1000
      console.log('previsionDataChapa', previsionDataChapa)
    } else {
      previsionDataChapa = 0
      toast.error('Requisição pode ter falhado. Dados Chapa Nulos ou Vazios')
    }
    const indiceRef = localStorage.getItem('indiceRef')
    console.log('indiceRef', indiceRef)

    console.log('porcentM', porcentM)
    console.log('porcentT', porcentT)
    console.log('porcentC', porcentC)
    console.log('porcentK', porcentK)
    console.log('porcentB', porcentB)
    setA(Number(previsionDataPapel) + Number(previsionDataCaixa) + Number(previsionDataChapa))
    if (cng) {
      const tempA = Number(previsionDataPapel) + Number(previsionDataCaixa) + Number(previsionDataChapa)
      defaultM = ((tempA + (tempA * (Number(indiceRef) / 100))) * (porcentM / 100)) * 1000
      setPrevValues(prevValues => ({
        ...prevValues,
        'ton-1': Math.round(defaultM)
      }))
      defaultT = ((tempA + (tempA * (Number(indiceRef) / 100))) * (porcentT / 100)) * 1000
      setPrevValues(prevValues => ({
        ...prevValues,
        'ton-2': Math.round(defaultT)
      }))
      defaultC = ((tempA + (tempA * (Number(indiceRef) / 100))) * (porcentC / 100)) * 1000
      setPrevValues(prevValues => ({
        ...prevValues,
        'ton-3': Math.round(defaultC)
      }))
      defaultK = ((tempA + (tempA * (Number(indiceRef) / 100))) * (porcentK / 100)) * 1000
      setPrevValues(prevValues => ({
        ...prevValues,
        'ton-4': Math.round(defaultK)
      }))
      defaultB = ((tempA + (tempA * (Number(indiceRef) / 100))) * (porcentB / 100)) * 1000
      setPrevValues(prevValues => ({
        ...prevValues,
        'ton-5': Math.round(defaultB)
      }))
      sumPrev = Math.round((Number(defaultM) + Number(defaultT) + Number(defaultC) + Number(defaultK) + Number(defaultB)))
      setPrevValues(prevValues => ({
        ...prevValues,
        'ton-6': sumPrev
      }))
    }
    console.log('defaultM', defaultM)
    console.log('defaultT', defaultT)
    console.log('defaultC', defaultC)
    console.log('defaultK', defaultK)
    console.log('defaultB', defaultB)
    const resultPrev = Math.round((Number(defaultM) + Number(defaultT) + Number(defaultC) + Number(defaultK) + Number(defaultB)))
    console.log('defaultRM', defaultRM)
    console.log('defaultRT', defaultRT)
    console.log('defaultRC', defaultRC)
    console.log('defaultRK', defaultRK)
    console.log('defaultRB', defaultRB)
    const resultRealized = Math.round((Number(defaultRM) + Number(defaultRT) + Number(defaultRC) + Number(defaultRK) + Number(defaultRB)))

   /* Miolo */
    data.push({
      _id: 'ton-1',
      product: 'Miolo',
      prev: defaultM | 0,
      realizedRs: defaultRM | 0
    })
    /* Testliner */
    data.push({
      _id: 'ton-2',
      product: 'Testliner',
      prev: defaultT | 0,
      realizedRs: defaultRT | 0
    })
    /* Capa */
    data.push({
      _id: 'ton-3',
      product: 'Capa',
      prev: defaultC | 0,
      realizedRs: defaultRC | 0
    })
    /* Kraft */
    data.push({
      _id: 'ton-4',
      product: 'Kraft',
      prev: defaultK | 0,
      realizedRs: defaultRK | 0
    })
    /* Branco  */
    data.push({
      _id: 'ton-5',
      product: 'Branco',
      prev: defaultB | 0,
      realizedRs: defaultRB | 0
    })

    /* Produção de Papel (Kg)  */
    data.push({
      _id: 'ton-6',
      product: 'Produção de Papel (Kg)',
      prev: resultPrev | 0,
      realizedRs: resultRealized | 0
    })

    setRows(data)
  }
  const isLoadingAnyQuery = [req1, req2, req3].some(
    (query) => query.isLoading || query.isFetching
  )
  const hasErrorInAnyQuery = [req1, req2, req3].some(
    (query) => query.isError
  )
  const allQueriesSuccess = (
    req1.isSuccess && !req1.isError && !req1.isFetching && !req2.isError && !req2.isFetching && !req3.isError && !req3.isFetching
  )

  let result: any

  if (isLoadingAnyQuery) {
    result = (
      <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          style={{
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          {loading
? (
            <>
              <CircularProgress variant="determinate" value={progress} />
              <p style={{ color: 'green', marginTop: '8px' }}>Aguarde: {progress}%</p>
            </>
          )
: (
          (req1 === null || req1 === undefined) ||
          (req2 === null || req2 === undefined)
? (
            <p style={{ color: 'red', marginTop: '8px' }}>
              Não houve resposta do sistema LN. Inconsistências técnicas na comunicação no momento.
            </p>
          )
: (
            <p>Requisição bem-sucedida!</p>
          )
)
        }
      </Box>
    )
  }

  if (hasErrorInAnyQuery) {
    result = (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }

  if (allQueriesSuccess) {
    result = (
      <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: '100%' }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="right" >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'right' }}>
                  <span style={{
                              marginRight: '15%',
                              width: '85%'
                              }}><strong>Média de Proporção de Produção (%)</strong>
                  </span>
                </div>
                </TableCell>
                {valorRow.map((item, index) => {
                  return (
                    <TableCell align="center" colSpan={1} key={index}>
                      {item.text}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
              return (
                <StyledTableRow key={row._id} style={row._id === 'total' ? { fontWeight: 'bold', textShadow: '0px 0 black, 0 0.2px black, 0.2px 0 black, 0 0px black' } : {}}>
                    <StyledTableCell align="center" style={{ wordBreak: 'break-word' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <span style={{
                                marginRight: '5%',
                                width: '50%',
                                textTransform: index === rows.length - 1 ? 'uppercase' : 'initial',
                                fontWeight: index === rows.length - 1 ? 'bold' : 'normal'
                            }}>{row.product}</span>
                            {row._id !== 'ton-6' && index !== rows.length - 1
              ? (
                            <TextField
                              style={{ width: '25%', marginRight: '5%', fontWeight: 'bold' }}
                              size="small"
                              value={
                                  (() => {
                                      switch (index) {
                                          case 0:
                                              return porcentM
                                          case 1:
                                              return porcentT
                                          case 2:
                                              return porcentC
                                          case 3:
                                              return porcentK
                                          case 4:
                                              return porcentB
                                          default:
                                              console.log('default')
                                              return ''
                                      }
                                  })()
                              }
                              onChange={(event) => handleInputChange(event, index)}
                              type="number"
                              inputProps={{
                                  min: 0,
                                  max: 100
                              }}
                            />
              )
              : ('')}
                        </div>
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ wordBreak: 'break-word' }}>
                        <div style={{
                            textTransform: index === rows.length - 1 ? 'uppercase' : 'initial',
                            fontWeight: index === rows.length - 1 ? 'bold' : 'normal'
                        }}>
                            {row._id === 'ton-6' && index === rows.length - 1
              ? (
                                <Chip
                                    label={
                                        cng === 'NO'
                                            ? parseInt(row.prev).toLocaleString('pt-BR')
                                            : parseInt(prevValues[`ton-${index + 1}`]).toLocaleString('pt-BR')
                                    }
                                    variant="outlined"
                                    style={{
                                        color: cng === 'NO' ? 'black' : '#414cff',
                                        background: cng === 'NO' ? 'white' : '#d8ffff',
                                        borderColor: cng === 'NO' ? 'black' : '#414cff',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}
                                />
                            )
              : (
                                cng === 'NO' ? parseInt(row.prev).toLocaleString('pt-BR') : parseInt(prevValues[`ton-${index + 1}`]).toLocaleString('pt-BR')
                            )}
                        </div>
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ wordBreak: 'break-word' }}>
                        <div style={{
                            textTransform: index === rows.length - 1 ? 'uppercase' : 'initial',
                            fontWeight: index === rows.length - 1 ? 'bold' : 'normal'
                        }}>
                            {row._id === 'ton-6' && index === rows.length - 1
              ? (
                                <Chip
                                    label={
                                        row.realizedRs !== 0
                                            ? Number(row.realizedRs).toLocaleString('pt-br')
                                            : parseInt(realValues[`ton-${index + 1}`]).toLocaleString('pt-BR')
                                    }
                                    variant="outlined"
                                    style={{
                                        color: row.realizedRs !== 0 ? 'black' : '#414cff',
                                        background: row.realizedRs !== 0 ? 'white' : '#d8ffff',
                                        borderColor: row.realizedRs !== 0 ? 'black' : '#414cff',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}
                                />
                            )
              : (
                                row.realizedRs !== 0 ? Number(row.realizedRs).toLocaleString('pt-br') : parseInt(realValues[`ton-${index + 1}`]).toLocaleString('pt-BR')
                            )}
                        </div>
                    </StyledTableCell>
                </StyledTableRow>
              )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TablePMBody date={props.date} req={req3.data?.data[3]}/>
    </>
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress < 100) {
            return prevProgress + 10
        } else {
            setLoading(false)
            clearInterval(interval)
            return prevProgress
        }
      })
    }, 2000)

    return () => {
        clearInterval(interval)
    }
  }, [A])

  return result
}

export default TablePMHead
