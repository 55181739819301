/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material'
import {
  StyledTableCell,
  StyledTableRow
} from 'pages/company/portfolio/components/table.styles'
import { Link, useNavigate } from 'react-router-dom'
import {
  getFTsProject,
  getFTsProjectByCompany,
  getIe,
  getItemTrimpaper
} from 'services/customerControl/CustomerControlServices'
import moment from 'moment'
import { httpClient } from 'config/httpClient'
import { toast } from 'react-toastify'
import { SelectProductType } from './SelectProductType'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

interface Props {
  search?: string
  companyId: string
  userId: string
}

const FTsProject: React.FC<Props> = ({ search = '', companyId, userId }) => {
  const [page, setPage] = useState(0)
  const navigate = useNavigate()

  const [ftsProject, setFtsProject] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingRequest, setLoadingRequest] = useState(false)

  const [selectedProductType, setSelectProductType] = useState<
    'BOBINA' | 'CHAPA' | 'CAIXA'
  >('CAIXA')

  const filteredFtsProject = ftsProject
    ?.filter((item) => item?.preFT?.type?.toUpperCase() === selectedProductType)
    ?.filter(
      (item) =>
        item?.itemReference?.includes(search) ||
        item?.preFT?.type?.toLowerCase()?.includes(search.toLowerCase())
    )

  const loadIes = useCallback(() => {
    setLoading(true)
    getFTsProjectByCompany(companyId, userId)
      .then((response) => {
        setFtsProject(response?.data ?? [])
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [userId])

  useEffect(() => {
    void loadIes()
  }, [userId])

  const handleSelectItem = (item, checked) => {
    setFtsProject((state) =>
      state.map((itemFt) =>
        itemFt?._id === item?._id
          ? {
              ...itemFt,
              checked
            }
          : itemFt
      )
    )
  }

  const getSelectedFTsProject = () => {
    const selectedFTs = ftsProject
      ?.filter((item) => item?.checked)
      ?.map((ft) => ft?._id)

    return selectedFTs
  }

  const handleCreateRequest = async () => {
    const selectedFTs = getSelectedFTsProject()

    if (!selectedFTs?.length) {
      toast.error(
        'Nenhuma FT selecionada. Para fazer um pedido deve selecionar uma ou mais FTs.'
      )
      return
    }

    setLoadingRequest(true)

    toast.promise(
      httpClient.post(`/pedidos/by-company/${companyId}?userId=${userId}`, {
        fts: selectedFTs,
        type: selectedProductType ?? null
      }),
      {
        pending: 'Aguarde, criando pedido...',
        success: {
          render() {
            setFtsProject((state) =>
              state?.map((item) => ({ ...item, checked: false }))
            )
            setLoadingRequest(false)
            navigate('/home/purchaseRequests')
            return 'Pedido realizado com sucesso'
          }
        },
        error: {
          render({ data }: any) {
            setLoadingRequest(false)
            return `Erro ao criar pedido. ${data?.response?.data?.message}`
          }
        }
      }
    )
  }

  const handleChange = (event: any) => {
    setSelectProductType(event.target.value as 'BOBINA' | 'CHAPA' | 'CAIXA')
  }

  return (
    <>
      <SelectProductType value={selectedProductType} onChange={handleChange} />

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell> </StyledTableCell>
              <StyledTableCell>Tipo</StyledTableCell>
              <StyledTableCell>Código da FT</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Criado em</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <StyledTableRow>
                <StyledTableCell colSpan={5} align="center">
                  <CircularProgress size={24} />
                </StyledTableCell>
              </StyledTableRow>
            )}

            {!loading &&
              filteredFtsProject
                ?.slice(page * 5, page * 5 + 5)
                .map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Checkbox
                          {...label}
                          checked={!!row?.checked}
                          onChange={(_, checked) =>
                            handleSelectItem(row, checked)
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.preFT?.type}
                      </StyledTableCell>
                      <StyledTableCell>{row?.itemReference}</StyledTableCell>
                      <StyledTableCell>{row?.status}</StyledTableCell>
                      <StyledTableCell>
                        {row?.createdAt &&
                          moment(row.createdAt).format('DD/MM/YYYY [às] HH:mm')}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell colSpan={5}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    ftsProject?.filter((item) => item.checked === true)
                      ?.length <= 0 || loadingRequest
                  }
                  onClick={handleCreateRequest}
                >
                  Fazer pedido
                </Button>
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5]}
          rowsPerPage={5}
          component="div"
          count={filteredFtsProject.length}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      </TableContainer>

      <Stack direction="row" justifyContent="flex-end" sx={{ paddingTop: 4 }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/home/purchaseRequests"
        >
          Voltar
        </Button>
      </Stack>
    </>
  )
}

export default FTsProject
