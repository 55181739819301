import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Tab, TextField, Menu, MenuItem } from '@mui/material'
import { DatePicker, LocalizationProvider, TabContext, TabList, TabPanel } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import ModulesResumo from './components/ModulesResumo'
import AdministrativeModule from './components/AdministrativeModule/AdministrativeModule'
import PaperMaterialsModule from './components/PaperMaterialsModule/PaperMaterialsModule'
import PoMaterialModule from './components/PoMaterialModule/PoMaterialModule'
import InsumoPapelModule from './components/InsumoPapelModule/InsumoPapelModule'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
const reportBudget: React.FC = () => {
  const [value, setValue] = useState('5')
  const [valueDate, setValueDate] = useState<any | null>(new Date())
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const currentDate = new Date()
  const minDate = new Date(2022, 0, 1)
  const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleListClick = () => {
    navigate('/home/report/reportBudgetConfig')
  }

  localStorage.setItem('dataOR', moment(valueDate).format('DD/MM/YYYY'))
  const dataAtual = localStorage.getItem('dataOR')
  const getChangeOR = localStorage.getItem('changeOR')
  if (dataAtual && getChangeOR === 'NO') {
    const dataParts = dataAtual.split('/')
    const mes = parseInt(dataParts[1], 10)
    const ano = parseInt(dataParts[2], 10)

    if (mes >= 5 && ano >= 2023) {
      // O mês é maio (05) e o ano é 2023
      console.log('Mês e ano correspondem a maio de 2023 e superior.')
      localStorage.setItem('indiceRef', '13')
    } else {
      console.log('Mês e ano inferiores a maio de 2023.')
      localStorage.setItem('indiceRef', '25')
    }
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr repeat(2, minmax(auto, max-content))',
        gap: '15px'
      }}
      >
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Relatórios', url: '/home/report' },
          { text: 'Orçamento Vs Realizado' }
        ]}
      />
      <Can
        module={MODULES.RELATÓRIOS}
        permissions={[PERMISSIONS.CREATE]}
      >
        <div>
          <ButtonHeader
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <SettingsOutlinedIcon />
          </ButtonHeader>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={ handleListClick }>Listar</MenuItem>
            <MenuItem onClick={() => { }}>Cadastrar Categoria</MenuItem>
          </Menu>
        </div>
      </Can>
      </div>
      <h4>Relatório Orçamento Vs Realizado: {moment(valueDate).locale('pt-br').format('MMMM YYYY')}</h4>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '10px',
          marginRight: '25px'
        }}
      >
        <div style={{ marginRight: '10px' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              views={['month', 'year']}
              label="Mês e Ano"
              value={valueDate}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(newValue) => {
                setValueDate(newValue)
              }}
              renderInput={(params) => <TextField {...params}
                autoComplete='off' helperText={null} />}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Administrativo" value="1" />
              <Tab label="Material PO" value="2" />
              <Tab label="Materiais Papel" value="3" />
              <Tab label="Insumo Papel" value="4" />
              <Tab label="Resumo" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1"><AdministrativeModule date={valueDate} /></TabPanel>
          <TabPanel value="2"><PoMaterialModule date={valueDate} /></TabPanel>
          <TabPanel value="3"><PaperMaterialsModule date={valueDate} /></TabPanel>
          <TabPanel value="4"><InsumoPapelModule date={valueDate} /></TabPanel>
          <TabPanel value="5"><ModulesResumo date={valueDate} /></TabPanel>
        </TabContext>
      </div>
    </Box>
  )
}

export default reportBudget
