import { Add, PendingActionsOutlined } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Chip, Paper, Stack, Tab, Table, TableBody, TableContainer, TableHead, Tooltip } from '@mui/material'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { Can } from 'components/Permission/Can'
import MoreMenu from 'components/view/MoreMenu'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { StyledTableCell, StyledTableRow } from 'pages/company/portfolio/components/table.styles'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { postBudgetRealized, getBudgetRealized, deleteBudgetRealized as deleteCategoria, getBaseBudgetRealized } from 'services/budgetRealized/BudgetRealizedService'
import { toBRL } from 'utils/formatString'
import BudgetConfigCreateForm from './BugdetConfigCreateForm'
import BudgetConfigPreForm from './BugdetConfigPreForm'
function getModuleName(moduleNumber) {
  switch (moduleNumber) {
    case 1:
      return 'Administrativo'
    case 2:
      return 'Materiais de PO'
    case 3:
      return 'Materiais de Papel'
    case 4:
      return 'Insumos de Papel'
    default:
      return 'Módulo Desconhecido'
  }
}
function BudgetConfigTable(props: any) {
  const [rows, setRows] = React.useState<any[]>([])
  const [openCreate, setOpenCreate] = React.useState(false)
  const [dataCreate, setDataCreate] = React.useState<any>()
  const [openPre, setOpenPre] = React.useState(false)
  const [dataPre, setDataPre] = React.useState<any>()
  const navigate = useNavigate()

  const handleBtnCreateClick = async (event) => {
    const currentDate = new Date()
    const month = currentDate.getMonth()
    const year = currentDate.getFullYear()
    const dataInitial = {
      month: month,
      year: year,
      refuse: 0,
      module: 1,
      categories: [
        {
          type: 'Não Definido',
          percentage: 0,
          launchValue: 0,
          items: []
        }
      ]
    }
    const obj = await postBudgetRealized(dataInitial)
    console.log('obj', obj)
    const dataTemp = { data: obj, _id: obj._id }
    console.log('dataTemp', dataTemp)
    await setDataCreate(dataTemp)
    setOpenCreate(true)
  }
  const handleBtnPreClick = async (event) => {
    setOpenPre(true)
  }
  const handlePreviousClick = () => {
    navigate('/home/report/reportBudget')
  }
  const realizedReq = useQuery(['realized', null],
    async () => {
      return getBudgetRealized()
    },
    {
      onSuccess: (data) => {
        console.log('data budget realized', data)
        setRows(data)
      },
      refetchOnWindowFocus: false
    }
  )
  const baseRealizedReq = useQuery(['realized-base', null],
    async () => {
      return getBaseBudgetRealized()
    },
    {
      onSuccess: async (data) => {
        console.log('BASE Budget Realized', data)
        await setDataPre(data)
      },
      refetchOnWindowFocus: false
    }
  )
  useEffect(() => {
    realizedReq.refetch()
    baseRealizedReq.refetch()
  }, [props.refetch])
  const { mutate: mutateDel } = useMutation(deleteCategoria, {
    onSuccess: data => {
      toast.success('Deletado com sucesso')
      realizedReq.refetch()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível deletar')
      }
    }
  })

  return (
    <>
      <Box
        minHeight="calc(100vh - 150px)"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ marginBottom: 3 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: 3 }}
            >
              <Tooltip title="Novo">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleBtnCreateClick}
                >
                  <Add fontSize="small" />
                </Button>
              </Tooltip>
              </Stack>
              <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: 3, marginLeft: '6px' }}
            >
              <Tooltip title="Criar Pré-preenchido">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleBtnPreClick}
                >
                  <PendingActionsOutlined fontSize="small" />
                </Button>
              </Tooltip>
            </Stack>
          </Box>

          {true && (
            <div>
              <TabContext value='1'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList >
                    <Tab label="Lista de Tipos e Categorias Contábeis" value="1" />
                  </TabList>
                </Box>

                <TabPanel value="1">
                  <TableContainer style={{ marginTop: '30px', maxHeight: 440 }} component={Paper}>
                    <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                      <TableHead >
                        <StyledTableRow>
                          <StyledTableCell align="center">Índice de Refugo (%)</StyledTableCell>
                          <StyledTableCell align="center">Tipo</StyledTableCell>
                          <StyledTableCell align="center">Mês</StyledTableCell>
                          <StyledTableCell align="center">Ano</StyledTableCell>
                          <StyledTableCell align="center">Categoria</StyledTableCell>
                          <StyledTableCell align="center">Previsão de Inflação (%)</StyledTableCell>
                          <StyledTableCell align="center">Valor de Lançamento</StyledTableCell>
                          <Can
                            module={MODULES.RELATÓRIOS}
                            permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                          >
                            <StyledTableCell align="right">Ações</StyledTableCell>
                          </Can>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                      {rows && rows.length > 0
                      ? (
                        rows.map((row, i) => (
                          <StyledTableRow key={i}>
                            <StyledTableCell align="center">
                            <Chip
                              label={`${row.refuse}%`}
                              variant="outlined"
                              style={{
                                color: '#414cff',
                                background: '#d8ffff',
                                borderColor: '#414cff',
                                fontWeight: 'bold',
                                fontSize: '14px'
                              }}
                            /></StyledTableCell>
                            <StyledTableCell component="th" scope="row">{getModuleName(row.module)}</StyledTableCell>
                            <StyledTableCell align="center">{row.month}</StyledTableCell>
                            <StyledTableCell align="center">{row.year}</StyledTableCell>
                            <StyledTableCell align="center">{row?.categories[0]?.type ?? 'Não Definido'}</StyledTableCell>
                            <StyledTableCell align="center">
                              <Chip
                              label={`${row?.categories[0]?.percentage ?? 0}%`}
                              variant="outlined"
                              style={{
                                color: '#ffcf01',
                                background: '#fffbf0',
                                borderColor: '#ffcf01',
                                fontWeight: 'bold',
                                fontSize: '14px'
                              }} />
                            </StyledTableCell>
                            <StyledTableCell align="center">{toBRL(row?.categories[0]?.launchValue) ?? toBRL(0)}</StyledTableCell>
                            <Can
                              module={MODULES.RELATÓRIOS}
                              permissions={[PERMISSIONS.READ, PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                            >
                              <StyledTableCell align="right">
                                <MoreMenu
                                  module={MODULES.RELATÓRIOS}
                                  isValidationPermissions
                                  view={async () => { props.view(row) }}
                                  edit={async () => { props.edit(row) }}
                                  delete={async () => { mutateDel(row._id) }}
                                />
                              </StyledTableCell>
                            </Can>
                          </StyledTableRow>
                        )))
                      : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={8} align="center">
                              Não encontrado nenhum registro!
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabContext>
            </div>
          )}
        </Box>
        {openCreate &&
        <GenericDialog open={openCreate} close={() => { setOpenCreate(false); setDataCreate(null) }}
          content={<BudgetConfigCreateForm edit={dataCreate} close={() => { setOpenCreate(false); setDataCreate(null); realizedReq.refetch() }} />} />}
        {openPre &&
        <GenericDialog open={openPre} close={() => { setOpenPre(false); setDataPre(null) }}
          content={<BudgetConfigPreForm edit={dataPre} close={() => { setOpenPre(false); setDataPre(null); realizedReq.refetch() }} />} />}
        <Stack alignItems="flex-end" sx={{ marginTop: 4 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handlePreviousClick}
            style={{
              width: '122px'
            }}
          >
            Voltar
          </Button>
        </Stack>
      </Box>
    </>
  )
}

export default BudgetConfigTable
