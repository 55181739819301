import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Table, TablePagination } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import StatusView from 'components/view/StatusView'
import moment from 'moment'
import { toast } from 'react-toastify'
import Button from 'components/form/Button'
import { httpClient } from 'config/httpClient'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const TableAppClient: React.FC = () => {
  const [rows, setRows] = useState<any[]>([])
  const [loading, setLoading] = useState('')
  const [page, setPage] = useState<number>(0)

  useEffect(() => {
    loadSellers()
    //   getCustomer()
    //     .then((data) => setRows(data.docs))
    //     .catch(() => toast.error('Erro ao listar clientes'))
  }, [])

  const loadSellers = () => {
    httpClient.get('sellers/status').then(data => {
      console.log('DATA', data)
      setRows(data.data)
    }).catch(() => { toast.error('Erro ao listar clientes') })
  }

  const sendLinkAccess = (email) => {
    setLoading(email)
    httpClient.post('mail', { email }).then(data => {
      toast.success('E-mail enviado com sucesso')
      setLoading('')
    }).catch(() => { toast.error('Erro ao enviar link de acesso'); setLoading('') })
  }

  return (
    <>
      <TableContainer style={{ marginTop: '30px' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nome</StyledTableCell>
              <StyledTableCell align='left'>Email</StyledTableCell>
              <StyledTableCell align='left'>Data de cadastro</StyledTableCell>
              <StyledTableCell align='left'>Status</StyledTableCell>
              <StyledTableCell align='right'>Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * 10, page * 10 + 10).map((row, index) => (
              <StyledTableRow key={`KEY_BODY_${index}`}>
                <StyledTableCell align='left'>{row.name}</StyledTableCell>
                <StyledTableCell align='left'>{row.email}</StyledTableCell>
                <StyledTableCell align='left'>{moment(row.createdAt).format('DD/MM/YYYY')}</StyledTableCell>
                <StyledTableCell align='left'>
                  <StatusView status={row.isActive} />
                </StyledTableCell>
                <StyledTableCell align='right'>
                  <Button loading={loading === row.email} mold='primary' onClick={() => sendLinkAccess(row.email)}>Enviar link de acesso</Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          rowsPerPage={10}
          component="div"
          count={rows.length}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      </TableContainer>
    </>
  )
}

export default TableAppClient
