import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Table } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import { toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const VariationsTable = (props: any) => {
  const [rows, setRows] = React.useState<any[]>([])

  useEffect(() => {
    parseTableData()
  }, [props])

  const parseTableData = () => {
    const data: any[] = []
    const initialValue = {
      kg: 0,
      rs: 0,
      rsKg: 0.0
    }

    const boxesThisMonth = { ...initialValue }
    const plateThisMonth = { ...initialValue }
    const paperThisMonth = { ...initialValue }
    const othersThisMonth = { ...initialValue }
    const boxesLastMonth = { ...initialValue }
    const plateLastMonth = { ...initialValue }
    const paperLastMonth = { ...initialValue }
    const othersLastMonth = { ...initialValue }

    console.log('VARIAT', props.variation)
    console.log('Outros', props.outros)
    if (props.variation) {
      props.variation.forEach((v) => {
        if (v.month !== props.month) {
          if (v.type === TipoProduto.CAIXA) {
            boxesThisMonth.kg += parseFloat(v.kg)
            boxesThisMonth.rs += parseFloat(v.rs)
            boxesThisMonth.rsKg += parseFloat(v.rskg)
          } else if (v.type === TipoProduto.CHAPA) {
            plateThisMonth.kg += parseFloat(v.kg)
            plateThisMonth.rs += parseFloat(v.rs)
            plateThisMonth.rsKg += parseFloat(v.rskg)
          } else if (v.type === TipoProduto.PAPEL) {
            paperThisMonth.kg += parseFloat(v.kg)
            paperThisMonth.rs += parseFloat(v.rs)
            paperThisMonth.rsKg += parseFloat(v.rskg)
          } else {
            othersThisMonth.kg += parseFloat(v.kg)
            othersThisMonth.rs += parseFloat(v.rs)
            othersThisMonth.rsKg += parseFloat(v.rskg)
          }
        } else {
          if (v.type === TipoProduto.CAIXA) {
            boxesLastMonth.kg += parseFloat(v.kg)
            boxesLastMonth.rs += parseFloat(v.rs)
            boxesLastMonth.rsKg += parseFloat(v.rskg)
          } else if (v.type === TipoProduto.CHAPA) {
            plateLastMonth.kg += parseFloat(v.kg)
            plateLastMonth.rs += parseFloat(v.rs)
            plateLastMonth.rsKg += parseFloat(v.rskg)
          } else if (v.type === TipoProduto.PAPEL) {
            paperLastMonth.kg += parseFloat(v.kg)
            paperLastMonth.rs += parseFloat(v.rs)
            paperLastMonth.rsKg += parseFloat(v.rskg)
          } else {
            othersLastMonth.kg += parseFloat(v.kg)
            othersLastMonth.rs += parseFloat(v.rs)
            othersLastMonth.rsKg += parseFloat(v.rskg)
          }
        }
      })

      if (props.outros) {
        othersThisMonth.rs = Number(props.outros?.mesAtual)
        othersLastMonth.rs = Number(props.outros?.mesPassado)
      }

      const devolucoes = {
        thisMonth: props.devolucoes?.mesAtual?.reduce((acc, item) => {
          return {
            kg: Number(acc.kg) + Number(item.kg),
            rs: Number(acc.rs) + Number(item.rs),
            qtd: Number(acc.qtd) + Number(item.qtd)
          }
        }, { kg: 0, rs: 0, qtd: 0 }),

        lastMonth: props.devolucoes?.mesPassado?.reduce((acc, item) => {
          return {
            kg: Number(acc.kg) + Number(item.kg),
            rs: Number(acc.rs) + Number(item.rs),
            qtd: Number(acc.qtd) + Number(item.qtd)
          }
        }, { kg: 0, rs: 0, qtd: 0 })
      }

      const totalLasthMonth = boxesLastMonth.rs + plateLastMonth.rs + paperLastMonth.rs + othersLastMonth.rs - devolucoes.lastMonth.rs
      const totalThisMonth = boxesThisMonth.rs + plateThisMonth.rs + paperThisMonth.rs + othersThisMonth.rs - devolucoes.thisMonth.rs

      data.push({
        _id: '_caixas',
        product: 'Caixas',
        variation: (((boxesLastMonth.rs - boxesThisMonth.rs) / boxesLastMonth.rs) * 100) * -1,
        rs: boxesThisMonth.rs - boxesLastMonth.rs
      })

      data.push({
        _id: '_chapas',
        product: 'Chapas',
        variation: (((plateLastMonth.rs - plateThisMonth.rs) / plateLastMonth.rs) * 100) * -1,
        rs: plateThisMonth.rs - plateLastMonth.rs
      })

      data.push({
        _id: '_papel',
        product: 'Papel',
        variation: (((paperLastMonth.rs - paperThisMonth.rs) / paperLastMonth.rs) * 100) * -1,
        rs: paperThisMonth.rs - paperLastMonth.rs
      })

      data.push({
        _id: '_others',
        product: 'Outros',
        variation: (((othersLastMonth.rs - othersThisMonth.rs) / othersLastMonth.rs) * 100) * -1,
        rs: othersThisMonth.rs - othersLastMonth.rs
      })

      data.push({
        _id: '_devolucoes',
        product: 'Devoluções',
        variation: (((devolucoes.lastMonth.rs - devolucoes.thisMonth.rs) / devolucoes.lastMonth.rs) * 100) * -1,
        rs: (devolucoes.thisMonth.rs - devolucoes.lastMonth.rs)
      })

      data.push({
        _id: '_total',
        product: 'Total',
        variation: (((totalLasthMonth - totalThisMonth) / totalLasthMonth) * 100) * -1,
        rs: totalThisMonth - totalLasthMonth
      })

      console.log({ devolucoes })
      setRows(data)
    }
  }

  return (
    <>
      <TableContainer
        style={{ marginTop: '30px' }}
        component={Paper}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Produto</StyledTableCell>
              <StyledTableCell align="center">Variação %</StyledTableCell>
              <StyledTableCell align="center">R$</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell align="center">{row.product}</StyledTableCell>
                <StyledTableCell align="center">{(isFinite(row.variation) ? row.variation : 0).toFixed(0)}%</StyledTableCell>
                <StyledTableCell align="center">{toBRL(row.rs, { noBRL: true })}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default VariationsTable
