import { httpLN } from 'config/httpClient'
import moment from 'moment'

export const getReportRevenues = async (date: string, dataRanger?: any): Promise<any[]> => {
  let url = ''
  if (!dataRanger.length) {
    url = `faturamentos/variacao?data=${date}`
  } else {
    const inicio = dataRanger.map(v => (moment.unix(v.unix).format('DD/MM/YYYY')))
    const fim = inicio[inicio.length - 1]
    url = `faturamentos/variacao?dataInicio=${inicio[0]}&dataFim=${fim}`
  }
  const { data } = await httpLN.get(url)
  return data.variacao as any[]
}
