/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  Select,
  Slider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import alertSuccess from 'components/Alerts/ModalSucess'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { RootState } from 'core/store'
import { useFormik } from 'formik'
import {
  StyledTableCell,
  StyledTableRow
} from 'pages/company/portfolio/components/table.styles'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { putApproveProposal, putReproveProposal } from 'services/ft/FTService'
import { FTProposalType } from 'types/ft/FTProposalType'
import { validationProposalFT } from 'types/validation/ValidationProposalFT'

interface ProposalType extends FTProposalType {
  _id: string
}
interface Props {
  proposalData: any | undefined
  ft: any
  proposal: any
  confirm: (values) => any
  close: () => void
}

function ResumeProposalForm(props: Props) {
  const { proposalData, ft, proposal } = props
  const [value, setValue] = React.useState<
    number | string | Array<number | string>
  >(0)
  const [totalValue, setTotalValue] = React.useState<number>(
    Number(proposalData?.price ?? 0)
  )
  const [precoPorKg, setPrecoPorKg] = React.useState<number>(
    Number(proposal?.precoPorKg ?? 0)
  )
  const [precoValorNF, setPrecoValorNF] = React.useState<number>(
    Number(proposal?.peloValorNF ?? 0)
  )
  const [typeCorrection, setTypeCorrection] = useState('discount')

  const { loginData } = useSelector((state: RootState) => state.login)
  console.log('LOGIN', loginData?.email, loginData?.tokenData, proposalData)

  useEffect(() => {
    if (typeof value === 'number') {
      if (typeCorrection === 'discount') {
        setTotalValue(
          Number(proposalData?.price ?? 0) -
          Number(proposalData?.price ?? 0) * (value / 100)
        )
        setPrecoPorKg(
          Number(proposal?.precoPorKg ?? 0) -
          Number(proposal?.precoPorKg ?? 0) * (value / 100)
        )
        setPrecoValorNF(
          Number(proposal?.peloValorNF ?? 0) -
          Number(proposal?.peloValorNF ?? 0) * (value / 100)
        )
      } else if (typeCorrection === 'add') {
        setTotalValue(
          Number(proposalData?.price ?? 0) +
          Number(proposalData?.price ?? 0) * (value / 100)
        )
        setPrecoPorKg(
          Number(proposal?.precoPorKg ?? 0) +
          Number(proposal?.precoPorKg ?? 0) * (value / 100)
        )
        setPrecoValorNF(
          Number(proposal?.peloValorNF ?? 0) +
          Number(proposal?.peloValorNF ?? 0) * (value / 100)
        )
      }
    } else {
      setTotalValue(Number(proposalData?.price ?? 0))
    }
  }, [value, typeCorrection])

  const { errors, touched, values, handleSubmit, setFieldValue, isSubmitting } =
    useFormik({
      onSubmit: (inputValues: any) => {
        console.log('valores da proposta', inputValues)
        console.log('ft', ft)
        mutateCreate({ ...inputValues, price: totalValue })
      },
      enableReinitialize: true,
      validationSchema: validationProposalFT,
      initialValues: {
        _id: proposalData?._id ?? '',
        status: proposalData?.status ?? 'Em análise',
        gramature: proposalData?.gramature ?? 0,
        internalMeasures: proposalData?.internalMeasures ?? '',
        observation:
          'Em caso de lote piloto não faturado em até 60 dias, será cobrado o valor da ferramenta desenvolvida (faca e/ou clichê), bem como o estoque produzido.',
        price: proposalData?.price ?? 0,
        comment: proposalData?.comment ?? '',
        shipping: proposalData?.shipping ?? 0,
        minimumLot: proposalData?.minimumLot ?? 0,
        paymentCondition: proposalData?.paymentCondition ?? '',
        financingRate: proposalData?.financingRate ?? 0,
        icms: proposalData?.icms ?? 0,
        validity: proposalData?.validity ?? 0,
        deadlineDeliverDays: proposalData?.deadlineDeliverDays ?? 0,
        budget: proposalData?.budget ?? '',
        user: loginData?.tokenData.sub ?? '',
        isDirectorship: proposalData?.isDirectorship ?? false
      }
    })

  const { mutate: mutateCreate } = useMutation(putApproveProposal, {
    onSuccess: (data) => {
      console.log('proposta criada', data)
      alertSuccess(
        'Proposta de orçamento atualizada com sucesso!',
        'O Cliente poderá aprovar ou reprovar a proposta.',
        () => {
          props.confirm(null)
        }
      )
    },
    onError: () => {
      toast.error('Não foi possível criar a proposta')
    }
  })

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (Number(newValue) > 50) {
      setValue(50)
      return
    }
    setValue(newValue)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value)
    if (event.target.value !== '' && Number(event.target.value) > 50) {
      setValue(50)
      return
    }
    setValue(event.target.value === '' ? '' : Number(event.target.value))
  }

  const handleBlur = () => {
    if (Number(value) < 0) {
      setValue(0)
    } else if (Number(value) > 50) {
      setValue(50)
    }
  }

  const handleReproveProposal = async () => {
    try {
      await putReproveProposal(proposalData?._id ?? '', values.comment)
      props.close()
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
        'Não foi possível reprovar a proposta.'
      )
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip
          title="Voltar"
          placement="top"
          onClick={() => {
            props.close()
          }}
        >
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>
          Proposta
        </div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginRight: '30px',
              marginTop: '20px'
            }}
          >
            <div style={{ marginTop: 5 }}>
              <Typography component="span" variant="body2">
                Informações do orçamento que serão enviadas como proposta para o
                cliente aprovar ou reprovar.
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px'
                }}
              >
                <div>
                  <TableContainer
                    style={{ marginTop: '30px' }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 550 }} aria-label="customized table">
                      <TableBody>
                        <StyledTableRow key={0}>
                          <StyledTableCell align="left">
                            <Typography
                              component="strong"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Valor total
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              value={totalValue?.toFixed(2)}
                              disabled={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={0}>
                          <StyledTableCell align="left">
                            <Typography
                              component="strong"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Preço por Kg
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              value={precoPorKg?.toFixed(2)}
                              disabled={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={0}>
                          <StyledTableCell align="left">
                            <Typography
                              component="strong"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Preço pelo valor da NF
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              value={precoValorNF?.toFixed(2)}
                              disabled={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={0}>
                          <StyledTableCell align="left">
                            {/* <Typography
                              component="strong"
                              variant="body2"
                              fontWeight="bold"
                            >
                              Desconto vendedor
                            </Typography> */}
                            <Select
                              value={typeCorrection}
                              // label="Age"
                              onChange={(event) => setTypeCorrection(event.target.value)}
                            >
                              <MenuItem value={'discount'}>Desconto</MenuItem>
                              <MenuItem value={'add'}>Acréscimo</MenuItem>
                            </Select>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ paddingRight: 56, paddingLeft: 64 }}
                          >
                            <Grid container spacing={2} alignItems="center" sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Grid item xs>
                                <Slider
                                  step={0.01}
                                  min={0}
                                  max={100}
                                  value={typeof value === 'number' ? value : 0}
                                  onChange={handleSliderChange}
                                  sx={{ width: '110px' }}
                                />
                              </Grid>
                              <Grid
                                item
                                sx={{ display: 'flex', flexDirection: 'row' }}
                              >
                                {/* <TextField
                                type="number"
                                variant='standard'
                                value={value}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                sx={{ width: 40 }}
                                /> */}
                                <Input
                                  value={Number(value).toFixed(2)}
                                  onChange={handleInputChange}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    step: 0.01,
                                    min: 0,
                                    max: 100,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider'
                                  }}
                                  sx={{ width: 100 }}
                                />
                                <Typography>%</Typography>
                              </Grid>
                            </Grid>
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={0}>
                          <StyledTableCell align="left">
                            Gramatura
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.gramature}
                              disabled={true}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={1}>
                          <StyledTableCell align="left">
                            Medida interna
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.internalMeasures}
                              disabled={true}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={2}>
                          <StyledTableCell align="left">Preço</StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.price}
                              disabled={true}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={3}>
                          <StyledTableCell align="left">Frete</StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.shipping}
                              disabled={true}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={4}>
                          <StyledTableCell align="left">
                            Lote mínimo
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.minimumLot}
                              disabled={true}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={5}>
                          <StyledTableCell align="left">
                            Condição de pagamento
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.paymentCondition}
                              disabled={true}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={6}>
                          <StyledTableCell align="left">
                            Taxa de financiamento
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.financingRate}
                              disabled={true}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={7}>
                          <StyledTableCell align="left">ICMS</StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.icms}
                              disabled={true}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={8}>
                          <StyledTableCell align="left">
                            Validade da proposta
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.validity}
                              type="number"
                              onChange={(event) => {
                                setFieldValue('validity', event.target.value)
                              }}
                              error={Boolean(
                                errors.validity && touched.validity
                              )}
                              helperText={
                                (errors.validity && touched.validity
                                  ? errors.validity
                                  : null) as React.ReactNode
                              }
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={9}>
                          <StyledTableCell align="left">
                            Prazo de entrega
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.deadlineDeliverDays}
                              type="number"
                              onChange={(event) => {
                                setFieldValue(
                                  'deadlineDeliverDays',
                                  event.target.value
                                )
                              }}
                              error={Boolean(
                                errors.deadlineDeliverDays &&
                                touched.deadlineDeliverDays
                              )}
                              helperText={
                                (errors.deadlineDeliverDays &&
                                  touched.deadlineDeliverDays
                                  ? errors.deadlineDeliverDays
                                  : null) as React.ReactNode
                              }
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={10}>
                          <StyledTableCell align="left">
                            Observação
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="outlined-multiline-static"
                              autoComplete="off"
                              multiline
                              rows={4}
                              value={values.observation}
                              defaultValue={
                                'Em caso de lote piloto não faturado em até 60 dias, será cobrado o valor da ferramenta desenvolvida (faca e/ou clichê), bem como o estoque produzido.'
                              }
                              onChange={(event) => {
                                setFieldValue(
                                  'observation',
                                  event.target.value
                                )
                              }}
                              error={Boolean(
                                errors.observation && touched.observation
                              )}
                              helperText={
                                errors.observation && touched.observation
                                  ? errors.observation
                                  : null
                              }
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={10}>
                          <StyledTableCell colSpan={2}>
                            <Stack spacing={2}>

                              <Typography component="span">Comentário</Typography>
                              <TextField
                                autoComplete="off"
                                multiline
                                minRows={3}
                                value={values.comment}
                                onChange={(event) => {
                                  setFieldValue(
                                    'comment',
                                    event.target.value
                                  )
                                }}
                                error={Boolean(
                                  errors.comment && touched.comment
                                )}
                                helperText={'Adicione um comentário referente a sua aprovação ou reprovação.'}
                              />
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type="button"
              mold="red"
              onClick={() => {
                void handleReproveProposal()
              }}
              disabled={isSubmitting}
              style={{ marginRight: 16 }}
            >
              Reprovar
            </Button>
            <Button
              style={{
                marginRight: '16px'
              }}
              mold="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Aprovar
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default ResumeProposalForm
