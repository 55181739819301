import { CircularProgress } from '@mui/material'
import React from 'react'
import { BoxStyle } from './deliveryMap.style'

interface Props {
  isLoading: boolean
}

export const LoadingDeliveryMap: React.FC<Props> = ({ isLoading = false }) => {
  return (
    <>
      {isLoading && (
        <BoxStyle>
          <CircularProgress />
        </BoxStyle>
      )}
    </>
  )
}
