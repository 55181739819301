import styled from 'styled-components'
import ButtonMaterial from '@mui/material/Button'

const Button = styled(ButtonMaterial)`
  padding: 4px;
  background-color: #4060e6;
  min-width: initial;
  margin-bottom: 10px;
`

const WrapperAccordion = styled.div`
  display: grid;
  gap: 10px;

  & > div::before {
    display: none;
  }
`

export { WrapperAccordion, Button }
