import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { Can } from 'components/Permission/Can'
import MoreMenu from 'components/view/MoreMenu'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { StyledTableCell, StyledTableRow } from 'pages/company/portfolio/components/table.styles'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { deleteTaxes, getTaxes } from 'services/material/MaterialService'

function TaxesTable(props: any) {
  const [rows, setRows] = React.useState<any[]>([])

  useEffect(() => {
    taxesReq.refetch()
  }, [props.refetch])

  const taxesReq = useQuery(['taxes', null],
    async () => {
      return getTaxes()
    },
    {
      onSuccess: (data) => {
        console.log('data taxes', data)
        setRows(data)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const { mutate: mutateDel } = useMutation(deleteTaxes, {
    onSuccess: data => {
      toast.success('Deletado com sucesso')
      taxesReq.refetch()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível deletar')
      }
    }
  })

  return (
    <div>
      <TableContainer style={{ marginTop: '30px', maxHeight: 440 }} component={Paper}>
        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Pagamento em dias</StyledTableCell>
              <StyledTableCell align="center">Taxa financeira</StyledTableCell>
              <Can
                module={MODULES.GERENCIAL}
                permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
              >
                <StyledTableCell align="right">Ações</StyledTableCell>
              </Can>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">{row.days}</StyledTableCell>
                <StyledTableCell align="center">{row.tax}%</StyledTableCell>
                <Can
                  module={MODULES.GERENCIAL}
                  permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                >
                  <StyledTableCell align="right">
                    <MoreMenu
                      module={MODULES.GERENCIAL}
                      isValidationPermissions
                      edit={async () => { props.edit(row) }}
                      delete={async () => { mutateDel(row._id) }}
                    />
                  </StyledTableCell>
                </Can>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default TaxesTable
