import * as yup from 'yup'

export const validationSchemaBudgetCompleteFT = yup.object().shape({
  monthlyConsumption: yup.number().required('Preencha esse campo!').moreThan(0, 'Informe um valor maior que zero!'),
  destinationCity: yup.string().required('Preencha esse campo!'),
  shipping: yup.string().required('Preencha esse campo!'),
  icms: yup.number().required('Preencha esse campo!'),
  minimumLot: yup.number().required('Preencha esse campo!').moreThan(0, 'Informe um valor maior que zero!'),
  glueClipClosure: yup.number().required('Preencha esse campo!')
})
