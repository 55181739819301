import { Typography } from '@mui/material'
import moment from 'moment'

export function DiferenceDays({ start, end }: { start: string, end: string }) {
  const startDate = moment(start)
  const endDate = moment(end)

  const days = endDate.diff(startDate, 'days')

  return (
    <>
      {endDate.isBefore(startDate) && (
        <Typography component="span" variant="body2" sx={{ color: '#EE207A' }}>
          {startDate.diff(endDate, 'days')} dias
        </Typography>
      )}

      {!endDate.isBefore(startDate) && (
        <Typography component="span" variant="body2" sx={{ color: '#4060E6' }}>
          {days > 0 ? '-' : ''}
          {days} dias
        </Typography>
      )}
    </>
  )
}
