/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unreachable */
import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import Button from 'components/form/Button'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import {
  Grid,
  TextField,
  FormControlLabel,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  CircularProgress,
  Stack,
  Checkbox,
  FormGroup
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import { httpClient } from 'config/httpClient'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import MoreMenu from 'components/view/MoreMenu'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ActionButton } from 'components/view/styles/style-more-menu'
import { ModalComponent } from 'components/Modal'
import alertSwalCancel from 'components/Alerts/ModalCancel'
import moment from 'moment'
import { onlyNumbers } from 'helpers/stringHelper'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'

function Status({ title }: { title: string }) {
  if (title?.toUpperCase()?.includes('PENDENTE DE APROVA')) {
    return (
      <Chip
        size="small"
        color="warning"
        label={title}
        variant="outlined"
        sx={{
          borderRadius: '4px',
          borderColor: '#EEBD20',
          color: '#EEBD20'
        }}
      />
    )
  } else if (title?.toUpperCase()?.includes('REJEITADO')) {
    return (
      <Chip
        size="small"
        color="error"
        label={'REPROVADO'}
        variant="outlined"
        sx={{
          borderRadius: '4px',
          borderColor: '#EE207A',
          color: '#EE207A'
        }}
      />
    )
  } else if (title?.toUpperCase()?.includes('ENTREGUE')) {
    return (
      <Chip
        size="small"
        color="success"
        label={title}
        variant="outlined"
        sx={{
          borderRadius: '4px',
          borderColor: '#72CE2B',
          color: '#72CE2B'
        }}
      />
    )
  } else if (title?.toUpperCase()?.includes('ENVIO PENDENTE')) {
    return (
      <Chip
        size="small"
        color="default"
        label={title}
        variant="outlined"
        sx={{
          borderRadius: '4px',
          borderColor: '#F06939',
          color: '#F06939'
        }}
      />
    )
  } else if (title?.toUpperCase()?.includes('APROVADO')) {
    return (
      <Chip
        size="small"
        color="default"
        label={title}
        variant="outlined"
        sx={{
          borderRadius: '4px',
          borderColor: '#72CE2B',
          color: '#72CE2B'
        }}
      />
    )
  } else {
    return (
      <Chip
        size="small"
        color="default"
        label={title || 'ENVIO PENDENTE'}
        variant="outlined"
        sx={{
          borderRadius: '4px',
          borderColor: '#F06939',
          color: '#F06939'
        }}
      />
    )
  }
}

interface PropsDocRow {
  title: string
  date?: string
  status?: string
  onAudit: () => void
  disabledAudit?: boolean
  onDownload?: () => void
  disabledDownload?: boolean
}

function DocTableRow({
  title,
  date,
  status = 'ENVIO PENDENTE',
  onAudit,
  disabledAudit = false,
  onDownload,
  disabledDownload = false
}: PropsDocRow) {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <strong>{title}</strong>
      </TableCell>
      <TableCell>{date && moment(date).format('DD/MM/YYYY')}</TableCell>
      <TableCell>
        <Status title={status} />
      </TableCell>
      <TableCell align="right">
        <MoreMenu>
          {onDownload && (
            <LightTooltip title="Baixar" placement="top">
              <ActionButton disabled={disabledDownload} onClick={onDownload}>
                <FileDownloadIcon />
              </ActionButton>
            </LightTooltip>
          )}
          <LightTooltip title="Auditar" placement="top">
            <ActionButton
              onClick={onAudit}
              disabled={disabledAudit || (status === 'REJEITADO' && title !== 'Declaração tributária')}
            >
              <AssignmentTurnedInIcon />
            </ActionButton>
          </LightTooltip>
        </MoreMenu>
      </TableCell>
    </TableRow>
  )
}

const CustomerControlDocumentation: React.FC = () => {
  const navigate = useNavigate()
  const { loginData } = useSelector((state: RootState) => state.login)
  console.log(
    '🚀 ~ file: TableCustomerControl.tsx ~ line 92 ~ loginData',
    loginData?.tokenData
  )
  const { state }: any = useLocation()
  console.log(
    '🚀 ~ file: CustomerControlDocumentation.tsx ~ line 132 ~ state',
    state
  )

  const modalAuditRef = useRef<any>(null)
  const modalAuditRejectRef = useRef<any>(null)
  const modalAuditDocsRef = useRef<any>(null)

  const [currentDoc, setCurrentDoc] = useState<any>(null)

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false)
  const [client, setClient] = useState<any>(state?.client)

  const [loadingDeclaration, setLoadingDeclaration] = useState<boolean>(false)

  const [blockedFinancial, setBlockedFinancial] = useState<boolean>(true)
  const [blockedFiscal, setBlockedFiscal] = useState<boolean>(true)
  const [showDocs, setShowDocs] = useState<boolean>(false)

  const { status, refetch } = useQuery(
    ['profiles'],
    async () => {
      return httpClient.get('/profiles')
    },
    {
      onSuccess: (response) => {
        console.log('profiles', response)
        const findFinancial = response.data?.find((item) =>
          item.name?.toUpperCase()?.includes('FINANCEIRO')
        )
        const findFiscal = response.data?.find((item) =>
          item.name?.toUpperCase()?.includes('FISCAL')
        )
        const findVendedor = response.data?.find((item) =>
          item.name?.toUpperCase()?.includes('VENDEDOR')
        )

        if (
          findFinancial &&
          findFinancial?._id === loginData?.tokenData?.profileId
        ) {
          setBlockedFinancial(false)
        }

        if (findFiscal && findFiscal?._id === loginData?.tokenData?.profileId) {
          setBlockedFiscal(false)
        }

        if (findVendedor && findVendedor?._id === loginData?.tokenData?.profileId) {
          setShowDocs(true)
        }
      },
      refetchOnWindowFocus: false
    }
  )

  const handleOpenAudit = () => {
    modalAuditRef?.current?.onOpen()
  }

  const handleCloseAudit = () => {
    modalAuditRef?.current?.onClose()
  }

  const handleOpenAuditReject = async () => {
    modalAuditRejectRef?.current?.onOpen()
  }

  const handleCloseAuditReject = () => {
    modalAuditRejectRef?.current?.onClose()
  }

  const handleOpenAuditDocs = (
    title: string,
    type: 'UB' | 'PB' | 'AB' | 'RB' | 'IR' | 'DC' | 'CC' | 'IE' | 'CS' | 'AC',
    typeClient: 'PF' | 'PJ'
  ) => {
    setCurrentDoc({ title, type, typeClient })
    modalAuditDocsRef?.current?.onOpen()
  }

  const handleCloseAuditDocs = () => {
    setCurrentDoc(null)
    modalAuditDocsRef?.current?.onClose()
  }

  function download(file: string, name: string, mimetype: string) {
    console.log('file', file, mimetype)
    if (!file) return
    // return
    let ext = ''
    switch (mimetype) {
      case 'application/pdf':
        ext = '.pdf'
        break
      case 'application/octet-stream':
        ext = '.dwg'
        break
      case 'image/jpeg':
        ext = '.jpg'
        break
      case 'image/png':
        ext = '.png'
        break
      default:
        ext = '.' + mimetype?.split('/')?.[1] ?? ''
    }

    const link = window.document.createElement('a')
    link.href = file
    link.download = `${name}${ext}`
    link.click()
    window.URL.revokeObjectURL(link.href)
  }

  const onLoadClient = () => {
    httpClient
      .get(`/company/${state?.client?._id}`)
      .then((response) => {
        setClient((state) => response.data || state)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    onLoadClient()
  }, [])

  const handleApproveDoc = () => {
    if (!currentDoc) return
    setLoadingStatus(true)

    httpClient
      .put(`/company/${state?.client?._id}/docs/approve`, {
        type: currentDoc?.type,
        typeClient: state?.client?.type
      })
      .then(() => {
        handleCloseAuditDocs()
        onLoadClient()
        toast.success('Aprovado com sucesso!')
      })
      .catch((error) => {
        console.log('Error: ', error)
        toast.error('Erro ao aprovar documentação!')
      })
      .finally(() => {
        setLoadingStatus(false)
      })
  }
  const handleReproveDoc = () => {
    if (!currentDoc) return
    setLoadingStatus(true)

    httpClient
      .put(`/company/${state?.client?._id}/docs/reprove`, {
        type: currentDoc?.type,
        typeClient: state?.client?.type
      })
      .then(() => {
        handleCloseAuditDocs()
        onLoadClient()
        toast.success('Reprovado com sucesso!')
      })
      .catch((error) => {
        console.log('Error: ', error)
        toast.error('Erro ao aprovar documentação!')
      })
      .finally(() => {
        setLoadingStatus(false)
      })
  }

  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Clientes', url: '/home/customer-control' },
          { text: 'Documentos' }
        ]}
      />

      <Typography fontWeight="bold" fontStyle="16px" sx={{ paddingY: 3 }}>
        Documentos
      </Typography>

      <Grid container spacing={4}>
        <Grid item md={12} className="text-right">
          <TableContainer>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>DOCUMENTO</TableCell>
                  <TableCell>ÚLTIMO ENVIO</TableCell>
                  <TableCell>STATUS</TableCell>
                  <TableCell align="right">AÇÕES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(!client?.pj?.docs?.length || !client?.pf?.docs?.length) &&
                  loading && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}

                {client?.type === 'PJ' && (
                  <>
                    <DocTableRow
                      title="Declaração tributária"
                      date={client?.declaration?.dateConfirmation}
                      status={
                        client?.declaration?.status?.length
                          ? client?.declaration?.status
                          : undefined
                      }
                      onAudit={() => handleOpenAudit()}
                      disabledAudit={
                        !client?.declaration?.status || (blockedFiscal && !showDocs)
                      }
                    />

                    <DocTableRow
                      title="Cartão de CNPJ"
                      date={client?.pj?.docs?.cnpjCard?.lastSend}
                      status={
                        client?.pj?.docs?.cnpjCard?.status?.length
                          ? client?.pj?.docs?.cnpjCard?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pj?.docs?.cnpjCard?.url,
                          client?.pj?.docs?.cnpjCard?._id,
                          client?.pj?.docs?.cnpjCard?.mimetype
                        )
                      }
                      disabledDownload={
                        !client?.pj?.docs?.cnpjCard?.url || (blockedFiscal && !showDocs)
                      }
                      onAudit={() =>
                        handleOpenAuditDocs('Cartão de CNPJ', 'CC', 'PJ')
                      }
                      disabledAudit={
                        !client?.pj?.docs?.cnpjCard?.url || blockedFiscal
                      }
                    />

                    <DocTableRow
                      title="Inscrição estadual"
                      date={client?.pj?.docs?.stateRegistration?.lastSend}
                      status={
                        client?.pj?.docs?.stateRegistration?.status?.length
                          ? client?.pj?.docs?.stateRegistration?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pj?.docs?.stateRegistration?.url,
                          client?.pj?.docs?.stateRegistration?._id,
                          client?.pj?.docs?.stateRegistration?.mimetype
                        )
                      }
                      disabledDownload={
                        !client?.pj?.docs?.stateRegistration?.url ||
                        (blockedFiscal && !showDocs)
                      }
                      onAudit={() =>
                        handleOpenAuditDocs('Inscrição estadual', 'IE', 'PJ')
                      }
                      disabledAudit={
                        !client?.pj?.docs?.stateRegistration?.url ||
                        blockedFiscal
                      }
                    />

                    <DocTableRow
                      title="Contrato social"
                      date={client?.pj?.docs?.socialContract?.lastSend}
                      status={
                        client?.pj?.docs?.socialContract?.status?.length
                          ? client?.pj?.docs?.socialContract?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pj?.docs?.socialContract?.url,
                          client?.pj?.docs?.socialContract?._id,
                          client?.pj?.docs?.socialContract?.mimetype
                        )
                      }
                      disabledDownload={
                        !client?.pj?.docs?.socialContract?.url ||
                        (blockedFinancial && !showDocs)
                      }
                      onAudit={() =>
                        handleOpenAuditDocs('Contrato social', 'CS', 'PJ')
                      }
                      disabledAudit={
                        !client?.pj?.docs?.socialContract?.url ||
                        blockedFinancial
                      }
                    />

                    <DocTableRow
                      title="Alteração contratual"
                      date={client?.pj?.docs?.contractualAlteration?.lastSend}
                      status={
                        client?.pj?.docs?.contractualAlteration?.status?.length
                          ? client?.pj?.docs?.contractualAlteration?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pj?.docs?.contractualAlteration?.url,
                          client?.pj?.docs?.contractualAlteration?._id,
                          client?.pj?.docs?.contractualAlteration?.mimetype
                        )
                      }
                      disabledDownload={
                        !client?.pj?.docs?.contractualAlteration?.url ||
                        (blockedFinancial && !showDocs)
                      }
                      onAudit={() =>
                        handleOpenAuditDocs('Alteração contratual', 'AC', 'PJ')
                      }
                      disabledAudit={
                        !client?.pj?.docs?.contractualAlteration?.url ||
                        blockedFinancial
                      }
                    />

                    <DocTableRow
                      title="Relação de bens"
                      date={client?.pj?.docs?.listAssets?.lastSend}
                      status={
                        client?.pj?.docs?.listAssets?.status?.length
                          ? client?.pj?.docs?.listAssets?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pj?.docs?.listAssets?.url,
                          client?.pj?.docs?.listAssets?._id,
                          client?.pj?.docs?.listAssets?.mimetype
                        )
                      }
                      disabledDownload={
                        !client?.pj?.docs?.listAssets?.url || (blockedFinancial && !showDocs)
                      }
                      onAudit={() =>
                        handleOpenAuditDocs('Relação de bens', 'RB', 'PJ')
                      }
                      disabledAudit={
                        !client?.pj?.docs?.listAssets?.url || blockedFinancial
                      }
                    />

                    <DocTableRow
                      title="Último balanço"
                      date={client?.pj?.docs?.lastBalance?.lastSend}
                      status={
                        client?.pj?.docs?.lastBalance?.status?.length
                          ? client?.pj?.docs?.lastBalance?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pj?.docs?.lastBalance?.url,
                          client?.pj?.docs?.lastBalance?._id,
                          client?.pj?.docs?.lastBalance?.mimetype
                        )
                      }
                      disabledDownload={
                        !client?.pj?.docs?.lastBalance?.url || (blockedFinancial && !showDocs)
                      }
                      onAudit={() =>
                        handleOpenAuditDocs('Último balanço', 'UB', 'PJ')
                      }
                      disabledAudit={
                        !client?.pj?.docs?.lastBalance?.url || blockedFinancial
                      }
                    />

                    <DocTableRow
                      title="Penúltimo balanço"
                      date={client?.pj?.docs?.penultimateBalance?.lastSend}
                      status={
                        client?.pj?.docs?.penultimateBalance?.status?.length
                          ? client?.pj?.docs?.penultimateBalance?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pj?.docs?.penultimateBalance?.url,
                          client?.pj?.docs?.penultimateBalance?._id,
                          client?.pj?.docs?.penultimateBalance?.mimetype
                        )
                      }
                      disabledDownload={
                        !client?.pj?.docs?.penultimateBalance?.url ||
                        (blockedFinancial && !showDocs)
                      }
                      onAudit={() =>
                        handleOpenAuditDocs('Penúltimo balanço', 'PB', 'PJ')
                      }
                      disabledAudit={
                        !client?.pj?.docs?.penultimateBalance?.url ||
                        blockedFinancial
                      }
                    />

                    <DocTableRow
                      title="Antepenúltimo balanço"
                      date={client?.pj?.docs?.antepenultimateBalance?.lastSend}
                      status={
                        client?.pj?.docs?.antepenultimateBalance?.status?.length
                          ? client?.pj?.docs?.antepenultimateBalance?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pj?.docs?.antepenultimateBalance?.url,
                          client?.pj?.docs?.antepenultimateBalance?._id,
                          client?.pj?.docs?.antepenultimateBalance?.mimetype
                        )
                      }
                      disabledDownload={
                        !client?.pj?.docs?.antepenultimateBalance?.url ||
                        (blockedFinancial && !showDocs)
                      }
                      onAudit={() =>
                        handleOpenAuditDocs('Antepenúltimo balanço', 'AB', 'PJ')
                      }
                      disabledAudit={
                        !client?.pj?.docs?.antepenultimateBalance?.url ||
                        blockedFinancial
                      }
                    />
                  </>
                )}

                {client?.type === 'PF' && (
                  <>
                    <DocTableRow
                      title="Identificação com foto"
                      date={client?.pf?.docs?.document?.lastSend}
                      status={
                        client?.pf?.docs?.document?.status?.length
                          ? client?.pf?.docs?.document?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pf?.docs?.document?.url,
                          client?.pf?.docs?.document?._id,
                          client?.pf?.docs?.document?.mimetype
                        )
                      }
                      disabledDownload={!client?.pf?.docs?.document?.url}
                      onAudit={() =>
                        handleOpenAuditDocs(
                          'Identificação com foto',
                          'DC',
                          'PF'
                        )
                      }
                      disabledAudit={!client?.pf?.docs?.document?.url}
                    />
                    <DocTableRow
                      title="Declaração de imposto de renda"
                      date={client?.pf?.docs?.ir?.lastSend}
                      status={
                        client?.pf?.docs?.ir?.status?.length
                          ? client?.pf?.docs?.ir?.status
                          : undefined
                      }
                      onDownload={() =>
                        download(
                          client?.pf?.docs?.ir?.url,
                          client?.pf?.docs?.ir?._id,
                          client?.pf?.docs?.ir?.mimetype
                        )
                      }
                      disabledDownload={!client?.pf?.docs?.ir?.url}
                      onAudit={() =>
                        handleOpenAuditDocs(
                          'Declaração de imposto de renda',
                          'IR',
                          'PF'
                        )
                      }
                      disabledAudit={!client?.pf?.docs?.ir?.url}
                    />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={12} className="text-right">
          <Button
            type="button"
            mold="red"
            onClick={() =>
              navigate('/home/customer-control', {
                state: undefined
              })
            }
            style={{ marginRight: '10px' }}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            mold="primary"
            type="button"
            disabled={loading}
            onClick={() =>
              navigate('/home/customer-control', {
                state: undefined
              })
            }
          >
            Salvar
          </Button>
        </Grid>
      </Grid>

      <ModalComponent
        maxWidth="xs"
        fullWidth
        aria-labelledby="auditar-docs"
        ref={modalAuditDocsRef}
      >
        <Stack spacing={3} paddingY={3} paddingX={6}>
          <Typography fontWeight="500" component="strong">
            {currentDoc?.title}
          </Typography>

          <Stack
            direction="row"
            spacing={3}
            paddingTop={4}
            justifyContent="flex-end"
          >
            <Button
              type="button"
              onClick={() => alertSwalCancel(handleCloseAuditDocs)}
              disabled={loadingStatus}
            >
              Cancelar
            </Button>
            <Button
              mold="red"
              type="button"
              onClick={handleReproveDoc}
              disabled={loadingStatus}
            >
              Reprovar
            </Button>
            <Button
              mold="green"
              type="submit"
              onClick={handleApproveDoc}
              disabled={loadingStatus}
            >
              Aprovar
            </Button>
          </Stack>
        </Stack>
      </ModalComponent>

      <ModalComponent
        maxWidth="lg"
        fullWidth
        aria-labelledby="auditar"
        ref={modalAuditRef}
      >
        <Stack spacing={3} paddingY={3} paddingX={6}>
          <Box
            borderLeft="20px solid #EEBD20"
            boxShadow="0 3px 6px #00000029"
            minHeight={74}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
          >
            <Typography
              fontSize="20px"
              fontWeight="500"
              component="strong"
              sx={{ paddingY: 1, paddingX: 2 }}
            >
              Resumo
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              sx={{ paddingY: 1, paddingX: 2 }}
            >
              <PermIdentityIcon />
              <Typography component="span" variant="body1" marginLeft={1}>
                Emitido por: {state?.client?.seller?.user?.name}
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              sx={{ paddingY: 1, paddingX: 2 }}
            >
              <CalendarTodayOutlinedIcon />
              <Typography component="span" variant="body1" marginLeft={1}>
                {moment(state?.client?.createdAt).format('DD/MM/YYYY')}
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              sx={{ paddingY: 1, paddingX: 2 }}
            >
              <AccessTimeOutlinedIcon />
              <Typography component="span" variant="body1" marginLeft={1}>
                {moment(state?.client?.createdAt).format('HH:mm')}
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              sx={{ paddingY: 1, paddingX: 2 }}
            >
              <CalendarTodayOutlinedIcon />
              <Typography component="span" variant="body1" marginLeft={1}>
                Para: {state?.client?.name}
              </Typography>
            </Box>
          </Box>

          <Typography fontWeight="500" component="strong">
            Declaração tributária
          </Typography>
          <Formik
            initialValues={{
              _id: (state?.client?._id ?? '') as React.ReactNode,
              file: (state?.client?.url ?? '') as React.ReactNode,
              cnpj: (state?.client?.declaration?.doc ?? '') as React.ReactNode,
              cpfRepresentative: (state?.client?.declaration?.cpfRepresentative ?? '') as string,
              formTaxation: (state?.client?.declaration?.formTaxation ?? '') as React.ReactNode,
              lat: (state?.client?.declaration?.lat ?? '') as React.ReactNode,
              long: (state?.client?.declaration?.long ?? '') as React.ReactNode,
              name: (state?.client?.name ?? '') as React.ReactNode,
              fantasy_name: (state?.client?.fantasy_name ?? '') as React.ReactNode,
              suframa_registration: (state?.client?.suframa_registration ?? '') as React.ReactNode,
              ir: (state?.client?.ir ?? '') as React.ReactNode,
              contractual_changes: (state?.client?.contractual_changes ?? '') as React.ReactNode,
              email_nfe: (state?.client?.nfe ?? '') as React.ReactNode,
              email_contact: (state?.client?.email_contact ?? '') as React.ReactNode,
              email_financial: (state?.client?.email_financial ?? '') as React.ReactNode,
              address: {
                zipcode: (state?.client?.address?.zipcode ?? '') as React.ReactNode,
                public_place: (state?.client?.address?.publicPlace ?? '') as React.ReactNode,
                number: (state?.client?.address?.number ?? undefined) as React.ReactNode,
                district: (state?.client?.address?.district ?? '') as React.ReactNode,
                state: (state?.client?.address?.state ?? '') as React.ReactNode,
                city: (state?.client?.address?.city ?? '') as React.ReactNode
              }
            }}
            // validationSchema={validationRepresentativeSchema}
            onSubmit={async (values) => {
              console.log(values)
              // const body = values?.representatives

              try {
                setLoadingDeclaration(true)
                await httpClient.put(
                  `/company/${state?.client?._id}/declaration/approve`
                )
                setClient((state) => ({
                  ...state,
                  declaration: { ...state?.declaration, status: 'APROVADO' }
                }))

                toast.success('Aprovado com sucesso!')

                handleCloseAudit()
              } catch (error) {
                console.log('Error: ', error)
                toast.error(error?.response?.data?.message)
                // eslint-disable-next-line no-unreachable
              } finally {
                setLoadingDeclaration(false)
              }
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
              <Form>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.name && touched.name)}
                        value={values.name}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Nome da Empresa"
                        helperText={
                          errors.name && touched.name ? errors.name : null
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputMask
                        mask={
                          onlyNumbers(values.cnpj as string).length > 11
                            ? '99.999.999/9999-99'
                            : '999.999.999-99'
                        }
                        disabled
                        maskChar=""
                        value={values.cnpj}
                      >
                        {() => (
                          <TextField
                            error={Boolean(errors.cnpj && touched.cnpj)}
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            label="CNPJ / CPF Rural"
                            helperText={
                              errors.cnpj && touched.cnpj ? errors.cnpj : null
                            }
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <InputMask
                        mask={
                          onlyNumbers(values.cpfRepresentative)?.length > 11
                            ? '99.999.999/9999-99'
                            : '999.999.999-99'
                        }
                        disabled
                        maskChar=""
                        value={values.cpfRepresentative}
                      >
                        {() => (
                          <TextField
                            error={Boolean(
                              errors.cpfRepresentative &&
                                touched.cpfRepresentative
                            )}
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            label="CPF do representante legal"
                            helperText={
                              errors.cpfRepresentative &&
                              touched.cpfRepresentative
                                ? errors.cpfRepresentative
                                : null
                            }
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <InputMask
                        mask="99999-999"
                        disabled
                        maskChar=""
                        value={values.address?.zipcode}
                      >
                        {() => (
                          <TextField
                            error={Boolean(
                              errors.address?.zipcode &&
                                touched.address?.zipcode
                            )}
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            label="CEP"
                            helperText={
                              errors.address?.zipcode &&
                              touched.address?.zipcode
                                ? errors.address?.zipcode
                                : null
                            }
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.address?.public_place &&
                            touched.address?.public_place
                        )}
                        value={values.address?.public_place}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Logradouro"
                        helperText={
                          errors.address?.public_place &&
                          touched.address?.public_place
                            ? errors.address?.public_place
                            : null
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        error={Boolean(
                          errors.address?.number && touched.address?.number
                        )}
                        value={values.address?.number}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Número"
                        helperText={
                          errors.address?.number && touched.address?.number
                            ? errors.address?.number
                            : null
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.address?.district && touched.address?.district
                        )}
                        value={values.address?.district}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Bairro"
                        helperText={
                          errors.address?.district && touched.address?.district
                            ? errors.address?.district
                            : null
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.address?.city && touched.address?.city
                        )}
                        value={values.address?.city}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Estado"
                        helperText={
                          errors.address?.city && touched.address?.city
                            ? errors.address?.city
                            : null
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.address?.state && touched.address?.state
                        )}
                        value={values.address?.state}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Estado"
                        helperText={
                          errors.address?.state && touched.address?.state
                            ? errors.address?.state
                            : null
                        }
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography fontWeight="500" component="strong">
                        Forma de Tributação do Imposto de Renda da Pessoa
                        Jurídica é:
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={3} style={{ marginTop: '25px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={values.formTaxation === 'real'} />
                        }
                        label="Lucro real"
                      />
                    </Grid>
                    <Grid item xs={4} md={3} style={{ marginTop: '25px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.formTaxation === 'presumed'}
                          />
                        }
                        label="Lucro presumido"
                      />
                    </Grid>
                    <Grid item xs={4} md={3} style={{ marginTop: '25px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.formTaxation === 'simple'}
                          />
                        }
                        label="Lucro simples"
                      />
                    </Grid>
                    <Grid item xs={4} md={3} style={{ marginTop: '25px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.formTaxation === 'arbitrated'}
                          />
                        }
                        label="Lucro arbitrado e Sem Fins Lucrativos"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography fontWeight="500" component="strong">
                        Geolocalização
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        error={Boolean(errors.lat && touched.lat)}
                        value={values.lat}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Latitude"
                        helperText={
                          errors.lat && touched.lat ? errors.lat : null
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        error={Boolean(errors.long && touched.long)}
                        value={values.long}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Longitude"
                        helperText={
                          errors.long && touched.long ? errors.long : null
                        }
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>Arquivo anexado</Typography>
                    </Grid>
                    {client?.declaration?.archive?.url && (
                      <Grid item xs={12}>
                        <Typography
                          component="a"
                          href={client?.declaration?.archive?.url}
                          target="_blank"
                          variant="body2"
                          color="primary"
                          fontWeight="bold"
                        >
                          Visualizar arquivo
                        </Typography>
                      </Grid>
                    )}
                    {!client?.declaration?.archive?.url && (
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          Nenhum arquivo anexado.
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Typography>* Termo de Compromisso</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Boolean(
                                state?.client?.declaration?.termOfCommitment
                              )}
                            />
                          }
                          label="Aceitar termo de compromisso"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Box>

                <Stack
                  direction="row"
                  spacing={3}
                  paddingTop={4}
                  justifyContent="flex-end"
                >
                  {loadingDeclaration && (
                    <Box display="flex" alignItems="center" marginRight={3}>
                      <CircularProgress size={18} />
                      <Typography component="span" variant="body2">
                        Carregando...
                      </Typography>
                    </Box>
                  )}
                  <Button
                    type="button"
                    onClick={() => handleCloseAudit()}
                    disabled={loadingDeclaration}
                  >
                    Cancelar
                  </Button>
                  <Button
                    mold="red"
                    onClick={handleOpenAuditReject}
                    type="button"
                    disabled={loadingDeclaration || blockedFiscal}
                  >
                    Reprovar
                  </Button>
                  <Button
                    mold="green"
                    type="submit"
                    disabled={loadingDeclaration || blockedFiscal}
                  >
                    Aprovar
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </ModalComponent>

      <ModalComponent
        maxWidth="md"
        fullWidth
        aria-labelledby="rejeitar"
        ref={modalAuditRejectRef}
      >
        <Stack spacing={3} paddingY={3} paddingX={6}>
          <Typography fontWeight="400" component="strong">
            Explique o motivo da reprovação
          </Typography>
          <Formik
            initialValues={{
              _id: state?.client?._id ?? '',
              description: ''
            }}
            // validationSchema={validationRepresentativeSchema}
            onSubmit={async (values) => {
              console.log(values)
              // const body = values?.representatives
              setLoadingDeclaration(true)
              try {
                await httpClient.put(
                  `/company/${state?.client?._id}/declaration/reprove`,
                  {
                    reasonDisapproval: values?.description
                  }
                )
                setClient((state) => ({
                  ...state,
                  declaration: { ...state?.declaration, status: 'REJEITADO' }
                }))
                toast.success('Reprovado com sucesso!')

                handleCloseAuditReject()
                handleCloseAudit()
              } catch (err: any) {
                toast.error(err?.response?.data?.message)
              } finally {
                setLoadingDeclaration(false)
              }
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
              <Form>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          errors.description && touched.description
                        )}
                        value={values.description}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={3}
                        autoComplete="off"
                        placeholder="Descreva"
                        helperText={
                          errors.description && touched.description
                            ? errors.description
                            : null
                        }
                        onChange={handleChange('description')}
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Stack
                  direction="row"
                  spacing={3}
                  paddingTop={2}
                  justifyContent="flex-end"
                >
                  <Button
                    onClick={handleCloseAuditReject}
                    type="button"
                    disabled={loadingDeclaration}
                  >
                    Cancelar
                  </Button>
                  <Button
                    mold="primary"
                    type="submit"
                    disabled={loadingDeclaration}
                  >
                    Enviar
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </ModalComponent>
    </>
  )
}

export default CustomerControlDocumentation
