import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import React, { useEffect } from 'react'
import { PurchaseOrderContainer } from './components/PurchaseOrderContainer'

const PurchaseOrderPage: React.FC = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Can
        module={MODULES.PEDIDOS}
        permissions={[PERMISSIONS.READ]}
      >
      <PurchaseOrderContainer />
    </Can>
  )
}

export default PurchaseOrderPage
