/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import StatusView from 'components/view/StatusView'
import MoreMenu from 'components/view/MoreMenu'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Icon,
  Switch,
  TablePagination
} from '@mui/material'
import { StyledTableCell, StyledTableRow } from './table.styles'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import {
  getAllFormulariosCaixa,
  getPortfolioBoxesForm,
  putStatusFormulariosCaixa,
  putStatusPortfolioBoxesForm
} from 'services/portfolio/PortfolioService'
import { useNavigate } from 'react-router-dom'

const TableBox = (props: any) => {
  const [page, setPage] = useState(0)

  const [originalData, setOriginalData] = React.useState<any[]>([])
  const [data, setData] = React.useState<any[]>([])

  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const portfolioReq = useQuery(
    'box-form',
    async () => {
      return getAllFormulariosCaixa()
    },
    {
      onSuccess: (data) => {
        setData(data)
        setOriginalData(data)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const { mutate: mutateStatus, isLoading: isLoadingDelete } = useMutation(
    putStatusFormulariosCaixa,
    {
      onSuccess: (data) => {
        portfolioReq.refetch()
      },
      onError: (e: any) => {
        toast.error('Não foi possível realizar essa alteração')
      },
      onSettled: () => {
        queryClient.invalidateQueries('boxes')
      }
    }
  )

  const handleStatus = (id) => {
    console.log('handle status', id)
    mutateStatus(id)
  }

  useEffect(() => {
    if (
      props?.search?.length) {
      const searchResult = originalData.filter((row) => {
        if (row?.specifications?.boxType?.name?.toUpperCase().includes(props?.search.toUpperCase())) {
          return true
        } else if (row?.specifications?.paperType?.name?.toUpperCase().includes(props?.search.toUpperCase())) {
          return true
        } else if (row?.specifications?.plateType?.name?.toUpperCase().includes(props?.search.toUpperCase())) {
          return true
        } else if (row?.specifications?.description?.toUpperCase().includes(props?.search.toUpperCase())) {
          return true
        } else if (
          row.reference.toUpperCase().includes(props?.search.toUpperCase())
        ) {
          return true
        }

        return false
      })

      setPage(0)
      setData(searchResult)
    } else {
      setData(originalData)
    }

    return () => {
      setData([])
      setPage(0)
    }
  }, [props?.search, originalData])

  const getPaper = (data: string[]) => {
    let paper = ''
    data?.forEach(item => {
      if (item?.length) {
        paper = `${paper}${item},`
      }
    })
    return paper.slice(0, -1)
  }

  return (
    <>
      {portfolioReq.isFetching
? (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )
: portfolioReq.isError
? (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>
            signal_wifi_statusbar_connected_no_internet_4
          </Icon>
        </Box>
      )
: (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Referência</StyledTableCell>
                <StyledTableCell align="left">Descrição</StyledTableCell>
                <StyledTableCell align="left">Tipo de caixa</StyledTableCell>
                <StyledTableCell align="left">Tipo de papel</StyledTableCell>
                <StyledTableCell align="left">Tipo de onda</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="right">Ações</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * 5, page * 5 + 5).map((row) => (
                <StyledTableRow key={row?._id}>
                  <StyledTableCell component="th" scope="row">
                    {row?.Referencia}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.Obs1}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.boxType?.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getPaper([
                    row?.Composicao?.papel1,
                    row?.Composicao?.papel2,
                    row?.Composicao?.papel3,
                    row?.Composicao?.papel4,
                    row?.Composicao?.papel5
                  ])}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.Composicao?.onda}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch checked={row?.isActive} />}
                        label={row?.isActive ? 'Ativo' : 'Inativo'}
                        onChange={() => handleStatus(row._id)}
                      />
                    </FormGroup>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <MoreMenu
                      edit={() =>
                        navigate('/home/portfolio/products/formCaixas', {
                          state: {
                            editable: true,
                            row: row
                          }
                        })
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5]}
            rowsPerPage={5}
            component="div"
            count={data.length}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        </TableContainer>
      )}
    </>
  )
}

export default TableBox
