import { Box, CircularProgress, Icon, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { Can } from 'components/Permission/Can'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import MoreMenu from 'components/view/MoreMenu'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { getFTsTrimbox } from 'services/ft/FTService'
import AuditOrdersProduction from './AuditOrdersProduction'

function TableOrdersProductions() {
  const [page, setPage] = React.useState(0)
  const [count, setCount] = React.useState(0)
  const [rows, setRows] = React.useState<any[]>([])
  const [codFT, setCodFT] = React.useState('')
  const [openDialog, setOpenDialog] = React.useState(false)

  const OPReq = useQuery(['orders-production', page],
    async () => {
      return getFTsTrimbox(page, 'Ativa', 'Ativa')
    },
    {
      onSuccess: (data) => {
        console.log('dados fts trimbox', data)
        setCount(data.totalElements)
        setRows(data.content)
      },
      refetchOnWindowFocus: false
    }
  )

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  if (OPReq.isFetching) {
    return (
      <Box sx={{ display: 'flex' }}
        style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
        <CircularProgress />
      </Box>
    )
  }

  if (OPReq.isError) {
    return (
      <Box sx={{ display: 'flex' }}
        style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
      </Box>
    )
  }

  return (
    <div>
      <TableContainer style={{ marginTop: '30px' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'>Código FT</StyledTableCell>
              <StyledTableCell align='left'>Emitido em</StyledTableCell>
              <StyledTableCell align='left'>Referência</StyledTableCell>
              <StyledTableCell align='left'>Status</StyledTableCell>
              <Can
                module={MODULES.FT}
                permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
              >
                <StyledTableCell align='left'>Ações</StyledTableCell>
              </Can>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.map(row =>
                <StyledTableRow key={0}>
                  <StyledTableCell align='left'>{row.codConjunto}</StyledTableCell>
                  <StyledTableCell align='left'>{moment(row.dataCriacao).format('DD/MM/YYYY \\à\\s HH:mm:ss')}</StyledTableCell>
                  <StyledTableCell align='left'>{row.referencia}</StyledTableCell>
                  <StyledTableCell align='left'>{row.statusFT}</StyledTableCell>
                  <Can
                    module={MODULES.FT}
                    permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                  >
                    <StyledTableCell align='left'>
                      <MoreMenu
                        audit={() => {
                          setCodFT(row.codConjunto)
                          setOpenDialog(true)
                        }} />
                    </StyledTableCell>
                  </Can>
                </StyledTableRow>
              )
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20]}
          rowsPerPage={20}
          component="div"
          count={count}
          page={page}
          onPageChange={(event: unknown, newPage: number) => { setPage(newPage) }}
        />
      </TableContainer>

      {openDialog && <GenericDialog
        widthRoot={900}
        open={openDialog}
        close={() => setOpenDialog(false)}
        content={<AuditOrdersProduction codFT={codFT} closeDialog={handleCloseDialog} />} />}
    </div>
  )
}

export default TableOrdersProductions
