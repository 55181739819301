import Swal from 'sweetalert2'
import './style.css'

const alertSwalCustom = (methodHandle?: () => void, title = 'Deseja mesmo realizar essa operação?') => {
  Swal.fire({
    title,
    customClass: {
      title: 'title_modal_cancel',
      popup: 'container_modal_cancel',
      confirmButton: 'button_modal_cancel yes',
      cancelButton: 'button_modal_cancel not'
    },
    showCancelButton: true,
    confirmButtonColor: '#4060E6',
    cancelButtonColor: '#EE207A',
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed && methodHandle) {
      methodHandle()
    }
  })
}

export default alertSwalCustom
