/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormatPrice } from 'utils/FormatPrice'
import { ReportSellerType } from '../types/reportSeller.type'
import { ReportSellerTableHead } from './ReportSellerTableHead'
import { StyledTableCell, StyledTableRow } from './table.styles'

interface Props {
  data: ReportSellerType[]
}

export const ReportSellerTable: React.FC<Props> = ({ data }) => {
  const [originalRows, setOriginalRows] = useState(data)
  const [rows, setRows] = useState(data)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState({
    pesoKg: 0,
    previsionPrice: 0,
    previsionPriceKg: 0,
    billed: 0,
    billedKg: 0,
    efficiency: 0,
    formattedPesoKg: '',
    formattedBilledKg: '',
    formattedPrevisionPrice: '',
    formattedPrevisionPriceKg: '',
    formattedBilled: '',
    formattedEfficiency: ''
  })

  const [search, setSearch] = useState({ seller: '', client: '' })

  useEffect(() => {
    setOriginalRows(data ?? [])
    setRows(data ?? [])
  }, [data?.length])

  useEffect(() => {
    function loadData() {
      if (search.seller?.length || search.client?.length) {
        const searchResult = originalRows.filter((row) => {
          if (
            row?.seller?.user?.name
              ?.toUpperCase()
              ?.includes(search.seller.toUpperCase()) &&
            row?.client?.name
              ?.toUpperCase()
              ?.includes(search.client.toUpperCase())
          ) {
            return true
          }

          return false
        })

        setPage(0)
        setRows(searchResult)

        setTotal(
          searchResult.reduce(
            (total, row) => {
              const pesoKg = Number(total.pesoKg) + Number(row?.pesoKg || 0)
              const previsionPrice =
                Number(total.previsionPrice) + Number(row?.previsionPrice || 0)
              const previsionPriceKg =
                Number(total.previsionPriceKg) +
                  Number(row?.previsionPriceKg) || 0
              const billed = Number(total.billed) + Number(row?.billed || 0)
              const billedKg = Number(total.billedKg) + Number(row?.billedKg || 0)
              const efficiency = billedKg / (previsionPriceKg / 100)

              return {
                formattedPesoKg: (
                  Number(total.pesoKg) + Number(row?.pesoKg)
                )?.toLocaleString('pt-BR'),
                formattedBilledKg: (
                  Number(total.billedKg) + Number(row?.billedKg)
                )?.toLocaleString('pt-BR'),
                formattedPrevisionPrice: FormatPrice(previsionPrice),
                formattedPrevisionPriceKg: FormatPrice(previsionPriceKg),
                formattedBilled: FormatPrice(billed),
                formattedEfficiency: `${Math.round(efficiency)}%`,
                pesoKg,
                previsionPrice,
                previsionPriceKg,
                billed,
                billedKg,
                efficiency
              }
            },
            {
              pesoKg: 0,
              previsionPrice: 0,
              previsionPriceKg: 0,
              billed: 0,
              billedKg: 0,
              efficiency: 0,
              formattedPesoKg: '',
              formattedBilledKg: '',
              formattedPrevisionPrice: '',
              formattedPrevisionPriceKg: '',
              formattedBilled: '',
              formattedEfficiency: ''
            }
          )
        )
      } else {
        setRows(originalRows)
        setPage(0)

        setTotal(
          originalRows.reduce(
            (total, row) => {
              const pesoKg = Number(total.pesoKg) + Number(row?.pesoKg || 0)
              const previsionPrice =
                Number(total.previsionPrice) + Number(row?.previsionPrice || 0)
              const previsionPriceKg =
                Number(total.previsionPriceKg) +
                  Number(row?.previsionPriceKg) || 0
              const billed = Number(total.billed) + Number(row?.billed || 0)
              const billedKg = Number(total.billedKg) + Number(row?.billedKg || 0)
              const efficiency = billedKg / (pesoKg / 100)

              return {
                formattedPesoKg: (
                  Number(total.pesoKg) + Number(row?.pesoKg)
                )?.toLocaleString('pt-BR'),
                formattedBilledKg: (
                  Number(total.billedKg) + Number(row?.billedKg)
                )?.toLocaleString('pt-BR'),
                formattedPrevisionPrice: FormatPrice(previsionPrice),
                formattedPrevisionPriceKg: FormatPrice(previsionPriceKg),
                formattedBilled: FormatPrice(billed),
                formattedEfficiency: `${Math.round(efficiency)}%`,
                pesoKg,
                previsionPrice,
                previsionPriceKg,
                billed,
                billedKg,
                efficiency
              }
            },
            {
              pesoKg: 0,
              previsionPrice: 0,
              previsionPriceKg: 0,
              billed: 0,
              billedKg: 0,
              efficiency: 0,
              formattedPesoKg: '',
              formattedBilledKg: '',
              formattedPrevisionPrice: '',
              formattedPrevisionPriceKg: '',
              formattedBilled: '',
              formattedEfficiency: ''
            }
          )
        )
      }
    }

    loadData()

    return () => {
      setRows([])
      setPage(0)
    }
  }, [search, originalRows])

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <ReportSellerTableHead
            countClients={rows?.length}
            total={total}
            searchSeller={(value) => setSearch({ ...search, seller: value })}
            searchClient={(value) => setSearch({ ...search, client: value })}
          />

          <TableBody>
            {rows?.slice(page * 5, page * 5 + 5)?.map((row, index) => (
              <StyledTableRow key={`${row?._id}-${row?.ies?.codeLn}`}>
                <StyledTableCell align="left">
                  {row?.seller?.user?.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography component="span" variant="body2">
                    Cod Parceiro: {row?.ies?.codeLn}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ display: 'flex' }}
                  >
                    {row?.client?.name}
                  </Typography>
                </StyledTableCell>
                {/* <StyledTableCell align="left">
                  <Typography component="span" variant="body2" noWrap>
                    {row?.formattedPrevisionPrice}
                  </Typography>
                </StyledTableCell> */}
                <StyledTableCell align="left">
                  {row?.formattedPesoKg}
                </StyledTableCell>
                {/* <StyledTableCell align="left">
                  <Typography component="span" variant="body2" noWrap>
                    {row?.formattedPrevisionPriceKg}
                  </Typography>
                </StyledTableCell> */}
                {/* <StyledTableCell align="left">
                  <Typography component="span" variant="body2" noWrap>
                    {row?.formattedBilled}
                  </Typography>
                </StyledTableCell> */}
                <StyledTableCell align="left">
                  <Typography component="span" variant="body2" noWrap>
                    {row?.formattedBilledKg}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row?.formattedEfficiency}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <StyledTableCell
                align="left"
                sx={{ fontWeight: 'bold', backgroundColor: '#EBEBEB' }}
              >
                TOTAL
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ fontWeight: 'bold', backgroundColor: '#EBEBEB' }}
              >
                {rows?.length} Clientes
              </StyledTableCell>
              {/* <StyledTableCell
                align="left"
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#EBEBEB'
                }}
              >
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="bold"
                  noWrap
                >
                  {total?.formattedPrevisionPrice}
                </Typography>
              </StyledTableCell> */}
              <StyledTableCell
                align="left"
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#EBEBEB'
                }}
              >
                {total?.formattedPesoKg}
              </StyledTableCell>
              {/* <StyledTableCell
                align="left"
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: '#EBEBEB'
                }}
              >
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="bold"
                  noWrap
                >
                  {total?.formattedPrevisionPriceKg}
                </Typography>
              </StyledTableCell> */}
              <StyledTableCell
                align="left"
                sx={{ fontWeight: 'bold', backgroundColor: '#EBEBEB' }}
              >
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="bold"
                  noWrap
                >
                  {total?.formattedBilledKg}
                </Typography>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ fontWeight: 'bold', backgroundColor: '#EBEBEB' }}
              >
                {total?.formattedEfficiency}
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5]}
          rowsPerPage={5}
          component="div"
          count={rows.length}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      </TableContainer>
    </>
  )
}
