/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  CircularProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { getReportPedidosDiaKg } from 'services/report/ReportMonthlyService'
import { StandardFilter } from 'utils/StandardFilter'
import { formatValue } from 'utils/formatString'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

function PedidosKgView(props: any) {
  const [originalData, setOriginalData] = React.useState([])
  const [clientKgData, setClientKgData] = React.useState([])
  const [valuesKgData, setValuesKgData] = React.useState<any[]>([])
  const [monthDays, setMonthDays] = React.useState<String[]>([])
  const [filters, setFilters] = React.useState<any>({})
  const [page, setPage] = React.useState(0)
  const paginationSize = 10

  React.useEffect(() => {
    const days: String[] = []
    for (let i = 1; i <= Number(moment().endOf('month').format('DD')); i++) {
      days.push(i < 10 ? `0${i}` : `${i}`)
    }
    setMonthDays(days)
  }, [props.date])

  React.useEffect(() => {
    StandardFilter(filters, setClientKgData, originalData)
  }, [filters])

  const pedidosKgReq = useQuery(
    ['PedidosKgReq', props.date],
    async () => {
      return getReportPedidosDiaKg(moment(props.date).format('DD/MM/YYYY'), props.type)
    },
    {
      onSuccess: (data) => {
        parseTableData(data?.result ?? [])
        setValuesKgData(data?.result ?? [])
        console.log('valuesKgData', data?.result ?? [])
      },
      onError: (error) => {
        console.log(
          'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
          error
        )
      },
      refetchOnWindowFocus: false
    }
  )

  const parseTableData = (data) => {
    console.log('pedidos kg data', data)
    const clients = data
      .map((m) => m.ordemParceiroNome)
      .filter((f, i) => data.map((m) => m.ordemParceiroNome).indexOf(f) === i)
      .sort((a, b) => a - b)

    const clientData = clients.map(client => ({ nameClient: client }))
    setOriginalData(clientData)
    setClientKgData(clientData)
  }

  const getClientDataValue = (client, date): number => {
    const filteredData = valuesKgData.filter(
      (item: any) =>
        item.ordemParceiroNome === client &&
        moment(item.ordemData, 'DD/MM/YYYY').format('DD') <= date
    )

    if (filteredData.length > 0) {
      return filteredData.reduce(
        (total: number, item: any) => total + Number(item.peso),
        0
      )
    }

    return 0
  }

  const getClientDataValueTotal = (date): number => {
    const filteredData = valuesKgData.filter(
      (item: any) => moment(item.ordemData, 'DD/MM/YYYY').format('DD') <= date
    )
    if (filteredData.length > 0) {
      return filteredData.reduce(
        (total: number, item: any) => total + Number(item.peso),
        0
      )
    }
    return 0
  }

  if (pedidosKgReq.isFetching) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div>

      <TableContainer style={{ marginTop: '30px', width: '80vw', margin: '0 auto' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: '500px', position: 'sticky', left: 0, zIndex: 9, background: '#fafafa !important' }}>
                <TextField
                  sx={{ minWidth: '200px' }}
                  id="standard-basic"
                  label="Pesquisar cliente"
                  autoComplete='off'
                  variant="standard"
                  onChange={(e) => {
                    setFilters({ ...filters, nameClient: e.target.value })
                    setPage(0)
                  }}
                />
              </StyledTableCell>
              {monthDays.map((d) => (
                <StyledTableCell align="center" sx={{ position: 'sticky', left: 0, zIndex: 8, background: '#fafafa !important' }}>
                  {`${d}`}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clientKgData?.slice(page * paginationSize, page * paginationSize + paginationSize)?.map((row: any, index) => (
              <StyledTableRow key={index}>
                <Box style={{ width: '325px', position: 'sticky', left: 0, zIndex: 10, background: '#FFF' }}>
                  <StyledTableCell align="left" sx={{ width: 'inherit' }}>
                    {row.nameClient}
                  </StyledTableCell>
                </Box>
                {monthDays.map((d) => (
                  <StyledTableCell align="center">
                    {`${getClientDataValue(row.nameClient, d) > 0
                      ? formatValue(getClientDataValue(row.nameClient, d))
                      : '-'
                      }`}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <Box style={{ width: '325px', position: 'sticky', left: 0, zIndex: 10, background: '#FFF' }}>
                <StyledTableCell align="left" sx={{ width: 'inherit' }}>
                  <b>Total do dia</b>
                </StyledTableCell>
              </Box>
              {monthDays.map((d) => (
                <StyledTableCell align="center">
                  {formatValue(getClientDataValueTotal(d))}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ width: '80vw', margin: '5px auto' }}>
        <TablePagination
          sx={{ minWidth: '195px', float: 'right' }}
          rowsPerPageOptions={[paginationSize]}
          rowsPerPage={paginationSize}
          component={Paper}
          count={clientKgData.length}
          page={page}
          onPageChange={(event: unknown, newPage: number) => { setPage(newPage) }}
        />
      </div>
      {
        /*
      <TableContainer sx={{ marginTop: '30px', maxHeight: 440, maxWidth: '1200px' }} component={Paper}>
        <Table sx={{ width: '100%' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ position: 'sticky', left: 0, zIndex: 9, background: '#fafafa !important' }}>
                Cliente
              </StyledTableCell>
              {monthDays.map((d) => (
                <StyledTableCell align="center" sx={{ position: 'sticky', left: 0, zIndex: 8, background: '#fafafa !important' }}>
                  {`${d}`}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clientKgData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="left" sx={{ position: 'sticky', left: 0, zIndex: 2, background: '#FFF' }}>
                  {row}
                </StyledTableCell>
                {monthDays.map((d) => (
                  <StyledTableCell align="center">
                    {`${getClientDateValue(row, d) > 0
                      ? Number(getClientDateValue(row, d)).toLocaleString(
                        'pt-br'
                      )
                      : '-'
                      }`}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      */
      }
    </div>
  )
}

export default PedidosKgView
