/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { httpClient } from 'config/httpClient'
import { AltersType, NotificationsType } from 'types/alerts/AlertsType'
import { toast } from 'react-toastify'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { AlertContainer } from './components/AlertContainer'

const Alerts: React.FC = () => {
  const [selectProfile, setSelectProfile] = useState<string>('')
  const [errorProfile, setErrorProfile] = useState(false)
  const [profileTypes, setProfileTypes] = useState([])
  const [alerts, setAlerts] = useState<AltersType[]>([])
  const [notifications, setNotifications] = useState<NotificationsType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedAll, setSelectedAll] = useState<boolean>(false)

  const loadData = useCallback(() => {
    setSelectProfile('')
    setLoading(true)
    void Promise.all([
      httpClient.get('/profiles'),
      httpClient.get('/alerts'),
      httpClient.get('/notifications')
    ])
      .then((values) => {
        setProfileTypes(values?.[0]?.data ?? [])
        setAlerts(values?.[1]?.data ?? [])
        setNotifications(values?.[2]?.data ?? [])
      })
      .catch((error) => {
        console.log(error?.response?.data?.message)
        toast.error(error.response?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleChangeAlertAndNotification = useCallback((profileId: string) => {
    setLoading(true)
    void Promise.all([
      httpClient.get(`/communications/alerts/${profileId}`),
      httpClient.get(`/communications/notifications/${profileId}`)
    ])
      .then((values) => {
        console.log(values)
        setSelectedAll(true)
        setAlerts((state) =>
          state.map((item) => {
            const findItemResponse = values?.[0]?.data?.find(
              (itemResponse) => itemResponse?.alert?._id === item?._id
            )

            if (!findItemResponse) {
              setSelectedAll(false)
              return { ...item, checked: false, profileAlertId: undefined }
            }

            return {
              ...item,
              checked: true,
              profileAlertId: findItemResponse?._id
            }
          })
        )
        setNotifications((state) =>
          state.map((item) => {
            const findItemResponse = values?.[1]?.data?.find(
              (itemResponse) => itemResponse?.notification?._id === item?._id
            )

            if (!findItemResponse) {
              return {
                ...item,
                checked: false,
                profileNotificationId: undefined
              }
            }

            return {
              ...item,
              checked: true,
              profileNotificationId: findItemResponse?._id
            }
          })
        )
      })
      .catch((error) => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleToggleNotification = async (
    isChecked = false,
    notification: string,
    profile: string,
    profileNotificationId?: string
  ) => {
    if (!profile?.length) {
      setErrorProfile(true)
      return
    }

    try {
      if (profileNotificationId) {
        await httpClient.delete(
          `/communications/notifications/${profileNotificationId}`
        )

        setNotifications((state) =>
          state.map((item) => {
            if (item?._id === notification) {
              return {
                ...item,
                checked: false,
                profileNotificationId: undefined
              }
            }

            return item
          })
        )
      }

      if (!isChecked && !profileNotificationId) {
        const { data } = await httpClient.post(
          '/communications/notifications',
          {
            profile,
            notification
          }
        )

        setNotifications((state) =>
          state.map((item) => {
            if (item?._id === notification) {
              return {
                ...item,
                checked: true,
                profileNotificationId: data?._id
              }
            }

            return item
          })
        )
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message)
    }
  }

  const handleToggleAlert = async (
    isChecked = false,
    alert: string,
    profile: string,
    profileAlertId?: string
  ) => {
    if (!profile?.length) {
      setErrorProfile(true)
      return
    }

    try {
      if (profileAlertId) {
        await httpClient.delete(`/communications/alerts/${profileAlertId}`)

        setAlerts((state) =>
          state.map((item) => {
            if (item?._id === alert) {
              return {
                ...item,
                checked: false,
                profileAlertId: undefined
              }
            }

            return item
          })
        )
      }

      if (!isChecked && !profileAlertId) {
        const { data } = await httpClient.post('/communications/alerts', {
          profile,
          alert
        })

        setAlerts((state) =>
          state.map((item) => {
            if (item?._id === alert) {
              return {
                ...item,
                checked: true,
                profileAlertId: data?._id
              }
            }

            return item
          })
        )
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message)
    }
  }

  const handleToggleAllAlert = (checked: boolean) => {
    alerts?.forEach((item) => {
      handleToggleAlert(checked,
        item._id,
        selectProfile,
        item?.profileAlertId)
    })
  }

  return (
    <Can
      module={MODULES.ALERTAS}
      permissions={[PERMISSIONS.READ, PERMISSIONS.UPDATE]}
      every
    >
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            component="strong"
            variant="body1"
            fontWeight="bold"
            sx={{ marginBottom: 3 }}
          >
            Selecione o tipo de perfil para configurar a visualização de alertas
          </Typography>

          <FormControl
            variant="standard"
            sx={{ width: '25%' }}
            error={errorProfile}
          >
            <InputLabel id="select-profile-label">Perfil</InputLabel>
            <Select
              labelId="select-profile-label"
              id="select-profile"
              label="Profile"
              value={selectProfile}
              onChange={(event: any) => {
                setSelectProfile(event.target.value)
                void handleChangeAlertAndNotification(event.target.value)
              }}
            >
              {profileTypes.map((item: any) => {
                return item?.status && (
                  <MenuItem
                    key={item?._id}
                    value={item._id}
                    // onClick={() => changeValueProfile(item)}
                  >
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>
              {errorProfile ? 'Selecione um perfil' : ''}
            </FormHelperText>
          </FormControl>

          {selectProfile && <AlertContainer isLoading={loading}>
            <Typography
              component="strong"
              variant="body1"
              fontWeight="bold"
              sx={{ marginTop: 4 }}
            >
              Selecione como e quais as notificações que este perfil pode
              visualizar
            </Typography>

            <Stack direction="row" spacing={3} marginTop={3}>
              <Card sx={{ width: '100%', height: 'auto' }}>
                <CardHeader
                  title={
                    <Typography component="strong" sx={{ color: '#FFF' }}>
                      Como deseja receber as notificações?
                    </Typography>
                  }
                  style={{ background: '#4060E6', height: '39px' }}
                />
                <CardContent>
                  <Grid container>
                    {notifications.map((item: NotificationsType, i) => (
                      <Grid key={item?._id} item md={notifications.length > 7 ? 6 : 12}>
                        <FormGroup style={{ marginLeft: '28px' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item?.checked ?? false}
                                onClick={async () =>
                                  handleToggleNotification(
                                    item?.checked,
                                    item._id,
                                    selectProfile,
                                    item?.profileNotificationId
                                  )
                                }
                              />
                            }
                            label={item.name}
                          />
                        </FormGroup>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ width: '100%', height: 'auto' }}>
                <CardHeader
                  title={
                    <Typography component="strong" sx={{ color: '#FFF' }}>
                      Quais alertas deseja receber?
                    </Typography>
                  }
                  style={{ background: '#4060E6', height: '39px' }}
                />
                <CardContent>
                  <Grid container>
                    {alerts.map((item: AltersType, i) => (
                      <Grid key={item?._id} item md={alerts.length > 7 ? 6 : 12}>
                        <FormGroup style={{ marginLeft: '28px' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item?.checked ?? false}
                                onClick={async () =>
                                  handleToggleAlert(
                                    item?.checked,
                                    item._id,
                                    selectProfile,
                                    item?.profileAlertId
                                  )
                                }
                              />
                            }
                            label={item.name}
                          />
                        </FormGroup>
                      </Grid>
                    ))}

                    <Grid item md={12}>
                      <FormGroup style={{ marginLeft: '28px', borderTop: '1px solid #ccc' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedAll ?? false}
                              onClick={async () => {
                                setSelectedAll(!selectedAll)
                                handleToggleAllAlert(selectedAll)
                                // handleToggleNotification(
                                //   item?.checked,
                                //   item._id,
                                //   selectProfile,
                                //   item?.profileNotificationId
                                // )
                              }}
                            />
                          }
                          label='Todos'
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Stack>
          </AlertContainer>}
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              style={{
                backgroundColor: '#4060E6',
                borderRadius: '2px',
                width: '122px',
                color: 'white'
              }}
              type="submit"
            >
              {loading ? 'CARREGANDO...' : 'SALVAR'}
            </Button>
          </div> */}
        </Box>
      </>
    </Can>
  )
}

export default Alerts
