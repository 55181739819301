import * as React from 'react'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { Container, FormControl, Input, InputLabel, Snackbar } from '@mui/material'
import { useState } from 'react'
import { httpClient } from '../../../config/httpClient'
import { useDispatch } from 'react-redux'
import { _changeLogin } from 'pages/login/slices/login.slice'
import jwtDecode from 'jwt-decode'

export interface SimpleDialogProps {
  open: boolean
  email: string
  onClose: () => void
  onConfirm: (result: boolean) => void
}

export default function ConfirmCodeDialog(props: SimpleDialogProps) {
  const dispatch = useDispatch()

  const [openSnack, setOpenSnack] = useState(false)
  const [code, setCode] = useState('')

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  const handleConfirmCode = async () => {
    try {
      const responseConfirmCode = await httpClient.post('auth/verify', {
        email: props.email,
        code: code
      })
      props.onConfirm(true)
      const tokenData: any = jwtDecode(responseConfirmCode.data.token)
      dispatch(_changeLogin({ tokenData: tokenData, accessToken: responseConfirmCode.data.token, logged: false, email: '', picture: '' }))
    } catch (e) {
      console.log('E', e)
      setOpenSnack(true)
    }
  }

  const handleChangeCode = (e) => {
    console.log('E', e.target.value)
    setCode(e.target.value)
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Digite o código recebido no seu email</DialogTitle>
      <Container style={{ width: '400px', marginBottom: '30px', display: 'flex', justifyContent: 'right', flexDirection: 'column' }}>
        {/* <div>Por favor informe uma nova senha</div> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>Email: {props.email}</div>
          <FormControl variant='standard' style={{ marginTop: '10px' }}>
            <InputLabel htmlFor='standard-adornment-password'>Código de confirmação</InputLabel>
            <Input
              id='standard-adornment-password'
              value={code}
              onChange={handleChangeCode}
            />
          </FormControl>
        </div>
        <Button onClick={async () => await handleConfirmCode()} variant='contained' disableElevation style={{ backgroundColor: '#4060E6', marginTop: '20px', alignSelf: 'flex-end' }}>
          Confirmar
        </Button>

      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        message='Código inválido'
        key='top center'
      />
    </Dialog>
  )
}
