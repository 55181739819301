import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
// import alertSwalCancel from 'components/Alerts/ModalCancel'
import GenericDialog from 'components/genericDialog/GenericDialog'
import IconAndTitleView from 'components/view/IconAndTitleView'
import StatusView from 'components/view/StatusViewFT'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { putApprovePreFT, putPreFTBudgetData } from 'services/ft/FTService'
import styled from 'styled-components'
import FTDisapprove from './FTDisapprove'
import iconPdf from 'assets/svgs/icon-pdf.svg'

function FTAuditBox(props: any) {
  console.log('form', props.form)
  const colorPrimary = '#5f7aea'
  const colorWarning = '#ce6c2b'
  const colorError = '#EE207A'
  const colorSuccess = '#72CE2B'
  const color =
    props.form.status === 'NOVO'
      ? colorPrimary
      : props.form.status === 'CORRIGIDO' || props.form.status === 'ANÁLISE'
      ? colorWarning
      : props.form.status === 'APROVADO'
      ? colorSuccess
      : colorError

  const RetangleView = styled.div`
    width: 25px;
    height: 60px;
    background-color: ${color};
  `

  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const [budgetData, setBudgetData] = React.useState<any>({
    minimumLotReference: 1500,
    destinationCity: 'Manaus',
    cliche: false,
    knife: false
  })

  const handleCloseDialog = () => {
    setOpenDialog(false)
    props.closeDialog()
  }

  const handleCancellDialog = () => {
    setOpenDialog(false)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { refetch: refetchApprove, isFetching: isFetchingApprove } = useQuery(
    'approve-ft-plate',
    async () => {
      return putApprovePreFT(props.form._id)
    },
    {
      onError: (error: any) => {
        console.log(error)
        toast.error(
          error?.response?.data?.message ??
            'Não foi possível realizar essa operação'
        )
      },
      onSuccess: (data) => {
        try {
          console.log('successo', data)
          props.closeDialog()
        } catch (e) {
          toast.error(e?.response?.data?.message)
          console.log('success error', e)
        }
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const { refetch: saveBudgetData, isFetching: isFetchingBudgetData } =
    useQuery(
      'save-ft-box',
      async () => {
        return putPreFTBudgetData(props.form._id, budgetData)
      },
      {
        onError: (error: any) => {
          console.log(error)
          toast.error(
            error?.response?.data?.message ??
              'Não foi possível realizar essa operação'
          )
        },
        onSuccess: (data) => {
          try {
            console.log('successo', data)
            // props.closeDialog()
            refetchApprove()
          } catch (e) {
            toast.error(e?.response?.data?.message)
            console.log('success error', e)
          }
        },
        refetchOnWindowFocus: false,
        enabled: false
      }
    )

  const handleApprove = () => {
    // refetchApprove()
    saveBudgetData()
  }

  if (isFetchingApprove || isFetchingBudgetData) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  const getPaper = (data: string[]) => {
    let paper = ''
    data?.forEach((item) => {
      if (item?.length) {
        paper = `${paper}${item},`
      }
    })
    return paper.slice(0, -1)
  }

  return (
    <Box>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <RetangleView />
        <Card style={{ display: 'flex', width: '100%', paddingBottom: '0px' }}>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '0px',
              paddingRight: '0px'
            }}
          >
            <div style={{ marginRight: '38px', fontWeight: '600' }}>
              {props.form.status}
            </div>
            <IconAndTitleView icon="person_outline" title="Emitido por: -" />
            <IconAndTitleView
              icon="calendar_today"
              title={moment(props.form.createdAt).format('DD/MM/YYYY')}
            />
            <IconAndTitleView
              icon="schedule"
              title={moment(props.form.createdAt).format('HH:mm')}
            />
            <IconAndTitleView
              icon="assignment_outline"
              title={`FT ${props.form._id.substring(0, 8)}`}
            />
            <IconAndTitleView
              icon="content_paste"
              title={`Produto: ${props.form.type}`}
            />
            <IconAndTitleView icon="person_outline" title="Para: -" />
          </CardContent>
        </Card>
      </div>

      <div style={{ marginTop: '16px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Status da solicitação
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <StatusView
            disabled={props.form.status !== 'NOVO'}
            style={{ marginRight: '14px' }}
            size="small"
            status="NOVO"
          />
          <StatusView
            disabled={props.form.status !== 'CORRIGIDO'}
            style={{ marginRight: '14px' }}
            size="small"
            status="CORRIGIDO"
          />
          <StatusView
            disabled={props.form.status !== 'APROVADO'}
            style={{ marginRight: '14px' }}
            size="small"
            status="APROVADO"
          />
          <StatusView
            disabled={props.form.status !== 'REPROVADO'}
            size="small"
            status="REPROVADO"
          />
        </div>
      </div>

      {!!props?.form?.descriptionFail?.length &&
        props.form.status === 'REPROVADO' && (
          <div
            style={{
              marginTop: '24px',
              backgroundColor: '#ffddec',
              padding: '10px',
              borderRadius: '12px'
            }}
          >
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Motivo da reprovação:
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '5px'
              }}
            >
              <div style={{ fontSize: '16px' }}>
                {props.form.descriptionFail}
              </div>
            </div>
          </div>
        )}

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Dados da Solicitação - Caixa
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Número da FT Amostra"
                variant="standard"
                defaultValue={`FT ${props.form._id.substring(0, 8)}`}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Nome da Empresa"
                variant="standard"
                defaultValue={props.form.client?.name ?? ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Emitido Por"
                variant="standard"
                defaultValue={props.form.collaborator?.name ?? ''}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Referência</div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={12}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label=""
                variant="standard"
                defaultValue={props.form?.preftbox?.specifications?.description ?? null}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Planejamento de P&D
        </div>

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="preftbox-paper">
                  Papel
                </FormLabel>
                <RadioGroup
                  aria-labelledby="preftbox-paper"
                  defaultValue={props?.form?.preftbox?.paper}
                  name="preftbox-paper"
                >
                  <FormControlLabel
                    value="Kraft/Kraft"
                    control={<Radio />}
                    label="Kraft/Kraft"
                    disabled
                  />
                  <FormControlLabel
                    value="Kraft/Testline"
                    control={<Radio />}
                    label="Kraft/Testline"
                    disabled
                  />
                  <FormControlLabel
                    value="Testline/Testline"
                    control={<Radio />}
                    label="Testline/Testline"
                    disabled
                  />
                  <FormControlLabel
                    value="Testline/Miolo"
                    control={<Radio />}
                    label="Testline/Miolo"
                    disabled
                  />
                  <FormControlLabel
                    value="Miolo/Miolo"
                    control={<Radio />}
                    label="Miolo/Miolo"
                    disabled
                  />
                  <FormControlLabel
                    value="Branco/testline"
                    control={<Radio />}
                    label="Branco/testline"
                    disabled
                  />
                  <FormControlLabel
                    value="Branco/Branco"
                    control={<Radio />}
                    label="Branco/Branco"
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            pointerEvents: 'none'
          }}
        >
          <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
            Definição
          </div>
          <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
            <Grid item xs={12}>
              <Stack>
                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        props.form.preftbox.planningRD.definition.justToDo
                      }
                    />
                  }
                  label="Item apenas para desenvolver"
                  disabled
                />

                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        props.form.preftbox.planningRD.definition.projectAndDo
                      }
                    />
                  }
                  label="Item para projetar e desenvolver"
                  disabled
                />

                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        props.form.preftbox.planningRD.definition
                          .withSimiliarProject
                      }
                    />
                  }
                  label="Item com projetos similares"
                  disabled
                />
              </Stack>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            pointerEvents: 'none'
          }}
        >
          <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
            Novo produto
          </div>
          <Grid
            container
            spacing={3}
            mt={0}
            style={{ width: '1000px', marginBottom: -15 }}
          >
            <Grid item xs={12}>
              <Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.form.preftbox.planningRD.newProduct.basedFTD
                      }
                    />
                  }
                  label="Baseado na FTD"
                />
                {props.form.preftbox.planningRD.newProduct.basedFTD && (
                  <Box>
                    <TextField
                      disabled
                      id="standard-basic"
                      label=""
                      variant="standard"
                      defaultValue={
                        props.form.preftbox.planningRD.newProduct?.basedFTDValue
                      }
                    />
                  </Box>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.form.preftbox.planningRD.newProduct
                          .drawingOrSpecification
                      }
                    />
                  }
                  label="Desenho/Especificação"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.form.preftbox.planningRD.newProduct
                          .mechanicalSample
                      }
                    />
                  }
                  label="Amostra Mecânica"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.form.preftbox.planningRD.newProduct
                          .productInCustomer
                      }
                    />
                  }
                  label="Produto do cliente"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.form.preftbox.planningRD.newProduct.similarProduct
                      }
                    />
                  }
                  label="Projeto semelhante"
                />

                <Box>
                  <TextField
                    disabled
                    id="standard-basic"
                    label="Outros"
                    variant="standard"
                    defaultValue={
                      props.form.preftbox.planningRD.newProduct.other
                    }
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px', paddingTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Especificação
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Código / Referência"
                variant="standard"
                value={
                  props.form.preftbox.reference
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Composição"
                variant="standard"
                value={
                  props.form.preftbox?.specifications?.Composicao?.composicao
                }
              />
            </Grid>
            {/* <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Tipo de caixa"
                variant="standard"
                defaultValue={
                  props.form.preftbox.specifications.boxType?.name ?? ''
                }
              />
            </Grid> */}
            <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Cod. Caixa"
                variant="standard"
                value={
                  props.form.preftbox?.specifications?.codTypebox?.cod ?? ''
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Papel"
                variant="standard"
                value={
                  getPaper([
                    props.form?.preftbox?.specifications?.Composicao?.papel1,
                    props.form?.preftbox?.specifications?.Composicao?.papel2,
                    props.form?.preftbox?.specifications?.Composicao?.papel3,
                    props.form?.preftbox?.specifications?.Composicao?.papel4,
                    props.form?.preftbox?.specifications?.Composicao?.papel5
                  ]) ?? ''
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Tipo de onda"
                variant="standard"
                value={
                  props.form.preftbox?.specifications?.Composicao?.onda ?? ''
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Nome Comercial"
                variant="standard"
                value={
                  props.form.preftbox?.specifications?.Composicao
                    ?.nomeComercial ?? ''
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Medidas internas
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Comprimento"
                variant="standard"
                defaultValue={props.form.preftbox.internalMeasures.diameter}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Largura"
                variant="standard"
                defaultValue={props.form.preftbox.internalMeasures.width}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Altura"
                variant="standard"
                defaultValue={props.form.preftbox.internalMeasures.height}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Diâmetro"
                variant="standard"
                defaultValue={props.form.preftbox.internalMeasures.diameter}
              />
            </Grid> */}
            {/* <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Peso (Kg)"
                variant="standard"
                defaultValue={props.form.preftbox.internalMeasures.weight ?? 0}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Fechamento"
                variant="standard"
                defaultValue={
                  props.form.preftbox.internalMeasures?.Fechamento?.fechamento
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Qtd de Impressões"
                variant="standard"
                defaultValue={props.form.preftbox.internalMeasures.prints}
              />
            </Grid>
          </Grid>
        </div>
        <TextField
          id="outlined-multiline-static"
          label="Dados adicionais"
          style={{ marginTop: 20, maxWidth: 800, pointerEvents: 'none' }}
          fullWidth
          multiline
          defaultValue={props.form.preftbox.extraData}
          rows={3}
        />
      </div>

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Gramatura Capa Interna
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            pointerEvents: 'none'
          }}
        >
          <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
            Amarrado ou arrumado no palete
          </div>
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={props.form.preftbox.tied} />}
                label="Amarrado"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={props.form.preftbox.tiedPallet} />}
                label="Arrumado no palete"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Pçs/Amarrado"
                variant="standard"
                defaultValue={props.form.preftbox.piecesOrTied}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Amarrado/Palete"
                variant="standard"
                defaultValue={props.form.preftbox.tiedOrPallet}
              />
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            pointerEvents: 'none'
          }}
        >
          <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
            Alças
          </div>
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={props.form.preftbox.handles} />}
                label="Sim"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={!props.form.preftbox.handles} />}
                label="Não"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Alças por caixa"
                variant="standard"
                defaultValue={props.form.preftbox.handlesValue}
              />
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            pointerEvents: 'none'
          }}
        >
          <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
            Furo
          </div>
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={props.form.preftbox.hole} />}
                label="Sim"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={!props.form.preftbox.hole} />}
                label="Não"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Furos por caixa"
                variant="standard"
                defaultValue={props.form.preftbox.holeValue}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Informações adicionais
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            pointerEvents: 'none'
          }}
        >
          <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
            Palete reforçado
          </div>
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Radio checked={props.form.preftbox.reinforcedPallet} />
                }
                label="Sim"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Radio checked={!props.form.preftbox.reinforcedPallet} />
                }
                label="Não"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Qtd de Fita"
                variant="standard"
                defaultValue={props.form.preftbox.amountTape}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="Qtd lastro do palete"
                variant="standard"
                defaultValue={props.form.preftbox.amountPalletBallast}
              />
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            pointerEvents: 'none'
          }}
        >
          <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
            Paletizado/Batido
          </div>
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Radio checked={props.form.preftbox.beatenOrPalletized} />
                }
                label="Paletizado"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Radio checked={!props.form.preftbox.beatenOrPalletized} />
                }
                label="Batido"
              />
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            pointerEvents: 'none'
          }}
        >
          <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
            Tara
          </div>
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={props.form.preftbox.tare} />}
                label="Sim"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={!props.form.preftbox.tare} />}
                label="Não"
              />
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            pointerEvents: 'none'
          }}
        >
          <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
            Laudo NF
          </div>
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={props.form.preftbox.reportNF} />}
                label="Sim"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={!props.form.preftbox.reportNF} />}
                label="Não"
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <div
          style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: -15 }}
        >
          Amostra
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            pointerEvents: 'none'
          }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={props.form.preftbox.needSample} />}
                label="Sim"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Radio checked={!props.form.preftbox.needSample} />}
                label="Não"
              />
            </Grid>
          </Grid>
        </div>
      </div>

      {props.form.preftbox.needSample && (
        <TextField
          id="outlined-multiline-static"
          label="Quantidade de amostra"
          style={{ marginTop: 20, maxWidth: 800, pointerEvents: 'none' }}
          fullWidth
          multiline
          defaultValue={props.form.preftbox.amountSample}
          rows={3}
        />
      )}

      <div style={{ marginTop: '24px' }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Quantidade</div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label=""
                variant="standard"
                defaultValue={props.form.qtd ?? 0}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >
              Arquivo Anexado
            </div>

            {!props.form?.attachments?.length &&
              !props.form?.attachment?.url && (
                <Typography>Nenhum arquivo anexado</Typography>
              )}
          </Grid>
          {props?.form?.attachments?.map((item, index) => (
            <Grid key={index} item xs={2}>
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <Box
                  component="a"
                  href={item?.url}
                  // target="_blank"
                  download
                  color="primary"
                  sx={{
                    border: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    padding: '4px 8px',
                    borderRadius: '4px',
                    textDecoration: 'none',
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  Baixar {item?.url?.includes('.pdf') ? 'PDF' : 'arquivo'}
                </Box>

                {!item?.url?.includes('.pdf') && (
                  <Avatar
                    src={item.url}
                    title="Arquivo"
                    alt={index}
                    sx={{
                      '& img': { objectFit: 'contain' },
                      borderRadius: 1,
                      height: 150,
                      width: '100%'
                    }}
                  />
                )}
                {item?.url?.includes('.pdf') && (
                  <Avatar
                    src={iconPdf}
                    title="Arquivo"
                    alt={index}
                    sx={{
                      '& img': { objectFit: 'contain' },
                      borderRadius: 1,
                      height: 150,
                      width: 100
                    }}
                  />
                )}
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            {props.form?.attachment?.url && (
              <Box
                component="a"
                href={props.form.attachment.url}
                // target="_blank"
                download
                color="primary"
                sx={{
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  padding: '4px 8px',
                  borderRadius: '4px',
                  textDecoration: 'none',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                Baixar {props.form?.attachment?.url?.includes('.pdf') ? 'PDF' : 'arquivo'}
              </Box>
            )}
            {props.form?.attachment?.url &&
              !props.form?.attachment?.url?.includes('.pdf') && (
                <Avatar
                  src={props.form.attachment.url}
                  title="Arquivo"
                  alt="Arquivo anexado"
                  sx={{
                    '& img': { objectFit: 'contain' },
                    borderRadius: 1,
                    height: 150,
                    width: '100%'
                  }}
                />
              )}
          </Grid>
        </Grid>
      </div>

      <form
        id="entry-data-form"
        style={{
          marginTop: '24px',
          backgroundColor: '#f0f0f0',
          padding: '10px',
          borderRadius: '12px'
        }}
      >
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Dados para preenchimento
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
            <Grid item xs={3}>
              <TextField
                onChange={(e) => {
                  setBudgetData({
                    ...budgetData,
                    destinationCity: e.target.value
                  })
                }}
                value={budgetData.destinationCity}
                autoComplete="off"
                fullWidth
                id="standard-basic"
                label="Cidade destino"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              {/* <TextField onChange={(e) => { setBudgetData({ ...budgetData, minimumLotReference: e.target.value }) }}
                value={budgetData.minimumLotReference}
                autoComplete='off'
                type='number' fullWidth id="standard-basic" label="Toneladas para lote mínimo" variant="standard" /> */}
              <FormControl variant="standard" sx={{ minWidth: 220 }}>
                <InputLabel id="simple-select-standard-label">
                  Lote mínimo (Toneladas)
                </InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  fullWidth
                  value={budgetData.minimumLotReference}
                  onChange={(e) => {
                    setBudgetData({
                      ...budgetData,
                      minimumLotReference: e.target.value
                    })
                  }}
                >
                  <MenuItem value={1500}>Maleta - 1,5 toneladas</MenuItem>
                  <MenuItem value={3000}>Corte vinco - 3 toneladas</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={budgetData.cliche}
                    onChange={(e, v) => {
                      setBudgetData({ ...budgetData, cliche: v })
                    }}
                  />
                }
                label="Clichê polímero"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={budgetData.knife}
                    onChange={(e, v) => {
                      setBudgetData({ ...budgetData, knife: v })
                    }}
                  />
                }
                label="Clichê metálico"
              />
            </Grid>
          </Grid>
        </div>
      </form>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginTop: 30
        }}
      >
        <Button
          style={{
            marginRight: '10px',
            backgroundColor: '#EBEBEB',
            color: '#484848'
          }}
          variant="contained"
          onClick={() => {
            props.closeDialog()
            // alertSwalCancel(() => {
            // })
          }}
        >
          Cancelar
        </Button>
        <Button
          disabled={
            props?.form?.status === 'APROVADO' ||
            props?.form?.status === 'REPROVADO' ||
            props?.form?.status === 'CANCELADO'
          }
          style={{
            marginRight: '10px',
            backgroundColor: '#EE207A',
            color: '#FFFFFF'
          }}
          sx={{ '&.Mui-disabled': { color: '#FFFFFF !important' } }}
          onClick={() => {
            handleOpenDialog()
          }}
          color="error"
          variant="contained"
        >
          Reprovar
        </Button>
        <Button
          disabled={
            props?.form?.status === 'APROVADO' ||
            props?.form?.status === 'REPROVADO' ||
            props?.form?.status === 'CANCELADO'
          }
          style={{
            marginRight: '10px',
            backgroundColor: '#1AB1B0',
            color: '#FFFFFF'
          }}
          sx={{ '&.Mui-disabled': { color: '#FFFFFF !important' } }}
          onClick={() => {
            handleApprove()
          }}
          color="success"
          variant="contained"
        >
          Aprovar
        </Button>
      </div>

      <GenericDialog
        open={openDialog}
        close={() => setOpenDialog(false)}
        content={
          <FTDisapprove
            id={props.form._id}
            cancellDialog={handleCancellDialog}
            closeDialog={handleCloseDialog}
          />
        }
      />
    </Box>
  )
}

export default FTAuditBox
