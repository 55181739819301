/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress, Table, TableFooter } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import { useQuery } from 'react-query'
import moment from 'moment'
import { httpClient } from 'config/httpClient'
import { toast } from 'react-toastify'
import { TableCellKgOrRs } from 'components/table/TableCellKgOrRs'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export interface EstoqueType {
  _id: string
  produto: string
  entradaMes?: number
  data: string
  kg: number
  rs: number
  createdAt: string
}

const EstoqueTable = (props: any) => {
  const [currentDataEstoques, setCurrentDataEstoques] = useState<EstoqueType[]>([])
  const [sameDatePreviousMonthEstoques, setSameDatePreviousMonthEstoques] =
    useState<EstoqueType[]>([])

  const { isLoading, refetch } = useQuery(
    ['managementControl-estoque', moment(props.date).format('YYYY-MM-DD')],
    async () =>
      httpClient.get(
        `/estoques/relatorio?data=${moment(props.date).format(
          'YYYY-MM-DD'
        )}&sameDatePreviousMonth=${moment(props.date)
          .set('month', moment(props.date).get('month') - 1)
          .format('YYYY-MM-DD')}`
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess({ data }) {
        setCurrentDataEstoques(data?.currentDateEstoques ?? [])
        setSameDatePreviousMonthEstoques(
          data?.sameDatePreviousMonthEstoques ?? []
        )
      },
      onError(err: any) {
        toast.error(err?.response?.data?.message)
      }
    }
  )

  const estoques = currentDataEstoques.map((item) => {
    const findSameDatePreviousMonth = sameDatePreviousMonthEstoques.find(
      (sameDateItem) =>
        moment(sameDateItem?.data).get('D') === moment(item.data).get('D') &&
        // sameDateItem.produto?.includes(item.produto)
        sameDateItem.produto === item.produto
    )
    // console.log('findSameDatePreviousMonth', findSameDatePreviousMonth)

    return {
      sameDatePreviousMonthKg: findSameDatePreviousMonth?.kg ?? 0,
      sameDatePreviousMonthRs: findSameDatePreviousMonth?.rs ?? 0,
      sameDatePreviousMonthRsKg: findSameDatePreviousMonth?.rs
        ? findSameDatePreviousMonth?.rs / (findSameDatePreviousMonth?.kg ?? 0)
        : 0,
      ...item,
      rskg: item?.rs ? item?.rs / (item?.kg ?? 0) : 0
    }
  })

  const estoquesTotal = estoques.reduce(
    (acc, item) => {
      const estoqueData = {
        ...item
      }

      return {
        sameDatePreviousMonthKg: acc.sameDatePreviousMonthKg + estoqueData.sameDatePreviousMonthKg,
        sameDatePreviousMonthRs: acc.sameDatePreviousMonthRs + estoqueData.sameDatePreviousMonthRs,
        entradaMes: acc.entradaMes + (estoqueData.entradaMes ?? 0),
        rs: acc.rs + estoqueData.rs,
        kg: acc.kg + estoqueData.kg,
        rskg: acc.rs
          ? Number(acc.rs + estoqueData.rs ?? 0) / Number(acc.kg + estoqueData.kg ?? 0)
          : acc.rskg
      }
    },
    {
      sameDatePreviousMonthKg: 0,
      sameDatePreviousMonthRs: 0,
      entradaMes: 0,
      rs: 0,
      kg: 0,
      rskg: 0
    }
  )

  return (
    <>
      {isLoading && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <TableContainer
          style={{ marginTop: '30px', maxHeight: 440 }}
          component={Paper}
        >
          <Table
            sx={{ minWidth: '100%' }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  sx={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 3,
                    background: '#f5f5f5 !important'
                  }}
                ></StyledTableCell>
                <StyledTableCell align="center" colSpan={3}>
                  Mês Anterior
                </StyledTableCell>
                <StyledTableCell align="left">Entrada mês</StyledTableCell>
                <StyledTableCell align="center" colSpan={3}>
                  Dia
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  align="left"
                  sx={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 3,
                    background: '#f5f5f5 !important'
                  }}
                >
                  Produto
                </StyledTableCell>
                <StyledTableCell align="left">Kg</StyledTableCell>
                <StyledTableCell align="left">R$</StyledTableCell>
                <StyledTableCell align="left">R$/kg</StyledTableCell>

                <StyledTableCell align="left">Kg</StyledTableCell>

                <StyledTableCell align="left">Kg</StyledTableCell>
                <StyledTableCell align="left">R$</StyledTableCell>
                <StyledTableCell align="left">R$/kg</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estoques.map((row) => (
                <StyledTableRow key={row._id}>
                  <StyledTableCell
                    align="left"
                    sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 2,
                      background: '#FFF'
                    }}
                  >
                    {row.produto}
                  </StyledTableCell>
                  <TableCellKgOrRs
                    value={row.sameDatePreviousMonthKg}
                    type="KG"
                  />
                  <TableCellKgOrRs
                    value={row.sameDatePreviousMonthRs}
                    type="RS"
                  />
                  <TableCellKgOrRs
                    value={row?.sameDatePreviousMonthRsKg}
                    type="RS"
                  />
                  <TableCellKgOrRs value={row?.entradaMes} type="KG" />
                  <TableCellKgOrRs value={row?.kg} type="KG" />
                  <TableCellKgOrRs value={row?.rs} type="RS" />
                  <TableCellKgOrRs value={row?.rskg} type="RS" />
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <StyledTableCell
                  align="left"
                  sx={{
                    fontWeight: 'bold',
                    position: 'sticky',
                    left: 0,
                    zIndex: 2,
                    background: '#FFF'
                  }}
                >
                  TOTAL
                </StyledTableCell>
                <TableCellKgOrRs
                  value={estoquesTotal.sameDatePreviousMonthKg}
                  type="KG"
                />
                <TableCellKgOrRs
                  value={estoquesTotal.sameDatePreviousMonthRs}
                  type="RS"
                />
                <TableCellKgOrRs
                  value={
                    estoquesTotal.sameDatePreviousMonthRs
                      ? estoquesTotal.sameDatePreviousMonthRs /
                      estoquesTotal.sameDatePreviousMonthKg
                      : 0
                  }
                  type="RS"
                />
                <TableCellKgOrRs value={estoquesTotal?.entradaMes} type="KG" />
                <TableCellKgOrRs value={estoquesTotal?.kg} type="KG" />
                <TableCellKgOrRs value={estoquesTotal?.rs} type="RS" />
                <TableCellKgOrRs value={estoquesTotal?.rskg} type="RS" />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default EstoqueTable
