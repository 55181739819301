import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Table } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import { formatValue, toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const productType = {
  '0001': 'Caixas',
  '0002': 'Chapas',
  '0006': 'Papel'
}

const RevenuesTable = (props: any) => {
  const [rows, setRows] = React.useState<any[]>([])
  const [totalFaturado, setTotalFaturado] = React.useState<{ rs: number, kg: number }>({ rs: 0, kg: 0 })
  const [totalDevolucoes, setTotalDevolucoes] = React.useState<{ rs: number, kg: number }>({ rs: 0, kg: 0 })

  useEffect(() => {
    props?.devolucoes?.sort((a, b) => {
      if (a.type < b.type) {
        return 1
      }
      if (a.type > b.type) {
        return -1
      }
      return 0
    })
    props?.devolucoes?.reverse()
  }, [])
  useEffect(() => {
    parseTableData()
    parseTotalDevolucoes()
  }, [props.variation])

  const parseTableData = () => {
    const data: any[] = []

    const boxes = {
      kg: 0,
      rs: 0,
      rsKg: 0.0
    }

    const plate = {
      kg: 0,
      rs: 0,
      rsKg: 0.0
    }

    const paper = {
      kg: 0,
      rs: 0,
      rsKg: 0.0
    }

    const others = {
      kg: 0,
      rs: 0,
      rsKg: 0.0
    }

    if (props.variation) {
      props.variation.forEach(v => {
        if (v.month === props.month) {
          if (v.type === TipoProduto.CAIXA) {
            boxes.kg += parseFloat(v.kg)
            boxes.rs += parseFloat(v.rs)
            boxes.rsKg += parseFloat(v.rskg)
          } else if (v.type === TipoProduto.CHAPA) {
            plate.kg += parseFloat(v.kg)
            plate.rs += parseFloat(v.rs)
            plate.rsKg += parseFloat(v.rskg)
          } else if (v.type === TipoProduto.PAPEL) {
            paper.kg += parseFloat(v.kg)
            paper.rs += parseFloat(v.rs)
            paper.rsKg += parseFloat(v.rskg)
          } else {
            others.kg += parseFloat(v.kg)
            others.rs += parseFloat(v.rs)
            others.rsKg += parseFloat(v.rskg)
          }
        }
      })

      data.push({
        _id: '_caixas',
        product: 'Caixas',
        kg: boxes.kg,
        rs: boxes.rs,
        rsKg: boxes.rsKg
      })

      data.push({
        _id: '_chapas',
        product: 'Chapas',
        kg: plate.kg,
        rs: plate.rs,
        rsKg: plate.rsKg
      })

      data.push({
        _id: '_papel',
        product: 'Papel',
        kg: paper.kg,
        rs: paper.rs,
        rsKg: paper.rsKg
      })

      const total = data.reduce((acc, item) => {
        return {
          kg: Number(acc.kg) + Number(item.kg),
          rs: Number(acc.rs) + Number(item.rs)
        }
      }, { kg: 0, rs: 0 })

      if (props?.outros?.data) {
        const rsOutros = Number(props.outros.data.valorOutros)
        data.push({
          _id: '_others',
          product: 'Outros',
          kg: 0,
          rs: rsOutros,
          rsKg: 0
        })

        total.rs = Number(total.rs) + rsOutros
      }

      setTotalFaturado(total)
      setRows(data)
    }
  }

  const parseTotalDevolucoes = () => {
    let totalKg = props?.devolucoes?.reduce((acc, item) => Number(acc) + Number(item.kg), 0)
    totalKg = isNaN(totalKg) ? 0 : totalKg
    totalKg = -1 * totalKg

    let totalRs = props?.devolucoes?.reduce((acc, item) => Number(acc) + Number(item.rs), 0)
    totalRs = isNaN(totalRs) ? 0 : totalRs
    totalRs = -1 * totalRs

    setTotalDevolucoes({
      kg: totalKg,
      rs: totalRs
    })
  }

  return (
    <>
      <TableContainer style={{ marginTop: '30px' }} component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'>Produto</StyledTableCell>
              <StyledTableCell align='left'>Kg</StyledTableCell>
              <StyledTableCell align='left'>R$</StyledTableCell>
              <StyledTableCell align='left'>R$/kg</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell align='left'>{row.product}</StyledTableCell>
                <StyledTableCell align='left'>{formatValue(row.kg ?? 0)}</StyledTableCell>
                <StyledTableCell align='left'>{toBRL(row.rs ?? 0, { noBRL: true })}</StyledTableCell>
                <StyledTableCell align='left'>{toBRL(row.rsKg ?? 0, { noBRL: true })}</StyledTableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <StyledTableCell>Total Faturado</StyledTableCell>
              <StyledTableCell>{formatValue(totalFaturado.kg)}</StyledTableCell>
              <StyledTableCell>{toBRL(totalFaturado.rs, { noBRL: true })}</StyledTableCell>
              <StyledTableCell>{toBRL(totalFaturado.rs / totalFaturado.kg, { noBRL: true })}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableHead>

            <TableRow sx={{ backgroundColor: '#ffe6e6' }}>
              <StyledTableCell align='left'>Devolução</StyledTableCell>
              <StyledTableCell align='left'>Kg</StyledTableCell>
              <StyledTableCell align='left'>R$</StyledTableCell>
              <StyledTableCell align='left'>R$/kg</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props?.devolucoes?.map(item =>
              <StyledTableRow>
                <StyledTableCell>{productType[item.type]}</StyledTableCell>
                <StyledTableCell>{formatValue(-1 * Number(item.kg))}</StyledTableCell>
                <StyledTableCell>{toBRL(-1 * Number(item.rs), { noBRL: true })}</StyledTableCell>
                <StyledTableCell>{toBRL(-1 * Number(item.rs / item.kg), { noBRL: true })}</StyledTableCell>
              </StyledTableRow>
            )}

            <StyledTableRow>
              <StyledTableCell>Total Devolução</StyledTableCell>
              <StyledTableCell>{formatValue(totalDevolucoes.kg)}</StyledTableCell>
              <StyledTableCell>{toBRL(totalDevolucoes.rs, { noBRL: true })}</StyledTableCell>
              <StyledTableCell>{toBRL(totalDevolucoes.rs / totalDevolucoes.kg, { noBRL: true })}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell><b>Total</b></StyledTableCell>
              <StyledTableCell><b>{formatValue(totalFaturado.kg + totalDevolucoes.kg)}</b></StyledTableCell>
              <StyledTableCell><b>{toBRL(totalFaturado.rs + totalDevolucoes.rs, { noBRL: true })}</b></StyledTableCell>
              <StyledTableCell><b>{
                toBRL(
                  (totalFaturado.rs + totalDevolucoes.rs) /
                  (totalFaturado.kg + totalDevolucoes.kg)
                  , { noBRL: true })
              }</b></StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default RevenuesTable
