/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, CircularProgress, Icon, TablePagination, TextField } from '@mui/material'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportMonthly, getReportMonthlyDays, getReportMonthlyLocal } from 'services/report/ReportMonthlyService'
import { useEffect, useState } from 'react'
import { IdentifierClient } from 'types/Report/Report'
import { StandardFilter } from 'utils/StandardFilter'
import { formatValue, toBRL } from 'utils/formatString'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function ReportDayAccumulated(props: any) {
  const [rows, setRows] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const date = moment(props.date).format('YYYY-MM')

  useEffect(() => {
    const loadedData = [props.req1.isLoading, props.req2.isLoading, props.req3.isLoading].every(loading => !loading)
    if (loadedData) {
      formatTableData(props.req1.data.response, props.req2.data, props.req3.data.response)
    }
  }, [props.req1.isLoading, props.req2.isLoading, props.req3.isLoading])

  const formatTableData = (invoicing: any[], forecast: any[], demand: any[]) => {
    console.log('faturamento:', invoicing, 'previsoes:', forecast, 'demanda:', demand)
    try {
      const arrayDemandFiltrada = [] as any[]
      demand.forEach((item) => {
        item.dados.forEach(element => {
          if (props.type.includes(element.type)) { arrayDemandFiltrada.push(element) }
        })
      })

      const arrayInvoicingFiltrada = [] as any[]
      invoicing.filter(({ dados }) => (
        dados.some((data) => props.type.includes(data.type))
      )).forEach((item) => {
        item.dados.forEach(element => {
          if (props.type.includes(element.type)) {
            arrayInvoicingFiltrada.push(element)
          }
        })
      })

      const count = moment(date).daysInMonth()
      console.log({ props })

      const dadosDaTabela = [] as any[]
      let acumuladoPedidosKg = 0
      let acumuladoPedidosRs = 0
      let acumuladoFaturadoKg = 0
      let acumuladoFaturadoRs = 0
      for (let i = 1; i < count + 1; i++) {
        const periodo = moment(date).date(i).format('DD/MM/YYYY')

        const dadosCadastro = arrayDemandFiltrada.filter((dados) => dados.periodo.includes(periodo))
        const dadosFaturamento = arrayInvoicingFiltrada.filter((dados) => dados.periodo.includes(periodo))

        const { kg: kgPedidos, rs: rsPedidos, rskg: rskgPedidos } = dadosCadastro.reduce((acumulador, dados) => {
          const { kg, rs, rskg } = dados
          acumulador.kg += kg
          acumulador.rs += rs
          acumulador.rskg += rskg
          return acumulador
        }, { kg: 0, rs: 0, rskg: 0 })
        acumuladoPedidosKg += kgPedidos
        acumuladoPedidosRs += rsPedidos

        const { kg: kgFaturamento, rs: rsFaturamento, rskg: rskgFaturamento } = dadosFaturamento.reduce((acumulador, dados) => {
          const { kg, rs, rskg } = dados
          acumulador.kg += kg
          acumulador.rs += rs
          acumulador.rskg += rskg
          return acumulador
        }, { kg: 0, rs: 0, rskg: 0 })
        acumuladoFaturadoKg += kgFaturamento
        acumuladoFaturadoRs += rsFaturamento

        const diasUteis = props?.days?.data?.worksDays?.length ?? 0
        let kgPrevisao: number
        let rsPrevisao: number
        if (diasUteis) {
          kgPrevisao = ((props?.prevision?.dados?.amountKg / diasUteis) * i) | 0
          rsPrevisao = ((props?.prevision?.dados?.amountPrice / diasUteis) * i) | 0
        } else {
          kgPrevisao = 0
          rsPrevisao = 0
        }

        dadosDaTabela.push({
          periodo,
          kgPrevisao,
          rsPrevisao,
          rsPedidos: acumuladoPedidosRs,
          kgPedidos: acumuladoPedidosKg,
          rskgPedidos,
          kgFaturamento: acumuladoFaturadoKg,
          rsFaturamento: acumuladoFaturadoRs,
          rskgFaturamento
        })
      }
      console.log({ dadosDaTabela })

      setRows(dadosDaTabela)
      setDataSource(dadosDaTabela)
    } catch (error) {
      console.log('erro data: ', error)
    }
  }

  useEffect(() => {
    StandardFilter(filters, setRows, dataSource)
  }, [filters, dataSource])

  // const search = () => {
  //   if (searchParam.length === 0) {
  //     setRows(originalRows)
  //   } else {
  //     const searchResult = originalRows.filter(row => row.date.toUpperCase().includes(searchParam.toUpperCase()))
  //     setRows(searchResult)
  //   }
  // }

  return (
    <>
      {(props.req1.isLoading || props.req1.isFetching) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <CircularProgress />
        </Box>
      )}
      {(props.req1.isError && props.req2.isError) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
        </Box>
      )}

      {(props.req1.isSuccess && !props.req1.isError && !props.req1.isFetching) && (
        <>
          <TableContainer style={{ marginTop: '30px' }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TextField
                      id="standard-basic"
                      label="Pesquisar data"
                      autoComplete='off'
                      variant="standard"
                      onChange={(e) => setFilters({ ...filters, periodo: e.target.value })}
                    />
                  </StyledTableCell>
                  <StyledTableCell align='left'>Previsão Kg</StyledTableCell>
                  <StyledTableCell align='left'>Previsão R$</StyledTableCell>
                  <StyledTableCell align='left'>Previsão R$/Kg</StyledTableCell>

                  <StyledTableCell align='left'>Cadastro Kg</StyledTableCell>
                  <StyledTableCell align='left'>Cadastro R$</StyledTableCell>
                  <StyledTableCell align='left'>Cadastro R$/Kg</StyledTableCell>

                  <StyledTableCell align='left'>Faturado Kg</StyledTableCell>
                  <StyledTableCell align='left'>Faturado R$</StyledTableCell>
                  <StyledTableCell align='left'>Faturado R$/Kg</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.slice(page * 5, page * 5 + 5)?.map((row) => {
                  return (
                    <StyledTableRow key={`${row.index}-${row.periodo}`}>
                      <StyledTableCell align='left'>{row.periodo}</StyledTableCell>

                      <StyledTableCell align='left'>
                        {
                          /* formatValue(row.kgPrevisao) */
                          '-'
                        }
                      </StyledTableCell>
                      <StyledTableCell align='left'>
                        {
                          /* toBRL(row.rsPrevisao, { noBRL: true }) */
                          '-'
                        }
                      </StyledTableCell>
                      <StyledTableCell align='left'>
                        {
                          /* toBRL(
                            isNaN(row.rsPrevisao / row.kgPrevisao)
                              ? 0
                              : row.rsPrevisao / row.kgPrevisao
                            , { noBRL: true }
                          ) */
                          '-'
                        }
                      </StyledTableCell>

                      <StyledTableCell align='left'>{formatValue(row.kgPedidos)}</StyledTableCell>
                      <StyledTableCell align='left'>{toBRL(row.rsPedidos, { noBRL: true })}</StyledTableCell>
                      <StyledTableCell align='left'>{toBRL(row.rsPedidos / row.kgPedidos, { noBRL: true })}</StyledTableCell>

                      <StyledTableCell align='left'>{formatValue(row.kgFaturamento)}</StyledTableCell>
                      <StyledTableCell align='left'>{toBRL(row.rsFaturamento, { noBRL: true })}</StyledTableCell>
                      <StyledTableCell align='left'>{toBRL(row.rsFaturamento / row.kgFaturamento, { noBRL: true })}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              rowsPerPage={5}
              component="div"
              count={rows.length}
              page={page}
              onPageChange={(event: unknown, newPage: number) => { setPage(newPage) }}
            />
          </TableContainer>
        </>
      )}
    </>
  )
}
