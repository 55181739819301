import { httpClient } from 'config/httpClient'
import RequestForProposalsType from 'types/Report/RequestForProposalsType'

const getTechnicalSheet = async (params: Record<string, unknown>) => {
  const { data } = await httpClient.get<RequestForProposalsType[]>('ft/status', {
    params
  })

  return data
}

const getFtsByProposal = async (params: Record<string, unknown>) => {
  const { data } = await httpClient.get<RequestForProposalsType[]>('ft/proposal/all', {
    params
  })

  return data
}

export { getTechnicalSheet, getFtsByProposal }
