/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getItemsDeliveryByReposition } from 'services/report/DeliveryMapService'
import { LoadingDeliveryMap } from '../components/LoadingDeliveryMap'
import { ShowErrorDeliveryMap } from '../components/ShowErrorDeliveryMap'
import Reposition from './Reposition'

interface Props {
  date?: Date
}

const RepositionContainer: React.FC<Props> = (props) => {
  const [dataValues, setDataValues] = useState<any[]>([])

  // const { isError, isLoading, isFetching } = useQuery(
  //   ['deliveryMap-RepositionContainer'],
  //   async () => {
  //     return getItemsDeliveryByReposition()
  //   },
  //   {
  //     onSuccess: async (data) => {
  //       setDataValues(data ?? [])
  //     },
  //     onError: (error) => {
  //       console.log('error', error)
  //     },
  //     refetchOnWindowFocus: false
  //   }
  // )

  return (
    <>
      {/* <LoadingDeliveryMap isLoading={isLoading || isFetching} />

      <ShowErrorDeliveryMap showError={isError} />

      {!isLoading && !isFetching && ( */}
        <Reposition date={props?.date} ordersIds={dataValues} />
      {/* )} */}
    </>
  )
}

export default RepositionContainer
