/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-constant-condition */
import React, { useState } from 'react'
import Button from 'components/form/Button'
import VisibilityIcon from '@mui/icons-material/Visibility'
import UploadIcon from '@mui/icons-material/Upload'
import InputMask from 'react-input-mask'
import { StyledDropZone } from 'react-drop-zone'
import {
  Grid,
  Box,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Button as MuiButton,
  CircularProgress,
  Dialog,
  AlertTitle,
  DialogActions,
  Stack,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { httpClient } from 'config/httpClient'
import moment from 'moment'
import { useQuery } from 'react-query'
import ImportantView from 'components/view/ImportantView'

interface UploadFiles {
  idClient: string
  type: 'UB' | 'PB' | 'AB' | 'RB' | 'IR' | 'DC' | 'CC' | 'IE' | 'CS' | 'AC'
  typeClient: 'PF' | 'PJ'
  file?: File
}

export function AttachDocRow({
  title,
  date,
  status,
  uploadFile
}: {
  title: string
  date?: string
  status?: string
  uploadFile: (file?: File) => void
}) {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <strong>{title}</strong>
      </TableCell>
      <TableCell>{date && moment(date).format('DD/MM/YYYY')}</TableCell>
      <TableCell>
        <Chip
          size="small"
          color="warning"
          label={status?.length ? status : 'ENVIO PENDENTE'}
          variant="outlined"
          sx={{
            borderRadius: '4px'
          }}
        />
      </TableCell>
      <TableCell align="right">
        <Box display="flex" justifyContent="flex-end">
          <InputFile id={title} onChangeFile={(file) => uploadFile(file)} />
        </Box>
      </TableCell>
    </TableRow>
  )
}

interface InputFileProps {
  id: string
  onChangeFile: (file?: File) => void
  accept?: string
}

function InputFile({
  id,
  onChangeFile,
  accept = 'image/png, image/jpeg, application/pdf, application/octet-stream'
}: InputFileProps) {
  return (
    <Box
      component="label"
      htmlFor={id}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px 16px',
        background: '#4060E6',
        color: (theme) => theme.palette.primary.contrastText
      }}
    >
      <UploadIcon />
      <input
        type="file"
        id={id}
        onChange={(event) => onChangeFile(event?.target?.files?.[0])}
        hidden
        accept={accept}
      />
    </Box>
  )
}

interface Props {
  goBack: () => void
  nextStep: () => void
}

const FormCustomerDocumentation: React.FC<Props> = ({ goBack, nextStep }) => {
  const navigate = useNavigate()
  const { state }: any = useLocation()

  const [open, setOpen] = useState<boolean>(false)
  const [openDeclaration, setOpenDeclaration] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingDeclaration, setLoadingDeclaration] = useState<boolean>(false)
  const [cpfRepresentative, setCpfRepresentative] = useState(
    state.client?.declaration?.cpfRepresentative ?? ''
  )
  const [formTaxation, setFormTaxation] = useState(
    state.client?.declaration?.formTaxation ?? ''
  )
  const [termOfCommitment, setTermOfCommitment] = useState(
    state.client?.declaration?.termOfCommitment ?? false
  )

  const [client, setClient] = useState<any | null>(state.client)
  const [docsPF, setDocsPF] = useState<any | null>(state.client?.pf?.docs)
  const [docsPJ, setDocsPJ] = useState<any | null>(state.client?.pj?.docs)

  const [selectedFile, setSelectedFile] = useState<any | null>(null)

  const [uploadFiles, setUploadFiles] = useState<UploadFiles[]>([])

  const { status, refetch } = useQuery(
    ['ft-proposal-client'],
    async () => {
      return httpClient.get(`/company/${state?.client?._id}`)
    },
    {
      onSuccess: (response) => {
        console.log('company', response)
        if (response.data) {
          setClient(response.data)
          setDocsPF((state) => response.data?.pf?.docs ?? state)
          setDocsPJ((state) => response.data?.pj?.docs ?? state)
          setCpfRepresentative(
            response.data?.declaration?.cpfRepresentative ?? ''
          )
          setFormTaxation(response.data?.declaration?.formTaxation ?? '')
          setTermOfCommitment(
            Boolean(response.data?.declaration?.termOfCommitment ?? false)
          )
        }
      },
      refetchOnWindowFocus: false
    }
  )

  const handleUpload = (
    idClient: string,
    type: 'UB' | 'PB' | 'AB' | 'RB' | 'IR' | 'DC' | 'CC' | 'IE' | 'CS' | 'AC',
    typeClient: 'PF' | 'PJ',
    file?: File
  ) => {
    if (!file) return
    setLoading(true)

    const formData = new FormData()

    formData.append('file', file)
    formData.append('type', type)
    formData.append('typeClient', typeClient)

    const headers = {
      'Content-Type': 'multpart/form-data'
    }

    // test
    toast.promise(
      httpClient.put(`/company/${idClient}/docs`, formData, { headers }),
      {
        pending: 'Enviando documento',
        success: {
          render() {
            refetch()
            setLoading(false)
            return 'Documento enviado com sucesso'
          }
        },
        error: {
          render({ data }: any) {
            setLoading(false)
            return data?.message
          }
        }
      }
    )
    // test

    // httpClient
    //   .put(`/company/${idClient}/docs`, formData, { headers })
    //   .then(() => {
    //     if (typeClient === 'PF') {
    //       setDocsPF((state) => ({
    //         ...state,
    //         ir:
    //           type === 'IR'
    //             ? { ...state.ir, status: 'PENDENTE DE APROVAÇÃO' }
    //             : state.ir,
    //         document:
    //           type === 'DC'
    //             ? { ...state.document, status: 'PENDENTE DE APROVAÇÃO' }
    //             : state.document
    //       }))
    //     } else {
    //       setDocsPJ((state) => ({
    //         ...state,
    //         antepenultimateBalance:
    //           type === 'AB'
    //             ? {
    //                 ...state?.antepenultimateBalance,
    //                 status: 'PENDENTE DE APROVAÇÃO'
    //               }
    //             : state?.antepenultimateBalance,
    //         lastBalance:
    //           type === 'UB'
    //             ? { ...state?.lastBalance, status: 'PENDENTE DE APROVAÇÃO' }
    //             : state?.lastBalance,
    //         listAssets:
    //           type === 'RB'
    //             ? { ...state?.listAssets, status: 'PENDENTE DE APROVAÇÃO' }
    //             : state?.listAssets,
    //         penultimateBalance:
    //           type === 'PB'
    //             ? {
    //                 ...state?.penultimateBalance,
    //                 status: 'PENDENTE DE APROVAÇÃO'
    //               }
    //             : state?.penultimateBalance
    //       }))
    //     }

    //     toast.success('Documento enviado com sucesso!')
    //     refetch()
    //   })
    //   .catch((error) => {
    //     console.error(error)
    //     toast.error(error?.response?.data?.message)
    //   })
    //   .finally(() => {
    //     setLoading(false)
    //   })
  }

  const handleClose = () => {
    navigate('/home/customer-control', {
      state: undefined
    })
  }

  const handleCloseDeclaration = () => {
    setOpenDeclaration(false)
    setSelectedFile(null)
  }

  const handleSubmitDeclaration = () => {
    const body = {
      name: state?.client?.name,
      doc: state?.client?.pf ? state?.client?.pf?.cpf : state?.client?.pj.cnpj,
      lat: '',
      long: '',
      formTaxation,
      cpfRepresentative,
      termOfCommitment,
      file: selectedFile
    }
    console.log('DECLARAÇÃO TRIBUTÁRIA', body)

    if (state?.client?._id) {
      setLoadingDeclaration(true)

      toast.promise(
        httpClient.post(`company/${state?.client?._id}/declaration`, body),
        {
          pending: 'Aguarde, enviando declaração',
          success: {
            render() {
              refetch()
              setLoadingDeclaration(false)
              handleCloseDeclaration()
              return 'Declaração enviada com sucesso'
            }
          },
          error: {
            render({ data }: any) {
              setLoadingDeclaration(false)
              return data?.message
            }
          }
        }
      )
    } else {
      alert('Cliente não encontrado.')
    }
  }

  const getBase64 = (file) => {
    if (
      !file?.type ||
      (!file?.type?.includes('image') &&
        !file?.type?.includes('application/pdf'))
    ) {
      toast.warn('Selecione uma imagem ou PDF.')
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const base64 = reader.result
      setSelectedFile(base64)
      // console.log('base64: ', base64)
    }
    reader.onerror = function (error) {
      console.log('Error file read: ', error)
    }
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={12} className="text-right">
          {status === 'loading' && (
            <Stack justifyContent="center" alignItems="center" minHeight={200}>
              <CircularProgress size={24} />
            </Stack>
          )}

          {status !== 'loading' && (
            <TableContainer>
              <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>DOCUMENTO</TableCell>
                    <TableCell>ÚLTIMO ENVIO</TableCell>
                    <TableCell>STATUS</TableCell>
                    <TableCell align="right">AÇÕES</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!state?.client?._id && loading && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}

                  {state?.client?.type === 'PJ' && (
                    <>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <strong>Declaração tributária *</strong>
                        </TableCell>
                        <TableCell>
                          {client?.declaration?.dateConfirmation &&
                            moment(
                              client?.declaration?.dateConfirmation
                            ).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            color="warning"
                            label={
                              client?.declaration?.status ?? 'ENVIO PENDENTE'
                            }
                            variant="outlined"
                            sx={{
                              borderRadius: '4px'
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <MuiButton
                            type="button"
                            color="primary"
                            variant="contained"
                            disabled={false}
                            onClick={() => setOpenDeclaration(true)}
                            sx={{
                              minWidth: 'auto',
                              borderRadius: 0,
                              boxShadow: 'none',
                              background: '#4060E6',
                              '&:hover': {
                                background: '#4060E6'
                              }
                            }}
                          >
                            <VisibilityIcon />
                          </MuiButton>
                        </TableCell>
                      </TableRow>

                      <AttachDocRow
                        title="Cartão de CNPJ *"
                        date={docsPJ?.cnpjCard?.lastSend}
                        status={docsPJ?.cnpjCard?.status ?? undefined}
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'CC', 'PJ', file)
                        }
                      />

                      <AttachDocRow
                        title="Inscrição estadual *"
                        date={docsPJ?.stateRegistration?.lastSend}
                        status={docsPJ?.stateRegistration?.status ?? undefined}
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'IE', 'PJ', file)
                        }
                      />

                      <AttachDocRow
                        title="Contrato social *"
                        date={docsPJ?.socialContract?.lastSend}
                        status={docsPJ?.socialContract?.status ?? undefined}
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'CS', 'PJ', file)
                        }
                      />

                      <AttachDocRow
                        title="Alteração contratual *"
                        date={docsPJ?.contractualAlteration?.lastSend}
                        status={
                          docsPJ?.contractualAlteration?.status ?? undefined
                        }
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'AC', 'PJ', file)
                        }
                      />

                      <AttachDocRow
                        title="Relação de bens *"
                        date={docsPJ?.listAssets?.lastSend}
                        status={docsPJ?.listAssets?.status ?? undefined}
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'RB', 'PJ', file)
                        }
                      />

                      <AttachDocRow
                        title="Último balanço *"
                        date={docsPJ?.lastBalance?.lastSend}
                        status={docsPJ?.lastBalance?.status ?? undefined}
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'UB', 'PJ', file)
                        }
                      />

                      <AttachDocRow
                        title="Penúltimo balanço"
                        date={docsPJ?.penultimateBalance?.lastSend}
                        status={docsPJ?.penultimateBalance?.status ?? undefined}
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'PB', 'PJ', file)
                        }
                      />

                      <AttachDocRow
                        title="Antepenúltimo balanço"
                        date={docsPJ?.antepenultimateBalance?.lastSend}
                        status={
                          docsPJ?.antepenultimateBalance?.status ?? undefined
                        }
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'AB', 'PJ', file)
                        }
                      />
                    </>
                  )}

                  {state?.client?.type === 'PF' && (
                    <>
                      <AttachDocRow
                        title="Identificação com foto"
                        date={docsPF?.document?.lastSend}
                        status={docsPF?.document?.status ?? undefined}
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'DC', 'PF', file)
                        }
                      />

                      <AttachDocRow
                        title="Declaração de imposto de renda"
                        date={docsPF?.ir?.lastSend}
                        status={docsPF?.ir?.status ?? undefined}
                        uploadFile={(file) =>
                          handleUpload(state?.client?._id, 'IR', 'PF', file)
                        }
                      />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>

        <Grid item md={12} className="text-left">
          <Typography component="span" variant="body2">
            Os campos com * são obrigatórios.
          </Typography>
        </Grid>
        <Grid item md={12} className="text-right">
          <Button
            type="button"
            mold="primary"
            onClick={goBack}
            style={{ marginRight: '10px' }}
            disabled={loading}
          >
            Voltar
          </Button>
          <Button
            mold="primary"
            type="button"
            disabled={
              loading ||
              (!client?.declaration?.dateConfirmation ||
              state?.client?.type === 'PJ'
                ? !docsPJ?.cnpjCard?.lastSend ||
                  !docsPJ?.socialContract?.lastSend ||
                  !docsPJ?.contractualAlteration?.lastSend ||
                  !docsPJ?.listAssets?.lastSend ||
                  !docsPJ?.lastBalance?.lastSend ||
                  !client?.declaration?.dateConfirmation
                : false)
            }
            onClick={() => setOpen(true)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 0,
            paddingX: 2,
            paddingY: 1,
            paddingRight: 4
          }
        }}
      >
        <Alert
          variant="outlined"
          severity="success"
          sx={{ border: 'none', color: 'inherit' }}
        >
          <AlertTitle>Cliente salvo com sucesso</AlertTitle>
          Audite os documentos enviados pelo seu cliente.
        </Alert>
        <DialogActions>
          <MuiButton
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{ borderRadius: 0, minWidth: 'auto' }}
          >
            OK
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseDeclaration}
        open={openDeclaration}
        fullWidth
        maxWidth="md"
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 0,
            paddingX: 2,
            paddingY: 1,
            paddingRight: 4
          }
        }}
      >
        <Stack spacing={2}>
          <Typography variant="body1" fontWeight="bold">
            Declaração Tributária
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                value={state?.client?.name}
                label="Denominação da empresa"
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={5}>
              <InputMask
                mask="99.999.999/9999-99"
                disabled
                maskChar=""
                value={state?.client?.pj?.cnpj}
              >
                {() => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="CNPJ"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={4}>
              <InputMask
                mask="999.999.999-99"
                disabled={false}
                maskChar=""
                value={cpfRepresentative}
                onChange={async (e) => {
                  setCpfRepresentative(e.target.value)
                }}
              >
                {() => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="CPF do representante legal"
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={12}>
              <Typography>Endereço</Typography>
            </Grid>
            <Grid item xs={4}>
              <InputMask
                mask="99999-999"
                disabled
                maskChar=""
                value={state?.client?.address?.zipcode}
              >
                {() => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Cep"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={state?.client?.address?.publicPlace}
                label="Logradouro"
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={state?.client?.address?.number}
                label="Número"
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={state?.client?.address?.district}
                label="Bairro"
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={state?.client?.address?.state}
                label="Estado"
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={state?.client?.address?.city}
                label="Cidade"
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>
                Forma de Tributação do Imposto de Renda da Pessoa Jurídica é: *
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={formTaxation}
                  onChange={(_, value) => setFormTaxation(value)}
                >
                  <FormControlLabel
                    value="real"
                    control={<Radio />}
                    label="Lucro real"
                  />
                  <FormControlLabel
                    value="presumed"
                    control={<Radio />}
                    label="Lucro presumido"
                  />
                  <FormControlLabel
                    value="simple"
                    control={<Radio />}
                    label="Lucro simples"
                  />
                  <FormControlLabel
                    value="arbitrated"
                    control={<Radio />}
                    label="Lucro arbitrado e Sem Fins Lucrativos"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography>Anexar arquivo</Typography>
            </Grid>
            {client?.declaration?.archive?.url && (
              <Grid item xs={12}>
                <Typography
                  component="a"
                  href={client?.declaration?.archive?.url}
                  target="_blank"
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                >
                  Visualizar arquivo
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  marginRight: '30px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1px',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: '8px',
                        border: false ? '1px solid red' : '0px solid red'
                      }}
                    >
                      <StyledDropZone
                        children={
                          selectedFile
                            ? '✔ Arquivo anexado!'
                            : 'Selecione ou arraste seu arquivo aqui'
                        }
                        onDrop={(file, text) => getBase64(file)}
                      />
                    </div>
                    <ImportantView
                      style={{ marginTop: '20px' }}
                      title="Importante"
                      content="Tamanho máximo 2mb"
                    />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography>Termo de Compromisso *</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(termOfCommitment)}
                      onChange={(_, checked) => setTermOfCommitment(checked)}
                    />
                  }
                  label="Aceitar termo de compromisso"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Stack>

        <DialogActions>
          <Button
            onClick={handleCloseDeclaration}
            disabled={loadingDeclaration}
          >
            Cancelar
          </Button>
          <Button
            mold="primary"
            onClick={handleSubmitDeclaration}
            disabled={
              !termOfCommitment || !formTaxation?.length || loadingDeclaration
            }
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FormCustomerDocumentation
