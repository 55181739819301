/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import ModulesType from 'types/modules/ModulesType'
import styled from 'styled-components'
import {
  ArrowDropDown,
  ArrowRight
} from '@mui/icons-material'
import PermissionsType from 'types/modules/PermissionsType'
import { httpClient } from 'config/httpClient'

interface Modules extends ModulesType {
  expanded: boolean
  checked: boolean
  permissions: Array<PermissionsType & { checked: boolean }>
}
interface Props {
  modules: ModulesType[]
  onChange: (modules: any[]) => void
  disabled?: boolean
}

const CardBlue = styled.div`
  background-color: #4060e6;
  width: 100%;
  height: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`

const TextCardBlue = styled.text`
  color: white;
  margin-bottom: 10px;
  opacity: 0.7;
`

const AccordeonModules: React.FC<Props> = (props) => {
  // console.log(props.modules)
  const [modules, setModules] = useState<Modules[]>([])

  const loadModules = useCallback(async () => {
    const response = await httpClient.get<any[]>('/modules')
    const preparedModules = response.data?.map((item) => {
      if (props?.modules?.length > 0) {
        if (item?.name === 'DASHBOARD') {
          const findPermission = props.modules.find((mod) => mod.cod === item?.cod)?.permissions?.[0].cod
          return {
            _id: item?._id ?? '',
            name: item?.name,
            cod: item?.cod,
            expanded: !!findPermission,
            checked: !!findPermission,
            permissions: [{ name: 'read', cod: 'R', checked: !!findPermission }]
          }
        }

        const findPermissions = props.modules.find((mod) => mod.cod === item?.cod)?.permissions

        return {
          _id: item?._id ?? '',
          name: item?.name,
          cod: item?.cod,
          expanded: !!findPermissions?.length,
          checked: !!findPermissions?.length,
          permissions: [
            { name: 'create', cod: 'C', checked: !!findPermissions?.find((permission) => permission.cod === 'C')?.cod },
            { name: 'read', cod: 'R', checked: !!findPermissions?.find((permission) => permission.cod === 'R')?.cod },
            { name: 'update', cod: 'U', checked: !!findPermissions?.find((permission) => permission.cod === 'U')?.cod },
            { name: 'delete', cod: 'D', checked: !!findPermissions?.find((permission) => permission.cod === 'D')?.cod }
          ]
        }
      } else {
        if (item?.name === 'DASHBOARD') {
          return {
            _id: item?._id ?? '',
            name: item?.name,
            cod: item?.cod,
            expanded: false,
            checked: false,
            permissions: [{ name: 'read', cod: 'R', checked: false }]
          }
        }

        return {
          _id: item?._id ?? '',
          name: item?.name,
          cod: item?.cod,
          expanded: false,
          checked: false,
          permissions: [
            { name: 'create', cod: 'C', checked: false },
            { name: 'read', cod: 'R', checked: false },
            { name: 'update', cod: 'U', checked: false },
            { name: 'delete', cod: 'D', checked: false }
          ]
        }
      }
    })

    setModules(preparedModules)
  }, [])

  useEffect(() => {
    // setModules(props.modules?.map((item) => ({
    //   ...item,
    //   expanded: false,
    //   checked: false,
    //   permissions: item.permissions?.map((permission) => ({
    //     ...permission,
    //     checked: false
    //   }))
    // })))
    loadModules()
  }, [loadModules])

  const changeModules = useCallback(() => {
    const currentModules = modules?.reduce((acc: any[], item) => {
      const permissions = item.permissions.reduce((accPerm: any[], permission) => {
        if (permission?.checked) {
          return [...accPerm, { name: permission.name, cod: permission.cod }]
        }

        return accPerm
      }, [])

      if (permissions?.length > 0) {
        return [...acc, { name: item.name, cod: item.cod, permissions }]
      }

      return acc
    }, [])

    props.onChange(currentModules)
  }, [modules])

  useEffect(() => {
    changeModules()
  }, [modules])

  const handleExpand = (cod: string) => {
    // setExpanded(isExpanded ? cod : false)
    setModules((state) =>
      state?.map((item) =>
        item.cod === cod ? { ...item, expanded: !item?.expanded } : item
      )
    )
  }

  const handleCheckAndExpand = (cod: string, checked: boolean) => {
    // setExpanded(isExpanded ? cod : false)
    setModules((state) =>
      state?.map((item) =>
        item.cod === cod
          ? {
              ...item,
              expanded: checked,
              checked: checked,
              permissions: item.permissions.map((perm) => ({
                ...perm,
                checked: checked
              }))
            }
          : item
      )
    )
  }

  const handleCheckPermission = (cod: string, permissionCod: string) => {
    // setExpanded(isExpanded ? cod : false)
    setModules((state) =>
      state?.map((item) =>
        item.cod === cod
          ? {
              ...item,
              permissions: item.permissions.map((perm) =>
                perm.cod === permissionCod
                  ? { ...perm, checked: !perm?.checked }
                  : perm
              )
            }
          : item
      )
    )
  }

  const getModuleName = (cod: string) => {
    if (cod === 'create') return 'Cadastrar'
    if (cod === 'read') return 'Visualizar'
    if (cod === 'update') return 'Atualizar'
    if (cod === 'delete') return 'Deletar'
    return ''
  }

  return (
    <>
      <Card style={{ width: '100%' }}>
        <CardBlue>
          <TextCardBlue>Módulos</TextCardBlue>
          {/* <Input placeholder='Pesquisar módulo' /> */}
        </CardBlue>
        <CardContent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {modules.map((item) => (
            <List
              sx={{ width: '100%', maxWidth: 250, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton disabled={props?.disabled} onClick={() => handleExpand(item.cod)}>
                {item?.expanded
? (
                  <ArrowDropDown htmlColor="#00008B" />
                )
: (
                  <ArrowRight htmlColor="#00008B" />
                )}
                <ListItemIcon>
                  <Checkbox
                    checked={item?.checked}
                    onChange={(_, checked) => handleCheckAndExpand(item.cod, checked)}
                  />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
              <Collapse in={item?.expanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ pl: 4 }}>
                  {item.permissions.map((perm) => (
                    <ListItemButton
                      disabled={props?.disabled}
                      onClick={() => handleCheckPermission(item.cod, perm.cod)}
                    >
                      <ListItemIcon>
                        <Checkbox checked={perm?.checked} />
                      </ListItemIcon>
                      <ListItemText primary={getModuleName(perm.name) } />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </List>
          ))}
        </CardContent>

      </Card>
    </>
  )
}

export default AccordeonModules
