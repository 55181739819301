/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Stack, TextField, Button as MuiButton, Box } from '@mui/material'
import Button from 'components/form/Button'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

function Header(props: any) {
  const [date, setDate] = React.useState<Date | null>(new Date())
  const [selectedMonths, setSelectedMonths] = useState<number[]>([moment().get('month')])

  // useEffect(() => {
  //   props.months(selectedMonths)
  // }, [selectedMonths])

  return (
    <>
      <Stack direction="row" spacing={3} width='100%'>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <DatePicker
            views={['year']}
            label="Ano"
            value={date}
            onChange={(newValue) => {
              setDate(newValue)
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                autoComplete="off"
                helperText={null}
              />
            )}
          />
        </LocalizationProvider>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent='flex-end'
          gap={2}
          flexWrap="wrap"
          marginY={2}
          width='100%'
        >
          {months.map((month, index) => {
            return selectedMonths.includes(index) ? (
              <MuiButton
                variant="contained"
                size="small"
                onClick={() =>
                  setSelectedMonths((state) =>
                    state.filter((value) => value !== index)
                  )
                }
              >
                {month}
              </MuiButton>
            ) : (
              <MuiButton
                variant="outlined"
                size="small"
                onClick={() => setSelectedMonths((state) => [...state, index])}
              >
                {month}
              </MuiButton>
            )
          })}
        </Box>
      </Stack>
      <Stack flex={1} marginTop={3} direction="row" justifyContent="flex-end">
        <Button
          onClick={() => {
            props.dateSearch(date)
            props.months(selectedMonths)
          }}
          style={{ height: '40px', width: '110px' }}
          mold="primary"
          disabled={!selectedMonths.length}
        >
          Pesquisar
        </Button>
      </Stack>
    </>
  )
}

export default Header
