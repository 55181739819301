import React from 'react'
import {
  BarChart,
  Bar,
  YAxis,
  Cell,
  Tooltip,
  CartesianGrid
} from 'recharts'
import styled from 'styled-components'
import ChartBottomLabel from './ChartBottomLabel'

// const CustomizedLabel = (props: any) => {
//   const { x, y, fill, value } = props

//   return (
//     <text
//       x={x}
//       y={y}
//       dy={-4}
//       dx={50}
//       fontSize="16"
//       fontFamily="sans-serif"
//       fill={fill}
//       textAnchor="middle"
//     >
//       {value}
//     </text>
//   )
// }

const RowCV = styled.div`
display: flex;
align-items: center;
justify-content: center;
`
const LabelTitle = styled.div`
 display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

const CustomTooltip = ({ active, title, payload, event }) => {
  if (active && payload && payload.length) {
    const TooltipContent = styled.div`
    background-color: white;
    padding: 5px;
    border: 1px solid ${payload[0].payload.color};
    border-radius: 5px;
    `
    return (
      <TooltipContent>
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>{`${title}`}</p>
        <p style={{ fontSize: 12 }}>{`${payload[0].value.toLocaleString('pt-br')}`}</p>
      </TooltipContent>
    )
  }

  return null
}

const formatTick = (value) => {
  let formatted = value
  if (value > 1000000000) {
    formatted = `${(value / 1000000000)}B`
  } else if (value > 1000000) {
    formatted = `${(value / 1000000)}M`
  } else if (value > 1000) {
    formatted = `${(value / 1000)}K`
  }

  return formatted
}

export default function ChartBar(props: any) {
  return (
    <div className="app">
      <LabelTitle>
        <p>{props.title}</p>
        <p style={{ fontSize: '11px' }}>Mês {props.month}</p>
      </LabelTitle>
      <div className="main">
        <RowCV>
          <BarChart
            width={280}
            height={240}
            data={props.data}
            margin={{ top: 25, right: 5, bottom: 25, left: 20 }}
          >
            {/* <XAxis dataKey="Text" fontFamily="sans-serif" dy="25" /> */}
            <Tooltip content={event => <CustomTooltip active={event.active} title={props.title} payload={event.payload} event={event} />} />
            <CartesianGrid vertical={false} />
            <YAxis tickFormatter={(value) => formatTick(value)} />
            <Bar
              dataKey="value"
              barSize={170}
              fontFamily="sans-serif"
            // label={<CustomizedLabel />}
            >
              {
                props.data.map(d => (
                  <Cell fill={d.color} />
                ))
              }
            </Bar>
          </BarChart>
        </RowCV>
        <RowCV>
          {
            props.data.map(d => (
              <ChartBottomLabel title={d.title} color={d.color} />
            ))
          }
        </RowCV>
      </div>
    </div>
  )
}
