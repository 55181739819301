import React from 'react'
import { styled } from '@mui/system'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TableBalanceStock from '../components/TableBalanceStock'
import EstoqueTable from './EstoqueTable'

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: black;
  cursor: pointer;
  font-size: 0.815rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 0px;
  display: flex;

  &:focus {
    border-radius: 0px;
    outline: 2px solid white;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #4060e3;
    color: white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: #fafafa;
  border-radius: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`
interface Props {
  date: string
  multipleDate?: any
}

const ModulesEstoque: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  return (
    <>
      <TabsUnstyled defaultValue={0}>
        <Box sx={{ width: '357px' }}>
          <TabsList>
            <Tab>Estoque</Tab>
            <Tab>Saldo de Estoque</Tab>
          </TabsList>
        </Box>
        <TabPanel value={0}>
          <EstoqueTable date={props.date} />
        </TabPanel>
        <TabPanel value={1}>
          <TableBalanceStock
            date={props.date}
            multipleDate={props.multipleDate}
          />
        </TabPanel>
      </TabsUnstyled>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '30px'
        }}
      >
        <Button
          style={{
            backgroundColor: '#4060E6',
            borderRadius: '2px',
            width: '122px',
            color: 'white'
          }}
          onClick={() => navigate('/home/report')}
        >
          Voltar
        </Button>
      </div>
    </>
  )
}

export default ModulesEstoque
