/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ArrowBack,
  ExpandMore
} from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Chip, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TextField } from '@mui/material'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import React, { ChangeEvent, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { updateItemBudgetRealized, createItemBudgetRealized } from 'services/budgetRealized/BudgetRealizedService'
import { BudgetItem } from 'types/budgetRealized/BudgetRealizedType'
import { contasContabeis } from 'constants/contasContabeis'

function BudgetConfigCreateItemForm(props: any) {
  const [auto] = useState<any[]>(contasContabeis ?? [])
  const [contaInput, setContaInput] = React.useState('0')
  const CREATE_ITEM_MUTATION_KEY = 'createItemBudgetRealized'
  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(CREATE_ITEM_MUTATION_KEY, createItemBudgetRealized, {
    onSuccess: (data, variables) => {
      setFieldValue('categories[0].items', data?.items)
      props.onSave(data?.items)
      toast.success('Item cadastrado com sucesso')
      props.close()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível cadastrar')
      }
    }
  })

  console.log('Create Item Form', props)
  console.log('Autocomplete', auto)
  const initialValues = {
    _id: props.edit?.data?._id || 0,
    account: props.edit?.data?.account || 0,
    launchPercentage: props.edit?.data?.launchPercentage || 0,
    describeAccount: props.edit?.data?.describeAccount || 'Não definido'
  }

  console.log('initialValues', initialValues)
  const createValuesForm = (values) => {
    return {
      _id: values._id,
      account: values.account,
      launchPercentage: values.launchPercentage,
      describeAccount: values.describeAccount
    }
  }

  const onSubmit = (values: BudgetItem) => {
    if (props.edit) {
      const valuesForm = {
        ...createValuesForm(values)
      }
      mutateCreate({ item: valuesForm, id: props.edit?._id })
    }
  }
  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik({
    onSubmit,
    enableReinitialize: true,
    /* validationSchema: validationSchemaMaterial, */
    initialValues
  })

  React.useEffect(() => {
    console.log('ok', errors)
  }, [])

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip title='Voltar' placement='top' onClick={() => { props.close() }}>
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>Criar Item</div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px', flex: 1 }}>
        <form autoComplete="off" onSubmit={handleSubmit} >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginRight: '30px',
            marginTop: '20px'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px',
                  width: '100%'
                }}
              >
                <Grid container spacing={2} sx={{ backgroundColor: '#F8F8F8' }}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="auto-account"
                      freeSolo
                      isOptionEqualToValue={(option, value) => option.conta === value.conta}
                      options={contasContabeis.map((conta) => ({
                        conta: conta[0],
                        descricao: conta[1]
                      }))}
                      value={contaInput === '0' ? { conta: values.account, descricao: values.describeAccount } : { conta: contaInput, descricao: 'Não Definido' }}
                      getOptionLabel={(option) => option?.conta}
                      onChange={(e, newValue: any) => {
                          setFieldValue('account', newValue.conta ?? 0)
                          setContaInput(newValue.conta)
                          setFieldValue('describeAccount', newValue.descricao ?? 'Não Definido')
                      }}
                      inputValue={contaInput} // Add this line to prevent resetting on input change
                      onInputChange={(e, newInputValue) => {
                        setContaInput(newInputValue)
                        setFieldValue('account', newInputValue)
                      }}
                      renderInput={(params) => <TextField variant="standard" {...params} label="Conta" required/>}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      variant="standard"
                      fullWidth
                      sx={{ marginBottom: '16px' }}
                      id="text-launchPercentage"
                      label="Porcentagem (%)"
                      value={values.launchPercentage}
                      type="number"
                      onChange={(e) => { setFieldValue('launchPercentage', e.target.value) }}
                      style={{ color: '#A8A8A8' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      variant="standard"
                      fullWidth
                      sx={{ marginBottom: '16px' }}
                      id="text-describeAccount"
                      label="Descrição Contábil"
                      value={values.describeAccount}
                      onChange={(e) => { setFieldValue('describeAccount', e.target.value) }}
                      style={{ color: '#A8A8A8' }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type='button'
              mold='red'
              onClick={() => { props.close() }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold='primary'
              type="submit"
              disabled={isLoadingCreate}
            >
              SALVAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default BudgetConfigCreateItemForm
