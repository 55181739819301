/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import {
  Table,
  TablePagination,
  Box,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  FormGroup,
  FormControlLabel,
  Switch,
  Chip,
  Typography,
  TextField,
  IconButton,
  Stack
} from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import SyncIcon from '@mui/icons-material/Sync'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import StatusView from 'components/view/StatusView'
import FeedIcon from '@mui/icons-material/Feed'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import MoreMenu from 'components/view/MoreMenu'
import CustomerControlType from 'types/customerControl/CustomerControlType'
import { maskCpfOrCnpj } from 'helpers/maskHelper'
import moment from 'moment'
import { ActionButton } from 'components/view/styles/style-more-menu'
import LightTooltip from 'components/tooltips/LightTooltip'
import { useNavigate } from 'react-router-dom'
import { Savings, Clear, SearchOutlined } from '@mui/icons-material'

import {
  getClient,
  synchronizeClient,
  updateStatusBlockClient,
  updateStatusClient
} from 'services/customerControl/CustomerControlServices'
import { useQuery } from 'react-query'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined'
import ModalLinkSeller from '../modal/ModalLinkSeller'
import { updateClient } from 'services/customerControl/customer-control.services'
import { toast } from 'react-toastify'
import LoadingTableCustomer from './LoadingTableCustomer'
import ModalBlockClient from '../modal/ModalBlockClient'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import ClearIcon from '@mui/icons-material/Clear'
import NoDataTableCustomer from './NoDataTableCustomer'
import {
  Approved,
  Blocked,
  Disapproved,
  New,
  Pending,
  Td,
  StyledTableRow,
  Unlocked
} from './tableCustomerControl.style'
import { ModalComponent } from 'components/Modal'
import { FinancialAnalysis } from './FinancialAnalysis'
import { FormatPrice } from 'utils/FormatPrice'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { ClientType } from './ClientType'

import Button from 'components/form/Button'

import ModalSerasa from '../modal/ModalSerasa'
import serasa from '../../../assets/img/icone_serasa.png'
import { httpClient } from 'config/httpClient'
import { TableHeadCustomer } from './table/TableHeadCustomer'
import { StatusDocument } from './table/StatusDocument'
import { CheckStatusDocument } from './table/CheckStatusDocument'
import { RootState } from 'core/store'
import { useSelector } from 'react-redux'
import { ButtonViewCredit } from './ButtonViewCredit'

const TableCustomerControl: React.FC = () => {
  const navigate = useNavigate()
  const { loginData } = useSelector((state: RootState) => state.login)
  console.log('🚀 ~ file: TableCustomerControl.tsx ~ line 92 ~ loginData', loginData?.tokenData)

  const modalFinancialAnalysisRef = useRef<any>(null)
  const modalClientTypeRef = useRef<any>(null)
  const modalCodMnemosineRef = useRef<any>(null)
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const inputSearchCodRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')
  const [searchCod, setSearchCod] = useState('')

  const [rows, setRows] = useState<CustomerControlType[]>([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(1)
  const [openBlockModal, setOpenBlockModal] = useState<boolean>(false)
  const [clientBlockModal, setClientBlockModal] = useState<any>()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [clientModal, setClientModal] = useState<any>()

  const [synchronizeData, setSynchronizeData] = useState<any>(null)

  const [openSerasa, setOpenSerasa] = useState<boolean>()
  const [dataSerasa, setDataSerasa] = useState<any>()
  const [searchValue, setSearchValue] = useState('')
  const [searchCodValue, setSearchCodValue] = useState('')

  const [synchronizeSellers, setSynchronizeSellers] = useState(false)

  const [openId, setOpenId] = useState(false)

  const { data, status, refetch, isFetching } = useQuery(
    ['clients', page, search, searchCod],
    async () =>
      getClient({
        page,
        search,
        searchCod,
        limit,
        offset
      }),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (status === 'success') {
      setRows(data?.docs ?? [])
      setTotal(data?.total ?? 0)
    }

    return () => {
      setRows([])
      setTotal(0)
    }
  }, [data?.docs, isFetching])

  useEffect(() => {
    if (search?.length || searchCod?.length) {
      refetch()
    }
  }, [search, searchCod])

  useEffect(() => {
    refetch()
  }, [limit])

  const edit = (row: CustomerControlType) => {
    navigate('/home/customer-control/form', { state: { client: row } })
  }

  const handleClearSearch = () => {
    setSearch('')
    setSearchCod('')
    setSearchValue('')
    setSearchCodValue('')
    setPage(0)
    setOffset(0)
    if (inputSearchRef?.current?.value) {
      inputSearchRef.current.value = ''
    }
    if (inputSearchCodRef?.current?.value) {
      inputSearchCodRef.current.value = ''
    }
    refetch()
  }

  const handleSearch = () => {
    setPage(0)
    setSearch(searchValue)
    setSearchCod(searchCodValue)

    setTimeout(() => {
      refetch()
    }, 300)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    refetch()
  }

  const handleCloseBlockModal = () => {
    setOpenBlockModal(false)
    refetch()
  }

  const handleChangeBlock = async (companyId) => {
    try {
      await updateStatusBlockClient(companyId)
    } catch (error: any) {
      console.log('error', error)
      toast.error(error?.response?.data?.message)
    }
    refetch()
  }

  const handleSynchronize = async (companyId) => {
    try {
      await synchronizeClient(companyId)
      toast.success('Cliente atualizado com sucesso!')
      refetch()
    } catch (error: any) {
      console.log('error', error)
      toast.error(error?.response?.data?.message)
    } finally {
      setSynchronizeData(null)
    }
  }

  const Actions = ({ item }: { item: CustomerControlType }) => {
    return (
      <>
        {synchronizeData?._id === item?._id && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <LightTooltip title="Sincronizando dados..." placement="top">
              <CircularProgress size={18} />
            </LightTooltip>
          </Box>
        )}

        {synchronizeData?._id !== item?._id && (
          <MoreMenu
            module={MODULES.CLIENTES}
            isValidationPermissions
            edit={() => edit(item)}
          >
            <LightTooltip title="Documentos" placement="top">
              <ActionButton
                onClick={() =>
                  navigate('/home/customer-control/documentation', {
                    state: { client: item }
                  })
                }
              >
                <FeedIcon />
              </ActionButton>
            </LightTooltip>
            <LightTooltip title="Análise crédito" placement="top">
              <ActionButton
                onClick={() => {
                  modalFinancialAnalysisRef?.current?.onOpen()
                  setClientModal(item)
                }}
              >
                <AttachMoneyOutlinedIcon />
              </ActionButton>
            </LightTooltip>
            <LightTooltip title="Vincular" placement="top">
              <ActionButton
                onClick={() => {
                  setOpenModal(true)
                  setClientModal(item)
                }}
              >
                <CompareArrowsIcon />
              </ActionButton>
            </LightTooltip>
            <LightTooltip title="Informações" placement="top">
              <ActionButton
                onClick={() => {
                  // setOpenModal(true)
                  setClientModal(item)
                  modalClientTypeRef?.current?.onOpen()
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  fill="#FFF"
                >
                  <path d="M3.475 18.5q-.9 0-1.5-.6t-.6-1.5V7.6q0-.9.6-1.5t1.5-.6H12.3q.9 0 1.488.6.587.6.587 1.5v8.8q0 .9-.587 1.5-.588.6-1.488.6Zm-.6-3.1q1.1-.7 2.35-1.075t2.65-.375q1.4 0 2.65.375t2.35 1.075V7.6q0-.25-.162-.425Q12.55 7 12.3 7H3.475q-.25 0-.425.175t-.175.425Zm5 .05q-1.075 0-2.15.312-1.075.313-2.05.913.025.15.188.238.162.087.337.087h7.375q.175 0 .325-.087.15-.088.25-.238-.975-.575-2.062-.9Q9 15.45 7.875 15.45Zm0-2.35q-1.05 0-1.813-.763Q5.3 11.575 5.3 10.5t.762-1.838Q6.825 7.9 7.875 7.9q1.075 0 1.838.762.762.763.762 1.838 0 1.075-.762 1.837-.763.763-1.838.763Zm0-1.4q.5 0 .85-.35t.35-.85q0-.5-.35-.85t-.85-.35q-.475 0-.825.35t-.35.85q0 .5.35.85t.825.35ZM17 18.5v-13h1.5v13Zm4.125 0v-13h1.5v13Zm-13.25-8Zm.05 6.5Z" />
                </svg>
              </ActionButton>
            </LightTooltip>

            <LightTooltip title="Sincronizar dados" placement="top">
              <ActionButton
                onClick={() => {
                  setSynchronizeData(item)
                  void handleSynchronize(item?._id)
                }}
                disabled={!!synchronizeData?._id}
              >
                <SyncIcon />
              </ActionButton>
            </LightTooltip>

            <LightTooltip title="Serasa" placement="top">
              <ActionButton
                onClick={() => {
                  setOpenSerasa(true)
                  setDataSerasa(item)
                }}
              >
                <img src={serasa} />
              </ActionButton>
            </LightTooltip>

            <LightTooltip title="Copiar código Mnemosine" placement="top">
              <ActionButton
                onClick={() => {
                  setClientModal(item)
                  modalCodMnemosineRef?.current?.onOpen()
                }}
              >
                ID
              </ActionButton>
            </LightTooltip>
          </MoreMenu>
        )}
      </>
    )
  }

  const handleCloseFinancialAnalysis = () => {
    modalFinancialAnalysisRef?.current?.onClose()
  }

  const handleCloseFinancialAnalysisSuccess = () => {
    refetch()
    modalFinancialAnalysisRef?.current?.onClose()
  }

  const handleCloseClientType = () => {
    modalClientTypeRef?.current?.onClose()
  }

  const handleCloseClientTypeSuccess = () => {
    refetch()
    modalClientTypeRef?.current?.onClose()
  }

  const handleRefetch = () => {
    refetch()
  }

  const handleSynchronizeSellers = async () => {
    setSynchronizeSellers(true)
    try {
      await httpClient.get('/company/synchronize-sellers/ln')

      toast.success('Vendedores sincronizados com sucesso!')
      refetch()
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          'Não foi possível sincronizar os vendedores.'
      )
    } finally {
      setSynchronizeSellers(false)
    }
  }

  return (
    <>
      <Grid container spacing={3} mt={0}>
        <Grid item xs={3}>
          <FormControl variant="standard" className="w-100">
            <InputLabel htmlFor="field-search">
              Pesquisar por cliente
            </InputLabel>
            <Input
              id="field-search"
              autoComplete="off"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              inputRef={inputSearchRef}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" className="w-100">
            <InputLabel htmlFor="field-search">
              Pesquisar por Cod. Parceiro
            </InputLabel>
            <Input
              id="field-search"
              autoComplete="off"
              type="text"
              value={searchCodValue}
              onChange={(e) => setSearchCodValue(e.target.value)}
              inputRef={inputSearchCodRef}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button
            mold="primary"
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={handleSearch}
            disabled={isFetching}
          >
            <SearchOutlined />
          </Button>

          {(!!search?.length ||
            !!searchCod?.length ||
            !!inputSearchRef?.current?.value?.length ||
            !!inputSearchCodRef?.current?.value?.length) && (
            <Button
              mold="red"
              type="button"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 4
              }}
              onClick={handleClearSearch}
              disabled={isFetching}
            >
              <Clear />
            </Button>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            mold="primary"
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 38
            }}
            onClick={handleSynchronizeSellers}
            disabled={synchronizeSellers}
          >
            {synchronizeSellers
              ? 'Aguarde, sincronizando...'
              : 'Sincronizar Vendedores'}
          </Button>
        </Grid>
      </Grid>

      <LoadingTableCustomer
        tableHead={<TableHeadCustomer />}
        loading={status === 'loading'}
      />

      {status !== 'loading' && (
        <>
          <TableContainer style={{ marginTop: '30px' }}>
            {isFetching && (
              <Box display="flex" alignItems="center">
                <CircularProgress size={18} sx={{ marginRight: 1 }} />
                <Typography component="span" variant="body2" color="primary">
                  Carregando...
                </Typography>
              </Box>
            )}

            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHeadCustomer />

              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={`KEY_BODY_${index}-${row?._id}`}>
                    {/* <Td component="th" scope="row">
                      <Typography component="span" variant="body2" noWrap>
                        {row?.type === 'PF'
                          ? maskCpfOrCnpj(row?.pf?.cpf ?? '')
                          : maskCpfOrCnpj(row?.pj?.cnpj ?? '')}
                      </Typography>
                    </Td> */}

                    <Td component="th" scope="row">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {row?.ies?.map((ie) => (
                          <Typography
                            key={ie?._id}
                            component="span"
                            variant="body2"
                            fontWeight="bold"
                          >
                            {ie?.codeLn}
                          </Typography>
                        ))}
                      </Box>
                    </Td>
                    <Td>{row.name}</Td>
                    <Td>{row.isActive ? 'Ativo' : 'Inativo'}</Td>
                    <Td>{row?.pilotLot ? 'Sim' : 'Não'}</Td>
                    <Td>
                      <Stack>
                        {row?.types?.map((item) => (<span key={item}>{item}</span>))}
                        {!row?.types?.length && '-'}
                      </Stack>
                    </Td>
                    <Td>{row.seller ? row.seller?.user?.name : ''}</Td>
                    <Td>
                      <CheckStatusDocument item={row} />
                    </Td>
                    <Td>
                      <ButtonViewCredit data={row} />
                      {/* <Typography variant="body2" component="span" noWrap>
                        {row?.currentBalance || row?.currentBalance === 0
                          ? FormatPrice(row?.currentBalance)
                          : ''}
                        </Typography> */}
                    </Td>
                    <Td>
                      <Can
                        module={MODULES.CLIENTES}
                        permissions={[PERMISSIONS.DELETE]}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={<Switch checked={row.isBlocked} />}
                            label={row.isBlocked ? 'Bloqueado' : 'Inativo'}
                            onChange={async () => handleChangeBlock(row._id)}
                            // onChange={async () => handleChangeStatus(row._id)}
                            sx={{ '& span': { fontSize: 14 } }}
                          />
                        </FormGroup>
                      </Can>
                    </Td>
                    <Td>
                      <Actions item={row} />
                    </Td>
                  </StyledTableRow>
                ))}

                <NoDataTableCustomer loading={false} data={rows} />
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 30, 50, 100]}
              rowsPerPage={limit}
              component="div"
              count={total}
              page={page}
              onPageChange={(event: unknown, newPage: number) => {
                console.log(newPage)
                setOffset(limit * (newPage + 1) - limit)
                setPage(newPage)
              }}
              onRowsPerPageChange={(event) =>
                setLimit(Number(event.target.value))
              }
              labelRowsPerPage="Exibir"
            />
          </TableContainer>

          {openModal && (
            <ModalLinkSeller
              data={clientModal}
              open={openModal}
              close={() => handleCloseModal()}
            />
          )}

          {openBlockModal && (
            <ModalBlockClient
              data={clientBlockModal}
              open={openBlockModal}
              close={() => handleCloseBlockModal()}
            />
          )}

          <ModalComponent
            maxWidth="lg"
            fullWidth
            aria-labelledby="financialAnalysis"
            ref={modalFinancialAnalysisRef}
          >
            <FinancialAnalysis
              data={clientModal}
              close={handleCloseFinancialAnalysis}
              closeSuccess={handleCloseFinancialAnalysisSuccess}
            />
          </ModalComponent>

          <ModalComponent
            maxWidth="md"
            fullWidth
            aria-labelledby="information"
            ref={modalClientTypeRef}
          >
            <ClientType
              data={clientModal}
              close={handleCloseClientType}
              closeSuccess={handleCloseClientTypeSuccess}
            />
          </ModalComponent>

          <ModalComponent
            maxWidth="sm"
            fullWidth
            aria-labelledby="codigo-mnemosine"
            ref={modalCodMnemosineRef}
          >
            <Box sx={{ padding: 3, borderRadius: 2 }}>
              <Typography fontWeight="bold" variant="body2">
                Empresa:
              </Typography>
              <Typography fontWeight="bold">{clientModal?.name}</Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Typography>Código Mnemosine:</Typography>
                <Typography
                  fontWeight="bold"
                  color="#4060e6"
                  sx={{ marginLeft: 1 }}
                >
                  {clientModal?._id}
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: 4,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  type="button"
                  onClick={() => modalCodMnemosineRef?.current?.onClose()}
                >
                  Fechar
                </Button>
              </Box>
            </Box>
          </ModalComponent>

          {openSerasa && (
            <ModalSerasa
              open={openSerasa}
              data={dataSerasa}
              close={() => setOpenSerasa(false)}
              refetch={handleRefetch}
            />
          )}
        </>
      )}
    </>
  )
}

export default TableCustomerControl
