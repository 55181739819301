import React from 'react'
import { Pie, PieChart, Tooltip } from 'recharts'
import ChartBottomLabel from './ChartBottomLabel'
import { LabelTitle, RowCV, TooltipContent } from './chartPie.style'

const CustomTooltip = ({ active, title, payload, event }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContent color={payload[0].payload.fill}>
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>{`${payload[0].payload.title}`}</p>
        <p style={{ fontSize: 12 }}>{`${payload[0].value}`}</p>
      </TooltipContent>
    )
  }

  return null
}

function ChartPie(props: any) {
  return (
    <div className="app">
      <LabelTitle>
        <p>{props.title}</p>
        {/* <p style={{ fontSize: '11px' }}>Mês {props.month}</p> */}
      </LabelTitle>
      <div className="main">
        <RowCV>
          <PieChart width={240} height={240} margin={{ top: 25, right: 25, bottom: 25, left: 25 }}>
            <Tooltip content={event => <CustomTooltip active={event.active} title={props.title} payload={event.payload} event={event} />} />
            <Pie data={props.data} dataKey="value" nameKey="title" cx="50%" cy="50%" innerRadius={50} outerRadius={90} />
          </PieChart>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {props.data.map(d => (
              <ChartBottomLabel title={`${d.title} (${d.value})`} color={d.fill} />
            ))}
          </div>
        </RowCV>
      </div>
    </div>
  )
}

export default ChartPie
