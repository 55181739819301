import React, { useCallback } from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Typography,
  Box
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { Search } from '@mui/icons-material'
import { getStatusValue } from 'types/Report/RequestForProposalsType'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'

interface Props {
  status: string
  date?: any
  changeFilters: (field: string, value: string) => void
}

const Filters: React.FC<Props> = ({ status, date, changeFilters }) => {
  const statusOptions = getStatusValue()

  const debounce = useCallback((func: any, wait: number): any => {
    let timeout: NodeJS.Timeout

    return function executedFunction(...args: number[]) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }, [])

  const returnedFunction = debounce((event: any, field: string) => {
    if (event?.target?.value) {
      changeFilters(field, event.target.value)
    } else {
      console.log('CLEAR')
      changeFilters(field, '')
    }
  }, 400)

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={6} style={{ display: 'flex' }}>
          <TextField
            label="Pesquisar por cliente"
            variant="standard"
            fullWidth
            onKeyUp={(event) => returnedFunction(event, 'search')}
            onKeyPress={(event) => returnedFunction(event, 'search')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          <TextField
            label="Pesquisar por vendedor"
            variant="standard"
            fullWidth
            onKeyUp={(event) => returnedFunction(event, 'searchSeller')}
            onKeyPress={(event) => returnedFunction(event, 'searchSeller')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={4} style={{ display: 'flex' }}>
          <TextField
            label="Pesquisar por FT"
            variant="standard"
            fullWidth
            onKeyUp={(event) => returnedFunction(event, 'searchFT')}
            onKeyPress={(event) => returnedFunction(event, 'searchFT')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            style={{ minWidth: '100%', marginTop: 24 }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="standard" style={{ minWidth: '100%', marginTop: 24 }}>
            <InputLabel id="status">Status</InputLabel>
            <Select
              labelId="status"
              value={status}
              label="Status"
              fullWidth
              onChange={({ target }) => changeFilters('status', target.value)}
            >
              <MenuItem value={'all'}>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{
                    textTransform: 'lowercase',
                    '::first-letter': {
                      textTransform: 'uppercase'
                    }
                  }}
                >
                  Todos
                </Typography>
              </MenuItem>
              {statusOptions.map((option, index) => (
                <MenuItem key={`key_option_status_${index}`} value={option}>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      textTransform: 'lowercase',
                      '::first-letter': {
                        textTransform: 'uppercase'
                      }
                    }}
                  >
                    {option}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ paddingTop: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              views={['year', 'month']}
              label="Mês e ano"
              inputFormat="MMMM yyyy"
              value={date ?? null}
              onChange={(value) => {
                changeFilters('date', value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    startAdornment: (
                      <Box paddingRight={2}>
                        {params?.InputProps?.endAdornment}
                      </Box>
                    )
                  }}
                  className="w-100"
                  variant="standard"
                  autoComplete="off"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  )
}

export default Filters
