import { httpClient, apiTrimbox } from 'config/httpClient'
import { BudgetDataType } from 'types/ft/BudgetDataType'
import { FTProposalType } from 'types/ft/FTProposalType'
import { FTSampleType } from 'types/ft/FTSampleType'

const encrypt = (text: string) => {
  const encrypted: string = window.btoa(apiTrimbox + text)
  return encrypted.replaceAll('/', '---')
}

export const getPreFTS = async (type: string): Promise<any[]> => {
  const { data } = await httpClient.get(`/prefts?type=${type}`)
  return data as any[]
}

export const putApprovePreFT = async (id: string): Promise<any[]> => {
  const { data } = await httpClient.put(`/prefts/${id}/approved`)
  return data as any[]
}

export const putPreFTBudgetData = async (
  id: string,
  extraBudgetData: any
): Promise<any[]> => {
  const { data } = await httpClient.put(
    `/prefts/${id}/budgetData`,
    extraBudgetData
  )
  return data as any[]
}

export const putDisapprovePreFT = async (
  id: string,
  motive: string
): Promise<any[]> => {
  const { data } = await httpClient.put(`/prefts/${id}/fail`, {
    descriptionFail: motive
  })
  return data as any[]
}

export const postFT = async (referenceData: any): Promise<any> => {
  const { data } = await httpClient.post('/ft', {
    itemReference: referenceData.itemReference,
    preFT: referenceData.preFT,
    status: referenceData.status
  })
  return data
}

export const postProposal = async (
  proposalForm: FTProposalType
): Promise<any> => {
  const { data } = await httpClient.post('/budget/proposal', proposalForm)
  return data
}

export const putApproveProposal = async (proposalForm: any): Promise<any> => {
  const { data } = await httpClient.put(
    `/budget/proposal/${proposalForm?._id}`,
    {
      price: proposalForm?.price,
      comment: proposalForm?.comment ?? '',
      status: 'APROVADA PELA CONTROLADORIA'
    }
  )
  return data
}

export const putReproveProposal = async (
  id: string,
  comment: string
): Promise<any> => {
  const { data } = await httpClient.put(`/budget/proposal/${id}`, {
    status: 'REPROVADA PELA CONTROLADORIA',
    comment
  })
  return data
}

export const postSample = async (sampleForm: FTSampleType): Promise<any> => {
  const { data } = await httpClient.post('/ft/amostra', sampleForm)
  return data
}

export const postBudget = async (budgetForm: BudgetDataType): Promise<any> => {
  const { data } = await httpClient.post('/budget', budgetForm)
  return data
}

export const getFTsTrimbox = async (
  page: number,
  firstStatus: string,
  secondStatus: string,
  search?: string
): Promise<any> => {
  const url = encrypt(
    `ft/search?page=${page}&limit=20&status=${firstStatus}&orStatus=${secondStatus}&orderBy=dataCriacao&direction=DESC&search=${
      search ?? ''
    }`
  )
  console.log('url => ', url)
  const { data } = await httpClient.get(
    `/ft/access-trimbox/getFts/${url}`
  )
  return data
}

export const getFTsTrimboxInMongo = async (items: any[]): Promise<any[]> => {
  const { data } = await httpClient.get(`ft/ft-trimbox/${items}`)
  return data
}

export const getFTsByPreFTTrimbox = async (
  status: string,
  preFTs: any[]
): Promise<any> => {
  const justIds = preFTs.map((preft) => {
    return { _id: preft._id }
  })
  const url = encrypt(`ft/ftsByPreFT?status=${status}`)
  const { data } = await httpClient.post(
    `/ft/accessExternalService/trimbox/${url}`,
    justIds
  )
  return data
}

export const getOrdersProductionByFTTrimbox = async (
  codFT: string
): Promise<any> => {
  const url = encrypt(`ft/orderProductionByFT?cod=${codFT}`)
  const { data } = await httpClient.get(
    `/ft/accessExternalService/trimbox/${url}`
  )
  return data
}

export const getFTs = async (userId: string | undefined = undefined): Promise<any[]> => {
  const { data } = await httpClient.get('/ft', {
    params: {
      userId: userId
    }
  })
  console.log('getFTs ', data)
  return data as any[]
}

export const getAmostras = async (idFT: string): Promise<any[]> => {
  const { data } = await httpClient.get(`/ft/amostras/${idFT}`)
  return data as any[]
}

export const getProposals = async (idBudget: string): Promise<any[]> => {
  const { data } = await httpClient.get(`/budget/proposals/${idBudget}`)
  return data as any[]
}

export const getFT = async (idFT: string): Promise<any> => {
  const { data } = await httpClient.get(`/ft/${idFT}`)
  return data
}

export const getFTByReference = async (idReference: string): Promise<any> => {
  const { data } = await httpClient.get(`/ft/ftByReference/${idReference}`)
  return data
}

export const getBudget = async (idFT: string): Promise<any> => {
  console.log('idFT', idFT)
  if (!idFT) return undefined

  const { data } = await httpClient.get(`/budget/budgets/${idFT}`)
  console.log('getBudget => ', data)
  return data as any[]
}

export const getFacaTrimbox = async (codPapel: string): Promise<any> => {
  const url = encrypt(`facas/${codPapel}`)
  const { data } = await httpClient.get(
    `/ft/accessExternalService/trimbox/${url}`
  )
  return data
}

export const getClicheTrimbox = async (codCliche: string): Promise<any> => {
  const url = encrypt(`cliches/${codCliche}`)
  const { data } = await httpClient.get(
    `/ft/accessExternalService/trimbox/${url}`
  )
  return data
}

export const getCompositionTrimbox = async (
  codComposition: string
): Promise<any> => {
  const url = encrypt(`compositions/param/?id=${codComposition}`)
  const { data } = await httpClient.get(
    `/ft/accessExternalService/trimbox/${url}`
  )
  console.log('FTservice => getCompositionTrimbox ', data)
  return data
}

export const synchronizeStatusFT = async (id: string) => {
  return await httpClient.put(`/ft/synchronize-status/${id}`)
}

export const synchronizeStatusFTByCodFT = async (codFT: string) => {
  return await httpClient.put(`/ft/synchronize-status/by-codft/${codFT}`)
}

export const getNameCompany = async (itemReference: string) => {
  return await httpClient.get(`/ft/findFTByItemReferenceNameCompany/${itemReference}`)
}

export interface Client {
  _id: string
  type: string
  name: string
  logo: string
  seller: string
  pf?: any
  pj?: any
  isActive: boolean
  description: string
}
export interface Seller {
  _id: string
  user: {
    name: string
    email: string
    occupation: string
    departament: string
  }
}

export const getSeller = async (userId: string) => {
  return httpClient.get<Seller>(`/sellers/${userId}/user`)
}

export const getClientsBySeller = async (userId: string) => {
  return httpClient.get<Client[]>(`/sellers/${userId}/clients`)
}

interface FTsMnemosineResponse {
  fts: any[]
  limit: number
  total: number
}
interface FTsMnemosineProps {
  page: number
  search?: string
  date?: string
}
export const getFTsMnemosine = async ({
  page,
  search = '',
  date = ''
}: FTsMnemosineProps) => {
  return httpClient.get<FTsMnemosineResponse>('/ft/all/mnemosine', {
    params: {
      page,
      search,
      date
    }
  })
}

export const postArtSample = async (artForm: any): Promise<any> => {
  const { data } = await httpClient.post('/ft/art', artForm)
  return data
}

export const getArts = async (idFT: string): Promise<any[]> => {
  const { data } = await httpClient.get(`/ft/arts/${idFT}`)
  return data as any[]
}
