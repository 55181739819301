/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  TablePagination,
  TextField,
  Typography
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import paperImg from '../../assets/img/formpaper.png'
import boxImg from '../../assets/img/formbox.png'
import plateImg from '../../assets/img/formplate.png'
import { ArrowRight, ChevronRight } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { httpClient, httpLN } from 'config/httpClient'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { getClientsBySeller, getSeller, Seller } from 'services/ft/FTService'
import {
  getCollaborators,
  getIe
} from 'services/customerControl/CustomerControlServices'
import { uuid } from 'utils/generateUuid'
import { onlyNumbers } from 'helpers/stringHelper'
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import {
  getCompositions,
  getFechamentos
} from 'services/portfolio/PortfolioService'
import { ModalPreFTPortfolioPlate } from './components/ModalPreFTPortfolioPlate'

export function PreFTPortfolioPlate() {
  const { loginData } = useSelector((state: RootState) => state.login)
  const navigate = useNavigate()

  const [originalForms, setOriginalForms] = useState<any[]>([])
  const [forms, setForms] = useState<any[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any | null>(null)
  const [page, setPage] = useState(0)

  const [loadingItems, setLoadingItems] = useState(true)

  const [composicao, setComposicao] = useState<any[]>([])
  const [fechamentos, setFechamentos] = useState<any[]>([])
  const [seller, setSeller] = useState<Seller | null>(null)
  const [clients, setClients] = useState<any[]>([])
  const [company, setCompany] = useState<any>()
  const [ies, setIes] = useState<any[]>([])
  const [collaborators, setCollaborators] = useState<any[]>([])
  const [loadingCollaborators, setLoadingCollaborators] = useState(false)
  const [noEditable, setNoEditable] = useState(true)

  const loadData = useCallback(() => {
    getCompositions()
      .then((data) => {
        console.log('TRIMBOX Composição', data)
        setComposicao(data)
      })
      .catch((error) => {
        console.log('Error:', error)
      })
    getFechamentos().then((data) => {
      console.log('TRIMBOX Fechamento', data)
      setFechamentos(data)
    })
  }, [])

  useEffect(() => {
    loadData()

    return () => {
      setComposicao([])
      setFechamentos([])
    }
  }, [loadData])

  const fetchFormFt = useCallback(async () => {
    setLoadingItems(true)

    try {
      const { data } = await httpClient.get(
        '/products/formularios/chapa?isActive=true'
      )
      setForms(data ?? [])
      setOriginalForms(data ?? [])
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setLoadingItems(false)
    }
  }, [])

  useEffect(() => {
    void fetchFormFt()
  }, [])

  const handleFilterCoil = (searchValue: string) => {
    // console.log(forms)
    if (searchValue?.length) {
      const filteredWithSearch = originalForms.filter((item: any) =>
        item?.Referencia?.toLowerCase()?.includes(searchValue.toLowerCase())
      )

      setForms(filteredWithSearch)
    } else {
      setForms(originalForms)
    }
  }

  const fetchAll = useCallback(async () => {
    getClientsBySeller(loginData?.tokenData?.sub)
      .then((responseClients) => {
        setClients(responseClients?.data ?? [])
        if (!responseClients?.data?.length) {
          toast.warn('Nenhuma empresa disponível.')
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })

    getSeller(loginData?.tokenData?.sub)
      .then((response) => {
        // console.log(response?.data)
        setSeller(response?.data)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }, [])

  useEffect(() => {
    void fetchAll()

    return () => {
      setSeller(null)
    }
  }, [fetchAll])

  const handleClickOpenModal = (item: any) => {
    setNoEditable(true)
    setSelectedItem(item)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedItem(null)
  }

  const handleLoadIes = useCallback(async (companyId) => {
    getIe(companyId)
      .then((response) => {
        setIes(response?.data ?? [])
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }, [])

  const handleLoadCollaborator = useCallback(async (companyId) => {
    setLoadingCollaborators(true)
    getCollaborators(companyId)
      .then((response) => {
        setCollaborators(response?.data ?? [])
        setLoadingCollaborators(false)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
        setLoadingCollaborators(false)
      })
  }, [])

  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'FT', url: '/home/ft' },
          { text: 'Portfólio', url: '/home/ft/portfolio' },
          { text: 'Chapa' }
        ]}
      />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginBottom: 3, marginTop: 2 }}
        >
          Chapa
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setNoEditable(false)
            setOpenModal(true)
            setSelectedItem(null)
          }}
        >
          Criar projeto
        </Button>
      </Stack>
      <Stack>
        <TextField
          type="text"
          placeholder="Pesquisar por descrição"
          variant="outlined"
          fullWidth
          onChange={(e) => handleFilterCoil(e.target.value)}
        />
      </Stack>

      <Stack spacing={1} sx={{ paddingTop: 2 }}>
        {loadingItems && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: 150 }}
          >
            <CircularProgress size={24} />
          </Stack>
        )}

        {!loadingItems &&
          forms?.slice(page * 10, page * 10 + 10).map((form, i) => (
            <Button
              key={i}
              onClick={() => handleClickOpenModal(form)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                border: '1px solid #ccc'
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                  src={plateImg}
                  sx={{ width: 50, height: 50, borderRadius: 1 }}
                />
                <Stack alignItems="flex-start">
                  <Typography component="span" variant="body1" color="#003050">
                    {form?.Referencia}
                  </Typography>
                </Stack>
              </Stack>

              <ChevronRight />
            </Button>
          ))}

        {!loadingItems && (
          <TablePagination
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
            component="div"
            count={forms.length}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} de ${count}`
              // return '' + from + '-' + to + ' de ' + count
            }}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        )}

        <Stack direction="row" justifyContent="flex-end" sx={{ paddingTop: 4 }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/home/ft/portfolio"
          >
            Voltar
          </Button>
        </Stack>
      </Stack>

      {/* Formulário preFT */}
      {openModal && (<ModalPreFTPortfolioPlate
      openModal={openModal}
      selectedItem={selectedItem}
      seller={seller}
      clients={clients}
      noEditable={noEditable}
      handleCloseModal={handleCloseModal}
      />)}
{/*
      {openModal && (
        <Dialog
          open={openModal}
          // onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
          scroll='paper'
          aria-labelledby="PreFTPortfolioPlate"
          aria-describedby="PreFTPortfolioPlate-description"
        >
          <DialogTitle id="PreFTPortfolioPlate">Pré-FT Chapa</DialogTitle>
          <Formik
            initialValues={{
              qtd: '',
              status: 'NOVO',
              type: 'CHAPA',
              formulariochapa: selectedItem?._id ?? null,
              client: null,
              ie: null,
              collaborator: null,
              seller: seller?._id ?? null,
              preftplate: {
                extraData: '',
                needSample: false,
                amountSample: '',

                // id_Importacao: '',
                // TipoABNT: '',
                Referencia: selectedItem?.Referencia ?? '',
                LarguraInterna: selectedItem?.LarguraInterna ?? '', // number
                ComprimentoInterno: selectedItem?.ComprimentoInterno ?? '', // number
                AlturaInterna: selectedItem?.AlturaInterna ?? '', // number
                Composicao: selectedItem?.Composicao ?? {
                  composicao: '',
                  onda: '',
                  onda1: '',
                  onda2: '',
                  onda3: '',
                  onda4: '',
                  onda5: '',
                  papel1: '',
                  papel2: '',
                  papel3: '',
                  papel4: '',
                  papel5: '',
                  nomeComercial: '',
                  mullen: undefined, // number
                  crushEnd: undefined, // number
                  gramatura: undefined, // number
                  crush: undefined, // number
                  preco: undefined, // number
                  coluna: undefined, // number
                  espessura: undefined // number
                },
                Fechamento: selectedItem?.Fechamento ?? {
                  fechamento: '',
                  descricao: '',
                  qtdEspessuras: undefined // number
                },
                ExigeLaudo: selectedItem?.ExigeLaudo ?? false, // boolean
                // TipoObs1: '',
                Obs1: selectedItem?.Obs1 ?? '',
                // PacotesLargura: undefined, // number
                // PacotesComprimento: undefined, // number
                // PacotesAltura: undefined, // number
                Amarrado: selectedItem?.Amarrado ?? false, // boolean
                Paletizado: selectedItem?.Paletizado ?? false // boolean
                // CodCliente: ''
              }
            }}
            // validationSchema={validationChangePasswordSchema}
            onSubmit={async (dataValues) => {
              const prepareData = {
                qtd: dataValues.qtd,
                status: dataValues.status,
                type: dataValues.type,
                formulariochapa: dataValues.formulariochapa,
                client: dataValues.client,
                ie: dataValues.ie,
                collaborator: dataValues.collaborator,
                seller: dataValues.seller,
                preftplate: {
                  extraData: dataValues.preftplate.extraData ?? '',
                  needSample: false,
                  amountSample: '',

                  // id_Importacao: '',
                  // TipoABNT: '',
                  Referencia: dataValues.preftplate.Referencia ?? '',
                  LarguraInterna: dataValues.preftplate.LarguraInterna ?? '', // number
                  ComprimentoInterno:
                    dataValues.preftplate.ComprimentoInterno ?? '', // number
                  AlturaInterna: dataValues.preftplate.AlturaInterna ?? '', // number
                  Composicao: dataValues.preftplate.Composicao ?? {
                    composicao: '',
                    onda: '',
                    onda1: '',
                    onda2: '',
                    onda3: '',
                    onda4: '',
                    onda5: '',
                    papel1: '',
                    papel2: '',
                    papel3: '',
                    papel4: '',
                    papel5: '',
                    nomeComercial: '',
                    mullen: undefined, // number
                    crushEnd: undefined, // number
                    gramatura: undefined, // number
                    crush: undefined, // number
                    preco: undefined, // number
                    coluna: undefined, // number
                    espessura: undefined // number
                  },
                  Fechamento: dataValues.preftplate.Fechamento ?? {
                    fechamento: '',
                    descricao: '',
                    qtdEspessuras: undefined // number
                  },
                  ExigeLaudo: dataValues.preftplate.ExigeLaudo ?? false, // boolean
                  // TipoObs1: '',
                  Obs1: dataValues.preftplate.Obs1 ?? '',
                  // PacotesLargura: undefined, // number
                  // PacotesComprimento: undefined, // number
                  // PacotesAltura: undefined, // number
                  Amarrado: dataValues.preftplate.Amarrado ?? false, // boolean
                  Paletizado: dataValues.preftplate.Paletizado ?? false // boolean
                  // CodCliente: ''
                }
              }

              console.log('prepareData', prepareData)

              try {
                await httpClient.post('/prefts', prepareData)

                toast.success('Solicitação de chapa criada com sucesso.')

                navigate('/home/ft')
              } catch (error: any) {
                toast.error(error?.response?.data?.message)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              isSubmitting
            }) => (
              <Form>
                <DialogContent dividers>
                  <Box sx={{ marginBottom: 3 }}>
                    <Typography variant="body2" component="span">
                      Este é um formulário de chapa. Você pode editar as
                      informações e solicitar um projeto
                    </Typography>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Autocomplete
                        noOptionsText="Sem opções"
                        loading={isSubmitting}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        getOptionLabel={(option) =>
                          option.isBlocked || !option.isActive
                            ? `${option.name} - [BLOQUEADO]`
                            : option.name
                        }
                        id="combo-box-clients"
                        options={clients.map((row) => ({
                          ...row,
                          label: row.name,
                          id: row._id
                        }))}
                        onChange={(_, selectedValue: any | null) => {
                          void setFieldValue('client', selectedValue?._id)
                          void handleLoadCollaborator(selectedValue?._id)
                          void setFieldValue('collaborator', null)
                          setCollaborators([])
                          setCompany(selectedValue)
                          if (selectedValue?.pj?.cnpj) {
                            void handleLoadIes(selectedValue?._id)
                          } else {
                            setIes([])
                            void setFieldValue('ie', null)
                          }
                        }}
                        getOptionDisabled={(option) =>
                          option.isBlocked || !option.isActive
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label="Solicitador por"
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        disabled
                        fullWidth
                        id="standard-basic"
                        label="Referência"
                        value={values?.preftplate?.Referencia?.toUpperCase()}
                      />
                    </Grid>

                    {company?.pj?.cnpj && (
                      <Grid item xs={6}>
                        <Autocomplete
                          noOptionsText="Sem opções"
                          loading={isSubmitting}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          getOptionLabel={(option) => option?.codeLn}
                          id="combo-box-ie"
                          options={ies.map((row) => ({
                            ...row,
                            label: row.name,
                            id: row._id
                          }))}
                          onChange={(_, selectedValue: any | null) => {
                            void setFieldValue('ie', selectedValue?._id)
                          }}
                          getOptionDisabled={(option) =>
                            option.isBlocked || !option.isActive
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label="Parceiro de negócio"
                              required
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      <Autocomplete
                        noOptionsText="Sem opções"
                        loading={isSubmitting}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        getOptionLabel={(option) => option?.name}
                        id="combo-box-collaborator"
                        options={collaborators.map((row) => ({
                          ...row,
                          label: row.name,
                          id: row._id
                        }))}
                        onChange={(_, selectedValue: any | null) => {
                          void setFieldValue(
                            'collaborator',
                            selectedValue?._id
                          )
                        }}
                        getOptionDisabled={(option) => !option.isActive}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label="Representante"
                            required
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography component="span" variant="body1">
                        Especificações
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        disabled={noEditable}
                        noOptionsText="Sem opções"
                        disablePortal
                        options={
                          composicao.map((item) => ({
                            label: item.composicao,
                            ...item
                          })) ?? []
                        }
                        fullWidth
                        getOptionLabel={(option) => option.composicao}
                        value={values?.preftplate?.Composicao}
                        defaultValue={composicao?.find(
                          (item) =>
                            item.composicao ===
                            values?.preftplate?.Composicao?.composicao
                        )}
                        onChange={(e, composicaoItem: any) => {
                          console.log('composicao', composicaoItem)
                          setFieldValue(
                            'preftplate.Composicao',
                            composicaoItem
                          )

                          const widthD = values?.preftplate?.LarguraInterna
                            ? `${values?.preftplate?.LarguraInterna} `
                            : ''
                          const lengthD = values?.preftplate?.ComprimentoInterno
                            ? `x ${values?.preftplate?.ComprimentoInterno} `
                            : ''
                          const nomenclature =
                          composicaoItem?.nomeComercial ?? ''

                          setFieldValue(
                            'preftplate.Referencia',
                            `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label="Composição"
                            required
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            label="Tipo de Onda"
                            variant="standard"
                            value={values?.preftplate?.Composicao?.onda ?? ''}
                            fullWidth
                            disabled
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Papel"
                            variant="standard"
                            value={`${
                              values?.preftplate?.Composicao?.papel1
                                ? values?.preftplate?.Composicao?.papel1 + ','
                                : ''
                            }${
                              values?.preftplate?.Composicao?.papel2
                                ? values?.preftplate?.Composicao?.papel2 + ','
                                : ''
                            }${
                              values?.preftplate?.Composicao?.papel3
                                ? values?.preftplate?.Composicao?.papel3 + ','
                                : ''
                            }${
                              values?.preftplate?.Composicao?.papel4
                                ? values?.preftplate?.Composicao?.papel4 + ','
                                : ''
                            }${
                              values?.preftplate?.Composicao?.papel5
                                ? values?.preftplate?.Composicao?.papel5 + ','
                                : ''
                            }`.slice(0, -1)}
                            fullWidth
                            disabled
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Nome Comercial"
                            variant="standard"
                            value={
                              values?.preftplate?.Composicao?.nomeComercial ??
                              ''
                            }
                            fullWidth
                            disabled
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography component="span" variant="body1">
                        Dimensões
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <TextField
                        disabled={noEditable}
                        type="number"
                        label="Largura Interna"
                        variant="standard"
                        value={values.preftplate.LarguraInterna}
                        error={Boolean(
                          errors.preftplate?.LarguraInterna &&
                            touched.preftplate?.LarguraInterna
                        )}
                        helperText={
                          errors.preftplate?.LarguraInterna &&
                          touched.preftplate?.LarguraInterna
                            ? errors.preftplate?.LarguraInterna
                            : null
                        }
                        onChange={(event) => {
                          const newValue = onlyNumbers(event.target.value)
                          setFieldValue(
                            'preftplate.LarguraInterna',
                            newValue ? Math.round(Number(newValue)) : ''
                          )

                          const widthD = newValue ? Math.round(Number(newValue)) + ' ' : ''
                          const lengthD = values?.preftplate?.ComprimentoInterno
                            ? `x ${values?.preftplate?.ComprimentoInterno} `
                            : ''
                          const nomenclature =
                            values?.preftplate?.Composicao?.nomeComercial ?? ''

                          setFieldValue(
                            'preftplate.Referencia',
                            `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
                          )
                        }}
                        autoComplete="off"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                      +
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        disabled={noEditable}
                        type="number"
                        label="Comprimento Interno"
                        variant="standard"
                        value={values.preftplate?.ComprimentoInterno}
                        error={Boolean(
                          errors.preftplate?.ComprimentoInterno &&
                            touched.preftplate?.ComprimentoInterno
                        )}
                        helperText={
                          errors.preftplate?.ComprimentoInterno &&
                          touched.preftplate?.ComprimentoInterno
                            ? errors.preftplate?.ComprimentoInterno
                            : null
                        }
                        onChange={(event) => {
                          const newValue = onlyNumbers(event.target.value)
                          setFieldValue(
                            'preftplate.ComprimentoInterno',
                            newValue ? Math.round(Number(newValue)) : ''
                          )

                          const widthD = values?.preftplate?.LarguraInterna
                            ? `${values?.preftplate?.LarguraInterna} `
                            : ''
                          const lengthD = newValue ? `x ${Math.round(Number(newValue))} ` : ''
                          const nomenclature =
                            values?.preftplate?.Composicao?.nomeComercial ?? ''

                          setFieldValue(
                            'preftplate.Referencia',
                            `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
                          )
                        }}
                        autoComplete="off"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                      +
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <TextField
                        disabled={noEditable}
                        type="number"
                        label="Altura Interna"
                        variant="standard"
                        value={values.preftplate?.AlturaInterna}
                        error={Boolean(
                          errors.preftplate?.AlturaInterna &&
                            touched.preftplate?.AlturaInterna
                        )}
                        helperText={
                          errors.preftplate?.AlturaInterna &&
                          touched.preftplate?.AlturaInterna
                            ? errors.preftplate?.AlturaInterna
                            : null
                        }
                        onChange={(event) => {
                          const newValue = onlyNumbers(event.target.value)
                          setFieldValue(
                            'preftplate.AlturaInterna',
                            newValue ? Math.round(Number(newValue)) : ''
                          )

                          const widthD = values?.preftplate?.LarguraInterna
                            ? `${values?.preftplate?.LarguraInterna} `
                            : ''
                          const lengthD = values?.preftplate?.ComprimentoInterno
                            ? `x ${values?.preftplate?.ComprimentoInterno} `
                            : ''
                          const nomenclature =
                            values?.preftplate?.Composicao?.nomeComercial ?? ''

                          setFieldValue(
                            'preftplate.Referencia',
                            `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
                          )
                        }}
                        autoComplete="off"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      md={1}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end'
                        }}
                      >
                        =
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField
                        type="number"
                        label="Total (GRM)"
                        variant="standard"
                        value={
                          Number(values?.preftplate?.LarguraInterna ?? 0) +
                          Number(values?.preftplate?.ComprimentoInterno ?? 0) +
                          Number(values?.preftplate?.AlturaInterna ?? 0)
                        }
                        autoComplete="off"
                        fullWidth
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        disabled={noEditable}
                        noOptionsText="Sem opções"
                        disablePortal
                        options={
                          fechamentos.map((item) => ({
                            label: item.fechamento,
                            ...item
                          })) ?? []
                        }
                        fullWidth
                        getOptionLabel={(option) => option.fechamento}
                        value={values?.preftplate?.Fechamento}
                        defaultValue={fechamentos?.find(
                          (item) =>
                            item.fechamento ===
                            values?.preftplate?.Fechamento?.fechamento
                        )}
                        onChange={(e, fechamentoItem: any) => {
                          setFieldValue(
                            'preftplate.Fechamento',
                            fechamentoItem
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label="Fechamento"
                            required
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        fullWidth
                        id="standard-basic"
                        label="Quantidade"
                        value={values.qtd}
                        onChange={(e) =>
                          setFieldValue(
                            'qtd',
                            onlyNumbers(e.target.value ?? '')
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Un</InputAdornment>
                          )
                        }}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        fullWidth
                        id="standard-basic"
                        label="Dados Adicionais"
                        value={values?.preftplate?.extraData}
                        onChange={handleChange('preftplate.extraData')}
                        multiline
                        minRows={3}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions sx={{ padding: 3 }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseModal}
                    disabled={isSubmitting}
                  >
                    Cancelar
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Solicitar projeto
                  </LoadingButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )} */}
    </>
  )
}
