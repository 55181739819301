import { TextField } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

function ShowDataOrdersProductions(props: any) {
  const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  `

  // const props = {
  //   id: 1,
  //   precoUnitVenda: 0.0,
  //   cor1: '000000',
  //   cor2: null,
  //   cor3: null,
  //   cor4: null,
  //   cor5: null,
  //   cor6: null,
  //   gramatura: 408,
  //   item: '044558A',
  //   endereco: 'ROD PA 275 KM 01 S/N',
  //   codCliente: '001715',
  //   areaBrutaUnid: 0.132,
  //   areaBrutaPedido: 790,
  //   areaLiquidaUnid: 0.128,
  //   pedCliente: '402004579',
  //   pesoLiquidoPeca: 52.359,
  //   pesoLiquidoUnid: 52.359,
  //   orcamento: null,
  //   pesoBrutoPeca: 0.052,
  //   tipoDoPedido: 0,
  //   codigoInterno: 'CV',
  //   fluxoProducao: 'VC/AM/EXP',
  //   pedido: '402004579-1',
  //   razaoSocial: 'LATICINIOS FORTALEZA INDUSTRIA E CO',
  //   dataEntrega1: '2022-07-04T01:00:00.000-03:00',
  //   dataEntrega2: '2022-07-04T01:00:00.000-03:00',
  //   idpedVenda: null,
  //   suspenso: 0,
  //   statusPedido: 1,
  //   iditemPedVenda: null,
  //   distancia: 0,
  //   municipio: 'ELDORADO DOS CARAJAS',
  //   cliente: 'LATICINIOS FO-001715',
  //   composicao: 'C125M110C125_B',
  //   referencia: 'TABULEIRO 410 X 313 LATICINIO FORTALEZA',
  //   dataCriacao: '2022-06-15T11:29:59.273-03:00',
  //   areaBrutaPeca: 0.132,
  //   areaLiquidaPeca: 0.128,
  //   descricao: 'Corte e Vinco',
  //   tipoABNT: 'CV',
  //   quantidadePedida: 6000,
  //   quantidadeUnidades: 6000,
  //   areaLiquidaPedido: 770,
  //   pesoLiquidoPedido: 314,
  //   descFlagEstoqueOuTerceiros: '0-normal',
  //   quantidadeMinima: 6000.0,
  //   quantidadeMaxima: 6000.0,
  //   exigeAgendamentoEntregas: 0,
  //   descrStatusPedido: '1-Ativo',
  //   codigoReferencia: null,
  //   flagEstoqueOuTerceiros: 0,
  //   vincosOnduladeira: '',
  //   grupoPaletizacao: null,
  //   uf: 'PA',
  //   pesoBrutoPedido: 314,
  //   maquina4: null,
  //   maquina6: null,
  //   representante: 'teste',
  //   maquina5: null,
  //   maquina2: 'AM',
  //   maquina3: 'EXP',
  //   maquina1: 'VC',
  //   precoUnidade: 0.0,
  //   obsAlteracoes: 'MARCELO em 01/07/2022 10:34:47\r\nmudou a data da ordem de venda\r\n\r\n',
  //   ordem: '1/1',
  //   arranjo: 3,
  //   lotePiloto: 1,
  //   clienteSusp: 0,
  //   usuarioUltModif: 'MARCELO',
  //   ultModificacao: '2022-07-01T11:34:47.000-03:00',
  //   nomeReduzido: 'LATICINIOS FORTALEZA INDUSTRIA E CO',
  //   tolMenos: 0,
  //   tolMais: 0,
  //   segmento: null,
  //   pesoBrutoUnid: 0.052,
  //   bairro: null
  // }

  return (
    <div>
      <h4>Dados da Ordem de Produção</h4>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', fontSize: '14px' }}>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Preço Unitário de Venda" variant="standard"
            defaultValue={props.op.precoUnitVenda} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Cores" variant="standard"
            // eslint-disable-next-line multiline-ternary
            defaultValue={(props.op.cor1 || props.op.cor2 || props.op.cor3 || props.op.cor4 || props.op.cor5 || props.op.cor6) ? `${props.op.cor1 ? `Cor 1: ${props.op.cor1}, ` : ''}
            ${props.op.cor2 ? `Cor 2: ${props.op.cor2}, ` : ''}
            ${props.op.cor3 ? `Cor 3: ${props.op.cor3}, ` : ''}
            ${props.op.cor4 ? `Cor 4: ${props.op.cor4}, ` : ''}
            ${props.op.cor5 ? `Cor 5: ${props.op.cor5}, ` : ''}
            ${props.op.cor6 ? `Cor 6: ${props.op.cor6}, ` : ''}` : ''} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Gramatura" variant="standard"
            defaultValue={props.op.gramatura} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Item" variant="standard"
            defaultValue={props.op.item} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Endereço" variant="standard"
            defaultValue={props.op.endereco} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Código do Cliente" variant="standard"
            defaultValue={props.op.codCliente} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Área Bruta por Unidade" variant="standard"
            defaultValue={props.op.areaBrutaUnid} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Área Bruta do Pedido" variant="standard"
            defaultValue={props.op.areaBrutaPedido} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Área líquida da Unidade" variant="standard"
            defaultValue={props.op.areaLiquidaUnid} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Pedido do Cliente" variant="standard"
            defaultValue={props.op.pedCliente} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Peso líquido da Peça" variant="standard"
            defaultValue={props.op.pesoLiquidoPeca} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Peso líquido da Unidade" variant="standard"
            defaultValue={props.op.pesoLiquidoUnid} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Orçamento" variant="standard"
            defaultValue={props.op.orcamento} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Peso Bruto da Peça" variant="standard"
            defaultValue={props.op.pesoBrutoPeca} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Tipo de Pedido" variant="standard"
            defaultValue={props.op.tipoDoPedido} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Código Interno" variant="standard"
            defaultValue={props.op.codigoInterno} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Fluxo de Produção" variant="standard"
            defaultValue={props.op.fluxoProducao} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Pedido" variant="standard"
            defaultValue={props.op.pedido} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Razão Social" variant="standard"
            defaultValue={props.op.razaoSocial} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Data de Entrega 1" variant="standard"
            defaultValue={props.op.dataEntrega1} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Data de Entrega 2" variant="standard"
            defaultValue={props.op.dataEntrega2} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Suspenso" variant="standard"
            defaultValue={props.op.suspenso} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Distancia" variant="standard"
            defaultValue={props.op.distancia} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Município" variant="standard"
            defaultValue={props.op.municipio} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Cliente" variant="standard"
            defaultValue={props.op.cliente} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Composição" variant="standard"
            defaultValue={props.op.composicao} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Referência" variant="standard"
            defaultValue={props.op.referencia} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Data de Criação" variant="standard"
            defaultValue={props.op.dataCriacao} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Área Bruta da Peça" variant="standard"
            defaultValue={props.op.areaBrutaPeca} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Área Líquida da Peça" variant="standard"
            defaultValue={props.op.areaLiquidaPeca} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Descrição" variant="standard"
            defaultValue={props.op.descricao} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Tipo ABNT" variant="standard"
            defaultValue={props.op.tipoABNT} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Quantidade Pedida" variant="standard"
            defaultValue={props.op.quantidadePedida} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Quantidade Unidades" variant="standard"
            defaultValue={props.op.quantidadeUnidades} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Área Líquida do Pedido" variant="standard"
            defaultValue={props.op.areaLiquidaPedido} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Peso Líquido do Pedido" variant="standard"
            defaultValue={props.op.pesoLiquidoPedido} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Exige Agendamento de Entregas" variant="standard"
            defaultValue={props.op.exigeAgendamentoEntregas} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Descrição do Status do Pedido" variant="standard"
            defaultValue={props.op.descrStatusPedido} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="UF" variant="standard"
            defaultValue={props.op.uf} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Peso Bruto do Pedido" variant="standard"
            defaultValue={props.op.pesoBrutoPedido} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Representante" variant="standard"
            defaultValue={props.op.representante} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Observações Alterações" variant="standard"
            defaultValue={props.op.obsAlteracoes} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Ordem" variant="standard"
            defaultValue={props.op.ordem} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Arranjo" variant="standard"
            defaultValue={props.op.arranjo} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Lote Piloto" variant="standard"
            defaultValue={props.op.lotePiloto} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Usuário da Última Modificação" variant="standard"
            defaultValue={props.op.usuarioUltModif} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Data da Última Modificação" variant="standard"
            defaultValue={props.op.ultModificacao} />
        </RowStyled>
      </div>
    </div>
  )
}

export default ShowDataOrdersProductions
