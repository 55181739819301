import { Breadcrumbs } from '@mui/material'
import React from 'react'
import { BreadcrumbsType } from 'types/breadcrumbs/BreadcrumbsType'
import { Link } from 'react-router-dom'
import { count } from 'helpers/arrayHelps'

interface Props {
  breadcrumbs: BreadcrumbsType[]
}

const BreadcrumbsComponent: React.FC<Props> = (props) => {
  return (
    <Breadcrumbs>
      {props.breadcrumbs.map((item: BreadcrumbsType, index: number) => {
        return (
          <div key={index}>
            {item.url
              ? (
              <Link
                to={item.url}
                className="item_breadcrumbs"
                style={{
                  color:
                    count(props.breadcrumbs) === index + 1
                      ? '#9ECF13'
                      : 'black'
                }}
              >
                {item.text}
              </Link>
                )
              : (
              <p className="item_breadcrumbs">{item.text}</p>
                )}
          </div>
        )
      })}
    </Breadcrumbs>
  )
}

export default BreadcrumbsComponent
