import { httpLN } from '../../../config/httpClient'

export const getInsumosPapel = async (month?: any, year?: any, items?: any) => {
  const { data } = await httpLN.get(`/insumos/papel-mes?items=${items}&month=${month}&year=${year}`)
  return data
}

export const getInsumosPapelTotalKg = async (month?: any, year?: any, items?: any) => {
  const { data } = await httpLN.get(`/insumos/papel-mes-total-kg?items=${items}&month=${month}&year=${year}`)
  return data
}

export const getInsumosPapelValorTotal = async (month?: any, year?: any, items?: any) => {
  const { data } = await httpLN.get(`/insumos/papel-mes-valor-total?items=${items}&month=${month}&year=${year}`)
  return data
}
