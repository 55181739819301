/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Add,
  ArrowBack,
  ExpandMore
} from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Chip, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, MenuItem, Paper, Select, Stack, Table, TableBody, TableContainer, TableHead, TextField } from '@mui/material'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import { onlyNumbersOrVirgula } from 'helpers/stringHelper'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { getBaseBudgetRealized, deleteItemBudgetRealized, deleteBudgetRealized, createMultBudgetRealized } from 'services/budgetRealized/BudgetRealizedService'
import { validationSchemaMaterial } from 'types/validation/ValidationMaterial'
import { BudgetRealizedType } from 'types/budgetRealized/BudgetRealizedType'
import NumericInput from 'material-ui-numeric-input'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import MoreMenu from 'components/view/MoreMenu'
import BudgetConfigItemForm from './BugdetConfigItemForm'
import BudgetConfigCreateItemForm from './BudgetConfigCreateItemForm'

function BudgetConfigPreForm(props: any) {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const years = Array.from({ length: currentYear - 2020 + 1 }, (_, i) => currentYear - i)
  const tipo = {
    0: 'Administrativo',
    1: 'Materiais de PO',
    2: 'Materiais de Papel',
    3: 'Insumos de Papel'
  }
  const tipoArray = Object.entries(tipo)
  const [checked, setChecked] = React.useState<string[]>([])
  const [bmonth, setBmonth] = React.useState<number>(currentMonth)
  const [byear, setByear] = React.useState<number>(currentYear)
  const { mutate: createMultiple, isLoading: isLoadingCreateMultiple } = useMutation(createMultBudgetRealized, {
    onSuccess: data => {
      console.log('create data list', data)
      const target = '*'

      if (!(data.message === '')) {
      const categoryOccurrences = data.message.split(target)
      categoryOccurrences.forEach((occurrence) => {
        const message = occurrence.trim()
        if (message.length > 0) {
          toast.error(message, { position: toast.POSITION.TOP_CENTER })
        }
      })
    } else {
      toast.success('Documentos cadastrados com sucesso')
    }
      props.close()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível cadastrar')
      }
    }
  })
  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }
  console.log('BASE PROPS', props)
  const initialValues = {
    objects: props?.edit?.map((obj) => ({
      _id: obj._id,
      month: obj.month || 0,
      year: obj.year || 0,
      refuse: obj.refuse || 0,
      module: obj.module || 5,
      categories: obj.categories || [],
      items: obj.categories[0]?.items || []
    }))
  }

  const createValuesForm = (values, checked) => {
    return {
      budgetRealizedForms: values.objects
        .filter(obj => checked.includes(obj.module))
        .map(obj => ({
          _id: obj._id,
          month: bmonth,
          year: byear,
          refuse: obj.refuse,
          module: obj.module,
          categories: [
            {
              type: obj.categories[0]?.type || 'Não definido',
              percentage: obj.categories[0]?.percentage || 0,
              launchValue: obj.categories[0]?.launchValue || 0,
              items: obj.categories[0]?.items || []
            }
          ]
        }))
    }
  }

  const onSubmit = (values: { objects: BudgetRealizedType[] }) => {
    if (checked.length === 0 || checked.length > 4) {
      console.error("Error: 'checked' array should contain between 1 and 4 elements.")
      toast.error('Nenhum Tipo de Documento escolhido.')
    } else {
      const newCheck = checked.map(item => (parseInt(item) + 1))
      const valuesForm = createValuesForm(values, newCheck)
      createMultiple(valuesForm)
    }
  }
  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik({
    onSubmit,
    enableReinitialize: true,
    initialValues
  })

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip title='Voltar' placement='top' onClick={() => { props.close() }}>
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>Gerar Tipos de Documento</div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px', flex: 1 }}>
        <form autoComplete="off" onSubmit={handleSubmit} >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginRight: '30px',
            marginTop: '20px'
          }}>

          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListSubheader
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: '400',
              fontSize: '1rem'
            }}
          >
            Tipos *
          </ListSubheader>
            <FormControl required variant="standard" fullWidth sx={{ marginBottom: '16px' }} >
            {tipoArray.map(([key, value], index) => {
              const labelId = `checkbox-list-label-${index}`
              return (
                <ListItem
                  key={index}
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(key)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.includes(key)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value}`} />
                  </ListItemButton>
                </ListItem>
              )
            })}
            </FormControl>
          </List>

          <Grid container spacing={2} >
            {/* Grid para o mês e ano */}
            <Grid item xs={12} sm={6}>
              <FormControl required variant="standard" fullWidth sx={{ marginBottom: '16px' }} >
                <InputLabel id='month-label'>Mês</InputLabel>
                <Select
                  labelId='month-label'
                  value={bmonth}
                  onChange={(e) => { setFieldValue('month', e.target.value); setBmonth(Number(e.target.value)) }}
                  style={{ color: '#A8A8A8' }}
                >
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl required variant="standard" fullWidth sx={{ marginBottom: '16px' }} >
                <InputLabel id='year-label'>Ano</InputLabel>
                <Select
                  labelId='year-label'
                  value={byear}
                  onChange={(e) => { setFieldValue('year', e.target.value); setByear(Number(e.target.value)) }}
                  style={{ color: '#A8A8A8' }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type='button'
              mold='red'
              onClick={() => { props.close() }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold='primary'
              type="submit"
              disabled={isLoadingCreateMultiple}
            >
              SALVAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default BudgetConfigPreForm
