/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import {
  StyledTableCell,
  StyledTableRow
} from 'pages/company/portfolio/components/table.styles'
import ModalAudit from './ModalAudit'
import { useQuery } from 'react-query'
import { getPedidos, getPedidosBobina, getPedidosChapa } from 'services/pedido/PedidoService'
import moment from 'moment'
import { Status } from 'components/Status'
import { TableActionButtons } from '../components/table/TableActionButtons'
import InputSearch from 'components/form/Input/InputSearch'
import { TableCell } from 'components/table/TableCell'
import Stack from '@mui/material/Stack'
import { ShowFts } from '../components/ShowFts'
import ChipFT from '../components/ChipFT'

const TableChapa: React.FC = () => {
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState<boolean>(false)
  const [dataValues, setDataValues] = useState()
  const [search, setSearch] = useState('')

  const { data, status, refetch, isFetching } = useQuery(
    'purchase-request-Chapa',
    async () => getPedidosChapa({ page: page + 1, search }),
    {
      refetchOnWindowFocus: false
    }
  )

  console.log(data)

  useEffect(() => {
    refetch()
  }, [search, page])

  const getQtdSolicitada = (row: any) => {
    const total = row?.pedidoFts?.reduce((acc, currentValue) => {
      const totalEntregas = currentValue?.entregas?.reduce(
        (total, currentEntrega) => total + Number(currentEntrega?.quantidade),
        0
      )
      return acc + (totalEntregas || 0)
    }, 0)

    return total
  }
  return (
    <>
      {status === 'loading' && (
        <Box
          sx={{
            display: 'flex',
            height: 200,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {status === 'success' && (
        <TableContainer component={Paper}>
          {isFetching && (
            <Box display="flex" alignItems="center">
              <CircularProgress size={18} sx={{ marginRight: 1 }} />
              <Typography component="span" variant="body2" color="primary">
                Carregando...
              </Typography>
            </Box>
          )}
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <InputSearch
                    label="Pesquisar por cliente ou FT"
                    onSearch={(value) => setSearch(value)}
                    onClear={() => setSearch('')}
                  />
                </TableCell>
                <TableCell align="center" label="QTD FT" />
                <TableCell align="center" label="Solicitado em" />
                <TableCell align="center" label="Qtd Solicitada" />
                <TableCell align="center" label="Status do pedido" />
                <TableCell align="center" label="Recorrente" />
                <TableCell align="center" label="Status ferramental" />
                <TableCell align="center" label="Book" />
                <TableCell align="center" label="Ações" />
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'success' && !data?.docs?.length && (
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <Box
                      sx={{
                        display: 'flex',
                        height: 200,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography component="span" variant="body2">
                        Nenhum pedido encontrado
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              )}
              {data?.docs?.map((row) => {
                return (
                  <StyledTableRow key={row?._id}>
                    {/* <StyledTableCell component="th" scope="row">
                      {row?._id}
                    </StyledTableCell> */}
                    <StyledTableCell component="th" scope="row">
                      {row?.client?.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <ChipFT pedidos={row?.pedidoFts} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.createdAt
                        ? moment(row?.createdAt).format('DD/MM/YYYY [às] HH:mm')
                        : ''}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {getQtdSolicitada(row)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Status title={row?.status?.toUpperCase()} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.isRecorrente ? 'SIM' : 'NÃO'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {!!row?.budgetsTool?.length && (
                        <>
                          <Status
                            title={
                              row?.budgetsTool.filter(
                                (item) =>
                                  item?.status?.toUpperCase() !== 'APROVADO'
                              )?.length >= 1
                                ? 'EM ANÁLISE'
                                : 'APROVADO'
                            }
                          />
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.booking?.status && (
                        <Status title={row?.booking?.status?.toUpperCase()} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TableActionButtons
                        tab={'CHAPA'}
                        item={row}
                        refresh={refetch}
                        onAudit={() => {
                          setOpen(true)
                          setDataValues(row)
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
            component="div"
            count={data?.total ?? 0}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        </TableContainer>
      )}
      {open && (
        <ModalAudit
          open={open}
          data={dataValues}
          close={() => setOpen(false)}
          closeSuccess={() => {
            setOpen(false)
            setPage(0)
            refetch()
          }}
        />
      )}
    </>
  )
}

export default TableChapa
