import { Box, CircularProgress, Paper, Table, TableBody, TableContainer } from '@mui/material'
import React from 'react'
import { StyledTableCell, StyledTableRow } from './table.styles'

export const LoadingTable: React.FC = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableBody>
        <StyledTableRow>
          <StyledTableCell>
            <Box display='flex' justifyContent='center' alignItems='center' height={200} width='100%'>
              <CircularProgress />
            </Box>
          </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
