import { Box } from '@mui/material'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import { GoBack } from 'components/form/GoBack'
import HeaderViewPortfolio from 'components/view/HeaderViewPortifolio'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ModulesPortfolio from './components/ModulesPortfolio'

const portfolio: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Empresa', url: '/home/company' },
          { text: 'Portfólio PCE' }
        ]}
      />

      <Box style={{ marginTop: '20px' }}>
        <HeaderViewPortfolio
          register={() => navigate('/home/portfolio/products')}
        />

        <ModulesPortfolio />

        <GoBack forRoute="/home/company" />
      </Box>
    </>
  )
}

export default portfolio
