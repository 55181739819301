import React from 'react'
import TableAuditOrdersProduction from './TableAuditOrdersProduction'

function AuditOrdersProduction(props: any) {
  return (
    <div>
      <TableAuditOrdersProduction codFT={props.codConjunto} />
    </div>
  )
}

export default AuditOrdersProduction
