/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useState } from 'react'
import { Box } from '@mui/system'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { Typography, Chip } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { _changePage } from 'pages/home/slices/home.slice'

const color: any = {
  PENDENTE: '#ce6c2b',
  APROVADO: '#72CE2B',
  REPROVADO: '#EE207A'
}
interface AccordionProps {
  title: string
  subtitle?: string
  status?: string
  children: ReactNode
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  subtitle,
  status,
  children
}) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <MuiAccordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{ marginBottom: 1, '&::before': { display: 'none' } }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${title}-content`}
        id={`panel${title}-header`}
      >
        <Box display="flex" alignItems="center">
          <Typography component="strong" fontWeight="bold">
            {title}
          </Typography>
          {subtitle && (
            <Typography component="span" variant="body2" sx={{ marginLeft: 2 }}>
              {subtitle}
            </Typography>
          )}
          {status && (
            <Chip
              label={status}
              size="small"
              variant="outlined"
              sx={{
                marginLeft: 2,
                borderColor: color[status],
                color: color[status]
              }}
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  )
}
