import { Box, Tooltip, Typography } from '@mui/material'
import moment from 'moment'

export function DaysOfDelay({
  dataEntregaOV,
  dataFaturamento
}: {
  dataEntregaOV: string
  dataFaturamento: string
}) {
  const dataEntregaOvDate = moment(dataEntregaOV)
  const dataFaturamentoDate = moment(dataFaturamento)

  return (
    <Tooltip
      title={`Data de Entrega: ${dataEntregaOvDate.format(
        'DD/MM/YYYY [às] HH:mm'
      )}. Data de Faturamento: ${dataFaturamentoDate.format(
        'DD/MM/YYYY [às] HH:mm'
      )}`}
    >
      <Box>
        {dataEntregaOvDate.isBefore(dataFaturamentoDate)
? (
          <Typography
            component="span"
            variant="body2"
            sx={{ color: '#EE207A' }}
          >
            {dataFaturamentoDate.diff(dataEntregaOvDate, 'days')} dias
          </Typography>
        )
: (
          '-'
        )}
      </Box>
    </Tooltip>
  )
}
