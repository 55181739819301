/* eslint-disable @typescript-eslint/comma-spacing */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import CustomButton from 'components/buttons/CustomButton'
import moment from 'moment'

interface Props {
  open: boolean
  data: any[]
  close: () => void
  refetch?: () => void
}

const TableDVApplicant: React.FC<Props> = (props) => {
  const closeModal = () => props.close()
  const [applicant] = useState<any[]>([props.data['DV-Applicant']?.Applicant[0]])

  return (
    <>
      <Dialog
        open={props.open}
        onClose={closeModal}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  {applicant[0].TipoDeDocumento === 'PJ' ?
                    <TableRow>
                      <StyledTableCell align="left">Tipo Documento</StyledTableCell>
                      <StyledTableCell align="left">CNPJ</StyledTableCell>
                    </TableRow>
                    :
                    <TableRow>
                      <StyledTableCell align="left">Tipo Documento</StyledTableCell>
                      <StyledTableCell align="left">CPF</StyledTableCell>
                      <StyledTableCell align="left">Data de nascimento</StyledTableCell>
                    </TableRow>
                  }
                </TableHead>
                <TableBody>
                  {applicant[0].TipoDeDocumento === 'PJ' ?
                    applicant?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row?.TipoDeDocumento}</StyledTableCell>
                        <StyledTableCell align="left">{row?.CNPJ}</StyledTableCell>
                      </StyledTableRow>
                    ))
                    :
                    applicant?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row?.TipoDeDocumento}</StyledTableCell>
                        <StyledTableCell align="left">{row?.CPF.replace(/^(\d{3})\D*(\d{3})\D*(\d{3})\D*(\d{2})$/g,'$1.$2.$3-$4')}</StyledTableCell>
                        <StyledTableCell align="left">{moment(row?.DataNascimento).format('DD/MM/YYYY')}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <CustomButton
            mold="primary"
            type="button"
            onClick={closeModal}
            autoFocus
          >
            Voltar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TableDVApplicant
