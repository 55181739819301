import React from 'react'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import { Box, CssBaseline, CircularProgress } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import moment from 'moment'
import RevenuesTable from './components/RevenuesTable'
import VariationsTable from './components/VariationsTable'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { getReportRevenues } from 'services/report/ReportRevenues'
import { getReportDevolucoes, getReportOthers } from 'services/report/ReportBilling'

const Tab = styled(TabUnstyled)`
    font-family: IBM Plex Sans, sans-serif;
    color: black;
    cursor: pointer;
    font-size: 0.815rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 0px;
    display: flex;

    &:focus {
      border-radius: 0px;
      outline: 2px solid white;
      outline-offset: 2px;
    }

    &.${tabUnstyledClasses.selected} {
      background-color: #4060E3;
      color: white;
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `
const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `
const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: white;
    border-radius: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    `

const ReportRevenues = (props: any) => {
  const [variation, setVariation] = React.useState<any>()
  const date = moment(props.date).format('DD/MM/YYYY')

  // const variRequest = 'faturamentos/variacao?data=' + moment(props.date).format('DD/MM/YYYY')

  // useEffect(() => {
  //   setIsLoading(true)
  //   httpLN.get(variRequest).then(
  //     response => {
  //       setIsLoading(false)
  //     }
  //   ).catch(e => {
  //     setIsLoading(false)
  //     toast.error('Falha ao carregar dados, tente novamente')
  //   })
  // }, [props.date])

  const queryMultiple = () => {
    const faturadoReq = useQuery(['revenues', date, props.multipleDate],
      async () => {
        return getReportRevenues(date, props.multipleDate)
      },
      {
        onSuccess: (data) => {
          console.log('Balance', faturadoReq.data)
          setVariation(data)
        },
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const devolucaoMesPassadoReq = useQuery(['devolucao-mes-passado-req', date, props.multipleDate],
      async () => {
        // valores de devolução do mês anterior
        const lastMonthDate = moment(props.date).subtract(1, 'month').format('DD/MM/YYYY')
        return await getReportDevolucoes(lastMonthDate, props.multipleDate)
      },
      {
        onSuccess: (data) => { },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )

    const devolucaoMesAtualReq = useQuery(['devolucao-mes-atual-req', date, props.multipleDate],
      async () => {
        return getReportDevolucoes(moment(props.date).format('DD/MM/YYYY'), props.multipleDate)
      },
      {
        onSuccess: (data) => { },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )

    const OutrosReq = useQuery(['faturamento-outros', date],
      async () => {
        return await getReportOthers(date)
      },
      {
        onSuccess: (data) => { console.log('Outros', data) },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )

    const OutrosMesPassadoReq = useQuery(['faturamento-outros-mes-passado', date],
      async () => {
        const dataMesPassado = moment(date, 'DD/MM/YYYY')
          .subtract(1, 'month')
          // .endOf('month')
          .format('DD/MM/YYYY')
        return await getReportOthers(dataMesPassado)
      },
      {
        onSuccess: (data) => { console.log('Outros mês passado', data) },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )

    return [faturadoReq, devolucaoMesPassadoReq, devolucaoMesAtualReq, OutrosReq, OutrosMesPassadoReq]
  }

  const [faturadoReq, devolucaoMesPassadoReq, devolucaoMesAtualReq, OutrosReq, OutrosMesPassadoReq] = queryMultiple()
  return (
    <div>
      <CssBaseline />
      {!faturadoReq.isFetching
        ? <div>
          <TabsUnstyled defaultValue={0}>
            <Box sx={{ width: '540px' }}>
              <TabsList>
                <Tab>{'Faturado mesmo período de ' + moment(props.date).add(-1, 'M').format('MMMM')}</Tab>
                <Tab>{'Variações de ' + moment(props.date).add(-1, 'M').format('MMMM') + ' / ' + moment(props.date).format('MMMM')}</Tab>
              </TabsList>
            </Box>
            <TabPanel value={0}>
              <RevenuesTable
                month={moment(props.date).add(-1, 'M').format('M')}
                variation={variation}
                devolucoes={devolucaoMesPassadoReq?.data?.response}
                outros={OutrosMesPassadoReq} />
            </TabPanel>
            <TabPanel value={1}>
              <VariationsTable
                lastMonth={moment(props.date).add('M').format('M')}
                month={moment(props.date).add(-1, 'M').format('M')}
                variation={variation}
                devolucoes={{ mesPassado: devolucaoMesPassadoReq?.data?.response, mesAtual: devolucaoMesAtualReq?.data?.response }}
                outros={{ mesAtual: OutrosReq?.data?.valorOutros, mesPassado: OutrosMesPassadoReq?.data?.valorOutros }} />
            </TabPanel>
          </TabsUnstyled>
        </div>
        : <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <CircularProgress />
        </Box>
      }
    </div>

  )
}

export default ReportRevenues
