import React, { ReactNode } from 'react'
import MuiTabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

interface Props {
  tabs: string[]
  value: number
  onValue: (value: number) => void
  children: ReactNode
}

export const Tabs: React.FC<Props> = ({ tabs, value, onValue, children }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', paddingTop: 4 }}>
      <Box sx={{ marginBottom: 3 }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          aria-label="purchase-order"
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab}
              key={index}
              {...a11yProps(index)}
              sx={{ textTransform: 'capitalize', fontWeight: 'normal' }}
            />
          ))}
        </MuiTabs>
      </Box>

      {children}
    </Box>
  )
}
