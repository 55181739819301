/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import CustomButton from 'components/buttons/CustomButton'
import { httpClient } from 'config/httpClient'
import { onlyNumbers } from 'helpers/stringHelper'
import { useMutation, useQueryClient } from 'react-query'
import { postPortfolioBoxes, putPortfolioBoxes } from 'services/portfolio/PortfolioService'
import { validationBoxType } from '../validations/validationBoxType'

interface modalView {
  open: boolean
  close: (changed?: boolean) => void
  editable?: boolean
  row?: any
}

const FormBoxType = (props: modalView) => {
  const { open, close, row, editable } = props

  const [loading, setLoading] = useState(false)

  const closeModal = () => close()

  const queryClient = useQueryClient()

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(putPortfolioBoxes, {
    onSuccess: data => {
      console.log(data)
      toast.success('Editado com sucesso')
      close(true)
    },
    onError: () => {
      toast.error('Não foi possível editar')
    },
    onSettled: () => {
      queryClient.invalidateQueries('boxes')
    }
  })

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(postPortfolioBoxes, {
    onSuccess: data => {
      console.log(data)
      toast.success('Cadastrado com sucesso')
      close(true)
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível cadastrar')
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('boxes')
    }
  })

  const { errors, touched, values, setFieldValue, handleSubmit, resetForm } = useFormik({
    onSubmit: (values: any) => {
      if (editable) {
        mutateEdit({
          name: values.name,
          obs: values.obs,
          _id: row._id
        })
      } else {
        mutateCreate({
          name: values.name,
          obs: values.obs
        })
      }
    },
    enableReinitialize: true,
    validationSchema: validationBoxType,
    initialValues: {
      name: row ? row.name : '',
      obs: row ? row.obs : ''
    }
  })

  if (editable) {
    return (
      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >
            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => { close() }}
                sx={{ minWidth: 'auto', padding: 1, marginRight: 2 }}
              >
                <ArrowBack />
              </Button>

              <span>Editar caixa</span>
            </Box>
          </DialogTitle>

          <DialogContent>
            <h4 style={{ marginTop: '25px' }}>Tipo de caixa</h4>
            <TextField
              id="input-dimensions.widthD"
              label="Nome da caixa"
              variant="standard"
              autoComplete="off"
              fullWidth
              required
              value={values.name}
              onChange={event => { setFieldValue('name', event.target.value) }}
              error={Boolean(errors.name && touched.name)}
              helperText={((errors.name && touched.name) ? errors.name : null) as React.ReactNode}
            />
            <TextField
              style={{ marginTop: '40px', width: '100%' }}
              id="outlined-multiline-static"
              label="Observações"
              autoComplete='off'
              multiline
              rows={3}
              value={values.obs}
              onChange={event => { setFieldValue('obs', event.target.value) }}
              error={Boolean(errors.obs && touched.obs)}
              helperText={((errors.obs && touched.obs) ? errors.obs : null) as React.ReactNode}
            />
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Grid item md={12} className="text-right">
              <CustomButton
                mold="red"
                type="button"
                onClick={() => { close() }}
                style={{ marginRight: '10px' }}
                disabled={loading}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                mold="primary"
                type="submit"
                loading={loading}
                disabled={loading}
              >
                Salvar
              </CustomButton>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      fullWidth
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => { close() }}
              sx={{ minWidth: 'auto', padding: 1, marginRight: 2 }}
            >
              <ArrowBack />
            </Button>

            <span>Nova caixa</span>
          </Box>
        </DialogTitle>

        <DialogContent>
          <h4 style={{ marginTop: '25px' }}>Tipo de caixa</h4>
          <TextField
            id="input-dimensions.widthD"
            label="Nome da caixa"
            variant="standard"
            autoComplete="off"
            fullWidth
            required
            onChange={event => { setFieldValue('name', event.target.value) }}
            error={Boolean(errors.name && touched.name)}
            helperText={((errors.name && touched.name) ? errors.name : null) as React.ReactNode}
          />
          <TextField
            style={{ marginTop: '40px', width: '100%' }}
            id="outlined-multiline-static"
            label="Observações"
            autoComplete='off'
            multiline
            rows={3}
            value={values.obs}
            onChange={event => { setFieldValue('obs', event.target.value) }}
            error={Boolean(errors.obs && touched.obs)}
            helperText={((errors.obs && touched.obs) ? errors.obs : null) as React.ReactNode}
          />
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Grid item md={12} className="text-right">
            <CustomButton
              mold="red"
              type="button"
              onClick={() => { close() }}
              style={{ marginRight: '10px' }}
              disabled={loading}
            >
              Cancelar
            </CustomButton>
            <CustomButton
              mold="primary"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              Salvar
            </CustomButton>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default FormBoxType
