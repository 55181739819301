import { Stack, Typography } from '@mui/material'
import React from 'react'

interface Props {
  title: string
}

export const Header: React.FC<Props> = ({ title }) => {
  return (
    <Stack marginTop={3} direction="row" justifyContent="space-between" alignItems="center">
      <Typography component="strong" variant="body1" fontWeight="bold">
        {title}
      </Typography>
    </Stack>
  )
}
