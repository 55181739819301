import {
  AssignmentTurnedIn,
  Book,
  ConstructionOutlined,
  DeleteOutlineOutlined
} from '@mui/icons-material'
import LightTooltip from 'components/tooltips/LightTooltip'
import MoreMenu from 'components/view/MoreMenu'
import { ActionButton } from 'components/view/styles/style-more-menu'
import { httpClient } from 'config/httpClient'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const TableActionButtons = ({
  item,
  tab,
  onAudit,
  refresh
}: {
  tab?: string
  item: any
  onAudit: () => void
  refresh: () => void
}) => {
  const navigate = useNavigate()

  const handleSuspended = (item: any) => {
    httpClient
      .patch(`pedidos/${item._id}/suspend`)
      .then(() => {
        refresh()
        toast.success('Suspendido com sucesso!')
      })
      .catch((error) => toast.error(`Erro ao suspender pedido: ${error?.response?.data?.message}`))
  }

  return (
    <MoreMenu>
      <LightTooltip title="Auditar" placement="top">
        <ActionButton onClick={onAudit}>
          <AssignmentTurnedIn />
        </ActionButton>
      </LightTooltip>
      <LightTooltip title="Ferramental" placement="top">
        <ActionButton
          onClick={() => navigate('budgetTools', { state: { item, tab } })}
        >
          <ConstructionOutlined />
        </ActionButton>
      </LightTooltip>
      <LightTooltip title="Book" placement="top">
        <ActionButton
          onClick={() => navigate('bookingForm', { state: { item, tab } })}
        >
          <Book />
        </ActionButton>
      </LightTooltip>
      <LightTooltip title="Suspender" placement="top">
        <ActionButton color="#EE207A" onClick={() => handleSuspended(item)}>
          <DeleteOutlineOutlined />
        </ActionButton>
      </LightTooltip>
    </MoreMenu>
  )
}
