import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserForm } from '../models/IUserForm'

interface LoginState {
  userFormnData: IUserForm | null
}

const initialState: LoginState = {
  userFormnData: {
    modules: []
  }
}

const userFormSlice = createSlice({
  name: 'userForm',
  initialState,
  reducers: {
    _changeModules(state, action: PayloadAction<IUserForm>) {
      state.userFormnData = action.payload
    }
  }
})

export const userFormReducer = userFormSlice.reducer
export const { _changeModules } = userFormSlice.actions
