import React, { useEffect, useState } from 'react'
import { Box, Button, Stack, Tab, TextField } from '@mui/material'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import ModulesPurchaseOrder from './purchaseOrder/ModulesPurchaseOrder'
import { useDispatch } from 'react-redux'
import { _changePage } from 'pages/home/slices/home.slice'
import { pages } from 'pages/home/HomePage'
import SalesOrdersTable from './salesOrders/SalesOrdersTable'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import { ptBR } from 'date-fns/locale'
import SuspendedTable from './suspended/SuspendedTable'
import { Add } from '@mui/icons-material'
import { Link, useLocation } from 'react-router-dom'
import NewSalesOrdersTable from './salesOrders/NewSalesOrdersTable'

const PurchaseRequests: React.FC = () => {
  const { state }: any = useLocation()
  const dispatch = useDispatch()
  const [value, setValue] = useState('1')

  const [valueDate, setValueDate] = useState<any | null>(new Date())
  const [newDate, setNewDate] = useState<any | null>(new Date())

  useEffect(() => {
    const page = pages.find((page) => page.route === 'purchaseRequests')
    if (page) {
      dispatch(_changePage(page))
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const getNewDate = () => {
    setNewDate(valueDate)
  }

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to='/home/purchaseRequests/listProjectsAndItems'
            sx={{
              borderRadius: '2px',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }}
          >
            Novo pedido
          </Button>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '10px'
            }}
          >
            <div style={{ marginRight: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label="Dia"
                  value={valueDate}
                  onChange={(newValue) => {
                    setValueDate(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginLeft: '10px' }}>
              <LoadingButton
                style={{
                  backgroundColor: '#4060E6',
                  borderRadius: '2px',
                  width: '122px',
                  height: '40px',
                  color: 'white',
                  marginTop: '0px'
                }}
                variant="outlined"
                onClick={() => getNewDate()}
              >
                Pesquisar
              </LoadingButton>
            </div>
          </div>
        </Stack>
        <div style={{ marginTop: 60 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Pedido de compra" value="1" />
                <Tab label="Novas Ordens de venda" value="2" />
                <Tab label="Ordens de venda" value="3" />
                <Tab label="Suspensos" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <ModulesPurchaseOrder tab={state?.tab} />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0 }}>
              <NewSalesOrdersTable />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0 }}>
              <SalesOrdersTable date={newDate} />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: 0 }}>
              <SuspendedTable />
            </TabPanel>
          </TabContext>
        </div>
      </Box>
    </>
  )
}

export default PurchaseRequests
