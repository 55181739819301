import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Button, Container, Icon, Popover } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../core/store'
import { _changePage } from './slices/home.slice'
import { PageDetails } from './models/PageDetails'
import UserAccount from './components/UserAccount'
import { _changeLogin } from '../login/slices/login.slice'
import ChangePasswordDialog from './components/ChangePasswordDialog'
import moment from 'moment'
import VersionLabel from './components/VersionLabel'
import PrivacyLabel from './components/PrivacyLabel'
import ModulesType from 'types/modules/ModulesType'
import { MenuNotifications } from 'components/notification/MenuNotifications'
import { modulesBase } from 'config/modules'
import DisplayTokenExpiration from './components/DisplayTokenExpiration'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`
  // ...(open && {
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen
  //   }),
  //   marginLeft: 0
  // })
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  // ...(open && {
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   marginLeft: `${drawerWidth}px`,
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen
  //   })
  // }),
  backgroundColor: 'white'
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

export const pages: PageDetails[] = modulesBase

export default function HomePage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const { page } = useSelector((state: RootState) => state.home)

  const { loginData } = useSelector((state: RootState) => state.login)
  const modules: ModulesType[] = loginData?.tokenData?.modules ?? []

  const [openUserAccount, setOpenUserAccount] = React.useState<boolean>(false)
  const [openChangePassword, setOpenChangePassword] =
    React.useState<boolean>(false)

  React.useEffect(() => {
    const validToken = moment.unix(loginData.tokenData.exp).isAfter(moment())
    if (!validToken) {
      logout()
    }
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? 'simple-popover' : undefined

  const changePage = (page: PageDetails) => {
    navigate(String(page.route), { state: { id: 7, color: 'green' } })
    dispatch(_changePage(page))
    handleDrawerClose()
  }

  const logout = () => {
    dispatch(
      _changeLogin({
        tokenData: '',
        accessToken: '',
        logged: false,
        email: '',
        picture: ''
      })
    )
    navigate('/login')
  }

  const RenderMenuItem = ({ page }: { page: PageDetails }) => {
    const showPage = modules?.find(item => item.cod === page.cod)
    if (!showPage?.cod) return <></>

    return (
      <ListItem
        button
        key={page.route}
        onClick={() => {
          changePage({
            name: page.name,
            description: page.description,
            icon: page.icon,
            route: page.route,
            cod: page.cod
          })
        }}
      >
        <ListItemIcon>
          <Icon color="primary">{page.icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={page.name} />
      </ListItem>
    )
  }

  const Menu = () => {
    return (
      <List>
        {pages.map((p: PageDetails) => (
          <React.Fragment key={p.route}>
            <RenderMenuItem
              page={p}
            />
          </React.Fragment>
        ))}
      </List>
    )
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" elevation={1}>
        <Toolbar sx={{ position: 'relative' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            // sx={{ mr: 2 }}
            sx={{ position: 'absolute', left: 24 }}
            style={{ color: '#484848' }}
          >
            <MenuIcon />
          </IconButton>
          <Container fixed sx={{ maxWidth: '90% !important' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Icon style={{ alignSelf: 'center' }} color="primary">
                  {page.icon}
                </Icon>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  style={{
                    color: '#484848',
                    alignSelf: 'center',
                    marginLeft: '10px'
                  }}
                >
                  {page.name}
                </Typography>
                <Typography
                  noWrap
                  component="div"
                  style={{
                    color: '#484848',
                    marginLeft: '10px',
                    alignSelf: 'center'
                  }}
                >
                  {page.description}
                </Typography>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* <IconButton aria-label="upload picture" component="span">
                  <Notifications
                    htmlColor="#4060E6"
                    style={{ alignSelf: 'center' }}
                  />
                </IconButton> */}
                <MenuNotifications />

                <IconButton
                  onClick={handleClick}
                  aria-label="upload picture"
                  component="span"
                  style={{ alignSelf: 'center', marginLeft: '10px', paddingRight: 0 }}
                >
                  <img
                    width="34"
                    height="34"
                    style={{ borderRadius: '16px' }}
                    src={loginData.picture}
                  />
                </IconButton>
              </div>

              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '150px',
                    padding: '4px'
                  }}
                >
                  <Button
                    style={{
                      color: '#212121',
                      textAlign: 'left',
                      justifyContent: 'left'
                    }}
                    variant="text"
                    onClick={() => setOpenUserAccount(true)}
                  >
                    Minha conta
                  </Button>
                  <Button
                    style={{
                      color: '#212121',
                      textAlign: 'left',
                      justifyContent: 'left'
                    }}
                    variant="text"
                    onClick={() => setOpenChangePassword(true)}
                  >
                    Alterar senha
                  </Button>
                  <Button
                    style={{
                      color: '#212121',
                      textAlign: 'left',
                      justifyContent: 'left'
                    }}
                    variant="text"
                    onClick={() => logout()}
                  >
                    Sair
                  </Button>
                </div>
              </Popover>
              {/* </Container> */}
            </div>
          </Container>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={false}
      >
        <DrawerHeader>
          <img
            style={{
              width: 'auto',
              maxWidth: '190px',
              display: 'flex',
              alignSelf: 'center',
              paddingInline: '30px'
            }}
            src={logo}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr'
              ? (
                <ChevronLeftIcon />
              )
              : (
                <ChevronRightIcon />
              )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Menu />
        <VersionLabel />
      </Drawer>
      <Main>
        <DrawerHeader />
        <Container fixed maxWidth='lg'
          sx={{ maxWidth: '90% !important' }}
        >
          <Outlet />
          {openUserAccount && (
            <UserAccount
              open={openUserAccount}
              close={() => setOpenUserAccount(false)}
            />
          )}
          {openChangePassword && (
            <ChangePasswordDialog
              open={openChangePassword}
              close={() => setOpenChangePassword(false)}
            />
          )}
        </Container>
      </Main>
      <React.Fragment key="left">
        <Drawer anchor={'left'} open={open} onClose={handleDrawerClose}>
          <DrawerHeader>
            <img
              style={{
                width: 'auto',
                maxWidth: '190px',
                display: 'flex',
                alignSelf: 'center',
                paddingInline: '30px'
              }}
              src={logo}
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr'
                ? (
                  <ChevronLeftIcon />
                )
                : (
                  <ChevronRightIcon />
                )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Menu />
          <DisplayTokenExpiration />
          <VersionLabel />
          <PrivacyLabel />
        </Drawer>
      </React.Fragment>
    </Box>
  )
}
