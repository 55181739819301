import { httpClient } from 'config/httpClient'
import { FormSalesGoalsType } from 'types/managementControl/salesGoals/SalesGoalsType'

export const createSalesGoals = async (dataBody: FormSalesGoalsType, id?: any) => {
    const { data } = await httpClient.post('/previsions/meta-sales', dataBody)
    return data as FormSalesGoalsType[]
  }

  export const getSalesGoals = async (): Promise<FormSalesGoalsType[]> => {
    const { data } = await httpClient.get('/previsions/report/meta-sales')
    return data as FormSalesGoalsType[]
  }

  export const putSalesGoals = async (id: any, dadosBody: FormSalesGoalsType) => {
    const { data } = await httpClient.put<any>(`/previsions/meta-sales/${id}`, dadosBody)
    return data
  }

  export const deleteSalesGoals = async (id: string) => {
    return await httpClient.delete(`/previsions/meta-sales/${id}`)
  }
