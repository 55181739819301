import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import HeaderView from '../../components/view/HeaderView'
import TableCustomerControl from './components/TableCustomerControl'

import { useNavigate } from 'react-router-dom'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'

const CustomerControl: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Can
      module={MODULES.CLIENTES}
      permissions={[PERMISSIONS.READ]}
    >
      <Box>
        <HeaderView register={() => navigate('/home/customer-control/form')} />
        <TableCustomerControl />
      </Box>
    </Can>
  )
}

export default CustomerControl
