enum IdentifierInformativeProduction {
  MES = '1',
  META_DIA = '2',
  ACUMULADO = '3'

}

interface InformativoProduction {
  identifier: IdentifierInformativeProduction
  previsionPriceKg: number
}

enum IdentifierClientProduction {
  OUTROS = '0004',
  TOTAL = '0',
  PAPELPRODUCTION = '62321a4dfe24f24bc319b535',
  CAIXAPRODUCTION = '62321aa4fe24f24bc319b536',
  CHAPAPRODUCTION = '623cf565692e6d4c8fe20136',
}

interface DataProduction {
  client: string
  identifierClient: IdentifierClientProduction
  mes: InformativoProduction
  meta_dia: InformativoProduction
  acumulado: InformativoProduction
}

export { IdentifierInformativeProduction, IdentifierClientProduction }
export type { DataProduction, InformativoProduction }
