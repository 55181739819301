/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Button as MuiButton,
  Checkbox,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import moment from 'moment';
import {
  BoxAlingCheckbox,
  ButtonRow,
  CardContentChieldren,
  CardFather,
  DialogStyle,
  DivAlingCard,
  DivAlingItensCard,
  DivRow,
  DivStepper,
  LabelCheckbox,
  LabelText,
  RetangleView
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import Stack from '@mui/material/Stack';
import { StepIconProps } from '@mui/material/StepIcon';
import { FieldArray, Form, Formik, FormikErrors, useFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import IconAndTitleView from 'components/view/IconAndTitleView';
import CustomButton from 'components/buttons/CustomButton';
import alertSwalCancel from 'components/Alerts/ModalCancel';
import GenericDialog from 'components/genericDialog/GenericDialog';
import ModalHead from './ModalHead';
import { useQuery } from 'react-query';
import { httpClient } from 'config/httpClient';
import { FormatPrice } from 'utils/FormatPrice';

function Section({ title, children }: { title: string, children: ReactNode }) {
  return (
    <>
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <h4>{title}</h4>
      </Grid>
      {children}
    </>
  );
}

const row: any = {};

interface Props {
  open: boolean
  close: () => void
  data?: any
}

const Budget: React.FC<Props> = (props) => {
  const [budget, setBudget] = useState<any>();
  const closeModal = () => props.close();

  const { errors, touched, values, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        _id: '',
        exit: false,
        reposition: false,
        ft: props.data?._id ?? '',
        cliente: '',
        ref: 'LN',
        ordemProducao: 'LN',
        ordemVenda: 'LN',
        qtdFaturada: 'LN',
        billingDate: '',
        nf: 'LN',
        diferencaEnviada: 'LN',
        qtdPallet: 'LN',
        entredaEstoque: 'LN',
        netWeight: '',
        dispatchedWeight: 0,
        requesteWeight: 0,
        board: '',
        deliveryAmountConfirmation: '',
        pallets: 'LN',
        formula: 'LN',
        deliveryTime: '',
        exitTime: '',
        startTime: '',
        observation: '',
        observationPCE: '',
        deliveryPrevision: '',
        dataFaturamentoLN: '',
        qtdSolicitadaOV: 'LN',
        paletizado: 'LN',
        Referencia: row?.Referencia ?? '',
        LarguraInterna: row?.LarguraInterna ?? undefined, // number
        ComprimentoInterno: row?.ComprimentoInterno ?? undefined, // number
        AlturaInterna: row?.AlturaInterna ?? undefined, // number
        Composicao: {
          composicao: row?.Composicao?.composicao ?? '',
          onda: row?.Composicao?.onda ?? '',
          onda1: row?.Composicao?.onda1 ?? '',
          onda2: row?.Composicao?.onda2 ?? '',
          onda3: row?.Composicao?.onda3 ?? '',
          onda4: row?.Composicao?.onda4 ?? '',
          onda5: row?.Composicao?.onda5 ?? '',
          papel1: row?.Composicao?.papel1 ?? '',
          papel2: row?.Composicao?.papel2 ?? '',
          papel3: row?.Composicao?.papel3 ?? '',
          papel4: row?.Composicao?.papel4 ?? '',
          papel5: row?.Composicao?.papel5 ?? '',
          nomeComercial: row?.Composicao?.nomeComercial ?? '',
          mullen: row?.Composicao?.mullen ?? undefined, // number
          crushEnd: row?.Composicao?.crushEnd ?? undefined, // number
          gramatura: row?.Composicao?.gramatura ?? undefined, // number
          crush: row?.Composicao?.crush ?? undefined, // number
          preco: row?.Composicao?.preco ?? undefined, // number
          coluna: row?.Composicao?.coluna ?? undefined, // number
          espessura: row?.Composicao?.espessura ?? undefined // number
        },
        Fechamento: {
          fechamento: row?.Fechamento?.fechamento ?? '',
          descricao: row?.Fechamento?.descricao ?? '',
          qtdEspessuras: row?.Fechamento?.qtdEspessuras ?? undefined // number
        },
        boxType: row?.boxType?._id ?? '',
        codTypebox: row?.codTypebox?._id ?? '',
        Paletizado: row?.Paletizado ?? undefined, // boolean
        Amarrado: row?.Amarrado ?? undefined, // boolean
        ExigeLaudo: row?.ExigeLaudo ?? undefined, // boolean
        Obs1: row?.Obs1 ?? '',
        PaletePadrao: row?.PaletePadrao ?? undefined, // boolean
        PaletePadraoLargura: row?.PaletePadraoLargura ?? '',
        PaletePadraoComprimento: row?.PaletePadraoComprimento ?? '',
        PaleteReforcado: row?.PaleteReforcado ?? undefined, // boolean
        PaleteReforcadoQuantidadeFita: row?.PaleteReforcadoQuantidadeFita ?? '',
        PaleteReforcadoQuantidadeLastro:
          row?.PaleteReforcadoQuantidadeLastro ?? '',
        AmarradoPalete: row?.AmarradoPalete ?? undefined, // boolean
        AmarradoPaleteQtd: row?.AmarradoPaleteQtd ?? '',
        AlcasFuro: row?.AlcasFuro ?? undefined, // boolean
        AlcasFuroQtdPorCaixa: row?.AlcasFuroQtdPorCaixa ?? '',
        Amostra: row?.Amostra ?? undefined, // boolean
        AmostraQtd: row?.AmostraQtd ?? '',
        Impressoes: row?.Impressoes ?? undefined, // boolean
        ImpressoesQtd: row?.ImpressoesQtd ?? '',
        ImpressoesCor: row?.ImpressoesCor ?? {
          cor1: '',
          cor2: '',
          cor3: '',
          cor4: '',
          cor5: '',
          cor6: '',
          cor7: '',
          cor8: '',
          cor9: '',
          cor10: ''
        },
        Tara: row?.Tara ?? undefined, // boolean
        Batido: row?.Batido ?? undefined, // boolean
        Definicao: row?.Definicao ?? '',
        NovoProduto: {
          BasedaNaFTD: row?.NovoProduto?.BasedaNaFTD ?? undefined, // boolean
          DesnehoEspecificacao:
            row?.NovoProduto?.DesnehoEspecificacao ?? undefined, // boolean
          AmostraMecanica: row?.NovoProduto?.AmostraMecanica ?? undefined, // boolean
          ProdutoDoCliente: row?.NovoProduto?.ProdutoDoCliente ?? undefined, // boolean
          ProdutoSemelhante: row?.NovoProduto?.ProdutoSemelhante ?? undefined, // boolean
          Outros: row?.NovoProduto?.Outros ?? undefined, // boolean
          OutrosObs: row?.NovoProduto?.OutrosObs ?? ''
        } // boolean
      },
      onSubmit(values) {
        const body = {
          exit: values.exit,
          reposition: values.reposition,
          billingDate: values.billingDate,
          netWeight: values.netWeight,
          dispatchedWeight: values.dispatchedWeight,
          requesteWeight: values.requesteWeight,
          deliveryAmountConfirmation: values.deliveryAmountConfirmation,
          deliveryTime: values.deliveryTime,
          exitTime: values.exitTime,
          startTime: values.startTime,
          observation: values.observation,
          deliveryPrevision: values.deliveryPrevision,
          observationPCE: values.observationPCE
        };
        if (props.data?.deliveryMap) {
          console.log('values: ', body);
        } else {
          console.log('values: ', body);
        }
      }
    });

  const { data, status, isFetching } = useQuery(
    ['modal-booking-budget', props?.data?.ft?._id],
    async () =>
      await httpClient.get<any[]>(`/budget/budgets/${props?.data?.ft?._id}`),
    {
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    setBudget(data?.data?.[0] ?? null);
  }, [data?.data, isFetching]);
  console.log(data);

  return (
    <>
      <DialogStyle
        open={props.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit}>
          <ModalHead title="Orçamento" idPreFT={props?.data?.ft?.preFT?._id} />

          {status !== 'loading' && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={2}>
                  <Section title="Quantidade">
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Previsão total (Unid)"
                        value={' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Previsão total (Kg)"
                        value={' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Amostra física (Unid)"
                        value={' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Lote mínimo"
                        value={budget?.loteMinimo ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  </Section>
                  <Section title="Previsão de consumo (Unidades)">
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Anual"
                        value={budget?.anual ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Semestral"
                        value={budget?.semestral ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Trimestral"
                        value={budget?.trimestral ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Bimestral"
                        value={budget?.bimestral ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  </Section>
                  <Section title="Especificações">
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Fechamento"
                        value={budget?.fechamento ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Resina"
                        value={budget?.resina ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Limpeza"
                        value={budget?.limpeza ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Largura (mm)"
                        value={''}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Área líquida"
                        value={budget?.areaLiquida ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Peso líquido"
                        value={budget?.pesoLiquido ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Área bruta"
                        value={budget?.areaBruta ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Peso bruto"
                        value={budget?.pesoBruto ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  </Section>
                  <Section title="Formação do preço">
                    <Grid item xs={12} md={3}>
                      <Stack direction="row" alignItems="flex-end">
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Peso (kg)"
                          value={budget?.pesoKg ?? ' '}
                          variant="standard"
                          disabled
                        />
                        <Box ml={2}>x</Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Tabela"
                        value={budget?.tabela ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Stack direction="row" alignItems="flex-end">
                        <Box mr={2}>=</Box>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Preço"
                          value={`${FormatPrice(budget?.preco) ?? ' '}`}
                          variant="standard"
                          disabled
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Preço total"
                        value={`${FormatPrice(budget?.precoNegociado)}`}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  </Section>

                  <Section title="Frete">
                    <Grid item xs={1}>
                      <FormControlLabel
                        control={<Checkbox checked={!!budget?.valorFrete} />}
                        label="Grátis"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Cidade de destino"
                            value={budget?.cidadeDestino ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="%"
                            value={' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="R$"
                            value={' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Preço total"
                            value={' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Section>
                  <Section title="Ferramental">
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={values.NovoProduto.Outros} />
                        }
                        label="Clichê polímero"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Custo (R$)"
                            value={' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Frete"
                            value={' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Diluído em (meses)"
                            value={' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={values.NovoProduto.Outros} />
                        }
                        label="Clichê metálico"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Custo (R$)"
                            value={' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Frete"
                            value={' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Diluído em (meses)"
                            value={' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Preço total"
                        value={'R$0,00'}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  </Section>

                  <Section title="Fechamento/Resina/Limpeza">
                    <Grid item xs={12}>
                      <Typography component="span">Fechamento</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Colagem manual"
                            value={FormatPrice(budget?.fechamentoColagemManual) ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Grampo/Cola"
                            value={FormatPrice(budget?.fechamentoGrampoCola) ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Resina"
                            value={FormatPrice(budget?.resina) ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Limpeza"
                            value={FormatPrice(budget?.limpeza) ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Preço"
                        value={FormatPrice(budget?.precoFechamento) ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Lote mínimo (Pçs)"
                            value={budget?.loteMinimo ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Preço à vista"
                            value={FormatPrice(budget?.precoAVista) ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Condições de pagamento"
                            value={`${budget?.condicoesPagamento} dias ddl`}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Taxa de financiamento"
                            value={`${budget?.valorTaxa}%`}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Valor da taxa"
                            value={FormatPrice(budget?.taxaFinanciamento) ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Preço por Kg com Ferramental"
                            value={FormatPrice(budget?.precoKgFerramental ?? 0) ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Preço por Kg sem Ferramental"
                            value={FormatPrice(
                              budget?.precoKgSemFerramental ?? 0
                            )}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Preço sugerido"
                            value={FormatPrice(budget?.precoSugerido) ?? ' '}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Preço por Kg"
                        value={FormatPrice(budget?.precoPorKg) ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Preço negociado"
                        value={FormatPrice(budget?.precoNegociado) ?? ' '}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  </Section>
                </Grid>

                <ButtonRow
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    marginTop: 30
                  }}
                >
                  <Button
                    style={{
                      marginRight: '10px',
                      backgroundColor: '#4060E6',
                      color: '#FFFFFF'
                    }}
                    variant="contained"
                    onClick={() => {
                      props.close();
                    }}
                  >
                    Voltar
                  </Button>
                  {/* <Button
                  style={{
                    marginRight: '10px',
                    backgroundColor: '#EE207A',
                    color: '#FFFFFF'
                  }}
                  onClick={() => {
                    handleOpenDialog();
                  }}
                  color="error"
                  variant="contained"
                >
                  Reprovar
                </Button>
                <Button
                  style={{
                    marginRight: '10px',
                    backgroundColor: '#1AB1B0',
                    color: '#FFFFFF'
                  }}
                  onClick={() => console.log('PORRA')}
                  color="success"
                  variant="contained"
                >
                  Gerar ordem de venda
                </Button> */}
                </ButtonRow>
              </DialogContentText>
            </DialogContent>
          )}
        </form>
      </DialogStyle>
    </>
  );
};

export default Budget;
