import { Card, CardContent, Container } from '@mui/material'
import styled from 'styled-components'
import background_login from '../../assets/img/background_login.png'

function PrivacyPage() {
  const ScrollView = styled.div`
    height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
  `

  return (
    <div style={{
      position: 'absolute',
      height: 'auto',
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(' + background_login + ')',
      WebkitBackgroundSize: 'cover'
    }}
    >
      <Container style={{ paddingTop: 30 }}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <ScrollView>
              <div>
                <h1 style={{ textAlign: 'center' }}>Política de Privacidade</h1>
                <p>Queremos ajudar você a entender como armazenamos os seus dados coletados no momento da utilização do nosso site, aplicativo e lojas físicas, bem como para quê coletamos esses dados e como você pode gerenciar isso. Caso não concorde com as condições aqui descritas, interrompa o uso. Preocupamo-nos em estar em conformidade com as Leis Federais que regem a proteção de dados pessoais na internet. </p>

                <p>
                  Este documento foi elaborado com base na Lei Federal nº 13.709/2018 - Lei Geral de Proteção de Dados “LGPD”. Leia com bastante atenção nossa Política de Privacidade antes de utilizar.
                </p>

                <b>1. Atualização da Política de Privacidade</b>
                <p>
                  Este documento poderá, a qualquer momento, ser modificado ou readequado de acordo com o aprimoramento das operações. Ao ocorrer, iremos notificá-lo através do e-mail cadastrado para que você analise as novas condições antes de optar por continuar a utilizar nossos serviços, te dando a opção de revogar o consentimento do uso dos seus dados.
                </p>
                <p>
                  Ao cadastrar-se, você deve dar o aceite nos termos de uso e política de privacidade para concordar com o tratamento dos dados que lhe são coletados.
                </p>
                <br></br>

                <b>2. Segurança das informações fornecidas</b>
                <p>
                  Comprometemo-nos a não divulgar os dados dos usuários cadastrados sem autorização para fins que não seja para consecução dos serviços oferecidos, a não ser mediante ordem judicial.
                </p>
                <p>
                  Utilizamos os seus dados com o objetivo de melhorar cada vez mais a sua experiência de compra e ir adequando nossas funcionalidades de acordo com suas necessidades.
                </p>
                <p>
                  Os usuários do sistema, devem comprometer-se a cumprir o aceite dos termos de uso e política de privacidade e não violar nenhum componente de segurança.
                </p>
                <br></br>

                <b>3. Lei geral de proteção de dados (LGPD)</b>
                <br></br>
                <br></br>
                <p>
                  3.1. Por meio desta cláusula, o USUÁRIO declara ciência e seu EXPRESSO CONSENTIMENTO para o sistema coletar e armazenar os seus dados quando julgar necessário.
                </p>
                <p>
                  3.2. O USUÁRIO autoriza a coleta de dados pessoais imprescindíveis à execução deste contrato, tendo sido informado quanto ao tratamento de dados que será realizado pelo sistema, nos termos da Lei n° 13.709/2018, especificamente quanto a coleta dos seguintes dados:
                  <br></br>
                  a) Nome completo;
                  <br></br>
                  b) Data de nascimento;
                  <br></br>
                  c) Número do Cadastro de Pessoas Físicas (CPF);
                  <br></br>
                  d) Números de telefone, WhatsApp e endereços de e-mail;
                  <br></br>
                  e) Número do Cadastro de Pessoas Jurídica (CNPJ).
                </p>
                <br></br>
                <p>
                  3.3. Finalidade do tratamento de dados: o USUÁRIO autoriza que o sistema utilize os dados pessoais e dados pessoais sensíveis listados neste contrato para as seguintes finalidades:
                  <br></br>
                  a) Possibilitar a execução do presente contrato;
                  <br></br>
                  b) Possibilitar que o sistema identifique o USUÁRIO;
                  <br></br>
                  c) Possibilitar que o sistema entre em contato com o USUÁRIO quando necessário;
                  <br></br>
                  d) Possibilitar que o sistema elabore contratos comerciais e emita cobranças contra o USUÁRIO;
                  <br></br>
                  e) Proporcionar a melhor experiência e suporte;
                  <br></br>
                  f) Cumprir as obrigações legais e regulatórias;
                  <br></br>
                  g) Gerar um maior grau de segurança e autenticidade;
                  <br></br>
                  h) Aprimoramento dos serviços prestados;
                  <br></br>
                  i) Prevenção e resolução de problemas técnicos ou de segurança;
                  <br></br>
                  j) Exercício regular de direitos do sistema®
                  <br></br>
                  k) Cumprimento de ordem judicial, de autoridade competente ou de órgão fiscalizador;
                  <br></br>
                  l) Fornecer produtos ou informar promoções.
                </p>
                <br></br>
                <p>
                  3.4. Compartilhamento de dados: Os dados coletados poderão ser utilizados para identificação de terrorismo, compartilhamento para órgãos de segurança, conforme solicitação legal pertinente, compartilhamento com autoridade administrativa e judicial no âmbito de suas competências com base no estrito cumprimento do dever legal, bem como com os órgãos de proteção ao crédito a fim de garantir a adimplência do USUÁRIO perante o sistema.
                </p>
                <br></br>
                <p>
                  3.5. O USUÁRIO poderá registrar e gravar todos os dados fornecidos em toda comunicação realizada com a sua equipe, seja por correio eletrônico, mensagens, telefone ou qualquer outro meio.
                </p>
                <br></br>
                <p>
                  3.6. Os dados coletados com base no legítimo interesse do USUÁRIO, bem como para garantir a fiel execução do contrato por parte do sistema, fundamentam-se no artigo 7º da LGPD.
                </p>
                <br></br>
                <p>
                  3.7. Responsabilidade pela Segurança dos dados: o sistema se responsabiliza por manter medidas de segurança, técnicas e administrativas suficientes a proteger os dados pessoais do Titular e à Autoridade Nacional de Proteção de Dados (ANPD), comunicando ao Titular, caso ocorra algum incidente de segurança que possa acarretar risco ou dano relevante, conforme artigo 48 da Lei n° 13.709/2020.
                </p>
                <br></br>
                <p>
                  3.8 Exclusão dos dados: será efetuado sem que haja prejuízo por parte do sistema, para tanto, caso o USUÁRIO deseje efetuar a revogação de algum dado, deverá preencher uma declaração neste sentido, ciente que a revogação de determinados dados poderá importar em eventuais prejuízos na prestação de serviços.
                </p>
                <br></br>
                <p>
                  3.9. Término do Tratamento de dados: Ao sistema é permitido manter e utilizar os dados pessoais do Titular durante todo o período contratualmente firmado para as finalidades relacionadas neste termo e ainda após o término da contratação, pelo prazo de 05 (cinco) anos, para cumprimento de obrigação legal ou impostas por órgãos de fiscalização, nos termos do artigo 16 da Lei n° 13.709/2018. Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser mantidos por período indefinido.
                </p>
                <br></br>
                <p>
                  3.10. O USUÁRIO autoriza, neste mesmo ato, a guarda dos documentos (contratos/documentos pessoais ou fiscais/notificações/ordens de serviços) - em que pese eles possuam dados pessoais - por parte do EMISSOR a fim de que ele cumpra com o determinado nas demais normas que regulam o presente contrato, bem como para o cumprimento da obrigação legal nos termos do artigo 16, inciso I, da Lei Geral de Proteção de Dados.
                </p>
                <br></br>
                <p>
                  3.11. O sistema informa que a gerência de dados ocorrerá através de um sistema que colherá e tratará os dados na forma da lei.
                </p>
                <br></br>
                <p>
                  3.12. O sistema informa que efetuará a manutenção do registro das operações de tratamento de dados pessoais da forma mencionada na cláusula anterior.
                </p>
                <br></br>
                <p>
                  3.13. Rescindido o contrato, os dados pessoais coletados serão armazenados pelo tempo determinado previsto neste termo de uso.
                </p>
                <br></br>
                <p>
                  3.14. Passado o termo de guarda pertinente ao sistema se compromete a efetuar o descarte dos dados adequadamente.
                </p>
                <br></br>
                <p>
                  3.15. Seus direitos: O USUÁRIO tem direito a obter do sistema, em relação aos dados por ele tratados, a qualquer momento, e mediante requisição, conforme previsão do art. 18 da LGPD:
                  <br></br>
                  a) Solicitar confirmação da existência de tratamento;
                  <br></br>
                  b) Acessar seus dados pessoais;
                  <br></br>
                  c) Solicitar a correção de dados incompletos, inexatos ou desatualizado;
                  <br></br>
                  d) Solicitar anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na LGPD;
                  <br></br>
                  e) Solicitar a portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;
                  <br></br>
                  f) Solicitar a eliminação dos dados pessoais tratados com o seu consentimento, exceto nas hipóteses previstas neste termo e no artigo 16 da LGPD;
                  <br></br>
                  g) Solicitar informação das autoridades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;
                  <br></br>
                  h) Obter informações sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e
                  <br></br>
                  i) Revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709, de 2018.
                </p>
                <br></br>
                <p>
                  3.16. O consentimento conferido por meio deste instrumento particular poderá ser revogado a qualquer momento pelo(a) USUÁRIO, mediante sua manifestação expressa, por meio de solicitação via e-mail ou por correspondência encaminhada ao sistema, ratificados os tratamentos realizados sob amparo do presente consentimento, nos termos do inciso VI do “caput” do art. 18 da Lei nº 13.709, de 2018. Nossa equipe estará pronta para atender sua solicitação no prazo de até 72 horas.
                </p>
              </div>
            </ScrollView>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}

export default PrivacyPage
