import * as yup from 'yup'
import { onlyNumbers } from 'helpers/stringHelper'

const validationInformationJuridicalSchema = yup.object().shape({
  pj: yup.object().shape({
    cnpj: yup
    .string()
    .required('Preencha este campo')
    .test('length-cpf', 'O comprimento mínimo é 11', (value) => {
      const length = onlyNumbers(value ?? '').length
      return length > 10
    })
    .test('length-cnpj', 'O comprimento mínimo é 14', (value) => {
      const length = onlyNumbers(value ?? '').length
      return length === 11 || length > 13
    })
  }),

  name: yup.string().required('Preencha este campo')
  // cep: yup
  //   .string()
  //   .typeError('você deve especificar um número')
  //   .required('Preencha este campo'),
  // public_place: yup.string().required('Preencha este campo'),
  // number: yup
  //   .number()
  //   .typeError('você deve especificar um número')
  //   .required('Preencha este campo'),
  // district: yup.string().required('Preencha este campo'),
  // state: yup.string().required('Preencha este campo'),
  // city: yup.string().required('Preencha este campo')
})

export { validationInformationJuridicalSchema }
