/* eslint-disable @typescript-eslint/no-unused-vars */
import TabsUnstyled from '@mui/base/TabsUnstyled'
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import MoreMenu from 'components/view/MoreMenu'
import StatusViewFT from 'components/view/StatusViewFT'
import { httpClient } from 'config/httpClient'
import { RootState } from 'core/store'
import moment from 'moment'
import { ModalFtProposalClient } from 'pages/ft/sampleDevFT/components/ModalFtProposalClient'
import { ModalFtSampleClient } from 'pages/ft/sampleDevFT/components/ModalFtSampleClient'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Tab, TabPanel, TabsList } from './solicitationContainer.styles'

interface Props {
  company: string
}

export const SolicitationContainer: React.FC<Props> = ({ company }) => {
  const { loginData } = useSelector((state: RootState) => state.login)
  console.log(loginData.tokenData.sub)
  const search = ''
  const [prefts, setPrefts] = useState([])
  const [openModalFtProposalClient, setOpenModalFtProposalClient] =
    useState(false)
  const [openModalFtSampleClient, setOpenModalFtSampleClient] =
    useState(false)
  const [selectFt, setSelectFt] = useState<any | null>(null)

  const { isLoading } = useQuery(
    ['clientes-seller-prefts'],
    async () => httpClient.get('/ft'),
    // httpClient.get(`/prefts/seller/all?user=${loginData.tokenData.sub}&types=CHAPA,CAIXA&search=${search ?? ''}`),
    {
      onSuccess: (response) => {
        console.log('FTS', response.data)
        setPrefts(response.data)
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message)
      },
      refetchOnWindowFocus: false,
      cacheTime: 5000
    }
  )

  return (
    <>
      <TabsUnstyled defaultValue={0}>
        <Box marginBottom={2}>
          <TabsList>
            <Tab type="button">Solicitações</Tab>
            <Tab type="button">Produção</Tab>
          </TabsList>
        </Box>

        <TabPanel value={0}>
          <Table sx={{ minWidth: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tipo</TableCell>
                <TableCell align="left">Cliente</TableCell>
                <TableCell align="left">Código FT</TableCell>
                <TableCell align="left">Emitido em</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prefts.map((row: any) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.preFT?.type}
                  </TableCell>
                  <TableCell align="left">{row?.preFT?.client?.name}</TableCell>
                  <TableCell align="left">{row?.itemReference}</TableCell>
                  <TableCell align="left">
                    {row?.createdAt &&
                      moment(row?.createdAt).format('DD/MM/YYYY [às] HH:mm')}
                  </TableCell>
                  <TableCell align="left">
                    <StatusViewFT status={row.status} />
                  </TableCell>
                  <TableCell align="left">
                    <MoreMenu
                      proposal={() => {
                        setSelectFt(row)
                        setOpenModalFtProposalClient(true)
                      }}
                      sample={() => {
                        setSelectFt(row)
                        setOpenModalFtSampleClient(true)
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value={1}>
          <h1>Produção</h1>
        </TabPanel>
      </TabsUnstyled>

      {selectFt && (
        <ModalFtProposalClient
          open={openModalFtProposalClient}
          onClose={() => {
            setOpenModalFtProposalClient(false)
            setSelectFt(null)
          }}
          ft={selectFt}
        />
      )}

      {selectFt && (
        <ModalFtSampleClient
          open={openModalFtSampleClient}
          onClose={() => {
            setOpenModalFtSampleClient(false)
            setSelectFt(null)
          }}
          ft={selectFt}
        />
      )}
    </>
  )
}
