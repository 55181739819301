/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable operator-linebreak */
import { Box, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import DatePicker from '@mui/lab/DatePicker'
import { getItemDeliveryMap, getOrderServiceByItem, getPalletsOrderProduction, getProductionFLow } from 'services/report/DeliveryMapService'
import CustomButton from 'components/buttons/CustomButton'
import { ItemDeliveryMap } from 'types/deliveryMap/OrderType'
import FormInitalValues from './FormInitialValues'
import FormDeliveryMap from './FormDeliveryMap'

interface itemDeliveryMapProps {
  fields: ItemDeliveryMap[]
}

const ViewOrderMap: React.FC = () => {
  const [valueDate, setValueDate] = useState<any | null>(new Date())
  const { state }: any = useLocation()
  const [orders, setOrders] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [row, setRow] = useState<itemDeliveryMapProps>({ fields: [] } as itemDeliveryMapProps)
  const [dataValues, setDataValues] = useState<any[]>([])

  useEffect(() => {
    void getItemDelivery()
  }, [])

  const getItemDelivery = async () => {
    try {
      if (state.row._id) {
        const response = await getItemDeliveryMap(state.row._id)
        setRow({ fields: response })
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const queryMultiple = () => {
    // requisição externa do LN
    const req1 = useQuery(['order-service', valueDate],
      async () => {
        return getOrderServiceByItem(state.row.itemReference, valueDate)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )
    const req2 = useQuery(['productionFlow', state.row.itemReference],
      async () => {
        return getProductionFLow(state.row.itemReference)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )
    return [req1, req2]
  }

  const [req1, req2] = queryMultiple()

  useEffect(() => {
    if (!req1.isLoading) {
      handleFormatData(req1.data, row, req2.data)
    }
  }, [req1.isLoading])

  const handleFormatData = (dataLN: any, dataLocal: any, produtionFlow: any) => {
    const filter = produtionFlow.filter(o1 => dataLN.some(o2 => o1.item === o2.item))
    const newDateOrder = [] as any[]
    dataLN.forEach((element) => {
      newDateOrder.push({
        ...element,
        productionFlow: filter[0]?.fluxoProducao ?? []
      })
    })

    setOrders(newDateOrder)

    const vazio = [] as any[]
    dataLocal.fields.forEach((item) => {
      vazio.push(item)
    })
    const result = newDateOrder.filter(o1 => !dataLocal.fields.some(o2 => o1.index === o2.index))
    result.forEach((item) => {
      vazio.push(item)
    })

    setDataValues(vazio)
  }

  const navigate = useNavigate()

  // const handleRefetch = () => {
  //   refetch()
  // }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Mapa de entrega', url: '/home/report/deliveryMap' },
          { text: 'Ordens' }
        ]}
      />
      <h4>Ordens</h4>
      <div style={{ display: 'flex', marginTop: '15px', justifyContent: 'flex-end' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <DatePicker
            label="Dia"
            value={valueDate}
            onChange={(newValue) => {
              setValueDate(newValue)
            }}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </LocalizationProvider>
      </div>
      <div>
        {(req1.isLoading) && (
          <Stack justifyContent="center" alignItems="center" height={200}>
            <CircularProgress />
            <div>Buscando Ordens da FT</div>
          </Stack>
        )}

        {req1.isError && (
          <>
            <Box
              sx={{
                display: 'flex',
                height: 200,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography component="span" variant="body2">
                Error ao listar, tente novamente
              </Typography>
            </Box>
          </>
        )}

        {(!req1.isLoading) && (
          row.fields.length
            ?
            <FormDeliveryMap dataValues={dataValues} state={state} />
            :
            <FormInitalValues state={state} orders={orders} />
        )}
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 3
        }}
      >
        <CustomButton
          mold="primary"
          type="submit"
          style={{ marginRight: '21px' }}
          loading={loading}
          disabled={loading}
          onClick={() => navigate('/home/report/deliveryMap')}
        >
          Voltar
        </CustomButton>
      </Box>
    </Box>
  )
}

export default ViewOrderMap
