import moment from 'moment'
import { HeaderParams } from 'types/salesOrder/HeaderParamsType'
import { LineParams } from 'types/salesOrder/LineParamsType'

const mountHeader = (headerParams: HeaderParams, customerData: string, extra: string) => {
  return '<SalesOrderHeader>' +
    '          <DocumentID>' +
    '            <ID accountingEntity="452" location="D_452VEN" lid="lid://infor.ln.ln01/0452" variationID="0" SalesOrderHeader_DocumentID_ID="' + `${headerParams.displayId}` + '">' + `${headerParams.displayId}` + '</ID>' +
    '          </DocumentID>' +
    '          <AlternateDocumentID>' +
    '            <ID schemeName="Customer">' + `${extra.length ? extra : headerParams.displayId}` + '</ID>' +
    '          </AlternateDocumentID>' +
    '          <DisplayID>' + `${headerParams.displayId}` + '</DisplayID>' +
    '          <LastModificationDateTime>' + `${headerParams.lastModificationDateTime}` + '</LastModificationDateTime>' +
    '          <DocumentDateTime>' + `${headerParams.documentDateTime}` + '</DocumentDateTime>' +
    '          <Status>' +
    '            <Code listID="Sales Order Status">Open</Code>' +
    '            <EffectiveDateTime>' + `${headerParams.effectiveDateTime}` + '</EffectiveDateTime>' +
    '            <ArchiveIndicator>false</ArchiveIndicator>' +
    '          </Status>' +
    '          <CustomerParty>' +
    customerData +
    '          </CustomerParty>' +
    '          <SupplierParty>' +
    '            <Location type="Office">' +
    '              <ID accountingEntity="452" lid="lid://infor.ln.ln01/0452">D_452VEN</ID>' +
    '              <Name>Departamento de Venda</Name>' +
    '            </Location>' +
    '          </SupplierParty>' +
    '          <ShipToParty>' +
    customerData +
    '          </ShipToParty>' +
    '          <ExtendedAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.priceAmount}` + '</ExtendedAmount>' +
    '          <ExtendedBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.priceAmount}` + '</ExtendedBaseAmount>' +
    '          <ExtendedReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.priceAmount}` + '</ExtendedReportAmount>' +
    '          <CanceledAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.canceledPriceAmount}` + '</CanceledAmount>' +
    '          <CanceledBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.canceledPriceAmount}` + '</CanceledBaseAmount>' +
    '          <CanceledReportingAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.canceledPriceAmount}` + '</CanceledReportingAmount>' +
    '          <BillToParty>' +
    customerData +
    '          </BillToParty>' +
    '          <PayFromParty>' +
    customerData +
    '          </PayFromParty>' +
    '          <PartialShipmentAllowedIndicator>true</PartialShipmentAllowedIndicator>' +
    '          <DropShipmentAllowedIndicator>false</DropShipmentAllowedIndicator>' +
    '          <PaymentTerm type="Standard">' +
    '            <IDs>' +
    '              <ID>' + `${headerParams.paymentTermID}` + '</ID>' +
    '            </IDs>' +
    '            <Description>' + `${headerParams.paymentTermDescription}` + '</Description>' +
    '            <PaymentTermCode listID="Payment Term" accountingEntity="451">' + `${headerParams.paymentTermID}` + '</PaymentTermCode>' +
    '            <Term>' +
    '              <Duration>' + `${headerParams.paymentTermDuration}` + '</Duration>' +
    '            </Term>' +
    '            <Discount>' +
    '              <DiscountPercent>' + `${headerParams.paymentTermDiscountPercent}` + '</DiscountPercent>' +
    '            </Discount>' +
    '            <Penalty>' +
    '              <ID/>' +
    '            </Penalty>' +
    '          </PaymentTerm>' +
    '          <RequestedShipDateTime>' + `${headerParams.requestedShipDateTime}` + '</RequestedShipDateTime>' +
    '          <RequiredDeliveryDateTime>' + `${headerParams.requiredDeliveryDateTime}` + '</RequiredDeliveryDateTime>' +
    '          <PromisedShipDateTime>' + `${headerParams.promisedShipDateTime}` + '</PromisedShipDateTime>' +
    '          <PromisedDeliveryDateTime>' + `${headerParams.promisedDeliveryDateTime}` + '</PromisedDeliveryDateTime>' +
    '          <PaymentMethodCode listID="Payment Methods" accountingEntity="451">' + `${headerParams.paymentMethodCode}` + '</PaymentMethodCode>' +
    '          <BackOrderIndicator>true</BackOrderIndicator>' +
    '          <UserArea>' +
    '            <Property>' +
    '              <NameValue name="ln.RateDeterminer" type="StringType">Document Date</NameValue>' +
    '            </Property>' +
    '            <Property>' +
    '              <NameValue name="ln.Blocked" type="IndicatorType">false</NameValue>' +
    '            </Property>' +
    '            <Property>' +
    '              <NameValue name="ln.Canceled" type="IndicatorType">false</NameValue>' +
    '            </Property>' +
    '            <Property>' +
    '              <NameValue name="ln.OrderType" type="StringType">' + `${headerParams.orderType}` + '</NameValue>' +
    '            </Property>' +
    '            <Property>' +
    '               <NameValue name="ln.DeliveryTerms" type="StringType">' + `${headerParams.deliveryTerm}` + '</NameValue>' +
    '            </Property>' +
    '            <Property>' +
    '              <NameValue name="ln.OrderSeries" type="StringType">' + `${headerParams.orderSeries}` + '</NameValue>' +
    '            </Property>' +
    '           <Property>' +
    '             <NameValue type="IndicatorType" name="ln.AllowDuplicateCustomerOrder">true</NameValue>' +
    '           </Property>' +
    '           <Property>' +
    '             <NameValue type="IndicatorType" name="ln.UseDeltaDetection">false</NameValue>' +
    '           </Property>' +
    '          </UserArea>' +
    (
      headerParams.carrierParty
        ? '<CarrierParty>' +
        '            <PartyIDs>' +
        '              <ID>' + `${headerParams.carrierParty}` + '</ID>' +
        '            </PartyIDs>' +
        '          </CarrierParty>'
        : ''
    ) +
    '          <BillingTriggerCode>CustomerAcceptance</BillingTriggerCode>' +
    '          <InvoiceImmediatelyIndicator>false</InvoiceImmediatelyIndicator>' +
    '          <PricingRequiredIndicator>false</PricingRequiredIndicator>' +
    '          <RushIndicator>false</RushIndicator>' +
    '          <SelfBillingIndicator>false</SelfBillingIndicator>' +
    '          <SalesPersonReference>' +
    '            <SalesPersonRole listID="Sales Person Roles">External</SalesPersonRole>' +
    '          </SalesPersonReference>' +
    '          <SalesPersonReference>' +
    '            <IDs>' +
    '              <ID accountingEntity="451" lid="lid://infor.ln.ln01/0451">254</ID>' +
    '            </IDs>' +
    '            <Name>' + `${headerParams.salesPersonReferenceName}` + '</Name>' +
    '            <SalesPersonRole listID="Sales Person Roles">Internal</SalesPersonRole>' +
    '          </SalesPersonReference>' +
    '          <Classification>' +
    '            <Codes>' +
    '              <Code listID="Industries" sequence="1" accountingEntity="451">VAR</Code>' +
    '              <Code listID="Departments" sequence="2" accountingEntity="452">452VEN</Code>' +
    '            </Codes>' +
    '          </Classification>' +
    '          <ExtendedPretaxAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.preTaxPriceAmount}` + '</ExtendedPretaxAmount>' +
    '          <ExtendedPretaxBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.preTaxPriceAmount}` + '</ExtendedPretaxBaseAmount>' +
    '          <ExtendedPretaxReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.preTaxPriceAmount}` + '</ExtendedPretaxReportAmount>' +
    '          <SubTotalAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.priceAmount}` + '</SubTotalAmount>' +
    '          <SubTotalBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.priceAmount}` + '</SubTotalBaseAmount>' +
    '          <SubTotalReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.priceAmount}` + '</SubTotalReportAmount>' +
    '          <BaseCurrencyAmount type="ExtendedAmount">' +
    '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.priceAmount}` + '</Amount>' +
    '          </BaseCurrencyAmount>' +
    '          <BaseCurrencyAmount type="ExtendedPretaxAmount">' +
    '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.preTaxPriceAmount}` + '</Amount>' +
    '          </BaseCurrencyAmount>' +
    '          <BaseCurrencyAmount type="SubTotalAmount">' +
    '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${headerParams.priceAmount}` + '</Amount>' +
    '          </BaseCurrencyAmount>' +
    '          <CurrencyCode listID="Currency">' + `${headerParams.currencyID}` + '</CurrencyCode>' +
    '        </SalesOrderHeader>'
}

const mountSalesOrderLines = (linesParams: LineParams[], headerParams: HeaderParams, customerData: string) => {
  let orderLines = ''
  linesParams.forEach((lineParam, i) => {
    orderLines += '<SalesOrderLine>' +
      '          <LineNumber>' + `${(i + 1) * 10}` + '</LineNumber>' +
      (!lineParam.customerOrderPosition
        ? '          <AlternateLineNumber/>'
        : `<AlternateLineNumber>${lineParam.customerOrderPosition}</AlternateLineNumber>`) +
      '          <Note/>' +
      '          <Status>' +
      '            <Code listID="Sales Order Status">Open</Code>' +
      '            <EffectiveDateTime>' + `${lineParam.effectiveDateTime}` + '</EffectiveDateTime>' +
      '            <ArchiveIndicator>false</ArchiveIndicator>' +
      '          </Status>' +
      '          <Item>' +
      '            <ItemID>' +
      '              <ID accountingEntity="452" lid="lid://infor.ln.ln01/0452">' + `${lineParam.itemId}` + '</ID>' +
      '            </ItemID>' +
      '            <Description>' + `${lineParam.itemDescription}` + '</Description>' +
      '            <SerializedLot>' +
      '              <Lot>' +
      '                <LotIDs>' +
      '                  <ID/>' +
      '                </LotIDs>' +
      '                <SerialNumber/>' +
      '              </Lot>' +
      '              <SerialNumber/>' +
      '              <LotSelection>Any</LotSelection>' +
      '            </SerializedLot>' +
      '          </Item>' +
      '          <Quantity unitCode="' + `${lineParam.unitCode}` + '">' + `${lineParam.unitAmount}` + '</Quantity>' +
      '          <BaseUOMQuantity unitCode="' + `${lineParam.unitCode}` + '">' + `${lineParam.unitAmount}` + '</BaseUOMQuantity>' +
      '          <UnitPrice>' +
      '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</Amount>' +
      '            <BaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</BaseAmount>' +
      '            <ReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</ReportAmount>' +
      '            <PerQuantity unitCode="' + `${lineParam.unitCode}` + '">' + `${lineParam.perQuantity}` + '</PerQuantity>' +
      '            <PerBaseUOMQuantity unitCode="' + `${lineParam.unitCode}` + '">' + `${lineParam.perQuantity}` + '</PerBaseUOMQuantity>' +
      '          </UnitPrice>' +
      '          <ExtendedAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</ExtendedAmount>' +
      '          <ExtendedBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</ExtendedBaseAmount>' +
      '          <ExtendedReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</ExtendedReportAmount>' +
      '          <TotalAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</TotalAmount>' +
      '          <TotalBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</TotalBaseAmount>' +
      '          <TotalReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</TotalReportAmount>' +
      '          <RequiredDeliveryDateTime>' + `${lineParam.requiredDeliveryDateTime}` + '</RequiredDeliveryDateTime>' +
      '          <ShipToParty>' +
      customerData +
      '          </ShipToParty>' +
      '          <PartialShipmentAllowedIndicator>true</PartialShipmentAllowedIndicator>' +
      '          <DropShipmentAllowedIndicator>false</DropShipmentAllowedIndicator>' +
      '          <PaymentTerm type="Standard">' +
      '            <IDs>' +
      '              <ID>' + `${headerParams.paymentTermID}` + '</ID>' +
      '            </IDs>' +
      '            <Description>' + `${headerParams.paymentTermDescription}` + '</Description>' +
      '            <PaymentTermCode listID="Payment Term" accountingEntity="451">' + `${headerParams.paymentTermID}` + '</PaymentTermCode>' +
      '          </PaymentTerm>' +
      '          <PromisedShipDateTime>' + `${lineParam.promisedShipDateTime}` + '</PromisedShipDateTime>' +
      '          <PromisedDeliveryDateTime>' + `${lineParam.promisedDeliveryDateTime}` + '</PromisedDeliveryDateTime>' +
      '          <BackOrderIndicator>true</BackOrderIndicator>' +
      '          <ScheduledDeliveryDateTime>' + `${lineParam.scheduledDeliveryDateTime}` + '</ScheduledDeliveryDateTime>' +
      '          <UserArea>' +
      '            <Property>' +
      '              <NameValue name="ln.SalesType" type="StringType">001</NameValue>' +
      '            </Property>' +
      '            <Property>' +
      '              <NameValue name="ln.Blocked" type="IndicatorType">false</NameValue>' +
      '            </Property>' +
      '            <Property>' +
      '              <NameValue name="ln.Canceled" type="IndicatorType">false</NameValue>' +
      '            </Property>' +
      '            <Property>' +
      '              <NameValue name="ln.OrderDate" type="DateTimeType">' + `${moment().utc().format()}` + '</NameValue>' +
      '            </Property>' +
      '            <Property>' +
      (lineParam.customerOrderNumber
        ? `<NameValue name="ln.CustomerOrderNumber" type="StringType"> ${lineParam.customerOrderNumber} </NameValue>`
        : '') +
      '            </Property>' +
      '          </UserArea>' +
      '          <RushIndicator>false</RushIndicator>' +
      '          <SelfBillingIndicator>false</SelfBillingIndicator>' +
      '          <ShipFromParty>' +
      '            <Location type="Warehouse">' +
      '              <ID accountingEntity="452" lid="lid://infor.ln.ln01/0451">' + `${lineParam.shipFromPartyId}` + '</ID>' +
      '            </Location>' +
      '          </ShipFromParty>' +
      '          <FixedPriceItemIndicator>false</FixedPriceItemIndicator>' +
      '          <ShippedQuantity unitCode="UN">0</ShippedQuantity>' +
      '          <ShippedBaseUOMQuantity unitCode="UN">0</ShippedBaseUOMQuantity>' +
      '          <UnitQuantityConversionFactor>1</UnitQuantityConversionFactor>' +
      '          <PricingAmount>' +
      '            <UnitBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</UnitBaseAmount>' +
      '            <UnitBaseBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</UnitBaseBaseAmount>' +
      '            <UnitBaseReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</UnitBaseReportAmount>' +
      '            <ExtendedUnitBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</ExtendedUnitBaseAmount>' +
      '            <ExtendedUnitBaseBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</ExtendedUnitBaseBaseAmount>' +
      '            <ExtendedUnitBaseReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</ExtendedUnitBaseReportAmount>' +
      '            <UnitPretaxAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</UnitPretaxAmount>' +
      '            <UnitPretaxBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</UnitPretaxBaseAmount>' +
      '            <UnitPretaxReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</UnitPretaxReportAmount>' +
      '            <TotalPretaxAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</TotalPretaxAmount>' +
      '            <TotalPretaxBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</TotalPretaxBaseAmount>' +
      '            <TotalPretaxReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</TotalPretaxReportAmount>' +
      '            <UnitTotalAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</UnitTotalAmount>' +
      '            <UnitTotalBaseAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</UnitTotalBaseAmount>' +
      '            <UnitTotalReportAmount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</UnitTotalReportAmount>' +
      '          </PricingAmount>' +
      '          <BaseCurrencyAmount type="ExtendedAmount">' +
      '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</Amount>' +
      '          </BaseCurrencyAmount>' +
      '          <BaseCurrencyAmount type="UnitPriceAmount">' +
      '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</Amount>' +
      '          </BaseCurrencyAmount>' +
      '          <BaseCurrencyAmount type="PricingAmountExtendedUnitBaseAmount">' +
      '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</Amount>' +
      '          </BaseCurrencyAmount>' +
      '          <BaseCurrencyAmount type="PricingAmountTotalPretaxAmount">' +
      '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</Amount>' +
      '          </BaseCurrencyAmount>' +
      '          <BaseCurrencyAmount type="PricingAmountUnitBaseAmount">' +
      '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</Amount>' +
      '          </BaseCurrencyAmount>' +
      '          <BaseCurrencyAmount type="PricingAmountUnitTotalAmount">' +
      '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.unitPriceAmount}` + '</Amount>' +
      '          </BaseCurrencyAmount>' +
      '          <BaseCurrencyAmount type="ExtendedPretaxAmount">' +
      '            <Amount currencyID="' + `${headerParams.currencyID}` + '">' + `${lineParam.extendedPriceAmount}` + '</Amount>' +
      '          </BaseCurrencyAmount>' +
      '        </SalesOrderLine>'
  })
  return orderLines
}

export const generateXML = (headerParams: HeaderParams, linesParams: LineParams[], extra: string, clientData: any) => {
  const customerData = '            <PartyIDs>' +
    '              <ID accountingEntity="451" lid="lid://infor.ln.ln01/0451">' + `${headerParams.clientLnId}` + '</ID>' +
    '            </PartyIDs>' +
    '            <Name>' + `${(headerParams.customerName).replace('&', '&amp;')}` + '</Name>'

  const salesOrderHeader = mountHeader(headerParams, customerData, extra)
  const salesOrderLines = mountSalesOrderLines(linesParams, headerParams, customerData)

  return '<?xml version="1.0"?>' +
    '  <ProcessSalesOrder xmlns="http://schema.infor.com/InforOAGIS/2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://schema.infor.com/InforOAGIS/2 http://schema.infor.com/2.14.x/InforOAGIS/BODs/ProcessSalesOrder.xsd" xmlns:xsd="http://www.w3.org/2001/XMLSchema" releaseID="9.2" versionID="2.14.x">' +
    '    <ApplicationArea>' +
    '      <Sender>' +
    '        <LogicalID>lid://infor.ln.ln01/0452</LogicalID>' +
    '        <ComponentID>erp</ComponentID>' +
    '        <ConfirmationCode>OnError</ConfirmationCode>' +
    '      </Sender>' +
    '      <CreationDateTime>' + `${headerParams.creationDateTime}` + '</CreationDateTime>' +
    '          <BODID>infor-nid:COMPAZ_' + `${headerParams.enviroment}` + ':452:D_452VEN:' + `${headerParams.displayId}` + ':?SalesOrder&amp;verb=Sync</BODID>' +
    '      ' +
    '    </ApplicationArea>' +
    '    <DataArea>' +
    '      <Process>' +
    '        <TenantID>COMPAZ_' + `${headerParams.enviroment}` + '</TenantID>' +
    '        <AccountingEntityID>452</AccountingEntityID>' +
    '        <LocationID>D_452VEN</LocationID>' +
    '        <ActionCriteria>' +
    '          <ActionExpression actionCode="Add"/>' +
    '        </ActionCriteria>' +
    '      </Process>' +
    '      <SalesOrder>' +
    salesOrderHeader +
    salesOrderLines +
    '      </SalesOrder>' +
    '    </DataArea>' +
    '  </ProcessSalesOrder>'
}
