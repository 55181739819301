/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { TableRow } from '@mui/material'
import { StyledTableCell } from './deliveryMap.style'

interface Props {
  result: any[]
  colSpan?: number
}

export function TotalWightTableRow({ result, colSpan = 11 }: Props) {
  const totalWeight =
    result?.reduce(
      (acc, item) =>
        acc +
        Number(Number(item?.quantidadeFaturada).toFixed(0)) *
          Number(item?.peso ?? 0),
      0
    ) ?? 0
  return (
    <TableRow>
      <StyledTableCell colSpan={colSpan}>
        Peso Total: {totalWeight?.toLocaleString()} kg
      </StyledTableCell>
    </TableRow>
  )
}
