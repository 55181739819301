/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import Stack from '@mui/material/Stack';
import GenericDialog from 'components/genericDialog/GenericDialog';
import { httpClient, httpLN } from 'config/httpClient';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { HeaderParams } from 'types/salesOrder/HeaderParamsType';
import { LineParams } from 'types/salesOrder/LineParamsType';
import { generateXML } from 'utils/GenerateOrderXML';
import {
  ButtonRow,
  DialogStyle,
  DivRow
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';

import {
  getClientData,
  getClientLimitCredit,
  getSalesOrderTrans,
  getSequencial,
  getTipoOV
} from 'services/orderService/OrderService';
import ModalReplanejarPedido from '../components/ModalReplanejarPedido';
import { ModalHeadAudit } from '../components/audit/ModalHeadAudit';

import alertMessage from 'components/Alerts/ModalAviso';
import ConfirmSellerOrder from './ConfirmSellerOrder';

function ShowComponentValidateStatus({
  status,
  children
}: {
  status: string
  children: any
}) {
  if (status === 'SUSPENSO') return <></>;
  return children;
}

const color: any = {
  'AMOSTRA ENVIADA': '#00B4E6',
  'ENVIAR QUANTIDADE': '#003050',
  ENVIADO: '#00B4E6',
  PENDENTE: '#EEBD20',
  APROVADO: '#00e63a',
  REPROVADO: '#EE207A',
  NOVO: '#6B49E8',
  RECORRENTE: '#6B49E8',
  'EM PRODUÇÃO': '#6B49E8',
  DESENVOLVIMENTO: '#6B49E8',
  CONTRAPROPOSTA: '#00B4E6',
  'AGUARDANDO APROVAÇÃO': '#00B4E6',
  CANCELADO: '#EE207A'
};

interface Props {
  open: boolean
  close: () => void
  closeSuccess: () => void
  data?: any
}

const ModalAudit: React.FC<Props> = (props) => {
  console.log('ModalAudit => ', props);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const [openReplanejar, setOpenReplanejar] = React.useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [pedido, setPedido] = useState<any>(props?.data);
  const [moeda, setMoeda] = useState('BRL');
  const [extra, setExtra] = useState('');
  const [repetirExtra, setRepetirExtra] = useState(false);
  const [tipoOV, setTipoOV] = useState('');
  const [transportadora, setTransportadora] = useState('');
  const [entrega, setEntrega] = useState('');

  const [openConfirmDialog, setOpenConfirmDialog] =
    React.useState<boolean>(false);

  const [xmlData, setXMLData] = useState('');
  const [xmlParams, setXMLParams] = useState({
    headerParams: {},
    linesParams: {},
    sequencial: 0
  });
  console.log('props', props)
  const transportadoraReq = useQuery(
    ['trans', null],
    async () => {
      return getSalesOrderTrans();
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 5000
    }
  );

  const tipoOVReq = useQuery(
    ['tipo-ov', null],
    async () => {
      return getTipoOV();
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 5000
    }
  );

  const siglaReq = useQuery(
    ['sigla', null],
    async () => {
      const userStr = localStorage.getItem('user');
      const user = JSON.parse(userStr ?? '{ sigla: "MNE" }');
      const sigla: string = user.sigla;
      return getSequencial(sigla);
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000
    }
  );

  const clienteDadosReq = useQuery(
    ['cliente-dados', null],
    async () => {
      let codClient = '';
      if (props.data.isRecorrente) {
        codClient = props.data.pedidoFts[0].clientID;
        console.log(
          'props.data.pedidoFts[0].clientID',
          props.data.pedidoFts[0].clientID
        );
      } else {
        codClient = props.data.pedidoFts[0].ft.preFT.ie.codeLn;
        console.log(
          'props.data.pedidoFts[0].ft.preFT.ie.codeLn',
          props.data.pedidoFts[0].ft.preFT.ie.codeLn
        );
      }
      return getClientData(codClient);
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 5000
    }
  );

  const clientCreditReq = useQuery(
    ['cliente-credito', null],
    async () => {
      let codClient = '';
      if (props.data.isRecorrente) {
        codClient = props.data.pedidoFts[0].clientID;
      } else {
        codClient = props.data.pedidoFts[0].ft.preFT.ie.codeLn;
      }
      return getClientLimitCredit(codClient);
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 5000
    }
  );

  const consultItemTrimbox = async (pedidoFt, pedidoFTs) => {
    const { data } = await httpLN.get<any>(
      `/order/item?id=${pedidoFt?.ft?.itemReference ?? pedidoFt?.codItem}`
    );

    pedidoFTs = pedidoFTs?.map((item) =>
      item?._id === pedidoFt?._id
        ? { ...item, ln: data ?? undefined, lnLoading: false }
        : item
    );

    setPedido({
      ...pedido,
      isBlockSellOrder: !data?.length,
      pedidoFts: pedidoFTs
    });
    console.log('pedidoFt?.ft?._id', pedidoFt?.ft?._id);
    console.log('pedidoFt?.ft', pedidoFt?.ft);
    console.log('pedidoFt?.codItem', pedidoFt?.codItem);

    return pedidoFTs;
  };

  const loadDataTrimbox = useCallback(async (pedidoFTs: any) => {
    setLoading(true);
    for (const pedidoFt of pedidoFTs) {
      if (pedidoFt?.status === 'SUSPENSO') {
        continue;
      }

      // Consultando cinco vezes caso dê erro
      try {
        pedidoFTs = await consultItemTrimbox(pedidoFt, pedidoFTs);
      } catch (error: any) {
        try {
          pedidoFTs = await consultItemTrimbox(pedidoFt, pedidoFTs);
        } catch (e) {
          try {
            pedidoFTs = await consultItemTrimbox(pedidoFt, pedidoFTs);
          } catch (e) {
            try {
              pedidoFTs = await consultItemTrimbox(pedidoFt, pedidoFTs);
            } catch (e) {
              try {
                pedidoFTs = await consultItemTrimbox(pedidoFt, pedidoFTs);
              } catch (e) {}
            }
          }
        }

        // Erro
        console.log(error?.response?.data?.message);
      }
    }

    try {
      const { data } = await httpLN.get<any>(
        `/order/client?id=${
          pedidoFTs[0].clientID ?? pedidoFTs[0].ft.preFT.ie.codLn
        }`
      );
      if (data.result.length) {
        setMoeda(data.result[0].moeda);
      }
    } catch (error: any) {
      console.log(error?.response?.data?.message);
    }

    setLoading(false);
  }, []);

  const loadPedido = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await httpClient.get(
        `pedidos/${props?.data?._id}/pedidosFt`
      );
      console.log('loadPedido', data);
      setPedido({
        ...props?.data,
        isBlockSellOrder: true,
        pedidoFts: data?.map((item) => ({
          ...item,
          lnLoading: item?.status === 'SUSPENSO' ? false : true
        }))
      });

      setLoading(false);
      await loadDataTrimbox(data);
    } catch (error: any) {
      setPedido({ ...props?.data, pedidoFts: [] });
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }, [props?.data?._id]);

  useEffect(() => {
    void loadPedido();
  }, [props?.data?._id]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    props.close();
  };

  const handleCancellDialog = () => {
    setOpenDialog(false);
  };

  const closeModal = () => props.close();

  const handleGenerateOrder = async () => {
    let hasError = false;
    let currentDateTime = '';
    try {
      currentDateTime = pedido.pedidoFts[0].entregas[0].data;
    } catch (e) {
      currentDateTime = moment().utc().format();
    }

    // Obter sigla e sequencial
    const userStr = localStorage.getItem('user');
    const user = JSON.parse(userStr ?? '{ sigla: "MNE" }');
    const sigla: string = user.sigla;
    const result = siglaReq.data.result[0];
    let sequencial = parseInt(result.sequencial ?? 0);

    // Gerar novo displayId
    const sequenceLength = sequencial.toString().length;
    let displayId = sigla;
    for (let i = 0; i < 6 - sequenceLength; i++) {
      displayId += '0';
    }
    displayId += `${sequencial}`;

    /* console.log('displayId', displayId);

    const siglaResult = await getSiglaXml(displayId);

    // Gerar novo displayId se já existe no servidor de arquivos
    if (siglaResult && siglaResult.message !== 'empty') {
        displayId = siglaResult.message;
    } else {
        toast.error('Erro no servidor de arquivos.');
    } */
    console.log('NewDisplayId', displayId);
    sequencial = parseInt(displayId.substring(3));
    console.log('sequencial', sequencial);
    console.log('clienteDadosReq', clienteDadosReq);
    const clientData = clienteDadosReq?.data?.result[0];
    if (!clienteDadosReq?.data?.result[0]?.limitePagamento) {
      console.log('isRecurrent - clientData NULL');
      alertMessage(`<br/><br/>
          <b>ATENÇÃO!&nbsp;</b>
          <br/><br/>
          <p style={{ textAlign: 'justify' }}>
          <b>O cadastro do cliente está em</b>&nbsp;<span style="color:red;font-weight:bold">processamento no LN.</span>&nbsp;
          <span style="color: 'red'">Por favor, aguarde a confirmação do cadastro finalizado pela equipe de São Paulo.</span>&nbsp;&nbsp;
          </p>
          <br/><br/>
          <p style={{ textAlign: 'justify' }}>
          Neste estado, <b>não deve ser gerada nenhuma ordem de venda.</b>
          </p>`);
      hasError = true;
    }
    let precoTotal = 0;
    const orderLines: LineParams[] = [];

    const ambiente =
      window.location.host === 'mnemosine.placibras.com.br' ? 'PRD' : 'TRN';
    // const ambiente = 'PRD'
    const limitCredit = clientCreditReq.data?.result[0]?.credito_disponivel;

    if (extra.length === 0) {
      toast.warning('Preencha o campo Ordem de Cliente OV');
      hasError = true;
    }

    if (entrega !== 'FOB') {
      if (transportadora.length === 0) {
        toast.warning('Preencha o campo de Transportadora');
        hasError = true;
      }
    }

    if (entrega.length === 0) {
      toast.warning('Preencha o campo Termo de Entrega');
      hasError = true;
    }

    if (pedido.isRecorrente) {
      for (const pedidoFT of pedido?.pedidoFts) {
        const atual = moment().format('HH');
        const utcH = moment().utc().format('HH');

        console.log('pedido ft isRecorrente', pedidoFT);
        pedidoFT.entregas?.forEach((pedidoEntrega) => {
          let dataEntrega;
          // Se a data no utc já for outro dia, precisa mudar a data de entrega
          if (utcH < atual) {
            dataEntrega = `${moment(pedidoEntrega.data)
              .add(1, 'days')
              .format('YYYY-MM-DD')}T23:59:59.000Z`;
          } else {
            dataEntrega = `${moment(pedidoEntrega.data).format(
              'YYYY-MM-DD'
            )}T23:59:59.000Z`;
          }

          if (
            moment(
              `${moment(pedidoEntrega.data).format('YYYY-MM-DD')} 23:59:59`
            ).isBefore(moment())
          ) {
            toast.warning(
              'Não é permitido gerar ordem de venda para datas passadas'
            );
            hasError = true;
          }

          if (moment(dataEntrega).isBefore(moment(currentDateTime))) {
            currentDateTime = moment(dataEntrega).format();
          }

          const precoMultiplicado = pedidoFT.preco * pedidoEntrega.quantidade;
          precoTotal += precoMultiplicado;
          const lineParam: LineParams = {
            effectiveDateTime: currentDateTime,
            itemId: pedidoFT.codItem,
            itemDescription: pedidoFT.ln.result[0].descricao ?? '',
            unitCode: pedidoFT.ln.result[0].unidade ?? '',
            unitPriceAmount: parseFloat(pedidoFT.preco).toFixed(5),
            unitAmount: pedidoEntrega.quantidade,
            perQuantity: '1',
            extendedPriceAmount: precoMultiplicado.toFixed(5),
            promisedShipDateTime: dataEntrega ?? moment().format(),
            promisedDeliveryDateTime: dataEntrega ?? moment().format(),
            scheduledDeliveryDateTime: dataEntrega ?? moment().format(),
            shipFromPartyId: `W_${pedidoFT.ln.result[0].armazem ?? ''}`,
            requiredDeliveryDateTime: dataEntrega ?? moment().format(),
            customerOrderNumber: repetirExtra
              ? extra
              : pedidoEntrega?.customerOrderNumber ?? null,
            customerOrderPosition: pedidoEntrega.customerOrderPosition ?? null
          };
          console.log('lineParam', lineParam);
          orderLines.push(lineParam);
        });
      }

      if (orderLines.length === 0) {
        toast.warning(
          'Não é permitido gerar ordem de venda sem quantidades solicitadas'
        );
        hasError = true;
      }

      const headerParams: HeaderParams = {
        enviroment: ambiente,
        customerName: props.data.client.name,
        customerAdressStreet: clientData?.linha2 ?? '',
        customerAdressNumber: clientData?.numero ?? '',
        customerAdressCity: clientData?.cidade ?? '',
        customerAdressCountry: clientData?.linha4 ?? '',
        customerAdressStateCode: clientData?.estado ?? '',
        customerAdressCountryCode: 'BR',
        customerAdressPostalCode: clientData?.cep ?? '',
        customerAdressBuildingName: clientData?.bloco ?? '',
        clientLnId: pedido?.pedidoFts[0].clientID,
        lastModificationDateTime: currentDateTime ?? '',
        documentDateTime: currentDateTime,
        effectiveDateTime: currentDateTime,
        currencyID: moeda,
        priceAmount: precoTotal.toFixed(5),
        canceledPriceAmount: '0',
        paymentTermID: clientData?.limitePagamento ?? '',
        paymentTermDescription: clientData?.descPagamento ?? '',
        paymentTermDuration: 'PT0H0M0S',
        paymentTermDiscountPercent: '0',
        requestedShipDateTime: currentDateTime,
        requiredDeliveryDateTime: currentDateTime,
        promisedShipDateTime: currentDateTime,
        promisedDeliveryDateTime: currentDateTime,
        paymentMethodCode: clientData?.metodoPagamento ?? '',
        orderType: tipoOV,
        orderSeries: sigla,
        salesPersonReferenceName: 'CONSUMIDOR',
        preTaxPriceAmount: '0',
        displayId: displayId,
        carrierParty: transportadora,
        deliveryTerm: entrega,
        creationDateTime: currentDateTime,
        clientLimitCredit: limitCredit
      };
      const xml = generateXML(headerParams, orderLines, extra, clientData);
      console.log('isRecurrent', xml);
      setXMLParams({
        headerParams: headerParams,
        linesParams: orderLines,
        sequencial: sequencial
      });
      setXMLData(xml);
    } else {
      for (const pedidoFT of pedido?.pedidoFts) {
        const atual = moment().format('HH');
        const utcH = moment().utc().format('HH');

        const preco = pedidoFT.budget?.precoNegociado ?? 0;
        console.log('not isRecurrent - pedido ft NO', pedidoFT);
        pedidoFT.entregas?.forEach((pedidoEntrega) => {
          let dataEntrega;
          // Se a data no utc já for outro dia, precisa mudar a data de entrega
          if (utcH < atual) {
            dataEntrega = `${moment(pedidoEntrega.data)
              .add(1, 'days')
              .format('YYYY-MM-DD')}T23:59:59.000Z`;
          } else {
            dataEntrega = `${moment(pedidoEntrega.data).format(
              'YYYY-MM-DD'
            )}T23:59:59.000Z`;
          }

          if (
            moment(
              `${moment(pedidoEntrega.data).format('YYYY-MM-DD')} 23:59:59`
            ).isBefore(moment())
          ) {
            toast.warning(
              'Não é permitido gerar ordem de venda para datas passadas'
            );
            hasError = true;
          }

          if (moment(dataEntrega).isBefore(moment(currentDateTime))) {
            currentDateTime = moment(dataEntrega).format();
          }

          const precoMultiplicado = preco * pedidoEntrega.quantidade;
          precoTotal += precoMultiplicado;
          const lineParam: LineParams = {
            effectiveDateTime: currentDateTime,
            itemId: pedidoFT.ln.result[0].item.trim() ?? '',
            itemDescription: pedidoFT.ln.result[0].descricao ?? '',
            unitCode: pedidoFT.ln.result[0].unidade ?? '',
            unitPriceAmount: preco.toFixed(5),
            unitAmount: pedidoEntrega.quantidade,
            perQuantity: '1',
            extendedPriceAmount: Math.round(precoMultiplicado).toFixed(5),
            promisedShipDateTime: dataEntrega ?? moment().format(),
            promisedDeliveryDateTime: dataEntrega ?? moment().format(),
            scheduledDeliveryDateTime: dataEntrega ?? moment().format(),
            shipFromPartyId: `W_${pedidoFT.ln.result[0].armazem ?? ''}`,
            requiredDeliveryDateTime: dataEntrega ?? moment().format(),
            customerOrderNumber: repetirExtra
              ? extra
              : pedidoEntrega?.customerOrderNumber ?? null,
            customerOrderPosition: pedidoEntrega?.customerOrderPosition ?? null
          };
          console.log('not isRecurrent - lineParam NO', lineParam);
          orderLines.push(lineParam);
        });
      }

      if (orderLines.length === 0) {
        toast.warning(
          'Não é permitido gerar ordem de venda sem quantidades solicitadas'
        );
        hasError = true;
      }
      console.log(
        'not isRecurrent - clientData.limitePagamento',
        clientData?.limitePagamento
      );
      console.log(
        'not isRecurrent - pedido?.pedidoFts[0].ft.preFT.ie.codeLn',
        pedido?.pedidoFts?.[0]?.ft?.preFT?.ie?.codeLn
      );

      const headerParams: HeaderParams = {
        enviroment: ambiente,
        customerName: props.data.client.name,
        customerAdressStreet: clientData?.linha2 ?? '',
        customerAdressNumber: clientData?.numero ?? '',
        customerAdressCity: clientData?.cidade ?? '',
        customerAdressCountry: clientData?.linha4 ?? '',
        customerAdressStateCode: clientData?.estado ?? '',
        customerAdressCountryCode: 'BR',
        customerAdressPostalCode: clientData?.cep ?? '',
        customerAdressBuildingName: clientData?.bloco ?? '',
        clientLnId: pedido?.pedidoFts[0].ft.preFT.ie.codeLn,
        lastModificationDateTime: currentDateTime,
        documentDateTime: currentDateTime,
        effectiveDateTime: currentDateTime,
        currencyID: moeda,
        priceAmount: Math.round(precoTotal).toFixed(5),
        canceledPriceAmount: '0',
        paymentTermID: clientData?.limitePagamento ?? '',
        paymentTermDescription: clientData?.descPagamento ?? '',
        paymentTermDuration: 'PT0H0M0S',
        paymentTermDiscountPercent: '0',
        requestedShipDateTime: currentDateTime,
        requiredDeliveryDateTime: currentDateTime,
        promisedShipDateTime: currentDateTime,
        promisedDeliveryDateTime: currentDateTime,
        paymentMethodCode: clientData?.metodoPagamento ?? '',
        orderType: tipoOV,
        orderSeries: sigla,
        salesPersonReferenceName: 'CONSUMIDOR',
        preTaxPriceAmount: '0',
        displayId: displayId,
        carrierParty: transportadora,
        deliveryTerm: entrega,
        creationDateTime: currentDateTime,
        clientLimitCredit: limitCredit
      };

      const xml = generateXML(headerParams, orderLines, extra, clientData);
      console.log('not isRecurrent', xml);
      setXMLParams({
        headerParams: headerParams,
        linesParams: orderLines,
        sequencial: sequencial
      });
      setXMLData(xml);
    }

    // verificação de tipo de cliente e limite de crédito
    console.log(
      'credito',
      Number(clientCreditReq.data?.result[0]?.credito_disponivel)
    );
    console.log('valor', precoTotal);
    console.log(
      'Number(clientCreditReq.data?.result[0].credito_disponivel) < precoTotal',
      Number(clientCreditReq.data?.result[0].credito_disponivel) < precoTotal
    );
    console.log('props?.data?.client?.inCash', props?.data?.client?.inCash);
    console.log('hasError 1', hasError);
    if (
      (clientCreditReq.data?.result[0]?.credito_disponivel
        ? Number(clientCreditReq.data?.result[0].credito_disponivel)
        : 0) < precoTotal &&
      !(props?.data?.client?.inCash ?? false) &&
      (clientCreditReq.data?.result[0]?.credito_disponivel
        ? Number(clientCreditReq.data?.result[0].credito_disponivel)
        : 0) >= 0.01
    ) {
      toast.warning(
        'Cliente não tem limite de crédito e não paga de modo a vista'
      );
      console.log(
        'Cliente não tem limite de crédito e não paga de modo a vista'
      );
      hasError = true;
    }
    console.log('hasError 2', hasError);
    if (!hasError) {
      console.log(
        'props?.data?.client?.pilotLot',
        props?.data?.client?.pilotLot
      );
      if (props?.data?.client?.pilotLot) {
        console.log('props?.data?.client?._id', props?.data?.client?._id);
        await httpClient.post(
          `/pedidos/alert-pilote-lot-generate-order/${
            props?.data?.client?._id ?? '0'
          }`,
          {
            client: props?.data?.client?.name ?? ''
          }
        );
      }

      setOpenConfirmDialog(true);
    }
  };

  const handleReprovePedidoFt = async (id: string) => {
    try {
      await httpClient.patch(`/pedidos/pedidoFt/${id}/reprovar`);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleReplanejarPedido = async (pedidoId: string) => {
    try {
      const response = await httpClient.patch(
        `/pedidos/${pedidoId}/replanejar`
      );
      // sobrescrever o pedido atual
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCloseSuccessXml = async () => {
    try {
      await httpClient.patch(`/pedidos/${props?.data?._id}/aprovar`);
      setOpenConfirmDialog(false);
      props.close();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCloseSuccessReplanejar = () => {
    setOpenReplanejar(null);
    props.closeSuccess();
  };

  const existsAllInLN = () => {
    return pedido?.pedidoFts?.find((r) => r.ln?.result.length === 0 || !r.ln)
      ? false
      : true;
  };

  const [hasSignatures, setHasSignatures] = useState(false);
  useEffect(() => {
    const onVerifiedAllSignatures = async () => {
      let hasSignatures = false;

      const pedidoFts = pedido?.pedidoFts ?? [];

      for (let i = 0; i < pedidoFts.length; i++) {
        const item = pedidoFts[i];
        const response = await httpClient.get(
          `/bookings/purchase-request/pedido/${item?.pedido}/${
            item?.ft?._id ?? item?.codItem
          }`
        );

        if (response?.data?._id) {
          if (
            response?.data?.assinaturas?.gerenciaComercial?.url &&
            response?.data?.assinaturas?.compras?.url
          ) {
            hasSignatures = true;
          } else {
            hasSignatures = false;
          }
        }
      }

      setHasSignatures(hasSignatures);
    };

    onVerifiedAllSignatures();
  }, [pedido]);

  console.log('hasSignatures', hasSignatures);

  return (
    <>
      <DialogStyle
        open={props.open}
        // onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHeadAudit data={props?.data} />

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxHeight: '80vh',
            overflowY: 'auto'
          }}
        >
          {(loading ||
            transportadoraReq.isFetching ||
            siglaReq.isFetching ||
            clienteDadosReq.isFetching ||
            tipoOVReq.isFetching ||
            clientCreditReq.isFetching) && (
            <Stack justifyContent="center" alignItems="center" height={200}>
              <CircularProgress />
              <div>Buscando dados do LN</div>
            </Stack>
          )}

          {!loading &&
            !transportadoraReq.isFetching &&
            !siglaReq.isFetching &&
            !clienteDadosReq.isFetching &&
            !tipoOVReq.isFetching &&
            !clientCreditReq.isFetching &&
            pedido?.pedidoFts?.map((pedidoFt) => (
              <Accordion
                sx={{
                  borderRadius: 2,
                  marginBottom: 1,
                  boxShadow: '0px 3px 6px #00000029',
                  '&::before': { display: 'none' },
                  maxWidth: '820px'
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ paddingY: 1 }}
                >
                  <Stack>
                    <Typography fontWeight="bold">
                      FT{' '}
                      {pedidoFt?.ft?.itemReference ||
                        pedidoFt?.ft ||
                        pedidoFt?.codItem}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      {pedidoFt?.lnLoading && (
                        <>
                          <CircularProgress size={24} sx={{ marginRight: 1 }} />
                          Consultando...
                        </>
                      )}

                      {!pedidoFt?.lnLoading && (
                        <Chip
                          size="small"
                          label={
                            pedidoFt?.ln?.result.length
                              ? 'TEM NO LN'
                              : 'NÃO TEM NO LN'
                          }
                          variant="outlined"
                          sx={{
                            color: pedidoFt?.ln?.result.length
                              ? '#00e63a'
                              : '#EE207A',
                            borderColor: pedidoFt?.ln?.result.length
                              ? '#00e63a'
                              : '#EE207A'
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <hr />
                  <Typography fontWeight="bold" sx={{ mt: 2 }}>
                    Dados da FT
                  </Typography>
                  <DivRow style={{ marginTop: '-20px' }}>
                    <Grid
                      container
                      spacing={3}
                      mt={0}
                      style={{ maxWidth: '800px' }}
                    >
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="FT"
                          value={
                            pedidoFt?.ft?._id ||
                            pedidoFt?.ft ||
                            pedidoFt?.codItem
                          }
                          variant="standard"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Cliente"
                          value={props?.data?.client?.name}
                          variant="standard"
                          disabled
                        />
                      </Grid>
                      {/* <Grid item xs={8}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Referência"
                          value={
                            pedidoFt?.ft?.preFT?.formulariochapa?.Referencia ||
                            pedidoFt?.ft?.preFT?.preftbox?.reference
                          }
                          variant="standard"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Ordem de produção"
                          variant="standard"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Ordem de venda"
                          variant="standard"
                          disabled
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Lote mínimo"
                          value={
                            pedidoFt?.loteMinimo ??
                            pedidoFt?.budget?.loteMinimo ??
                            ''
                          }
                          variant="standard"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Qtd. total"
                          value={pedidoFt?.quantidadeTotal}
                          variant="standard"
                          disabled
                        />
                      </Grid>
                      {pedido.isBobina && (
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Preço"
                            defaultValue={pedidoFt?.preco}
                            variant="outlined"
                            onChange={(e) => {
                              pedidoFt.preco = e.target.value;
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </DivRow>
                  <Typography fontWeight="bold" sx={{ mt: 2 }}>
                    Quantidades solicitadas
                  </Typography>
                  <DivRow style={{ marginTop: '-20px' }}>
                    <Grid
                      container
                      spacing={3}
                      mt={0}
                      style={{ maxWidth: '800px' }}
                    >
                      {!pedidoFt?.entregas?.length && (
                        <Grid item xs={12}>
                          <Typography sx={{ mt: 2 }}>
                            Nenhuma quantidade solicitada.
                          </Typography>
                        </Grid>
                      )}
                      {pedidoFt?.entregas?.map((entrega) => (
                        <React.Fragment key={entrega?._id}>
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              id="standard-basic"
                              label="Quantidade solicitada"
                              value={entrega?.quantidade}
                              variant="standard"
                              disabled
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              id="standard-basic"
                              label="Data de entrega"
                              value={
                                entrega?.data
                                  ? moment(entrega.data).format('DD/MM/YYYY')
                                  : ''
                              }
                              variant="standard"
                              disabled
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              id="standard-basic"
                              label="Ordem de cliente linha"
                              autoComplete="off"
                              value={entrega?.customerOrderNumber}
                              variant="outlined"
                              disabled={repetirExtra}
                              onChange={(e) => {
                                entrega.customerOrderNumber = e.target.value;
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              id="standard-basic"
                              label="Posição"
                              autoComplete="off"
                              value={entrega?.customerOrderPosition}
                              variant="outlined"
                              onChange={(e) => {
                                entrega.customerOrderPosition = e.target.value;
                              }}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </DivRow>
                  <ShowComponentValidateStatus status={pedidoFt?.status}>
                    <Stack direction="row" justifyContent="flex-end" mt={4}>
                      {/* {pedidoFt?.status !== 'APROVADO' &&
                        pedidoFt?.status !== 'CONTRAPROPOSTA' && (
                          <Button
                            style={{
                              marginRight: '10px',
                              backgroundColor: '#1AB1B0',
                              color: '#FFFFFF',
                              textTransform: 'capitalize'
                            }}
                            onClick={() => {
                              handleApprovePedidoFt(pedidoFt?._id);
                            }}
                            color="success"
                            variant="contained"
                          >
                            Aprovar
                          </Button>
                        )} */}
                    </Stack>
                  </ShowComponentValidateStatus>
                </AccordionDetails>
              </Accordion>
            ))}

          {!loading &&
          !transportadoraReq.isFetching &&
          !siglaReq.isFetching &&
          !clienteDadosReq.isFetching &&
          !tipoOVReq.isFetching &&
          !tipoOVReq.isFetching &&
          !clientCreditReq.isFetching ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  maxWidth: '820px',
                  marginTop: '10px'
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          setRepetirExtra(!repetirExtra);
                        }}
                        checked={repetirExtra}
                      />
                    }
                    label="Repetir Ordem de Cliente"
                  />
                </FormGroup>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  maxWidth: '820px',
                  marginTop: '5px'
                }}
              >
                <TextField
                  fullWidth
                  onChange={(e) => {
                    setExtra(e.target.value);
                  }}
                  value={extra}
                  id="extra-input"
                  label="Ordem de cliente OV"
                  variant="outlined"
                />
                <TextField
                  id="transportation-select"
                  select
                  label="Tipo de OV"
                  value={tipoOV}
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  style={{ marginLeft: '10px' }}
                  onChange={(e) => {
                    setTipoOV(e.target.value);
                  }}
                >
                  {tipoOVReq.data?.result
                    ?.sort(
                      (a, b) =>
                        a.codTipo.replace(/\D/g, '') -
                        b.codTipo.replace(/\D/g, '')
                    )
                    .map((data) => (
                      <MenuItem key={data.codTipo} value={data.codTipo}>
                        {`${data.codTipo} - ${data.descricao}`}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  id="transportation-select"
                  select
                  label="Cond. de entr."
                  value={entrega}
                  variant="outlined"
                  fullWidth
                  style={{ marginLeft: '10px' }}
                  onChange={(e) => {
                    setEntrega(e.target.value);
                  }}
                >
                  <MenuItem key={'FOB'} value={'FOB'}>
                    FOB
                  </MenuItem>
                  <MenuItem key={'CIF'} value={'CIF'}>
                    CIF
                  </MenuItem>
                </TextField>
                <TextField
                  id="transportation-select"
                  select
                  label="Transport."
                  value={transportadora}
                  variant="outlined"
                  fullWidth
                  style={{ marginLeft: '10px' }}
                  onChange={(e) => {
                    setTransportadora(e.target.value);
                  }}
                >
                  {transportadoraReq.data?.result
                    ?.sort(
                      (a, b) =>
                        a.cod.replace(/\D/g, '') - b.cod.replace(/\D/g, '')
                    )
                    .map((data) => (
                      <MenuItem key={data.cod} value={data.cod}>
                        {`${data.cod} - ${data.desc}`}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <Stack
            direction="row"
            marginTop={6}
            style={{ justifyContent: 'right' }}
          >
            <div>
              <ButtonRow
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end'
                }}
              >
                <Button
                  style={{
                    marginRight: '10px',
                    backgroundColor: '#EE207A',
                    border: '1px solid #EE207A',
                    color: '#FFFF',
                    textTransform: 'inherit'
                  }}
                  variant="contained"
                  onClick={() => {
                    props.close();
                  }}
                >
                  Fechar
                </Button>

                {!loading && (
                  <>
                    <Button
                      style={{
                        marginRight: '10px',
                        backgroundColor: '#4060E6',
                        color: '#FFFFFF',
                        textTransform: 'inherit'
                      }}
                      onClick={() => {
                        setOpenReplanejar(true);
                      }}
                      color="success"
                      variant="contained"
                    >
                      Replanejar
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        // backgroundColor: existsAllInLN() && props?.data?.booking?.status === 'APROVADO' ? '#1AB1B0' : '#A8A8A8',
                        // backgroundColor: existsAllInLN()
                        backgroundColor:
                          existsAllInLN() &&
                          (props?.data?.booking?.status === 'APROVADO' || props?.data?.type === 'CHAPA' ||
                            (props?.data?.pedidoFts &&
                              props?.data?.pedidoFts?.filter(
                                (item) => item?.ft?.preFT?._id
                              )?.length === 0) || hasSignatures)
                            ? '#1AB1B0'
                            : '#A8A8A8',
                        color: '#FFFFFF',
                        textTransform: 'inherit'
                      }}
                      onClick={() => {
                        handleGenerateOrder();
                      }}
                      color="success"
                      variant="contained"
                      // disabled={!existsAllInLN() || props?.data?.booking?.status !== 'APROVADO'}
                      // disabled={!existsAllInLN()}
                      disabled={
                        !existsAllInLN() ||
                        ((props?.data?.booking?.status !== 'APROVADO' && props?.data?.type === 'CAIXA') &&
                          props?.data?.pedidoFts?.filter(
                            (item) => item?.ft?.preFT?._id
                          )?.length > 0 && !hasSignatures)
                      }
                    >
                      Gerar ordem de venda
                    </Button>
                  </>
                )}
              </ButtonRow>
            </div>
          </Stack>
        </DialogContent>
      </DialogStyle>

      {/* <GenericDialog
        open={openDialog}
        close={() => setOpenDialog(false)}
        content={
          <FailedOrder
            id={'1'}
            cancellDialog={handleCancellDialog}
            closeDialog={handleCloseDialog}
          />
        }
      /> */}

      <ModalReplanejarPedido
        open={!!openReplanejar}
        close={() => setOpenReplanejar(false)}
        closeSuccess={handleCloseSuccessReplanejar}
        data={pedido}
      />

      {openConfirmDialog && (
        <GenericDialog
          open={openConfirmDialog}
          close={() => setOpenConfirmDialog(false)}
          content={
            <ConfirmSellerOrder
              close={() => setOpenConfirmDialog(false)}
              closeSuccess={handleCloseSuccessXml}
              pedidoID={pedido._id}
              xmlData={xmlData}
              xmlParams={xmlParams}
            />
          }
        />
      )}
    </>
  );
};

export default ModalAudit;
