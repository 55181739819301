const contaSalario = [
  '41001001',
  '41005001',
  '41007001',
  '41015001',
  '41101001',
  '41102001',
  '41103001',
  '41105001',
  '41106001',
  '41107001',
  '41205001',
  '41206001',
  '41210001',
  '41211001',
  '41216001',
  '41222001',
  '41224001',
  '41225001',
  '41226001',
  '41104001',
  '41201001',
  '41227001',
  '41110001',
  '41210002',
  '41230001'
]

const contaDespesa = [
  '41412001',
'41017001',
'41017002',
'41017003',
'41017004',
'41302001',
'41303001',
'41306001',
'41308001',
'41310001',
'41311001',
'41313002',
'41315001',
'41318001',
'41323001',
'41324001',
'41325001',
'41331001',
'41412003',
'41333001',
'41335001',
'41337001',
'41338001',
'41345002',
'41349001',
'41401001',
'41402001',
'41403001',
'41405001',
'41406001',
'41407001',
'41408001',
'41409001',
'41411001',
'41413001',
'41414002',
'41415001',
'41415002',
'41416001',
'41417001',
'41417002',
'41418001',
'41421001',
'41425001',
'41498003',
'41701001',
'41702001',
'41707001',
'41710001',
'41713001',
'41801001',
'41808001',
'41809001',
'41810001',
'41812001',
'41815001',
'41816001',
'41823001',
'41824001',
'41892001',
'41892002',
'41019002',
'41313001',
'41406003',
'41406004',
'41406090',
'41601001',
'41715001',
'41811001',
'41823002',
'41016001',
'41414001',
'41410001',
'41410002',
'41410003',
'41307001',
'41412005',
'41406002',
'41807001',
'41398001',
'41318002',
'41426001'
]

const dimensao = [
  '70206',
  '70210',
  '70216',
  '80201',
  '34351',
  '34352',
  '34301',
  '80202',
  '80203',
  '80204',
  '34405',
  '34406',
  '34201',
  '70107',
  '34101',
  '34302',
  '34401',
  '34402',
  '34403',
  '34404',
  '34407',
  '70103',
  '70201',
  '70202',
  '70203',
  '70204',
  '70208',
  '70209',
  '70211',
  '70213',
  '70212',
  '34408',
  '40202',
  '70301',
  '70302',
  '40102',
  '40203',
  '34409'
]
export { contaSalario, contaDespesa, dimensao }
