/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  Box,
  CircularProgress,
  Icon,
  TablePagination,
  TextField
} from '@mui/material'
import { useEffect, useState } from 'react'
import { StandardFilter } from 'utils/StandardFilter'
import { formatValue, toBRL } from 'utils/formatString'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export function ReportItem(props: any) {
  const [rows, setRows] = React.useState<any[]>([])
  const [page, setPage] = React.useState(0)
  const [filters, setFilters] = useState<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  // const [searchParam, setSearchParam] = React.useState('')
  // const [originalRows, setOriginalRows] = React.useState<any[]>([])

  useEffect(() => {
    const loadedData = [
      props.req1?.isLoading,
      props.req5?.isLoading,
      props.req6?.isLoading
    ].every((loading) => !loading)
    if (loadedData) {
      formatTableData(
        props.req1.data.response, props.req6.data.response, props.req5.data.response
      )
    }
  }, [props.req1?.isLoading, props.req6?.isLoading])

  const removeItemsDuplicates = (arr: any) => {
    const unique = arr.reduce(function (acc, curr) {
      if (!acc.includes(curr?.item)) {
        acc.push(curr?.item)
      }
      return acc
    }, [])
    return unique
  }

  const formatTableData = (
    invoicing: any[],
    items: any[],
    clients: any[]
  ) => {
    try {
      // // Pedidos
      // const arrayFiltradoDemand = [] as any[]
      // const allClient = [] as any[]
      // fts.forEach((item) => {
      //   if (item?.preFT.type === 'CAIXA' && props?.type === '0001') {
      //     allClient.push(item)
      //   }
      //   if (item?.preFT.type === 'CHAPA' && props?.type === '0002') {
      //     allClient.push(item)
      //   }
      //   if (item?.preFT.type === 'BOBINA' && props?.type === '0006') {
      //     allClient.push(item)
      //   }
      // })

      // Faturamento
      // const arrayFiltradoInvoicing = [] as any[]
      // const clientInoicing = [] as any[]
      // invoicing.forEach((item) => {
      //   item.dados.forEach((element) => {
      //     if (element.type === props.type) {
      //       clientInoicing.push(item.client)
      //       arrayFiltradoInvoicing.push({
      //         ...element,
      //         client: item.client
      //       })
      //     }
      //   })
      // })

      // Items faturamento
      const arrayFiltradoItems = [] as any[]
      items?.forEach((item) => {
        if (props.type.includes(item.type)) {
          const findClient = invoicing?.find((element) => element?.cod?.includes(item?.item ?? ''))?.client
          arrayFiltradoItems.push({
            ...item,
            client: findClient
          })
        }
      })

      const arrayUniqueItems = removeItemsDuplicates(arrayFiltradoItems) as any[]

      const dadosDaTabela = [] as any[]
      arrayUniqueItems?.forEach((codItem) => {
        const { kg: kgFaturamento, rs: rsFaturamento, client: name } = arrayFiltradoItems.reduce(
          (acumulador, dados) => {
            if (dados?.item?.includes(codItem)) {
              const { kg, rs, client } = dados
              console.log(client)
              acumulador.kg += kg
              acumulador.rs += rs
              acumulador.client = client

              return acumulador
            }
            return acumulador
          },
          { kg: 0, rs: 0, rskg: 0, client: '' }
        )

        const findClient = clients?.find((item) => item?.codItem?.includes(codItem))

        dadosDaTabela.push({
          name: findClient?.client ?? '',
          item: codItem,
          kgFaturamento,
          rsFaturamento
        })
      })

      setRows(dadosDaTabela)
      setDataSource(dadosDaTabela)
    } catch (error) {
      console.log('erro data: ', error)
    }
  }

  useEffect(() => {
    StandardFilter(filters, setRows, dataSource)
  }, [filters, dataSource])

  return (
    <>
      {(props.req1?.isLoading || props.req1?.isFetching) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {props.req1.isError && props.req6.isError && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>
            signal_wifi_statusbar_connected_no_internet_4
          </Icon>
        </Box>
      )}

      {props.req1.isSuccess && !props.req1.isError && !props.req1.isFetching && (
        <>
          <TableContainer style={{ marginTop: '30px' }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TextField
                      id="standard-basic"
                      label="Pesquisar cod. item"
                      variant="standard"
                      onChange={(e) =>
                        setFilters({ ...filters, item: e.target.value })
                      }
                    />
                    {/* Cliente */}
                  </StyledTableCell>
                  <StyledTableCell align="left">Cod. Item</StyledTableCell>
                  {/* <StyledTableCell align="left">Data</StyledTableCell> */}
                  <StyledTableCell align="left">Faturado Kg</StyledTableCell>
                  <StyledTableCell align="left">Faturado R$</StyledTableCell>
                  <StyledTableCell align="left">Faturado R$/Kg</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.slice(page * 5, page * 5 + 5)?.map((row) => {
                  return (
                    <StyledTableRow key={row.item}>
                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="left">{row?.item}</StyledTableCell>
                      <StyledTableCell align="left">{formatValue(row.kgFaturamento)}</StyledTableCell>
                      <StyledTableCell align="left">{toBRL(row.rsFaturamento)}</StyledTableCell>
                      <StyledTableCell align="left">
                        {toBRL(isNaN(row.rsFaturamento / row.kgFaturamento)
                          ? 0
                          : Number(row.rsFaturamento / row.kgFaturamento)
                          , { noBRL: true }
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              rowsPerPage={5}
              component="div"
              count={rows.length}
              page={page}
              onPageChange={(event: unknown, newPage: number) => {
                setPage(newPage)
              }}
            />
          </TableContainer>
        </>
      )}
    </>
  )
}
