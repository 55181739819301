import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  kg: yup
    .number()
    .positive('age must be greater than zero')
    .required('Preencha esse campo!'),
  product: yup
    .string()
    .required('Preencha esse campo!')
})
