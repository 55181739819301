/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import { useQuery } from 'react-query'
import {
  getReportMonthly,
  getReportMonthlyDays,
  getReportMonthlyLocal,
  getReportPedidosMensal
} from 'services/report/ReportMonthlyService'
import { Box, CircularProgress, Icon } from '@mui/material'
import { formatValue, toBRL } from 'utils/formatString'
import { getReport } from 'services/report/ReportBilling'
import { TipoProduto } from 'utils/enums'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function TotalView(props: any) {
  const [rows, setRows] = React.useState<any[]>([])
  const [cadastrado, setCadastrado] = React.useState<any[]>([])
  const [dataLocal, setDataLocal] = React.useState<any>([])
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')

  const queryMultiple = () => {
    // requisição externa do LN
    const req1 = useQuery(
      ['Cadastrados', mes, ano],
      async () => {
        const data = moment(props.date)
          .endOf('month')
          .format('DD/MM/YYYY')
        return getReportPedidosMensal(data)
      },
      {
        onSuccess: (data) => {
          // console.log({ data })
          // parseTableData(dataLocal, data, cadastrado)
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false
      }
    )
    // requisição banco local
    const req2 = useQuery(
      ['local', mes, ano],
      async () => {
        return getReportMonthlyLocal(mes, ano)
      },
      {
        onSuccess: (data) => {
          setDataLocal(data)
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false
      }
    )

    const req3 = useQuery(['sales', mes, ano, props.multipleDate],
      async () => {
        const month = Number(mes)
        const year = Number(ano)
        const lastDayOfMonth = moment()
          .year(year)
          .month(month - 1)
          .endOf('month')
          .format('DD/MM/YYYY')
        return getReport(lastDayOfMonth, {})
      },
      {
        onSuccess: (data) => { },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )

    return [req1, req2, req3]
  }

  const [req1, req2, req3] = queryMultiple()

  const isLoading = () => {
    return (
      (req1.isLoading || req1.isFetching) ||
      (req2.isLoading || req2.isFetching) ||
      (req3.isLoading || req3.isFetching)
    )
  }

  const parseTableData = (dataClient: any, dataCadastrados: any, dataValue: any) => {
    const data: any[] = []

    let boxClientData: any = {}
    let plateClientData: any = {}
    let paperClientData: any = {}
    if (dataClient.caixas.length) {
      boxClientData = dataClient.caixas.reduce((a, b) => ({
        pesoKg: parseFloat(a.pesoKg) + parseFloat(b.pesoKg),
        previsionPrice: parseFloat(a.previsionPrice) + parseFloat(b.previsionPrice),
        previsionPriceKg:
          parseFloat(a.previsionPriceKg) + parseFloat(b.previsionPriceKg)
      }))
    }

    if (dataClient.chapas.length) {
      plateClientData = dataClient.chapas.reduce((a, b) => ({
        pesoKg: parseFloat(a.pesoKg) + parseFloat(b.pesoKg),
        previsionPrice: parseFloat(a.previsionPrice) + parseFloat(b.previsionPrice),
        previsionPriceKg:
          parseFloat(a.previsionPriceKg) + parseFloat(b.previsionPriceKg)
      }))
    }

    if (dataClient.bobinas.length) {
      paperClientData = dataClient.bobinas.reduce((a, b) => ({
        pesoKg: parseFloat(a.pesoKg) + parseFloat(b.pesoKg),
        previsionPrice: parseFloat(a.previsionPrice) + parseFloat(b.previsionPrice),
        previsionPriceKg:
          parseFloat(a.previsionPriceKg) + parseFloat(b.previsionPriceKg)
      }))
    }

    let boxRevenueData: any = {}
    let plateRevenueData: any = {}
    let paperRevenueData: any = {}
    boxRevenueData = plateRevenueData = paperRevenueData = { kg: 999, rs: 888, rskg: 777 }
    const response = req3.data.response || []
    for (const row of response) {
      if (row.tipo === '2') { // dados de faturamento mensal
        // console.log('tipo.dados', row.dados[0])
        if (row.dados[0].type === TipoProduto.CAIXA) {
          boxRevenueData = row.dados[0]
        }
        if (row.dados[0].type === TipoProduto.CHAPA) {
          plateRevenueData = row.dados[0]
        }
        if (row.dados[0].type === TipoProduto.PAPEL) {
          paperRevenueData = row.dados[0]
        }
      }
    }

    const cadastradoPapelKg = dataCadastrados.result[2]?.dados[0]?.kg
    const cadastradoPapelRs = dataCadastrados.result[2]?.dados[0]?.rs
    const cadastradoChapaKg = dataCadastrados.result[1]?.dados[0]?.kg
    const cadastradoChapaRs = dataCadastrados.result[1]?.dados[0]?.rs
    const cadastradoCaixaKg = dataCadastrados.result[0]?.dados[0]?.kg
    const cadastradoCaixaRs = dataCadastrados.result[0]?.dados[0]?.rs

    data.push({
      _id: 'caixa',
      product: 'Caixa',
      prevKg: boxClientData.pesoKg | 0,
      prevRs: boxClientData.previsionPrice | 0,
      prevRsKg: boxClientData.previsionPriceKg | 0,
      cadKg: cadastradoCaixaKg ?? 0,
      cadRs: cadastradoCaixaRs ?? 0,
      fatKg: boxRevenueData.kg | 0,
      fatRs: boxRevenueData.rs | 0,
      fatRsKg: boxRevenueData.rskg | 0
    })
    data.push({
      _id: 'chapas',
      product: 'Chapas',
      prevKg: plateClientData.pesoKg | 0,
      prevRs: plateClientData.previsionPrice | 0,
      prevRsKg: plateClientData.previsionPriceKg | 0,
      cadKg: cadastradoChapaKg ?? 0,
      cadRs: cadastradoChapaRs ?? 0,
      fatKg: plateRevenueData.kg | 0,
      fatRs: plateRevenueData.rs | 0,
      fatRsKg: plateRevenueData.rskg | 0
    })
    data.push({
      _id: 'bobinas',
      product: 'Bobinas',
      prevKg: paperClientData.pesoKg | 0,
      prevRs: paperClientData.previsionPrice | 0,
      prevRsKg: paperClientData.previsionPriceKg | 0,
      cadKg: cadastradoPapelKg ?? 0,
      cadRs: cadastradoPapelRs ?? 0,
      fatKg: paperRevenueData.kg | 0,
      fatRs: paperRevenueData.rs | 0,
      fatRsKg: paperRevenueData.rskg | 0
    })
    data.push({
      _id: 'total_geral',
      product: 'Total geral',
      prevKg:
        (boxClientData.pesoKg | 0) +
        (plateClientData.pesoKg | 0) +
        (paperClientData.pesoKg | 0),
      prevRs:
        (boxClientData.previsionPrice | 0) +
        (plateClientData.previsionPrice | 0) +
        (paperClientData.previsionPrice | 0),
      prevRsKg:
        (boxClientData.previsionPriceKg | 0) +
        (plateClientData.previsionPriceKg | 0) +
        (paperClientData.previsionPriceKg | 0),
      cadKg: (cadastradoCaixaKg | 0) +
        (cadastradoChapaKg | 0) +
        (cadastradoPapelKg | 0),
      cadRs: (cadastradoCaixaRs | 0) +
        (cadastradoChapaRs | 0) +
        (cadastradoPapelRs | 0),
      fatKg:
        (boxRevenueData.kg | 0) +
        (plateRevenueData.kg | 0) +
        (paperRevenueData.kg | 0),
      fatRs:
        (boxRevenueData.rs | 0) +
        (plateRevenueData.rs | 0) +
        (paperRevenueData.rs | 0),
      fatRsKg:
        (boxRevenueData.rskg | 0) +
        (plateRevenueData.rskg | 0) +
        (paperRevenueData.rskg | 0)
    })
    console.log('data =>', data)
    setRows(data)
  }

  React.useEffect(() => {
    if (
      !req1.isLoading &&
      !req2.isLoading &&
      !req3.isLoading
    ) {
      parseTableData(req2.data, req1.data, req3.data)
    }
  }, [req1.isLoading, req2.isLoading, req3.isLoading])

  return (
    <>
      {isLoading() && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {(req1.isError || req2.isError || req3.isError) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>
            signal_wifi_statusbar_connected_no_internet_4
          </Icon>
        </Box>
      )}

      {req1.isSuccess && req2.isSuccess && req3.isSuccess && (
        <>
          <TableContainer style={{ marginTop: '30px', maxHeight: 440 }} component={Paper}>
            <Table sx={{ minWidth: '100%' }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 3,
                    background: '#fafafa !important'
                  }}>Fat. p/ produto</StyledTableCell>
                  <StyledTableCell align="center">Previsão Kg</StyledTableCell>
                  <StyledTableCell align="center">Previsão R$</StyledTableCell>
                  <StyledTableCell align="center">Previsão R$/KG</StyledTableCell>

                  <StyledTableCell align="center">Cadastro KG</StyledTableCell>
                  <StyledTableCell align="center">Cadastrado R$</StyledTableCell>
                  <StyledTableCell align="center">Cadastrado R$/Kg</StyledTableCell>

                  <StyledTableCell align="center">Faturado Kg</StyledTableCell>
                  <StyledTableCell align="center">Faturado R$</StyledTableCell>
                  <StyledTableCell align="center">Faturado R$/Kg</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell align="left" sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 2,
                      background: '#FFF'
                    }}>
                      {row.product}
                    </StyledTableCell>

                    <StyledTableCell align="center">{formatValue(row.prevKg)}</StyledTableCell>
                    <StyledTableCell align="center">{toBRL(row.prevRs, { noBRL: true })}</StyledTableCell>
                    <StyledTableCell align="center">{toBRL(Number(row.prevRs) / Number(row.prevKg), { noBRL: true })}</StyledTableCell>

                    <StyledTableCell align="center">{formatValue(row.cadKg)}</StyledTableCell>
                    <StyledTableCell align="center">{toBRL(row.cadRs, { noBRL: true })}</StyledTableCell>
                    <StyledTableCell align="center">{toBRL(Number(row.cadRs ?? 0) / Number(row.cadKg ?? 1), { noBRL: true })}</StyledTableCell>

                    <StyledTableCell align="center">{formatValue(row.fatKg)}</StyledTableCell>
                    <StyledTableCell align="center">{toBRL(row.fatRs, { noBRL: true })}</StyledTableCell>
                    <StyledTableCell align="center">
                      {
                        toBRL(row.fatRs
                          ? Number(row.fatRs ?? 0) / Number(row.fatKg ?? 1)
                          : 0
                          , { noBRL: true }
                        )
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}
