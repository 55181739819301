import { Chip } from '@mui/material'
import { store } from 'core/store'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

const Label = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: end;
  margin-bottom: 18px;
  cursor: default;
`

function DisplayTokenExpiration() {
  const state = store.getState()

  const loadExpirationTime = () => {
    return moment.unix(state.login.loginData.tokenData.exp).format('DD/MM \\à\\s HH:mm')
  }

  return (
    <Label aria-label='versão' >
      <Chip
        size="small"
        color="primary"
        label={`Validade da sessão: ${loadExpirationTime()}`}
        variant="outlined"
        sx={{
          borderRadius: '4px'
        }}
      />
    </Label>
  )
}

export default DisplayTokenExpiration
