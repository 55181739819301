/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportConsolidated } from 'services/report/ReportConsolidated'
import { ReportVolumeMes } from 'types/Report/ReportVolume'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import {
  Box,
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import TableReportPlates from './TableReportPlates'
import { toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
}

const BoxStyle = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  marginright: 88px;
`
const IconStyle = styled(Icon)`
  margin-left: 10px;
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FAFAFA',
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const TotalPlates: React.FC<Props> = (props) => {
  const [dados, setDados] = useState<any[]>([])
  const [meses, setMeses] = useState<ReportVolumeMes[]>([])
  const [dadosArray, setDadosArray] = useState<any>([])
  const mes = moment(props.date).format('M')
  const date = moment(props.date).format('DD/MM/YYYY')

  const platesReq = useQuery(
    ['plates', date],
    async () => {
      return getReportConsolidated(date)
    },
    {
      onSuccess: (data) => {
        handleValorTable(data.response)
        handlePeriodo()
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const handleValorTable = (valorData: any) => {
    const valor: any[] = [
      {
        text: 'CHAPAS',
        type: TipoProduto.CHAPA,
        ingoneType: false
      }
    ]
    setDados(valor)
    console.log('dados chapas =>', valor)

    const listaDados: any = []
    valorData.forEach((element) => {
      listaDados.push(...element.dados)
    })
    setDadosArray(listaDados)
  }

  const handlePeriodo = () => {
    const mesesValue = [
      {
        text: 'Janeiro',
        value: 1
      },
      {
        text: 'Fevereiro',
        value: 2
      },
      {
        text: 'Março',
        value: 3
      },
      {
        text: 'Abril',
        value: 4
      },
      {
        text: 'Maio',
        value: 5
      },
      {
        text: 'Junho',
        value: 6
      },
      {
        text: 'Julho',
        value: 7
      },
      {
        text: 'Agosto',
        value: 8
      },
      {
        text: 'Setembro',
        value: 9
      },
      {
        text: 'Outubro',
        value: 10
      },
      {
        text: 'Novembro',
        value: 11
      },
      {
        text: 'Dezembro',
        value: 12
      }
    ]
    const meses: any[] = []
    let i = 0
    while (i < Number(mes)) {
      meses.push(mesesValue[i])
      i++
    }
    setMeses(meses)
  }

  const getTotalProduct = (type: any, periodo: any, ingoneType: boolean) => {
    const resultFilter = dadosArray.filter(
      (item) =>
        Number(item.periodo) === periodo && (item.type === type || ingoneType)
    )
    return resultFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.rs)
    }, 0)
  }

  return (
    <>
      {(platesReq.isLoading || platesReq.isFetching) && (
        <BoxStyle>
          <CircularProgress />
        </BoxStyle>
      )}
      {platesReq.isError && (
        <BoxStyle>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <IconStyle>signal_wifi_statusbar_connected_no_internet_4</IconStyle>
        </BoxStyle>
      )}
      {platesReq.isSuccess && !platesReq.isError && !platesReq.isFetching && (
        <>
          <Paper sx={{ width: parseInt(mes) < 4 ? '50%' : '100%' }}>
            <TableContainer sx={{ maxHeight: '100%' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 3,
                      background: '#fafafa'
                    }}>Fat. Produto</StyledTableCell>
                    {meses.map((mes, index) => (
                      <StyledTableCell align="left" colSpan={1} key={index}>
                        {mes.text}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell align="left">Total</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left"></StyledTableCell>
                    {meses.map((mes, index) => (
                      <>
                        <StyledTableCell key={mes.text} align="left">
                          <Typography
                            component="span"
                            fontWeight={500}
                            variant="body2"
                            noWrap
                          >
                            Faturado R$
                          </Typography>
                        </StyledTableCell>
                      </>
                    ))}
                    <StyledTableCell align="left">Faturado R$</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {dados.map((row, index) => {
                    let totalSum = 0
                    return (
                      <StyledTableRow role="checkbox" tabIndex={-1} key={index}>
                        <StyledTableCell
                          component="th"
                          key="nome_cliente"
                          align={'left'}
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 2,
                            background: '#FFF'
                          }}
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            fontWeight="bold"
                          >
                            {row.text}
                          </Typography>
                        </StyledTableCell>
                        {meses.map((mes, index) => {
                          const result = getTotalProduct(
                            row.type,
                            mes.value,
                            row.ingoneType
                          )
                          totalSum += result
                          return (
                            <>
                              <StyledTableCell key={index} align={'left'}>
                                {toBRL(result, { noBRL: true })}
                              </StyledTableCell>
                            </>
                          )
                        })}
                        <StyledTableCell>
                          {toBRL(totalSum, { noBRL: true })}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div style={{ marginTop: '25px' }}>
            <TableReportPlates date={props.date} platesReq={platesReq} />
          </div>
        </>
      )}
    </>
  )
}

export default TotalPlates
