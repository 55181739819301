import { PermIdentity } from '@mui/icons-material'
import {
  Box,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { formatValue } from 'utils/formatString'

const StockModalView = ({ openModal, setOpenModal, item }) => {
  return <GenericDialog
    open={openModal}
    close={() => setOpenModal(false)}
    content={
      <Box>
        <Box
          borderLeft="20px solid #4060e6"
          boxShadow="0 3px 6px #00000029"
          minHeight={74}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{ paddingY: 1, paddingX: 2 }}
          >
            <PermIdentity />
            <Typography component="span" variant="body1" marginLeft={1}>
              Parceiro: {item?.nomeParceiro}
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={3} mt={0}>
          <Grid item xs={4}>
            <TextField
              label="Código do parceiro"
              value={item?.codigoParceiro ?? ''}
              type="text"
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Nome do parceiro"
              value={item?.nomeParceiro ?? ''}
              type="text"
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Código do item"
              value={item?.item ?? ''}
              type="text"
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="Descrição item"
              value={item?.descricao ?? ''}
              type="text"
              variant="standard"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Peso unitário"
              value={formatValue(item?.pesoUnitario, { precision: 5 })}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Estoque disponível"
              value={formatValue(item?.estoqueDisponivel)}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Peso total"
              value={formatValue(item?.estoqueDisponivel * item?.pesoUnitario, { precision: 3 })}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Estoque bloqueado"
              value={
                formatValue(item?.estoqueBloqueado, { precision: 3 })
              }
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Estoque alocado"
              value={formatValue(item?.estoqueAlocado, { precision: 3 })}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Estoque por ordem"
              value={formatValue(item?.estoquePorOrdem, { precision: 3 })}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Saldo do mês"
              value={formatValue(item?.saldoMes, { precision: 3 })}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Sobra do mês"
              value={formatValue(item?.sobraMes, { precision: 3 })}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Consumo"
              value={formatValue(item?.consumo, { precision: 3 })}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Armazém"
              value={item?.armazem ?? ''}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Peso OV"
              value={formatValue(item?.ordemPesoTotal, { precision: 3 })}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Entregue OV"
              value={formatValue(item?.quantidadeEntregueKg, { precision: 3 })}
              type="text"
              variant="standard"
              inputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Box>
    }
  />
}

export default StockModalView
