import React, { useState } from 'react'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import Box from '@mui/material/Box'
import TableCoil from './TableCoil'
import TablePlate from './TablePlate'
import { Tab, TabPanel, TabsList } from './modulesPortfolio.styles'
import { FormControl, Grid, Input, InputAdornment, InputLabel } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import TableBox from './TableBox'

const ModulesPortfolio: React.FC = () => {
  const { value }: any = useParams()

  const [search, setSearch] = useState('')

  return (
    <>
      <Grid container spacing={3} mt={0}>
        <Grid item xs={3}>
          <FormControl variant='standard' className='w-100'>
            <InputLabel htmlFor='field-search'>
              Pesquisar
            </InputLabel>
            <Input
              id='field-search'
              autoComplete='off'
              type='search'
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <SearchOutlined />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <TabsUnstyled defaultValue={value ? Number(value) : 0}>
        <Box marginTop={4}>
          <TabsList>
            <Tab>Bobinas e Simplex</Tab>
            <Tab>Chapas</Tab>
            <Tab>Caixas</Tab>
          </TabsList>
        </Box>
        <TabPanel value={0}>
          <TableCoil search={search} />
        </TabPanel>
        <TabPanel value={1}>
          <TablePlate search={search} />
        </TabPanel>
        <TabPanel value={2}>
          <TableBox search={search} />
        </TabPanel>
      </TabsUnstyled>
    </>
  )
}

export default ModulesPortfolio
