import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardActionArea, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Typography } from '@mui/material'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import CustomerControlType from 'types/customerControl/CustomerControlType'
import { useQuery } from 'react-query'
import { getCustomerSerasa } from 'services/customerControl/CustomerControlServices'
import TableDVApplicant from '../components/TableDVApplicant'
import TableDVRFInternal from '../components/TableDVRFInternal'
import TableFinPJ from '../components/TableFinPJ'
import TablePolicy from '../components/TablePolicy'
import CustomButton from 'components/buttons/CustomButton'
import TableSPC from '../components/TableSPC'
import TableConsultationRecord from '../components/TableConsultationRecord'

interface Props {
  open: boolean
  data: CustomerControlType
  close: () => void
  refetch: () => void
}

interface CardAll {
  title: string
  onclick: () => void
}

export const CardViewT = (props: CardAll) => {
  return (
    <CardActionArea>
      <Card sx={{ minWidth: 275 }} onClick={props.onclick}>
        <CardContent>
          <Typography
            sx={{
              display: 'flex',
              fontSize: 14,
              flexDirection: 'row',
              justifyContent: 'center'
            }}
            color="black"
            gutterBottom>
            {props.title}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  )
}

const ModalSerasa: React.FC<Props> = (props) => {
  const closeModal = () => props.close()
  const dataSerasa = props.data
  const [serasa, setSerasa] = useState<any>()
  const [openSpc, setOpenSpc] = useState<boolean>(false)
  const [openRegistro, setOpenRegistro] = useState<boolean>(false)
  const [openFinPj, setOpenFinPj] = useState<boolean>()
  const [openDVRF, setOpenDVRF] = useState<boolean>()
  const [openDV, setOpenDV] = useState<boolean>()
  const [openPolicy, setOpenPolicy] = useState<boolean>()

  useEffect(() => {
    handleDataFormat(dataSerasa)
  }, [])

  const handleDataFormat = (dataSerasa) => {
    const cpfCnpj = dataSerasa.type === 'PJ' ? dataSerasa.pj.cnpj : dataSerasa.pf.cpf
    const obj = {
      cpfCnpj: cpfCnpj,
      type: dataSerasa.type
    }
    setSerasa(obj)
  }

  const { data, isError, isLoading, isSuccess, isFetching, refetch } = useQuery(['serasa', serasa],
    async () => getCustomerSerasa(serasa.cpfCnpj, serasa.type),
    {
      refetchOnWindowFocus: false
    }
  )

  const handleRefetch = () => {
    refetch()
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={closeModal}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {(isLoading || isFetching) && (
          <Stack justifyContent="center" alignItems="center" height={200}>
            <CircularProgress />
            <div>Consultando dados do Serasa</div>
          </Stack>
        )}
        {isError && (
          <>
            <Box
              sx={{
                display: 'flex',
                height: 200,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography component="span" variant="body2">
                Nenhum dado encontrado
              </Typography>
            </Box>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '35px', marginBottom: '10px' }}>
              <Button
                variant="contained"
                disableElevation
                onClick={closeModal}>
                Voltar
              </Button>
            </div>
          </>
        )}

        {(isSuccess && !isError && !isFetching) && (
          <>
            <DialogTitle id="alert-dialog-title">
              <Box
                borderLeft="20px solid #4060E6"
                boxShadow="0 3px 6px #00000029"
                minHeight={74}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
              >
                <Typography
                  fontSize="20px"
                  fontWeight="500"
                  component="strong"
                  sx={{ paddingY: 1, paddingX: 2 }}
                >
                  Consulta Serasa
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ paddingY: 1, paddingX: 2 }}
                >
                  <PermIdentityIcon />
                  <Typography component="span" variant="body1" marginLeft={1}>
                    Para: {dataSerasa.name ?? ''}
                  </Typography>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={6}>
                    <CardViewT title='SPC' onclick={() => {
                      setOpenSpc(true)
                    }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CardViewT title='Registro de Consulta' onclick={() => setOpenRegistro(true)} />
                  </Grid>
                </Grid>
                <Grid container columns={12} spacing={3} style={{ marginTop: '5px' }}>
                  <Grid item xs={6}>
                    <CardViewT title='Anotações Fin PJ' onclick={() => setOpenFinPj(true)} />
                  </Grid>
                  <Grid item xs={6}>
                    <CardViewT title='DV-RF interal' onclick={() => setOpenDVRF(true)} />
                  </Grid>
                </Grid>
                <Grid container columns={12} spacing={3} style={{ marginTop: '5px' }}>
                  <Grid item xs={6}>
                    <CardViewT title='DV Applicant' onclick={() => {
                      setOpenDV(true)
                    }} />
                  </Grid>
                  <Grid item xs={6}>
                    <CardViewT title='Policy rules componet tree results' onclick={() => setOpenPolicy(true)} />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 3 }}>
              <CustomButton
                mold="primary"
                type="button"
                onClick={closeModal}
                autoFocus
              >
                Voltar
              </CustomButton>
              <CustomButton
                style={{ color: 'white', backgroundColor: '#1AB1B0' }}
                type="button"
                onClick={handleRefetch}
                autoFocus
              >
                Atualizar
              </CustomButton>
            </DialogActions>
          </>
        )}
      </Dialog>
      {openSpc && <TableSPC open={openSpc} data={data.data} close={() => setOpenSpc(false)} />}
      {openRegistro && <TableConsultationRecord open={openRegistro} data={data.data} close={() => setOpenRegistro(false)} />}
      {openFinPj && <TableFinPJ open={openFinPj} data={data.data} close={() => setOpenFinPj(false)} />}
      {openDVRF && <TableDVRFInternal open={openDVRF} data={data.data} close={() => setOpenDVRF(false)} />}
      {openDV && <TableDVApplicant open={openDV} data={data.data} close={() => setOpenDV(false)} />}
      {openPolicy && <TablePolicy open={openPolicy} data={data.data} close={() => setOpenPolicy(false)} />}
    </>
  )
}

export default ModalSerasa
