/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button as MuiButton,
  Checkbox,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PrevisionManagement from './PrevisionManagement'
import AddIcon from '@mui/icons-material/Add'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/lab'
import DatePicker from '@mui/lab/DatePicker'
import Button from 'components/form/Button'
import moment from 'moment'
import { httpClient } from 'config/httpClient'
import { MODULES } from 'helpers/modules'
import { Can } from 'components/Permission/Can'
import { PERMISSIONS } from 'helpers/permissions'

const Management: React.FC = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<any | null>(new Date())
  const [selectDate, setSelectDate] = useState<any | null>(new Date())
  const [months, setMonths] = useState<any[]>([
    {
      month: 'Janeiro',
      select: false,
      disabled: false
    },
    {
      month: 'Fevereiro',
      select: false,
      disabled: false
    },
    {
      month: 'Março',
      select: false,
      disabled: false
    },
    {
      month: 'Abril',
      select: false,
      disabled: false
    },
    {
      month: 'Maio',
      select: false,
      disabled: false
    },
    {
      month: 'Junho',
      select: false,
      disabled: false
    },
    {
      month: 'Julho',
      select: false,
      disabled: false
    },
    {
      month: 'Agosto',
      select: false,
      disabled: false
    },
    {
      month: 'Setembro',
      select: false,
      disabled: false
    },
    {
      month: 'Outubro',
      select: false,
      disabled: false
    },
    {
      month: 'Novembro',
      select: false,
      disabled: false
    },
    {
      month: 'Dezembro',
      select: false,
      disabled: false
    }
  ])
  const [previsionsForm, setPrevisionsForm] = useState<any[]>([])

  const [selectedMonths, setSelectedMonths] = useState<any[]>([])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const loadData = useCallback(() => {
    httpClient
      .get(`previsions/form?year=${moment(selectDate).format('YYYY')}`)
      .then((response) => {
        // axios.get(`http://localhost:9003/api/v1/previsions/form?year=${moment(selectDate).format('YYYY')}`).then((response) => {
        console.log(response.data)
        setPrevisionsForm(response.data)
        setMonths((state) =>
          state.map((month) => {
            const existsPrevisionsInMonth = response.data?.find(
              (item) => item?.month === month.month
            )
            return {
              ...month,
              disabled: !existsPrevisionsInMonth || false
            }
          })
        )
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [selectDate])

  useEffect(() => {
    setLoading(true)
    loadData()
  }, [loadData, selectDate])

  useEffect(() => {
    setSelectedMonths(
      months?.reduce((acc, month) => {
        if (month?.selected) {
          const findPrevisions = previsionsForm.find(
            (item) => item?.month === month.month
          )
          return [...acc, { month: month?.month, data: findPrevisions ?? [] }]
        }
        return acc
      }, [])
    )
  }, [months, selectDate])

  const CheckboxItem = ({
    name,
    checked,
    disabled
  }: {
    name: string
    checked: boolean
    disabled: boolean
  }) => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="8px"
        borderRadius="4px"
        border="1px solid"
        sx={{
          opacity: disabled ? 0.5 : 1,
          boxShadow: disabled || checked ? 'none' : '0px 3px 4px #00000029',
          fontSize: '12px',
          background: checked ? '#4060E6' : 'transparent',
          color: checked ? '#ffffff' : '#262626',
          borderColor: checked ? 'transparent' : '#E5E5E5'
        }}
      >
        {name}
      </Box>
    )
  }

  const handleToggleMonth = (month: string) => {
    setMonths((state) =>
      state.map((item) =>
        item.month === month ? { ...item, selected: !item?.selected } : item
      )
    )
  }

  const handleSelectDate = () => {
    if (!date) {
      document.getElementById('select-year')?.focus()
      return
    }

    setMonths((state) => state.map((item) => ({ ...item, selected: false })))
    setSelectDate(date)
  }

  const handleRefetch = () => {
    loadData()
  }

  const handleRefetchDelete = () => {
    loadData()
    setMonths((state) =>
      state.map((item) => ({ ...item, selected: false }))
    )
  }

  return (
    <Box minHeight="calc(100vh - 150px)" display="flex" flexDirection="column" justifyContent='space-between'>
      <Box>
        <BreadcrumbsComponent
          breadcrumbs={[
            { text: 'Gerencial', url: '/home/managementControl' },
            { text: 'Previsão' }
          ]}
        />

        <Box marginTop={2} paddingBottom={3}>
          <Typography fontWeight="bold">Previsões</Typography>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Can
            module={MODULES.GERENCIAL}
            permissions={[PERMISSIONS.CREATE]}
          >
            <MuiButton
              variant="outlined"
              size="large"
              startIcon={<AddIcon />}
              onClick={() =>
                navigate(
                  '/home/managementControl/management/management-control-form'
                )
              }
              sx={{
                border: 'none',
                textTransform: 'inherit',
                boxShadow: '0px 3px 6px #00000029',
                color: '#8A8A8A',
                '&:hover': {
                  border: 'none'
                }
              }}
            >
              Nova previsão
            </MuiButton>
          </Can>

          <Stack
            direction="row"
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                views={['year']}
                label="Ano"
                inputFormat="yyyy"
                value={date}
                onChange={(value) => {
                  console.log('date', value)
                  setDate(value)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="select-year"
                    autoFocus
                    autoComplete='off'
                    InputProps={{
                      endAdornment: (
                        <Box paddingRight={1} marginLeft="0px">
                          {params?.InputProps?.endAdornment}
                        </Box>
                      )
                    }}
                    className="w-100"
                    variant="standard"
                    required
                  />
                )}
              />
            </LocalizationProvider>

            <Button type="button" mold="primary" onClick={handleSelectDate}>
              Gerar
            </Button>
          </Stack>
        </Stack>

        {loading && !selectedMonths?.length && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={200}
          >
            <CircularProgress />
          </Box>
        )}

        <Stack marginTop={3} direction="row" flexWrap="wrap">
          {!loading &&
            selectDate &&
            months.map((month, i) => (
              <Checkbox
                key={i}
                checked={month?.selected ?? false}
                size="small"
                onChange={() => handleToggleMonth(month?.month)}
                icon={
                  <CheckboxItem
                    name={month?.month}
                    checked={month?.selected}
                    disabled={month?.disabled}
                  />
                }
                checkedIcon={
                  <CheckboxItem
                    name={month?.month}
                    checked={month?.selected}
                    disabled={month?.disabled}
                  />
                }
                disabled={month?.disabled}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  padding: 0,
                  marginRight: '8px',
                  marginBottom: '8px',
                  '& input': {
                    opacity: '0 !important'
                  }
                }}
              />
            ))}
        </Stack>

        {selectDate && selectedMonths?.length > 0 && (
          <PrevisionManagement
            previsionsForm={selectedMonths}
            refresh={handleRefetch}
            handleRefetchDelete={handleRefetchDelete}
          />
        )}
      </Box>

      <Stack alignItems="flex-end" sx={{ marginTop: 4 }}>
        <Link
          style={{ textDecoration: 'none', color: 'black' }}
          to="/home/managementControl"
        >
          <Button
            mold="primary"
            style={{
              width: '122px'
            }}
          >
            Voltar
          </Button>
        </Link>
      </Stack>
    </Box>
  )
}

export default Management
