import { Box, CircularProgress, TextField } from '@mui/material'
import Button from 'components/form/Button'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { postSalesOrder, postXML } from 'services/orderService/OrderService'
import styled from 'styled-components'
import alertMessage from 'components/Alerts/ModalAviso'

interface Props {
  xmlData: string
  xmlParams: { headerParams: any, linesParams: any, sequencial: number }
  pedidoID: string
  close: () => any
  closeSuccess: () => any
}

function ConfirmSellerOrder(props: Props) {
  const [loading, setLoading] = useState(false)

  const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  `

  const sendXML = () => {
    console.log('xml data', props)
    setLoading(true)
    postXML(props.xmlData).then(
      data => {
        console.log('postXML', data)
        toast.success('Ordem de venda enviada com sucesso!')
        setLoading(false)
        postSalesOrder({
          pedido: props.pedidoID,
          displayId: props.xmlParams.headerParams.displayId,
          sequencial: props.xmlParams.sequencial,
          status: 'Enviado',
          client_mne: ''
        })
        props.closeSuccess()
      }
    ).catch(e => {
      setLoading(false)
      toast.error('Não foi possível gerar a ordem de venda')
    })
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}
        style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <h4>Confirmar envio de Ordem de Venda</h4>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', fontSize: '14px' }}>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Ambiente" variant="standard"
            defaultValue={props.xmlParams.headerParams.enviroment} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Cliente" variant="standard"
            defaultValue={props.xmlParams.headerParams.customerName} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Rua" variant="standard"
            defaultValue={props.xmlParams.headerParams.customerAdressStreet} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Número" variant="standard"
            defaultValue={props.xmlParams.headerParams.customerAdressNumber} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Cidade" variant="standard"
            defaultValue={props.xmlParams.headerParams.customerAdressCity} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="País" variant="standard"
            defaultValue={props.xmlParams.headerParams.customerAdressCountry} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Estado" variant="standard"
            defaultValue={props.xmlParams.headerParams.customerAdressStateCode} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Código do País" variant="standard"
            defaultValue={props.xmlParams.headerParams.customerAdressCountryCode} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="CEP" variant="standard"
            defaultValue={props.xmlParams.headerParams.customerAdressPostalCode} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="ID Cliente LN" variant="standard"
            defaultValue={props.xmlParams.headerParams.clientLnId} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Moeda" variant="standard"
            defaultValue={props.xmlParams.headerParams.currencyID} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Preço total" variant="standard"
            defaultValue={props.xmlParams.headerParams.priceAmount} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Termo de pagamento" variant="standard"
            defaultValue={props.xmlParams.headerParams.paymentTermID} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Descrição do termo" variant="standard"
            defaultValue={props.xmlParams.headerParams.paymentTermDescription} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Método de pagamento" variant="standard"
            defaultValue={props.xmlParams.headerParams.paymentMethodCode} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Tipo de ordem" variant="standard"
            defaultValue={props.xmlParams.headerParams.orderType} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Display ID" variant="standard"
            defaultValue={props.xmlParams.headerParams.displayId} />
        </RowStyled>
        <RowStyled>
          <TextField disabled fullWidth id="standard-basic" label="Data de requisição" variant="standard"
            defaultValue={props.xmlParams.headerParams.effectiveDateTime} />
        </RowStyled>
      </div>

      <div style={{ marginTop: '16px' }}>Itens dessa ordem: </div>
      {
        props.xmlParams.linesParams.map(
          item => (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', fontSize: '14px', border: 'solid 1px gray', borderRadius: '8px', padding: '5px' }}>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Item ID" variant="standard"
                  defaultValue={item.itemId} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Descrição do Item" variant="standard"
                  defaultValue={item.itemDescription} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Tipo de unidade" variant="standard"
                  defaultValue={item.unitCode} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Preço por unidade" variant="standard"
                  defaultValue={item.unitPriceAmount} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Quantidade" variant="standard"
                  defaultValue={item.unitAmount} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Quantidade por unidade" variant="standard"
                  defaultValue={item.perQuantity} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Preço total" variant="standard"
                  defaultValue={item.extendedPriceAmount} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Data de entrega" variant="standard"
                  defaultValue={item.promisedShipDateTime} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Armazém" variant="standard"
                  defaultValue={item.shipFromPartyId} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Termo de entrega" variant="standard"
                  defaultValue={item.deliveryTerm} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Código Transportadora" variant="standard"
                  defaultValue={item.carrierParty} />
              </RowStyled>
              <RowStyled>
                <TextField disabled fullWidth id="standard-basic" label="Número de Pedido" variant="standard"
                  defaultValue={item.customerOrderNumber} />
              </RowStyled>
            </div>
          )
        )
      }
      <div style={{ width: '100%', display: 'flex', justifyContent: 'right', marginTop: '18px' }}>
        <Button mold='primary'
                onClick={() => {
                                  const parsedPriceAmount = parseFloat(props.xmlParams.headerParams.priceAmount)
                                  const roundedPriceAmount = parseFloat(parsedPriceAmount.toFixed(2))
                                  const parsedClientLimitCredit = parseFloat(props.xmlParams.headerParams.clientLimitCredit)
                                  const roundedClientLimitCredit = parseFloat(parsedClientLimitCredit.toFixed(2))

                                  if (props.xmlParams.headerParams.paymentTermID === 'D00') {
                                    toast.success('Pagamento modo À Vista')
                                    sendXML()
                                  } else if (props.xmlParams.headerParams.paymentTermID === 'D12') {
                                    toast.success('Com Duplicata, mas passa')
                                    sendXML()
                                  } else if ((roundedPriceAmount > roundedClientLimitCredit) && (roundedClientLimitCredit >= 0.01)) {
                                    alertMessage(`<br/><br/>
                                                  <b>ATENÇÃO!&nbsp;</b>
                                                  <br/><br/>
                                                  <p style={{ textAlign: 'justify' }}>
                                                  <b>Limite de Crédito</b>&nbsp;<span style="color:red;font-weight:bold">R$ ${roundedClientLimitCredit}</span>&nbsp;
                                                    <span style="color: 'red'">inferior</span>&nbsp;
                                                    ao <b>Preço Total</b> da Ordem de Venda&nbsp;
                                                    <span style="color:green;font-weight:bold">R$ ${roundedPriceAmount}</span>
                                                  </p>
                                                  <br/><br/>
                                                  <p style={{ textAlign: 'justify' }}>
                                                    Portanto esta operação não será finalizada sendo imprescindível que providencie a regularização monetária.
                                                  </p>
                                                </div>`)
                                  } else {
                                    sendXML()
                                  }
                                }
                              }
        >Confirmar</Button>
      </div>

    </>
  )
}

export default ConfirmSellerOrder
