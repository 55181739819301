import { Box } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import React, { ReactNode } from 'react'
import { Tab, TabPanel, TabsList } from './productsTabPanel.styles'

interface Props {
  tabs: string[]
  defaultTab?: number
  tabPanel: ReactNode[]
  marginTop?: number
  marginBottom?: number
  value?: number
  change?: (event: React.SyntheticEvent, value: number | string | boolean) => void
}

export const ProductsTabPanel: React.FC<Props> = ({ tabs = [], defaultTab = 0, tabPanel = [], marginTop = 0, marginBottom, ...params }) => {
  return (
    <TabsUnstyled onChange={params.change} value={params.value} defaultValue={defaultTab > tabs.length ? tabs.length : defaultTab}>
      <Box marginTop={marginTop} marginBottom={marginBottom}>
        <TabsList>
          {tabs.map((tab) => (
            <Tab key={tab}>{tab}</Tab>
          ))}
        </TabsList>
      </Box>
      {tabPanel.map((tabElement, index) => (
        <TabPanel key={`tab-panel-${index}`} value={index}>
          {tabElement}
        </TabPanel>
      ))}
    </TabsUnstyled>
  )
}
