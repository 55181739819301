import React from 'react'
import {
  BarChart,
  Bar,
  YAxis,
  Cell,
  Tooltip,
  CartesianGrid
} from 'recharts'
import ChartBottomLabel from './ChartBottomLabel'
import { TooltipContent } from './chartPie.style'
import { LabelTitle, RowCV } from './styles'

const CustomTooltip = ({ active, title, payload, event }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContent color={payload[0].payload.color}>
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>{`${payload[0].payload.title}`}</p>
        <p style={{ fontSize: 12 }}>{`${payload[0].value}`}</p>
      </TooltipContent>
    )
  }

  return null
}

export default function ChartComplaint(props: any) {
  return (
    <div className="app">
      <LabelTitle>
        <p>{props.title}</p>
      </LabelTitle>
      <div className="main">
        <RowCV>
          <BarChart
            width={400}
            height={255}
            data={props.data}
            margin={{ top: 25, right: 40, bottom: 25, left: 0 }}
          >
            {/* <XAxis dataKey="Text" fontFamily="sans-serif" dy="25" /> */}
            <Tooltip content={event => <CustomTooltip active={event.active} title={props.title} payload={event.payload} event={event} />} />
            <CartesianGrid vertical={false} />
            <YAxis />
            <Bar
              dataKey="value"
              barSize={60}
              fontFamily="sans-serif"
            // label={<CustomizedLabel />}
            >
              {
                props.data.map((d, index) => (
                  <Cell key={index} fill={d.color} />
                ))
              }
            </Bar>
          </BarChart>
        </RowCV>
        <RowCV>
          {
            props.data.map((d, index) => (
              <ChartBottomLabel key={index} title={d.title} color={d.color} />
            ))
          }
        </RowCV>
      </div>
    </div>
  )
}
