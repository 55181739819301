import { Box, Card, CardContent, Dialog, Grid } from '@mui/material'
import styled from 'styled-components'

export const DialogStyle = styled(Dialog)`
.MuiDialog-paper {
  width: 1150px;
  max-width: none;
  min-height: 400px; 
  min-width: 1100px;

}
`
export const RetangleView = styled.div`
  width: 25px;
  height: 60px;
  background-color: #4060E6;
`

export const DivAlingCard = styled.div`
  display: flex;
  flex-direction: row;
`
export const CardFather = styled(Card)`
  display: flex;
  width: 100%; 
  padding-bottom: 0px;
`
export const CardContentChieldren = styled(CardContent)`
  display: flex; 
  flex-direction: row; 
  padding-bottom: 10px !important; 
  padding-right: 0px;
`
export const DivAlingItensCard = styled.div`
  display: flex; 
  margin-top: 2px;
  align-items: center !important;
`

export const LabelText = styled.div`
  font: normal normal bold 16px/22px Roboto; 
  color: #262626;
`
export const DivStepper = styled.div`
  display: flex; 
  flex-direction: row; 
  margin-top: 15px;
`

export const BoxAlingCheckbox = styled(Box)`
  flex-grow: 1; 
  margin-top: 20px;
`
export const LabelCheckbox = styled(Grid)`
  font: normal normal normal 16px/22px Segoe UI; 
  color: #262626;
`
export const DivRow = styled.div`
  display: flex; 
  flex-direction: row; 
  margin-left: 2px;
`
export const ButtonRow = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: end; 
  margin-top: 30;
`
