import { Clear, SearchOutlined } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  FormControl,
  Icon,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import Button from 'components/form/Button'
import { Can } from 'components/Permission/Can'
import MoreMenu from 'components/view/MoreMenu'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getFTsTrimbox } from 'services/ft/FTService'
import { SynchronizeStatusFTByCodFT } from './sampleDevFT/components/SynchronizeStatusFTByCodFT'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

function DisabledFT() {
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const [page, setPage] = React.useState(0)
  const [rows, setRows] = React.useState<any[]>([])
  const [search, setSearch] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')

  const ftReq = useQuery(
    ['ft-trimbox', page, search],
    async () => {
      return getFTsTrimbox(page, 'Suspenso', 'Desativada', search)
    },
    {
      onSuccess: (data) => {
        setRows(data.content)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )
  console.log('DisabledFT=>ftReq', ftReq)
  const handleSearch = () => {
    setSearch(searchValue)
    ftReq.refetch()
  }

  const handleClearSearch = () => {
    setSearch('')
    setSearchValue('')
    if (inputSearchRef?.current?.value) {
      inputSearchRef.current.value = ''
    }
  }

  const isLoadingAnyQuery = [ftReq].some(
    (query) => query.isLoading || query.isFetching
  )
  const hasErrorInAnyQuery = [ftReq].some(
    (query) => query.isError
  )
  const allQueriesSuccess = (
    ftReq.isSuccess && !ftReq.isError && !ftReq.isFetching
  )

  let result: any

  if (isLoadingAnyQuery) {
    result = (
      <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          style={{
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          {loading
? (
            <>
              <CircularProgress variant="determinate" value={progress} />
              <p style={{ color: 'green', marginTop: '8px' }}>Consultando Trimbox: {progress}%</p>
            </>
          )
: (
          (ftReq === null || ftReq === undefined)
? (
            <p style={{ color: 'red', marginTop: '8px' }}>
              Não houve resposta do sistema Trimbox. Inconsistências técnicas na comunicação no momento.
            </p>
          )
: (
            <p>Espere um momento, por favor!</p>
          )
)
        }
      </Box>
    )
  }

  if (hasErrorInAnyQuery) {
    result = (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }

  if (allQueriesSuccess) {
    result = (
      <div>
        <Stack sx={{ paddingTop: 2 }}>
          <FormControl variant="standard" className="w-100">
            <InputLabel htmlFor="field-search">Pesquisar</InputLabel>
            <Input
              id="field-search"
              autoComplete="off"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              inputRef={inputSearchRef}
              disabled={ftReq.isFetching}
              endAdornment={
                <InputAdornment position="end">
                  {/* <SearchOutlined /> */}
                  <Button
                  mold="primary"
                  type="button"
                  style={{
                    padding: 0,
                    paddingLeft: 8,
                    paddingRight: 8,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClick={handleSearch}
                  disabled={ftReq.isFetching}
                >
                  <SearchOutlined />
                </Button>

                  {(!!searchValue?.length ||
                    !!inputSearchRef?.current?.value?.length) && (
                    <Button
                      mold="red"
                      type="button"
                      style={{
                        padding: 0,
                        paddingLeft: 8,
                        paddingRight: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 4
                      }}
                      onClick={handleClearSearch}
                      disabled={ftReq.isFetching}
                    >
                      <Clear />
                    </Button>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Stack>

        <TableContainer style={{ marginTop: '30px' }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Código FT</StyledTableCell>
                <StyledTableCell align="left">Nome da Empresa</StyledTableCell>
                <StyledTableCell align="left">Emitido em</StyledTableCell>
                <Can
                  module={MODULES.FT}
                  permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                >
                  <StyledTableCell align="right">Ações</StyledTableCell>
                </Can>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">{row.codConjunto}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.company}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(row.dataCriacao).format('DD/MM/YYYY [às] HH:mm')}
                  </StyledTableCell>
                  <Can
                    module={MODULES.FT}
                    permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                  >
                    <StyledTableCell align="right">
                      <MoreMenu module={MODULES.FT} isValidationPermissions>
                        <SynchronizeStatusFTByCodFT cod={row.codConjunto} />
                      </MoreMenu>
                    </StyledTableCell>
                  </Can>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20]}
            rowsPerPage={20}
            component="div"
            count={rows.length}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        </TableContainer>
        {/* {openDialog && <GenericDialog widthRoot={1150} style={{ minHeight: '400px', minWidth: '1100px', maxWidth: 'none' }}
          open={openDialog}
          close={() => setOpenDialog(false)}
          content={<FTAuditBox form={formData} closeDialog={handleCloseDialog} />} />} */}
      </div>
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress < 100) {
            return prevProgress + 1
        } else {
            setLoading(false)
            clearInterval(interval)
            return prevProgress
        }
      })
    }, 1500)

    return () => {
        clearInterval(interval)
    }
  }, [])

  return result
}

export default DisabledFT
