import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import MoreMenu from 'components/view/MoreMenu'
import ViewUser from './ViewUser'
import { useNavigate } from 'react-router-dom'
import UserType from 'types/user/UserType'
import { updateStatusUser, getUser, searchUser } from 'services/user/UserService'
import { toast } from 'react-toastify'
import moment from 'moment'
import StatusView from 'components/view/StatusView'
import { Box, CircularProgress, FormControl, Grid, Input, InputAdornment, InputLabel, TablePagination, Typography } from '@mui/material'
import { useCallback, useRef, useState, useEffect } from 'react'
import { SearchOutlined } from '@mui/icons-material'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    height: 73
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function TableUsers(props: any) {
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')

  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState<UserType[]>([])
  const [rowsView, setRowsView] = useState<UserType>()
  const [loading, setLoading] = useState(true)

  const [count, setCount] = React.useState(0)
  const [page, setPage] = React.useState(0)

  const navigate = useNavigate()
  const edit = (row: UserType) => {
    navigate('/home/user-form', { state: { row: row } })
  }

  // React.useEffect(() => {
  //   getUser(page + 1)
  //     .then((data) => {
  //       setRows(data.docs)
  //       setCount(data.total)
  //     })
  //     .catch(() => toast.error('Erro ao listar Usuarios'))
  // }, [page])

  useEffect(() => {
    if (search?.length > 0) {
      searchUser(search, page + 1)
        .then((data) => {
          setRows(data.docs)
          setCount(data.total)
        })
        .catch(() => toast.error('Erro ao listar Usuários'))
        .finally(() => setLoading(false))
    } else {
      getUser(page + 1)
        .then((data) => {
          setRows(data.docs)
          setCount(data.total)
        })
        .catch(() => toast.error('Erro ao listar Usuários')).finally(() => setLoading(false))
    }
  }, [search, page])

  const desactive = async (id: string) => {
    try {
      await updateStatusUser(id, true)
      toast.success('Usuário desativado com sucesso!')
      getUser(page + 1)
        .then((data) => {
          setRows(data.docs)
          setCount(data.total)
        })
        .catch(() => toast.error('Erro ao listar Usuários')).finally(() => setLoading(false))
    } catch (error: any) {
      console.log('Error: ', error)
      toast.error('Erro ao atualizar informações do usuário')
    }
  }

  const active = async (id: string) => {
    try {
      await updateStatusUser(id, false)
      toast.success('Usuário ativado com sucesso!')
      getUser(page + 1)
        .then((data) => {
          setRows(data.docs)
          setCount(data.total)
        })
        .catch(() => toast.error('Erro ao listar Usuários')).finally(() => setLoading(false))
    } catch (error: any) {
      console.log('Error: ', error)
      toast.error('Erro ao atualizar informações do usuário')
    }
  }

  const debounce = useCallback((func: any, wait: number): any => {
    let timeout: NodeJS.Timeout

    return function executedFunction(...args: number[]) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }, [])

  const returnedFunction = debounce((event: any) => {
    if (event?.target?.value) {
      // console.log(event.target.value);
      // setIsLoading(true);
      setSearch(event.target.value)
      console.log(event.target.value)
    } else {
      setSearch('')
      console.log(event.target.value)
    }
  }, 800)

  return (
    <>
      <Grid container spacing={3} mt={0}>
        <Grid item xs={3}>
          <FormControl variant='standard' className='w-100'>
            <InputLabel htmlFor='field-search'>
              Pesquisar
            </InputLabel>
            <Input
              id='field-search'
              autoComplete='off'
              type='search'
              defaultValue=''
              onKeyUp={(event) => returnedFunction(event)}
              onKeyPress={(event) => returnedFunction(event)}
              onClick={(event) => returnedFunction(event)}
              ref={inputSearchRef}
              endAdornment={
                <InputAdornment position='end'>
                  <SearchOutlined />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      {loading
        ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>
                    <Box display='flex' justifyContent='center' alignItems='center' height={200} width='100%'>
                      <CircularProgress />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )
        : (
          <>
            <TableContainer style={{ marginTop: '30px' }} component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      Login / Usuário
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Nome
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Tipo de Perfil
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Data de Cadastro
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Status
                    </StyledTableCell>
                      <Can
                        module={MODULES.USUÁRIOS}
                        permissions={[PERMISSIONS.READ, PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                      >
                      <StyledTableCell align="center">
                        Ações
                      </StyledTableCell>
                    </Can>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: ['auto', '30%'],
                          minWidth: [300, 300, 300, 400]
                        }}
                      >
                        <Typography
                          component="span"
                          noWrap
                          sx={{
                            display: 'block',
                            width: '100%',
                            maxWidth: [300, 300, 300, 400]
                          }}
                        >
                          {row.email}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: ['auto', '20%'] }}>
                        {row.name ? row.name : ''}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: ['auto', '16%'] }}>
                        {row.profile ? row.profile.name : ''}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: ['auto', '16%'] }}>
                        {moment(row.createdAt).format('DD/MM/YYYY')}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: ['auto', '10%'] }}>
                        <StatusView status={row.isActive} />
                      </StyledTableCell>
                      <Can
                        module={MODULES.USUÁRIOS}
                        permissions={[PERMISSIONS.READ, PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                      >
                        {row.isActive
                          ? <StyledTableCell align="right" sx={{ width: ['auto', '8%'] }}>
                            <MoreMenu
                              module={MODULES.PERFIL}
                              isValidationPermissions
                              edit={() => edit(row)}
                              view={() => {
                                setOpen(true)
                                setRowsView(row)
                              }}
                              inactive={async () => desactive(String(row._id))}
                            />
                          </StyledTableCell>
                          : <StyledTableCell align="right" sx={{ width: ['auto', '8%'] }}>
                            <MoreMenu
                              module={MODULES.PERFIL}
                              isValidationPermissions
                              edit={() => edit(row)}
                              view={() => {
                                setOpen(true)
                                setRowsView(row)
                              }}
                              active={async () => active(String(row._id))}
                            />
                          </StyledTableCell>
                        }
                      </Can>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10]}
                rowsPerPage={10}
                component="div"
                count={count}
                page={page}
                onPageChange={(event: unknown, newPage: number) => {
                  setPage(newPage)
                }}
              />
            </TableContainer>
            <ViewUser open={open} close={() => setOpen(false)} dados={rowsView} />
          </>
        )}
    </>
  )
}
