/* eslint-disable @typescript-eslint/no-unused-vars */
import { httpClient, httpLN } from 'config/httpClient'

// import faturadoLn042022 from './faturadoLN042022.tmp.json'

export const getReportSeller = async ({ year, month }: {year: number, month: number}) => {
  const { data } = await httpClient.get<any>(`/previsions/report/seller?year=${year}&month=${month}`)
  return data
}

export const getReportSellerBilledLN = async ({ year, month }: {year: number, month: number}) => {
  const { data } = await httpLN.get<any>(`/faturamentos/cliente?mes=${month}&ano=${year}`)
  return data

  // APENAS PARA DESENVOLVIMENTO | Em Produção deve consultar os dados reais
  // return faturadoLn042022
}
