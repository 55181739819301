import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  CircularProgress,
  Icon
} from '@mui/material'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportBilling, getReportDevolucaoMensal, getReportProductionMonth, getReportBalanceteSucataMensal } from 'services/report/ReportBilling'
import { getReportRH } from 'services/report/ReportRH'
import { toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
}

const TableResumoResultOp: React.FC<Props> = (props) => {
  const [rows, setRows] = useState<any[]>([])
  const [dataLocal, setDataLocal] = useState<any>([])
  const [dataPlanejed, setDataPlanejed] = useState<any>([])
  const [dataDevolucao, setDataDevolucao] = useState<any>([])
  const [dataBalanceteSucata, setDataBalanceteSucata] = useState<any>([])
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const dataAtual = moment(props.date).format('DD/MM/YYYY')
  const [valorRow] = useState<any[]>([
    {
      text: 'Previsto',
      value: 1
    },
    {
      text: 'Realizado',
      value: 2
    },
    {
      text: 'Projetado',
      value: 3
    }
  ])
  const [priceKgPapel, setPriceKgPapel] = useState<number>(0)
  const [priceKgCaixa, setPriceKgCaixa] = useState<number>(0)
  const [priceKgChapa, setPriceKgChapa] = useState<number>(0)
  console.log(mes, ano)
  const queryMultiple = () => {
    const req1 = useQuery(['report', dataAtual],
      async () => {
        const currentDate = new Date(props.date)
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        return getReportProductionMonth(props.date, firstDay)
      },
      {
        onSuccess: (data) => {
          console.log('req1 => ', data)
          dataHandle(dataLocal, data, dataPlanejed)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verefifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    const req2 = useQuery(['sales-report', mes, ano],
      async () => {
        return getReportBilling(mes, ano)
      },
      {
        onSuccess: (date) => {
          console.log('date =>', date)
          setDataLocal(date)
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false
      }
    )
    const req3 = useQuery(['report-rh', props.date],
      async () => {
        return getReportRH(props.date)
      },
      {
        onSuccess: (data) => {
          setDataPlanejed(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verefifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    const req4 = useQuery(['devolucao', dataAtual],
      async () => {
        return getReportDevolucaoMensal(dataAtual)
      },
      {
        onSuccess: (data) => {
          setDataDevolucao(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verefifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    const req5 = useQuery(['balancete', dataAtual],
      async () => {
        return getReportBalanceteSucataMensal(dataAtual)
      },
      {
        onSuccess: (data) => {
          setDataBalanceteSucata(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verefifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    return [req1, req2, req3, req4, req5]
  }
  const [req1, req2, req3] = queryMultiple()
  const dataHandle = (valor, dataValues, dataPlan) => {
    const data: any[] = []
    const um: number = 1.1

    let previsionDataPapel: any
    let previsionDataCaixa: any
    let previsionDataChapa: any

    const papelDataArray: any[] = []
    const caixaDataArray: any[] = []
    const chapaDataArray: any[] = []
    const prodPapelDataArray: any[] = []
    const prodChapaPODataArray: any[] = []

    let realizedPapel: any
    let realizedCaixa: any
    let realizedChapa: any
    let realizedProdPapel: any
    let realizedProdChapaPO: any

    let resultCaixa: any
    let resultChapa: any
    let resultPapel: any
    let resultProdPapel: any
    let resultProdChapaPO: any
    let resultVendasCaixas: any
    let resultVendasChapas: any
    let resultVendasPapelClienteFinal: any

    const arrayFaturamento: any[] = []

    let vendasChapas: any
    let vendasCaixas: any
    let vendasPapelClienteFinal: any
    let vendasCancDevolucoes = 0

    dataValues?.result?.forEach(item => {
      item.dados.forEach(e => {
        if (e.type === TipoProduto.CAIXA) {
          console.log(e.price.toFixed(2))
          setPriceKgCaixa(e.price.toFixed(2))
          caixaDataArray.push(e)
        }
        if (e.type === TipoProduto.CHAPA) {
          prodChapaPODataArray.push(e)
          console.log(e.price.toFixed(2))
          setPriceKgChapa(e.price.toFixed(2))
          chapaDataArray.push(e)
        }
        if (e.type === TipoProduto.PAPEL) {
          prodPapelDataArray.push(e)
          console.log(e.price.toFixed(2))
          setPriceKgPapel(e.price.toFixed(2))
          papelDataArray.push(e)
        }
      })
    })
    console.log('dataBalanceteSucata', dataBalanceteSucata)
    dataDevolucao?.response?.forEach(item => {
      vendasCancDevolucoes += Number(item.preco)
    })

    if (Object.keys(valor.bobina.dados).length) {
      previsionDataPapel = (valor.bobina.dados.amountKg ?? 0) / 1000
      vendasPapelClienteFinal = (Math.floor((Number(previsionDataPapel))) * 1000) * Number(priceKgPapel)
    }
    if (Object.keys(valor.caixa.dados).length) {
      previsionDataCaixa = (valor.caixa.dados.amountKg ?? 0) / 1000
      vendasCaixas = (Math.floor((Number(previsionDataCaixa))) * 1000) * Number(priceKgCaixa)
    }
    if (Object.keys(valor.chapa.dados).length) {
      previsionDataChapa = (valor.chapa.dados.amountKg ?? 0) / 1000
      vendasChapas = (Math.floor((Number(previsionDataChapa))) * 1000) * Number(priceKgChapa)
    }

    if (caixaDataArray.length) {
      realizedCaixa = caixaDataArray[0].kg
      resultCaixa = realizedCaixa / 1000
      resultVendasCaixas = Number(caixaDataArray[0].valor1.toFixed(2))
    }

    if (chapaDataArray.length) {
      realizedChapa = chapaDataArray[0].kg
      resultChapa = realizedChapa / 1000
      resultVendasChapas = Number(chapaDataArray[0].valor1.toFixed(2))
      realizedProdChapaPO = (Number(resultCaixa) + Number(resultChapa)) * um
    }

    if (papelDataArray.length) {
      realizedPapel = papelDataArray[0].kg
      resultPapel = realizedPapel / 1000
      resultVendasPapelClienteFinal = Number(papelDataArray[0].valor1.toFixed(2))
    }

    if (prodPapelDataArray.length) {
      realizedProdPapel = prodPapelDataArray[0].kg
      resultProdPapel = realizedProdPapel / 1000
    }

    if (prodChapaPODataArray.length) {
      realizedProdChapaPO = prodChapaPODataArray[0].kg
      resultProdChapaPO = realizedProdChapaPO / 1000
    }

    let balancete
    let outrasVendas
    if (dataBalanceteSucata && dataBalanceteSucata.response && dataBalanceteSucata.response[0]) {
      outrasVendas = Number(dataBalanceteSucata.response[0].vendasucata)
      balancete = Number(dataBalanceteSucata?.response?.[0]?.balancete)
    } else {
      outrasVendas = 0
      balancete = 0
    }
    const ajusteValorPresente = -1 * balancete
    dataPlan.forEach(e => {
      if (e.production.name === 'Faturamento') {
        arrayFaturamento.push(e)
      }
    })

    const vendabruta = (Number(resultVendasPapelClienteFinal) + Number(resultVendasCaixas) + Number(resultVendasChapas))
    const resultVendaBruta = Number(resultVendasPapelClienteFinal) + Number(resultVendasCaixas) + Number(resultVendasChapas) + Number(ajusteValorPresente) + Number(outrasVendas) + Number(vendasCancDevolucoes)
    const projVendaBruta = Number(resultVendasPapelClienteFinal) + Number(resultVendasCaixas) + Number(resultVendasChapas) + Number(ajusteValorPresente) + Number(outrasVendas) + Number(vendasCancDevolucoes)

    console.log('vendabruta', vendabruta)
    console.log('resultPapel', resultPapel)
    console.log('resultProdPapel', resultProdPapel)
    console.log('resultProdChapaPO', resultProdChapaPO)

    /* LUCRO BRUTO */
    data.push({
      _id: 'ton-1',
      product: 'LUCRO BRUTO',
      prev: toBRL(vendasPapelClienteFinal | 0),
      realizedRs: toBRL(resultVendasPapelClienteFinal | 0),
      projectedRs: toBRL(resultVendasPapelClienteFinal | 0)
    })
    /* Desp. Administração */
    data.push({
      _id: 'ton-2',
      product: 'Desp. Administração',
      prev: toBRL(vendasCaixas | 0),
      realizedRs: toBRL(resultVendasCaixas | 0),
      projectedRs: toBRL(resultVendasCaixas | 0)
    })
    /* Gastos para vender */
    data.push({
      _id: 'ton-3',
      product: 'Gastos para vender',
      prev: toBRL(vendasChapas | 0),
      realizedRs: toBRL(resultVendasChapas | 0),
      projectedRs: toBRL(resultVendasChapas | 0)
    })
    /* RESULTADO ANTES ICMS */
    data.push({
      _id: 'ton-4',
      product: 'RESULTADO ANTES ICMS',
      prev: '',
      realizedRs: toBRL(ajusteValorPresente | 0),
      projectedRs: toBRL(ajusteValorPresente | 0)
    })
    /* Impostos Sobre Vendas */
    data.push({
      _id: 'ton-5',
      product: 'Impostos Sobre Vendas',
      prev: '',
      realizedRs: toBRL(outrasVendas | 0),
      projectedRs: toBRL(outrasVendas | 0)
    })
    /* Despesas financeiras  */
    data.push({
      _id: 'ton-6',
      product: 'Despesas financeiras',
      prev: '',
      realizedRs: toBRL((vendasCancDevolucoes * -1) | 0),
      projectedRs: toBRL((vendasCancDevolucoes * -1) | 0)
    })
    /* Ajuste Contábilidade  */
    data.push({
      _id: 'ton-7',
      product: 'Ajuste Contábilidade',
      prev: toBRL(vendabruta | 0),
      realizedRs: toBRL(resultVendaBruta | 0),
      projectedRs: toBRL(projVendaBruta | 0)
    })

    /* RESULTADO DA OPERAÇÃO EBIT  */
    data.push({
      _id: 'ton-8',
      product: 'RESULTADO DA OPERAÇÃO EBIT',
      prev: toBRL(vendabruta | 0),
      realizedRs: toBRL(resultVendaBruta | 0),
      projectedRs: toBRL(projVendaBruta | 0)
    })

    /* Margem  */
    data.push({
      _id: 'ton-9',
      product: 'Margem',
      prev: toBRL(vendabruta | 0),
      realizedRs: toBRL(resultVendaBruta | 0),
      projectedRs: toBRL(projVendaBruta | 0)
    })

    /* Custo Fixo  */
    data.push({
      _id: 'ton-10',
      product: 'Custo Fixo',
      prev: toBRL(vendabruta | 0),
      realizedRs: toBRL(resultVendaBruta | 0),
      projectedRs: toBRL(projVendaBruta | 0)
    })

    /* Custo Variável  */
    data.push({
      _id: 'ton-11',
      product: 'Custo Variável',
      prev: toBRL(vendabruta | 0),
      realizedRs: toBRL(resultVendaBruta | 0),
      projectedRs: toBRL(projVendaBruta | 0)
    })

    /* Ponto de Equilíbrio  */
    data.push({
      _id: 'ton-12',
      product: 'Ponto de Equilíbrio',
      prev: toBRL(vendabruta | 0),
      realizedRs: toBRL(resultVendaBruta | 0),
      projectedRs: toBRL(projVendaBruta | 0)
    })
    setRows(data)
  }

  return (
    <>
      {(req1.isLoading || req1.isFetching || req3.isLoading || req3.isFetching) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {(req1.isError && req2.isError && req3.isError) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>
            signal_wifi_statusbar_connected_no_internet_4
          </Icon>
        </Box>
      )}

      {(req1.isSuccess && !req1.isError && !req1.isFetching) && (
        <>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: '100%' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    {valorRow.map((item, index) => {
                      return (
                        <TableCell align="left" colSpan={1} key={index}>
                          {item.text}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow key={row._id} style={row._id === 'total' ? { fontWeight: 'bold', textShadow: '0px 0 black, 0 0.2px black, 0.2px 0 black, 0 0px black' } : {}}>
                        <TableCell style={{ wordBreak: 'break-word' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                            {row.product}
                          </div>
                        </TableCell>
                        <TableCell align="left" style={{ wordBreak: 'break-word' }}>
                          {row.prev.toLocaleString('pt-br')}
                        </TableCell>
                        <TableCell align="left" style={{ wordBreak: 'break-word' }}>
                          {row.realized ? Number(row.realized).toLocaleString('pt-br') : row.realizedRs}
                        </TableCell>
                        <TableCell align="left" style={{ wordBreak: 'break-word' }}>
                          {row.projected ? Number(row.projected).toLocaleString('pt-br') : row.projectedRs}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>

          </Paper>
        </>
      )}

    </>
  )
}

export default TableResumoResultOp
