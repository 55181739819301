import { CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'

interface Props {
  isLoading?: boolean
  children?: React.ReactNode
}

export const AlertContainer: React.FC<Props> = ({ isLoading, children }) => {
  return (
    <>
      {isLoading && (
        <Stack justifyContent="center" alignItems="center" minHeight={200}>
          <CircularProgress size={24} />
          <Typography component="span" variant="body2">
            Aguarde, carregando...
          </Typography>
        </Stack>
      )}

      {!isLoading && children}
    </>
  )
}
