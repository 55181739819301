/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react'
import { Box, Grid, TextField } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import {
  DivModules,
  TabsList,
  Tab,
  TabPanel,
  TabPanelTable
} from './styles/deliveryMap.styles'
import NewDashBoard from './dashBoard/NewDashBoard'
import Mnemosine from './mnemosine/mnemosine'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import PlateDelay from './PlateDelayMap/PlateDelay'
import CoilDelay from './CoilDelayMap/CoilDelay'
import Plate from './PlateMap/Plate'
import RepositionContainer from './RepositionMap/RepositionContainer'
import KanbanDelayContainer from './kanbanDelayMap/KanbanDelayContainer'
import WalletDelayContainer from './WalletDelayMap/WalletDelayContainer'
import WalletContainer from './WalletMap/WalletContainer'
import KanbanContainer from './kanbanMap/KanbanContainer'
import OutOfState from './OutOfStateMap/OutOfState'
import OTDeOTIFMap from './OTDeOTIFMap'
import PilotLotContainer from './PilotLotMap/PilotLotContainer'
import GeneralItems from './Overview/GeneralItems'

const DeliveryMapModules: React.FC = () => {
  // const [search, setSearch] = useState('')
  const [selectDate, setSelectDate] = React.useState<Date | null>(new Date())
  const [deliveryMap, setDeliveryMap] = useState<any[]>([])

  // const debounce = useCallback((func: any, wait: number): any => {
  //   let timeout: NodeJS.Timeout

  //   return function executedFunction(...args: number[]) {
  //     const later = () => {
  //       clearTimeout(timeout)
  //       func(...args)
  //     }

  //     clearTimeout(timeout)
  //     timeout = setTimeout(later, wait)
  //   }
  // }, [])

  // const returnedFunction = debounce((event: any) => {
  //   if (event?.target?.value) {
  //     setSearch(event.target.value)
  //     console.log(event.target.value)
  //   } else {
  //     setSearch('')
  //     console.log(event.target.value)
  //   }
  // }, 800)

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <BreadcrumbsComponent
          breadcrumbs={[
            { text: 'Gerencial', url: '/home/managementControl' },
            { text: 'Mapa de entrega' }
          ]}
        />
      </Box>
      <div>
        <strong>Mapa de entrega</strong>
      </div>

      <Grid container spacing={3} mt={0}>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '10px',
              marginRight: '0px'
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                views={['year', 'month', 'day']}
                label="Dia"
                inputFormat="dd/MM/yyyy"
                value={selectDate}
                onChange={(newValue) => {
                  setSelectDate(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" helperText={null} />
                )}
              />
            </LocalizationProvider>
          </div>
        </Grid>
      </Grid>

      {/* <div style={{ marginTop: '5px' }}>
        <Grid item xs={2}>
          <FormControl variant='standard' className='w-100'>
            <InputLabel htmlFor='field-search'>
              Pesquisar
            </InputLabel>
            <Input
              id='field-search'
              autoComplete='off'
              type='search'
              defaultValue=''
              onKeyUp={(event) => returnedFunction(event)}
              onKeyPress={(event) => returnedFunction(event)}
              onClick={(event) => returnedFunction(event)}
              ref={inputSearchRef}
              endAdornment={
                <InputAdornment position='end'>
                  <SearchOutlined />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </div> */}
      <DivModules>
        <TabsUnstyled defaultValue={9}>
          <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <TabsList>
              {[
                'Resumo',
                'Overview',
                'OTD e OTIF',
                // 'Kanban em atraso',
                // 'Chapa em atraso',
                // 'Carteira em atraso',
                'Reposições',
                'Papel',
                'Chapa',
                'Lote piloto',
                'Kanban',
                'Carteira',
                'Fora do Estado'
              ].map((item) => (
                <Tab key={item}>{item}</Tab>
              ))}
            </TabsList>
          </Box>
          <TabPanelTable value={0}>
            <NewDashBoard date={moment(selectDate).format('YYYY/MM/DD')} />
          </TabPanelTable>
          {/* <TabPanel value={1}>
            <KanbanDelayContainer date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={1}>
            <PlateDelay date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={2}>
            <WalletDelayContainer date={selectDate ?? undefined} />
          </TabPanel> */}
          <TabPanel value={0}>
            <OTDeOTIFMap date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={1}>
            <GeneralItems date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={2}>
            <RepositionContainer date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={3}>
            <CoilDelay date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={4}>
            <Plate date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={5}>
            <PilotLotContainer date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={6}>
            <KanbanContainer date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={7}>
            <WalletContainer date={selectDate ?? undefined} />
          </TabPanel>
          <TabPanel value={8}>
            <OutOfState date={selectDate ?? undefined} />
          </TabPanel>
        </TabsUnstyled>
      </DivModules>
    </>
  )
}

export default DeliveryMapModules
