import React, { useEffect } from 'react'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { useFormik } from 'formik'
import { validationSchema } from '../validation/ValidationRH'
import { RHType } from '../types/RHType'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { ArrowBack } from '@mui/icons-material'
import Button from 'components/form/Button'
import DatePicker from 'react-multi-date-picker'
import NumericInput from 'material-ui-numeric-input'
import { httpClient } from 'config/httpClient'
import moment from 'moment'
import { toast } from 'react-toastify'

const RegisterRH = (props: any) => {
  const [products, setProducts] = React.useState<any[]>([])

  const { errors, touched, values, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      onSubmit: (values: RHType) => {
        const dates = values.days.map((v) =>
          moment.unix(v.unix).format('DD/MM/YYYY')
        )
        const datesWorks = values?.worksDays?.map((v) =>
          moment.unix(v.unix).format('DD/MM/YYYY')
        )
        const valuesForm: RHType = {
          product: values.product,
          shift: values.shift,
          days: dates,
          worksDays: datesWorks
        }
        httpClient
          .post('rh', {
            month: moment(dates[0], 'DD/MM/YYYY').format('M'),
            production: valuesForm.product,
            year: moment(dates[0], 'DD/MM/YYYY').format('YYYY'),
            shift: valuesForm.shift,
            days: dates,
            worksDays: datesWorks
          })
          .then((response) => {
            toast.success('Registrado com sucesso')
            props.registered()
          })
          .catch((e) => {
            toast.error(e.response.data.message)
          })
      },
      enableReinitialize: true,
      validationSchema,
      initialValues: {
        product: '',
        shift: '',
        days: props?.defaultDate ?? null,
        worksDays: props?.defaultWorkDate ?? null
      }
    })

  useEffect(() => {
    loadProducts()
  }, [])

  const loadProducts = () => {
    httpClient.get('products/productions').then((response) => {
      setProducts(response.data)
    })
  }

  const CustomComponent = (props: any) => {
    React.useEffect(() => {}, [props])

    return (
      <div>
        <TextField
          style={{ width: '200px' }}
          id="standard-basic"
          label={props.label}
          variant="standard"
          value={
            props.value.length > 1
              ? `${props.value[0]} à ${props.value[props.value.length - 1]}`
              : props.value[0]
          }
          onClick={() => props.openCalendar()}
          helperText={
            errors.product && touched.product ? 'Campo Obrigatorio' : ''
          }
        />
      </div>
    )
  }

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  const handleDaysPicker = (days) => {
    console.log(days)
    const filteredDays: any[] = []
    days.forEach((day) => {
      let different = 0
      filteredDays.forEach((fd) => {
        if (
          moment.unix(fd.unix).format('MM') !==
          moment.unix(day.unix).format('MM')
        ) {
          different++
        }
      })
      if (different === 0) {
        filteredDays.push(day)
      }
    })
    // setFieldValue('days', filteredDays)
    return filteredDays
  }

  const handleFillDays = () => {
    const startDate = moment(props.defaultDate).startOf('month')
    // const endDate = moment(props.defaultDate).endOf('month')
    const endDay = +moment(props.defaultDate).endOf('month').format('DD')

    const daysOfMonth: any[] = []

    const currentDate = startDate
    const days = Array(endDay).fill(null)
    days.forEach(() => {
      const newDay = new Date(currentDate.add('day', 1).format('YYYY-MM-DD'))
      daysOfMonth.push(newDay)
    })

    const filteredDays = handleDaysPicker(daysOfMonth)
    setFieldValue('days', filteredDays)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip
          title="Voltar"
          placement="top"
          onClick={() => {
            props.closeDialog()
          }}
        >
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>
          Novo planejamento mensal
        </div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginRight: '30px',
              marginTop: '20px'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px'
                }}
              >
                <div style={{ color: '#262626' }}>
                  <h5>Selecione os dias trabalhados</h5>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        required
                        variant="standard"
                        sx={{ width: '100%' }}
                        error={!!(errors.product && touched.product)}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Produto
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Produto"
                          value={values.product}
                          onChange={(e) => {
                            setFieldValue('product', e.target.value)
                          }}
                        >
                          {products.map((data) => (
                            <MenuItem value={data._id}>{data.name}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.product && touched.product
                            ? 'Campo Obrigatorio'
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        // hideWeekDays
                        weekDays={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                        value={values.days}
                        onChange={(e) => {
                          console.log('teste', e)
                          const filteredDays = handleDaysPicker(e)
                          setFieldValue('days', filteredDays)
                        }}
                        format="DD/MM/YYYY"
                        multiple
                        months={months}
                        sort
                        required
                        render={<CustomComponent label='Período Planejado' />}
                      />
                      <Button type='button' style={{ marginTop: 8 }} onClick={handleFillDays}>Preencher</Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumericInput
                        name="Turno"
                        precision={0}
                        decimalChar=","
                        thousandChar="."
                        error={!!(errors.shift && touched.shift)}
                        style={{ width: '100%' }}
                        id="standard-basic"
                        label="Turno"
                        variant="standard"
                        value={values.shift}
                        onChange={handleChange('shift')}
                        required
                        helperText={
                          errors.shift && touched.shift
                            ? 'Campo Obrigatorio'
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        // hideWeekDays
                        weekDays={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                        value={values.worksDays}
                        onChange={(e) => {
                          const filteredWorksDays = handleDaysPicker(e)
                          setFieldValue('worksDays', filteredWorksDays)
                        }}
                        format="DD/MM/YYYY"
                        multiple
                        months={months}
                        sort
                        render={<CustomComponent label='Período Trabalhado' />}

                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '130px'
            }}
          >
            <Button
              type="button"
              mold="red"
              onClick={() => {
                props.closeDialog()
              }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold="primary"
              type="submit"
            >
              SALVAR
            </Button>
          </div>
        </form>
      </Box>
    </>
  )
}

export default RegisterRH
