import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import React, { useState } from 'react'
import { BreadcrumbsType } from 'types/breadcrumbs/BreadcrumbsType'
import { Container } from './components/Container'
import { GoBack } from './components/GoBack'
import { Header } from './components/Header'
import { ReportSellerContainer } from './container/ReportSellerContainer'

const breadcrumbs: BreadcrumbsType[] = [
  { text: 'Relatórios', url: '/home/report' },
  { text: 'Relatório de Vendedor' }
]

const ReportSeller: React.FC = () => {
  const [selectDate, setSelectDate] = useState<any | null>(null)

  return (
    <Container>
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />

      <Header
        title="Relatório de Vendedor"
        selectedDate={(date) => setSelectDate(date)}
      />

      {selectDate && <ReportSellerContainer date={selectDate} />}

      <GoBack forRoute="/home/report" />
    </Container>
  )
}

export { ReportSeller }
