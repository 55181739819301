import {
  AssignmentTurnedIn, Visibility
  // DeleteOutlineOutlined
} from '@mui/icons-material'
import LightTooltip from 'components/tooltips/LightTooltip'
import MoreMenu from 'components/view/MoreMenu'
import { ActionButton } from 'components/view/styles/style-more-menu'

export const TableActionButtons = ({
  item,
  onAudit,
  onView
}: {
  item: any
  onAudit: () => void
  onView: () => void
}) => {
  return (
    <MoreMenu>
      <LightTooltip title="Auditar" placement="top">
        <ActionButton onClick={onAudit}>
          <AssignmentTurnedIn />
        </ActionButton>
      </LightTooltip>
      <LightTooltip title="Ver orçamento" placement="top">
        <ActionButton onClick={onView}>
          <Visibility />
        </ActionButton>
      </LightTooltip>
      {/* <LightTooltip title="Suspender" placement="top">
        <ActionButton color="#EE207A">
          <DeleteOutlineOutlined />
        </ActionButton>
      </LightTooltip> */}
    </MoreMenu>
  )
}
