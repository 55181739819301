import React, { useState } from 'react'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

import { ContainerFile, IconUpload, TextCallbackImage } from './styles/style-input-file'

interface Props {
  preview?: any
  getFile?: (file: any, blob?: any) => void
}

const InputFile: React.FC<Props> = (props) => {
  const refInput = React.createRef<HTMLInputElement>()
  const [preview, setPreview] = useState<any>(props.preview ?? '')

  const changeImage = () => {
    const current = refInput.current
    if (current) {
      current.click()
    }
  }

  const upload = () => {
    const files = refInput.current?.files
    if (files && files.length > 0) {
      const reader = new window.FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = () => {
        const result = reader.result
        console.log('pqp foto', files[0])

        setPreview(result)

        if (props.getFile) {
          props.getFile(result, files[0])
        }
      }
    }
  }

  return (
    <>
      <ContainerFile
        aria-label='upload arquivo'
        style={{ backgroundImage: `url(${preview})` }}
      >
        {
          !preview && (
            <>
              <ImageOutlinedIcon className='image-callback' sx={{ fontSize: 60 }} />
              <TextCallbackImage>Imagem não selecionada</TextCallbackImage>
            </>
          )
        }
        <IconUpload
          aria-roledescription='mandar um arquivo do computador'
          aria-label='icon-update'
          onClick={changeImage}
        />
      </ContainerFile>

      <input
        type='file'
        ref={refInput}
        accept='image/*'
        onChange={upload}
        className={'d-none'}
      />
    </>
  )
}

export default InputFile
