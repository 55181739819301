import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getClientPrevisions } from 'services/customerControl/CustomerControlServices'
import CustomerControlType from 'types/customerControl/CustomerControlType'
import PageableType from 'types/PageableType'
import debounce from 'lodash.debounce'

interface Props {
  onChange: (c: CustomerControlType) => void
  value?: CustomerControlType
  currentValue?: CustomerControlType
}

export default function AutocompleteClients(props: Props) {
  const [search, setSearch] = useState('')

  const [rows, setRows] = useState<CustomerControlType[]>([])
  const [limit] = useState(10)

  const [selectedValue, setSelectedValue] = useState<CustomerControlType>()

  const { isFetching, refetch } = useQuery('clients',
    async () => {
      // meta
      return getClientPrevisions({ page: 0, search, limit })
    },
    {
      onSuccess(data: PageableType<CustomerControlType>) {
        setRows(data.docs)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 5
    }
  )

  const debouncedFetchData = debounce(() => {
    refetch()
  }, 500)

  useEffect(() => {
    debouncedFetchData()
  }, [search])

  if (props.value) {
    return (
      <div>
        <Autocomplete
          noOptionsText='Sem opções'
          loading={isFetching}
          getOptionLabel={(option) => (option.isBlocked || !option.isActive) ? `${option.name} - [BLOQUEADO]` : option.name}
          id="combo-box-clients"
          options={rows.map((row) => ({ ...row, label: row.name }))}
          onChange={(event: React.SyntheticEvent<Element, Event>, value: CustomerControlType | null) => {
            props.onChange(value!)
          }}
          getOptionDisabled={(option) => option.isBlocked || !option.isActive}
          defaultValue={props.value}
          inputValue={search}
          onInputChange={(event, newInputValue) => {
            setSearch(newInputValue)
          }}
          renderInput={(params) =>
            <TextField variant="standard" {...params} label="Pesquisar cliente" />}
        />
      </div>
    )
  }

  return (
    <div>
      <Autocomplete
        noOptionsText='Sem opções'
        loading={isFetching}
        getOptionLabel={(option) => (option.isBlocked || !option.isActive) ? `${option.name} - [BLOQUEADO]` : option.name}
        id="combo-box-clients"
        options={rows.map((row) => ({ ...row, label: row.name }))}
        onChange={(event: React.SyntheticEvent<Element, Event>, value: CustomerControlType | null) => {
          props.onChange(value!)
          setSelectedValue(value!)
        }}
        getOptionDisabled={(option) => option.isBlocked || !option.isActive}
        defaultValue={props.currentValue}
        value={selectedValue}
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue)
        }}
        renderInput={(params) =>
          <TextField variant="standard" {...params} label="Pesquisar cliente" />}
      />
    </div>
  )
}
