import React from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'

const QueryPage: React.FC = () => {
  const fecthData = 'https://random.dog/woof.json'

  const { data, isError, isLoading, isSuccess } = useQuery('animals',
    async () => {
      return axios(fecthData)
    },
    {
      onSuccess: (data) => {
        console.log('result', data)
      },
      onError: (error) => {
        console.log(error)
      },
      refetchOnWindowFocus: false
    }
  )

  return (
    <div>

      {isLoading && (
        <h2>isLoading</h2>
      )}
      {isError && (
        <h2>isError</h2>
      )}

      {isSuccess && (
        <img width={400} height={400} src={data?.data.url} alt='' />
      )}

    </div>
  )
}

export default QueryPage
