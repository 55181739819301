import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { putDisapprovePreFT } from 'services/ft/FTService'
import { validationSchemaFT } from 'types/validation/ValidationFTDisapprove'

function FTDisapprove(props: any) {
  const {
    setFieldValue,
    errors,
    touched,
    values,
    handleSubmit
  } = useFormik({
    onSubmit: (values: any) => {
      refetchDisapprove()
    },
    enableReinitialize: true,
    validationSchema: validationSchemaFT,
    initialValues: {
      motive: ''
    }
  })

  const { refetch: refetchDisapprove, isFetching: isFetchingDisapprove } = useQuery(
    'disapprove-ft',
    async () => {
      return putDisapprovePreFT(props.id, values.motive)
    },
    {
      onError: (error: any) => {
        console.log(error)
        toast.error(error?.response?.data?.message ?? 'Não foi possível realizar essa operação')
      },
      onSuccess: (data) => {
        console.log('successo', data)
        props.closeDialog()
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  if (isFetchingDisapprove) {
    return (
      <Box sx={{ display: 'flex' }}
        style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ fontSize: 16, marginBottom: 16 }}>Explique o motivo da reprovação</div>
      <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
        <TextField
          id="outlined-multiline-static"
          label="Motivo da reprovação"
          multiline
          rows={4}
          value={values.motive}
          onChange={event => { setFieldValue('motive', event.target.value) }}
          error={Boolean(errors.motive && touched.motive)}
          helperText={
            errors.motive && touched.motive
              ? errors.motive
              : null
          }
        />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: 10 }}>
          <Button type='button' style={{ marginRight: '10px', backgroundColor: '#EBEBEB', color: '#484848' }} variant="contained" onClick={() => { props.cancellDialog() }}>Cancelar</Button>
          <Button type='submit' color="primary" variant="contained">Salvar</Button>
        </div>
      </form>

    </div>
  )
}

export default FTDisapprove
