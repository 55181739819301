/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { ReactNode, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogContentText,
  Grid,
  TextField
} from '@mui/material';
import moment from 'moment';
import {
  ButtonRow,
  DialogStyle
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import ModalHead from './ModalHead';
import { httpClient } from 'config/httpClient';
import { FormatPrice } from 'utils/FormatPrice';
import { useQuery } from 'react-query';

function Section({ title, children }: { title: string, children: ReactNode }) {
  return (
    <>
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <h4>{title}</h4>
      </Grid>
      {children}
    </>
  );
}

interface Props {
  open: boolean
  close: () => void
  data?: any
}

const ApprovedProposal: React.FC<Props> = (props) => {
  const [proposal, setProposal] = React.useState<any>(null);

  console.log('ApprovedProposal =>', props.data)
  console.log('ApprovedProposal => proposal => ', proposal);

  const { data, status, refetch, isFetching } = useQuery(
    ['modal-approved-proposal', props?.data?.ft?.preFT?._id],
    async () =>
      await httpClient.get<any[]>(
        `/budget/proposalsByPreFT/${props?.data?.ft?.preFT?._id}`
      ),
    {
      refetchOnWindowFocus: false
    }
  );

  const closeModal = () => props.close();

  useEffect(() => {
    const proposals = data?.data ?? []

    let selectedProposal = null

    if (proposals.length > 1) {
      // Find the most recent proposal by comparing updatedAt timestamps
      selectedProposal = proposals.reduce((prev, current) =>
        new Date(current.updatedAt) > new Date(prev.updatedAt) ? current : prev
      )
    } else if (proposals.length === 1) {
      // Only one item, so return it
      selectedProposal = proposals[0]
    }

    setProposal(selectedProposal)
  }, [data?.data, isFetching])

  console.log('proposal', proposal)
  console.log('proposal?.status', proposal?.status)

  switch (proposal?.status?.toUpperCase()) {
    case 'APROVADO PELO CLIENTE':
      return (
        <>
          <DialogStyle
            open={props.open}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ModalHead title="Proposta aprovada" data={props?.data} colorTitle="#72CE2B" codItem={props?.data?.codItem ?? props?.data?.ft?.itemReference}/>

            {status === 'loading' && (
              <Box
                display="flex"
                alignItems="center"
                height={200}
                justifyContent="center"
                flexDirection="column"
              >
                <CircularProgress size={24} />
                <span>Carregando...</span>
              </Box>
            )}

            {status !== 'loading' && proposal?._id && (
              <form>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid container spacing={2}>
                      <Section title="Proposta">
                        {/* ... Rest of the content ... */}
                      </Section>
                    </Grid>
                    <ButtonRow
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        marginTop: 30
                      }}
                    >
                      {/* ... Buttons ... */}
                    </ButtonRow>
                  </DialogContentText>
                </DialogContent>
              </form>
            )}
          </DialogStyle>
        </>
      );
    case 'REPROVADA PELO CLIENTE':
      return (
        <>
          <DialogStyle
            open={props.open}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ModalHead title="Proposta reprovada" data={props?.data} colorTitle="#EE207A" codItem={props?.data?.codItem ?? props?.data?.ft?.itemReference}/>
            {status === 'loading' && (
              <Box
                display="flex"
                alignItems="center"
                height={200}
                justifyContent="center"
                flexDirection="column"
              >
                <CircularProgress size={24} />
                <span>Carregando...</span>
              </Box>
            )}

            {status !== 'loading' && proposal?._id && (
              <form>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid container spacing={2}>
                      <Section title={proposal?.status}>
                        {proposal?.disapprovalReason && (
                          <Grid item xs={12} md={12}>
                            <TextField
                              fullWidth
                              id="standard-basic"
                              label="Motivo da reprovação"
                              value={proposal?.disapprovalReason}
                              multiline
                              minRows={3}
                              variant="outlined"
                              disabled
                              InputProps={{
                                style: {
                                  fontWeight: 'bold',
                                  fontSize: 16,
                                  color: 'rgba(0, 0, 0, 0.8)'
                                }
                              }}
                            />
                          </Grid>
                        )}
                      </Section>
                    </Grid>
                    <div style={{ textAlign: 'right', marginTop: 20 }}>
                    <Button variant="contained" color="primary" onClick={ () => { props.close() }}>
                    Voltar
                    </Button>
                    </div>
                  </DialogContentText>
                </DialogContent>
              </form>
            )}
          </DialogStyle>
        </>
      );
    case 'PENDENTE PELA CONTROLADORIA':
      return (
        <>
          <DialogStyle
            open={props.open}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ModalHead title="Proposta pendente" data={props?.data} colorTitle="#EE207A" codItem={props?.data?.codItem ?? props?.data?.ft?.itemReference}/>
            {status === 'loading' && (
              <Box
                display="flex"
                alignItems="center"
                height={200}
                justifyContent="center"
                flexDirection="column"
              >
                <CircularProgress size={24} />
                <span>Carregando...</span>
              </Box>
            )}

            {status !== 'loading' && proposal?._id && (
              <form>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid container spacing={2}>
                      <Section title={proposal?.status}>
                        {proposal?.observation && (
                          <Grid item xs={12} md={12}>
                            <TextField
                              fullWidth
                              id="standard-basic"
                              label="Aguardando resposta"
                              value={proposal?.observation}
                              multiline
                              minRows={3}
                              variant="outlined"
                              disabled
                              InputProps={{
                                style: {
                                  fontWeight: 'bold',
                                  fontSize: 16,
                                  color: 'rgba(0, 0, 0, 0.8)'
                                }
                              }}
                            />
                          </Grid>
                        )}
                      </Section>
                    </Grid>
                    <div style={{ textAlign: 'right', marginTop: 20 }}>
                    <Button variant="contained" color="primary" onClick={ () => { props.close() }}>
                    Voltar
                    </Button>
                    </div>
                  </DialogContentText>
                </DialogContent>
              </form>
            )}
          </DialogStyle>
        </>
      );
    default:
      return (
        <>
          <DialogStyle
            open={props.open}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ModalHead title="Pendente pela Controladoria" data={props?.data} colorTitle="#CE6C2B" codItem={props?.data?.codItem ?? props?.data?.ft?.itemReference}/>

            {status === 'loading' && (
              <Box
                display="flex"
                alignItems="center"
                height={200}
                justifyContent="center"
                flexDirection="column"
              >
                <CircularProgress size={24} />
                <span>Carregando...</span>
              </Box>
            )}

            {status !== 'loading' && (
              <form>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid container spacing={2}>
                      <Section title="ORÇAMENTO NÃO ENCONTRADO">
                        <Grid item xs={12} md={12}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Motivo"
                            value="Desculpe, não foi possível encontrar nenhum orçamento relacionado ou referenciado para esta solicitação."
                            multiline
                            minRows={3}
                            variant="outlined"
                            disabled
                            InputProps={{
                              style: {
                                fontWeight: 'bold',
                                fontSize: 16,
                                color: 'rgba(0, 0, 0, 0.8)'
                              }
                            }}
                          />
                        </Grid>
                      </Section>
                    </Grid>
                    <div style={{ textAlign: 'right', marginTop: 20 }}>
                    <Button variant="contained" color="primary" onClick={ () => { props.close() }}>
                    Voltar
                    </Button>
                    </div>
                  </DialogContentText>
                </DialogContent>

              </form>
            )}
          </DialogStyle>
        </>
      );
  }
};

export default ApprovedProposal;
