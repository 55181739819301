import Swal from 'sweetalert2'
import './style.css'

const alertSwal = (methodDelete?: () => void) => {
  Swal.fire({
    title: 'Deseja mesmo realizar essa operação?',
    customClass: {
      title: 'title_modal_delete',
      popup: 'container_modal_delete',
      cancelButton: 'button_modal_delete not',
      confirmButton: 'button_modal_delete yes'
    },
    showCancelButton: true,
    confirmButtonColor: '#4060E6',
    cancelButtonColor: '#EE207A',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Confirmar',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed && methodDelete) {
      methodDelete()
    }
  })
}

export default alertSwal
