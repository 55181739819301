import React, { useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Box, Button, Grid, TextField } from '@mui/material'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import { onlyNumbers } from 'helpers/stringHelper'
import styled from 'styled-components'
import { ptBR } from 'date-fns/locale'
import { useFormik } from 'formik'
import { SalesGoalsType } from 'types/managementControl/salesGoals/SalesGoalsType'
import moment from 'moment'
import { putSalesGoals, createSalesGoals } from 'services/managementControl/salesGoals/salesGoals.services'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const GridButton = styled.div`
  margin-top: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const ButtonCancel = styled(Button)`
  margin-right: 10px;
  background-color: #EE207A;
  border-radius: 2px;
  width: 122px;
  color: white;
`
const ButtonSave = styled(Button)`
  background-color: #4060E6;
  border-radius: 2px;
  width: 122px;
  color: white;
`

const FormSalesGoals: React.FC = () => {
  const navigate = useNavigate()
  const { state }: any = useLocation()
  const year = parseInt(state?.row?.year)
  const month: number = state?.row?.month

  const [valueData] = useState<Date>(new Date())
  const [rowDate] = useState<Date>(new Date(year, month))
  console.log('parede', rowDate)

  const handleChangeIntegerNumber = (field: string) => (event: any) => {
    const newValue = onlyNumbers(event.target.value)
    setFieldValue(field, newValue ? Math.round(Number(newValue)) : '')
  }

  const {
    setFieldValue,
    values,
    handleSubmit
  } = useFormik({
    onSubmit: async (values: SalesGoalsType) => {
      if (!state) {
        try {
          const body = {
            month: moment(values.date).format('M'),
            year: moment(values.date).format('YYYY'),
            metaSales: values.goal
          }
          await createSalesGoals(body)
          navigate('/home/managementControl/management/sales-goals')
          toast.success('Meta salva com sucesso!')
        } catch (error: any) {
          console.log('erro', error.response.data)
          if (error.response.data.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error('Erro ao salvar meta!')
          }
        }
      } else {
        try {
          const body = {
            month: moment(values.date).format('M'),
            year: moment(values.date).format('YYYY'),
            metaSales: values.goal
          }
          await putSalesGoals(values._id, body)
          toast.success('Dados editados com sucesso!')
          navigate('/home/managementControl/management/sales-goals')
        } catch (error: any) {
          console.log('erro', error.response.data)
          if (error.response.data.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error('Erro ao editar informações do usuario')
          }
        }
       }
    },
    enableReinitialize: true,
    initialValues: {
      date: rowDate ?? valueData,
      goal: state?.row?.metaSales ?? '',
      _id: state?.row?._id ?? ''
    }
  })

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <form onSubmit={handleSubmit}>
        <BreadcrumbsComponent
          breadcrumbs={[
            { text: 'Gerencial', url: '/home/managementControl' },
            { text: 'Meta de Vendas' }
          ]}
        />
        <div style={{ marginTop: '10px', fontSize: '16px' }}>
          Meta de Vendas
        </div>
        <Grid container spacing={1} style={{ marginTop: '20px' }}>
          <Grid item xs={3} sm={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                views={['month', 'year']}
                label="Mês e Ano"
                value={values.date}
                onChange={(newValue) => { setFieldValue('date', newValue) }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="input-dimensions.vincos"
              type="number"
              label="Meta"
              variant="standard"
              value={values.goal}
              onChange={handleChangeIntegerNumber('goal')}
              autoComplete="off"
              required
            />
          </Grid>
        </Grid>
        <GridButton>
          <ButtonCancel onClick={() => navigate('/home/managementControl/management/sales-goals')}>
            Cancelar
          </ButtonCancel>
          <ButtonSave type="submit" disabled={false}>
            {'SALVAR'}
          </ButtonSave>
        </GridButton>
      </form>
    </Box>
  )
}

export default FormSalesGoals
