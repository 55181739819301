const contasContabeis: Array<[string, string]> = [
  ['11101204', 'Maria da Conceicao C Souza'],
  ['11101205', 'Iran Pimental Marques'],
  ['11101208', 'Patricia Barbosa Rolim'],
  ['11103001', 'Banco Brasil S/A'],
  ['11103104', 'Caixa Economica Federal'],
  ['11103237', 'Banco Bradesco S/A'],
  ['11103341', 'Banco Itau S/A'],
  ['11103422', 'Banco Safra S/A'],
  ['11103637', 'Banco Sofisa S/A'],
  ['11103707', 'Banco Daycoval S/A'],
  ['11103999', 'Banco Transitório'],
  ['11104237', 'Banco Bradesco S/A'],
  ['11110237', 'Banco Bradesco S/A'],
  ['11110341', 'Banco Itaú S/A'],
  ['11110422', 'Banco Safra S/A'],
  ['11110707', 'Banco Daycoval S/A'],
  ['11201001', 'Clientes - Terceiros'],
  ['11201002', 'Clientes - Ligadas'],
  ['11201005', 'Faturamento p/ Entrega Futura'],
  ['11201007', 'Ctas.Receber de Clientes-Divs.'],
  ['11201101', 'Ajuste a Valor Presente'],
  ['11201115', 'Dep de Clientes a Identificar'],
  ['11202001', 'Provisão  Risco de Créditos'],
  ['11202002', 'Prov p/ Risco Crédito - Inded'],
  ['11203001', 'Adiantamento a Fornecedores'],
  ['11203998', 'Outras Contas - Regularizar'],
  ['11210003', 'Outros'],
  ['11210094', 'Cleia Silva - RH'],
  ['11210095', 'João Eduardo S Silva'],
  ['11210102', 'Fama Company Despachos Ltda'],
  ['11210104', 'J de A Bastos Eireli'],
['11210107', 'Lider Processamento de Dados'],
['11210999', 'Valores a Classificar - Rateio'],
['11211001', 'Empréstimos a Funcionários'],
['11211002', 'Empréstimos - Ótica'],
['11211003', 'Empréstimos - Farmácia'],
['11215019', 'Edson Aparecido da Silva'],
['11215020', 'Dabela Despachante'],
['11220001', 'Adiant de Ordenados e Salário'],
['11220002', 'Adiantamento de Férias'],
['11220003', 'Adiantamento de 13º Salário'],
['11220004', 'Arredondamento de Salários'],
['11230001', 'Adiantamento Fornecedores Nac'],
['11230002', 'Adiantamento Fornecedores Imp'],
['11230003', 'Adiantamento Fornecedores-Dvs'],
['11230004', 'AdiantFornec-Estrangeiros'],
['11230005', 'AdiantFrete s/ Merc - Estrang'],
['11230006', 'AdiantCapatazia Merc-Estrang'],
['11231001', 'Everton dos Santos Nunes'],
['11231002', 'Isaac Sverner'],
['11231006', 'Mario Estravate'],
['11231007', 'João Paulo Bottura'],
['11233204', 'Carolina Spindel Sverner'],
['11233205', 'Juliana Spindel Sverner'],
['11233206', 'Daniel Spindel Sverner'],
['11240011', 'ICMS a Compensar - Imobilizado'],
['11240024', 'PIS a Compensar'],
['11240025', 'PIS a Compensar - Importado'],
['11240026', 'PIS a Restituir'],
['11240027', 'COFINS a Compensar'],
['11240028', 'COFINS a Compensar - Importado'],
['11240029', 'COFINS a Restituir'],
['11240040', 'CSLL a Compensar'],
['11240051', 'IRRF a Compensar'],
['11240052', 'IRRF a compensar - Aplicações'],
['11240901', 'Impostos a Recuperar'],
['11250001', 'Matéria - Prima'],
['11250004', 'Matéria Prima -NPT'],
['11250049', 'Mercadorias em Transito'],
['11250050', 'Produtos em Elaboração'],
['11250051', 'Produtos Semi Elaborados'],
['11250054', 'ProdSemi-Elaborados-Bobinas'],
['11250055', 'ProdSemi-Elaborados-Chapas'],
['11250101', 'Produto Acabado Bobina'],
['11250102', 'Produto Acabado Chapa'],
['11250103', 'Produto Acabado Caixa'],
['11250110', 'Estoques Sinistrado'],
['11250251', 'Almoxarifado Manutenção'],
['11250252', 'Almoxarifado Mat Uso e Consumo'],
['11250900', 'Provisão p/Perdas em Estoque'],
['11250980', 'Ajuste a Valor Presente'],
['11250998', 'Valores a Classificar - WIP'],
['11250999', 'Valores a Classificar'],
['11275001', 'Depósito Judicial'],
['11275012', 'Bloqueio Judicial - SAC'],
['11280001', 'Prêmios de Seguros a Apropriar'],
['11280009', 'Outras Despesas Antecipadas'],
['12105001', 'Digibras Industria Brasil'],
['12105006', 'Cemaz Indústria Elet da Amaz'],
['12105011', 'Imovan Indústria da Amazônia'],
['12105012', 'Componel Ind e Com Ltda'],
['12105013', 'Supersonic Transp Armaz Gerais'],
['12105019', 'SRH Participações'],
['12105023', 'Placibras Ind e Com Ltda'],
['12105024', 'Kenlex Participações SA'],
['12105025', 'Santron Indústrias Eletrônicas'],
['12133001', 'Outros Créditos Diversos'],
['12140010', 'Icms a Compensar'],
['12140024', 'PIS a Compensar'],
['12140027', 'COFINS a Compensar'],
['12140060', 'Taxa SUFRAMA a recuperar'],
['12175001', 'Depósito Judicial'],
['12175002', 'Depósito Judicial Recur Trab'],
['12175003', 'Depósito Jud Recurs-Fornecedor'],
['12175005', 'Depósito Judicial - FGTS'],
['12175006', 'Depósito Judicial - INSS'],
['12175012', 'Bloqueio Judicial - SAC'],
['12175016', 'Dep Judicial  ISS - PCE'],
['12175017', 'IPTU/Coleta Lixo/ConsVias Log'],
['12175999', 'Demais Depósitos'],
['12505001', 'Benfeitorias'],
['12508001', 'Prédio Av Acai N 1325 A'],
['12508002', 'Prédio Av Autaz Mirim'],
['12508999', 'Instal.Pred.Terc.Totalm.Deprec'],
['12510001', 'Instalações Industriais'],
['12510999', 'Instalações Inds Total. Deprec'],
['12520001', 'Máq e Equipamentos Industriais'],
['12520999', 'Máqs.e Equip.Inds.Total.Deprec'],
['12525001', 'Equipamentos Proc de Dados'],
['12525999', 'Equip.Proc.Dados-Totalm.Deprec'],
['12533001', 'Equipamentos de Escritório'],
['12533200', 'EquipsEscritTotalmDeprec'],
['12535001', 'Moveis e Utensílios'],
['12535200', 'Moveis e Utens Totalm. Deprec.'],
['12537001', 'Veículos'],
['12537999', 'Veículos Totalmente Deprec.'],
['12539001', 'Instalações em Andamento'],
['12539004', 'Reforma Predial-Infraestrutura'],
['12539005', 'Reforma Maq. Acabamento'],
['12539006', 'Reforma Maq. Onduladeira'],
['12539007', 'Reforma Maq. Papel'],
['12539008', 'Nova Caldeira 4'],
['12539009', 'Construção Novo Galpão'],
['12539010', 'Recuperação Maq.Onduladeira'],
['12539011', 'Recuperação Prédio Onduladeira'],
['12539012', 'Recuperação Moveis/Proc. Dados'],
['12539013', 'Projeto Contra Incêndio'],
['12606001', 'Benfeitorias Prédios Terceiros'],
['12606002', 'Benf. Prédio Av.Buriti N6000'],
['12608001', 'Prédio Av Acai N 1325 A'],
['12608002', 'Prédio Av Autaz Mirim'],
['12608999', 'Inst Pr Proprio Totalm Deprec'],
['12610001', 'Deprec Instalações Industriais'],
['12610999', 'Inst.Industriais Total.Deprec.'],
['12620001', 'Máquinas e Equip Industriais'],
['12620999', 'Máqs.e Equip.Indust.Tot.Deprec'],
['12625001', 'Deprec Equipamentos de Dados'],
['12625999', 'Equip.Proc.Dados-Totalm.Deprec'],
['12633001', 'Equipamentos de Escritório'],
['12633999', 'Equip.Escrit.Total.Depreciados'],
['12635001', 'Moveis e Utensilios'],
['12635999', 'Móveis Utens.Total.Depreciados'],
['12637001', 'Veículos'],
['12637999', 'Veiculos Totalm. Depreciados'],
['12804001', 'Software'],
['12804999', 'Sistema Software Total.Amortz.'],
['12854001', 'Amortizações Software'],
['12854999', 'Amortiz.Sist.Softw.Totalm.Amtz'],
['15201001', 'Contas Movimentos Filiais'],
['15201002', 'Contas Balanço Filiais'],
['15201003', 'Contas Mercadorias Filiais'],
['21101001', 'Empréstimos - Banco Bradesco'],
['21101002', 'Empréstimos - Banco Safra'],
['21101004', 'Empréstimos - Banco Sofisa S A'],
['21101006', 'Empréstimos - Banco Daycoval'],
['21120001', 'Fornecedores - Terceiros'],
['21120002', 'Fornecedores - Exterior'],
['21120003', 'Fornecedores - Ligadas'],
['21120004', 'Fornecedores - Diversos'],
['21120050', 'Fornec - Compra Entrega Futura'],
['21120099', 'Fornecedores - CPA'],
['21120100', 'Fornecedores - Transp Terceiro'],
['21120101', 'Fornecedores - Transp Ligadas'],
['21120999', 'Ajuste a Valor Presente'],
['21130001', 'ISS Retido Sobre Serviços'],
['21130010', 'ICMS a Recolher'],
['21130012', 'ICMS Retido Transportador'],
['21130020', 'Contribuição Financeira - UEA'],
['21130021', 'Contribuição FMPE'],
['21130022', 'FTI a Recolher'],
['21130040', 'IRRF Sobre Folha de Pagamento'],
['21130041', 'IRRF Sobre Serviços'],
['21130042', 'INSS Ret Fonte Sobre Serviços'],
['21130043', 'PIS/COFINS/CSLL Ret s/ Serviço'],
['21130044', 'PIS a Recolher'],
['21130045', 'COFINS a Recolher'],
['21130047', 'Contribuição Social a Recolher'],
['21130048', 'Imposto de Renda a Recolher'],
['21130090', 'Parcelamento RFB'],
['21130092', 'Tributos Federais - Lei 11941'],
['21140001', 'Salários a Pagar'],
['21140003', 'Folha de Pagamento 13º salario'],
['21140008', 'Pagamentos a Compensar'],
['21140009', 'Salários Não Reclamados'],
['21140010', 'Pensão Alimentícia'],
['21140011', 'Salário Família'],
['21140012', 'PLR a Pagar'],
['21140013', 'Salário Maternidade'],
['21140020', 'Provisão p/ 13° Salário'],
['21140021', 'Provisão p/ Férias'],
['21140022', 'Provisão PLR'],
['21140040', 'Processos Trabalhistas a Pagar'],
['21140041', 'Outros Processos Judiciais Pg'],
['21140100', 'INSS a Recolher'],
['21140101', 'FGTS a Recolher'],
['21140102', 'SESI'],
['21140103', 'SENAI'],
['21140190', 'Parcelamento FGTS- Caixa'],
['21140191', 'Parcelamento PERT- CLT'],
['21160001', 'Adiant. Cliente - Terceiros'],
['21170071', 'Capatazia s/ Import. a Pagar'],
['21170081', 'Fretes a Pagar - Nacional'],
['21170082', 'Fretes a Pagar - Internacional'],
['21170090', 'Outras Contas a Pagar'],
['21170091', 'Outras Contas a Pagar - RH'],
['21180001', 'Emprést. Consignado - BV'],
['21180002', 'Emprést. Consignado - Itau'],
['21180041', 'Contribuição Sindical'],
['21180061', 'Mensalidade Sindical'],
['21180062', 'Contribuição de Custeio'],
['21180101', 'Farmácia'],
['21180130', 'Seguro de Vida em Grupo'],
['21180140', 'Comissões a Pagar'],
['21190001', 'Provisão com Terceirizados'],
['22101001', 'Empréstimos - Banco Bradesco'],
['22101002', 'Empréstimos - Banco Safra'],
['22101004', 'Empréstimos - Banco Sofisa SA'],
['22101006', 'Empréstimos - Banco Daycoval'],
['22133002', 'Componel Ind e Com. Ltda'],
['22133004', 'Supersonic Logistica e Transpo'],
['22133005', 'Kenlex Participações S.A'],
['22140100', 'INSS a Recolher - Suspenso'],
['22140101', 'FGTS a Recolher'],
['22140190', 'Parcelamento FGTS - Caixa'],
['22140192', 'Outros Processos Judiciais Pg'],
['22180001', 'Lucros a Pagar'],
['22190001', 'Provisão com Terceirizados'],
['22190002', 'Provisão Contig Trabalhista'],
['22190003', 'Provisão Contig Tributária'],
['23101001', 'Capital Social Subscrito'],
['23407001', 'Reserva Legal'],
['23407002', 'Subvenção ICMS'],
['23407003', 'Subvenção IR'],
['23407004', 'Reserva para Investimento'],
['23901002', 'Resultado do Exercicio'],
['23901003', 'Resultado 1º trimestre'],
['23901004', 'Resultado 2º trimestre'],
['23901005', 'Resultado 3º trimestre'],
['23901006', 'Resultado 4º trimestre'],
['23903001', 'Lucros Acumulados'],
['23905001', 'Prejuizos Acumulados'],
['25201001', 'Contas Movimentos Matriz'],
['25201002', 'Contas Balanço Matriz'],
['25201003', 'Contas Mercadorias Matriz'],
['31001001', 'Venda Fab. Própria - Terceiros'],
['31003001', 'Componel Ind e Comercio Ltda'],
['31023001', 'Venda Materiais Divs - Sucata'],
['31090001', 'Ajuste a Valor Presente'],
['32001001', 'Dev Venda Fab Própria - Terc'],
['32201001', 'ICMS Venda Fab Própria -Terc'],
['32218001', 'ICMS s/ Venda Mat Prima - Terc'],
['32230001', 'ICMS Incentivo Fiscal'],
['32301001', 'PIS s/ Venda Fab Própria -Terc'],
['32306001', 'PIS s/ Venda Produtos p/ ZFM'],
['32323001', 'PIS s/ Venda Mat Divs - Sucata'],
['32401001', 'COFINS s/ Venda Fab Próp -Terc'],
['32406001', 'COFINS s/ Vendas Prod p/ ZFM'],
['32423001', 'COFINS s/ Venda Mat Divs-Sucat'],
['34001001', 'Custos Venda Fab Própria- Terc'],
['35001001', 'Juros Recebidos'],
['35004001', 'Descontos Obtidos'],
['35008001', 'Receitas de Aplic Financeiras'],
['35015001', 'Correção Monetária Ativa'],
['35020001', 'Variação Cambial Ativa'],
['35050001', 'Ajuste a Valor Presente'],
['35060001', 'PIS sobre receitas financeiras'],
['35060002', 'COFINS sobre rec. financeiras'],
['35202001', 'Incentivo Fiscal - IRPJ'],
['35205001', 'Rendas Diversas'],
['35290001', 'Reversão Provisões Operac.'],
['35299001', 'PIS sobre Demais Receitas'],
['35299002', 'COFINS sobre Demais Receitas'],
['41001001', 'Ordenados e Salários'],
['41003001', 'Vencto Diretorias e Conselhos'],
['41005001', 'Serviços Extraordinários'],
['41007001', 'Prémios a Empregados'],
['41009001', 'Gratificação'],
['41015001', 'Serviços Temporários'],
['41016001', 'Serviços Terceirizados'],
['41017001', 'Serviços Téc Prof de Terceiros'],
['41017002', 'Serv Tec de Prof Terceiros -PF'],
['41017003', 'Honorários Advocaticios - PJ'],
['41019002', 'Segurança e Vigilancia PJ'],
['41099002', 'Orden/Salário Aplic no Custo'],
['41101001', 'Encargos com INSS'],
['41102001', 'Encargos com FGTS'],
['41103001', '13º Salário Pago'],
['41104001', 'Provisão 13° Salário'],
['41105001', 'Férias Pagas'],
['41106001', 'Provisão Férias'],
['41107001', 'Encargos e Leis Sociais Pagos'],
['41110001', 'Contribuição Sindical'],
['41198001', 'Prov Contig Trabalhista'],
['41199002', 'Enc Trab Aplic ao Custo.'],
['41201001', 'Contrib Lazer Funcionários'],
['41201002', 'Clube e Recreação Colaborador'],
['41205001', 'Auxílio Sociais'],
['41206001', 'Bolsa de Estudos'],
['41210001', 'Plano de Assistência Médica'],
['41210002', 'Assistência Odontológica'],
['41211001', 'Departamento Médico'],
['41215001', 'Seguro de Vida em Grupo'],
['41216001', 'Uniformes de Trabalho'],
['41222001', 'Transportes de Funcionários'],
['41223001', 'Participação Lucro/Resultado'],
['41223002', 'Provisão Part Lucro/Resultado'],
['41224001', 'Alimentação Funcionários'],
['41225001', 'Vale Transporte'],
['41226001', 'Cesta Básica'],
['41227001', 'Cursos e Treinamentos'],
['41230001', 'Creches'],
['41299002', 'Enc Sociais Aplic em Custo'],
['41302001', 'Material de Higiene e Limpeza'],
['41303001', 'Material de Consumo'],
['41306001', 'Carretos, Fretes e Despachos'],
['41307001', 'Desp. com Armazenagem'],
['41308001', 'Mat e Serviços Seg Trabalho'],
['41310001', 'Telefones'],
['41311001', 'Correios e Malotes'],
['41313001', 'Agua e Esgoto'],
['41313002', 'Tratamento resíduo e efluentes'],
['41315001', 'Energia Eletrica'],
['41318001', 'Seguros Internos - Prédio'],
['41318002', 'Seguros Internos - Estoques'],
['41323001', 'Manut Cons de Maq e Equip'],
['41325001', 'Mudança e Adaptação de Setores'],
['41331001', 'Manutenção Prédios Terceiros'],
['41333001', 'Despesas com ISO'],
['41335001', 'Bens de Natureza Permanente'],
['41337001', 'Manutenção de Veículos'],
['41338001', 'Combustivel de Veiculos'],
['41349001', 'Despesa com Incineração Scrap'],
['41398001', 'Provisão - Perdas em Estoque'],
['41399002', 'Despesas Op Aplicadas em Custo'],
['41401001', 'Material de Expediente'],
['41402001', 'Lanches e Refeições'],
['41403001', 'Despesas Condução'],
['41405001', 'Infrações Fiscais'],
['41406001', 'Taxas Cartoriais'],
['41406002', 'Taxas TCIF'],
['41406003', 'Taxas Administrativas - SEFAZ'],
['41406004', 'Impostos Diversos'],
['41406090', 'Demais Taxas Administrativas'],
['41407001', 'IPTU'],
['41408001', 'Despesas Legais e Judiciais'],
['41409001', 'Mensalidades e Assinaturas'],
['41410001', 'Serv Técn de Terceiros PJ'],
['41410002', 'Serv Técn de Terceiros PF'],
['41410003', 'Honorários Advocatícios PJ'],
['41411001', 'Aluguel de Imóveis'],
['41412001', 'Serviços Terceirizados PJ'],
['41412003', 'Serv Segurança e Vigilância PJ'],
['41412005', 'Serviços Temporários'],
['41413001', 'Alugueis de Equipamentos'],
['41414001', 'Fotos e Xerox'],
['41414002', 'Fotos e Xerox - GL'],
['41415001', 'Material de Informática'],
['41415002', 'Manut e Assinatura de Software'],
['41416001', 'Aluguel de Veículos'],
['41417001', 'Multas Fiscais - Dedutível'],
['41417002', 'Multas Fiscais - Indedutível'],
['41418001', 'Depesas com Copa'],
['41421001', 'Deps Estacionamento e Pedágios'],
['41425001', 'Despesas de Viagens'],
['41426001', 'Doações - Cesta Básica'],
['41426002', 'Contrib. e Doações - Indedutív'],
['41498001', 'Provisão Contig Tributaria'],
['41498003', 'Provisão Desp Op. e Adm.'],
['41499002', 'Demais Desp Op/Adm Aplic Custo'],
['41501001', 'Depreciação de Ativos Fixos'],
['41599002', 'Depreciação Aplicada em Custos'],
['41601001', 'Amortizações do Intágivel'],
['41699002', 'Amortzações Aplicado em Custos'],
['41701001', 'Juros Pagos'],
['41702001', 'IOF'],
['41707001', 'Atualização Monetária Passiva'],
['41710001', 'Variação Cambial Passiva'],
['41710999', 'Result. Variação Camb. Passiva'],
['41713001', 'Tarifas Bancárias'],
['41715001', 'Desconto Concedidos'],
['41730001', 'Ajuste a Valor Presente'],
['41801001', 'Comissões s/ vendas'],
['41807001', 'Autorizadas - Peças'],
['41809001', 'Despesas com Armazenagem'],
['41810001', 'Propaganda e Publicidade'],
['41811001', 'Despesas com Representação'],
['41812001', 'Descontos Concedidos'],
['41815001', 'Brindes'],
['41816001', 'Despesas com Amostras'],
['41823001', 'Fretes Merc/Prod Vendidos'],
['41823002', 'Despesas Acessorias de Export'],
['41892001', 'Provisão p/ Risco de Creditos'],
['41892002', 'Provisão p/ Risco Credito-Ind.'],
['51002001', 'Alienação de Ativos Imobilizados'],
['51004001', 'Baixa de Ativos Imobilizados'],
['71001001', 'Imposto de Renda s/ Lucro'],
['71002001', 'Contribuição Social Sobre o Lucro'],
['81001001', 'Resultado do Período'],
['9000000001', 'Compensação - Receita de Vendas'],
['9000000003', 'Compensação - Estoque'],
['9000000004', 'Compensação - Ordem de Compra'],
['9000000005', 'Compensação - Custo'],
['9000000006', 'Compensação - Custo WIP'],
['9000000011', 'Recebimento Fiscal Transitório'],
['9000000012', 'Compensação - TNP'],
['9000000013', 'Transitório - Carga Financeiro'],
['9000000015', 'Transitório - Carga Estoques']
]

export { contasContabeis }
