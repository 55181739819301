/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Select, SelectChangeEvent, TextField } from '@mui/material'
import { toast } from 'react-toastify'
import { getClient, updateClientSeller } from 'services/customerControl/CustomerControlServices'
import CustomerControlType from 'types/customerControl/CustomerControlType'
import { useFormik } from 'formik'
import { useLocation } from 'react-router-dom'
import UserType from 'types/user/UserType'
import { getUser } from 'services/user/UserService'
import { useMutation, useQuery } from 'react-query'
import { getSearchSeller, getSellers } from 'services/seller/SellerService'

interface ModalView {
  open: boolean
  close: () => void
  data: any
}

const ModalLinkSeller = (props: ModalView) => {
  const { open, close, data } = props
  const [age, setAge] = useState('')
  const [page, setPage] = useState(1)
  const [pageSeller, setPageSeller] = useState(1)
  const [company, setCompany] = useState<CustomerControlType[]>([])
  const [seller, setSeller] = useState<UserType[]>([])
  const [count, setCount] = useState<number>(0)
  const [search, setSearch] = useState('')
  const [sellers, setSellers] = useState<any>()

  const { state }: any = useLocation()

  useEffect(() => {
    getClient({ page })
      .then((data) => setCompany(data.docs))
      .catch(() => toast.error('Erro ao listar clientes'))
  }, [])

  const sellersReq = useQuery(['sellers'],
    async () => {
      // return getSearchSeller(search)
      return getSellers()
    },
    {
      onSuccess: (data) => {
        const dataFilered: any[] = []
        data.forEach(seller => {
          if (seller.user !== null) {
            dataFilered.push(seller)
          }
        })
        setSellers(dataFilered)
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    console.log('vai pesquisar agora', search)
  }, [search])

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(updateClientSeller, {
    onSuccess: data => {
      console.log(data)
      toast.success('Vinculado com sucesso')
      close()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível vincular')
      }
    }
  })

  const {
    setFieldValue,
    errors,
    touched,
    values,
    handleChange,
    handleSubmit
  } = useFormik({
    onSubmit: async (values: any) => {
      if (!values?.seller?.length) {
        toast.error('Selecione o vendedor.')
        return
      }

      console.log('client id', props.data._id)
      console.log('seller id', values.seller)
      mutateUpdate({
        clientId: props.data._id,
        userId: values.seller
      })
    },
    enableReinitialize: true,
    initialValues: {
      company: state?.row?.company ?? '',
      seller: data?.seller?._id ?? ''
    }
  })

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">Vincular Vendedor</DialogTitle>
        <DialogContent>
          <Grid xs={4}><strong>Selecione a empresa</strong></Grid>
          <TextField
            id="input-client"
            label="Empresa"
            variant="standard"
            style={{ marginTop: '20px' }}
            value={props.data.name}
            fullWidth
            disabled
          />
          <div style={{ marginTop: '20px', marginBottom: 40, height: '350px' }}>
            <Grid style={{ marginBottom: '10px' }} xs={4}><strong>Vincule a um vendedor</strong></Grid>
            {!sellersReq.isFetching && <Autocomplete
                noOptionsText='Sem opções'
              disablePortal
              id="combo-box-demo"
              options={sellers.map(seller => (
                { label: seller.user.name, ...seller }
              )) ?? []}
              fullWidth
              // value={sellers?.find(seller => seller?._id === values?.seller)}
              // getOptionLabel={(option) => option?.user?.name}
              defaultValue={sellers?.find(seller => seller?._id === values?.seller)?.user?.name}
              onChange={(e, v: any) => {
                console.log('v.user._id', v?.user?._id)
                setFieldValue('seller', v.user._id)
              }}
              renderInput={(params) => <TextField onChange={(e) => setSearch(e.target.value)} variant='standard' {...params} label="Vendedor" />}
            />}
            {/* <FormControl variant="standard" fullWidth style={{ marginTop: '20px' }}>
              <InputLabel id="demo-simple-select-standard-label">Vendedor</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={values.seller}
                onChange={(event: SelectChangeEvent<string>) => {
                  console.log('selere', event.target.value)
                  setFieldValue('seller', event.target.value)
                }}
                label="Vendedor"
              >
                {seller.map((item: any) => {
                  return (
                    <MenuItem value={item._id}>{item.name}</MenuItem>
                  )
                })}
                <Pagination
                  color="primary"
                  count={count}
                  page={pageSeller}
                  onChange={(event: unknown, newPage: number) => { setPageSeller(newPage) }} />
              </Select>
            </FormControl> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} style={{ borderRadius: '2px', backgroundColor: '#EE207A', color: 'white' }}>Cancelar</Button>
          <Button
            type="submit"
            style={{ borderRadius: '2px', backgroundColor: '#4060E6', color: 'white' }}
          >
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ModalLinkSeller
