import { Box, Card, CardContent, Icon, TextField } from '@mui/material'
import alertSuccess from 'components/Alerts/ModalSucess'
import Button from 'components/form/Button'
import ImportantView from 'components/view/ImportantView'
import { httpClient } from 'config/httpClient'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

function TitleAndContent(props: any) {
  const [loading, setLoading] = React.useState(false)
  const [image, setImage] = React.useState<string | Blob>()
  // const [title, setTitle] = React.useState('')
  const [content, setContent] = React.useState('')
  const [allContent, setAllContent] = React.useState({ text: '', _id: '', url: '' })
  // const [openSnack, setOpenSnack] = React.useState(false)
  // const [messageSnack, setMessageSnack] = React.useState('')

  const navigate = useNavigate()

  useEffect(() => {
    loadContent()
  }, [])

  const loadContent = async () => {
    try {
      const responseAllContent = await httpClient.get('/quemsomos')
      console.log('RESPONSE ALL', responseAllContent.data[0])
      setAllContent(responseAllContent.data[0])
      setImage(responseAllContent.data[0].url)
    } catch (e) {
      console.log('ERRO ALL', e)
    }
  }

  const saveContent = async () => {
    setLoading(true)
    try {
      // const text = title + ': ' + content
      const text = content
      const responseText = await httpClient.put('/quemsomos/' + allContent._id, { text })
      console.log('RESPONSE2', responseText)

      // setMessageSnack('Salvo com sucesso')
      alertSuccess('Salvo com sucesso', 'Dados salvos com sucesso', () => { })
      // setOpenSnack(true)
      setLoading(false)
    } catch (e) {
      console.log('ERRO', e)
      toast.error('Falha ao salvar, revise seus dados')
      // setMessageSnack('Falha ao salvar, revise seus dados')
      // setOpenSnack(true)
      setLoading(false)
    }
  }

  const handleImagePicker = (v: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(v.target.files[0])
    reader.onload = function () {
      const image = new Image()
      image.src = reader.result?.toString() ?? ''

      image.onload = function () {
        // access image size here
        console.log('width', image.width)
        console.log('height', image.height)
        if ((image.width > 1920 || image.width < 540) || (image.height > 1080 || image.height < 540)) {
          toast.warning('Resolução de imagem não permitida')
        } else {
          setImage(result)

          const formData = new FormData()
          formData.append('file', v.target.files[0]!)
          httpClient.patch('/quemsomos/' + allContent._id, formData).then(
            responseFile => {
              console.log('RESPONSE', responseFile)
              // toast.success('Foto anexada com sucesso')
              alertSuccess('Foto anexada com sucesso', 'Dados salvos com sucesso', () => { })
            }
          ).catch(e => {
            toast.error('Não foi possível anexar essa imagem')
          })
        }
      }
      // Aqui temos a sua imagem convertida em string em base64.
      const result: any = reader.result
    }
  }

  return (
    <>
      <Card style={{ ...props.style, width: '90%', marginTop: '20px' }}>
        <CardContent>
          <div>Cria seu conteúdo</div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <TextField
              style={{ width: '60%' }}
              id="standard-basic"
              label="Título"
              variant="standard"
              onChange={e => setTitle(e.target.value)}
            /> */}
            <TextField
              id="standard-multiline-flexible"
              label="Descreva"
              multiline
              autoComplete='off'
              maxRows={4}
              rows={6}
              style={{ marginTop: '20px' }}
              defaultValue={allContent.text}
              onChange={e => setContent(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div style={{ marginTop: '20px', display: !props.editable ? 'none' : 'flex' }}>Adicione uma imagem</div>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: '10px' }}>
            <Icon sx={{ color: 'action.active', mr: 1, my: 0.5 }}>attach_file</Icon>
            <TextField type="file" label="Anexar imagem" id="inputFile"
              autoComplete='off' InputLabelProps={{
              shrink: true
            }} variant="standard" onChange={handleImagePicker} />
          </Box>
          <ImportantView style={{ marginTop: '20px', maxWidth: '550px' }} title="Importante" content="A imagem deve conter uma resolução máxima de 1920x1080 ou mínima de 540x540 px. Tamanho máximo de 4 mb." />
          {image && <img
            style={{
              border: '2px solid #6868684A',
              borderRadius: '6px',
              width: '80%',
              height: 'fit-content'
            }}
            src={String(image)}
          ></img>}
        </CardContent>
      </Card>
      <div style={{ flexDirection: 'row', marginTop: '20px', justifyContent: 'right', display: 'flex' }}>
        <Button mold='red' style={{ marginRight: '10px' }} onClick={() => navigate('/home/company')} loading={loading}>Cancelar</Button>
        <Button mold='primary' onClick={async () => saveContent()} loading={loading}>Salvar</Button>
      </div>
      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
        autoHideDuration={2000}
        message={messageSnack}
        key='top center'
      /> */}
    </>

  )
}

export default TitleAndContent
