import React, { useEffect, useState } from 'react'
import { Stack, Divider, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { Armazem } from 'utils/enums'

const WarehouseCheckboxes = (props) => {
  const [boxCheckbox, setBoxCheckbox] = useState(true)
  const [plateCheckbox, setPlateCheckbox] = useState(true)
  const [paperCheckbox, setPaperCheckbox] = useState(true)

  useEffect(() => {
    const checked: any = []
    boxCheckbox && checked.push(Armazem.CAIXA)
    plateCheckbox && checked.push(Armazem.CHAPA)
    paperCheckbox && checked.push(Armazem.PAPEL)
    props.setCheckboxes(checked)
    props.setPage && props.setPage(0)
  }, [boxCheckbox, plateCheckbox, paperCheckbox])

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={1}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <FormGroup>
        <FormControlLabel
          sx={{ marginRight: 0 }}
          control={<Checkbox checked={boxCheckbox} onChange={() => setBoxCheckbox(!boxCheckbox)} />}
          label={Armazem.CAIXA}
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          sx={{ marginRight: 0 }}
          control={<Checkbox checked={plateCheckbox} onChange={() => setPlateCheckbox(!plateCheckbox)} />}
          label={Armazem.CHAPA}
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          sx={{ marginRight: 0 }}
          control={<Checkbox checked={paperCheckbox} onChange={() => setPaperCheckbox(!paperCheckbox)} />}
          label={Armazem.PAPEL}
        />
      </FormGroup>
    </Stack>
  )
}

export default WarehouseCheckboxes
