/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import LightTooltip from 'components/tooltips/LightTooltip'
import MoreMenu from 'components/view/MoreMenu'
import { ActionButton } from 'components/view/styles/style-more-menu'
import React, { useRef, useState } from 'react'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import { StyledTableCell, StyledTableRow } from '../../table.styles'
import {
  TableHeadPurchaseOrder,
  THeadCell
} from '../../TableHeadPurchaseOrder'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import { Audit } from '../Audit'
import { ModalComponent } from 'components/Modal'

const cells: THeadCell[] = [
  {
    title: 'Código FT',
    align: 'left'
  },
  {
    title: 'Solicitado em',
    align: 'left'
  },
  {
    title: 'Nome da Empresa',
    align: 'left'
  },
  {
    title: 'Lote mínimo',
    align: 'left'
  },
  {
    title: 'Quantidade solicitada',
    align: 'left'
  },
  {
    title: 'Método de entrega',
    align: 'left'
  },
  {
    title: 'Consumo',
    align: 'left'
  },
  {
    title: 'Status',
    align: 'left'
  },
  {
    title: 'Ações',
    align: 'left'
  }
]

export const PilotBatch: React.FC = () => {
  const modalAuditRef = useRef<any>(null)

  const [originalRows, setOriginalRows] = useState<any[]>([])
  const [rows, setRows] = useState<any[]>([{ _id: 'fake-id' }])
  const [page, setPage] = useState(0)

  const handleOpenAudit = () => {
    modalAuditRef?.current?.onOpen()
  }

  const handleCloseAudit = () => {
    modalAuditRef?.current?.onClose()
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHeadPurchaseOrder cells={cells} />

          <TableBody>
            {rows?.slice(page * 5, page * 5 + 5)?.map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell align="left">1234567</StyledTableCell>
                <StyledTableCell align="left">27/06/2022</StyledTableCell>
                <StyledTableCell align="left">
                  Tubokraft da amazônia indústria de tubos LTDA
                </StyledTableCell>
                <StyledTableCell align="left">4320</StyledTableCell>
                <StyledTableCell align="left">4320</StyledTableCell>
                <StyledTableCell align="left">Entrega mensal</StyledTableCell>
                <StyledTableCell align="left">1440</StyledTableCell>
                <StyledTableCell align="left">Novo</StyledTableCell>
                <StyledTableCell align="right">
                  <MoreMenu>
                    <LightTooltip title="Auditar" placement="top">
                      <ActionButton onClick={handleOpenAudit}>
                        <AssignmentTurnedInIcon />
                      </ActionButton>
                    </LightTooltip>
                    <LightTooltip title="Suspender" placement="top">
                      <ActionButton
                        color="#ee207a"
                        onClick={() => console.log('')}
                      >
                        <DeleteOutlineOutlined />
                      </ActionButton>
                    </LightTooltip>
                  </MoreMenu>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5]}
          rowsPerPage={5}
          component="div"
          count={rows.length}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      </TableContainer>

      <ModalComponent
        maxWidth="lg"
        fullWidth
        aria-labelledby="auditar"
        ref={modalAuditRef}
      >
      <Audit onClose={handleCloseAudit} />
      </ModalComponent>
    </>
  )
}
