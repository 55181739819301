import { styled } from '@mui/material/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 7px)',
      right: 'calc(50% + 7px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#4060E6'
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4'
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#e8f5ff',
      borderTopWidth: 3,
      borderRadius: 1
    }
  }))

  export const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : 'white',
      border: theme.palette.mode === 'dark' ? 'none' : '2px solid #4060E6',
      borderRadius: theme.palette.mode === 'dark' ? 'none' : '25px',
      width: theme.palette.mode === 'dark' ? 12 : 14,
      display: 'flex',
      height: theme.palette.mode === 'dark' ? 22 : 14,
      marginTop: theme.palette.mode === 'dark' ? 0 : 4,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#4060E6'
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18
      },
      '& .QontoStepIcon-circle': {
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
      },
      '& .QontoStepIcon-circle-after': {
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: '#4060E6'
      }
    })
  )
