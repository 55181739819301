import { Breadcrumbs, Link, Menu, MenuItem, Typography } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'

function MaterialTaxesHeader(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr repeat(2, minmax(auto, max-content))',
        gap: '15px'
      }}
    >
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="text.primary"
            onClick={() => {
              navigate('/home/managementControl')
            }}
          >
            Gerencial
          </Link>
          <Typography >Valores de materiais</Typography>
        </Breadcrumbs>
        <h4>Valores de materiais</h4>
      </div>

      <Can
        module={MODULES.GERENCIAL}
        permissions={[PERMISSIONS.CREATE]}
      >
      <div>
        <ButtonHeader
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <SettingsOutlinedIcon />
        </ButtonHeader>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={() => { props.setOpenRegisterMaterial() }}>Cadastrar materiais</MenuItem>
          <MenuItem onClick={() => { props.setOpenRegisterTax() }}>Cadastrar taxas</MenuItem>
        </Menu>
      </div>
      </Can>
    </div>
  )
}

export default MaterialTaxesHeader
