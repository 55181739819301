/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dialog, DialogProps, useMediaQuery } from '@mui/material'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

type Props = Partial<Omit<DialogProps, 'open' | 'onClose'>> & {
  children?: React.ReactNode
}

const ModalComponent = forwardRef((props: Props, ref) => {
  const isMobile = useMediaQuery('(max-width:650px)')
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = () => setIsOpen(true)
  const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') =>
    setIsOpen(false)

  useImperativeHandle(ref, () => ({
    onOpen,
    onClose,
    isOpen
  }))

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth='lg'
      sx={{ '& .MuiPaper-root': { borderRadius: 0 } }}
      {...props}
      open={isOpen}
      onClose={onClose}
    />
  )
})

export { ModalComponent }
