/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import moment from 'moment'
import { Form, Formik } from 'formik'
import Button from 'components/form/Button'
import { updateClientCurrentBalance } from 'services/customerControl/CustomerControlServices'
import { toast } from 'react-toastify'
import { useCallback, useEffect, useState } from 'react'
import { httpClient, httpLN } from 'config/httpClient'
import { FormatPrice } from 'utils/FormatPrice'

interface IE {
  codeLn: string
  isActive: boolean
}
interface CreditoLN {
  codigo: string
  credito_disponivel: string
  credito_utilizado: string
  nome: string
}
interface FinancialCredit {
  creditoDisponivel: number
  creditoUtilizado: number
  currentBalance: number
  formattedCreditoDisponivel: string
  formattedCreditoUtilizado: string
  formattedCurrentBalance: string
}

interface Props {
  close: () => void
  closeSuccess: () => void
  data: any
}

export const FinancialAnalysis = ({ data, close, closeSuccess }: Props) => {
  const [financialCredit, setFinancialCredit] = useState<FinancialCredit>()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)

  const getFinancialCredit = useCallback(async () => {
    try {
      const responseIes = await httpClient.get<IE[]>(
        `/company/${data?._id}/ie`
      )

      if (!responseIes?.data?.length) {
        toast.error('Nenhuma inscrição estadual encontrada.')
        close()
        return
      }

      const ies = responseIes.data
      console.log('IES', ies)

      let creditoDisponivel = 0
      let creditoUtilizado = 0

      for (const item of ies) {
        const responseLN = await httpLN.get<{ result: CreditoLN[] }>(
          `/clientes/cliente_credito?codParceiro=${item?.codeLn}`
        )

        responseLN?.data?.result?.forEach((ln) => {
          creditoDisponivel =
            creditoDisponivel + Number(ln?.credito_disponivel || 0)
          creditoUtilizado =
            creditoUtilizado + Number(ln?.credito_utilizado || 0)
        })

        console.log(
          'CREDITO LN',
          responseLN.data,
          creditoDisponivel,
          creditoUtilizado
        )
      }

      setFinancialCredit({
        creditoDisponivel,
        creditoUtilizado,
        formattedCreditoDisponivel: FormatPrice(creditoDisponivel),
        formattedCreditoUtilizado: FormatPrice(creditoUtilizado),
        currentBalance: creditoDisponivel - creditoUtilizado,
        formattedCurrentBalance: FormatPrice(
          creditoDisponivel - creditoUtilizado
        )
      })
      setLoading(false)
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          'Erro! não foi possível fazer a analise de crédito.'
      )
      setLoading(false)
    }
  }, [close])

  useEffect(() => {
    getFinancialCredit()
  }, [getFinancialCredit])
  console.log('Crédito financeiro', data)

  console.log('ANALISE DE CREDITO', financialCredit)

  const handleUpdateCurrentBalance = async () => {
    setLoadingUpdate(true)
    try {
      await httpClient.put(`/company/${data?._id}/current-balance`, {
        currentBalance: financialCredit?.currentBalance
      })
      closeSuccess()
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  }

  return (
    <Stack spacing={3} paddingY={3} paddingX={6}>
      <Box
        borderLeft="20px solid #EEBD20"
        boxShadow="0 3px 6px #00000029"
        minHeight={74}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
      >
        <Typography
          fontSize="20px"
          fontWeight="500"
          component="strong"
          sx={{ paddingY: 1, paddingX: 2 }}
        >
          Crédito financeiro
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          sx={{ paddingY: 1, paddingX: 2 }}
        >
          <PermIdentityIcon />
          <Typography component="span" variant="body1" marginLeft={1}>
            Para: {data?.name}
          </Typography>
        </Box>
      </Box>

      {/* <Formik
        initialValues={{
          _id: data?._id ?? '',
          creditoTotal: '',
          dataLancamento: moment(data?.createdAt).format('DD/MM/YYYY') ?? '',
          jaUtilizado: '',
          currentBalance: ''
        }}
        // validationSchema={validationRepresentativeSchema}
        onSubmit={async (values) => {
          console.log(values)
          // const body = values?.representatives

          const body = { currentBalance: values.currentBalance }

          try {
            await updateClientCurrentBalance(String(values._id), body)

            toast.success('Aprovado com sucesso!')

            close()
          } catch (error: any) {
            console.log('Error: ', error)
            toast.error(error?.response?.data?.message)
          }
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
          </Form>
        )}
      </Formik> */}
      <Box sx={{ flexGrow: 1 }}>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 150,
              width: '100%'
            }}
          >
            <CircularProgress />
            <Typography>Aguarde, carregando...</Typography>
          </Box>
        )}

        {!loading && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                defaultValue={financialCredit?.formattedCreditoDisponivel}
                variant="standard"
                fullWidth
                label="Crédito total"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value=""
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Data de lançamento do crédito"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                defaultValue={financialCredit?.formattedCreditoUtilizado}
                variant="standard"
                fullWidth
                label="Já utilizado"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                defaultValue={financialCredit?.formattedCurrentBalance}
                variant="standard"
                fullWidth
                label="Limite disponível"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>

      <Stack
        direction="row"
        spacing={3}
        paddingTop={4}
        justifyContent="flex-end"
      >
        <Button
          type="button"
          mold="red"
          onClick={close}
          disabled={loading || loadingUpdate}
        >
          Cancelar
        </Button>
        <Button
          mold="primary"
          type="submit"
          disabled={loading || loadingUpdate}
          onClick={handleUpdateCurrentBalance}
        >
          Salvar
        </Button>
      </Stack>
    </Stack>
  )
}
