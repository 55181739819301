import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import { Box } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import { styled } from '@mui/material/styles'
import GenericDialog from 'components/genericDialog/GenericDialog'
import React from 'react'
import MaterialForm from './MaterialForm'
import MaterialTable from './MaterialTable'
import MaterialTaxesHeader from './MaterialTaxesHeader'
import TaxesForm from './TaxesForm'
import TaxesTable from './TaxesTable'

const Tab = styled(TabUnstyled)`
font-family: IBM Plex Sans, sans-serif;
color: black;
cursor: pointer;
font-size: 0.815rem;
font-weight: bold;
background-color: transparent;
width: 100%;
padding: 12px 16px;
margin: 6px 6px;
border: none;
border-radius: 0px;
display: flex;

&:focus {
  border-radius: 0px;
  outline: 2px solid white;
  outline-offset: 2px;
}

&.${tabUnstyledClasses.selected} {
  background-color: #4060E3;
  color: white;
}

&.${buttonUnstyledClasses.disabled} {
  opacity: 0.5;
  cursor: not-allowed;
}
`

const TabPanel = styled(TabPanelUnstyled)`
width: 100%;
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
`

const TabsList = styled(TabsListUnstyled)`
min-width: 320px;
background-color: white;
border-radius: 0px;
margin-bottom: 0px;
display: flex;
align-items: center;
justify-content: center;
align-content: space-between;
`

function MaterialTaxes() {
  const [openRegisterMaterial, setOpenRegisterMaterial] = React.useState(false)
  const [openRegisterTaxes, setOpenRegisterTaxes] = React.useState(false)

  const [openEditMaterial, setOpenEditMaterial] = React.useState(false)
  const [openEditTaxes, setOpenEditTaxes] = React.useState(false)

  const [dataEditMaterial, setDataEditMaterial] = React.useState<any>()
  const [dataEditTaxes, setDataEditTaxes] = React.useState<any>()

  const handleOpenRegisterMaterial = () => {
    setOpenRegisterMaterial(true)
  }

  const handleOpenRegisterTaxes = () => {
    setOpenRegisterTaxes(true)
  }

  return (
    <div>
      <MaterialTaxesHeader setOpenRegisterMaterial={handleOpenRegisterMaterial} setOpenRegisterTax={handleOpenRegisterTaxes} />
      <div style={{ marginTop: 20 }}>
        <TabsUnstyled defaultValue={0}>
          <Box sx={{ width: '300px', marginLeft: '-18px' }}>
            <TabsList>
              <Tab style={{ width: 135 }}>Materiais</Tab>
              <Tab style={{ width: 135 }}>Taxa financeira</Tab>
            </TabsList>
          </Box>
          <TabPanel value={0}><MaterialTable edit={(data) => { setOpenEditMaterial(true); setDataEditMaterial(data) }} refetch={openRegisterMaterial || openEditMaterial} /></TabPanel>
          <TabPanel value={1}><TaxesTable edit={(data) => { setOpenEditTaxes(true); setDataEditTaxes(data) }} refetch={openRegisterTaxes || openEditTaxes} /></TabPanel>
        </TabsUnstyled>
      </div>

      {openRegisterMaterial &&
        <GenericDialog open={openRegisterMaterial} close={() => setOpenRegisterMaterial(false)}
          content={<MaterialForm close={() => setOpenRegisterMaterial(false)} />} />}

      {openRegisterTaxes &&
        <GenericDialog open={openRegisterTaxes} close={() => setOpenRegisterTaxes(false)}
          content={<TaxesForm close={() => setOpenRegisterTaxes(false)} />} />}

      {openEditMaterial &&
        <GenericDialog open={openEditMaterial} close={() => { setOpenEditMaterial(false); setDataEditMaterial(null) }}
          content={<MaterialForm edit={dataEditMaterial} close={() => { setOpenEditMaterial(false); setDataEditMaterial(null) }} />} />}

      {openEditTaxes &&
        <GenericDialog open={openEditTaxes} close={() => { setOpenEditTaxes(false); setDataEditTaxes(null) }}
          content={<TaxesForm edit={dataEditTaxes} close={() => { setOpenEditTaxes(false); setDataEditTaxes(null) }} />} />}
    </div>
  )
}

export default MaterialTaxes
