/* eslint-disable @typescript-eslint/no-unused-vars */
import { httpClient, httpLN } from 'config/httpClient'

// import faturadoLn042022 from './faturadoLN042022.tmp.json'

export const getClientsSalesReport = async (
  page: number,
  month: string,
  year: string,
  search?: string,
  type?: string
) => {
  const { data } = await httpClient.get<any>(
    `/company/sales-report?page=${page + 1}&month=${month}&year=${year}&search=${search}&type=${type}`
  )
  return data
}

export const getCountNewClientsSalesReport = async (
  month: string,
  year: string
) => {
  const { data } = await httpClient.get<any>(
    `/company/sales-report/new-clients?month=${month}&year=${year}`
  )

  return data
}
