import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string
  background?: string
  className?: string
  loading?: boolean
  mold?: 'red' | 'primary'
  style?: React.CSSProperties
  type?: 'submit' | 'reset' | 'button' | undefined
}

const CustomButton: React.FC<Props> = ({
  color,
  style,
  mold,
  loading,
  children,
  background,
  type,
  ...rest
}) => {
  const getBackgroundColor = (mold?: string) => {
    const backgrounds = {
      red: '#ee207a',
      primary: '#4060E6',
      default: '#E8E8E8'
    }

    return mold ? backgrounds[mold] : backgrounds.default
  }

  return (
    <button
      style={{
        color: color ?? (mold && '#fff'),
        border: 'none',
        padding: '7px 20px',
        borderRadius: '2px',
        backgroundColor: mold ? getBackgroundColor(mold) : background ?? '#E8E8E8',
        transition: 'all 0.3s',
        ...style
      }}
      type={type}
      {...rest}
    >
      {loading
        ? (
        <CircularProgress
          style={{ marginBottom: '-4px' }}
          color="inherit"
          size={15}
        />
          )
        : children}
    </button>
  )
}

export default CustomButton
