/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AssignmentTurnedIn, KeyboardArrowRight } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from 'components/form/Button'
import { httpClient } from 'config/httpClient'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import boxImg from 'assets/img/formbox.png'
import plateImg from 'assets/img/formplate.png'
import coilImg from 'assets/img/formpaper.png'
import pdfImg from 'assets/svgs/icon-pdf.svg'
import Avatar from '@mui/material/Avatar/Avatar'

function RowItem({
  title,
  value,
  ...rest
}: {
  title: string
  value: string | number
}) {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Typography
        variant="body2"
        component="strong"
        fontWeight="bold"
        sx={{ minWidth: 200 }}
      >
        {title}
      </Typography>
      <Typography variant="body2" component="span" textAlign="right">
        {value}
      </Typography>
    </Stack>
  )
}

const getImage: any = {
  caixa: boxImg,
  bobina: coilImg,
  chapa: plateImg
}

const color = {
  'AMOSTRA ENVIADA': '#00B4E6',
  APROVADO: '#00e63a',
  REPROVADO: '#EE207A',
  ENTREGUE: '#6B49E8',
  AMOSTRA: '#6B49E8',
  DESENVOLVIMENTO: '#6B49E8',
  'EM PRODUÇÃO': '#6B49E8',
  CANCELADO: '#EE207A'
}

interface Props {
  open: boolean
  onClose: () => void
  ft: any
}

export const ModalFtSampleClient: React.FC<Props> = ({ open, onClose, ft }) => {
  const [samples, setSamples] = useState<any[]>([])

  const inputImage = useRef<HTMLInputElement>(null)

  const [sample, setSample] = useState<any | null>(null)
  const [openSample, setOpenSample] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [selectImage, setSelectImage] = useState<File>()
  const [selectImageType, setSelectImageType] = useState('')
  const [observation, setObservation] = useState('')

  const { status, refetch, isFetching } = useQuery(
    ['ft-sample-client'],
    async () => {
      return httpClient.get(`/ft/amostras/${ft?._id}`)
    },
    {
      onSuccess: (response) => {
        console.log('sample by preft', response)
        setSamples(response.data)
      },
      refetchOnWindowFocus: false
    }
  )

  const handleOpenSample = (item: any) => {
    setSample(item)
    setOpenSample(true)
  }

  const handleCloseSample = () => {
    setIsLoading(false)
    setSample(null)
    setOpenSample(false)
    setSelectImage(undefined)
    inputImage?.current?.files ? (inputImage.current.value = '') : ''
    setSelectImageType('')
  }

  const handleApprove = async () => {
    setIsLoading(true)

    try {
      if (selectImage) {
        const formData = new FormData()
        formData.append('file', selectImage)
        await httpClient.put(`ft/amostra/attach-file/${sample?._id}`, formData)
      }

      await httpClient.put(`/ft/amostra/${sample?._id}`, {
        comment: observation ?? '',
        status: 'APROVADO'
      })

      toast.success(
        `${
          sample?.draw
            ? 'Desenho aprovado com sucesso'
            : 'Amostra aprovada com sucesso!'
        }`
      )

      refetch()

      handleCloseSample()
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleReprove = async () => {
    // if (!observation?.length) {
    //   toast.warn('Informe no campo de observações o motivo da reprovação.')
    //   return
    // }
    setIsLoading(true)

    try {
      await httpClient.put(`/ft/amostra/${sample?._id}`, {
        comment: observation ?? '',
        status: 'REPROVADO'
      })

      refetch()

      handleCloseSample()
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant="h6" component="span" sx={{ marginRight: 2 }}>
            Amostra ou Desenho
          </Typography>
          <Typography variant="body2" component="span">
            Auditar amostra ou desenho.
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ minHeight: 300 }}>
          <Stack sx={{ marginBottom: 3 }}>
            <Typography variant="body2" component="span" fontWeight="bold">
              FT {ft?.itemReference}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              fontWeight="bold"
              color={color[ft?.status?.toUpperCase()]}
            >
              {ft?.status?.toUpperCase()}
            </Typography>
          </Stack>

          {(status === 'loading' || isFetching) && (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 4 }}
            >
              <CircularProgress size={24} />
            </Stack>
          )}

          {status !== 'loading' && !isFetching && !samples.length && (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 4 }}
            >
              <Typography variant="body2">
                Nenhuma amostra encontrada.
              </Typography>
            </Stack>
          )}

          {status !== 'loading' &&
            !isFetching &&
            samples.map((row) => (
              <Box key={row?._id} sx={{ paddingBottom: 2 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => handleOpenSample(row)}
                  sx={{
                    borderRadius: 1,
                    padding: 1,
                    border: '2px solid #00000029',
                    cursor: 'pointer'
                  }}
                >
                  <Avatar
                    alt={row?._id}
                    src={getImage[ft?.preFT?.type?.toLowerCase()]}
                    sx={{
                      borderRadius: 1,
                      marginRight: 2,
                      height: 60,
                      width: 60
                    }}
                  />

                  <Stack>
                    <Typography variant="body2" component="span">
                      {row?._id}
                    </Typography>
                    <Typography variant="body2" component="span">
                      {row?.draw ? 'Desenho' : 'Amostra'}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      color={color[row?.status]}
                      fontWeight="bold"
                    >
                      {row?.status}
                    </Typography>
                  </Stack>

                  <Box sx={{ marginLeft: 'auto' }}>
                    <Button mold="primary">
                      Auditar {row?.draw ? 'desenho' : 'amostra'}
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
        </DialogContent>
      </Dialog>

      {sample?._id && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openSample}
          // onClose={handleCloseSample}
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              alt={ft?._id}
              src={getImage[ft?.preFT?.type?.toLowerCase()]}
              sx={{
                borderRadius: 1,
                marginRight: 2,
                height: 60,
                width: 60
              }}
            />
            <Typography variant="h6" component="span" sx={{ marginRight: 2 }}>
              Auditar {sample?.draw ? 'desenho' : 'amostra'} {'- '}
              <Typography variant="body1" component="span">
                {sample?._id}
              </Typography>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" component="span">
              Você precisa aprovar o desenho ou a amostra física solicitada para
              o projeto ser ativado.
            </Typography>
            {sample?.draw && (
              <Stack spacing={2} alignItems="center" sx={{ paddingY: 2 }}>
                {sample?.url?.includes('.pdf') && (
                  <Stack alignItems="center" spacing={1}>
                    <Avatar
                      alt={sample?._id}
                      src={pdfImg}
                      sx={{
                        width: 40,
                        height: 40,
                        objectFit: 'contain',
                        borderRadius: 1
                      }}
                    />

                    <Typography
                      variant="body2"
                      component="a"
                      href={sample?.url}
                      target="_blank"
                      color="primary"
                    >
                      Visualizar arquivo
                    </Typography>
                  </Stack>
                )}

                {!sample?.url?.includes('.pdf') && (
                  <Avatar
                    alt={sample?._id}
                    src={sample?.url}
                    sx={{
                      width: 300,
                      height: 350,
                      objectFit: 'contain',
                      borderRadius: 1
                    }}
                  />
                )}

                <TextField
                  fullWidth
                  name="comment"
                  label="Observações"
                  placeholder="Digite uma observação"
                  multiline
                  minRows={4}
                  variant="outlined"
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                />
              </Stack>
            )}

            {!sample?.draw && (
              <Stack
                spacing={2}
                alignItems="center"
                sx={{ paddingY: 2, width: '100%' }}
              >
                {selectImage && (
                  <Avatar
                    alt={sample?._id}
                    src={
                      selectImageType?.includes('pdf')
                        ? pdfImg
                        : URL.createObjectURL(selectImage)
                    }
                    sx={{
                      width: 300,
                      height: 350,
                      objectFit: 'contain',
                      borderRadius: 1
                    }}
                  />
                )}
                {!selectImage && (
                  <>
                    {sample?.url?.includes('.pdf') && (
                      <Stack alignItems="center" spacing={1}>
                        <Avatar
                          alt={sample?._id}
                          src={pdfImg}
                          sx={{
                            width: 40,
                            height: 40,
                            objectFit: 'contain',
                            borderRadius: 1
                          }}
                        />

                        <Typography
                          variant="body2"
                          component="a"
                          href={sample?.url}
                          target="_blank"
                          color="primary"
                        >
                          Visualizar arquivo
                        </Typography>
                      </Stack>
                    )}

                    {!sample?.url?.includes('.pdf') && (
                      <Avatar
                        alt={sample?._id}
                        src={sample?.url}
                        sx={{
                          width: 300,
                          height: 350,
                          objectFit: 'contain',
                          borderRadius: 1
                        }}
                      />
                    )}
                  </>
                )}

                {sample?.status?.toUpperCase() !== 'APROVADO' && (
                  <Stack
                    direction="row"
                    spacing={3}
                    justifyContent="center"
                    sx={{ width: '100%' }}
                  >
                    <input
                      ref={inputImage}
                      type="file"
                      id="id-img-sample"
                      accept="image/*"
                      // accept="image/*,application/pdf"
                      style={{ visibility: 'hidden', display: 'none' }}
                      onChange={(e) => {
                        setSelectImage(e.target?.files?.[0])
                        setSelectImageType(e.target?.files?.[0]?.type ?? '')
                      }}
                    />
                    <label
                      htmlFor="id-img-sample"
                      style={{
                        width: '100%',
                        height: 40,
                        cursor: 'pointer',
                        maxWidth: 140,
                        marginLeft: '0'
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#1ab1b0',
                          color: '#FFFFFF',
                          borderRadius: '2px',
                          paddingY: '4px',
                          paddingX: 2,
                          fontSize: 14,
                          maxWidth: 140,
                          height: 40,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Anexar arquivo
                      </Box>
                    </label>

                    {selectImage && (
                      <Button
                        mold="red"
                        onClick={() => {
                          setSelectImage(undefined)
                          inputImage?.current?.files
                            ? (inputImage.current.value = '')
                            : ''
                          setSelectImageType('')
                        }}
                      >
                        Remover foto
                      </Button>
                    )}
                  </Stack>
                )}

                <TextField
                  fullWidth
                  name="comment"
                  label="Observações"
                  placeholder="Digite uma observação"
                  multiline
                  minRows={4}
                  variant="outlined"
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                />
              </Stack>
            )}

            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={3}
              sx={{
                marginTop: 3,
                // borderTop: '2px solid #00000029',
                paddingTop: 3,
                width: '100%'
              }}
            >
              <Box sx={{ flex: 1, marginRight: 'auto' }}>
                <Button disabled={isLoading} onClick={handleCloseSample}>
                  Voltar
                </Button>
              </Box>
              <Button
                mold="red"
                disabled={isLoading
                  // || sample?.status?.toUpperCase() === 'APROVADO'
                }
                onClick={handleReprove}
              >
                Reprovar
              </Button>
              <Button
                mold="primary"
                disabled={isLoading || (!sample?.draw && !selectImage && !sample?.url)
                // || sample?.status?.toUpperCase() === 'APROVADO'
                }
                onClick={handleApprove}
              >
                Aprovar
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
