/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField
} from '@mui/material';
import moment from 'moment';
import {
  ButtonRow,
  CardContentChieldren,
  CardFather,
  DialogStyle,
  DivAlingCard,
  DivAlingItensCard,
  RetangleView
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import { useFormik } from 'formik';

import IconAndTitleView from 'components/view/IconAndTitleView';
import ModalHead from './ModalHead';
import { useQuery } from 'react-query';
import { httpClient } from 'config/httpClient';
import { Accordion } from '../components/Accordion';
import Button from 'components/form/Button';

interface Props {
  open: boolean
  close: () => void
  data?: any
  bookFinished?: boolean
}

const ToolingBudget: React.FC<Props> = (props) => {
  const { bookFinished } = props
  const closeModal = () => props.close();

  const { data, status } = useQuery(
    ['modal-booking-tooling-budget', props?.data?.ft?._id],
    async () =>
      await httpClient.get<any[]>(`/budgets-tool/ft/${props?.data?.ft?._id}`),
    {
      refetchOnWindowFocus: false
    }
  );

  console.log(data?.data);

  return (
    <>
      <DialogStyle
        open={props.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHead
          title="Orçamento ferramental"
          idPreFT={props?.data?.ft?.preFT?._id}
        />

        {status === 'loading' && (
          <Box
            display="flex"
            alignItems="center"
            height={200}
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress size={24} />
            <span>Carregando...</span>
          </Box>
        )}

        <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <>
            <Grid container spacing={2}>
              {status !== 'loading' &&
                data?.data?.map((item, index) => (
                  <React.Fragment key={item?._id}>
                    <Grid item xs={12} md={8}>
                      <Accordion
                        title={`Orçamento ${index + 1}`}
                        status={item?.status}
                      >
                        <Stack alignItems="flex-start">
                          <a href={item?.url} target="_blank">
                            <Button type="button" mold="primary">
                              Visualizar arquivo
                            </Button>
                          </a>
                          <TextField
                            value={item?.justification}
                            multiline
                            minRows={3}
                            disabled
                            sx={{ marginTop: 2, width: '100%' }}
                          />
                        </Stack>
                      </Accordion>
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>

            <ButtonRow
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                marginTop: 30
              }}
            >
              <Button
                mold="primary"
                onClick={() => {
                  props.close();
                }}
              >
                Voltar
              </Button>
            </ButtonRow>
          </>
        </DialogContent>
      </DialogStyle>
    </>
  );
};

export default ToolingBudget;
