/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Add,
  ArrowBack,
  ExpandMore
} from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableContainer, TableHead, TextField } from '@mui/material'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { Can } from 'components/Permission/Can'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import { WrapperAccordion } from 'pages/reports/requestForProposals/components/ViewProposal/styles'
import React, { useState } from 'react'

function BudgetConfigView(props: any) {
  const containerStyles = {
    background: '#599ba3'
  }
  const [open, setOpen] = useState<boolean>(false)
  const initialValues = {
    _id: props?.view?._id,
    month: props?.view?.month || 0,
    year: props?.view?.year || 0,
    refuse: props?.view?.refuse || 0,
    module: props?.view?.module || 5,
    categories: props?.view?.categories ?? []
  }
  const getModuleLabel = (module) => {
    switch (module) {
      case 1:
        return 'Administrativo'
      case 2:
        return 'Materiais de PO'
      case 3:
        return 'Materiais de Papel'
      case 4:
        return 'Insumos de Papel'
      default:
        return 'Módulo Desconhecido'
    }
  }
  const closeModal = () => setOpen(false)

  return (
    <div>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <LightTooltip title='Voltar' placement='top' onClick={() => { props.close() }}>
        <ButtonHeader style={{ justifySelf: 'start' }}>
          <ArrowBack />
        </ButtonHeader>
      </LightTooltip>
      <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>Categoria</div>
    </div>

    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px', flex: 1 }}>
      <TableContainer elevation={0} component={Paper} style={ containerStyles }>
        <Table>
          <TableHead>
            <StyledTableRow style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr'
              }}>
              <StyledTableCell align="center">Tipo</StyledTableCell>
              <StyledTableCell align="center">Índice de Refugo</StyledTableCell>
              <StyledTableCell align='center'>Mês/Ano</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
              <StyledTableRow
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                background: '#c1eff4'
              }}
              >
                <StyledTableCell align="center">{getModuleLabel(initialValues.module)}</StyledTableCell>
                <StyledTableCell align="center">{initialValues.refuse}</StyledTableCell>
                <StyledTableCell align="center">{initialValues.month}/{initialValues.year}</StyledTableCell>
              </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer elevation={0} component={Paper} style={ containerStyles }>
        <Table>
          <TableHead>
            <StyledTableRow style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr'
              }}>
              <StyledTableCell align="center">Categoria</StyledTableCell>
              <StyledTableCell align="center">Previsão de Inflação</StyledTableCell>
              <StyledTableCell align='center'>Valor de Lançamento</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
              <StyledTableRow
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                background: '#d8ffff'
              }}
              >
                <StyledTableCell align="center">{initialValues.categories[0].type}</StyledTableCell>
                <StyledTableCell align="center">{initialValues.categories[0].percentage}</StyledTableCell>
                <StyledTableCell align="center">{initialValues.categories[0].launchValue}</StyledTableCell>
              </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer elevation={0} component={Paper} style={ containerStyles }>
        <Table>
          <TableHead>
            <StyledTableRow style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr'
              }}>
              <StyledTableCell>Contas</StyledTableCell>
              <StyledTableCell>Descrição</StyledTableCell>
              <StyledTableCell align='center'>Porcentagem (%)</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {initialValues.categories[0].items.map((row, i) => (
              <StyledTableRow
              key={i}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                background: '#c1eff4'
              }}
              >
                <StyledTableCell>{row.account}</StyledTableCell>
                <StyledTableCell>{row.describeAccount}</StyledTableCell>
                <StyledTableCell align="center">{row.launchPercentage}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </div>
  )
}

export default BudgetConfigView
