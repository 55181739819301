import React from 'react'
import { styled } from '@mui/system'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PerCoilsAndSimplex from './PerCoilsAndSimplex'
import PerPlates from './PerPlates'
import PerBoxes from './PerBoxes'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportRH } from 'services/report/ReportRH'
import { getReportBilling } from 'services/report/ReportBilling'
// import PerDay from './PerDay'
// import PerClient from './PerClient'

const Tab = styled(TabUnstyled)`
    font-family: IBM Plex Sans, sans-serif;
    color: black;
    cursor: pointer;
    font-size: 0.815rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 0px;
    display: flex;

    &:focus {
      border-radius: 0px;
      outline: 2px solid white;
      outline-offset: 2px;
    }

    &.${tabUnstyledClasses.selected} {
      background-color: #4060E3;
      color: white;
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `

const TabPanel = styled(TabPanelUnstyled)`
    /* width: 50%; */
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `

const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: white;
    border-radius: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `

export default function SubtotalView(props: any) {
  const navigate = useNavigate()

  console.log('props.date', props.date)
  // requisição para pegar os dias úteis
  const diasReq = useQuery(['report-rh', props.date],
    async () => {
      const data = await getReportRH(props.date)
      if (data) {
        return {
          automaticWorksDays: data[0]?.automaticWorksDays,
          days: data[0]?.days,
          worksDays: data[0]?.worksDays
        }
      } else {
        return {}
      }
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 5
    }
  )

  const previsionReq = useQuery(['sales-report', moment(props.date).format('M'), moment(props.date).format('y')],
    async () => {
      return getReportBilling(moment(props.date).format('M'), moment(props.date).format('y'))
    },
    {
      onSuccess: (data) => {
        console.log('data =>', data)
      },
      onError: (error) => {
        console.log(
          'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
          error
        )
      },
      refetchOnWindowFocus: false
    }
  )

  console.log({ previsionReq })
  console.log({ diasReq })

  return (
    <>
      <TabsUnstyled defaultValue={0}>
        <Box sx={{ width: '357px' }}>
          <TabsList>
            <Tab sx={{ whiteSpace: 'nowrap' }}>Papeis</Tab>
            <Tab>Chapas</Tab>
            <Tab>Caixas</Tab>
          </TabsList>
        </Box>
        <TabPanel value={0}><PerCoilsAndSimplex date={props.date} days={diasReq} prevision={previsionReq} /></TabPanel>
        <TabPanel value={1}><PerPlates date={props.date} days={diasReq} prevision={previsionReq} /></TabPanel>
        <TabPanel value={2}><PerBoxes date={props.date} days={diasReq} prevision={previsionReq} /></TabPanel>
      </TabsUnstyled>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '30px'
        }}
      >
        <Button
          style={{
            backgroundColor: '#4060E6',
            borderRadius: '2px',
            width: '122px',
            color: 'white'
          }}
          onClick={() => navigate('/home/report')}
        >
          Voltar
        </Button>
      </div>
    </>
  )
}
