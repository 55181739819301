import { PortfolioPaperType } from 'types/portfolio/PortfolioPaperType'
import { PortfolioPlateType } from 'types/portfolio/PortfolioPlateType'
import { httpClient } from '../../config/httpClient'

export const getPortfolioPapers = async () => {
  const { data } = await httpClient.get<PortfolioPaperType[]>('/products/papers')
  return data
}

export const getPortfolioPlates = async () => {
  const { data } = await httpClient.get<PortfolioPlateType[]>('/products/plates')
  return data
}

export const getPortfolioBoxes = async () => {
  const { data } = await httpClient.get<PortfolioPlateType[]>('/products/boxes')
  return data
}

export const getPortfolioPapersForm = async () => {
  const { data } = await httpClient.get<any[]>('/products/papers/form')
  return data
}

export const getPortfolioPlatesForm = async () => {
  const { data } = await httpClient.get<any[]>('/products/plates/form')
  return data
}

export const getPortfolioBoxesForm = async () => {
  const { data } = await httpClient.get<any[]>('/products/boxes/form')
  return data
}

export const postPortfolioBoxesForm = async (box: any) => {
  const { data } = await httpClient.post<any>('/products/boxes/form', box)
  return data
}

export const postPortfolioBoxes = async (box: any) => {
  const { data } = await httpClient.post<any>('/products/boxes', box)
  return data
}

export const putPortfolioBoxesForm = async (box: any) => {
  const { data } = await httpClient.put<any>(`/products/boxes/form/${box._id}`, box)
  return data
}

export const putPortfolioBoxes = async (box: any) => {
  const { data } = await httpClient.put<any>(`/products/boxes/${box._id}`, box)
  return data
}

export const putStatusPortfolioBoxes = async (id: string) => {
  const { data } = await httpClient.put(`/products/boxes/status/${id}`)
  return data
}

export const putStatusPortfolioBoxesForm = async (id: string) => {
  const { data } = await httpClient.delete(`/products/boxes/form/${id}`)
  return data
}

export const getCardboard = async (plateName: string) => {
  const { data } = await httpClient.get(`/products/plates/compositions/${plateName}`)
  return data
}

export const getCompositions = async () => {
  const { data } = await httpClient.get<any[]>('/products/trimbox/compositions')
  return data
}

export const getFechamentos = async () => {
  const { data } = await httpClient.get<any[]>('/products/trimbox/fechamentos')
  return data
}

export const postFormularioChapa = async (body: any) => {
  const { data } = await httpClient.post<any>('/products/formularios/chapa', body)
  return data
}

export const putFormularioChapa = async (id: string, body: any) => {
  const { data } = await httpClient.put<any>(`/products/formularios/chapa/${id}`, body)
  return data
}

export const getAllFormulariosChapa = async () => {
  const { data } = await httpClient.get<any[]>('/products/formularios/chapa')
  return data
}

export const postFormularioCaixa = async (body: any) => {
  const { data } = await httpClient.post<any>('/products/formularios/caixa', body)
  return data
}

export const putFormularioCaixa = async (body: any) => {
  const { data } = await httpClient.put<any>(`/products/formularios/caixa/${body._id}`, body)
  return data
}

export const getAllFormulariosCaixa = async () => {
  const { data } = await httpClient.get<any[]>('/products/formularios/caixa')
  return data
}

export const putStatusFormulariosCaixa = async (id: string) => {
  const { data } = await httpClient.delete(`/products/formularios/caixa/${id}`)
  return data
}

export const getAllCodBoxes = async () => {
  const { data } = await httpClient.get<any[]>('/products/typebox')
  return data
}
