import axios from 'axios'
import EventBus from 'utils/EventBus'
import { store } from '../core/store'

// Para usar outro endpoint crie um arquivo .env com a variável REACT_APP_productionUrl

const productionUrl = process.env.REACT_APP_productionUrl ?? 'https://pce-general.herokuapp.com/api/v1'
const apiLN = process.env.REACT_APP_LN_URL ?? 'https://pce-integration.herokuapp.com/api/'
export const apiTrimbox = process.env.REACT_APP_trimboxUrl ?? 'http://200.174.24.67:30003/api/'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

export const httpClient = axios.create({
  baseURL: productionUrl
})

export const httpLN = axios.create({
  baseURL: apiLN
})

export const httpTrimbox = axios.create({
  baseURL: apiTrimbox
})

// Interceptor de solicitação
httpClient.interceptors.request.use(function (config) {
  const state = store.getState()
  config.headers = {}
  config.headers.Authorization = `Bearer ${state.login.loginData.accessToken}`
  return config
}, async function (error) {
  return Promise.reject(error)
})

// Interceptor de resposta
httpClient.interceptors.response.use(function (config) {
  return config
}, async function (error) {
  if (error.response.status === 403 || error.response.status === 401) {
    EventBus.dispatch('logout', null)
  }
  return Promise.reject(error)
})
