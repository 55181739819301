import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import CustomButton from 'components/buttons/CustomButton'
import FormPaperType from './FormPaperType'
import { getPortfolioPapers } from 'services/portfolio/PortfolioService'
import { PortfolioPaperType } from 'types/portfolio/PortfolioPaperType'
import { StyledTableCell, StyledTableRow } from '../components/table.styles'
import MoreMenu from 'components/view/MoreMenu'
import { httpClient } from 'config/httpClient'
import { useQuery } from 'react-query'
import FormEditPaperType from './FormEditPaperType'

interface modalView {
  open: boolean
  close: () => void
}

const ModalPaperType = (props: modalView) => {
  const { open, close } = props

  const [originalRows, setOriginalRows] = useState<PortfolioPaperType[]>([])
  const [rows, setRows] = useState<PortfolioPaperType[]>([])

  const [page, setPage] = useState(0)

  const [openModal, setOpenModal] = useState(false)

  const [openModalEdit, setOpenModalEdit] = useState<any | null>(null)

  const { data, status, refetch, isFetching } = useQuery(
    'papers-modal',
    getPortfolioPapers,
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    setPage(0)
    setOriginalRows(data ?? [])
    setRows(data ?? [])
  }, [data?.length, isFetching])

  const closeModal = () => close()

  const changeSearch = (search: string) => {
    if (search.length === 0) {
      setPage(0)
      setRows(originalRows)
    } else {
      const searchResult = originalRows.filter((row) =>
        row.name.toUpperCase().includes(search.toUpperCase())
      )
      setRows(searchResult)
    }
  }

  const handleRefetch = () => {
    setPage(0)
    refetch()
  }

  const handleStatus = (id: string) => {
    httpClient
      .patch(`products/papers/status/${id}`)
      .then(() => {
        handleRefetch()
      })
      .catch((error) => console.log(error))
  }

  const handleEdit = (row: any) => {
    setOpenModalEdit(row)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tipo de papel
          <Grid
            item
            xs={12}
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '15px'
            }}
          >
            <Button
              variant="outlined"
              style={{ height: '30px' }}
              onClick={() => setOpenModal(true)}
            >
              + Novo papel
            </Button>

            <FormControl variant="standard">
              <InputLabel htmlFor="field-search">Pesquisar</InputLabel>
              <Input
                id="field-search"
                defaultValue=""
                type="search"
                onChange={(event) => changeSearch(event.target.value.trim())}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchOutlined />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {status !== 'loading' && (
            <TableContainer style={{ marginTop: '30px' }}>
              <Table sx={{ minWidth: '100%' }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sized</StyledTableCell>
                    <StyledTableCell align="left">
                      Tipo de papel
                    </StyledTableCell>
                    <StyledTableCell align="left">Gramatura</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="right">Ações</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.slice(page * 5, page * 5 + 5)?.map((row) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {row.withsized.checked && 'Com sized'}
                        {row.withoutsized.checked && 'Sem sized'}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.withsized.checked && (
                          <>
                            <Typography variant="body2" component="span">
                              Base: {row.grammage.value}
                            </Typography>
                          </>
                        )}
                        {row.withoutsized.checked && (
                          <>
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{ marginRight: 1 }}
                            >
                              Mín: {row.grammage.min}
                            </Typography>
                            <Typography variant="body2" component="span">
                              Máx: {row.grammage.max}
                            </Typography>
                          </>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <FormGroup>
                          <FormControlLabel
                            control={<Switch checked={row.isActive} />}
                            label={row.isActive ? 'Ativo' : 'Inativo'}
                            onChange={() => handleStatus(row._id)}
                          />
                        </FormGroup>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <MoreMenu
                          edit={() => handleEdit(row)}
                          // delete={() => handleStatus(row._id)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5]}
                rowsPerPage={5}
                component="div"
                count={rows.length}
                page={page}
                onPageChange={(event: unknown, newPage: number) => {
                  setPage(newPage)
                }}
              />
            </TableContainer>
          )}

          {status === 'loading' && (
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={200}
                        width="100%"
                      >
                        <CircularProgress />
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <CustomButton
            mold="primary"
            type="button"
            onClick={closeModal}
            autoFocus
          >
            Voltar
          </CustomButton>
        </DialogActions>
      </Dialog>

      <FormPaperType open={openModal} close={() => setOpenModal(false)} />

      {openModalEdit?._id && (
        <FormEditPaperType
          open={!!openModalEdit?._id}
          data={openModalEdit}
          close={() => setOpenModalEdit(null)}
          refetch={handleRefetch}
        />
      )}
    </>
  )
}

export default ModalPaperType
