import { AddOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import 'react-drop-zone/dist/styles.css'
import { useQuery } from 'react-query'
import { getArts } from 'services/ft/FTService'
import GenericDialog from 'components/genericDialog/GenericDialog'
import SampleShowContent from '../components/SampleShowContent'
import ArtSampleForm from '../components/ArtSampleForm'

function ArtSampleStep(props: any) {
  const [expandedElement, setExpandedElement] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [openFormDialog, setOpenFormDialog] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const handleChangeExpandPropose = (idPropose) => {
    console.log('propose', idPropose)
    if (expandedElement === idPropose) {
      idPropose = -1
    }
    setExpandedElement(idPropose)
  }

  const arts = useQuery(
    'ft-art',
    async () => {
      return getArts(props.ft._id)
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  if (arts.isFetching) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div>
      <Box>
        <div style={{ width: '100%', marginTop: '30px' }}>
          {arts.data?.map((amostra, i) => (
            <div style={{ marginTop: '5px' }}>
              <Accordion
                expanded={expandedElement === i}
                onChange={() => handleChangeExpandPropose(i)}
                style={{ width: '100%' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panelbh-header"
                >
                  <Typography sx={{ width: '100%', flexShrink: 0 }}>
                    <div style={{ fontWeight: 'bold', marginRight: 10 }}>
                      Arte para aprovação do cliente
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      maxWidth: 700,
                      width: '100%',
                      gap: 24
                    }}
                  >
                    <Box>
                      {amostra.url?.includes('.pdf')
? (
                        <Box
                          component="a"
                          href={amostra.url}
                          target="_blank"
                          sx={{ textDecoration: 'none' }}
                        >
                          <Button variant="contained">Visualizar PDF</Button>
                        </Box>
                      )
: (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setImageUrl(amostra.url)
                            setOpenDialog(true)
                          }}
                        >
                          Visualizar foto
                        </Button>
                      )}
                    </Box>
                    <Box>
                      <Typography>Status</Typography>
                      {!amostra.status.toUpperCase()?.includes('APROVADO') &&
                        !amostra.status.toUpperCase().includes('REPROVADO') && (
                          <Chip
                            label={amostra.status.toUpperCase()}
                            size="small"
                          />
                        )}
                      {amostra.status.toUpperCase().includes('APROVADO') && (
                        <Chip
                          label="Aprovado"
                          color="success"
                          variant="outlined"
                          size="small"
                          sx={{ color: '#72ce2b', borderColor: '#72ce2b' }}
                        />
                      )}
                      {amostra.status.toUpperCase().includes('REPROVADO') && (
                        <Chip
                          label="Reprovado"
                          color="error"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </Box>
                  </div>
                  <TextField
                    style={{ maxWidth: 700, marginTop: '20px', width: '100%' }}
                    id="outlined-multiline-static"
                    label="Observações"
                    multiline
                    rows={3}
                    defaultValue={amostra.comment ?? ''}
                    disabled
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          ))}

          {!arts.data?.find(
            (a) =>
              a.status.includes('Aprovado') ||
              a.status.includes('aprovado') ||
              a.status.includes('APROVADO') ||
              a.status.includes('PENDENTE') ||
              a.status.includes('AGUARDANDO APROVAÇÃO DA ARTE')
          ) && (
            <Paper style={{ width: 'fit-content', marginTop: 30 }}>
              <Button
                onClick={() => {
                  setOpenFormDialog(true)
                }}
                variant="text"
                startIcon={<AddOutlined />}
              >
                Enviar arte
              </Button>
            </Paper>
          )}
        </div>
      </Box>
      <GenericDialog
        allowBack
        open={openDialog}
        close={() => setOpenDialog(false)}
        content={<SampleShowContent image={imageUrl} />}
      />

      <GenericDialog
        widthRoot={670}
        allowBack={false}
        open={openFormDialog}
        close={() => setOpenFormDialog(false)}
        content={
          <ArtSampleForm
            ftId={props.ft._id}
            close={() => setOpenFormDialog(false)}
            confirm={() => {
              setOpenFormDialog(false)
              arts.refetch()
            }}
          />
        }
      />
    </div>
  )
}

export default ArtSampleStep
