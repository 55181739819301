/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react'
import { Formik, Form, FieldArray, FormikErrors } from 'formik'
import Button from 'components/form/Button'
import Add from '@mui/icons-material/Add'
import {
  Grid,
  TextField,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import styled from 'styled-components'
import ClearIcon from '@mui/icons-material/Clear'
import { useLocation, useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import {
  RepresentativeType
} from 'types/customerControl/FormCustomerControlType'
import { toast } from 'react-toastify'
import {
  createClientRepresentatives,
  deleteClientRepresentative,
  createClientIE,
  updateClientIE,
  deleteClientIE
} from 'services/customerControl/CustomerControlServices'
import { validationRepresentativeSchema } from '../validations/validationRepresentativeSchema'
import { httpClient } from 'config/httpClient'
import { onlyNumbersOrPoint } from 'helpers/stringHelper'

const TitleSection = styled('p')`
  font: normal normal bold 16px/22px Roboto;
  letter-spacing: 0px;
  color: #262626;
`

const ButtonAdd = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  border: none;
  text-align: center;
  font: normal normal normal 12px/22px Roboto;
  letter-spacing: 0px;
  color: #8a8a8a;

  &:hover {
    background: #f5f5f5;
  }
`

const ButtonCancel = styled.button`
  border-radius: 2px;
  border: none;
  background: #f5222d;
  color: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Props {
  goBack: () => void
  nextStep: () => void
}

const FormCustomerIE: React.FC<Props> = ({ goBack, nextStep }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const { state }: any = useLocation()
  // Quando for uma nova IE acrescentar uma tag = new
  const [stateEnrollment, setStateEnrollment] = useState<any[]>([])

  useEffect(() => {
    if (state?.client?._id) {
      setLoading(true)
      httpClient.get(`company/${state.client._id}/ie`).then((response) => {
        console.log('IE', response.data)
        setStateEnrollment(response.data?.length
          ? response.data
          : [
            {
              value: '',
              company: state.client._id ?? '',
              tag: 'new',
              isActive: true
            }
          ])
      }).catch((error) => {
        toast.error(error.response.data.message ?? 'Erro ao buscar os inscrições estaduais')
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [state?.client?._id])

  const checkErrorRepresentatives = (item: unknown, name: string) => {
    const error = item as FormikErrors<{ [key: string]: string }>
    return Boolean(error?.[name])
  }

  const checkErrorTouchedStateEnrollment = (item: unknown, name: string) => {
    const error = item as FormikErrors<{ [key: string]: string }>
    return Boolean(error?.[name])
  }

  const getErrorStateEnrollment = (item: unknown, name: string) => {
    const error = item as FormikErrors<{ [key: string]: string }>
    return error?.[name] ? error?.[name] : ''
  }

  const handleDelete = async (
    callback: (() => void) | undefined,
    idC: string | undefined
  ) => {
    console.log(idC)
    if (!callback) return

    if (!idC) {
      callback()
      return
    }

    try {
      await deleteClientIE(state?.client?._id, idC)
      callback()
    } catch (error: any) {
      toast.error(error?.response.data?.message)
      return false
    }
  }

  if (loading && !stateEnrollment?.length) {
    return (
      <Box display='flex' justifyContent='center' alignItems="center" height={200} width='100%'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      {!!stateEnrollment?.length && (
        <Formik
          initialValues={{
            _id: state?.client?._id ?? '',
            stateEnrollment
          }}
          validationSchema={validationRepresentativeSchema}
          onSubmit={async (values) => {
            console.log(values)
            if (!state?.client?._id) {
              toast.error('Erro cliente não encontrado para salvar os dados dos representantes')
              return
            }

            const hasDuplicatedIe = values?.stateEnrollment?.filter((ie) => {
              return (
                values.stateEnrollment?.filter((ie2) => {
                  return ie.value === ie2.value
                }).length > 1
              )
            })

            // Comentada verificação de inscrições estaduais duplicadas
            // if (hasDuplicatedIe.length) {
            //   toast.error('Existem inscrições estaduais duplicadas')
            //   return
            // }

            console.log(values)

            const formData = values?.stateEnrollment?.map((item: any) => {
              if (item?._id) {
                return item
              }

              return { value: item.value, company: item.company, tag: 'new' }
            })
            console.log(values, formData)

            try {
              setLoading(true)
              await createClientIE(
                state.client?._id,
                formData
              )
            } catch (error: any) {
              console.log('Error: ', error)
              toast.error(error?.response?.data?.message)
              return
            } finally {
              setLoading(false)
            }

            toast.success('Salvo com sucesso!')
            nextStep()
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Grid container spacing={4}>
                <FieldArray
                  name="stateEnrollment"
                  validateOnChange={false}
                  render={(arrayHelpers) => (
                    <>
                      <Grid item xs={12}>
                        <TitleSection style={{ marginBottom: '10px' }}>
                          Inscrição estadual
                        </TitleSection>
                        {values.stateEnrollment.map((enrollment, index) => (
                          <Grid
                            container
                            spacing={3}
                            key={`key_enrollment_${index}`}
                            style={{ marginBottom: '30px', paddingBottom: '24px' }}
                          >
                            <Grid item xs={11}>
                              <Grid container spacing={3}>
                                <Grid item xs={8}>
                                  <TextField
                                    error={
                                      checkErrorRepresentatives(
                                        errors.stateEnrollment?.[index],
                                        'value'
                                      ) &&
                                      checkErrorTouchedStateEnrollment(
                                        touched.stateEnrollment?.[index],
                                        'value'
                                      )
                                    }
                                    value={enrollment.value}
                                    fullWidth
                                    autoComplete="off"
                                    variant="standard"
                                    label="Inscrição estadual"
                                    required
                                    helperText={
                                      checkErrorRepresentatives(
                                        errors.stateEnrollment?.[index],
                                        'value'
                                      ) &&
                                        checkErrorTouchedStateEnrollment(
                                          touched.stateEnrollment?.[index],
                                          'value'
                                        )
                                        ? getErrorStateEnrollment(
                                          errors.stateEnrollment?.[index],
                                          'value'
                                        )
                                        : null
                                    }
                                    onChange={(event) => setFieldValue(
                                      `stateEnrollment.${index}.value`,
                                      onlyNumbersOrPoint(event.target.value)
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl variant='standard' fullWidth>
                                    <InputLabel id={`stateEnrollment.${index}.isActive`}>Status</InputLabel>
                                    <Select
                                      labelId={`stateEnrollment.${index}.isActive`}
                                      id={`stateEnrollment.${index}.isActive-id`}
                                      value={enrollment?.isActive ? 'Ativo' : 'Inativo'}
                                      label="Status"
                                      onChange={(event) => {
                                        setFieldValue(
                                          `stateEnrollment.${index}.isActive`,
                                          event?.target?.value === 'Ativo'
                                        )
                                      }}
                                      required
                                    >
                                      <MenuItem value='Ativo'>Ativo</MenuItem>
                                      <MenuItem value='Inativo'>Inativo</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <ButtonCancel
                                type="button"
                                disabled={values.stateEnrollment?.length <= 1 || !!enrollment?._id}
                                onClick={() => {
                                  handleDelete(
                                    () => arrayHelpers.remove(index),
                                    enrollment?._id
                                  )
                                }}
                              >
                                <ClearIcon />
                              </ButtonCancel>
                            </Grid>
                          </Grid>
                        ))}

                        <ButtonAdd
                          type="button"
                          style={{ marginTop: '30px' }}
                          onClick={() =>
                            arrayHelpers.push({
                              value: '',
                              isActive: true
                            })
                          }
                        >
                          <Add /> Nova inscrição estadual
                        </ButtonAdd>
                      </Grid>
                    </>
                  )}
                />

                <Grid item md={12} className="text-right">
                  <Button
                    type="button"
                    mold="primary"
                    onClick={goBack}
                    style={{ marginRight: '10px' }}
                    disabled={loading}
                  >
                    Voltar
                  </Button>
                  <Button
                    mold="primary"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default FormCustomerIE
