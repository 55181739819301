import React from 'react'
import { ColumnCV, DotCV } from './styles'

function ChartBottomLabel(props: any) {
  return (
    <ColumnCV>
      <DotCV color={props.color} />
      <div>{props.title}</div>
    </ColumnCV>
  )
}

export default ChartBottomLabel
