/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Box } from '@mui/material'
import { DivModules, Tab, TabPanel, TabsList } from '../styles/purchaseRequests.styles'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import TablePilot from './TablePilot'
import TableKanban from './TableKanban'
import TableWallet from './TableWallet'
import TableBobinaSimplex from './TableBobinaSimplex'
import TablePlate from './TablePlate'
import TableChapa from './TableChapa'
import TableCaixa from './TableCaixa'
import TableAllType from './TableAllType'

const ModulesPurchaseOrder: React.FC<any> = ({ tab }) => {
  let tabValue
  switch (tab) {
    case 'PAPEL':
      tabValue = 0
      break
    case 'CHAPA':
      tabValue = 1
      break
    case 'CAIXA':
      tabValue = 2
      break
    case 'TODOS':
      tabValue = 3
      break
    default:
      tabValue = 0
  }

  return (
    <>
      <DivModules>
        <TabsUnstyled defaultValue={tabValue}>
          <Box sx={{ width: '700px' }}>
            <TabsList>
              {/* <Tab>Lote piloto</Tab> */}
              {/* <Tab>Kanban</Tab> */}
              {/* <Tab>Carteira</Tab> */}
              <Tab>Bobinas e Simplex</Tab>
              <Tab>Chapas</Tab>
              <Tab>Caixa</Tab>
              <Tab>Todos</Tab>
            </TabsList>
          </Box>
          {/* <TabPanel value={0}><TablePilot /></TabPanel> */}
          {/* <TabPanel value={0}>
            <TableKanban />
          </TabPanel> */}
          {/* <TabPanel value={1}>
            <TableWallet />
          </TabPanel> */}
          <TabPanel value={0}>
            <TableBobinaSimplex />
          </TabPanel>
          <TabPanel value={1}>
            <TableChapa />
          </TabPanel>
          <TabPanel value={2}>
            <TableCaixa />
          </TabPanel>
          <TabPanel value={3}>
            <TableAllType />
          </TabPanel>
        </TabsUnstyled>
      </DivModules>
    </>
  )
}

export default ModulesPurchaseOrder
