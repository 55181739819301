import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
  getBudget,
  getClicheTrimbox,
  getCompositionTrimbox,
  getFacaTrimbox,
  postBudget
} from 'services/ft/FTService'
import { BudgetDataType } from 'types/ft/BudgetDataType'
import { FTBudgetCompleteType } from 'types/ft/FTBudgetCompleteType'
import { FTBudgetType } from 'types/ft/FTBudgetType'
import { FTProposalType } from 'types/ft/FTProposalType'
import { validationSchemaBudgetCompleteFT } from 'types/validation/ValidationBudgetCompleteFT'
import { roundUp } from 'utils/formatString'

interface Props {
  budgetData: FTBudgetType | undefined
  ft: any
  preft: any
  ftTrimboxData: any
  confirm: (values) => any
  setBudgetId: (values) => any
}

function BudgetSampleBoxStep(props: Props) {
  console.log('BudgetSampleBoxStep -> props ', props)

  const strTrimbox = props?.ftTrimboxData?.descObs
  console.log('ainda definindo padrões: descObs', strTrimbox)

  const [budgetData, setBudgetData] = React.useState<BudgetDataType>()
  const { data: budget, status } = useQuery(
    ['budget-req', props.ft?._id || ''],
    async () => {
      return await getBudget(props.ft?._id || '')
    },
    {
      onSuccess: (data) => {
        if (data.length > 0) {
          const budgetData = data[0]
          console.log('BudgetSampleBoxStep -> getBudget', budgetData)
          setBudgetData(budgetData)
          props.setBudgetId(budgetData._id)

          const proposalData: FTProposalType = {
            status: 'EM ANÁLISE',
            gramature: budgetData?.gramatura ?? 0,
            internalMeasures: mountInternalMeasures(),
            observation: '',
            price: budgetData?.precoNegociado ?? 0,
            shipping: budgetData?.valorFreteCalculado ?? 0,
            minimumLot: budgetData?.loteMinimo ?? 0,
            paymentCondition: `${budgetData?.condicoesPagamento} dias`,
            financingRate: budgetData?.valorTaxa ?? 0,
            icms: values.icms,
            validity: 30,
            deadlineDeliverDays: 30,
            budget: budgetData._id
          }
          props.confirm(proposalData)
        }
      },
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (status === 'success') {
      setBudgetData(budget[0])
    }
  }, [budget, status])

  let initialClicheCheck = props.preft.cliche
  if (budgetData?.custoCliche && Number(budgetData?.custoCliche) > 0) {
    initialClicheCheck = true
  }
  const [clicheCheck, setClicheCheck] = React.useState(false)
  let initialFacaCheck = props.preft.knife
  if (budgetData?.custoFaca && Number(budgetData?.custoFaca) > 0) {
    initialFacaCheck = true
  }
  const [facaCheck, setFacaCheck] = React.useState(false)
  const [openInputCompleto, setOpenInputCompleto] = React.useState(false)
  const [cortes, setCortes] = React.useState(props.ftTrimboxData.pecasPorConjunto ?? 0)
  const formatoTrim = ((props.preft.type === 'CAIXA' ? 0 : props.preft.type === 'CHAPA' ? props.ftTrimboxData.largura / 1000 ?? 0 : 0))
  const [formato, setFormato] = React.useState(formatoTrim)
  const [arranjoComprimento, setArranjoComprimento] = React.useState(1)
  const [arranjoLargura, setArranjoLargura] = React.useState(1)
  const comprimentoWithNumbersOnly = props.ftTrimboxData.comprimento.toString().replace(/\D/g, '')
  const [comprimento, setComprimento] = React.useState((parseInt(comprimentoWithNumbersOnly) / 1000) ?? 0)
  const [materialValues, setMaterialValues] = React.useState(props.budgetData ?? {
    productValue: 0,
    taxValue: 0,
    closureValue: 0,
    resinValue: 0,
    cleaningValue: 0,
    productName: '',
    daysValue: 0
  })

  React.useEffect(() => {
    setFacaCheck(initialFacaCheck)
    setClicheCheck(initialClicheCheck)
  }, [initialFacaCheck, initialClicheCheck])

  const [preftData] = React.useState<any>({
    minimumLotReference: budgetData?.loteMinimo ?? 0,
    destinationCity: budgetData?.cidadeDestino ?? 'Manaus',
    cliche: (Number(budgetData?.custoCliche) > 0) ? true : props.preft.cliche,
    knife: (Number(budgetData?.custoFaca) > 0) ? true : props.preft.knife
  })

  if (status !== 'success') {
    console.log('EMPTY BUDGET')
  } else {
    console.log('BudgetSampleBoxStep => mongo BUDGET', budgetData)
    if (budgetData !== undefined) {
      // Substituir NaN por 0
      budgetData.resina = isNaN(budgetData?.resina) ? 0 : budgetData?.resina
      budgetData.fechamento = isNaN(budgetData?.fechamento) ? 0 : budgetData?.fechamento
      budgetData.limpeza = isNaN(budgetData?.limpeza) ? 0 : budgetData?.limpeza
      // Verificar os valores atualizados
      console.log(budgetData)
    } else {
      console.log('budgetData é undefined.')
    }
    console.log('status -> ', budgetData?.status)
    console.log('preftData -> ', preftData)
  }

  /* Empty */
  const facaReq = useQuery(
    ['faca-ft-trimbox', null],
    async () => {
      console.log('ftTrimboxData', props.ftTrimboxData)
      return getFacaTrimbox(props.ftTrimboxData.codFaca)
    },
    {
      refetchOnWindowFocus: false
    }
  )
  /* Empty */
  const clicheReq = useQuery(
    ['cliche-ft-trimbox', null],
    async () => {
      return getClicheTrimbox(props.ftTrimboxData.codCliche)
    },
    {
      refetchOnWindowFocus: false
    }
  )
  /* Empty */
  const compositionReq = useQuery(
    ['gramature-ft-trimbox', null],
    async () => {
      return getCompositionTrimbox(props.ftTrimboxData.codComposition)
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const mountInternalMeasures = () => {
    try {
      if (props.preft.preftplate) {
        return `${props.preft.formulariochapa.Referencia}`
      } else {
        return `${props.preft?.preftbox?.reference ?? '0x0x0'}`
      }
    } catch (e) { }
    return '0x0x0'
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: mutateCreate } = useMutation(postBudget, {
    onSuccess: (data) => {
      console.log({ postBudgetData: data })
      console.log('orçamento criado', data._id)
      const proposalData: FTProposalType = {
        status: 'EM ANÁLISE',
        gramature: budgetData?.gramatura ?? 0,
        internalMeasures: mountInternalMeasures(),
        observation: '',
        price: budgetData?.precoNegociado ?? 0,
        shipping: budgetData?.valorFreteCalculado ?? 0,
        minimumLot: budgetData?.loteMinimo ?? 0,
        paymentCondition: `${budgetData?.condicoesPagamento} dias`,
        financingRate: budgetData?.valorTaxa ?? 0,
        icms: values.icms,
        validity: 0,
        deadlineDeliverDays: 0,
        budget: data._id
      }
      toast.success('Orçamento feito com sucesso')
      props.setBudgetId(data._id)
      props.confirm(proposalData)
    },
    onError: () => {
      toast.error('Não foi possível criar esse orçamento')
    }
  })

  const { errors, touched, values, setFieldValue, handleChange, handleSubmit } = useFormik({
    onSubmit: function (inputValues: FTBudgetCompleteType) {
      let custoFaca = facaCheck
        ? facaReq.isError
          ? 0
          : facaReq.data.data?.preco ?? 0
        : 0
      let custoCliche = clicheCheck
        ? clicheReq.isError
          ? 0
          : clicheReq.data?.preco ?? 0
        : 0
      let gramatura = budgetData?.gramatura ?? (compositionReq.isError ? null : (compositionReq.data?.gramatura / 1000) ?? 0)

      const trimboxData = props.ftTrimboxData

      console.log('dados para orçamento inputados', inputValues)
      console.log('dados para orçamento trimbox', trimboxData)
      console.log('dados para orçamento valores material', materialValues)
      console.log('dados para orçamento faca', custoFaca)
      console.log('dados para orçamento cliche', custoCliche)
      console.log('dados para orçamento composition', gramatura)

      // Caso os dados do trimbox e de valores de material sejam inputados
      if (openInputCompleto) {
        gramatura = inputValues.gramatura ?? (compositionReq?.data?.gramatura ?? 0) / 1000
        custoFaca = inputValues.faca ?? (facaReq?.data?.data?.preco ?? 0)
        custoCliche = inputValues.cliche ?? (clicheReq?.data?.preco ?? 0)
        materialValues.productValue = inputValues.tabela ?? materialValues.productValue
        materialValues.taxValue = inputValues.taxa ?? materialValues.taxValue
        materialValues.closureValue = inputValues.fechamento ?? materialValues.closureValue
        materialValues.resinValue = inputValues.resina ?? (materialValues.resinValue ?? 0)
        materialValues.cleaningValue = inputValues.limpeza ?? materialValues.cleaningValue
        trimboxData.comprimento = comprimento / arranjoComprimento
        trimboxData.areaBrutaChapa = ((formato / cortes / arranjoLargura) * trimboxData.comprimento)
        trimboxData.areaLiquidaChapa = inputValues.areaLiquida ?? trimboxData.areaLiquidaChapa
      }

      setMaterialValues(materialValues)
      const pesoBruto = Math.round((trimboxData?.areaBrutaChapa ?? 0) * (gramatura ?? 0) * 100000) / 100000
      const tabela = materialValues?.productValue ?? 0
      const valorAgregado =
        (materialValues?.closureValue ?? 0) +
        (inputValues?.glueClipClosure ?? 0) +
        (materialValues?.resinValue ?? 0) * (trimboxData?.areaBrutaChapa ?? 0) +
        (materialValues?.cleaningValue ?? 0)

      const precoFerramental =
        ((custoCliche ?? 0) / ((inputValues?.monthlyConsumption ?? 1) * 6) +
          (custoFaca ?? 0) / ((inputValues?.monthlyConsumption ?? 1) * 6)) ?? 0

      const valorTaxa = materialValues?.taxValue ?? 0
      const pesoBrutoComTabela = pesoBruto * (tabela ?? 0)
      const valorAgregadoComFerramental = valorAgregado + precoFerramental
      const fretePesoBruto = inputValues?.shipping ?? 0 * pesoBruto
      const valorProduto = valorAgregadoComFerramental + pesoBrutoComTabela + fretePesoBruto
      const taxaFinanciamento = (valorProduto * (valorTaxa ?? 0)) / 100
      const shipping = Number(inputValues.shipping) || 0

      const precoSugerido = Math.floor((valorAgregado +
        precoFerramental +
        shipping * pesoBruto +
        pesoBruto * tabela +
        taxaFinanciamento) * 10000) / 10000

      console.log('preco sugerido', precoSugerido)

      const _budgetData = {
        areaLiquida: parseFloat(Number(trimboxData.areaLiquidaChapa ?? 0).toFixed(4)),
        areaBruta: parseFloat(Number(trimboxData.areaBrutaChapa ?? 0).toFixed(4)),
        fechamento: parseFloat(Number(materialValues?.closureValue ?? 0).toFixed(4)),
        resina: parseFloat(Number((materialValues?.resinValue ?? 0) * (trimboxData.areaBrutaChapa ?? 0)).toFixed(4)),
        limpeza: parseFloat(Number(materialValues?.cleaningValue ?? 0).toFixed(4)),
        gramatura: parseFloat(Number(gramatura ?? 0).toFixed(4)),
        tipoProduto: ((props.preft.type === 'CAIXA' ? 'Caixa' : props.preft.type === 'CHAPA' ? 'Chapa' : '')),
        tabela: parseFloat(Number(materialValues?.productValue ?? 0).toFixed(4)),
        mensal: parseFloat(Number(inputValues.monthlyConsumption ?? 0).toFixed(4)),
        cidadeDestino: inputValues.destinationCity ?? '',
        valorFrete: parseFloat(Number(inputValues.shipping ?? 0).toFixed(4)),
        fechamentoColagemManual: parseFloat(Number(materialValues?.closureValue ?? 0).toFixed(4)),
        fechamentoGrampoCola: parseFloat(Number(inputValues.glueClipClosure ?? 0).toFixed(4)),
        loteMinimo: parseFloat(Number(inputValues.minimumLot ?? 0).toFixed(4)),
        condicoesPagamento: parseFloat(Number(materialValues?.daysValue ?? 0).toFixed(4)),
        valorTaxa: parseFloat(Number(materialValues?.taxValue ?? 0).toFixed(4)),
        pesoLiquido: parseFloat((Number(trimboxData?.areaLiquidaChapa ?? 0) * (gramatura ?? 0)).toFixed(4)),
        pesoBruto: parseFloat(pesoBruto?.toString() ?? '0'),
        anual: parseFloat(
          Number(inputValues.monthlyConsumption * 12)?.toFixed(4) ?? 0
        ),
        semestral: parseFloat(
          Number(inputValues.monthlyConsumption * 6)?.toFixed(4) ?? 0
        ),
        trimestral: parseFloat(
          Number(inputValues.monthlyConsumption * 3)?.toFixed(4) ?? 0
        ),
        bimestral: parseFloat(
          Number(inputValues.monthlyConsumption * 2)?.toFixed(4) ?? 0
        ),
        pesoKg: parseFloat(Number(pesoBruto)?.toFixed(4)) ?? 0,
        preco: Math.floor(pesoBruto * tabela * 10000) / 10000 ?? 0,
        valorFreteCalculado: parseFloat(
          Number(inputValues.shipping * pesoBruto)?.toFixed(4) ?? 0
        ),
        custoCliche: parseFloat(Number(custoCliche)?.toFixed(4)) ?? 0,
        custoFaca: parseFloat(Number(custoFaca)?.toFixed(4)) ?? 0,
        custoClicheDiluido: parseFloat(
          Number(custoCliche / (inputValues.monthlyConsumption * 6))?.toFixed(4) ?? 0
        ),
        custoFacaDiluido: parseFloat(
          Number(custoFaca / (inputValues.monthlyConsumption * 6))?.toFixed(4) ?? 0
        ),
        precoFerramental: parseFloat(Number(precoFerramental || 0).toFixed(4)),
        valorAgregado: parseFloat(Number(valorAgregado || 0).toFixed(4)),
        precoFechamento: parseFloat(Number(valorAgregado || 0).toFixed(4)),
        precoAVista: Math.floor((
          valorAgregado +
          precoFerramental +
          (inputValues.shipping || 0) * pesoBruto +
          pesoBruto * tabela
        ) * 10000) / 10000,
        taxaFinanciamento: parseFloat(Number(taxaFinanciamento || 0).toFixed(4)),
        precoKgFerramental: parseFloat(
          Number(precoSugerido / pesoBruto || 0).toFixed(4)
        ),
        precoKgSemFerramental: parseFloat(
          Number((pesoBruto * tabela) / pesoBruto || 0).toFixed(4)
        ),
        precoSugerido: precoSugerido || 0,
        precoPorKg: parseFloat(Number(precoSugerido / pesoBruto || 0).toFixed(4)),
        precoNegociado: roundUp(precoSugerido, 2),
        peloValorNF: Number(precoSugerido / (trimboxData.areaLiquidaChapa * (gramatura ?? 0)) || 0).toFixed(2),
        KG: parseFloat(
          Number(inputValues.monthlyConsumption * pesoBruto || 0).toFixed(4)
        ),
        NF: Math.ceil((inputValues.monthlyConsumption || 0) * precoSugerido * 0.1) / 0.1,
        status: 'Criado',
        ft: props.ft._id || ''
      }
      console.log('inputValues', inputValues)
      console.log('Resultado:', _budgetData)
      setBudgetData(_budgetData)
      // mutateCreate(_budgetData)
    },
    enableReinitialize: true,
    validationSchema: validationSchemaBudgetCompleteFT,
    initialValues: {
      monthlyConsumption: budgetData?.mensal ?? (props?.preft?.preftbox?.amount?.monthConsumption ?? 0),
      destinationCity: budgetData?.cidadeDestino ?? props.preft.destinationCity,
      shipping: 0,
      icms: 0,
      // (valor de maleta ou corte e vinco) / Peso
      minimumLot: budgetData?.loteMinimo ?? Math.round(props.preft.minimumLotReference / (props.ftTrimboxData.areaBrutaChapa * ((compositionReq.data?.gramatura / 1000) ?? 0))),
      glueClipClosure: 0,
      gramatura: budgetData?.gramatura ?? ((props.preft.type === 'CAIXA' ? ((props.preft.preftbox?.specifications?.Composicao?.gramatura ?? 0) / 1000) : props.preft.type === 'CHAPA' ? ((props.preft.preftplate?.Composicao?.gramatura ?? 0) / 1000) : 0)),
      tabela: budgetData?.tabela ?? 0,
      taxa: budgetData?.taxaFinanciamento ?? 0,
      areaLiquida: budgetData?.areaLiquida ?? ((props.preft.type === 'CAIXA' ? props.ftTrimboxData?.areaBrutaChapa ?? 0 : (props.preft.type === 'CHAPA' ? props.ftTrimboxData?.areaLiquidaChapa ?? 0 : 0))),
      fechamento: budgetData?.fechamento ?? 0,
      resina: budgetData?.resina ?? 0,
      limpeza: budgetData?.limpeza ?? 0,
      faca: budgetData?.custoFaca ?? 0,
      cliche: budgetData?.custoCliche ?? 0
    }
  })

  if (
    facaReq.isFetching ||
    compositionReq.isFetching ||
    clicheReq.isFetching
  ) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <div
        style={{
          display:
            facaReq.isError ||
              !facaReq.data ||
              compositionReq.isError ||
              !compositionReq.data ||
              clicheReq.isError ||
              !clicheReq.data
              ? 'flex'
              : 'none',
          flexDirection: 'column',
          marginTop: '18px'
        }}
      >
        <div>Observações: </div>
        <div
          style={{
            display: clicheReq.isError || !clicheReq.data ? 'flex' : 'none'
          }}
        >
          Dados para clichê polímero(clichê) não encontrados no trimbox.
        </div>
        <div
          style={{
            display: facaReq.isError || !facaReq.data ? 'flex' : 'none'
          }}
        >
          Dados para clichê metálico(faca) não encontrados no trimbox.
        </div>
        {
          (compositionReq.isError || !compositionReq.data)
            ? <div>
              Dados para gramatura(composition) não encontrados no trimbox.
            </div>
            : <div>
              Dados da gramatura(composition): {(compositionReq.data?.gramatura / 1000 ?? 0)}.
              <br></br>
              Lote mínimo padrão: {`${props.preft.minimumLotReference} / ${props.ftTrimboxData.areaBrutaChapa} x ${((compositionReq.data?.gramatura / 1000) ?? 0)}`}
            </div>
        }

      </div>
      <form
        id="entry-data-form"
        onSubmit={handleSubmit}
        style={{
          marginTop: '24px',
          backgroundColor: '#f0f0f0',
          padding: '10px',
          borderRadius: '12px'
        }}
      >
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Dados de entrada do orçamento
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
            <Grid item xs={3}>
              <TextField
                type="number"
                fullWidth
                id="standard-basic"
                label="Consumo mensal (Unid)"
                variant="standard"
                value={values.monthlyConsumption}
                autoComplete="off"
                error={Boolean(
                  errors.monthlyConsumption && touched.monthlyConsumption
                )}
                helperText={
                  errors.monthlyConsumption && touched.monthlyConsumption
                    ? errors.monthlyConsumption
                    : null
                }
                // onChange={handleChange('monthlyConsumption')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('monthlyConsumption', 0)
                  } else {
                    setFieldValue('monthlyConsumption', event.target.value)
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={handleChange('destinationCity')}
                value={values.destinationCity}
                autoComplete="off"
                error={Boolean(
                  errors.destinationCity && touched.destinationCity
                )}
                helperText={
                  errors.destinationCity && touched.destinationCity
                    ? errors.destinationCity
                    : null
                }
                fullWidth
                id="standard-basic"
                label="Cidade destino"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // onChange={handleChange('shipping')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('shipping', 0)
                  } else {
                    setFieldValue('shipping', event.target.value)
                  }
                }}
                value={values.shipping}
                autoComplete="off"
                error={Boolean(errors.shipping && touched.shipping)}
                helperText={
                  errors.shipping && touched.shipping ? errors.shipping : null
                }
                type="number"
                fullWidth
                id="standard-basic"
                label="Frete (R$)"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // onChange={handleChange('icms')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('icms', 0)
                  } else {
                    setFieldValue('icms', event.target.value)
                  }
                }}
                value={values.icms}
                autoComplete="off"
                error={Boolean(errors.icms && touched.icms)}
                helperText={errors.icms && touched.icms ? errors.icms : null}
                type="number"
                fullWidth
                id="standard-basic"
                label="Desconto ICMS (%)"
                variant="standard"
                defaultValue="0.0"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // onChange={handleChange('minimumLot')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('minimumLot', 0)
                  } else {
                    setFieldValue('minimumLot', event.target.value)
                  }
                }}
                value={values.minimumLot}
                autoComplete="off"
                error={Boolean(errors.minimumLot && touched.minimumLot)}
                helperText={
                  errors.minimumLot && touched.minimumLot
                    ? errors.minimumLot
                    : null
                }
                type="number"
                fullWidth
                id="standard-basic"
                label="Lote mínimo (Unid)"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // onChange={handleChange('glueClipClosure')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('glueClipClosure', 0)
                  } else {
                    setFieldValue('glueClipClosure', event.target.value)
                  }
                }}
                value={values.glueClipClosure}
                autoComplete="off"
                error={Boolean(
                  errors.glueClipClosure && touched.glueClipClosure
                )}
                helperText={
                  errors.glueClipClosure && touched.glueClipClosure
                    ? errors.glueClipClosure
                    : null
                }
                type="number"
                fullWidth
                id="standard-basic"
                label="Fechamento (grampo/cola)"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clicheCheck}
                    onChange={() => {
                      setClicheCheck(!clicheCheck)
                    }}
                  />
                }
                label="Clichê polímero"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={facaCheck}
                    onChange={() => {
                      setFacaCheck(!facaCheck)
                    }}
                  />
                }
                label="Clichê metálico"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('gramatura')}
                value={values.gramatura ?? (compositionReq.data?.gramatura / 1000 ?? 0)}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Gramatura"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => {
                  if (e.target.value) {
                    setFacaCheck(true)
                  } else {
                    setFacaCheck(false)
                  }
                  setFieldValue('faca', e.target.value)
                }}
                // onChange={handleChange('faca')}
                value={values.faca}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Faca"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => {
                  if (e.target.value) {
                    setClicheCheck(true)
                  } else {
                    setClicheCheck(false)
                  }
                  setFieldValue('cliche', e.target.value)
                }}
                // onChange={handleChange('cliche')}
                value={values.cliche}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Clichê"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('tabela')}
                value={values.tabela ?? materialValues.productValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Tabela"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('taxa')}
                value={values.taxa ?? materialValues.taxValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Taxa"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => { setFormato(Number(e.target.value)) }}
                value={formato}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Formato"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => { setCortes(Number(e.target.value)) }}
                value={cortes}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Cortes"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => { setComprimento(Number(e.target.value)) }}
                value={comprimento}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Comprimento"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('areaLiquida')}
                value={values.areaLiquida ?? props.ftTrimboxData.areaLiquidaChapa}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Área"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('fechamento')}
                value={values.fechamento ?? materialValues.closureValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Fechamento"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('resina')}
                value={values.resina ?? materialValues.resinValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Resina"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('limpeza')}
                value={values.limpeza ?? materialValues.cleaningValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Limpeza"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={e => { setArranjoLargura(Number(e.target.value)) }}
                value={arranjoLargura}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Arranjo Largura"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={e => { setArranjoComprimento(Number(e.target.value)) }}
                value={arranjoComprimento}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Arranjo Comprimento"
                variant="standard"
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            type="button"
            onClick={() => {
              setOpenInputCompleto(!openInputCompleto)
            }}
            style={{ marginTop: '10px' }}
          >
            Input Completo
          </Button>
          <Button type="submit" style={{ marginTop: '10px' }}>
            Gerar orçamento
          </Button>
          <Button onClick={() => { mutateCreate(budgetData!) }} variant='outlined' type="button" style={{ marginTop: '10px', marginLeft: '10px' }} disabled={!budgetData}>
            Salvar
          </Button>
        </div>
      </form>

      {budgetData && (
        <div>
          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Especificações
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '5px'
              }}
            >
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="FTD"
                    variant="standard"
                    value={props.ftTrimboxData.codConjunto}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Área líquida (m²)"
                    variant="standard"
                    value={budgetData?.areaLiquida}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Peso líquido (kg)"
                    variant="standard"
                    value={budgetData?.pesoLiquido}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Área bruta (m²)"
                    variant="standard"
                    value={budgetData?.areaBruta}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Peso bruto (kg)"
                    variant="standard"
                    value={budgetData?.pesoBruto}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Fechamento"
                    variant="standard"
                    value={budgetData?.fechamento}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Resina"
                    variant="standard"
                    value={budgetData?.resina}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Limpeza"
                    variant="standard"
                    value={budgetData?.limpeza}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Gramatura (G/m²)"
                    variant="standard"
                    value={budgetData?.gramatura}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Tipo de produto"
                    variant="standard"
                    value={budgetData?.tipoProduto}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Tabela"
                    variant="standard"
                    value={budgetData?.tabela}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Previsão de consumo (Unidades)
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '5px'
              }}
            >
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Anual"
                    variant="standard"
                    value={budgetData?.anual}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Semestral"
                    variant="standard"
                    value={budgetData?.semestral}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Trimestral"
                    variant="standard"
                    value={budgetData?.trimestral}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Bimestral"
                    variant="standard"
                    value={budgetData?.bimestral}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Mensal"
                    variant="standard"
                    value={budgetData?.mensal}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Formação do preço
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={12}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Peso (kg)"
                    variant="standard"
                    value={roundUp(budgetData?.pesoKg, 3)}
                  />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>x</div>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Tabela"
                    variant="standard"
                    value={budgetData?.tabela}
                  />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>=</div>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço"
                    variant="standard"
                    value={roundUp(budgetData?.preco, 4)}
                  />
                  <div style={{ marginInline: 18, paddingTop: 12 }}></div>
                  <TextField
                    style={{ fontWeight: 'bold' }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço total"
                    variant="standard"
                    value={roundUp(budgetData?.preco, 3)}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Frete</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                pointerEvents: 'none'
              }}
            >
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Cidade de destino"
                    variant="standard"
                    value={budgetData?.cidadeDestino}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Valor"
                    variant="standard"
                    value={budgetData?.valorFrete}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{ fontWeight: 'bold' }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço total"
                    variant="standard"
                    value={budgetData?.valorFreteCalculado}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                marginBottom: -15
              }}
            >
              Ferramental
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                pointerEvents: 'none'
              }}
            >
              <Grid
                container
                spacing={3}
                mt={0}
                style={{ width: '1000px', marginBottom: -15 }}
              >
                <Grid item xs={3}>
                  <div style={{ fontSize: '14px' }}>Clichê polímero</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Custo (R$)"
                    variant="standard"
                    value={budgetData?.custoCliche}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Diluído em 6 meses"
                    variant="standard"
                    value={budgetData?.custoClicheDiluido}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                mt={0}
                style={{ width: '1000px', marginBottom: -15 }}
              >
                <Grid item xs={3}>
                  <div style={{ fontSize: '14px' }}>Clichê metálico</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Custo (R$)"
                    variant="standard"
                    value={budgetData?.custoFaca}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Diluído em 6 meses"
                    variant="standard"
                    value={budgetData?.custoFacaDiluido}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    style={{ fontWeight: 'bold' }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço total"
                    variant="standard"
                    value={budgetData?.precoFerramental}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Fechamento/Resina/Limpeza
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                pointerEvents: 'none'
              }}
            >
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Fechamento (colagem manual)"
                    variant="standard"
                    value={budgetData?.fechamentoColagemManual}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Fechamento (grampo/cola)"
                    variant="standard"
                    value={budgetData?.fechamentoGrampoCola}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Resina"
                    variant="standard"
                    value={budgetData?.resina}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Limpeza"
                    variant="standard"
                    value={budgetData?.limpeza}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Valor agregado"
                    variant="standard"
                    value={budgetData?.valorAgregado}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{ fontWeight: 'bold' }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço total"
                    variant="standard"
                    value={budgetData?.precoFechamento}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <Card sx={{ width: 750, marginTop: '30px' }}>
            <CardContent>
              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Lote mínimo (Pçs)"
                    variant="standard"
                    value={budgetData?.loteMinimo}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço à vista"
                    variant="standard"
                    value={budgetData?.precoAVista}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Frete calculado"
                    variant="standard"
                    value={budgetData?.valorFreteCalculado}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Condições de pagamento (Dias)"
                    variant="standard"
                    value={budgetData?.condicoesPagamento}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Taxa de financiamento (R$)"
                    variant="standard"
                    value={budgetData?.taxaFinanciamento}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Valor da taxa (%)"
                    variant="standard"
                    value={budgetData?.valorTaxa}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço por Kg com Ferramental (R$)"
                    variant="standard"
                    value={budgetData?.precoKgFerramental}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço por Kg sem Ferramental (R$)"
                    variant="standard"
                    value={budgetData?.precoKgSemFerramental}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço sugerido (R$)"
                    variant="standard"
                    value={budgetData?.precoSugerido}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço por Kg"
                    variant="standard"
                    value={budgetData?.precoPorKg}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço negociado"
                    variant="standard"
                    value={budgetData?.precoNegociado}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Pelo valor da NF"
                    variant="standard"
                    value={`R$ ${budgetData?.peloValorNF}`}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="KG"
                    variant="standard"
                    value={budgetData?.KG}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="NF"
                    variant="standard"
                    value={budgetData?.NF}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      )}
    </Box>
  )
}

export default BudgetSampleBoxStep
