/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useState } from 'react'
import { Box } from '@mui/system'
import {
  Grid,
  Typography,
  Icon,
  TextField,
  CircularProgress
} from '@mui/material'
import { useFormik } from 'formik'
import ImportantView from 'components/view/ImportantView'
import { toast } from 'react-toastify'
import { validationBudgetToolSchema } from '../validations/validationBudgetToolSchema'
import { httpClient } from 'config/httpClient'
import Button from 'components/form/Button'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'

interface Props {
  data?: any
  cod: string | number
  onChange: (values: any) => void
  canAction: boolean
  children: ReactNode
}

const BudgetToolItem: React.FC<Props> = ({
  data,
  cod,
  onChange,
  canAction,
  children
}) => {
  const [image, setImage] = useState<string | Blob>()
  const [currentFile, setCurrentFile] = useState<File>()
  const [loading, setLoading] = useState(false)

  const handleImagePicker = (file: any) => {
    if (!file.target?.files[0]) return

    if (file.target?.files[0]?.size > 4100000) {
      toast.warning('O arquivo não pode ser maior que 4mb.')
      return
    }

    setCurrentFile(file.target?.files[0])

    const reader = new FileReader()
    reader.readAsDataURL(file.target.files[0])
    reader.onload = function () {
      const image = new Image()
      image.src = reader.result?.toString() ?? ''

      // image.onload = function () {
      //   // access image size here
      //   console.log('width', image.width)
      //   console.log('height', image.height)
      //   if (
      //     image.width > 1920 ||
      //     image.width < 540 ||
      //     image.height > 1080 ||
      //     image.height < 540
      //   ) {
      //     toast.warning('Resolução de imagem não permitida')
      //   } else {
      //     setImage(result)
      //   }
      // }

      // Aqui temos a sua imagem convertida em string em base64.
      const result: any = reader.result
      console.log('ARQUIVO', result)
      setFieldValue('file', result)
    }
  }

  const { errors, touched, values, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        _id: data?._id,
        ft: data?.ft ?? '',
        arrivalDate: data?.arrivalDate ?? null,
        codItem: data?.codItem ?? '',
        pedido: data?.pedido ?? '',
        status: data?.status ?? 'PENDENTE',
        url: data?.url ?? '',
        justification: data?.justification ?? '',
        arrivalStatus: data?.arrivalStatus ?? '',
        file: ''
      },
      // validationSchema: validationBudgetToolSchema,
      onSubmit: async (values) => {
        const body = values

        if (!values.file && !values.url && !canAction) {
          toast.warning('Selecione um arquivo.')
          return
        }

        setLoading(true)
        console.log(body)
        try {
          const response = await httpClient.post('budgets-tool', body)
          console.log('CREATED', response.data)
          setLoading(false)
          onChange(response.data)
          toast.success('Salvo com sucesso!')
        } catch (error: any) {
          toast.error(error?.response?.data?.message)
          setLoading(false)
        }
      }
    })

  return (
    <form onSubmit={handleSubmit}>
      <hr />
      <Typography
        component="div"
        sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      >
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              background: 'rgba(255,255,255,0.5)'
            }}
          >
            <CircularProgress size={24} />
          </Box>
        )}
        {!data?.status?.includes('APROVADO') && (
          <Box
            component="label"
            htmlFor={`file-upload-${cod}`}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '16px 24px',
              border: '1px dashed #0075E7',
              borderRadius: 5,
              color: '#5E5D5D',
              marginTop: 2,
              minHeight: 150,
              width: '100%',
              maxWidth: '600px !important'
            }}
          >
            <FileUploadOutlinedIcon fontSize="large" />
            <Typography component="span" variant="body2">
              Clique para selecionar um arquivo.
            </Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{
                padding: '4px 8px',
                borderRadius: 1,
                color: '#FFFFFF',
                backgroundColor: '#0075E7'
              }}
            >
              Selecione o arquivo
            </Typography>
            <input
              id={`file-upload-${cod}`}
              type="file"
              accept="image/png, image/jpeg, application/pdf, application/octet-stream"
              hidden
              onChange={handleImagePicker}
            />
          </Box>
        )}
        {!data?.status?.includes('APROVADO') && (
          <Box>
            <strong>{currentFile?.name}</strong>
          </Box>
        )}

        {!data?.status?.includes('APROVADO') && (
          <ImportantView
            style={{ marginTop: '16px', maxWidth: '550px' }}
            title="Importante"
            content="Tamanho máximo de 4 mb."
          />
        )}

        {((currentFile && !currentFile?.type?.includes('pdf')) ??
          (values?.url && !values?.url?.includes('.pdf'))) && (
          <img
            style={{
              border: '2px solid #6868684A',
              borderRadius: '6px',
              width: '20%',
              height: 'fit-content'
            }}
            src={currentFile ? URL.createObjectURL(currentFile) : values?.url}
          ></img>
        )}

        {data?.url && (
          <Box
            component="a"
            href={data?.url}
            target="_blank"
            color="primary"
            sx={{
              marginTop: '16px',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <OpenInNewOutlinedIcon fontSize="small" />
            <span>Ver arquivo</span>
          </Box>
        )}
        {/* {canAction && ( */}
        <Box style={{ marginTop: '16px' }}>
          {/* <Typography> */}
          <TextField
            error={Boolean(errors.justification && touched.justification)}
            label="Observação"
            multiline
            rows={4}
            fullWidth
            value={values.justification}
            onChange={handleChange('justification')}
            helperText={
              (errors.justification && touched.justification
                ? errors.justification
                : null) as React.ReactNode
            }
          />
          {/* </Typography> */}
        </Box>
        <Box style={{ marginTop: '16px', maxWidth: 250 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              label="Data de chegada"
              inputFormat="dd/MM/yyyy"
              value={values.arrivalDate}
              onChange={async (value) => setFieldValue('arrivalDate', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="w-100"
                  autoComplete="off"
                  variant="standard"
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box style={{ marginTop: '16px', maxWidth: 250 }}>
        <TextField
            error={Boolean(errors.arrivalStatus && touched.arrivalStatus)}
            label="Status de chegada"
            fullWidth
            value={values.arrivalStatus}
            onChange={handleChange('arrivalStatus')}
            helperText={
              (errors.arrivalStatus && touched.arrivalStatus
                ? errors.arrivalStatus
                : null) as React.ReactNode
            }
          />
        </Box>
        {/* )} */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginTop={2}
        >
          {children}

          {/* {canAction && ( */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              flex: 1
            }}
          >
            <Button
              type="submit"
              mold="red"
              disabled={loading}
              onClick={() => {
                void setFieldValue('status', 'REPROVADO')
              }}
              style={{ marginRight: '16px' }}
            >
              Reprovar
            </Button>
            <Button
              type="submit"
              mold="green"
              disabled={loading}
              onClick={() => {
                void setFieldValue('status', 'APROVADO')
              }}
              style={{ marginRight: '16px' }}
            >
              Aprovar
            </Button>
            <Button
              type="submit"
              mold="green"
              disabled={loading}
              onClick={() => {
                void setFieldValue('status', 'PENDENTE')
              }}
            >
              Salvar
            </Button>
          </div>
          {/* )} */}

          {/* {!canAction && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                flex: 1
              }}
            >
              <Button
                type="submit"
                mold="green"
                disabled={loading}
                onClick={() => {
                  void setFieldValue('status', 'PENDENTE')
                }}
              >
                Salvar
              </Button>
            </div>
          )} */}
        </Box>
      </Typography>
    </form>
  )
}

export { BudgetToolItem }
