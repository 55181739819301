import { InfoOutlined } from '@mui/icons-material'
import { Button, Card, CardContent, Container, Grid, Snackbar, TextField } from '@mui/material'
import { httpClient } from 'config/httpClient'
import FirstAccessDialog from 'pages/login/components/FirstAccessDialog'
import PrivacyLabelLogin from 'pages/login/components/PrivacyLabelLogin'
import React, { useState } from 'react'
import background_login from '../../assets/img/background_login.png'
import sigla from '../../assets/img/Logo_PCE.png'
import ConfirmCodeDialog from './components/ConfirmCodeDialog'

const RecoverPage = () => {
  const [open, setOpen] = useState(false)
  const [openChangePass, setOpenChangePass] = useState(false)
  const [email, setEmail] = useState('')
  const [openSnack, setOpenSnack] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseChangePass = () => {
    setOpenChangePass(false)
  }

  const handleConfirm = (result: boolean) => {
    if (result) {
      setOpen(false)
      setOpenChangePass(true)
    }
  }

  const handleSendCode = async () => {
    try {
      const response = await httpClient.post('/auth/send', { email: email })
      console.log('RESPONSE', response)
      setOpen(true)
    } catch (e) {
      setOpenSnack(true)
    }
  }

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  return (
    <div style={{
      position: 'absolute',
      height: 'auto',
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      backgroundImage: 'url(' + background_login + ')',
      WebkitBackgroundSize: 'cover'
    }}
    >
      <Container style={{ height: '100%' }}>
        <Grid container spacing={2} style={{ height: '100%' }}>
          <Grid item xs={12} md={6} style={{ height: '100%' }}>
            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div style={{ fontSize: '42px', color: 'white', fontWeight: 500, marginBottom: '20px' }}>Recuperar Acesso</div>
              <Card sx={{ maxWidth: '360px' }}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', paddingLeft: '50px', paddingRight: '50px', paddingTop: '60px', paddingBottom: '60px' }}>
                  <div style={{ width: 'auto', display: 'flex', alignSelf: 'center', textAlign: 'center' }}>Digite seu e-mail cadastrado para receber seu acesso</div>
                  <TextField type='email' value={email} onChange={e => setEmail(e.target.value)} id='standard-basic' label='Digite seu email' variant='standard' style={{ color: '#A8A8A8', marginTop: '16px' }} />

                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                    <InfoOutlined color='primary' />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                      <div style={{ fontSize: '14px' }}>Importante</div>
                      <div style={{ fontSize: '12px' }}>Em caso de dúvida contate seu suporte.</div>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
                    <Button style={{
                      backgroundColor: '#4060E6',
                      borderRadius: '2px',
                      width: '100%',
                      color: 'white',
                      marginTop: '50px'
                    }}
                      onClick={async () => handleSendCode()}
                    >Confirmar
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        backgroundColor: '#EE207A',
                        borderRadius: '2px',
                        width: '100%',
                        color: 'white',
                        marginTop: '10px'
                      }}
                      onClick={() => setOpen(false)}
                      href='/'
                    >Cancelar
                    </Button>

                  </div>
                </CardContent>
              </Card>
              <div style={{ fontSize: '12px', color: 'white', fontWeight: 400, marginTop: '18px', marginLeft: '34px' }}>Mnemosine - PCE ©2022 Created by Creathus</div>
              <PrivacyLabelLogin />
            </div>
          </Grid>
          <Grid item xs={0} md={6} style={{ height: '100%', display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
            <img style={{ width: 150, height: 90, display: 'flex', marginBottom: '40px' }} src={sigla} />
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        message='Falha ao enviar código para o email informado'
        key='top center'
      />

      <ConfirmCodeDialog
        open={open}
        email={email}
        onClose={handleClose}
        onConfirm={result => handleConfirm(result)}
      />

      <FirstAccessDialog
        open={openChangePass}
        onClose={handleCloseChangePass}
      />
    </div>
  )
}

export default RecoverPage
