import { Stack } from '@mui/material'
import Button from 'components/form/Button'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  forRoute: string
}

export const GoBack: React.FC<Props> = ({ forRoute }) => {
  return (
    <Stack alignItems="flex-end" sx={{ marginTop: 4 }}>
      <Link
        style={{ textDecoration: 'none', color: 'black' }}
        to={forRoute}
      >
        <Button
          mold="primary"
          style={{
            width: '122px'
          }}
        >
          Voltar
        </Button>
      </Link>
    </Stack>
  )
}
