import { FileUploadRounded, InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import ViewUserType from 'types/user/ViewUserType'

interface modalView {
  open: boolean
  close: () => void
  dados?: ViewUserType
}

const ViewUser = (props: modalView) => {
  const { open, close, dados } = props
  const closeModal = () => close()

  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              >
                <div
                  style={{
                    marginTop: '10px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginBottom: '20px'
                  }}
                >
                  Informações do usuário
                </div>
                <span
                  style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      marginRight: '30px'
                    }}
                  >
                    <div style={{ position: 'relative', alignSelf: 'center' }}>
                      <img
                        style={{
                          border: '1px solid #0A2EB4',
                          borderRadius: '24px',
                          width: '160px',
                          height: '160px'
                        }}
                        src={dados?.url}
                      ></img>
                      <label
                        htmlFor="icon-button-file"
                        style={{
                          position: 'relative',
                          top: '-5px',
                          right: '30px'
                        }}
                      >
                        <IconButton
                          style={{ color: 'white', backgroundColor: '#00B9FF' }}
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <FileUploadRounded />
                        </IconButton>
                      </label>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <InfoOutlined color="primary" />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '10px'
                        }}
                      >
                        <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                          Importante
                        </div>
                        <div style={{ fontSize: '12px' }}>
                          Tamanho máximo 2mb, 256x256 px.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      label="Nome completo"
                      variant="standard"
                      value={dados?.name}
                      disabled
                    />
                    <TextField
                      style={{ marginTop: '24px' }}
                      id="standard-basic"
                      label="Seu email"
                      variant="standard"
                      value={dados?.email}
                      disabled
                    />
                    <TextField
                      style={{ marginTop: '24px' }}
                      id="standard-basic"
                      label="Setor"
                      variant="standard"
                      value={dados?.departament}
                      disabled
                    />
                  </div>
                </span>

                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{ display: 'flex', flex: 1, marginRight: '30px' }}
                  >
                    <FormControl variant="standard" sx={{ width: '100%', left: 20 }} >
                      <InputLabel id="demo-simple-select-label">Perfil</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Perfil"
                        value={dados?.profile.name}
                        >
                        <MenuItem value={dados?.profile.name} disabled>
                          <em>{dados?.profile.name}</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div style={{ display: 'flex', flex: 1 }}>
                    <TextField
                      style={{ width: '100%' }}
                      id="standard-basic"
                      label="Função"
                      variant="standard"
                      value={dados?.occupation}
                      disabled
                    />
                  </div>
                </span>
                <FormGroup
                  style={{ alignContent: 'flex-end', marginTop: '10px' }}
                >
                  <div style={{ fontSize: '14px' }}>Status da conta</div>
                  <FormControlLabel
                    control={<Switch checked={dados?.isActive} />}
                    label={dados?.isActive ? 'Ativo' : 'Inativo'}
                  />
                </FormGroup>
              </div>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{
            marginRight: '10px',
            backgroundColor: '#EE207A',
            borderRadius: '2px',
            width: '122px',
            color: 'white'
          }} onClick={closeModal} autoFocus>Voltar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ViewUser
