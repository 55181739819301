import React, { useState } from 'react'
import { useQuery } from 'react-query'
import Filters from './components/Filters/index'
import { BreadcrumbsType } from 'types/breadcrumbs/BreadcrumbsType'
import RequestForProposalsTable from './components/RequestForProposalsTable'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import { getFtsByProposal } from 'services/report/ReportRequestForProposalsService'
import { ProductsTabPanel } from 'pages/reportControl/reportSeller/components/ProductsTabPanel'
import { NavigationType } from 'types/Report/RequestForProposalsType'
import { LoadingTable } from 'pages/reportControl/proposalForDirection/components/LoadingTable'
import { Box } from '@mui/material'

const breadcrumbs: BreadcrumbsType[] = [
  { text: 'Gerencial', url: '/home/managementControl' },
  { text: 'Solicitações de propostas' }
]

const RequestForProposals: React.FC = () => {
  const [navigation, setNavigation] = useState<NavigationType>(
    NavigationType.CASHIER
  )
  const [filters, setFilters] = useState({
    search: '',
    searchSeller: '',
    searchFT: '',
    date: null,
    status: 'all'
  })

  const changeFilters = (nameField: string, value: unknown): void => {
    setFilters({ ...filters, [nameField]: value })
  }

  const { data, status, isFetching } = useQuery(
    ['report-request-proposal', filters?.search, filters?.searchSeller, navigation],
    async () =>
      getFtsByProposal({
        search: filters.search,
        searchSeller: filters.searchSeller,
        product: navigation ? 'CAIXA' : 'CHAPA'
        // month: filters.date ? moment(filters.date).get('month') : '',
        // year: filters.date ? moment(filters.date).get('year') : ''
      }),
    {
      refetchOnWindowFocus: false
    }
  )

  const isLoading = status === 'loading' || isFetching
  const panel = isLoading
? (
    <LoadingTable />
  )
: (
    <RequestForProposalsTable
      data={data ?? []}
      date={filters.date}
      searchFT={filters.searchFT}
      proposalStatus={filters.status}
    />
  )

  return (
    <>
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />

      <Box sx={{ marginTop: 3 }}>
        <h4>Solicitações de propostas</h4>
      </Box>

      <Filters
        status={filters.status}
        date={filters.date}
        changeFilters={(field: string, value: string) =>
          changeFilters(field, value)
        }
      />

      <ProductsTabPanel
        value={navigation}
        change={(_, value) => setNavigation(+value)}
        marginTop={4}
        marginBottom={1}
        tabs={['Chapas', 'Caixas']}
        tabPanel={[panel, panel]}
      />
    </>
  )
}

export default RequestForProposals
