
import { ReactNode } from 'react'
import { useCan } from 'utils/useCan'

interface CanProps {
  children: ReactNode
  disabled?: boolean
  every?: boolean
  permissions?: string[]
  module?: 'DASHBOARD' |
  'PERFIL' |
  'USUÁRIOS' |
  'CLIENTES' |
  'EMPRESA' |
  'GERENCIAL' |
  'FT' |
  'PEDIDOS' |
  'RELATÓRIOS' |
  'ALERTAS' | string
}

const modulesName = {
  DASHBOARD: 'MD',
  PERFIL: 'MP',
  USUÁRIOS: 'MU',
  CLIENTES: 'MC',
  EMPRESA: 'ME',
  GERENCIAL: 'MG',
  FT: 'MT',
  PEDIDOS: 'MO',
  RELATÓRIOS: 'MR',
  ALERTAS: 'MA'
}

export function Can({ children, permissions = [], module, every = false, disabled = false }: CanProps) {
  function showChildrenComponents(options: {
    permissions?: string[]
    every: boolean
    module: 'DASHBOARD' |
    'PERFIL' |
    'USUÁRIOS' |
    'CLIENTES' |
    'EMPRESA' |
    'GERENCIAL' |
    'FT' |
    'PEDIDOS' |
    'RELATÓRIOS' |
    'ALERTAS' | string
  }) {
    return useCan({
      permissions: options?.permissions,
      moduleCod: modulesName[options?.module],
      every
    })
  }

  if (module && !disabled && !showChildrenComponents({ permissions, module, every })) {
    return null
  }

  return <>{children}</>
}
