/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress, Icon, Table, Typography } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import { useQuery } from 'react-query'
import { getReportRH } from 'services/report/ReportRH'
import { getReportProduction } from 'services/report/ReportProduction'
import { getTargetProduction, getValorImpressoraAcumulado, getValorImpressoraDia } from 'services/report/ReportBilling'
import moment from 'moment'
import { calcularIndiceRefugo } from 'utils/commonValues'
import { formatValue } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const TableProduction = (props: any) => {
  const { date } = props
  const { multipleDate } = props
  const dia = moment(props.date).format('D')
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const indiceRefugo = calcularIndiceRefugo(props.date)
  const [temDiaZerado, setTemDiaZerado] = useState(false)
  const [produtosComDiaZerado, setProdutosComDiaZerado] = useState([])

  const [rows, setRows] = React.useState<any[]>([])
  // const [isFetching, setIsLoading] = React.useState(false)
  // const [isError, setIsError] = React.useState(false)

  // useEffect(() => {
  //   fetchData(props.date)
  // }, [props.date])

  // const fetchData = (date) => {
  //   const endpointRH = `rh/report?day=${moment(date).format('DD/MM/YYYY')}&month=${moment(date).format('M')}&year=${moment(date).format('YYYY')}`
  //   const endpointGoals = `previsions/report/meta?mes=${moment(date).format('M')}&ano=${moment(date).format('YYYY')}`
  //   const endpointProduction = `producao/dia?data=${moment(date).format('DD/MM/YYYY')}`

  //   setIsLoading(true)
  //   setIsError(false)
  //   httpClient.get(endpointRH).then(
  //     responseRh => {
  //       httpClient.get(endpointGoals).then(
  //         responseGoals => {
  //           httpLN.get(endpointProduction).then(
  //             responseProduction => {
  //               console.log('DADOS PRODU', responseRh.data, responseGoals.data, responseProduction.data.result)
  //               parseTableData(responseRh.data, responseGoals.data, responseProduction.data.result)
  //               setIsLoading(false)
  //             }
  //           ).catch(e => {
  //             console.log('e', e)
  //             setIsLoading(false)
  //             setIsError(true)
  //           })
  //         }
  //       ).catch(e => {
  //         console.log('e', e)
  //         setIsLoading(false)
  //         setIsError(true)
  //       })
  //     }
  //   ).catch(e => {
  //     console.log('e', e)
  //     setIsLoading(false)
  //     setIsError(true)
  //   })
  // }

  const queryMultiple = () => {
    // requisição externa do LN
    // const goalsReq = useQuery(['goals', date],
    //   async () => {
    //     return getReportGoals(date)
    //   },
    //   {
    //     refetchOnWindowFocus: false,
    //     cacheTime: 1000 * 60 * 5
    //   }
    // )

    const goalsReq = useQuery(['goals', date],
      async () => {
        return getTargetProduction(moment(date).format('YYYY'), moment(date).format('MM'))
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const rhReq = useQuery(['rh', date],
      async () => {
        return getReportRH(date)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const productionReq = useQuery(['production', date, multipleDate],
      async () => {
        return getReportProduction(date, multipleDate)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const impressoraDiaReq = useQuery(['impressora-dia', date, multipleDate],
      async () => {
        return getValorImpressoraDia(date, multipleDate)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const impressoraAcumuladoReq = useQuery(['impressora-acumulado', dia, mes, ano],
      async () => {
        return getValorImpressoraAcumulado(dia, mes, ano)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    return [goalsReq, rhReq, productionReq, impressoraDiaReq, impressoraAcumuladoReq]
  }

  const parseTableData = async (dataRH: any, dataMetas: any, dataProduction: any, impressoraDia: any, impressoraAcumulado: any) => {
    const data: any[] = []

    const caixasDP = {
      dia: 0,
      acumulado: 0
    }

    const chapasDP = {
      dia: 0,
      acumulado: 0
    }

    const papeisDP = {
      dia: 0,
      acumulado: 0
    }

    dataProduction.forEach(dp => {
      dp.dados.forEach(dado => {
        if (dp.tipo === '1') {
          if (dado.type === TipoProduto.CAIXA) {
            caixasDP.dia += parseFloat(dado.kg)
          }
          if (dado.type === TipoProduto.CHAPA) {
            chapasDP.dia += parseFloat(dado.kg)
          }
          if (dado.type === TipoProduto.PAPEL) {
            papeisDP.dia += parseFloat(dado.kg)
          }
        } else {
          if (dado.type === TipoProduto.CAIXA) {
            caixasDP.acumulado += parseFloat(dado.kg)
          }
          if (dado.type === TipoProduto.CHAPA) {
            chapasDP.acumulado += parseFloat(dado.kg)
          }
          if (dado.type === TipoProduto.PAPEL) {
            papeisDP.acumulado += parseFloat(dado.kg)
          }
        }
      })
    })

    let valorImpressoraDia
    if (typeof impressoraDia === 'object') {
      valorImpressoraDia = Number(impressoraDia.somaPesoLiquido)
    } else {
      valorImpressoraDia = 0
    }

    let valorImpressoraAcumulado
    if (typeof impressoraAcumulado === 'object') {
      valorImpressoraAcumulado = Number(impressoraAcumulado.somaPesoLiquido)
    } else {
      valorImpressoraAcumulado = 0
    }
    console.log({ goalsReq, rhReq, productionReq, valorImpressoraDia, valorImpressoraAcumulado })

    const plannedDaysCaixa = dataRH.find(v => v.production.name === 'Caixa')?.days?.length ?? 0
    const plannedDaysChapa = dataRH.find(v => v.production.name === 'Chapa')?.days?.length ?? 0
    const plannedDaysImpressora = plannedDaysCaixa
    const plannedDaysBobina = dataRH.find(v => v.production.name === 'Bobina')?.days?.length ?? 0

    const automaticWorkDaysCaixa = dataRH.find(v => v.production.name === 'Caixa')?.automaticWorksDays?.length ?? 0
    const automaticWorkDaysChapa = dataRH.find(v => v.production.name === 'Chapa')?.automaticWorksDays?.length ?? 0
    const automaticWorkDaysImpressora = automaticWorkDaysCaixa
    const automaticWorkDaysBobina = dataRH.find(v => v.production.name === 'Bobina')?.automaticWorksDays?.length ?? 0

    const monthKgBox = dataMetas?.map(v => v.product === '623cf565692e6d4c8fe20136' ? v.pesoKg : 0).reduce((a, b) => a + b)
    const montKgChapa = dataMetas?.map(v => v.product === '62321aa4fe24f24bc319b536' ? v.pesoKg : 0).reduce((a, b) => a + b)
    const monthKgPapel = dataMetas?.map(v => v.product === '62321a4dfe24f24bc319b535' ? v.pesoKg : 0).reduce((a, b) => a + b)

    data.push({
      produto: 'Caixa',
      mediaReal: !automaticWorkDaysCaixa ? 0 : (caixasDP.acumulado + caixasDP.dia) / automaticWorkDaysCaixa,
      diaKg: caixasDP.dia,
      acumuladoKg: caixasDP.acumulado + caixasDP.dia,
      diferencaKg: !plannedDaysCaixa ? 0 : (caixasDP.acumulado + caixasDP.dia) - ((monthKgBox / plannedDaysCaixa) * automaticWorkDaysCaixa),
      plannedDays: plannedDaysCaixa,
      automaticWorkDays: automaticWorkDaysCaixa
    })

    data.push({
      produto: 'Chapa',
      mediaReal: automaticWorkDaysChapa ? (chapasDP.acumulado + chapasDP.dia) / automaticWorkDaysChapa : 0,
      diaKg: chapasDP.dia,
      acumuladoKg: chapasDP.acumulado + chapasDP.dia,
      diferencaKg: plannedDaysChapa ? (chapasDP.acumulado + chapasDP.dia) - (((monthKgBox + montKgChapa) / plannedDaysChapa) * automaticWorkDaysChapa) : 0,
      plannedDays: plannedDaysChapa,
      automaticWorkDays: automaticWorkDaysChapa
    })

    data.push({
      produto: 'Impressora',
      mediaReal: automaticWorkDaysImpressora ? valorImpressoraAcumulado / automaticWorkDaysImpressora : 0,
      diaKg: valorImpressoraDia,
      acumuladoKg: valorImpressoraAcumulado,
      diferencaKg: plannedDaysImpressora ? valorImpressoraAcumulado - ((monthKgBox / plannedDaysCaixa) * automaticWorkDaysCaixa) : 0,
      plannedDays: plannedDaysImpressora,
      automaticWorkDays: automaticWorkDaysImpressora
    })

    data.push({
      produto: 'Papel',
      mediaReal: automaticWorkDaysBobina ? (papeisDP.acumulado + papeisDP.dia) / automaticWorkDaysBobina : 0,
      diaKg: papeisDP.dia,
      acumuladoKg: papeisDP.acumulado + papeisDP.dia,
      diferencaKg: plannedDaysBobina ? (papeisDP.acumulado + papeisDP.dia) - (((monthKgBox + montKgChapa + monthKgPapel) + ((monthKgBox + montKgChapa) * indiceRefugo)) / plannedDaysBobina) * automaticWorkDaysBobina : 0,
      plannedDays: plannedDaysBobina,
      automaticWorkDays: automaticWorkDaysBobina
    })
    console.log({ data })

    // se existem dias zerados, mostrar aviso ao usuário
    const products: any = [
      { label: 'Caixa', automaticWorkDays: automaticWorkDaysCaixa, plannedDays: plannedDaysCaixa },
      { label: 'Chapa', automaticWorkDays: automaticWorkDaysChapa, plannedDays: plannedDaysChapa },
      { label: 'Papel', automaticWorkDays: automaticWorkDaysBobina, plannedDays: plannedDaysBobina }
    ]

    const produtosDiaZerado = products
      .filter(product => !product.automaticWorkDays || !product.plannedDays)
      .map(product => product.label)

    setTemDiaZerado(produtosDiaZerado.length > 0)
    setProdutosComDiaZerado(produtosDiaZerado)

    const total = data.reduce((p, c) => {
      const tratado = {
        produto: 'Total',
        mediaReal: parseFloat((isFinite(p.mediaReal) ? p.mediaReal : 0)) + parseFloat((isFinite(c.mediaReal) ? c.mediaReal : 0)),
        diaKg: parseFloat(p.diaKg) + parseFloat(c.diaKg),
        acumuladoKg: parseFloat(p.acumuladoKg) + parseFloat(c.acumuladoKg),
        diferencaKg: parseFloat(p.diferencaKg) + parseFloat(c.diferencaKg)
      }
      return tratado
    })

    data.push(total)
    setRows(data)
  }

  const [goalsReq, rhReq, productionReq, impressoraDiaReq, impressoraAcumuladoReq] = queryMultiple()

  useEffect(() => {
    if (!goalsReq.isFetching && !rhReq.isFetching && !productionReq.isFetching && !impressoraDiaReq.isFetching && !impressoraAcumuladoReq.isFetching) {
      parseTableData(rhReq.data, goalsReq.data[0]?.previsions, productionReq.data, impressoraDiaReq.data, impressoraAcumuladoReq.data)
    }
  }, [goalsReq.isFetching, rhReq.isFetching, productionReq.isFetching, impressoraDiaReq.isFetching, impressoraAcumuladoReq.isFetching])

  return (
    <>
      {
        goalsReq.isFetching || rhReq.isFetching || productionReq.isFetching
          ? <Box sx={{ display: 'flex' }}
            style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
            <CircularProgress />
          </Box>
          : goalsReq.isError || rhReq.isError || productionReq.isError
            ? <Box sx={{ display: 'flex' }}
              style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
              <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
              <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
            </Box>
            : <>
              <TableContainer component={Paper} sx={{ height: 'min-content', width: 'min-content' }}>
                <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='left' sx={{
                        position: 'sticky',
                        left: 0,
                        zIndex: 3,
                        background: '#FFF'
                      }}>Produto</StyledTableCell>
                      <StyledTableCell align='left'>Média real kg</StyledTableCell>
                      <StyledTableCell align='left'>Dia kg</StyledTableCell>
                      <StyledTableCell align='left'>Acumulado kg</StyledTableCell>
                      <StyledTableCell align='left'>Diferença kg</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell align='left' sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 2,
                          background: '#FFF'
                        }}>
                          {row.produto}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {formatValue(row.mediaReal)}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {formatValue(row.diaKg)}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {formatValue(row.acumuladoKg)}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {formatValue(row.diferencaKg)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {
                temDiaZerado &&
                <>
                  <Typography sx={{ color: 'red', fontWeight: 'bold', fontSize: 14 }}>
                    Atenção: há valores não cadastrados de Dias Planejados/Trabalhados para:
                  </Typography>
                  {
                    produtosComDiaZerado.map((produto) => {
                      return <p>{`- ${produto}`}</p>
                    })
                  }
                </>
              }
            </>
      }
    </>
  )
}

export default TableProduction
