import React from 'react'
import { Card, CardHeader, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  rote: string
}

const CardView: React.FC<Props> = (props) => {
  return (
    <Grid container item xs={12}>
      <Link style={{ textDecoration: 'none', color: 'black' }} to={props.rote}>
        <Grid container item sm={12}>
          <Grid container item lg={12}>
            <Card sx={{ marginTop: 2, width: 500, height: 50 }} style={{ borderRadius: '8px' }}>
              <CardHeader
                title={props.title}
                sx={{ color: '#7F85F9', '& span': { fontSize: '1.2rem' } }}
              />
            </Card>
          </Grid>
        </Grid>
      </Link>
    </Grid>
  )
}

export default CardView
