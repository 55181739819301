import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  Icon
} from '@mui/material'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportBilling, getReportTipoPapel } from 'services/report/ReportBilling'
import TableIPBody from './TableIPBody'

interface Props {
  date: string
}

const TableIPHead: React.FC<Props> = (props) => {
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const [dataReq1, setDataReq1] = useState<any>([])

  const queryMultiple = () => {
    const req1 = useQuery(['sales-report', mes, ano],
      async () => {
        return getReportBilling(mes, ano)
      },
      {
        onSuccess: (data) => {
          console.log('req1->getReportBilling =>', data)
          setDataReq1(data)
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )
    const req2 = useQuery(['papel-report', props.date],
      async () => {
        return getReportTipoPapel(props.date)
      },
      {
        onSuccess: (data) => {
          const tipoDeDado = typeof data
          console.log('Tipo de dado:', tipoDeDado)
          if (Array.isArray(data)) {
            console.log('É um array:', data)
          } else if (typeof data === 'object' && data !== null && Symbol.iterator in data) {
            console.log('É um objeto iterável:', data)
            const arrayData = Array.from(data)
            console.log('Array:', arrayData)
          } else {
            console.log('Não é um objeto iterável:', data)
            const arrayData = Object.values(data)
            console.log('Array personalizado:', arrayData)
            dataHandle(dataReq1, arrayData)
          }
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )
    return [req1, req2]
  }

  const [req1, req2] = queryMultiple()
  const dataHandle = (valor, realized) => {
    console.log('realized', realized)
  }

  return (
    <>
      {(req1.isLoading || req1.isFetching || req2.isLoading || req2.isFetching) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {(req1.isError && req2.isError) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>
            signal_wifi_statusbar_connected_no_internet_4
          </Icon>
        </Box>
      )}
      {(req1.isSuccess && !req1.isError && !req1.isFetching && !req2.isError && !req2.isFetching) && (
        <>
          <TableIPBody date={props.date}/>
        </>
      )}

    </>
  )
}

export default TableIPHead
