import { Avatar, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import paperImg from '../../assets/img/formpaper.png'
import boxImg from '../../assets/img/formbox.png'
import plateImg from '../../assets/img/formplate.png'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'

export function PreFTPortfolio() {
  return (
    <>
    <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'FT', url: '/home/ft' },
          { text: 'Portfólio' }
        ]}
      />

      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: 2, marginTop: 2 }}>
        Portfólio
      </Typography>

      <Stack direction="row" spacing={2} sx={{ paddingTop: 2 }}>
        <Button
          component={Link}
          to="/home/ft/portfolio/coil"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 120,
            width: 120
          }}
        >
          <Avatar
            src={paperImg}
            sx={{ width: 100, height: 100, borderRadius: 1 }}
          />
          <Typography component="span" variant="body2">
            Bobina
          </Typography>
        </Button>
        <Button
          component={Link}
          to="/home/ft/portfolio/plate"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 120,
            width: 120
          }}
        >
          <Avatar
            src={plateImg}
            sx={{ width: 100, height: 100, borderRadius: 1 }}
          />
          <Typography component="span" variant="body2">
            Chapa
          </Typography>
        </Button>
        <Button
          component={Link}
          to="/home/ft/portfolio/box"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 120,
            width: 120
          }}
        >
          <Avatar
            src={boxImg}
            sx={{ width: 100, height: 100, borderRadius: 1 }}
          />
          <Typography component="span" variant="body2">
            Caixa
          </Typography>
        </Button>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" sx={{ paddingTop: 4 }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/home/ft"
        >
          Voltar
        </Button>
      </Stack>
    </>
  )
}
