/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import alertSuccess from 'components/Alerts/ModalSucess'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { RootState } from 'core/store'
import { useFormik } from 'formik'
import {
  StyledTableCell,
  StyledTableRow
} from 'pages/company/portfolio/components/table.styles'
import React from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { postProposal } from 'services/ft/FTService'
import { FTProposalType } from 'types/ft/FTProposalType'
import { validationProposalFT } from 'types/validation/ValidationProposalFT'

interface Props {
  proposalData: FTProposalType | undefined
  ft: any
  confirm: (values) => any
  close: () => void
}

function ResumeProposalForm(props: Props) {
  const { proposalData, ft } = props
  console.log('ft', ft)

  const { loginData } = useSelector((state: RootState) => state.login)
  console.log('LOGIN', loginData?.email, loginData?.tokenData)

  const [assigned, setAssigned] = React.useState(false)
  const [hiddenGrammage, setHiddenGrammage] = React.useState(false)

  const { errors, touched, values, handleSubmit, setFieldValue } = useFormik({
    onSubmit: (inputValues: FTProposalType) => {
      console.log('valores da proposta', inputValues)
      console.log('ft', ft)
      mutateCreate(inputValues)
    },
    enableReinitialize: true,
    validationSchema: validationProposalFT,
    initialValues: {
      status: proposalData?.status ?? 'IN_ANALYSIS',
      gramature: proposalData?.gramature ?? 0,
      internalMeasures: proposalData?.internalMeasures ?? '0x0x0',
      observation:
        'Em caso de lote piloto não faturado em até 60 dias, será cobrado o valor da ferramenta desenvolvida (faca e/ou clichê), bem como o estoque produzido.',
      price: proposalData?.price ?? 0,
      shipping: proposalData?.shipping ?? 0,
      minimumLot: proposalData?.minimumLot ?? 0,
      paymentCondition: proposalData?.paymentCondition ?? '',
      financingRate: proposalData?.financingRate ?? 0,
      icms: proposalData?.icms ?? 0,
      validity: proposalData?.validity ?? 30,
      deadlineDeliverDays: proposalData?.deadlineDeliverDays ?? 30,
      budget: proposalData?.budget ?? '',
      user: loginData?.tokenData.sub ?? '',
      isDirectorship: proposalData?.isDirectorship ?? false,
      hiddenGrammage: hiddenGrammage ?? false,
      typeOfFreight: ''
    }
  })

  const { mutate: mutateCreate } = useMutation(postProposal, {
    onSuccess: (data) => {
      console.log('proposta criada', data)
      alertSuccess(
        'Proposta de orçamento enviado com sucesso!',
        'O Cliente poderá aprovar ou reprovar a proposta.',
        () => {
          props.confirm(null)
        }
      )
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ?? 'Não foi possível criar a proposta'
      )
    }
  })

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip
          title="Voltar"
          placement="top"
          onClick={() => {
            props.close()
          }}
        >
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>
          Proposta
        </div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginRight: '30px',
              marginTop: '20px'
            }}
          >
            <div style={{ marginTop: 5 }}>
              Informações do orçamento que serão enviadas como proposta para o
              cliente aprovar ou reprovar
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px'
                }}
              >
                <div>
                  <TableContainer
                    style={{ marginTop: '30px' }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 550 }} aria-label="customized table">
                      <TableBody>
                        <StyledTableRow key={0}>
                          <StyledTableCell align="left">
                            Gramatura
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.gramature}
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow key={0}>
                          <StyledTableCell align="left">
                            Ocultar o valor da gramatura para o cliente na
                            proposta
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <FormGroup style={{ marginTop: '10px' }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={hiddenGrammage}
                                    onChange={(v, checked) => {
                                      setHiddenGrammage(checked)
                                      setFieldValue('hiddenGrammage', checked)
                                    }}
                                  />
                                }
                                label={hiddenGrammage ? 'Sim' : 'Não' }
                              />
                            </FormGroup>
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={1}>
                          <StyledTableCell align="left">
                            Medida interna
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.internalMeasures}
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={2}>
                          <StyledTableCell align="left">Preço</StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.price}
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={3}>
                          <StyledTableCell align="left">Frete</StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.shipping}
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={4}>
                          <StyledTableCell align="left">
                            Lote mínimo
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.minimumLot}
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* <StyledTableRow key={5}>
                          <StyledTableCell align='left'>
                            Condição de pagamento
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard" defaultValue={values.paymentCondition} disabled />
                          </StyledTableCell>
                        </StyledTableRow> */}

                        <StyledTableRow key={6}>
                          <StyledTableCell align="left">
                            Taxa de financiamento
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.financingRate}
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={7}>
                          <StyledTableCell align="left">ICMS</StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.icms}
                              disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={8}>
                          <StyledTableCell align="left">
                            * Validade da proposta
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.validity}
                              type="number"
                              onChange={(event) => {
                                setFieldValue('validity', event.target.value)
                              }}
                              error={Boolean(
                                errors.validity && touched.validity
                              )}
                              helperText={
                                errors.validity && touched.validity
                                  ? errors.validity
                                  : null
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={9}>
                          <StyledTableCell align="left">
                            * Prazo de entrega
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              variant="standard"
                              defaultValue={values.deadlineDeliverDays}
                              type="number"
                              onChange={(event) => {
                                setFieldValue(
                                  'deadlineDeliverDays',
                                  event.target.value
                                )
                              }}
                              error={Boolean(
                                errors.deadlineDeliverDays &&
                                  touched.deadlineDeliverDays
                              )}
                              helperText={
                                errors.deadlineDeliverDays &&
                                touched.deadlineDeliverDays
                                  ? errors.deadlineDeliverDays
                                  : null
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={9}>
                          <StyledTableCell align="left">
                            * Tipo de frete
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              fullWidth
                              variant="standard"
                              defaultValue={values.typeOfFreight}
                              type="text"
                              onChange={(event) => {
                                setFieldValue(
                                  'typeOfFreight',
                                  event.target.value
                                )
                              }}
                              error={Boolean(
                                errors.typeOfFreight && touched.typeOfFreight
                              )}
                              helperText={
                                errors.typeOfFreight && touched.typeOfFreight
                                  ? errors.typeOfFreight
                                  : null
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={10}>
                          <StyledTableCell
                            style={{ width: '200px' }}
                            align="left"
                          >
                            Observação
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              id="outlined-multiline-static"
                              autoComplete="off"
                              fullWidth
                              multiline
                              rows={5}
                              value={values.observation}
                              defaultValue={
                                'Em caso de lote piloto não faturado em até 60 dias, será cobrado o valor da ferramenta desenvolvida (faca e/ou clichê), bem como o estoque produzido.'
                              }
                              onChange={(event) => {
                                setFieldValue(
                                  'observation',
                                  event.target.value
                                )
                              }}
                              error={Boolean(
                                errors.observation && touched.observation
                              )}
                              helperText={
                                errors.observation && touched.observation
                                  ? errors.observation
                                  : null
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <FormGroup style={{ marginTop: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(v, o) => {
                            setAssigned(o)
                          }}
                        />
                      }
                      label="* Confirmar assinatura"
                    />
                  </FormGroup>

                  <FormGroup style={{ marginTop: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={values.isDirectorship}
                          onChange={(v, checked) => {
                            setFieldValue('isDirectorship', checked)
                          }}
                        />
                      }
                      label="Aprovação Controladoria"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type="button"
              mold="red"
              onClick={() => {
                props.close()
              }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold="primary"
              type="submit"
              disabled={!assigned}
            >
              SALVAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default ResumeProposalForm
