/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import {
  ButtonRow,
  DialogStyle
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import ModalHead from './ModalHead';
import { useQuery } from 'react-query';
import { httpClient } from 'config/httpClient';
import { Accordion } from '../components/Accordion';
import Button from 'components/form/Button';
import { toast } from 'react-toastify';
import { SolicitationToolItem } from '../components/SolicitationToolItem';

interface Props {
  open: boolean
  close: () => void
  data?: any
  bookFinished?: boolean
}

const SolicitationTool: React.FC<Props> = (props) => {
  const { bookFinished } = props
  console.log(props.data)
  const closeModal = () => props.close();
  const [solicitations, setSolicitations] = useState<any[]>([
    { pedido: props?.data?.pedido, ft: props?.data?.ft?._id, codItem: props?.data?.codItem }
  ]);

  const { data, status, isFetching } = useQuery(
    ['modal-booking-solicitation-tool', props?.data?.pedido],
    async () =>
      await httpClient.get<any[]>(
        `/bookings/solicitation-tool/pedido/${props?.data?.pedido}/${props?.data?.ft?._id ?? props?.data?.codItem}`
      ),
    {
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    setSolicitations(
      data?.data?.length
        ? data.data
        : [{ pedido: props?.data?.pedido, ft: props?.data?.ft?._id, codItem: props?.data?.codItem }]
    );
  }, [data?.data, isFetching]);

  const handleDeleteSolicitation = async (index: number, id?: string) => {
    if (id) {
      setSolicitations((state) => state.filter((item) => item?._id !== id));
      try {
        await httpClient.delete(`bookings/solicitation-tool/${id}`);

        toast.success('Deletado com sucesso!');
        setSolicitations([
          { pedido: props?.data?.pedido, ft: props?.data?.ft?._id }
        ]);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
      return;
    }
    setSolicitations((state) =>
      state.filter((_, itemIndex) => itemIndex !== index)
    );
  };

  return (
    <>
      <DialogStyle
        open={props.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHead
          title="Desenho do ferramental"
          idPreFT={props?.data?.ft?.preFT?._id}
        />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {solicitations?.map((attachment: any, index) => (
              <SolicitationToolItem
                key={`${attachment?._id}-${index}`}
                data={attachment}
                cod={`${attachment?._id}-${index}`}
                bookFinished={bookFinished}
                onChange={(values) => {
                  setSolicitations((state) =>
                    state?.map((current, currentIndex) =>
                      currentIndex === index
                        ? { ...current, ...values }
                        : current
                    )
                  );
                }}
              >
                {!bookFinished && <Button
                  mold="red"
                  type="button"
                  onClick={async () =>
                    handleDeleteSolicitation(index, attachment?._id)
                  }
                >
                  Remover
                </Button>}
              </SolicitationToolItem>
            ))}

            <ButtonRow
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                marginTop: 30
              }}
            >
              <Button
                type="button"
                mold="primary"
                onClick={() => {
                  props.close();
                }}
              >
                Voltar
              </Button>
            </ButtonRow>
          </DialogContentText>
        </DialogContent>
      </DialogStyle>
    </>
  );
};

export default SolicitationTool;
