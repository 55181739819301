import React from 'react'

interface Props {
  status: boolean
}

const StatusView: React.FC<Props> = (props) => {
  return (
    <div style={{
      borderRadius: '3px',
      color: props.status ? '#72CE2B' : '#EE207A',
      padding: '4px 8px',
      border: '1px solid',
      textAlign: 'center',
      backgroundColor: props.status ? '#EBFFD8' : '#FFDDEC'
    }}>
      {props.status ? 'Ativo' : 'Inativo'}
    </div>
  )
}

export default StatusView
