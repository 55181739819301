/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/quotes */
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Step,
  StepButton,
  Stepper
} from "@mui/material"
import Button from "components/form/Button"
import GenericDialog from "components/genericDialog/GenericDialog"
import IconAndTitleView from "components/view/IconAndTitleView"
import moment from "moment"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { FTBudgetType } from "types/ft/FTBudgetType"
import MaterialValuesForm from "../components/MaterialValuesForm"
import ResumeProposalForm from "../components/ResumeProposalForm"
import BudgetSampleBoxStep from "../stepsBoxPlate/BudgetSampleBoxStep"
import DataSampleBoxStep from "../stepsBoxPlate/DataSampleBoxStep"
import ProposalBoxStep from "../stepsBoxPlate/ProposalBoxStep"
import FisicSampleBoxStep from "../stepsBoxPlate/FisicSampleBoxStep"
import { useMutation, useQuery } from "react-query"
import { getFTByReference, postFT } from "services/ft/FTService"
import { FTProposalType } from "types/ft/FTProposalType"
import ResumeViewProposal from "../components/ResumeViewProposal"
import DrawSampleBoxStep from "./DrawSampleBoxStep"
import ArtSampleStep from "./ArtSampleStep"

function AuditFT(props: any) {
  const navigate = useNavigate()
  const { state, search: searchLocation }: any = useLocation()
  console.log('AuditFT -> searchLocation ', searchLocation)

  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean
  }>([true])
  const [openBudgetModal, setOpenBudgetModal] = React.useState(false)
  const [openProposalModal, setOpenProposalModal] = React.useState(false)
  const [openViewProposalModal, setOpenViewProposalModal] =
    React.useState(false)
  const [budgetId, setBudgetId] = React.useState("")
  const [budgetData, setBudgetData] = React.useState<FTBudgetType>()
  const [proposalData, setProposalData] = React.useState<FTProposalType>()
  const [proposalViewData, setProposalViewData] =
    React.useState<FTProposalType>()
  const [existsProposal, setExistsProposal] = React.useState(false)
  const [ft, setFT] = React.useState<any>()

  const { mutate: mutateEdit } = useMutation(postFT, {
    onSuccess: (data) => {
      console.log("ft criada", data)
      setFT(data)
    }
  })

  console.log("TrimboxData", props.ftTrimboxData)

  const ftReq = useQuery(
    ["ft-referencia", state.ftTrimbox],
    async () => {
      console.log("state.ftTrimboxData", state.ftTrimboxData)
      return getFTByReference(state.ftTrimbox.codConjunto)
    },
    {
      onSuccess: (data) => {
        console.log("ft existente", data)
        setFT(data)
        const localFT = state.ft ?? ft
        const newCompleted = completed
        if (localFT.existsBudget) {
          newCompleted[0] = true
          newCompleted[1] = true
        }
        if (localFT.existsProposal) {
          newCompleted[0] = true
          newCompleted[1] = true
          newCompleted[2] = true
        }
        if (localFT.existsAmostra) {
          newCompleted[0] = true
          newCompleted[1] = true
          newCompleted[2] = true
          newCompleted[3] = true
        }
        setCompleted(newCompleted)
      },
      onError: () => {
        mutateEdit({
          itemReference: state.ftTrimbox.codConjunto,
          preFT: state.ftTrimbox.codERP,
          status: state.status
        })
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const handleNext = () => {
    const newActiveStep =
      activeStep === steps.length - 1 && !(Object.keys(completed).length === steps.length)
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  const handleBudgetModal = () => {
    setOpenBudgetModal(true)
  }

  const handleProposalModal = () => {
    setOpenProposalModal(true)
  }

  const handleConfirmBudget = (values) => {
    setOpenBudgetModal(false)
    setBudgetData(values)
    handleComplete()
    handleNext()
  }

  const handleConfirmProposal = (values) => {
    setOpenProposalModal(false)
    handleComplete()
    handleNext()
  }

  props = {
    form: {
      status: state.ftTrimbox.statusFT,
      colaborador: state.preft.collaborator?.name ?? "",
      cliente: state.preft.client?.name ?? "",
      createdAt: state.ftTrimbox.dataCriacao,
      _id: state.ftTrimbox.codConjunto,
      type: state.type
    }
  }

  const RetangleView = styled.div`
    width: 25px;
    height: 60px;
    background-color: "#5f7aea";
  `
  const StepButtonStyled = styled(StepButton)`
    .Mui-completed path {
      color: #72ce2b;
    }
    .Mui-active {
      fill: rgb(57, 118, 186) !important;
    }
  `

  let stepsData, steps, stepsStatus
  if (props.form.type === "Chapa") {
    stepsData = [
      state.type === "Chapa" ? (
        <DataSampleBoxStep formPlate={state.preft} ft={ft} />
      ) : (
        <DataSampleBoxStep formBox={state.preft} ft={ft} />
      ),
      <BudgetSampleBoxStep
        setBudgetId={(id) => {
          setBudgetId(id)
        }}
        confirm={(values) => setProposalData(values)}
        ftTrimboxData={state.ftTrimbox}
        preft={state.preft}
        budgetData={budgetData}
        ft={ft}
      />,
      <ProposalBoxStep
        setExistsProposal={(exists) => setExistsProposal(exists)}
        budgetId={budgetId}
        setViewProposal={(dataView) => {
          setOpenViewProposalModal(true)
          setProposalViewData(dataView)
        }}
        ft={ft}
        back={() => {
          setActiveStep(1)
        }}
      />
    ]
    steps = ["Dados da FT", "Orçamento", "Proposta"]
    stepsStatus = ["Dados", "Orçamento", "Proposta"]
  } else {
    stepsData = [
      state.type === "Chapa" ? (
        <DataSampleBoxStep formPlate={state.preft} ft={ft} />
      ) : (
        <DataSampleBoxStep formBox={state.preft} ft={ft} />
      ),
      <BudgetSampleBoxStep
        setBudgetId={(id) => {
          setBudgetId(id)
        }}
        confirm={(values) => setProposalData(values)}
        ftTrimboxData={state.ftTrimbox}
        preft={state.preft}
        budgetData={budgetData}
        ft={ft}
      />,
      <ProposalBoxStep
        setExistsProposal={(exists) => setExistsProposal(exists)}
        budgetId={budgetId}
        setViewProposal={(dataView) => {
          setOpenViewProposalModal(true)
          setProposalViewData(dataView)
        }}
        ft={ft}
        back={() => {
          setActiveStep(1)
        }}
      />,
      ft?.status === "Desenvolvimento" ? (
        <DrawSampleBoxStep ft={ft} />
      ) : (
        <FisicSampleBoxStep ft={ft} />
      ),
      state.type === "Chapa" ? <></> : <ArtSampleStep ft={ft} />
    ]

    steps =
      state.type === "Chapa"
        ? [
          "Dados da FT",
          "Orçamento",
          "Proposta",
          ft?.status === "Desenvolvimento" ? "Desenho" : "Amostra"
        ]
        : [
          "Dados da FT",
          "Orçamento",
          "Proposta",
          ft?.status === "Desenvolvimento" ? "Desenho" : "Amostra",
          "Arte"
        ]

    stepsStatus =
      state.type === "Chapa"
        ? [
          "Dados",
          "Orçamento",
          "Proposta",
          ft?.status === "Desenvolvimento" ? "Desenho" : "Amostra"
        ]
        : [
          "Dados",
          "Orçamento",
          "Proposta",
          ft?.status === "Desenvolvimento" ? "Desenho" : "Amostra",
          "Arte"
        ]
  }

  if (ftReq.isFetching) {
    return (
      <Box
        sx={{ display: "flex" }}
        style={{
          justifyContent: "center",
          marginTop: "124px",
          marginRight: "88px"
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <RetangleView />
        <Card style={{ display: "flex", width: "100%", paddingBottom: "0px" }}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              paddingBottom: "0px",
              paddingRight: "0px"
            }}
          >
            <div style={{ marginRight: "38px", fontWeight: "600" }}>
              {stepsStatus[activeStep]}
            </div>
            <IconAndTitleView
              icon="person_outline"
              title={`Emitido por: ${props.form.colaborador}`}
            />
            <IconAndTitleView
              icon="calendar_today"
              title={moment(props.form.createdAt).format("DD/MM/YYYY")}
            />
            <IconAndTitleView
              icon="schedule"
              title={moment(props.form.createdAt).format("HH:mm")}
            />
            <IconAndTitleView
              icon="assignment_outline"
              title={`FT ${props.form._id.substring(0, 8)}`}
            />
            <IconAndTitleView
              icon="content_paste"
              title={`Produto: ${props.form.type}`}
            />
            <IconAndTitleView
              icon="person_outline"
              title={`Para: ${props.form.cliente}`}
            />
          </CardContent>
        </Card>
      </div>

      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButtonStyled
                  disabled={!completed[index] && !activeStep}
                  onClick={handleStep(index)}
                >
                  {label}
                </StepButtonStyled>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
      {stepsData[activeStep]}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
          marginTop: 30
        }}
      >
        {activeStep === 0 && (
          <Button
            type="button"
            mold="red"
            onClick={() => navigate(`/home/ft${searchLocation}`)}
            style={{ marginRight: "10px" }}
          >
            Cancelar
          </Button>
        )}

        {activeStep !== 0 && (
          <Button
            type="button"
            onClick={() => {
              setActiveStep(activeStep - 1)
            }}
            style={{ marginRight: "10px" }}
          >
            Voltar
          </Button>
        )}

        {activeStep === 0 && (
          <Button
            type="button"
            mold="primary"
            onClick={handleBudgetModal}
            style={{ marginRight: "10px" }}
          >
            Próximo
          </Button>
        )}

        {activeStep === 1 && budgetId !== "" && (
          <Button
            type="button"
            mold="green"
            onClick={handleProposalModal}
            style={{ marginRight: "10px" }}
          >
            Gerar Proposta
          </Button>
        )}
        {activeStep === 1 && (
          <Button
            type="button"
            mold="primary"
            onClick={handleNext}
            style={{ marginRight: "10px" }}
          >
            Próximo
          </Button>
        )}

        {(activeStep === 2 || activeStep === 3) &&
          existsProposal &&
          props.form.type !== "Chapa" && (
            <Button
              type="button"
              mold="primary"
              onClick={handleNext}
              style={{ marginRight: "10px" }}
            >
              Próximo
            </Button>
          )}

        {activeStep === 3 && props.form.type !== "Chapa" && (
          <Button
            type="button"
            mold="primary"
            onClick={handleNext}
            style={{ marginRight: "10px" }}
          >
            Próximo
          </Button>
        )}

        {props.form.type === "Chapa" && activeStep === 2 && (
          <Button
            type="button"
            mold="primary"
            onClick={() => {
              navigate(`/home/ft${searchLocation}`)
            }}
            style={{ marginRight: "10px" }}
          >
            Fechar
          </Button>
        )}

        {activeStep === 4 && (
          <Button
            type="button"
            mold="primary"
            onClick={() => {
              navigate(`/home/ft${searchLocation}`)
            }}
            style={{ marginRight: "10px" }}
          >
            Fechar
          </Button>
        )}
      </div>

      {openBudgetModal && (
        <GenericDialog
          open={openBudgetModal}
          close={() => setOpenBudgetModal(false)}
          content={
            <MaterialValuesForm
              confirm={(values) => handleConfirmBudget(values)}
              close={() => setOpenBudgetModal(false)}
            />
          }
        />
      )}

      {openProposalModal && (
        <GenericDialog
          open={openProposalModal}
          close={() => setOpenProposalModal(false)}
          content={
            <ResumeProposalForm
              ft={ft}
              proposalData={proposalData}
              confirm={(values) => handleConfirmProposal(values)}
              close={() => setOpenProposalModal(false)}
            />
          }
        />
      )}

      {openViewProposalModal && (
        <GenericDialog
          open={openViewProposalModal}
          close={() => setOpenViewProposalModal(false)}
          content={
            <ResumeViewProposal
              proposalData={proposalViewData}
              close={() => setOpenViewProposalModal(false)}
            />
          }
        />
      )}
    </div>
  )
}

export default AuditFT
