import styled from 'styled-components'

export const RowCV = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const LabelTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const TooltipContent = styled.div<{ color: string }>`
  background-color: white;
  padding: 5px;
  border: 1px solid ${(props) => props.color};
  border-radius: 5px;
`
