import * as React from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { Menu, MenuItem } from '@mui/material'
import TableUsers from './components/TableUsers'
import { useNavigate } from 'react-router-dom'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import HeaderView from 'components/view/HeaderView'

export default function Registration() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Can
      module={MODULES.USUÁRIOS}
      permissions={[PERMISSIONS.READ]}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr repeat(2, minmax(auto, max-content))',
            gap: '15px'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
            <div>
              <HeaderView register={() => navigate('/home/user-form')} />
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          </div>
          <div>
            <ButtonHeader
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <SettingsOutlinedIcon />
            </ButtonHeader>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem onClick={() => navigate('/home/managementControl/management/link-app')}>Link de reenvio</MenuItem>
            </Menu>
          </div>
        </div>
        <TableUsers />
      </Box>
    </Can>
  )
}
