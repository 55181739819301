import { onlyNumbers } from './stringHelper'

const maskCpf = (value: string): string => {
  return onlyNumbers(value).replace(
    /^(\d{3})(\d{3})(\d{3})(\d{2})/,
    '$1.$2.$3-$4'
  )
}

const maskCnpj = (value: string): string => {
  return onlyNumbers(value).replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5'
  )
}

const maskCpfOrCnpj = (value?: string) => {
  const valueFormatted = onlyNumbers(String(value))
  return valueFormatted.length > 11
    ? maskCnpj(valueFormatted)
    : maskCpf(valueFormatted)
}

export { maskCnpj, maskCpf, maskCpfOrCnpj }
