import styled from 'styled-components'

const ActionButton = styled.button`
  border: none;
  height: 32px;
  width: 32px;
  padding: 5px;
  color: #fff;
  border-radius: 3px;
  display: flex;
  place-content: center;
  align-items: center;
  background: ${(props) => props.color ?? '#4060e6'};
`

const ActionContainer = styled.div`
  padding: 8px;
  display: flex;
  gap: 0px 10px;
`

export { ActionButton, ActionContainer }
