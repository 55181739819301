/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { DialogStyle } from '../../reportControl/deliveryMap/styles/dialogMap.styles';

import { AttachFileOutlined } from '@mui/icons-material';
import ButtonPce from 'components/form/Button';
import { httpClient } from 'config/httpClient';
import { RootState } from 'core/store';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ModalHead from './ModalHead';
import { ModalSignaturePurchaseRequest } from './ModalSignaturePurchaseRequest';

interface Props {
  open: boolean
  close: () => void
  data?: any
  purchaseRequest?: any
  bookFinished?: boolean
}

const PurchaseRequest: React.FC<Props> = (props) => {
  const { purchaseRequest, bookFinished } = props;
  const closeModal = () => props.close();
  console.log('PEDIDO', props.data);
  const { loginData } = useSelector((state: RootState) => state.login)
  console.log(loginData?.tokenData?.sub)

  const { data } = useQuery(
    ['modal-booking-conclusao', props?.data?.pedido],
    async () =>
      await httpClient.get<any>(
        `/bookings/pdf/download/${props?.data?.pedido}/${
          props?.data?.ft?._id ?? props?.data?.codItem
        }`
      ),
    {
      refetchOnWindowFocus: false
    }
  );

  const desenhoTecnico = !!data?.data?.desenhoTecnico?.length
  const desenhoFerramental = !!data?.data?.desenhoFerramental?.length
  const orcamentoFerramental = !!data?.data?.orcamentoFerramental?.length

  const allowSignature = desenhoTecnico && desenhoFerramental && orcamentoFerramental

  const [open, setOpen] = useState(false);
  const [selectSignature, setSelectSignature] = useState<
    | 'setorVendas'
    | 'clicheria'
    | 'desenvolvimento'
    | 'compras'
    | 'gerenciaComercial'
    | 'coordenador'
    | null
  >(null);

  const getPaper = (data: string[]) => {
    let paper = '';
    data?.forEach((item) => {
      if (item?.length) {
        paper = `${paper}${item},`;
      }
    });
    return paper.slice(0, -1);
  };

  const { isSubmitting, values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      ft: purchaseRequest?.ft ?? props?.data?.ft?._id ?? null,
      pedido: purchaseRequest?.pedido ?? props?.data?.pedido ?? '',
      codItem: purchaseRequest?.codItem ?? props?.data?.codItem ?? '',
      informacao: purchaseRequest?.informacao ?? [
        {
          name: 'Clichê Impressão em Polímero',
          checked: false,
          value: ''
        },
        {
          name: 'Clichê Metálico Plana',
          checked: false,
          value: ''
        },
        {
          name: 'Ficha de Impressão',
          checked: false,
          value: ''
        },
        {
          name: 'Clichê Metálico Rotativo',
          checked: false,
          value: ''
        },
        {
          name: 'Rótulo',
          checked: false,
          value: ''
        }
      ],
      nomePedido: purchaseRequest?.nomePedido ?? '',
      qtde: purchaseRequest?.qtde ?? '',
      entrega: purchaseRequest?.entrega ?? null,
      nomeCliente:
        purchaseRequest?.nomeCliente ??
        props?.data?.ft?.preFT?.client?.name ??
        '',
      medidaInterna: purchaseRequest?.medidaInterna ?? {
        altura:
          purchaseRequest?.altura ??
          props?.data?.ft?.preFT?.preftbox?.internalMeasures?.height ??
          '',
        largura:
          purchaseRequest?.largura ??
          props?.data?.ft?.preFT?.preftbox?.internalMeasures?.width ??
          '',
        comprimento:
          purchaseRequest?.comprimento ??
          props?.data?.ft?.preFT?.preftbox?.internalMeasures?.diameter ??
          '',
        referencia: purchaseRequest?.referencia ?? '',
        tipoPapel:
          purchaseRequest?.tipoPapel ??
          getPaper([
            props?.data?.ft?.preFT?.preftbox?.specifications?.Composicao
              ?.papel1,
            props?.data?.ft?.preFT?.preftbox?.specifications?.Composicao
              ?.papel2,
            props?.data?.ft?.preFT?.preftbox?.specifications?.Composicao
              ?.papel3,
            props?.data?.ft?.preFT?.preftbox?.specifications?.Composicao
              ?.papel4,
            props?.data?.ft?.preFT?.preftbox?.specifications?.Composicao
              ?.papel5
          ]) ??
          '',
        tipoOnda:
          purchaseRequest?.tipoOnda ??
          props?.data?.ft?.preFT?.preftbox?.specifications?.Composicao?.onda ??
          '',
        impressora: purchaseRequest?.impressora ?? ''
      },
      tipoImpressora: purchaseRequest?.tipoImpressora ?? '',
      tipoCompra: purchaseRequest?.tipoCompra ?? '',
      dataNPcsProduzidas: purchaseRequest?.dataNPcsProduzidas ?? '',
      dataPcsUltimaCompraCliche:
        purchaseRequest?.dataPcsUltimaCompraCliche ?? null,
      clicheInicialDiluido: purchaseRequest?.clicheInicialDiluido ?? '',
      requeridoPor: purchaseRequest?.requeridoPor ?? {
        desenhoEspecificacao: purchaseRequest?.desenhoEspecificacao ?? false,
        amostra: purchaseRequest?.amostra ?? false,
        copiaCliche: purchaseRequest?.copiaCliche ?? false
      },
      informacoesCores: purchaseRequest?.informacoesCores ?? [
        {
          cor: '',
          referencia: '',
          opcao: ''
        }
      ],
      numeroProposta: purchaseRequest?.numeroProposta ?? '',
      observacao: purchaseRequest?.observacao ?? '',
      setorSolicitante: purchaseRequest?.setorSolicitante ?? '',
      assinaturas: purchaseRequest?.assinaturas ?? {
        setorVendas: {
          lastSend: '',
          tam: '',
          mimetype: '',
          url: '',
          status: ''
        },
        clicheria: {
          lastSend: '',
          tam: '',
          mimetype: '',
          url: '',
          status: ''
        },
        desenvolvimento: {
          lastSend: '',
          tam: '',
          mimetype: '',
          url: '',
          status: ''
        },
        compras: {
          lastSend: '',
          tam: '',
          mimetype: '',
          url: '',
          status: ''
        },
        gerenciaComercial: {
          lastSend: '',
          tam: '',
          mimetype: '',
          url: '',
          status: ''
        },
        coordenador: {
          lastSend: '',
          tam: '',
          mimetype: '',
          url: '',
          status: ''
        }
      }
    },
    onSubmit(values) {
      const body = values;

      if (purchaseRequest?._id) {
        httpClient
          .put(`bookings/purchase-request/${purchaseRequest?._id}`, body)
          .then(() => {
            toast.success('Atualizado com sucesso');
            props.close();
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
          });
      } else {
        httpClient
          .post('bookings/purchase-request', body)
          .then(() => {
            toast.success('Salvo com sucesso');
            props.close();
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
          });
      }
    }
  });

  function formatReal(valor) {
    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ',$1');

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    }

    let value = valor;
    if (valor === 'NaN') {
      return (value = '');
    }
    return value;
  }

  const removeSignature = (id: string, type: string, user: string) => {
    console.log(user !== loginData?.tokenData?.sub, !loginData?.tokenData?.profile?.includes('ADMIN'))
    if (user !== loginData?.tokenData?.sub && !loginData?.tokenData?.profile?.includes('ADMIN')) {
      toast.warning('Somente o usuário que assinou pode remover a assinatura.')
      return
    }

    httpClient
      .put(`bookings/purchase-request/remove-signature/${id}`, {
        signature: type
      })
      .then(() => {
        toast.success('Assinatura removida com sucesso');
        props.close();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <DialogStyle
        open={props.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHead
          title="Solicitação para compra"
          idPreFT={props?.data?.ft?.preFT?._id}
          codItem={props?.data?.codItem ?? props?.data?.ft?.itemReference}
        />

        {false && status === 'loading' && (
          <Box
            display="flex"
            alignItems="center"
            height={200}
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress size={24} />
            <span>Carregando...</span>
          </Box>
        )}

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {values.informacao?.map((item, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent={'space-between'}
                    >
                      <FormGroup>
                        <FormControlLabel
                          checked={values?.informacao?.[index]?.checked}
                          onChange={async (_, checked) =>
                            setFieldValue(
                              `informacao[${index}].checked`,
                              checked
                            )
                          }
                          control={<Checkbox />}
                          label={item.name}
                        />
                      </FormGroup>
                      {values?.informacao?.[index]?.checked &&
                        item.name !== 'Ficha de Impressão' && (
                          <TextField
                            size="small"
                            placeholder="valor"
                            type="text"
                            required
                            value={values?.informacao?.[index]?.value}
                            onChange={async (event) =>
                              setFieldValue(
                                `informacao[${index}].value`,
                                formatReal(event?.target?.value)
                              )
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                    </Stack>
                  </Grid>
                ))}

                <Grid item xs={12} md={12}></Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="PEDIDO"
                    required
                    fullWidth
                    size="small"
                    value={values?.nomePedido}
                    onChange={async (event) =>
                      setFieldValue('nomePedido', event?.target?.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="QTDE"
                    required
                    fullWidth
                    size="small"
                    value={values?.qtde}
                    onChange={async (event) =>
                      setFieldValue('qtde', event?.target?.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptBR}
                  >
                    <DatePicker
                      // views={['year', 'month']}
                      label="ENTREGA"
                      // inputFormat="MMMM yyyy"
                      value={values?.entrega}
                      onChange={async (value) =>
                        setFieldValue('entrega', value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            startAdornment: (
                              <Box paddingRight={2}>
                                {params?.InputProps?.endAdornment}
                              </Box>
                            )
                          }}
                          className="w-100"
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="COD. CLIENTE - NOME CLIENTE"
                    required
                    fullWidth
                    size="small"
                    value={values?.nomeCliente}
                    onChange={async (event) =>
                      setFieldValue('nomeCliente', event?.target?.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography fontWeight={'bold'}>MEDIDA INTERNA</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    type="number"
                    fullWidth
                    size="small"
                    label="COMPRIMENTO"
                    value={values?.medidaInterna?.comprimento}
                    onChange={async (event) =>
                      setFieldValue(
                        'medidaInterna.comprimento',
                        event?.target?.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    label="LARGURA"
                    value={values?.medidaInterna?.largura}
                    onChange={async (event) =>
                      setFieldValue(
                        'medidaInterna.largura',
                        event?.target?.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    type="number"
                    fullWidth
                    size="small"
                    label="ALTURA"
                    value={values?.medidaInterna?.altura}
                    onChange={async (event) =>
                      setFieldValue(
                        'medidaInterna.altura',
                        event?.target?.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="REFERÊNCIA"
                    fullWidth
                    size="small"
                    value={values?.medidaInterna?.referencia}
                    onChange={async (event) =>
                      setFieldValue(
                        'medidaInterna.referencia',
                        event?.target?.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="TIPO PAPEL"
                    value={values?.medidaInterna?.tipoPapel}
                    onChange={async (event) =>
                      setFieldValue(
                        'medidaInterna.tipoPapel',
                        event?.target?.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="TIPO ONDA"
                    value={values?.medidaInterna?.tipoOnda}
                    onChange={async (event) =>
                      setFieldValue(
                        'medidaInterna.tipoOnda',
                        event?.target?.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="IMPRESSORA"
                    value={values?.medidaInterna?.impressora}
                    onChange={async (event) =>
                      setFieldValue(
                        'medidaInterna.impressora',
                        event?.target?.value
                      )
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography fontWeight={'bold'}>IMPRESSORA</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={values?.tipoImpressora}
                      onChange={async (event) =>
                        setFieldValue('tipoImpressora', event?.target?.value)
                      }
                    >
                      <FormControlLabel
                        value="WAD1"
                        control={<Radio />}
                        label="WAD1"
                      />
                      <FormControlLabel
                        value="WAD2"
                        control={<Radio />}
                        label="WAD2"
                      />
                      <FormControlLabel
                        value="WAD3"
                        control={<Radio />}
                        label="WAD3"
                      />
                      <FormControlLabel
                        value="WAD4"
                        control={<Radio />}
                        label="WAD4"
                      />
                      <FormControlLabel
                        value="SUNRISE"
                        control={<Radio />}
                        label="SUNRISE"
                      />
                      <FormControlLabel
                        value="WAM"
                        control={<Radio />}
                        label="WAM"
                      />
                      <FormControlLabel
                        value="CORTE E VINCO"
                        control={<Radio />}
                        label="CORTE E VINCO"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography fontWeight={'bold'}>TIPO DE COMPRA</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-tipoCompra-group-label"
                      name="row-radio-tipoCompra-group"
                      value={values?.tipoCompra}
                      onChange={async (event) =>
                        setFieldValue('tipoCompra', event?.target?.value)
                      }
                    >
                      <FormControlLabel
                        value="ALTERACAO"
                        control={<Radio />}
                        label="ALTERAÇÃO"
                      />
                      <FormControlLabel
                        value="REPOSICAO"
                        control={<Radio />}
                        label="REPOSIÇÃO"
                      />
                      <FormControlLabel
                        value="REPREMONTAGEM"
                        control={<Radio />}
                        label="REPREMONTAGEM"
                      />
                      <FormControlLabel
                        value="NOVO"
                        control={<Radio />}
                        label="NOVO"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    label="Nº PÇS PRODUZIDAS ATÉ A DATA"
                    InputProps={{
                      endAdornment: <Box>PÇS</Box>
                    }}
                    value={values?.dataNPcsProduzidas}
                    onChange={(event) => {
                      setFieldValue('dataNPcsProduzidas', event?.target?.value);
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    required={
                      values?.tipoCompra === 'ALTERACAO' ||
                      values?.tipoCompra === 'REPOSICAO'
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptBR}
                  >
                    <DatePicker
                      // views={['year', 'month']}
                      label="DATA DA ULTIMA COMPRA DO CLICHÊ"
                      // inputFormat="MMMM yyyy"
                      value={values?.dataPcsUltimaCompraCliche}
                      onChange={(value) => {
                        setFieldValue('dataPcsUltimaCompraCliche', value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            startAdornment: (
                              <Box paddingRight={2}>
                                {params?.InputProps?.endAdornment}
                              </Box>
                            )
                          }}
                          className="w-100"
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          required={
                            values?.tipoCompra === 'ALTERACAO' ||
                            values?.tipoCompra === 'REPOSICAO'
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="CLICHÊ INICIAL DILUÍDO"
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: <Box>PÇS</Box>
                    }}
                    value={values?.clicheInicialDiluido}
                    onChange={async (event) =>
                      setFieldValue(
                        'clicheInicialDiluido',
                        event?.target?.value
                      )
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography fontWeight={'bold'}>REQUERIDO POR</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="DESENHO E/OU ESPECIFICAÇÃO "
                      checked={values?.requeridoPor?.desenhoEspecificacao}
                      onChange={async (_, checked) =>
                        setFieldValue(
                          'requeridoPor.desenhoEspecificacao',
                          checked
                        )
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="AMOSTRA"
                      checked={values?.requeridoPor?.amostra}
                      onChange={async (_, checked) =>
                        setFieldValue('requeridoPor.amostra', checked)
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="CÓPIA DE CLICHÊ"
                      checked={values?.requeridoPor?.copiaCliche}
                      onChange={async (_, checked) =>
                        setFieldValue('requeridoPor.copiaCliche', checked)
                      }
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography fontWeight={'bold'}>
                    Informações para analise do Depto de Compras
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack
                    direction="row"
                    spacing={3}
                    justifyContent="space-between"
                  >
                    <Typography fontWeight={'bold'}>CORES</Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={async () =>
                        setFieldValue('informacoesCores', [
                          ...values?.informacoesCores,
                          {
                            cor: '',
                            referencia: '',
                            opcao: ''
                          }
                        ])
                      }
                    >
                      Adicionar
                    </Button>
                  </Stack>
                </Grid>

                {values?.informacoesCores?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} md={12}>
                      <Stack direction="row" spacing={3}>
                        <TextField
                          label={`${index + 1}ºCOR`}
                          fullWidth
                          required
                          size="small"
                          value={values?.informacoesCores?.[index]?.cor}
                          onChange={async (event) =>
                            setFieldValue(
                              `informacoesCores[${index}].cor`,
                              event?.target?.value
                            )
                          }
                        />

                        <TextField
                          label="Referência"
                          fullWidth
                          size="small"
                          value={values?.informacoesCores?.[index]?.referencia}
                          onChange={async (event) =>
                            setFieldValue(
                              `informacoesCores[${index}].referencia`,
                              event?.target?.value
                            )
                          }
                        />

                        <TextField
                          label="Opção"
                          fullWidth
                          size="small"
                          value={values?.informacoesCores?.[index]?.opcao}
                          onChange={async (event) =>
                            setFieldValue(
                              `informacoesCores[${index}].opcao`,
                              event?.target?.value
                            )
                          }
                        />

                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ minWidth: 86 }}
                          onClick={async () =>
                            setFieldValue(
                              'informacoesCores',
                              values?.informacoesCores?.filter(
                                (_, i) => i !== index
                              ) ?? []
                            )
                          }
                        >
                          Remover
                        </Button>
                      </Stack>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={12} md={12}>
                  <Typography fontWeight={'bold'}>
                    ANÁLISE DO SETOR DE VENDAS
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="PROPOSTA Nº"
                    required
                    fullWidth
                    size="small"
                    value={values?.numeroProposta}
                    onChange={async (event) =>
                      setFieldValue('numeroProposta', event?.target?.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="OBSERVAÇÃO"
                    required
                    fullWidth
                    size="small"
                    value={values?.observacao}
                    onChange={async (event) =>
                      setFieldValue('observacao', event?.target?.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography fontWeight={'bold'}>SETOR SOLICITANTE</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-group-label"
                      name="row-radio-group"
                      value={values?.setorSolicitante}
                      onChange={async (event) =>
                        setFieldValue('setorSolicitante', event?.target?.value)
                      }
                    >
                      <FormControlLabel
                        value="COMENCIAL"
                        control={<Radio />}
                        label="COMENCIAL"
                      />
                      <FormControlLabel
                        value="DESENVOLVIMENTO"
                        control={<Radio />}
                        label="DESENVOLVIMENTO"
                      />
                      <FormControlLabel
                        value="CLICHERIA PCE"
                        control={<Radio />}
                        label="CLICHERIA PCE"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {/* End form */}
              </Grid>

              <Typography fontWeight={'bold'} marginTop={2} marginBottom={2}>
                ASSINATURAS
              </Typography>
              <Stack
                direction={'row'}
                gap={3}
                justifyContent="center"
                flexWrap="wrap"
              >
                {/* <Stack spacing={2} width="100%" sx={{ maxWidth: 190 }}>
                  <Stack
                    spacing={2}
                    sx={{ border: '1px solid #ccc' }}
                    padding={1}
                  >
                    <Typography textAlign={'center'}>
                      {'SETOR DE VENDAS'}
                    </Typography>
                    <Stack spacing={2} width="100%">
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        width="100%"
                      >
                        <AttachFileOutlined
                          sx={{ transform: 'rotate(45deg)' }}
                        />
                        <Typography variant="body2" textAlign={'center'}>
                          INSERIR A ASSINATURA DIGITAL
                        </Typography>
                      </Stack>
                      <Typography textAlign={'center'}>
                        {values?.assinaturas?.setorVendas?.lastSend
                          ? moment(
                              values?.assinaturas?.setorVendas?.lastSend
                            ).format('DD/MM/YYYY [às] HH:mm')
                          : '--/--/----'}
                      </Typography>
                      {!!values?.assinaturas?.setorVendas?.url?.length && (
                        <Avatar
                          src={values?.assinaturas?.setorVendas?.url}
                          sx={{
                            borderRadius: 0,
                            width: '100%',
                            height: 80,
                            '& img': {
                              objectFit: 'contain',
                              objectPosition: 'center'
                            }
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                  {(values?.assinaturas?.setorVendas?.url?.length ||
                    values?.assinaturas?.setorVendas?.lastSend) && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        removeSignature(purchaseRequest?._id, 'setorVendas');
                      }}
                    >
                      REMOVER
                    </Button>
                  )}

                  {!values?.assinaturas?.setorVendas?.url?.length &&
                    !values?.assinaturas?.setorVendas?.lastSend && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (!purchaseRequest?._id) {
                            toast.warn(
                              'O formulário deve ser salvo antes de assinar.'
                            );
                          } else {
                            setSelectSignature('setorVendas');
                            setOpen(true);
                          }
                        }}
                      >
                        ASSINAR
                      </Button>
                    )}
                </Stack> */}
                {/* <Stack spacing={2} width="100%" sx={{ maxWidth: 190 }}>
                  <Stack
                    spacing={2}
                    sx={{ border: '1px solid #ccc' }}
                    padding={1}
                  >
                    <Typography textAlign={'center'}>{'CLICHERIA'}</Typography>
                    <Stack spacing={2} width="100%">
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        width="100%"
                      >
                        <AttachFileOutlined
                          sx={{ transform: 'rotate(45deg)' }}
                        />
                        <Typography variant="body2" textAlign={'center'}>
                          INSERIR A ASSINATURA DIGITAL
                        </Typography>
                      </Stack>
                      <Typography textAlign={'center'}>
                        {values?.assinaturas?.clicheria?.lastSend
                          ? moment(
                              values?.assinaturas?.clicheria?.lastSend
                            ).format('DD/MM/YYYY [às] HH:mm')
                          : '--/--/----'}
                      </Typography>
                      {!!values?.assinaturas?.clicheria?.url?.length && (
                        <Avatar
                          src={values?.assinaturas?.clicheria?.url}
                          sx={{
                            borderRadius: 0,
                            width: '100%',
                            height: 80,
                            '& img': {
                              objectFit: 'contain',
                              objectPosition: 'center'
                            }
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                  {(values?.assinaturas?.clicheria?.url?.length ||
                    values?.assinaturas?.clicheria?.lastSend) && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        removeSignature(purchaseRequest?._id, 'clicheria');
                      }}
                    >
                      REMOVER
                    </Button>
                  )}

                  {!values?.assinaturas?.clicheria?.url?.length &&
                    !values?.assinaturas?.clicheria?.lastSend && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (!purchaseRequest?._id) {
                            toast.warn(
                              'O formulário deve ser salvo antes de assinar.'
                            );
                          } else {
                            setSelectSignature('clicheria');
                            setOpen(true);
                          }
                        }}
                      >
                        ASSINAR
                      </Button>
                    )}
                </Stack> */}
                {/* <Stack spacing={2} width="100%" sx={{ maxWidth: 190 }}>
                  <Stack
                    spacing={2}
                    sx={{ border: '1px solid #ccc' }}
                    padding={1}
                  >
                    <Typography textAlign={'center'}>
                      {'DESENVOLVIMENTO'}
                    </Typography>
                    <Stack spacing={2} width="100%">
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        width="100%"
                      >
                        <AttachFileOutlined
                          sx={{ transform: 'rotate(45deg)' }}
                        />
                        <Typography variant="body2" textAlign={'center'}>
                          INSERIR A ASSINATURA DIGITAL
                        </Typography>
                      </Stack>
                      <Typography textAlign={'center'}>
                        {values?.assinaturas?.desenvolvimento?.lastSend
                          ? moment(
                              values?.assinaturas?.desenvolvimento?.lastSend
                            ).format('DD/MM/YYYY [às] HH:mm')
                          : '--/--/----'}
                      </Typography>
                      {!!values?.assinaturas?.desenvolvimento?.url?.length && (
                        <Avatar
                          src={values?.assinaturas?.desenvolvimento?.url}
                          sx={{
                            borderRadius: 0,
                            width: '100%',
                            height: 80,
                            '& img': {
                              objectFit: 'contain',
                              objectPosition: 'center'
                            }
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                  {(values?.assinaturas?.desenvolvimento?.url?.length ||
                    values?.assinaturas?.desenvolvimento?.lastSend) && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        removeSignature(
                          purchaseRequest?._id,
                          'desenvolvimento'
                        );
                      }}
                    >
                      REMOVER
                    </Button>
                  )}

                  {!values?.assinaturas?.desenvolvimento?.url?.length &&
                    !values?.assinaturas?.desenvolvimento?.lastSend && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (!purchaseRequest?._id) {
                            toast.warn(
                              'O formulário deve ser salvo antes de assinar.'
                            );
                          } else {
                            setSelectSignature('desenvolvimento');
                            setOpen(true);
                          }
                        }}
                      >
                        ASSINAR
                      </Button>
                    )}
                </Stack> */}

                <Stack spacing={2} width="100%" sx={{ maxWidth: 190 }}>
                  <Stack
                    spacing={2}
                    sx={{ border: '1px solid #ccc' }}
                    padding={1}
                  >
                    <Typography textAlign={'center'} sx={{ minHeight: 48 }}>{'CONTROLADORIA'}</Typography>
                    <Stack spacing={2} width="100%">
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        width="100%"
                      >
                        <AttachFileOutlined
                          sx={{ transform: 'rotate(45deg)' }}
                        />
                        <Typography variant="body2" textAlign={'center'}>
                          INSERIR A ASSINATURA DIGITAL
                        </Typography>
                      </Stack>
                      <Typography textAlign={'center'}>
                        {values?.assinaturas?.compras?.lastSend
                          ? moment(
                              values?.assinaturas?.compras?.lastSend
                            ).format('DD/MM/YYYY [às] HH:mm')
                          : '--/--/----'}
                      </Typography>
                      {!!values?.assinaturas?.compras?.url?.length && (
                        <Avatar
                          src={values?.assinaturas?.compras?.url}
                          sx={{
                            borderRadius: 0,
                            width: '100%',
                            height: 80,
                            '& img': {
                              objectFit: 'contain',
                              objectPosition: 'center'
                            }
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                  {(values?.assinaturas?.compras?.url?.length ||
                    values?.assinaturas?.compras?.lastSend) && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        removeSignature(purchaseRequest?._id, 'compras', purchaseRequest?.assinaturas?.compras?.user);
                      }}
                    >
                      REMOVER
                    </Button>
                  )}

                  {!values?.assinaturas?.compras?.url?.length &&
                    !values?.assinaturas?.compras?.lastSend && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (!purchaseRequest?._id) {
                            toast.warn(
                              'O formulário deve ser salvo antes de assinar.'
                            );
                          } else if (!allowSignature) {
                            toast.warn(
                              'Todos os documentos devem ser adicionados antes de assinar. Verifique no botão "Conclusão, baixar documentos" todos os documentos já adicionados.'
                            );
                          } else if (bookFinished) {
                            toast.warn(
                              'Book concluído, não pode mais remover a assinatura.'
                            );
                          } else if (!loginData?.tokenData?.profile?.includes('CONTROLADORIA BOOK') && !loginData?.tokenData?.profile?.includes('DIRETORIA BOOK')) {
                            toast.warn(
                              'Você não tem permissão para assinar.'
                            );
                          } else {
                            setSelectSignature('compras');
                            setOpen(true);
                          }
                        }}
                      >
                        ASSINAR
                      </Button>
                    )}
                </Stack>

                <Stack spacing={2} width="100%" sx={{ maxWidth: 190 }}>
                  <Stack
                    spacing={2}
                    sx={{ border: '1px solid #ccc' }}
                    padding={1}
                  >
                    <Typography textAlign={'center'} sx={{ minHeight: 48 }}>
                      {'DIRETORIA COMERCIAL'}
                    </Typography>
                    <Stack spacing={2} width="100%">
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        width="100%"
                      >
                        <AttachFileOutlined
                          sx={{ transform: 'rotate(45deg)' }}
                        />
                        <Typography variant="body2" textAlign={'center'}>
                          INSERIR A ASSINATURA DIGITAL
                        </Typography>
                      </Stack>
                      <Typography textAlign={'center'}>
                        {values?.assinaturas?.gerenciaComercial?.lastSend
                          ? moment(
                              values?.assinaturas?.gerenciaComercial?.lastSend
                            ).format('DD/MM/YYYY [às] HH:mm')
                          : '--/--/----'}
                      </Typography>
                      {!!values?.assinaturas?.gerenciaComercial?.url
                        ?.length && (
                        <Avatar
                          src={values?.assinaturas?.gerenciaComercial?.url}
                          sx={{
                            borderRadius: 0,
                            width: '100%',
                            height: 80,
                            '& img': {
                              objectFit: 'contain',
                              objectPosition: 'center'
                            }
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                  {(values?.assinaturas?.gerenciaComercial?.url?.length ||
                    values?.assinaturas?.gerenciaComercial?.lastSend) && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        removeSignature(
                          purchaseRequest?._id,
                          'gerenciaComercial', purchaseRequest?.assinaturas?.gerenciaComercial?.user
                        );
                      }}
                    >
                      REMOVER
                    </Button>
                  )}

                  {!values?.assinaturas?.gerenciaComercial?.url?.length &&
                    !values?.assinaturas?.gerenciaComercial?.lastSend && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (!purchaseRequest?._id) {
                            toast.warn(
                              'O formulário deve ser salvo antes de assinar.'
                            );
                          } else if (!allowSignature) {
                            toast.warn(
                              'Todos os documentos devem ser adicionados antes de assinar. Verifique no botão "Conclusão, baixar documentos" todos os documentos já adicionados.'
                            );
                          } else if (bookFinished) {
                            toast.warn(
                              'Book concluído, não pode mais remover a assinatura.'
                            );
                          } else if (!loginData?.tokenData?.profile?.includes('DIRETORIA BOOK') && !loginData?.tokenData?.profile?.includes('CONTROLADORIA BOOK')) {
                            toast.warn(
                              'Você não tem permissão para assinar.'
                            );
                          } else {
                            setSelectSignature('gerenciaComercial');
                            setOpen(true);
                          }
                        }}
                      >
                        ASSINAR
                      </Button>
                    )}
                </Stack>
              </Stack>

              {/* <Stack
                paddingTop={3}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Stack spacing={2} width="100%" sx={{ maxWidth: 190 }}>
                  <Stack
                    spacing={2}
                    sx={{ border: '1px solid #ccc' }}
                    padding={1}
                  >
                    <Typography textAlign={'center'}>
                      {'COORDENADOR'}
                    </Typography>
                    <Stack spacing={2} width="100%">
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        width="100%"
                      >
                        <AttachFileOutlined
                          sx={{ transform: 'rotate(45deg)' }}
                        />
                        <Typography variant="body2" textAlign={'center'}>
                          INSERIR A ASSINATURA DIGITAL
                        </Typography>
                      </Stack>
                      <Typography textAlign={'center'}>
                        {values?.assinaturas?.coordenador?.lastSend
                          ? moment(
                              values?.assinaturas?.coordenador?.lastSend
                            ).format('DD/MM/YYYY [às] HH:mm')
                          : '--/--/----'}
                      </Typography>
                      {!!values?.assinaturas?.coordenador?.url?.length && (
                        <Avatar
                          src={values?.assinaturas?.coordenador?.url}
                          sx={{
                            borderRadius: 0,
                            width: '100%',
                            height: 80,
                            '& img': {
                              objectFit: 'contain',
                              objectPosition: 'center'
                            }
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                  {(values?.assinaturas?.coordenador?.url?.length ||
                    values?.assinaturas?.coordenador?.lastSend) && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        removeSignature(purchaseRequest?._id, 'coordenador');
                      }}
                    >
                      REMOVER
                    </Button>
                  )}

                  {!values?.assinaturas?.coordenador?.url?.length &&
                    !values?.assinaturas?.coordenador?.lastSend && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (!purchaseRequest?._id) {
                            toast.warn(
                              'O formulário deve ser salvo antes de assinar.'
                            );
                          } else {
                            setSelectSignature('coordenador');
                            setOpen(true);
                          }
                        }}
                      >
                        ASSINAR
                      </Button>
                    )}
                </Stack>
              </Stack> */}

              <Stack
                direction="row"
                spacing={3}
                marginTop={6}
                justifyContent="space-between"
              >
                <ButtonPce
                  onClick={() => {
                    props.close();
                  }}
                  disabled={isSubmitting}
                >
                  VOLTAR
                </ButtonPce>
                {!bookFinished && <ButtonPce mold="primary" type="submit" disabled={isSubmitting}>
                  SALVAR
                </ButtonPce>}
              </Stack>
            </Box>
          </>
        </DialogContent>
      </DialogStyle>

      {selectSignature && purchaseRequest?._id && (
        <ModalSignaturePurchaseRequest
          id={purchaseRequest._id}
          signature={selectSignature}
          open={open}
          onCloseSuccess={() => {
            setSelectSignature(null);
            setOpen(false);
            props.close();
          }}
          onClose={() => {
            setSelectSignature(null);
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default PurchaseRequest;
