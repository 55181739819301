import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { Card, FormControl, IconButton, Input, InputAdornment, InputLabel, Snackbar, Step, StepLabel, Stepper, TextField, Button as ButtonTop, FormHelperText } from '@mui/material'
import ImportantView from 'components/view/ImportantView'
import Button from 'components/form/Button'
import pathDone from '../../../assets/svgs/done.svg'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { httpClient } from 'config/httpClient'
import { Form, Formik } from 'formik'
import { validationChangePasswordSchema } from 'pages/login/validations/validationChangePasswordSchema'

interface Props {
  open: boolean
  close: () => void
}

const ChangePasswordDialog: React.FC<Props> = (props) => {
  const { loginData } = useSelector((state: RootState) => state.login)

  const steps = [
    'Inserir token',
    'Digitar senha',
    'Alteração finalizada'
  ]

  const [openSnack, setOpenSnack] = React.useState(false)

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  const [code, setCode] = React.useState('')
  const [step, setStep] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [tokenGenerated, setTokenGenerated] = React.useState(false)

  React.useEffect(() => {
    console.log('LOGIN DATA', loginData)
  }, [])

  const generateToken = async () => {
    setLoading(true)
    try {
      const response = await httpClient.post('/auth/send', { email: loginData.email })
      console.log('RESPONSE', response)
      setTokenGenerated(true)
      setLoading(false)
    } catch (e) {
      setOpenSnack(true)
      setLoading(false)
    }
  }

  const handleConfirmCode = async () => {
    setLoading(true)
    try {
      await httpClient.post('auth/verify', {
        email: loginData.email,
        code: code
      })
      setStep(1)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setOpenSnack(true)
    }
  }

  // First input
  const [valuesFirstInput, setValuesFirstInput] = useState({
    password: '',
    showPassword: false
  })

  const handleClickShowPassword = () => {
    setValuesFirstInput({
      ...valuesFirstInput,
      showPassword: !valuesFirstInput.showPassword
    })
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  // Second input
  const [valuesConfirm, setValuesConfirm] = useState({
    password: '',
    showPassword: false
  })

  const handleClickShowPasswordConfirm = () => {
    setValuesConfirm({
      ...valuesConfirm,
      showPassword: !valuesConfirm.showPassword
    })
  }

  const handleMouseDownPasswordConfirm = (event: any) => {
    event.preventDefault()
  }

  const handleChangePass = async (values: any) => {
    setLoading(true)
    const tokenData = loginData.tokenData
    const accessToken = loginData.accessToken
    if (values.password === values.confirmPassword) {
      try {
        await httpClient.patch(`/users/${tokenData.sub}`, {
          password: values.password,
          confirm: values.confirmpassword
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        setLoading(false)
        setStep(2)
      } catch (e) {
        console.log('E', e)
        setLoading(false)
        setOpenSnack(true)
      }
    } else {
      setLoading(false)
      setOpenSnack(true)
    }
  }

  return (
    <Dialog
      open={props.open}
      keepMounted
      fullWidth
      onClose={props.close}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Card style={{ padding: '14px' }}>
          <Stepper activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Card>

        {
          step === 0
            ? <>
              {
                tokenGenerated
                  ? <div style={{ marginTop: 16 }}>
                    <div style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>Inserir token</div>
                    <ImportantView title='Importante' content='O token foi gerado e enviado para seu e-mail, copie e cole o token no campo a baixo para prosseguir com alteração de sua senha.' />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <TextField
                        id="standard-basic"
                        label="Digitar código"
                        variant="standard"
                        value={code}
                        style={{ width: '300px' }}
                        onChange={(v) => { setCode(v.target.value) }}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <Button mold='primary' loading={loading} onClick={() => { handleConfirmCode() }}>Confirmar</Button>
                    </div>
                  </div>
                  : <div style={{ marginTop: 16 }}>
                    <div style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>Gerar código</div>
                    <ImportantView title='Importante' content='Um código será enviado para o seu e-mail, caso não receba, aguarde 5 minutos para gerar novamente' />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <Button mold='primary' loading={loading} onClick={async () => generateToken()}>Gerar código</Button>
                    </div>
                  </div>
              }
            </>
            : step === 1
              ? <>
                <div style={{ marginTop: 16, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
                  <div style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>Mudar senha</div>
                  <Formik
                    initialValues={{
                      password: '',
                      confirmPassword: ''
                    }}
                    validationSchema={validationChangePasswordSchema}
                    onSubmit={async (values) => {
                      console.log('OK', values.password, values.confirmPassword)
                      if (values.password === values.confirmPassword) {
                        handleChangePass(values)
                      } else {
                        setOpenSnack(true)
                      }
                    }}
                  >
                    {
                      ({
                        values, errors, touched, handleChange, setFieldValue
                      }) => (<Form style={{ display: 'contents' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', width: '300px', alignSelf: 'center', justifySelf: 'center' }}>
                          <FormControl variant='standard'>
                            <InputLabel htmlFor='standard-adornment-password'>Digite uma nova senha</InputLabel>
                            <Input
                              id='standard-adornment-password'
                              type={valuesFirstInput.showPassword ? 'text' : 'password'}
                              value={values.password}
                              onChange={handleChange('password')}
                              error={Boolean(errors.password && touched.password)}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {valuesFirstInput.showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <FormHelperText error={Boolean(errors.password && touched.password)}>
                              {(errors.password && touched.password) ? errors.password : ''}
                            </FormHelperText>
                          </FormControl>

                          <FormControl style={{ marginTop: '12px' }} variant='standard'>
                            <InputLabel htmlFor='standard-adornment-password'>Digite a nova senha novamente</InputLabel>
                            <Input
                              id='standard-adornment-password'
                              type={valuesConfirm.showPassword ? 'text' : 'password'}
                              value={values.confirmPassword}
                              onChange={handleChange('confirmPassword')}
                              error={Boolean(values.password !== values.confirmPassword)}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={handleClickShowPasswordConfirm}
                                    onMouseDown={handleMouseDownPasswordConfirm}
                                  >
                                    {valuesConfirm.showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <FormHelperText error={Boolean(values.password !== values.confirmPassword)}>
                              {(Boolean(values.password !== values.confirmPassword) && touched.confirmPassword) ? errors.confirmPassword : ''}
                            </FormHelperText>
                          </FormControl>
                        </div>
                        <ButtonTop type='submit' variant='contained' disableElevation style={{ backgroundColor: '#4060E6', marginTop: '20px', alignSelf: 'flex-end' }}>
                          Confirmar
                        </ButtonTop>
                      </Form>
                      )}
                  </Formik>
                </div>
              </>
              : <>
                <div style={{ marginTop: 16, textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ fontSize: '24px', fontWeight: 'bold', width: '200px', marginBottom: '10px' }}>Senha Alterada com Sucesso</div>
                  <img src={pathDone} width={100} height={100} alt='icon pdf' />
                </div>
                <Button mold='primary' onClick={() => { setStep(0); setTokenGenerated(false); props.close() }}>Ok</Button>
              </>
        }

      </DialogContent>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        message='Senha ou token inválidos tente novamente'
        key='top center'
      />
    </Dialog >
  )
}

export default ChangePasswordDialog
