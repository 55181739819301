import { MutationFunction } from 'react-query'
import { httpClient, httpLN } from '../../config/httpClient'

export const postBudgetRealized = async (realized: any): Promise<any> => {
  console.log('postBudgetRealized', realized)
  const { data } = await httpClient.post<any>('/budget-realized', realized)
  return data
}

export const getBudgetRealized = async () => {
  const { data } = await httpClient.get<any>('/budget-realized')
  return data
}
export const getBudgetRealizedMonthAndYear = async (month?: any, year?: any) => {
  const { data } = await httpClient.get<any>(`/budget-realized/${month}/${year}`)
  return data
}
export const getBudgetRealizedMonthAndYearItems = async (month?: any, year?: any) => {
  const { data } = await httpClient.get<any>(`/budget-realized/category-item/${month}/${year}`)
  console.log('getBudgetRealizedMonthAndYearItems => ', data)
  return data
}
export const getBaseBudgetRealized = async () => {
  const { data } = await httpClient.get<any>('/budget-realized/base')
  return data
}
export const deleteBudgetRealized = async (id: string) => {
  const { data } = await httpClient.delete(`/budget-realized/${id}`)
  return data
}

export const putBudgetRealized = async (realized: any): Promise<any> => {
  console.log('putBudgetRealized', realized)
  const { data } = await httpClient.put(`/budget-realized/${realized._id}`, realized)
  return data
}

export const postPutBudgetRealized = async (realized: any): Promise<any> => {
  console.log('postPutBudgetRealized', realized)
  const { data } = await httpClient.put(`/budget-realized/create/${realized._id}`, realized)
  return data
}

export const createMultBudgetRealized = async (realized: any): Promise<any> => {
  console.log('createMultBudgetRealized', realized)
  const { data } = await httpClient.post('/budget-realized/createMultiple', realized)
  return data
}

export const getInsumosPapel = async (month?: any, year?: any, items?: any) => {
  console.log('monthyearitems', month, year, items)
  const { data } = await httpLN.get(`/insumos/papel-mes?items=${items}&month=${month}&year=${year}`)
  return data
}

interface Result {
  success: boolean
  items: any
}

interface DeleteItemBudgetRealizedArgs {
  id1: any
  id2: any
}

interface UpdateItemBudgetRealizedArgs {
  item: any
  id: any
}

interface CreateItemBudgetRealizedArgs {
  item: any
  id: any
}

export const deleteItemBudgetRealized: MutationFunction<Result, DeleteItemBudgetRealizedArgs> = async ({ id1, id2 }) => {
  const { data } = await httpClient.delete(`/budget-realized/${id2}/${id1}`)

  const resultItems: Result = { success: true, items: data?.categories[0]?.items }
  return resultItems
}

export const updateItemBudgetRealized: MutationFunction<Result, UpdateItemBudgetRealizedArgs> = async ({ item, id }) => {
  console.log('item', item)
  console.log('id', id)
  const { data } = await httpClient.put(`/budget-realized/item/${id}/${item._id}`, item)
  const resultItems: Result = { success: true, items: data?.categories[0]?.items }
  console.log('resultItems', resultItems)
  return resultItems // Corrigido para retornar o objeto resultItems dentro de uma promessa
}

export const createItemBudgetRealized: MutationFunction<Result, CreateItemBudgetRealizedArgs> = async ({ item, id }) => {
  console.log('create Item', item)
  console.log('create Item ID', id)
  const { data } = await httpClient.post(`/budget-realized/item/${id}`, item)
  const resultItems: Result = { success: true, items: data?.categories[0]?.items }
  console.log('resultItems', resultItems)
  return resultItems
}
