/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import moment from 'moment'
import {
  CardContentChieldren,
  CardFather,
  DivAlingCard,
  DivAlingItensCard,
  RetangleView
} from '../../reportControl/deliveryMap/styles/dialogMap.styles'
import IconAndTitleView from 'components/view/IconAndTitleView'
import { CircularProgress, DialogTitle } from '@mui/material'
import { useQuery } from 'react-query'
import { httpClient } from 'config/httpClient'

interface Props {
  title: string
  idPreFT?: string
  codItem?: string
  data?: any
  colorTitle?: string
}

const ModalHead: React.FC<Props> = ({ title, idPreFT, codItem, data, colorTitle }) => {
  if (!idPreFT && !data?.ft?.preFT?._id) {
    return (
      <DialogTitle id={`alert-dialog-${title}`}>
      <DivAlingCard>
        <RetangleView />
        <CardFather>
          <CardContentChieldren>
            <DivAlingItensCard>
              <div style={{ marginRight: '20px', color: colorTitle }}>{title}</div>
            </DivAlingItensCard>
          </CardContentChieldren>
        </CardFather>
      </DivAlingCard>
    </DialogTitle>
    )
  }

  const {
    data: dataDetails,
    status
  } = useQuery(
    ['modal-booking-head', idPreFT ?? data?.ft?.preFT?._id],
    async () => await httpClient.get<any>(`/prefts/${idPreFT ?? data?.ft?.preFT?._id}`),
    {
      refetchOnWindowFocus: false
    }
  )

  return (
    <DialogTitle id={`alert-dialog-${title}`}>
      <DivAlingCard>
        <RetangleView />
        <CardFather>
          <CardContentChieldren>
            <DivAlingItensCard>
              <div style={{ marginRight: '20px', color: colorTitle }}>{title}</div>
              {status === 'loading' && <CircularProgress size={24} />}
              {status !== 'loading' && (
                <>
                  <IconAndTitleView
                    icon="person_outline"
                    title={`Emitido por: ${dataDetails?.data?.seller?.user?.name}`}
                  />
                  {/* <IconAndTitleView icon="person_outline" title="Faturado:" /> */}
                  <IconAndTitleView
                    icon="calendar_today"
                    title={moment(dataDetails?.data?.createdAt).format(
                      'DD/MM/YYYY'
                    )}
                  />
                  <IconAndTitleView
                    icon="schedule"
                    title={moment(dataDetails?.data?.createdAt).format('HH:mm')}
                  />
                  <IconAndTitleView
                    icon="person_outline"
                    title={`Para: ${dataDetails?.data?.client?.name}`}
                  />
                  <IconAndTitleView
                    title={`FT: ${codItem ?? ''}`}
                  />
                </>
              )}
            </DivAlingItensCard>
          </CardContentChieldren>
        </CardFather>
      </DivAlingCard>
    </DialogTitle>
  )
}

export default ModalHead
