/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable operator-linebreak */
import { Box, CircularProgress, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import Button from 'components/form/Button'
import StatusViewGeneric from 'components/view/StatusViewGeneric'
import moment from 'moment'
import { StyledTableCell, StyledTableRow } from 'pages/purchaseOrder/components/table.styles'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { getNewOrderSales, getStatusOVXml, postUpdateStatusOV } from 'services/pedido/PedidoService'
import { ViewOption } from 'types/alerts/AlertsType'
import LightTooltip from '../../../components/tooltips/LightTooltip'

const SalesOrdersTable: React.FC = () => {
  const queryClient = useQueryClient()

  const [page, setPage] = useState(0)
  const [ovMNE, setovMNE] = useState('')
  const [sigla, setSigla] = useState('')

  const { data, refetch, isFetching } = useQuery(
    ['sales-order', null],
    async () => getNewOrderSales(),
    {
      refetchOnWindowFocus: false
    }
  )

  let ovLN

  const reqStatus = useQuery(
    ['status-sales-order', ovLN],
    async () => {
      if (ovLN.length > 0) {
        console.log(ovLN)
        return getStatusOVXml(ovLN)
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          try {
            console.log('onSuccess', data)
            if ((typeof data.result === 'undefined') && (typeof data === 'string')) {
              if ((data !== 'Não está no LN e não há arquivos de Erro gerados!') && (data !== 'Processando dados. Aguarde!')) {
                mutateEdit({ status: 'Error _' + data, _id: ovMNE, displayId: `${sigla} - Não Gerado` })
              } else if (data === 'Processando dados. Aguarde!') {
                mutateEdit({ status: 'Aviso _' + data, _id: ovMNE, displayId: sigla })
              } else {
                mutateEdit({ status: 'Aviso _' + data, _id: ovMNE, displayId: sigla })
              }
            } else if (data?.result?.length > 0) {
              mutateEdit({ status: 'No LN', _id: ovMNE, displayId: sigla })
            }
          } catch (e) { }
        }
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(
    postUpdateStatusOV,
    {
      onError: (e: any) => {
        if (e.response) {
          toast.error(e.response.data.message)
        } else {
          toast.error('Não foi possível consultar')
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('sales-order')
      }
    }
  )

  const optionsView: ViewOption[] = [
    {
      status: 'Enviado',
      color: '#5f7aea',
      bgColor: '#edf0ff'
    },
    {
      status: 'No LN',
      color: '#72CE2B',
      bgColor: '#EBFFD8'
    },
    {
      status: 'Error',
      color: '#CE2B72',
      bgColor: '#FFEDED'
    },
    {
      status: 'Aviso',
      color: '#E5D21E',
      bgColor: '#FFF8DC'
    }
  ]

  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return (
    <>
      {(isFetching || reqStatus?.isFetching || isLoadingEdit) && (
        <Box
          sx={{
            display: 'flex',
            height: 200,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isFetching && !reqStatus?.isFetching && !isLoadingEdit && (
        <TableContainer component={Paper}>

          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Nome da Empresa</StyledTableCell>
                <StyledTableCell align="left">Ordem de Venda</StyledTableCell>
                <StyledTableCell align="left">Data criação</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="center">Ações</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'success' && !data?.length && (
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <Box
                      sx={{
                        display: 'flex',
                        height: 200,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography component="span" variant="body2">
                        Nenhuma ordem encontrada
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              )}
              {data?.slice(page * 10, page * 10 + 10).map((row, index) => {
                const valueStatus = ((row.status !== 'Enviado') && (row.status !== 'No LN')) ? row.status.split(' _')[0] : row.status
                const msgError = ((row.status !== 'Enviado') && (row.status !== 'No LN')) ? row.status.split(' _')[1] : ''
                return (
                  <StyledTableRow key={index} style={{ backgroundColor: valueStatus === 'Error' ? '#FFBABA' : 'inherit' }}>
                    <StyledTableCell component="th" scope="row">
                      {row?.client_mne}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row?.displayId}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(row?.createdAt).format('DD/MM/YYYY \\à\\s HH:mm')}
                    </StyledTableCell>
                    <LightTooltip title={msgError} placement='top' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      <StyledTableCell align="left">
                          <StatusViewGeneric options={optionsView} status={valueStatus} />
                      </StyledTableCell>
                    </LightTooltip>
                    <StyledTableCell align="center">
                    <Button
                      type="button"
                      mold="primary"
                      onClick={() => { ovLN = row.displayId; setovMNE(row._id); setSigla(row.displayId); reqStatus.refetch() }}
                      style={{ padding: '4px' }}
                      disabled={valueStatus === 'Error'}
                    >
                      Verificar status
                    </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}

            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
            component="div"
            count={data?.length ?? 0}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        </TableContainer>
      )}
    </>
  )
}

export default SalesOrdersTable
