import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Label = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  padding-bottom: 5px;
  cursor: default;
  width: 60%;

  &:hover {
    color: #9db0ff;
  }
`

function PrivacyLabelLogin() {
  const navigate = useNavigate()

  return (
    <Label aria-label='política de privacidade' >
      <div onClick={() => { navigate('/privacy-policy') }}>Política de privacidade</div>
    </Label>
  )
}

export default PrivacyLabelLogin
