/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { ReactNode } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import {
  ButtonRow,
  DialogStyle,
  DivRow,
  LabelText
} from '../../reportControl/deliveryMap/styles/dialogMap.styles'
import { useFormik } from 'formik';
import ModalHead from './ModalHead';

function Section({ title, children }: { title: string, children: ReactNode }) {
  return (
    <>
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <h4>{title}</h4>
      </Grid>
      {children}
    </>
  );
}

const row: any = {};

interface Props {
  open: boolean
  close: () => void
  data?: any
}

const FTSample: React.FC<Props> = (props) => {
  const closeModal = () => props.close();

  const { errors, touched, values, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        _id: '',
        exit: false,
        reposition: false,
        ft: props.data?._id ?? '',
        cliente: '',
        ref: 'LN',
        ordemProducao: 'LN',
        ordemVenda: 'LN',
        qtdFaturada: 'LN',
        billingDate: '',
        nf: 'LN',
        diferencaEnviada: 'LN',
        qtdPallet: 'LN',
        entredaEstoque: 'LN',
        netWeight: '',
        dispatchedWeight: 0,
        requesteWeight: 0,
        board: '',
        deliveryAmountConfirmation: '',
        pallets: 'LN',
        formula: 'LN',
        deliveryTime: '',
        exitTime: '',
        startTime: '',
        observation: '',
        observationPCE: '',
        deliveryPrevision: '',
        dataFaturamentoLN: '',
        qtdSolicitadaOV: 'LN',
        paletizado: 'LN',
        Referencia: row?.Referencia ?? '',
        LarguraInterna: row?.LarguraInterna ?? undefined, // number
        ComprimentoInterno: row?.ComprimentoInterno ?? undefined, // number
        AlturaInterna: row?.AlturaInterna ?? undefined, // number
        Composicao: {
          composicao: row?.Composicao?.composicao ?? '',
          onda: row?.Composicao?.onda ?? '',
          onda1: row?.Composicao?.onda1 ?? '',
          onda2: row?.Composicao?.onda2 ?? '',
          onda3: row?.Composicao?.onda3 ?? '',
          onda4: row?.Composicao?.onda4 ?? '',
          onda5: row?.Composicao?.onda5 ?? '',
          papel1: row?.Composicao?.papel1 ?? '',
          papel2: row?.Composicao?.papel2 ?? '',
          papel3: row?.Composicao?.papel3 ?? '',
          papel4: row?.Composicao?.papel4 ?? '',
          papel5: row?.Composicao?.papel5 ?? '',
          nomeComercial: row?.Composicao?.nomeComercial ?? '',
          mullen: row?.Composicao?.mullen ?? undefined, // number
          crushEnd: row?.Composicao?.crushEnd ?? undefined, // number
          gramatura: row?.Composicao?.gramatura ?? undefined, // number
          crush: row?.Composicao?.crush ?? undefined, // number
          preco: row?.Composicao?.preco ?? undefined, // number
          coluna: row?.Composicao?.coluna ?? undefined, // number
          espessura: row?.Composicao?.espessura ?? undefined // number
        },
        Fechamento: {
          fechamento: row?.Fechamento?.fechamento ?? '',
          descricao: row?.Fechamento?.descricao ?? '',
          qtdEspessuras: row?.Fechamento?.qtdEspessuras ?? undefined // number
        },
        boxType: row?.boxType?._id ?? '',
        codTypebox: row?.codTypebox?._id ?? '',
        Paletizado: row?.Paletizado ?? undefined, // boolean
        Amarrado: row?.Amarrado ?? undefined, // boolean
        ExigeLaudo: row?.ExigeLaudo ?? undefined, // boolean
        Obs1: row?.Obs1 ?? '',
        PaletePadrao: row?.PaletePadrao ?? undefined, // boolean
        PaletePadraoLargura: row?.PaletePadraoLargura ?? '',
        PaletePadraoComprimento: row?.PaletePadraoComprimento ?? '',
        PaleteReforcado: row?.PaleteReforcado ?? undefined, // boolean
        PaleteReforcadoQuantidadeFita: row?.PaleteReforcadoQuantidadeFita ?? '',
        PaleteReforcadoQuantidadeLastro:
          row?.PaleteReforcadoQuantidadeLastro ?? '',
        AmarradoPalete: row?.AmarradoPalete ?? undefined, // boolean
        AmarradoPaleteQtd: row?.AmarradoPaleteQtd ?? '',
        AlcasFuro: row?.AlcasFuro ?? undefined, // boolean
        AlcasFuroQtdPorCaixa: row?.AlcasFuroQtdPorCaixa ?? '',
        Amostra: row?.Amostra ?? undefined, // boolean
        AmostraQtd: row?.AmostraQtd ?? '',
        Impressoes: row?.Impressoes ?? undefined, // boolean
        ImpressoesQtd: row?.ImpressoesQtd ?? '',
        ImpressoesCor: row?.ImpressoesCor ?? {
          cor1: '',
          cor2: '',
          cor3: '',
          cor4: '',
          cor5: '',
          cor6: '',
          cor7: '',
          cor8: '',
          cor9: '',
          cor10: ''
        },
        Tara: row?.Tara ?? undefined, // boolean
        Batido: row?.Batido ?? undefined, // boolean
        Definicao: row?.Definicao ?? '',
        NovoProduto: {
          BasedaNaFTD: row?.NovoProduto?.BasedaNaFTD ?? undefined, // boolean
          DesnehoEspecificacao:
            row?.NovoProduto?.DesnehoEspecificacao ?? undefined, // boolean
          AmostraMecanica: row?.NovoProduto?.AmostraMecanica ?? undefined, // boolean
          ProdutoDoCliente: row?.NovoProduto?.ProdutoDoCliente ?? undefined, // boolean
          ProdutoSemelhante: row?.NovoProduto?.ProdutoSemelhante ?? undefined, // boolean
          Outros: row?.NovoProduto?.Outros ?? undefined, // boolean
          OutrosObs: row?.NovoProduto?.OutrosObs ?? ''
        } // boolean
      },
      onSubmit(values) {
        const body = {
          exit: values.exit,
          reposition: values.reposition,
          billingDate: values.billingDate,
          netWeight: values.netWeight,
          dispatchedWeight: values.dispatchedWeight,
          requesteWeight: values.requesteWeight,
          deliveryAmountConfirmation: values.deliveryAmountConfirmation,
          deliveryTime: values.deliveryTime,
          exitTime: values.exitTime,
          startTime: values.startTime,
          observation: values.observation,
          deliveryPrevision: values.deliveryPrevision,
          observationPCE: values.observationPCE
        };
        if (props.data?.deliveryMap) {
          console.log('values: ', body);
        } else {
          console.log('values: ', body);
        }
      }
    });

  return (
    <>
      <DialogStyle
        open={props.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit}>
          <ModalHead title="FT" idPreFT={props?.data?.ft?.preFT?._id} />

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ marginTop: '24px' }}>
                <LabelText>Dados da Solicitação - Caixa</LabelText>
                <DivRow style={{ marginTop: '-20px' }}>
                  <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Numero da FT"
                        value={props?.data?.ft?.itemReference}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Nome da Empresa"
                        value={props?.data?.preFT?.client?.name}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Emitido por"
                        value={props?.data?.preFT?.seller?.user?.name}
                        variant="standard"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Rêferencia"
                        value={
                          props?.data?.preFT?.preftbox?.reference ??
                          props?.data?.preFT?.formulariochapa?.Referencia ?? props?.data?.preFT?.preftplate?.Referencia
                        }
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </DivRow>
              </div>
              <Grid container spacing={2}>
                <Section title="Quantidade">
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Previsão total (Unid)"
                      value={
                        props?.data?.preFT?.preftbox?.amount?.previsionTotal
                      }
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Previsão total (Kg)"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Previsão de consumo mensal (Unid)"
                      value={
                        props?.data?.preFT?.preftbox?.amount?.monthConsumption
                      }
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Lote mínimo"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                </Section>

                <Section title="Planejamento do P&D">
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="Definicao">Definição</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="Definicao"
                        name="Definicao"
                        value={
                          (props?.data?.preFT?.preftbox?.planningRD?.definition
                            ?.justToDo
                            ? 'justToDo'
                            : '') ??
                          (props?.data?.preFT?.preftbox?.planningRD?.definition
                            ?.projectAndDo
                            ? 'projectAndDo'
                            : '') ??
                          (props?.data?.preFT?.preftbox?.planningRD?.definition
                            ?.withSimiliarProject
                            ? 'withSimiliarProject'
                            : '')
                        }
                      >
                        <FormControlLabel
                          value="justToDo"
                          control={<Radio />}
                          label="Item para desenvolver"
                          disabled
                        />
                        <FormControlLabel
                          value="projectAndDo"
                          control={<Radio />}
                          label="Item para Projetar e Desenvolver"
                          disabled
                        />
                        <FormControlLabel
                          value="withSimiliarProject"
                          control={<Radio />}
                          label="Item com projetos similares"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="NovoProduto">Novo Produto</FormLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.basedFTD
                          }
                          disabled
                        />
                      }
                      label="Baseada na FTD"
                    />
                  </Grid>
                  {props?.data?.preFT?.preftbox?.planningRD?.newProduct
                    ?.basedFTD && (
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={
                          props?.data?.preFT?.preftbox?.planningRD?.newProduct
                            ?.basedFTDValue
                        }
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.drawingOrSpecification
                          }
                          disabled
                        />
                      }
                      label="Desenho/Especificação"
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.mechanicalSample
                          }
                          disabled
                        />
                      }
                      label="Amostra Mecânica"
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.productInCustomer
                          }
                          disabled
                        />
                      }
                      label="Produto do Cliente"
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props?.data?.preFT?.preftbox?.planningRD?.newProduct
                              ?.similarProduct
                          }
                          disabled
                        />
                      }
                      label="Produto semelhante"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={1}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                !!props?.data?.preFT?.preftbox?.planningRD
                                  ?.newProduct?.other?.length
                              }
                              disabled
                            />
                          }
                          label="Outros"
                        />
                      </Grid>
                      {!!props?.data?.preFT?.preftbox?.planningRD?.newProduct
                        ?.other?.length && (
                        <Grid item xs={11}>
                          <TextField
                            type="text"
                            placeholder="Observação"
                            variant="outlined"
                            value={
                              props?.data?.preFT?.preftbox?.planningRD
                                ?.newProduct?.other
                            }
                            autoComplete="off"
                            fullWidth
                            multiline
                            disabled
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Section>

                {props?.data?.preFT?.preftbox?._id ? (
                  <>
                    <Section title="Especificações">
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Composição"
                          value={
                            props?.data?.preFT?.preftbox?.specifications
                              ?.Composicao?.composicao
                          }
                          variant="standard"
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Tipo de caixa"
                          value={
                            props?.data?.preFT?.preftbox?.specifications
                              ?.boxType?.name
                          }
                          variant="standard"
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Cod. Caixa"
                          value={
                            props?.data?.preFT?.preftbox?.specifications
                              ?.codTypebox?.cod
                          }
                          variant="standard"
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              label="Tipo de Onda"
                              variant="standard"
                              value={
                                props?.data?.preFT?.preftbox?.specifications
                                  ?.Composicao?.onda
                              }
                              fullWidth
                              disabled
                              autoComplete="off"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Papel"
                              variant="standard"
                              value={`${
                                props?.data?.preFT?.preftbox?.specifications
                                  ?.Composicao?.papel1
                                  ? props?.data?.preFT?.preftbox?.specifications
                                      ?.Composicao?.papel1 + ','
                                  : ''
                              }${
                                props?.data?.preFT?.preftbox?.specifications
                                  ?.Composicao?.papel2
                                  ? props?.data?.preFT?.preftbox?.specifications
                                      ?.Composicao?.papel2 + ','
                                  : ''
                              }${
                                props?.data?.preFT?.preftbox?.specifications
                                  ?.Composicao?.papel3
                                  ? props?.data?.preFT?.preftbox?.specifications
                                      ?.Composicao?.papel3 + ','
                                  : ''
                              }${
                                props?.data?.preFT?.preftbox?.specifications
                                  ?.Composicao?.papel4
                                  ? props?.data?.preFT?.preftbox?.specifications
                                      ?.Composicao?.papel4 + ','
                                  : ''
                              }${
                                props?.data?.preFT?.preftbox?.specifications
                                  ?.Composicao?.papel5
                                  ? props?.data?.preFT?.preftbox?.specifications
                                      ?.Composicao?.papel5 + ','
                                  : ''
                              }`.slice(0, -1)}
                              fullWidth
                              disabled
                              autoComplete="off"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Nome Comercial"
                              variant="standard"
                              value={
                                props?.data?.preFT?.preftbox?.specifications
                                  ?.Composicao?.nomeComercial ?? ''
                              }
                              fullWidth
                              disabled
                              autoComplete="off"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Section>

                    <Section title="Dimensões">
                      <Grid item xs={6} md={3}>
                        <TextField
                          type="number"
                          label="Largura Interna"
                          variant="standard"
                          value={
                            props?.data?.preFT?.preftbox?.internalMeasures
                              ?.width
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item alignSelf="flex-end">
                        +
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          type="number"
                          label="Comprimento Interno"
                          variant="standard"
                          value={
                            props?.data?.preFT?.preftbox?.internalMeasures
                              ?.diameter
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item alignSelf="flex-end">
                        +
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          type="number"
                          label="Altura Interna"
                          variant="standard"
                          value={
                            props?.data?.preFT?.preftbox?.internalMeasures
                              ?.height
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid
                        item
                        md={1}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end'
                          }}
                        >
                          =
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={1}>
                        <TextField
                          type="number"
                          label="Total (GRM)"
                          variant="standard"
                          value={
                            Number(
                              props?.data?.preFT?.preftbox?.internalMeasures
                                ?.diameter ?? 0
                            ) +
                            Number(
                              props?.data?.preFT?.preftbox?.internalMeasures
                                ?.diameter ?? 0
                            ) +
                            Number(
                              props?.data?.preFT?.preftbox?.internalMeasures
                                ?.height ?? 0
                            )
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          type="text"
                          label="Fechamento"
                          variant="standard"
                          value={
                            props?.data?.preFT?.preftbox?.internalMeasures
                              ?.Fechamento?.fechamento
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Section>
                  </>
                ) : (
                  <>
                    <Section title="Especificações">
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Composição"
                          value={
                            props?.data?.preFT?.formulariochapa?.Composicao
                              ?.composicao ?? props?.data?.preFT?.preftplate?.Composicao
                              ?.composicao
                          }
                          variant="standard"
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              label="Tipo de Onda"
                              variant="standard"
                              value={
                                props?.data?.preFT?.formulariochapa?.Composicao
                                  ?.onda ?? props?.data?.preFT?.preftplate?.Composicao
                                  ?.onda
                              }
                              fullWidth
                              disabled
                              autoComplete="off"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Papel"
                              variant="standard"
                              value={`${
                                props?.data?.preFT?.formulariochapa?.Composicao
                                  ?.papel1 || props?.data?.preFT?.preftplate?.Composicao
                                  ?.papel1
                                  ? props?.data?.preFT?.formulariochapa
                                      ?.Composicao?.papel1 ?? props?.data?.preFT?.preftplate?.Composicao?.papel1 + ','
                                  : ''
                              }${
                                props?.data?.preFT?.formulariochapa?.Composicao
                                  ?.papel2 || props?.data?.preFT?.preftplate?.Composicao
                                  ?.papel2
                                  ? props?.data?.preFT?.formulariochapa
                                      ?.Composicao?.papel2 ?? props?.data?.preFT?.preftplate?.Composicao?.papel2 + ','
                                  : ''
                              }${
                                props?.data?.preFT?.formulariochapa?.Composicao
                                  ?.papel3 || props?.data?.preFT?.preftplate?.Composicao
                                  ?.papel3
                                  ? props?.data?.preFT?.formulariochapa
                                      ?.Composicao?.papel3 ?? props?.data?.preFT?.preftplate?.Composicao
                                      ?.papel3 + ','
                                  : ''
                              }${
                                props?.data?.preFT?.formulariochapa?.Composicao
                                  ?.papel4 || props?.data?.preFT?.preftplate?.Composicao
                                  ?.papel4
                                  ? props?.data?.preFT?.formulariochapa
                                      ?.Composicao?.papel4 ?? props?.data?.preFT?.preftplate?.Composicao
                                      ?.papel1 + ','
                                  : ''
                              }${
                                props?.data?.preFT?.formulariochapa?.Composicao
                                  ?.papel5 || props?.data?.preFT?.preftplate?.Composicao
                                  ?.papel5
                                  ? props?.data?.preFT?.formulariochapa
                                      ?.Composicao?.papel5 ?? props?.data?.preFT?.preftplate?.Composicao
                                      ?.papel1 + ','
                                  : ''
                              }`.slice(0, -1)}
                              fullWidth
                              disabled
                              autoComplete="off"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Nome Comercial"
                              variant="standard"
                              value={
                                props?.data?.preFT?.formulariochapa?.Composicao
                                  ?.nomeComercial ?? props?.data?.preFT?.preftplate?.Composicao?.nomeComercial ?? ''
                              }
                              fullWidth
                              disabled
                              autoComplete="off"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Section>

                    <Section title="Dimensões">
                      <Grid item xs={6} md={3}>
                        <TextField
                          type="number"
                          label="Largura Interna"
                          variant="standard"
                          value={
                            props?.data?.preFT?.formulariochapa?.LarguraInterna ?? props?.data?.preFT?.preftplate?.LarguraInterna
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item alignSelf="flex-end">
                        +
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          type="number"
                          label="Comprimento Interno"
                          variant="standard"
                          value={
                            props?.data?.preFT?.formulariochapa
                              ?.ComprimentoInterno ?? props?.data?.preFT?.preftplate?.ComprimentoInterno
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item alignSelf="flex-end">
                        +
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          type="number"
                          label="Altura Interna"
                          variant="standard"
                          value={
                            props?.data?.preFT?.formulariochapa?.AlturaInterna ?? props?.data?.preFT?.preftplate?.AlturaInterna
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid
                        item
                        md={1}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end'
                          }}
                        >
                          =
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={1}>
                        <TextField
                          type="number"
                          label="Total (GRM)"
                          variant="standard"
                          value={
                            Number(
                              props?.data?.preFT?.formulariochapa
                                ?.LarguraInterna ?? props?.data?.preFT?.preftplate?.LarguraInterna ?? 0
                            ) +
                            Number(
                              props?.data?.preFT?.formulariochapa
                                ?.ComprimentoInterno ?? props?.data?.preFT?.preftplate?.ComprimentoInterno ?? 0
                            ) +
                            Number(
                              props?.data?.preFT?.formulariochapa
                                ?.AlturaInterna ?? props?.data?.preFT?.preftplate?.AlturaInterna ?? 0
                            )
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          type="text"
                          label="Fechamento"
                          variant="standard"
                          value={
                            props?.data?.preFT?.formulariochapa?.Fechamento
                              ?.fechamento ?? props?.data?.preFT?.preftplate?.Fechamento?.fechamento
                          }
                          autoComplete="off"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Section>
                  </>
                )}

                {props?.data?.preFT?.preftbox?._id ? (
                  <Section title="Informações Adicionais">
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Paletizado">Paletizado</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Paletizado"
                          name="Paletizadopreftbox"
                          value={
                            props?.data?.preFT?.preftbox?.tiedOrPallet ?? false
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Amarrado">Amarrado</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Amarrado"
                          name="Amarrado"
                          value={props?.data?.preFT?.preftbox?.tied}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="ExigeLaudo">Laudo</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="ExigeLaudo"
                          name="ExigeLaudo"
                          value={
                            props?.data?.preFT?.formulariocaixa?.ExigeLaudo
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        label="Observação"
                        variant="outlined"
                        value={props?.data?.preFT?.preftbox?.extraData}
                        autoComplete="off"
                        fullWidth
                        multiline
                        minRows={3}
                        disabled
                      />
                    </Grid>
                  </Section>
                ) : (
                  <Section title="Informações Adicionais">
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Paletizado">Paletizado</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Paletizado"
                          name="Paletizado"
                          value={
                            props?.data?.preFT?.formulariochapa?.Paletizado ?? props?.data?.preFT?.preftplate?.Paletizado
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Amarrado">Amarrado</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Amarrado"
                          name="Amarrado"
                          value={
                            props?.data?.preFT?.formulariochapa?.Amarrado ?? props?.data?.preFT?.preftplate?.Amarrado ??
                            false
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="ExigeLaudo">Laudo</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="ExigeLaudo"
                          name="ExigeLaudo"
                          value={
                            props?.data?.preFT?.formulariochapa?.ExigeLaudo ?? props?.data?.preFT?.preftplate?.ExigeLaudo
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        label="Observação"
                        variant="outlined"
                        value={props?.data?.preFT?.formulariochapa?.Obs1 ?? props?.data?.preFT?.preftplate?.Obs1}
                        autoComplete="off"
                        fullWidth
                        multiline
                        minRows={3}
                        disabled
                      />
                    </Grid>
                  </Section>
                )}

                {props?.data?.preFT?.preftbox?._id && (
                  <Section title="Informações Cliente">
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="PaletePadrao">Palete Padrão</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="PaletePadrao"
                          name="PaletePadrao"
                          value={
                            props?.data?.preFT?.formulariocaixa?.PaletePadrao
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                      {props?.data?.preFT?.formulariocaixa?.PaletePadrao && (
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6}>
                            <TextField
                              type="number"
                              label="Largura"
                              variant="standard"
                              value={
                                props?.data?.preFT?.formulariocaixa
                                  ?.PaletePadraoLargura
                              }
                              autoComplete="off"
                              fullWidth
                              disabled
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField
                              type="number"
                              label="Comprimento"
                              variant="standard"
                              value={
                                props?.data?.preFT?.formulariocaixa
                                  ?.PaletePadraoComprimento
                              }
                              autoComplete="off"
                              fullWidth
                              disabled
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="PaleteReforcado">
                          Palete Reforçado
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="PaleteReforcado"
                          name="PaleteReforcado"
                          value={props?.data?.preFT?.preftbox?.reinforcedPallet}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                      {props?.data?.preFT?.preftbox.reinforcedPallet && (
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6}>
                            <TextField
                              type="number"
                              label="Quantidade de fita"
                              variant="standard"
                              value={props?.data?.preFT?.preftbox?.amountTape}
                              autoComplete="off"
                              fullWidth
                              disabled
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <TextField
                              type="number"
                              label="Quantidade lastro do palete"
                              variant="standard"
                              value={
                                props?.data?.preFT?.preftbox
                                  ?.amountPalletBallast
                              }
                              autoComplete="off"
                              fullWidth
                              disabled
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="AmarradoPalete">
                          Amarrado no Palete
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="AmarradoPalete"
                          name="AmarradoPalete"
                          value={
                            props?.data?.preFT?.formulariocaixa?.AmarradoPalete
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                      {props?.data?.preFT?.formulariocaixa?.AmarradoPalete && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              type="number"
                              label="Qtd Amarrado/palete"
                              variant="standard"
                              value={
                                props?.data?.preFT?.formulariocaixa
                                  ?.AmarradoPaleteQtd
                              }
                              autoComplete="off"
                              fullWidth
                              disabled
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Alcas">Alças</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Alcas"
                          name="Alcas"
                          value={props?.data?.preFT?.preftbox?.handles}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                      {props?.data?.preFT?.preftbox?.handles && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              type="number"
                              label="Alças por Caixas"
                              variant="standard"
                              value={
                                props?.data?.preFT?.preftbox?.handlesValue
                              }
                              autoComplete="off"
                              fullWidth
                              disabled
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Furo">Furo</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Furo"
                          name="Furo"
                          value={props?.data?.preFT?.preftbox?.hole}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                      {props?.data?.preFT?.preftbox?.hole && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              type="number"
                              label="Furos por Caixas"
                              variant="standard"
                              value={
                                props?.data?.preFT?.preftbox?.holeValue
                              }
                              autoComplete="off"
                              fullWidth
                              disabled
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Amostra">Amostra</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Amostra"
                          name="Amostra"
                          value={props?.data?.preFT?.preftbox?.needSample}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                      {props?.data?.preFT?.preftbox?.needSample && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              type="number"
                              label="Quantidade de Amostras"
                              variant="standard"
                              value={props?.data?.preFT?.preftbox?.amountSample}
                              autoComplete="off"
                              fullWidth
                              required
                              disabled
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Impressoes">Impressões</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Impressoes"
                          name="Impressoes"
                          value={
                            !!props?.data?.preFT?.preftbox?.internalMeasures
                              ?.prints
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                      {!!props?.data?.preFT?.preftbox?.internalMeasures
                        ?.prints && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              type="number"
                              label="Quantidade de Impressões"
                              variant="standard"
                              value={
                                props?.data?.preFT?.preftbox?.internalMeasures
                                  ?.prints
                              }
                              autoComplete="off"
                              fullWidth
                              disabled
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Tara">Tara</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Tara"
                          name="Tara"
                          value={props?.data?.preFT?.formulariocaixa?.Tara}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="Batido">Batido</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="Batido"
                          name="Batido"
                          value={props?.data?.preFT?.formulariocaixa?.Batido}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Sim"
                            disabled
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Não"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Section>
                )}

                <Section title="Dados da Engenharia">
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Tipo de papelão"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                </Section>

                <Section title="Medidas">
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Largura"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Altura"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Área"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Peso bruto"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                </Section>
                <Section title="Ajustes de onduladeira">
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label=""
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    +
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label=""
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    +
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label=""
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    =
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label=""
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Observação"
                      variant="outlined"
                      value={values.Obs1}
                      error={Boolean(errors?.Obs1 && touched?.Obs1)}
                      helperText={
                        (errors?.Obs1 && touched?.Obs1 ? errors?.Obs1 : null) as React.ReactNode
                      }
                      onChange={handleChange('Obs1')}
                      autoComplete="off"
                      fullWidth
                      multiline
                      minRows={3}
                    />
                  </Grid>
                </Section>

                <Section title="Fluxos">
                  <Grid item xs={12} md={1}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fluxo 1"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fluxo 2"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fluxo 3"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fluxo 4"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fluxo 5"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fluxo 6"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fluxo 7"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fluxo 8"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                </Section>

                <Section title="Dados de acessório">
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Tipo de acessório"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Área liquida"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Peso liquido"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Tipo de papelão"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Área bruta"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Peso bruto"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Quantidade de amarrado"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fitilhos (LxC)"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                </Section>

                <Section title="Dados de produção">
                  <Grid item xs={12}>
                    <Typography component="span">Medidas internas</Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Comprimento"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Largura"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Altura"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography component="span">Medidas da peça</Typography>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Largura"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Comprimento"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography component="span">Cores</Typography>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Código cor"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Código cor"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                </Section>

                <Section title="Faca">
                  <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={''}
                        variant="standard"
                        disabled
                      />
                      /
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={''}
                        variant="standard"
                        disabled
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={''}
                        variant="standard"
                        disabled
                      />
                      /
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={''}
                        variant="standard"
                        disabled
                      />
                    </Box>
                  </Grid>
                </Section>

                <Section title="Fechamento">
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Fechamento"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Quantidade por amarrado"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="span">Arranjo</Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Largura"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Comprimento"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="span">Fitilho</Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Largura"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Comprimento"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography component="span">
                      Ajustes de impressora
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={''}
                        variant="standard"
                        disabled
                      />
                      +
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={''}
                        variant="standard"
                        disabled
                      />
                      +
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={''}
                        variant="standard"
                        disabled
                      />
                      +
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={''}
                        variant="standard"
                        disabled
                      />
                      =
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label=""
                        value={''}
                        variant="standard"
                        disabled
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography component="span">
                      Dados de Paletização
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Paletizado com"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Peças por palete"
                      value={''}
                      variant="standard"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography component="span">Medidas do Palete</Typography>
                  </Grid>
                </Section>
              </Grid>

              <ButtonRow
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  marginTop: 30
                }}
              >
                <Button
                  style={{
                    marginRight: '10px',
                    backgroundColor: '#4060E6',
                    color: '#FFFFFF'
                  }}
                  variant="contained"
                  onClick={() => {
                    props.close();
                  }}
                >
                  Voltar
                </Button>
                {/* <Button
                  style={{
                    marginRight: '10px',
                    backgroundColor: '#EE207A',
                    color: '#FFFFFF'
                  }}
                  onClick={() => {
                    handleOpenDialog();
                  }}
                  color="error"
                  variant="contained"
                >
                  Reprovar
                </Button>
                <Button
                  style={{
                    marginRight: '10px',
                    backgroundColor: '#1AB1B0',
                    color: '#FFFFFF'
                  }}
                  onClick={() => console.log('PORRA')}
                  color="success"
                  variant="contained"
                >
                  Gerar ordem de venda
                </Button> */}
              </ButtonRow>
            </DialogContentText>
          </DialogContent>
        </form>
      </DialogStyle>
    </>
  );
};

export default FTSample;
