import React from 'react'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import BudgetConfigTable from './components/BudgetConfigTable'
import GenericDialog from 'components/genericDialog/GenericDialog'
import BudgetConfigForm from './components/BugdetConfigForm'
import BudgetConfigView from './components/BudgetConfigView'

const TabPanel = styled(TabPanelUnstyled)`
width: 100%;
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
`
const reportBudgetConfig: React.FC = () => {
  const [openEdit, setOpenEdit] = React.useState(false)
  const [dataEdit, setDataEdit] = React.useState<any>()

  const [openView, setOpenView] = React.useState(false)
  const [dataView, setDataView] = React.useState<any>()

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr repeat(2, minmax(auto, max-content))',
        gap: '15px'
      }}
      >
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Relatórios', url: '/home/report' },
          { text: 'Orçamento Vs Realizado', url: '/home/report/reportBudget' },
          { text: 'Contas Contábeis e Outros' }
        ]}
      />
      </div>

      <div style={{ marginTop: 20 }}>
        <TabsUnstyled defaultValue={0}>
          <TabPanel value={0}><BudgetConfigTable view={(data) => { setOpenView(true); setDataView(data) }} edit={(data) => { setOpenEdit(true); setDataEdit(data) }} refetch={openEdit} /></TabPanel>
        </TabsUnstyled>
      </div>
      {openEdit &&
        <GenericDialog open={openEdit} close={() => { setOpenEdit(false); setDataEdit(null) }}
          content={<BudgetConfigForm edit={dataEdit} close={() => { setOpenEdit(false); setDataEdit(null) }} />} />}
      {openView &&
        <GenericDialog open={openView} close={() => { setOpenView(false); setDataView(null) }}
          content={<BudgetConfigView view={dataView} close={() => { setOpenView(false); setDataView(null) }} />} />}
    </Box>
  )
}

export default reportBudgetConfig
