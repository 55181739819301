import { styled } from '@mui/material/styles'
import {
  Box,
  Icon,
  TableRow
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FAFAFA',
    color: theme.palette.common.black,
    verticalAlign: 'top'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export const BoxStyle = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  margin-right: 88px;
`

export const IconStyle = styled(Icon)`
  margin-left: 10px;
`
