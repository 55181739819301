function SampleShowContent(props: any) {
  const { image } = props

  return (
    <>
      {image && <img
        style={{
          border: '2px solid #6868684A',
          borderRadius: '6px',
          width: '80%',
          height: 'fit-content'
        }}
        src={String(image)}
      ></img>}
    </>

  )
}

export default SampleShowContent
