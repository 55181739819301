import { httpClient } from '../../config/httpClient'
import FormProfileType from '../../types/profiles/FormProfileType'

export const createProfile = async (dadosForm: FormProfileType) => {
  return await httpClient.post('/profiles', dadosForm)
}

export const getProfile = async () => {
  return await httpClient.get('/profiles')
}

export const updateProfile = async (id: string, dadosForm: any) => {
  return await httpClient.put(`/profiles/${id}`, dadosForm)
}

export const deleteProfile = async (id: string) => {
  return await httpClient.delete(`/profiles/${id}`)
}
