/* eslint-disable */
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Box, Tab, Tabs } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { getStockAtualLN } from 'services/report/Stock'
import { MovementTable } from '../components/MovementTable'
import { StockTable } from '../components/StockTable'
import { SummaryTable } from '../components/SummaryTable'

function a11yProps(index: number) {
  return {
    id: `stock-tab-${index}`,
    'aria-controls': `stock-tabpanel-${index}`
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`stock-tabpanel-${index}`}
      aria-labelledby={`stock-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

export const StockContainer: React.FC = () => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date())
  const [selectedDateExit, setSelectDateExit] = React.useState<Date | null>(new Date())
  const [value, setValue] = React.useState(0)

  const { data, status, refetch, isFetching } = useQuery(
    'report-stock',
    async () => {
      const date = moment(selectedDate).format('YYYY-MM-DD')
      return getStockAtualLN(date)
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {
        /*
        <TabPanel value={value} index={0}>
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: '10px',
                marginRight: '25px'
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  label="Data"
                  inputFormat="dd/MM/yyyy"
                  value={selectDate}
                  onChange={(newValue) => {
                    setSelectDate(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" helperText={null} />
                  )}
                />
              </LocalizationProvider>
              <LoadingButton
                sx={{
                  // backgroundColor: '#4060E6',
                  width: '122px',
                  height: '40px',
                  marginLeft: 2
                }}
                variant="contained"
                loading={status === 'loading' || isFetching}
                disabled={status === 'loading' || isFetching}
                onClick={async () => refetch()}
              >
                {status !== 'loading' && !isFetching && 'Pesquisar'}
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: '10px',
                marginRight: '25px'
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  label="Data"
                  inputFormat="dd/MM/yyyy"
                  value={selectDateExit}
                  onChange={(newValue) => {
                    setSelectDateExit(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </Grid>
        </Grid>
      </TabPanel>
        */
      }
      <Box sx={{ marginTop: 3, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="ESTOQUE" {...a11yProps(0)} />
          <Tab label="RESUMO" {...a11yProps(1)} />
          <Tab label="MOVIMENTAÇÃO" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <StockTable
          data={data}
          status={status}
          isFetching={isFetching}
          selectedDate={selectedDate}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SummaryTable
          data={data}
          status={status}
          isFetching={isFetching}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MovementTable selectDate={selectedDateExit} />
      </TabPanel>
    </>
  )
}
