import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { ReportVolumeMes } from 'types/Report/ReportVolume'
import { StandardFilter } from 'utils/StandardFilter'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import {
  Box,
  CircularProgress,
  Icon,
  TextField,
  Typography
} from '@mui/material'
import moment from 'moment'
import { formatValue, toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
  boxReq: any
}

const BoxStyle = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  marginright: 88px;
`
const IconStyle = styled(Icon)`
  margin-left: 10px;
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FAFAFA',
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const stickyStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 3,
  background: '#fafafa'
}

const TableBox: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [dados, setDados] = useState<any>([])
  const [dataSource, setDataSource] = useState<any[]>([])
  const [filters, setFilters] = useState<any>({})
  const [meses, setMeses] = useState<ReportVolumeMes[]>([])
  const mes = moment(props.date).format('M')

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => {
    const loadedData = [props.boxReq.isLoading].every((loading) => !loading)
    if (loadedData) {
      handleTableData(props.boxReq.data.response)
    }
  }, [props.boxReq.isLoading])

  const handleTableData = (data) => {
    const filteredData = data.filter((item) =>
      item.dados.some((dado) => dado.type === TipoProduto.CAIXA)
    )
    setDataSource(filteredData)
    setDados(filteredData)
    handlePeriodo()
  }

  useEffect(() => {
    StandardFilter(filters, setDados, dataSource)
  }, [filters, dataSource])

  const handlePeriodo = () => {
    const mesesValue = [
      {
        text: 'Janeiro',
        value: 1
      },
      {
        text: 'Fevereiro',
        value: 2
      },
      {
        text: 'Março',
        value: 3
      },
      {
        text: 'Abril',
        value: 4
      },
      {
        text: 'Maio',
        value: 5
      },
      {
        text: 'Junho',
        value: 6
      },
      {
        text: 'Julho',
        value: 7
      },
      {
        text: 'Agosto',
        value: 8
      },
      {
        text: 'Setembro',
        value: 9
      },
      {
        text: 'Outubro',
        value: 10
      },
      {
        text: 'Novembro',
        value: 11
      },
      {
        text: 'Dezembro',
        value: 12
      }
    ]
    const meses: any[] = []
    let i = 0
    while (i < Number(mes)) {
      meses.push(mesesValue[i])
      i++
    }
    setMeses(meses)
  }

  const getRowByPeriodo = (row: any, periodo: number) => {
    const dadosFilter = row.dados.filter((valor) => valor.type === TipoProduto.CAIXA)
    return dadosFilter.find((item) => Number(item.periodo) === periodo)
  }

  const getSumByPeriodKG = (row: any) => {
    const dadosFilter = row.dados.filter((valor) => valor.type === TipoProduto.CAIXA)
    return dadosFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.kg)
    }, 0)
  }

  const getSumByPeriodRSKg = (row: any) => {
    const dadosFilter = row.dados.filter((valor) => valor.type === TipoProduto.CAIXA)
    return dadosFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.rskg)
    }, 0)
  }

  return (
    <>
      {(props.boxReq.isLoading || props.boxReq.isFetching) && (
        <BoxStyle>
          <CircularProgress />
        </BoxStyle>
      )}
      {props.boxReq.isError && (
        <BoxStyle>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <IconStyle>signal_wifi_statusbar_connected_no_internet_4</IconStyle>
        </BoxStyle>
      )}

      {props.boxReq.isSuccess &&
        !props.boxReq.isError &&
        !props.boxReq.isFetching && (
          <>
            <Paper sx={{ width: '80vw' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table sx={{ maxWidth: '100%' }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center" sx={stickyStyle}>Cliente</StyledTableCell>
                      {meses.map((mes) => (
                        <StyledTableCell align="center" colSpan={2}>
                          {mes.text}
                        </StyledTableCell>
                      ))}
                      <StyledTableCell align="right">Acumulado</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell style={{ width: '20%', top: 57 }} sx={stickyStyle}>
                        <TextField
                          id="standard-basic"
                          label="Pesquisar Cliente"
                          variant="standard"
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setFilters({ ...filters, client: e.target.value })
                          }
                        />
                      </StyledTableCell>
                      {meses.map((mes, index) => (
                        <>
                          <StyledTableCell
                            align={'center'}
                            key={index}
                            style={{ top: 57 }}
                          >
                            KG
                          </StyledTableCell>
                          <StyledTableCell
                            align={'center'}
                            key={mes.text}
                            style={{ top: 57 }}
                          >
                            R$/KG
                          </StyledTableCell>
                        </>
                      ))}
                      <StyledTableCell align={'center'} style={{ top: 57 }}>KG</StyledTableCell>
                      <StyledTableCell align={'center'} style={{ top: 57 }}>R$/KG</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {dados
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const valorTotalKG = getSumByPeriodKG(row)
                        const valorTotalRSKg = getSumByPeriodRSKg(row)
                        return (
                          <StyledTableRow hover role="checkbox" tabIndex={-1}>
                            <StyledTableCell sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 2,
                              background: '#FFF'
                            }}>{row.client}</StyledTableCell>
                            {meses.map((mes) => {
                              const valor = getRowByPeriodo(row, mes.value)
                              return (
                                <>
                                  <StyledTableCell align={'center'}>
                                    {formatValue(valor?.kg ?? 0)}
                                  </StyledTableCell>
                                  <StyledTableCell align={'center'}>
                                    <Typography
                                      variant="body2"
                                      component="span"
                                      noWrap
                                    >
                                      {toBRL(valor?.rskg ?? 0, { noBRL: true })}
                                    </Typography>
                                  </StyledTableCell>
                                </>
                              )
                            })}
                            <StyledTableCell align={'center'}>
                              {formatValue(valorTotalKG)}
                            </StyledTableCell>
                            <StyledTableCell align={'center'}>
                              <Typography
                                variant="body2"
                                component="span"
                                noWrap
                              >
                                {toBRL(valorTotalRSKg, { noBRL: true })}
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                labelRowsPerPage="Linhas por página:"
                count={dados.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}
    </>
  )
}

export default TableBox
