/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  CircularProgress,
  Icon,
  Typography
} from '@mui/material'
import {
  DataValues,
  IdentifierClient,
  IdentifierInformative
} from 'types/Report/Report'
import { FATURAMENTO_MES, META_DIA } from 'constants/constReportBillid'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportBilling } from 'services/report/ReportBilling'
import { getReportRH } from 'services/report/ReportRH'
import { formatValue, toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'
// import { _addReport } from '../slice/reportRealizedDay.slice'

interface Props {
  date: string
}

const TableSalesGoal: React.FC<Props> = (props) => {
  // const [filters, setFilters] = useState<any>({})
  const [daysutils, setDaysUtils] = useState<any>()
  const [daysFatured, setDaysFatured] = useState<any>()
  const [salesData, setSalesData] = useState<any>([])
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const [valorRow] = useState<any[]>([
    {
      text: 'Mês',
      value: FATURAMENTO_MES
    },
    {
      text: 'Dia',
      value: META_DIA
    },
    {
      text: 'Acumulado',
      value: 3
    }
  ])

  const queryMultiple = () => {
    const req1 = useQuery(['sales-report', mes, ano],
      async () => {
        return getReportBilling(mes, ano)
      },
      {
        onSuccess: (date) => {
          console.log('date =>', date)
          const valueDifference = [
            {
              tipo: '1',
              dados: [
                {
                  type: TipoProduto.CAIXA,
                  kg: date.caixa.dados.amountKg,
                  rs: date.caixa.dados.amountPrice,
                  rskg: 0
                }
              ]
            },
            {
              tipo: '1',
              dados: [
                {
                  type: TipoProduto.CHAPA,
                  kg: date.chapa.dados.amountKg,
                  rs: date.chapa.dados.amountPrice,
                  rskg: 0
                }
              ]
            },
            {
              tipo: '1',
              dados: [
                {
                  type: TipoProduto.PAPEL,
                  kg: date.bobina.dados.amountKg,
                  rs: date.bobina.dados.amountPrice,
                  rskg: 0
                }
              ]
            }
          ]
          setSalesData(valueDifference)
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false
      }
    )
    console.log('req1 =>', req1)
    const req2 = useQuery(['report-rh', props.date],
      async () => {
        return getReportRH(props.date)
      },
      {
        onSuccess: (data) => {
          handleDataPlanejed(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    console.log('req2 =>', req2)
    return [req1, req2]
  }

  const [req1] = queryMultiple()

  const [dataValues, setDataValues] = useState<DataValues[]>([])

  useEffect(() => {
    getDataReport()
  }, [daysFatured, daysutils, salesData])

  const handleDataPlanejed = (dataPlan) => {
    const arrayFaturamento: any[] = []
    dataPlan.forEach(e => {
      if (e.production.name === 'Faturamento') {
        arrayFaturamento.push(e)
      }
    })
    if (arrayFaturamento.length) {
      const daysPlanejed = arrayFaturamento[0].days.length ?? 0
      const automaticWorkDays = arrayFaturamento[0].automaticWorksDays.length ?? 0
      setDaysFatured(automaticWorkDays)
      setDaysUtils(daysPlanejed)
    }
  }

  const searchDataResponse = (type, typeClient) => {
    const search = salesData.find(
      (response) =>
        response.tipo === type &&
        response.dados.some((responseItem) => responseItem.type === typeClient)
    )
    return search || {}
  }

  const getDataReport = () => {
    const initialCustomers = makeClientInitial()
    console.log('initialCustomers =>', initialCustomers)

    initialCustomers.forEach((data) => {
      const typeClient = data.identifierClient

      const { dados: dataAccumulated } = searchDataResponse(
        data.faturamento.identifier,
        typeClient
      )

      // Dia
      if (dataAccumulated) {
        const { rs, kg, rskg } = dataAccumulated[0]
        console.log('dias uteis', daysutils)
        data.acumulado = {
          ...data.acumulado,
          rs: rs / daysutils,
          kg: kg / daysutils,
          rskg
        }
      }

      const { dados: dataBilling } = searchDataResponse(
        data.faturamento.identifier,
        typeClient
      )
      if (dataBilling) {
        console.log('billing', dataBilling[0])
        const { rs, kg, rskg } = dataBilling[0]
        data.faturamento = { ...data.faturamento, rs, kg, rskg }
      }

      const { dados: dataDiferenca } = searchDataResponse(
        data.faturamento.identifier,
        typeClient
      )
      if (dataDiferenca) {
        const { rs, kg, rskg } = dataDiferenca[0]
        data.diferenca = {
          ...data.diferenca,
          rs: (rs / daysutils) * daysFatured,
          kg: (kg / daysutils) * daysFatured,
          rskg
        }
      }
    })

    const getTotal = (index: 'acumulado' | 'faturamento' | 'diferenca') =>
      initialCustomers.reduce(
        (acum, item) => {
          acum.rs += item[index].rs
          acum.kg += item[index].kg
          acum.rskg += item[index].rskg ?? 0
          return acum
        },
        { rs: 0, kg: 0, rskg: 0 }
      )

    const accumulatedTotal = getTotal('acumulado')
    const totalBilled = getTotal('faturamento')
    const totalDiference = getTotal('diferenca')

    const columnTotal = initialCustomers[initialCustomers.length - 1]
    columnTotal.acumulado = {
      ...columnTotal.acumulado,
      ...accumulatedTotal
    }

    columnTotal.faturamento = {
      ...columnTotal.faturamento,
      ...totalBilled
    }

    columnTotal.diferenca = {
      ...columnTotal.diferenca,
      ...totalDiference
    }

    setDataValues(initialCustomers)
  }

  // Monta o json com os valores todos zerados
  const makeClientInitial = () => {
    const customers = [
      {
        text: 'Caixas',
        type: IdentifierClient.CAIXA
      },
      {
        text: 'Chapas',
        type: IdentifierClient.CHAPA
      },
      {
        text: 'Papel',
        type: IdentifierClient.PAPEL
      },
      {
        text: 'Total',
        type: IdentifierClient.TOTAL
      }
    ]

    const updateData: DataValues[] = []
    customers.forEach((client) => {
      const informative = {
        kg: 0,
        rs: 0,
        rskg: 0
      }

      updateData.push({
        client: client.text,
        identifierClient: client.type,
        acumulado: {
          ...informative,
          identifier: IdentifierInformative.ACUMULADO
        },
        diferenca: {
          ...informative,
          identifier: IdentifierInformative.DIFFERENCE
        },
        faturamento: {
          ...informative,
          identifier: IdentifierInformative.FATURAMENTO_DIA
        },
        devolucao: {
          ...informative,
          identifier: IdentifierInformative.DEVOLUCAO
        }
      })
    })

    return updateData
  }

  return (
    <>
      {(req1.isLoading || req1.isRefetching) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {req1.isError && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>
            signal_wifi_statusbar_connected_no_internet_4
          </Icon>
        </Box>
      )}

      {req1.isSuccess && !(req1.isLoading || req1.isRefetching) && (
        <>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align='left'></TableCell>
                    {valorRow.map((item, index) => {
                      return (
                        <TableCell align='left' colSpan={item.text === 'Mês' ? 3 : 2} key={index}>
                          {item.text}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 3,
                      background: '#FFF'
                    }}>
                      Produto
                    </TableCell>
                    {valorRow.map((item) => (
                      <>
                        <TableCell>KG</TableCell>
                        <TableCell>R$</TableCell>
                        {item.text === 'Mês'
                          ? <TableCell>R$/KG</TableCell>
                          : undefined}
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataValues.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        key={index}
                      >
                        <TableCell sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 2,
                          background: '#FFF'
                        }}>{row.client}</TableCell>
                        <TableCell >
                          {!Number.isFinite(row.faturamento.kg)
                            ? 0
                            : formatValue(row.faturamento.kg)}
                        </TableCell>
                        <TableCell >
                          <Typography component="span" noWrap variant='body2'>
                            {toBRL(row.faturamento.rs ?? 0, { noBRL: true })}
                          </Typography>
                        </TableCell>
                        <TableCell >
                          <Typography component="span" noWrap variant='body2'>
                            {toBRL(
                              row?.faturamento?.rs
                                ? row.faturamento.rs / row.faturamento.kg
                                : 0
                              , { noBRL: true }
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell >
                          {!Number.isFinite(row.acumulado.kg)
                            ? 0
                            : formatValue(row.acumulado.kg)}
                        </TableCell>
                        <TableCell >
                          <Typography component="span" noWrap variant='body2'>
                            {toBRL(!Number.isFinite(row.acumulado.rs)
                              ? 0
                              : row.acumulado.rs
                              , { noBRL: true })}
                          </Typography>
                        </TableCell>
                        <TableCell >
                          {!Number.isFinite(row.diferenca.kg)
                            ? 0
                            : formatValue(row.diferenca.kg)}
                        </TableCell>
                        <TableCell >
                          <Typography component="span" noWrap variant='body2'>
                            {toBRL(!Number.isFinite(row.diferenca.rs)
                              ? 0
                              : row.diferenca.rs
                              , { noBRL: true })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  )
}

export default TableSalesGoal
