import { httpLN } from 'config/httpClient'

export const getReportConsolidated = async (date: string): Promise<any> => {
  const { data } = await httpLN.get(`/faturamentos/mes?data=${date}`)
  return data as any[]
}

export const getReportDevolutionMonthly = async (date: string): Promise<any> => {
  const { data } = await httpLN.get(`/faturamentos/devolucao-mensal?data=${date}`)
  return data as any[]
}
