import { RootState } from 'core/store'
import { useSelector } from 'react-redux'
import ModulesType from 'types/modules/ModulesType'

interface UseCanParams {
  moduleCod: 'MD' | 'MP' | 'MU' | 'MC' | 'ME' | 'MG' | 'MT' | 'MO' | 'MR' | 'MA' | string
  permissions?: string[]
  every?: boolean
};

export function useCan({ moduleCod, permissions = [], every = false }: UseCanParams) {
  const { loginData } = useSelector((state: RootState) => state.login)
  const modules: ModulesType[] = loginData?.tokenData?.modules ?? []

  if (!modules?.length || !permissions?.length) {
    return false
  }

  const findModule = modules?.find(item => item.cod === moduleCod)

  if (!findModule) {
    return false
  }

  const hasAllPermissions = permissions?.every((permission) => {
    return !!findModule?.permissions?.find((item) => item.cod === permission)
  })

  if (every && !hasAllPermissions) {
    return false
  }

  const hasPermissions = permissions?.some((permission) => {
    return !!findModule?.permissions?.find((item) => item.cod === permission)
  })

  if (!every && !hasPermissions) {
    return false
  }

  return true
}
