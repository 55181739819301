enum IdentifierInformative {
  FATURAMENTO_DIA = '1',
  ACUMULADO = '2',
  DIFFERENCE = '3',
  DEVOLUCAO = '4'
}

interface Informativo {
  identifier: IdentifierInformative
  kg: number
  rs: number
  rskg?: number
}

enum IdentifierClient {
  CAIXA = '0001',
  CHAPA = '0002',
  PAPEL = '0006',
  OUTROS = '0004',
  PP = '0008',
  PO = '0007',
  TOTAL = '0',
}

interface DataValues {
  client: string
  identifierClient: IdentifierClient
  faturamento: Informativo
  acumulado: Informativo
  diferenca: Informativo
  devolucao: Informativo
}

interface StockBalance {
  tipo: string
  kg: number
  qtd: number
}

export { IdentifierInformative, IdentifierClient }
export type { Informativo, DataValues, StockBalance }
