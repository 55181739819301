import React, { useState } from 'react'
import { Box, TextField } from '@mui/material'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import ModulesClient from './components/ModulesClient'
import TableTotalProduct from './components/TableTotalProduct'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import moment from 'moment'
import 'moment/locale/pt-br'
import { ptBR } from 'date-fns/locale'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'

const reportConsolidated: React.FC = () => {
  const [value, setValue] = React.useState('1')
  const [valueDate, setValueDate] = useState<any | null>(new Date())

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Relatórios', url: '/home/report' },
          { text: 'Faturamento R$ Consolidado' }
        ]}
      />
        <h4>Faturamento R$ Consolidado: {moment(valueDate).locale('pt-br').format('MMMM YYYY')}</h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '10px',
            marginRight: '25px'
          }}
        >
          <div style={{ marginRight: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Dia"
                value={valueDate}
                onChange={(newValue) => {
                  setValueDate(newValue)
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </div>
          {/* <LoadingButton
            style={{
              backgroundColor: '#4060E6',
              borderRadius: '2px',
              width: '122px',
              height: '35px',
              color: 'white',
              marginTop: '10px'
            }}
            variant="outlined"
            loading={loading}
            onClick={() => getClickReportDados()}
          >
            {loading ? '' : 'Pesquisar'}
          </LoadingButton> */}
        </div>
        <div>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Por Cliente" value="1" />
                <Tab label="Total por produto" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" style={{ paddingLeft: 0, paddingRight: 0 }}><ModulesClient date={valueDate}/></TabPanel>
            <TabPanel value="2" style={{ paddingLeft: 0, paddingRight: 0 }}><TableTotalProduct date={valueDate}/></TabPanel>
          </TabContext>
        </div>
      </Box>
    </>
  )
}

export default reportConsolidated
