/* eslint-disable @typescript-eslint/no-unused-vars */
import { KeyboardArrowRight } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from 'components/form/Button'
import { httpClient } from 'config/httpClient'
import moment from 'moment'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { StatusRequestForProposalsType } from 'types/Report/RequestForProposalsType'
import boxImg from 'assets/img/formbox.png'
import plateImg from 'assets/img/formplate.png'
import coilImg from 'assets/img/formpaper.png'
import Avatar from '@mui/material/Avatar/Avatar'
import { PropostaPdf } from './PropostaPdf'
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer'
import { getBudget } from 'services/ft/FTService'
import { BudgetDataType } from 'types/ft/BudgetDataType'

function RowItem({
  title,
  value,
  ...rest
}: {
  title: string
  value: string | number
}) {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Typography
        variant="body2"
        component="strong"
        fontWeight="bold"
        sx={{ minWidth: 200 }}
      >
        {title}
      </Typography>
      <Typography variant="body2" component="span" textAlign="right">
        {value}
      </Typography>
    </Stack>
  )
}

const getImage: any = {
  caixa: boxImg,
  bobina: coilImg,
  chapa: plateImg
}

const color = {
  'NOVA PROPOSTA': '#6B49E8',
  APROVADO: '#00e63a',
  REPROVADO: '#EE207A',
  NOVO: '#6B49E8',
  'EM ANÁLISE': '#00B4E6',
  'PENDENTE PELA CONTROLADORIA': '#00B4E6',
  'APROVADA PELO CLIENTE': '#00e63a',
  'APROVADA PELO VENDEDOR': '#00e63a',
  'RECUSADO PELO CLIENTE': '#EE207A',
  'REPROVADA PELO CLIENTE': '#EE207A',
  'RECUSADA PELO VENDEDOR': '#EE207A',
  'REPROVADA PELO VENDEDOR': '#EE207A',
  'REPROVADA PELA CONTROLADORIA': '#EE207A',
  CANCELADO: '#EE207A'
}

interface Props {
  open: boolean
  onClose: () => void
  ft: any
}

export const ModalFtProposalClient: React.FC<Props> = ({
  open,
  onClose,
  ft
}) => {
  console.log('ft', ft)
  const [proposals, setProposals] = useState<any[]>([])

  const [proposal, setProposal] = useState<any | null>(null)
  const [openProposal, setOpenProposal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openPDF, setOpenPDF] = useState(false)

  const [openReprove, setOpenReprove] = useState(false)
  const [disapprovalReason, setDisapprovalReason] = useState('')

  const [budgetData, setBudgetData] = React.useState<BudgetDataType>()

  const { status, refetch, isFetching } = useQuery(
    ['ft-proposal-client'],
    async () => {
      return httpClient.get(`/budget/proposalsByPreFT/${ft?.preFT?._id}`)
    },
    {
      onSuccess: (response) => {
        console.log('proposal by preft', response)
        setProposals(response.data)
      },
      refetchOnWindowFocus: false
    }
  )

  useQuery(
    ['budget-req', null],
    async () => {
      return getBudget(ft?._id)
    },
    {
      onSuccess: (data) => {
        if (data.length > 0) {
          const budgetData = data[0]
          setBudgetData(budgetData)
        }
      },
      refetchOnWindowFocus: false
    }
  )

  const handleOpenProposal = (item: any) => {
    setProposal(item)
    setOpenProposal(true)
  }

  const handleCloseProposal = () => {
    setProposal(null)
    setOpenProposal(false)
    setOpenReprove(false)
    setDisapprovalReason('')
  }

  const handleChangeStatus = async (
    status:
      | 'EM ANÁLISE'
      | 'REPROVADA PELO VENDEDOR'
      | 'REPROVADA PELO CLIENTE'
      | 'APROVADA PELO VENDEDOR'
      | 'APROVADA PELO CLIENTE',
    item: any
  ) => {
    if (
      (status === 'REPROVADA PELO CLIENTE' ||
        status === 'REPROVADA PELO VENDEDOR') &&
      !disapprovalReason?.length
    ) {
      toast.warn('Informe o motivo da reprovação.')
      return
    }

    setIsLoading(true)
    try {
      await httpClient.put(`/budget/proposal/${item?._id ?? proposal?._id}`, {
        status,
        disapprovalReason: disapprovalReason ?? ''
      })
      if (status === StatusRequestForProposalsType.FAILED_CUSTOMER) {
        toast.success('Proposta reprovada')
      } else {
        toast.success('Proposta aprovada')
      }

      await httpClient.post(`/budget/proposal/recuse-price/${item?._id ?? proposal?._id}`)

      refetch()

      setIsLoading(false)
      handleCloseProposal()
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
      setIsLoading(false)
    }
  }

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant="h6" component="span" sx={{ marginRight: 2 }}>
            Propostas
          </Typography>
          <Typography variant="body2" component="span">
            Aprovar propostas como cliente.
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ minHeight: 300 }}>
          {(status === 'loading' || isFetching) && (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 4 }}
            >
              <CircularProgress size={24} />
            </Stack>
          )}

          {status !== 'loading' && !isFetching && !proposals.length && (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 4 }}
            >
              <Typography variant="body2">
                Nenhuma proposta encontrada.
              </Typography>
            </Stack>
          )}

          {status !== 'loading' &&
            !isFetching &&
            proposals.map((proposal) => (
              <Box key={proposal?._id} sx={{ paddingBottom: 2 }}>
                <Typography variant="body2" component="span">
                  {moment(proposal?.createdAt).format('DD/MM/YYYY') ===
                  moment().format('DD/MM/YYYY')
                    ? moment(proposal?.createdAt).fromNow()
                    : moment(proposal?.createdAt).format(
                        'DD/MM/YYYY [às] HH:mm'
                      )}
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => handleOpenProposal(proposal)}
                  sx={{
                    borderRadius: 1,
                    padding: 1,
                    border: '2px solid #00000029',
                    cursor: 'pointer'
                  }}
                >
                  <Avatar
                    alt={proposal?._id}
                    src={getImage[ft?.preFT?.type?.toLowerCase()]}
                    sx={{
                      borderRadius: 1,
                      marginRight: 2,
                      height: 60,
                      width: 60
                    }}
                  />

                  <Stack spacing={1}>
                    <Typography variant="body2" component="span">
                      {proposal?._id}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      color={color[proposal?.status]}
                      fontWeight="bold"
                    >
                      {proposal?.status}
                    </Typography>
                  </Stack>

                  <Box sx={{ marginLeft: 'auto' }}>
                    <KeyboardArrowRight />
                  </Box>
                </Box>
              </Box>
            ))}
        </DialogContent>
      </Dialog>

      {proposal?._id && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openProposal}
          // onClose={handleCloseProposal}
        >
          <DialogTitle>
            <Typography variant="h6" component="span" sx={{ marginRight: 2 }}>
              Proposta{' '}
              <Typography variant="body1" component="span">
                {proposal?._id}
              </Typography>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" component="span">
              Você pode aprovar ou reprovar a proposta pelo cliente.
            </Typography>

            {(proposal?.status?.toUpperCase() ===
              StatusRequestForProposalsType.APPROVED_BOARD ||
              proposal?.status?.toUpperCase() ===
                StatusRequestForProposalsType.APPROVED_CUSTOMER ||
              proposal?.status?.toUpperCase() ===
                StatusRequestForProposalsType.APPROVED_SELLER) && (
              <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
                <Button onClick={() => setOpenPDF(true)}>Gerar PDF</Button>
              </Stack>
            )}

            <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
              <Typography variant="body2" component="strong" fontWeight="bold">
                REF:
              </Typography>
              <Typography variant="body2" component="strong" fontWeight="bold">
                {ft?.preFT?.preftbox?.reference ||
                  ft?.preFT?.formulariochapa?.Referencia ||
                  ft?.preFT?.preftplate?.Referencia ||
                  ''}
              </Typography>
            </Stack>

            <RowItem
              title="Preço"
              value={`R$ ${
                proposal?.price?.toFixed(2)?.replace('.', ',') ?? '0,00'
              }`}
            />
            <RowItem
              title="Frete"
              value={`R$ ${
                proposal?.shipping?.toFixed(2)?.replace('.', ',') ?? '0,00'
              }`}
            />
            <RowItem
              title="Gramatura"
              value={`${proposal?.gramature ?? '0'}g`}
            />
            <RowItem title="Medida interna" value={''} />
            <RowItem
              title="Lote mínimo"
              value={`${proposal?.minimumLot ?? '0'} Unid`}
            />
            <RowItem
              title="Condição de pagamento"
              value={proposal?.paymentCondition}
            />
            <RowItem
              title="Taxa de financiamento"
              value={`${proposal?.financingRate}%`}
            />
            <RowItem title="ICMS" value={proposal?.icms} />
            <RowItem
              title="Validade da proposta"
              value={`${proposal?.validity} dias`}
            />
            <RowItem
              title="Prazo de entrega"
              value={`${proposal?.deadlineDeliverDays} dias úteis`}
            />
            <Box
              sx={{
                marginBottom: 2,
                marginTop: 2
              }}
            >
              <Typography variant="body2" component="span">
                As ordens de fabricação poderão ser consideradas completas com
                10% a mais ou a menos da quantidade solicitada.
              </Typography>
            </Box>
            <RowItem title="Dados Adicionais" value={proposal?.observation} />
            {proposal?.disapprovalReason && (
              <RowItem
                title="Motivo da reprovação"
                value={proposal?.disapprovalReason}
              />
            )}

            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={3}
              sx={{ marginTop: 3 }}
            >
              <Box sx={{ flex: 1, marginRight: 'auto' }}>
                <Button disabled={isLoading} onClick={handleCloseProposal}>
                  Voltar
                </Button>
              </Box>
              {
                // proposal?.status?.toUpperCase() !== StatusRequestForProposalsType.APPROVED_CUSTOMER &&
                // proposal?.status?.toUpperCase() !== StatusRequestForProposalsType.FAILED_CUSTOMER &&
                // proposal?.status?.toUpperCase() !==
                //   StatusRequestForProposalsType.PENDING_BOARD &&
                proposal?.status?.toUpperCase() !==
                  StatusRequestForProposalsType.FAILED_BOARD && (
                  <>
                    <Button
                      mold="red"
                      disabled={isLoading}
                      onClick={async () =>
                        // handleChangeStatus('REPROVADA PELO CLIENTE', proposal)
                        setOpenReprove(true)
                      }
                    >
                      Reprovar
                    </Button>
                    {proposal?.status?.toUpperCase() !==
                      StatusRequestForProposalsType.PENDING_BOARD && (
                      <Button
                        mold="primary"
                        disabled={isLoading}
                        onClick={async () =>
                          handleChangeStatus('APROVADA PELO CLIENTE', proposal)
                        }
                      >
                        Aprovar
                      </Button>
                    )}
                  </>
                )
              }
            </Stack>
          </DialogContent>
        </Dialog>
      )}

      {openReprove && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openReprove}
          onClose={setOpenReprove}
        >
          <DialogTitle>
            <Typography variant="h6" component="span" sx={{ marginRight: 2 }}>
              Reprovar proposta
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ minHeight: 200 }}>
            <Stack sx={{ paddingTop: 2 }}>
              <TextField
                name="disapprovalReason"
                label="Motivo da reprovação"
                value={disapprovalReason}
                onChange={(event) => setDisapprovalReason(event?.target?.value)}
                multiline
                minRows={6}
                disabled={isLoading}
                fullWidth
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={3}
              sx={{ marginTop: 3 }}
            >
              <Button
                disabled={isLoading}
                onClick={async () => setOpenReprove(false)}
              >
                Cancelar
              </Button>
              <Button
                mold="red"
                disabled={isLoading}
                onClick={async () =>
                  handleChangeStatus('REPROVADA PELO CLIENTE', proposal)
                }
              >
                Reprovar
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      )}

      {openPDF && proposal && (
        <Dialog fullWidth maxWidth="md" open={openPDF}>
          <DialogContent sx={{ minHeight: 200 }}>
            <PropostaPdf
              onClose={() => setOpenPDF(false)}
              ft={ft}
              budgetData={budgetData}
              proposal={{
                ...proposal,
                client: ft?.preFT?.client?.name,
                reference:
                  ft?.preFT?.preftbox?.reference ||
                  ft?.preFT?.formulariochapa?.Referencia ||
                  ft?.preFT?.preftplate?.Referencia ||
                  ''
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
