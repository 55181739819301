import { FileDownload } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import moment from 'moment'
import * as XLSX from 'xlsx'

interface Props {
  fileName?: string
  dataValues?: any[]
}

export function ExportExcel({ dataValues, fileName = 'arquivo' }: Props) {
  const handleExportClick = () => {
    if (!dataValues) return

    const data = dataValues?.map((item) => ({
      'Data de entrega OV': item?.dataEntregaOV
        ? moment(item.dataEntregaOV).format('DD/MM/YYYY HH:mm')
        : '',
      FT: item.item,
      Prioridade: item?.deliveryMap?.priority ?? '',
      Cliente: item.codClient,
      Referencia: item.reference,
      'Quantidade Solicitada': item.quantidadeSolicitada,
      'Quantidade Faturada': item.quantidadeFaturada,
      'Data Faturamento': item?.dataFaturamento
        ? moment(item.dataFaturamento).format('DD/MM/YYYY HH:mm')
        : '',
      // OP: item?.deliveryMap?.production_order,
      NF: item.nf,
      'Linha Faturar OV': item.linhaFaturarOV,
      id: item.id,
      peso: item.peso,
      entradaEstoque: item.entradaEstoque,
      saldo: item?.saldo
    }))

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(data)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Planilha1')
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }

  return (
    <>
      {!!dataValues?.length && (
        <Stack paddingY={2} alignItems={'flex-start'}>
          <Button
            color="success"
            onClick={handleExportClick}
            startIcon={<FileDownload />}
            variant='outlined'
          >
            Exportar para Excel
          </Button>
        </Stack>
      )}
    </>
  )
}
