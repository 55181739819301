/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowUpwardOutlined } from '@mui/icons-material'
import { Rating } from '@mui/lab'
import React from 'react'
import {
  BarChart,
  Bar,
  YAxis,
  Cell,
  Tooltip,
  CartesianGrid,
  XAxis
} from 'recharts'
import { TooltipContent } from './chartPie.style'
import { ArrowUpIcon, LabelRate, LabelTitle, RowCV } from './styles'

const CustomTooltip = ({ active, title, payload, event }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContent color={payload[0].payload.color}>
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>{`${title}`}</p>
        <p style={{ fontSize: 12 }}>{`${payload[0].value}`}</p>
      </TooltipContent>
    )
  }

  return null
}
export default function ChartRating(props: any) {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LabelTitle>
          <p> {props.title}</p>
        </LabelTitle>
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', textAlign: 'left' }}>
          <LabelRate>{!isNaN(props.total) ? props.total?.toFixed(2) : 0}</LabelRate>
          <Rating style={{ marginTop: 14, marginLeft: 10 }} name="read-only" value={props.total?.toFixed(1)} readOnly />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', textAlign: 'left' }}>
          <ArrowUpIcon>
            <ArrowUpwardOutlined style={{ fontSize: '16px' }} htmlColor='#4060E6' />
          </ArrowUpIcon>
          <div style={{ marginRight: 5 }}>{isNaN(props.evolution) ? 0 : props.evolution}%</div>
          <div>último mês</div>
        </div> */}
        <RowCV>
          <BarChart
            width={550}
            height={210}
            data={props.data}
            layout='vertical'
            margin={{ top: 25, right: 100, bottom: 25, left: -20 }}
          >
            {/* <XAxis dataKey="Text" fontFamily="sans-serif" dy="25" /> */}
            <Tooltip content={event => <CustomTooltip active={event.active} title={props.title} payload={event.payload} event={event} />} />
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis type="number" hide />
            <YAxis type="category" width={150} dataKey="title" />
            <Bar
              dataKey="value"
              barSize={10}
              fontFamily="sans-serif"
            // label={<CustomizedLabel />}
            >
              {
                props.data.map(d => (
                  <Cell fill={d.color} />
                ))
              }
            </Bar>
          </BarChart>
        </RowCV>
      </div>
    </div>
  )
}
