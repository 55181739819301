import React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function MiuTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

interface Props {
  index: number
  value: number
  children?: React.ReactNode
}

export const TabPanel: React.FC<Props> = ({ index, value, children }) => {
  return (
    <MiuTabPanel value={value} index={index}>
      {children}
    </MiuTabPanel>
  )
}
