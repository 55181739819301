import React from 'react'
import { Grid } from '@mui/material'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import styled from 'styled-components'
import TableCustomerReview from './TableCustomerReview'

const GridStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const CustomerReview: React.FC = () => {
  return (
    <>
      <GridStyle>
        <Grid>
          <BreadcrumbsComponent
            breadcrumbs={[
              { text: 'Gerencial', url: '/home/managementControl' },
              { text: 'Avaliações do App Cliente' }
            ]}
          />
        </Grid>
      </GridStyle>
      <Grid>
        <strong>Avaliações do App Cliente</strong>
      </Grid>
      <Grid>
        <TableCustomerReview/>
      </Grid>
    </>
  )
}

export default CustomerReview
