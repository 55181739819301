/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  TablePagination,
  TextField,
  Typography
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import paperImg from '../../assets/img/formpaper.png'
import boxImg from '../../assets/img/formbox.png'
import plateImg from '../../assets/img/formplate.png'
import { ArrowRight, ChevronRight } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { httpClient, httpLN } from 'config/httpClient'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { getClientsBySeller, getSeller, Seller } from 'services/ft/FTService'
import { getIe } from 'services/customerControl/CustomerControlServices'
import { uuid } from 'utils/generateUuid'
import { onlyNumbers } from 'helpers/stringHelper'
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'

export function PreFTPortfolioCoil() {
  const { loginData } = useSelector((state: RootState) => state.login)
  const navigate = useNavigate()

  const [originalForms, setOriginalForms] = useState<
    Array<{
      codItem: string
      dataCriacao: string
      descricao: string
      descricaoTipo: string
    }>
  >([])
  const [forms, setForms] = useState<
    Array<{
      codItem: string
      dataCriacao: string
      descricao: string
      descricaoTipo: string
    }>
  >([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any | null>(null)
  const [page, setPage] = useState(0)

  const [loadingItems, setLoadingItems] = useState(true)

  const [seller, setSeller] = useState<Seller | null>(null)
  const [clients, setClients] = useState<any[]>([])
  const [company, setCompany] = useState<any>()
  const [ies, setIes] = useState<any[]>([])

  const fetchFormFt = useCallback(async () => {
    setLoadingItems(true)

    try {
      const { data } = await httpLN.get('/order/itemsBobina')
      setForms(data?.result ?? [])
      setOriginalForms(data?.result ?? [])
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setLoadingItems(false)
    }
  }, [])

  useEffect(() => {
    void fetchFormFt()
  }, [])

  const handleFilterCoil = (searchValue: string) => {
    // console.log(forms)
    if (searchValue?.length) {
      const filteredWithSearch = originalForms.filter(
        (item: any) =>
          item?.codItem?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
          item?.descricaoTipo?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
          item?.descricao?.toLowerCase()?.includes(searchValue.toLowerCase())
      )

      setForms(filteredWithSearch)
    } else {
      setForms(originalForms)
    }
  }

  const fetchAll = useCallback(async () => {
    getClientsBySeller(loginData?.tokenData?.sub)
      .then((responseClients) => {
        setClients(responseClients?.data ?? [])
        if (!responseClients?.data?.length) {
          toast.warn('Nenhuma empresa disponível.')
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })

    getSeller(loginData?.tokenData?.sub)
      .then((response) => {
        // console.log(response?.data)
        setSeller(response?.data)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }, [])

  useEffect(() => {
    void fetchAll()

    return () => {
      setSeller(null)
    }
  }, [fetchAll])

  const handleClickOpenModal = (item: any) => {
    setSelectedItem(item)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedItem(null)
  }

  const handleLoadIes = useCallback(async (companyId) => {
    getIe(companyId)
      .then((response) => {
        setIes(response?.data ?? [])
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }, [])

  return (
    <>
    <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'FT', url: '/home/ft' },
          { text: 'Portfólio', url: '/home/ft/portfolio' },
          { text: 'Bobina ou Simplex' }
        ]}
      />

      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: 3, marginTop: 2 }}>
        Bobina ou Simplex
      </Typography>

      <Stack>
        <TextField
          type="text"
          placeholder="Pesquisar por código do parceiro ou descrição"
          variant="outlined"
          fullWidth
          onChange={(e) => handleFilterCoil(e.target.value)}
        />
      </Stack>

      <Stack spacing={1} sx={{ paddingTop: 2 }}>
        {loadingItems && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: 150 }}
          >
            <CircularProgress size={24} />
          </Stack>
        )}

        {!loadingItems &&
          forms?.slice(page * 10, page * 10 + 10).map((form, i) => (
            <Button
              key={i}
              onClick={() => handleClickOpenModal(form)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                border: '1px solid #ccc'
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                  src={paperImg}
                  sx={{ width: 50, height: 50, borderRadius: 1 }}
                />
                <Stack alignItems="flex-start">
                  <Typography
                    component="span"
                    variant="body2"
                    color="#003050"
                    fontWeight="bold"
                  >
                    Código: {form?.codItem}
                  </Typography>
                  <Typography component="span" variant="body1" color="#003050">
                    {form?.descricao}
                  </Typography>
                </Stack>
              </Stack>

              <ChevronRight />
            </Button>
          ))}

        {!loadingItems && (
          <TablePagination
            rowsPerPageOptions={[5]}
            rowsPerPage={5}
            component="div"
            count={forms.length}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} de ${count}`
              // return '' + from + '-' + to + ' de ' + count
            }}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        )}

      <Stack direction="row" justifyContent="flex-end" sx={{ paddingTop: 4 }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/home/ft/portfolio"
        >
          Voltar
        </Button>
      </Stack>
      </Stack>

      {/* Formulário preFT */}
      {selectedItem && (
        <Dialog
          open={openModal}
          // onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
          aria-labelledby="PreFTPortfolioCoil"
          aria-describedby="PreFTPortfolioCoil-description"
        >
          <DialogTitle id="PreFTPortfolioCoil">Pré-FT Bobina</DialogTitle>
          <Formik
            initialValues={{
              status: 'NOVO',
              type: 'BOBINA',
              client: null,
              ie: null,
              collaborator: null,
              seller: seller?._id ?? null,

              codCliente: '',
              nomeReduzidoCli: '',
              codPedido: '',
              formatoSN: 1,
              papel: selectedItem?.descricao,
              codItem: selectedItem?.codItem,
              descrErpProduto: selectedItem?.descricaoTipo,
              largura: '',
              diamComp: '',
              tubFib: '',
              dataEntrega: undefined,
              quantPedida: '',
              endereco: '',
              bairro: '',
              municipio: '',
              cnpjLoja: ''
            }}
            // validationSchema={validationChangePasswordSchema}
            onSubmit={async (dataValues) => {
              const prepareData = {
                codCliente: dataValues.codCliente, // '000000295',
                codPedido: dataValues.codPedido, // '1dcad5642b5024b92895',
                papel: dataValues.papel, // '',
                extra1: dataValues.codItem, // '042012',
                largura: Number(dataValues.largura ?? 0), // 55,
                diamComp: Number(dataValues.diamComp ?? 0), // 55,
                tubFib: Number(dataValues.tubFib ?? 0), // 1
                dataEntrega:
                  dataValues.dataEntrega ??
                  moment().format('YYYY-MM-DDTHH:mm:ss.SSS'), // '2022-08-29T14:37:32.554-03:00',
                quantPedida: Number(dataValues.quantPedida ?? 0), // 5555,
                endereco: dataValues.endereco, //
                bairro: dataValues.bairro, //
                municipio: dataValues.municipio, //
                cnpjLoja: dataValues.cnpjLoja, //
                descrErpProduto: dataValues.descrErpProduto ?? ''
              }

              // console.log('prepareData', prepareData)

              try {
                await httpClient.post(
                  '/pedidos/trimpaper/pedidos',
                  prepareData
                )

                toast.success(
                  'Solicitação de bobina criada com sucesso. Lembre-se de aprovar a solicitação no Trimpaper.'
                )

                navigate('/home/purchaseRequests/listProjectsAndItems')
              } catch (error: any) {
                toast.error(error?.response?.data?.message)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              isSubmitting
            }) => (
              <Form>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Autocomplete
                        noOptionsText='Sem opções'
                        loading={isSubmitting}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        getOptionLabel={(option) =>
                          option.isBlocked || !option.isActive
                            ? `${option.name} - [BLOQUEADO]`
                            : option.name
                        }
                        id="combo-box-clients"
                        options={clients.map((row) => ({
                          ...row,
                          label: row.name,
                          id: row._id
                        }))}
                        onChange={(_, selectedItem: any | null) => {
                          void setFieldValue(
                            'nomeReduzidoCli',
                            selectedItem?.name?.split(' ')?.[0]
                          )
                          const hash = uuid()?.split('-')
                          // console.log(hash)
                          void setFieldValue(
                            'codPedido',
                            `${hash?.[0]}${hash?.[4]}`
                          )

                          void setFieldValue('client', selectedItem?._id)
                          void setFieldValue(
                            'endereco',
                            `${selectedItem?.address?.publicPlace}, ${selectedItem?.address?.number}`
                          )
                          void setFieldValue(
                            'bairro',
                            selectedItem?.address?.district
                          )
                          void setFieldValue(
                            'municipio',
                            selectedItem?.address?.city
                          )

                          // void handleLoadCollaborator(selectedItem?._id)
                          // void setFieldValue('collaborator', null)
                          // setCollaborators([])
                          setCompany(selectedItem)
                          if (selectedItem?.pj?.cnpj) {
                            void setFieldValue(
                              'cnpjLoja',
                              selectedItem?.pj?.cnpj
                            )
                            void handleLoadIes(selectedItem?._id)
                          } else {
                            setIes([])
                            void setFieldValue('ie', null)
                          }
                        }}
                        getOptionDisabled={(option) =>
                          option.isBlocked || !option.isActive
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            label="Solicitador por"
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        disabled
                        fullWidth
                        id="standard-basic"
                        label="Referência"
                        value={values.papel}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        disabled
                        fullWidth
                        id="standard-basic"
                        label="Descrição tipo"
                        value={values.descrErpProduto}
                      />
                    </Grid>
                    {company?.pj?.cnpj && (
                      <Grid item xs={6}>
                        <Autocomplete
                          noOptionsText='Sem opções'
                          loading={isSubmitting}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          getOptionLabel={(option) => option?.codeLn}
                          id="combo-box-clients"
                          options={ies.map((row) => ({
                            ...row,
                            label: row.name,
                            id: row._id
                          }))}
                          onChange={(_, selectedItem: any | null) => {
                            void setFieldValue('ie', selectedItem?._id)
                            void setFieldValue(
                              'codCliente',
                              selectedItem?.codeLn
                            )
                          }}
                          getOptionDisabled={(option) =>
                            option.isBlocked || !option.isActive
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label="Parceiro de negócio"
                              required
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        disabled
                        fullWidth
                        id="standard-basic"
                        label="Código do Cliente"
                        value={values.codCliente}
                        helperText="Código único do cliente no Trimpaper"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        disabled
                        fullWidth
                        id="standard-basic"
                        label="Papel"
                        value={values.papel}
                        helperText="Código único do papel/Gramatura"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        variant="standard"
                        autoComplete='off'
                        fullWidth
                        id="standard-basic"
                        label="Largura"
                        value={values.largura}
                        onChange={(e) =>
                          setFieldValue(
                            'largura',
                            onlyNumbers(e.target.value ?? '')
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mm</InputAdornment>
                          )
                        }}
                        helperText="Largura da bobina ou folha (Em milímetros)"
                        required
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        variant="standard"
                        autoComplete='off'
                        fullWidth
                        id="standard-basic"
                        label="Diâmetro"
                        value={values.diamComp}
                        onChange={(e) =>
                          setFieldValue(
                            'diamComp',
                            onlyNumbers(e.target.value ?? '')
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mm</InputAdornment>
                          )
                        }}
                        helperText="Diâmetro da Bobina (Em milímetros)"
                        required
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        variant="standard"
                        autoComplete='off'
                        fullWidth
                        id="standard-basic"
                        label="Tubete"
                        value={values.tubFib}
                        onChange={(e) =>
                          setFieldValue(
                            'tubFib',
                            onlyNumbers(e.target.value ?? '')
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mm</InputAdornment>
                          )
                        }}
                        helperText="(Em milímetros)"
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        autoComplete='off'
                        fullWidth
                        id="standard-basic"
                        label="Quantidade"
                        value={values.quantPedida}
                        onChange={(e) =>
                          setFieldValue(
                            'quantPedida',
                            onlyNumbers(e.target.value ?? '')
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">T</InputAdornment>
                          )
                        }}
                        helperText="(Em toneladas)"
                        required
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptBR}
                      >
                        <DatePicker
                          views={['year', 'month', 'day']}
                          label="Data"
                          minDate={new Date()}
                          inputFormat="dd/MM/yyyy"
                          value={new Date(values.dataEntrega ?? '') ?? null}
                          onChange={(value) => {
                            setFieldValue('dataEntrega', value)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                startAdornment: (
                                  <Box paddingRight={2}>
                                    {params?.InputProps?.endAdornment}
                                  </Box>
                                )
                              }}
                              className="w-100"
                              variant="standard"
                              autoComplete="off"
                              required
                              helperText={
                                moment(values.dataEntrega).isBefore(new Date())
                                  ? 'Data inválida.'
                                  : ''
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        fullWidth
                        id="standard-basic"
                        label="Endereço"
                        value={values.endereco}
                        onChange={handleChange('endereco')}
                        // helperText="Código único do papel/Gramatura"
                        required
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        fullWidth
                        id="standard-basic"
                        label="Bairro"
                        value={values.bairro}
                        onChange={handleChange('bairro')}
                        // helperText="Código único do papel/Gramatura"
                        required
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        fullWidth
                        id="standard-basic"
                        label="Município"
                        value={values.municipio}
                        onChange={handleChange('municipio')}
                        // helperText="Código único do papel/Gramatura"
                        required
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions sx={{ padding: 3 }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseModal}
                    disabled={isSubmitting}
                  >
                    Cancelar
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Solicitar projeto
                  </LoadingButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  )
}
