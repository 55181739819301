/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Avatar,
  Button,
  CircularProgress,
  Stack,
  TablePagination,
  TextField,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import boxImg from '../../assets/img/formbox.png'
import { ChevronRight } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { httpClient } from 'config/httpClient'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { getClientsBySeller, getSeller, Seller } from 'services/ft/FTService'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import { ModalPreFTPortfolioBox } from './components/ModalPreFTPortfolioBox'

export function PreFTPortfolioBox() {
  const { loginData } = useSelector((state: RootState) => state.login)

  const [originalForms, setOriginalForms] = useState<any[]>([])
  const [forms, setForms] = useState<any[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any | null>(null)
  const [page, setPage] = useState(0)

  const [loadingItems, setLoadingItems] = useState(true)

  const [seller, setSeller] = useState<Seller | null>(null)
  const [clients, setClients] = useState<any[]>([])

  const fetchFormFt = useCallback(async () => {
    setLoadingItems(true)

    try {
      const { data } = await httpClient.get(
        '/products/formularios/caixa?isActive=true'
      )
      setForms(data ?? [])
      setOriginalForms(data ?? [])
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setLoadingItems(false)
    }
  }, [])

  useEffect(() => {
    void fetchFormFt()
  }, [])

  const handleFilterCoil = (searchValue: string) => {
    // console.log(forms)
    if (searchValue?.length) {
      const filteredWithSearch = originalForms.filter((item: any) =>
        item?.Referencia?.toLowerCase()?.includes(searchValue.toLowerCase())
      )

      setForms(filteredWithSearch)
    } else {
      setForms(originalForms)
    }
  }

  const fetchAll = useCallback(async () => {
    getClientsBySeller(loginData?.tokenData?.sub)
      .then((responseClients) => {
        setClients(responseClients?.data ?? [])
        if (!responseClients?.data?.length) {
          toast.warn('Nenhuma empresa disponível.')
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })

    getSeller(loginData?.tokenData?.sub)
      .then((response) => {
        // console.log(response?.data)
        setSeller(response?.data)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }, [])

  useEffect(() => {
    void fetchAll()

    return () => {
      setSeller(null)
    }
  }, [fetchAll])

  const handleClickOpenModal = (item: any) => {
    setSelectedItem(item)
    setOpenModal(true)
  }

  const handleClickOpenModalNoItem = () => {
    setSelectedItem(null)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedItem(null)
  }

  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'FT', url: '/home/ft' },
          { text: 'Portfólio', url: '/home/ft/portfolio' },
          { text: 'Caixa' }
        ]}
      />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginBottom: 3, marginTop: 2 }}
        >
          Caixa
        </Typography>

        <Button variant="contained" color="primary" onClick={handleClickOpenModalNoItem}>
          Criar projeto
        </Button>
      </Stack>
      <Stack>
        <TextField
          type="text"
          placeholder="Pesquisar por descrição"
          variant="outlined"
          fullWidth
          onChange={(e) => handleFilterCoil(e.target.value)}
        />
      </Stack>

      <Stack spacing={1} sx={{ paddingTop: 2 }}>
        {loadingItems && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: 150 }}
          >
            <CircularProgress size={24} />
          </Stack>
        )}

        {!loadingItems &&
          forms?.slice(page * 10, page * 10 + 10).map((form, i) => (
            <Button
              key={i}
              onClick={() => handleClickOpenModal(form)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                border: '1px solid #ccc'
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                  src={boxImg}
                  sx={{ width: 50, height: 50, borderRadius: 1 }}
                />
                <Stack alignItems="flex-start">
                  <Typography component="span" variant="body1" color="#003050">
                    {form?.Referencia}
                  </Typography>
                </Stack>
              </Stack>

              <ChevronRight />
            </Button>
          ))}

        {!loadingItems && (
          <TablePagination
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
            component="div"
            count={forms.length}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} de ${count}`
              // return '' + from + '-' + to + ' de ' + count
            }}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        )}

        <Stack direction="row" justifyContent="flex-end" sx={{ paddingTop: 4 }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/home/ft/portfolio"
          >
            Voltar
          </Button>
        </Stack>
      </Stack>

      {/* Formulário preFT */}
      {openModal && (
        <ModalPreFTPortfolioBox
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          seller={seller}
          item={selectedItem}
          clients={clients}
        />
      )}
    </>
  )
}
