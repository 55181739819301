/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { apiTrimbox, httpClient, httpLN } from 'config/httpClient'
import moment from 'moment'
import OrderType from 'types/deliveryMap/OrderType'

export const getDeliveryMap = async (
  type: string,
  page: number
): Promise<any> => {
  const { data } = await httpClient.get(
    `ft/param?type=${type}&offset=${page + 1}&limit=${5}`
  )
  return data
}

export const getOrderServicePaginated = async (
  page: number,
  date: string,
  search?: string
): Promise<any> => {
  const { data } = await httpLN.get(
    `order/orderServicePaginated?page=${page}&limit=20&date=${date}&search=${search}`
  )
  return data
}

export const getOrderServiceByItem = async (
  itemId: string,
  date: string
): Promise<OrderType[]> => {
  const dateFormated = moment(date).format('YYYY/MM/DD')
  const { data } = await httpLN.get(
    `order/orderServiceByItem?id=${itemId}&date=${dateFormated}`
  )
  return data.result as OrderType[]
}

export const getItemDeliveryMap = async (id: string): Promise<any> => {
  const { data } = await httpClient.get(`/itemDeliveryMap/${id}`)
  return data
}

const encrypt = (text: string) => {
  const encrypted: string = window.btoa(apiTrimbox + text)
  return encrypted.replaceAll('/', '---')
}

export const getPalletsOrderProduction = async (
  itemId: string,
  idOrdem: string
): Promise<any> => {
  const url = encrypt(
    `ft/palletsAmountByOrderProduction?cod=${itemId}&op=${idOrdem}`
  )
  const { data } = await httpClient.get(
    `/ft/accessExternalService/trimbox/${url}`
  )
  return data
}

export const getProductionFLow = async (itemId: string): Promise<any> => {
  const url = encrypt(`ft/orderProductionByFT?cod=${itemId}`)
  const { data } = await httpClient.get(
    `/ft/accessExternalService/trimbox/${url}`
  )
  return data
}

export const getSellerOrderByTypeAndDelayPaginated = async (
  page: number,
  date: string,
  codeParceiros: Array<{ id: string }>,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.post(
    `order/sellerOrderByTypeAndDelayPaginated?page=${page}&limit=20&date=${date}&withOp=${
     'no'
    }`,
    {
      clientsIds: codeParceiros
    }
  )
  return data
}

export const getSellerOrderByTypePaginated = async (
  page: number,
  date: string,
  codeParceiros: Array<{ id: string }>,
  search?: string,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.post(
    `order/sellerOrderByTypePaginated?page=${page}&limit=20&date=${date}&search=${
      search ?? ''
    }&withOp=${'no'}`,
    {
      clientsIds: codeParceiros
    },
    {
      timeout: 10 * 60 * 1000
    }
  )
  return data
}

export const getSellerOrderByTypePaginatedAll = async (
  page: number,
  date: string,
  codeParceiros: Array<{ id: string }>,
  search?: string,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.post(
    `order/sellerOrderByTypePaginated?page=${page}&limit=5000&date=${date}&search=${
      search ?? ''
    }&withOp=${'no'}`,
    {
      clientsIds: codeParceiros
    }
  )
  return data
}

export const getSellerOrderByIDPaginated = async (
  page: number,
  date: string,
  orderProductionIds: Array<{ id: string }>,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.post(
    `order/sellerOrderByIDPaginated?page=${page}&limit=20&date=${date}&withOp=${
      'no'
    }`,
    {
      ordersIds: orderProductionIds
    }
  )
  return data
}

export const getSellerOrderReposicaoPaginated = async (
  page: number,
  date: string,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.get(
    `order/sellerOrderReposicaoPaginated?page=${page}&limit=20&date=${date}&withOp=${
      'no'
    }`
  )
  return data
}

export const getOrderServiceByPlateAndDelayPaginated = async (
  page: number,
  date: string,
  search?: string,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.get(
    `order/orderServiceByPlateAndDelayPaginated?page=${page}&limit=20&date=${date}&search=${search}&withOp=${
      'no'
    }`
  )
  return data
}

export const getOrderServiceByCoilPaginated = async (
  page: number,
  date: string,
  search?: string,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.get(
    `order/orderServiceByCoilPaginated?page=${page}&limit=20&date=${date}&search=${search}&withOp=${
      'no'
    }`
  )
  return data
}

export const getOrderServiceByCoilAll = async (
  page: number,
  date: string,
  search?: string,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.get(
    `order/orderServiceByCoilPaginated?page=${page}&limit=5000&date=${date}&search=${search}&withOp=${
      'no'
    }`
  )
  return data
}

export const getOrderServiceByPlatePaginated = async (
  page: number,
  date: string,
  search?: string,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.get(
    `order/orderServiceByPlatePaginated?page=${page}&limit=20&date=${date}&search=${search}&withOp=${
      'no'
    }`
  )
  return data
}

export const getOrderServiceByPlateAll = async (
  page: number,
  date: string,
  search?: string,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.get(
    `order/orderServiceByPlatePaginated?page=${page}&limit=5000&date=${date}&search=${search}&withOp=${
      'no'
    }`
  )
  return data
}

export const getSellerOrderOutOfStatePaginated = async (
  page: number,
  date: string,
  search?: string,
  withOp?: 'yes' | 'no'
): Promise<any> => {
  const { data } = await httpLN.get(
    `order/sellerOrderOutOfStatePaginated?page=${page}&limit=20&date=${date}&search=${search}&withOp=${
      'no'
    }`
  )
  return data
}

export const getItemsDeliveryByReposition = async (): Promise<any> => {
  const { data } = await httpClient.get('itemDeliveryMap/all/reposition')
  return data
}

export const getCodeParceirosByClientType = async (
  type: 'KANBAN' | 'CARTEIRA'
): Promise<any> => {
  const { data } = await httpClient.get(`/company/${type}/typeCompany`)
  return data
}

export const getCodeParceirosByPilotLot = async (): Promise<any> => {
  const { data } = await httpClient.get('/company/all/pilotLot')
  return data
}
