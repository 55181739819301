import React from 'react'
import { ViewOption } from 'types/alerts/AlertsType'

interface Props {
  status: string
  size?: 'small' | 'medium' | 'large'
  style?: any
  disabled?: boolean
  options: ViewOption[]
}

const StatusViewGeneric: React.FC<Props> = (props) => {
  const option = props.options.find(o => o.status === props.status) ?? { status: '', color: '#5f7aea', bgColor: '#edf0ff' }

  if (props.disabled) {
    option.color = '#989898'
    option.bgColor = '#eaeaea'
  }

  if (props.size === 'small') {
    return (
      <div style={props.style}>
        <div style={{
          borderRadius: '3px',
          color: option.color,
          padding: '2px 6px',
          border: '1px solid',
          textAlign: 'center',
          backgroundColor: option.bgColor,
          fontSize: '11px'
        }}>
          {props.status}
        </div>
      </div>

    )
  }

  return (
    <div style={props.style}>
      <div style={{
        borderRadius: '3px',
        color: option.color,
        padding: '4px 8px',
        border: '1px solid',
        textAlign: 'center',
        backgroundColor: option.bgColor
      }}>
        {props.status}
      </div>
    </div>

  )
}

export default StatusViewGeneric
