/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useState } from 'react'
import { Box } from '@mui/system'
import {
  Grid,
  Typography,
  Icon,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material'
import { useFormik } from 'formik'
import ImportantView from 'components/view/ImportantView'
import { toast } from 'react-toastify'
import { httpClient } from 'config/httpClient'
import Button from 'components/form/Button'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'

interface Props {
  data?: any
  cod: string | number
  onChange: (values: any) => void
  children: ReactNode
  bookFinished?: boolean
}

const SolicitationToolItem: React.FC<Props> = ({
  data,
  cod,
  onChange,
  bookFinished,
  children
}) => {
  const [currentFile, setCurrentFile] = useState<File>()
  const [loading, setLoading] = useState(false)
  console.log('SolicitationToolItem => data', data)

  const handleImagePicker = (file: any) => {
    if (!file.target?.files[0]) return

    if (file.target?.files[0]?.size > 4100000) {
      toast.warning('O arquivo não pode ser maior que 4mb.')
      return
    }

    setCurrentFile(file.target?.files[0])

    const reader = new FileReader()
    reader.readAsDataURL(file.target.files[0])
    reader.onload = function () {
      const image = new Image()
      image.src = reader.result?.toString() ?? ''

      // Aqui temos a sua imagem convertida em string em base64.
      const result: any = reader.result
      console.log('ARQUIVO', result)
      setFieldValue('file', result)
    }
  }

  const { errors, touched, values, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        _id: data?._id,
        pedido: data?.pedido ?? '',
        ft: data?.ft,
        codItem: data?.codItem ?? '',
        url: data?.url ?? '',
        comment: data?.comment ?? '',
        specification: data?.specification ?? '',
        file: ''
      },
      // validationSchema: validationBudgetToolSchema,
      onSubmit: async (values) => {
        const body = values

        if (!values.file && !values.url) {
          toast.warning('Selecione um arquivo.')
          return
        }

        setLoading(true)
        console.log(body)
        try {
          const response = await httpClient.post(
            'bookings/solicitation-tool',
            body
          )
          console.log('CREATED FERRAMENTAL', response.data)
          setLoading(false)
          onChange(response.data)
          toast.success('Salvo com sucesso!')
        } catch (error: any) {
          toast.error(error?.response?.data?.message)
          setLoading(false)
        }
      }
    })

  return (
    <form onSubmit={handleSubmit}>
      <hr />
      {!bookFinished && <Typography
        component="div"
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Box
          component="label"
          htmlFor={`file-upload-${cod}`}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px 24px',
            border: '1px dashed #0075E7',
            borderRadius: 5,
            color: '#5E5D5D',
            marginTop: 2,
            minHeight: 150,
            width: '100%',
            maxWidth: '600px !important'
          }}
        >
          <FileUploadOutlinedIcon fontSize="large" />
          <Typography component="span" variant="body2">
            Clique para selecionar um arquivo.
          </Typography>
          <Typography
            component="span"
            variant="body2"
            sx={{
              padding: '4px 8px',
              borderRadius: 1,
              color: '#FFFFFF',
              backgroundColor: '#0075E7'
            }}
          >
            Selecione o arquivo
          </Typography>
          <input
            id={`file-upload-${cod}`}
            type="file"
            accept="image/png, image/jpeg, application/pdf, application/dwg"
            hidden
            onChange={handleImagePicker}
          />
        </Box>
        <Box>
          <strong>{currentFile?.name}</strong>
        </Box>
        <ImportantView
          style={{ marginTop: '16px', maxWidth: '550px' }}
          title="Importante"
          content="Tamanho máximo de 4 mb."
        />
        {/* {((currentFile && !currentFile?.type?.includes('pdf')) ??
          (values?.url && !values?.url?.includes('.pdf'))) && (
          <img
            style={{
              border: '2px solid #6868684A',
              borderRadius: '6px',
              width: '40%',
              height: 'fit-content'
            }}
            src={currentFile ? URL.createObjectURL(currentFile) : values?.url}
          ></img>
        )} */}
      </Typography>}
      {data?.url && (
        <Box
          component="a"
          href={data?.url}
          target="_blank"
          color="primary"
          sx={{
            marginTop: '16px',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <OpenInNewOutlinedIcon fontSize="small" />
          <span>Ver arquivo</span>
        </Box>
      )}

      <Box style={{ marginTop: '16px' }}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Especificação
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={values.specification}
            name="radio-buttons-group"
            onChange={handleChange('specification')}
          >
            <FormControlLabel value="faca" control={<Radio />} label="Faca" />
            <FormControlLabel
              value="cliche"
              control={<Radio />}
              label="Clichê"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box style={{ marginTop: '16px' }}>
        {/* <Typography> */}
        <TextField
          error={Boolean(errors.comment && touched.comment)}
          label="Comentário"
          multiline
          rows={4}
          fullWidth
          value={values.comment}
          onChange={handleChange('comment')}
          helperText={
            (errors.comment && touched.comment
              ? errors.comment
              : null) as React.ReactNode
          }
        />
        {/* </Typography> */}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        marginTop={2}
      >
        {children}

        {!bookFinished && <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            flex: 1,
            marginLeft: 16
          }}
        >
          <Button
            type="submit"
            mold="green"
            loading={loading}
            disabled={loading}
          >
            Salvar
          </Button>
        </div>}
      </Box>
    </form>
  )
}

export { SolicitationToolItem }
