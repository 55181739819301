import { httpClient } from 'config/httpClient'
import PageableType from 'types/PageableType'
import UserType from 'types/user/UserType'

export const getSearchSeller = async (param: string, offset = 1): Promise<PageableType<UserType>> => {
  const { data } = await httpClient.get(`/sellers/search?query=${param}&offset=${offset}&limit=${10}`)
  return data as PageableType<UserType>
}

export const getSellers = async (): Promise<any> => {
  const { data } = await httpClient.get('/sellers/all')
  return data as any[]
}
