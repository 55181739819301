import { httpLN } from 'config/httpClient'

export const getStockLN = async (
  dataInicio: string,
  dataFim: string
) => {
  const { data } = await httpLN.get<any>(
    `/estoque/pordata?dataInicio=${dataInicio}&dataFim=${dataFim}`
  )
  return data
}

export const getStockAtualLN = async (dataAtual: string) => {
  const { data } = await httpLN.get<any>(
    `/estoque/atual?data=${dataAtual}`
  )
  return data
}

export const getStockMovimentacaoSaidaLN = async (
  dataInicio: string,
  dataFim: string
) => {
  const { data } = await httpLN.get<any>(
    `/estoque/movimentacao/saida?dataInicio=${dataInicio}&dataFim=${dataFim}`
  )
  return data
}

export const getStockMovimentacaoEntradaLN = async (
  dataInicio: string,
  dataFim: string
) => {
  const { data } = await httpLN.get<any>(
    `/estoque/movimentacao/entrada?dataInicio=${dataInicio}&dataFim=${dataFim}`
  )
  return data
}
