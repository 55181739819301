import { TableHead, TableRow } from '@mui/material'
import React from 'react'
import { Td } from './table.styles'

export interface THeadCell {
  title: string
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit'
}

interface Props {
  cells: THeadCell[]
}

export const TableHeadPurchaseOrder: React.FC<Props> = ({ cells }) => {
  return (
    <TableHead>
      <TableRow>
        {cells.map((item) => {
          return (
            <Td key={item.title} align={item.align}>
              {item.title}
            </Td>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
