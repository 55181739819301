/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import CustomButton from 'components/buttons/CustomButton'

interface Props {
  open: boolean
  data: any[]
  close: () => void
  refetch?: () => void
}

const TablePolicy: React.FC<Props> = (props) => {
  const closeModal = () => props.close()
  const [dataValues, setDataValues] = useState<any[]>([])
  const [ResultsPre] = useState<any>(props.data['DV-Results']?.Applicant[0]?.Result[0]?.ResultsPre[0]?.['Policy Rules-Component Tree Results'])

  useEffect(() => {
    const loadedData = [ResultsPre].every(loading => loading)
    if (loadedData) {
      formatData(ResultsPre)
    }
  }, [])

  const formatData = (body) => {
    const formated = [{
      component_id: body['Component ID'] ?? '',
      component_name: body['Component Name'] ?? '',
      component_tree_id: body['Component Tree ID'] ?? '',
      component_tree_name: body['Component Tree Name'] ?? '',
      leaf_node_id: body['Leaf Node ID'] ?? '',
      leaf_node_name: body['Leaf Node Name'] ?? '',
      outcome_id: body['Outcome ID'] ?? '',
      outcome_name: body['Outcome Name'] ?? ''
    }]
    setDataValues(formated)
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={closeModal}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                  <StyledTableCell align="left">ID</StyledTableCell>
                    <StyledTableCell align="left">Nome</StyledTableCell>
                    <StyledTableCell align="left">Tree Id</StyledTableCell>
                    <StyledTableCell align="left">Tree name</StyledTableCell>
                    <StyledTableCell align="left">Node id</StyledTableCell>
                    <StyledTableCell align="left">Tipo</StyledTableCell>
                    <StyledTableCell align="left">Out come id</StyledTableCell>
                    <StyledTableCell align="left">Out come name</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataValues.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{row.component_id}</StyledTableCell>
                    <StyledTableCell align="left">{row.component_name}</StyledTableCell>
                    <StyledTableCell align="left">{row.component_tree_id}</StyledTableCell>
                    <StyledTableCell align="left">{row.component_tree_name}</StyledTableCell>
                    <StyledTableCell align="left">{row.leaf_node_id}</StyledTableCell>
                    <StyledTableCell align="left">{row.leaf_node_name}</StyledTableCell>
                    <StyledTableCell align="left">{row.outcome_id}</StyledTableCell>
                    <StyledTableCell align="left">{row.outcome_name}</StyledTableCell>
                  </StyledTableRow>
                ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <CustomButton
            mold="primary"
            type="button"
            onClick={closeModal}
            autoFocus
          >
            Voltar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TablePolicy
