import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import { useEffect, useState } from 'react'

interface Props {
  defaultValue?: string
  onChange?: (value: string) => void
}

export function CheckboxDimension({ defaultValue, onChange }: Props) {
  const [valueOne, setValueOne] = useState(() => {
    if (String(defaultValue)?.includes('1950')) return true
    return false
  })
  const [valueTwo, setValueTwo] = useState(() => {
    if (String(defaultValue)?.includes('2050')) return true
    return false
  })
  const [valueThree, setValueThree] = useState(() => {
    if (String(defaultValue)?.includes('2190')) return true
    return false
  })

  useEffect(() => {
    let value = ''

    function setValue(addValue: string) {
      if (value.length > 0) value += `,${addValue}`
      if (!value.length) value = addValue
    }

    if (valueOne) setValue('1950')
    if (valueTwo) setValue('2050')
    if (valueThree) setValue('2190')

    onChange?.(value)
  }, [valueOne, valueTwo, valueThree])

  return (
    <>
      <FormControl sx={{ m: 0 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Dimensões</FormLabel>
        <FormGroup style={{ flexDirection: 'row' }}>
          <FormControlLabel
            control={
              <Checkbox checked={valueOne} onChange={(_, checked) => setValueOne(checked)} name="1950" />
            }
            label="1950"
          />
          <FormControlLabel
            control={
              <Checkbox checked={valueTwo} onChange={(_, checked) => setValueTwo(checked)} name="2050" />
            }
            label="2050"
          />
          <FormControlLabel
            control={
              <Checkbox checked={valueThree} onChange={(_, checked) => setValueThree(checked)} name="2190" />
            }
            label="2190"
          />
        </FormGroup>
      </FormControl>
    </>
  )
}
