import React, { useEffect, useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow
} from '@mui/material'
import RequestForProposalsType from 'types/Report/RequestForProposalsType'
import { count } from 'helpers/arrayHelps'
import MoreMenu from 'components/view/MoreMenu'
import ViewProposal from './ViewProposal/index'
import {
  StyledTableCell,
  StyledTableRow
} from 'pages/reportControl/proposalForDirection/components/table.styles'
import moment from 'moment'
import { maskCpfOrCnpj } from 'helpers/maskHelper'

interface Props {
  data: RequestForProposalsType[]
  date: any
  searchFT?: string
  proposalStatus: string
}

const headers = [
  {
    text: 'Código FT'
  },
  {
    text: 'Emitido em'
  },
  {
    text: 'CNPJ/CPF Rural'
  },
  {
    text: 'Nome da empresa'
  },
  {
    text: 'Vendedor'
  },
  {
    text: 'Amostra'
  },
  {
    text: 'Ações'
  }
]

const RequestForProposalsTable: React.FC<Props> = ({
  data,
  date,
  searchFT,
  proposalStatus
}) => {
  const [page, setPage] = useState<number>(0)
  const [openView, setOpenView] = useState<boolean>(false)
  const [selectedProposal, setSelectedProposal] =
    useState<RequestForProposalsType | null>(null)
  const [items, setItems] = useState<RequestForProposalsType[]>([])

  useEffect(() => {
    if (proposalStatus === 'all') {
      setItems(data)
    } else {
      setItems(
        data?.filter((item) => {
          const budgets = item?.orcamento?.filter(
            (budget) =>
              !!budget?.propostas?.find((proposta) => {
                const history = proposta?.statusHistory?.find((history) =>
                  history?.status
                    ?.toUpperCase()
                    ?.includes(proposalStatus.toUpperCase())
                )
                return (
                  history ??
                  !!proposta?.status
                    ?.toUpperCase()
                    ?.includes(proposalStatus.toUpperCase())
                )
              })
          )

          console.log(budgets)

          return budgets?.length
        })
      )
    }
  }, [data, proposalStatus])

  const filteredItems = date
    ? items?.filter((item) => {
        const budgets = item?.orcamento?.filter(
          (budget) =>
            !!budget?.propostas?.find((proposta) => {
              // const history = proposta?.statusHistory?.find((history) =>
              //  moment(history.date).get('month') === moment(date).get('month')
              // )
              return (
                // history ??
                moment(proposta.createdAt).get('month') ===
                moment(date).get('month')
              )
            })
        )

        if (searchFT) {
          return budgets?.length || item?.itemReference?.toUpperCase()?.includes(searchFT?.toUpperCase() ?? '')
        }

        return budgets?.length
      })
    : items?.filter((item) => item?.itemReference?.toUpperCase()?.includes(searchFT?.toUpperCase() ?? ''))

  const toView = (proposal: RequestForProposalsType): void => {
    setOpenView(true)
    setSelectedProposal(proposal)
  }

  return (
    <>
      <ViewProposal
        proposal={selectedProposal}
        open={openView}
        setOpen={setOpenView}
      />
      <Paper>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <StyledTableCell key={`header_cashier_${index}`}>
                    {header.text}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems
                .slice(page * 10, page * 10 + 10)
                .map((item, index) => {
                  const client = item.preft.at(0)?.cliente.at(0)
                  const seller = item.preft.at(0)?.vendedor.at(0)?.user.at(0)

                  return (
                    <StyledTableRow key={`table_cashier_row_${index}`}>
                      <StyledTableCell align="left">
                        {item.itemReference}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(item.createdAt).format('DD/MM/YYYY')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {maskCpfOrCnpj(
                          client?.type === 'PF'
                            ? client?.pf.cpf
                            : client?.pj.cnpj
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {client?.name ?? 'nome não identificado'}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {seller?.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.existsAmostra ? 'Sim' : 'Não'}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <MoreMenu view={() => toView(item)} />
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          component="div"
          rowsPerPage={10}
          count={count(filteredItems)}
          rowsPerPageOptions={[10]}
          onPageChange={(_, newPage: number) => setPage(newPage)}
        />
      </Paper>
    </>
  )
}

export default RequestForProposalsTable
