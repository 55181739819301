import { httpClient } from 'config/httpClient'
import React, { useEffect } from 'react'

function ViewContent(props: any) {
  const [allContent, setAllContent] = React.useState({ text: '', _id: '', url: '' })

  const [image, setImage] = React.useState<string | Blob>()

  useEffect(() => {
    loadContent()
  }, [])

  const loadContent = async () => {
    try {
      const responseAllContent = await httpClient.get('/quemsomos')
      console.log('RESPONSE ALL', responseAllContent.data[0])
      setAllContent(responseAllContent.data[0])
      setImage(responseAllContent.data[0].url)
    } catch (e) {
      console.log('ERRO ALL', e)
    }
  }

  return (
    <>
      <div>Conteúdo:</div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ padding: '10px', width: '70%', fontSize: '14px', border: '1px solid', borderColor: 'black', borderRadius: '6px', margin: '15px' }}>
          {allContent.text}
        </div>
      </div>

      {image && <img
        style={{
          border: '2px solid #6868684A',
          borderRadius: '6px',
          width: '80%',
          height: 'fit-content'
        }}
        src={String(image)}
      ></img>}
    </>

  )
}

export default ViewContent
