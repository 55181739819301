import React from 'react'
import { Chip, Grid, Typography } from '@mui/material'

function ChipFT(props: any) {
  const responsiveStyle = {
    margin: 'auto',
    '@media (max-width: 1300px)': { width: '200px' },
    '@media (min-width: 1400px)': { width: '250px' }
  }
  const chipStyle = {
    ':hover': { backgroundColor: 'rgba(64, 96, 230, 0.5)' }
  }
  const textStyle = {
    textAlign: 'center',
    color: 'blue',
    fontWeight: 'bold'
  }

  return (
    <>
      <Typography sx={textStyle}>{props?.pedidos?.length}</Typography>
      <Grid container rowSpacing={2} sx={responsiveStyle} alignItems="center" justifyContent="center">
        {props?.pedidos?.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} key={index}>
            <Chip
              label={`FT ${item?.codItem ?? item?.ft?.itemReference}`}
              variant='outlined'
              sx={chipStyle}
              key={`${item?.codItem ?? item?.ft?.itemReference}`}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default ChipFT
