/* eslint-disable no-unneeded-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import CustomButton from 'components/buttons/CustomButton'
import { useFormik } from 'formik'
import { httpClient } from 'config/httpClient'
import { toast } from 'react-toastify'
import customerReviewType from 'types/managementControl/customerReview/customerReviewType'

interface Props {
  open: boolean
  close: () => void
  data: customerReviewType
  refetch: () => void
}

const ModalcustomerReview: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const handleClose = () => props.close()
  const handleRefetch = () => props.refetch()

  const { errors, touched, values, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        _id: props.data._id ?? '',
        comment: props.data.comment ?? '',
        solution: props.data.solution ?? '',
        isActive: props.data.isActive
      },
      onSubmit(values) {
        setLoading(true)
        console.log('values: ', values)
        httpClient.put(`/evaluations/${values?._id}`, values).then(() => {
          toast.success('Solução registrada com sucesso')
          handleClose()
          handleRefetch()
        }).catch((error: any) => {
          console.log('Error: ', error)
          toast.error(error?.response?.data?.message ?? 'Erro ao registrar solução')
        }).finally(() => {
          setLoading(false)
        })
      }
    })

  const handleChangeActive = (field: any, valorChecked: any) => (event: any) => {
    setFieldValue(field, valorChecked ? true : false)
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit}>
          <Box component="form">
            <DialogTitle id="alert-dialog-title">
              <Box display="flex" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  sx={{ minWidth: 'auto', padding: 1, marginRight: 2 }}
                >
                  <ArrowBack />
                </Button>

                <span>Editar avaliação do cliente</span>
              </Box>
            </DialogTitle>
          </Box>
          <DialogContent>
            <Typography fontWeight="bold">Avaliação do cliente</Typography>
            <Box marginTop={3}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(errors.comment && touched.comment)}
                    value={values.comment}
                    disabled
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    multiline
                    minRows={3}
                    helperText={
                      errors.comment && touched.comment ? errors.comment : null
                    }
                    onChange={handleChange('comment')}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight="bold">Registrar possível solução para o problema descrito</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(errors.solution && touched.solution)}
                    value={values.solution}
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    label="Solução"
                    multiline
                    minRows={3}
                    helperText={
                      errors.solution && touched.solution ? errors.solution : null
                    }
                    onChange={handleChange('solution')}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight="bold">Status</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={values.isActive} onChange={handleChangeActive('isActive', true)} />} label="Ativo" />
                  </FormGroup>
                </Grid>
                <Grid item xs={10}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={!values.isActive} onChange={handleChangeActive('isActive', false)} />} label="Inativo" />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Grid item md={12} className="text-right">
              <CustomButton
                mold="red"
                type="button"
                onClick={handleClose}
                style={{ marginRight: '10px' }}
                disabled={loading}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                mold="primary"
                type="submit"
                loading={loading}
                disabled={loading}
              >
                Salvar
              </CustomButton>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default ModalcustomerReview
