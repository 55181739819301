import { httpClient, httpLN } from '../../config/httpClient'

interface Response {
  docs: any[]
  limit: number
  total: number
  result?: any[]
}

export const getPedidos = async ({
  page = 1,
  limit = 10,
  pilotLot = '',
  typeClient,
  search = ''
}: {
  page: number
  limit?: number
  pilotLot?: string
  typeClient?: 'KANBAN' | 'CARTEIRA'
  search?: string
}) => {
  const { data } = await httpClient.get<Response>(
    `/pedidos?page=${page}&limit=${limit}&pilotLot=${pilotLot}&typeClient=${typeClient}&search=${search}`
  )
  return data
}

export const getPedidosBobina = async ({
  page = 1,
  limit = 10,
  search = ''
}: {
  page: number
  limit?: number
  search?: string
}) => {
  const { data } = await httpClient.get<Response>(
    `/pedidos/bobina?page=${page}&limit=${limit}&search=${search}`
  )
  return data
}

export const getPedidosChapa = async ({
  page = 1,
  limit = 10,
  search = ''
}: {
  page: number
  limit?: number
  search?: string
}) => {
  const { data } = await httpClient.get<Response>(
    `/pedidos/chapa?page=${page}&limit=${limit}&search=${search}`
  )
  return data
}

export const getPedidosCaixa = async ({
  page = 1,
  limit = 10,
  search = ''
}: {
  page: number
  limit?: number
  search?: string
}) => {
  const { data } = await httpClient.get<Response>(
    `/pedidos/caixa?page=${page}&limit=${limit}&search=${search}`
  )
  return data
}

export const getTodosPedidos = async ({
  page = 1,
  limit = 10,
  search = ''
}: {
  page: number
  limit?: number
  search?: string
}) => {
  const { data } = await httpClient.get<Response>(
    `/pedidos/list-all?page=${page}&limit=${limit}&search=${search}`
  )
  return data
}

export const getOrderSales = async ({
  page = 0,
  limit = 20,
  date = ''
}: {
  page: number
  limit?: number
  date: string
}) => {
  const { data } = await httpLN.get<any>(
    `order/orderServicePaginated?page=${page}&limit=${limit}&date=${date}`
  )
  return data
}

export const getStatusOVXml = async (ov: string) => {
  const { data } = await httpLN.get<any>(
    `order/statusOVXml?codOV=${ov}`
  )
  return data
}

export const getNewOrderSales = async () => {
  const { data } = await httpClient.get<any>('order')
  console.log('getNewOrderSales ', data)
  return data
}

export const getSuspendedOrders = async ({
  page = 1,
  limit = 10,
  search = ''
}: {
  page: number
  limit?: number
  search?: string
}) => {
  const { data } = await httpClient.get<Response>(
    `pedidos/suspensos?page=${page}&limit=${limit}&search=${search}`
  )

  return data
}

export const postUpdateStatusOV = async ({
  _id,
  status,
  displayId
}: {
  _id: string
  status: string
  displayId: string
}) => {
  const { data } = await httpClient.post<any>('order/update-status-order', {
    _id,
    status,
    displayId
  })
  return data
}
