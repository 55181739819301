/* eslint-disable operator-linebreak */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import CustomButton from 'components/buttons/CustomButton'

interface Props {
  open: boolean
  data: any[]
  close: () => void
  refetch?: () => void
}

const TableConsultationRecord: React.FC<Props> = (props) => {
  const closeModal = () => props.close()

  const [Serasa] = useState<any>(props.data['DV-Relato']?.RelatoB49C[0]?.RegistroConsultas?.Serasa)

  const [dataValues, setDataValues] = useState<any[]>([])

  useEffect(() => {
    const loadedData = [Serasa].every(loading => loading)
    if (loadedData) {
      dateFormat(Serasa)
    }
  }, [])

  const dateFormat = (data) => {
    const objFormated = [{
      M0Consultas: data.hasOwnProperty('M0Consultas') ? data.M0Consultas : 0,
      M1Consultas: data.hasOwnProperty('M1Consultas') ? data.M1Consultas : 0,
      M2Consultas: data.hasOwnProperty('M2Consultas') ? data.M2Consultas : 0,
      M3Consultas: data.hasOwnProperty('M3Consultas') ? data.M3Consultas : 0,
      M4Consultas: data.hasOwnProperty('M4Consultas') ? data.M4Consultas : 0,
      M5Consultas: data.hasOwnProperty('M5Consultas') ? data.M5Consultas : 0,
      M6Consultas: data.hasOwnProperty('M6Consultas') ? data.M6Consultas : 0,
      M7Consultas: data.hasOwnProperty('M7Consultas') ? data.M7Consultas : 0,
      M8Consultas: data.hasOwnProperty('M8Consultas') ? data.M8Consultas : 0,
      M9Consultas: data.hasOwnProperty('M9Consultas') ? data.M9Consultas : 0,
      M10Consultas: data.hasOwnProperty('M10Consultas') ? data.M10Consultas : 0,
      M11Consultas: data.hasOwnProperty('M11Consultas') ? data.M11Consultas : 0,
      M12Consultas: data.hasOwnProperty('M12Consultas') ? data.M12Consultas : 0,
      M13Consultas: data.hasOwnProperty('M13Consultas') ? data.M13Consultas : 0,
      TotalConsultas: data.hasOwnProperty('TotalConsultas') ? data.TotalConsultas : 0
    }]

    setDataValues(objFormated)
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={closeModal}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">M0EConsultas</StyledTableCell>
                    <StyledTableCell align="left">M1Consultas</StyledTableCell>
                    <StyledTableCell align="left">M2Consultas</StyledTableCell>
                    <StyledTableCell align="left">M3Consultas</StyledTableCell>
                    <StyledTableCell align="left">M4Consultas</StyledTableCell>
                    <StyledTableCell align="left">M5Consultas</StyledTableCell>
                    <StyledTableCell align="left">M6Consultas</StyledTableCell>
                    <StyledTableCell align="left">M7Consultas</StyledTableCell>
                    <StyledTableCell align="left">M8Consultas</StyledTableCell>
                    <StyledTableCell align="left">M9Consultas</StyledTableCell>
                    <StyledTableCell align="left">M10Consultas</StyledTableCell>
                    <StyledTableCell align="left">M11Consultas</StyledTableCell>
                    <StyledTableCell align="left">M12Consultas</StyledTableCell>
                    <StyledTableCell align="left">M12Consultas</StyledTableCell>
                    <StyledTableCell align="left">Quantidade Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Serasa === undefined
                    ?
                    <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <Box
                          sx={{
                            display: 'flex',
                            height: 200,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Typography component="span" variant="body2">
                            Nenhum Registro Encontrado
                          </Typography>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                    :
                    dataValues?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row?.M0Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M1Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M2Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M3Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M4Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M5Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M6Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M7Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M8Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M9Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M10Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M11Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M12Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M13Consultas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.TotalConsultas}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <CustomButton
            mold="primary"
            type="button"
            onClick={closeModal}
            autoFocus
          >
            Voltar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TableConsultationRecord
