import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress, Table } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import MoreMenu from 'components/view/MoreMenu'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { deleteRH } from 'services/report/ReportRH'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import moment from 'moment'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const RHTable = (props: any) => {
  const [rows, setRows] = React.useState<any[]>([])

  useEffect(() => {
    parseTableData(props.data)
  }, [props.data])

  const parseTableData = (rhData) => {
    const data: any[] = []

    rhData.forEach(rh => {
      const worksDays = rh.worksDays?.filter((item) => {
        const date = item.split('/')
        const currentDate = `${date[2]}-${date[1]}-${date[0]}`
        return moment(currentDate).isBefore(moment(props.date))
      })

      data.push({
        _id: rh._id,
        product: rh.production.name,
        days_plan: (rh.days).length,
        days_work: (worksDays).length,
        turn: rh.shift,
        days: rh.days,
        worksDays: rh.worksDays,
        productId: rh.production._id
      })
    })
    setRows(data)
  }

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(deleteRH, {
    onSuccess: data => {
      console.log(data)
      props.reload()
      toast.success('Deletado com sucesso')
    },
    onError: (e) => {
      toast.error('Não foi possível deletar')
    }
  })

  if (isLoadingDelete) {
    return (
      <Box sx={{ display: 'flex' }}
        style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <TableContainer style={{ marginTop: '30px', maxHeight: 440 }} component={Paper}>
        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'>Produto</StyledTableCell>
              <StyledTableCell align='center'>Dias planejados</StyledTableCell>
              <StyledTableCell align='center'>Dias trabalhados</StyledTableCell>
              <StyledTableCell align='center'>Turnos</StyledTableCell>
              <Can
                module={MODULES.GERENCIAL}
                permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
              >
                <StyledTableCell align='right'>Ações</StyledTableCell>
              </Can>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell align='left'>
                  {row.product}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {row.days_plan}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {row.days_work}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {row.turn}
                </StyledTableCell>
                <Can
                  module={MODULES.GERENCIAL}
                  permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                >
                <StyledTableCell align='right'>
                  <MoreMenu
                    module={MODULES.GERENCIAL}
                    isValidationPermissions
                    delete={() => { mutateDelete(row._id) }}
                    edit={() => { props.dataEdit(row); props.edit() }} />
                </StyledTableCell>
                </Can>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default RHTable
