import { Alert, AlertTitle, Box, CircularProgress, Icon } from '@mui/material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getBudgetRealizedMonthAndYear } from 'services/budgetRealized/BudgetRealizedService'
import { constTiposCategorias } from 'constants/constTiposCategorias'
export interface CheckDateProps {
  date: string
}

function checkDate(props: CheckDateProps): React.ReactElement<any, any> | null {
  const [chaves, setChaves] = useState<string[]>([])
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()
  const propDate = new Date(props.date)
  const propMonth = propDate.getMonth() + 1
  const propYear = propDate.getFullYear()
  console.log('currentMonth === propMonth && currentYear === propYear', currentMonth, propMonth, currentYear, propYear)

  const req1 = useQuery(['realized-search', propMonth, propYear],
    async () => {
      return getBudgetRealizedMonthAndYear(propMonth, propYear)
    },
    {
      onSuccess: (data) => {
        console.log('getBudgetRealizedMonthAndYear', data)
        const lista = Object.keys(data?.data)
        setChaves(lista)
      },
      refetchOnWindowFocus: false
    }
  )
  const renderAlert = (caseTipo, listCategoria) => {
    console.log('caseTipo', caseTipo)
    console.log('listCategoria', listCategoria)
    switch (caseTipo) {
      case 1:
        return (
          <Alert severity="warning" style={{ border: '3px solid rgb(229, 246, 253)' }}>
            <AlertTitle>ADMINISTRATIVO</AlertTitle>
            {listCategoria.map((category, index) => (
              <div key={index}>{category}</div>
            ))}
          </Alert>
        )
      case 2:
        return (
          <Alert severity="warning" style={{ border: '3px solid rgb(229, 246, 253)' }}>
            <AlertTitle>MATERIAL DE PO</AlertTitle>
            {listCategoria.map((category, index) => (
              <div key={index}>{category}</div>
            ))}
          </Alert>
        )
      case 3:
        return (
          <Alert severity="warning" style={{ border: '3px solid rgb(229, 246, 253)' }}>
            <AlertTitle>MATERIAIS DE PAPEL</AlertTitle>
            {listCategoria.map((category, index) => (
              <div key={index}>{category}</div>
            ))}
          </Alert>
        )
      case 4:
        return (
          <Alert severity="warning" style={{ border: '3px solid rgb(229, 246, 253)' }}>
            <AlertTitle>INSUMO DE PAPEL</AlertTitle>
            {listCategoria.map((category, index) => (
              <div key={index}>{category}</div>
            ))}
          </Alert>
        )
      default:
        return null
    }
  }
  const getMonthName = (monthNumber: number): string => {
    const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ]

    if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1]
    } else {
      throw new Error('Mês inválido. Erro na solicitação.')
    }
  }

  if ((currentYear === propYear) && (currentMonth === propMonth)) {
    return (
      <div style={{
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        backgroundColor: 'rgb(229, 246, 253)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        borderRadius: '4px'
        }}
      >
        <div style={{
          fontSize: '18px',
          textAlign: 'center',
          fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
          fontWeight: '400',
          color: 'rgb(1, 67, 97)'
          }}
        >
          <Alert severity="info" style={{ backgroundColor: 'rgb(229, 246, 253)', color: 'rgb(1, 67, 97)' }}>
            <AlertTitle>O mês atual ainda não terminou para visualizar os relatórios.</AlertTitle>
          </Alert>
        </div>
      </div>
    )
  } else {
    return (
      <>
      { (req1.isLoading || req1.isFetching) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      ) }
      { (req1.isError) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>
            signal_wifi_statusbar_connected_no_internet_4
          </Icon>
        </Box>
      ) }
      { (req1.isSuccess && !req1.isError && !req1.isFetching && req1 && req1.data && req1.data?.data) && (
        <>
        {
        ((Object.keys(req1.data?.data).length === 0) && (
          <div style={{
            margin: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            backgroundColor: 'rgb(229, 246, 253)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            borderRadius: '4px'
            }}
          >
            <div style={{
              fontSize: '18px',
              textAlign: 'center',
              fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
              fontWeight: '400',
              color: 'rgb(1, 67, 97)'
              }}
            >
              <Alert severity="info" style={{ backgroundColor: 'rgb(229, 246, 253)', color: 'rgb(1, 67, 97)' }}>
                <AlertTitle>{getMonthName(propMonth)} de {propYear} não tem tipos de documentos cadastrados.</AlertTitle>
              </Alert>
            </div>
          </div>
        )) ||
        (((Object.keys(req1.data?.data).length > 0) && (Object.keys(req1.data?.data).length < 4)) && (
          <div style={{
            margin: 0,
            display: 'flex',
            flexDirection: 'column', // Alteração para organizar em coluna
            alignItems: 'center', // Centralizar horizontalmente
            height: '50vh',
            backgroundColor: 'rgb(229, 246, 253)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            borderRadius: '4px'
          }}>
            <Alert severity="info" style={{
              backgroundColor: 'rgb(229, 246, 253)',
              color: 'rgb(1, 67, 97)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px' // Espaço abaixo do Alert
            }}>
              <AlertTitle>Tipos de documentos e categorias que precisam ser cadastradas.</AlertTitle>
            </Alert>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
              color: 'rgb(1, 67, 97)',
              width: '100%' // Largura total
            }}>
              {constTiposCategorias
                .filter(([num]) => !chaves.includes(num.toString()))
                .map(([num, tipo]) => (
                  <React.Fragment key={num}>{renderAlert(num, tipo)}</React.Fragment>
                ))
              }
            </div>
          </div>

        )) ||
        (
          <strong>OK</strong>
        )
        }
        </>
      ) }
      </>
    )
  }
}

export default checkDate
