/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { SearchOutlined } from '@mui/icons-material'
import { Box, CircularProgress, FormControl, Input, InputAdornment, InputLabel, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { Status } from 'components/Status'
import moment from 'moment'
import { StyledTableCell, StyledTableRow } from 'pages/purchaseOrder/components/table.styles'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getSuspendedOrders } from 'services/pedido/PedidoService'
import { ShowFts } from '../components/ShowFts'
import ChipFT from '../components/ChipFT'

const SuspendedTable: React.FC = () => {
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const inputSearchRef = useRef<HTMLInputElement>(null)

  const { data, status, refetch, isFetching } = useQuery(
    'suspended-orders',
    async () => getSuspendedOrders({ page: page + 1, search }),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    refetch()
  }, [search, page])

  const debounce = useCallback((func: any, wait: number): any => {
    let timeout: NodeJS.Timeout

    return function executedFunction(...args: number[]) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }, [])

  const returnedFunction = debounce((event: any) => {
    if (event?.target?.value) {
      setSearch(event.target.value)
    } else {
      console.log('CLEAR')
      setSearch('')
    }
  }, 400)

  const getQtdSolicitada = (row: any) => {
    const total = row?.pedidoFts?.reduce((acc, currentValue) => {
      const totalEntregas = currentValue?.entregas?.reduce(
        (total, currentEntrega) => total + Number(currentEntrega?.quantidade),
        0
      )
      return acc + (totalEntregas || 0)
    }, 0)

    return total
  }

  return (
    <>
      {status === 'loading' && (
        <Box
          sx={{
            display: 'flex',
            height: 200,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {status === 'success' && (
        <TableContainer component={Paper}>
          {isFetching && (
            <Box display="flex" alignItems="center">
              <CircularProgress size={18} sx={{ marginRight: 1 }} />
              <Typography component="span" variant="body2" color="primary">
                Carregando...
              </Typography>
            </Box>
          )}
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell align="center">ID</StyledTableCell> */}
                <StyledTableCell>
                  <FormControl variant="standard" className="w-100">
                    <InputLabel htmlFor="field-search">
                      Pesquisar por cliente
                    </InputLabel>
                    <Input
                      id="field-search"
                      autoComplete="off"
                      type="text"
                      defaultValue=""
                      onKeyUp={(event) => returnedFunction(event)}
                      onKeyPress={(event) => returnedFunction(event)}
                      // onClick={(event) => returnedFunction(event)}
                      ref={inputSearchRef}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchOutlined />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </StyledTableCell>
                <StyledTableCell align="center">QTD FT</StyledTableCell>
                <StyledTableCell align="center">Solicitado em</StyledTableCell>
                <StyledTableCell align="center">Qtd Solicitada</StyledTableCell>
                <StyledTableCell align="center">
                  Status do pedido
                </StyledTableCell>
                <StyledTableCell align="center">Recorrente</StyledTableCell>
                <StyledTableCell align="center">
                  Status ferramental
                </StyledTableCell>
                <StyledTableCell align="center">Book</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'success' && !data?.docs?.length && (
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <Box
                      sx={{
                        display: 'flex',
                        height: 200,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography component="span" variant="body2">
                        Nenhum pedido encontrado
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              )}
              {data?.docs?.map((row) => {
                return (
                  <StyledTableRow key={row?._id}>
                    <StyledTableCell component="th" scope="row">
                      {row?.client?.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <ChipFT pedidos={row?.pedidoFts} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.createdAt
                        ? moment(row?.createdAt).format('DD/MM/YYYY')
                        : ''}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {getQtdSolicitada(row)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Status title={row?.status?.toUpperCase()} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.isRecorrente ? 'SIM' : 'NÃO'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {!!row?.budgetsTool?.length && (
                        <>
                          <Status
                            title={
                              row?.budgetsTool.filter(
                                (item) =>
                                  item?.status?.toUpperCase() !== 'APROVADO'
                              )?.length >= 1
                                ? 'EM ANÁLISE'
                                : 'APROVADO'
                            }
                          />
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.booking?.status && (
                        <Status title={row?.booking?.status?.toUpperCase()} />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
            component="div"
            count={data?.total ?? 0}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        </TableContainer>
      )}
    </>
  )
}

export default SuspendedTable
