import * as yup from 'yup'

const validationRepresentativeSchema = yup.object().shape({
  representatives: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Preencha este campo'),
      email: yup.string().email('E-mail inválido').required('Preencha este campo')
      // status: yup.string().required('Preencha este campo')
    })
  )
})

export { validationRepresentativeSchema }
