import React, { useState } from 'react'
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import FormCustomerInformation from './components/FormCustomerInformation'
import FormCustomerRepresentatives from './components/FormCustomerRepresentatives'
import FormCustomerDocumentation from './components/FormCustomerDocumentation'
import FormCustomerIE from './components/FormCustomerIE'
import FormCustomerInformationJuridical from './components/FormCustomerInformationJuridical'
import { useLocation } from 'react-router-dom'
import { MODULES } from 'helpers/modules'
import { Can } from 'components/Permission/Can'
import { PERMISSIONS } from 'helpers/permissions'

const steps = ['Informações do Cliente', 'Representantes', 'Documentação']
const stepsPJ = [
  'Informações do Cliente',
  'Inscrição estadual',
  'Representantes',
  'Documentação'
]

const FormCustomerControl: React.FC = () => {
  const { state }: any = useLocation()
  console.log(state)

  const [typePerson, setTypePerson] = useState<'PF' | 'PJ'>(() => {
    if (state?.client?.type) {
      return state?.client?.type
    }
    return undefined
  })
  const [activeStep, setActiveStep] = useState(0) //  0

  const handleGoBack = () => {
    setActiveStep((state) => (state > 0 ? state - 1 : 0))
  }

  const handleNextStep = () => {
    const limit = typePerson === 'PJ' ? stepsPJ.length - 1 : steps.length - 1
    setActiveStep((state) => (state < limit ? state + 1 : limit))
  }

  return (
    <Can
      module={MODULES.CLIENTES}
      permissions={[PERMISSIONS.CREATE, PERMISSIONS.UPDATE]}
    >
    <>
      <Box
        component={Paper}
        marginBottom={5}
        paddingY={3}
        sx={{ width: '100%' }}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          {typePerson === 'PF' &&
            steps.map((label, index) => (
              <Step
                key={label}
                onClick={() => {
                  if (state?.client?._id) {
                    setActiveStep(index)
                  }
                }}
                sx={{
                  '& svg.MuiStepIcon-root': { color: '#1867C0 !important' },
                  '& .Mui-disabled, & .MuiStepLabel-root': {
                    cursor: state?.client?._id ? 'pointer' : 'auto'
                  }
                }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          {typePerson !== 'PF' &&
            stepsPJ.map((label, index) => (
              <Step
                key={label}
                onClick={() => {
                  if (state?.client?._id) {
                    setActiveStep(index)
                  }
                }}
                sx={{
                  '& svg.MuiStepIcon-root': { color: '#1867C0 !important' },
                  '& .Mui-disabled, & .MuiStepLabel-root': {
                    cursor: state?.client?._id ? 'pointer' : 'auto'
                  }
                }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
        </Stepper>
      </Box>

      {activeStep === 0 && (
        <Grid container marginBottom={3} spacing={3}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="type-person-select-label">Pessoa</InputLabel>
              <Select
                labelId="type-person-select-label"
                id="type-person-select"
                value={typePerson}
                label="Pessoa"
                onChange={(e) =>
                  setTypePerson(e.target.value as 'PF' | 'PJ')
                }
              >
                <MenuItem value="PF" disabled={!!state?.client?._id}>Física</MenuItem>
                <MenuItem value="PJ" disabled={!!state?.client?._id}>Jurídica</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      {typePerson === 'PF' && (
        <>
          {activeStep === 0 && (
            <FormCustomerInformation nextStep={handleNextStep} />
          )}

          {activeStep === 1 && (
            <FormCustomerRepresentatives
              goBack={handleGoBack}
              nextStep={handleNextStep}
            />
          )}

          {activeStep === 2 && (
            <FormCustomerDocumentation
              goBack={handleGoBack}
              nextStep={handleNextStep}
            />
          )}
        </>
      )}

      {typePerson === 'PJ' && (
        <>
          {activeStep === 0 && (
            <FormCustomerInformationJuridical nextStep={handleNextStep} />
          )}

          {activeStep === 1 && (
            <FormCustomerIE goBack={handleGoBack} nextStep={handleNextStep} />
          )}

          {activeStep === 2 && (
            <FormCustomerRepresentatives
              goBack={handleGoBack}
              nextStep={handleNextStep}
            />
          )}

          {activeStep === 3 && (
            <FormCustomerDocumentation
              goBack={handleGoBack}
              nextStep={handleNextStep}
            />
          )}
        </>
      )}
    </>
    </Can>
  )
}

export default FormCustomerControl
