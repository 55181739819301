import React from 'react'
import { Box, Button, TextField } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import FaqType from '../../../types/faq/FaqType'
import { useFormik } from 'formik'
import { validationSchema } from '../../../types/validation/ValidationFaq'
import { toast } from 'react-toastify'
import { createFaq, updateFaq } from '../../../services/faq/FaqService'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'

const Faq: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { state }: any = useLocation()

  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit
  } = useFormik({
    onSubmit: async (values: FaqType) => {
      const valuesForm: FaqType = {
        question: values.question,
        answer: values.answer
      }
      if (!state) {
        setLoading(true)
        try {
          const { data } = await createFaq(valuesForm)
          if (data) {
            toast.success('Salvo com sucesso!')
            setTimeout(() => {
              navigate('/home/company')
            }, 2500)
          } else {
            toast.error('Erro ao salvar')
          }
        } catch (error: any) {
          console.log('erro', error.response.data)
        } finally {
          setLoading(false)
        }
      } else {
        setLoading(true)
        try {
          const { data } = await updateFaq(String(values._id), valuesForm)
          if (!data) {
            toast.success('Salvo com sucesso!')
            setTimeout(() => {
              navigate('/home/company')
            }, 2500)
          } else {
            toast.error('Erro ao salvar')
          }
        } catch (error: any) {
          console.log('erro', error.response.data)
        } finally {
          setLoading(false)
        }
      }
    },
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      question: state?.row?.question ?? '',
      answer: state?.row?.answer ?? '',
      _id: state?.row?._id ?? ''
    }
  })
  return (
    <>

      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Empresa', url: '/home/company' },
          { text: 'Cadastrar Dúvida Frequente' }
        ]}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form onSubmit={handleSubmit}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginRight: '30px',
            marginTop: '20px'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px'
                }}
              >
                <div style={{ color: '#262626', marginTop: '40px' }}>
                  <h3>Crie seu título e contéudo</h3>
                  <TextField
                    error={!!(errors.question && touched.question)}
                    style={{ width: '100%' }}
                    id="standard-basic"
                    label="Descrição"
                    variant="standard"
                    value={values.question}
                    onChange={handleChange('question')}
                    helperText={errors.question && touched.question ? 'Campo Obrigatorio' : ''} />

                  <TextField
                    error={!!(errors.answer && touched.answer)}
                    style={{ marginTop: '40px', width: '100%' }}
                    id="outlined-multiline-static"
                    label="Resposta"
                    multiline
                    rows={3}
                    value={values.answer}
                    onChange={handleChange('answer')}
                    helperText={errors.answer && touched.answer ? 'Campo Obrigatorio' : ''}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '80px'
            }}
          >
            <Button
              style={{
                marginRight: '10px',
                backgroundColor: '#EE207A',
                borderRadius: '2px',
                width: '122px',
                color: 'white'
              }}
              onClick={() => navigate('/home/company')}
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: '#4060E6',
                borderRadius: '2px',
                width: '122px',
                color: 'white'
              }}
              type="submit"
            >
              {loading ? 'CARREGANDO...' : 'SALVAR'}
            </Button>
          </div>
        </form>
      </Box>
    </>
  )
}

export default Faq
