import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string
  background?: string
  className?: string
  loading?: boolean
  mold?: 'red' | 'primary' | 'green'
  style?: React.CSSProperties
  type?: 'submit' | 'reset' | 'button' | undefined
}

const Button: React.FC<Props> = ({
  color,
  style,
  mold,
  loading,
  children,
  background,
  type,
  ...attributes
}) => {
  const getBackgroundColor = (mold?: string) => {
    const backgrounds = {
      red: '#ee207a',
      primary: '#4060E6',
      green: '#1AB1B0',
      default: '#ccc'
    }

    return mold ? backgrounds[mold] : backgrounds.default
  }

  return (
    <button
      style={{
        color: color ?? (mold && '#fff'),
        border: 'none',
        padding: '7px 20px',
        borderRadius: '2px',
        backgroundColor: mold ? getBackgroundColor(mold) : background ?? '#ccc',
        ...style
      }}
      type={type}
      {...attributes}
    >
      {loading ? (<CircularProgress style={{ marginBottom: '-4px' }} color='inherit' size={15} />) : children}
    </button>
  )
}

export default Button
