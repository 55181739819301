import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import MoreMenu from 'components/view/MoreMenu'
import { Box, CircularProgress, FormControlLabel, FormGroup, Switch, TablePagination } from '@mui/material'
import { StyledTableCell, StyledTableRow } from './table.styles'
import { httpClient } from 'config/httpClient'
import { useQuery } from 'react-query'
import { getAllFormulariosChapa } from 'services/portfolio/PortfolioService'
import { useNavigate } from 'react-router-dom'

interface Props {
  search?: string
}

const TablePlate: React.FC<Props> = ({ search }) => {
  const navigate = useNavigate()

  const [originalRows, setOriginalRows] = useState<any[]>([])
  const [rows, setRows] = useState<any[]>([])
  const [page, setPage] = useState(0)

  const { data, status, refetch, isFetching } = useQuery(
    'formularios-chapa',
    getAllFormulariosChapa,
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    setOriginalRows(data ?? [])
  }, [data?.length, isFetching])

  useEffect(() => {
    if (search?.length) {
      const searchResult = originalRows.filter((row) => {
        if (row?.Composicao?.composicao?.toUpperCase().includes(search.toUpperCase())) {
          return true
        } else if (row?.Referencia?.toUpperCase().includes(search.toUpperCase())) {
          return true
        } else if (row?.Composicao?.onda?.toUpperCase().includes(search.toUpperCase())) {
          return true
        }

        return false
      }
      )

      setPage(0)
      setRows(searchResult)
    } else {
      setRows(originalRows)
    }
  }, [search, originalRows])

  const handleRefetch = () => {
    refetch()
  }

  const handleDelete = (id: string) => {
    httpClient
      .delete(`products/formularios/chapa/${id}`)
      .then(() => {
        handleRefetch()
      })
      .catch((error) => console.log(error))
  }

  const handleEdit = (row: any) => {
    navigate(`/home/portfolio/products/formChapas/${row._id}`, {
      state: row
    })
  }

  if (status === 'loading') {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
          <StyledTableRow>
            <StyledTableCell>
              <Box display='flex' justifyContent='center' alignItems='center' height={200} width='100%'>
                <CircularProgress />
              </Box>
            </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Referência</StyledTableCell>
              <StyledTableCell align="left">Tipo de Papel</StyledTableCell>
              <StyledTableCell align="left">Tipo de Onda</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="right">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.slice(page * 5, page * 5 + 5)?.map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell align="left">{row?.Referencia}</StyledTableCell>
                <StyledTableCell align="left">{row?.Composicao?.composicao}</StyledTableCell>
                <StyledTableCell align="left">{row?.Composicao?.onda}</StyledTableCell>
                <StyledTableCell align="left">
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch checked={row.isActive} />}
                      label={row.isActive ? 'Ativo' : 'Inativo'}
                      onChange={() => handleDelete(row._id)}
                    />
                  </FormGroup>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <MoreMenu edit={() => handleEdit(row)} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5]}
          rowsPerPage={5}
          component="div"
          count={rows.length}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      </TableContainer>
    </>
  )
}

export default TablePlate
