/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import Button from 'components/form/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { PortfolioPaperType } from 'types/portfolio/PortfolioPaperType'
import {
  getCompositions,
  getFechamentos,
  getPortfolioPapers,
  getPortfolioPlates,
  putFormularioChapa
} from 'services/portfolio/PortfolioService'
import { PortfolioPlateType } from 'types/portfolio/PortfolioPlateType'
import { useFormik } from 'formik'
import { httpClient } from 'config/httpClient'
import { toast } from 'react-toastify'
import { onlyNumbers } from 'helpers/stringHelper'
import { validationFormularioChapa } from '../validations/validationFormularioChapa'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const FormEditChapas: React.FC = () => {
  const navigate = useNavigate()
  const { state }: any = useLocation()

  const [composicao, setComposicao] = useState<any[]>([])
  const [fechamentos, setFechamentos] = useState<any[]>([])

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: {
      reference: state?.reference ?? '',
      // id_Importacao: '',
      // TipoABNT: '',
      Referencia: state?.Referencia ?? '',
      LarguraInterna: state?.LarguraInterna ?? undefined, // number
      ComprimentoInterno: state?.ComprimentoInterno ?? undefined, // number
      AlturaInterna: state?.AlturaInterna ?? undefined, // number
      Composicao: {
        composicao: state?.Composicao?.composicao ?? '',
        onda: state?.Composicao?.onda ?? '',
        onda1: state?.Composicao?.onda1 ?? '',
        onda2: state?.Composicao?.onda2 ?? '',
        onda3: state?.Composicao?.onda3 ?? '',
        onda4: state?.Composicao?.onda4 ?? '',
        onda5: state?.Composicao?.onda5 ?? '',
        papel1: state?.Composicao?.papel1 ?? '',
        papel2: state?.Composicao?.papel2 ?? '',
        papel3: state?.Composicao?.papel3 ?? '',
        papel4: state?.Composicao?.papel4 ?? '',
        papel5: state?.Composicao?.papel5 ?? '',
        nomeComercial: state?.Composicao?.nomeComercial ?? '',
        mullen: state?.Composicao?.mullen ?? undefined, // number
        crushEnd: state?.Composicao?.crushEnd ?? undefined, // number
        gramatura: state?.Composicao?.gramatura ?? undefined, // number
        crush: state?.Composicao?.crush ?? undefined, // number
        preco: state?.Composicao?.preco ?? undefined, // number
        coluna: state?.Composicao?.coluna ?? undefined, // number
        espessura: state?.Composicao?.espessura ?? undefined // number
      },
      Fechamento: state?.Fechamento ?? {
        fechamento: state?.fechamento ?? '',
        descricao: state?.descricao ?? '',
        qtdEspessuras: state?.qtdEspessuras ?? undefined // number
      },
      ExigeLaudo: state?.ExigeLaudo ?? undefined, // boolean
      // TipoObs1: state?.TipoObs1 ?? '',
      Obs1: state?.Obs1 ?? '',
      // PacotesLargura: state?.PacotesLargura ?? undefined, // number
      // PacotesComprimento: state?.PacotesComprimento ?? undefined, // number
      // PacotesAltura: state?.PacotesAltura ?? undefined, // number
      Amarrado: state?.Amarrado ?? undefined, // boolean
      Paletizado: state?.Paletizado ?? undefined // boolean
      // CodCliente: state?.CodCliente ?? ''
    },
    validationSchema: validationFormularioChapa,
    onSubmit: async (values) => {
      const body = {
        ...values,
        Composicao: {
          composicao: values?.Composicao?.composicao,
          onda: values?.Composicao?.onda,
          onda1: values?.Composicao?.onda1,
          onda2: values?.Composicao?.onda2,
          onda3: values?.Composicao?.onda3,
          onda4: values?.Composicao?.onda4,
          onda5: values?.Composicao?.onda5,
          papel1: values?.Composicao?.papel1,
          papel2: values?.Composicao?.papel2,
          papel3: values?.Composicao?.papel3,
          papel4: values?.Composicao?.papel4,
          papel5: values?.Composicao?.papel5,
          nomeComercial: values?.Composicao?.nomeComercial,
          mullen: values?.Composicao?.mullen, // number
          crushEnd: values?.Composicao?.crushEnd, // number
          gramatura: values?.Composicao?.gramatura, // number
          crush: values?.Composicao?.crush, // number
          preco: values?.Composicao?.preco, // number
          coluna: values?.Composicao?.coluna, // number
          espessura: values?.Composicao?.espessura // number
        }
      }

      console.log('body: ', body)

      try {
        await putFormularioChapa(state?._id, body)
        toast.success('Salvo com sucesso')

        navigate('/home/portfolio/1')
      } catch (error: any) {
        console.log('Error: ', error)
        toast.error(
          error?.response?.data?.message?.[0] ?? error?.response?.data?.message ?? 'Erro ao cadastrar chapa'
        )
      }
    }
  })

  const loadData = useCallback(() => {
    getCompositions()
      .then((data) => {
        console.log('TRIMBOX Composição', data)
        setComposicao(data)
      })
      .catch((error) => {
        console.log('Error:', error)
      })
    getFechamentos().then((data) => {
      console.log('TRIMBOX Fechamento', data)
      setFechamentos(data)
    })
  }, [])

  useEffect(() => {
    loadData()

    return () => {
      setComposicao([])
      setFechamentos([])
    }
  }, [loadData])

  useEffect(() => {
    const widthD = values?.LarguraInterna ? `${values?.LarguraInterna} ` : ''
    const lengthD = values?.ComprimentoInterno
      ? `x ${values?.ComprimentoInterno} `
      : ''
    const nomenclature = values?.Composicao?.nomeComercial ?? ''

    setFieldValue(
      'Referencia',
      `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
    )
  }, [
    values?.LarguraInterna,
    values?.ComprimentoInterno,
    values?.Composicao?.nomeComercial
  ])

  const handleChangeIntegerNumber = (field: string) => (event: any) => {
    const newValue = onlyNumbers(event.target.value)
    setFieldValue(field, newValue ? Math.round(Number(newValue)) : '')
  }

  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Empresa', url: '/home/company' },
          { text: 'Portfólio PCE', url: '/home/portfolio' },
          { text: 'Formulário de Produto' }
        ]}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Box marginTop={2} marginBottom={2}>
          <Typography fontSize={20} fontWeight="500" component="h2">
            Formulário Chapa
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="standard-basic"
              label="Solicitado Por"
              variant="standard"
              fullWidth
              autoComplete="off"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="standard-basic"
              label="Representante"
              variant="standard"
              fullWidth
              autoComplete="off"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-basic"
              placeholder="Referência"
              variant="standard"
              value={values.Referencia}
              fullWidth
              disabled
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <h4>Especificações</h4>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="select-paper-standard-label" required>
                Composição
              </InputLabel>
              <Select
                labelId="select-paper-standard-label"
                id="select-paper-standard"
                label="Papel"
                value={values?.Composicao?.composicao}
                onChange={(e) => {
                  setFieldValue(
                    'Composicao',
                    composicao?.find(
                      (item) => item.composicao === e.target.value
                    )
                  )
                }}
                MenuProps={MenuProps}
                required
              >
                {composicao.map((item: any) => {
                  return (
                    <MenuItem key={item.composicao} value={item.composicao}>
                      {item?.composicao}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Tipo de Onda"
                  variant="standard"
                  value={values?.Composicao?.onda ?? ''}
                  fullWidth
                  disabled
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Papel"
                  variant="standard"
                  value={`${
                    values?.Composicao?.papel1
                      ? values?.Composicao?.papel1 + ','
                      : ''
                  }${
                    values?.Composicao?.papel2
                      ? values?.Composicao?.papel2 + ','
                      : ''
                  }${
                    values?.Composicao?.papel3
                      ? values?.Composicao?.papel3 + ','
                      : ''
                  }${
                    values?.Composicao?.papel4
                      ? values?.Composicao?.papel4 + ','
                      : ''
                  }${
                    values?.Composicao?.papel5
                      ? values?.Composicao?.papel5 + ','
                      : ''
                  }`.slice(0, -1)}
                  fullWidth
                  disabled
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Nome Comercial"
                  variant="standard"
                  value={values?.Composicao?.nomeComercial ?? ''}
                  fullWidth
                  disabled
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <h4>Dimensões</h4>
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              type="number"
              label="Vincos larguraInt"
              variant="standard"
              value={values.LarguraInterna}
              error={Boolean(errors.LarguraInterna && touched.LarguraInterna)}
              helperText={
                (errors.LarguraInterna && touched.LarguraInterna
                  ? errors.LarguraInterna
                  : null) as React.ReactNode
              }
              onChange={handleChangeIntegerNumber('LarguraInterna')}
              autoComplete="off"
              fullWidth
              required
            />
          </Grid>
          <Grid item alignSelf="flex-end">
            +
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              type="number"
              label="ComprInter"
              variant="standard"
              value={values.ComprimentoInterno}
              error={Boolean(
                errors.ComprimentoInterno && touched.ComprimentoInterno
              )}
              helperText={
                (errors.ComprimentoInterno && touched.ComprimentoInterno
                  ? errors.ComprimentoInterno
                  : null) as React.ReactNode
              }
              onChange={handleChangeIntegerNumber('ComprimentoInterno')}
              autoComplete="off"
              fullWidth
              required
            />
          </Grid>
          <Grid item alignSelf="flex-end">
            +
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              type="number"
              label="AlturaInter"
              variant="standard"
              value={values.AlturaInterna}
              error={Boolean(errors.AlturaInterna && touched.AlturaInterna)}
              helperText={
                (errors.AlturaInterna && touched.AlturaInterna
                  ? errors.AlturaInterna
                  : null) as React.ReactNode
              }
              onChange={handleChangeIntegerNumber('AlturaInterna')}
              autoComplete="off"
              fullWidth
              required
            />
          </Grid>
          <Grid item md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>=</Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              type="number"
              label="total (calculo)"
              variant="standard"
              value={
                Number(values?.LarguraInterna ?? 0) +
                Number(values?.ComprimentoInterno ?? 0) +
                Number(values?.AlturaInterna ?? 0)
              }
              autoComplete="off"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="select-paper-fechamento" required>
                Fechamento
              </InputLabel>
              <Select
                labelId="select-paper-fechamento"
                label="Papel"
                value={values?.Fechamento?.fechamento}
                onChange={(e) => {
                  setFieldValue(
                    'Fechamento',
                    fechamentos?.find(
                      (item) => item.fechamento === e.target.value
                    )
                  )
                }}
                MenuProps={MenuProps}
                required
              >
                {fechamentos.map((item: any) => {
                  return (
                    <MenuItem key={item.fechamento} value={item.fechamento}>
                      {item?.fechamento}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <h4>Informações adicionais</h4>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl error={Boolean(errors.Paletizado && touched.Paletizado)}>
              <FormLabel id="paletizado-group-label">Paletizado</FormLabel>
              <RadioGroup
                row
                aria-labelledby="paletizado-group-label"
                name="paletizado"
                value={values.Paletizado}
                onChange={(_, value) => {
                  void setFieldValue('Paletizado', value === 'true')
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
              {errors.Paletizado && touched.Paletizado && (
                <FormHelperText>{(errors?.Paletizado) as React.ReactNode}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl error={Boolean(errors.Amarrado && touched.Amarrado)}>
              <FormLabel id="amarrado-group-label">Amarrado</FormLabel>
              <RadioGroup
                row
                aria-labelledby="amarrado-group-label"
                name="amarrado-group"
                value={values.Amarrado}
                onChange={(_, value) => {
                  void setFieldValue('Amarrado', value === 'true')
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
              {errors.Amarrado && touched.Amarrado && (
                <FormHelperText>{(errors?.Amarrado) as React.ReactNode}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl
              error={Boolean(errors.ExigeLaudo && touched.ExigeLaudo)}
            >
              <FormLabel id="laudo-group-label">Laudo</FormLabel>
              <RadioGroup
                row
                aria-labelledby="laudo-group-label"
                name="laudo-group"
                value={values.ExigeLaudo}
                onChange={(_, value) => {
                  void setFieldValue('ExigeLaudo', value === 'true')
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
              {errors.ExigeLaudo && touched.ExigeLaudo && (
                <FormHelperText>{(errors?.ExigeLaudo) as React.ReactNode}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '30px' }}>
            <TextField
              label="Observações"
              value={values.Obs1}
              error={Boolean(errors.Obs1 && touched.Obs1)}
              helperText={(errors.Obs1 && touched.Obs1 ? errors.Obs1 : null) as React.ReactNode}
              onChange={handleChange('Obs1')}
              autoComplete="off"
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={8}
          className="text-right"
          style={{
            alignSelf: 'flex-end',
            justifySelf: 'self-end',
            marginTop: '10%'
          }}
        >
          <Button
            mold="red"
            type="button"
            onClick={() => navigate('/home/portfolio/1', { state: undefined })}
            style={{ marginRight: '10px' }}
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          <Button
            mold="primary"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {'SALVAR'}
          </Button>
        </Grid>
      </Box>
    </>
  )
}

export default FormEditChapas
