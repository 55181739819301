import React from 'react'
import { Chip } from '@mui/material'

const colors: any = {
  NOVO: '#4060E6',
  PENDENTE: '#EEBD20',
  AGUARDANDO: '#00B4E6',
  'AGUARDANDO APROVAÇÃO': '#00B4E6',
  'EM ANÁLISE': '#A1A1A1',
  CONTRAPROPOSTA: '#00B4E6',
  APROVADO: '#72CE2B',
  REPROVADO: '#EE207A',
  RECUSADO: '#EE207A',
  CANCELADO: '#EE207A',
  SUSPENSO: '#EE207A',
  'PENDENTE DIRETORIA': '#CE6C2B',
  'APROVADA PELA CONTROLADORIA': '#72CE2B',
  'REPROVADA PELA CONTROLADORIA': '#EE207A',
  'NO LN': '#72CE2B'
}

interface Props {
  title:
    | 'PENDENTE'
    | 'AGUARDANDO'
    | 'AGUARDANDO APROVAÇÃO'
    | 'CONTRAPROPOSTA'
    | 'APROVADO'
    | 'REPROVADO'
    | 'RECUSADO'
    | 'CANCELADO'
    | 'EM ANÁLISE'
    | 'SUSPENSO'
    | 'PENDENTE DIRETORIA'
    | 'APROVADA PELA CONTROLADORIA'
    | 'REPROVADA PELA CONTROLADORIA'
    | 'NOVO'
    | 'NO LN'
  children?: React.ReactNode
}

export const Status: React.FC<Props> = ({ title, children, ...rest }) => {
  return (
    <Chip
      label={title}
      variant="outlined"
      size="small"
      sx={{ borderColor: colors?.[title], color: colors?.[title] }}
      {...rest}
    />
  )
}
