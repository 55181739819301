export enum TipoProduto {
    CAIXA = '0001',
    CHAPA = '0002',
    PAPEL = '0006',
    PAPEL_ALT = '0003',
    CHAPA_ALT = '0007',
    PAPEL_TESTLINER = '0010',
    PAPEL_MIOLO_PCE = '0011',
    PAPEL_BRANCO_PCE = '0012',
    PAPEL_PA_KRAFT = '0014',
    PAPEL_PA_TESTLINER = '0015',
    OUTROS = '0004'
}

export enum TipoDevolucao {
    CAIXA = '0001d',
    CHAPA = '0002d',
    PAPEL = '0006d'
}

export enum Armazem {
    CAIXA = '452PCX',
    CHAPA = '452PCH',
    PAPEL = '452PBO'
}

const chapaTypes: any = [
    TipoProduto.CHAPA,
    TipoProduto.CHAPA_ALT
]

const papelTypes: any = [
    TipoProduto.PAPEL,
    TipoProduto.PAPEL_ALT,
    TipoProduto.PAPEL_TESTLINER,
    TipoProduto.PAPEL_MIOLO_PCE,
    TipoProduto.PAPEL_BRANCO_PCE,
    TipoProduto.PAPEL_PA_KRAFT,
    TipoProduto.PAPEL_PA_TESTLINER
]

const isCaixa = (item) => {
    return item === TipoProduto.CAIXA
}

const isChapa = (item) => {
    return chapaTypes.includes(item)
}

const isPapel = (item) => {
    return papelTypes.includes(item)
}

export { isCaixa, isChapa, isPapel }
