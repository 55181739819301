import { httpClient, httpLN } from 'config/httpClient'
import moment from 'moment'
import { format } from 'date-fns'

export const getReportBilling = async (mes: string, ano: string): Promise<any> => {
  const { data } = await httpClient.get(`/previsions/report/info?mes=${mes}&ano=${ano}`)
  console.log('getReportBilling =>', data)
  return data as any[]
}

export const getReport = async (date: string, rangeDate?: any): Promise<any> => {
  let url = ''
  if (!rangeDate.length) {
    url = `/faturamentos/dia?data=${date}`
  } else {
    const inicio = rangeDate.map(v => (moment.unix(v.unix).format('DD/MM/YYYY')))
    const fim = inicio[inicio.length - 1]
    url = `/faturamentos/dia?dataInicio=${inicio[0]}&dataFim=${fim}`
  }

  const { data } = await httpLN.get(url)
  console.log('getReport => ', data)
  return data as any[]
}

export const getReportProductionMonth = async (lastDate?: any, firstDate?: any): Promise<any[]> => {
  const inicio = format(firstDate, 'dd/MM/yyyy')
  const fim = format(lastDate, 'dd/MM/yyyy')
  console.log('início e fim: ', inicio, fim)
  const url = `producao/mes?dataInicio=${inicio}&dataFim=${fim}`
  const { data } = await httpLN.get(url)
  console.log('getReportProductionMonth => ', data)
  return data as any[]
}

export const getReportOrder = async (date: string, rangeDate?: any): Promise<any> => {
  let url = ''
  if (!rangeDate.length) {
    url = `/pedidos/dia?data=${date}`
  } else {
    const inicio = rangeDate.map(v => (moment.unix(v.unix).format('DD/MM/YYYY')))
    const fim = inicio[inicio.length - 1]
    url = `/pedidos/dia?dataInicio=${inicio[0]}&dataFim=${fim}`
  }
  const { data } = await httpLN.get(url)
  console.log('getReportOrder =>', data)
  return data.result as any[]
}

export const getReportOrderAccumulated = async (date: string, rangeDate?: any): Promise<any> => {
  let url = ''
  if (!rangeDate.length) {
    url = `/pedidos/dia-acumulado?data=${date}`
  } else {
    const inicio = rangeDate.map(v => (moment.unix(v.unix).format('DD/MM/YYYY')))
    const fim = inicio[inicio.length - 1]
    url = `/pedidos/dia-acumulado?dataInicio=${inicio[0]}&dataFim=${fim}`
  }
  const { data } = await httpLN.get(url)
  console.log('getReportOrderAccumulated =>', data)
  return data.result as any[]
}

export const getReportDevolucoes = async (date: string, rangeDate?: any): Promise<any> => {
  let url = ''
  if (!rangeDate.length) {
    url = `/faturamentos/devolucao?data=${date}`
  } else {
    const inicio = rangeDate.map(v => (moment.unix(v.unix).format('DD/MM/YYYY')))
    const fim = inicio[inicio.length - 1]
    url = `/faturamentos/devolucao?dataInicio=${inicio[0]}&dataFim=${fim}`
  }
  const { data } = await httpLN.get(url)
  console.log('getReportDevolucoes =>', data)
  return data as any[]
}

export const getReportOthers = async (date: string): Promise<any> => {
  const url = `/faturamentos/outros?data=${date}`
  const { data } = await httpLN.get(url)
  console.log('getReportOthers =>', data)
  return data as any[]
}

export const getReportOthersMensal = async (ano: string): Promise<any> => {
  const url = `/faturamentos/outros-mensal?ano=${ano}`
  const { data } = await httpLN.get(url)
  console.log('getReportOthersMensal =>', data)
  return data as any[]
}

export const getReportDevolucaoMensal = async (date: string): Promise<any> => {
  const url = `/producao/devolucao-mes?data=${date}`
  const { data } = await httpLN.get(url)
  console.log('getReportDevolucaoMensal =>', data)
  return data as any[]
}

export const getReportBalanceteSucataMensal = async (date: string): Promise<any> => {
  const url = `/producao/balancete-sucata-mes?data=${date}`
  const { data } = await httpLN.get(url)
  console.log('getReportBalanceteSucataMensal =>', data)
  return data as any[]
}

export const getPrevision = async (mes: string, ano: string): Promise<any> => {
  const { data } = await httpClient.get(`/previsions/report/info?mes=${mes}&ano=${ano}`)
  console.log('getPrevision =>', data)
  return data as any[]
}

export const getTargetProduction = async (ano: string, mes: string): Promise<any> => {
  const { data } = await httpClient.get(`/previsions/form?year=${ano}&month=${mes}`)
  console.log('getTargetProduction =>', data)
  return data as any[]
}

export const getValorImpressoraDia = async (date: string, dataRanger?: any): Promise<any> => {
  if (!dataRanger.length) {
    const params = {
      dia: moment(date).format('D'),
      mes: moment(date).format('M'),
      ano: moment(date).format('YYYY')
    }
    const { data } = await httpClient.get('previsions/printer/day', { params })
    console.log('getValorImpressoraDia =>', data)
    return data as any[]
  } else {
    const inicio = dataRanger.map(v => (moment.unix(v.unix).format('DD/MM/YYYY')))
    const fim = inicio[inicio.length - 1]
    const params = {
      dia: moment(fim, 'DD/MM/YYYY').format('D'),
      mes: moment(fim, 'DD/MM/YYYY').format('M'),
      ano: moment(fim, 'DD/MM/YYYY').format('YYYY'),
      diaInicial: moment(inicio[0], 'DD/MM/YYYY').format('D'),
      mesInicial: moment(inicio[0], 'DD/MM/YYYY').format('M'),
      anoInicial: moment(inicio[0], 'DD/MM/YYYY').format('YYYY')
    }
    console.log({ params })
    const { data } = await httpClient.get('previsions/printer/day-interval', { params })
    console.log('getValorImpressoraDiaIntervalo =>', data)
    return data as any[]
  }
}

export const getValorImpressoraAcumulado = async (dia: string, mes: string, ano: string): Promise<any> => {
  const params = { dia, mes, ano }
  const { data } = await httpClient.get('previsions/printer/accumulated', { params })
  console.log('getValorImpressoraAcumulado =>', data)
  return data as any[]
}

export const getReportPedidoCadastradoClientes = async (date: string): Promise<any> => {
  const { data } = await httpLN.get(`/pedidos/cadastrado-clientes?data=${date}`)
  console.log('getReportPedidoCadastradoClientes =>', data)
  return data.result as any[]
}

export const getReportValorPorKg = async (lastDate?: any, firstDate?: any): Promise<any[]> => {
  const inicio = format(firstDate, 'dd/MM/yyyy')
  const fim = format(lastDate, 'dd/MM/yyyy')
  const url = `producao/valor-por-kg?dataInicio=${inicio}&dataFim=${fim}`
  const { data } = await httpLN.get(url)
  console.log('getReportValorPorKg =>', data)
  return data
}

export const getReportTipoPapel = async (lastDate?: any): Promise<any[]> => {
  const fim = format(lastDate, 'dd/MM/yyyy')
  console.log('lastDate =>', lastDate)
  console.log('fim =>', fim)
  const url = `producao/tipo-papel-mes?data=${fim}`
  const { data } = await httpLN.get(url)
  console.log('getReportTipoPapel =>', data)
  return data
}

export interface ContabilItem {
  conta: string
  description: string
  result: string
}

export const getReportContaContabil = async (lastDate?: any, selectedCodes?: string[]): Promise<any[]> => {
  const dataCorrente = format(lastDate, 'dd/MM/yyyy')
  if (!dataCorrente) {
    throw new Error('A data não foi fornecida.')
  }

  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/ // Regular expression to validate the format DD/MM/YYYY

  if (!dateRegex.test(dataCorrente)) {
    throw new Error('A data está fora do padrão DD/MM/YYYY.')
  }

  let url = `producao/conta-contabil-mes?data=${dataCorrente}`

  if (selectedCodes && selectedCodes.length > 0) {
    const selectedCodesString = selectedCodes.join(',')
    url += `&codes=${selectedCodesString}`
  }

  return await httpLN.get(url)
}

export const getReportConsumo = async (lastDate?: any, codesA?: string[], codesB?: string[], dimensao?: string[]): Promise<any[]> => {
  const dataCorrente = format(lastDate, 'dd/MM/yyyy')
  if (!dataCorrente) {
    throw new Error('A data não foi fornecida.')
  }

  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/ // Regular expression to validate the format DD/MM/YYYY

  if (!dateRegex.test(dataCorrente)) {
    throw new Error('A data está fora do padrão DD/MM/YYYY.')
  }

  let url = `producao/consumo-mes?data=${dataCorrente}`

  if (codesA && codesA.length > 0) {
    const selectedCodesString = codesA.join(',')
    url += `&codesA=${selectedCodesString}`
  }

  if (codesB && codesB.length > 0) {
    const selectedCodesString = codesB.join(',')
    url += `&codesB=${selectedCodesString}`
  }

  if (dimensao && dimensao.length > 0) {
    const selectedCodesString = dimensao.join(',')
    url += `&dimensao=${selectedCodesString}`
  }

  const { data } = await httpLN.get(url)
  return data as any[]
}

export const getRefuseIndex = async (mes: string, ano: string): Promise<any> => {
  console.log('IN getRefuseIndex', mes, ano)
  const { data } = await httpClient.get(`budget-refuse-index/${mes}/${ano}`)
  console.log('getRefuseIndex =>', data)
  return data as any[]
}
