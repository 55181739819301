import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Label = styled('div')`
  display: flex;
  justify-content: center;
  align-items: end;
  color: gray;
  font-size: 12px;
  padding-bottom: 5px;
  cursor: default;

  &:hover {
    color: #4060E6;
  }
`

function PrivacyLabel() {
  const navigate = useNavigate()

  return (
    <Label aria-label='política de privacidade' >
      <div onClick={() => { navigate('/privacy-policy') }}>Política de privacidade</div>
    </Label>
  )
}

export default PrivacyLabel
