import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import React from 'react'
import { BreadcrumbsType } from 'types/breadcrumbs/BreadcrumbsType'
import { Container } from './components/Container'
import { GoBack } from './components/GoBack'
import { Header } from './components/Header'
import { ReportProposalForDirectionContainer } from './container/ReportProposalForDirectionContainer'

const breadcrumbs: BreadcrumbsType[] = [
  { text: 'Gerencial', url: '/home/managementControl' },
  { text: 'Propostas para a Controladoria' }
]

const ReportProposalForDirection: React.FC = () => {
  return (
    <Container>
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />

      <Header title="Propostas para a Controladoria" />
      <ReportProposalForDirectionContainer />

      <GoBack forRoute="/home/managementControl" />
    </Container>
  )
}

export { ReportProposalForDirection }
