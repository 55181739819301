/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import {
  ButtonRow,
  DialogStyle,
  LabelText
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import ModalHead from './ModalHead';
import { httpClient } from 'config/httpClient';
import { useQuery } from 'react-query';
import { CloudDownload } from '@mui/icons-material';

function CardDownload({ href, title }: { href: string, title: string }) {
  return (
    <Grid item xs={4} md={2}>
      <Tooltip title={title} placement='top' arrow>
        <Box
          component="a"
          href={href}
          download
          sx={{
            width: '100%',
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            color: (theme) => theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 1,
            cursor: 'pointer'
          }}
        >
          <CloudDownload fontSize="large" />
        </Box>
      </Tooltip>
    </Grid>
  );
}

interface Props {
  open: boolean
  close: () => void
  data?: any
}

const Finished: React.FC<Props> = (props) => {
  console.log(props.data);

  const closeModal = () => props.close();

  const { data, status } = useQuery(
    ['modal-booking-conclusao', props?.data?.pedido],
    async () =>
      await httpClient.get<any>(
        `/bookings/pdf/download/${props?.data?.pedido}/${
          props?.data?.ft?._id ?? props?.data?.codItem
        }`
      ),
    {
      refetchOnWindowFocus: false
    }
  );

  // console.log(data?.data);

  return (
    <>
      <DialogStyle
        open={props.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHead title="Conclusão, baixar documentos" idPreFT={props?.data?.ft?.preFT?._id} />
        <DialogContent>
          {status === 'loading' && (
            <Box
              display="flex"
              alignItems="center"
              height={200}
              justifyContent="center"
              flexDirection="column"
            >
              <CircularProgress size={24} />
              <strong>Carregando...</strong>
            </Box>
          )}

          {status !== 'loading' && (
            <div style={{ marginTop: '24px' }}>
              <LabelText>Desenho técnico*</LabelText>
              <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                {data?.data?.desenhoTecnico?.map((item, index) => (
                  <CardDownload
                    key={item?._id}
                    href={item?.url ?? ''}
                    title={item?.comment}
                  />
                ))}
                {!data?.data?.desenhoTecnico?.length && (
                  <Grid item xs={12}>
                    <Typography>Nenhum documento anexado.</Typography>
                  </Grid>
                )}
              </Grid>

              <LabelText>Desenho do ferramental*</LabelText>
              <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                {data?.data?.desenhoFerramental?.map((item, index) => (
                  <CardDownload
                    key={item?._id}
                    href={item?.url ?? ''}
                    title={item?.comment}
                  />
                ))}
                {!data?.data?.desenhoFerramental?.length && (
                  <Grid item xs={12}>
                    <Typography>Nenhum documento anexado.</Typography>
                  </Grid>
                )}
              </Grid>

              <LabelText>Orçamento ferramental*</LabelText>
              <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                {data?.data?.orcamentoFerramental?.map((item, index) => (
                  <CardDownload
                    key={item?._id}
                    href={item?.url ?? ''}
                    title={item?.justification}
                  />
                ))}

                {!data?.data?.orcamentoFerramental?.length && (
                  <Grid item xs={12}>
                    <Typography>Nenhum documento anexado.</Typography>
                  </Grid>
                )}
              </Grid>

              <LabelText>Outros anexos</LabelText>
              <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                {data?.data?.outrosAnexos?.map((item, index) => (
                  <CardDownload
                    key={item?._id}
                    href={item?.url ?? ''}
                    title={item?.comment}
                  />
                ))}
              </Grid>
            </div>
          )}

          <ButtonRow
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              marginTop: 30
            }}
          >
            <Button
              style={{
                marginRight: '10px',
                backgroundColor: '#4060E6',
                color: '#FFFFFF'
              }}
              variant="contained"
              onClick={() => {
                props.close();
              }}
            >
              Voltar
            </Button>
          </ButtonRow>
        </DialogContent>
      </DialogStyle>
    </>
  );
};

export default Finished;
