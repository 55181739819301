/* eslint-disable @typescript-eslint/no-unused-vars */
import { Visibility } from '@mui/icons-material'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { httpClient, httpLN } from 'config/httpClient'
import { useCallback, useState } from 'react'
import { FormatPrice } from 'utils/FormatPrice'

interface IE {
  codeLn: string
  isActive: boolean
}
interface CreditoLN {
  codigo: string
  credito_disponivel: string
  credito_utilizado: string
  nome: string
}
interface FinancialCredit {
  creditoDisponivel: number
  creditoUtilizado: number
  currentBalance: number
  formattedCreditoDisponivel: string
  formattedCreditoUtilizado: string
  formattedCurrentBalance: string
}

interface Props {
  data: any
}

export function ButtonViewCredit({ data }: Props) {
  const [isNotIe, setIsNotIe] = useState<string | null>(null)
  const [isError, setIsError] = useState<string | null>(null)
  const [financialCredit, setFinancialCredit] = useState<FinancialCredit>()
  const [loading, setLoading] = useState(false)

  const getFinancialCredit = useCallback(async () => {
    setLoading(true)
    try {
      const responseIes = await httpClient.get<IE[]>(
        `/company/${data?._id}/ie`
      )

      if (!responseIes?.data?.length) {
        setIsNotIe('Nenhuma inscrição estadual encontrada.')
        setLoading(false)
        return
      }

      const ies = responseIes.data
      console.log('IES', ies)

      let creditoDisponivel = 0
      let creditoUtilizado = 0

      for (const item of ies) {
        const responseLN = await httpLN.get<{ result: CreditoLN[] }>(
          `/clientes/cliente_credito?codParceiro=${item?.codeLn}`
        )

        responseLN?.data?.result?.forEach((ln) => {
          creditoDisponivel =
            creditoDisponivel + Number(ln?.credito_disponivel || 0)
          creditoUtilizado =
            creditoUtilizado + Number(ln?.credito_utilizado || 0)
        })

        console.log(
          'CREDITO LN',
          responseLN.data,
          creditoDisponivel,
          creditoUtilizado
        )
      }

      setFinancialCredit({
        creditoDisponivel,
        creditoUtilizado,
        formattedCreditoDisponivel: FormatPrice(creditoDisponivel),
        formattedCreditoUtilizado: FormatPrice(creditoUtilizado),
        currentBalance: creditoDisponivel - creditoUtilizado,
        formattedCurrentBalance: FormatPrice(
          ((creditoDisponivel < 0) ? 0 : creditoDisponivel - creditoUtilizado)
        )
      })
      setLoading(false)
    } catch (error: any) {
      setIsError('Erro! não foi possível fazer a analise de crédito.')
      setLoading(false)
    }
  }, [])

  return (
    <>
      {(isNotIe ?? isError) && <Typography variant='body2'>{isNotIe ?? isError}</Typography>}

      {!isNotIe && !isError && !financialCredit?.formattedCreditoDisponivel && (
        <Stack>
          <Button variant='outlined' onClick={getFinancialCredit} disabled={loading}>
            {!loading && <Visibility fontSize='small' />}
            {loading && <CircularProgress size={24} />}
          </Button>
        </Stack>
      )}

      {!isNotIe && financialCredit?.formattedCreditoDisponivel && (
        <Stack>
        <Typography variant="body2" whiteSpace="nowrap" style={{ color: 'black' }}>
            Limite de Crédito:{' '}
            {financialCredit?.formattedCreditoDisponivel}
        </Typography>
        <Typography variant="body2" whiteSpace="nowrap" style={{ color: 'red' }}>
          Utilizado:{' '}
            {financialCredit?.formattedCreditoUtilizado}
        </Typography>
        <Typography variant="body2" whiteSpace="nowrap" style={{ color: 'blue' }}>
          Disponível:{' '}
            {financialCredit?.formattedCurrentBalance}
        </Typography>
      </Stack>
      )}
    </>
  )
}
