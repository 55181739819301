import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILogin } from '../models/ILogin'

interface LoginState {
  loginData: ILogin | null
}

const initialState: LoginState = {
  loginData: {
    tokenData: '',
    accessToken: '',
    logged: false,
    email: '',
    picture: ''
  }
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    _changeLogin(state, action: PayloadAction<ILogin>) {
      state.loginData = action.payload
    }
  }
})

export const loginReducer = loginSlice.reducer
export const { _changeLogin } = loginSlice.actions
