import { Box, CircularProgress, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import MoreMenu from 'components/view/MoreMenu'
import StatusView from 'components/view/StatusView'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteSalesGoals, getSalesGoals } from 'services/managementControl/salesGoals/salesGoals.services'
import styled from 'styled-components'
import { FormSalesGoalsType } from 'types/managementControl/salesGoals/SalesGoalsType'

const BoxStyle = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  marginRight: 88px;
`
const IconStyle = styled(Icon)`
  margin-left: 10px
`

const TableSalesGoals: React.FC = () => {
  const navigate = useNavigate()
  const [dataValues, setDataValues] = useState<FormSalesGoalsType[]>([])

  const queryMultiple = () => {
    const data = useQuery(['meta'],
      async () => {
        return getSalesGoals()
      },
      {
        onSuccess: (data) => {
          setDataValues(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    return [data]
  }

  const [data] = queryMultiple()

  const edit = (row: FormSalesGoalsType) => {
    const mes = {
      janeiro: 0,
      fevereiro: 1,
      marco: 2,
      abril: 3,
      maio: 4,
      junho: 5,
      julho: 6,
      agosto: 7,
      setembro: 8,
      outubro: 9,
      novembro: 10,
      dezembro: 11
    }
    const mesFormated = row.month.toLowerCase()
    navigate('/home/managementControl/management/sales-goals-form', {
      state: {
        row: {
          ...row,
          month: mes[mesFormated]
        }
      }
    })
  }

  const delet = async (id: string) => {
    try {
      await deleteSalesGoals(id)
      toast.success('Meta excluida com sucesso!')
      setTimeout(() => {
        queryMultiple()
      }, 2500)
    } catch (error: any) {
      console.log('Error: ', error)
      toast.error('Erro ao excluir meta')
    }
  }

  return (
    <>
      {(data.isLoading || data.isFetching) && (
        <BoxStyle>
          <CircularProgress />
        </BoxStyle>
      )}
      {data.isError && (
        <BoxStyle>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <IconStyle>signal_wifi_statusbar_connected_no_internet_4</IconStyle>
        </BoxStyle>
      )}

      {(data.isSuccess && !data.isError && !data.isFetching) && (
        <>
          <TableContainer style={{ marginTop: '30px', width: '40%', maxHeight: 440 }} component={Paper}>
            <Table sx={{ width: '100%' }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Previsões</TableCell>
                  <TableCell align='left'>Produto</TableCell>
                  <TableCell align='center'>Status</TableCell>
                  <TableCell align='center'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataValues.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row" align='left'>
                      {`${row.month} ${row.year}`}
                    </TableCell>
                    <TableCell align='left'>{row.metaSales ? row.metaSales : 0}</TableCell>
                    <TableCell><StatusView status={row.isActive} /></TableCell>
                    <TableCell>
                      <MoreMenu
                        edit={() => edit(row)}
                        delete={async () => delet(String(row._id))}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

export default TableSalesGoals
