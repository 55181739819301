/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  Button as MuiButton,
  CircularProgress,
  Stack,
  Chip
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { _changePage } from 'pages/home/slices/home.slice'
import Button from 'components/form/Button'
import BookingFormItem from '../booking/BookingFormItem'
import { toast } from 'react-toastify'
import { httpClient } from 'config/httpClient'
import { RootState } from 'core/store'

const color: any = {
  PENDENTE: '#ce6c2b',
  APROVADO: '#72CE2B',
  REPROVADO: '#EE207A'
}

const BookingForm: React.FC = () => {
  const params = useParams()
  const { loginData } = useSelector((state: RootState) => state.login)
  const { state }: any = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [booking, setBooking] = useState<any>(state?.item)
  const [open, setOpen] = useState<boolean>(false)
  const [openApprove, setOpenApprove] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingBooking, setLoadingBooking] = useState<boolean>(true)

  console.warn('TOTAL', state?.item)

  useEffect(() => {
    dispatch(
      _changePage({
        name: 'Book',
        description: 'Anexe e aprove o orçamento do ferramental da FT',
        icon: 'shopping_bag',
        route: 'purchaseRequests/bookingForm',
        cod: 'MO'
      })
    )
  }, [])

  const loadBooking = useCallback(async () => {
    setLoadingBooking(true)
    try {
      const { data } = await httpClient.get(
        `bookings/pedido/${state?.item?._id}`
      )

      setBooking((state) => ({ ...state, booking: data }))
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setLoadingBooking(false)
    }
  }, [state?.item?._id])

  useEffect(() => {
    void loadBooking()
  }, [state?.item?._id])

  const handleSolicitation = async (pedidoId: string) => {
    setLoading(true)

    try {
      await httpClient.post('/bookings', { pedido: pedidoId, status: 'PENDENTE' })
      setOpen(true)
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleApproveSolicitation = async (pedidoId: string) => {
    setLoading(true)

    try {
      await httpClient.post('/bookings', { pedido: pedidoId, status: 'APROVADO' })
      setOpenApprove(true)
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    navigate('/home/purchaseRequests', { state: { tab: state.tab } })
  }

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1', flexGrow: 1 }}>
        <BreadcrumbsComponent
          breadcrumbs={[
            { text: 'Pedido de compra', url: '/home/purchaseRequests' },
            { text: 'Book' }
          ]}
        />
        <Stack direction="row">
          <h4>Book</h4>
          {booking?.booking?.status && (
            <Chip
              label={booking?.booking?.status}
              size="small"
              variant="outlined"
              sx={{
                marginLeft: 2,
                borderColor: color[booking?.booking?.status],
                color: color[booking?.booking?.status]
              }}
            />
          )}
        </Stack>

        {loadingBooking && (
          <Box display="flex" alignItems="center" justifyContent='center' height={200} width="100%">
            <CircularProgress size={24} />
          </Box>
        )}

        {!loadingBooking &&
          booking?.pedidoFts?.map((pedidoFT, index) => (
            <BookingFormItem key={index} pedidoFT={pedidoFT} bookFinished={booking?.booking?.status === 'APROVADO'} recorrente={state?.item?.isRecorrente} />
          ))}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '40px'
          }}
        >
          {/* <Stack marginRight={'auto'}>
          <Button
                type="button"
                mold='primary'
                style={{
                  marginRight: '10px',
                  // backgroundColor: '#1AB1B0',
                  // borderColor: '#1AB1B0',
                  color: 'white'
                }}
                loading={loading}
                disabled={loading}
                onClick={() => {
                  void handleSolicitation(state?.item?._id)
                }}
              >
                Assinar Book
              </Button>
          </Stack> */}
          {loginData?.tokenData?.profile?.includes('CONTROLADORIA') && !loadingBooking &&
            (booking?.booking?._id ||
              booking?.booking?.status === 'PENDENTE') && (
              <Button
                type="button"
                style={{
                  marginRight: '10px',
                  backgroundColor: '#1AB1B0',
                  borderColor: '#1AB1B0',
                  color: 'white'
                }}
                loading={loading}
                disabled={loading}
                onClick={() => {
                  void handleApproveSolicitation(state?.item?._id)
                }}
              >
                Aprovar Book
              </Button>
            )}

          {!loadingBooking &&
            (!booking?.booking?._id ||
              booking?.booking?.status === 'REPROVADO' ||
              booking?.booking?.status === 'ENVIAR') && (
              <Button
                type="button"
                style={{
                  marginRight: '10px',
                  backgroundColor: '#1AB1B0',
                  borderColor: '#1AB1B0',
                  color: 'white'
                }}
                loading={loading}
                disabled={loading}
                onClick={() => {
                  void handleSolicitation(state?.item?._id)
                }}
              >
                Solicitar aprovação da controladoria
              </Button>
            )}
          <Button
            mold="primary"
            type="button"
            onClick={() => navigate('/home/purchaseRequests', { state: { tab: state.tab } })}
            disabled={loading}
          >
            Voltar
          </Button>
        </div>
      </Box>

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 0,
            paddingX: 2,
            paddingY: 1,
            paddingRight: 4
          }
        }}
      >
        <Alert
          variant="outlined"
          severity="success"
          sx={{ border: 'none', color: 'inherit' }}
        >
          <AlertTitle>Enviado com sucesso</AlertTitle>O perfil Controladoria
          será notificado.
        </Alert>
        <DialogActions>
          <MuiButton
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{ borderRadius: 0, minWidth: 'auto' }}
          >
            OK
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleClose}
        open={openApprove}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 0,
            paddingX: 2,
            paddingY: 1,
            paddingRight: 4
          }
        }}
      >
        <Alert
          variant="outlined"
          severity="success"
          sx={{ border: 'none', color: 'inherit' }}
        >
          <AlertTitle>Aprovado com sucesso</AlertTitle>.
        </Alert>
        <DialogActions>
          <MuiButton
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{ borderRadius: 0, minWidth: 'auto' }}
          >
            OK
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BookingForm
