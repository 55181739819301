/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import { Notifications } from '@mui/icons-material'
import { useQuery } from 'react-query'
import { getCommunications } from 'services/communication/CommunicationService'
import moment from 'moment'
import Button from 'components/form/Button'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'

export const MenuNotifications: React.FC = () => {
  const { loginData } = useSelector((state: RootState) => state.login)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [communications, setCommunications] = React.useState<any[]>([])
  const [newMessagesTotal, setNewMessagesTotal] = React.useState(0)

  const { data, status, refetch, isFetching } = useQuery(
    'communications',
    getCommunications,
    {
      refetchOnWindowFocus: true,
      cacheTime: 5 * 60 * 1000,
      staleTime: 4 * 60 * 1000
    }
  )

  useEffect(() => {
    setCommunications(
      data?.filter(
        (item) =>
          !!item?.profiles?.find(
            (profile) => profile === loginData?.tokenData?.profileId
          )
      ) ?? []
    )

    if (data?.length) {
      setNewMessagesTotal(data?.reduce((total: number, message: any) => {
        const findProfile = message?.profiles?.find(
          (profile) => profile === loginData?.tokenData?.profileId
        )
        if (!message?.isRead && !!findProfile) {
          return total + 1
        }
        return total
      }, 0))
    }
  }, [data, isFetching])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    refetch()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label="message"
        component="span"
        onClick={handleClick}
        style={{ position: 'relative' }}
      >
        <Notifications htmlColor="#4060E6" style={{ alignSelf: 'center' }} />
        {newMessagesTotal > 0 && (
          <Chip
            label={newMessagesTotal > 99 ? '99' : newMessagesTotal}
            sx={{
              borderRadius: '50%',
              position: 'absolute',
              right: 6,
              top: 6,
              zIndex: 6,
              background: '#EE207A',
              color: '#FFF',
              border: '1px solid #FFF',
              padding: 0,
              height: 16,
              width: 16,
              '& span': {
                padding: 0,
                fontSize: 10
              }
            }}
          />
        )}
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuList dense>
          <Paper sx={{ width: 420, boxShadow: 'none' }}>
            {!communications?.length && status === 'loading' && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={100}
              >
                <CircularProgress size={24} />
              </Box>
            )}

            {!communications?.length && status !== 'loading' && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={100}
              >
                <Typography component="span" variant="body2">
                  Nenhuma notificação encontrada.
                </Typography>
              </Box>
            )}

            {communications?.map((item) => (
              <React.Fragment key={item?._id}>
                <MenuItem
                  onClick={() => {
                    handleClose()
                    navigate('/home/notifications')
                  }}
                >
                  <Stack direction="row" sx={{ width: '100%' }}>
                    <Avatar>{item?.code}</Avatar>
                    <Stack pl={2} sx={{ flex: 1, width: '100%' }}>
                      <ListItemText sx={{ whiteSpace: 'normal' }}>
                        {item?.message}
                      </ListItemText>
                      {!item?.isRead && (
                        <ListItemText
                          sx={{
                            '& span': { fontSize: '0.75rem !important' },
                            color: '#3699FF'
                          }}
                        >
                          Nova
                        </ListItemText>
                      )}
                    </Stack>
                    <ListItemText
                      sx={{ maxWidth: 100, marginLeft: 2, textAlign: 'right' }}
                    >
                      <Typography component="span" variant="body2" whiteSpace='break-spaces'>
                        {moment(item?.createdAt).fromNow()}
                      </Typography>
                    </ListItemText>
                  </Stack>
                </MenuItem>
                <Divider />
              </React.Fragment>
            ))}
          </Paper>
        </MenuList>

        {!!communications?.length && (
          <Stack
            justifyContent="center"
            alignItems="center"
            width="100%"
            padding={1}
          >
            <Button
              mold="primary"
              onClick={() => {
                handleClose()
                navigate('/home/notifications')
              }}
            >
              Ir para Notificações
            </Button>
          </Stack>
        )}
      </Menu>
    </>
  )
}
