/* eslint-disable @typescript-eslint/no-unused-vars */
import { FileUploadOutlined } from '@mui/icons-material'
import { CircularProgress, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from 'components/form/Button'
import { httpClient } from 'config/httpClient'
import { RootState } from 'core/store'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

interface Props {
  id: string
  open: boolean
  onClose: () => void
  onCloseSuccess: () => void
  signature: 'setorVendas' | 'clicheria' | 'desenvolvimento' | 'compras' | 'gerenciaComercial' | 'coordenador'
}

export function ModalSignaturePurchaseRequest({ id, open, onClose, onCloseSuccess, signature }: Props) {
  const { loginData } = useSelector((state: RootState) => state.login)
  console.log(loginData?.tokenData)
  const [currentFile, setCurrentFile] = useState<File>()
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    resetForm()
    onCloseSuccess()
  }

  const handleImagePicker = (file: any) => {
    if (!file.target?.files[0]) return

    if (file.target?.files[0]?.size > 4100000) {
      toast.warning('O arquivo não pode ser maior que 4mb.')
      return
    }

    setCurrentFile(file.target?.files[0])

    const reader = new FileReader()
    reader.readAsDataURL(file.target.files[0])
    reader.onload = function () {
      const image = new Image()
      image.src = reader.result?.toString() ?? ''

      // Aqui temos a sua imagem convertida em string em base64.
      const result: any = reader.result
      console.log('ARQUIVO', result)
      setFieldValue('file', result)
    }
  }

  const { resetForm, values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      signature: signature ?? '',
      file: ''
    },
    onSubmit(values) {
      if (!values.file) {
        toast.warning('Selecione um arquivo.')
        return
      }

      setLoading(true)

      const body = { ...values, user: loginData?.tokenData?.sub }
      httpClient
      .post(`bookings/purchase-request/signature/${id}`, body)
      .then(() => {
        toast.success('Assinado com sucesso')
        handleClose()
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
        setLoading(false)
      })
    }
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="assinatura-dialog-title"
      aria-describedby="assinatura-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Anexar assinatura'}
      </DialogTitle>
      <DialogContent>
        <Stack component='form' spacing={3} onSubmit={handleSubmit}>
        <Box
          component="label"
          htmlFor={'file-upload'}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px 24px',
            border: '1px dashed #0075E7',
            borderRadius: 5,
            color: '#5E5D5D',
            marginTop: 2,
            minHeight: 150,
            width: '100%',
            maxWidth: '600px !important'
          }}
        >
          <FileUploadOutlined fontSize="large" />
          <Typography component="span" variant="body2">
            Clique para selecionar um arquivo.
          </Typography>
          <Typography
            component="span"
            variant="body2"
            sx={{
              padding: '4px 8px',
              borderRadius: 1,
              color: '#FFFFFF',
              backgroundColor: '#0075E7'
            }}
          >
            Selecione o arquivo
          </Typography>
          <input
            id={'file-upload'}
            type="file"
            accept="image/png, image/jpeg, application/pdf, application/octet-stream"
            hidden
            onChange={handleImagePicker}
            disabled={loading}
          />
        </Box>

          <Stack direction='row' spacing={4} justifyContent='space-between'>
            <Button onClick={onClose} disabled={loading}>CANCELAR</Button>
            <Button mold={loading ? undefined : 'primary'} type='submit' disabled={!values.file || loading}>
              {loading ? <CircularProgress size={24} /> : 'ASSINAR'}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>

  )
}
