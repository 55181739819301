/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  TablePagination,
  TextField,
  Typography
} from '@mui/material'
import { Add, ArrowRight, ChevronRight, Delete } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { httpClient, httpLN } from 'config/httpClient'
import { Form, Formik, useFormik } from 'formik'
import { onlyNumbers } from 'helpers/stringHelper'
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab'
import {
  getCollaborators,
  getIe
} from 'services/customerControl/CustomerControlServices'
import { useNavigate } from 'react-router-dom'
import {
  getCompositions,
  getFechamentos
} from 'services/portfolio/PortfolioService'
import ImportantView from 'components/view/ImportantView'
import { StyledDropZone } from 'react-drop-zone'
import { FileType, UploadDocsPreFT } from './UploadDocsPreFT'
import { getPromiseBase64 } from 'utils/base64'

interface Props {
  openModal: boolean
  selectedItem: any
  seller: any
  clients: any[]
  noEditable: boolean
  handleCloseModal: () => void
}

export function ModalPreFTPortfolioPlate({
  openModal,
  selectedItem,
  seller,
  clients,
  noEditable,
  handleCloseModal
}: Props) {
  // console.log('🚀 ~ file: ModalPreFTPortfolioPlate.tsx ~ line 60 ~ item', item)
  const navigate = useNavigate()

  const [composicao, setComposicao] = useState<any[]>([])
  const [fechamentos, setFechamentos] = useState<any[]>([])

  const [collaborators, setCollaborators] = useState<any[]>([])
  const [loadingCollaborators, setLoadingCollaborators] = useState(false)
  const [company, setCompany] = useState<any>()
  const [ies, setIes] = useState<any[]>([])

  const [selectedFile, setSelectedFile] = useState<File>()
  const [uploadFiles, setUploadFiles] = useState<any[]>([])

  const fetchAll = useCallback(async () => {
    getCompositions()
      .then((data) => {
        // console.log('TRIMBOX Composição', data)
        setComposicao(data ?? [])
      })
      .catch((error) => {
        console.warn('Error:', error)
      })
    getFechamentos()
      .then((data) => {
        // console.log('TRIMBOX Fechamento', data)
        setFechamentos(data ?? [])
      })
      .catch((error) => {
        console.log('Error:', error)
      })
  }, [])

  useEffect(() => {
    void fetchAll()
  }, [])

  const handleLoadIes = useCallback(async (companyId) => {
    getIe(companyId)
      .then((response) => {
        setIes(response?.data ?? [])
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }, [])

  const handleLoadCollaborator = useCallback(async (companyId) => {
    setLoadingCollaborators(true)
    getCollaborators(companyId)
      .then((response) => {
        setCollaborators(response?.data ?? [])
        setLoadingCollaborators(false)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
        setLoadingCollaborators(false)
      })
  }, [])

  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    resetForm
  } = useFormik({
    onSubmit: async (dataValues: any) => {
      const prepareData = {
        qtd: dataValues.qtd,
        status: dataValues.status,
        type: dataValues.type,
        formulariochapa: dataValues.formulariochapa,
        client: dataValues.client,
        ie: dataValues.ie,
        collaborator: dataValues.collaborator,
        seller: dataValues.seller,
        preftplate: {
          extraData: dataValues.preftplate.extraData ?? '',
          needSample: dataValues.preftplate.needSample ?? false,
          amountSample: dataValues.preftplate.amountSample ?? '',
          grammage: dataValues.preftplate.grammage ?? '',
          monthConsumption: dataValues.preftplate.monthConsumption ?? '',

          // id_Importacao: '',
          // TipoABNT: '',
          Referencia: dataValues.preftplate.Referencia ?? '',
          LarguraInterna: dataValues.preftplate.LarguraInterna ?? '', // number
          ComprimentoInterno: dataValues.preftplate.ComprimentoInterno ?? '', // number
          AlturaInterna: dataValues.preftplate.AlturaInterna ?? '', // number
          Composicao: dataValues.preftplate.Composicao ?? {
            composicao: '',
            onda: '',
            onda1: '',
            onda2: '',
            onda3: '',
            onda4: '',
            onda5: '',
            papel1: '',
            papel2: '',
            papel3: '',
            papel4: '',
            papel5: '',
            nomeComercial: '',
            mullen: undefined, // number
            crushEnd: undefined, // number
            gramatura: undefined, // number
            crush: undefined, // number
            preco: undefined, // number
            coluna: undefined, // number
            espessura: undefined // number
          },
          Fechamento: dataValues.preftplate.Fechamento ?? {
            fechamento: '',
            descricao: '',
            qtdEspessuras: undefined // number
          },
          ExigeLaudo: dataValues.preftplate.ExigeLaudo ?? false, // boolean
          // TipoObs1: '',
          Obs1: dataValues.preftplate.Obs1 ?? '',
          // PacotesLargura: undefined, // number
          // PacotesComprimento: undefined, // number
          // PacotesAltura: undefined, // number
          Amarrado: dataValues.preftplate.Amarrado ?? false, // boolean
          Paletizado: dataValues.preftplate.Paletizado ?? false // boolean
          // CodCliente: ''
        }
      }

      console.log('prepareData', prepareData)

      try {
        const response = await httpClient.post('/prefts', prepareData)

        if (response.data?._id && uploadFiles?.length) {
          // const formData = new FormData()

          // formData.append('file', selectedFile)

          // const headers = {
          //   'Content-Type': 'multpart/form-data'
          // }

          // await httpClient.put(`/prefts/${response.data._id}/docs`, {files: uploadFiles})
          await httpClient.put(`/prefts/${response.data._id}/multi-docs`, {
            files: uploadFiles
          })
        }

        toast.success('Solicitação de chapa criada com sucesso.')

        navigate('/home/ft')
      } catch (error: any) {
        toast.error(error?.response?.data?.message)
      }
    },
    // validationSchema: validationFormFtBoxSchema,
    enableReinitialize: true,
    initialValues: {
      qtd: '',
      status: 'NOVO',
      type: 'CHAPA',
      formulariochapa: selectedItem?._id ?? null,
      client: null,
      ie: null,
      collaborator: null,
      seller: seller?._id ?? null,
      preftplate: {
        extraData: '',
        needSample: false,
        amountSample: '',
        grammage: '',
        monthConsumption: '',

        // id_Importacao: '',
        // TipoABNT: '',
        Referencia: selectedItem?.Referencia ?? '',
        LarguraInterna: selectedItem?.LarguraInterna ?? '', // number
        ComprimentoInterno: selectedItem?.ComprimentoInterno ?? '', // number
        AlturaInterna: selectedItem?.AlturaInterna ?? '', // number
        Composicao: selectedItem?.Composicao ?? {
          composicao: '',
          onda: '',
          onda1: '',
          onda2: '',
          onda3: '',
          onda4: '',
          onda5: '',
          papel1: '',
          papel2: '',
          papel3: '',
          papel4: '',
          papel5: '',
          nomeComercial: '',
          mullen: undefined, // number
          crushEnd: undefined, // number
          gramatura: undefined, // number
          crush: undefined, // number
          preco: undefined, // number
          coluna: undefined, // number
          espessura: undefined // number
        },
        Fechamento: selectedItem?.Fechamento ?? {
          fechamento: '',
          descricao: '',
          qtdEspessuras: undefined // number
        },
        ExigeLaudo: selectedItem?.ExigeLaudo ?? false, // boolean
        // TipoObs1: '',
        Obs1: selectedItem?.Obs1 ?? '',
        // PacotesLargura: undefined, // number
        // PacotesComprimento: undefined, // number
        // PacotesAltura: undefined, // number
        Amarrado: selectedItem?.Amarrado ?? false, // boolean
        Paletizado: selectedItem?.Paletizado ?? false // boolean
        // CodCliente: ''
      }
    }
  })

  // const handleSelectFile = (file: File) => {
  //   if (isSubmitting) {
  //     return
  //   }

  //   if (
  //     !file?.type ||
  //     (!file?.type?.includes('image') &&
  //       !file?.type?.includes('application/pdf'))
  //   ) {
  //     toast.warn('Selecione uma imagem ou PDF.')
  //     return
  //   }

  //   setSelectedFile(file)
  // }

  const handleFiles = async (files: FileType[]) => {
    const filesInBase64: any[] = []

    for (const item of files) {
      if (item?.file) {
        const fileBase64 = await getPromiseBase64(item.file)

        filesInBase64.push({
          ...item,
          file: fileBase64
        })
      } else {
        filesInBase64.push(item)
      }
    }

    setUploadFiles(filesInBase64)
  }

  return (
    <Dialog
      open={openModal}
      // onClose={handleCloseModal}
      fullWidth
      maxWidth="md"
      scroll="paper"
      aria-labelledby="PreFTPortfolioPlate"
      aria-describedby="PreFTPortfolioPlate-description"
    >
      <DialogTitle id="PreFTPortfolioPlate">Pré-FT Chapa</DialogTitle>
      <Box component="form" onSubmit={handleSubmit}>
        {isSubmitting && <LinearProgress />}
        <DialogContent dividers>
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="body2" component="span">
              Este é um formulário de chapa. Você pode editar as informações e
              solicitar um projeto
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                noOptionsText="Sem opções"
                loading={isSubmitting}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                getOptionLabel={(option) =>
                  option.isBlocked || !option.isActive
                    ? `${option.name} - [BLOQUEADO]`
                    : option.name
                }
                id="combo-box-clients"
                options={clients.map((row) => ({
                  ...row,
                  label: row.name,
                  id: row._id
                }))}
                onChange={(_, selectedValue: any | null) => {
                  void setFieldValue('client', selectedValue?._id)
                  void handleLoadCollaborator(selectedValue?._id)
                  void setFieldValue('collaborator', null)
                  setCollaborators([])
                  setCompany(selectedValue)
                  if (selectedValue?.pj?.cnpj) {
                    void handleLoadIes(selectedValue?._id)
                  } else {
                    setIes([])
                    void setFieldValue('ie', null)
                  }
                }}
                getOptionDisabled={(option) =>
                  option.isBlocked || !option.isActive
                }
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Solicitador por"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                disabled
                fullWidth
                id="standard-basic"
                label="Referência"
                value={values?.preftplate?.Referencia?.toUpperCase()}
              />
            </Grid>

            {company?.pj?.cnpj && (
              <Grid item xs={6}>
                <Autocomplete
                  noOptionsText="Sem opções"
                  loading={isSubmitting}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => option?.codeLn}
                  id="combo-box-ie"
                  options={ies.map((row) => ({
                    ...row,
                    label: row.name,
                    id: row._id
                  }))}
                  onChange={(_, selectedValue: any | null) => {
                    void setFieldValue('ie', selectedValue?._id)
                  }}
                  getOptionDisabled={(option) =>
                    option.isBlocked || !option.isActive
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Parceiro de negócio"
                      required
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <Autocomplete
                noOptionsText="Sem opções"
                loading={isSubmitting}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                getOptionLabel={(option) => option?.name}
                id="combo-box-collaborator"
                options={collaborators.map((row) => ({
                  ...row,
                  label: row.name,
                  id: row._id
                }))}
                onChange={(_, selectedValue: any | null) => {
                  void setFieldValue('collaborator', selectedValue?._id)
                }}
                getOptionDisabled={(option) => !option.isActive}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Representante"
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                Especificações
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                label="Composição"
                variant="standard"
                value={values?.preftplate?.Composicao?.composicao ?? ''}
                fullWidth
                disabled
                autoComplete="off"
              />
              {/* <Autocomplete
                disabled={noEditable}
                noOptionsText="Sem opções"
                disablePortal
                options={
                  composicao.map((item) => ({
                    label: item.composicao,
                    ...item
                  })) ?? []
                }
                fullWidth
                getOptionLabel={(option) => option.composicao}
                value={values?.preftplate?.Composicao}
                defaultValue={composicao?.find(
                  (item) =>
                    item.composicao ===
                    values?.preftplate?.Composicao?.composicao
                )}
                onChange={(e, composicaoItem: any) => {
                  console.log('composicao', composicaoItem)
                  setFieldValue('preftplate.Composicao', composicaoItem)

                  const widthD = values?.preftplate?.LarguraInterna
                    ? `${values?.preftplate?.LarguraInterna} `
                    : ''
                  const lengthD = values?.preftplate?.ComprimentoInterno
                    ? `x ${values?.preftplate?.ComprimentoInterno} `
                    : ''
                  const nomenclature = composicaoItem?.nomeComercial ?? ''

                  setFieldValue(
                    'preftplate.Referencia',
                    `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Composição"
                  />
                )}
              /> */}
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    label="Tipo de Onda"
                    variant="standard"
                    value={values?.preftplate?.Composicao?.onda ?? ''}
                    fullWidth
                    disabled
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Papel"
                    variant="standard"
                    value={`${
                      values?.preftplate?.Composicao?.papel1
                        ? values?.preftplate?.Composicao?.papel1 + ','
                        : ''
                    }${
                      values?.preftplate?.Composicao?.papel2
                        ? values?.preftplate?.Composicao?.papel2 + ','
                        : ''
                    }${
                      values?.preftplate?.Composicao?.papel3
                        ? values?.preftplate?.Composicao?.papel3 + ','
                        : ''
                    }${
                      values?.preftplate?.Composicao?.papel4
                        ? values?.preftplate?.Composicao?.papel4 + ','
                        : ''
                    }${
                      values?.preftplate?.Composicao?.papel5
                        ? values?.preftplate?.Composicao?.papel5 + ','
                        : ''
                    }`.slice(0, -1)}
                    fullWidth
                    disabled
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  {/* <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Nome Comercial"
                value={
                  values?.preftplate?.Composicao?.nomeComercial ??
                  ''
                }
              /> */}
                  {!!composicao?.length && (
                    <Autocomplete
                      noOptionsText="Sem opções"
                      loading={isSubmitting}
                      isOptionEqualToValue={(option, value) =>
                        option.nomeComercial === value.nomeComercial
                      }
                      getOptionLabel={(option) => option.nomeComercial}
                      options={composicao}
                      onChange={(_, selectedValue: any | null) => {
                        void setFieldValue(
                          'preftplate.Composicao',
                          selectedValue
                        )

                        const widthD = values?.preftplate?.LarguraInterna
                          ? `${values?.preftplate?.LarguraInterna} `
                          : ''
                        const lengthD = values?.preftplate?.ComprimentoInterno
                          ? `x ${values?.preftplate?.ComprimentoInterno} `
                          : ''
                        const nomenclature = selectedValue?.nomeComercial ?? ''

                        setFieldValue(
                          'preftplate.Referencia',
                          `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
                        )
                      }}
                      defaultValue={composicao?.find(
                        (item) =>
                          item.nomeComercial ===
                          values?.preftplate?.Composicao?.nomeComercial
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label="Nome Comercial"
                          required
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                Dimensões
              </Typography>
            </Grid>

            <Grid item xs={6} md={2}>
              <TextField
                disabled={noEditable}
                type="number"
                label="Comprimento Interno"
                variant="standard"
                value={values.preftplate?.ComprimentoInterno}
                error={Boolean(
                  errors.preftplate?.ComprimentoInterno &&
                    touched.preftplate?.ComprimentoInterno
                )}
                helperText={
                  (errors.preftplate?.ComprimentoInterno &&
                  touched.preftplate?.ComprimentoInterno
                    ? errors.preftplate?.ComprimentoInterno
                    : null) as React.ReactNode
                }
                onChange={(event) => {
                  const newValue = onlyNumbers(event.target.value)
                  setFieldValue(
                    'preftplate.ComprimentoInterno',
                    newValue ? Math.round(Number(newValue)) : ''
                  )

                  const widthD = values?.preftplate?.LarguraInterna
                    ? `${values?.preftplate?.LarguraInterna} `
                    : ''
                  const lengthD = newValue
                    ? `x ${Math.round(Number(newValue))} `
                    : ''
                  const nomenclature =
                    values?.preftplate?.Composicao?.nomeComercial ?? ''

                  setFieldValue(
                    'preftplate.Referencia',
                    `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
                  )
                }}
                autoComplete="off"
                fullWidth
                required
              />
            </Grid>
            <Grid item alignSelf="flex-end">
              +
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                disabled={noEditable}
                type="number"
                label="Largura Interna"
                variant="standard"
                value={values.preftplate.LarguraInterna}
                error={Boolean(
                  errors.preftplate?.LarguraInterna &&
                    touched.preftplate?.LarguraInterna
                )}
                helperText={
                  (errors.preftplate?.LarguraInterna &&
                  touched.preftplate?.LarguraInterna
                    ? errors.preftplate?.LarguraInterna
                    : null) as React.ReactNode
                }
                onChange={(event) => {
                  const newValue = onlyNumbers(event.target.value)
                  setFieldValue(
                    'preftplate.LarguraInterna',
                    newValue ? Math.round(Number(newValue)) : ''
                  )

                  const widthD = newValue
                    ? Math.round(Number(newValue)) + ' '
                    : ''
                  const lengthD = values?.preftplate?.ComprimentoInterno
                    ? `x ${values?.preftplate?.ComprimentoInterno} `
                    : ''
                  const nomenclature =
                    values?.preftplate?.Composicao?.nomeComercial ?? ''

                  setFieldValue(
                    'preftplate.Referencia',
                    `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
                  )
                }}
                autoComplete="off"
                fullWidth
                required
              />
            </Grid>
            <Grid item alignSelf="flex-end">
              +
            </Grid>
            <Grid item xs={6} md={2}>
              <TextField
                disabled={noEditable}
                type="number"
                label="Altura Interna"
                variant="standard"
                value={values.preftplate?.AlturaInterna}
                error={Boolean(
                  errors.preftplate?.AlturaInterna &&
                    touched.preftplate?.AlturaInterna
                )}
                helperText={
                  (errors.preftplate?.AlturaInterna &&
                  touched.preftplate?.AlturaInterna
                    ? errors.preftplate?.AlturaInterna
                    : null) as React.ReactNode
                }
                onChange={(event) => {
                  const newValue = onlyNumbers(event.target.value)
                  setFieldValue(
                    'preftplate.AlturaInterna',
                    newValue ? Math.round(Number(newValue)) : ''
                  )

                  const widthD = values?.preftplate?.LarguraInterna
                    ? `${values?.preftplate?.LarguraInterna} `
                    : ''
                  const lengthD = values?.preftplate?.ComprimentoInterno
                    ? `x ${values?.preftplate?.ComprimentoInterno} `
                    : ''
                  const nomenclature =
                    values?.preftplate?.Composicao?.nomeComercial ?? ''

                  setFieldValue(
                    'preftplate.Referencia',
                    `CH ${widthD}${lengthD}${nomenclature}`.toUpperCase()
                  )
                }}
                autoComplete="off"
                fullWidth
                required
              />
            </Grid>
            <Grid
              item
              md={1}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end'
                }}
              >
                =
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <TextField
                type="number"
                label="Total (GRM)"
                variant="standard"
                value={
                  Number(values?.preftplate?.LarguraInterna ?? 0) +
                  Number(values?.preftplate?.ComprimentoInterno ?? 0) +
                  Number(values?.preftplate?.AlturaInterna ?? 0)
                }
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Autocomplete
                disabled={noEditable}
                noOptionsText="Sem opções"
                disablePortal
                options={
                  fechamentos.map((item) => ({
                    label: item.fechamento,
                    ...item
                  })) ?? []
                }
                fullWidth
                getOptionLabel={(option) => option.fechamento}
                value={values?.preftplate?.Fechamento}
                defaultValue={fechamentos?.find(
                  (item) =>
                    item.fechamento ===
                    values?.preftplate?.Fechamento?.fechamento
                )}
                onChange={(e, fechamentoItem: any) => {
                  setFieldValue('preftplate.Fechamento', fechamentoItem)
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Fechamento"
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="standard"
                fullWidth
                id="standard-basic"
                label="Consumo do mês"
                value={values.preftplate.monthConsumption}
                onChange={(e) =>
                  setFieldValue(
                    'preftplate.monthConsumption',
                    onlyNumbers(e.target.value ?? '')
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  )
                }}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="standard"
                fullWidth
                id="standard-basic"
                label="Gramatura"
                value={values.preftplate.grammage}
                onChange={(e) =>
                  setFieldValue(
                    'preftplate.grammage',
                    onlyNumbers(e.target.value ?? '')
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  )
                }}
                required
              />
            </Grid>
            {/*
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                id="standard-basic"
                label="Quantidade"
                value={values.qtd}
                onChange={(e) =>
                  setFieldValue('qtd', onlyNumbers(e.target.value ?? ''))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Un</InputAdornment>
                  )
                }}
                required
              />
            </Grid> */}

            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                id="standard-basic"
                label="Dados Adicionais"
                value={values?.preftplate?.extraData}
                onChange={handleChange('preftplate.extraData')}
                multiline
                minRows={3}
              />
            </Grid>

            <Grid item xs={12}>
              <UploadDocsPreFT
                urls={[]}
                disabled={isSubmitting}
                onChange={(files) => handleFiles(files)}
              />
            </Grid>
            {/*
            <Grid item xs={12}>
              <Typography>Anexar arquivo</Typography>
            </Grid>
            {selectedFile && (
              <Grid item xs={12}>
                <Typography
                  component="a"
                  href={URL.createObjectURL(selectedFile)}
                  target="_blank"
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                >
                  Visualizar arquivo
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  marginRight: '30px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1px',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: '8px',
                        border: false ? '1px solid red' : '0px solid red'
                      }}
                    >
                      <StyledDropZone
                        children={
                          selectedFile
                            ? '✔ Arquivo anexado!'
                            : 'Selecione ou arraste seu arquivo aqui'
                        }
                        onDrop={(file, text) => handleSelectFile(file)}
                      />
                    </div>
                    <ImportantView
                      style={{ marginTop: '20px' }}
                      title="Importante"
                      content="Tamanho máximo 2mb"
                    />
                  </div>
                </div>
              </div>
            </Grid> */}
          </Grid>
        </DialogContent>

        <DialogActions sx={{ padding: 3 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseModal}
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            {!isSubmitting ? 'Solicitar projeto' : 'Aguarde...'}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
