import { InfoOutlined } from '@mui/icons-material'
import React from 'react'

function ImportantView(props: any) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', ...props.style }}>
      <InfoOutlined color="primary" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '10px'
        }}
      >
        <div style={{ fontSize: '13px' }}>
          {props.title}
        </div>
        <div style={{ fontSize: '11px' }}>
          {props.content}
        </div>
      </div>
    </div>
  )
}

export default ImportantView
