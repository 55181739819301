import React, { useEffect } from 'react'
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  CssBaseline,
  Link,
  TextField,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Button from 'components/form/Button'
import moment from 'moment'
import RHTable from './components/RHTable'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import AddIcon from '@mui/icons-material/Add'
import RegisterRH from './components/RegisterRH'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { ptBR } from 'date-fns/locale'
import { httpClient } from 'config/httpClient'
import EditRH from './components/EditRH'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { GoBack } from 'components/form/GoBack'

const ReportRH: React.FC = () => {
  const navigate = useNavigate()

  const [date, setDate] = React.useState<Date | null>(new Date())
  const [dateSearch, setDateSearch] = React.useState<Date | null>(new Date())
  const [load, setLoad] = React.useState(true)
  const [rhData, setRhData] = React.useState([])

  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const [openDialogEdit, setOpenDialogEdit] = React.useState<boolean>(false)

  const [dataEdit, setDataEdit] = React.useState<any>()

  useEffect(() => {
    loadData()
  }, [dateSearch])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setLoad(false)
    const day =
      moment(dateSearch).format('DD') > moment().format('DD') &&
      moment(dateSearch).format('MM') === moment().format('MM')
        ? moment().format('DD/MM/YYYY')
        : moment(dateSearch).format('DD/MM/YYYY')
    httpClient
      .get(
        `rh/report?day=${day}&month=${moment(dateSearch).format(
          'M'
        )}&year=${moment(dateSearch).format('YYYY')}`
      )
      .then((response) => {
        setRhData(response.data)
        setLoad(true)
      })
  }

  const handleChangeDate = () => {
    setDateSearch(date)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setOpenDialogEdit(false)
    setDataEdit(false)
  }

  const handleRegister = () => {
    setOpenDialog(false)
    setOpenDialogEdit(false)
    setDataEdit(false)
    loadData()
  }

  const reloadRH = () => {
    loadData()
  }

  return (
    <div>
      <CssBaseline />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}
      >
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="text.primary"
              onClick={() => {
                navigate('/home/managementControl')
              }}
            >
              Gerencial
            </Link>
            <Typography>Planejado Dia</Typography>
          </Breadcrumbs>
          <h4>Planejado Dia: {moment(date).format('MMMM YYYY')}</h4>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center'
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              mask="__/__/____"
              label="Data"
              value={date}
              onChange={(newValue) => {
                setDate(newValue)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Button
            loading={!load}
            onClick={handleChangeDate}
            style={{ height: '40px', width: '100px', marginLeft: '20px' }}
            mold="primary"
          >
            Gerar
          </Button>
        </div>
      </div>
      <Can module={MODULES.GERENCIAL} permissions={[PERMISSIONS.CREATE]}>
        <LightTooltip
          title="Cadastrar"
          placement="top"
          onClick={() => {
            setOpenDialog(true)
          }}
        >
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <AddIcon />
          </ButtonHeader>
        </LightTooltip>
      </Can>

      {load
? (
        <div>
          <RHTable
            edit={() => setOpenDialogEdit(true)}
            dataEdit={(data) => {
              setDataEdit(data)
            }}
            data={rhData}
            date={date}
            reload={reloadRH}
          />
        </div>
      )
: (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <GoBack forRoute="/home/managementControl" />

      {openDialog && (
        <GenericDialog
          style={{ minHeight: '500px' }}
          open={openDialog}
          close={() => setOpenDialog(false)}
          content={
            <RegisterRH
              registered={handleRegister}
              closeDialog={handleCloseDialog}
              defaultDate={date}
            />
          }
        />
      )}

      {openDialogEdit && (
        <GenericDialog
          style={{ minHeight: '400px' }}
          open={openDialogEdit}
          close={() => setOpenDialogEdit(false)}
          content={
            <EditRH
              dataEdit={dataEdit}
              registered={handleRegister}
              closeDialog={handleCloseDialog}
            />
          }
        />
      )}
    </div>
  )
}

export default ReportRH
