/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button as MuiButton,
  InputAdornment,
  CircularProgress,
  Typography
} from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import AddIcon from '@mui/icons-material/Add'
import { useFormik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
// import { validationSchema } from 'types/validation/ValidationManagementControl'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import { getCustomer } from 'services/customerControl/customer-control.services'
import CustomerControlType from 'types/customerControl/CustomerControlType'
import { httpClient } from 'config/httpClient'
import moment from 'moment'
import { ptBR } from 'date-fns/locale'
import ClearIcon from '@mui/icons-material/Clear'
import { onlyNumbers } from 'helpers/stringHelper'
import Button from 'components/form/Button'
import { Tab, TabPanel, TabsList } from './previsionManagementForm.styles'
import alertSwalCustom from 'components/Alerts/ModalCustom'
import AutocompleteClients from './AutocompleteClients'

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

const FormEditManagementControl: React.FC = (props: any) => {
  const { state }: any = useLocation()
  const navigate = useNavigate()

  // const date = new Date()
  const date = moment()
    .year(state?.year)
    .month(months.findIndex((month) => month === state?.month) || 0)
    .date(1)

  const [loading, setLoading] = useState<boolean>(false)
  const [clients, setClients] = useState<CustomerControlType[]>([])
  const [products, setProducts] = useState<any[]>([])
  const [previsions, setPrevisions] = React.useState<any>({
    coil: [],
    plate: [],
    box: []
  })
  const [coil, setCoil] = useState<string | null>(null)
  const [plate, setPlate] = useState<string | null>(null)
  const [box, setBox] = useState<string | null>(null)
  // const [sellers, setSellers] = useState<any[]>([])

  const [total, setTotal] = useState({
    coil: {
      peso: 0,
      rs: 0,
      rsKg: 0
    },
    plate: {
      peso: 0,
      rs: 0,
      rsKg: 0
    },
    box: {
      peso: 0,
      rs: 0,
      rsKg: 0
    }
  });

  useEffect(() => {
    // Função para calcular os totais
    const calculateTotals = () => {
      const coilTotal = { peso: 0, rs: 0, rsKg: 0 };
      const plateTotal = { peso: 0, rs: 0, rsKg: 0 };
      const boxTotal = { peso: 0, rs: 0, rsKg: 0 };

      // Calcular os totais para coil
      for (const item of previsions.coil) {
        coilTotal.peso += item.pesoKg;
        coilTotal.rs += item.previsionPrice;
      }
      coilTotal.rsKg = coilTotal.rs / coilTotal.peso;

      // Calcular os totais para plate
      for (const item of previsions.plate) {
        plateTotal.peso += item.pesoKg;
        plateTotal.rs += item.previsionPrice;
      }
      plateTotal.rsKg = plateTotal.rs / plateTotal.peso;

      // Calcular os totais para box
      for (const item of previsions.box) {
        boxTotal.peso += item.pesoKg;
        boxTotal.rs += item.previsionPrice;
      }
      boxTotal.rsKg = boxTotal.rs / boxTotal.peso;

      // Atualizar os valores totais
      setTotal({
        coil: coilTotal,
        plate: plateTotal,
        box: boxTotal
      });
    };

    calculateTotals(); // Chamar a função de cálculo inicialmente

    // Atualizar os totais sempre que os valores de previsions mudarem
    // Aqui, consideramos apenas o primeiro nível do objeto previsions
    // Se você precisar de uma verificação mais profunda, pode usar bibliotecas como o lodash
    setPrevisions((prevPrevisions) => {
      calculateTotals(); // Chamar a função de cálculo novamente
      return prevPrevisions;
    });
  }, [previsions?.coil, previsions?.box, previsions?.plate]);
console.log(previsions.coil)
  useEffect(() => {
    setLoading(true)
    getCustomer()
      .then((data) => setClients(data.docs))
      .catch(() => toast.error('Erro ao listar clientes'))

    httpClient
      .get('products')
      .then((response) => {
        setProducts(response.data)
        const coliId = response.data.find(
          (item) => item.name === 'Bobinas'
        )?._id
        const plateId = response.data.find(
          (item) => item.name === 'Chapas'
        )?._id
        const boxId = response.data.find((item) => item.name === 'Caixas')?._id

        setCoil(coliId)
        setPlate(plateId)
        setBox(boxId)

        console.log('STATEE', state)

        setPrevisions({
          coil: state?.previsions
            ?.filter((prevision) => prevision?.product === coliId)
            ?.map((item) => ({
              ...item,
              formId: item?._id,
              client: item?.client,
              seller: item?.seller,
              pesoKg: item?.pesoKg,
              previsionPrice: item?.previsionPrice,
              previsionPriceKg: item?.previsionPriceKg
            })) || [{ formId: Math.random() * (1000 - 1) + 1 }],
          plate: state?.previsions
            ?.filter((prevision) => prevision?.product === plateId)
            ?.map((item) => ({
              ...item,
              formId: item?._id,
              client: item?.client,
              seller: item?.seller,
              pesoKg: item?.pesoKg,
              previsionPrice: item?.previsionPrice,
              previsionPriceKg: item?.previsionPriceKg
            })) || [{ formId: Math.random() * (1000 - 1) + 1 }],
          box: state?.previsions
            ?.filter((prevision) => prevision?.product === boxId)
            ?.map((item) => ({
              ...item,
              formId: item?._id,
              client: item?.client,
              seller: item?.seller,
              pesoKg: item?.pesoKg,
              previsionPrice: item?.previsionPrice,
              previsionPriceKg: item?.previsionPriceKg
            })) || [{ formId: Math.random() * (1000 - 1) + 1 }]
        })
      })
      .catch(() => toast.error('Erro ao listar produtos'))
      .finally(() => {
        setLoading(false)
      })

    // httpClient
    //   .get('sellers')
    //   .then((data) => {
    //     console.log('vendedores', data)
    //     setSellers(data.data)
    //   })
    //   .catch(() => toast.error('Erro ao listar vendedores'))
  }, [state])

  const handleChangePrevisionCoil = (prevision: any) => {
    setPrevisions((state) => ({
      ...state,
      coil: state?.coil?.map((item) => {
        if (item.formId === prevision.formId) {
          if (prevision?.previsionPrice && item?.pesoKg) {
            const valor = prevision.previsionPrice / item.pesoKg
            return {
              ...item,
              ...prevision,
              isUpdate: !!item?._id,
              isCreate: !item?._id,
              product: item?.product || coil,
              previsionPriceKg: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            }
          }
          if (prevision?.pesoKg && item?.previsionPrice) {
            const valor = item.previsionPrice / prevision.pesoKg
            return {
              ...item,
              ...prevision,
              isUpdate: !!item?._id,
              isCreate: !item?._id,
              product: item?.product || coil,
              previsionPriceKg: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            }
          }
          if (item?.pesoKg && item?.previsionPrice) {
            const valor = item.previsionPrice / item.pesoKg
            return {
              ...item,
              ...prevision,
              isUpdate: !!item?._id,
              isCreate: !item?._id,
              product: item?.product || coil,
              previsionPriceKg: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            }
          }

          return {
            ...item,
            ...prevision,
            isUpdate: !!item?._id,
            isCreate: !item?._id,
            product: item?.product || coil,
            previsionPriceKg: undefined
          }
        }
        return item
      })
    }))
  }

  const handleChangePrevisionPlate = (prevision: any) => {
    setPrevisions((state) => ({
      ...state,
      plate: state?.plate?.map((item) => {
        if (item.formId === prevision.formId) {
          if (prevision?.previsionPrice && item?.pesoKg) {
            const valor = prevision.previsionPrice / item.pesoKg
            return {
              ...item,
              ...prevision,
              isUpdate: !!item?._id,
              isCreate: !item?._id,
              product: item?.product || plate,
              previsionPriceKg: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            }
          }
          if (prevision?.pesoKg && item?.previsionPrice) {
            const valor = item.previsionPrice / prevision.pesoKg
            return {
              ...item,
              ...prevision,
              isUpdate: !!item?._id,
              isCreate: !item?._id,
              product: item?.product || plate,
              previsionPriceKg: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            }
          }
          if (item?.pesoKg && item?.previsionPrice) {
            const valor = item.previsionPrice / item.pesoKg
            return {
              ...item,
              ...prevision,
              isUpdate: !!item?._id,
              isCreate: !item?._id,
              product: item?.product || plate,
              previsionPriceKg: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            }
          }

          return {
            ...item,
            ...prevision,
            isUpdate: !!item?._id,
            isCreate: !item?._id,
            product: item?.product || plate,
            previsionPriceKg: undefined
          }
        }
        return item
      })
    }))
  }

  const handleChangePrevisionBox = (prevision: any) => {
    setPrevisions((state) => ({
      ...state,
      box: state?.box?.map((item) => {
        if (item.formId === prevision.formId) {
          if (prevision?.previsionPrice && item?.pesoKg) {
            const valor = prevision.previsionPrice / item.pesoKg
            return {
              ...item,
              ...prevision,
              isUpdate: !!item?._id,
              isCreate: !item?._id,
              product: item?.product || box,
              previsionPriceKg: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            }
          }
          if (prevision?.pesoKg && item?.previsionPrice) {
            const valor = item.previsionPrice / prevision.pesoKg
            return {
              ...item,
              ...prevision,
              isUpdate: !!item?._id,
              isCreate: !item?._id,
              product: item?.product || box,
              previsionPriceKg: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            }
          }
          if (item?.pesoKg && item?.previsionPrice) {
            const valor = item.previsionPrice / item.pesoKg
            return {
              ...item,
              ...prevision,
              isUpdate: !!item?._id,
              isCreate: !item?._id,
              product: item?.product || box,
              previsionPriceKg: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            }
          }

          return {
            ...item,
            ...prevision,
            isUpdate: !!item?._id,
            isCreate: !item?._id,
            product: item?.product || box,
            previsionPriceKg: undefined
          }
        }
        return item
      })
    }))
  }

  const generateId = () => {
    return Math.random() * (1000 - 1) + 1
  }

  const handleAddPrevision = (product: string) => {
    const formId = generateId()

    if (product === 'coil') {
      setPrevisions((state) => ({
        ...state,
        coil: [...state.coil, { formId }]
      }))
    } else if (product === 'plate') {
      setPrevisions((state) => ({
        ...state,
        plate: [...state.plate, { formId }]
      }))
    } else if (product === 'box') {
      setPrevisions((state) => ({ ...state, box: [...state.box, { formId }] }))
    }
  }

  const handleRemovePrevision = (product: string, prevision: any) => {
    if (!prevision?._id) {
      if (product === 'coil') {
        setPrevisions((state) => ({
          ...state,
          coil: state.coil?.filter((item) => item.formId !== prevision.formId)
        }))
        if (previsions?.coil?.length <= 1) {
          const formId = generateId()
          setPrevisions((state) => ({ ...state, coil: [{ formId }] }))
        }
      } else if (product === 'plate') {
        setPrevisions((state) => ({
          ...state,
          plate: state.plate?.filter(
            (item) => item.formId !== prevision.formId
          )
        }))
        if (previsions?.plate?.length <= 1) {
          const formId = generateId()
          setPrevisions((state) => ({ ...state, plate: [{ formId }] }))
        }
      } else if (product === 'box') {
        setPrevisions((state) => ({
          ...state,
          box: state.box?.filter((item) => item.formId !== prevision.formId)
        }))
        if (previsions?.box?.length <= 1) {
          const formId = generateId()
          setPrevisions((state) => ({ ...state, box: [{ formId }] }))
        }
      }

      return
    }

    // axios
    //   .delete(`http://localhost:9003/api/v1/previsions/${prevision?._id}`)
    httpClient
      .delete(`previsions/${prevision?._id}`)
      .then(() => {
        if (product === 'coil') {
          setPrevisions((state) => ({
            ...state,
            coil: state.coil?.filter(
              (item) => item.formId !== prevision.formId
            )
          }))
          if (previsions?.coil?.length <= 1) {
            const formId = generateId()
            setPrevisions((state) => ({ ...state, coil: [{ formId }] }))
          }
        } else if (product === 'plate') {
          setPrevisions((state) => ({
            ...state,
            plate: state.plate?.filter(
              (item) => item.formId !== prevision.formId
            )
          }))
          if (previsions?.plate?.length <= 1) {
            const formId = generateId()
            setPrevisions((state) => ({ ...state, plate: [{ formId }] }))
          }
        } else if (product === 'box') {
          setPrevisions((state) => ({
            ...state,
            box: state.box?.filter((item) => item.formId !== prevision.formId)
          }))
          if (previsions?.box?.length <= 1) {
            const formId = generateId()
            setPrevisions((state) => ({ ...state, box: [{ formId }] }))
          }
        }
      })
      .catch((e) => {
        if (
          e.response.data.response.message !== null &&
          e.response.data.response.message !== undefined
        ) {
          toast.error(e.response.data.response.message)
        } else {
          toast.error('Erro ao deletar previsão')
        }
      })
  }

  const handleSubmit = (event: any) => {
    event?.preventDefault()

    if (!state?._id) return

    setLoading(true)

    const allPrevisions = [
      ...previsions?.coil,
      ...previsions?.plate,
      ...previsions?.box
    ]

    const body = {
      previsionForm: state._id,
      previsionsCreate: allPrevisions?.filter(
        (prevision) => prevision?.isCreate
      ),
      previsionsUpdate: allPrevisions?.filter(
        (prevision) => prevision?.isUpdate
      )
    }

    console.log(allPrevisions, body)
    console.log(JSON.stringify(body))

    httpClient.put(`previsions/form/${state?._id}`, body)
      // axios
      //   .put(`http://localhost:9003/api/v1/previsions/form/${state?._id}`, body)
      .then(() => {
        toast.success('Salvo com sucesso!')

        navigate('/home/managementControl/management', {
          state: undefined
        })
      })
      .catch((error) => {
        toast.error(error.response?.data?.message)
        setLoading(false)
      })
  }

  const handleCancel = () => {
    navigate('/home/managementControl/management')
  }

  return (
    <>
      <Box sx={{ display: 'grid', gridTemplateRows: 'max-content auto' }}>
        <Card>
          <CardContent>
            <Box
              sx={{ marginTop: '10px', fontSize: '16px', fontWeight: '600' }}
            >
              Novas Previsões
            </Box>

            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid
                  item
                  md={12}
                  style={{ marginTop: '10px', marginBottom: '24px' }}
                >
                  <Grid item xs={6} md={4} paddingLeft="20px">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ptBR}
                    >
                      <DatePicker
                        views={['year', 'month']}
                        inputFormat="MMMM yyyy"
                        label="Mês e Ano"
                        value={date}
                        onChange={(newValue) => {
                          // setDate(newValue)
                        }}
                        disabled
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            autoFocus
                            variant="standard"
                            helperText={null}
                            autoComplete='off'
                            InputProps={{
                              startAdornment: (
                                <Box paddingRight={1} marginLeft="-40px">
                                  {params?.InputProps?.endAdornment}
                                </Box>
                              )
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                {loading && !products?.length && (
                  <Box display='flex' justifyContent='center' alignItems='center' width='100%' height={100}>
                    <CircularProgress />
                  </Box>
                )}

                {date && (
                  <>
                    <Grid item xs={12}>
                      <TabsUnstyled defaultValue={state?.defaultTab ?? 0}
                      >
                        <Box marginBottom={2}>
                          <TabsList>
                            <Tab type="button">Bobinas</Tab>
                            <Tab type="button">Chapas</Tab>
                            <Tab type="button">Caixas</Tab>
                          </TabsList>
                        </Box>

                        <TabPanel value={0}>
                          <Grid container spacing={2}>
                          <Grid item xs={12} md={5} textAlign={'right'}>
                            <Typography
                              component={'strong'}
                              textAlign={'right'}
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Peso kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.coil?.peso}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    kg
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.coil?.rs}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={3} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$/kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={
                                Number(total?.coil?.rsKg)?.toFixed(2) ?? ''
                              }
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>

                            {previsions?.coil?.map((prevision, index) => (
                              <Fragment key={prevision.formId}>
                                <Grid item xs={12} md={3}>
                                  <FormControl
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                  >
                                    <AutocompleteClients value={prevision?.client || ''} onChange={(value) =>
                                      handleChangePrevisionCoil({
                                        formId: prevision.formId,
                                        client: value
                                      })} />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <FormControl
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                  >
                                    <TextField
                                      style={{ width: '100%' }}
                                      id={`seller-${prevision.formId}`}
                                      label="Vendedor"
                                      variant="standard"
                                      autoComplete='off'
                                      value={prevision?.seller?.user?.name || prevision.client?.seller?.user?.name || ''}
                                      disabled
                                    />
                                    {/* <InputLabel
                                      id="select-cliente-label"
                                      required
                                    >
                                      Vendedor
                                    </InputLabel>
                                    <Select
                                      labelId="select-cliente-label"
                                      id={`select-cliente-${prevision.formId}`}
                                      label="Cliente"
                                      value={prevision?.seller || (prevision?.client ? clients.find(d => d._id === prevision?.client)?.seller?._id : null) || ''}
                                      onChange={(e) =>
                                        handleChangePrevisionCoil({
                                          formId: prevision.formId,
                                          seller: e.target.value
                                        })
                                      }
                                      required
                                    >
                                      {sellers.map((item: any) => {
                                        return (
                                          <MenuItem
                                            value={item._id}
                                          >
                                            {item.user.name}
                                          </MenuItem>
                                        )
                                      })}
                                    </Select> */}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    id={`kg-${prevision.formId}`}
                                    label="Peso kg"
                                    variant="standard"
                                    type="number"
                                    autoComplete='off'
                                    value={prevision?.pesoKg || ''}
                                    onChange={(e) => {
                                      if (!onlyNumbers(e.target.value)) {
                                        handleChangePrevisionCoil({
                                          formId: prevision.formId,
                                          pesoKg: undefined
                                        })
                                      } else {
                                        handleChangePrevisionCoil({
                                          formId: prevision.formId,
                                          pesoKg: Math.round(
                                            Number(e.target.value) <= 0
                                              ? 1
                                              : Number(e.target.value)
                                          )
                                        })
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          kg
                                        </InputAdornment>
                                      )
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} md={2}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    id={`reais-${index}`}
                                    label="Previsão R$"
                                    variant="standard"
                                    type="number"
                                    autoComplete='off'
                                    value={prevision?.previsionPrice || ''}
                                    onChange={(e) => {
                                      if (!onlyNumbers(e.target.value)) {
                                        handleChangePrevisionCoil({
                                          formId: prevision.formId,
                                          previsionPrice: undefined
                                        })
                                      } else {
                                        handleChangePrevisionCoil({
                                          formId: prevision.formId,
                                          previsionPrice: Number(
                                            Number(e.target.value) <= 0
                                              ? 1.0
                                              : Number(e.target.value).toFixed(
                                                2
                                              )
                                          )
                                        })
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === 'Enter' &&
                                        prevision?.previsionPrice
                                      ) {
                                        document
                                          .getElementById(
                                            'button-new-prevision-coil'
                                          )
                                          ?.focus()
                                      }
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          R$
                                        </InputAdornment>
                                      )
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    label="Previsão R$/kg"
                                    variant="standard"
                                    type="number"
                                    autoComplete='off'
                                    value={
                                      Number(prevision?.previsionPriceKg)?.toFixed(2) ?? ''
                                    }
                                    disabled={true}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          R$
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  style={{ alignSelf: 'flex-end' }}
                                >
                                  <MuiButton
                                    id={`button-delete-${index}`}
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                      handleRemovePrevision('coil', prevision)
                                    }
                                    sx={{
                                      boxShadow: 'none !important',
                                      minWidth: 'auto',
                                      minHeight: 'auto',
                                      padding: '4px'
                                    }}
                                  >
                                    <ClearIcon fontSize="small" />
                                  </MuiButton>
                                </Grid>
                              </Fragment>
                            ))}
                          </Grid>
                          <Grid item xs={12} marginTop={4}>
                            <MuiButton
                              variant="contained"
                              id="button-new-prevision-coil"
                              startIcon={<AddIcon />}
                              onClick={() => {
                                handleAddPrevision('coil')
                              }}
                              sx={{
                                '&:hover': {
                                  background: '#fff'
                                },
                                textTransform: 'none',
                                background: '#fff',
                                color: '#8A8A8A'
                              }}
                            >
                              Nova previsão
                            </MuiButton>
                          </Grid>
                        </TabPanel>

                        <TabPanel value={1}>
                          <Grid container spacing={2}>
                          <Grid item xs={12} md={5} textAlign={'right'}>
                            <Typography
                              component={'strong'}
                              textAlign={'right'}
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Peso kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.plate?.peso}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    kg
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.plate?.rs}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={3} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$/kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={
                                Number(total?.plate?.rsKg)?.toFixed(2) ?? ''
                              }
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>

                            {previsions?.plate?.map((prevision, index) => (
                              <Fragment key={prevision.formId}>
                                <Grid item xs={12} md={3}>
                                  <FormControl
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                  >
                                    <AutocompleteClients value={prevision?.client || ''} onChange={(value) =>
                                      handleChangePrevisionPlate({
                                        formId: prevision.formId,
                                        client: value
                                      })} />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <FormControl
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                  >
                                    <TextField
                                      style={{ width: '100%' }}
                                      id={`seller-${prevision.formId}`}
                                      label="Vendedor"
                                      variant="standard"
                                      autoComplete='off'
                                      value={prevision?.seller?.user?.name || prevision.client?.seller?.user?.name || ''}
                                      disabled
                                    />
                                    {/* <InputLabel
                                      id="select-cliente-label"
                                      required
                                    >
                                      Vendedor
                                    </InputLabel>
                                    <Select
                                      labelId="select-cliente-label"
                                      id={`select-cliente-${prevision.formId}`}
                                      label="Cliente"
                                      value={prevision?.seller || (prevision?.client ? clients.find(d => d._id === prevision?.client)?.seller?._id : null) || ''}
                                      onChange={(e) =>
                                        handleChangePrevisionPlate({
                                          formId: prevision.formId,
                                          seller: e.target.value
                                        })
                                      }
                                      required
                                    >
                                      {sellers.map((item: any) => {
                                        return (
                                          <MenuItem
                                            value={item._id}
                                          >
                                            {item.user.name}
                                          </MenuItem>
                                        )
                                      })}
                                    </Select> */}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    id={`kg-${prevision.formId}`}
                                    label="Peso kg"
                                    variant="standard"
                                    type="number"
                                    autoComplete='off'
                                    value={prevision?.pesoKg || ''}
                                    onChange={(e) => {
                                      if (!onlyNumbers(e.target.value)) {
                                        handleChangePrevisionPlate({
                                          formId: prevision.formId,
                                          pesoKg: undefined
                                        })
                                      } else {
                                        handleChangePrevisionPlate({
                                          formId: prevision.formId,
                                          pesoKg: Math.round(
                                            Number(e.target.value) <= 0
                                              ? 1
                                              : Number(e.target.value)
                                          )
                                        })
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          kg
                                        </InputAdornment>
                                      )
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} md={2}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    id={`reais-${index}`}
                                    label="Previsão R$"
                                    variant="standard"
                                    type="number"
                                    autoComplete='off'
                                    value={prevision?.previsionPrice || ''}
                                    onChange={(e) => {
                                      if (!onlyNumbers(e.target.value)) {
                                        handleChangePrevisionPlate({
                                          formId: prevision.formId,
                                          previsionPrice: undefined
                                        })
                                      } else {
                                        handleChangePrevisionPlate({
                                          formId: prevision.formId,
                                          previsionPrice: Number(
                                            Number(e.target.value) <= 0
                                              ? 1.0
                                              : Number(e.target.value).toFixed(
                                                2
                                              )
                                          )
                                        })
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === 'Enter' &&
                                        prevision?.previsionPrice
                                      ) {
                                        document
                                          .getElementById(
                                            'button-new-prevision-plate'
                                          )
                                          ?.focus()
                                      }
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          R$
                                        </InputAdornment>
                                      )
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    label="Previsão R$/kg"
                                    variant="standard"
                                    type="number"
                                    autoComplete='off'
                                    value={
                                      Number(prevision?.previsionPriceKg)?.toFixed(2) ?? ''
                                    }
                                    disabled={true}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          R$
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  style={{ alignSelf: 'flex-end' }}
                                >
                                  <MuiButton
                                    id={`button-delete-${index}`}
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                      handleRemovePrevision('plate', prevision)
                                    }
                                    sx={{
                                      boxShadow: 'none !important',
                                      minWidth: 'auto',
                                      minHeight: 'auto',
                                      padding: '4px'
                                    }}
                                  >
                                    <ClearIcon fontSize="small" />
                                  </MuiButton>
                                </Grid>
                              </Fragment>
                            ))}
                          </Grid>
                          <Grid item xs={12} marginTop={4}>
                            <MuiButton
                              variant="contained"
                              id="button-new-prevision-plate"
                              startIcon={<AddIcon />}
                              onClick={() => {
                                handleAddPrevision('plate')
                              }}
                              sx={{
                                '&:hover': {
                                  background: '#fff'
                                },
                                textTransform: 'none',
                                background: '#fff',
                                color: '#8A8A8A'
                              }}
                            >
                              Nova previsão
                            </MuiButton>
                          </Grid>
                        </TabPanel>

                        <TabPanel value={2}>
                          <Grid container spacing={2}>
                          <Grid item xs={12} md={5} textAlign={'right'}>
                            <Typography
                              component={'strong'}
                              textAlign={'right'}
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Peso kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.box?.peso}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    kg
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.box?.rs}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={3} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$/kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={Number(total?.box?.rsKg)?.toFixed(2) ?? ''}
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>

                            {previsions?.box?.map((prevision, index) => (
                              <Fragment key={prevision.formId}>
                                <Grid item xs={12} md={3}>
                                  <FormControl
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                  >
                                    <AutocompleteClients value={prevision?.client || ''} onChange={(value) =>
                                      handleChangePrevisionBox({
                                        formId: prevision.formId,
                                        client: value
                                      })} />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <FormControl
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                  >
                                    <TextField
                                      style={{ width: '100%' }}
                                      id={`seller-${prevision.formId}`}
                                      label="Vendedor"
                                      variant="standard"
                                      autoComplete='off'
                                      value={prevision?.seller?.user?.name || prevision.client?.seller?.user?.name || ''}
                                      disabled
                                    />
                                    {/* <InputLabel
                                      id="select-cliente-label"
                                      required
                                    >
                                      Vendedor
                                    </InputLabel>
                                    <Select
                                      labelId="select-cliente-label"
                                      id={`select-cliente-${prevision.formId}`}
                                      label="Cliente"
                                      value={prevision?.seller || (prevision?.client ? clients.find(d => d._id === prevision?.client)?.seller?._id : null) || ''}
                                      onChange={(e) =>
                                        handleChangePrevisionBox({
                                          formId: prevision.formId,
                                          seller: e.target.value
                                        })
                                      }
                                      required
                                    >
                                      {sellers.map((item: any) => {
                                        return (
                                          <MenuItem
                                            value={item._id}
                                          >
                                            {item.user.name}
                                          </MenuItem>
                                        )
                                      })}
                                    </Select> */}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    id={`kg-${prevision.formId}`}
                                    label="Peso kg"
                                    variant="standard"
                                    type="number"
                                    autoComplete='off'
                                    value={prevision?.pesoKg || ''}
                                    onChange={(e) => {
                                      if (!onlyNumbers(e.target.value)) {
                                        handleChangePrevisionBox({
                                          formId: prevision.formId,
                                          pesoKg: undefined
                                        })
                                      } else {
                                        handleChangePrevisionBox({
                                          formId: prevision.formId,
                                          pesoKg: Math.round(
                                            Number(e.target.value) <= 0
                                              ? 1
                                              : Number(e.target.value)
                                          )
                                        })
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          kg
                                        </InputAdornment>
                                      )
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} md={2}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    id={`reais-${index}`}
                                    label="Previsão R$"
                                    variant="standard"
                                    type="number"
                                    autoComplete='off'
                                    value={prevision?.previsionPrice || ''}
                                    onChange={(e) => {
                                      if (!onlyNumbers(e.target.value)) {
                                        handleChangePrevisionBox({
                                          formId: prevision.formId,
                                          previsionPrice: undefined
                                        })
                                      } else {
                                        handleChangePrevisionBox({
                                          formId: prevision.formId,
                                          previsionPrice: Number(
                                            Number(e.target.value) <= 0
                                              ? 1.0
                                              : Number(e.target.value).toFixed(
                                                2
                                              )
                                          )
                                        })
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === 'Enter' &&
                                        prevision?.previsionPrice
                                      ) {
                                        document
                                          .getElementById(
                                            'button-new-prevision-box'
                                          )
                                          ?.focus()
                                      }
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          R$
                                        </InputAdornment>
                                      )
                                    }}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    label="Previsão R$/kg"
                                    variant="standard"
                                    type="number"
                                    autoComplete='off'
                                    value={
                                      Number(prevision?.previsionPriceKg)?.toFixed(2) ?? ''
                                    }
                                    disabled={true}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          R$
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  style={{ alignSelf: 'flex-end' }}
                                >
                                  <MuiButton
                                    id={`button-delete-${index}`}
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    onClick={() =>
                                      handleRemovePrevision('box', prevision)
                                    }
                                    sx={{
                                      boxShadow: 'none !important',
                                      minWidth: 'auto',
                                      minHeight: 'auto',
                                      padding: '4px'
                                    }}
                                  >
                                    <ClearIcon fontSize="small" />
                                  </MuiButton>
                                </Grid>
                              </Fragment>
                            ))}
                          </Grid>
                          <Grid item xs={12} marginTop={4}>
                            <MuiButton
                              variant="contained"
                              id="button-new-prevision-box"
                              startIcon={<AddIcon />}
                              onClick={() => {
                                handleAddPrevision('box')
                              }}
                              sx={{
                                '&:hover': {
                                  background: '#fff'
                                },
                                textTransform: 'none',
                                background: '#fff',
                                color: '#8A8A8A'
                              }}
                            >
                              Nova previsão
                            </MuiButton>
                          </Grid>
                        </TabPanel>
                      </TabsUnstyled>
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  md={12}
                  marginTop={8}
                  className="text-right"
                  style={{
                    alignSelf: 'flex-end',
                    justifySelf: 'self-end'
                  }}
                >
                  <Button
                    type="button"
                    mold='red'
                    onClick={() =>
                      alertSwalCustom(handleCancel, 'Você realmente quer cancelar?')

                    }
                    disabled={loading}
                    style={{
                      width: '122px'
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    disabled={loading}
                    loading={loading}
                    style={{
                      backgroundColor: '#4060E6',
                      width: '122px',
                      color: 'white',
                      marginLeft: '16px'
                    }}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default FormEditManagementControl
