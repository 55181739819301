import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  CircularProgress,
  Icon,
  Tooltip,
  TextField
} from '@mui/material'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportBilling, getReportDevolucaoMensal, getReportProductionMonth, getReportBalanceteSucataMensal, getRefuseIndex } from 'services/report/ReportBilling'
import { getReportRH } from 'services/report/ReportRH'
import { styled } from '@mui/material/styles'
import { toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
}

const TableResumoInfoFat: React.FC<Props> = (props) => {
  const cng = localStorage.getItem('changeOR')
  const changeRefugo = localStorage.getItem('changeRefugo')
  const [rows, setRows] = useState<any[]>([])
  const [dataLocal, setDataLocal] = useState<any>([])
  const [dataPlanejed, setDataPlanejed] = useState<any>([])
  const [dataDevolucao, setDataDevolucao] = useState<any>([])
  const [dataFaturamento, setDataFaturamento] = useState<any>([])
  const [dataBalanceteSucata, setDataBalanceteSucata] = useState<any>([])
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const dataAtual = moment(props.date).format('DD/MM/YYYY')
  const [valorRow] = useState<any[]>([
    {
      text: 'Previsto',
      value: 1
    },
    {
      text: 'Realizado',
      value: 2
    },
    {
      text: 'Projetado',
      value: 3
    }
  ])
  const [porcent, setPorcent] = useState<number>(0)
  const [refuse, setRefuse] = useState<number>(0)
  const [A, setA] = useState<number>(0)
  const [priceKgPapel, setPriceKgPapel] = useState<number>(0)
  const [priceKgCaixa, setPriceKgCaixa] = useState<number>(0)
  const [priceKgChapa, setPriceKgChapa] = useState<number>(0)
  const [previsionDataPapelProduzidas, setPrevisionDataPapelProduzidas] = useState<number>(0)
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))
  console.log('dataAtual', dataAtual)
  console.log('porcent', porcent)

  const handleInputChange = (event) => {
    localStorage.setItem('changeRefugo', 'YES')
    localStorage.setItem('changeOR', 'YES')
    const newValue = parseInt(event.target.value)
    if (!isNaN(newValue)) {
      localStorage.setItem('indiceRef', newValue.toString())
      setPorcent(newValue)
      const temp = A + (A * (newValue / 100))
      setPrevisionDataPapelProduzidas(Math.floor(temp))
    }
  }
  const queryMultiple = () => {
    const req0 = useQuery(['refuse-index', mes, ano],
      async () => {
        return getRefuseIndex(mes, ano)
      },
      {
        onSuccess: (data) => {
          console.log('req0 => ', data)
          setRefuse(data?.refuse)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verefifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    const req1 = useQuery(['report', dataAtual],
      async () => {
        const currentDate = new Date(props.date)
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        return getReportProductionMonth(props.date, firstDay)
      },
      {
        onSuccess: (data) => {
          console.log('req1 => ', data)
          dataHandle(dataLocal, data, dataPlanejed)
          setDataFaturamento(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verefifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    const req2 = useQuery(['sales-report', mes, ano],
      async () => {
        return getReportBilling(mes, ano)
      },
      {
        onSuccess: (date) => {
          console.log('date =>', date)
          setDataLocal(date)
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false
      }
    )
    const req3 = useQuery(['report-rh', props.date],
      async () => {
        return getReportRH(props.date)
      },
      {
        onSuccess: (data) => {
          setDataPlanejed(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verefifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    const req4 = useQuery(['devolucao', dataAtual],
      async () => {
        return getReportDevolucaoMensal(dataAtual)
      },
      {
        onSuccess: (data) => {
          setDataDevolucao(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verefifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    const req5 = useQuery(['balancete', dataAtual],
      async () => {
        return getReportBalanceteSucataMensal(dataAtual)
      },
      {
        onSuccess: (data) => {
          setDataBalanceteSucata(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verefifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    return [req0, req1, req2, req3, req4, req5]
  }

  const [req0, req1, req2, req3] = queryMultiple()
  const dataHandle = (valor, dataValues, dataPlan) => {
    const getData = localStorage.getItem('dataOR')
    let num = 0
    const idf = Number(refuse) // Number(localStorage.getItem('indiceRef'))
    console.log('idf', idf)
    if (getData && cng === 'NO') {
      const dataParts = getData.split('/')
      const mes = parseInt(dataParts[1], 10)
      const ano = parseInt(dataParts[2], 10)
      if (mes >= 5 && ano >= 2023) {
        num = 13
      } else {
        num = 25
      }
      setPorcent(num)
    } else if (getData && cng === 'YES') {
      setPorcent(idf)
    }
    console.log('idf', idf)
    const data: any[] = []
    const um: number = 1.1

    let previsionDataPapel: any
    let previsionDataCaixa: any
    let previsionDataChapa: any
    let previsionDataPO: any

    const papelDataArray: any[] = []
    const caixaDataArray: any[] = []
    const chapaDataArray: any[] = []
    const prodPapelDataArray: any[] = []
    const prodChapaPODataArray: any[] = []

    let realizedPapel: any
    let realizedCaixa: any
    let realizedChapa: any
    let realizedProdPapel: any
    let realizedProdChapaPO: any

    let resultCaixa: any
    let resultChapa: any
    let resultPapel: any
    let resultProdPapel: any
    let resultProdChapaPO: any
    let resultVendasCaixas: any
    let resultVendasChapas: any
    let resultVendasPapelClienteFinal: any

    let projCaixa: any
    let projChapa: any
    let projPapel: any
    let projProdPapel: any
    let projProdPO: any

    const arrayFaturamento: any[] = []

    let vendasChapas: any
    let vendasCaixas: any
    let vendasPapelClienteFinal: any
    let vendasCancDevolucoes = 0

    dataValues?.result?.forEach(item => {
      item.dados.forEach(e => {
        if (e.type === TipoProduto.CAIXA) {
          console.log(e.price.toFixed(2))
          setPriceKgCaixa(e.price.toFixed(2))
          caixaDataArray.push(e)
        }
        if (e.type === TipoProduto.CHAPA) {
          prodChapaPODataArray.push(e)
          console.log(e.price.toFixed(2))
          setPriceKgChapa(e.price.toFixed(2))
          chapaDataArray.push(e)
        }
        if (e.type === TipoProduto.PAPEL) {
          prodPapelDataArray.push(e)
          console.log(e.price.toFixed(2))
          setPriceKgPapel(e.price.toFixed(2))
          papelDataArray.push(e)
        }
      })
    })
    console.log('dataBalanceteSucata', dataBalanceteSucata)
    console.log('dataDevolucao', dataDevolucao)
    dataDevolucao?.response?.forEach((item: any) => {
      vendasCancDevolucoes += Number(item.preco)
    })

    if (Object.keys(valor.bobina.dados).length) {
      previsionDataPapel = (valor.bobina.dados.amountKg ?? 0) / 1000
      vendasPapelClienteFinal = (Math.floor((Number(previsionDataPapel))) * 1000) * Number(priceKgPapel)
    }
    if (Object.keys(valor.caixa.dados).length) {
      previsionDataCaixa = (valor.caixa.dados.amountKg ?? 0) / 1000
      setA(Math.floor(Number(previsionDataPapel)) + Math.floor(Number(previsionDataCaixa)) + Math.floor(Number(previsionDataChapa)))
      vendasCaixas = (Math.floor((Number(previsionDataCaixa))) * 1000) * Number(priceKgCaixa)
    }
    if (Object.keys(valor.chapa.dados).length) {
      previsionDataChapa = (valor.chapa.dados.amountKg ?? 0) / 1000
      previsionDataPO = Math.floor(Number(previsionDataCaixa)) + Math.floor(Number(previsionDataChapa))
      vendasChapas = (Math.floor((Number(previsionDataChapa))) * 1000) * Number(priceKgChapa)
    }

    setA(Math.floor(Number(previsionDataPapel)) + Math.floor(Number(previsionDataCaixa)) + Math.floor(Number(previsionDataChapa)))
    const defaultProdPapel = A + (A * (porcent / 100))

    console.log('dataAtual', dataAtual)
    console.log('localStorage.getItem("dataOR")', localStorage.getItem('dataOR'))

    const tempA = Math.floor(Number(previsionDataPapel)) + Math.floor(Number(previsionDataCaixa)) + Math.floor(Number(previsionDataChapa))
    console.log('tempA', tempA)
    const prevProdPapel = tempA + (tempA * (idf / 100))
    setPrevisionDataPapelProduzidas(defaultProdPapel)

    console.log('priceKgCaixa', priceKgCaixa)
    console.log('priceKgChapa', priceKgChapa)
    console.log('priceKgPapel', priceKgPapel)

    console.log('CX previsionDataCaixa', previsionDataCaixa)
    console.log('Number(previsionDataCaixa)', Number(previsionDataCaixa))
    console.log('(Number(previsionDataCaixa)) * 1000', (Number(previsionDataCaixa)) * 1000)
    console.log('CH previsionDataChapa', previsionDataChapa)
    console.log('Number(previsionDataChapa)', Number(previsionDataChapa))
    console.log('(Number(previsionDataChapa)) * 1000', (Number(previsionDataChapa)) * 1000)
    console.log('PP previsionDataPapel', previsionDataPapel)
    console.log('Number(previsionDataPapel)', Number(previsionDataPapel))
    console.log('(Number(previsionDataPapel)) * 1000', (Number(previsionDataPapel)) * 1000)

    console.log('PROD PP prevProdPapel', prevProdPapel)
    console.log('Number(prevProdPapel)', Number(prevProdPapel))
    console.log('(Number(previsionDataPapel)) * 1000', (Number(prevProdPapel)) * 1000)

    console.log('vendasCaixas', vendasCaixas)
    console.log('vendasChapas', vendasChapas)
    console.log('vendasPapelClienteFinal', vendasPapelClienteFinal)

    console.log('vendasCancDevolucoes', vendasCancDevolucoes)
    console.log('caixaDataArray', caixaDataArray)
    console.log('chapaDataArray', chapaDataArray)
    console.log('papelDataArray', papelDataArray)

    if (caixaDataArray.length) {
      realizedCaixa = caixaDataArray[0].kg
      console.log('realizedCaixa', realizedCaixa)
      resultCaixa = realizedCaixa / 1000
      console.log('resultCaixa', resultCaixa)
      resultVendasCaixas = Number(caixaDataArray[0].valor1.toFixed(2))
      console.log('resultVendasCaixas', resultVendasCaixas)
    }

    if (chapaDataArray.length) {
      realizedChapa = chapaDataArray[0].kg
      console.log('realizedChapa', realizedChapa)
      resultChapa = realizedChapa / 1000
      console.log('resultChapa', resultChapa)
      resultVendasChapas = Number(chapaDataArray[0].valor1.toFixed(2))
      console.log('resultVendasChapas', resultVendasChapas)
      realizedProdChapaPO = (Number(resultCaixa) + Number(resultChapa)) * um
    }

    if (papelDataArray.length) {
      realizedPapel = papelDataArray[0].kg
      console.log('realizedPapel', realizedPapel)
      resultPapel = realizedPapel / 1000
      console.log('resultPapel', resultPapel)
      resultVendasPapelClienteFinal = Number(papelDataArray[0].valor1.toFixed(2))
      console.log('resultVendasPapelClienteFinal', resultVendasPapelClienteFinal)
    }

    if (prodPapelDataArray.length) {
      realizedProdPapel = prodPapelDataArray[0].kg
      console.log('realizedProdPapel', realizedProdPapel)
      resultProdPapel = realizedProdPapel / 1000
      console.log('resultProdPapel', resultProdPapel)
    }

    if (prodChapaPODataArray.length) {
      realizedProdChapaPO = prodChapaPODataArray[0].kg
      console.log('realizedProdChapaPO', realizedProdChapaPO)
      resultProdChapaPO = realizedProdChapaPO / 1000
      console.log('resultProdChapaPO', resultProdChapaPO)
    }

    let balancete
    let outrasVendas
    if (dataBalanceteSucata && dataBalanceteSucata.response && dataBalanceteSucata.response[0]) {
      outrasVendas = Number(dataBalanceteSucata.response[0].vendasucata)
      balancete = Number(dataBalanceteSucata?.response?.[0]?.balancete)
    } else {
      outrasVendas = 0
      balancete = 0
    }
    const ajusteValorPresente = -1 * balancete
    dataPlan.forEach(e => {
      if (e.production.name === 'Faturamento') {
        arrayFaturamento.push(e)
      }
    })

    console.log('dataFaturamento', dataFaturamento)
    console.log('dataDevolucao', dataDevolucao)
    console.log('resultVendasPapelClienteFinal', resultVendasPapelClienteFinal)
    console.log('resultVendasChapas', resultVendasChapas)
    console.log('resultVendasCaixas', resultVendasCaixas)
    console.log('vendasCancDevolucoes', vendasCancDevolucoes)
    console.log('ajusteValorPresente', ajusteValorPresente)
    console.log('outrasVendas', outrasVendas)
    if (arrayFaturamento.length) {
      const daysPlanejed = arrayFaturamento[0].days.length
      const workDays = arrayFaturamento[0].worksDays.length ?? 0
      projCaixa = (Math.floor(Number(resultCaixa) + ((Number(resultCaixa) / daysPlanejed) * (daysPlanejed - workDays))) || 0)
      projChapa = (Math.floor(Number(resultChapa) + ((Number(resultChapa) / daysPlanejed) * (daysPlanejed - workDays))) || 0)
      projPapel = (Math.floor(Number(resultPapel) + ((Number(resultPapel) / daysPlanejed) * (daysPlanejed - workDays))) || 0)
      projProdPapel = (Math.floor((Number(realizedProdPapel) + ((Number(realizedProdPapel) / daysPlanejed) * (daysPlanejed - workDays))) / 1000) || 0)
      projProdPO = (Math.floor((Number(realizedProdChapaPO) + ((Number(realizedProdChapaPO) / daysPlanejed) * (daysPlanejed - workDays))) / 1000) || 0)
    }

    const vendabruta = (Number(resultVendasPapelClienteFinal) + Number(resultVendasCaixas) + Number(resultVendasChapas))
    const resultVendaBruta = Number(resultVendasPapelClienteFinal) + Number(resultVendasCaixas) + Number(resultVendasChapas) + Number(ajusteValorPresente) + Number(outrasVendas) + Number(vendasCancDevolucoes)
    const projVendaBruta = Number(resultVendasPapelClienteFinal) + Number(resultVendasCaixas) + Number(resultVendasChapas) + Number(ajusteValorPresente) + Number(outrasVendas) + Number(vendasCancDevolucoes)

    console.log('vendabruta', vendabruta)
    /* Toneladas Vendidas Papel */
    data.push({
      _id: 'ton-1',
      product: 'Vendas de Papel (toneladas)',
      prev: previsionDataPapel | 0,
      realized: resultPapel | 0,
      projected: projPapel | 0
    })
    /* Toneladas Vendidas Caixa */
    data.push({
      _id: 'ton-2',
      product: 'Vendas de Caixa (toneladas)',
      prev: previsionDataCaixa | 0,
      realized: resultCaixa | 0,
      projected: projCaixa | 0
    })
    /* Toneladas Vendidas Chapa */
    data.push({
      _id: 'ton-3',
      product: 'Vendas de Chapa (toneladas)',
      prev: previsionDataChapa | 0,
      realized: resultChapa | 0,
      projected: projChapa | 0
    })
    /* Toneladas Produzidas de Papel */
    data.push({
      _id: 'ton-4',
      product: 'Produção de Papel (toneladas)',
      prevRs: Math.floor(prevProdPapel) | 0,
      realized: resultProdPapel | 0,
      projected: projProdPapel | 0
    })
    /* Toneladas Produzidas de PO */
    data.push({
      _id: 'ton-5',
      product: 'Produção de PO (toneladas)',
      prev: previsionDataPO | 0,
      realized: resultProdChapaPO | 0,
      projected: projProdPO | 0
    })
    /* -------------------------- */
    data.push({
      _id: 'ton-6',
      product: '',
      prev: 0,
      realized: 0,
      projected: 0
    })
    /* Vendas de Papel Clientes Final */
    data.push({
      _id: 'ton-7',
      product: 'Vendas de Papel Clientes Final',
      prev: toBRL(vendasPapelClienteFinal | 0),
      realizedRs: toBRL(resultVendasPapelClienteFinal | 0),
      projectedRs: toBRL(resultVendasPapelClienteFinal | 0)
    })
    /* Vendas de Caixas */
    data.push({
      _id: 'ton-8',
      product: 'Vendas de Caixas',
      prev: toBRL(vendasCaixas | 0),
      realizedRs: toBRL(resultVendasCaixas | 0),
      projectedRs: toBRL(resultVendasCaixas | 0)
    })
    /* Vendas de Chapas */
    data.push({
      _id: 'ton-9',
      product: 'Vendas de Chapas',
      prev: toBRL(vendasChapas | 0),
      realizedRs: toBRL(resultVendasChapas | 0),
      projectedRs: toBRL(resultVendasChapas | 0)
    })
    /* (-) Ajuste ao Valor Presente ( AVP ) */
    data.push({
      _id: 'ton-10',
      product: '(-) Ajuste ao Valor Presente ( AVP )',
      prev: '',
      realizedRs: toBRL(ajusteValorPresente | 0),
      projectedRs: toBRL(ajusteValorPresente | 0)
    })
    /* Outras Vendas */
    data.push({
      _id: 'ton-11',
      product: 'Outras Vendas',
      prev: '',
      realizedRs: toBRL(outrasVendas | 0),
      projectedRs: toBRL(outrasVendas | 0)
    })
    /* Vendas Canc./Devoluções  */
    data.push({
      _id: 'ton-12',
      product: 'Vendas Canc./Devoluções ',
      prev: '',
      realizedRs: toBRL((vendasCancDevolucoes * -1) | 0),
      projectedRs: toBRL((vendasCancDevolucoes * -1) | 0)
    })
    /* Venda Bruta  */
    data.push({
      _id: 'ton-13',
      product: 'VENDA BRUTA / LÍQUIDA',
      prev: toBRL(vendabruta | 0),
      realizedRs: toBRL(resultVendaBruta | 0),
      projectedRs: toBRL(projVendaBruta | 0)
    })

    setRows(data)
  }

  useEffect(() => { }, [A, previsionDataPapelProduzidas])

  return (
    <>
      {(req0.isLoading || req1.isLoading || req1.isFetching || req3.isLoading || req3.isFetching) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {(req0.isError && req1.isError && req2.isError && req3.isError) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>
            signal_wifi_statusbar_connected_no_internet_4
          </Icon>
        </Box>
      )}
      {(req0.isSuccess && req1.isSuccess && !req1.isError && !req1.isFetching) && (
        <>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: '100%' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'right' }}>
                        <span style={{
                          marginRight: '20%',
                          width: '80%'
                        }}><strong>Índice de Refugo (%)</strong>
                        </span>
                      </div>
                    </TableCell>
                    {valorRow.map((item, index) => {
                      return (
                        <TableCell align="left" colSpan={1} key={index}>
                          {item.text}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    if (row._id === 'ton-6') {
                      return (
                        <TableRow key={row._id} sx={{ backgroundColor: '#ffe6e6' }}>
                          <StyledTableCell align="left" colSpan={4}></StyledTableCell>
                        </TableRow>
                      )
                    } else {
                      return (
                        <TableRow key={row._id} style={row._id === 'total' ? { fontWeight: 'bold', textShadow: '0px 0 black, 0 0.2px black, 0.2px 0 black, 0 0px black' } : {}}>
                          <TableCell style={{ wordBreak: 'break-word' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <span style={{
                                marginRight: '5%',
                                width: '50%'
                              }}>{row.product}</span>
                              {index === 3 && (
                                <Tooltip title={`Índice de refugo: ${porcent}%`} placement="top">
                                  <TextField
                                    style={{ width: '25%', marginRight: '5%', fontWeight: 'bold' }}
                                    size="small"
                                    value={porcent}
                                    type="number"
                                    inputProps={{
                                      min: 0,
                                      max: 100,
                                      style: { marginRight: '5%', fontWeight: 'bold' }
                                    }}
                                    onChange={handleInputChange}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="left" style={{ wordBreak: 'break-word' }}>
                            {(index === 3 && changeRefugo === 'NO') ? row.prevRs.toLocaleString('pt-br') : ((index === 3 && changeRefugo === 'YES') ? previsionDataPapelProduzidas.toLocaleString('pt-br') : row.prev.toLocaleString('pt-br'))}
                          </TableCell>
                          <TableCell align="left" style={{ wordBreak: 'break-word' }}>
                            {row.realized ? Number(row.realized).toLocaleString('pt-br') : row.realizedRs}
                          </TableCell>
                          <TableCell align="left" style={{ wordBreak: 'break-word' }}>
                            {row.projected ? Number(row.projected).toLocaleString('pt-br') : row.projectedRs}
                          </TableCell>
                        </TableRow>
                      )
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>

          </Paper>
        </>
      )}
    </>
  )
}

export default TableResumoInfoFat
