/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-constant-condition */
import React, { useRef, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { toast } from 'react-toastify'
import { httpClient } from 'config/httpClient'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Box,
  CircularProgress,
  Grow,
  Input,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import moment from 'moment'
import {
  BoxProducts,
  Tab,
  TabPanel,
  TabsList
} from './previsionManagement.styles'
import Button from 'components/form/Button'
import CreateIcon from '@mui/icons-material/Create'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import { ModalComponent as ModalDuplicatePrevisions } from 'components/Modal'
import { ModalTitle } from 'components/Modal/Title'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import { useNavigate } from 'react-router-dom'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { Delete } from '@mui/icons-material'
import alertSwal from 'components/Alerts/ModalDelete'
import { formatValue, toBRL } from 'utils/formatString'

function RenderTableHeaderTotal({
  data,
  type,
  search
}: {
  data: any
  type: string
  search: string
}) {
  const totais = data?.reduce(
    (acc, row) => {
      if (
        row?.product === type &&
        (!search?.length ||
          row?.client?.name?.toUpperCase()?.includes(search?.toUpperCase()))
      ) {
        return {
          rs: Number(row?.previsionPrice ?? 0) + acc.rs,
          kg: Number(row?.pesoKg ?? 0) + acc.kg,
          rsKg: 0
        }
      }
      return acc
    },
    {
      rs: 0,
      kg: 0,
      rsKg: 0
    }
  )

  return (
    <TableRow>
      <TableCell
        sx={{
          top: 116,
          verticalAlign: 'top',
          background: '#FFF'
        }}
      >
        Total
      </TableCell>
      <TableCell
        sx={{
          top: 116,
          textTransform: 'capitalize',
          background: '#FFF',
          fontWeight: 'bold'
        }}
      >
        -
      </TableCell>
      <TableCell
        sx={{
          top: 116,
          textTransform: 'capitalize',
          background: '#FFF',
          fontWeight: 'bold'
        }}
      >
        {toBRL(totais?.rs)}
      </TableCell>
      <TableCell
        sx={{
          top: 116,
          textTransform: 'capitalize',
          background: '#FFF',
          fontWeight: 'bold'
        }}
      >
        {formatValue(totais?.kg)}
      </TableCell>
      <TableCell
        sx={{
          top: 116,
          textTransform: 'capitalize',
          background: '#FFF',
          fontWeight: 'bold'
        }}
      >
        {toBRL(totais?.rs / totais?.kg)}
      </TableCell>
    </TableRow>
  )
}

function RenderTableHeaderWithSearchClient({
  onSearch,
  defaultValue
}: {
  onSearch: (search: string) => void
  defaultValue?: string
}) {
  const [searchValue, setSearchValue] = useState(defaultValue ?? '')
  const inputSearchRef = useRef<HTMLInputElement>(null)

  return (
    <TableRow>
      <TableCell
        sx={{
          top: 58,
          verticalAlign: 'top',
          background: '#FFF'
        }}
      >
        {/* Cliente */}
        <Input
          id="field-search"
          placeholder="Pesquisar por cliente"
          autoComplete="off"
          type="text"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value)
            onSearch(e.target.value)
          }}
          inputRef={inputSearchRef}
          fullWidth
        />
      </TableCell>
      <TableCell
        sx={{
          top: 58,
          verticalAlign: 'top',
          background: '#FFF'
        }}
      >
        Vendedor
      </TableCell>
      <TableCell
        sx={{
          top: 58,
          verticalAlign: 'top',
          background: '#FFF'
        }}
      >
        R$
      </TableCell>
      <TableCell
        sx={{
          top: 58,
          verticalAlign: 'top',
          background: '#FFF'
        }}
      >
        KG
      </TableCell>
      <TableCell
        sx={{
          top: 58,
          verticalAlign: 'top',
          background: '#FFF'
        }}
      >
        R$/KG
      </TableCell>
    </TableRow>
  )
}

interface Props {
  previsionsForm: any[]
  refresh: () => void
  handleRefetchDelete: () => void
}

const PrevisionManagement: React.FC<Props> = ({ previsionsForm, refresh, handleRefetchDelete }) => {
  const navigate = useNavigate()
  const modalDuplicatePrevisionsRef = useRef<any>(null)
  const [selectTab, setSelectTab] = useState(0)

  const [loading, setLoading] = useState(false)
  const [selectDate, setSelectDate] = useState<any | null>(null)
  const [selectPrevisionForm, setSelectPrevisionForm] = useState<any | null>(
    null
  )
  const [search, setSearch] = useState('')

  const handleOpenDuplicatePrevisions = (previsionForm: any) => {
    setSelectPrevisionForm(previsionForm)
    modalDuplicatePrevisionsRef?.current?.onOpen()
  }

  const handleCloseDuplicatePrevisions = () => {
    modalDuplicatePrevisionsRef?.current?.onClose()
  }

  const handleDuplicatePrevisions = () => {
    if (!selectPrevisionForm?.data?._id) return

    if (!selectDate) {
      document.getElementById('date-duplicate-previsions')?.focus()
      return
    }
    setLoading(true)

    const body = {
      id: selectPrevisionForm?.data?._id,
      year: moment(selectDate).format('YYYY'),
      month: moment(selectDate).format('M')
    }

    httpClient
      .post('/previsions/form/duplicate', body)
      .then((response) => {
        toast.success('Duplicado com sucesso!')
        setSelectDate(null)
        setSelectPrevisionForm(null)

        handleCloseDuplicatePrevisions()
        refresh()
      })
      .catch((error) => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={200}
      >
        <CircularProgress />
      </Box>
    )
  }

  const handleDeletePrivissions = async (form: any) => {
    const { _id } = form?.data

    try {
      await httpClient.delete(`/previsions/form/${_id}`)
      handleRefetchDelete()
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }

  return (
    <>
      <BoxProducts
        display="flex"
        flexDirection="row"
        width="100%"
        marginTop={3}
        sx={{ overflowX: 'auto', '& > div': { marginRight: 3 } }}
      >
        {previsionsForm?.map(
          (form) =>
            form?.month && (
              <Grow in style={{ transformOrigin: '0 0 0' }} timeout={300}>
                <Box
                  key={form?.month}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  width="100%"
                  borderRadius={3}
                >
                  <TabsUnstyled defaultValue={false ? Number(false) : 0}>
                    <Box width="100%" borderRadius="24px">
                      <TabsList
                        sx={{
                          '& button:first-of-type': {
                            borderRadius: '24px 0 0 0'
                          },
                          '& button:last-child': { borderRadius: '0 24px 0 0' },
                          '& button': {
                            fontWeight: '600'
                          }
                        }}
                      >
                        <Tab>BOBINA</Tab>
                        <Tab>CHAPA</Tab>
                        <Tab>CAIXA</Tab>
                      </TabsList>
                    </Box>
                    <TabPanel value={0} onClick={() => setSelectTab(0)}>
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table
                          stickyHeader
                          sx={{ minWidth: '100%', background: '#FFF' }}
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  textTransform: 'uppercase',
                                  color: '#4060e6',
                                  background: '#FFF',
                                  fontWeight: 'bold'
                                }}
                              >
                                {form?.month}
                              </TableCell>
                              <TableCell
                                colSpan={4}
                                sx={{
                                  textTransform: 'capitalize',
                                  background: '#FFF',
                                  fontWeight: 'bold'
                                }}
                              >
                                Previsões
                              </TableCell>
                            </TableRow>
                            <RenderTableHeaderWithSearchClient
                              onSearch={(value) => setSearch(value)}
                              defaultValue={search}
                            />

                            <RenderTableHeaderTotal
                              data={form?.data?.previsions}
                              type="62321a4dfe24f24bc319b535"
                              search={search}
                            />
                          </TableHead>
                          <TableBody>
                            {form?.data?.previsions?.map(
                              (row) =>
                                row?.product === '62321a4dfe24f24bc319b535' &&
                                (!search?.length ||
                                  row?.client?.name
                                    ?.toUpperCase()
                                    ?.includes(search?.toUpperCase())) && (
                                  <TableRow key={row?._id}>
                                    <TableCell component="th" scope="row">
                                      {row?.client?.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row?.seller?.user?.name}
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        noWrap
                                      >
                                        {toBRL(row?.previsionPrice)}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {formatValue(row?.pesoKg)}
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        noWrap
                                      >
                                        {toBRL(row?.previsionPriceKg)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={1} onClick={() => setSelectTab(1)}>
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table
                          stickyHeader
                          sx={{ minWidth: '100%', background: '#FFF' }}
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  textTransform: 'uppercase',
                                  color: '#4060e6',
                                  background: '#FFF',
                                  fontWeight: 'bold'
                                }}
                              >
                                {form?.month}
                              </TableCell>
                              <TableCell
                                colSpan={4}
                                sx={{
                                  textTransform: 'capitalize',
                                  background: '#FFF',
                                  fontWeight: 'bold'
                                }}
                              >
                                Previsões
                              </TableCell>
                            </TableRow>
                            <RenderTableHeaderWithSearchClient
                              onSearch={(value) => setSearch(value)}
                              defaultValue={search}
                            />

                            <RenderTableHeaderTotal
                              data={form?.data?.previsions}
                              type="62321aa4fe24f24bc319b536"
                              search={search}
                            />
                          </TableHead>
                          <TableBody>
                            {form?.data?.previsions?.map(
                              (row) =>
                                row?.product === '62321aa4fe24f24bc319b536' &&
                                (!search?.length ||
                                  row?.client?.name
                                    ?.toUpperCase()
                                    ?.includes(search?.toUpperCase())) && (
                                  <TableRow key={row?._id}>
                                    <TableCell component="th" scope="row">
                                      {row?.client?.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row?.seller?.user?.name}
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        noWrap
                                      >
                                        {toBRL(row?.previsionPrice)}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {formatValue(row?.pesoKg)}
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        noWrap
                                      >
                                        {toBRL(row?.previsionPriceKg)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={2} onClick={() => setSelectTab(2)}>
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table
                          stickyHeader
                          sx={{ minWidth: '100%', background: '#FFF' }}
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  textTransform: 'uppercase',
                                  color: '#4060e6',
                                  background: '#FFF',
                                  fontWeight: 'bold'
                                }}
                              >
                                {form?.month}
                              </TableCell>
                              <TableCell
                                colSpan={4}
                                sx={{
                                  textTransform: 'capitalize',
                                  background: '#FFF',
                                  fontWeight: 'bold'
                                }}
                              >
                                Previsões
                              </TableCell>
                            </TableRow>
                            <RenderTableHeaderWithSearchClient
                              onSearch={(value) => setSearch(value)}
                              defaultValue={search}
                            />

                            <RenderTableHeaderTotal
                              data={form?.data?.previsions}
                              type="623cf565692e6d4c8fe20136"
                              search={search}
                            />
                          </TableHead>
                          <TableBody>
                            {form?.data?.previsions?.map(
                              (row) =>
                                row?.product === '623cf565692e6d4c8fe20136' &&
                                (!search?.length ||
                                  row?.client?.name
                                    ?.toUpperCase()
                                    ?.includes(search?.toUpperCase())) && (
                                  <TableRow key={row?._id}>
                                    <TableCell component="th" scope="row">
                                      {row?.client?.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row?.seller?.user?.name}
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        noWrap
                                      >
                                        {toBRL(row?.previsionPrice)}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {formatValue(row?.pesoKg)}
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        noWrap
                                      >
                                        {toBRL(row?.previsionPriceKg)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabsUnstyled>
                  <Can
                    module={MODULES.GERENCIAL}
                    permissions={[PERMISSIONS.UPDATE, PERMISSIONS.CREATE]}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                      alignItems="center"
                      marginTop={1}
                    >
                      <Button
                        type="button"
                        mold="primary"
                        style={{
                          padding: '4px 8px',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                        onClick={() =>
                          navigate(
                            `/home/managementControl/management/management-control-form-edit/${form?.data?._id}`,
                            {
                              state: { ...form?.data, defaultTab: selectTab }
                            }
                          )
                        }
                      >
                        <CreateIcon fontSize="small" />
                      </Button>

                      <Button
                        type="button"
                        mold="primary"
                        onClick={() => handleOpenDuplicatePrevisions(form)}
                        style={{
                          padding: '4px 8px',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        <CopyAllIcon fontSize="small" />
                      </Button>

                      <Button
                        type="button"
                        mold="red"
                        onClick={() => alertSwal(async () => handleDeletePrivissions(form))}
                        style={{
                          padding: '4px 8px',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        <Delete fontSize="small" />
                      </Button>
                    </Stack>
                  </Can>
                </Box>
              </Grow>
            )
        )}
      </BoxProducts>

      <ModalDuplicatePrevisions
        maxWidth="sm"
        fullWidth
        aria-labelledby="duplicate-previsions"
        ref={modalDuplicatePrevisionsRef}
      >
        <ModalTitle id="duplicate-previsions">
          <Button
            onClick={handleCloseDuplicatePrevisions}
            mold="primary"
            style={{
              padding: '4px 8px',
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: '16px'
            }}
          >
            <ArrowBackIcon fontSize="small" />
          </Button>

          <span>Duplicar previsão</span>
        </ModalTitle>

        <Stack paddingX={2}>
          <Typography fontWeight="bold" sx={{ marginBottom: 2 }}>
            Selecione mês que você deseja criar uma previsão
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              views={['year', 'month']}
              label="Mês e ano"
              inputFormat="MMMM yyyy"
              value={selectDate}
              onChange={(value) => {
                console.log('date', value)
                setSelectDate(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="date-duplicate-previsions"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <Box paddingRight={1} marginLeft="-3px">
                        {params?.InputProps?.endAdornment}
                      </Box>
                    )
                  }}
                  className="w-100"
                  variant="standard"
                  autoComplete="off"
                  required
                />
              )}
            />
          </LocalizationProvider>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          marginTop={3}
          padding={2}
          justifyContent="flex-end"
        >
          <Button
            mold="red"
            disabled={loading}
            onClick={handleCloseDuplicatePrevisions}
          >
            Cancelar
          </Button>
          <Button
            mold="primary"
            loading={loading}
            disabled={loading}
            onClick={handleDuplicatePrevisions}
          >
            Salvar
          </Button>
        </Stack>
      </ModalDuplicatePrevisions>
    </>
  )
}

export default PrevisionManagement
