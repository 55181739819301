/* eslint-disable operator-linebreak */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import CustomButton from 'components/buttons/CustomButton'

interface Props {
  open: boolean
  data: any[]
  close: () => void
  refetch?: () => void
}

const TableSPC: React.FC<Props> = (props) => {
  const closeModal = () => props.close()

  const [Spc] = useState<any>(props.data['DV-Relato']?.RelatoB49C[0]?.RegistroConsultas?.SPC)

  const [dataValues, setDataValues] = useState<any[]>([])

  useEffect(() => {
    const loadedData = [Spc].every(loading => loading)
    if (loadedData) {
      dateFormat(Spc)
    }
  }, [])

  const dateFormat = (data) => {
    const objFormated = [{
      M0EConsultasSPC: data.hasOwnProperty('M0EConsultasSPC') ? data.M0EConsultasSPC : 0,
      M1ConsultasSPC: data.hasOwnProperty('M1ConsultasSPC') ? data.M1ConsultasSPC : 0,
      M2ConsultasSPC: data.hasOwnProperty('M2ConsultasSPC') ? data.M2ConsultasSPC : 0,
      M3ConsultasSPC: data.hasOwnProperty('M3ConsultasSPC') ? data.M3ConsultasSPC : 0,
      M4ConsultasSPC: data.hasOwnProperty('M4ConsultasSPC') ? data.M4ConsultasSPC : 0,
      M5ConsultasSPC: data.hasOwnProperty('M5ConsultasSPC') ? data.M5ConsultasSPC : 0,
      M6ConsultasSPC: data.hasOwnProperty('M6ConsultasSPC') ? data.M6ConsultasSPC : 0,
      M7ConsultasSPC: data.hasOwnProperty('M7ConsultasSPC') ? data.M7ConsultasSPC : 0,
      M8ConsultasSPC: data.hasOwnProperty('M8ConsultasSPC') ? data.M8ConsultasSPC : 0,
      M9ConsultasSPC: data.hasOwnProperty('M9ConsultasSPC') ? data.M9ConsultasSPC : 0,
      M10ConsultasSPC: data.hasOwnProperty('M10ConsultasSPC') ? data.M10ConsultasSPC : 0,
      M11ConsultasSPC: data.hasOwnProperty('M11ConsultasSPC') ? data.M11ConsultasSPC : 0,
      M12ConsultasSPC: data.hasOwnProperty('M12ConsultasSPC') ? data.M12ConsultasSPC : 0,
      M13ConsultasSPC: data.hasOwnProperty('M13ConsultasSPC') ? data.M13ConsultasSPC : 0,
      QuantidadeTotal: data.hasOwnProperty('QuantidadeTotal') ? data.QuantidadeTotal : 0
    }]

    setDataValues(objFormated)
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={closeModal}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">M0EConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M1ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M2ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M3ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M4ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M5ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M6ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M7ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M8ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M9ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M10ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M11ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M12ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">M12ConsultasSPC</StyledTableCell>
                    <StyledTableCell align="left">Quantidade Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Spc === undefined
                    ?
                    <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <Box
                          sx={{
                            display: 'flex',
                            height: 200,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Typography component="span" variant="body2">
                            Nenhum Registro Encontrado
                          </Typography>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                    :
                    dataValues?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row?.M0EConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M1ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M2ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M3ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M4ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M5ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M6ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M7ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M8ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M9ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M10ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M11ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M12ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.M13ConsultasSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.QuantidadeTotal}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <CustomButton
            mold="primary"
            type="button"
            onClick={closeModal}
            autoFocus
          >
            Voltar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TableSPC
