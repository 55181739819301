import React from 'react'
import { BoxStyle, IconStyle } from './deliveryMap.style'

interface Props {
  showError: boolean
}

export const ShowErrorDeliveryMap: React.FC<Props> = ({ showError = false }) => {
  return (
    <>
      {showError && (
        <BoxStyle>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <IconStyle>signal_wifi_statusbar_connected_no_internet_4</IconStyle>
        </BoxStyle>
      )}
    </>
  )
}
