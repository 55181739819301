/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unreachable */
import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import Button from 'components/form/Button'
import {
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  Box,
  Alert,
  Typography,
  CircularProgress
} from '@mui/material'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import InputFile from 'components/form/Input/InputFile'
import InputMask from 'react-input-mask'
import { onlyNumbers } from 'helpers/stringHelper'
import { ValuesFormCustomerControlType } from 'types/customerControl/FormCustomerControlType'
import { toast } from 'react-toastify'
import { validationInformationSchema } from '../validations/validationInformationSchema'
import {
  createClient,
  getDataClientBigboost,
  updateClient,
  uploadPhotoClient
} from 'services/customerControl/CustomerControlServices'
import { httpClient } from 'config/httpClient'

const TitleSection = styled('p')`
  font: normal normal bold 16px/22px Roboto;
  letter-spacing: 0px;
  color: #262626;
`

interface Props {
  nextStep: () => void
}

const FormCustomerInformation: React.FC<Props> = ({ nextStep }) => {
  const navigate = useNavigate()
  const { state }: any = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingBigboost, setLoadingBigboost] = useState<boolean>(false)

  const handleUploadPhoto = (file: File) => {
    if (!state?.client?._id) return

    const formData = new FormData()
    formData.append('file', file)

    uploadPhotoClient(state.client._id, formData)
      .then(() => {})
      .catch((error: any) => {
        toast.error(error?.response?.data?.message)
      })
  }

  const handleSearchCpfOrCnpjBigboost = async (cpfOrCnpj: string) => {
    setLoadingBigboost(true)
    try {
      const value = cpfOrCnpj
        ?.replace('/', '')
        ?.replaceAll('.', '')
        ?.replace('-', '')
      const data = await getDataClientBigboost(value)

      if (!data?.Result?.length) return null

      return data.Result
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          error?.message ??
          'Error na api do Bigboost'
      )
    } finally {
      setLoadingBigboost(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          _id: state?.client?._id ?? '',
          file: state?.client?.url ?? '',
          name: state?.client?.name ?? '',
          quantidadeIntencaoCompra: state?.client?.quantidadeIntencaoCompra ?? '',
          description: state?.client?.description ?? '',
          pf: {
            cpf: state?.client?.pf?.cpf ?? '',
            tel1: state?.client?.pf?.tel1 ?? '',
            tel2: state?.client?.pf?.tel2 ?? '',
            email: state?.client?.pf?.email ?? '',
            emailContactFinancial:
              state?.client?.pf?.emailContactFinancial ?? '',
            docs: state?.client?.pf?.docs ?? {}
          },
          address: {
            zipcode: state?.client?.address?.zipcode ?? '',
            publicPlace: state?.client?.address?.publicPlace ?? '',
            number: state?.client?.address?.number ?? '',
            district: state?.client?.address?.district ?? '',
            state: state?.client?.address?.state ?? '',
            city: state?.client?.address?.city ?? ''
          },
          differentDeliveryAddress:
            state?.client?.differentDeliveryAddress ?? false,
          deliveryAddress: {
            zipcode: state?.client?.deliveryAddress?.zipcode ?? '',
            publicPlace: state?.client?.deliveryAddress?.publicPlace ?? '',
            number: state?.client?.deliveryAddress?.number ?? '',
            district: state?.client?.deliveryAddress?.district ?? '',
            state: state?.client?.deliveryAddress?.state ?? '',
            city: state?.client?.deliveryAddress?.city ?? ''
          }
        }}
        validationSchema={validationInformationSchema}
        onSubmit={async (values) => {
          const body = {
            type: 'PF',
            _id: state?.client?._id,
            file: values.file || null,
            url: state?.client?.url ?? '',
            description: values?.description ?? 'aa',
            pf: {
              cpf: values?.pf?.cpf?.replaceAll('/', '')
              ?.replaceAll('.', '')
              ?.replaceAll('-', '') ?? '',
              tel1: values?.pf?.tel1 ?? '',
              tel2: values?.pf?.tel2 ?? '',
              email: values?.pf?.email ?? '',
              emailContactFinancial: values?.pf?.emailContactFinancial ?? '',
              docs: state?.client?.pf?.docs ?? {}
            },
            name: values?.name ?? '',
            quantidadeIntencaoCompra: values?.quantidadeIntencaoCompra ?? '',
            address: {
              zipcode: values?.address?.zipcode ?? '',
              publicPlace: values?.address?.publicPlace ?? '',
              number: values?.address?.number ?? '',
              district: values?.address?.district ?? '',
              state: values?.address?.state ?? '',
              city: values?.address?.city ?? ''
            },
            // differentDeliveryAddress: values?.differentDeliveryAddress === 'true' ?? false,
            ...(values?.differentDeliveryAddress
              ? {
                  differentDeliveryAddress: true,
                  deliveryAddress: {
                    zipcode: values?.deliveryAddress?.zipcode ?? '',
                    publicPlace: values?.deliveryAddress?.publicPlace ?? '',
                    number: values?.deliveryAddress?.number ?? '',
                    district: values?.deliveryAddress?.district ?? '',
                    state: values?.deliveryAddress?.state ?? '',
                    city: values?.deliveryAddress?.city ?? ''
                  }
                }
              : {})
          }
          console.log(body)

          const formData = new FormData()
          Object.keys(body).forEach((key) => {
            if (key === 'file') {
              formData.append('file', body.file)
              return
            }

            if (key === '_id') {
              if (state?.client?._id) {
                formData.append('_id', state?.client?._id)
              }
              return
            }

            const value =
              typeof body[key] === 'object'
                ? JSON.stringify(body[key])
                : body[key]
            formData.append(key, value)
          })

          const dadosForm =
            formData as unknown as ValuesFormCustomerControlType

          try {
            setLoading(true)
            if (state?.client?._id) {
              await updateClient(values._id, dadosForm)

              toast.success('Dados alterados com sucesso!')

              navigate('/home/customer-control/form', {
                state: { client: { ...state.client, ...values } }
              })
            } else {
              const { data } = await createClient(dadosForm)

              navigate('/home/customer-control/form', {
                state: { client: data }
              })

              toast.success('Cliente salvo com sucesso!')
            }

            nextStep()
          } catch (error: any) {
            console.log('Error: ', error)
            toast.error(
              error?.response?.data?.message ?? 'Erro ao enviar formulário'
            )
          } finally {
            setLoading(false)
          }
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            <Box sx={{ position: 'relative' }}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <InputFile
                    getFile={async (file, blob) => {
                      setFieldValue('file', blob)
                      handleUploadPhoto(blob)
                    }}
                    preview={state?.client?.logo}
                  />
                  <Alert
                    variant="outlined"
                    severity="info"
                    sx={{
                      border: 'none',
                      padding: 0,
                      justifyContent: 'center'
                    }}
                  >
                    <Typography component="span" color="#262626">
                      <strong>Important</strong> <br />
                      <small>Maximum size 2mb, 256x256 px.</small>
                    </Typography>
                  </Alert>
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <InputMask
                        mask="999.999.999-99"
                        disabled={false}
                        maskChar=""
                        value={values.pf.cpf}
                        onChange={async (e) => {
                          setFieldValue('pf.cpf', e?.target?.value ?? '')
                          if (e?.target?.value?.length === 14) {
                            const data = await handleSearchCpfOrCnpjBigboost(
                              e.target.value
                            )

                            setFieldValue(
                              'name',
                              data?.[0]?.BasicData?.Name ?? ''
                            )
                            setFieldValue(
                              'pf.email',
                              data?.[0]?.Emails?.[0]?.EmailAddress ?? ''
                            )
                            setFieldValue(
                              'pf.emailContactFinancial',
                              data?.[0]?.Emails?.[0]?.EmailAddress ?? ''
                            )
                            setFieldValue(
                              'address.zipcode',
                              data?.[0]?.Addresses?.[0]?.ZipCode ?? ''
                            )
                            setFieldValue(
                              'address.publicPlace',
                              data?.[0]?.Addresses?.[0]?.AddressMain ?? ''
                            )
                            setFieldValue(
                              'address.number',
                              data?.[0]?.Addresses?.[0]?.Number ?? ''
                            )
                            setFieldValue(
                              'address.district',
                              data?.[0]?.Addresses?.[0]?.Neighborhood ?? ''
                            )
                            setFieldValue(
                              'address.state',
                              data?.[0]?.Addresses?.[0]?.State ?? ''
                            )
                            setFieldValue(
                              'address.city',
                              data?.[0]?.Addresses?.[0]?.City ?? ''
                            )
                          }
                        }}
                      >
                        {() => (
                          <TextField
                            error={Boolean(errors.pf?.cpf && touched.pf?.cpf)}
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            label="CPF"
                            helperText={
                              (errors.pf?.cpf && touched.pf?.cpf
                                ? errors.pf?.cpf
                                : null) as React.ReactNode
                            }
                            required
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        error={Boolean(errors.name && touched.name)}
                        value={values.name}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Nome completo"
                        required
                        helperText={
                          (errors.name && touched.name ? errors.name : null) as React.ReactNode
                        }
                        onChange={handleChange('name')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(errors.quantidadeIntencaoCompra && touched.quantidadeIntencaoCompra)}
                        value={values.quantidadeIntencaoCompra}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        type='number'
                        label="Quantidade de intenção de compra"
                        helperText={
                          (errors.quantidadeIntencaoCompra && touched.quantidadeIntencaoCompra ? errors.quantidadeIntencaoCompra : null) as React.ReactNode
                        }
                        onChange={handleChange('quantidadeIntencaoCompra')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputMask
                        mask="(99) 99999-9999"
                        disabled={false}
                        maskChar=""
                        value={values.pf.tel1}
                        onChange={handleChange('pf.tel1')}
                      >
                        {() => (
                          <TextField
                            error={Boolean(errors.pf?.tel1 && touched.pf?.tel1)}
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            label="Contato 1"
                            helperText={
                              (errors.pf?.tel1 && touched.pf?.tel1
                                ? errors.pf?.tel1
                                : null) as React.ReactNode
                            }
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={4}>
                      <InputMask
                        mask="(99) 99999-9999"
                        disabled={false}
                        maskChar=""
                        value={values.pf.tel2}
                        onChange={handleChange('pf.tel2')}
                      >
                        {() => (
                          <TextField
                            error={Boolean(errors.pf?.tel2 && touched.pf?.tel2)}
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            label="Contato 2"
                            helperText={
                              (errors.pf?.tel2 && touched.pf?.tel2
                                ? errors.pf?.tel2
                                : null) as React.ReactNode
                            }
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.pf?.email && touched.pf?.email)}
                        value={values.pf?.email}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="E-mail"
                        type="email"
                        helperText={
                          (errors.pf?.email && touched.pf?.email
                            ? errors.pf?.email
                            : null) as React.ReactNode
                        }
                        onChange={handleChange('pf.email')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.pf?.emailContactFinancial &&
                            touched.pf?.emailContactFinancial
                        )}
                        value={values.pf?.emailContactFinancial}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="E-mail de contato financeiro"
                        type="email"
                        helperText={
                          (errors.pf?.emailContactFinancial &&
                          touched.pf?.emailContactFinancial
                            ? errors.pf?.emailContactFinancial
                            : null) as React.ReactNode
                        }
                        onChange={handleChange('pf.emailContactFinancial')}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <InputMask
                        mask="99999-999"
                        disabled={false}
                        maskChar=""
                        value={values.address?.zipcode}
                        onChange={handleChange('address.zipcode')}
                      >
                        {() => (
                          <TextField
                            error={Boolean(
                              errors.address?.zipcode &&
                                touched.address?.zipcode
                            )}
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            label="CEP"
                            required
                            helperText={
                              (errors.address?.zipcode &&
                              touched.address?.zipcode
                                ? errors.address?.zipcode
                                : null) as React.ReactNode
                            }
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.address?.publicPlace &&
                            touched.address?.publicPlace
                        )}
                        value={values.address?.publicPlace}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Logradouro"
                        required
                        helperText={
                          (errors.address?.publicPlace &&
                          touched.address?.publicPlace
                            ? errors.address?.publicPlace
                            : null) as React.ReactNode
                        }
                        onChange={handleChange('address.publicPlace')}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        error={Boolean(
                          errors.address?.number && touched.address?.number
                        )}
                        value={values.address?.number}
                        variant="standard"
                        type="number"
                        fullWidth
                        autoComplete="off"
                        label="Número"
                        required
                        helperText={
                          (errors.address?.number && touched.address?.number
                            ? errors.address?.number
                            : null) as React.ReactNode
                        }
                        onChange={handleChange('address.number')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.address?.district && touched.address?.district
                        )}
                        value={values.address?.district}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Bairro"
                        required
                        helperText={
                          (errors.address?.district && touched.address?.district
                            ? errors.address?.district
                            : null) as React.ReactNode
                        }
                        onChange={handleChange('address.district')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.address?.state && touched.address?.state
                        )}
                        variant="standard"
                        value={values.address?.state}
                        fullWidth
                        autoComplete="off"
                        label="Estado"
                        required
                        onChange={handleChange('address.state')}
                        helperText={
                          (errors.address?.state && touched.address?.state
                            ? errors.address?.state
                            : null) as React.ReactNode
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.address?.city && touched.address?.city
                        )}
                        value={values.address?.city}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Cidade"
                        required
                        helperText={
                          (errors.address?.city && touched.address?.city
                            ? errors.address?.city
                            : null) as React.ReactNode
                        }
                        onChange={handleChange('address.city')}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={9} sx={{ marginLeft: 'auto' }}>
                  <Box
                    paddingTop={3}
                    paddingBottom={4}
                    display="flex"
                    alignItems="center"
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.differentDeliveryAddress}
                          onChange={handleChange('differentDeliveryAddress')}
                        />
                      }
                      label={
                        <Typography
                          color={
                            values.differentDeliveryAddress
                              ? 'primary'
                              : 'inherit'
                          }
                        >
                          O endereço de entrega é diferente do endereço de
                          cobrança
                        </Typography>
                      }
                    />
                  </Box>

                  {values.differentDeliveryAddress && (
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <TitleSection>Endereço de cobrança</TitleSection>
                      </Grid>

                      <Grid item xs={3}>
                        <InputMask
                          mask="99999-999"
                          disabled={false}
                          maskChar=""
                          value={values.deliveryAddress?.zipcode}
                          onChange={handleChange('deliveryAddress.zipcode')}
                        >
                          {() => (
                            <TextField
                              error={Boolean(
                                errors.deliveryAddress?.zipcode &&
                                  touched.deliveryAddress?.zipcode
                              )}
                              variant="standard"
                              fullWidth
                              autoComplete="off"
                              label="CEP"
                              required={values.differentDeliveryAddress}
                              helperText={
                                (errors.deliveryAddress?.zipcode &&
                                touched.deliveryAddress?.zipcode
                                  ? errors.deliveryAddress?.zipcode
                                  : null) as React.ReactNode
                              }
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(
                            errors.deliveryAddress?.publicPlace &&
                              touched.deliveryAddress?.publicPlace
                          )}
                          value={values.deliveryAddress?.publicPlace}
                          variant="standard"
                          fullWidth
                          autoComplete="off"
                          label="Logradouro"
                          required={values.differentDeliveryAddress}
                          helperText={
                            (errors.deliveryAddress?.publicPlace &&
                            touched.deliveryAddress?.publicPlace
                              ? errors.deliveryAddress?.publicPlace
                              : null) as React.ReactNode
                          }
                          onChange={handleChange('deliveryAddress.publicPlace')}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          error={Boolean(
                            errors.deliveryAddress?.number &&
                              touched.deliveryAddress?.number
                          )}
                          value={values.deliveryAddress?.number}
                          variant="standard"
                          fullWidth
                          autoComplete="off"
                          label="Número"
                          required={values.differentDeliveryAddress}
                          helperText={
                            (errors.deliveryAddress?.number &&
                            touched.deliveryAddress?.number
                              ? errors.deliveryAddress?.number
                              : null) as React.ReactNode
                          }
                          onChange={handleChange('deliveryAddress.number')}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          error={Boolean(
                            errors.deliveryAddress?.district &&
                              touched.deliveryAddress?.district
                          )}
                          value={values.deliveryAddress?.district}
                          variant="standard"
                          fullWidth
                          autoComplete="off"
                          label="Bairro"
                          required={values.differentDeliveryAddress}
                          helperText={
                            (errors.deliveryAddress?.district &&
                            touched.deliveryAddress?.district
                              ? errors.deliveryAddress?.district
                              : null) as React.ReactNode
                          }
                          onChange={handleChange('deliveryAddress.district')}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          error={Boolean(
                            errors.deliveryAddress?.state &&
                              touched.deliveryAddress?.state
                          )}
                          variant="standard"
                          value={values.deliveryAddress?.state}
                          fullWidth
                          autoComplete="off"
                          label="Estado"
                          required={values.differentDeliveryAddress}
                          onChange={handleChange('deliveryAddress.state')}
                          helperText={
                            (errors.deliveryAddress?.state &&
                            touched.deliveryAddress?.state
                              ? errors.deliveryAddress?.state
                              : null) as React.ReactNode
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          error={Boolean(
                            errors.deliveryAddress?.city &&
                              touched.deliveryAddress?.city
                          )}
                          value={values.deliveryAddress?.city}
                          variant="standard"
                          fullWidth
                          autoComplete="off"
                          label="Cidade"
                          required={values.differentDeliveryAddress}
                          helperText={
                            (errors.deliveryAddress?.city &&
                            touched.deliveryAddress?.city
                              ? errors.deliveryAddress?.city
                              : null) as React.ReactNode
                          }
                          onChange={handleChange('deliveryAddress.city')}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {loadingBigboost && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  height="100%"
                  width="100%"
                  bgcolor="rgba(255,255,255,0.7)"
                  p={4}
                >
                  <CircularProgress size={24} />
                  <Typography
                    variant="body2"
                    component="span"
                    fontWeight="bold"
                    mt={1}
                  >
                    Aguarde, Carregando...
                  </Typography>
                </Box>
              )}
            </Box>

            <Grid container spacing={4}>
              <Grid item md={12} className="text-right">
                <Button
                  type="button"
                  mold={!state?.client?._id ? 'red' : undefined}
                  onClick={() => navigate('/home/customer-control')}
                  style={{ marginRight: '10px' }}
                  disabled={loading}
                >
                  {state?.client?._id ? 'Voltar' : 'Cancelar'}
                </Button>
                <Button
                  mold="primary"
                  type="submit"
                  loading={loading}
                  disabled={loading || loadingBigboost}
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default FormCustomerInformation
