import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  TextField
} from '@mui/material'
import React from 'react'

export const getPaper = (data: string[]) => {
  let paper = ''
  data?.forEach((item) => {
    if (item?.length) {
      paper = `${paper}${item},`
    }
  })
  return paper.slice(0, -1)
}

function DataSampleBoxStep(props: any) {
  console.log('ok', props)
  if (props?.formBox?.preftbox) {
    return (
      <Box>
        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Dados da Solicitação
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Número da FT"
                  variant="standard"
                  defaultValue={`FT ${props?.ft?.itemReference}`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Nome da Empresa"
                  variant="standard"
                  defaultValue={props.formBox.client?.name ?? ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Emitido Por"
                  variant="standard"
                  defaultValue={props.formBox.collaborator?.name ?? ''}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Referência</div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label=""
                  variant="standard"
                  defaultValue={props.formBox.preftbox.specifications.description}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Planejamento de P&D
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px',
              pointerEvents: 'none'
            }}
          >
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
              Definição
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.formBox.preftbox.planningRD.definition.justToDo
                      }
                    />
                  }
                  label="Item apenas para desenvolver"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.formBox.preftbox.planningRD.definition
                          .projectAndDo
                      }
                    />
                  }
                  label="Item para projetar e desenvolver"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.formBox.preftbox.planningRD.definition
                          .withSimiliarProject
                      }
                    />
                  }
                  label="Item com projetos similares"
                />
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px',
              pointerEvents: 'none'
            }}
          >
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
              Novo produto
            </div>
            <Grid
              container
              spacing={3}
              mt={0}
              style={{ width: '1000px', marginBottom: -15 }}
            >
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.formBox.preftbox.planningRD.newProduct.basedFTD
                      }
                    />
                  }
                  label="Baseado na FTD"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.formBox.preftbox.planningRD.newProduct
                          .drawingOrSpecification
                      }
                    />
                  }
                  label="Desenho/Especificação"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.formBox.preftbox.planningRD.newProduct
                          .mechanicalSample
                      }
                    />
                  }
                  label="Amostra Mecânica"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.formBox.preftbox.planningRD.newProduct
                          .productInCustomer
                      }
                    />
                  }
                  label="Produto do cliente"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.formBox.preftbox.planningRD.newProduct
                          .similarProduct
                      }
                    />
                  }
                  label="Projeto semelhante"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Outros"
                  variant="standard"
                  defaultValue={
                    props.formBox.preftbox.planningRD.newProduct.other
                  }
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Especificação
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={4}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Descrição"
                  variant="standard"
                  defaultValue={
                    props.formBox.preftbox.reference
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Tipo de caixa"
                  variant="standard"
                  defaultValue={
                    props.formBox.preftbox.specifications.boxType?.name ?? ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Tipo de papel"
                  variant="standard"
                  defaultValue={
                    getPaper([
                      props?.formBox?.preftbox?.specifications?.Composicao?.papel1,
                      props?.formBox?.preftbox?.specifications?.Composicao?.papel2,
                      props?.formBox?.preftbox?.specifications?.Composicao?.papel3,
                      props?.formBox?.preftbox?.specifications?.Composicao?.papel4,
                      props?.formBox?.preftbox?.specifications?.Composicao?.papel5
                    ]) ?? getPaper([
                      props?.formBox?.formulariocaixa?.Composicao?.papel1,
                      props?.formBox?.formulariocaixa?.Composicao?.papel2,
                      props?.formBox?.formulariocaixa?.Composicao?.papel3,
                      props?.formBox?.formulariocaixa?.Composicao?.papel4,
                      props?.formBox?.formulariocaixa?.Composicao?.papel5
                    ]) ?? ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Tipo de onda"
                  variant="standard"
                  defaultValue={
                    props.formBox?.formulariocaixa?.Composicao?.onda ??
                    props.formBox?.preftbox?.specifications?.Composicao?.onda
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Papelão"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.specifications.cardboard}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Modelo"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.specifications.model}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Medidas internas
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px'
            }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Largura"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.internalMeasures.width}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Altura"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.internalMeasures.height}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Diâmetro"
                  variant="standard"
                  defaultValue={
                    props.formBox.preftbox.internalMeasures.diameter
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Gramatura"
                  variant="standard"
                  defaultValue={
                    props.formBox.preftbox.internalMeasures.grammage
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Peso (Kg)"
                  variant="standard"
                  defaultValue={
                    props.formBox.preftbox.internalMeasures.weight ?? 0
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Fechamento"
                  variant="standard"
                  defaultValue={props.formBox?.preftbox?.internalMeasures?.Fechamento?.fechamento ?? props.formBox?.formulariocaixa?.Fechamento?.fechamento}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Qtd de Impressões"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.internalMeasures.prints}
                />
              </Grid>
            </Grid>
          </div>
          <TextField
            id="outlined-multiline-static"
            label="Dados adicionais"
            style={{ marginTop: 20, maxWidth: 800, pointerEvents: 'none' }}
            fullWidth
            multiline
            defaultValue={props.formBox.preftbox.extraData}
            rows={3}
          />
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Gramatura Capa Interna
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px',
              pointerEvents: 'none'
            }}
          >
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
              Amarrado ou arrumado no palete
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Radio checked={props.formBox.preftbox.tied} />}
                  label="Amarrado"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Radio checked={props.formBox.preftbox.tiedPallet} />
                  }
                  label="Arrumado no palete"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Pçs/Amarrado"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.piecesOrTied}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Amarrado/Palete"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.tiedOrPallet}
                />
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px',
              pointerEvents: 'none'
            }}
          >
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
              Alças
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Radio checked={props.formBox.preftbox.handles} />}
                  label="Sim"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Radio checked={!props.formBox.preftbox.handles} />}
                  label="Não"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Alças por caixa"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.handlesValue}
                />
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px',
              pointerEvents: 'none'
            }}
          >
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
              Furo
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Radio checked={props.formBox.preftbox.hole} />}
                  label="Sim"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Radio checked={!props.formBox.preftbox.hole} />}
                  label="Não"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Furos por caixa"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.holeValue}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Informações adicionais
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px',
              pointerEvents: 'none'
            }}
          >
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
              Palete reforçado
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Radio checked={props.formBox.preftbox.reinforcedPallet} />
                  }
                  label="Sim"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Radio checked={!props.formBox.preftbox.reinforcedPallet} />
                  }
                  label="Não"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Qtd de Fita"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.amountTape}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Qtd lastro do palete"
                  variant="standard"
                  defaultValue={props.formBox.preftbox.amountPalletBallast}
                />
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px',
              pointerEvents: 'none'
            }}
          >
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
              Paletizado/Batido
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={props.formBox.preftbox.beatenOrPalletized}
                    />
                  }
                  label="Paletizado"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={!props.formBox.preftbox.beatenOrPalletized}
                    />
                  }
                  label="Batido"
                />
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px',
              pointerEvents: 'none'
            }}
          >
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
              Tara
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Radio checked={props.formBox.preftbox.tare} />}
                  label="Sim"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Radio checked={!props.formBox.preftbox.tare} />}
                  label="Não"
                />
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px',
              pointerEvents: 'none'
            }}
          >
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>
              Laudo NF
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Radio checked={props.formBox.preftbox.reportNF} />}
                  label="Sim"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Radio checked={!props.formBox.preftbox.reportNF} />}
                  label="Não"
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div
            style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: -15 }}
          >
            Amostra
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              pointerEvents: 'none'
            }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Radio checked={props.formBox.preftbox.needSample} />
                  }
                  label="Sim"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Radio checked={!props.formBox.preftbox.needSample} />
                  }
                  label="Não"
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {props.formBox.preftbox.needSample && (
          <TextField
            id="outlined-multiline-static"
            label="Quantidade de amostra"
            style={{ marginTop: 20, maxWidth: 800, pointerEvents: 'none' }}
            fullWidth
            multiline
            defaultValue={props.formBox.preftbox.amountSample}
            rows={3}
          />
        )}

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Quantidade</div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label=""
                  variant="standard"
                  defaultValue={props?.formBox?.qtd ?? 0}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {/* {dados de pré ft acima} */}

        {/* <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Dados da FT</div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Número da FT Amostra" variant="standard" defaultValue={`FT ${(props.formBox.preftbox._id).substring(0, 8)}`} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth id="standard-basic" label="Nome da Empresa" variant="standard" defaultValue="-" />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Emitido Por" variant="standard" defaultValue="-" />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Referência</div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={6}>
                <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue='-' />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Planejamento de P&D</div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Definição</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={4}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Item apenas para desenvolver" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Item para projetar e desenvolver" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Item com projetos similares" />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Novo produto</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px', marginBottom: -15 }}>
              <Grid item xs={4}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Baseado na FTD" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Desenho/Especificação" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Amostra Mecânica" />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={4}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Produto do cliente" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Projeto semelhante" />
              </Grid>
              <Grid item xs={4}>
                <TextField disabled fullWidth id="standard-basic" label="Outros" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Especificação</div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={4}>
                <TextField disabled fullWidth id="standard-basic" label="Descrição" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={4}>
                <TextField disabled fullWidth id="standard-basic" label="Tipo de caixa" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={4}>
                <TextField disabled fullWidth id="standard-basic" label="Tipo de papel" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={4}>
                <TextField disabled fullWidth id="standard-basic" label="Tipo de onda" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={4}>
                <TextField disabled fullWidth id="standard-basic" label="Papelão" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={4}>
                <TextField disabled fullWidth id="standard-basic" label="Modelo" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Medidas internas</div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Largura" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Altura" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Diâmetro" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Gramatura" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Fechamento" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Qtd de Impressões" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>
          <TextField
            id="outlined-multiline-static"
            label="Dados adicionais"
            style={{ marginTop: 20, maxWidth: 1000 }}
            fullWidth
            multiline
            defaultValue={'-'}
            rows={3}
          />
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Gramatura Capa Interna</div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Amarrado ou arrumado no palete</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Amarrado" />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Arrumado no palete" />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Pçs/Amarrado" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Amarrado/Palete" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Alças/Furo</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Sim" />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Não" />
              </Grid>
              <Grid item xs={4}>
                <TextField disabled fullWidth id="standard-basic" label="Quantidade de alças e furos por caixa" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Informações adicionais</div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Palete reforçado</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Sim" />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Não" />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Qtd de Fita" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Qtd lastro do palete" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Paletizado/Batido</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Paletizado" />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Batido" />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Tara</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Sim" />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Não" />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: -15 }}>Amostra</div>
          <div style={{ display: 'flex', flexDirection: 'column', pointerEvents: 'none' }}>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Sim" />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={false} />} label="Não" />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Quantidade de amostra (Unid)" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>
        </div>

        <TextField
          id="outlined-multiline-static"
          label="Observações"
          style={{ marginTop: 20, maxWidth: 1000 }}
          fullWidth
          multiline
          defaultValue={'-'}
          rows={3}
        />

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Dados da Engenharia</div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={4}>
                <TextField disabled fullWidth id="standard-basic" label="Tipo de papelão" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Medidas</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Largura" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Altura" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Área" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Peso bruto" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Ajustes de onduladeira</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={12}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'10'} />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>+</div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'10'} />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>+</div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'10'} />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>=</div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'30'} />
                </div>
              </Grid>
            </Grid>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Observações"
            style={{ marginTop: 20, maxWidth: 1000 }}
            fullWidth
            multiline
            defaultValue={'-'}
            rows={3}
            disabled
          />
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Fluxos</div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={1.5}>
                <TextField disabled fullWidth id="standard-basic" label="Fluxo 1" variant="standard" defaultValue={'1W6'} />
              </Grid>
              <Grid item xs={1.5}>
                <TextField disabled fullWidth id="standard-basic" label="Fluxo 2" variant="standard" defaultValue={'GR'} />
              </Grid>
              <Grid item xs={1.5}>
                <TextField disabled fullWidth id="standard-basic" label="Fluxo 3" variant="standard" defaultValue={'AM'} />
              </Grid>
              <Grid item xs={1.5}>
                <TextField disabled fullWidth id="standard-basic" label="Fluxo 4" variant="standard" defaultValue={'EXP'} />
              </Grid>
              <Grid item xs={1.5}>
                <TextField disabled fullWidth id="standard-basic" label="Fluxo 5" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={1.5}>
                <TextField disabled fullWidth id="standard-basic" label="Fluxo 6" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={1.5}>
                <TextField disabled fullWidth id="standard-basic" label="Fluxo 7" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={1.5}>
                <TextField disabled fullWidth id="standard-basic" label="Fluxo 8" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Dados de acessório</div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Tipo de acessório" variant="standard" defaultValue={'Caixa normal'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Área líquida" variant="standard" defaultValue={'10,12'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Peso líquido" variant="standard" defaultValue={'7.430'} />
              </Grid>
              <Grid item xs={6}>
                <TextField disabled fullWidth id="standard-basic" label="Tipo de papelão" variant="standard" defaultValue={'7.430'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Área bruta" variant="standard" defaultValue={'7.430'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Peso bruto" variant="standard" defaultValue={'7.430'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Quantidade por amarrado" variant="standard" defaultValue={'25'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Fitilhos (LxC)" variant="standard" defaultValue={'2x'} />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Dados de produção</div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Medidas internas</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Comprimento" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Largura" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Altura" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Medidas da peça</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Largura" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Comprimento" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Cores</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Código cor" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Código cor" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Faca</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={12}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'-0'} />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>/</div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'-0'} />
                  <div style={{ marginInline: 18, paddingTop: 12 }}></div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'-0'} />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>/</div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'-0'} />
                </div>
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Fechamento</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={6}>
                <TextField disabled fullWidth id="standard-basic" label="Fechamento" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Quantidade por amarrado" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none', marginRight: 30 }}>
              <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Arranjo</div>
              <Grid container spacing={3} mt={0} style={{ width: '500px' }}>
                <Grid item xs={6}>
                  <TextField disabled fullWidth id="standard-basic" label="Largura" variant="standard" defaultValue={'-'} />
                </Grid>
                <Grid item xs={6}>
                  <TextField disabled fullWidth id="standard-basic" label="Comprimento" variant="standard" defaultValue={'-'} />
                </Grid>
              </Grid>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
              <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Fitilho</div>
              <Grid container spacing={3} mt={0} style={{ width: '500px' }}>
                <Grid item xs={6}>
                  <TextField disabled fullWidth id="standard-basic" label="Largura" variant="standard" defaultValue={'-'} />
                </Grid>
                <Grid item xs={6}>
                  <TextField disabled fullWidth id="standard-basic" label="Comprimento" variant="standard" defaultValue={'-'} />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Ajustes de impressora</div>
            <Grid container spacing={3} mt={0} style={{ width: '700px' }}>
              <Grid item xs={12}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'10'} />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>+</div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'20'} />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>+</div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'30'} />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>+</div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'40'} />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>=</div>
                  <TextField disabled fullWidth id="standard-basic" label="" variant="standard" defaultValue={'100'} />
                </div>
              </Grid>
            </Grid>
          </div>

        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Dados de Paletização</div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Paletizado com" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Peças por palete" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px', pointerEvents: 'none' }}>
            <div style={{ fontSize: '14px', marginTop: 10, marginBottom: -15 }}>Medidas do Palete</div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Largura" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Comprimento" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Lastro" variant="standard" defaultValue={'-'} />
              </Grid>
              <Grid item xs={3}>
                <TextField disabled fullWidth id="standard-basic" label="Altura" variant="standard" defaultValue={'-'} />
              </Grid>
            </Grid>
          </div>
        </div> */}
      </Box>
    )
  } else if (props?.formPlate?.preftplate) {
    return (
      <Box>
        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Dados da Solicitação
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Número da FT"
                  variant="standard"
                  defaultValue={`FT ${props?.ft?.itemReference}`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Nome da Empresa"
                  variant="standard"
                  defaultValue={props.formPlate.client?.name ?? ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Emitido Por"
                  variant="standard"
                  defaultValue={props.formPlate.collaborator?.name ?? ''}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Referência</div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label=""
                  variant="standard"
                  defaultValue={
                    props.formPlate?.formulariochapa?.Referencia ??
                    props.formPlate?.preftplate?.Referencia
                  }
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Especificação
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Nomenclatura"
                  variant="standard"
                  defaultValue={
                    props.formPlate?.formulariochapa?.Composicao
                      ?.nomeComercial ??
                    props.formPlate?.preftplate?.Composicao?.nomeComercial
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Tipo de onda"
                  variant="standard"
                  defaultValue={
                    props.formPlate?.formulariochapa?.Composicao?.onda ??
                    props.formPlate?.preftplate?.Composicao?.onda
                  }
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Dimensões</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px'
            }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '900px' }}>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Largura Interna"
                  variant="standard"
                  defaultValue={
                    props.formPlate?.formulariochapa?.LarguraInterna ??
                    props.formPlate?.preftplate?.LarguraInterna
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Comprimento Interna"
                    variant="standard"
                    defaultValue={`${
                      props.formPlate?.formulariochapa?.ComprimentoInterno ??
                      props.formPlate?.preftplate?.ComprimentoInterno
                    }`}
                  />
                  <div style={{ marginInline: 10, marginTop: 15 }}></div>
                  <TextField
                    // style={{ marginTop: 15 }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Altura Interna"
                    variant="standard"
                    defaultValue={`${
                      props.formPlate?.formulariochapa?.AlturaInterna ??
                      props.formPlate?.preftplate?.AlturaInterna
                    }`}
                  />
                  <div style={{ marginInline: 10, marginTop: 15 }}>=</div>
                  <TextField
                    // style={{ marginTop: 15 }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Total (calculo)"
                    variant="standard"
                    defaultValue={
                      Number(
                        props.formPlate?.formulariochapa?.LarguraInterna ??
                          props.formPlate?.preftplate?.LarguraInterna ??
                          0
                      ) +
                      Number(
                        props.formPlate?.formulariochapa?.ComprimentoInterno ??
                          props.formPlate?.preftplate?.ComprimentoInterno ??
                          0
                      ) +
                      Number(
                        props.formPlate?.formulariochapa?.AlturaInterna ??
                          props.formPlate?.preftplate?.AlturaInterna ??
                          0
                      )
                    }
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={4}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label="Fechamento"
                  variant="standard"
                  defaultValue={
                    props.formPlate?.formulariochapa?.Fechamento?.fechamento ??
                    props.formPlate?.preftplate?.Fechamento?.fechamento
                  }
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '24px', pointerEvents: 'none' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5px'
            }}
          >
            <div
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                marginTop: 20,
                marginBottom: -10
              }}
            >
              Amostra
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '600px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.formPlate?.preftplate?.needSample}
                    />
                  }
                  label="Sim"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!props.formPlate?.preftplate?.needSample}
                    />
                  }
                  label="Não"
                />
              </Grid>
            </Grid>
            <TextField
              className="w-100"
              placeholder="Observações"
              autoComplete="no"
              value={props.formPlate?.preftplate?.extraData ?? ''}
              multiline
              rows={3}
              disabled={true}
            />
          </div>
        </div>

        <div style={{ marginTop: '24px' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Quantidade</div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
          >
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  id="standard-basic"
                  label=""
                  variant="standard"
                  defaultValue={props.formPlate?.qtd ?? 0}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    )
  } else {
    return <div></div>
  }
}

export default DataSampleBoxStep
