/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import ReportClient from './ReportClient'
import ReportDay from './ReportDay'
import { ReportFT } from './ReportFT'
import { ReportItem } from './ReportItem'
import ReportDayAccumulated from './ReportDayAccumulated'

interface Props {
  date: any
  type: string[]
  days: any
  prevision: any
  req1: any
  req2: any
  req3: any
  req5: any
  req6: any
}

const SelectModules: React.FC<Props> = (props) => {
  const [optionView, setOptionView] = React.useState(1)

  return (
    <>
      <FormControl variant="standard" sx={{ width: '200px' }}>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label=""
          value={optionView}
          onChange={(event: any) => {
            setOptionView(event.target.value)
          }}
        >
          <MenuItem value={1}>Por data - dia</MenuItem>
          <MenuItem value={2}>Por data - acumulado</MenuItem>
          <MenuItem value={3}>Por cliente</MenuItem>
          <MenuItem value={4}>Por item</MenuItem>
        </Select>
      </FormControl>

      {optionView === 1 && (
        <ReportDay
          prevision={props.prevision}
          date={props.date}
          type={props.type}
          days={props.days}
          req1={props.req1}
          req2={props.req2}
          req3={props.req3}
        />
      )}

      {optionView === 2 && (
        <ReportDayAccumulated
          prevision={props.prevision}
          date={props.date}
          type={props.type}
          days={props.days}
          req1={props.req1}
          req2={props.req2}
          req3={props.req3}
        />
      )}

      {optionView === 3 && (
        <ReportClient
          prevision={props.prevision}
          date={props.date}
          type={props.type}
          days={props.days}
          req1={props.req1}
          req2={props.req2}
          req3={props.req3}
        />
      )}

      {optionView === 4 && (
        <ReportItem
          prevision={props.prevision}
          date={props.date}
          type={props.type}
          days={props.days}
          req1={props.req1}
          req6={props.req6}
          req5={props.req5}
        />
      )}
    </>
  )
}

export default SelectModules
