import { LoadingButton } from '@mui/lab'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import styled from 'styled-components'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'

export const DivStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 25px;
`

export const LoadingButtonStyle = styled(LoadingButton)`
  background-color: #4060E6;
  border-radius: 2px;
  width: 122px;
  height: 35px;
  color: white;
  margin-top: 10px;
`

export const GridDate = styled.div`
  margin-right: 10px;
`
export const GridButton = styled.div`
  margin-left: 10px;
`

export const DivModules = styled.div`
  margin-top: 5px;
`
export const Tab = styled(TabUnstyled)`
    font-family: IBM Plex Sans, sans-serif;
    color: black;
    cursor: pointer;
    font-size: 0.815rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 0;
    border: 1px solid #eee;
    border-radius: 0px;
    display: flex;
    align-items: flex-start;

    &:hover {
      background-color: #FFF;
    }

    &:focus {
      border-radius: 0px;
      outline: 2px solid white;
      outline-offset: 2px;
    }

    &.${tabUnstyledClasses.selected} {
      background-color: #4060E3;
      color: white;
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `

export const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `
export const TabPanelTable = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`

export const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: #FAFAFA;
    border-radius: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    align-content: space-between;
  `
