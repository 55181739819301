/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'

import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { _changePage } from 'pages/home/slices/home.slice'
import { useDispatch, useSelector } from 'react-redux'
import { pages } from 'pages/home/HomePage'
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import { useQuery } from 'react-query'
import { getCommunications } from 'services/communication/CommunicationService'
import moment from 'moment'
import Button from 'components/form/Button'
import { toast } from 'react-toastify'
import { httpClient } from 'config/httpClient'
import { modules } from 'config/modules'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'core/store'
import { TabPanel } from './components/TabPanel'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const Notifications: React.FC = () => {
  const { loginData } = useSelector((state: RootState) => state.login)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [communications, setCommunications] = React.useState<any[]>([])
  const [newCommunications, setNewCommunications] = React.useState<any[]>([])
  const [value, setValue] = React.useState(0)

  const { data, status, refetch, isFetching } = useQuery(
    'communications',
    getCommunications,
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    const filterData =
      data?.filter(
        (item) =>
          !!item?.profiles?.find(
            (profile) => profile === loginData?.tokenData?.profileId
          )
      ) ?? []
    setCommunications(filterData)
    setNewCommunications(filterData?.filter((item) => !item?.isRead))
  }, [data, isFetching])

  useEffect(() => {
    const page = pages.find((page) => page.route === 'notifications')
    if (page) {
      dispatch(_changePage(page))
    }
  }, [])

  const handleNavigate = (code: string, id: string) => {
    console.log(code, modules)
    const findRoute = modules.find((module) => module.cod === code)

    // if (!findRoute) {
    //   return
    // }

    httpClient
      .put(`/communications/${id}`)
      .then(() => {
        if (findRoute) {
          navigate(`/home/${findRoute.route}`)
        } else {
          refetch()
        }
      })
      .catch((error) => {
        console.log(error)
        if (findRoute) {
          navigate(`/home/${findRoute.route}`)
        } else {
          refetch()
        }
      })
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleClearNotifications = () => {
    const ids = newCommunications.map((item) => item._id)
    httpClient
      .put('communications/many', ids)
      .then(() => {
        refetch()
      })
      .catch((error) => {
        toast.error(error?.response?.message)
      })
  }

  return (
    // <Can
    //   module={MODULES.ALERTAS}
    //   permissions={[PERMISSIONS.READ, PERMISSIONS.UPDATE]}
    //   every
    // >
    <>
      <Typography fontWeight="bold">Notificações do Sistema</Typography>

      <Box py={4} sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Novas" {...a11yProps(0)} />
            <Tab label="Todas" {...a11yProps(1)} />
          </Tabs>

          <Stack direction={'row'} justifyContent="flex-end">
            {status !== 'loading' && !!newCommunications?.length && (
              <Button onClick={handleClearNotifications}>
                Limpar notificações
              </Button>
            )}
          </Stack>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {(status === 'loading' || isFetching) && (
            <CircularProgress size={18} />
          )}
        </Stack>

        <TabPanel value={value} index={0}>
          <Paper sx={{ width: '100%', boxShadow: 'none' }}>
            {status !== 'loading' && !newCommunications?.length && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={100}
              >
                <Typography component="span" variant="body2">
                  Nenhuma notificação nova.
                </Typography>
              </Box>
            )}

            <MenuList dense>
              {newCommunications?.map((item) => (
                <Box key={item?._id}>
                  <MenuItem
                    // disabled={item?.isRead}
                    onClick={() => handleNavigate(item?.code, item?._id)}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      sx={{ width: '100%' }}
                    >
                      <Avatar>{item?.code}</Avatar>
                      <Stack
                        justifyContent="center"
                        pl={2}
                        sx={{ flex: 1, width: '100%' }}
                      >
                        <ListItemText sx={{ whiteSpace: 'normal' }}>
                          {item?.message}
                        </ListItemText>
                        {!item?.isRead && (
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Chip
                              label="Nova"
                              size="small"
                              variant="outlined"
                              sx={{ color: '#3699FF', borderColor: '#3699FF' }}
                            />
                            {/* <Button
                            type="button"
                            mold="primary"
                            onClick={() => {
                              void handleRead(item?._id)
                            }}
                          >
                            Marcar como lida
                          </Button> */}
                          </Stack>
                        )}
                      </Stack>
                      <ListItemText
                        sx={{
                          maxWidth: 100,
                          marginLeft: 2,
                          textAlign: 'right'
                        }}
                      >
                        <Typography variant="body2" whiteSpace="normal">
                          {moment(item?.createdAt).fromNow()}
                        </Typography>
                      </ListItemText>
                    </Stack>
                  </MenuItem>
                  <Divider />
                </Box>
              ))}
            </MenuList>
          </Paper>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Paper sx={{ width: '100%', boxShadow: 'none' }}>
            {status !== 'loading' && !communications?.length && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={100}
              >
                <Typography component="span" variant="body2">
                  Nenhuma notificação encontrada.
                </Typography>
              </Box>
            )}

            <MenuList dense>
              {communications?.map((item) => (
                <Box key={item?._id}>
                  <MenuItem
                    // disabled={item?.isRead}
                    onClick={() => handleNavigate(item?.code, item?._id)}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      sx={{ width: '100%' }}
                    >
                      <Avatar>{item?.code}</Avatar>
                      <Stack
                        justifyContent="center"
                        pl={2}
                        sx={{ flex: 1, width: '100%' }}
                      >
                        <ListItemText sx={{ whiteSpace: 'normal' }}>
                          {item?.message}
                        </ListItemText>
                        {!item?.isRead && (
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Chip
                              label="Nova"
                              size="small"
                              variant="outlined"
                              sx={{ color: '#3699FF', borderColor: '#3699FF' }}
                            />
                            {/* <Button
                            type="button"
                            mold="primary"
                            onClick={() => {
                              void handleRead(item?._id)
                            }}
                          >
                            Marcar como lida
                          </Button> */}
                          </Stack>
                        )}
                      </Stack>
                      <ListItemText
                        sx={{
                          maxWidth: 100,
                          marginLeft: 2,
                          textAlign: 'right'
                        }}
                      >
                        <Typography variant="body2" whiteSpace="normal">
                          {moment(item?.createdAt).fromNow()}
                        </Typography>
                      </ListItemText>
                    </Stack>
                  </MenuItem>
                  <Divider />
                </Box>
              ))}
            </MenuList>
          </Paper>
        </TabPanel>
      </Box>
    </>
    // </Can>
  )
}

export default Notifications
