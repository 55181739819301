import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import Button from 'components/form/Button'
import { httpClient } from 'config/httpClient'
import { useFormik } from 'formik'
import { onlyNumbers } from 'helpers/stringHelper'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPortfolioPapers } from 'services/portfolio/PortfolioService'
import { PortfolioPaperType } from 'types/portfolio/PortfolioPaperType'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const FormEditCoil: React.FC = () => {
  const navigate = useNavigate()
  const { state }: any = useLocation()

  const [papers, setPapers] = useState<PortfolioPaperType[]>([])

  const {
    errors,
    touched,
    values,
    setFieldValue,
    isSubmitting,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: {
      reference: '',
      especification: state?.especification?._id ?? '',
      weight: state?.weight ?? '',
      format: state?.format ?? '',
      diameter: state?.diameter ?? '',
      tubete: state?.tubete ?? '',
      weightPaper: state?.weightPaper ?? '',
      marguerite: state?.marguerite ?? false,
      batoque: state?.batoque ?? false,
      pallet: state?.pallet ?? false,
      description: state?.description ?? '',
      dennison: {
        min: state?.dennison?.min ?? '',
        nom: state?.dennison?.nom ?? '',
        max: state?.dennison?.max ?? ''
      },
      delamination: {
        min: state?.delamination?.min ?? '',
        nom: state?.delamination?.nom ?? '',
        max: state?.delamination?.max ?? ''
      },
      cmt: {
        min: state?.cmt?.min ?? '',
        nom: state?.cmt?.nom ?? '',
        max: state?.cmt?.max ?? ''
      },
      rct: {
        min: state?.rct?.min ?? '',
        nom: state?.rct?.nom ?? '',
        max: state?.rct?.max ?? ''
      },
      ur: {
        min: state?.ur?.min ?? '',
        nom: state?.ur?.nom ?? '',
        max: state?.ur?.max ?? ''
      },
      cobb: {
        min: state?.cobb?.min ?? '',
        nom: state?.cobb?.nom ?? '',
        max: state?.cobb?.max ?? ''
      },
      grammage: {
        min: state?.grammage?.min ?? '',
        nom: state?.grammage?.nom ?? '',
        max: state?.grammage?.max ?? ''
      }
    },
    // validationSchema: {},
    onSubmit(values) {
      console.log('values: ', values)

      const body = {
        ...values,
        dennison: {
          min: values?.dennison?.min || 0,
          nom: values?.dennison?.nom || 0,
          max: values?.dennison?.max || 0
        },
        delamination: {
          min: values?.delamination?.min || 0,
          nom: values?.delamination?.nom || 0,
          max: values?.delamination?.max || 0
        },
        cmt: {
          min: values?.cmt?.min || 0,
          nom: values?.cmt?.nom || 0,
          max: values?.cmt?.max || 0
        },
        rct: {
          min: values?.rct?.min || 0,
          nom: values?.rct?.nom || 0,
          max: values?.rct?.max || 0
        },
        ur: {
          min: values?.ur?.min || 0,
          nom: values?.ur?.nom || 0,
          max: values?.ur?.max || 0
        },
        cobb: {
          min: values?.cobb?.min || 0,
          nom: values?.cobb?.nom || 0,
          max: values?.cobb?.max || 0
        },
        grammage: {
          min: values?.grammage?.min || 0,
          nom: values?.grammage?.nom || 0,
          max: values?.grammage?.max || 0
        }
      }

      httpClient.put(`products/papers/form/${state?._id}`, body).then(() => {
        toast.success('Salvo com sucesso')

        navigate('/home/portfolio')
      }).catch((error) => {
        console.log('Error: ', error)
        toast.error('Erro ao editar bobina e simplex')
      })
    }
  })

  useEffect(() => {
    const weight = values?.weight ? `${values?.weight}G ` : ''
    const format = values?.format ? `${values?.format} MM ` : ''
    const paper = values?.especification
      ? papers?.find((paper) => paper._id === values.especification)
      : ''
    const specification =
      typeof paper !== 'string'
        ? `${paper?.name} ${
            paper?.withsized?.checked ? 'C/ SIZE ' : 'S/ SIZE '
          }`
        : ''

    setFieldValue(
      'reference',
      `${specification}${weight}${
        weight && format ? 'X ' : ''
      }${format}`.toUpperCase()
    )
  }, [values?.especification, values?.weight, values?.format, papers])

  useEffect(() => {
    if (values?.especification) {
      const paper = papers?.find(paper => paper._id === values.especification)

      if (!paper) return

      if (paper.withsized.checked) {
        setFieldValue('cmt.min', paper.withsized.cmt.kgf?.min || '')
        setFieldValue('cmt.max', paper.withsized.cmt.kgf?.min || '')

        setFieldValue('rct.min', paper.withsized.rct.kgf?.min || '')
        setFieldValue('rct.max', paper.withsized.rct.kgf?.min || '')

        setFieldValue('weight', paper.grammage.value)
      } else {
        setFieldValue('cmt.min', paper.withoutsized.cmt)
        setFieldValue('cmt.max', paper.withoutsized.cmt)

        setFieldValue('rct.min', paper.withoutsized.rct)
        setFieldValue('rct.max', paper.withoutsized.rct)
      }

      setFieldValue('ur.min', paper.ur.min)
      setFieldValue('ur.max', paper.ur.max)

      setFieldValue('cobb.min', paper.cobb.min)
      setFieldValue('cobb.max', paper.cobb.max)

      setFieldValue('grammage.min', paper.grammage.min)
      setFieldValue('grammage.max', paper.grammage.max)
    }
  }, [values?.especification, papers])

  useEffect(() => {
    getPortfolioPapers()
      .then((data) => {
        setPapers(data)
      })
      .catch(() => console.log('Erro ao carregar lista de papeis'))

    return () => {
      setPapers([])
    }
  }, [])

  const handleChangeIntegerNumber = (field: string) => (event: any) => {
    const newValue = onlyNumbers(event.target.value)
    setFieldValue(field, newValue ? Math.round(Number(newValue)) : '')
  }

  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Empresa', url: '/home/company' },
          { text: 'Portfólio PCE', url: '/home/portfolio' },
          { text: 'Formulário de Produto' }
        ]}
      />
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box marginTop={2} marginBottom={2}>
          <Typography fontSize={20} fontWeight="500" component="h2">
            Formulário Bobina e Simplex
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="standard-basic"
              placeholder="Referência"
              variant="standard"
              value={values.reference}
              fullWidth
              autoComplete='off'
              disabled
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <h4>Especificação</h4>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="select-paper-standard-label" required>
                Papel
              </InputLabel>
              <Select
                labelId="select-paper-standard-label"
                id="select-paper-standard"
                label="Papel"
                value={values?.especification}
                onChange={(e) => {
                  setFieldValue('especification', e.target.value)
                }}
                MenuProps={MenuProps}
                required
              >
                {papers.map((item: any) => {
                  return (
                    <MenuItem key={item._id} value={item._id}
                    disabled={isSubmitting}>
                      {item.name}{' '}
                      {item?.withsized?.checked ? 'c/ Size' : 's/ Size'}{' '}
                      {item?.withsized?.checked ? item?.grammage?.value : `${item?.grammage?.min} ${item?.grammage?.max}`}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              id="input-weight"
              type="number"
              label="Peso (g)"
              variant="standard"
              value={values.weight}
              error={Boolean(errors.weight && touched.weight)}
              helperText={
                (errors.weight && touched.weight ? errors.weight : null) as React.ReactNode
              }
              onChange={handleChangeIntegerNumber('weight')}
              autoComplete="off"
              fullWidth
              required
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              id="input-format"
              type="number"
              label="Formato (mm)"
              variant="standard"
              value={values.format}
              error={Boolean(errors.format && touched.format)}
              helperText={
                (errors.format && touched.format ? errors.format : null) as React.ReactNode
              }
              onChange={handleChangeIntegerNumber('format')}
              autoComplete="off"
              fullWidth
              required
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="input-diameter"
              type="number"
              label="Diâmetro da Bobina (mm)"
              variant="standard"
              value={values.diameter}
              error={Boolean(errors.diameter && touched.diameter)}
              helperText={
                (errors.diameter && touched.diameter ? errors.diameter : null) as React.ReactNode
              }
              onChange={handleChangeIntegerNumber('diameter')}
              autoComplete="off"
              fullWidth
              required
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              id="input-tubete"
              type="number"
              label="Tubete (mm)"
              variant="standard"
              value={values.tubete}
              error={Boolean(errors.tubete && touched.tubete)}
              helperText={
                (errors.tubete && touched.tubete ? errors.tubete : null) as React.ReactNode
              }
              onChange={handleChangeIntegerNumber('tubete')}
              autoComplete="off"
              fullWidth
              required
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              id="input-weightPaper"
              type="number"
              label="Peso da Bobina (Kg)"
              variant="standard"
              value={values.weightPaper}
              error={Boolean(errors.weightPaper && touched.weightPaper)}
              helperText={
                (errors.weightPaper && touched.weightPaper
                  ? errors.weightPaper
                  : null) as React.ReactNode
              }
              onChange={handleChangeIntegerNumber('weightPaper')}
              autoComplete="off"
              fullWidth
              required
              disabled={isSubmitting}
            />
          </Grid>
          <Grid container sx={{ paddingLeft: '16px', marginBottom: 2 }}>
            <Grid item xs={3} style={{ marginTop: '25px' }}>
              <Typography component="h4" fontWeight="bold">
                Testes Físicos
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ marginTop: '25px' }}>
              <h4>Mínimo</h4>
            </Grid>
            <Grid item xs={3} style={{ marginTop: '25px' }}>
              <h4>Nominal</h4>
            </Grid>
            <Grid item xs={3} style={{ marginTop: '25px' }}>
              <h4>Máximo</h4>
            </Grid>
          </Grid>
          {/* Start | Testes Físicos */}
          <Grid container spacing={2} style={{ paddingLeft: '16px' }}>
            <Grid item xs={3}>
              Gramatura (g/m2)
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.grammage.min}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                type='number'
                value={values.grammage.nom}
                onChange={handleChangeIntegerNumber('grammage.nom')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.grammage.max}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              Cobb (gH20/M2)
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.cobb.min}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                type='number'
                value={values.cobb.nom}
                onChange={handleChangeIntegerNumber('cobb.nom')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.cobb.max}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              Dennison
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                type="number"
                variant="standard"
                value={values.dennison.min}
                error={Boolean(errors.dennison?.min && touched.dennison?.min)}
                helperText={
                  (errors.dennison?.min && touched.dennison?.min
                    ? errors.dennison?.min
                    : null) as React.ReactNode
                }
                onChange={handleChangeIntegerNumber('dennison.min')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                type="number"
                variant="standard"
                value={values.dennison.nom}
                error={Boolean(errors.dennison?.nom && touched.dennison?.nom)}
                helperText={
                  (errors.dennison?.nom && touched.dennison?.nom
                    ? errors.dennison?.nom
                    : null) as React.ReactNode
                }
                onChange={handleChangeIntegerNumber('dennison.nom')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                type="number"
                variant="standard"
                value={values.dennison.max}
                error={Boolean(errors.dennison?.max && touched.dennison?.max)}
                helperText={
                  (errors.dennison?.max && touched.dennison?.max
                    ? errors.dennison?.max
                    : null) as React.ReactNode
                }
                onChange={handleChangeIntegerNumber('dennison.max')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>

            <Grid item xs={3}>
              Delaminação
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                type="number"
                variant="standard"
                value={values.delamination.min}
                error={Boolean(errors.delamination?.min && touched.delamination?.min)}
                helperText={
                  (errors.delamination?.min && touched.delamination?.min
                    ? errors.delamination?.min
                    : null) as React.ReactNode
                }
                onChange={handleChangeIntegerNumber('delamination.min')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                type="number"
                variant="standard"
                value={values.delamination.nom}
                error={Boolean(errors.delamination?.nom && touched.delamination?.nom)}
                helperText={
                  (errors.delamination?.nom && touched.delamination?.nom
                    ? errors.delamination?.nom
                    : null) as React.ReactNode
                }
                onChange={handleChangeIntegerNumber('delamination.nom')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                type="number"
                variant="standard"
                value={values.delamination.max}
                error={Boolean(errors.delamination?.max && touched.delamination?.max)}
                helperText={
                  (errors.delamination?.max && touched.delamination?.max
                    ? errors.delamination?.max
                    : null) as React.ReactNode
                }
                onChange={handleChangeIntegerNumber('delamination.max')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>

            <Grid item xs={3}>
              CMT (Kgf)
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.cmt.min}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                type='number'
                value={values.cmt.nom}
                onChange={handleChangeIntegerNumber('cmt.nom')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.cmt.max}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              RCT (Kgf)
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.rct.min}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                type='number'
                value={values.rct.nom}
                onChange={handleChangeIntegerNumber('rct.nom')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.rct.max}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              Umidade
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.ur.min}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                type='number'
                variant="standard"
                value={values.ur.nom}
                onChange={handleChangeIntegerNumber('ur.nom')}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                variant="standard"
                value={values.ur.max}
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          {/* End | Testes Físicos */}
          <Grid item xs={4} md={2} style={{ marginTop: '25px' }}>
            <FormControlLabel
              control={<Checkbox checked={values.marguerite} onChange={handleChange('marguerite')} />}
              label="Margarida"
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={4} md={2} style={{ marginTop: '25px' }}>
            <FormControlLabel
              control={<Checkbox checked={values.pallet} onChange={handleChange('pallet')} />}
              label="Pallet"
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={4} md={2} style={{ marginTop: '25px' }}>
            <FormControlLabel
              control={<Checkbox checked={values.batoque} onChange={handleChange('batoque')} />}
              label="Batoque"
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '30px' }}>
            <TextField
              className="w-100"
              placeholder="Observações"
              autoComplete='off'
              value={values.description}
              onChange={handleChange('description')}
              multiline
              rows={3}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid
            item
            md={12}
            className="text-right"
            style={{
              alignSelf: 'flex-end',
              justifySelf: 'self-end',
              marginTop: '10%'
            }}
          >
            <Button
              mold="red"
              type="button"
              onClick={() => navigate('/home/portfolio', { state: undefined })}
              style={{ marginRight: '10px' }}
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button
              mold="primary"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {'SALVAR'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default FormEditCoil
