/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material'
import {
  StyledTableCell,
  StyledTableRow
} from 'pages/company/portfolio/components/table.styles'
import { Link, useNavigate } from 'react-router-dom'
import {
  getIe,
  getItemTrimpaper
} from 'services/customerControl/CustomerControlServices'
import moment from 'moment'
import { httpClient, httpLN } from 'config/httpClient'
import { toast } from 'react-toastify'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

interface Props {
  search?: string
  companyId: string
  collaboratorId: string
}

const ItemsCoil: React.FC<Props> = ({ search = '', companyId, collaboratorId }) => {
  const [page, setPage] = useState(0)
  const navigate = useNavigate()

  const [items, setItems] = useState<any[]>([])
  const [itemsBobina, setItemsBobina] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingRequest, setLoadingRequest] = useState(false)

  const loadItems = useCallback(async (ies) => {
    setItems([])
    setLoading(true)
    for (const ie of ies) {
      try {
        const { data } = await getItemTrimpaper(ie?.codeLn)
        console.log(
          '🚀 ~ file: ItemsCoil.tsx ~ line 70 ~ loadItems ~ data',
          data, itemsBobina
        )

        const parseData = data?.map((item) => {
          const findItemBobina = itemsBobina?.find((bobina) => bobina?.codItem === item?.extra1)
          return {
            ...item,
            descricaoBobina: findItemBobina?.descricao,
            clientID: ie?.codeLn
          }
        })

        setItems((state) =>
          state?.length ? [...state, ...parseData] : parseData
        )
      } catch (error) {
        // console.log(error.status)
      }
    }
    setLoading(false)
  }, [])

  const loadIes = useCallback(() => {
    getIe(companyId)
      .then((response) => {
        // setIes(response?.data ?? [])
        loadItems(response?.data ?? [])
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        setLoading(false)
      })
  }, [companyId])

  const fetchItemsBobina = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await httpLN.get('/order/itemsBobina')
      setItemsBobina(data?.result ?? [])
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      void loadIes()
    }
  }, [])

  useEffect(() => {
    fetchItemsBobina()
  }, [companyId])

  const handleSelectItem = (item, checked) => {
    setItems((state) =>
      state.map((itemCoil) =>
        itemCoil?.idPedido === item?.idPedido
          ? {
              ...itemCoil,
              checked
            }
          : itemCoil
      )
    )
  }

  const handleCreateRequest = async () => {
    const selectedFTs = items
      ?.filter((item) => item?.checked)
      ?.map((item) => ({
        clientID: item.clientID,
        codItem: item.extra1,
        preco: item.precoVenda
      }))

    if (!selectedFTs?.length) {
      alert(
        'Nenhum item selecionado. Para fazer um pedido deve selecionar um ou mais.'
      )
      return
    }

    setLoadingRequest(true)

    toast.promise(
      httpClient.post(`/pedidos/bobina/company/${companyId}`, {
        items: selectedFTs
      }),
      {
        pending: 'Aguarde, criando pedido...',
        success: {
          render() {
            setItems((state) =>
              state?.map((item) => ({ ...item, checked: false }))
            )
            setLoadingRequest(false)
            navigate('/home/purchaseRequests')
            return 'Pedido de bobina realizado com sucesso'
          }
        },
        error: {
          render({ data }: any) {
            setLoadingRequest(false)
            return `Erro ao criar pedido. ${data?.message}`
          }
        }
      }
    )
    // try {
    //   await httpClient.post(`/pedidos/bobina/company/${companyId}`, {
    //     items: selectedFTs
    //   })

    //   setItems((state) => state?.map((item) => ({ ...item, checked: false })))

    //   // toWithParams('TabProject', { item: { tab: 'Pedidos' } }, true)
    //   // navigate('Pedidos')
    // } catch (error) {
    //   alert(error)
    // } finally {
    //   setLoadingRequest(false)
    // }
  }

  console.log('itens', items)

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell> </StyledTableCell>
              <StyledTableCell>Código do Item</StyledTableCell>
              <StyledTableCell>Papel</StyledTableCell>
              <StyledTableCell>Descrição</StyledTableCell>
              <StyledTableCell>Preço</StyledTableCell>
              <StyledTableCell>Criado em</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <StyledTableRow>
                <StyledTableCell colSpan={5} align="center">
                  <CircularProgress size={24} />
                </StyledTableCell>
              </StyledTableRow>
            )}

            {!loading &&
              items
                ?.filter(
                  (item) =>
                    item?.extra1?.includes(search) ||
                    item?.descricao?.toLowerCase()?.includes(search.toLowerCase()) ||
                    item?.codPapel?.toLowerCase()?.includes(search.toLowerCase()) ||
                    item?.nomeFantasia?.toLowerCase()?.includes(search.toLowerCase())
                )
                .slice(page * 5, page * 5 + 5)
                .map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Checkbox
                          {...label}
                          checked={!!row?.checked}
                          onChange={(_, checked) =>
                            handleSelectItem(row, checked)
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.codPedido}
                      </StyledTableCell>
                      <StyledTableCell>{row?.codPapel?.toUpperCase()}</StyledTableCell>
                      <StyledTableCell>{row?.descricao}</StyledTableCell>
                      <StyledTableCell>
                        R$ {row?.precoVenda?.toLocaleString('pt-BR')}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.dataCriacao &&
                          moment(row.dataCriacao).format(
                            'DD/MM/YYYY [às] HH:mm'
                          )}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell colSpan={6}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    items?.filter((item) => item.checked === true)?.length <=
                      0 || loadingRequest
                  }
                  onClick={handleCreateRequest}
                >
                  Fazer pedido
                </Button>
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5]}
          rowsPerPage={5}
          component="div"
          count={
            items?.filter(
              (item) =>
                item?.extra1?.includes(search) ||
                item?.descricao?.toLowerCase()?.includes(search.toLowerCase())
            ).length
          }
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      </TableContainer>

      <Stack direction="row" justifyContent="flex-end" sx={{ paddingTop: 4 }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/home/purchaseRequests"
        >
          Voltar
        </Button>
      </Stack>
    </>
  )
}

export default ItemsCoil
