/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, PureComponent, useRef, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

import { LoadingDeliveryMap } from '../components/LoadingDeliveryMap'
import { ShowErrorDeliveryMap } from '../components/ShowErrorDeliveryMap'
import { Box, Stack, TextField } from '@mui/material'
import Button from 'components/form/Button'
import { Clear, SearchOutlined } from '@mui/icons-material'
import {
  getCodeParceirosByClientType,
  getCodeParceirosByPilotLot,
  getOrderServiceByCoilAll,
  getOrderServiceByCoilPaginated,
  getOrderServiceByPlateAll,
  getOrderServiceByPlatePaginated,
  getSellerOrderByTypePaginated,
  getSellerOrderByTypePaginatedAll
} from 'services/report/DeliveryMapService'
import moment from 'moment'

interface Props {
  date?: Date
}

const OTDeOTIFMap: React.FC<Props> = (props) => {
  const [dataValues, setDataValues] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState('')
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')

  const [clientsCarteira, setClientsCarteira] = useState([])
  const [clientsKanban, setClientsKanban] = useState([])
  const [clientsPilotLot, setClientsPilotLot] = useState([])

  const [carteiraKanbanPilotLotOTDCX, setCarteiraKanbanPilotLotOTDCX] =
    useState(0)
  const [carteiraKanbanPilotLotOTIFCX, setCarteiraKanbanPilotLotOTIFCX] =
    useState(0)

  const [carteiraKanbanPilotLotOTDCH, setCarteiraKanbanPilotLotOTDCH] =
    useState(0)
  const [carteiraKanbanPilotLotOTIFCH, setCarteiraKanbanPilotLotOTIFCH] =
    useState(0)

  const [oTDPP, setOTDPP] = useState(0)
  const [oTIFPP, setOTIFPP] = useState(0)

  const getClientesCarteira = useQuery(
    ['deliveryMap-OTDeOTIFMap-carteira'],
    async () => {
      return getCodeParceirosByClientType('CARTEIRA')
    },
    {
      onSuccess: async (data) => {
        setClientsCarteira(data ?? [])
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const getClientesKanban = useQuery(
    ['deliveryMap-OTDeOTIFMap-kanban'],
    async () => {
      return getCodeParceirosByClientType('KANBAN')
    },
    {
      onSuccess: async (data) => {
        setClientsKanban(data ?? [])
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const getPilotLot = useQuery(
    ['deliveryMap-OTDeOTIFMap-PilotLotContainer'],
    async () => {
      return getCodeParceirosByPilotLot()
    },
    {
      onSuccess: async (data) => {
        setClientsPilotLot(data ?? [])
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const getAllKanbanCarteiraPilot = useQuery(
    ['deliveryMap-OTDeOTIFMap-KanbanCarteiraPilot'],
    async () => {
      return getSellerOrderByTypePaginatedAll(
        0,
        moment(props.date).format('YYYY/MM/DD'),
        [...clientsCarteira, ...clientsKanban, ...clientsPilotLot],
        search?.toUpperCase(),
        'no'
      )
    },
    {
      onSuccess: async (data) => {
        if (data?.result?.length) {
          let totalQuantidadeFaturada = 0
          let totalOT2 = 0
          let totalIFValue = 0
          let count = 0

          for (let i = 0; i < data?.result?.length; i++) {
            const item = data?.result?.[i]
            totalQuantidadeFaturada +=
              item.quantidadeFaturada > 0
                ? parseFloat(item.quantidadeFaturada)
                : 0
            totalOT2 += item.ot2 > 0 ? parseFloat(item.ot2) : 0

            const iFValue =
              item.quantidadeFaturada > 0 && item.quantidadeSolicitada > 0
                ? (parseFloat(item.quantidadeFaturada) /
                    parseFloat(item.quantidadeSolicitada)) *
                    100 >
                  100
                  ? 100
                  : (parseFloat(item.quantidadeFaturada) /
                      parseFloat(item.quantidadeSolicitada)) *
                    100
                : 0
            totalIFValue += iFValue
            count++
          }

          const resultado = (totalOT2 / totalQuantidadeFaturada) * 100
          console.log(Number(resultado?.toFixed(0)))
          setCarteiraKanbanPilotLotOTDCX(Number(resultado?.toFixed(0)))

          const mediaIFValue = totalIFValue / count
          console.log(
            Number(mediaIFValue?.toFixed(0)),
            Number(Number((mediaIFValue * resultado) / 100)?.toFixed(0))
          )
          setCarteiraKanbanPilotLotOTIFCX(
            Number(Number((mediaIFValue * resultado) / 100)?.toFixed(0))
          )
        }
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const getAllPlate = useQuery(
    ['deliveryMap-OTDeOTIFMap-Plate'],
    async () => {
      return getOrderServiceByPlateAll(
        0,
        moment(props.date).format('YYYY/MM/DD'),
        search?.toUpperCase(),
        'no'
      )
    },
    {
      onSuccess: async (data) => {
        if (data?.result?.length) {
          let totalQuantidadeFaturada = 0
          let totalOT2 = 0
          let totalIFValue = 0
          let count = 0

          for (let i = 0; i < data?.result?.length; i++) {
            const item = data?.result?.[i]
            totalQuantidadeFaturada +=
              item.quantidadeFaturada > 0
                ? parseFloat(item.quantidadeFaturada)
                : 0
            totalOT2 += item.ot2 > 0 ? parseFloat(item.ot2) : 0

            const iFValue =
              item.quantidadeFaturada > 0 && item.quantidadeSolicitada > 0
                ? (parseFloat(item.quantidadeFaturada) /
                    parseFloat(item.quantidadeSolicitada)) *
                    100 >
                  100
                  ? 100
                  : (parseFloat(item.quantidadeFaturada) /
                      parseFloat(item.quantidadeSolicitada)) *
                    100
                : 0
            totalIFValue += iFValue
            count++
          }

          const resultado = (totalOT2 / totalQuantidadeFaturada) * 100
          console.log(Number(resultado?.toFixed(0)))
          setCarteiraKanbanPilotLotOTDCH(Number(resultado?.toFixed(0)))

          const mediaIFValue = totalIFValue / count
          console.log(
            Number(mediaIFValue?.toFixed(0)),
            Number(Number((mediaIFValue * resultado) / 100)?.toFixed(0))
          )
          setCarteiraKanbanPilotLotOTIFCH(
            Number(Number((mediaIFValue * resultado) / 100)?.toFixed(0))
          )
        }
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const getAllCoil = useQuery(
    ['deliveryMap-OTDeOTIFMap-CoilDelay'],
    async () => {
      return getOrderServiceByCoilAll(
        0,
        moment(props.date).format('YYYY/MM/DD'),
        search?.toUpperCase(),
        'no'
      )
    },
    {
      onSuccess: async (data) => {
        if (data?.result?.length) {
          let totalQuantidadeFaturada = 0
          let totalOT2 = 0
          let totalIFValue = 0
          let count = 0

          for (let i = 0; i < data?.result?.length; i++) {
            const item = data?.result?.[i]
            totalQuantidadeFaturada +=
              item.quantidadeFaturada > 0
                ? parseFloat(item.quantidadeFaturada)
                : 0
            totalOT2 += item.ot2 > 0 ? parseFloat(item.ot2) : 0

            const iFValue =
              item.quantidadeFaturada > 0 && item.quantidadeSolicitada > 0
                ? (parseFloat(item.quantidadeFaturada) /
                    parseFloat(item.quantidadeSolicitada)) *
                    100 >
                  100
                  ? 100
                  : (parseFloat(item.quantidadeFaturada) /
                      parseFloat(item.quantidadeSolicitada)) *
                    100
                : 0
            totalIFValue += iFValue
            count++
          }

          const resultado = (totalOT2 / totalQuantidadeFaturada) * 100
          console.log(Number(resultado?.toFixed(0)))
          setOTDPP(Number(resultado?.toFixed(0)))

          const mediaIFValue = totalIFValue / count
          console.log(
            Number(mediaIFValue?.toFixed(0)),
            Number(Number((mediaIFValue * resultado) / 100)?.toFixed(0))
          )
          setOTIFPP(
            Number(Number((mediaIFValue * resultado) / 100)?.toFixed(0))
          )
        }
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const { isError, isLoading, isFetching } = useQuery(
    ['deliveryMap-OTDeOTIFMap-cx'],
    async () => {
      return []
    },
    {
      onSuccess: async (data) => {
        setDataValues(data ?? [])
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  useEffect(() => {
    if (
      getClientesCarteira?.status === 'success' &&
      getClientesKanban?.status === 'success' &&
      getPilotLot?.status === 'success'
    ) {
      getAllKanbanCarteiraPilot.refetch()
    }
  }, [
    getClientesCarteira?.status,
    getClientesCarteira?.data,
    getClientesKanban?.status,
    getClientesKanban?.data,
    getPilotLot?.status,
    getPilotLot?.data
  ])

  const handleSearch = () => {
    setSearch(searchValue)

    // setTimeout(() => {
    //   refetch()
    // }, 300)
  }

  const handleClearSearch = () => {
    setSearch('')
    setSearchValue('')

    if (inputSearchRef?.current?.value) {
      inputSearchRef.current.value = ''
    }

    // setTimeout(() => {
    //   refetch()
    // }, 300)
  }

  const data = [
    {
      name: 'CX',
      OTIF: carteiraKanbanPilotLotOTIFCX ?? 0,
      OTD: carteiraKanbanPilotLotOTDCX ?? 0,
      amt: 2400
    },
    {
      name: 'CH',
      OTIF: carteiraKanbanPilotLotOTIFCH ?? 0,
      OTD: carteiraKanbanPilotLotOTDCH ?? 0,
      amt: 2210
    },
    {
      name: 'PP',
      OTIF: oTDPP ?? 0,
      OTD: oTIFPP ?? 0,
      amt: 2290
    }
  ]

  return (
    <>
      <Stack direction="row" spacing={2} maxWidth={'100%'} sx={{ paddingY: 2 }}>
        <TextField
          label="Pesquisar por cliente"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          inputRef={inputSearchRef}
          variant="standard"
          fullWidth
        />
        <Button
          mold="primary"
          type="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={handleSearch}
          disabled={isFetching}
        >
          <SearchOutlined />
        </Button>

        {(!!search?.length || !!inputSearchRef?.current?.value?.length) && (
          <Button
            mold="red"
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 4
            }}
            onClick={handleClearSearch}
            disabled={isFetching}
          >
            <Clear />
          </Button>
        )}
      </Stack>

      <LoadingDeliveryMap
        isLoading={
          isLoading ||
          isFetching ||
          getAllCoil?.isFetching ||
          getAllKanbanCarteiraPilot?.isFetching ||
          getAllPlate?.isFetching
        }
      />

      <ShowErrorDeliveryMap showError={isError} />

      {!getAllCoil?.isFetching &&
        !getAllKanbanCarteiraPilot?.isFetching &&
        !getAllPlate?.isFetching && (
          <Box width={'100%'} maxWidth={800} mx={'auto'} minHeight={500}>
            <ResponsiveContainer width={'100%'} height={500} debounce={50}>
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="OTIF" name="OTIF (%)" fill="#4a6fc1" />
                <Bar dataKey="OTD" name="OTD (%)" fill="#ec823e" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
    </>
  )
}

export default OTDeOTIFMap
