/* eslint-disable @typescript-eslint/no-unused-vars */

import { styled } from '@mui/material/styles'

import TableCell, {
  tableCellClasses,
  TableCellProps
} from '@mui/material/TableCell'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
  [`&.${tableCellClasses.footer}`]: {
    fontWeight: 'bold'
  }

}))

interface Props extends TableCellProps {
  value?: number | string
  type: 'RS' | 'KG'
}

export function TableCellKgOrRs({ value, type, ...rest }: Props) {
  return (
    <>
      {type === 'KG'
? (
        <StyledTableCell align="left" sx={{ whiteSpace: 'nowrap' }} {...rest}>
          {Number(value ?? 0).toLocaleString('pt-br')}
        </StyledTableCell>
      )
: (
        <StyledTableCell align="left" sx={{ whiteSpace: 'nowrap' }} {...rest}>
          R${' '}
          {Number(value ?? 0).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </StyledTableCell>
      )}
    </>
  )
}
