import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import TableAppClient from './TableAppClient'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const ColumnStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px
`
const ButtonStyle = styled(Button)`
  background-color: #4060e6;
  border-radius: 2px;
  width: 122px;
  color: white;
`

const AppClientList: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Box>
      <div>Representantes que ainda não acessaram o app</div>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Usuários', url: '/home/registration' },
          { text: 'Link de reenvio' }
        ]}
      />
      <TableAppClient />
      <ColumnStyle>
        <ButtonStyle onClick={() => navigate('/home/registration')}>
          Voltar
        </ButtonStyle>
      </ColumnStyle>
    </Box>
  )
}

export default AppClientList
