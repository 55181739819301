/* eslint-disable operator-linebreak */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-self-compare */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import CustomButton from 'components/buttons/CustomButton'
import moment from 'moment-timezone'

interface Props {
  open: boolean
  data: any[]
  close: () => void
  refetch?: () => void
}

const TableDVRFInternal: React.FC<Props> = (props) => {
  const closeModal = () => props.close()
  const [dataValues, setDataValues] = useState<any[]>([])
  const [DVRF] = useState<any>(props.data['DV-RFInternal'].RFInternal[0])
  const [statusTable] = useState<any>(DVRF.Status.hasOwnProperty('Mensagem'))
  const [infor, setInfor] = useState<any>()

  useEffect(() => {
    const loadedData = [DVRF].every(loading => loading)
    if (loadedData) {
      handleData(DVRF)
    }
  }, [])

  const handleData = (data) => {
    if (statusTable) {
      setInfor(data.Status.Mensagem)
    } else {
      const formated = [{
        nome: data?.IC28_100?.Nome ?? '',
        data_situacao: moment(data?.IC28_100['Data Situacao']).format('DD/MM/YYYY') ?? '',
        situacao: data?.IC28_100?.Situacao ?? '',
        codigo: data.Status.Codigo ?? ''
      }]
      setDataValues(formated)
    }
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={closeModal}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Nome</StyledTableCell>
                    <StyledTableCell align="left">Data da situação</StyledTableCell>
                    <StyledTableCell align="left">Situação</StyledTableCell>
                    <StyledTableCell align="left">Codigo</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statusTable
                    ?
                    <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <Box
                          sx={{
                            display: 'flex',
                            height: 200,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Typography component="span" variant="body2">
                            {infor}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                    :
                    dataValues?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row.nome}</StyledTableCell>
                        <StyledTableCell align="left">{row.data_situacao}</StyledTableCell>
                        <StyledTableCell align="left">{row.situacao}</StyledTableCell>
                        <StyledTableCell align="left">{row.codigo}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <CustomButton
            mold="primary"
            type="button"
            onClick={closeModal}
            autoFocus
          >
            Voltar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TableDVRFInternal
