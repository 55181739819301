/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { Status } from 'components/Status'
import { maskCpfOrCnpj } from 'helpers/maskHelper'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ReportProposalForDirectionTableHead } from './ReportProposalForDirectionTableHead'
import ResumeBudget from './ResumeBudget'
import ResumeProposalForm from './ResumeProposalForm'
import { StyledTableCell, StyledTableRow } from './table.styles'
import { TableActionButtons } from './TableActionButtons'
import { toBRL } from 'utils/formatString'
import Button from 'components/form/Button'
import alertSuccess from 'components/Alerts/ModalSucess'
import { putApproveProposal, putReproveProposal } from 'services/ft/FTService'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'

interface Props {
  data: any
  refetch: () => void
}

export const ReportProposalForDirectionTable: React.FC<Props> = ({
  data,
  refetch
}) => {
  const [rows, setRows] = useState(data?.docs ?? [])
  const [selectProposal, setSelectProposal] = useState<any>()
  const [openProposalModal, setOpenProposalModal] = React.useState(false)
  const [selectBudget, setSelectBudget] = useState<any>()
  const [openBudgetModal, setOpenBudgetModal] = React.useState(false)
  const { loginData } = useSelector((state: RootState) => state.login)

  useEffect(() => {
    setRows(data?.docs ?? [])
  }, [data?.docs?.length])

  const handleConfirmProposal = () => {
    setOpenProposalModal(false)
    refetch()
  }

  const { mutate: mutateCreate } = useMutation(putApproveProposal, {
    onSuccess: (data) => {
      alertSuccess(
        'Proposta de orçamento atualizada com sucesso!',
        'O Cliente poderá aprovar ou reprovar a proposta.',
        () => {
          refetch()
        }
      )
    },
    onError: () => {
      toast.error('Não foi possível criar a proposta')
    }
  })

  const handleAprove = (row) => {
    mutateCreate({ ...row?.propostas, price: row?.propostas?.price, user: loginData?.tokenData.sub })
  }

  const handleReproval = async (row) => {
    try {
      await putReproveProposal(row?.propostas?._id ?? '', '')
      refetch()
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
        'Não foi possível reprovar a proposta.'
      )
    }
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
          <ReportProposalForDirectionTableHead />

          <TableBody>
            {!rows?.length && (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={7}>
                  Nenhuma proposta pendente de aprovação
                </StyledTableCell>
              </StyledTableRow>
            )}

            {rows.map((row, index) => (
              <StyledTableRow key={`${row?._id}`}>
                <StyledTableCell align="left">
                  {row?.fts?.[0]?.itemReference}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography component="span" variant="body2">
                    {moment(row?.propostas?.createdAt).format('DD/MM/YYYY')}
                  </Typography>
                </StyledTableCell>
                {
                  /*
                  <StyledTableCell align="left">
                    <Typography component="span" variant="body2" noWrap>
                      {row?.fts?.[0]?.prefts?.[0]?.cliente?.[0]?.type === 'PF'
                        ? maskCpfOrCnpj(
                            row?.fts?.[0]?.prefts?.[0]?.cliente?.[0]?.pf?.cpf ??
                              ''
                          )
                        : maskCpfOrCnpj(
                            row?.fts?.[0]?.prefts?.[0]?.cliente?.[0]?.pj?.cnpj ??
                              ''
                          )}
                    </Typography>
                  </StyledTableCell>
                  */
                }
                <StyledTableCell align="left">
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ display: 'flex' }}
                  >
                    {row?.fts?.[0]?.prefts?.[0]?.cliente?.[0]?.name}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row?.fts?.[0]?.prefts?.[0]?.vendedor?.[0]?.user?.[0]?.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {toBRL(row?.precoPorKg)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {toBRL(row?.peloValorNF)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Button
                    style={{ width: '110px', marginBottom: '2px' }}
                    mold="primary"
                    onClick={async () => handleAprove(row)}
                  // disabled={isSubmitting}
                  >
                    Aprovar
                  </Button>
                  <Button
                    type="button"
                    mold="red"
                    style={{ width: '110px', textAlign: 'center' }}
                    onClick={async () => handleReproval(row)}
                  // disabled={isSubmitting}
                  >
                    Reprovar
                  </Button>
                </StyledTableCell>
                {
                  /*
                  <StyledTableCell align="right">
                    <Status title={row?.propostas?.status} />
                  </StyledTableCell>
                  */
                }
                <StyledTableCell align="right">
                  <TableActionButtons
                    item={row}
                    onAudit={() => {
                      setSelectProposal(row)
                      setOpenProposalModal(true)
                    }}
                    onView={() => {
                      setSelectBudget(row)
                      setOpenBudgetModal(true)
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        {/* <TablePagination
          rowsPerPageOptions={[data?.limit]}
          rowsPerPage={data?.limit}
          component="div"
          count={rows.length}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        /> */}
      </TableContainer>

      {openProposalModal && (
        <GenericDialog
          open={openProposalModal}
          close={() => setOpenProposalModal(false)}
          content={
            <ResumeProposalForm
              ft={selectProposal?.fts}
              proposalData={selectProposal?.propostas}
              proposal={selectProposal}
              confirm={(values) => handleConfirmProposal()}
              close={() => {
                setOpenProposalModal(false)
                refetch()
              }}
            />
          }
        />
      )}

      {openBudgetModal && (
        <GenericDialog
          widthRoot={860}
          open={openBudgetModal}
          close={() => setOpenBudgetModal(false)}
          content={
            <ResumeBudget
              ft={selectBudget?.fts}
              budget={selectBudget}
              close={() => {
                setOpenBudgetModal(false)
              }}
            />
          }
        />
      )}
    </>
  )
}
