/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import { ReportVolumeMes } from 'types/Report/ReportVolume'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import {
  Box,
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import TableCoilsAndSimplex from './TableCoilsAndSimplex'
import { getReportVolume } from 'services/report/ReportVolume'
import { formatValue, toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
}

const BoxStyle = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  marginright: 88px;
`
const IconStyle = styled(Icon)`
  margin-left: 10px;
`

const stickyStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 3,
  background: '#fafafa'
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FAFAFA',
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const TotalCoilsAndSimplex: React.FC<Props> = (props) => {
  const [dados, setDados] = useState<any[]>([])
  const [meses, setMeses] = useState<ReportVolumeMes[]>([])
  const [dadosArray, setDadosArray] = useState<any>([])
  const mes = moment(props.date).format('M')
  const date = moment(props.date).format('DD/MM/YYYY')

  const coilsAndSimplexReq = useQuery(
    ['coilsAndsimplex', date],
    async () => {
      return getReportVolume(date)
    },
    {
      onSuccess: (data) => {
        handleValorTable(data.response)
        handlePeriodo()
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const handleValorTable = (valorData: any) => {
    const valor: any[] = [
      {
        text: 'PAPEL',
        type: TipoProduto.PAPEL,
        ingoneType: false
      }
    ]
    setDados(valor)

    const listaDados: any = []
    valorData.forEach((element) => {
      listaDados.push(...element.dados)
    })
    setDadosArray(listaDados)
  }

  const handlePeriodo = () => {
    const mesesValue = [
      {
        text: 'Janeiro',
        value: 1
      },
      {
        text: 'Fevereiro',
        value: 2
      },
      {
        text: 'Março',
        value: 3
      },
      {
        text: 'Abril',
        value: 4
      },
      {
        text: 'Maio',
        value: 5
      },
      {
        text: 'Junho',
        value: 6
      },
      {
        text: 'Julho',
        value: 7
      },
      {
        text: 'Agosto',
        value: 8
      },
      {
        text: 'Setembro',
        value: 9
      },
      {
        text: 'Outubro',
        value: 10
      },
      {
        text: 'Novembro',
        value: 11
      },
      {
        text: 'Dezembro',
        value: 12
      }
    ]
    const meses: any[] = []
    let i = 0
    while (i < Number(mes)) {
      meses.push(mesesValue[i])
      i++
    }
    setMeses(meses)
  }

  const getRowByPeriodoKG = (type: any, periodo: any, ingoneType: boolean) => {
    const resultFilter = dadosArray.filter(
      (item) =>
        Number(item.periodo) === periodo && (item.type === type || ingoneType)
    )
    return resultFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.kg)
    }, 0)
  }

  const getRowByPeriodoRS = (type: any, periodo: any, ingoneType: boolean) => {
    const resultFilter = dadosArray.filter(
      (item) =>
        Number(item.periodo) === periodo && (item.type === type || ingoneType)
    )
    return resultFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.rs)
    }, 0)
  }

  const getResult = (valorKg, valorRs) => {
    const resultado = Number(valorRs) / Number(valorKg)
    if (Number.isNaN(resultado)) {
      return 0
    } else {
      return resultado
    }
  }

  return (
    <>
      {(coilsAndSimplexReq.isLoading || coilsAndSimplexReq.isFetching) && (
        <BoxStyle>
          <CircularProgress />
        </BoxStyle>
      )}
      {coilsAndSimplexReq.isError && (
        <BoxStyle>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <IconStyle>signal_wifi_statusbar_connected_no_internet_4</IconStyle>
        </BoxStyle>
      )}
      {coilsAndSimplexReq.isSuccess &&
        !coilsAndSimplexReq.isError &&
        !coilsAndSimplexReq.isFetching && (
          <>
            <Paper sx={{ width: '80vw' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table sx={{ maxWidth: '100%' }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="left" sx={{ ...stickyStyle, minWidth: '120px' }}>
                        Fat. Produto
                      </StyledTableCell>
                      {meses.map((mes, index) => (
                        <StyledTableCell align="center" colSpan={2}>
                          {mes.text}
                        </StyledTableCell>
                      ))}
                      <StyledTableCell align="center" colSpan={2}>
                        Total
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="left" sx={stickyStyle} ></StyledTableCell>
                      {meses.map((mes, index) => (
                        <>
                          <StyledTableCell align={'left'}>KG</StyledTableCell>
                          <StyledTableCell align={'left'}>R$/KG</StyledTableCell>
                        </>
                      ))}
                      <StyledTableCell align="left">KG</StyledTableCell>
                      <StyledTableCell align="left">R$/KG</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {dados.map((row, index) => {
                      let totalSumRskg = 0
                      let totalSumkg = 0
                      return (
                        <StyledTableRow role="checkbox" tabIndex={-1}>
                          <StyledTableCell component="th" align={'left'} sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 2,
                            background: '#FFF'
                          }}>
                            <Typography
                              variant="body2"
                              component="span"
                              fontWeight="bold"
                            >
                              {row.text}
                            </Typography>
                          </StyledTableCell>
                          {meses.map((mes, index) => {
                            const valorKg = getRowByPeriodoKG(
                              row.type,
                              mes.value,
                              row.ingoneType
                            )
                            const valorRs = getRowByPeriodoRS(
                              row.type,
                              mes.value,
                              row.ingoneType
                            )
                            const resultado = getResult(valorKg, valorRs)
                            totalSumkg += valorKg
                            totalSumRskg += resultado
                            return (
                              <>
                                <StyledTableCell align={'left'}>
                                  {formatValue(Number(valorKg ?? 0))}
                                </StyledTableCell>
                                <StyledTableCell align={'left'}>
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    noWrap
                                  >
                                    {toBRL(resultado ?? 0, { noBRL: true })}
                                  </Typography>
                                </StyledTableCell>
                              </>
                            )
                          })}
                          <StyledTableCell align={'left'}>
                            {formatValue(totalSumkg ?? 0)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {toBRL(totalSumRskg, { noBRL: true })}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <div style={{ marginTop: '25px' }}>
              <TableCoilsAndSimplex
                date={props.date}
                coilsAndSimplex={coilsAndSimplexReq}
              />
            </div>
          </>
        )}
    </>
  )
}

export default TotalCoilsAndSimplex
