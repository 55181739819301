import React from 'react'
import styled from 'styled-components'

const NotFoundText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  font-size: 22px;
`

function NotFound () {
  return (
    <div>
      <NotFoundText>404 - Página não encontrada</NotFoundText>
    </div>
  )
}

export default NotFound
