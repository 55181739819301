import { RootState } from 'core/store'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

export function LoggedRoutes() {
  const { loginData } = useSelector((state: RootState) => state.login)
  const location = useLocation()

  if (!loginData.logged) {
    return <Navigate to='/login' state={{ from: location }} />
  }

  return <Outlet />
}
