/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import {
  DivRow,
  LabelText
} from 'pages/reportControl/deliveryMap/styles/dialogMap.styles'
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  Grid,
  Paper,
  TextField,
  Button as MuiButton,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Solicitation from '../booking/Solicitation'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { _changePage } from 'pages/home/slices/home.slice'
import FTSample from '../booking/FTSample'
import Budget from '../booking/Budget'
import ApprovedProposal from '../booking/ApprovedProposal'
import Sample from '../booking/Sample'
import ToolingBudget from '../booking/ToolingBudget'
import Button from 'components/form/Button'
import { Accordion } from '../components/Accordion'
import { httpClient } from 'config/httpClient'
import { useQuery } from 'react-query'
import TechnicalDrawing from './TechnicalDrawing'
import SolicitationTool from './SolicitationTool'
import Attachments from './Attachments'
import Finished from './Finished'
import PurchaseRequest from './PurchaseRequest'
import ModalPurchaseRequest from './ModalPurchaseRequest'

const Item = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  width: '100%',
  height: '100%',
  textTransform: 'none',
  fontWeight: '300',
  textAlign: 'center',
  color: '#8A8A8A',
  boxShadow: '0px 3px 6px #00000029',
  paddingRight: 16,
  paddingLeft: 16,
  '&:hover': {
    color: '#1A2027'
  }
}))

function StatusButton({ active = false }: { active?: boolean }) {
  return (
    <Box>
      <Box
        sx={{
          marginLeft: 8,
          width: 10,
          height: 10,
          borderRadius: 10,
          bgcolor: (theme) =>
            active ? theme.palette.success.main : theme.palette.error.main
        }}
      />
    </Box>
  )
}

interface Props {
  pedidoFT: any
  bookFinished?: boolean
  recorrente?: boolean
}

const BookingFormItem: React.FC<Props> = ({ pedidoFT, bookFinished, recorrente }) => {
  const { state }: any = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedFt, setSelectedFt] = useState<any>()
  const [openFinished, setFinished] = useState<boolean>(false)
  const [openSolicitation, setOpenSolicitation] = useState<boolean>(false)
  const [openFTSample, setOpenFTSample] = useState<boolean>(false)
  const [openBudget, setOpenBudget] = useState<boolean>(false)
  const [openApprovedProposal, setOpenApprovedProposal] =
    useState<boolean>(false)
  const [openSample, setOpenSample] = useState<boolean>(false)
  const [openDraw, setOpenDraw] = useState<boolean>(false)
  const [openToolingBudget, setOpenToolingBudget] = useState<boolean>(false)
  const [openPurchaseRequest, setOpenPurchaseRequest] =
    useState<boolean>(false)
  const [openSolicitationTool, setOpenSolicitationTool] =
    useState<boolean>(false)
  const [openAttachments, setOpenAttachments] = useState<boolean>(false)
  const { data, status } = useQuery(
    ['modal-booking-head', pedidoFT?.ft?.preFT?._id],
    async () =>
      await httpClient.get<any>(`/prefts/${pedidoFT?.ft?.preFT?._id}`),
    {
      refetchOnWindowFocus: false
    }
  )
  console.log('recorrente', recorrente)
  console.log('pedidoFT', pedidoFT)
  const { data: dataAttachments } = useQuery(
    ['modal-booking-conclusao', pedidoFT?.pedido],
    async () =>
      await httpClient.get<any>(
        `/bookings/pdf/download/${pedidoFT?.pedido}/${
          pedidoFT?.ft?._id ?? pedidoFT?.codItem
        }`
      ),
    {
      refetchOnWindowFocus: false
    }
  )
 console.log('dataAttachments => ', dataAttachments)
  const { data: dataPurchaseRequest } = useQuery(
    ['modal-booking-ModalPurchaseRequest'],
    async () =>
      await httpClient.get(
        `/bookings/purchase-request/pedido/${pedidoFT?.pedido}/${
          pedidoFT?.ft?._id ?? pedidoFT?.codItem
        }`
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  )
  console.log('dataPurchaseRequest => ', dataPurchaseRequest)
  const completedPurchaseOrder =
    dataPurchaseRequest?.data?.assinaturas?.gerenciaComercial?.url?.length ||
    dataPurchaseRequest?.data?.assinaturas?.gerenciaComercial?.lastSend ||
    dataPurchaseRequest?.data?.assinaturas?.compras?.url?.length ||
    dataPurchaseRequest?.data?.assinaturas?.compras?.lastSend

  const [proposal, setProposal] = React.useState<any>(null)

  const { data: budget, refetch, isFetching } = useQuery(
    ['modal-approved-proposal', pedidoFT?.ft?.preFT?._id],
    async () =>
      await httpClient.get<any[]>(
        `/budget/proposalsByPreFT/${pedidoFT?.ft?.preFT?._id}`
      ),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    const proposals = budget?.data ?? []

    let selectedProposal = null

    if (proposals.length > 1) {
      // Find the most recent proposal by comparing updatedAt timestamps
      selectedProposal = proposals.reduce((prev, current) =>
        new Date(current.updatedAt) > new Date(prev.updatedAt) ? current : prev
      )
    } else if (proposals.length === 1) {
      // Only one item, so return it
      selectedProposal = proposals[0]
    }

    setProposal(selectedProposal)
  }, [budget?.data, isFetching])

  console.log('proposal', proposal)
  console.log('proposal?.status', proposal?.status)

  return (
    <>
      <Accordion
        title={`Dados da FT ${
          pedidoFT?.ft?.itemReference ?? pedidoFT?.codItem
        }`}
      >
        <Box>
          {/* <LabelText>Dados da FT</LabelText> */}
          <DivRow style={{ marginTop: '-20px' }}>
            <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="FT"
                  value={pedidoFT?.ft?.itemReference ?? pedidoFT?.codItem}
                  variant="standard"
                  disabled
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Cliente"
                  value={state?.item?.client?.name}
                  variant="standard"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Rêferencia"
                  value={''}
                  variant="standard"
                  disabled
                />
              </Grid>
            </Grid>
          </DivRow>
        </Box>
        <Box mt={3}>
          <LabelText>Clique para visualizar:</LabelText>
          {recorrente && (
            <DivRow style={{ marginTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <Item
                    sx={{
                      backgroundColor: '#D9D9D9',
                      cursor: 'initial',
                      '&:hover': { backgroundColor: '#D9D9D9' }
                    }}
                  >
                    Solicitação
                  </Item>
                  <StatusButton />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    sx={{
                      backgroundColor: '#D9D9D9',
                      cursor: 'initial',
                      '&:hover': { backgroundColor: '#D9D9D9' }
                    }}
                  >
                    FT
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    sx={{
                      backgroundColor: '#D9D9D9',
                      cursor: 'initial',
                      '&:hover': { backgroundColor: '#D9D9D9' }
                    }}
                  >
                    Orçamento
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    sx={{
                      backgroundColor: '#D9D9D9',
                      cursor: 'initial',
                      '&:hover': { backgroundColor: '#D9D9D9' }
                    }}
                  >
                    Proposta aprovada
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    sx={{
                      backgroundColor: '#D9D9D9',
                      cursor: 'initial',
                      '&:hover': { backgroundColor: '#D9D9D9' }
                    }}
                  >
                    Amostra
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenDraw(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.desenhoTecnico?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.desenhoTecnico?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Desenho técnico
                    {!dataAttachments?.data?.desenhoTecnico?.length && (
                      <StatusButton />
                    )}
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenSolicitationTool(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.desenhoFerramental?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.desenhoFerramental?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Desenho do ferramental
                    {!dataAttachments?.data?.desenhoFerramental?.length && (
                      <StatusButton />
                    )}
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenToolingBudget(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.orcamentoFerramental?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.orcamentoFerramental?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Orçamento ferramental
                    {!dataAttachments?.data?.orcamentoFerramental?.length && (
                      <StatusButton />
                    )}
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenPurchaseRequest(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        completedPurchaseOrder
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        completedPurchaseOrder
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Solicitação de compra
                    {!completedPurchaseOrder && <StatusButton />}
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenAttachments(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.outrosAnexos?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.outrosAnexos?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Outros anexos
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setFinished(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.desenhoTecnico?.length &&
                        dataAttachments?.data?.desenhoFerramental?.length &&
                        dataAttachments?.data?.orcamentoFerramental?.length &&
                        dataAttachments?.data?.outrosAnexos?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.desenhoTecnico?.length &&
                        dataAttachments?.data?.desenhoFerramental?.length &&
                        dataAttachments?.data?.orcamentoFerramental?.length &&
                        dataAttachments?.data?.outrosAnexos?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Conclusão, baixar documentos
                    {(!dataAttachments?.data?.desenhoTecnico?.length ||
                      !dataAttachments?.data?.desenhoFerramental?.length ||
                      !dataAttachments?.data?.orcamentoFerramental?.length ||
                      !dataAttachments?.data?.outrosAnexos?.length) && (
                      <StatusButton />
                    )}
                  </Item>
                </Grid>
              </Grid>
            </DivRow>
          )}

          {!recorrente && pedidoFT?.ft && (
            <DivRow style={{ marginTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenSolicitation(true)
                    }}
                    sx={{
                      bgcolor: (theme) => theme.palette.success.light,
                      color: (theme) => theme.palette.success.contrastText
                    }}
                  >
                    Solicitação
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenFTSample(true)
                    }}
                    sx={{
                      bgcolor: (theme) => theme.palette.success.light,
                      color: (theme) => theme.palette.success.contrastText
                    }}
                  >
                    FT
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenBudget(true)
                    }}
                    sx={{
                      bgcolor: (theme) => theme.palette.success.light,
                      color: (theme) => theme.palette.success.contrastText
                    }}
                  >
                    Orçamento
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenApprovedProposal(true)
                    }}
                    sx={{
                      bgcolor: (theme) => {
                        switch (proposal?.status) {
                          case 'REPROVADA PELO CLIENTE':
                            return theme.palette.warning.light
                          case 'APROVADA PELO CLIENTE':
                            return theme.palette.success.light
                          case 'PENDENTE PELA CONTROLADORIA':
                            return theme.palette.warning.light
                          default:
                            return theme.palette.warning.light
                        }
                      },
                      color: (theme) => {
                        switch (proposal?.status) {
                          case 'REPROVADA PELO CLIENTE':
                            return theme.palette.warning.contrastText
                          case 'APROVADA PELO CLIENTE':
                            return theme.palette.success.contrastText
                          case 'PENDENTE PELA CONTROLADORIA':
                            return theme.palette.warning.contrastText
                          default:
                            return theme.palette.warning.contrastText
                        }
                      }
                    }}
                    >
                    {(() => {
                      switch (proposal?.status) {
                        case 'REPROVADA PELO CLIENTE':
                          return 'Proposta reprovada'
                        case 'APROVADA PELO CLIENTE':
                          return 'Proposta aprovada'
                        case 'PENDENTE PELA CONTROLADORIA':
                          return 'Proposta pendente'
                        default:
                          return 'Sem proposta'
                      }
                    })()}
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenSample(true)
                    }}
                    sx={{
                      bgcolor: (theme) => theme.palette.success.light,
                      color: (theme) => theme.palette.success.contrastText
                    }}
                  >
                    Amostra
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenDraw(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.desenhoTecnico?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.desenhoTecnico?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Desenho técnico
                    {!dataAttachments?.data?.desenhoTecnico?.length && (
                      <StatusButton />
                    )}
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenSolicitationTool(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.desenhoFerramental?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.desenhoFerramental?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Desenho do ferramental
                    {!dataAttachments?.data?.desenhoFerramental?.length && (
                      <StatusButton />
                    )}
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenToolingBudget(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.orcamentoFerramental?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.orcamentoFerramental?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Orçamento ferramental
                    {!dataAttachments?.data?.orcamentoFerramental?.length && (
                      <StatusButton />
                    )}
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenPurchaseRequest(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        completedPurchaseOrder
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        completedPurchaseOrder
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Solicitação de compra
                    {!completedPurchaseOrder && <StatusButton />}
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setOpenAttachments(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.outrosAnexos?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.outrosAnexos?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Outros anexos
                  </Item>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Item
                    onClick={() => {
                      setSelectedFt({ ...pedidoFT, preFT: data?.data })
                      setFinished(true)
                    }}
                    sx={{
                      bgcolor: (theme) =>
                        dataAttachments?.data?.desenhoTecnico?.length &&
                        dataAttachments?.data?.desenhoFerramental?.length &&
                        dataAttachments?.data?.orcamentoFerramental?.length &&
                        dataAttachments?.data?.outrosAnexos?.length
                          ? theme.palette.success.light
                          : undefined,
                      color: (theme) =>
                        dataAttachments?.data?.desenhoTecnico?.length &&
                        dataAttachments?.data?.desenhoFerramental?.length &&
                        dataAttachments?.data?.orcamentoFerramental?.length &&
                        dataAttachments?.data?.outrosAnexos?.length
                          ? theme.palette.success.contrastText
                          : undefined
                    }}
                  >
                    Conclusão, baixar documentos
                    {(!dataAttachments?.data?.desenhoTecnico?.length ||
                      !dataAttachments?.data?.desenhoFerramental?.length ||
                      !dataAttachments?.data?.orcamentoFerramental?.length ||
                      !dataAttachments?.data?.outrosAnexos?.length) && (
                      <StatusButton />
                    )}
                  </Item>
                </Grid>
              </Grid>
            </DivRow>
          )}
        </Box>
      </Accordion>

      {openFinished && (
        <Finished
          open={openFinished}
          data={selectedFt}
          close={() => setFinished(false)}
        />
      )}
      {openSolicitation && (
        <Solicitation
          open={openSolicitation}
          data={selectedFt}
          close={() => setOpenSolicitation(false)}
          bookFinished={bookFinished}
        />
      )}
      {openFTSample && (
        <FTSample
          open={openFTSample}
          data={selectedFt}
          close={() => setOpenFTSample(false)}
        />
      )}
      {openBudget && (
        <Budget
          open={openBudget}
          data={selectedFt}
          close={() => setOpenBudget(false)}
        />
      )}
      {openApprovedProposal && (
        <ApprovedProposal
          open={openApprovedProposal}
          data={selectedFt}
          close={() => setOpenApprovedProposal(false)}
        />
      )}
      {openSample && (
        <Sample
          open={openSample}
          data={selectedFt}
          close={() => setOpenSample(false)}
        />
      )}
      {openDraw && (
        <TechnicalDrawing
          open={openDraw}
          data={selectedFt}
          close={() => setOpenDraw(false)}
          bookFinished={bookFinished}
        />
      )}
      {openToolingBudget && (
        <ToolingBudget
          open={openToolingBudget}
          data={selectedFt}
          close={() => setOpenToolingBudget(false)}
          bookFinished={bookFinished}
        />
      )}
      {openPurchaseRequest && (
        <ModalPurchaseRequest
          open={openPurchaseRequest}
          data={selectedFt}
          close={() => setOpenPurchaseRequest(false)}
          bookFinished={bookFinished}
        />
      )}
      {openSolicitationTool && (
        <SolicitationTool
          open={openSolicitationTool}
          data={selectedFt}
          close={() => setOpenSolicitationTool(false)}
          bookFinished={bookFinished}
        />
      )}
      {openAttachments && (
        <Attachments
          open={openAttachments}
          data={selectedFt}
          close={() => setOpenAttachments(false)}
          bookFinished={bookFinished}
        />
      )}
    </>
  )
}

export default BookingFormItem
