import { Rating } from '@mui/lab'
import moment from 'moment'
import React from 'react'
import customerReviewType from 'types/managementControl/customerReview/customerReviewType'
import { ContentComments, LabelTitle, RowCV } from './styles'

interface Props {
  title: string
  comments: customerReviewType[] | undefined
}

function ChartComments(props: Props) {
  return (
    <div>
      <LabelTitle>
        <p>{props.title}</p>
      </LabelTitle>
      <div>
        <RowCV>

          <ContentComments>
            {
              props.comments?.map((content, index) =>
                content.comment.length > 0
                  ? <div key={index} style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                    <div>
                      {content.comment}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ fontSize: 11 }}>{content.user?.name} • {moment(content.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                      <Rating name="read-only" value={2} readOnly />
                    </div>
                  </div>
                  : <div key={index}></div>
              )
            }
          </ContentComments>

        </RowCV>
      </div>
    </div>
  )
}

export default ChartComments
