import {
  Box,
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import MoreMenu from 'components/view/MoreMenu'
import StatusView from 'components/view/StatusView'
import React, { useState } from 'react'
import Rating from '@mui/material/Rating'
import ModalcustomerReview from './ModalCustomerReview'
import { useQuery } from 'react-query'
import { getReviewClient } from 'services/managementControl/customerReview/customerReview.service'
import styled from 'styled-components'
import moment from 'moment'
import customerReviewType from 'types/managementControl/customerReview/customerReviewType'
import { httpClient } from 'config/httpClient'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'

const BoxStyle = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  marginright: 88px;
`
const IconStyle = styled(Icon)`
  margin-left: 10px;
`

const TableCustomerReview: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [dataValues, setDataValues] = useState<customerReviewType[]>([])
  const [customerViewData, setCustomerViewData] = useState<
    customerReviewType | any
  >()

  const { isError, isLoading, isSuccess, isFetching, refetch } = useQuery(
    ['customer-review'],
    async () => {
      return getReviewClient()
    },
    {
      onSuccess: (data) => {
        setDataValues(data)
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const handleRefetch = () => {
    refetch()
  }

  const handleDelete = (id: string) => {
    httpClient
      .delete(`/evaluations/${id}`)
      .then(() => {
        handleRefetch()
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      {(isLoading || isFetching) && (
        <BoxStyle>
          <CircularProgress />
        </BoxStyle>
      )}
      {isError && (
        <BoxStyle>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <IconStyle>signal_wifi_statusbar_connected_no_internet_4</IconStyle>
        </BoxStyle>
      )}

      {isSuccess && !isError && !isFetching && (
        <>
          <>
            <TableContainer style={{ marginTop: '30px', maxHeight: 440 }} component={Paper}>
              <Table sx={{ width: '100%' }} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">ID</TableCell>
                    <TableCell align="left">DATA</TableCell>
                    <TableCell align="left">NOME</TableCell>
                    <TableCell align="left">E-MAIL</TableCell>
                    <TableCell align="left">AVALIAÇÃO</TableCell>
                    <TableCell align="left">COMENTÁRIO</TableCell>
                    <TableCell align="left">NOME DA ÁREA</TableCell>
                    <TableCell align="center">STATUS</TableCell>
                    <Can
                      module={MODULES.GERENCIAL}
                      permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                    >
                      <TableCell align="center">AÇÕES</TableCell>
                    </Can>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataValues.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" align="left">
                        {item.user?._id}
                      </TableCell>
                      <TableCell align="left">
                        {moment(item.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell align="left">{item.user?.name}</TableCell>
                      <TableCell align="left">{item.user?.email}</TableCell>
                      <TableCell align="left">
                        <Rating name="read-only" value={item.rating} readOnly />
                      </TableCell>
                      <TableCell align="left">{item.comment}</TableCell>
                      <TableCell align="left">{item?.area}</TableCell>
                      <TableCell>
                        <StatusView status={item.isActive} />
                      </TableCell>
                      <Can
                        module={MODULES.GERENCIAL}
                        permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                      >
                        <TableCell>
                          <MoreMenu
                            module={MODULES.GERENCIAL}
                            isValidationPermissions
                            edit={() => {
                              setOpen(true)
                              setCustomerViewData(item)
                            }}
                            delete={async () => handleDelete(item._id)}
                          />
                        </TableCell>
                      </Can>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          {open && (
            <ModalcustomerReview
              open={open}
              close={() => setOpen(false)}
              data={customerViewData}
              refetch={handleRefetch}
            />
          )}
        </>
      )}
    </>
  )
}

export default TableCustomerReview
