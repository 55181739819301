/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useMemo } from 'react'
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts'

let ctx

export const measureText14HelveticaNeue = text => {
  if (!ctx) {
    ctx = document.createElement('canvas').getContext('2d')
    ctx.font = "14px 'Helvetica Neue"
  }

  return ctx.measureText(text).width
}

export const SimpleBarChart = ({ data, xKey, yKey, zKey }) => {
  const maxTextWidth = useMemo(() => data.reduce((acc, cur) => {
    const value = cur[yKey]
    const width = measureText14HelveticaNeue(value.toLocaleString())
    if (width > acc) {
      return width
    }
    return acc
  }, 0),
    [data, yKey]
  )

  return (
    <ResponsiveContainer width={'100%'} height={50 * data.length} debounce={50}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ left: 20, right: 100 }}
      >
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          yAxisId={0}
          dataKey={xKey}
          type="category"
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          orientation="right"
          yAxisId={1}
          dataKey={yKey}
          type="category"
          axisLine={false}
          tickLine={false}
          tickFormatter={value => value.toLocaleString()}
          mirror
          tick={{
            transform: `translate(${maxTextWidth + 10}, -10)`
          }}
        />
        {/* <YAxis
          orientation="right"
          yAxisId={2}
          dataKey={zKey}
          type="category"
          axisLine={false}
          tickLine={false}
          tickFormatter={value => value.toLocaleString()}
          mirror
          tick={{
            transform: `translate(${maxTextWidth + 70}, 10)`
          }}
        /> */}
        <Bar dataKey={yKey} minPointSize={2} barSize={32}>
          {data.map((d, idx) => {
            return <Cell key={d[xKey]} fill={d.color} />
          })}
        </Bar>
        {/* <Bar dataKey={zKey} minPointSize={2} barSize={32}>
          {data.map((item, idx) => {
            return <Cell key={item[xKey]} fill={color} />
          })}
        </Bar> */}
      </BarChart>
    </ResponsiveContainer>
  )
}

export const SimpleBarChartTotal = ({ data, xKey, yKey }) => {
  const maxTextWidth = useMemo(() => data.reduce((acc, cur) => {
    const value = cur[yKey]
    const width = measureText14HelveticaNeue(value.toLocaleString())
    if (width > acc) {
      return width
    }
    return acc
  }, 0),
    [data, yKey]
  )

  return (
    <ResponsiveContainer width={'100%'} height={50 * data.length} debounce={50}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ left: 20, right: 100 }}
      >
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          yAxisId={0}
          dataKey={xKey}
          type="category"
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          orientation="right"
          yAxisId={1}
          dataKey={yKey}
          type="category"
          axisLine={false}
          tickLine={false}
          tickFormatter={value => value.toLocaleString()}
          mirror
          tick={{
            transform: `translate(${maxTextWidth + 10}, -10)`
          }}
        />
        <Bar dataKey={yKey} minPointSize={2} barSize={32}>
          {data.map((d, idx) => {
            return <Cell key={d[xKey]} fill={'#4060E6'} />
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
