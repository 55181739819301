/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import { Box, Grid, Stack, TextField, Typography } from '@mui/material'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import React, { ReactNode } from 'react'
import { FTProposalType } from 'types/ft/FTProposalType'
import { FormatPrice } from 'utils/FormatPrice'

function Section({ title, children }: { title: string, children: ReactNode }) {
  return (
    <>
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <h4>{title}</h4>
      </Grid>
      {children}
    </>
  )
}
interface ProposalType extends FTProposalType {
  _id: string
}
interface Props {
  budget: any | undefined
  ft: any
  close: () => void
}

function ResumeBudget(props: Props) {
  const { budget, ft } = props

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip
          title="Voltar"
          placement="top"
          onClick={() => {
            props.close()
          }}
        >
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>
          Orçamento
        </div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={2}>
          <Section title="Quantidade">
            {/* <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Previsão total (Unid)"
                  value={''}
                  variant="standard"
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Previsão total (Kg)"
                  value={''}
                  variant="standard"
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Amostra física (Unid)"
                  value={''}
                  variant="standard"
                  disabled
                />
              </Grid> */}

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Lote mínimo"
                value={budget?.loteMinimo}
                variant="standard"
                disabled
              />
            </Grid>
          </Section>
          <Section title="Especificações">
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                id="standard-basic"
                label="FTD"
                variant="standard"
                value={ft?.[0]?.itemReference}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Área líquida (m²)"
                value={budget?.areaLiquida}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Peso líquido (kg)"
                value={budget?.pesoLiquido}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Área bruta (m²)"
                value={budget?.areaBruta}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Peso bruto (kg)"
                value={budget?.pesoBruto}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Fechamento"
                value={budget?.fechamento}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Resina"
                value={budget?.resina}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Limpeza"
                value={budget?.limpeza}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Peso bruto"
                value={budget?.pesoBruto}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Gramatura (G/m²)"
                value={budget?.gramatura}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Tipo de produto"
                value={budget?.tipoProduto}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Tabela"
                value={budget?.tabela}
                variant="standard"
                disabled
              />
            </Grid>
          </Section>
          <Section title="Previsão de consumo (Unidades)">
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Anual"
                value={budget?.anual}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Semestral"
                value={budget?.semestral}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Trimestral"
                value={budget?.trimestral}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Bimestral"
                value={budget?.bimestral}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Mensal"
                value={budget?.mensal}
                variant="standard"
                disabled
              />
            </Grid>
          </Section>
          <Section title="Formação do preço">
            <Grid item xs={12} md={3}>
              <Stack direction="row" alignItems="flex-end">
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Peso (kg)"
                  value={Math.ceil(budget?.pesoKg * 100) / 100}
                  variant="standard"
                  disabled
                />
                <Box ml={2}>x</Box>
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Tabela"
                value={budget?.tabela}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack direction="row" alignItems="flex-end">
                <Box mr={2}>=</Box>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Preço"
                  value={`${FormatPrice(budget?.preco)}`}
                  variant="standard"
                  disabled
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Preço total"
                value={`${FormatPrice(budget?.preco)}`}
                variant="standard"
                disabled
              />
            </Grid>
          </Section>

          <Section title="Frete">
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Cidade de destino"
                    value={budget?.cidadeDestino}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Valor"
                    value={budget?.valorFrete}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Peço total"
                    value={budget?.valorFreteCalculado}
                    variant="standard"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Section>
          <Section title="Ferramental">
            <Grid item xs={12}>
              <Typography component="span">Clichê polímero</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Custo (R$)"
                    value={budget?.custoCliche}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Diluído em (meses)"
                    value={budget?.custoClicheDiluido}
                    variant="standard"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography component="span">Clichê metálico</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Custo (R$)"
                    value={budget?.custoFaca}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Diluído em (meses)"
                    value={budget?.custoFacaDiluido}
                    variant="standard"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Preço total"
                value={budget?.precoFerramental}
                variant="standard"
                disabled
              />
            </Grid>
          </Section>

          <Section title="Fechamento/Resina/Limpeza">
            <Grid item xs={12}>
              <Typography component="span">Fechamento</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Colagem manual"
                    value={FormatPrice(budget?.fechamentoColagemManual)}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Grampo/Cola"
                    value={FormatPrice(budget?.fechamentoGrampoCola)}
                    variant="standard"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Resina"
                    value={FormatPrice(budget?.resina)}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Limpeza"
                    value={FormatPrice(budget?.limpeza)}
                    variant="standard"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Valor agregado"
                value={FormatPrice(budget?.valorAgregado)}
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Preço"
                value={FormatPrice(budget?.precoFechamento)}
                variant="standard"
                disabled
              />
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Lote mínimo (Pçs)"
                    value={budget?.loteMinimo}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Preço à vista"
                    value={FormatPrice(budget?.precoAVista)}
                    variant="standard"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Condições de pagamento"
                    value={`${budget?.condicoesPagamento} dias ddl`}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Taxa de financiamento"
                    value={`${budget?.valorTaxa}%`}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Valor da taxa"
                    value={FormatPrice(budget?.taxaFinanciamento)}
                    variant="standard"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Preço por Kg com Ferramental"
                    value={FormatPrice(budget?.precoKgFerramental ?? 0)}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Preço por Kg sem Ferramental"
                    value={FormatPrice(budget?.precoKgSemFerramental ?? 0)}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Preço sugerido"
                    value={FormatPrice(budget?.precoSugerido)}
                    variant="standard"
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Preço por Kg"
                value={FormatPrice(budget?.precoPorKg)}
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Preço negociado"
                value={FormatPrice(budget?.precoNegociado)}
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Pelo valor da NF"
                value={FormatPrice(budget?.peloValorNF)}
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="KG"
                value={budget?.KG}
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="standard-basic"
                label="NF"
                value={budget?.NF}
                variant="standard"
                disabled
              />
            </Grid>
          </Section>
        </Grid>
      </Box>
    </div>
  )
}

export default ResumeBudget
