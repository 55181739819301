/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  Box,
  CircularProgress,
  Icon,
  TablePagination,
  TextField
} from '@mui/material'
import { useEffect, useState } from 'react'
import { StandardFilter } from 'utils/StandardFilter'
import { formatValue, toBRL } from 'utils/formatString'
import { toast } from 'react-toastify'

const types = {
  '0001': 'caixas',
  '0002': 'chapas',
  '0003': 'bobinas',
  '0006': 'bobinas',
  '0007': 'chapas'
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function ReportClient(props: any) {
  const [rows, setRows] = React.useState<any[]>([])
  const [page, setPage] = React.useState(0)
  const [filters, setFilters] = useState<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  // const [searchParam, setSearchParam] = React.useState('')
  // const [originalRows, setOriginalRows] = React.useState<any[]>([])

  useEffect(() => {
    const loadedData = [
      props.req1.isLoading,
      props.req2.isLoading,
      props.req3.isLoading
    ].every((loading) => !loading)
    if (loadedData) {
      formatTableData(
        props.req1.data.response,
        props.req2.data,
        props.req3.data.response
      )
    }
  }, [props.req1.isLoading, props.req2.isLoading, props.req3.isLoading])

  const formatTableData = (
    invoicing: any[],
    forecast: any[],
    demand: any[]
  ) => {
    try {
      // Previsão
      const arrayFiltradoPrevision = [] as any[]
      for (const type of props.type) {
        forecast?.[types[type]].forEach((item) => {
          arrayFiltradoPrevision.push({
            ...item,
            kg: item.pesoKg,
            rs: item.previsionPrice,
            client: item?.client?.name
          })
        })
      }

      // Pedidos
      const arrayFiltradoDemand = [] as any[]
      const clintDemand = [] as any[]
      demand.forEach((item) => {
        item.dados.forEach((element) => {
          if (props.type.includes(element.type)) {
            clintDemand.push(item.client)
            arrayFiltradoDemand.push({
              ...element,
              client: item.client
            })
          }
        })
      })

      // Faturamento
      const arrayFiltradoInvoicing = [] as any[]
      const clientInoicing = [] as any[]
      invoicing.forEach((item) => {
        item.dados.forEach((element) => {
          if (props.type.includes(element.type)) {
            clientInoicing.push(item.client)
            arrayFiltradoInvoicing.push({
              ...element,
              client: item.client
            })
          }
        })
      })

      const allClient = clintDemand
        .concat(clientInoicing)
        .filter((x, i, a) => a.indexOf(x) === i)

      const dadosDaTabela = [] as any[]

      allClient.forEach((name) => {
        // previsões
        const dadosPrevisao = arrayFiltradoPrevision.filter((dados) =>
          dados.client.includes(name)
        )

        const dadosDemanda = arrayFiltradoDemand.filter((dados) =>
          dados.client.includes(name)
        )

        const dadosFaturamento = arrayFiltradoInvoicing.filter((dados) =>
          dados.client.includes(name)
        )

        const { kg: kgPrevisao, rs: rsPrevisao } = dadosPrevisao.reduce(
          (acumulador, dados) => {
            const { kg, rs } = dados
            acumulador.kg += kg
            acumulador.rs += rs
            return acumulador
          },
          { kg: 0, rs: 0, rskg: 0 }
        )

        const { kg: kgPedidos, rs: rsPedidos } = dadosDemanda.reduce(
          (acumulador, dados) => {
            const { kg, rs } = dados
            acumulador.kg += kg
            acumulador.rs += rs
            return acumulador
          },
          { kg: 0, rs: 0, rskg: 0 }
        )

        const { kg: kgFaturamento, rs: rsFaturamento } =
          dadosFaturamento.reduce(
            (acumulador, dados) => {
              const { kg, rs } = dados
              acumulador.kg += kg
              acumulador.rs += rs
              return acumulador
            },
            { kg: 0, rs: 0, rskg: 0 }
          )

        dadosDaTabela.push({
          name,
          rsPrevisao,
          kgPrevisao,
          rsPedidos,
          kgPedidos,
          kgFaturamento,
          rsFaturamento
        })
      })

      setRows(dadosDaTabela)
      setDataSource(dadosDaTabela)
    } catch (error) {
      console.log('erro data: ', error)
      toast.warn(' Referência Perdida de Registro. Contate o Time de TI')
    }
  }

  useEffect(() => {
    StandardFilter(filters, setRows, dataSource)
  }, [filters, dataSource])

  // const search = () => {
  //   if (searchParam.length === 0) {
  //     setRows(originalRows)
  //   } else {
  //     const searchResult = originalRows.filter(row => row.date.toUpperCase().includes(searchParam.toUpperCase()))
  //     setRows(searchResult)
  //   }
  // }

  return (
    <>
      {(props.req1.isLoading || props.req1.isFetching) && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {props.req1.isError && props.req2.isError && (
        <Box
          sx={{ display: 'flex' }}
          style={{
            justifyContent: 'center',
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>
            signal_wifi_statusbar_connected_no_internet_4
          </Icon>
        </Box>
      )}

      {props.req1.isSuccess && !props.req1.isError && !props.req1.isFetching && (
        <>
          <TableContainer style={{ marginTop: '30px' }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TextField
                      id="standard-basic"
                      label="Pesquisar cliente"
                      variant="standard"
                      onChange={(e) =>
                        setFilters({ ...filters, name: e.target.value })
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">Previsão Kg</StyledTableCell>
                  <StyledTableCell align="left">Previsão R$</StyledTableCell>
                  <StyledTableCell align="left">Previsão R$/Kg</StyledTableCell>

                  <StyledTableCell align="left">Cadastro Kg</StyledTableCell>
                  <StyledTableCell align="left">Cadastro R$</StyledTableCell>
                  <StyledTableCell align="left">Cadastro R$/Kg</StyledTableCell>

                  <StyledTableCell align="left">Faturado Kg</StyledTableCell>
                  <StyledTableCell align="left">Faturado R$</StyledTableCell>
                  <StyledTableCell align="left">Faturado R$/Kg</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.slice(page * 5, page * 5 + 5)?.map((row) => {
                  return (
                    <StyledTableRow key={row.index}>
                      <StyledTableCell align="left">{row.name}</StyledTableCell>

                      <StyledTableCell align="left">{formatValue(row?.kgPrevisao)}</StyledTableCell>
                      <StyledTableCell align="left">{toBRL(row?.rsPrevisao, { noBRL: true })}</StyledTableCell>
                      <StyledTableCell align="left">
                        {toBRL(isNaN(row.rsPrevisao / row.kgPrevisao)
                          ? 0
                          : Number(row.rsPrevisao / row.kgPrevisao)
                          , { noBRL: true }
                        )}</StyledTableCell>

                      <StyledTableCell align="left">{formatValue(row.kgPedidos)}</StyledTableCell>
                      <StyledTableCell align="left">{toBRL(row.rsPedidos, { noBRL: true })}</StyledTableCell>
                      <StyledTableCell align="left">
                        {toBRL(isNaN(row.rsPedidos / row.kgPedidos)
                          ? 0
                          : Number(row.rsPedidos / row.kgPedidos)
                          , { noBRL: true }
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="left">{formatValue(row.kgFaturamento)}</StyledTableCell>
                      <StyledTableCell align="left">{toBRL(row.rsFaturamento, { noBRL: true })}</StyledTableCell>
                      <StyledTableCell align="left">
                        {toBRL(isNaN(row.rsFaturamento / row.kgFaturamento)
                          ? 0
                          : Number(row.rsFaturamento / row.kgFaturamento)
                          , { noBRL: true }
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              rowsPerPage={5}
              component="div"
              count={rows.length}
              page={page}
              onPageChange={(event: unknown, newPage: number) => {
                setPage(newPage)
              }}
            />
          </TableContainer>
        </>
      )}
    </>
  )
}
