/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import styled from 'styled-components'
import { SimpleBarChart, SimpleBarChartTotal } from 'pages/dashboard/components/ChartPrincipalMap'
import { useQuery, UseQueryResult } from 'react-query'
import { getCodesOVsReposition, getCodLNsCarteira, getCodLNsKanbam, getOVByCoil, getOVByPlate, getOVByPlatesDelayed, getOVOutOfState, postOVByID, postOVByType, postOVByTypeDelayed } from 'services/orderService/OverviewOrderService'

const DotCV = styled.div`
width: 18px;
height: 18px;
background-color: #EE207A;
margin-right: 10px;
margin-left: 15px;
`
const DotCV2 = styled.div`
width: 18px;
height: 18px;
background-color: #4060E6;
margin-right: 10px;
margin-left: 15px;
`

interface OV {
  dataEntregaOV: string
  item: string
  codClient: string
  reference: string
  quantidadeSolicitada: number
  quantidadeFaturada: number
  dataFaturamento: number
  nf: number
  linhaFaturarOV: string
  id: string
  estado: string
  peso: number
  entradaEstoque: string
}

const NewDashBoard = (props: any) => {
  const [dataProgramado, setDataProgramado] = useState<any[]>([])
  const [dataExpedido, setDataExpedido] = useState<any[]>([])
  const [dataTotalProgramado, setDataTotalProgramado] = useState({ kg: 0, atraso: 0, dia: 0 })
  const [dataTotalExpedido, setDataTotalExpedido] = useState({ kg: 0, atraso: 0, dia: 0 })
  const [dataTotal, setDataTotal] = useState<any[]>([])
  const [dataOVsIDs, setDataOVsIDs] = useState<any[]>([])

  // Carregados primeiro
  const ovClientsKanbam = useQuery(['clients-kanbam', props.date],
    async () => {
      return getCodLNsKanbam()
    },
    {
      onSuccess: (data) => {
        ovClientsCarteira.refetch()
      },
      refetchOnWindowFocus: false
    }
  )

  const ovClientsCarteira = useQuery(['clients-carteira', null],
    async () => {
      return getCodLNsCarteira()
    },
    {
      onSuccess: (data) => {
        ovsCodesReposition.refetch()
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const ovsCodesReposition = useQuery(['codes-ovs-reposition', null],
    async () => {
      return getCodesOVsReposition()
    },
    {
      onSuccess(data) {
        ovKanbamDelayed.refetch()
        ovCarteira.refetch()
        ovPlateDelayed.refetch()
        ovCoil.refetch()

        ovPlate.refetch()
        ovCarteiraDelayed.refetch()
        ovOutOfState.refetch()
        ovKanbam.refetch()

        setDataOVsIDs(data)
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  // Carregados segundo
  const ovKanbamDelayed = useQuery(['ov-kanbam-delayed', null],
    async () => {
      return postOVByTypeDelayed(props.date, {
        clientsIds: ovClientsKanbam.data
      })
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const ovCarteira = useQuery(['ov-carteira', null],
    async () => {
      return postOVByType(props.date, {
        clientsIds: ovClientsCarteira.data
      })
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const ovPlateDelayed = useQuery(['ov-plate-delayed', null],
    async () => {
      return getOVByPlatesDelayed(props.date)
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const ovCoil = useQuery(['ov-coil', null],
    async () => {
      return getOVByCoil(props.date)
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const ovReposition = useQuery(['ov-reposition', dataOVsIDs],
    async (ctx) => {
      console.log('ctx', ctx)
      if (ctx.queryKey[1].length) {
        return postOVByID(props.date, dataOVsIDs)
      }
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const ovPlate = useQuery(['ov-plate', null],
    async () => {
      return getOVByPlate(props.date)
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const ovCarteiraDelayed = useQuery(['ov-carteira-delayed', null],
    async () => {
      return postOVByTypeDelayed(props.date, {
        clientsIds: ovClientsCarteira.data
      })
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const ovOutOfState = useQuery(['ov-out-of-state', null],
    async () => {
      return getOVOutOfState(props.date)
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const ovKanbam = useQuery(['ov-kanbam', null],
    async () => {
      return postOVByType(props.date, {
        clientsIds: ovClientsKanbam.data
      })
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  useEffect(() => {
    if (ovsCodesReposition.isFetched && !ovsCodesReposition.isFetching && !fetchLoading()) {
      parseData(
        ovKanbamDelayed.data?.result ?? [],
        ovCarteiraDelayed.data?.result ?? [],
        ovCarteira.data?.result ?? [],
        ovKanbam.data?.result ?? [],
        ovReposition.data?.result ?? [],
        ovPlateDelayed.data?.result ?? [],
        ovCoil.data?.result ?? [],
        ovPlate.data?.result ?? [],
        ovOutOfState.data?.result ?? [])
    }
  }, [ovKanbamDelayed.isFetching, ovCarteira.isFetching, ovPlateDelayed.isFetching, ovCoil.isFetching, ovReposition.isFetching,
  ovPlate.isFetching, ovCarteiraDelayed.isFetching, ovOutOfState.isFetching, ovKanbam.isFetching])

  const parseData = (kanbanDelayed: OV[], carteiraDelayed: OV[], carteira: OV[], kanbam: OV[],
    reposition: OV[], plateDelayed: OV[], coil: OV[], plate: OV[], outOfState: OV[]) => {
    console.log('dados carregados', outOfState)

    const colorAtrasado = '#EE207A'
    const colorDia = '#4060E6'

    const programado = [
      { name: 'Kanban Atraso', pv: 0, color: colorAtrasado },
      { name: 'Carteira Atraso', pv: 0, color: colorAtrasado },
      { name: 'Chapa Atraso', pv: 0, color: colorAtrasado },
      { name: 'Reposição', pv: 0, uv: 0, color: colorDia },
      { name: 'Lote Piloto', pv: 0, color: colorDia },
      { name: 'Kanban', pv: 0, color: colorDia },
      { name: 'Chapa', pv: 0, color: colorDia },
      { name: 'Carteira', pv: 0, color: colorDia },
      { name: 'Fora do estado', pv: 0, color: colorDia },
      { name: 'Papel', pv: 0, color: colorDia }
    ]

    const expedido = [
      { name: 'Kanban Atraso', pv: 0, color: colorAtrasado },
      { name: 'Carteira Atraso', pv: 0, color: colorAtrasado },
      { name: 'Chapa Atraso', pv: 0, color: colorAtrasado },
      { name: 'Reposição', pv: 0, color: colorDia },
      { name: 'Lote Piloto', pv: 0, color: colorDia },
      { name: 'Kanban', pv: 0, color: colorDia },
      { name: 'Chapa', pv: 0, color: colorDia },
      { name: 'Carteira', pv: 0, color: colorDia },
      { name: 'Fora do estado', pv: 0, color: colorDia },
      { name: 'Papel', pv: 0, color: colorDia }
    ]

    // Kanban atrasado
    if (kanbanDelayed.length) {
      let value = 0
      let valueExpedido = 0
      kanbanDelayed.forEach(v => {
        value += v.quantidadeSolicitada * v.peso
        valueExpedido += v.quantidadeFaturada * v.peso
      })
      programado[programado.findIndex(v => v.name === 'Kanban Atraso' && v.color === colorAtrasado)].pv = value
      expedido[expedido.findIndex(v => v.name === 'Kanban Atraso' && v.color === colorAtrasado)].pv = valueExpedido
    }

    // Carteira atrasado
    if (carteiraDelayed.length) {
      let value = 0
      let valueExpedido = 0
      carteiraDelayed.forEach(v => {
        value += v.quantidadeSolicitada * v.peso
        valueExpedido += v.quantidadeFaturada * v.peso
      })
      programado[programado.findIndex(v => v.name === 'Carteira Atraso' && v.color === colorAtrasado)].pv = value
      expedido[expedido.findIndex(v => v.name === 'Carteira Atraso' && v.color === colorAtrasado)].pv = valueExpedido
    }

    // Chapa atrasado
    if (plateDelayed.length) {
      let value = 0
      let valueExpedido = 0
      plateDelayed.forEach(v => {
        value += v.quantidadeSolicitada * v.peso
        valueExpedido += v.quantidadeFaturada * v.peso
      })
      programado[programado.findIndex(v => v.name === 'Chapa Atraso' && v.color === colorAtrasado)].pv = value
      expedido[expedido.findIndex(v => v.name === 'Chapa Atraso' && v.color === colorAtrasado)].pv = valueExpedido
    }

    // Reposição
    if (reposition.length) {
      let value = 0
      let valueExpedido = 0
      reposition.forEach(v => {
        value += v.quantidadeSolicitada * v.peso
        valueExpedido += v.quantidadeFaturada * v.peso
      })
      programado[programado.findIndex(v => v.name === 'Reposição' && v.color === colorDia)].pv = value
      expedido[expedido.findIndex(v => v.name === 'Reposição' && v.color === colorDia)].pv = valueExpedido
    }

    // Lote piloto
    // if (plateDelayed.length) {
    //   let value = 0
    //   let valueExpedido = 0
    //   plateDelayed.forEach(v => {
    //     value += v.quantidadeSolicitada * v.peso
    //      valueExpedido += v.quantidadeFaturada * v.peso
    //   })
    //   programado[programado.findIndex(v => v.name === 'Lote piloto' && v.color === colorAtrasado)].pv = value
    //   expedido[expedido.findIndex(v => v.name === 'Lote piloto' && v.color === colorDia)].pv = valueExpedido
    // }

    // Kanban
    if (kanbam.length) {
      let value = 0
      let valueExpedido = 0
      kanbam.forEach(v => {
        value += v.quantidadeSolicitada * v.peso
        valueExpedido += v.quantidadeFaturada * v.peso
      })
      programado[programado.findIndex(v => v.name === 'Kanban' && v.color === colorDia)].pv = value
      expedido[expedido.findIndex(v => v.name === 'Kanban' && v.color === colorDia)].pv = valueExpedido
    }

    // Chapa
    if (plate.length) {
      let value = 0
      let valueExpedido = 0
      plate.forEach(v => {
        value += v.quantidadeSolicitada * v.peso
        valueExpedido += v.quantidadeFaturada * v.peso
      })
      programado[programado.findIndex(v => v.name === 'Chapa' && v.color === colorDia)].pv = value
      expedido[expedido.findIndex(v => v.name === 'Chapa' && v.color === colorDia)].pv = valueExpedido
    }

    // Carteira
    if (carteira.length) {
      let value = 0
      let valueExpedido = 0
      carteira.forEach(v => {
        value += v.quantidadeSolicitada * v.peso
        valueExpedido += v.quantidadeFaturada * v.peso
      })
      programado[programado.findIndex(v => v.name === 'Carteira' && v.color === colorDia)].pv = value
      expedido[expedido.findIndex(v => v.name === 'Carteira' && v.color === colorDia)].pv = valueExpedido
    }

    // Fora do estado
    if (outOfState.length) {
      let value = 0
      let valueExpedido = 0
      outOfState.forEach(v => {
        value += v.quantidadeSolicitada * v.peso
        valueExpedido += v.quantidadeFaturada * v.peso
      })
      programado[programado.findIndex(v => v.name === 'Fora do estado' && v.color === colorDia)].pv = value
      expedido[expedido.findIndex(v => v.name === 'Fora do estado' && v.color === colorDia)].pv = valueExpedido
    }

    // Papel
    if (coil.length) {
      let value = 0
      let valueExpedido = 0
      coil.forEach(v => {
        value += v.quantidadeSolicitada * v.peso
        valueExpedido += v.quantidadeFaturada * v.peso
      })
      programado[programado.findIndex(v => v.name === 'Papel' && v.color === colorDia)].pv = value
      expedido[expedido.findIndex(v => v.name === 'Papel' && v.color === colorDia)].pv = valueExpedido
    }

    setDataTotalProgramado({
      dia: programado[3].pv + programado[4].pv + programado[5].pv +
        programado[6].pv + programado[7].pv + programado[8].pv,
      atraso: programado[0].pv + programado[1].pv + programado[2].pv,
      kg: programado[0].pv + programado[1].pv + programado[2].pv +
        programado[3].pv + programado[4].pv + programado[5].pv +
        programado[6].pv + programado[7].pv + programado[8].pv
    })

    setDataTotalExpedido({
      dia: expedido[3].pv + expedido[4].pv + expedido[5].pv +
        expedido[6].pv + expedido[7].pv + expedido[8].pv,
      atraso: expedido[0].pv + expedido[1].pv + expedido[2].pv,
      kg: expedido[0].pv + expedido[1].pv + expedido[2].pv +
        expedido[3].pv + expedido[4].pv + expedido[5].pv +
        expedido[6].pv + expedido[7].pv + expedido[8].pv
    })

    const total = [
      {
        name: 'Total a entregar',
        pv:
          programado[programado.findIndex(v => v.name === 'Kanban' && v.color === colorDia)].pv +
          programado[programado.findIndex(v => v.name === 'Carteira' && v.color === colorDia)].pv
      },
      {
        name: 'Total a produzir',
        pv: 0
      },
      {
        name: '(%) a Produzir',
        pv: 0
      }
    ]

    setDataProgramado(programado)
    setDataExpedido(expedido)
    setDataTotal(total)
  }

  const fetchLoading = () => {
    return (ovKanbamDelayed.isFetching || ovKanbamDelayed.isRefetching ||
      ovCarteiraDelayed.isFetching || ovCarteiraDelayed.isRefetching ||
      ovCarteira.isFetching || ovCarteira.isRefetching ||
      ovKanbam.isFetching || ovKanbam.isRefetching ||
      ovReposition.isFetching || ovReposition.isRefetching ||
      ovPlateDelayed.isFetching || ovPlateDelayed.isRefetching ||
      ovCoil.isFetching || ovCoil.isRefetching ||
      ovPlate.isFetching || ovPlate.isRefetching ||
      ovOutOfState.isFetching || ovOutOfState.isRefetching ||
      ovClientsKanbam.isFetching || ovClientsKanbam.isRefetching ||
      ovClientsCarteira.isFetching || ovClientsCarteira.isRefetching ||
      ovsCodesReposition.isFetching || ovsCodesReposition.isFetching)
  }

  if (fetchLoading()) {
    return (
      <Stack justifyContent="center" alignItems="center" height={200}>
        <CircularProgress />
        <div>Buscando dados do LN</div>
      </Stack>
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <div style={{ display: 'flex', width: '100%', gap: '1rem' }}>
          <div style={{ flex: 1, maxWidth: '50%' }}>
            <Card sx={{ display: 'flex' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <CardContent style={{ paddingBottom: '0px' }}>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 14px/12px Roboto' }}>
                        <strong>{'Volume Programado (KG)'}</strong>
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CardContent>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 12px/10px Roboto' }}>
                        <p>{'Total programado (KG)'}</p>
                      </Typography>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 24px/12px Roboto', marginTop: 16 }}>
                        <p>{dataTotalProgramado.kg.toLocaleString('pt-BR')}</p>
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CardContent>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 12px/10px Roboto' }}>
                        <p>{'Total atrasos dia'}</p>
                      </Typography>
                      <Typography component="div" style={{ color: '#EE207A', font: 'normal normal bold 24px/12px Roboto', marginTop: 16 }}>
                        <p>{dataTotalProgramado.atraso.toLocaleString('pt-BR')}</p>
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 12px/10px Roboto' }}>
                        <p>{'Total programado dia'}</p>
                      </Typography>
                      <Typography component="div" style={{ color: '#262626', font: 'normal normal bold 24px/12px Roboto', marginTop: 16 }}>
                        <p>{dataTotalProgramado.dia.toLocaleString('pt-BR')}</p>
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <Card sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={12}>
                <CardContent style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 14px/12px Roboto' }}>
                    <strong>{'Volume Expedido (KG)'}</strong>
                  </Typography>
                  <Typography component="div" style={{ display: 'flex', alignItems: 'center', font: 'normal normal normal 16px/12px Roboto', color: '#484848' }}>
                    <DotCV />
                    <div>Em atraso</div>
                    <DotCV2 />
                    <div>Do dia</div>
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} md={12}>
                <CardContent>
                  <SimpleBarChart data={dataProgramado} xKey="name" yKey="pv" zKey="uv" />
                </CardContent>
              </Grid>
            </Card>
            <Card sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={12}>
                <CardContent style={{ flexDirection: 'row' }}>
                  <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 14px/12px Roboto' }}>
                    <strong>{'Volume a entregar x produzir (KG)'}</strong>
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} md={12}>
                <CardContent>
                  <SimpleBarChartTotal data={dataTotal} xKey="name" yKey="pv" />
                </CardContent>
              </Grid>
            </Card>
          </div>

          {/* SEGUNDO CARD */}

          <div style={{ flex: 1, maxWidth: '50%' }}>
            <Card sx={{ display: 'flex' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <CardContent style={{ paddingBottom: '0px' }}>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 14px/12px Roboto' }}>
                        <strong>{'Volume Expedido (KG)'}</strong>
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CardContent>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 12px/10px Roboto' }}>
                        <p>{'Total expedido (KG)'}</p>
                      </Typography>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 24px/12px Roboto', marginTop: 16 }}>
                        <p>{dataTotalExpedido.kg.toLocaleString('pt-BR')}</p>
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CardContent>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 12px/10px Roboto' }}>
                        <p>{'Total atrasos expedidos dia'}</p>
                      </Typography>
                      <Typography component="div" style={{ color: '#EE207A', font: 'normal normal bold 24px/12px Roboto', marginTop: 16 }}>
                        <p>{dataTotalExpedido.atraso.toLocaleString('pt-BR')}</p>
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 12px/10px Roboto' }}>
                        <p>{'Total expedido dia'}</p>
                      </Typography>
                      <Typography component="div" style={{ color: '#262626', font: 'normal normal bold 24px/12px Roboto', marginTop: 16 }}>
                        <p>{dataTotalExpedido.dia.toLocaleString('pt-BR')}</p>
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <Card sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={12}>
                <CardContent style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <Typography component="div" style={{ color: '#484848', font: 'normal normal bold 14px/12px Roboto' }}>
                    <strong>{'Volume Programado (KG)'}</strong>
                  </Typography>
                  <Typography component="div" style={{ display: 'flex', alignItems: 'center', font: 'normal normal normal 16px/12px Roboto', color: '#484848' }}>
                    <DotCV />
                    <div>Em atraso</div>
                    <DotCV2 />
                    <div>Do dia</div>
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} md={12}>
                <CardContent>
                  <SimpleBarChart data={dataExpedido} xKey="name" yKey="pv" zKey="uv" />
                </CardContent>
              </Grid>
            </Card>
          </div>
        </div>
      </Box>
    </>
  )
}

export default NewDashBoard
