import React, { useState } from 'react'
import { useFormik } from 'formik'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { TextField } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import Button from 'components/form/Button'
import FormUserType from 'types/user/FormUserType'
import { validationSchema } from 'types/validation/ValidationUser'
import { httpClient } from 'config/httpClient'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { toast } from 'react-toastify'
import { myAccount, updateUser } from 'services/user/UserService'
import InputFileEdit from 'components/form/Input/InputFileEdit'
import MyAccountType from 'types/user/MyAccountType'

interface Props {
  open: boolean
  close: () => void
}

const UserAccount: React.FC<Props> = (props) => {
  const { loginData } = useSelector((state: RootState) => state.login)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [userData, setUserData] = useState<MyAccountType>()

  React.useEffect(() => {
    getMyAccount(loginData.email)
  }, [])

  const getMyAccount = async (loginData: string) => {
    try {
      myAccount(loginData).then((data) => {
        setUserData(data)
        })
        .catch(() => toast.error('Erro ao listar dados')).finally(() => setLoading(false))
    } catch (error: any) {
      console.log('Error: ', error)
      toast.error('Erro ao atualizar dados')
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: FormUserType) => {
      console.log('DADOS', values)
      try {
        const dadosBody: any = {
          name: values.name,
          email: values.email,
          profile: values.profile._id,
          occupation: values.role,
          departament: values.sector,
          nick: values.nick,
          modules: values.modules
        }
        await updateUser(String(values._id), dadosBody)
        toast.success('Dados editados com sucesso!')
        props.close()
        // setTimeout(() => {
        //   navigate('/home/registration')
        // }, 2500)
      } catch (error: any) {
        console.log('erro', error.response.data)
        if (error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Erro ao editar informações do usuario')
        }
      } finally {
        setLoading(false)
      }
    },
    initialValues: {
      name: userData?.name ?? '',
      email: userData?.email ?? '',
      sector: userData?.departament ?? '',
      profile: userData?.profile.name ?? '',
      modules: userData?.modules,
      role: userData?.occupation ?? '',
      file: userData?.url ?? '',
      _id: userData?._id ?? ''
    }
  })

  const updatePicture = async (blob) => {
    try {
      const formData = new FormData()
      formData.append('file', blob)
      await httpClient.patch('/users/photo/' + String(userData?._id), formData)
      toast.success('Foto salva com sucesso!')
    } catch (error: any) {
      if (error.response.data.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Erro ao alterar foto')
      }
    }
  }

  return (
    <Dialog
      open={props.open}
      keepMounted
      fullWidth
      onClose={props.close}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <p style={{ marginBottom: '10px' }}>Minhas informações</p>
        <form onSubmit={formik.handleSubmit}>
          <span style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                marginRight: '30px'
              }}
            >
              <div style={{ position: 'relative', alignSelf: 'center', marginRight: '40px' }}>
                <InputFileEdit getFile={async (file, blob) => updatePicture(blob)} preview={formik.values.file ?? userData?.url} />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                <InfoOutlined color="primary" />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '10px'
                  }}
                >
                  <div style={{ fontSize: '14px' }}>
                    Importante
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    Tamanho máximo 2mb, 256x256 px.
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <TextField
                error={!!(formik.errors.name && formik.touched.name)}
                id="standard-basic"
                label="Nome completo"
                variant="standard"
                value={formik.values.name}
                onChange={formik.handleChange('name')}
                helperText={formik.errors.name && formik.touched.name ? 'Campo Obrigatorio' : ''}
              />
              <TextField
                error={!!(formik.errors.sector && formik.touched.sector)}
                style={{ marginTop: '24px' }}
                id="standard-basic"
                label="Setor"
                variant="standard"
                value={formik.values.sector}
                onChange={formik.handleChange('sector')}
                helperText={formik.errors.sector && formik.touched.sector ? 'Campo Obrigatorio' : ''}
              />
              <TextField
                error={!!(formik.errors.role && formik.touched.role)}
                style={{ marginTop: '24px' }}
                id="standard-basic"
                label="Função"
                variant="standard"
                value={formik.values.role}
                onChange={formik.handleChange('role')}
                helperText={formik.errors.role && formik.touched.role ? 'Campo Obrigatorio' : ''}
              />
            </div>
          </span>

          <span style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flex: 1, marginLeft: '10px' }}>
              <TextField
                disabled
                error={!!(formik.errors.profile && formik.touched.profile)}
                style={{ marginTop: '24px', width: '100%' }}
                id="standard-basic"
                label="Selecionar tipo de perfil"
                variant="standard"
                value={formik.values.profile}
                onChange={formik.handleChange('profile')}
                helperText={formik.errors.profile && formik.touched.profile ? 'Campo Obrigatorio' : ''}
              />
            </div>

            <div style={{ display: 'flex', flex: 1 }}>
            </div>
          </span>
          <div style={{ flexDirection: 'row', marginTop: '20px', justifyContent: 'right', display: 'flex' }}>
            <Button mold='red' type='button' style={{ marginRight: '10px' }} onClick={() => props.close()} loading={false}>Cancelar</Button>
            <Button mold='primary' type='submit' loading={loading}>Salvar</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default UserAccount
