import React from 'react'
import { Pie, PieChart, Tooltip } from 'recharts'
import styled from 'styled-components'
import ChartBottomLabel from './ChartBottomLabel'

const RowCV = styled.div`
display: flex;
align-items: center;
justify-content: center;
`
const LabelTitle = styled.div`
 display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`
const CustomTooltip = ({ active, title, payload, event }) => {
  if (active && payload && payload.length) {
    const TooltipContent = styled.div`
    background-color: white;
    padding: 5px;
    border: 1px solid ${payload[0].payload.fill};
    border-radius: 5px;
    `
    return (
      <TooltipContent>
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>{`${title}`}</p>
        <p style={{ fontSize: 12 }}>{`${payload[0].value.toLocaleString('pt-br')}`}</p>
      </TooltipContent>
    )
  }

  return null
}

function ChartPie(props: any) {
  return (
    <div className="app">
      <LabelTitle>
        <p>{props.title}</p>
        <p style={{ fontSize: '11px' }}>Mês {props.month}</p>
      </LabelTitle>
      <div className="main">
        <RowCV>
          <PieChart width={240} height={240} margin={{ top: 25, right: 25, bottom: 25, left: 25 }}>
            <Tooltip content={event => <CustomTooltip active={event.active} title={props.title} payload={event.payload} event={event} />} />
            <Pie data={props.data} dataKey="value" nameKey="title" cx="50%" cy="50%" innerRadius={50} outerRadius={90} />
          </PieChart>
        </RowCV>
        <RowCV>
          {props.data.map(d => (
            <ChartBottomLabel title={d.title} color={d.fill} />
          ))}
        </RowCV>
      </div>
    </div>
  )
}

export default ChartPie
