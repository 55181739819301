import styled from 'styled-components'
import imageUpload from '../../../../assets/img/upload.png'

const ContainerFile = styled.div`
  height: 9.6875rem;
  width: 9.6875rem;
  position: relative;
  background-size: cover;
  background-position: center top;
  border-radius: 1.5rem;
  margin: auto;
  box-shadow: 0px 0px 0px 2px #3b58c3, inset 0px 0px 0px 3px #fff;
  display: grid;
  align-items: flex-start;

  & .image-callback {
    align-self: flex-end;
    justify-self: center;
    color: #969799;
  }
`

const TextCallbackImage = styled.p`
  font-size: 14px;
  color: #969799;
  text-align: center;
`

const IconUpload = styled.i`
  cursor: pointer;
  content: '';
  height: 2.3rem;
  width: 2.3rem;
  display: inline-block;
  position: absolute;
  bottom: -5px;
  right: -5px;
  border-radius: 50%;
  background-image: url(${imageUpload});
  background-repeat: no-repeat;
  background-color: #00b9ff;
  background-position: center;
`

export { ContainerFile, TextCallbackImage, IconUpload }
