import { Box, CircularProgress, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getReportVolume } from 'services/report/ReportVolume'
import { ReportVolumeMes } from 'types/Report/ReportVolume'
import { StandardFilter } from 'utils/StandardFilter'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
}

const TableReportStock: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [dados, setDados] = useState<any>([])
  const [dataSource, setDataSource] = useState<any[]>([])
  const [filters, setFilters] = useState<any>({})
  const [meses, setMeses] = useState<ReportVolumeMes[]>([])
  const mes = moment(props.date).format('M')
  const date = moment(props.date).format('DD/MM/YYYY')

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const queryMultiple = () => {
    // requisição externa do LN
    const req1 = useQuery(['caixas', date],
      async () => {
        return getReportVolume(date)
      },
      {
        onSuccess: (data) => {
          const filteredData = data.response.filter((item) => (
            item.dados.some((dado) => dado.type === TipoProduto.CAIXA)
          ))
          setDataSource(filteredData)
          setDados(filteredData)
          handlePeriodo()
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    return [req1]
  }

  const [req1] = queryMultiple()

  useEffect(() => {
    StandardFilter(filters, setDados, dataSource)
  }, [filters, dataSource])

  const handlePeriodo = () => {
    const mesesValue = [
      {
        text: 'Janeiro',
        value: 1
      },
      {
        text: 'Fevereiro',
        value: 2
      },
      {
        text: 'Março',
        value: 3
      },
      {
        text: 'Abril',
        value: 4
      },
      {
        text: 'Maio',
        value: 5
      },
      {
        text: 'Junho',
        value: 6
      },
      {
        text: 'Julho',
        value: 7
      },
      {
        text: 'Agosto',
        value: 8
      },
      {
        text: 'Setembro',
        value: 9
      },
      {
        text: 'Outubro',
        value: 10
      },
      {
        text: 'Novembro',
        value: 11
      },
      {
        text: 'Dezembro',
        value: 12
      }
    ]
    const meses: any[] = []
    let i = 0
    while (i < Number(mes)) {
      meses.push(mesesValue[i])
      i++
    }
    setMeses(meses)
  }

  const getRowByPeriodo = (row: any, periodo: number) => {
    const dadosFilter = row.dados.filter((valor) => valor.type === TipoProduto.CAIXA)
    return dadosFilter.find((item) => Number(item.periodo) === periodo)
  }
  const getSumByPeriodKG = (row: any) => {
    const dadosFilter = row.dados.filter((valor) => valor.type === TipoProduto.CAIXA)
    return dadosFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.kg)
    }, 0)
  }

  const getSumByPeriodRSKg = (row: any) => {
    const dadosFilter = row.dados.filter((valor) => valor.type === TipoProduto.CAIXA)
    return dadosFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.rskg)
    }, 0)
  }
  return (
    <>
      {(req1.isLoading || req1.isFetching) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <CircularProgress />
        </Box>
      )}
      {req1.isError && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
        </Box>
      )}

      {(req1.isSuccess && !req1.isError && !req1.isFetching) && (
        <>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: '100%' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      Cliente
                    </TableCell>
                    {
                      meses.map((mes) => (
                        <TableCell align="center" colSpan={2}>
                          {mes.text}
                        </TableCell>
                      ))
                    }
                    <TableCell align="center">
                      Acumulado
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell key='cliente_carne' style={{ width: '20%' }}>
                      <TextField
                        id="standard-basic"
                        label="Pesquisar Cliente"
                        variant="standard"
                        style={{ width: '100%' }}
                        onChange={(e) => setFilters({ ...filters, client: e.target.value })}
                      />
                    </TableCell>
                    {meses.map((mes, index) => (
                      <>
                        <TableCell
                          key={mes.text}
                          align={'center'}
                        >
                          KG
                        </TableCell>
                        <TableCell
                          key={mes.text}
                          align={'center'}
                        >
                          R$/KG
                        </TableCell>
                      </>
                    ))}
                    <TableCell align={'center'}>
                      KG
                    </TableCell>
                    <TableCell align={'center'}>
                      R$/KG
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dados
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const valorTotalKG = getSumByPeriodKG(row)
                      const valorTotalRSKg = getSumByPeriodRSKg(row)
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          <TableCell key='nome_cliente'>
                            {row.client}
                          </TableCell>
                          {meses.map((mes) => {
                            const valor = getRowByPeriodo(row, mes.value)
                            return (
                              <>
                                <TableCell key={mes.text} align={'center'}>
                                  {Number(valor?.kg ?? 0).toLocaleString('pt-br')}
                                </TableCell>
                                <TableCell key={mes.text} align={'center'}>
                                  {Number(valor?.rskg ?? 0).toLocaleString('pt-br')}
                                </TableCell>
                              </>
                            )
                          })}
                          <TableCell key='nome_cliente' align={'center'}>
                            {valorTotalKG.toLocaleString('pt-br')}
                          </TableCell>
                          <TableCell key='nome_cliente' align={'center'}>
                            {valorTotalRSKg.toLocaleString('pt-br')}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={dados.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
    </>
  )
}

export default TableReportStock
