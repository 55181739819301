import React from 'react'
import styled from 'styled-components'

function ChartBottomLabel(props: any) {
  const DotCV = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props.color};
  border-radius: 20px;
  margin-right: 10px;
  margin-left: 15px;
`

  const RowCV = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

  return (
    <RowCV>
      <DotCV />
      <div>{props.title}</div>
    </RowCV>
  )
}

export default ChartBottomLabel
