/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Clear } from '@mui/icons-material'
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import Button from 'components/form/Button'
import React, { useRef, useState } from 'react'
import { LoadingTable } from './LoadingTable'
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableRow
} from './table.styles'
import { formatValue } from 'utils/formatString'
import { Armazem } from 'utils/enums'
import WarehouseCheckboxes from './WarehouseCheckbox'

interface Props {
  data: any
  status: string
  isFetching: boolean
}

export const SummaryTable: React.FC<Props> = ({
  data,
  status,
  isFetching
}) => {
  const inputSearchParceiroRef = useRef<HTMLInputElement>(null)
  const [page, setPage] = useState<any>(0)
  const [searchValueParceiro, setSearchValueParceiro] = React.useState('')
  const [checkboxes, setCheckboxes] = useState<string[]>([Armazem.CAIXA, Armazem.CHAPA, Armazem.PAPEL])
  const paginationSize = 10

  let rows = data?.result
    ?.filter((row: any) => {
      return (
        row?.nomeParceiro
          ?.toUpperCase()
          ?.includes(searchValueParceiro?.toUpperCase())
      )
    })
    .reduce((accumulator, current) => {
      // agrupar por Nome do Parceiro
      if (!checkboxes.includes(current.armazem)) {
        return accumulator
      }
      const { nomeParceiro, ...rest } = current
      const existingItem = accumulator.find(item => item.nomeParceiro === nomeParceiro)

      if (existingItem) {
        existingItem.pesoEstoqueDisponivel = Number(existingItem.pesoEstoqueDisponivel) + Number(rest.pesoEstoqueDisponivel)
        existingItem.ordemPesoTotal = Number(existingItem.ordemPesoTotal) + Number(rest.ordemPesoTotal)
        existingItem.quantidadeEntregueKg = Number(existingItem.quantidadeEntregueKg) + Number(rest.quantidadeEntregueKg)
      } else {
        accumulator.push({
          nomeParceiro,
          codigoParceiro: rest.codigoParceiro,
          pesoEstoqueDisponivel: rest.pesoEstoqueDisponivel,
          ordemPesoTotal: rest.ordemPesoTotal,
          quantidadeEntregueKg: rest.quantidadeEntregueKg
        })
      }
      return accumulator
    }, [])
    .map(row => {
      const saldo = row.ordemPesoTotal - row.quantidadeEntregueKg
      const consumo = row.pesoEstoqueDisponivel <= saldo ? row.pesoEstoqueDisponivel : saldo
      const sobra = row.pesoEstoqueDisponivel - saldo >= 0 ? row.pesoEstoqueDisponivel - saldo : 0
      return {
        ...row,
        consumo,
        sobra
      }
    })
  // ordenar por nomeParceiro
  /*
  .sort((a: any, b: any) => {
    const nameA = a.nomeParceiro.toUpperCase()
    const nameB = b.nomeParceiro.toUpperCase()
    if (nameA > nameB) {
      return 1
    }
    if (nameA < nameB) {
      return -1
    } else {
      return 0
    }
  })
  */

  console.log(rows)

  const estoqueTotals = rows?.reduce(
    (acc, stock) => {
      return {
        pesoEstoqueDisponivelTotal: acc.pesoEstoqueDisponivelTotal + Number(stock?.pesoEstoqueDisponivel),
        consumoTotal: acc.consumoTotal + Number(stock?.consumo),
        sobraTotal: acc.sobraTotal + Number(stock?.sobra)
      }
    },
    {
      pesoEstoqueDisponivelTotal: 0,
      consumoTotal: 0,
      sobraTotal: 0
    }
  )

  rows = rows
    ?.map(row => ({
      ...row,
      porcentagemSobra: (row.sobra / estoqueTotals.sobraTotal) * 100
    }))
    .sort((a: any, b: any) => {
      return b.porcentagemSobra > a.porcentagemSobra ? 1 : -1
    })

  const handleClearSearchParceiro = () => {
    setSearchValueParceiro('')
    if (inputSearchParceiroRef?.current?.value) {
      inputSearchParceiroRef.current.value = ''
    }
  }

  return (
    <>
      <Stack direction="row" spacing={3} sx={{ paddingBottom: 2 }}>
        <FormControl variant="standard" className="w-100">
          <InputLabel htmlFor="field-search">Pesquisar por parceiro</InputLabel>
          <Input
            id="field-search"
            autoComplete="off"
            type="text"
            value={searchValueParceiro}
            onChange={(e) => {
              setSearchValueParceiro(e.target.value)
              setPage(0)
            }}
            inputRef={inputSearchParceiroRef}
            disabled={isFetching}
            endAdornment={
              <InputAdornment position="end">
                {(!!searchValueParceiro?.length ||
                  !!inputSearchParceiroRef?.current?.value?.length) && (
                    <Button
                      mold="red"
                      type="button"
                      style={{
                        padding: 0,
                        paddingLeft: 8,
                        paddingRight: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 4
                      }}
                      onClick={handleClearSearchParceiro}
                      disabled={isFetching}
                    >
                      <Clear />
                    </Button>
                  )}
              </InputAdornment>
            }
          />
        </FormControl>
        <WarehouseCheckboxes
          setCheckboxes={setCheckboxes}
          setPage={setPage}
        />
      </Stack>

      <TableContainer component={Paper} sx={{ width: '80vw', margin: '0 auto' }}>
        <Table sx={{ width: '100%' }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableHeadCell align="left">Rótulos de Linha</StyledTableHeadCell>
              <StyledTableHeadCell align="left">Soma de Peso de Estoque disponível</StyledTableHeadCell>
              <StyledTableHeadCell align="left">Soma de Consumo</StyledTableHeadCell>
              <StyledTableHeadCell align="left">Soma de Sobra de Estoque</StyledTableHeadCell>
              <StyledTableHeadCell align="left">% Sobra</StyledTableHeadCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ top: 57 }} sx={{ position: 'sticky', left: 0, zIndex: 3 }}>
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="bold"
                  noWrap
                >
                  TOTAL
                </Typography>
              </StyledTableCell>

              <StyledTableCell style={{ top: 57 }}>
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="bold"
                  noWrap
                >
                  {formatValue(estoqueTotals?.pesoEstoqueDisponivelTotal, { precision: 1 })}
                </Typography>
              </StyledTableCell>

              <StyledTableCell style={{ top: 57 }}>
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="bold"
                  noWrap
                >
                  {formatValue(estoqueTotals?.consumoTotal, { precision: 1 })}
                </Typography>
              </StyledTableCell>

              <StyledTableCell style={{ top: 57 }}>
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="bold"
                  noWrap
                >
                  {formatValue(estoqueTotals?.sobraTotal, { precision: 1 })}
                </Typography>
              </StyledTableCell>

              <StyledTableCell style={{ top: 57 }}></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!rows?.length && status !== 'loading' && (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={8}>
                  Nenhum dado encontrado.
                </StyledTableCell>
              </StyledTableRow>
            )}

            {status === 'loading' && (
              <StyledTableRow>
                <StyledTableCell colSpan={8}>
                  <LoadingTable />
                </StyledTableCell>
              </StyledTableRow>
            )}

            {!!rows?.length &&
              rows?.slice(page * paginationSize, page * paginationSize + paginationSize).map((row, index) => (
                <StyledTableRow key={`${row?.nomeParceiro}-${index}`}>
                  <StyledTableCell align="left">
                    <Typography component="span" variant="body2" noWrap>
                      {row?.nomeParceiro}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography component="span" variant="body2" noWrap>
                      {formatValue(row?.pesoEstoqueDisponivel, { precision: 1 })}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography component="span" variant="body2" noWrap>
                      {formatValue(row?.consumo, { precision: 1 })}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography component="span" variant="body2" noWrap>
                      {formatValue(row?.sobra, { precision: 1 })}
                    </Typography>
                  </StyledTableCell>
                  <Tooltip
                    title={formatValue(row?.porcentagemSobra, { isPercent: true, precision: 2 })}
                    placement='right'
                    followCursor
                  >
                    <StyledTableCell align="left">
                      <Typography component="span" variant="body2" noWrap>
                        {formatValue(row?.porcentagemSobra, { isPercent: true })}
                      </Typography>
                    </StyledTableCell>
                  </Tooltip>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ width: '80vw', margin: '5px auto' }}>
        <TablePagination
          sx={{ width: 'fit-content', float: 'right', marginLeft: '10px', marginTop: '3px' }}
          rowsPerPageOptions={[paginationSize]}
          rowsPerPage={paginationSize}
          component={Paper}
          count={rows?.length ?? 0}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      </div>
      <br /> <br />
    </>
  )
}
