/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import BudgetToolsType from 'types/purchaseOrder/BudgetTools.type'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Grid,
  Typography,
  Button as MuiButton,
  Icon,
  TextField,
  CircularProgress,
  Chip
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import { useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import ImportantView from 'components/view/ImportantView'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { _changePage } from 'pages/home/slices/home.slice'
import { pages } from 'pages/home/HomePage'
import { httpClient } from 'config/httpClient'
import { BudgetToolItem } from '../components/BudgetToolItem'
import Button from 'components/form/Button'
import { RootState } from 'core/store'

interface AccordionProps {
  title: string
  status?: string
  isExpanded: boolean
  onToggleExpanded?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => void
  children: ReactNode
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  status,
  isExpanded,
  onToggleExpanded,
  children
}) => {
  const colors = {
    APROVADO: '#72CE2B',
    REPROVADO: '#EE207A',
    PENDENTE: '#CCC'
  }

  return (
    <MuiAccordion expanded={isExpanded} onChange={onToggleExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${title}-content`}
        id={`panel${title}-header`}
      >
        <Box display="flex" alignItems="center">
          <Typography component="strong" fontWeight="bold">
            {title}
          </Typography>
          {status && (
            <Chip
              variant="outlined"
              size="small"
              label={status}
              sx={{
                textTransform: 'capitalize',
                borderColor: colors[status],
                color: colors[status],
                marginLeft: 3
              }}
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  )
}

const BudgetTools: React.FC = () => {
  const { loginData } = useSelector((state: RootState) => state.login)

  const dispatch = useDispatch()
  const { state }: any = useLocation()
  const [expanded, setExpanded] = useState<any | false>(false)
  const [loading, setLoading] = useState(true)
  const [image, setImage] = useState<string | Blob>()
  const [fts, setFts] = useState<any[]>([])
  console.log(state)

  const loadBudgetTools = useCallback(async () => {
    try {
      const response = await httpClient.get(
        `/budgets-tool/pedido/${state?.item?._id}`
      )
      console.log(response.data)
      setFts(
        response.data?.map((item) => ({
          ...item,
          budgetsTool: item?.budgetsTool?.length
            ? item.budgetsTool
            : [
              {
                ft: item?.ft?._id,
                codItem: item?.codItem,
                pedido: item?.pedido
              },
              {
                ft: item?.ft?._id,
                codItem: item?.codItem,
                pedido: item?.pedido
              }
            ]
        }))
      )
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }, [state?.item?._id])

  useEffect(() => {
    dispatch(
      _changePage({
        name: 'Ferramental',
        description: 'Anexe e aprove o orçamento do ferramental da FT',
        icon: 'shopping_bag',
        route: 'purchaseRequests/budgetTools',
        cod: 'MO'
      })
    )

    void loadBudgetTools()
  }, [])

  const expandedHandle =
    (panel: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const handlePushSolicitacao = (id: string) => {
    // const newOrdem = (orcamento as any[]) || (orcamentoVazio as any[])
    // newOrdem.push({
    //   justification: ''
    // })
    // setFieldValue('balance', newOrdem)
    setFts((state) =>
      state?.map((item) =>
        item._id === id
          ? {
            ...item,
            budgetsTool: [
              ...item.budgetsTool,
              {
                ft: item?.ft?._id,
                codItem: item?.codItem,
                pedido: item?.pedido
              }
            ]
          }
          : item
      )
    )
  }
  const navigate = useNavigate()

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <BreadcrumbsComponent
          breadcrumbs={[
            { text: 'Pedido de compra', url: '/home/purchaseRequests' },
            { text: 'Ferramental' }
          ]}
        />
        <h4>Ferramental</h4>

        {loading && (
          <Box display="flex" alignItems="center" height="200">
            <CircularProgress size={24} />
          </Box>
        )}

        {fts?.map((item, index) => {
          return (
            <Box key={item?._id} style={{ marginTop: 3 }}>
              <Accordion
                title={item?.ft?._id ? `FT ${item?.ft?.itemReference}` : `Cod. Item ${item?.codItem}`}
                isExpanded={expanded === index}
                onToggleExpanded={expandedHandle(index)}
              >
                {item?.budgetsTool?.map((budget, indexBudget) => (
                  <Accordion
                    key={`${budget?._id}-${indexBudget}`}
                    title={`Orçamento ${indexBudget + 1}`}
                    status={budget?.status}
                    isExpanded={true}
                    onToggleExpanded={() => { }}
                  >
                    <BudgetToolItem
                      data={budget}
                      cod={`${budget?._id}-${indexBudget}-${index}`}
                      canAction={loginData?.tokenData?.profile?.includes('COMPRA')}
                      onChange={(values) => {
                        setFts((state) =>
                          state?.map((budgetItem) =>
                            budgetItem?._id === item?._id
                              ? {
                                ...budgetItem,
                                budgetsTool: budgetItem?.budgetsTool?.map(
                                  (currentBudget, currentBudgetIndex) =>
                                    currentBudgetIndex === indexBudget
                                      ? { ...currentBudget, ...values }
                                      : currentBudget
                                )
                              }
                              : budgetItem
                          )
                        )
                      }}
                    >
                      {indexBudget > 1 && !budget?._id && (
                        <Button
                          mold="red"
                          type='button'
                          onClick={() => {
                            setFts((state) =>
                              state?.map((budgetItem) =>
                                budgetItem?._id === item?._id
                                  ? {
                                    ...budgetItem,
                                    budgetsTool: budgetItem?.budgetsTool?.filter(
                                      (_, ftBudgetIndex) =>
                                        ftBudgetIndex !== indexBudget
                                    )
                                  }
                                  : budgetItem
                              )
                            )
                          }}
                        >
                          Remover
                        </Button>
                      )}
                    </BudgetToolItem>
                  </Accordion>
                ))}
                <Grid item xs={6} style={{ marginTop: '20px' }}>
                  <MuiButton
                    variant="outlined"
                    size="large"
                    startIcon={<AddIcon />}
                    onClick={() => handlePushSolicitacao(item?._id)}
                    sx={{
                      border: 'none',
                      textTransform: 'inherit',
                      boxShadow: '0px 3px 6px #00000029',
                      color: '#8A8A8A',
                      '&:hover': {
                        border: 'none'
                      }
                    }}
                  >
                    Novo orçamento
                  </MuiButton>
                </Grid>
              </Accordion>
            </Box>
          )
        })}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 3
          }}
        >
          <Button
            mold='primary'
            onClick={() => navigate('/home/purchaseRequests', { state: { tab: state.tab } })}
          >
            Voltar
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default BudgetTools
