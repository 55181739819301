import { DialogTitle } from '@mui/material'
import {
  CardContentChieldren,
  CardFather,
  DivAlingCard,
  DivAlingItensCard,
  RetangleView
} from 'pages/reportControl/deliveryMap/styles/dialogMap.styles'
import React from 'react'

export const ModalHead: React.FC<any> = (props) => {
  return (
    <DialogTitle id="alert-dialog-title">
      <DivAlingCard>
        <RetangleView />
        <CardFather>
          <CardContentChieldren>
            <DivAlingItensCard>
              <div style={{ marginRight: '20px' }}>{props?.title}</div>
            </DivAlingItensCard>
          </CardContentChieldren>
        </CardFather>
      </DivAlingCard>
    </DialogTitle>
  )
}
