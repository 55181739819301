import React, { useCallback } from 'react'
import { TableHead, TableRow } from '@mui/material'
import { ReportSellerSearch } from './ReportSellerSearch'
import { StyledTableCell, StyledTableHeadCell } from './table.styles'

interface Props {
  countClients?: number
  total?: any
  searchSeller: (value: string) => void
  searchClient: (value: string) => void
}

export const ReportSellerTableHead: React.FC<Props> = ({
  countClients,
  total,
  searchSeller,
  searchClient
}) => {
  const debounce = useCallback((func: any, wait: number): any => {
    let timeout: NodeJS.Timeout

    return function executedFunction(...args: number[]) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }, [])

  const returnedFunctionSeller = debounce((event: any) => {
    if (event?.target?.value) {
      searchSeller(event.target.value)
    } else {
      searchSeller('')
    }
  }, 500)

  const returnedFunctionClient = debounce((event: any) => {
    if (event?.target?.value) {
      searchClient(event.target.value)
    } else {
      searchClient('')
    }
  }, 500)

  return (
    <TableHead>
      <TableRow>
        <StyledTableHeadCell>VENDEDOR</StyledTableHeadCell>
        <StyledTableHeadCell>CLIENTE</StyledTableHeadCell>
        <StyledTableHeadCell align="left" colSpan={1}>PREVISÃO</StyledTableHeadCell>
        <StyledTableHeadCell align="left">CADASTRADO</StyledTableHeadCell>
        <StyledTableHeadCell align="left">EFICIÊNCIA</StyledTableHeadCell>
      </TableRow>

      <TableRow>
        <StyledTableCell align="left" sx={{ fontWeight: 'regular' }}>
          <ReportSellerSearch
            placeholder="Pesquisar por vendedor"
            onChange={(event) => returnedFunctionSeller(event)}
          />
        </StyledTableCell>
        <StyledTableCell align="left" sx={{ fontWeight: 'regular' }}>
          <ReportSellerSearch
            placeholder="Pesquisar por cliente"
            onChange={(event) => returnedFunctionClient(event)}
          />
        </StyledTableCell>
        {/* <StyledTableCell
          align="left"
          sx={{ fontWeight: 'regular' }}
        >
          R$
        </StyledTableCell> */}
        <StyledTableCell
          align="left"
          sx={{ fontWeight: 'regular' }}
        >
          KG
        </StyledTableCell>
        {/* <StyledTableCell
          align="left"
          sx={{ fontWeight: 'regular' }}
        >
          R$/KG
        </StyledTableCell> */}
        <StyledTableCell align="left" sx={{ fontWeight: 'regular' }}>
          {/* R$ */}
          KG
        </StyledTableCell>
        <StyledTableCell align="left" sx={{ fontWeight: 'regular' }}>
          %
        </StyledTableCell>
      </TableRow>

      {/* <TableRow>
        <StyledTableCell
          align="left"
          sx={{ fontWeight: 'bold', backgroundColor: '#EBEBEB' }}
        >
          TOTAL
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{ fontWeight: 'bold', backgroundColor: '#EBEBEB' }}
        >
          {countClients} Clientes
        </StyledTableCell>
        <StyledTableCell align="left" sx={{ padding: 0, backgroundColor: '#EBEBEB' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  sx={{
                    borderBottom: 0,
                    fontWeight: 'bold'
                   // backgroundColor: '#EBEBEB'
                  }}
                >
                  <Typography
                    component="span"
                    variant="body2"
                    fontWeight="bold"
                    noWrap
                  >
                    {total?.formattedPrevisionPrice}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{
                    borderBottom: 0,
                    fontWeight: 'bold'
                   // backgroundColor: '#EBEBEB'
                  }}
                >
                  {total?.formattedPesoKg}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{
                    borderBottom: 0,
                    fontWeight: 'bold'
                   // backgroundColor: '#EBEBEB'
                  }}
                >
                  <Typography
                    component="span"
                    variant="body2"
                    fontWeight="bold"
                    noWrap
                  >
                    {total?.formattedPrevisionPriceKg}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{ fontWeight: 'bold', backgroundColor: '#EBEBEB' }}
        >
          <Typography component="span" variant="body2" fontWeight="bold" noWrap>
            {total?.formattedBilled}
          </Typography>
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{ fontWeight: 'bold', backgroundColor: '#EBEBEB' }}
        >
          {total?.formattedEfficiency}
        </StyledTableCell>
      </TableRow> */}
    </TableHead>
  )
}
