/* eslint-disable space-before-blocks */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React from 'react'
import {
  Box,
  CircularProgress,
  Icon
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useQuery } from 'react-query'
import {
  getInsumosPapel, getInsumosPapelTotalKg, getInsumosPapelValorTotal
} from 'services/budgetRealized/supplies/PaperSupplies'
import moment from 'moment'
import { toBRL, toKG } from 'utils/formatString'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

interface Props {
  date: string
}

function createDataObra(
  col1,
  col2,
  col3,
  col4,
  col5,
  col6,
  col7Param
) {
  const col7Data = col7Param.map((item) => ({
    conta: item.itef,
    desc: item.dsca,
    emp: '',
    porc: '',
    lanca: 0,
    prev: item.qnty,
    result: item.value
  }))

  return {
    col1,
    col2,
    col3,
    col4,
    col5,
    col6,
    col7: col7Data
  }
}
function Row(props: { row: ReturnType<typeof createDataObra>, rowTotal: any, rowValues: any }) {
  const { row, rowTotal, rowValues } = props
  const [open, setOpen] = React.useState(false)
  const [backgroundColor, setBackgroundColor] = React.useState('white')

  const toggleBackgroundColor = () => {
    setBackgroundColor((prevColor) => prevColor === '#4060E3' ? 'white' : '#4060E3')
  }

  const fragment = (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ backgroundColor }} >
        <StyledTableCell>
          <IconButton
            style={{ fontWeight: 'bold', fontSize: 'larger', color: backgroundColor === '#4060E3' ? 'white' : 'black' }}
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open)
              toggleBackgroundColor()
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: 'larger', color: backgroundColor === '#4060E3' ? 'white' : 'black' }}>
          {row.col1}
        </StyledTableCell>
        <StyledTableCell align="center">{row.col2}</StyledTableCell>
        <StyledTableCell align="center">{row.col3}</StyledTableCell>
        <StyledTableCell align="center">{row.col4}</StyledTableCell>
        <StyledTableCell align="center">{row.col5}</StyledTableCell>
        {
          open
            ? (
              <>
                <StyledTableCell align="center">{row.col4}</StyledTableCell>
                <StyledTableCell align="center">{row.col5}</StyledTableCell>
              </>
            )
            : (
              <>
                <StyledTableCell align="center" style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                  {rowTotal.length > 0 ? toKG(rowTotal) : 0}
                </StyledTableCell>

                <StyledTableCell align="center" style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                  {rowValues.length > 0 ? toBRL(rowValues) : 0}
                </StyledTableCell>
              </>
            )
        }
      </TableRow>

      <TableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span>KG</span>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span>VR$</span>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {row.col7.map((item, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell component="th" scope="row">{item.conta}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">{item.desc}</StyledTableCell>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}></StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="right" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    {item.prev.toLocaleString('pt-br')}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="right" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      {item.result.toLocaleString('pt-br')}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                  <StyledTableRow key={0}>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>TOTAL</StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="right" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    {rowTotal.length > 0 ? toKG(rowTotal) : 0}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="right" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    {rowValues.length > 0 ? toBRL(rowValues) : 0}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  )
  return fragment
}
const TableIPBody: React.FC<Props> = (props) => {
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const papeis = ['Testliner', 'Kraft', 'Miolo', 'Branco']

  const insumosTotalKgReq = useQuery(['insumos-total-kg', mes, ano, papeis],
    async () => {
      return getInsumosPapelTotalKg(mes, ano, papeis)
    },
    {
      onSuccess: (data) => {
        console.log('getInsumosPapelTotalKg()', data)
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 5
    }
  )

  const insumosValorTotalReq = useQuery(['insumos-valor-total', mes, ano, papeis],
  async () => {
    return getInsumosPapelValorTotal(mes, ano, papeis)
  },
  {
    onSuccess: (data) => {
      console.log('getInsumosPapelValorTotal()', data)
    },
    onError: (error) => {
      console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
    },
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 5
  }
)

  const insumosReq = useQuery(['insumos', mes, ano, papeis],
    async () => {
      return getInsumosPapel(mes, ano, papeis)
    },
    {
      onSuccess: (data) => {
        console.log('getInsumosPapel()', data)
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 5
    }
  )

  let result: any
  if (insumosReq.isLoading || insumosReq.isFetching) {
    result = (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (insumosReq.isError) {
    result = (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }

  if (insumosReq.isSuccess && !insumosReq.isError && !insumosReq.isFetching) {
    const titles = Object.keys(insumosReq.data)

    const rows = titles.map(title => {
        if (Array.isArray(insumosReq.data[title])) {
            return createDataObra(title, '', '', '', '', '', insumosReq.data[title])
        } else {
            console.error(`Expected an array for insumosReq.data[${title}], but got: `, insumosReq.data[title])
            return createDataObra(title, '', '', '', '', '', [])
        }
    })

    const rowsTotal = titles.map(title => {
      return insumosTotalKgReq.data[title].total
    })

    const rowsValues = titles.map(title => {
      return insumosValorTotalReq.data[title].value_total
    })

    const sumRowsTotal = rowsTotal.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0)
    const sumRowsValues = rowsValues.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0)

    result = (
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: '100%' }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ fontWeight: 'bold', fontSize: 'larger', wordBreak: 'break-word' }}>
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell align="center" >KG</StyledTableCell >
                <StyledTableCell align="center" >VR$</StyledTableCell >
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                (<Row key={row.col1} row={row} rowTotal={rowsTotal[index]} rowValues={rowsValues[index]} />)
              ))}
              <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ fontWeight: 'bold', fontSize: 'larger', wordBreak: 'break-word' }}>
                <StyledTableCell style={{ wordBreak: 'break-word' }}></StyledTableCell >
                <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                TOTAL DESPESAS
                </StyledTableCell >
                <StyledTableCell align="right" style={{ wordBreak: 'break-word' }}></StyledTableCell >
                <StyledTableCell align="right"></StyledTableCell >
                <StyledTableCell align="right" style={{ wordBreak: 'break-word' }}></StyledTableCell >
                <StyledTableCell align="right"></StyledTableCell >
                <StyledTableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>{toKG(sumRowsTotal)}</StyledTableCell>
                <StyledTableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', wordBreak: 'break-word' }}>{toBRL(sumRowsValues)}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }

  return result
}

export default TableIPBody
