/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import alertSuccess from 'components/Alerts/ModalSucess'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import ImportantView from 'components/view/ImportantView'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import { StyledDropZone } from 'react-drop-zone'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postSample } from 'services/ft/FTService'
import { FTSampleType } from 'types/ft/FTSampleType'
import { validationSampleFT } from 'types/validation/ValidationSampleFT'

interface Props {
  confirm: () => void
  close: () => void
  ftId: string
}

function FisicSampleForm(props: Props) {
  const {
    errors,
    touched,
    values,
    handleSubmit,
    setFieldValue
  } = useFormik({
    onSubmit: (inputValues: FTSampleType) => {
      console.log('valores da amostra', inputValues)
      const sampleForm: FTSampleType = {
        draw: false,
        comment: inputValues.comment,
        url: inputValues.url,
        status: inputValues.status,
        ft: props.ftId
      }
      mutateCreate(sampleForm)
    },
    enableReinitialize: true,
    validationSchema: validationSampleFT,
    initialValues: {
      draw: false,
      comment: '-',
      url: '-',
      status: 'Aguardando amostra',
      ft: props.ftId
    }
  })

  const { mutate: mutateCreate } = useMutation(
    postSample,
    {
      onSuccess: (data) => {
        console.log('amostra criada', data)
        alertSuccess(
          'Amostra de desenho enviado com sucesso!',
          'O Cliente poderá analisar a amostra.',
          () => { props.confirm() })
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message ?? 'Não foi possível criar a amostra')
      }
    }
  )

  const getBase64 = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const base64 = reader.result
      setFieldValue('url', base64)
      console.log('base64: ', base64)
    }
    reader.onerror = function (error) {
      console.log('Error file read: ', error)
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip title='Voltar' placement='top' onClick={() => { props.close() }}>
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>Nova amostra física</div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginRight: '30px',
            marginTop: '20px'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 600 }}>
                  <FormControl variant="standard" sx={{ m: 1, marginRight: 2, minWidth: 120, flexGrow: 1 }}>
                    <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={values.status}
                      onChange={(e) => { setFieldValue('status', e.target.value) }}
                    >
                      <MenuItem value={'Aguardando amostra'}>Aguardando amostra</MenuItem>
                      <MenuItem value={'Aprovado'}>Aprovado</MenuItem>
                      <MenuItem value={'Reprovado'}>Reprovado</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <TextField
                  style={{ marginTop: '20px', width: '100%' }}
                  id="outlined-multiline-static"
                  label="Observações"
                  multiline
                  rows={3}
                  onChange={(e) => { setFieldValue('comment', e.target.value) }}
                  error={Boolean(errors.comment && touched.comment)}
                  helperText={(errors.comment && touched.comment) ? errors.comment : null}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type='button'
              mold='red'
              onClick={() => { props.close() }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold='primary'
              type="submit"
            >
              SALVAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default FisicSampleForm
