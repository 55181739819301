enum StatusRequestForProposalsType {
  IN_ANALYSIS = 'EM ANÁLISE',
  APPROVED_SELLER = 'APROVADA PELO VENDEDOR',
  APPROVED_CUSTOMER = 'APROVADA PELO CLIENTE',
  APPROVED_BOARD = 'APROVADA PELA CONTROLADORIA',
  FAILED_SELLER = 'REPROVADA PELO VENDEDOR',
  FAILED_CUSTOMER = 'REPROVADA PELO CLIENTE',
  FAILED_BOARD = 'REPROVADA PELA CONTROLADORIA',
  PENDING_BOARD = 'PENDENTE PELA CONTROLADORIA',
}

enum NavigationType {
  PLATE = 0,
  CASHIER = 1,
}

interface ProposalType {
  _id: string
  budget: string
  user: string
  status: string
  gramature: number
  internalMeasures: string
  observation: string
  price: number
  shipping: number
  minimumLot: number
  paymentCondition: string
  financingRate: number
  icms: number
  validity: number
  deadlineDeliverDays: number
  isActive: boolean
  isDirectorship: boolean
  createdAt: string
  updatedAt: string
  statusHistory?: Array<{
    date: string
    status: string
  }>
}

interface RequestBudgetType {
  _id: string
  ft: string
  status: string
  areaLiquida: number
  pesoLiquido: number
  areaBruta: number
  pesoBruto: number
  fechamento: number
  resina: number
  limpeza: number
  gramatura: number
  tipoProduto: string
  tabela: number
  anual: number
  semestral: number
  trimestral: number
  bimestral: number
  mensal: number
  pesoKg: number
  preco: number
  cidadeDestino: string
  valorFrete: number
  valorFreteCalculado: number
  custoCliche: number
  custoFaca: number
  custoClicheDiluido: number
  custoFacaDiluido: number
  precoFerramental: number
  fechamentoColagemManual: number
  fechamentoGrampoCola: number
  valorAgregado: number
  precoFechamento: number
  loteMinimo: number
  precoAVista: number
  condicoesPagamento: number
  taxaFinanciamento: number
  valorTaxa: number
  precoKgFerramental: number
  precoKgSemFerramental: number
  precoSugerido: number
  precoPorKg: number
  precoNegociado: number
  peloValorNF: number
  KG: number
  NF: number
  isActive: boolean
  createdAt: string
  updatedAt: string
  _proposta: ProposalType[]
  propostas: ProposalType[]
}

interface ClientType {
  _id: string
  type: 'PJ' | 'PF'
  name: string
  pj: {
    cnpj: string
  }
  pf: {
    cpf: string
  }
}

interface SellerType {
  user: Array<{ name: string }>
}

interface PreDatasheetType {
  cliente: ClientType[]
  vendedor: SellerType[]
}

interface RequestForProposalsType {
  _id: string
  status: string
  preFT: string
  itemReference: string
  existsBudget: boolean
  existsProposal: boolean
  existsAmostra: boolean
  approvedProposal: boolean
  approvedAmostra: boolean
  isActive: boolean
  deliveryMap: string
  createdAt: string
  updatedAt: string
  __v: number
  preft: PreDatasheetType[]
  orcamento: RequestBudgetType[]
}

const getStatusValue = (): string[] => {
  return Object.values(StatusRequestForProposalsType).map(String)
}

export { NavigationType, getStatusValue, StatusRequestForProposalsType }

export type { ProposalType, RequestBudgetType }

export default RequestForProposalsType
