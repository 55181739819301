import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import React from 'react'
import { BreadcrumbsType } from 'types/breadcrumbs/BreadcrumbsType'
import { Container } from './components/Container'
import { GoBack } from './components/GoBack'
import { SalesReportContainer } from './container/SalesReportContainer'

const breadcrumbs: BreadcrumbsType[] = [
  { text: 'Relatórios', url: '/home/report' },
  { text: 'Relatório de Cliente' }
]

const SalesReport: React.FC = () => {
  return (
    <Container>
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />

      <SalesReportContainer />

      <GoBack forRoute="/home/report" />
    </Container>
  )
}

export { SalesReport }
