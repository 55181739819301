import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, CircularProgress, Icon } from '@mui/material'
import { ACUMULADO } from 'constants/constReportBillid'
import { DataValues, IdentifierClient, IdentifierInformative } from 'types/Report/Report'
import { getReportBilling, getReportOrderAccumulated } from 'services/report/ReportBilling'
import moment from 'moment'
import { useQuery } from 'react-query'
import { formatValue, toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
  multipleDate?: any
}

const TableSalesOrder: React.FC<Props> = (props) => {
  // const [filters, setFilters] = useState<any>({})
  const [valorLocal, setValorLocal] = useState<any>([])
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const date = moment(props.date).format('DD/MM/YYYY')

  const [valorRow] = useState<any[]>([
    {
      text: 'Acumulado',
      value: ACUMULADO
    },
    {
      text: 'Diferença',
      value: 3
    }
  ])

  const queryMultiple = () => {
    // requisição externa do LN
    const req1 = useQuery(['sales', date, props.multipleDate],
      async () => {
        return getReportOrderAccumulated(date, props.multipleDate)
      },
      {
        onSuccess: (data) => {
          getDataReport({ response: data })
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },

        refetchOnWindowFocus: false
      }
    )

    // requisição banco local
    const req2 = useQuery(['sales-report', mes, ano],
      async () => {
        return getReportBilling(mes, ano)
      },
      {
        onSuccess: (data) => {
          const valueDifference = [
            {
              tipo: '3',
              dados: [
                {
                  type: TipoProduto.CAIXA,
                  kg: data.caixa.dados.amountKg,
                  rs: data.caixa.dados.amountPrice,
                  rskg: 0
                }
              ]
            },
            {
              tipo: '3',
              dados: [
                {
                  type: TipoProduto.CHAPA,
                  kg: data.chapa.dados.amountKg,
                  rs: data.chapa.dados.amountPrice,
                  rskg: 0
                }
              ]
            },
            {
              tipo: '3',
              dados: [
                {
                  type: TipoProduto.PAPEL,
                  kg: data.bobina.dados.amountKg,
                  rs: data.bobina.dados.amountPrice,
                  rskg: 0
                }
              ]
            }
          ]
          setValorLocal(valueDifference)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    return [req1, req2]
  }

  const [req1, req2] = queryMultiple()

  const [dataValues, setDataValues] = useState<DataValues[]>([])

  const searchDataResponse = (type, typeClient, dataa) => {
    const dataResponse = dataa.response.concat(valorLocal)

    const search = dataResponse.find((response) => (
      response.tipo === type &&
      response.dados.some((responseItem) => responseItem.type === typeClient)
    ))
    return search || {}
  }

  const getDataReport = (dataa) => {
    const initialCustomers = makeClientInitial()

    initialCustomers.forEach((data) => {
      const typeClient = data.identifierClient

      const { dados: dataAccumulated } = searchDataResponse(data.acumulado.identifier, typeClient, dataa)

      let rsAcumulado = 0
      let kgAcumulado = 0
      if (dataAccumulated) {
        const { rs, kg, rskg } = dataAccumulated[0]
        rsAcumulado = rs
        kgAcumulado = kg
        data.acumulado = { ...data.acumulado, rs, kg, rskg }
      }

      const { dados: dataDiferenca } = searchDataResponse(data.diferenca.identifier, typeClient, dataa)
      if (dataDiferenca) {
        const { rs, kg, rskg } = dataDiferenca[0]
        data.diferenca = {
          ...data.diferenca,
          rs: rsAcumulado - rs,
          kg: kgAcumulado - kg,
          rskg
        }
      }
    })

    const getTotal = (index: 'acumulado' | 'diferenca') => initialCustomers.reduce((acum, item) => {
      acum.rs += item[index].rs
      acum.kg += item[index].kg
      acum.rskg += 0
      return acum
    }, { rs: 0, kg: 0, rskg: 0 })

    const accumulatedTotal = getTotal('acumulado')
    accumulatedTotal.rskg = accumulatedTotal.rs / accumulatedTotal.kg
    const totalDiference = getTotal('diferenca')

    const columnTotal = initialCustomers[initialCustomers.length - 1]
    columnTotal.acumulado = {
      ...columnTotal.acumulado,
      ...accumulatedTotal
    }

    columnTotal.diferenca = {
      ...columnTotal.diferenca,
      ...totalDiference
    }

    setDataValues(initialCustomers)
  }

  const makeClientInitial = () => {
    const customers = [
      {
        text: 'Caixas',
        type: IdentifierClient.CAIXA
      },
      {
        text: 'Chapas',
        type: IdentifierClient.CHAPA
      },
      {
        text: 'Papel',
        type: IdentifierClient.PAPEL
      },
      {
        text: 'Total',
        type: IdentifierClient.TOTAL
      }
    ]

    const updateData: DataValues[] = []
    customers.forEach((client) => {
      const informative = {
        kg: 0,
        rs: 0,
        rskg: 0
      }

      updateData.push({
        client: client.text,
        identifierClient: client.type,
        acumulado: {
          ...informative,
          identifier: IdentifierInformative.ACUMULADO
        },
        diferenca: {
          ...informative,
          identifier: IdentifierInformative.DIFFERENCE
        },
        faturamento: {
          ...informative,
          identifier: IdentifierInformative.FATURAMENTO_DIA
        },
        devolucao: {
          ...informative,
          identifier: IdentifierInformative.DEVOLUCAO
        }
      })
    })

    return updateData
  }

  return (
    <>
      {(req1.isLoading || req1.isFetching) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <CircularProgress />
        </Box>
      )}
      {req1.isError && req2.isError && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
        </Box>
      )}

      {(req1.isSuccess && !req1.isError && !req1.isFetching) && (
        <>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">

                    </TableCell>
                    {valorRow.map((item) => {
                      return (
                        <TableCell align="center" colSpan={3}>
                          {item.text}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell key='cliente_carne' style={{ width: '20%' }} sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 3,
                      background: '#FFF'
                    }}>
                      Produto
                      {/* <TextField
                        id="standard-basic"
                        label="Pesquisar Produto"
                        variant="standard"
                        style={{ width: '100%' }}
                        onChange={(e) => setFilters({ ...filters, cliente: e.target.value })} /> */}
                    </TableCell>
                    {valorRow.map((item, index) => (
                      <>
                        <TableCell key={item.text} align={'center'}>KG</TableCell>
                        <TableCell key={item.text} align={'center'}>R$</TableCell>
                        <TableCell
                          key={item.text}
                          align={'center'}
                          style={{ display: (item.value === 3 && index === 1) ? 'none' : 'table-cell' }}
                        >
                          R$/KG
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataValues.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.client}>
                        <TableCell key='nome_cliente' sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 2,
                          background: '#FFF'
                        }}>
                          {row.client}
                        </TableCell>
                        <TableCell align={'center'}>{formatValue(row.acumulado.kg ?? 0)}</TableCell>
                        <TableCell align={'center'}>{toBRL(row.acumulado.rs ?? 0, { noBRL: true })}</TableCell>
                        <TableCell align={'center'}>{toBRL(row.acumulado.rskg ?? 0, { noBRL: true })}</TableCell>
                        <TableCell align={'center'}>{formatValue(row.diferenca.kg ?? 0)}</TableCell>
                        <TableCell align={'center'}>{toBRL(row.diferenca.rs ?? 0, { noBRL: true })}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  )
}

export default TableSalesOrder
