import React from 'react'
// import HeaderFilter from './components/HeaderFilter'
import TableOrdersProductions from './components/TableOrdersProductions'

function OrdersProduction() {
  return (
    <div>
      {/* <HeaderFilter /> */}
      <TableOrdersProductions />
    </div>
  )
}

export default OrdersProduction
