import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import CustomButton from 'components/buttons/CustomButton'
import { httpClient } from 'config/httpClient'
import { onlyNumbers } from 'helpers/stringHelper'

interface modalView {
  open: boolean
  close: () => void
}

const FormPaperType = (props: modalView) => {
  const { open, close } = props

  const [loading, setLoading] = useState(false)

  const closeModal = () => close()

  // Se alguma das validações não passar vai retornar true
  const validationMinMaxHasErrors = (values: any) => {
    let isError = false

    if (values.ur.min && values.ur.max && values.ur.min > values.ur.max) {
      setFieldError(
        'ur.min',
        'O valor mínimo não pode ser maior que o valor máximo.'
      )
      isError = true
    }
    if (
      values.grammage.min &&
      values.grammage.max &&
      values.grammage.min > values.grammage.max
    ) {
      setFieldError(
        'grammage.min',
        'O valor mínimo não pode ser maior que o valor máximo.'
      )
      isError = true
    }
    if (
      values.cobb.min &&
      values.cobb.max &&
      values.cobb.min > values.cobb.max
    ) {
      setFieldError(
        'cobb.min',
        'O valor mínimo não pode ser maior que o valor máximo.'
      )
      isError = true
    }

    return isError
  }

  const {
    errors,
    touched,
    values,
    setFieldError,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      description: '',
      grammage: {
        value: '',
        min: '',
        max: ''
      },
      ur: {
        min: '',
        max: ''
      },
      cobb: {
        min: '',
        max: ''
      },
      withoutsized: {
        checked: true,
        rct: '',
        cmt: '',
        mullen: ''
      },
      withsized: {
        checked: false,
        rct: {
          kgf: { min: '' },
          ibf: { min: '' },
          kn: { min: '' }
        },
        cmt: {
          kgf: { min: '' },
          ibf: { min: '' },
          n: { min: '' }
        },
        mullen: {
          kgf: { min: '' },
          kpa: { min: '' }
        }
      }
    },
    // validationSchema: {},
    onSubmit(values) {
      if (validationMinMaxHasErrors(values)) {
        return
      }
      if (!values.name.trim()?.length) {
        setFieldError('name', 'O nome é obrigatório.')
        return
      }

      setLoading(true)

      const body = {
        ...values,
        withsized: values?.withsized?.checked
          ? values.withsized
          : {
              checked: false,
              rct: {
                kgf: null,
                ibf: null,
                kn: null
              },
              cmt: {
                kgf: null,
                ibf: null,
                n: null
              },
              mullen: {
                kgf: null,
                kpa: null
              }
            },
        withoutsized: !values?.withsized?.checked
          ? values.withoutsized
          : {
              checked: false,
              rct: null,
              cmt: null,
              mullen: null
            }
      }

      console.log('values: ', values, body)

      httpClient
        .post('products/papers', body)
        .then(() => {
          toast.success('Papel adicionado com sucesso')

          closeModal()
        })
        .catch((error) => {
          console.log('Error: ', error)
          toast.error(
            `Erro ao adicionar o papel. ${error?.response?.data?.message}`
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }
  })

  const handleClose = () => {
    closeModal()
    resetForm()
  }

  const handleChangeIntegerNumber = (field: string) => (event: any) => {
    const newValue = onlyNumbers(event.target.value)
    setFieldValue(field, newValue ? Math.round(Number(newValue)) : '')
  }

  const handleChangeNumber = (field: string) => (event: any) => {
    setFieldValue(field, event.target.value ? Number(event.target.value) : '')
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      fullWidth
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{ minWidth: 'auto', padding: 1, marginRight: 2 }}
            >
              <ArrowBack />
            </Button>

            <span>Novo papel</span>
          </Box>

          <FormControlLabel
            control={
              <Switch
                value={values.withsized?.checked}
                // onChange={(_, value) => setNoSized(value)}
                onChange={handleChange('withsized.checked')}
              />
            }
            label={values.withsized?.checked ? 'Com sized' : 'Sem sized'}
          />
        </DialogTitle>
        <DialogContent>
          <Typography fontWeight="bold">Especificações para papel</Typography>

          <Box marginTop={3}>
            {/* SEM SIZE */}
            {!values.withsized?.checked && (
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(errors.name && touched.name)}
                    value={values.name}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Papel"
                    helperText={
                      errors.name && touched.name ? errors.name : null
                    }
                    onChange={handleChange('name')}
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    error={Boolean(errors.description && touched.description)}
                    value={values.description}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Descrição"
                    helperText={
                      errors.description && touched.description
                        ? errors.description
                        : null
                    }
                    onChange={handleChange('description')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Variação gramatura base
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.grammage?.min && touched.grammage?.min
                        )}
                        value={values.grammage?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(g/m2) Mínimo"
                        type="number"
                        helperText={
                          errors.grammage?.min && touched.grammage?.min
                            ? errors.grammage?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber('grammage.min')}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.grammage?.max && touched.grammage?.max
                        )}
                        value={values.grammage?.max}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(g/m2) Máximo"
                        type="number"
                        helperText={
                          errors.grammage?.max && touched.grammage?.max
                            ? errors.grammage?.max
                            : null
                        }
                        onChange={handleChangeIntegerNumber('grammage.max')}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Esmagamento do anel (RCT)
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          errors.withoutsized?.rct && touched.withoutsized?.rct
                        )}
                        value={values.withoutsized?.rct}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Kgf"
                        type="number"
                        helperText={
                          errors.withoutsized?.rct && touched.withoutsized?.rct
                            ? errors.withoutsized?.rct
                            : null
                        }
                        onChange={handleChangeIntegerNumber('withoutsized.rct')}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Concora Medium Test (CMT)
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          errors.withoutsized?.cmt && touched.withoutsized?.cmt
                        )}
                        value={values.withoutsized?.cmt}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Kgf"
                        type="number"
                        helperText={
                          errors.withoutsized?.cmt && touched.withoutsized?.cmt
                            ? errors.withoutsized?.cmt
                            : null
                        }
                        onChange={handleChangeIntegerNumber('withoutsized.cmt')}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Resistência ao Estouro (Muller)
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          errors.withoutsized?.mullen &&
                            touched.withoutsized?.mullen
                        )}
                        value={values.withoutsized?.mullen}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="Kgf/cm²"
                        type="number"
                        helperText={
                          errors.withoutsized?.mullen &&
                          touched.withoutsized?.mullen
                            ? errors.withoutsized?.mullen
                            : null
                        }
                        onChange={handleChangeNumber(
                          'withoutsized.mullen'
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Absorção de água (Cobb Test)
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.cobb?.min && touched.cobb?.min)}
                        value={values.cobb?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(gH²O/m²) Mínimo"
                        type="number"
                        helperText={
                          errors.cobb?.min && touched.cobb?.min
                            ? errors.cobb?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber('cobb.min')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.cobb?.max && touched.cobb?.max)}
                        value={values.cobb?.max}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(gH²O/m²) Máximo"
                        type="number"
                        helperText={
                          errors.cobb?.max && touched.cobb?.max
                            ? errors.cobb?.max
                            : null
                        }
                        onChange={handleChangeIntegerNumber('cobb.max')}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">Umidade</Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.ur?.min && touched.ur?.min)}
                        value={values.ur?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(%) Mínimo"
                        type="number"
                        helperText={
                          errors.ur?.min && touched.ur?.min
                            ? errors.ur?.min
                            : null
                        }
                        onChange={handleChangeNumber('ur.min')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.ur?.max && touched.ur?.max)}
                        value={values.ur?.max}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(%) Máximo"
                        type="number"
                        helperText={
                          errors.ur?.max && touched.ur?.max
                            ? errors.ur?.max
                            : null
                        }
                        onChange={handleChangeNumber('ur.max')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* COM SIZE */}
            {values.withsized?.checked && (
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(errors.name && touched.name)}
                    value={values.name}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Papel"
                    helperText={
                      errors.name && touched.name ? errors.name : null
                    }
                    onChange={handleChange('name')}
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      errors.grammage?.value && touched.grammage?.value
                    )}
                    value={values.grammage?.value}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Gramatura Base (g/m2)"
                    type="number"
                    helperText={
                      errors.grammage?.value && touched.grammage?.value
                        ? errors.grammage?.value
                        : null
                    }
                    onChange={handleChangeIntegerNumber('grammage.value')}
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Gramatura Parâmetros
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.grammage?.min && touched.grammage?.min
                        )}
                        value={values.grammage?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(g/m2) Mínimo"
                        type="number"
                        helperText={
                          errors.grammage?.min && touched.grammage?.min
                            ? errors.grammage?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber('grammage.min')}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.grammage?.max && touched.grammage?.max
                        )}
                        value={values.grammage?.max}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(g/m2) Máximo"
                        type="number"
                        helperText={
                          errors.grammage?.max && touched.grammage?.max
                            ? errors.grammage?.max
                            : null
                        }
                        onChange={handleChangeIntegerNumber('grammage.max')}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Resistência ao Estouro (Muller)
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.withsized?.mullen?.kgf?.min &&
                            touched.withsized?.mullen?.kgf?.min
                        )}
                        value={values.withsized?.mullen?.kgf?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(Kgf/cm²) Mínimo"
                        type="number"
                        helperText={
                          errors.withsized?.mullen?.kgf?.min &&
                          touched.withsized?.mullen?.kgf?.min
                            ? errors.withsized?.mullen?.kgf?.min
                            : null
                        }
                        onChange={handleChangeNumber(
                          'withsized.mullen.kgf.min'
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(
                          errors.withsized?.mullen?.kpa?.min &&
                            touched.withsized?.mullen?.kpa?.min
                        )}
                        value={values.withsized?.mullen?.kpa?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(Kpa) Mínimo"
                        type="number"
                        helperText={
                          errors.withsized?.mullen?.kpa?.min &&
                          touched.withsized?.mullen?.kpa?.min
                            ? errors.withsized?.mullen?.kpa?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber(
                          'withsized.mullen.kpa.min'
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Esmagamento do anel (RCT)
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.withsized?.rct?.kgf?.min &&
                            touched.withsized?.rct?.kgf?.min
                        )}
                        value={values.withsized?.rct?.kgf?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(Kgf/cm) Mín"
                        type="number"
                        helperText={
                          errors.withsized?.rct?.kgf?.min &&
                          touched.withsized?.rct?.kgf?.min
                            ? errors.withsized?.rct?.kgf?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber(
                          'withsized.rct.kgf.min'
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.withsized?.rct?.ibf?.min &&
                            touched.withsized?.rct?.ibf?.min
                        )}
                        value={values.withsized?.rct?.ibf?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(Lbf/m) Mín"
                        type="number"
                        helperText={
                          errors.withsized?.rct?.ibf?.min &&
                          touched.withsized?.rct?.ibf?.min
                            ? errors.withsized?.rct?.ibf?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber(
                          'withsized.rct.ibf.min'
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.withsized?.rct?.kn?.min &&
                            touched.withsized?.rct?.kn?.min
                        )}
                        value={values.withsized?.rct?.kn?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(Kn/m) Mín"
                        type="number"
                        helperText={
                          errors.withsized?.rct?.kn?.min &&
                          touched.withsized?.rct?.kn?.min
                            ? errors.withsized?.rct?.kn?.min
                            : null
                        }
                        onChange={handleChangeNumber('withsized.rct.kn.min')}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Concora Medium Test (CMT)
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.withsized?.cmt?.kgf?.min &&
                            touched.withsized?.cmt?.kgf?.min
                        )}
                        value={values.withsized?.cmt?.kgf?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(Kgf) Mín"
                        type="number"
                        helperText={
                          errors.withsized?.cmt?.kgf?.min &&
                          touched.withsized?.cmt?.kgf?.min
                            ? errors.withsized?.cmt?.kgf?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber(
                          'withsized.cmt.kgf.min'
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.withsized?.cmt?.ibf?.min &&
                            touched.withsized?.cmt?.ibf?.min
                        )}
                        value={values.withsized?.cmt?.ibf?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(Lbf) Mín"
                        type="number"
                        helperText={
                          errors.withsized?.cmt?.ibf?.min &&
                          touched.withsized?.cmt?.ibf?.min
                            ? errors.withsized?.cmt?.ibf?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber(
                          'withsized.cmt.ibf.min'
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        error={Boolean(
                          errors.withsized?.cmt?.n?.min &&
                            touched.withsized?.cmt?.n?.min
                        )}
                        value={values.withsized?.cmt?.n?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(N) Mín"
                        type="number"
                        helperText={
                          errors.withsized?.cmt?.n?.min &&
                          touched.withsized?.cmt?.n?.min
                            ? errors.withsized?.cmt?.n?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber(
                          'withsized.cmt.n.min'
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    Absorção de água (Cobb Test)
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.cobb?.min && touched.cobb?.min)}
                        value={values.cobb?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(gH²O/m²) Mínimo"
                        type="number"
                        helperText={
                          errors.cobb?.min && touched.cobb?.min
                            ? errors.cobb?.min
                            : null
                        }
                        onChange={handleChangeIntegerNumber('cobb.min')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.cobb?.max && touched.cobb?.max)}
                        value={values.cobb?.max}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(gH²O/m²) Máximo"
                        type="number"
                        helperText={
                          errors.cobb?.max && touched.cobb?.max
                            ? errors.cobb?.max
                            : null
                        }
                        onChange={handleChangeIntegerNumber('cobb.max')}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography fontWeight="bold">Umidade</Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.ur?.min && touched.ur?.min)}
                        value={values.ur?.min}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(%) Mínimo"
                        type="number"
                        helperText={
                          errors.ur?.min && touched.ur?.min
                            ? errors.ur?.min
                            : null
                        }
                        onChange={handleChangeNumber('ur.min')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(errors.ur?.max && touched.ur?.max)}
                        value={values.ur?.max}
                        variant="standard"
                        fullWidth
                        autoComplete="off"
                        label="(%) Máximo"
                        type="number"
                        helperText={
                          errors.ur?.max && touched.ur?.max
                            ? errors.ur?.max
                            : null
                        }
                        onChange={handleChangeNumber('ur.max')}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={Boolean(errors.description && touched.description)}
                    value={values.description}
                    variant="standard"
                    fullWidth
                    multiline
                    minRows={3}
                    autoComplete="off"
                    label="Descrição"
                    helperText={
                      errors.description && touched.description
                        ? errors.description
                        : null
                    }
                    onChange={handleChange('description')}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Grid item md={12} className="text-right">
            <CustomButton
              mold="red"
              type="button"
              onClick={handleClose}
              style={{ marginRight: '10px' }}
              disabled={loading}
            >
              Cancelar
            </CustomButton>
            <CustomButton
              mold="primary"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              Salvar
            </CustomButton>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default FormPaperType
