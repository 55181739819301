/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material'
import {
  StyledTableCell,
  StyledTableRow
} from 'pages/company/portfolio/components/table.styles'
import { Link, useNavigate } from 'react-router-dom'
import {
  getIe,
  getItemRecurringLN,
  getItemTrimpaper
} from 'services/customerControl/CustomerControlServices'
import moment from 'moment'
import { httpClient } from 'config/httpClient'
import { toast } from 'react-toastify'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

interface Props {
  search?: string
  companyId: string
  userId: string
}

const ItemsRecurring: React.FC<Props> = ({
  search = '',
  companyId,
  userId
}) => {
  const [page, setPage] = useState(0)
  const navigate = useNavigate()

  const [originalItems, setOriginalItems] = useState<any[]>([])
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [limit, setLimit] = useState(10)

  const [selectedProductType, setSelectProductType] = useState<
    'BOBINA' | 'CHAPA' | 'CAIXA'
  >('CAIXA')

  const loadItems = useCallback(async (ies) => {
    setItems([])
    setLoading(true)

    let itemsRecurring: any[] = []
    for (const ie of ies) {
      console.log(ie?.codeLn)
      try {
        const { data } = await getItemRecurringLN(ie?.codeLn)

        if (data?.result?.length) {
          itemsRecurring = [...itemsRecurring, ...data?.result]
        }
      } catch (error) {
        // console.log(error.status)
      }
      setItems(itemsRecurring)
    }
    setLoading(false)
  }, [])

  const loadIes = useCallback(() => {
    getIe(companyId)
      .then((response) => {
        // setIes(response?.data ?? [])
        loadItems(response?.data ?? [])
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
      })
  }, [companyId])

  useEffect(() => {
    void loadIes()
  }, [companyId])

  const handleSelectItem = (item, checked) => {
    setItems((state) =>
      state.map((ft) =>
        ft?.codItem === item?.codItem &&
        ft?.preco === item?.preco &&
        ft?.tipo === item?.tipo
          ? {
              ...ft,
              checked
            }
          : ft
      )
    )
  }

  const handleCreateRequest = async () => {
    const selectedFTs = items
      ?.filter(
        (item) =>
          item?.checked && item?.tipo?.toUpperCase() === selectedProductType
      )
      ?.map((item) => ({
        clientID: item.clientID,
        codItem: item.codItem,
        preco: item.preco
      }))

    if (!selectedFTs?.length) {
      alert(
        'Nenhum item selecionado. Para fazer um pedido deve selecionar um ou mais.'
      )
      return
    }

    setLoadingRequest(true)

    toast.promise(
      httpClient.post(
        `/pedidos/recorrente/by-company-and-collaborator/${companyId}?userId=${userId}`,
        {
          items: selectedFTs,
          type: selectedProductType ?? null
        }
      ),
      {
        pending: 'Aguarde, criando pedido...',
        success: {
          render() {
            setItems((state) =>
              state?.map((item) => ({ ...item, checked: false }))
            )
            setLoadingRequest(false)
            navigate('/home/purchaseRequests')
            return 'Pedido realizado com sucesso'
          }
        },
        error: {
          render({ data }: any) {
            setLoadingRequest(false)
            return `Erro ao criar pedido. ${data}`
          }
        }
      }
    )
  }

  const handleChange = (event: any) => {
    setSelectProductType(event.target.value as 'BOBINA' | 'CHAPA' | 'CAIXA')
  }

  return (
    <>
      <Stack sx={{ marginTop: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="product-type-select-label">Tipo</InputLabel>
          <Select
            labelId="product-type-select-label"
            id="product-type-select"
            value={selectedProductType}
            label="Age"
            onChange={handleChange}
          >
            {/* <MenuItem value={'BOBINA'}>Bobina</MenuItem> */}
            <MenuItem value={'CHAPA'}>Chapa</MenuItem>
            <MenuItem value={'CAIXA'}>Caixa</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell> </StyledTableCell>
              <StyledTableCell>Tipo</StyledTableCell>
              <StyledTableCell>Código do Item</StyledTableCell>
              <StyledTableCell>Preço</StyledTableCell>
              <StyledTableCell>Bloqueado</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <StyledTableRow>
                <StyledTableCell colSpan={5} align="center">
                  <CircularProgress size={24} />
                </StyledTableCell>
              </StyledTableRow>
            )}

            {!loading &&
              items
                .filter(
                  (item) => item?.tipo?.toUpperCase() === selectedProductType
                )
                ?.filter(
                  (item) =>
                    item?.codItem?.includes(search) ||
                    item?.tipo?.toLowerCase()?.includes(search.toLowerCase())
                )
                ?.filter((item) => !item?.bloqueado)
                ?.slice(page * limit, page * limit + limit)
                .map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Checkbox
                          {...label}
                          checked={!!row?.checked}
                          disabled={row?.bloqueado}
                          onChange={(_, checked) =>
                            handleSelectItem(row, checked)
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.tipo?.toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.codItem}
                      </StyledTableCell>
                      <StyledTableCell>
                        R${' '}
                        {Number(row?.preco)?.toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 5
                        })}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.bloqueado ? 'Sim' : 'Não'}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell colSpan={5}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    items?.filter((item) => item.checked === true)?.length <=
                      0 || loadingRequest
                  }
                  onClick={handleCreateRequest}
                >
                  Fazer pedido
                </Button>
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 30, 50, 100, 1000]}
          rowsPerPage={limit}
          onRowsPerPageChange={(e) => setLimit(Number(e.target.value ?? 10))}
          component="div"
          count={
            items
              .filter(
                (item) => item?.tipo?.toUpperCase() === selectedProductType
              )
              ?.filter(
                (item) =>
                  item?.codItem?.includes(search) ||
                  item?.tipo?.toLowerCase()?.includes(search.toLowerCase())
              )
              ?.filter((item) => !item?.bloqueado).length
          }
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      </TableContainer>

      <Stack direction="row" justifyContent="flex-end" sx={{ paddingTop: 4 }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/home/purchaseRequests"
        >
          Voltar
        </Button>
      </Stack>
    </>
  )
}

export default ItemsRecurring
