import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: 73
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

interface Props {
  loading: boolean
  data: any[]
}

const NoDataTableCustomer: React.FC<Props> = ({ loading, data }) => {
  if (loading || data.length > 0) return <></>

  return (
    <>
      <StyledTableRow>
        <StyledTableCell colSpan={8}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={100}
            width="100%"
          >
            <Typography variant='body2'>Nenhum dado encontrado.</Typography>
          </Box>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default NoDataTableCustomer
