interface FormatValueParams {
  precision?: number
  isPercent?: Boolean
}

/**
 * A function to format the a number.
 *
 * @param {number} num - The number that will be formatted
 * @param {FormatValueParams} params - Optional params, such as number precision
 * @returns {string} A string of the formatted number
 */
export function formatValue(value: number | string, params: FormatValueParams = { precision: 0, isPercent: false }) {
  value = Number(value)
  if (isNaN(value) || !isFinite(value)) {
    return 0
  } else {
    if (params.isPercent) {
      const precision = params.precision ?? 0
      return value.toLocaleString('pt-BR', { maximumFractionDigits: precision }) + '%'
    }
    return value.toLocaleString('pt-BR', { maximumFractionDigits: params.precision })
  }
}

export const toBRL = (value: number | string | undefined, params: any = {}) => {
  value = Number(value)
  const precision = params.precision ?? 2
  const brl = params.noBRL ? '' : 'R$ '
  if ((!value && value !== 0) || !isFinite(value)) {
    value = 0
  }
  return `${brl}${value.toLocaleString('pt-br', { minimumFractionDigits: precision, maximumFractionDigits: precision })}`
}

export const toKG = (value: number | string | undefined, params: any = {}) => {
  value = Number(value)
  const precision = params.precision ?? 4
  if ((!value && value !== 0) || !isFinite(value)) {
    value = 0
  }
  return `${value.toLocaleString('pt-br', { minimumFractionDigits: precision, maximumFractionDigits: precision })}`
}

export const roundUp = (value: number | string, decimalPlaces: number) => {
  value = Number(value)
  const factor = 10 ** decimalPlaces
  return Math.ceil(value * factor) / factor
}
