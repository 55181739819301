import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { TextField } from '@mui/material'
import Button from 'components/form/Button'
import { ptBR } from 'date-fns/locale'
import React from 'react'

function DashboardHeader(props: any) {
  const [date, setDate] = React.useState<Date | null>(new Date())

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
      <div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <DatePicker
            views={['month', 'year']}
            label="Mês e Ano"
            value={date}
            onChange={(newValue) => {
              setDate(newValue)
            }}
            renderInput={(params) => <TextField {...params}
              autoComplete='off' helperText={null} />}
          />
        </LocalizationProvider>
        <Button onClick={() => props.dateSearch(date)} style={{ height: '40px', width: '110px', marginLeft: '20px' }} mold='primary'>Pesquisar</Button>
      </div>
    </div>
  )
}

export default DashboardHeader
