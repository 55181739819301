/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React from 'react';
import {
  DialogContent,
  DialogContentText,
  Grid,
  Stack,
  TextField
} from '@mui/material';
import moment from 'moment';
import {
  ButtonRow,
  DialogStyle
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import ModalHead from './ModalHead';
import { useQuery } from 'react-query';
import { httpClient } from 'config/httpClient';
import { Accordion } from '../components/Accordion';
import Button from 'components/form/Button';

interface Props {
  open: boolean
  close: () => void
  data?: any
}

const Sample: React.FC<Props> = (props) => {
  const closeModal = () => props.close();

  const { data, status } = useQuery(
    ['modal-booking-sample', props?.data?.ft?._id],
    async () =>
      await httpClient.get<any[]>(`/ft/amostras/${props?.data?.ft?._id}`),
    {
      refetchOnWindowFocus: false
    }
  );
  console.log('SAMPLE', data);

  return (
    <>
      <DialogStyle
        open={props.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHead title="Amostra" idPreFT={props?.data?.ft?.preFT?._id} />

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              {status !== 'loading' &&
                data?.data?.map((item) => (
                  <React.Fragment key={item?._id}>
                    {item?.status?.toUpperCase()?.includes('APROVAD') && (
                      <Grid item xs={12} md={8}>
                        <Accordion
                          title="Amostra física"
                          subtitle={
                            item?.status === 'APROVADO'
                              ? `Aprovada em ${moment(item?.createdAt).format(
                                  'DD/MM/YYYY'
                                )}`
                              : `Reprovada em ${moment(item?.createdAt).format(
                                  'DD/MM/YYYY'
                                )}`
                          }
                        >
                          <Stack alignItems="flex-start">
                            <a href={item?.url} target="_blank">
                              <Button type="button" mold="primary">
                                Visualizar
                              </Button>
                            </a>
                            <TextField
                              value={item?.comment}
                              multiline
                              minRows={3}
                              disabled
                              sx={{ marginTop: 2, width: '100%' }}
                            />
                          </Stack>
                        </Accordion>
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
            </Grid>

            <ButtonRow
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                marginTop: 30
              }}
            >
              <Button
                type="button"
                mold="primary"
                onClick={() => {
                  props.close();
                }}
              >
                Voltar
              </Button>
            </ButtonRow>
          </DialogContentText>
        </DialogContent>
      </DialogStyle>
    </>
  );
};

export default Sample;
