/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Button as MuiButton,
  InputAdornment,
  Typography
} from '@mui/material';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import AddIcon from '@mui/icons-material/Add';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getCustomer } from 'services/customerControl/customer-control.services';
import CustomerControlType from 'types/customerControl/CustomerControlType';
import { httpClient } from 'config/httpClient';
import moment from 'moment';
import { ptBR } from 'date-fns/locale';
import ClearIcon from '@mui/icons-material/Clear';
import { onlyNumbers } from 'helpers/stringHelper';
import Button from 'components/form/Button';
import { Tab, TabPanel, TabsList } from './previsionManagementForm.styles';
import AutocompleteClients from './AutocompleteClients';

const FormManagementControl: React.FC = (props: any) => {
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const [date, setDate] = useState<Date | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [previsionForm, setPrevisionForm] = useState<any | null>(null);

  const [dados, setDados] = useState<CustomerControlType[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  // const [sellers, setSellers] = useState<any[]>([])

  const [previsions, setPrevisions] = React.useState<any>({
    coil: [{ formId: Math.random() * (1000 - 1) + 1 }],
    plate: [{ formId: Math.random() * (1000 - 1) + 1 }],
    box: [{ formId: Math.random() * (1000 - 1) + 1 }]
  });

  const [total, setTotal] = useState({
    coil: {
      peso: 0,
      rs: 0,
      rsKg: 0
    },
    plate: {
      peso: 0,
      rs: 0,
      rsKg: 0
    },
    box: {
      peso: 0,
      rs: 0,
      rsKg: 0
    }
  });

  useEffect(() => {
    // Função para calcular os totais
    const calculateTotals = () => {
      const coilTotal = { peso: 0, rs: 0, rsKg: 0 };
      const plateTotal = { peso: 0, rs: 0, rsKg: 0 };
      const boxTotal = { peso: 0, rs: 0, rsKg: 0 };

      // Calcular os totais para coil
      for (const item of previsions.coil) {
        coilTotal.peso += item.kg;
        coilTotal.rs += item.reais;
      }
      coilTotal.rsKg = coilTotal.rs / coilTotal.peso;

      // Calcular os totais para plate
      for (const item of previsions.plate) {
        plateTotal.peso += item.kg;
        plateTotal.rs += item.reais;
      }
      plateTotal.rsKg = plateTotal.rs / plateTotal.peso;

      // Calcular os totais para box
      for (const item of previsions.box) {
        boxTotal.peso += item.kg;
        boxTotal.rs += item.reais;
      }
      boxTotal.rsKg = boxTotal.rs / boxTotal.peso;

      // Atualizar os valores totais
      setTotal({
        coil: coilTotal,
        plate: plateTotal,
        box: boxTotal
      });
    };

    calculateTotals(); // Chamar a função de cálculo inicialmente

    // Atualizar os totais sempre que os valores de previsions mudarem
    // Aqui, consideramos apenas o primeiro nível do objeto previsions
    // Se você precisar de uma verificação mais profunda, pode usar bibliotecas como o lodash
    setPrevisions((prevPrevisions) => {
      calculateTotals(); // Chamar a função de cálculo novamente
      return prevPrevisions;
    });
  }, [previsions]);

  useEffect(() => {
    setLoading(false);
    getCustomer()
      .then((data) => setDados(data.docs))
      .catch(() => toast.error('Erro ao listar clientes'));

    httpClient
      .get('products')
      .then((data) => {
        setProducts(data.data);
      })
      .catch(() => toast.error('Erro ao listar produtos'));

    // httpClient
    //   .get('sellers')
    //   .then((data) => {
    //     console.log('vendedores', data)
    //     setSellers(data.data)
    //   })
    //   .catch(() => toast.error('Erro ao listar vendedores'))
  }, []);

  const handleChangePrevisionCoil = (prevision: any) => {
    setPrevisions((state) => ({
      ...state,
      coil: state?.coil?.map((item) => {
        if (item.formId === prevision.formId) {
          if (prevision?.reais && item?.kg) {
            const valor = prevision.reais / item.kg;
            return {
              ...item,
              ...prevision,
              total: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            };
          }
          if (prevision?.kg && item?.reais) {
            const valor = item.reais / prevision.kg;
            return {
              ...item,
              ...prevision,
              total: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            };
          }
          if (item?.kg && item?.reais) {
            const valor = item.reais / item.kg;
            return {
              ...item,
              ...prevision,
              total: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            };
          }

          return {
            ...item,
            ...prevision,
            total: undefined
          };
        }
        return item;
      })
    }));
  };

  const handleChangePrevisionPlate = (prevision: any) => {
    setPrevisions((state) => ({
      ...state,
      plate: state?.plate?.map((item) => {
        if (item.formId === prevision.formId) {
          if (prevision?.reais && item?.kg) {
            const valor = prevision.reais / item.kg;
            return {
              ...item,
              ...prevision,
              total: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            };
          }
          if (prevision?.kg && item?.reais) {
            const valor = item.reais / prevision.kg;
            return {
              ...item,
              ...prevision,
              total: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            };
          }
          if (item?.kg && item?.reais) {
            const valor = item.reais / item.kg;
            return {
              ...item,
              ...prevision,
              total: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            };
          }

          return {
            ...item,
            ...prevision,
            total: undefined
          };
        }
        return item;
      })
    }));
  };

  const handleChangePrevisionBox = (prevision: any) => {
    setPrevisions((state) => ({
      ...state,
      box: state?.box?.map((item) => {
        if (item.formId === prevision.formId) {
          if (prevision?.reais && item?.kg) {
            const valor = prevision.reais / item.kg;
            return {
              ...item,
              ...prevision,
              total: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            };
          }
          if (prevision?.kg && item?.reais) {
            const valor = item.reais / prevision.kg;
            return {
              ...item,
              ...prevision,
              total: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            };
          }
          if (item?.kg && item?.reais) {
            const valor = item.reais / item.kg;
            return {
              ...item,
              ...prevision,
              total: Number.isNaN(valor) || !Number.isFinite(valor) ? 0 : valor
            };
          }

          return {
            ...item,
            ...prevision,
            total: undefined
          };
        }
        return item;
      })
    }));
  };

  const generateId = () => {
    return Math.random() * (1000 - 1) + 1;
  };

  const handleAddPrevision = (product: string) => {
    const formId = generateId();

    if (product === 'coil') {
      setPrevisions((state) => ({
        ...state,
        coil: [...state.coil, { formId }]
      }));
    } else if (product === 'plate') {
      setPrevisions((state) => ({
        ...state,
        plate: [...state.plate, { formId }]
      }));
    } else if (product === 'box') {
      setPrevisions((state) => ({ ...state, box: [...state.box, { formId }] }));
    }
  };

  const handleRemovePrevision = (product: string, prevision: any) => {
    if (!prevision?._id) {
      if (product === 'coil') {
        setPrevisions((state) => ({
          ...state,
          coil: state.coil?.filter((item) => item.formId !== prevision.formId)
        }));
        if (previsions?.coil?.length <= 1) {
          const formId = generateId();
          setPrevisions((state) => ({ ...state, coil: [{ formId }] }));
        }
      } else if (product === 'plate') {
        setPrevisions((state) => ({
          ...state,
          plate: state.plate?.filter(
            (item) => item.formId !== prevision.formId
          )
        }));
        if (previsions?.plate?.length <= 1) {
          const formId = generateId();
          setPrevisions((state) => ({ ...state, plate: [{ formId }] }));
        }
      } else if (product === 'box') {
        setPrevisions((state) => ({
          ...state,
          box: state.box?.filter((item) => item.formId !== prevision.formId)
        }));
        if (previsions?.box?.length <= 1) {
          const formId = generateId();
          setPrevisions((state) => ({ ...state, box: [{ formId }] }));
        }
      }

      return;
    }

    // axios
    //     .delete(`http://localhost:9003/api/v1/previsions/${prevision?._id}`)
    httpClient
      .delete(`previsions/${prevision?._id}`)
      .then(() => {
        if (product === 'coil') {
          setPrevisions((state) => ({
            ...state,
            coil: state.coil?.filter(
              (item) => item.formId !== prevision.formId
            )
          }));
          if (previsions?.coil?.length <= 1) {
            const formId = generateId();
            setPrevisions((state) => ({ ...state, coil: [{ formId }] }));
          }
        } else if (product === 'plate') {
          setPrevisions((state) => ({
            ...state,
            plate: state.plate?.filter(
              (item) => item.formId !== prevision.formId
            )
          }));
          if (previsions?.plate?.length <= 1) {
            const formId = generateId();
            setPrevisions((state) => ({ ...state, plate: [{ formId }] }));
          }
        } else if (product === 'box') {
          setPrevisions((state) => ({
            ...state,
            box: state.box?.filter((item) => item.formId !== prevision.formId)
          }));
          if (previsions?.box?.length <= 1) {
            const formId = generateId();
            setPrevisions((state) => ({ ...state, box: [{ formId }] }));
          }
        }
      })
      .catch((e) => {
        if (
          e.response.data.response.message !== null &&
          e.response.data.response.message !== undefined
        ) {
          toast.error(e.response.data.response.message);
        } else {
          toast.error('Erro ao deletar previsão');
        }
      });
  };

  const handleCreate = async () => {
    let previsionFormId = previsionForm?._id;

    if (!previsionForm?._id) {
      const body = {
        month: moment(date).format('M'),
        year: moment(date).format('YYYY')
      };

      try {
        // const response = await axios
        // .post('http://localhost:9003/api/v1/previsions/form', body)
        const response = await httpClient.post('/previsions/form', body);

        setPrevisionForm(response.data);
        previsionFormId = response.data?._id;
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message ||
            'Não foi possível criar formulário de previsões!'
        );
      }
    }

    if (!previsionFormId) {
      return;
    }

    console.log(previsions);

    previsions?.coil?.forEach((currentPrevision) => {
      if (!currentPrevision?._id) {
        if (!currentPrevision?.total) {
          setPrevisions((state) => ({
            ...state,
            coil: state.coil.map((item) => {
              if (item.formId === currentPrevision.formId) {
                return {
                  ...item,
                  error: {
                    kg: !currentPrevision?.kg,
                    reais: !currentPrevision?.reais,
                    client: !currentPrevision?.client
                  }
                };
              }
              return item;
            })
          }));
        } else {
          if (!currentPrevision?.client) {
            setPrevisions((state) => ({
              ...state,
              coil: state.coil.map((item) => {
                if (item.formId === currentPrevision.formId) {
                  return {
                    ...item,
                    error: {
                      client: 'Campo obrigatório'
                    }
                  };
                }
                return item;
              })
            }));
            return;
          }

          const body = {
            month: moment(date).format('M'),
            year: moment(date).format('YYYY'),
            product: products?.find((product) => product.name === 'Bobinas')
              ?._id,
            client: currentPrevision.client,
            seller: currentPrevision.seller,
            pesoKg: currentPrevision.kg,
            previsionPrice: currentPrevision.reais,
            previsionPriceKg: currentPrevision.total,
            previsionForm: previsionFormId
          };
          console.log(body);

          // axios
          //   .post('http://localhost:9003/api/v1/previsions', body)
          httpClient
            .post('previsions', body)
            .then((response) => {
              toast.success('Salvo com sucesso');
              console.log(response.data);
              // Receber os dados cadastrados e adicionar o id no array de previsions
              setPrevisions((state) => ({
                ...state,
                coil: state.coil.map((item) => {
                  if (item.formId === currentPrevision.formId) {
                    return {
                      ...item,
                      _id: response.data?.[0]?._id,
                      error: {}
                    };
                  }

                  return item;
                })
              }));
            })
            .catch((e) => {
              setPrevisions((state) => ({
                ...state,
                coil: state.coil.map((item) => {
                  if (item.formId === currentPrevision.formId) {
                    return {
                      ...item,
                      error: {
                        cliente: e.response.data.response.message
                      }
                    };
                  }
                  return item;
                })
              }));
              if (
                e.response.data.response.message !== null &&
                e.response.data.response.message !== undefined
              ) {
                const count = previsions.coil.filter(
                  (item) => item.client === currentPrevision.client
                ).length;

                const clientName =
                  dados?.find(
                    (client) => client._id === currentPrevision.client
                  )?.name ?? '';

                const description =
                  count >= 2
                    ? `O produto "Bobina" para o cliente "${clientName}" está
                duplicado.`
                    : 'Produto "Bobina".';

                toast.error(
                  <Box display="flex" flexDirection="column">
                    <Typography component="span">
                      {e.response.data.response.message}
                    </Typography>
                    <Typography component="span">{description}</Typography>
                  </Box>
                );
              } else {
                toast.error('Erro ao cadastrar previsão');
              }
            });
        }
      }
    });

    previsions?.plate?.forEach((currentPrevision) => {
      if (!currentPrevision?._id) {
        if (!currentPrevision?.total) {
          setPrevisions((state) => ({
            ...state,
            plate: state.plate.map((item) => {
              if (item.formId === currentPrevision.formId) {
                return {
                  ...item,
                  error: {
                    kg: !currentPrevision?.kg,
                    reais: !currentPrevision?.reais,
                    client: !currentPrevision?.client
                  }
                };
              }
              return item;
            })
          }));
        } else {
          if (!currentPrevision?.client) {
            setPrevisions((state) => ({
              ...state,
              plate: state.plate.map((item) => {
                if (item.formId === currentPrevision.formId) {
                  return {
                    ...item,
                    error: {
                      client: 'Campo obrigatório'
                    }
                  };
                }
                return item;
              })
            }));
            return;
          }

          const body = {
            month: moment(date).format('M'),
            year: moment(date).format('YYYY'),
            product: products?.find((product) => product.name === 'Chapas')
              ?._id,
            client: currentPrevision.client,
            seller: currentPrevision.seller,
            pesoKg: currentPrevision.kg,
            previsionPrice: currentPrevision.reais,
            previsionPriceKg: currentPrevision.total,
            previsionForm: previsionFormId
          };

          // axios
          //   .post('http://localhost:9003/api/v1/previsions', body)
          httpClient
            .post('previsions', body)
            .then((response) => {
              toast.success('Salvo com sucesso');
              console.log(response.data);
              // Receber os dados cadastrados e adicionar o id no array de previsions
              setPrevisions((state) => ({
                ...state,
                plate: state.plate.map((item) => {
                  if (item.formId === currentPrevision.formId) {
                    return {
                      ...item,
                      _id: response.data?.[0]?._id,
                      error: {}
                    };
                  }

                  return item;
                })
              }));
            })
            .catch((e) => {
              setPrevisions((state) => ({
                ...state,
                plate: state.plate.map((item) => {
                  if (item.formId === currentPrevision.formId) {
                    return {
                      ...item,
                      error: {
                        client: e.response.data.response.message
                      }
                    };
                  }
                  return item;
                })
              }));
              if (
                e.response.data.response.message !== null &&
                e.response.data.response.message !== undefined
              ) {
                const count = previsions.plate.filter(
                  (item) => item.client === currentPrevision.client
                ).length;

                const clientName =
                  dados?.find(
                    (client) => client._id === currentPrevision.client
                  )?.name ?? '';

                const description =
                  count >= 2
                    ? `O produto "Chapa" para o cliente "${clientName}" está
                duplicado.`
                    : 'Produto "Chapa".';

                toast.error(
                  <Box display="flex" flexDirection="column">
                    <Typography component="span">
                      {e.response.data.response.message}
                    </Typography>
                    <Typography component="span">{description}</Typography>
                  </Box>
                );
              } else {
                toast.error('Erro ao cadastrar previsão');
              }
            });
        }
      }
    });

    previsions?.box?.forEach((currentPrevision) => {
      if (!currentPrevision?._id) {
        if (!currentPrevision?.total) {
          setPrevisions((state) => ({
            ...state,
            box: state.box.map((item) => {
              if (item.formId === currentPrevision.formId) {
                return {
                  ...item,
                  error: {
                    kg: !currentPrevision?.kg,
                    reais: !currentPrevision?.reais,
                    client: !currentPrevision?.client
                  }
                };
              }
              return item;
            })
          }));
        } else {
          if (!currentPrevision?.client) {
            setPrevisions((state) => ({
              ...state,
              box: state.box.map((item) => {
                if (item.formId === currentPrevision.formId) {
                  return {
                    ...item,
                    error: {
                      client: 'Campo obrigatório'
                    }
                  };
                }
                return item;
              })
            }));
            return;
          }

          const body = {
            month: moment(date).format('M'),
            year: moment(date).format('YYYY'),
            product: products?.find((product) => product.name === 'Caixas')
              ?._id,
            client: currentPrevision.client,
            seller: currentPrevision.seller,
            pesoKg: currentPrevision.kg,
            previsionPrice: currentPrevision.reais,
            previsionPriceKg: currentPrevision.total,
            previsionForm: previsionFormId
          };

          // axios
          // .post('http://localhost:9003/api/v1/previsions', body)
          httpClient
            .post('previsions', body)
            .then((response) => {
              toast.success('Salvo com sucesso');
              console.log(response.data);
              // Receber os dados cadastrados e adicionar o id no array de previsions
              setPrevisions((state) => ({
                ...state,
                box: state.box.map((item) => {
                  if (item.formId === currentPrevision.formId) {
                    return {
                      ...item,
                      _id: response.data?.[0]?._id,
                      error: {}
                    };
                  }

                  return item;
                })
              }));
            })
            .catch((e) => {
              setPrevisions((state) => ({
                ...state,
                box: state.box.map((item) => {
                  if (item.formId === currentPrevision.formId) {
                    return {
                      ...item,
                      error: {
                        client: e.response.data.response.message
                      }
                    };
                  }
                  return item;
                })
              }));
              if (
                e.response.data.response.message !== null &&
                e.response.data.response.message !== undefined
              ) {
                const count = previsions.box.filter(
                  (item) => item.client === currentPrevision.client
                ).length;

                const clientName =
                  dados?.find(
                    (client) => client._id === currentPrevision.client
                  )?.name ?? '';

                const description =
                  count >= 2
                    ? `O produto "Caixa" para o cliente "${clientName}" está
                duplicado.`
                    : 'Produto "Caixa".';

                toast.error(
                  <Box display="flex" flexDirection="column">
                    <Typography component="span">
                      {e.response.data.response.message}
                    </Typography>
                    <Typography component="span">{description}</Typography>
                  </Box>
                );
              } else {
                toast.error('Erro ao cadastrar previsão');
              }
            });
        }
      }
    });
  };

  return (
    <>
      <Box sx={{ display: 'grid', gridTemplateRows: 'max-content auto' }}>
        <Card>
          <CardContent>
            <Box
              sx={{ marginTop: '10px', fontSize: '16px', fontWeight: '600' }}
            >
              Novas Previsões
            </Box>

            <Grid container spacing={2}>
              <Grid
                item
                md={12}
                style={{ marginTop: '10px', marginBottom: '24px' }}
              >
                <Grid item xs={6} md={4} paddingLeft="20px">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptBR}
                  >
                    <DatePicker
                      views={['year', 'month']}
                      inputFormat="MMMM yyyy"
                      label="Mês e Ano"
                      // minDate={moment(new Date()).add(-30, 'days').format()}
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      disabled={!!previsionForm?._id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus
                          variant="standard"
                          helperText={null}
                          autoComplete="off"
                          InputProps={{
                            startAdornment: (
                              <Box paddingRight={1} marginLeft="-40px">
                                {params?.InputProps?.endAdornment}
                              </Box>
                            )
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              {date && (
                <>
                  <Grid item xs={12}>
                    <TabsUnstyled defaultValue={state?.defaultTab ?? 0}>
                      <Box marginBottom={2}>
                        <TabsList>
                          <Tab type="button">Bobinas</Tab>
                          <Tab type="button">Chapas</Tab>
                          <Tab type="button">Caixas</Tab>
                        </TabsList>
                      </Box>

                      <TabPanel value={0}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={5} textAlign={'right'}>
                            <Typography
                              component={'strong'}
                              textAlign={'right'}
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Peso kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.coil?.peso}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    kg
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.coil?.rs}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={3} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$/kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={
                                Number(total?.coil?.rsKg)?.toFixed(2) ?? ''
                              }
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>

                          {previsions?.coil?.map((prevision, index) => (
                            <Fragment key={prevision.formId}>
                              <input
                                type="text"
                                hidden
                                value={
                                  products?.find(
                                    (product) => product.name === 'Bobinas'
                                  )?._id
                                }
                              />
                              <Grid item xs={12} md={3}>
                                <FormControl
                                  variant="standard"
                                  sx={{ width: '100%' }}
                                >
                                  <AutocompleteClients
                                    onChange={(value) => {
                                      prevision.currentClientValue = value;
                                      handleChangePrevisionCoil({
                                        formId: prevision.formId,
                                        client: value
                                      });
                                    }}
                                    currentValue={prevision.currentClientValue}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FormControl
                                  variant="standard"
                                  sx={{ width: '100%' }}
                                >
                                  <TextField
                                    style={{ width: '100%' }}
                                    id={`seller-${prevision.formId}`}
                                    label="Vendedor"
                                    variant="standard"
                                    autoComplete="off"
                                    value={
                                      prevision.currentClientValue?.seller?.user
                                        ?.name || ''
                                    }
                                    disabled
                                  />
                                  {/*
                                  <InputLabel
                                    id="select-cliente-label"
                                    required
                                  >
                                    Vendedor
                                  </InputLabel>
                                  <Select
                                    labelId="select-cliente-label"
                                    id={`select-cliente-${prevision.formId}`}
                                    label="Vendedor"
                                    disabled
                                    value={prevision.currentClientValue?.seller?._id || ''}
                                    // value={prevision?.seller || (prevision?.client ? dados.find(d => d._id === prevision?.client)?.seller?._id : null) || ''}
                                    onChange={(e) =>
                                      handleChangePrevisionCoil({
                                        formId: prevision.formId,
                                        seller: e.target.value
                                      })
                                    }
                                    required
                                  >
                                    {sellers.map((item: any) => {
                                      return (
                                        <MenuItem
                                          value={item._id}
                                          disabled={!!prevision?._id}
                                        >
                                          {item?.user?.name}
                                        </MenuItem>
                                      )
                                    })}
                                  </Select> */}
                                </FormControl>
                              </Grid>
                              <Grid item xs={4} md={2}>
                                <TextField
                                  style={{ width: '100%' }}
                                  id={`kg-${prevision.formId}`}
                                  label="Peso kg"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off"
                                  value={prevision?.kg || ''}
                                  onChange={(e) => {
                                    if (!onlyNumbers(e.target.value)) {
                                      handleChangePrevisionCoil({
                                        formId: prevision.formId,
                                        kg: undefined
                                      });
                                    } else {
                                      handleChangePrevisionCoil({
                                        formId: prevision.formId,
                                        kg: Math.round(
                                          Number(e.target.value) <= 0
                                            ? 1
                                            : Number(e.target.value)
                                        )
                                      });
                                    }
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        kg
                                      </InputAdornment>
                                    )
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  disabled={!!prevision?._id}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} md={2}>
                                <TextField
                                  style={{ width: '100%' }}
                                  id={`reais-${index}`}
                                  label="Previsão R$"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off"
                                  value={prevision?.reais || ''}
                                  onChange={(e) => {
                                    if (!onlyNumbers(e.target.value)) {
                                      handleChangePrevisionCoil({
                                        formId: prevision.formId,
                                        reais: undefined
                                      });
                                    } else {
                                      handleChangePrevisionCoil({
                                        formId: prevision.formId,
                                        reais: Number(
                                          Number(e.target.value) <= 0
                                            ? 1.0
                                            : Number(e.target.value).toFixed(2)
                                        )
                                      });
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter' && prevision?.reais) {
                                      document
                                        .getElementById(
                                          'button-new-prevision-coil'
                                        )
                                        ?.focus();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        R$
                                      </InputAdornment>
                                    )
                                  }}
                                  disabled={!!prevision?._id}
                                  required
                                />
                              </Grid>
                              <Grid item xs={3} md={2}>
                                <TextField
                                  style={{ width: '100%' }}
                                  label="Previsão R$/kg"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off"
                                  value={
                                    Number(prevision?.total)?.toFixed(2) ?? ''
                                  }
                                  disabled={true}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        R$
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                style={{ alignSelf: 'flex-end' }}
                              >
                                <MuiButton
                                  id={`button-delete-${index}`}
                                  variant="contained"
                                  color="error"
                                  size="small"
                                  onClick={() =>
                                    handleRemovePrevision('coil', prevision)
                                  }
                                  sx={{
                                    boxShadow: 'none !important',
                                    minWidth: 'auto',
                                    minHeight: 'auto',
                                    padding: '4px'
                                  }}
                                >
                                  <ClearIcon fontSize="small" />
                                </MuiButton>
                              </Grid>
                            </Fragment>
                          ))}
                        </Grid>
                        <Grid item xs={12} marginTop={4}>
                          <MuiButton
                            variant="contained"
                            id="button-new-prevision-coil"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              handleCreate();
                              handleAddPrevision('coil');
                            }}
                            sx={{
                              '&:hover': {
                                background: '#fff'
                              },
                              textTransform: 'none',
                              background: '#fff',
                              color: '#8A8A8A'
                            }}
                          >
                            Nova previsão
                          </MuiButton>
                        </Grid>
                      </TabPanel>

                      <TabPanel value={1}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={5} textAlign={'right'}>
                            <Typography
                              component={'strong'}
                              textAlign={'right'}
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Peso kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.plate?.peso}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    kg
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.plate?.rs}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={3} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$/kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={
                                Number(total?.plate?.rsKg)?.toFixed(2) ?? ''
                              }
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>

                          {previsions?.plate?.map((prevision, index) => (
                            <Fragment key={prevision.formId}>
                              <input
                                type="text"
                                hidden
                                value={
                                  products?.find(
                                    (product) => product.name === 'Chapas'
                                  )?._id
                                }
                              />
                              <Grid item xs={12} md={3}>
                                <FormControl
                                  variant="standard"
                                  sx={{ width: '100%' }}
                                >
                                  <AutocompleteClients
                                    onChange={(value) => {
                                      prevision.currentClientValue = value;
                                      handleChangePrevisionPlate({
                                        formId: prevision.formId,
                                        client: value
                                      });
                                    }}
                                    currentValue={prevision.currentClientValue}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FormControl
                                  variant="standard"
                                  sx={{ width: '100%' }}
                                >
                                  <TextField
                                    style={{ width: '100%' }}
                                    id={`seller-${prevision.formId}`}
                                    label="Vendedor"
                                    variant="standard"
                                    autoComplete="off"
                                    value={
                                      prevision.currentClientValue?.seller?.user
                                        ?.name || ''
                                    }
                                    disabled
                                  />
                                  {/* <InputLabel
                                    id="select-cliente-label"
                                    required
                                  >
                                    Vendedor
                                  </InputLabel>
                                  <Select
                                    labelId="select-cliente-label"
                                    id={`select-cliente-${prevision.formId}`}
                                    label="Vendedor"
                                    value={prevision?.seller || (prevision?.client ? dados.find(d => d._id === prevision?.client)?.seller?._id : null) || ''}
                                    onChange={(e) =>
                                      handleChangePrevisionPlate({
                                        formId: prevision.formId,
                                        seller: e.target.value
                                      })
                                    }
                                    required
                                  >
                                    {sellers.map((item: any) => {
                                      return (
                                        <MenuItem
                                          value={item._id}
                                          disabled={!!prevision?._id}
                                        >
                                          {item?.user?.name}
                                        </MenuItem>
                                      )
                                    })}
                                  </Select> */}
                                </FormControl>
                              </Grid>
                              <Grid item xs={4} md={2}>
                                <TextField
                                  style={{ width: '100%' }}
                                  id={`kg-${prevision.formId}`}
                                  label="Peso kg"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off"
                                  value={prevision?.kg || ''}
                                  onChange={(e) => {
                                    if (!onlyNumbers(e.target.value)) {
                                      handleChangePrevisionPlate({
                                        formId: prevision.formId,
                                        kg: undefined
                                      });
                                    } else {
                                      handleChangePrevisionPlate({
                                        formId: prevision.formId,
                                        kg: Math.round(
                                          Number(e.target.value) <= 0
                                            ? 1
                                            : Number(e.target.value)
                                        )
                                      });
                                    }
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        kg
                                      </InputAdornment>
                                    )
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  disabled={!!prevision?._id}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} md={2}>
                                <TextField
                                  style={{ width: '100%' }}
                                  id={`reais-${index}`}
                                  label="Previsão R$"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off"
                                  value={prevision?.reais || ''}
                                  onChange={(e) => {
                                    if (!onlyNumbers(e.target.value)) {
                                      handleChangePrevisionPlate({
                                        formId: prevision.formId,
                                        reais: undefined
                                      });
                                    } else {
                                      handleChangePrevisionPlate({
                                        formId: prevision.formId,
                                        reais: Number(
                                          Number(e.target.value) <= 0
                                            ? 1.0
                                            : Number(e.target.value).toFixed(2)
                                        )
                                      });
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter' && prevision?.reais) {
                                      document
                                        .getElementById(
                                          'button-new-prevision-plate'
                                        )
                                        ?.focus();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        R$
                                      </InputAdornment>
                                    )
                                  }}
                                  disabled={!!prevision?._id}
                                  required
                                />
                              </Grid>
                              <Grid item xs={3} md={2}>
                                <TextField
                                  style={{ width: '100%' }}
                                  label="Previsão R$/kg"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off"
                                  value={
                                    Number(prevision?.total)?.toFixed(2) ?? ''
                                  }
                                  disabled={true}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        R$
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                style={{ alignSelf: 'flex-end' }}
                              >
                                <MuiButton
                                  id={`button-delete-${index}`}
                                  variant="contained"
                                  color="error"
                                  size="small"
                                  onClick={() =>
                                    handleRemovePrevision('plate', prevision)
                                  }
                                  sx={{
                                    boxShadow: 'none !important',
                                    minWidth: 'auto',
                                    minHeight: 'auto',
                                    padding: '4px'
                                  }}
                                >
                                  <ClearIcon fontSize="small" />
                                </MuiButton>
                              </Grid>
                            </Fragment>
                          ))}
                        </Grid>
                        <Grid item xs={12} marginTop={4}>
                          <MuiButton
                            variant="contained"
                            id="button-new-prevision-plate"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              handleCreate();
                              handleAddPrevision('plate');
                            }}
                            sx={{
                              '&:hover': {
                                background: '#fff'
                              },
                              textTransform: 'none',
                              background: '#fff',
                              color: '#8A8A8A'
                            }}
                          >
                            Nova previsão
                          </MuiButton>
                        </Grid>
                      </TabPanel>

                      <TabPanel value={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={5} textAlign={'right'}>
                            <Typography
                              component={'strong'}
                              textAlign={'right'}
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Peso kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.box?.peso}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    kg
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={total?.box?.rs}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              disabled
                              required
                            />
                          </Grid>
                          <Grid item xs={3} md={2}>
                            <TextField
                              style={{ width: '100%' }}
                              label="Previsão R$/kg"
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              value={Number(total?.box?.rsKg)?.toFixed(2) ?? ''}
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>

                          {previsions?.box?.map((prevision, index) => (
                            <Fragment key={prevision.formId}>
                              <input
                                type="text"
                                hidden
                                value={
                                  products?.find(
                                    (product) => product.name === 'Caixas'
                                  )?._id
                                }
                              />
                              <Grid item xs={12} md={3}>
                                <FormControl
                                  variant="standard"
                                  sx={{ width: '100%' }}
                                >
                                  <AutocompleteClients
                                    onChange={(value) => {
                                      prevision.currentClientValue = value;
                                      handleChangePrevisionBox({
                                        formId: prevision.formId,
                                        client: value
                                      });
                                    }}
                                    currentValue={prevision.currentClientValue}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FormControl
                                  variant="standard"
                                  sx={{ width: '100%' }}
                                >
                                  <TextField
                                    style={{ width: '100%' }}
                                    id={`seller-${prevision.formId}`}
                                    label="Vendedor"
                                    variant="standard"
                                    autoComplete="off"
                                    value={
                                      prevision.currentClientValue?.seller?.user
                                        ?.name || ''
                                    }
                                    disabled
                                  />
                                  {/* <InputLabel
                                    id="select-cliente-label"
                                    required
                                  >
                                    Vendedor
                                  </InputLabel>
                                  <Select
                                    labelId="select-cliente-label"
                                    id={`select-cliente-${prevision.formId}`}
                                    label="Vendedor"
                                    value={prevision?.seller || (prevision?.client ? dados.find(d => d._id === prevision?.client)?.seller?._id : null) || ''}
                                    onChange={(e) =>
                                      handleChangePrevisionBox({
                                        formId: prevision.formId,
                                        seller: e.target.value
                                      })
                                    }
                                    required
                                  >
                                    {sellers.map((item: any) => {
                                      return (
                                        <MenuItem
                                          value={item._id}
                                          disabled={!!prevision?._id}
                                        >
                                          {item?.user?.name}
                                        </MenuItem>
                                      )
                                    })}
                                  </Select> */}
                                </FormControl>
                              </Grid>
                              <Grid item xs={4} md={2}>
                                <TextField
                                  style={{ width: '100%' }}
                                  id={`kg-${prevision.formId}`}
                                  label="Peso kg"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off"
                                  value={prevision?.kg || ''}
                                  onChange={(e) => {
                                    if (!onlyNumbers(e.target.value)) {
                                      handleChangePrevisionBox({
                                        formId: prevision.formId,
                                        kg: undefined
                                      });
                                    } else {
                                      handleChangePrevisionBox({
                                        formId: prevision.formId,
                                        kg: Math.round(
                                          Number(e.target.value) <= 0
                                            ? 1
                                            : Number(e.target.value)
                                        )
                                      });
                                    }
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        kg
                                      </InputAdornment>
                                    )
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  disabled={!!prevision?._id}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4} md={2}>
                                <TextField
                                  style={{ width: '100%' }}
                                  id={`reais-${index}`}
                                  label="Previsão R$"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off"
                                  value={prevision?.reais || ''}
                                  onChange={(e) => {
                                    if (!onlyNumbers(e.target.value)) {
                                      handleChangePrevisionBox({
                                        formId: prevision.formId,
                                        reais: undefined
                                      });
                                    } else {
                                      handleChangePrevisionBox({
                                        formId: prevision.formId,
                                        reais: Number(
                                          Number(e.target.value) <= 0
                                            ? 1.0
                                            : Number(e.target.value).toFixed(2)
                                        )
                                      });
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter' && prevision?.reais) {
                                      document
                                        .getElementById(
                                          'button-new-prevision-box'
                                        )
                                        ?.focus();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        R$
                                      </InputAdornment>
                                    )
                                  }}
                                  disabled={!!prevision?._id}
                                  required
                                />
                              </Grid>
                              <Grid item xs={3} md={2}>
                                <TextField
                                  style={{ width: '100%' }}
                                  label="Previsão R$/kg"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off"
                                  value={
                                    Number(prevision?.total)?.toFixed(2) ?? ''
                                  }
                                  disabled={true}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        R$
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                style={{ alignSelf: 'flex-end' }}
                              >
                                <MuiButton
                                  id={`button-delete-${index}`}
                                  variant="contained"
                                  color="error"
                                  size="small"
                                  onClick={() =>
                                    handleRemovePrevision('box', prevision)
                                  }
                                  sx={{
                                    boxShadow: 'none !important',
                                    minWidth: 'auto',
                                    minHeight: 'auto',
                                    padding: '4px'
                                  }}
                                >
                                  <ClearIcon fontSize="small" />
                                </MuiButton>
                              </Grid>
                            </Fragment>
                          ))}
                        </Grid>
                        <Grid item xs={12} marginTop={4}>
                          <MuiButton
                            variant="contained"
                            id="button-new-prevision-box"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              handleCreate();
                              handleAddPrevision('box');
                            }}
                            sx={{
                              '&:hover': {
                                background: '#fff'
                              },
                              textTransform: 'none',
                              background: '#fff',
                              color: '#8A8A8A'
                            }}
                          >
                            Nova previsão
                          </MuiButton>
                        </Grid>
                      </TabPanel>
                    </TabsUnstyled>
                  </Grid>
                </>
              )}

              <Grid
                item
                md={12}
                marginTop={8}
                className="text-right"
                style={{
                  alignSelf: 'flex-end',
                  justifySelf: 'self-end'
                }}
              >
                <Button
                  type="button"
                  onClick={() => navigate('/home/managementControl/management')}
                  disabled={loading}
                  style={{
                    width: '122px'
                  }}
                >
                  Voltar
                </Button>
                <Button
                  type="button"
                  onClick={handleCreate}
                  disabled={loading}
                  style={{
                    backgroundColor: '#4060E6',
                    width: '122px',
                    color: 'white',
                    marginLeft: '16px'
                  }}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default FormManagementControl;
