/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Paper,
  Radio,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import alertSuccess from 'components/Alerts/ModalSucess'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import {
  StyledTableCell,
  StyledTableRow
} from 'pages/company/portfolio/components/table.styles'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getMaterial,
  getTaxes,
  postMaterial,
  putMaterial
} from 'services/material/MaterialService'
import { FTBudgetType } from 'types/ft/FTBudgetType'
import { MaterialType } from 'types/material/MaterialType'
import { validationSchemaBudgetFT } from 'types/validation/ValidationBudgetFT'

function MaterialValuesForm(props: any) {
  const [rows, setRows] = React.useState<any[]>([])
  const [rowsTaxes, setRowsTaxes] = React.useState<any[]>([])
  const [productSelected, setProductSelected] = React.useState({
    value: 0,
    material: ''
  })
  const [taxSelected, setTaxSelected] = React.useState({ days: 0, tax: 0 })
  const navigate = useNavigate()

  useQuery(
    ['material', null],
    async () => {
      return getMaterial()
    },
    {
      onSuccess: (data) => {
        console.log('data material', data)
        setRows(data)
        data.forEach((product) => {
          if (product.material === 'Fechamento') {
            // Fechamento
            setFieldValue('closureValue', product.value)
          } else if (product.material === 'Resina') {
            // Resina
            setFieldValue('resinValue', product.value)
          } else if (product.material === 'Limpeza') {
            // Limpeza
            setFieldValue('cleaningValue', product.value)
          } else {
            // Produto
            setProductSelected(product)
            setFieldValue('productValue', product.value)
            setFieldValue('productName', product.material)
          }
        })
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  useQuery(
    ['taxes', null],
    async () => {
      return getTaxes()
    },
    {
      onSuccess: (data) => {
        data.sort((a, b) => {
          return a.days - b.days
        })
        console.log('data taxas', data)
        setRowsTaxes(data)
        data.forEach((tax) => {
          setTaxSelected(tax)
          setFieldValue('daysValue', tax.days)
          setFieldValue('taxValue', tax.tax)
        })
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const { errors, touched, values, handleSubmit, setFieldValue } = useFormik({
    onSubmit: (values: FTBudgetType) => {
      console.log(values)
      alertSuccess(
        'Valores do material ajustados',
        'Os valores serão preenchidos automaticamente no orçamento.',
        () => {
          props.confirm(values)
        }
      )
    },
    enableReinitialize: true,
    initialValues: {
      productValue: 0,
      productName: '',
      closureValue: 0,
      resinValue: 0,
      cleaningValue: 0,
      daysValue: 0,
      taxValue: 0
    }
  })

  const handleChangeProduct = (product) => {
    setFieldValue('productValue', product.value)
    setFieldValue('productName', product.material)
    setProductSelected(product)
  }

  const handleChangeTax = (tax) => {
    setFieldValue('taxValue', tax.tax)
    setFieldValue('daysValue', tax.days)
    setTaxSelected(tax)
  }

  const handleInputChange = (product, newValue) => {
    console.log('product', product)
    console.log('value', newValue)

    if (product.material === 'Fechamento') {
      // Fechamento
      setFieldValue('closureValue', newValue)
    } else if (product.material === 'Resina') {
      // Resina
      setFieldValue('resinValue', newValue)
    } else if (product.material === 'Limpeza') {
      // Limpeza
      setFieldValue('cleaningValue', newValue)
    } else {
      // Produto
      setFieldValue('productValue', newValue)
      setFieldValue('productName', product.material)
    }
  }

  const handleInputChangeTax = (tax) => {
    setFieldValue('taxValue', tax)
  }

  const handleInputChangeDays = (days) => {
    setFieldValue('daysValue', days)
  }

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip
          title="Voltar"
          placement="top"
          onClick={() => {
            props.close()
          }}
        >
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>
          Valores de materiais
        </div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginRight: '30px',
              marginTop: '20px'
            }}
          >
            <div>
              Baseado na tabela preenchida em{' '}
              <a
                style={{ fontWeight: 'bold', color: '#0000EE', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() =>
                  navigate('/home/managementControl/management/material-taxes')
                }
              >
                Controle Gerencial
              </a>
              .
            </div>
            <div style={{ marginTop: 5 }}>
              Você pode alterar os valores para o orçamento deste projeto, mas
              os valores base da tabela não mudam.
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px'
                }}
              >
                <div>
                  <TableContainer
                    style={{ marginTop: '30px' }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 530 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Produto
                          </StyledTableCell>
                          <StyledTableCell align="left"></StyledTableCell>
                          <StyledTableCell align="left"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .filter((r) => r.category === 0)
                          .map((row) => (
                            <StyledTableRow key={row._id}>
                              <StyledTableCell align="left">
                                {row.material}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <TextField
                                  id="standard-basic"
                                  label="R$"
                                  variant="standard"
                                  defaultValue={row.value}
                                  onChange={(event) => {
                                    handleInputChange(row, event.target.value)
                                  }}
                                  type='number'
                                  disabled={productSelected !== row}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Radio
                                  checked={productSelected === row}
                                  onChange={() => handleChangeProduct(row)}
                                  name="radio-buttons"
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TableContainer
                    style={{ marginTop: '30px' }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 530 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Acabamento
                          </StyledTableCell>
                          <StyledTableCell align="left"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .filter((r) => r.category === 1)
                          .map((row) => (
                            <StyledTableRow key={row._id}>
                              <StyledTableCell align="left">
                                {row.material}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <TextField
                                  id="standard-basic"
                                  label="R$"
                                  variant="standard"
                                  defaultValue={row.value}
                                  onChange={(event) => {
                                    handleInputChange(row, event.target.value)
                                  }}
                                  type='number'
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TableContainer
                    style={{ marginTop: '30px' }}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 530 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Taxa</StyledTableCell>
                          <StyledTableCell align="left"></StyledTableCell>
                          <StyledTableCell align="left"></StyledTableCell>
                          <StyledTableCell align="left"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsTaxes.map((row) => (
                          <StyledTableRow key={row._id}>
                            <StyledTableCell align="left">
                              {row.material}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <TextField
                                id="standard-basic"
                                label="Dias"
                                variant="standard"
                                defaultValue={row.days}
                                onChange={(event) => {
                                  handleInputChangeDays(event.target.value)
                                }}
                                type='number'
                                disabled={taxSelected !== row}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <TextField
                                id="standard-basic"
                                label="Taxa (%)"
                                variant="standard"
                                defaultValue={row.tax}
                                onChange={(event) => {
                                  handleInputChangeTax(event.target.value)
                                }}
                                type='number'
                                inputProps={{ step: 0.01 }}
                                disabled={taxSelected !== row}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Radio
                                checked={taxSelected === row}
                                onChange={() => handleChangeTax(row)}
                                name="radio-buttons"
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type="button"
              mold="red"
              onClick={() => {
                props.close()
              }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold="primary"
              type="submit"
            >
              SALVAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default MaterialValuesForm
