import React from 'react'

interface Props {
  status: string
  size?: 'small' | 'medium' | 'large'
  style?: any
  disabled?: boolean
}

const StatusView: React.FC<Props> = (props) => {
  let colorPrimary = '#5f7aea'
  let colorWarning = '#ce6c2b'
  let colorError = '#EE207A'
  let colorSuccess = '#72CE2B'

  let bgPrimary = '#edf0ff'
  let bgWarning = '#fff1d7'
  let bgError = '#FFDDEC'
  let bgSuccess = '#EBFFD8'

  if (props.disabled) {
    colorPrimary = '#989898'
    colorWarning = '#989898'
    colorError = '#989898'
    colorSuccess = '#989898'

    bgPrimary = '#eaeaea'
    bgWarning = '#eaeaea'
    bgError = '#eaeaea'
    bgSuccess = '#eaeaea'
  }

  if (props.size === 'small') {
    return (
      <div style={props.style}>
        <div style={{
          borderRadius: '3px',
          color: props.status === 'NOVO' ? colorPrimary : (props.status === 'CORRIGIDO' || props.status === 'ANÁLISE') ? colorWarning : (props.status === 'APROVADO' || props.status === 'CADASTRADO') ? colorSuccess : colorError,
          padding: '2px 6px',
          border: '1px solid',
          textAlign: 'center',
          backgroundColor: props.status === 'NOVO' ? bgPrimary : props.status === 'CORRIGIDO' ? bgWarning : (props.status === 'APROVADO' || props.status === 'CADASTRADO') ? bgSuccess : bgError,
          fontSize: '11px'
        }}>
          {props.status}
        </div>
      </div>

    )
  }

  return (
    <div style={props.style}>
      <div style={{
        borderRadius: '3px',
        color: props.status === 'NOVO' ? colorPrimary : (props.status === 'CORRIGIDO' || props.status === 'ANÁLISE') ? colorWarning : (props.status === 'APROVADO' || props.status === 'CADASTRADO') ? colorSuccess : colorError,
        padding: '4px 8px',
        border: '1px solid',
        textAlign: 'center',
        backgroundColor: props.status === 'NOVO' ? bgPrimary : props.status === 'CORRIGIDO' ? bgWarning : (props.status === 'APROVADO' || props.status === 'CADASTRADO') ? bgSuccess : bgError
      }}>
        {props.status}
      </div>
    </div>

  )
}

export default StatusView
