import React from 'react'
import { Grid, Box, Typography, Tooltip } from '@mui/material'

interface ItemsPanelProps {
  data: Array<{
    ft: string
    cliente: string
    tipo: string
    machine: string
  }>
  legend: Array<{
    code: string
    description: string
  }>
}

// Function to count machine occurrences
const countMachines = (data: ItemsPanelProps['data']) => {
  const machineCounts: { [key: string]: number } = {}
  data.forEach(({ machine }) => {
    machineCounts[machine] = (machineCounts[machine] || 0) + 1
  })
  return machineCounts
}

// Component to display the machine counts
const ItemsPanel: React.FC<ItemsPanelProps> = ({ data, legend }) => {
  const machineCounts = countMachines(data)
  const colors = ['#B34D4D', '#ff875e', '#ff794b', '#ff6a37', '#FF6633',
    '#E6B333', '#3366E6', '#999966', '#ccff00', '#b8e600',
    '#a3cd00', '#8fb300', '#7b9a00', '#668000', '#FF99E6',
    '#6680B3', '#002b3b', '#FF1A66', '#FF33FF', '#33FFCC',
    '#162a23', '#00acec', '#ff0028']

  return (
    <Grid item xs={12}>
      <Box
        component="section"
        sx={{ p: 2, textAlign: 'justify' }}
      >
        <Box
          className="dashboard-container"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '3px',
            padding: '6px'
          }}
        >
          {Object.entries(machineCounts).map(([machine, count], index) => {
            const matchingLegend = legend.find((l) => l.code === machine)
            const tooltipTitle = matchingLegend ? matchingLegend.description : machine
            return (
              <Tooltip key={machine} title={tooltipTitle}>
                <Box
                  className="machine-card"
                  sx={{
                    backgroundColor: colors[index % colors.length],
                    flex: '1 1 70px',
                    padding: '5px',
                    color: 'white',
                    borderRadius: '6px',
                    textAlign: 'center',
                    fontSize: '0.8em'
                  }}
                >
                  <Typography>{machine}</Typography>
                  <Typography variant="body1">{count}</Typography>
                </Box>
              </Tooltip>
            )
          })}
        </Box>
      </Box>
    </Grid>
  )
}

export default ItemsPanel
