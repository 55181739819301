import styled from 'styled-components'

export const RowCV = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`
export const ColumnCV = styled.div`
display: flex;
align-items: center;
justify-content: flex-start !important;
flex-direction: row;
`
export const LabelTitle = styled.div`
 display: flex;
align-items: flex-start;
justify-content: left;
flex-direction: column;
`
export const LabelRate = styled.div`
  font-size: 32px;
`
export const ArrowUpIcon = styled.div`
  background-color: #b5bff5;
  border-radius: 50px;
  font-size: 11px;
  padding: 1px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
`
export const ContentComments = styled.div`
    height: 255px;
    overflow-y: scroll;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      width: 2px;
    }
`
export const DotCV = styled.div<{color: string}>`
width: 10px;
height: 10px;
background-color: ${props => props.color};
border-radius: 20px;
margin-right: 10px;
margin-left: 15px;
`
