import {
  RemoveRedEye
} from '@mui/icons-material'
import LightTooltip from 'components/tooltips/LightTooltip'
import MoreMenu from 'components/view/MoreMenu'
import { ActionButton } from 'components/view/styles/style-more-menu'

export const TableActionButtons = ({
  onView
}: {
  onView: () => void
}) => {
  return (
    <MoreMenu>
      <LightTooltip title="Visualizar" placement="top">
        <ActionButton onClick={onView}>
          <RemoveRedEye />
        </ActionButton>
      </LightTooltip>
    </MoreMenu>
  )
}
