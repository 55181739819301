/* eslint-disable @typescript-eslint/no-unused-vars */
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import Button from 'components/form/Button'
import { httpClient } from 'config/httpClient'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { toast } from 'react-toastify'

interface Props {
  close: () => void
  closeSuccess: () => void
  data: any
}

export const ClientType = ({ data, close, closeSuccess }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)

  console.log('ClientType data', data)
  const handleTypes = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
    types: string[]
  ) => {
    if (event.target.checked) {
      return [...types, type]
    }

    return types.filter((item) => item !== type)
  }

  return (
    <Stack spacing={3} paddingY={3} paddingX={6}>
      <Box
        borderLeft="20px solid #EEBD20"
        boxShadow="0 3px 6px #00000029"
        minHeight={74}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
      >
        <Typography
          fontSize="20px"
          fontWeight="500"
          component="strong"
          sx={{ paddingY: 1, paddingX: 2 }}
        >
          Tipo de Cliente
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          sx={{ paddingY: 1, paddingX: 2 }}
        >
          <PermIdentityIcon />
          <Typography component="span" variant="body1" marginLeft={1}>
            Para: {data?.name}
          </Typography>
        </Box>
      </Box>

      <Formik
        initialValues={{
          _id: data?._id ?? '',
          pilotLot: data?.pilotLot ?? false,
          typeClient: data?.typeClient ?? '',
          minimumPurchaseAmount: data?.minimumPurchaseAmount ?? '',
          types: data?.types ?? [],
          inCash: data?.inCash ?? false
        }}
        // validationSchema={validationClientTypeSchema}
        onSubmit={async (values) => {
          console.log(values)
          setLoading(true)

          try {
            await httpClient.put(`/company/types/${data?._id}`, values)

            toast.success('Atualizado com sucesso!')
            setLoading(false)

            closeSuccess()
          } catch (error: any) {
            console.log('Error: ', error)
            toast.error(error?.response?.data?.message)
            setLoading(false)
          }
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">Tipo</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.types.includes('KANBAN')}
                          onChange={(event) => {
                            setFieldValue(
                              'types',
                              handleTypes(event, 'KANBAN', values.types)
                            )
                            if (event.target.checked) {
                              setFieldValue('types', ['KANBAN'])
                            } else {
                              setFieldValue('types', values.types.filter(type => type !== 'KANBAN'))
                            }
                          }}
                          name="KANBAN"
                          disabled={values.types.includes('CARTEIRA')}
                        />
                      }
                      label="Kanban"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.types.includes('CARTEIRA')}
                          onChange={(event) => {
                            setFieldValue(
                              'types',
                              handleTypes(event, 'CARTEIRA', values.types)
                            )
                            if (event.target.checked) {
                              setFieldValue('types', ['CARTEIRA'])
                            } else {
                              setFieldValue('types', values.types.filter(type => type !== 'CARTEIRA'))
                            }
                          }}
                          name="CARTEIRA"
                          disabled={values.types.includes('KANBAN')}
                        />
                      }
                      label="Carteira"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl component="fieldset" variant="standard" fullWidth>
                  <FormLabel component="legend">Cliente à vista</FormLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.inCash}
                        onChange={() => setFieldValue('inCash', !values.inCash)}
                      />
                    }
                    label={
                      <Typography color={values.inCash ? 'primary' : 'inherit'}>
                        {values.inCash ? 'Sim' : 'Não'}
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl component="fieldset" variant="standard" fullWidth>
                  <FormLabel component="legend">Lote Piloto</FormLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.pilotLot}
                        onChange={() =>
                          setFieldValue('pilotLot', !values.pilotLot)
                        }
                      />
                    }
                    label={
                      <Typography
                        color={values.pilotLot ? 'primary' : 'inherit'}
                      >
                        {values.pilotLot ? 'Sim' : 'Não'}
                      </Typography>
                    }
                  />
                </FormControl>

                {values.pilotLot && (
                  <TextField
                    label="Valor mínimo de compra"
                    name="minimumPurchaseAmount"
                    value={values.minimumPurchaseAmount}
                    variant="outlined"
                    onChange={(e) =>
                      setFieldValue('minimumPurchaseAmount', e.target.value)
                    }
                  />
                )}
              </Grid>
            </Grid>
            <Stack
              direction="row"
              spacing={3}
              paddingTop={4}
              justifyContent="flex-end"
            >
              <Button
                type="button"
                mold="red"
                onClick={close}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                mold="primary"
                type="submit"
                disabled={loading}
                loading={loading}
              >
                Salvar
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  )
}
