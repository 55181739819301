import { Box, CssBaseline } from '@mui/material'
import { Can } from 'components/Permission/Can'
import CardView from 'components/view/CardView'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { _addReportLoad } from './reportConsolidated/slices/load.slice'

const Report: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(_addReportLoad(false))
  }, [])

  localStorage.removeItem('indiceRef')
  localStorage.removeItem('dataOR')
  localStorage.removeItem('changeOR')
  localStorage.removeItem('changeRefugo')

  return (
    <Can module={MODULES.RELATÓRIOS} permissions={[PERMISSIONS.READ]}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        <h4>Selecione o relatório que deseja visualizar</h4>
        <CardView title='Dashboard' rote='/home/report/dashboard' />
        {/* <CardView title='Mapa de entrega' rote='/home/report/deliveryMap' /> */}
        <CardView title='Realizado Dia' rote='/home/report/realizedDay' />
        <CardView title='Faturamento R$ Consolidado' rote='/home/report/consolidated' />
        <CardView title='Volume x R$Kg' rote='/home/report/volume' />
        <CardView title='Mensal' rote='/home/report/billing/monthly' />
        {/* <CardView title='Metas de Produção' rote='/home/report/productionGoals' /> */}
        <CardView title='Orçamento Vs Realizado' rote='/home/report/reportBudget' />
        <CardView title='Relatório de Vendedor' rote='/home/report/reportSeller' />
        {/* <CardView title='Propostas para a Controladoria' rote='/home/report/proposalsForDirection' /> */}
        {/* <CardView title='Solicitações de propostas' rote='/home/report/requestForProposals' /> */}
        <CardView title='Relatório de Cliente' rote='/home/report/sales-report' />
        <CardView title='Relatório Estoque' rote='/home/report/stock' />
      </Box>
    </Can>
  )
}

export default Report
