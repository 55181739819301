/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import { Box, Button, Stack, TextField } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'

import styled from 'styled-components'
// import FTDevTablePaper from './FTDevTablePaper'
import FTDevTablePlates from './FTDevTablePlates'
import FTDevTableBox from './FTDevTableBox'
import FTHeader from 'pages/ft/components/FTHeader'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import { Close } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: black;
  cursor: pointer;
  font-size: 0.815rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  min-width: 85px;
  padding: 12px 16px;
  margin: 6px 6px;
  border: 1px solid #eee;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:focus {
    border-radius: 0px;
    outline: 2px solid white;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #4060e3;
    color: white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: white;
  border-radius: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: space-between;
`

function DevFT(props: any) {
  const { search: searchLocation } = useLocation()
  console.log(searchLocation)
  const type = searchLocation
    ?.replace('?', '')
    ?.split('&')
    ?.find((item) => item.includes('type'))
    ?.replace('type=', '')
  console.log(type)

  const [tab, setTab] = useState<string | number>(
    type?.includes('box') ? 1 : type?.includes('plate') ? 0 : 2
  )
  const [search, setSearch] = React.useState('')
  const [date, setDate] = React.useState<string | null>(null)

  const handleSearch = (query) => {
    console.log('search', query)
    setSearch(query)
  }
  return (
    <div>
      <TabsUnstyled value={tab} onChange={(_, value) => setTab(value)}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: 3 }}>
          <TabsList>
            {/* <Tab style={{ width: 190 }}>Bobinas & Simplex</Tab> */}
            <Tab style={{ width: 80 }}>Chapas</Tab>
            <Tab style={{ width: 70 }}>Caixas</Tab>
          </TabsList>
          {Number(tab) < 2 && (
            <>
              <FTHeader search={handleSearch} />

              <Stack marginLeft={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DatePicker
                    views={['year', 'month', 'day']}
                    label="Filtrar por data"
                    inputFormat="dd/MM/yyyy"
                    value={date}
                    onChange={(value) => {
                      console.log('date', value)
                      setDate(value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          startAdornment: (
                            <Box paddingRight={2}>
                              {params?.InputProps?.endAdornment}
                            </Box>
                          ),
                          endAdornment: (
                            <Box>
                              {date && (
                                <Button
                                  size="small"
                                  onClick={() => setDate(null)}
                                >
                                  <Close fontSize="small" />
                                </Button>
                              )}
                            </Box>
                          )
                        }}
                        className="w-100"
                        variant="standard"
                        autoComplete="off"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </>
          )}
        </Box>
        {/* <TabPanel value={0}><FTDevTablePaper /></TabPanel> */}
        <TabPanel value={0}>
          <FTDevTablePlates search={search} date={date} />
        </TabPanel>
        <TabPanel value={1}>
          <FTDevTableBox search={search} date={date} />
        </TabPanel>
      </TabsUnstyled>
    </div>
  )
}

export default DevFT
