import { httpClient } from 'config/httpClient'
import PageableType from 'types/PageableType'
import FormUsertype from 'types/user/FormUserType'
import MyAccountType from 'types/user/MyAccountType'
import UserType from 'types/user/UserType'

export const createUser = async (dadosForm: FormUsertype) => {
  const headers = {
    'Content-Type': 'multpart/form-data'
  }
  return await httpClient.post('/users', dadosForm, { headers })
}

export const getUser = async (offset = 1): Promise<PageableType<UserType>> => {
  const { data } = await httpClient.get(`/users?offset=${offset}&limit=${10}`)
  return data as PageableType<UserType>
}

export const searchUser = async (param: string, offset = 1): Promise<PageableType<UserType>> => {
  const { data } = await httpClient.get(`/users/search?query=${param}&offset=${1}&limit=${10}`)
  return data as PageableType<UserType>
}

export const updateStatusUser = async (id: string, status: boolean) => {
  if (!status) {
    return await httpClient.put(`/users/active/${id}`)
  } else {
    return await httpClient.put(`/users/inactive/${id}`)
  }
}

export const updateUser = async (id: string, dadosForm: any) => {
  const headers = {
    'Content-Type': 'application/json'
  }
  return await httpClient.put(`/users/${id}`, dadosForm, { headers })
}

export const myAccount = async (email: string): Promise<MyAccountType> => {
  const { data } = await httpClient.get(`/users/email?param=${email}`)
  return data as unknown as MyAccountType
}
