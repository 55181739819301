import { httpClient } from 'config/httpClient'
import { useQuery } from 'react-query'

const getDeliveryMapToday = async ({ page, offset, limit, search, startDate, endDate }) => {
  const { data } = await httpClient.get('/ftsByDeliveryMap/delivery-map-today', {
    params: {
      page,
      offset,
      limit,
      search,
      startDate,
      endDate
    }
  })

  return {
    response: data.response,
    totalCount: data.totalCount
  }
}

export const useDeliveryMapToday = (params) => {
  return useQuery(
    ['delivery-map-today', params],
    async () => await getDeliveryMapToday(params),
    {
      refetchOnWindowFocus: false
    }
  )
}
