import { Breadcrumbs, Typography, Link } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import TitleAndContent from './TitleAndContent'

const LabelBold = styled.div`
  font-weight: bold;
`

function EditContent() {
  const navigate = useNavigate()

  return (
    <div>
      <Breadcrumbs>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => { navigate('/home/company') }}>
          Empresa
        </Link>
        <Typography color="text.primary">Quem somos</Typography>
      </Breadcrumbs>
      <LabelBold style={{ marginTop: '10px' }}>Edite as Informações</LabelBold>
      {/* <TextField style={{ width: '50%' }} id="standard-basic" label="Título" variant="standard" />
      <ImportantView style={{ marginTop: '20px' }} title="Importante" content="Importante Este título irá aparecer na lista externa, o título deve conter um máximo de caracteres: 20" /> */}
      <TitleAndContent editable={true} />
    </div >
  )
}

export default EditContent
