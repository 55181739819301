/* eslint-disable @typescript-eslint/no-unused-vars */
import { Autocomplete } from '@mui/lab'
import {
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography
} from '@mui/material'
import Stack from '@mui/material/Stack/Stack'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import { useState } from 'react'
import { toast } from 'react-toastify'
import {
  getCollaborators
} from 'services/customerControl/CustomerControlServices'
import { Tab, TabPanel, TabsList } from './styles/purchaseRequests.styles'
import ItemsCoil from './projectsAndItems/ItemsCoil'
import FTsProject from './projectsAndItems/FTsProject'
import ItemsRecurring from './projectsAndItems/ItemsRecurring'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import { SearchOutlined } from '@mui/icons-material'
import AutocompleteClient from './components/AutocompleteClient'

export const ListProjectsAndItems = () => {
  // const { loginData } = useSelector((state: RootState) => state.login)

  // const [clients, setClients] = useState<any[]>([])
  const [collaborators, setCollaborators] = useState<any[]>([])
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null)
  const [selectedUser, setSelectedUserCollaborator] = useState<string | null>(
    null
  )
  const [search, setSearch] = useState('')

  const [isFetchingCollaborators, setIsFetchingCollaborators] =
    useState<boolean>(false)

  // const { isFetching, refetch } = useQuery(
  //   'clients-projects-and-items',
  //   async () => {
  //     // meta
  //     // return getClientBySellerForRequest('6331f3d3054d009cda81ba38')
  //     return getClientBySellerForRequest(loginData?.tokenData?.sub)
  //   },
  //   {
  //     onSuccess(data: any[]) {
  //       setClients(
  //         data?.map((client) => ({
  //           _id: client._id,
  //           name: client.name,
  //           isBlocked: client?.isBlocked,
  //           isActive: client?.isActive
  //         }))
  //       )
  //     },
  //     refetchOnWindowFocus: false,
  //     cacheTime: 1000 * 60 * 5
  //   }
  // )

  const handleSelectCompany = async (companyId) => {
    if (!companyId) {
      return
    }

    setSelectedCompany(companyId)
    setIsFetchingCollaborators(true)
    try {
      const { data } = await getCollaborators(companyId)
      setCollaborators(data)
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    } finally {
      setIsFetchingCollaborators(false)
    }
  }

  const handleSearch = (query) => {
    console.log('search', query)
    setSearch(query)
  }
  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Pedido de Venda', url: '/home/purchaseRequests' },
          { text: 'Novo Pedido' }
        ]}
      />
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ marginBottom: 3, marginTop: 2 }}
      >
        Novo Pedido
      </Typography>

          <AutocompleteClient
            onChange={(value) => {
              setSelectedCompany(null)
              handleSelectCompany(value?._id)
              setSelectedUserCollaborator(null)
            }}>
              <>

              </>
              {!!selectedCompany && (
                  <Autocomplete
                    noOptionsText='Sem opções'
                    loading={isFetchingCollaborators}
                    disabled={!selectedCompany}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    getOptionLabel={(option) => option.name}
                    id="combo-box-collaborators"
                    options={collaborators.map((row) => ({
                      ...row,
                      label: row.name
                    }))}
                    onChange={(_, value: any | null) => {
                      setSelectedUserCollaborator(value?.user?._id)
                      // props.onChange(value!)
                      // setSelectedValue(value!)
                    }}
                    getOptionDisabled={(option) => !option.isActive}
                    // defaultValue={props.currentValue}
                    // value={selectedValue}
                    // inputValue={search}
                    // onInputChange={(event, newInputValue) => {
                    //   setSearch(newInputValue)
                    // }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label="Selecionar representante"
                      />
                    )}
                  />
              )}
            </AutocompleteClient>

      {selectedCompany && selectedUser && (
        <Stack sx={{ marginTop: 3 }}>
          <TabsUnstyled defaultValue={0}>
            <Stack
              spacing={2}
              direction="row"
              sx={{ width: '100%', display: 'flex', alignItems: 'flex-end' }}
            >
              <TabsList>
                {/* <Tab>Lote piloto</Tab> */}
                <Tab>Bobina</Tab>
                {/* <Tab>Produção</Tab> */}
                <Tab>Projetos</Tab>
                <Tab>Recorrentes</Tab>
              </TabsList>

              <FormControl variant="standard">
                <InputLabel htmlFor="input-with-icon-adornment">
                  Pesquisar
                </InputLabel>
                <Input
                  style={{ width: 250 }}
                  id="input-with-icon-adornment"
                  onChange={(event) => {
                    handleSearch(event.target.value)
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchOutlined />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Stack>

            <TabPanel value={0}>
              <ItemsCoil
                search={search}
                companyId={selectedCompany}
                collaboratorId={selectedUser}
              />
            </TabPanel>
            {/* <TabPanel value={1}>
              <FTsProduction
                companyId={selectedCompany}
                userId={selectedUser}
              />
            </TabPanel> */}
            <TabPanel value={1}>
              <FTsProject
                search={search}
                companyId={selectedCompany}
                userId={selectedUser}
              />
            </TabPanel>
            <TabPanel value={2}>
              <ItemsRecurring
                search={search}
                companyId={selectedCompany}
                userId={selectedUser}
              />
            </TabPanel>
          </TabsUnstyled>
        </Stack>
      )}
    </>
  )
}
