import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
// import Typography from '@mui/material/Typography'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ReportVolumeMes } from 'types/Report/ReportVolume'
import { Box, Button, CircularProgress, Icon, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import moment from 'moment'
import { getReportConsolidated, getReportDevolutionMonthly } from 'services/report/ReportConsolidated'
import { toBRL } from 'utils/formatString'
import { getReportOthersMensal } from 'services/report/ReportBilling'
import { TipoDevolucao, TipoProduto } from 'utils/enums'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E8E8E8'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    boxShadow: '0px 1px 0px #E8E8E8'
  },

  '&:nth-child(even)': {
    backgroundColor: '#FAFAFA !important'
  }
}))

interface Props {
  date: string
}
const TableTotalProduct: React.FC<Props> = (props) => {
  const [dados, setDados] = React.useState<any>([])
  const [outros, setOutros] = React.useState<any>([])
  const [meses, setMeses] = React.useState<ReportVolumeMes[]>([])
  const [dadosArray, setDadosArray] = React.useState<any>([])
  const mes = moment(props.date).format('M')
  const date = moment(props.date).format('DD/MM/YYYY')
  const navigate = useNavigate()

  const totalProductsReq = useQuery(['total-product', date],
    async () => {
      return getReportConsolidated(date)
    },
    {
      onSuccess: (data) => {
        devolutionsReq.refetch()
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const devolutionsReq = useQuery(['total-product-devolution', date],
    async () => {
      return getReportDevolutionMonthly(date)
    },
    {
      onSuccess: (data) => {
        handleValorTable(totalProductsReq.data.response, data.response)
        handlePeriodo()
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const othersReq = useQuery(['total-others-monthly', date],
    async () => {
      const ano = moment(date, 'DD/MM/YYYY').format('YYYY')
      return getReportOthersMensal(ano)
    },
    {
      onSuccess: (data) => {
        console.log({ outrosMensal: data })
        const rowsOutros: any = []
        Object.keys(data).forEach(key => {
          if (isNaN(Number(key))) {
            return
          }
          rowsOutros.push({
            type: '0004',
            periodo: key,
            kg: 0,
            rs: data[key],
            rskg: 0
          })
        })

        setOutros(rowsOutros)
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const handleValorTable = (valorData: any, valorDevolucao: any) => {
    const valor: any[] = [
      {
        text: 'Caixas',
        type: TipoProduto.CAIXA,
        ingoneType: false
      },
      {
        text: 'Chapas',
        type: TipoProduto.CHAPA,
        ingoneType: false
      },
      {
        text: 'Papel',
        type: TipoProduto.PAPEL,
        ingoneType: false
      },
      {
        text: 'Outros',
        type: TipoProduto.OUTROS,
        ingoneType: false
      },
      {
        text: 'Devoluções caixa',
        type: TipoDevolucao.CAIXA,
        ingoneType: false
      },
      {
        text: 'Devoluções chapa',
        type: TipoDevolucao.CHAPA,
        ingoneType: false
      },
      {
        text: 'Devoluções papeis',
        type: TipoDevolucao.PAPEL,
        ingoneType: false
      },
      {
        text: 'Total',
        type: 'total',
        ingoneType: true
      }
    ]
    setDados(valor)

    const listaDados: any = []
    valorData.forEach((element) => {
      listaDados.push(...element.dados)
    })
    console.log('lista dados', listaDados)
    setDadosArray([...listaDados, ...outros])
  }

  const handlePeriodo = () => {
    const mesesValue = [
      {
        text: 'Janeiro',
        value: 1
      },
      {
        text: 'Fevereiro',
        value: 2
      },
      {
        text: 'Março',
        value: 3
      },
      {
        text: 'Abril',
        value: 4
      },
      {
        text: 'Maio',
        value: 5
      },
      {
        text: 'Junho',
        value: 6
      },
      {
        text: 'Julho',
        value: 7
      },
      {
        text: 'Agosto',
        value: 8
      },
      {
        text: 'Setembro',
        value: 9
      },
      {
        text: 'Outubro',
        value: 10
      },
      {
        text: 'Novembro',
        value: 11
      },
      {
        text: 'Dezembro',
        value: 12
      }
    ]
    const meses: any[] = []
    let i = 0
    while (i < Number(mes)) {
      meses.push(mesesValue[i])
      i++
    }
    setMeses(meses)
  }

  const getSumByPeriod = (type: any, periodo: any, ingoneType: boolean) => {
    const resultFilter = dadosArray.filter((item) => Number(item.periodo) === periodo && (item.type === type || ingoneType))
    return resultFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.rs)
    }, 0)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getTotalProduct = (row: any, type: any, ingoneType: boolean) => {
    const dadosFilter = dadosArray.filter((item) => (item.type === type || ingoneType))
    return dadosFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.rs)
    }, 0)
  }

  const getTotalDevolutionByMonth = (month, type) => {
    // eslint-disable-next-line eqeqeq, @typescript-eslint/restrict-plus-operands
    const ok = devolutionsReq.data.response.filter(f => f.nfmonth == month && (f.categ + 'd') == type)
      .reduce((a, b) => ({ preco: (parseFloat(a.preco ?? 0) + parseFloat(b.preco ?? 0)) }), 0)
    return ok
  }

  const getTotalDevolution = (type) => {
    // eslint-disable-next-line eqeqeq, @typescript-eslint/restrict-plus-operands
    const ok = devolutionsReq.data.response.filter(f => (f.categ + 'd') == type)
      .reduce((a, b) => ({ preco: (parseFloat(a.preco ?? 0) + parseFloat(b.preco ?? 0)) }), 0)
    return ok
  }

  return (
    <>
      {(totalProductsReq.isLoading || totalProductsReq.isFetching || devolutionsReq.isFetching || othersReq.isLoading) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <CircularProgress />
        </Box>
      )}
      {(totalProductsReq.isError || devolutionsReq.isError) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
        </Box>
      )}

      {(totalProductsReq.isSuccess && !totalProductsReq.isError && !totalProductsReq.isFetching && !devolutionsReq.isFetching) && (
        <>
          <Paper sx={{ width: '80vw' }}>
            <TableContainer sx={{ minWidth: '100%' }}>
              <Table sx={{ maxWidth: '100%' }} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left" sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 3,
                      background: '#fafafa !important'
                    }}>
                      Fat. p/ produto
                    </StyledTableCell>
                    {meses.map((mes) => (
                      <StyledTableCell align="left" colSpan={1}>
                        {mes.text}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell align="left">
                      Total
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dados
                    .map((row) => {
                      let valorTotal
                      if (row.type === 'total') {
                        valorTotal = getTotalProduct(row, row.type, row.ingoneType) -
                          Number(getTotalDevolution(TipoDevolucao.CAIXA).preco ?? 0) -
                          Number(getTotalDevolution(TipoDevolucao.CHAPA).preco ?? 0) -
                          Number(getTotalDevolution(TipoDevolucao.PAPEL).preco ?? 0)
                      } else {
                        valorTotal = getTotalProduct(row, row.type, row.ingoneType)
                      }

                      if (row.type.includes('d')) {
                        return (
                          <StyledTableRow role="checkbox" tabIndex={-1} key={row.name} style={{ backgroundColor: row.text === 'Total' ? '#EBEBEB' : undefined }}>
                            <StyledTableCell component="th" key='nome_cliente' align={'left'} sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 2,
                              background: '#FFF'
                            }}>
                              <Typography variant='body2' component='span' fontWeight="bold" >
                                {row.text}
                              </Typography>
                            </StyledTableCell>
                            {meses.map((mes) => {
                              return (
                                <>
                                  <StyledTableCell key={mes.text} align={'left'}>
                                    <Typography variant='body2' noWrap>
                                      {toBRL(-1 * getTotalDevolutionByMonth(mes.value, row.type).preco ?? 0, { noBRL: true })}
                                    </Typography>
                                  </StyledTableCell>
                                </>
                              )
                            })}
                            <StyledTableCell align={'center'}>
                              <Typography
                                component="span"
                                noWrap
                                variant="body2"
                              >
                                {toBRL(-1 * getTotalDevolution(row.type).preco ?? 0, { noBRL: true })}
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      } else {
                        return (
                          <StyledTableRow role="checkbox" tabIndex={-1} key={row.name} style={{ backgroundColor: row.text === 'Total' ? '#EBEBEB' : undefined }}>
                            <StyledTableCell component="th" key='nome_cliente' align={'left'} sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 2,
                              background: '#FFF'
                            }}>
                              <Typography variant='body2' component='span' fontWeight="bold">
                                {row.text}
                              </Typography>
                            </StyledTableCell>
                            {meses.map((mes) => {
                              let result
                              if (row.type === 'total') {
                                result = Number(getSumByPeriod(row.type, mes.value, row.ingoneType)) -
                                  Number(getTotalDevolutionByMonth(mes.value, TipoDevolucao.CAIXA).preco ?? 0) -
                                  Number(getTotalDevolutionByMonth(mes.value, TipoDevolucao.CHAPA).preco ?? 0) -
                                  Number(getTotalDevolutionByMonth(mes.value, TipoDevolucao.PAPEL).preco ?? 0)
                              } else {
                                result = Number(getSumByPeriod(row.type, mes.value, row.ingoneType))
                              }
                              return (
                                <>
                                  <StyledTableCell key={mes.text} align={'left'}>
                                    <Typography variant='body2' noWrap>
                                      {toBRL(result, { noBRL: true })}
                                    </Typography>
                                  </StyledTableCell>
                                </>
                              )
                            })}
                            <StyledTableCell align={'center'}>
                              <Typography
                                component="span"
                                noWrap
                                variant="body2"
                              >
                                {toBRL(valorTotal, { noBRL: true })}
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              style={{
                backgroundColor: '#4060E6',
                borderRadius: '2px',
                width: '122px',
                color: 'white'
              }}
              onClick={() => navigate('/home/report')}
            >
              Voltar
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default TableTotalProduct
