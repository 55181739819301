import React from 'react'
import { Pie, PieChart, Tooltip } from 'recharts'
import styled from 'styled-components'
import ChartBottomLabel from './ChartBottomLabel'

const RowCV = styled.div`
display: flex;
align-items: center;
justify-content: center;
`
const LabelTitle = styled.div`
 display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`
const CustomTooltip = ({ active, title, payload, event }) => {
  if (active && payload && payload.length) {
    const TooltipContent = styled.div`
    background-color: white;
    padding: 5px;
    border: 1px solid ${payload[0].payload.fill};
    border-radius: 5px;
    `
    return (
      <TooltipContent>
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>{`${title}`}</p>
        <p style={{ fontSize: 12 }}>{`${payload[0].value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`}</p>
      </TooltipContent>
    )
  }

  return null
}

function ChartPieAndPie(props: any) {
  return (
    <div className="app">
      <LabelTitle>
        <p>{props.title}</p>
        <p style={{ fontSize: '11px' }}>Mês {props.month}</p>
      </LabelTitle>
      <RowCV>
        <PieChart width={240} height={240} margin={{ top: 25, right: 25, left: 25, bottom: 25 }}>
          <Tooltip content={event => <CustomTooltip active={event.active} title={props.title} payload={event.payload} event={event} />} />
          <Pie data={props.data} dataKey="value" nameKey="title" cx="50%" cy="50%" innerRadius={50} outerRadius={90} />
        </PieChart>

        {/* <PieChart width={170} height={300} margin={{ top: 25, right: 0, left: 10, bottom: 25 }}>
          <Pie data={props.data} dataKey="value" nameKey="title" cx="50%" cy="50%" innerRadius={50} outerRadius={70} label />
        </PieChart> */}

        {/* <PieChart width={200} height={300} margin={{ top: 25, right: 0, left: 65, bottom: 25 }}>
          <Pie data={[props.data[0]]} dataKey="value" nameKey="title" cx="50%" cy="50%" innerRadius={0} outerRadius={50} label />
        </PieChart> */}
      </RowCV>
      <RowCV>
        {props.data.map(d => (
          <ChartBottomLabel title={d.title} color={d.fill} />
        ))}
      </RowCV>

    </div>
  )
}

export default ChartPieAndPie
