import { AssignmentTurnedIn } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Icon,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import GenericDialog from 'components/genericDialog/GenericDialog'
import MoreMenu from 'components/view/MoreMenu'
import StatusViewFT from 'components/view/StatusViewFT'
import { ActionButton } from 'components/view/styles/style-more-menu'
import moment from 'moment-timezone'
import React from 'react'
import { useQuery } from 'react-query'
import { getPreFTS } from 'services/ft/FTService'
import { ModalEditPreFTPortfolioPlate } from '../components/ModalEditPreFTPortfolioPlate'
import FTAuditPlate from './FTAuditPlate'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

function FTPlateTable(props: any) {
  const [openModalEdit, setOpenModalEdit] = React.useState<boolean>(false)
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const [formData, setFormData] = React.useState<any[]>([])
  const [originalData, setOriginalData] = React.useState<any[]>([])
  const [formDataEdit, setFormDataEdit] = React.useState<any>()
  const [rows, setData] = React.useState<any[]>([])
  const [page, setPage] = React.useState(0)

  const preFtReq = useQuery(
    ['preft', null],
    async () => {
      return getPreFTS('CHAPA')
    },
    {
      onSuccess: (data) => {
        console.log('data?', data)
        setData(data)
        setOriginalData(data)
      },
      refetchOnWindowFocus: false,
      cacheTime: 5000
    }
  )

  React.useEffect(() => {
    if (props.search.length > 0) {
      const result = originalData.filter((item) => {
        const filterSearch =
          String(item._id).includes(props.search) ||
          item?.client?.name
            ?.toUpperCase()
            ?.includes(props.search?.toUpperCase()) ||
          item?.collaborator?.name
            ?.toUpperCase()
            ?.includes(props.search?.toUpperCase())

        if (props.date) {
          return (
            filterSearch &&
            moment(props.date).format('YYYY-MM-DD') ===
              moment(item.createdAt).format('YYYY-MM-DD')
          )
        }

        return filterSearch
      })
      setData(result)
    } else if (props.date) {
      setPage(0)
      const result = originalData.filter((item) => {
        return (
          moment(props.date).format('YYYY-MM-DD') ===
          moment(item.createdAt).format('YYYY-MM-DD')
        )
      })
      setData(result ?? [])
    } else {
      setData(originalData)
    }
  }, [props.search, props.date])

  const handleCloseDialog = () => {
    setOpenDialog(false)
    preFtReq.refetch()
  }

  const handleOpenDialog = (data) => {
    setFormData(data)
    setOpenDialog(true)
  }

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false)
  }

  const handleCloseModalEditSuccess = () => {
    setOpenModalEdit(false)
    preFtReq.refetch()
  }

  const handleOpenModalEdit = (data) => {
    setFormDataEdit(data)
    setOpenModalEdit(true)
  }

  React.useEffect(() => {
    console.log('data', preFtReq.data)
    return () => {}
  }, [preFtReq.isFetching])

  if (preFtReq.isFetching) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (preFtReq.isError) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }

  return (
    <div>
      <TableContainer style={{ marginTop: '30px' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Número <br />PRÉ FT</StyledTableCell>
              <StyledTableCell align="left">Descrição</StyledTableCell>
              <StyledTableCell align="left">Emitido em</StyledTableCell>
              {/* <StyledTableCell align='left'>CNPJ / CPF Rural</StyledTableCell> */}
              <StyledTableCell align="left">Nome da Empresa</StyledTableCell>
              <StyledTableCell align="left">
                Nome do Representante
              </StyledTableCell>
              <StyledTableCell align="left">Amostra</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Auditar</StyledTableCell>
              <StyledTableCell align="left">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.slice(page * 5, page * 5 + 5)?.map((data) => (
              <StyledTableRow key={data._id}>
                <StyledTableCell align="left">
                  {data._id.substring(0, 8)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data?.preftplate?.Referencia}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {moment(data.createdAt).format('DD/MM/YYYY')}
                </StyledTableCell>
                {/* <StyledTableCell align='left'>{data.client?.pf?.cpf ?? ''}</StyledTableCell> */}
                <StyledTableCell align="left">
                  {data.client?.name ?? ''}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data.collaborator?.name ?? ''}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {data.preftplate?.needSample ? 'Sim' : 'Não'}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <StatusViewFT status={data.status} />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <ActionButton
                    aria-label="audit"
                    onClick={() => handleOpenDialog(data)}
                  >
                    <AssignmentTurnedIn />
                  </ActionButton>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <MoreMenu
                    audit={() => handleOpenDialog(data)}
                    edit={
                      data.status !== 'APROVADO'
                        ? () => handleOpenModalEdit(data)
                        : undefined
                    }
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5]}
          rowsPerPage={5}
          component="div"
          count={rows?.length}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      </TableContainer>

      {openDialog && (
        <GenericDialog
          widthRoot={1150}
          style={{ minHeight: '400px', minWidth: '1100px', maxWidth: 'none' }}
          open={openDialog}
          close={() => setOpenDialog(false)}
          content={
            <FTAuditPlate form={formData} closeDialog={handleCloseDialog} />
          }
        />
      )}

      {formDataEdit?._id && openModalEdit && (
        <ModalEditPreFTPortfolioPlate
          form={formDataEdit}
          openModal={openModalEdit}
          handleCloseModal={handleCloseModalEdit}
          handleCloseModalSuccess={handleCloseModalEditSuccess}
        />
      )}
    </div>
  )
}

export default FTPlateTable
