import React from 'react'
import { Box, Breadcrumbs, CssBaseline, Link, Tab, TextField, Typography } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import { DatePicker, LocalizationProvider, TabContext, TabList, TabPanel } from '@mui/lab'
import { TabStyled, TabPanelStyled, TabsListStyled } from './modulesPedidosKg.styles'
import { useNavigate } from 'react-router-dom'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import SubtotalView from './components/SubtotalView'
import TotalView from './components/TotalView'
import moment from 'moment'
import { ptBR } from 'date-fns/locale'
import PedidosKgView from './components/PedidosKgView'

const ReportMonthly: React.FC = () => {
  const navigate = useNavigate()

  const [date, setDate] = React.useState<Date | null>(new Date())
  const [value, setValue] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <div>
      <CssBaseline />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="text.primary"
              onClick={() => {
                navigate('/home/report')
              }}
            >
              Relatórios
            </Link>
            <Typography>Mensal</Typography>
          </Breadcrumbs>
          <h4>Relatório Mensal: {moment(date).format('MMMM YYYY')}</h4>
        </div>
        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              views={['month', 'year']}
              label="Mês e Ano"
              value={date}
              onChange={(newValue) => {
                setDate(newValue)
              }}
              renderInput={(params) => <TextField {...params}
                autoComplete='off' helperText={null} />}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Subtotal" value="1" />
              <Tab label="Total" value="2" />
              <Tab label="Pedidos em Kg" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1"><SubtotalView date={date} /></TabPanel>
          <TabPanel value="2"><TotalView date={date} /></TabPanel>
          <TabPanel value="3">
            <TabsUnstyled defaultValue={value ? Number(value) : 0}>
              <Box>
                <TabsListStyled>
                  <TabStyled>Papeis</TabStyled>
                  <TabStyled>Chapas</TabStyled>
                  <TabStyled>Caixas</TabStyled>
                  <TabStyled>Totais</TabStyled>
                </TabsListStyled>
              </Box>
              <TabPanelStyled value={0}>
                <PedidosKgView date={date} type={'PAPEL'} />
              </TabPanelStyled>
              <TabPanelStyled value={1}>
                <PedidosKgView date={date} type={'CHAPA'} />
              </TabPanelStyled>
              <TabPanelStyled value={2}>
                <PedidosKgView date={date} type={'CAIXA'} />
              </TabPanelStyled>
              <TabPanelStyled value={3}>
                <PedidosKgView date={date} type={'TOTAIS'} />
              </TabPanelStyled>
            </TabsUnstyled>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  )
}

export default ReportMonthly
