/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SectionTab, SectionTabPanel, SectionTabsList } from '../../styles'
import { BoxReplacement } from './BoxReplacement'
import { CoilAndSimplex } from './CoilAndSimplex'
import { Kanban } from './Kanban'
import { PilotBatch } from './PilotBatch'
import { Plates } from './Plates'
import { Wallet } from './Wallet'

const tabs = [
  'Lote piloto',
  'Kanban',
  'Carteira',
  'Bobinas & Simplex',
  'Chapas',
  'Reposição de caixa'
]

interface Props {
  valeu?: number
}

export const SalesOrdersModule: React.FC<Props> = () => {
  const [value] = useState(0)

  return (
    <>
      <TabsUnstyled defaultValue={value ? Number(value) : 0}>
        <Box marginTop={4}>
          <SectionTabsList>
            {tabs.map((tab) => (
              <SectionTab key={tab}>{tab}</SectionTab>
            ))}
          </SectionTabsList>
        </Box>

        <SectionTabPanel value={0} children={<PilotBatch />} />
        <SectionTabPanel value={1} children={<Kanban />} />
        <SectionTabPanel value={2} children={<Wallet />} />
        <SectionTabPanel value={3} children={<CoilAndSimplex />} />
        <SectionTabPanel value={4} children={<Plates />} />
        <SectionTabPanel value={5} children={<BoxReplacement />} />
      </TabsUnstyled>
    </>
  )
}
