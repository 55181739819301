import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import styled from 'styled-components'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'

export const DivModules = styled.div`
  margin-top: 5px;
`
export const Tab = styled(TabUnstyled)`
    font-family: IBM Plex Sans, sans-serif;
    color: black;
    cursor: pointer;
    font-size: 0.815rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    border: none;
    border-radius: 0px;
    display: flex;
    white-space: nowrap;
    text-align: center;
    justify-content: center;

    &:focus {
      border-radius: 0px;
      outline: 2px solid white;
      outline-offset: 2px;
    }

    &.${tabUnstyledClasses.selected} {
      background-color: #4060E3;
      color: white;
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `

export const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `

export const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: #FAFAFA;
    border-radius: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `
