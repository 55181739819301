import React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import { LoadingDeliveryMap } from './LoadingDeliveryMap'
import { ShowErrorDeliveryMap } from './ShowErrorDeliveryMap'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    height: 73,
    whiteSpace: 'nowrap'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: 'nowrap'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

interface Item {
  id: number
  ft: string
  client: string
  type: string
  fluxo: string
}

interface TableItemsProps {
  data: any[] | undefined
  setSearch: (search: string) => void
  loading: boolean
  error: boolean
  page: number
  setPage: (page: number) => void
  count: number
}

const transformData = (data: any[]): Item[] => {
  const fluxoOptions = [
    'AM', 'CM',
    'COL', 'COL SP', 'COL-2', 'CV', 'CVSP',
    'DIG SP',
    'GR', 'GR2', 'GR3', 'GR4', 'GR5', 'GR6',
    'IW', 'IW2', 'IW3', 'IW4', 'IW5', 'IW6',
    'LP', 'VC', 'EXP'
  ]

  return data.map((item, index) => {
    const name = item.clientDetails?.name || '-'
    const codeLn = item.codeLn || '-'
    const client = `${name} - ${codeLn}`

    return {
      id: index,
      ft: item.ftsDetails?.itemReference || '-',
      client,
      type: item.clientDetails?.typeClient || '-',
      fluxo: fluxoOptions[Math.floor(Math.random() * fluxoOptions.length)]
    }
  })
}

const TableItems: React.FC<TableItemsProps> = ({ data = [], count, setSearch, loading, error, page, setPage }) => {
  const transformedData = transformData(data)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1)
  }

  return (
    <>
      <LoadingDeliveryMap isLoading={loading} />
      <ShowErrorDeliveryMap showError={error} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Código</StyledTableCell>
              <StyledTableCell>Cliente</StyledTableCell>
              <StyledTableCell>Tipo</StyledTableCell>
              <StyledTableCell>Máquina</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {transformedData.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{item.ft}</StyledTableCell>
                <StyledTableCell>{item.client}</StyledTableCell>
                <StyledTableCell>{item.type}</StyledTableCell>
                <StyledTableCell>{item.fluxo}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={10}
          rowsPerPageOptions={[]}
        />
      </TableContainer>
    </>
  )
}

export default TableItems
