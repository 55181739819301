/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  getReportProposalForDirection
} from 'services/report/ReportProposalForDirection'
import { LoadingTable } from '../components/LoadingTable'
import { ReportProposalForDirectionTable } from '../components/ReportProposalForDirectionTable'

export const ReportProposalForDirectionContainer: React.FC = () => {
  const { data, status, refetch } = useQuery(
    'report-proposalsForDirection',
    async () =>
      getReportProposalForDirection(),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    refetch()
  }, [])

  console.log(`Refetching ${JSON.stringify(data)}`)
  console.log(`status ${JSON.stringify(status)}`)
  console.log(`refetch ${JSON.stringify(refetch)}`)
  return (
    <>
      {(status === 'loading') && <LoadingTable />}

      {status !== 'loading' && (
        <ReportProposalForDirectionTable data={data} refetch={refetch} />
      )}
    </>
  )
}
