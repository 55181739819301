/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import moment from 'moment';
import {
  ButtonRow,
  CardContentChieldren,
  CardFather,
  DialogStyle,
  DivAlingCard,
  DivAlingItensCard,
  RetangleView
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import { useFormik } from 'formik';

import IconAndTitleView from 'components/view/IconAndTitleView';
import ModalHead from './ModalHead';
import { useQuery } from 'react-query';
import { httpClient } from 'config/httpClient';
import { Accordion } from '../components/Accordion';
import { AttachFileOutlined } from '@mui/icons-material';
import ButtonPce from 'components/form/Button';
import { toast } from 'react-toastify';
import PurchaseRequest from './PurchaseRequest';

interface Props {
  open: boolean
  close: () => void
  data?: any
  bookFinished?: boolean
}

const ModalPurchaseRequest: React.FC<Props> = (props) => {
  const { bookFinished } = props

  const { data, isLoading, status } = useQuery(
    [
      'modal-booking-ModalPurchaseRequest',
      props?.data?.ft?._id ?? props?.data?.codItem,
      props.open
    ],
    async () =>
      await httpClient.get(
        `/bookings/purchase-request/pedido/${props?.data?.pedido}/${
          props?.data?.ft?._id ?? props?.data?.codItem
        }`
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  );

  console.log('ModalPurchaseRequest', data?.data);

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {status === 'success' && (
        <PurchaseRequest
          open={props.open}
          data={props.data}
          close={props.close}
          purchaseRequest={data?.data}
          bookFinished={bookFinished}
        />
      )}
    </>
  );
};

export default ModalPurchaseRequest;
