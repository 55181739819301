/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getReportPedidoCadastradoClientes } from 'services/report/ReportBilling'
import {
  getReportSeller,
  getReportSellerBilledLN
} from 'services/report/ReportSeller'
import { Header } from '../components/Header'
import { LoadingTable } from '../components/LoadingTable'
import { ProductsTabPanel } from '../components/ProductsTabPanel'
import { ResponseReportSeller } from '../types/reportSeller.type'
import { ReportSellerTableContainer } from './ProductContainer/ReportSellerTableContainer'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: any
}

export const ReportSellerContainer: React.FC<Props> = ({ date }) => {
  const [originalRows, setOriginalRows] = useState<ResponseReportSeller>()
  const currentDate = moment(date).format('MM-YYYY')
  const pedidoDate = moment(date).format('DD/MM/YYYY')

  // requisição externa do LN
  const req1 = useQuery(['pedido-cadastrado-clientes', pedidoDate],
    async () => {
      return getReportPedidoCadastradoClientes(pedidoDate)
    },
    {
      // onSuccess: (data) => {
      //   console.log('CADASTRADO CLIENTE', data)
      // },
      // onError: (error) => {
      //   console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      // },

      refetchOnWindowFocus: false
    }
  )
  // console.log('CADASTRADO CLIENTE', req1.data)

  // const { data: dataBilled, status: statusBilled, isFetching: isFetchingBilled } = useQuery(
  //   ['report-seller-ln', currentDate],
  //   async () => getReportSellerBilledLN({
  //     year: +moment(date).format('YYYY'),
  //     month: +moment(date).format('M')
  //   }),
  //   {
  //     refetchOnWindowFocus: false
  //   }
  // )

  // console.log('LN', dataBilled)

  const { data, status, refetch, isFetching } = useQuery(
    ['report-seller', '06-2022'],
    async () =>
      getReportSeller({
        year: +moment(date).format('YYYY'),
        month: +moment(date).format('M')
      }),
    {
      refetchOnWindowFocus: false
    }
  )

  console.log('PREVISIONS', data)

  useEffect(() => {
    setOriginalRows(data ?? [])
  }, [data?.length, isFetching])

  useEffect(() => {
    refetch()
  }, [currentDate])

  return (
    <>
      {(req1?.status === 'loading' || status === 'loading') && <LoadingTable />}

      {req1?.status !== 'loading' && status !== 'loading' && (
        <ProductsTabPanel
          tabs={['Papeis', 'Chapas', 'Caixas']}
          defaultTab={0}
          marginTop={2}
          tabPanel={[
            <ReportSellerTableContainer
              data={data?.bobinas ?? []}
              dataLN={req1?.data ?? []}
              isLoading={isFetching || req1?.isFetching}
              type={TipoProduto.PAPEL}
            />,
            <ReportSellerTableContainer
              data={data?.chapas ?? []}
              dataLN={req1?.data ?? []}
              isLoading={isFetching || req1?.isFetching}
              type={TipoProduto.CHAPA}
            />,
            <ReportSellerTableContainer
              data={data?.caixas ?? []}
              dataLN={req1?.data ?? []}
              isLoading={isFetching || req1?.isFetching}
              type={TipoProduto.CAIXA}
            />
          ]}
        />
      )}
    </>
  )
}
