/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Paper from '@mui/material/Paper'
import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ActionButton } from 'components/view/styles/style-more-menu'
import { Clear, SearchOutlined, Visibility } from '@mui/icons-material'
import { useQuery } from 'react-query'
import { getSellerOrderByTypePaginated } from 'services/report/DeliveryMapService'
import moment from 'moment'
import { httpClient, httpTrimbox } from 'config/httpClient'
import { toast } from 'react-toastify'
import { LoadingDeliveryMap } from '../components/LoadingDeliveryMap'
import { ShowErrorDeliveryMap } from '../components/ShowErrorDeliveryMap'
import {
  StyledTableCell,
  StyledTableRow
} from '../components/deliveryMap.style'
import { DiferenceDays } from '../components/DiferenceDays'
import { SelectPriorityDeliveryMap } from '../components/SelectPriorityDeliveryMap'
import AutocompleteOP from '../components/AutocompleteOP'
import ModalDeliveryMap from '../components/ModalDeliveryMap'
import Button from 'components/form/Button'
import { TotalWightTableRow } from '../components/TotalWightTableRow'

import * as XLSX from 'xlsx'
import { ExportExcel } from '../components/ExportExcel'
import { DaysOfDelay } from '../components/DaysOfDelay'

interface Props {
  date?: Date
  codeParceiros?: Array<{ id: string }>
}

const PilotLot: React.FC<Props> = (props) => {
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const { codeParceiros = [] } = props
  // const { codeParceiros = [{ id: '000001292' }] } = props
  const [dataValues, setDataValues] = useState<any[]>([])
  const [count, setCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const [selectData, setSelectData] = React.useState<any>()
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [search, setSearch] = useState('')

  const { data, isError, isLoading, isSuccess, isFetching, refetch } = useQuery(
    ['deliveryMap-kanban', page, search],
    async () => {
      return getSellerOrderByTypePaginated(
        page,
        moment(props.date).format('YYYY/MM/DD'),
        codeParceiros,
        search?.toUpperCase(),
        'yes'
      )
    },
    {
      onSuccess: async (data) => {
        setDataValues(data?.result ?? [])
        setCount(data?.rows ?? 0)
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const loadDeliveryMap = useCallback(() => {
    const ids = data?.result?.map((item) => item?.id) ?? []
    setLoading(true)

    httpClient
      .get(`/itemDeliveryMap?ids=${ids}`)
      .then((response) => {
        setDataValues(
          (state) =>
            data?.result?.map((item) => {
              const findItemDeliveryMap = response?.data?.find(
                (itemMap) => itemMap?.id === item?.id
              )
              console.log('findItemDeliveryMap', findItemDeliveryMap)
              if (findItemDeliveryMap) {
                return { ...item, deliveryMap: findItemDeliveryMap }
              }
              return item
            }) ?? []
        )
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dataValues])

  useEffect(() => {
    if (data?.result?.length > 0) {
      loadDeliveryMap()
    }
  }, [data?.result, isFetching])

  useEffect(() => {
    setPage(0)
    refetch()
  }, [props.date])

  const handleRefetch = () => {
    // refetch()
    loadDeliveryMap()
  }

  const handleLoadOP = async (ov: any, ordensProducao: any) => {
    console.log('Ops!', ordensProducao)
    if (ordensProducao?.length > 0) {
      return
    }

    setDataValues((state) =>
      state?.map((row) => {
        if (row?.id === ov?.id) {
          return { ...row, isLoading: true }
        }

        return row
      })
    )

    try {
      const { data } = await httpTrimbox.get(
        `/ft/orderProductionByFT?cod=${ov?.item}`
      )

      setDataValues((state) =>
        state?.map((row) => {
          if (row?.id === ov?.id) {
            return { ...row, isLoading: false, ordensProducao: data }
          }

          return row
        })
      )
    } catch (error) {
      setDataValues((state) =>
        state?.map((row) => {
          if (row?.id === ov?.id) {
            return { ...row, isLoading: false }
          }

          return row
        })
      )
      toast.error(
        error?.response?.data?.message ?? 'Erro não ao consultar OP no LN.'
      )
    }
  }

  const handleSelectOP = async (ov: any, op: any) => {
    console.log('SELECT OP', ov, op)

    setDataValues((state) =>
      state?.map((row) => {
        if (row?.id === ov?.id) {
          return {
            ...row,
            op: op,
            referencia: op?.referencia,
            isLoadingPallet: true
          }
        }

        return row
      })
    )
    try {
      // Buscar pallet
      const { data } = await httpTrimbox.get(
        `/ft/palletsAmountByOrderProduction?cod=${op?.item}&op=${op?.pedido}`
      )
      console.log('PALLET', data)
      setDataValues((state) =>
        state?.map((row) => {
          if (row?.id === ov?.id) {
            return { ...row, pallet: data, isLoadingPallet: false }
          }

          return row
        })
      )

      if (ov?.deliveryMap?.id) {
        await httpClient.put(`/itemDeliveryMap/update-item/${ov?.id}`, {
          ...ov.deliveryMap,
          production_order: op?.pedido,
          pallet_quantity: data
        })
        // handleRefetch()
        // setLoading(false)
      } else {
        await httpClient.post('/itemDeliveryMap', {
          production_order: op?.pedido,
          pallet_quantity: data,
          id: ov?.id
        })
        // handleRefetch()
      }
    } catch (error) {
      setDataValues((state) =>
        state?.map((row) => {
          if (row?.id === ov?.id) {
            return { ...row, isLoadingPallet: false }
          }

          return row
        })
      )
      toast.error(
        error?.response?.data?.message ??
          'Erro não ao consultar quantidade de Pallet no LN.'
      )
    }
  }

  const handleSelectData = (row: any) => {
    setSelectData(row)
    setOpen(true)
  }

  const handleUpdatePriority = (row: any, priority: string) => {
    if (row?.deliveryMap?.id) {
      httpClient
        .put(`/itemDeliveryMap/update-item/${row?.id}`, {
          ...row.deliveryMap,
          priority
        })
        .then(() => {
          toast.success('Dados alterado com sucesso')
          handleRefetch()
        })
        .catch((error: any) => {
          console.log('Error: ', error)
          toast.error(
            error?.response?.data?.message ?? 'Erro ao Alterar Dados'
          )
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      httpClient
        .post('/itemDeliveryMap', { priority, id: row?.id })
        .then(() => {
          toast.success('Dados registrado com sucesso')
          handleRefetch()
        })
        .catch((error: any) => {
          console.log('Error: ', error)
          toast.error(
            error?.response?.data?.message ?? 'Erro ao Registrar Dados'
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleSearch = () => {
    setSearch(searchValue)

    // setTimeout(() => {
    //   refetch()
    // }, 300)
  }

  const handleClearSearch = () => {
    setSearch('')
    setSearchValue('')

    if (inputSearchRef?.current?.value) {
      inputSearchRef.current.value = ''
    }

    // setTimeout(() => {
    //   refetch()
    // }, 300)
  }

  return (
    <>
      <Stack direction="row" spacing={2} maxWidth={'100%'} sx={{ paddingY: 2 }}>
        <TextField
          label="Pesquisar"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          inputRef={inputSearchRef}
          variant="standard"
          fullWidth
        />
        <Button
          mold="primary"
          type="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={handleSearch}
          disabled={isFetching}
        >
          <SearchOutlined />
        </Button>

        {(!!search?.length || !!inputSearchRef?.current?.value?.length) && (
          <Button
            mold="red"
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 4
            }}
            onClick={handleClearSearch}
            disabled={isFetching}
          >
            <Clear />
          </Button>
        )}
      </Stack>

      <LoadingDeliveryMap isLoading={isLoading || isFetching || loading} />

      <ShowErrorDeliveryMap showError={isError} />

      {isSuccess && !isError && !isFetching && !loading && (
        <>
          <ExportExcel fileName="kanban" dataValues={dataValues} />

          <TableContainer component={Paper} sx={{ maxHeight: 540 }}>
            <Table
              stickyHeader
              sx={{ minWidth: 700 }}
              aria-label="customized table"
              id="table-to-xls"
            >
              <TableHead>
                <TotalWightTableRow result={dataValues} />
                <TableRow>
                  {[
                    'Atraso',
                    'Prioridade',
                    'FT',
                    'Cliente',
                    'Referência',
                    'Qtd Solicitada',
                    'Qtd faturada',
                    // 'OP',
                    'OV',
                    // 'Pallet',
                    'Posição (Sequencial)',
                    'Ações'
                  ].map((item) => (
                    <StyledTableCell key={item} align="left">
                      {item}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataValues.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row?.dataEntregaOV && !row?.dataFaturamento ? (
                        <Tooltip
                          title={`Data de Entrega: ${moment(row?.dataEntregaOV).format(
                            'DD/MM/YYYY [às] HH:mm'
                          )}. Data de Faturamento: -`}
                        >
                          <span>-</span>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                      {row?.dataEntregaOV && row?.dataFaturamento ? (
                        <DaysOfDelay
                          dataEntregaOV={row?.dataEntregaOV}
                          dataFaturamento={row?.dataFaturamento}
                        />
                      ) : (
                        ''
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <SelectPriorityDeliveryMap
                        value={row?.deliveryMap?.priority ?? ''}
                        onChange={(value) => {
                          handleUpdatePriority(row, value)
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.item}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.codClient}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.reference ?? '-'}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {Number(row?.quantidadeSolicitada)?.toFixed(0)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.quantidadeFaturada ? Number(row?.quantidadeFaturada)?.toFixed(0) : '-'}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">
                      {row?.orderProducao?.length ? row?.orderProducao : '-'}
                    </StyledTableCell> */}
                    <StyledTableCell align="left">
                      {row?.linhaFaturarOV ?? '-'}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.sequencial ? Number(row?.sequencial)?.toFixed(0) : '-'}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">
                      {!row?.isLoadingPallet && (
                        <>
                          {row?.pallet ??
                            row?.deliveryMap?.pallet_quantity ??
                            '-'}
                        </>
                      )}
                      {row?.isLoadingPallet && <CircularProgress size={18} />}
                    </StyledTableCell> */}
                    <StyledTableCell align="left">
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <LightTooltip
                          title={!row?.op ? 'Selecione a OP' : 'Ver mais'}
                        >
                          <ActionButton
                            // onClick={() => {
                            //   view(row)
                            // }}
                            onClick={() => handleSelectData(row)}
                            // disabled={!row?.op && !row?.deliveryMap?.production_order}
                          >
                            <Visibility />
                          </ActionButton>
                        </LightTooltip>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20]}
            rowsPerPage={20}
            component="div"
            count={count}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />

          {open && (
            <ModalDeliveryMap
              open={open}
              data={selectData}
              close={() => setOpen(false)}
              refetch={handleRefetch}
            />
          )}
        </>
      )}
    </>
  )
}

export default PilotLot
