import { removeAcento } from '../helpers/stringHelper'
import { toast } from 'react-toastify'

const StandardFilter = (filtros: any, setDados: any, dadosOriginais: any[], callbackError: any | null = null) => {
  try {
    const valoresFiltro = Object.keys(filtros).map((item: any) => filtros[item])

    if (valoresFiltro.join('').trim()) {
      const dadosFiltrados = dadosOriginais.filter((item: any) => {
        let result = true

        Object.keys(filtros).forEach((campo: any) => {
          const valorOriginal = removeAcento(item[campo]).toLowerCase()
          const valorFiltro = removeAcento(filtros[campo]).toLowerCase()

          if (!valorOriginal.includes(valorFiltro)) {
            result = false
          }
        })
        return result
      })
      setDados(dadosFiltrados)
    } else {
      setDados(dadosOriginais)
    }
  } catch (error) {
    if (callbackError) {
      callbackError()
    } else {
      toast.info('Erro ao realizar filtro')
    }
    console.log('Error', error)
  }
}

export { StandardFilter }
