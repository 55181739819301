/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import { Box, Paper, Radio, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import alertSuccess from 'components/Alerts/ModalSucess'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import { StyledTableCell, StyledTableRow } from 'pages/company/portfolio/components/table.styles'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postProposal } from 'services/ft/FTService'
import { FTProposalType } from 'types/ft/FTProposalType'
import { validationProposalFT } from 'types/validation/ValidationProposalFT'

interface Props {
  proposalData: FTProposalType | undefined
  close: () => void
}

function ResumeViewProposal(props: Props) {
  const { proposalData } = props

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip title='Voltar' placement='top' onClick={() => { props.close() }}>
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>Proposta</div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form autoComplete="off" >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginRight: '30px',
            marginTop: '20px'
          }}>
            <div style={{ marginTop: 5 }}>Informações do orçamento que serão enviadas como proposta para o cliente aprovar ou reprovar</div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px'
                }}
              >
                <div>
                  <TableContainer style={{ marginTop: '30px' }} component={Paper}>
                    <Table sx={{ minWidth: 550 }} aria-label='customized table'>
                      <TableBody>

                        <StyledTableRow key={0}>
                          <StyledTableCell align='left'>
                            Gramatura
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard" defaultValue={proposalData?.gramature} disabled={true} />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={1}>
                          <StyledTableCell align='left'>
                            Medida interna
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard" defaultValue={proposalData?.internalMeasures} disabled={true} />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={2}>
                          <StyledTableCell align='left'>
                            Preço
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard" defaultValue={proposalData?.price} disabled={true} />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={3}>
                          <StyledTableCell align='left'>
                            Frete
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard" defaultValue={proposalData?.shipping} disabled={true} />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={4}>
                          <StyledTableCell align='left'>
                            Lote mínimo
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard" defaultValue={proposalData?.minimumLot} disabled={true} />
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* <StyledTableRow key={5}>
                          <StyledTableCell align='left'>
                            Condição de pagamento
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard" defaultValue={proposalData?.paymentCondition} disabled={true} />
                          </StyledTableCell>
                        </StyledTableRow> */}

                        <StyledTableRow key={6}>
                          <StyledTableCell align='left'>
                            Taxa de financiamento
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard" defaultValue={proposalData?.financingRate} disabled={true} />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={7}>
                          <StyledTableCell align='left'>
                            ICMS
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard" defaultValue={proposalData?.icms} disabled={true} />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={8}>
                          <StyledTableCell align='left'>
                            Validade da proposta
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" disabled variant="standard" defaultValue={proposalData?.validity} type='number'
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={9}>
                          <StyledTableCell align='left'>
                            Prazo de entrega
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField fullWidth id="standard-basic" variant="standard"
                              defaultValue={proposalData?.deadlineDeliverDays} type='number' disabled
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow key={10}>
                          <StyledTableCell style={{ width: '200px' }} align='left'>
                            Observação
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            <TextField
                              fullWidth
                              id="outlined-multiline-static"
                              autoComplete='off'
                              multiline
                              rows={5}
                              disabled
                              value={proposalData?.observation}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type='button'
              mold='primary'
              onClick={() => { props.close() }}
            >
              FECHAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default ResumeViewProposal
