import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Td } from '../tableCustomerControl.style'

interface TableHeadProps {
  title: string
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit'
}

export function TableHeadCustomer() {
  const tableHead: TableHeadProps[] = [
    // {
    //   title: 'CNPJ / CPF',
    //   align: 'left'
    // },
    {
      title: 'Código Parceiro',
      align: 'left'
    },
    {
      title: 'Nome da Empresa',
      align: 'left'
    },
    {
      title: 'Situação Cadastral',
      align: 'left'
    },
    {
      title: 'Lote Piloto',
      align: 'left'
    },
    {
      title: 'Tipo de Cliente',
      align: 'left'
    },
    // {
    //   title: 'Data de Cadastro',
    //   align: 'left'
    // },
    {
      title: 'Vendedor Vinculado',
      align: 'left'
    },
    {
      title: 'Documentos',
      align: 'left'
    },
    {
      title: 'Crédito / Limite de Crédito',
      align: 'left'
    },
    {
      title: 'Bloqueio',
      align: 'left'
    },
    {
      title: 'Ações',
      align: 'left'
    }
  ]
  return (
    <TableHead>
      <TableRow>
        {tableHead.map((item) => {
          return (
            <Td key={item.title} align={item.align}>
              {item.title}
            </Td>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
