import { Tooltip } from '@mui/material'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import styled from 'styled-components'
import { VERSION } from 'git-version-info'

const Label = styled('div')`
  display: flex;
  justify-content: center;
  align-items: end;
  color: gray;
  font-size: 10px;
  cursor: default;
`

function VersionLabel() {
  const [click, setClick] = useState(0)

  const openGit = () => {
    if (click === 2) {
      window.open(`https://github.com/creathus/pce-general-web/commit/${VERSION.sha}`, '_blank')
      setClick(0)
    } else {
      setClick(click + 1)
    }
  }

  if (VERSION.branch !== null) {
    return (
      <Label aria-label='versão' >
        <Tooltip onClick={openGit} title={`${VERSION.branch}, ${moment.tz(VERSION.committerDate, 'America/Manaus').format('DD/MM/YYYY')}`}>
          <div>Versão: {VERSION.abbreviatedSha}</div>
        </Tooltip>
      </Label>
    )
  }

  return (
    <Label aria-label='versão' >
      <div>Versão: {VERSION.abbreviatedSha}</div>
    </Label>
  )
}

export default VersionLabel
