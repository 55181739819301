/* eslint-disable @typescript-eslint/no-unused-vars */
import { Clear, PermIdentity, SearchOutlined } from '@mui/icons-material'
import {
  Box,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { Status } from 'components/Status'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  getClientsSalesReport
} from 'services/report/SalesReport'
import { Header } from './Header'
import { LoadingTable } from './LoadingTable'
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableRow
} from './table.styles'
import { TableActionButtons } from './TableActionButtons'

export const SalesReportTable: React.FC<{filterDate: Date | null, filterSearch: string}> = ({ filterDate, filterSearch }) => {
  const [select, setSelect] = useState<any>()
  const [page, setPage] = useState(0)
  const [openModal, setOpenModal] = React.useState(false)

  const { data, status, refetch, isFetching } = useQuery(
    ['report-sales-report', filterDate, filterSearch],
    async () =>
      getClientsSalesReport(
        page,
        moment(filterDate ?? new Date()).format('M'),
        moment(filterDate ?? new Date()).format('YYYY'),
        filterSearch
      ),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    setPage(0)
  }, [filterDate, filterSearch])

  return (
    <>
    <Grid container spacing={3}>
    {isFetching && (
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Box display="flex" alignItems="flex-end">
            <CircularProgress size={18} />
            <Typography
              component="span"
              variant="body2"
              sx={{ marginLeft: '8px' }}
            >
              Aguarde, carregando...
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableHeadCell align="left">
                Cod. Parceiro
              </StyledTableHeadCell>
              <StyledTableHeadCell align="left">
                Nome da Empresa
              </StyledTableHeadCell>
              <StyledTableHeadCell align="left">
                Vendedor Vinculado
              </StyledTableHeadCell>
              <StyledTableHeadCell align="left">Status</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Ações</StyledTableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!data?.docs?.length && status !== 'loading' && (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={7}>
                  Nenhum cliente encontrado.
                </StyledTableCell>
              </StyledTableRow>
            )}

            {status === 'loading' && (
              <StyledTableRow>
                <StyledTableCell colSpan={5}>
                  <LoadingTable />
                </StyledTableCell>
              </StyledTableRow>
            )}

            {data?.docs.map((row, index) => (
              <StyledTableRow key={`${row?._id}-${row?.ies?.codeLn}`}>
                <StyledTableCell align="left">
                  <Box display="flex" flexDirection="column">
                    {row?.ies?.map((item) => (
                      <Typography
                        key={item?._id}
                        component="span"
                        variant="body2"
                      >
                        {item?.codeLn}
                      </Typography>
                    ))}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography component="span" variant="body2">
                    {row?.name}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography component="span" variant="body2" noWrap>
                    {row?.seller?.user?.name}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Status title={row?.isNew ? 'NOVO' : 'NO LN'} />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <TableActionButtons
                    onView={() => {
                      setSelect(row)
                      setOpenModal(true)
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

        <TablePagination
          rowsPerPageOptions={[data?.limit ?? 0]}
          rowsPerPage={data?.limit ?? 0}
          component="div"
          count={data?.total ?? 0}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage)
          }}
        />
      {openModal && (
        <GenericDialog
          open={openModal}
          close={() => setOpenModal(false)}
          content={
            <Box>
              <Box
                borderLeft="20px solid #4060e6"
                boxShadow="0 3px 6px #00000029"
                minHeight={74}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ paddingY: 1, paddingX: 2 }}
                >
                  <PermIdentity />
                  <Typography component="span" variant="body1" marginLeft={1}>
                    Para: {select?.name}
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={3} mt={0}>
                {!select?.total?.prefts &&
                !select?.total?.fts &&
                !select?.total?.pedidos &&
                !select?.total?.ftsAtiva &&
                !select?.total?.ftsSuspensa &&
                !select?.total?.amostrasAprovada &&
                !select?.total?.amostrasReprovada
? (
                  <Grid item xs={12}>
                    <Typography textAlign={'center'}>
                      Nenhuma ação realizada.
                    </Typography>
                  </Grid>
                )
: (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        label="Quantidade solicitada (Pré-FT)"
                        value={select?.total?.prefts ?? 0}
                        type="text"
                        variant="standard"
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Quantidade FT"
                        value={select?.total?.fts ?? 0}
                        type="text"
                        variant="standard"
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Quantidade de Pedidos"
                        value={select?.total?.pedidos ?? 0}
                        type="text"
                        variant="standard"
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Quantidade FT Ativas"
                        value={select?.total?.ftsAtiva ?? 0}
                        type="text"
                        variant="standard"
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Quantidade FT Suspensas"
                        value={select?.total?.ftsSuspensa ?? 0}
                        type="text"
                        variant="standard"
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Quantidade Amostras Aprovadas"
                        value={select?.total?.amostrasAprovada ?? 0}
                        type="text"
                        variant="standard"
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Quantidade Amostras Reprovadas"
                        value={select?.total?.amostrasReprovada ?? 0}
                        type="text"
                        variant="standard"
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          }
        />
      )}
    </>
  )
}
