import React, { useState } from 'react'

import { ButtonHeader } from './styles/style-header-view'

// ICONS
import AddIcon from '@mui/icons-material/Add'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

// components
import LightTooltip from '../tooltips/LightTooltip'
import { Menu, MenuItem } from '@mui/material'
import ModalWaveType from 'pages/company/portfolio/modals/ModalWaveType'
import ModalPaperType from 'pages/company/portfolio/modals/ModalPaperType'
import ModalBoxType from 'pages/company/portfolio/modals/ModalBoxType'

interface Props {
  register?: () => void
}

const HeaderViewPortfolio: React.FC<Props> = (props) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalPaperType, setOpenModalPaperType] = useState<boolean>(false)
  const [openModalBoxType, setOpenModalBoxType] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr repeat(2, minmax(auto, max-content))',
        gap: '15px'
      }}
    >
      <LightTooltip title='Cadastrar' placement='top' onClick={props.register}>
        <ButtonHeader style={{ justifySelf: 'start' }}>
          <AddIcon />
        </ButtonHeader>
      </LightTooltip>

      <div>
        <ButtonHeader
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <SettingsOutlinedIcon />
        </ButtonHeader>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={() => { setOpenModal(true); handleClose() }}>Tipo de Onda</MenuItem>
          <MenuItem onClick={() => { setOpenModalPaperType(true); handleClose() }}>Tipo de Papel</MenuItem>
          <MenuItem onClick={() => { setOpenModalBoxType(true); handleClose() }}>Tipo de Caixa</MenuItem>
        </Menu>
      </div>

      <ModalWaveType open={openModal} close={() => setOpenModal(false)} />

      <ModalPaperType open={openModalPaperType} close={() => setOpenModalPaperType(false)} />

      <ModalBoxType open={openModalBoxType} close={() => setOpenModalBoxType(false)} />
    </div>
  )
}

export default HeaderViewPortfolio
