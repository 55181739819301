import LightTooltip from 'components/tooltips/LightTooltip'
import { ActionButton } from 'components/view/styles/style-more-menu'
import React, { useState } from 'react'
import SyncIcon from '@mui/icons-material/Sync'
import { toast } from 'react-toastify'
import { synchronizeStatusFTByCodFT } from 'services/ft/FTService'
import { Box, CircularProgress } from '@mui/material'

interface Props {
  cod: string
}

export const SynchronizeStatusFTByCodFT: React.FC<Props> = ({ cod }) => {
  const [isLoading, setLoading] = useState(false)

  const handleSynchronize = async (codFT) => {
    if (!codFT) {
      toast.warn('Necessário auditar a FT.')
      return
    }

    setLoading(true)
    try {
      await synchronizeStatusFTByCodFT(codFT)
      toast.success('Status da FT atualizado com sucesso!')
    } catch (error: any) {
      console.log('error', error)
      if (error?.response?.status === 500) {
        toast.warn(`A FT ${codFT} não existe no Mnemosine. Por isso não pode ser sincronizada.`)
      } else {
        toast.error(error?.response?.data?.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LightTooltip title="Sincronizando status..." placement="top">
            <CircularProgress size={18} />
          </LightTooltip>
        </Box>
      )}
      {!isLoading && (
        <LightTooltip title="Sincronizar status" placement="top">
          <ActionButton
            aria-label="sincronizar"
            onClick={() => {
              void handleSynchronize(cod)
            }}
          >
            <SyncIcon />
          </ActionButton>
        </LightTooltip>
      )}
    </>
  )
}
