/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { httpClient } from 'config/httpClient'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { Header } from './components/Header'
import { SolicitationContainer } from './components/SocitationContainer'

const Solicitation: React.FC = () => {
  const { loginData } = useSelector((state: RootState) => state.login)
  console.log(loginData.tokenData.sub)

  const [clients, setClients] = useState([])
  const [selectClient, setSelectClient] = useState<any>()

  const { isLoading } = useQuery(
    ['clientes-seller', loginData.tokenData.sub],
    async () => httpClient.get(`/sellers/${loginData.tokenData.sub}/clients`),
    {
      onSuccess: (response) => {
        console.log('clientes-seller', response.data)
        setClients(response.data)
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message)
      },
      refetchOnWindowFocus: false,
      cacheTime: 5000
    }
  )

  return (
    <Can
      module={MODULES.FT}
      permissions={[PERMISSIONS.READ, PERMISSIONS.UPDATE]}
      every
    >
      <>
        <Header title="Solicitações Clientes">
          <Autocomplete
            noOptionsText='Sem opções'
            disablePortal
            options={clients}
            fullWidth
            getOptionLabel={(option: any) => option.name}
            value={selectClient}
            // defaultValue={composicao?.find(
            //   (item) => item.composicao === values?.Composicao?.composicao
            // )}
            onChange={(e, item: any) => {
              console.log('client', item)
              setSelectClient(item)
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                label="Cliente"
                required
              />
            )}
          />
        </Header>

        <Stack sx={{ marginTop: 6 }}>
          <SolicitationContainer company={selectClient?.id ?? ''} />
        </Stack>
      </>
    </Can>
  )
}

export default Solicitation
