/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import moment from 'moment';
import {
  ButtonRow,
  CardContentChieldren,
  CardFather,
  DialogStyle,
  DivAlingCard,
  DivAlingItensCard,
  RetangleView
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import { useFormik } from 'formik';

import IconAndTitleView from 'components/view/IconAndTitleView';
import ModalHead from './ModalHead';
import { useQuery } from 'react-query';
import { httpClient } from 'config/httpClient';
import { Accordion } from '../components/Accordion';
import Button from 'components/form/Button';
import { toast } from 'react-toastify';
import { FileUploadOutlined, OpenInNewOutlined } from '@mui/icons-material';
import ImportantView from 'components/view/ImportantView';
import { AttachmentItem } from '../components/AttachmentItem';

interface Props {
  open: boolean
  close: () => void
  data?: any
  bookFinished?: boolean
}

const Attachments: React.FC<Props> = (props) => {
  const { bookFinished } = props
  const [attachments, setAttachments] = useState<any[]>([])
  const closeModal = () => props.close();

  const { data, status, isFetching } = useQuery(
    ['modal-booking-attachments', props?.data?.pedido],
    async () =>
      await httpClient.get<any[]>(`/bookings/attachments/pedido/${props?.data?.pedido}/${props?.data?.ft?._id}`),
    {
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    setAttachments(data?.data ?? [])
  }, [data?.data, isFetching]);

  const handleNewAttachment = () => {
    setAttachments([...attachments, { pedido: props?.data?.pedido, ft: props?.data?.ft?._id }])
  }

  const handleDeleteAttachment = async (index: number, id?: string) => {
    if (id) {
      setAttachments((state) => state.filter((item) => item?._id !== id))
      try {
        await httpClient.delete(`bookings/attachments/${id}`)

        toast.success('Deletado com sucesso!')
      } catch (error: any) {
        toast.error(error?.response?.data?.message)
      }
      return
    }
    setAttachments((state) => state.filter((_, itemIndex) => itemIndex !== index))
  }

  return (
    <>
      <DialogStyle
        open={props.open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHead
          title="Solicitação ferramental"
          idPreFT={props?.data?.ft?.preFT?._id}
        />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {attachments?.map((attachment: any, index) => (
                  <Accordion
                    key={`${attachment?._id}-${index}`}
                    title={`Anexo ${index + 1}`}
                  >
                    <AttachmentItem
                      data={attachment}
                      cod={`${attachment?._id}-${index}`}
                      onChange={(values) => {
                        setAttachments((state) =>
                          state?.map(
                            (current, currentIndex) =>
                              currentIndex === index
                                ? { ...current, ...values }
                                : current
                          )
                        )
                      }}
                    >
                      {bookFinished && <Button
                        mold="red"
                        type='button'
                        onClick={async () => handleDeleteAttachment(index, attachment?._id)}
                      >
                        Remover
                      </Button>}
                    </AttachmentItem>
                  </Accordion>
                ))}

              <Stack padding={2} alignItems='flex-start'>
                <Button onClick={handleNewAttachment}>Novo anexo</Button>
              </Stack>

              <ButtonRow
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  marginTop: 30
                }}
              >
                <Button
                  type="button"
                  mold="primary"
                  onClick={() => {
                    props.close();
                  }}
                >
                  Voltar
                </Button>
              </ButtonRow>
            </DialogContentText>
          </DialogContent>
      </DialogStyle>
    </>
  );
};

export default Attachments;
