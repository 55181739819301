import React from 'react'

function AlertStatus (props: any) {
  if (props.status) {
    return <div style={{ backgroundColor: '#EBFFD8', border: '1px solid #72CE2B', color: '#72CE2B', borderRadius: '2px', width: 'fit-content', paddingInline: '4px', display: 'inline' }}>ATIVO</div>
  }
  return <div style={{ backgroundColor: '#FFDDEC', border: '1px solid #EE207A', color: '#EE207A', borderRadius: '2px', width: 'fit-content', paddingInline: '4px', display: 'inline' }}>INATIVO</div>
}

export default AlertStatus
