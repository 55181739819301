/* eslint-disable @typescript-eslint/no-unused-vars */
import { Add, ArrowBack } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  TextField,
  TableFooter
} from '@mui/material'
import alertSwalCustom from 'components/Alerts/ModalCustom'
import alertSwal from 'components/Alerts/ModalDelete'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import MoreMenu from 'components/view/MoreMenu'
import { httpClient } from 'config/httpClient'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ModalCreateEstoque } from './components/ModalCreateEstoque'
import { ModalEditEstoque } from './components/ModalEditEstoque'

export interface EstoqueType {
  _id: string
  produto: string
  entradaMes?: number
  data: string
  kg: number
  rs: number
  createdAt: string
}

export function Estoque() {
  const [estoques, setEstoques] = useState<EstoqueType[]>([])
  const [openModalCreateEstoque, setOpenModalCreateEstoque] = useState(false)
  const [openModalEditEstoque, setOpenModalEditEstoque] = useState(false)
  const [selectedEstoque, setSelectedEstoque] = useState<EstoqueType>()

  const [valueDate, setValueDate] = useState<any | null>(new Date())

  const { isLoading, refetch } = useQuery(
    ['managementControl-estoque', moment(valueDate).format('YYYY-MM-DD')],
    async () =>
      httpClient.get(
        `/estoques?data=${moment(valueDate).format('YYYY-MM-DD')}`
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess({ data }) {
        setEstoques(data ?? [])
      },
      onError(err: any) {
        toast.error(err?.response?.data?.message)
      }
    }
  )

  const total = estoques.reduce(
    (acc, currentValue) => {
      return {
        entradaMes: acc.entradaMes + Number(currentValue?.entradaMes ?? 0),
        rs: acc.rs + Number(currentValue?.rs ?? 0),
        kg: acc.kg + Number(currentValue?.kg ?? 0)
      }
    },
    { entradaMes: 0, rs: 0, kg: 0 }
  )

  const handleSelectEditEstoque = (item: EstoqueType) => {
    setSelectedEstoque(item)
    setOpenModalEditEstoque(true)
  }

  const handleDeleteEstoque = (item: EstoqueType) => {
    httpClient
      .delete(`/estoques/${item?._id}`)
      .then(() => {
        toast.success('Dados deletado com sucesso!')

        refetch()
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message)
      })
  }

  return (
    <>
      <Box
        minHeight="calc(100vh - 150px)"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <BreadcrumbsComponent
            breadcrumbs={[
              { text: 'Gerencial', url: '/home/managementControl' },
              { text: 'Estoque' }
            ]}
          />
          <Typography component="h4" sx={{ marginTop: 3, marginBottom: 3 }}>
            Estoque
          </Typography>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 3 }}
          >
            <Button
              color="primary"
              onClick={() => setOpenModalCreateEstoque(true)}
              variant="contained"
            >
              <Add fontSize="small" />
            </Button>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Dia"
                value={valueDate}
                onChange={(newValue) => {
                  setValueDate(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Stack>

          {isLoading && (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: 200 }}
            >
              <CircularProgress size={18} />
            </Stack>
          )}

          {!isLoading && (
            <TableContainer>
              <Table
                stickyHeader
                component={Paper}
                sx={{ minWidth: '100%', background: '#FFF' }}
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Produto</TableCell>
                    <TableCell>Entrada do Mês KG</TableCell>
                    <TableCell>KG</TableCell>
                    <TableCell>R$</TableCell>
                    <TableCell>R$/KG</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {estoques?.map((item) => (
                    <TableRow key={item._id}>
                      <TableCell>{item.produto}</TableCell>
                      <TableCell>
                        {item?.entradaMes?.toLocaleString('pt-BR')}
                      </TableCell>
                      <TableCell>{item.kg.toLocaleString('pt-BR')}</TableCell>
                      <TableCell>
                        {item.rs?.toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </TableCell>
                      <TableCell>
                        {(item.rs / item.kg).toLocaleString('BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </TableCell>
                      <TableCell>
                        <MoreMenu
                          edit={() => handleSelectEditEstoque(item)}
                          delete={() => handleDeleteEstoque(item)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {!estoques?.length && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        Nenhum estoque econtrado.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {total?.entradaMes?.toLocaleString('pt-BR')}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {total.kg.toLocaleString('pt-BR')}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {total.rs?.toLocaleString('pt-BR', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {total.rs
                        ? (total.rs / total.kg).toLocaleString('BR', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        : '0,00'}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Box>

        <Stack alignItems="flex-end" sx={{ marginTop: 4 }}>
          <Button
            color="primary"
            component={Link}
            to="/home/managementControl"
            variant="contained"
            style={{
              width: '122px'
            }}
          >
            Voltar
          </Button>
        </Stack>
      </Box>

      <ModalCreateEstoque
        open={openModalCreateEstoque}
        onClose={() => {
          setOpenModalCreateEstoque(false)
          setSelectedEstoque(undefined)
        }}
        onCloseSuccess={() => {
          setOpenModalCreateEstoque(false)
          setSelectedEstoque(undefined)
          refetch()
        }}
      />

      <ModalEditEstoque
        open={openModalEditEstoque}
        item={selectedEstoque}
        onClose={() => {
          setOpenModalEditEstoque(false)
          setSelectedEstoque(undefined)
        }}
        onCloseSuccess={() => {
          setOpenModalEditEstoque(false)
          setSelectedEstoque(undefined)
          refetch()
        }}
      />
    </>
  )
}
