import React from 'react'
import alertSwal from '../Alerts/ModalDelete'
import { IconButton, Popover, SxProps, Theme } from '@mui/material'
import { ActionContainer, ActionButton } from './styles/style-more-menu'
import {
  ModeEdit,
  MoreVert,
  Visibility,
  DeleteOutlineOutlined,
  AssignmentTurnedIn,
  CheckOutlined,
  Calculate,
  AssignmentTurnedInOutlined
} from '@mui/icons-material'
import LightTooltip from 'components/tooltips/LightTooltip'
import { Can } from 'components/Permission/Can'
import { PERMISSIONS } from 'helpers/permissions'

interface Props {
  edit?: () => void
  view?: () => void
  delete?: () => void
  inactive?: () => void
  active?: () => void
  audit?: () => void
  proposal?: () => void
  sample?: () => void
  sx?: SxProps<Theme>
  module?: string
  isValidationPermissions?: boolean
  children?: React.ReactNode
}

const MoreMenu: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(
    null
  )

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <IconButton
        component="span"
        aria-label="upload picture"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={props?.sx}
      >
        <MoreVert />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            transform: 'translateX(-77%)'
          }
        }}
      >
        <ActionContainer onClick={() => setAnchorEl(null)}>
          <Can
            module={props?.module}
            permissions={[PERMISSIONS.READ]}
            disabled={!props?.isValidationPermissions}
          >
            <LightTooltip
              style={{ display: props.view ? 'flex' : 'none' }}
              title="Visualizar"
              placement="top"
            >
              <ActionButton aria-label="visibility" onClick={props.view}>
                <Visibility />
              </ActionButton>
            </LightTooltip>
          </Can>
          <Can
            module={props?.module}
            permissions={[PERMISSIONS.UPDATE]}
            disabled={!props?.isValidationPermissions}
          >
            <LightTooltip
              style={{ display: props.edit ? 'flex' : 'none' }}
              title="Editar"
              placement="top"
            >
              <ActionButton aria-label="edit" onClick={props.edit}>
                <ModeEdit />
              </ActionButton>
            </LightTooltip>
          </Can>

          <Can
            module={props?.module}
            permissions={[PERMISSIONS.UPDATE]}
            disabled={!props?.isValidationPermissions}
          >
            <LightTooltip
              style={{ display: props.audit ? 'flex' : 'none' }}
              title="Auditar"
              placement="top"
            >
              <ActionButton aria-label="audit" onClick={props.audit}>
                <AssignmentTurnedIn />
              </ActionButton>
            </LightTooltip>
          </Can>

          <Can
            module={props?.module}
            permissions={[PERMISSIONS.DELETE]}
            disabled={!props?.isValidationPermissions}
          >
            <LightTooltip
              style={{ display: props.delete ? 'flex' : 'none' }}
              title="Excluir"
              placement="top"
            >
              <ActionButton
                color="#ee207a"
                aria-label="delete"
                onClick={() => {
                  alertSwal(props.delete)
                  setAnchorEl(null)
                }}
              >
                <DeleteOutlineOutlined />
              </ActionButton>
            </LightTooltip>
          </Can>
          <Can
            module={props?.module}
            permissions={[PERMISSIONS.UPDATE]}
            disabled={!props?.isValidationPermissions}
          >
            <LightTooltip
              style={{ display: props.inactive ? 'flex' : 'none' }}
              title="Desativar"
              placement="top"
            >
              <ActionButton
                color="#ee207a"
                aria-label="inactive"
                onClick={() => {
                  alertSwal(props.inactive)
                  setAnchorEl(null)
                }}
              >
                <DeleteOutlineOutlined />
              </ActionButton>
            </LightTooltip>
          </Can>

          <Can
            module={props?.module}
            permissions={[PERMISSIONS.UPDATE]}
            disabled={!props?.isValidationPermissions}
          >
            <LightTooltip
              style={{ display: props.active ? 'flex' : 'none' }}
              title="Ativar"
              placement="top"
            >
              <ActionButton
                color="#72CE2B"
                aria-label="inactive"
                onClick={() => {
                  alertSwal(props.active)
                  setAnchorEl(null)
                }}
              >
                <CheckOutlined />
              </ActionButton>
            </LightTooltip>
          </Can>

          <Can
            module={props?.module}
            permissions={[PERMISSIONS.UPDATE]}
            disabled={!props?.isValidationPermissions}
          >
            <LightTooltip
              style={{ display: props.proposal ? 'flex' : 'none' }}
              title="Propostas"
              placement="top"
            >
              <ActionButton
                // color="#72CE2B"
                aria-label="calculate"
                onClick={props.proposal}
              >
                <Calculate />
              </ActionButton>
            </LightTooltip>
          </Can>

          {props.sample && <Can
            module={props?.module}
            permissions={[PERMISSIONS.UPDATE]}
            disabled={!props?.isValidationPermissions}
          >
            <LightTooltip
              style={{ display: 'flex' }}
              title="Amostras ou Desenhos"
              placement="top"
            >
              <ActionButton
                // color="#72CE2B"
                aria-label="sample"
                onClick={props.sample}
              >
                <AssignmentTurnedInOutlined />
              </ActionButton>
            </LightTooltip>
          </Can>}

          {props.children}
        </ActionContainer>
      </Popover>
    </>
  )
}

export default MoreMenu
