/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getBudget,
  getClicheTrimbox,
  getCompositionTrimbox,
  getFacaTrimbox,
  postBudget
} from 'services/ft/FTService'
import { BudgetDataType } from 'types/ft/BudgetDataType'
import { FTBudgetCompleteType } from 'types/ft/FTBudgetCompleteType'
import { FTBudgetType } from 'types/ft/FTBudgetType'
import { FTProposalType } from 'types/ft/FTProposalType'
import { validationSchemaBudgetCompleteFT } from 'types/validation/ValidationBudgetCompleteFT'

interface Props {
  budgetData: FTBudgetType | undefined
  ft: any
  preft: any
  ftTrimboxData: any
  confirm: (values) => any
  setBudgetId: (values) => any
}

function BudgetCalculate(props: Props) {
  const navigate = useNavigate()
  const [budgetData, setBudgetData] = React.useState<BudgetDataType>()
  const [clicheCheck, setClicheCheck] = React.useState(props?.preft?.cliche)
  const [facaCheck, setFacaCheck] = React.useState(props?.preft?.knife)
  const [openInputCompleto, setOpenInputCompleto] = React.useState(true)
  const [cortes, setCortes] = React.useState(0)
  const [formato, setFormato] = React.useState(0)
  const [arranjoComprimento, setArranjoComprimento] = React.useState(1)
  const [arranjoLargura, setArranjoLargura] = React.useState(1)
  const [comprimento, setComprimento] = React.useState(
    props?.ftTrimboxData?.comprimento
      ? props?.ftTrimboxData?.comprimento / 1000
      : 0
  )
  const [materialValues, setMaterialValues] = React.useState(
    props?.budgetData ?? {
      productValue: 0,
      taxValue: 0,
      closureValue: 0,
      resinValue: 0,
      cleaningValue: 0,
      productName: '',
      daysValue: 0
    }
  )

  const facaReq = useQuery(
    ['faca-ft-trimbox', null],
    async () => {
      console.log('ftTrimboxData', props?.ftTrimboxData)
      return getFacaTrimbox(props?.ftTrimboxData?.codFaca)
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const clicheReq = useQuery(
    ['cliche-ft-trimbox', null],
    async () => {
      return getClicheTrimbox(props?.ftTrimboxData?.codCliche)
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const compositionReq = useQuery(
    ['gramature-ft-trimbox', null],
    async () => {
      return getCompositionTrimbox(props?.ftTrimboxData?.codComposition)
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const mongoBudget = useQuery(
    ['budget-req', null],
    async () => {
      return getBudget(props.ft?._id || '')
    }
  )
  if (mongoBudget.isLoading || mongoBudget.isError || !mongoBudget.data || mongoBudget.data.length === 0) {
    console.log('EMPTY BUDGET')
  } else {
    console.log('BudgetCalculate => mongo BUDGET', mongoBudget)
  }

  const { errors, touched, values, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      onSubmit: function (inputValues: FTBudgetCompleteType) {
        let custoFaca = facaCheck
          ? facaReq.isError
            ? 0
            : facaReq.data.data?.preco ?? 0
          : 0
        let custoCliche = clicheCheck
          ? clicheReq.isError
            ? 0
            : clicheReq.data?.preco ?? 0
          : 0
        let gramatura = compositionReq.isError
          ? null
          : compositionReq.data?.gramatura / 1000 ?? 0

        // eslint-disable-next-line prefer-const
        let trimboxData = props?.ftTrimboxData ?? { comprimento: 0 }

        console.log('dados para orçamento inputados', inputValues)
        console.log('dados para orçamento trimbox', trimboxData)
        console.log('dados para orçamento valores material', materialValues)
        console.log('dados para orçamento faca', custoFaca)
        console.log('dados para orçamento cliche', custoCliche)
        console.log('dados para orçamento composition', gramatura)

        // Caso os dados do trimbox e de valores de material sejam inputados
        if (openInputCompleto) {
          gramatura =
            inputValues.gramatura ?? compositionReq.data?.gramatura / 1000 ?? 0
          custoFaca = inputValues.faca ?? facaReq.data.data?.preco ?? 0
          custoCliche = inputValues.cliche ?? clicheReq.data?.preco ?? 0
          materialValues.productValue =
            inputValues.tabela ?? materialValues.productValue
          materialValues.taxValue = inputValues.taxa ?? materialValues.taxValue
          materialValues.closureValue =
            inputValues.fechamento ?? materialValues.closureValue
          materialValues.resinValue =
            inputValues.resina ?? materialValues.resinValue
          materialValues.cleaningValue =
            inputValues.limpeza ?? materialValues.cleaningValue
          trimboxData.comprimento = comprimento / arranjoComprimento
          console.log('TESTE', trimboxData.comprimento, comprimento)
          trimboxData.areaBrutaChapa =
            (formato / cortes / arranjoLargura) * trimboxData.comprimento
          trimboxData.areaLiquidaChapa =
            inputValues.areaLiquida ?? trimboxData.areaLiquidaChapa
        }

        setMaterialValues(materialValues)

        const pesoBruto =
          Math.round(trimboxData.areaBrutaChapa * (gramatura ?? 0) * 100000) /
          100000 // trimboxData.areaBrutaChapa * (gramatura ?? 0)

        const tabela = materialValues?.productValue ?? 0

        const valorAgregado =
          (materialValues?.closureValue ?? 0) +
          inputValues.glueClipClosure +
          (materialValues?.resinValue ?? 0) * trimboxData.areaBrutaChapa +
          (materialValues?.cleaningValue ?? 0)

        const precoFerramental =
          custoCliche / (inputValues.monthlyConsumption * 6) +
          custoFaca / (inputValues.monthlyConsumption * 6)

        const valorTaxa = materialValues?.taxValue ?? 0

        const taxaFinanciamento =
          ((valorAgregado +
            precoFerramental +
            inputValues.shipping * pesoBruto +
            pesoBruto * tabela) *
            valorTaxa) /
          100

        console.log(
          valorAgregado,
          precoFerramental,
          inputValues.shipping,
          pesoBruto,
          taxaFinanciamento
        )

        const precoSugerido =
          Math.floor(
            (valorAgregado +
              precoFerramental +
              Number(inputValues.shipping) * pesoBruto +
              pesoBruto * tabela +
              taxaFinanciamento) *
              10000
          ) / 10000

        console.log('preco sugerido', precoSugerido)

        const _budgetData = {
          areaLiquida: parseFloat(
            Number(trimboxData.areaLiquidaChapa).toFixed(4)
          ),
          areaBruta: parseFloat(Number(trimboxData.areaBrutaChapa).toFixed(4)),
          fechamento: parseFloat(
            Number(materialValues?.closureValue ?? 0).toFixed(4)
          ),
          resina: parseFloat(
            Number(
              (materialValues?.resinValue ?? 0) * trimboxData.areaBrutaChapa
            ).toFixed(4)
          ),
          limpeza: parseFloat(
            Number(materialValues?.cleaningValue ?? 0).toFixed(4)
          ),
          gramatura: parseFloat(Number(gramatura).toFixed(4)),
          tipoProduto: materialValues?.productName ?? '',
          tabela: parseFloat(
            Number(materialValues?.productValue ?? 0).toFixed(4)
          ),
          mensal: parseFloat(Number(inputValues.monthlyConsumption).toFixed(4)),
          cidadeDestino: inputValues.destinationCity,
          valorFrete: parseFloat(Number(inputValues.shipping).toFixed(4)),
          fechamentoColagemManual: parseFloat(
            Number(materialValues?.closureValue ?? 0).toFixed(4)
          ),
          fechamentoGrampoCola: parseFloat(
            Number(inputValues.glueClipClosure).toFixed(4)
          ),
          loteMinimo: parseFloat(Number(inputValues.minimumLot).toFixed(4)),
          condicoesPagamento: parseFloat(
            Number(materialValues?.daysValue ?? 0).toFixed(4)
          ),
          valorTaxa: parseFloat(
            Number(materialValues?.taxValue ?? 0).toFixed(4)
          ),
          pesoLiquido: parseFloat(
            Number(trimboxData.areaLiquidaChapa * (gramatura ?? 0)).toFixed(4)
          ),
          pesoBruto: pesoBruto,
          anual: parseFloat(
            Number(inputValues.monthlyConsumption * 12).toFixed(4)
          ),
          semestral: parseFloat(
            Number(inputValues.monthlyConsumption * 6).toFixed(4)
          ),
          trimestral: parseFloat(
            Number(inputValues.monthlyConsumption * 3).toFixed(4)
          ),
          bimestral: parseFloat(
            Number(inputValues.monthlyConsumption * 2).toFixed(4)
          ),
          pesoKg: parseFloat(Number(pesoBruto).toFixed(4)),
          preco: Math.floor(pesoBruto * tabela * 10000) / 10000,
          valorFreteCalculado: parseFloat(
            Number(inputValues.shipping * pesoBruto).toFixed(4)
          ),
          custoCliche: parseFloat(Number(custoCliche).toFixed(4)),
          custoFaca: parseFloat(Number(custoFaca).toFixed(4)),
          custoClicheDiluido: parseFloat(
            Number(custoCliche / (inputValues.monthlyConsumption * 6)).toFixed(
              4
            )
          ),
          custoFacaDiluido: parseFloat(
            Number(custoFaca / (inputValues.monthlyConsumption * 6)).toFixed(4)
          ),
          precoFerramental: parseFloat(Number(precoFerramental).toFixed(4)),
          valorAgregado: parseFloat(Number(valorAgregado).toFixed(4)),
          precoFechamento: parseFloat(Number(valorAgregado).toFixed(4)),
          precoAVista:
            Math.floor(
              (valorAgregado +
                precoFerramental +
                inputValues.shipping * pesoBruto +
                pesoBruto * tabela) *
                10000
            ) / 10000,
          taxaFinanciamento: parseFloat(Number(taxaFinanciamento).toFixed(4)),
          precoKgFerramental: parseFloat(
            Number(precoSugerido / pesoBruto).toFixed(4)
          ),
          precoKgSemFerramental: parseFloat(
            Number((pesoBruto * tabela) / pesoBruto).toFixed(4)
          ),
          precoSugerido: precoSugerido,
          precoPorKg: parseFloat(Number(precoSugerido / pesoBruto).toFixed(4)),
          precoNegociado: Number(Math.ceil(precoSugerido * 100) / 100).toFixed(
            2
          ),
          peloValorNF: Number(
            precoSugerido / (trimboxData.areaLiquidaChapa * (gramatura ?? 0))
          ).toFixed(2),
          KG: parseFloat(
            Number(inputValues.monthlyConsumption * pesoBruto).toFixed(4)
          ),
          NF:
            Math.ceil(inputValues.monthlyConsumption * precoSugerido * 0.1) /
            0.1,
          status: 'Criado',
          ft: props?.ft?._id
        }
        console.log('Resultado:', _budgetData)
        setBudgetData(_budgetData)
        // mutateCreate(_budgetData)
      },
      enableReinitialize: true,
      validationSchema: validationSchemaBudgetCompleteFT,
      initialValues: {
        monthlyConsumption:
          props?.preft?.preftbox?.amount?.monthConsumption ?? '',
        destinationCity: props?.preft?.destinationCity,
        shipping: 0,
        icms: 0,
        // (valor de maleta ou corte e vinco) / Peso
        minimumLot: Math.round(
          props?.preft?.minimumLotReference /
            (props?.ftTrimboxData?.areaBrutaChapa *
              (compositionReq.data?.gramatura / 1000 ?? 0))
        ),
        glueClipClosure: 0
      }
    })

  if (facaReq.isFetching || compositionReq.isFetching || clicheReq.isFetching) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  const handleGoBack = () => navigate('/home/ft')

  return (
    <Box>
      <div
        style={{
          display:
            facaReq.isError ||
            !facaReq.data ||
            compositionReq.isError ||
            !compositionReq.data ||
            clicheReq.isError ||
            !clicheReq.data
              ? 'flex'
              : 'none',
          flexDirection: 'column',
          marginTop: '18px'
        }}
      >
        <div>Observações: </div>
        <div
          style={{
            display: clicheReq.isError || !clicheReq.data ? 'flex' : 'none'
          }}
        >
          Dados para clichê polímero(clichê) não encontrados no trimbox.
        </div>
        <div
          style={{
            display: facaReq.isError || !facaReq.data ? 'flex' : 'none'
          }}
        >
          Dados para clichê metálico(faca) não encontrados no trimbox.
        </div>
        {compositionReq.isError || !compositionReq.data
? (
          <div>
            Dados para gramatura(composition) não encontrados no trimbox.
          </div>
        )
: (
          <div>
            Dados da gramatura(composition):{' '}
            {compositionReq.data?.gramatura / 1000 ?? 0}.<br></br>
            Lote mínimo padrão:{' '}
            {`${props?.preft?.minimumLotReference} / ${
              props?.ftTrimboxData?.areaBrutaChapa
            } x ${compositionReq.data?.gramatura / 1000 ?? 0}`}
          </div>
        )}
      </div>
      <form
        id="entry-data-form"
        onSubmit={handleSubmit}
        style={{
          marginTop: '24px',
          backgroundColor: '#f0f0f0',
          padding: '10px',
          borderRadius: '12px'
        }}
      >
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Dados de entrada do orçamento
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}
        >
          <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
            <Grid item xs={3}>
              <TextField
                type="number"
                fullWidth
                id="standard-basic"
                label="Consumo mensal (Unid)"
                variant="standard"
                value={values.monthlyConsumption}
                autoComplete="off"
                error={Boolean(
                  errors.monthlyConsumption && touched.monthlyConsumption
                )}
                helperText={
                  errors.monthlyConsumption && touched.monthlyConsumption
                    ? errors.monthlyConsumption
                    : null
                }
                // onChange={handleChange('monthlyConsumption')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('monthlyConsumption', 0)
                  } else {
                    setFieldValue('monthlyConsumption', event.target.value)
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={handleChange('destinationCity')}
                value={values.destinationCity}
                autoComplete="off"
                error={Boolean(
                  errors.destinationCity && touched.destinationCity
                )}
                helperText={
                  errors.destinationCity && touched.destinationCity
                    ? errors.destinationCity
                    : null
                }
                fullWidth
                id="standard-basic"
                label="Cidade destino"
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // onChange={handleChange('shipping')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('shipping', 0)
                  } else {
                    setFieldValue('shipping', event.target.value)
                  }
                }}
                value={values.shipping}
                autoComplete="off"
                error={Boolean(errors.shipping && touched.shipping)}
                helperText={
                  errors.shipping && touched.shipping ? errors.shipping : null
                }
                type="number"
                fullWidth
                id="standard-basic"
                label="Frete (R$)"
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // onChange={handleChange('icms')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('icms', 0)
                  } else {
                    setFieldValue('icms', event.target.value)
                  }
                }}
                value={values.icms}
                autoComplete="off"
                error={Boolean(errors.icms && touched.icms)}
                helperText={errors.icms && touched.icms ? errors.icms : null}
                type="number"
                fullWidth
                id="standard-basic"
                label="Desconto ICMS (%)"
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // onChange={handleChange('minimumLot')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('minimumLot', 0)
                  } else {
                    setFieldValue('minimumLot', event.target.value)
                  }
                }}
                value={values.minimumLot}
                autoComplete="off"
                error={Boolean(errors.minimumLot && touched.minimumLot)}
                helperText={
                  errors.minimumLot && touched.minimumLot
                    ? errors.minimumLot
                    : null
                }
                type="number"
                fullWidth
                id="standard-basic"
                label="Lote mínimo (Unid)"
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // onChange={handleChange('glueClipClosure')}
                onChange={(event) => {
                  if (Number(event.target.value ?? 0) < 0) {
                    setFieldValue('glueClipClosure', 0)
                  } else {
                    setFieldValue('glueClipClosure', event.target.value)
                  }
                }}
                value={values.glueClipClosure}
                autoComplete="off"
                error={Boolean(
                  errors.glueClipClosure && touched.glueClipClosure
                )}
                helperText={
                  errors.glueClipClosure && touched.glueClipClosure
                    ? errors.glueClipClosure
                    : null
                }
                type="number"
                fullWidth
                id="standard-basic"
                label="Fechamento (grampo/cola)"
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={clicheCheck}
                    onChange={() => {
                      setClicheCheck(!clicheCheck)
                    }}
                  />
                }
                label="Clichê polímero"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={facaCheck}
                    onChange={() => {
                      setFacaCheck(!facaCheck)
                    }}
                  />
                }
                label="Clichê metálico"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('gramatura')}
                value={
                  values.gramatura ?? compositionReq.data?.gramatura / 1000 ?? 0
                }
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Gramatura"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => {
                  if (e.target.value) {
                    setFacaCheck(true)
                  } else {
                    setFacaCheck(false)
                  }
                  setFieldValue('faca', e.target.value)
                }}
                // onChange={handleChange('faca')}
                value={values.faca}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Faca"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => {
                  if (e.target.value) {
                    setClicheCheck(true)
                  } else {
                    setClicheCheck(false)
                  }
                  setFieldValue('cliche', e.target.value)
                }}
                // onChange={handleChange('cliche')}
                value={values.cliche}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Clichê"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('tabela')}
                value={values.tabela ?? materialValues.productValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Tabela"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('taxa')}
                value={values.taxa ?? materialValues.taxValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Taxa"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => {
                  setFormato(Number(e.target.value))
                }}
                value={formato}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Formato"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => {
                  setCortes(Number(e.target.value))
                }}
                value={cortes}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Cortes"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => {
                  setComprimento(Number(e.target.value))
                }}
                value={comprimento}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Comprimento"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('areaLiquida')}
                value={
                  values.areaBruta ?? props?.ftTrimboxData?.areaBrutaChapa
                }
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Área"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('fechamento')}
                value={values.fechamento ?? materialValues.closureValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Fechamento"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('resina')}
                value={values.resina ?? materialValues.resinValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Resina"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={handleChange('limpeza')}
                value={values.limpeza ?? materialValues.cleaningValue}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Limpeza"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => {
                  setArranjoLargura(Number(e.target.value))
                }}
                value={arranjoLargura}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Arranjo Largura"
                variant="standard"
                required
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: openInputCompleto ? '' : 'none' }}
            >
              <TextField
                onChange={(e) => {
                  setArranjoComprimento(Number(e.target.value))
                }}
                value={arranjoComprimento}
                autoComplete="off"
                type="number"
                fullWidth
                id="standard-basic"
                label="Arranjo Comprimento"
                variant="standard"
                required
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Button type="submit" style={{ marginTop: '10px' }}>
            Gerar orçamento
          </Button>
          {/* <Button onClick={() => { mutateCreate(budgetData!) }} variant='outlined' type="button" style={{ marginTop: '10px', marginLeft: '10px' }}>
            Salvar
          </Button> */}
        </div>
      </form>

      {budgetData && (
        <div>
          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Especificações
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '5px'
              }}
            >
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="FTD"
                    variant="standard"
                    value={props?.ftTrimboxData?.codConjunto}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Área líquida (m²)"
                    variant="standard"
                    value={budgetData?.areaLiquida}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Peso líquido (kg)"
                    variant="standard"
                    value={budgetData?.pesoLiquido}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Área bruta (m²)"
                    variant="standard"
                    value={
                      isNaN(budgetData?.areaBruta) ? 0 : budgetData?.areaBruta
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Peso bruto (kg)"
                    variant="standard"
                    value={
                      isNaN(budgetData?.pesoBruto) ? 0 : budgetData?.pesoBruto
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Fechamento"
                    variant="standard"
                    value={
                      isNaN(budgetData?.fechamento) ? 0 : budgetData?.fechamento
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Resina"
                    variant="standard"
                    value={isNaN(budgetData?.resina) ? 0 : budgetData?.resina}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Limpeza"
                    variant="standard"
                    value={isNaN(budgetData?.limpeza) ? 0 : budgetData?.limpeza}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Gramatura (G/m²)"
                    variant="standard"
                    value={
                      isNaN(budgetData?.gramatura) ? 0 : budgetData?.gramatura
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Tipo de produto"
                    variant="standard"
                    value={budgetData?.tipoProduto}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Tabela"
                    variant="standard"
                    value={isNaN(budgetData?.tabela) ? 0 : budgetData?.tabela}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Previsão de consumo (Unidades)
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '5px'
              }}
            >
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Anual"
                    variant="standard"
                    value={isNaN(budgetData?.anual) ? 0 : budgetData?.anual}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Semestral"
                    variant="standard"
                    value={
                      isNaN(budgetData?.semestral) ? 0 : budgetData?.semestral
                    }
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Trimestral"
                    variant="standard"
                    value={
                      isNaN(budgetData?.trimestral) ? 0 : budgetData?.trimestral
                    }
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Bimestral"
                    variant="standard"
                    value={
                      isNaN(budgetData?.bimestral) ? 0 : budgetData?.bimestral
                    }
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Mensal"
                    variant="standard"
                    value={isNaN(budgetData?.mensal) ? 0 : budgetData?.mensal}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Formação do preço
            </div>
            <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
              <Grid item xs={12}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Peso (kg)"
                    variant="standard"
                    value={
                      isNaN(Math.ceil(budgetData?.pesoKg * 100) / 100)
                        ? 0
                        : Math.ceil(budgetData?.pesoKg * 100) / 100
                    }
                  />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>x</div>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Tabela"
                    variant="standard"
                    value={isNaN(budgetData?.tabela) ? 0 : budgetData?.tabela}
                  />
                  <div style={{ marginInline: 10, paddingTop: 12 }}>=</div>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.preco)) ? 0 : budgetData?.preco
                    }
                  />
                  <div style={{ marginInline: 18, paddingTop: 12 }}></div>
                  <TextField
                    style={{ fontWeight: 'bold' }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço total"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.preco)) ? 0 : budgetData?.preco
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Frete</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                pointerEvents: 'none'
              }}
            >
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Cidade de destino"
                    variant="standard"
                    value={budgetData?.cidadeDestino}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Valor"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.valorFrete))
                        ? 0
                        : budgetData?.valorFrete
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{ fontWeight: 'bold' }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Peço total"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.valorFreteCalculado))
                        ? 0
                        : budgetData?.valorFreteCalculado
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                marginBottom: -15
              }}
            >
              Ferramental
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                pointerEvents: 'none'
              }}
            >
              <Grid
                container
                spacing={3}
                mt={0}
                style={{ width: '1000px', marginBottom: -15 }}
              >
                <Grid item xs={3}>
                  <div style={{ fontSize: '14px' }}>Clichê polímero</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Custo (R$)"
                    variant="standard"
                    value={budgetData?.custoCliche}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Diluído em 6 meses"
                    variant="standard"
                    value={budgetData?.custoClicheDiluido}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                mt={0}
                style={{ width: '1000px', marginBottom: -15 }}
              >
                <Grid item xs={3}>
                  <div style={{ fontSize: '14px' }}>Clichê metálico</div>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Custo (R$)"
                    variant="standard"
                    value={budgetData?.custoFaca}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Diluído em 6 meses"
                    variant="standard"
                    value={budgetData?.custoFacaDiluido}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    style={{ fontWeight: 'bold' }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço total"
                    variant="standard"
                    value={budgetData?.precoFerramental}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ marginTop: '24px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Fechamento/Resina/Limpeza
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                pointerEvents: 'none'
              }}
            >
              <Grid container spacing={3} mt={0} style={{ width: '1000px' }}>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Fechamento (colagem manual)"
                    variant="standard"
                    value={budgetData?.fechamentoColagemManual}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Fechamento (grampo/cola)"
                    variant="standard"
                    value={budgetData?.fechamentoGrampoCola}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Resina"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.resina)) ? 0 : budgetData?.resina
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Limpeza"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.limpeza))
                        ? 0
                        : budgetData?.limpeza
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Valor agregado"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.valorAgregado))
                        ? 0
                        : budgetData?.valorAgregado
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{ fontWeight: 'bold' }}
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço total"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.precoFechamento))
                        ? 0
                        : budgetData?.precoFechamento
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <Card sx={{ width: 750, marginTop: '30px' }}>
            <CardContent>
              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Lote mínimo (Pçs)"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.loteMinimo))
                        ? 0
                        : budgetData?.loteMinimo
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço à vista"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.precoAVista))
                        ? 0
                        : budgetData?.precoAVista
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Condições de pagamento (Dias)"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.condicoesPagamento))
                        ? 0
                        : budgetData?.condicoesPagamento
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Taxa de financiamento (R$)"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.taxaFinanciamento))
                        ? 0
                        : budgetData?.taxaFinanciamento
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Valor da taxa (%)"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.valorTaxa))
                        ? 0
                        : budgetData?.valorTaxa
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço por Kg com Ferramental (R$)"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.precoKgFerramental))
                        ? 0
                        : budgetData?.precoKgFerramental
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço por Kg sem Ferramental (R$)"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.precoKgSemFerramental))
                        ? 0
                        : budgetData?.precoKgSemFerramental
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço sugerido (R$)"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.precoSugerido))
                        ? 0
                        : budgetData?.precoSugerido
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço por Kg"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.precoPorKg))
                        ? 0
                        : budgetData?.precoPorKg
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Preço negociado"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.precoNegociado))
                        ? 0
                        : budgetData?.precoNegociado
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} mt={0}>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="Pelo valor da NF"
                    variant="standard"
                    value={
                      isNaN(Number(budgetData?.peloValorNF))
                        ? 0
                        : `R$ ${budgetData?.peloValorNF}`
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="KG"
                    variant="standard"
                    value={isNaN(Number(budgetData?.KG)) ? 0 : budgetData?.KG}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled
                    fullWidth
                    id="standard-basic"
                    label="NF"
                    variant="standard"
                    value={isNaN(Number(budgetData?.NF)) ? 0 : budgetData?.NF}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      )}

      <Button style={{ marginTop: '10px' }} onClick={handleGoBack}>
        Voltar
      </Button>
    </Box>
  )
}

export default BudgetCalculate
