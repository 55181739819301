/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Clear } from '@mui/icons-material'
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material'
import Button from 'components/form/Button'
import React, { useRef, useState } from 'react'
import { LoadingTable } from './LoadingTable'
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableRow
} from './table.styles'
import { TableActionButtons } from './TableActionButtons'
import StockModalView from './StockModalView'
import { formatValue } from 'utils/formatString'
import moment from 'moment'
import WarehouseCheckboxes from './WarehouseCheckbox'
import { Armazem } from 'utils/enums'

interface Props {
  data: any
  selectedDate: Date | null
  status: string
  isFetching: boolean
}

export const StockTable: React.FC<Props> = ({
  data,
  selectedDate,
  status,
  isFetching
}) => {
  const inputSearchItemRef = useRef<HTMLInputElement>(null)
  const inputSearchParceiroRef = useRef<HTMLInputElement>(null)
  const [selection, setSelection] = useState<any>()
  const [page, setPage] = useState<any>(0)
  const [openModal, setOpenModal] = React.useState(false)
  const [searchValueItem, setSearchValueItem] = React.useState('')
  const [searchValueParceiro, setSearchValueParceiro] = React.useState('')
  const [checkboxes, setCheckboxes] = React.useState<string[]>([Armazem.CAIXA, Armazem.CHAPA, Armazem.PAPEL])
  const paginationSize = 10

  const rows = data?.result
    ?.filter((row: any) => {
      return (
        row?.item
          ?.toUpperCase()
          ?.includes(searchValueItem?.toUpperCase()) &&
        row?.nomeParceiro
          ?.toUpperCase()
          ?.includes(searchValueParceiro?.toUpperCase()) &&
        checkboxes.includes(row?.armazem)
      )
    })
    ?.map((row) => {
      const saldoMes = row.ordemPesoTotal - row.quantidadeEntregueKg
      const pesoTotal = row.pesoEstoqueDisponivel

      return {
        ...row,
        consumo: Number(pesoTotal <= saldoMes ? pesoTotal : saldoMes),
        sobraMes: Number(pesoTotal - saldoMes < 0 ? 0 : pesoTotal - saldoMes),
        saldoMes
      }
    })
  console.log(rows)

  const estoqueTotals = rows?.reduce(
    (acc, stock) => {
      return {
        estoqueDisponivelTotal: acc.estoqueDisponivelTotal + Number(stock?.estoqueDisponivel),
        pesoEstoqueDisponivelTotal: acc.pesoEstoqueDisponivelTotal + Number(stock?.pesoEstoqueDisponivel),
        consumoTotal: acc.consumoTotal + Number(stock?.consumo),
        sobraMesTotal: acc.sobraMesTotal + Number(stock?.sobraMes),
        saldoTotal: acc.saldoTotal + Number(stock?.saldoMes)
      }
    },
    {
      estoqueDisponivelTotal: 0,
      pesoEstoqueDisponivelTotal: 0,
      consumoTotal: 0,
      sobraMesTotal: 0,
      saldoTotal: 0
    }
  )

  const handleClearSearchItem = () => {
    setSearchValueItem('')
    if (inputSearchItemRef?.current?.value) {
      inputSearchItemRef.current.value = ''
    }
  }

  const handleClearSearchParceiro = () => {
    setSearchValueParceiro('')
    if (inputSearchParceiroRef?.current?.value) {
      inputSearchParceiroRef.current.value = ''
    }
  }

  return (
    <>
      <Stack direction="row" spacing={3} sx={{ paddingBottom: 2 }}>
        <FormControl variant="standard" className="w-100">
          <InputLabel htmlFor="field-search">Pesquisar por item</InputLabel>
          <Input
            id="field-search"
            autoComplete="off"
            type="text"
            value={searchValueItem}
            onChange={(e) => {
              setSearchValueItem(e.target.value)
              setPage(0)
            }}
            inputRef={inputSearchItemRef}
            disabled={isFetching}
            endAdornment={
              <InputAdornment position="end">
                {(!!searchValueItem?.length ||
                  !!inputSearchItemRef?.current?.value?.length) && (
                    <Button
                      mold="red"
                      type="button"
                      style={{
                        padding: 0,
                        paddingLeft: 8,
                        paddingRight: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 4
                      }}
                      onClick={handleClearSearchItem}
                      disabled={isFetching}
                    >
                      <Clear />
                    </Button>
                  )}
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="standard" className="w-100">
          <InputLabel htmlFor="field-search">Pesquisar por parceiro</InputLabel>
          <Input
            id="field-search"
            autoComplete="off"
            type="text"
            value={searchValueParceiro}
            onChange={(e) => {
              setSearchValueParceiro(e.target.value)
              setPage(0)
            }}
            inputRef={inputSearchParceiroRef}
            disabled={isFetching}
            endAdornment={
              <InputAdornment position="end">
                {(!!searchValueParceiro?.length ||
                  !!inputSearchParceiroRef?.current?.value?.length) && (
                    <Button
                      mold="red"
                      type="button"
                      style={{
                        padding: 0,
                        paddingLeft: 8,
                        paddingRight: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 4
                      }}
                      onClick={handleClearSearchParceiro}
                      disabled={isFetching}
                    >
                      <Clear />
                    </Button>
                  )}
              </InputAdornment>
            }
          />
        </FormControl>
        <WarehouseCheckboxes
          setCheckboxes={setCheckboxes}
          setPage={setPage}
        />
      </Stack>
      {
        isFetching
          ? <LoadingTable />
          : <>
            <TableContainer component={Paper} sx={{ width: '80vw', margin: '0 auto' }}>
              <Table sx={{ width: '100%' }} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell align="left" sx={{ position: 'sticky', left: 0, zIndex: 3 }}>Item</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Armazém</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Descrição Item</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Nome do Parceiro</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Estoque Disponível</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Peso Estq. Disponível</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Saldo {`${moment(selectedDate).format('MMM')}/${moment(selectedDate).format('YY')}`}</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Consumo {`${moment(selectedDate).format('MMM')}/${moment(selectedDate).format('YY')}`}</StyledTableHeadCell>
                    <StyledTableHeadCell align="left">Sobra Estq. {`${moment(selectedDate).format('MMM')}/${moment(selectedDate).format('YY')}`}</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Ações</StyledTableHeadCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell style={{ top: 57 }} sx={{ position: 'sticky', left: 0, zIndex: 3 }}>
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="bold"
                        noWrap
                      >
                        TOTAL
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ top: 57 }}></StyledTableCell>
                    <StyledTableCell style={{ top: 57 }}></StyledTableCell>
                    <StyledTableCell style={{ top: 57 }}></StyledTableCell>
                    <StyledTableCell style={{ top: 57 }}>
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="bold"
                        noWrap
                      >
                        {formatValue(estoqueTotals?.estoqueDisponivelTotal, { precision: 1 })}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell style={{ top: 57 }}>
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="bold"
                        noWrap
                      >
                        {formatValue(estoqueTotals?.pesoEstoqueDisponivelTotal, { precision: 1 })}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell style={{ top: 57 }}>
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="bold"
                        noWrap
                      >
                        {formatValue(estoqueTotals?.saldoTotal, { precision: 1 })}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell style={{ top: 57 }}>
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="bold"
                        noWrap
                      >
                        {formatValue(estoqueTotals?.consumoTotal, { precision: 1 })}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell style={{ top: 57 }}>
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="bold"
                        noWrap
                      >
                        {formatValue(estoqueTotals?.sobraMesTotal, { precision: 1 })}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ top: 57 }}></StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!rows?.length && status !== 'loading' && (
                    <StyledTableRow>
                      <StyledTableCell align="center" colSpan={8}>
                        Nenhum dado encontrado.
                      </StyledTableCell>
                    </StyledTableRow>
                  )}

                  {status === 'loading' && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={8}>
                        <LoadingTable />
                      </StyledTableCell>
                    </StyledTableRow>
                  )}

                  {!!rows?.length &&
                    rows?.slice(page * paginationSize, page * paginationSize + paginationSize).map((row, index) => (
                      <StyledTableRow key={`${row?.item}-${index}`}>
                        <StyledTableCell
                          align="left"
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 2,
                            background: '#FFF'
                          }}
                        >
                          <Typography component="span" variant="body2" noWrap>
                            {row?.item}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography component="span" variant="body2">
                            {row?.armazem}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography component="span" variant="body2" noWrap>
                            {row?.descricao}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography component="span" variant="body2" noWrap>
                            {row?.nomeParceiro}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography component="span" variant="body2" noWrap>
                            {formatValue(row?.estoqueDisponivel, { precision: 1 })}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography component="span" variant="body2" noWrap>
                            {formatValue(row?.pesoEstoqueDisponivel, { precision: 1 })}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography component="span" variant="body2" noWrap>
                            {formatValue(row?.saldoMes, { precision: 1 })}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography component="span" variant="body2" noWrap>
                            {formatValue(row?.consumo, { precision: 1 })}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography component="span" variant="body2" noWrap>
                            {formatValue(row?.sobraMes, { precision: 1 })}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <TableActionButtons
                            onView={() => {
                              setSelection(row)
                              setOpenModal(true)
                            }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ width: '80vw', margin: '5px auto' }}>
              <TablePagination
                sx={{ width: 'fit-content', float: 'right', marginLeft: '10px', marginTop: '3px' }}
                rowsPerPageOptions={[paginationSize]}
                rowsPerPage={paginationSize}
                component={Paper}
                count={rows?.length ?? 0}
                page={page}
                onPageChange={(event: unknown, newPage: number) => {
                  setPage(newPage)
                }}
              />
            </div>
          </>
      }
      <br /> <br />

      {openModal && (
        <StockModalView
          openModal={openModal}
          setOpenModal={setOpenModal}
          item={selection}
        />
      )}
    </>
  )
}
