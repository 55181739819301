import { httpClient, httpLN } from 'config/httpClient'
import { ValuesFormCustomerControlType } from 'types/customerControl/FormCustomerControlType'
import PageableType from 'types/PageableType'
import CustomerControlType from '../../types/customerControl/CustomerControlType'

export const getClient = async ({
  page,
  search,
  searchCod,
  limit,
  offset,
  startDate,
  endDate
}: {
  page: number
  search?: string
  searchCod?: string
  limit?: number
  offset?: number
  startDate?: string
  endDate?: string
}): Promise<PageableType<CustomerControlType>> => {
  const { data } = await httpClient.get(
    `/company?page=${page + 1}&offset=${offset ?? 0}&limit=${
      limit ?? 10
    }&search=${search ?? ''}&startDate=${startDate ?? ''}&endDate=${
      endDate ?? ''
    }&codeLn=${searchCod ?? ''}`
  )
  return data as PageableType<CustomerControlType>
}

export const getClientPrevisions = async ({
  page,
  search,
  searchCod,
  limit,
  offset,
  startDate,
  endDate
}: {
  page: number
  search?: string
  searchCod?: string
  limit?: number
  offset?: number
  startDate?: string
  endDate?: string
}): Promise<PageableType<CustomerControlType>> => {
  const { data } = await httpClient.get(
    `/company/previsions?page=${page + 1}&offset=${offset ?? 0}&limit=${
      limit ?? 10
    }&search=${search ?? ''}&startDate=${startDate ?? ''}&endDate=${
      endDate ?? ''
    }&codeLn=${searchCod ?? ''}`
  )
  return data as PageableType<CustomerControlType>
}

export const updateOrCreate = async (
  data: ValuesFormCustomerControlType,
  id?: number
) => {
  return httpClient({
    data,
    url: '/falta-o-endpoint',
    method: id ? 'PUT' : 'POST'
  })
}

export const createClient = async (
  dadosForm: ValuesFormCustomerControlType
) => {
  const headers = {
    'Content-Type': 'multpart/form-data'
  }
  return await httpClient.post('/company', dadosForm, { headers })
}

export const createClientRepresentatives = async (id: string, body: any) => {
  return await httpClient.post(`/company/${id}/collaborator`, body)
}

export const updateClientRepresentative = async (
  id: string,
  idC: string,
  body: any
) => {
  return await httpClient.put(`/company/${id}/collaborator/${idC}`, body)
}

export const createClientIE = async (id: string, body: any[]) => {
  return await httpClient.post(`/company/${id}/ie`, body)
}

export const updateClientIE = async (id: string, idIe: string, body: any) => {
  return await httpClient.put(`/company/${id}/ie/${idIe}`, body)
}

export const updateClient = async (id: string, body: any) => {
  return await httpClient.put(`/company/${id}`, body)
}

export const updateClientSeller = async (ids: any) => {
  return await httpClient.put('/sellers/associateClientSeller', {
    clientId: ids.clientId,
    userId: ids.userId
  })
}

export const updateBlockClient = async (data: any) => {
  return await httpClient.put(`/company/${data?.clientId}/block`, {
    description: data?.description
  })
}

export const deleteClient = async (id: string) => {
  return await httpClient.delete(`/falta-o-endpoint/${id}`)
}

export const deleteClientRepresentative = async (id: string, idC: string) => {
  return await httpClient.delete(`/company/${id}/collaborator/${idC}`)
}

export const deleteClientIE = async (id: string, idIe: string) => {
  return await httpClient.delete(`/company/${id}/ie/${idIe}`)
}

export const updateStatusClient = async (id: string) => {
  return await httpClient.patch(`/company/${id}/status`)
}

export const updateStatusBlockClient = async (id: string) => {
  return await httpClient.put(`/company/${id}/block`)
}

export const synchronizeClient = async (id: string) => {
  return await httpClient.put(`/company/${id}/synchronize`)
}

export const uploadPhotoClient = async (id: string, body: any) => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  }
  return await httpClient.patch(`/company/photo/${id}`, body, { headers })
}

export const updateClientCurrentBalance = async (id: string, body: any) => {
  return await httpClient.put(`/company/${id}/current-balance`, body)
}

export const getCustomerSerasa = async (cpfCnpj: string, type: any) => {
  const { data } = await httpClient.get(
    `company/serasa/NovaProposta/${cpfCnpj}?type=${type}`
  )
  return data
}

export const getDataClientBigboost = async (cpfCnpj: string) => {
  const { data } = await httpClient.get(`company/bigboost/${cpfCnpj}`)
  //   const { data } = await axios.post('https://bigboost.bigdatacorp.com.br/peoplev2', {
  //     Datasets: 'basic_data,addresses,emails',
  //     q: `doc{${cpfCnpj}}`,
  //     AccessToken: '57bf3778-e16d-4e63-8c4d-9ebab6143c15'
  // })
  return data
}

export const getClientBySellerForRequest = async (userId: string) => {
  const { data } = await httpClient.get(`/sellers/${userId}/clients`)
  return data
}

export const getCollaborators = async (companyId: string) => {
  return httpClient.get<any[]>(`/company/${companyId}/collaborators/active`)
}

export const getIe = async (companyId: string) => {
  return httpClient.get<any[]>(`/company/${companyId}/ie`)
}

export const getItemTrimpaper = async (codeLn: string) => {
  return httpClient.get<any[]>(`/pedidos/trimpaper/pedidos/${codeLn}`)
}

export const getFTsProduction = async (userId?: string) => {
  return httpClient.get(`/ft?userId=${userId ?? ''}`)
}

export const getFTsProject = async (userId?: string) => {
  return httpClient.get(`/ft?userId=${userId ?? ''}`)
}

export const getFTsProjectByCompany = async (companyId: string, userId?: string) => {
  return httpClient.get(`/ft/all-by-company?companyId=${companyId}&userId=${userId ?? ''}`)
}

export const getItemRecurringLN = async (codeLn: string) => {
  return httpLN.get<{result: any[]}>(`/order/recurringItems?id=${codeLn}`)
}
