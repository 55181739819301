import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { ReportDados, ReportVolumeMes } from 'types/Report/ReportVolume'
import { StandardFilter } from 'utils/StandardFilter'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import {
  Box,
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
  platesReq: any
}

const BoxStyle = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  marginright: 88px;
`
const IconStyle = styled(Icon)`
  margin-left: 10px;
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FAFAFA',
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const TableReportPlates: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [meses, setMeses] = useState<ReportVolumeMes[]>([])
  const [dados, setDados] = useState<ReportDados[]>([])
  const [filters, setFilters] = useState<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const mes = moment(props.date).format('M')
  const stickyStyle = { zIndex: 3, background: '#fafafa' }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => {
    const loadedData = [props.platesReq.isLoading].every((loading) => !loading)
    if (loadedData) {
      handleTableData(props.platesReq.data.response)
    }
  }, [props.platesReq.isLoading])

  const handleTableData = (data) => {
    const filteredData = data.filter((item) =>
      item.dados.some((dado) => dado.type === TipoProduto.CHAPA)
    )
    setDataSource(filteredData)
    setDados(filteredData)
    handlePeriodo()
  }

  useEffect(() => {
    StandardFilter(filters, setDados, dataSource)
  }, [filters, dataSource])

  const handlePeriodo = () => {
    const mesesValue = [
      {
        text: 'Janeiro',
        value: 1
      },
      {
        text: 'Fevereiro',
        value: 2
      },
      {
        text: 'Março',
        value: 3
      },
      {
        text: 'Abril',
        value: 4
      },
      {
        text: 'Maio',
        value: 5
      },
      {
        text: 'Junho',
        value: 6
      },
      {
        text: 'Julho',
        value: 7
      },
      {
        text: 'Agosto',
        value: 8
      },
      {
        text: 'Setembro',
        value: 9
      },
      {
        text: 'Outubro',
        value: 10
      },
      {
        text: 'Novembro',
        value: 11
      },
      {
        text: 'Dezembro',
        value: 12
      }
    ]
    const meses: any[] = []
    let i = 0
    while (i < Number(mes)) {
      meses.push(mesesValue[i])
      i++
    }
    setMeses(meses)
  }

  const getRowByPeriodo = (row: any, periodo: number) => {
    const dadosFilter = row.dados.filter((valor) => valor.type === TipoProduto.CHAPA)
    return dadosFilter.find((item) => Number(item.periodo) === periodo)
  }

  const getSumByPeriod = (row: any) => {
    const dadosFilter = row.dados.filter((valor) => valor.type === TipoProduto.CHAPA)
    return dadosFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.rs)
    }, 0)
  }

  return (
    <>
      {(props.platesReq.isLoading || props.platesReq.isFetching) && (
        <BoxStyle>
          <CircularProgress />
        </BoxStyle>
      )}
      {props.platesReq.isError && (
        <BoxStyle>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <IconStyle>signal_wifi_statusbar_connected_no_internet_4</IconStyle>
        </BoxStyle>
      )}
      {props.platesReq.isSuccess &&
        !props.platesReq.isError &&
        !props.platesReq.isFetching && (
          <>
            <Paper sx={{ width: parseInt(mes) === 1 ? '50%' : '100%' }}>
              <TableContainer sx={{ maxHeight: 540 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center" sx={{
                        position: 'sticky',
                        left: 0,
                        zIndex: 3,
                        background: '#fafafa'
                      }}>Cliente</StyledTableCell>
                      {meses.map((mes) => (
                        <StyledTableCell sx={stickyStyle} align="center" colSpan={1}>
                          {mes.text}
                        </StyledTableCell>
                      ))}
                      <StyledTableCell sx={stickyStyle} align="center">Total</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        key="cliente_carne"
                        style={{ width: '20%', top: 57 }}
                        sx={stickyStyle}
                      >
                        <TextField
                          id="standard-basic"
                          label="Pesquisar Cliente"
                          variant="standard"
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setFilters({ ...filters, client: e.target.value })
                          }
                        />
                      </StyledTableCell>
                      {meses.map((mes, index) => (
                        <>
                          <StyledTableCell key={mes.text} align={'center'} style={{ top: 57 }} sx={stickyStyle}>
                            <Typography
                              component="span"
                              fontWeight={500}
                              variant="body2"
                              noWrap
                            >
                              Faturado R$
                            </Typography>
                          </StyledTableCell>
                        </>
                      ))}
                      <StyledTableCell align={'center'} style={{ top: 57 }} sx={stickyStyle}>
                        Faturado R$
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {dados
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const valorTotal = getSumByPeriod(row)
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.cliente}
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 2,
                              background: '#FFF'
                            }}
                          >
                            <StyledTableCell>{row.client}</StyledTableCell>
                            {meses.map((mes) => {
                              const valor = getRowByPeriodo(row, mes.value)
                              return (
                                <>
                                  <StyledTableCell
                                    key={mes.text}
                                    align={'center'}
                                  >
                                    <Typography
                                      component="span"
                                      noWrap
                                      variant="body2"
                                    >
                                      {toBRL(valor?.rs ?? 0, { noBRL: true })}
                                    </Typography>
                                  </StyledTableCell>
                                </>
                              )
                            })}
                            <StyledTableCell align={'center'}>
                              <Typography
                                component="span"
                                noWrap
                                variant="body2"
                              >
                                {toBRL(valorTotal, { noBRL: true })}
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                labelRowsPerPage="Linhas por página:"
                count={dados.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}
    </>
  )
}

export default TableReportPlates
