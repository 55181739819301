import { httpLN } from 'config/httpClient'
import moment from 'moment'

export const getReportProduction = async (date: string, dataRanger?: any): Promise<any[]> => {
  let url = ''
    if (!dataRanger.length) {
      url = `producao/dia?data=${moment(date).format('DD/MM/YYYY')}`
    } else {
      const inicio = dataRanger.map(v => (moment.unix(v.unix).format('DD/MM/YYYY')))
      const fim = inicio[inicio.length - 1]
      url = `producao/dia?dataInicio=${inicio[0]}&dataFim=${fim}`
    }
  const { data } = await httpLN.get(url)
  return data.result ? data.result as any[] : []
}
