import { httpClient } from 'config/httpClient'
import moment from 'moment'

export const getReportRH = async (date: string): Promise<any[]> => {
  const { data } = await httpClient.get(`rh/report?day=${moment(date).format('DD/MM/YYYY')}&month=${moment(date).format('M')}&year=${moment(date).format('YYYY')}`)
  return data as any[]
}

export const deleteRH = async (id: string) => {
  const { data } = await httpClient.delete(`rh/${id}`)
  return data as any[]
}

export const putRH = async (values: any) => {
  const { data } = await httpClient.put(`rh/${values.id}`, values)
  return data as any[]
}
