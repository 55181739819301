import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ReportState {
  load: boolean | null
}
const initialState: ReportState = {
  load: false
}

const reportsliceload = createSlice({
  name: 'load',
  initialState,
  reducers: {

    _addReportLoad (state, action: PayloadAction<any>) {
      state.load = action.payload
    }

  }
})
export const reportReducerLoad = reportsliceload.reducer
export const { _addReportLoad } = reportsliceload.actions
