import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, CircularProgress, Icon } from '@mui/material'
import { ACUMULADO, FATURAMENTO_DIA } from 'constants/constReportBillid'
import { DataValues, IdentifierClient, IdentifierInformative } from 'types/Report/Report'
import TableReportDevolution from './TableReportDevolution'
import { useQuery } from 'react-query'
import { getReport, getReportBilling, getReportOthers } from 'services/report/ReportBilling'
import moment from 'moment'
import { formatValue, toBRL } from 'utils/formatString'
import { getReportRH } from 'services/report/ReportRH'
import { isInvalidNumber } from 'utils/validators'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
  multipleDate?: any
}

const TableReportBilled: React.FC<Props> = (props) => {
  const [valorLocal, setValorLocal] = useState<any>([])
  const [valorOutros, setValorOutros] = useState<any>(0)
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const date = moment(props.date).format('DD/MM/YYYY')
  const [daysutils, setDaysUtils] = useState<any>()
  const [daysFatured, setDaysFatured] = useState<any>()
  const [valorRow] = useState<any[]>([
    {
      text: 'Faturamento dia',
      value: FATURAMENTO_DIA
    },
    {
      text: 'Acumulado',
      value: ACUMULADO
    },
    {
      text: 'Diferença',
      value: 3
    }
  ])
  const queryMultiple = () => {
    // requisição externa do LN
    const req1 = useQuery(['sales', date, props.multipleDate],
      async () => {
        return getReport(date, props.multipleDate)
      },
      {
        onSuccess: (data) => {
          getDataReport(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )

    // requisição banco local
    const req2 = useQuery(['sales-report', mes, ano],
      async () => {
        return getReportBilling(mes, ano)
      },
      {
        onSuccess: (data) => {
          const valueDifference = [
            {
              tipo: '3',
              dados: [{
                type: TipoProduto.CAIXA,
                kg: data.caixa.dados.amountKg,
                rs: data.caixa.dados.amountPrice,
                rskg: 0
              }]
            },
            {
              tipo: '3',
              dados: [{
                type: TipoProduto.CHAPA,
                kg: data.chapa.dados.amountKg,
                rs: data.chapa.dados.amountPrice,
                rskg: 0
              }]
            },
            {
              tipo: '3',
              dados: [{
                type: TipoProduto.PAPEL,
                kg: data.bobina.dados.amountKg,
                rs: data.bobina.dados.amountPrice,
                rskg: 0
              }]
            }
          ]
          setValorLocal(valueDifference)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )

    const req3 = useQuery(['report-rh', props.date],
      async () => {
        return getReportRH(props.date)
      },
      {
        onSuccess: (data) => {
          const handleDataPlanejed = (dataPlan) => {
            const arrayFaturamento: any[] = []
            dataPlan.forEach(e => {
              if (e.production.name === 'Faturamento') {
                arrayFaturamento.push(e)
              }
            })
            if (arrayFaturamento.length) {
              const daysPlanejed = arrayFaturamento[0].days.length ?? 0
              const automaticWorkDays = arrayFaturamento[0].automaticWorksDays.length ?? 0
              setDaysFatured(automaticWorkDays)
              setDaysUtils(daysPlanejed)
            }
          }
          handleDataPlanejed(data)
        },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )

    const req4 = useQuery(['sales-report', mes, ano],
      async () => {
        return getReportBilling(mes, ano)
      },
      {
        onSuccess: (data) => { },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )

    const req5 = useQuery(['faturamento-outros', date],
      async () => {
        return await getReportOthers(date)
      },
      {
        onSuccess: (data) => { console.log('Outros', data) },
        onError: (error) => {
          console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
        },
        refetchOnWindowFocus: false
      }
    )
    console.log('Dias úteis e faturados', daysutils, daysFatured)
    console.log({ req1, req2, req3, req4, req5 })

    return [req1, req2, req3, req4, req5]
  }

  const [req1, req2, req3, req4, req5] = queryMultiple()
  const [dataValues, setDataValues] = useState<DataValues[]>([])

  const searchDataResponse = (type, typeClient, dataa) => {
    const dataResponse = dataa?.response?.concat(valorLocal)

    const search = dataResponse?.find((response) => (
      response.tipo === type &&
      response.dados.some((responseItem) => responseItem.type === typeClient)
    ))
    return search || {}
  }

  const getDataReport = (dataa) => {
    const initialCustomers = makeClientInitial()

    initialCustomers.forEach((data) => {
      if (data.client === 'Outros') {
        const outros = isInvalidNumber(req5?.data?.valorOutros) ? 0 : Number(req5?.data?.valorOutros)
        setValorOutros(outros)
        data.acumulado.rs = outros
      }

      const typeClient = data.identifierClient
      const { dados: dataAccumulated } = searchDataResponse(data.acumulado.identifier, typeClient, dataa)

      let rsAcumulado = 0
      let kgAcumulado = 0

      if (dataAccumulated) {
        const { rs, kg, rskg } = dataAccumulated[0]
        rsAcumulado = rs
        kgAcumulado = kg
        data.acumulado = { ...data.acumulado, rs, kg, rskg }
      }

      const { dados: dataBilling } = searchDataResponse(data.faturamento.identifier, typeClient, dataa)
      if (dataBilling) {
        const { rs, kg, rskg } = dataBilling[0]
        data.faturamento = { ...data.faturamento, rs, kg, rskg }
      }

      const { dados: dataDiferenca } = searchDataResponse(data.diferenca.identifier, typeClient, dataa)
      if (dataDiferenca) {
        console.log('rsAcumulado =>', rsAcumulado)
        console.log('kgAcumulado =>', kgAcumulado)
        let metaKgMes = 0
        let metaRsMes = 0
        switch (String(typeClient)) {
          case TipoProduto.CAIXA:
            metaKgMes = req4.data.caixa.dados.amountKg
            metaRsMes = req4.data.caixa.dados.amountPrice
            break
          case TipoProduto.CHAPA:
            metaKgMes = req4.data.chapa.dados.amountKg
            metaRsMes = req4.data.chapa.dados.amountPrice
            break
          case TipoProduto.PAPEL:
            metaKgMes = req4.data.bobina.dados.amountKg
            metaRsMes = req4.data.bobina.dados.amountPrice
            break
        }
        const diasUteis = req3?.data[0]?.worksDays.length
        const diasFaturados = req3?.data[0]?.automaticWorksDays.length
        const acumuladoKgMes = metaKgMes / diasUteis * diasFaturados
        const acumuladoRsMes = metaRsMes / diasUteis * diasFaturados
        data.diferenca = {
          ...data.diferenca,
          rs: isNaN(rsAcumulado - acumuladoRsMes) ? 0 : rsAcumulado - acumuladoRsMes,
          kg: isNaN(kgAcumulado - acumuladoKgMes) ? 0 : kgAcumulado - acumuladoKgMes,
          rskg: isNaN((rsAcumulado - acumuladoRsMes) / (kgAcumulado - acumuladoKgMes)) ? 0 : (rsAcumulado - acumuladoRsMes) / (kgAcumulado - acumuladoKgMes)
        }
        console.log('data.diferenca =>', data.diferenca)
      }
    })

    const getTotal = (index: 'acumulado' | 'faturamento' | 'diferenca') => initialCustomers.reduce((acum, item) => {
      acum.rs += item[index].rs
      acum.kg += item[index].kg
      acum.rskg += item[index].rskg ?? 0
      return acum
    }, { rs: 0, kg: 0, rskg: 0 })

    const accumulatedTotal = getTotal('acumulado')
    const totalBilled = getTotal('faturamento')
    const totalDiference = getTotal('diferenca')

    const columnTotal = initialCustomers[initialCustomers.length - 1]
    columnTotal.acumulado = {
      ...columnTotal.acumulado,
      ...accumulatedTotal
    }

    columnTotal.faturamento = {
      ...columnTotal.faturamento,
      ...totalBilled
    }

    columnTotal.diferenca = {
      ...columnTotal.diferenca,
      ...totalDiference
    }

    setDataValues(initialCustomers)
  }

  const makeClientInitial = () => {
    const customers = [
      {
        text: 'Caixas',
        type: IdentifierClient.CAIXA
      },
      {
        text: 'Chapas',
        type: IdentifierClient.CHAPA
      },
      {
        text: 'Papel',
        type: IdentifierClient.PAPEL
      },
      {
        text: 'Outros',
        type: IdentifierClient.OUTROS
      },
      {
        text: 'Total',
        type: IdentifierClient.TOTAL
      }
    ]

    const updateData: DataValues[] = []
    customers.forEach((client) => {
      const informative = {
        kg: 0,
        rs: 0,
        rskg: 0
      }

      updateData.push({
        client: client.text,
        identifierClient: client.type,
        acumulado: {
          ...informative,
          identifier: IdentifierInformative.ACUMULADO
        },
        diferenca: {
          ...informative,
          identifier: IdentifierInformative.DIFFERENCE
        },
        faturamento: {
          ...informative,
          identifier: IdentifierInformative.FATURAMENTO_DIA
        },
        devolucao: {
          ...informative,
          identifier: IdentifierInformative.DEVOLUCAO
        }
      })
    })

    return updateData
  }

  return (
    <>
      {(req1.isLoading || req1.isFetching) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <CircularProgress />
        </Box>
      )}
      {req1.isError && req2.isError && req3.isError && req4.isError && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
        </Box>
      )}

      {(req1.isSuccess && !req1.isError && !req1.isFetching) && (
        <>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {valorRow.map((item, index) => {
                      return (
                        <TableCell
                          align="center"
                          style={{ backgroundColor: index === 1 ? 'white' : 'white' }}
                          colSpan={3}
                          key={`${index}-${item}`}
                        >
                          {item.text}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell key='cliente_carne' sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 3,
                      background: '#FFF'
                    }}>
                      Produto
                    </TableCell>
                    {valorRow.map((item, index) => (
                      <>
                        <TableCell
                          key={`KG-${item.text}`}
                          style={{ backgroundColor: index === 1 ? 'white' : 'white' }}
                          align={'center'}
                        >
                          KG
                        </TableCell>
                        <TableCell
                          key={`R$-${item.text}`}
                          style={{ backgroundColor: index === 1 ? 'white' : 'white' }}
                          align={'center'}
                        >
                          R$
                        </TableCell>
                        <TableCell
                          key={`R$KG-${item.text}`}
                          align={'center'}
                          style={{
                            display: (item.value === 3 && index === 6) ? 'none' : 'table-cell',
                            backgroundColor: index === 1 ? 'white' : 'white'
                          }}
                        >
                          R$/KG
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataValues
                    .map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell key='nome_cliente' sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 2,
                            background: '#FFF'
                          }}>
                            {row.client}
                          </TableCell>
                          <TableCell align={'center'}>{formatValue(row.faturamento.kg ?? 0)}</TableCell>
                          <TableCell align={'center'}>{toBRL(row.faturamento.rs ?? 0, { noBRL: true })}</TableCell>
                          <TableCell align={'center'}>{toBRL(row.faturamento.rs / row.faturamento.kg, { noBRL: true })}</TableCell>

                          <TableCell align={'center'}>{formatValue(row.acumulado.kg ?? 0)}</TableCell>
                          <TableCell align={'center'}>{toBRL(row.acumulado.rs ?? 0, { noBRL: true })}</TableCell>
                          <TableCell align={'center'}>{toBRL(row.acumulado.rs / row.acumulado.kg, { noBRL: true })}</TableCell>

                          <TableCell align={'center'}>{formatValue(row.diferenca.kg ?? 0)}</TableCell>
                          <TableCell align={'center'}>{toBRL(row.diferenca.rs ?? 0, { noBRL: true })}</TableCell>
                          <TableCell align={'center'}>{toBRL(row.diferenca.rs / row.diferenca.kg, { noBRL: true })}</TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <TableReportDevolution
            faturamento={req1}
            previsao={valorLocal}
            valorOutros={valorOutros}
            date={props.date}
            multipleDate={props.multipleDate}
          />
        </>
      )}
    </>
  )
}

export default TableReportBilled
