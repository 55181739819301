import CustomerControlType from 'types/customerControl/CustomerControlType'
import { StatusDocument } from './StatusDocument'

export function CheckStatusDocument({ item }: { item: CustomerControlType }) {
  if (item?.pf?.cpf) {
    const { ir, document } = item?.pf?.docs ?? { ir: {}, document: {} }
    const isNew =
      ir?.status === 'PENDENTE DE APROVAÇÃO' &&
      document?.status === 'PENDENTE DE APROVAÇÃO'
    const status =
      ir?.status === 'APROVADO' && document?.status === 'APROVADO'
        ? 'Aprovado'
        : 'Pendente'
    return <StatusDocument title={isNew ? 'Novo' : status} />
  }

  const {
    // declaracaoTributaria,
    antepenultimateBalance,
    lastBalance,
    listAssets,
    penultimateBalance,
    cnpjCard,
    contractualAlteration,
    socialContract,
    stateRegistration
  } = item?.pj?.docs ?? {
    // declaracaoTributaria: {},
    antepenultimateBalance: {},
    lastBalance: {},
    listAssets: {},
    penultimateBalance: {},
    cnpjCard: {},
    contractualAlteration: {},
    socialContract: {},
    stateRegistration: {}
  }

  if (item?.declaration?.status === 'REJEITADO') {
    return <StatusDocument title="Reprovado" />
  }

  const isNew =
    antepenultimateBalance?.status === 'PENDENTE DE APROVAÇÃO' &&
    lastBalance?.status === 'PENDENTE DE APROVAÇÃO' &&
    listAssets?.status === 'PENDENTE DE APROVAÇÃO' &&
    penultimateBalance?.status === 'PENDENTE DE APROVAÇÃO'
  const status =
    item?.declaration?.status === 'APROVADO' &&
    // antepenultimateBalance?.status === 'APROVADO' &&
    lastBalance?.status === 'APROVADO' &&
    listAssets?.status === 'APROVADO' &&
    cnpjCard?.status === 'APROVADO' &&
    contractualAlteration?.status === 'APROVADO' &&
    socialContract?.status === 'APROVADO' &&
    stateRegistration?.status === 'APROVADO'
    // penultimateBalance?.status === 'APROVADO'
      ? 'Aprovado'
      : 'Pendente'
  return <StatusDocument title={isNew ? 'Novo' : status} />
}
