import { Stack, Typography } from '@mui/material'

import React from 'react'

interface Props {
  title: string
  children?: React.ReactNode
}

export const Header: React.FC<Props> = ({ title, children }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography component="strong" variant="body1" fontWeight="bold">
        {title}
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="flex-end"
        minWidth={300}
      >
        {children}

        {/* <Button type="button" mold="primary" onClick={onSelect}>
          Buscar
        </Button> */}
      </Stack>
    </Stack>
  )
}
