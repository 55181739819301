
import { SearchOutlined } from '@mui/icons-material'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import React from 'react'

function FTHeader(props: any) {
  // const [searchParam, setSearchParam] = React.useState('')
  // const [param, setParam] = React.useState<string | null>(null)

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <FormControl variant='standard'>
        <InputLabel htmlFor='input-with-icon-adornment'>
          Pesquisar
        </InputLabel>
        <Input
          style={{ width: 250 }}
          id='input-with-icon-adornment'
          onChange={(event) => { props.search(event.target.value) }}
          endAdornment={
            <InputAdornment position='end'>
              <SearchOutlined />
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  )
}

export default FTHeader
