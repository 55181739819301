import { Box, TextField } from '@mui/material'
import React from 'react'
import TableReportVolume from './components/TableReportVolume'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import ModulesClient from './components/ModulesClient'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import 'moment/locale/pt-br'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'

const ReportVolume: React.FC = () => {
  const [value, setValue] = React.useState('1')
  const [valueDate, setValueDate] = React.useState<any | null>(new Date())

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Relatórios', url: '/home/report' },
          { text: 'Volume x R$Kg' }
        ]}
      />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <h4>Relatório Volume x R$Kg: {moment(valueDate).locale('pt-br').format('MMMM YYYY')}</h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '10px',
            marginRight: '25px'
          }}
        >
          <div style={{ marginRight: '10px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data"
                value={valueDate}
                onChange={(newValue) => {
                  setValueDate(newValue)
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </div>
          {/* <LoadingButton
            style={{
              backgroundColor: '#4060E6',
              borderRadius: '2px',
              width: '122px',
              height: '35px',
              color: 'white',
              marginTop: '10px'
            }}
            variant="outlined"
            loading={loading}
            onClick={() => getClickData()}
          >
            {loading ? '' : 'Pesquisar'}
          </LoadingButton> */}
        </div>
        <div>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Clientes" value="1" />
                <Tab label="Mês" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1"><ModulesClient date={valueDate} /></TabPanel>
            <TabPanel value="2"><TableReportVolume date={valueDate} /> </TabPanel>
          </TabContext>
        </div>
      </Box>
    </>
  )
}

export default ReportVolume
