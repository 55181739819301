import {
  Approved,
  Disapproved,
  New,
  Pending
} from '../tableCustomerControl.style'

export function StatusDocument({ title }: { title: string }) {
  switch (title) {
    case 'Novo':
      return <New label={title} variant="outlined" size="small" />
    case 'Pendente':
      return <Pending label={title} variant="outlined" size="small" />
    case 'Reprovado':
      return <Disapproved label={title} variant="outlined" size="small" />
    case 'Aprovado':
      return <Approved label={title} variant="outlined" size="small" />
    default:
      return <New label={title} variant="outlined" size="small" />
  }
}
