import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import {
  Box,
  CircularProgress,
  Icon
} from '@mui/material'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportBilling, getReportTipoPapel } from 'services/report/ReportBilling'
import TableAdmBody from './TableAdmBody'
import { getBudgetRealizedMonthAndYearItems } from 'services/budgetRealized/BudgetRealizedService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

interface Props {
  date: string
  req: any
}

const TableAdmHead: React.FC<Props> = (props) => {
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<any[]>([])
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const [valorRow] = useState<any[]>([
    {
      text: 'Previsto',
      value: 1
    },
    {
      text: 'Realizado',
      value: 2
    }
  ])

  const [setDataReq1] = useState<any>([])

  const queryMultiple = () => {
    const req1 = useQuery(['sales-report', mes, ano],
      async () => {
        return getReportBilling(mes, ano)
      },
      {
        onSuccess: (data) => {
          console.log('TablePMHead=>req1=>getReportBilling =>', data)
          setDataReq1(data)
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false
      }
    )
    const req2 = useQuery(['papel-report', props.date],
      async () => {
        console.log('data para getReportTipoPapel', props.date)
        return getReportTipoPapel(props.date)
      },
      {
        onSuccess: (data) => {
          console.log('TablePMHead=>req2=>getReportTipoPapel =>', data)
          const tipoDeDado = typeof data
          let arrayData: any
          console.log('Tipo de dado:', tipoDeDado)
          if (Array.isArray(data)) {
            console.log('É um array:', data)
          } else if (typeof data === 'object' && data !== null && Symbol.iterator in data) {
            console.log('É um objeto iterável:', data)
            arrayData = Array.from(data)
            console.log('Array:', arrayData)
          } else {
            console.log('Não é um objeto iterável:', data)
            arrayData = Object.values(data)
            console.log('Array personalizado:', arrayData)
          }
          dataHandle(arrayData)
        },
        onError: (error) => {
          console.log(
            'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
            error
          )
        },
        refetchOnWindowFocus: false
      }
    )
    const req3 = useQuery(['realized', null],
    async () => {
      return getBudgetRealizedMonthAndYearItems(mes, ano)
    },
    {
      onSuccess: (data) => {
        console.log('TablePMHead=>req3=>getBudgetRealizedMonthAndYearItems =>', data)
      },
      onError: (error) => {
        console.log(
          'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
          error
        )
      },
      refetchOnWindowFocus: false
    })
    return [req1, req2, req3]
  }
  const [req1, req2, req3] = queryMultiple()
  const dataHandle = (realized) => {
    console.log('realized', realized)
    const data: any[] = []

    /* Produção de Caixas (Pçs) */
    data.push({
      _id: 'ton-5',
      product: 'Produção de Caixas (Kg)',
      prev: 0,
      realized: 0
    })

    /* Produção de Papel (Kg)  */
    data.push({
      _id: 'ton-6',
      product: 'Produção de Papel (Kg)',
      prev: 0,
      realized: 0
    })

    setRows(data)
  }
  const isLoadingAnyQuery = [req1, req2, req3].some(
    (query) => query.isLoading || query.isFetching
  )
  const hasErrorInAnyQuery = [req1, req2, req3].some(
    (query) => query.isError
  )
  const allQueriesSuccess = (
    req1.isSuccess && !req1.isError && !req1.isFetching && !req2.isError && !req2.isFetching && !req3.isError && !req3.isFetching
  )

  let result: any

  if (isLoadingAnyQuery) {
    result = (
      <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          style={{
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          {loading
? (
            <>
              <CircularProgress variant="determinate" value={progress} />
              <p style={{ color: 'green', marginTop: '8px' }}>Aguarde: {progress}%</p>
            </>
          )
: (
          (req1 === null || req1 === undefined) ||
          (req2 === null || req2 === undefined)
? (
            <p style={{ color: 'red', marginTop: '8px' }}>
              Não houve resposta do sistema LN. Inconsistências técnicas na comunicação no momento.
            </p>
          )
: (
            <p>Requisição bem-sucedida!</p>
          )
)
        }
      </Box>
    )
  }

  if (hasErrorInAnyQuery) {
    result = (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }

  if (allQueriesSuccess) {
    result = (
      <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: '100%' }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="right" >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'right' }}>
                  <span style={{
                              marginRight: '15%',
                              width: '85%'
                              }}><strong></strong>
                  </span>
                </div>
                </TableCell>
                {valorRow.map((item, index) => {
                  return (
                    <TableCell align="center" colSpan={1} key={index}>
                      {item.text}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                  return (
                    <StyledTableRow key={row._id} style={row._id === 'total' ? { fontWeight: 'bold', textShadow: '0px 0 black, 0 0.2px black, 0.2px 0 black, 0 0px black' } : {}}>
                      <StyledTableCell align="center" style={{ wordBreak: 'break-word' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <span style={{
                                      marginRight: '5%',
                                      width: '50%',
                                      textTransform: 'uppercase',
                                      fontWeight: 'bold'
                                      }}>{row.product}</span>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ wordBreak: 'break-word' }}>
                        <span style={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold'
                        }}>
                        {
                          Number(row.prev).toLocaleString('pt-BR')
                        }
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ wordBreak: 'break-word' }}>
                        <span style={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold'
                        }}>
                          {
                            Number(row.realized).toLocaleString('pt-br')
                          }
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TableAdmBody date={props.date} req={req3.data?.data[1]}/>
    </>
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress < 100) {
            return prevProgress + 10
        } else {
            setLoading(false)
            clearInterval(interval)
            return prevProgress
        }
      })
    }, 2000)

    return () => {
        clearInterval(interval)
    }
  }, [])

  return result
}

export default TableAdmHead
