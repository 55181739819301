import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'
import { toast } from 'react-toastify'
import {
  updateBlockClient
} from 'services/customerControl/CustomerControlServices'
import { useFormik } from 'formik'
import { useMutation } from 'react-query'

interface ModalView {
  open: boolean
  close: () => void
  data: any
}

const ModalBlockClient = (props: ModalView) => {
  const { open, close, data } = props

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    updateBlockClient,
    {
      onSuccess: (data) => {
        console.log(data)
        toast.success(`Cliente ${props?.data?.isBlocked ? 'desbloqueado' : 'bloqueado'} com sucesso!`)
        close()
      },
      onError: (e: any) => {
        if (e.response) {
          toast.error(e.response.data.message)
        } else {
          toast.error(`Não foi possível ${props?.data?.isBlocked ? 'desbloquear' : 'bloquear'} o cliente.`)
        }
      }
    }
  )

  const { errors, touched, values, handleChange, handleSubmit } =
    useFormik({
      onSubmit: async (values: any) => {
        mutateUpdate({
          clientId: data._id,
          description: values.description
        })
      },
      enableReinitialize: true,
      initialValues: {
        description: ''
      }
    })

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">
          Explique o motivo do {props?.data?.isBlocked ? 'desbloqueio' : 'bloqueio'}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sx={{ paddingTop: 1 }}>
              <TextField
                error={Boolean(errors.description && touched.description)}
                value={values.description}
                variant="outlined"
                fullWidth
                autoComplete="off"
                label="Descreva"
                multiline
                minRows={4}
                required
                helperText={
                  errors.description && touched.description ? errors.description : null
                }
                onChange={handleChange('description')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingX: 3, paddingBottom: 3 }}>
          <Button
            onClick={close}
            style={{
              borderRadius: '2px',
              backgroundColor: '#EE207A',
              color: 'white'
            }}
            disabled={isLoadingUpdate}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            style={{
              borderRadius: '2px',
              backgroundColor: '#4060E6',
              color: 'white'
            }}
            disabled={isLoadingUpdate}
          >
            Enviar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ModalBlockClient
