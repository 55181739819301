import { httpClient } from '../../config/httpClient'
import FaqType from '../../types/faq/FaqType'

export const createFaq = async (dadosForm: FaqType) => {
  return await httpClient.post('/duvidasFrequentes', dadosForm)
}

export const getFaq = async () => {
  return await httpClient.get('/duvidasFrequentes')
}

export const deleteFaq = async (id: string) => {
  return await httpClient.delete(`/duvidasFrequentes/${id}`)
}

export const updateFaq = async (id: string, dadosForm: FaqType) => {
  return await httpClient.put(`/duvidasFrequentes/${id}`, dadosForm)
}
