/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import {
  DialogStyle
} from '../../reportControl/deliveryMap/styles/dialogMap.styles';
import { FieldArray, Form, Formik, FormikErrors } from 'formik';
import { toast } from 'react-toastify';
import { Add, Clear } from '@mui/icons-material';
import { httpClient } from 'config/httpClient';
import DatePicker from '@mui/lab/DatePicker';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { ModalHead } from './ModalHead';
import { onlyNumbersOrPoint } from 'helpers/stringHelper';
import moment from 'moment';

interface Props {
  open: boolean
  close: () => void
  closeSuccess: () => void
  data?: any
}

const ModalReplanejarPedido: React.FC<Props> = (props) => {
  const [input, setInput] = useState('');
  const [valueQtd, setValueQtd] = useState(0);
  const [loading, setLoading] = useState(false);
  console.log('ModalReplanejarPedido => ', props.data);

  const closeModal = () => props.close();

  const checkErrorRepresentatives = (item: unknown, name: string) => {
    const error = item as FormikErrors<{ [key: string]: string }>;
    return Boolean(error?.[name]);
  };

  const checkErrorTouchedStateEnrollment = (item: unknown, name: string) => {
    const error = item as FormikErrors<{ [key: string]: string }>;
    return Boolean(error?.[name]);
  };

  const getErrorStateEnrollment = (item: unknown, name: string) => {
    const error = item as FormikErrors<{ [key: string]: string }>;
    return error?.[name] ? error?.[name] : '';
  };

  const handleChangeQuantity = (quantityTotal: number, value: number, loteMinimo: number, entregas: any) => {
    // if (loteMinimo > value) {
    //   toast.warning(
    //     'A quantidade por data não pode ser menor que o lote mínimo.'
    //   );
    //   // return false;
    // }

    if (quantityTotal < value) {
      toast.warning(
        'A quantidade por data não pode ser maior que a quantidade total. ');
      return false;
    }

    const sumTotal = entregas?.reduce((acc, currentValue) => acc + Number(currentValue?.quantidade ?? 0), 0)

    if (sumTotal > quantityTotal) {
      toast.warning(
        'A soma das quantidades por data não pode ser maior que a quantidade total.'
      );
      return false;
    }

    return true;
  };

  return (
    <>
      <DialogStyle
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHead title="Replanejar pedido" />

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxHeight: '80vh',
            overflowY: 'auto'
          }}
        >
          {/* {loading && (
            <Stack justifyContent="center" alignItems="center" height={200}>
              <CircularProgress />
            </Stack>
          )} */}

          <>
            <Formik
              initialValues={{
                pedidoFts:
                  props?.data?.pedidoFts?.map((item) => ({
                    pedido: item?.pedido,
                    ft: item?.ft?._id,
                    preco: item?.preco,
                    codItem: item?.codItem,
                    clientID: item?.clientID,
                    loteMinimo: item?.loteMinimo ?? item?.budget?.loteMinimo,
                    quantidadeTotal: item?.quantidadeTotal ?? '',
                    entregas: item?.entregas
                  })) ?? []
              }}
              // validationSchema={validationReplanejarPedidoSchema}
              onSubmit={async (values) => {
                console.log('values', values);
                let hasErrorQuantity = false;
                values?.pedidoFts?.forEach(
                  (value: any) => {
                    const validate = value?.entregas?.filter((item: any) => !item?.quantidade)

                    if (!value?.entregas?.length) {
                      hasErrorQuantity = true;
                    }
                  }
                )

                if (hasErrorQuantity) {
                  toast.warning('Informe a quantidade por data de entrega para todas as FTs.')
                  return
                }

                let hasErrorMinimumLot = false;
                values?.pedidoFts?.forEach(
                  (value: any) => {
                    const validate = value?.entregas?.filter((item: any) => item?.quantidade < value?.loteMinimo)

                    if (validate?.length > 0) {
                      hasErrorMinimumLot = true;
                    }
                  }
                )

                if (props?.data?.client?.pilotLot === false) {
                  if (hasErrorMinimumLot) {
                    toast.warning('Cliente não tem Lote Piloto.')
                    console.log('Cliente não tem Lote Piloto.')
                    return
                  }
                } else {
                  toast.success('Cliente pode pedir abaixo do lote mínimo.')
                  console.log('Cliente ' + props?.data?.client?.typeClient + ' com Lote Piloto. ')
                  console.log('Depois de Replanejado ID =>  ' + props?.data?._id)
                  console.log('Depois de Replanejado pedidoFts =>  ' + props?.data?.pedidoFts)
                  console.log('Depois de Replanejado entregas =>  ' + props?.data?.pedidoFts?.entregas)
                }

                let hasErrorTotal = false;
                values?.pedidoFts?.forEach(
                  (value: any) => {
                    const validate = value?.entregas?.reduce((acc, item: any) => acc + Number(item?.quantidade ?? 0), 0)

                    if (validate > value?.quantidadeTotal) {
                      hasErrorTotal = true;
                    }
                  }
                )

                if (hasErrorTotal) {
                  toast.warning('A quantidade por data não pode ser maior que a quantidade total.')
                  return
                }

                let hasErrorDate = false;
                values?.pedidoFts?.forEach(
                  (value: any) => {
                    const validate = value?.entregas?.filter((item: any) => {
                      const formatDate = moment(item?.data)
                      const today = moment()

                      if (moment(today).isAfter(formatDate)) {
                        return true
                      }
                      return false
                    })

                    if (validate?.length) {
                      hasErrorDate = true;
                    }
                  }
                )

                if (hasErrorDate) {
                  toast.warning('A data de entrega não pode ser menor ou igual a data atual.')
                  return
                }

                try {
                  setLoading(true);
                  console.log('Loading TRUE')
                  await httpClient.put(
                    `/pedidos/replanejar/${props.data?._id}`,
                    values
                  );

                  props.closeSuccess();
                } catch (error: any) {
                  toast.error(error?.response?.data?.message);
                  setLoading(false);
                  console.log('Loading FALSE')
                }
              }}
            >
              {({ values, errors, touched, setFieldValue, setFieldError, handleSubmit }) => (
                <Form>
                  <Grid container spacing={4}>
                    <FieldArray
                      name="pedidoFts"
                      validateOnChange={false}
                      render={(array) => (
                        <>
                          {values?.pedidoFts?.map((value, index) => (
                            <Grid item xs={8}>
                              <Typography fontWeight="bold" sx={{ mb: 2 }}>
                                FT {value?.codItem ?? value?.ft?.itemReference}
                              </Typography>

                              <Grid container spacing={4}>
                                <FieldArray
                                  name="entregas"
                                  validateOnChange={false}
                                  render={(arrayHelpers) => (
                                    <>
                                      <Grid item xs={12}>
                                        <Typography
                                          fontWeight="bold"
                                          sx={{ mb: 2 }}
                                        >
                                          Quantidades solicitadas
                                        </Typography>
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{ marginBottom: 3 }}
                                        >
                                        <Grid item xs={6}>
                                          <TextField
                                            fullWidth
                                            id="standard-basic"
                                            label="Lote mínimo"
                                            value={values.pedidoFts?.[index]
                                              ?.loteMinimo}
                                            variant="standard"
                                            disabled
                                          />
                                        </Grid>
                                          <Grid item xs={6}>
                                            <TextField
                                              error={
                                                !!(
                                                  errors.pedidoFts?.[index]
                                                    ?.quantidadeTotal &&
                                                  touched.pedidoFts?.[index]
                                                    ?.quantidadeTotal
                                                )
                                              }
                                              value={
                                                values.pedidoFts?.[index]
                                                  ?.quantidadeTotal
                                              }
                                              fullWidth
                                              autoComplete="off"
                                              variant="standard"
                                              label="Quantidade Total"
                                              required
                                              helperText={
                                                errors.pedidoFts?.[index]
                                                  ?.quantidadeTotal
                                              }
                                              onChange={(event) => {
                                                  if (Number(event?.target?.value ?? 0) < 10000000) {
                                                    setFieldValue(
                                                    `pedidoFts.${index}.quantidadeTotal`,
                                                    onlyNumbersOrPoint(
                                                      event.target.value
                                                    )
                                                    )
                                                    setInput(event.target.value)
                                                    setValueQtd(parseInt(event.target.value))
                                                  }
                                                }
                                              }
                                            />
                                          </Grid>
                                          {/* <Grid item xs={12}>
                                  <TextField
                                    value={props?.data?.budget?.loteMinimo}
                                    fullWidth
                                    autoComplete="off"
                                    variant="standard"
                                    label="Lote mínimo"
                                    disabled
                                  />
                                </Grid> */}
                                        </Grid>
                                        {values.pedidoFts?.[
                                          index
                                        ]?.entregas.map(
                                          (entrega, indexPedido) => (
                                            <Grid
                                              container
                                              spacing={3}
                                              key={`key_enrollment_${indexPedido}`}
                                              style={{
                                                marginBottom: '30px',
                                                paddingBottom: '24px'
                                              }}
                                            >
                                              <Grid item xs={11}>
                                                <Grid container spacing={3}>
                                                  <Grid item xs={8}>
                                                    <TextField
                                                      error={
                                                        checkErrorRepresentatives(
                                                          errors.pedidoFts?.[
                                                            index
                                                          ]?.entregas?.[
                                                            indexPedido
                                                          ],
                                                          'quantidade'
                                                        ) &&
                                                        checkErrorTouchedStateEnrollment(
                                                          touched.pedidoFts?.[
                                                            index
                                                          ]?.entregas?.[
                                                            indexPedido
                                                          ],
                                                          'quantidade'
                                                        )
                                                      }
                                                      value={entrega.quantidade}
                                                      fullWidth
                                                      autoComplete="off"
                                                      variant="standard"
                                                      label="Quantidade"
                                                      required
                                                      helperText={
                                                        checkErrorRepresentatives(
                                                          errors.pedidoFts?.[
                                                            index
                                                          ]?.entregas?.[
                                                            indexPedido
                                                          ],
                                                          'quantidade'
                                                        ) &&
                                                        checkErrorTouchedStateEnrollment(
                                                          touched.pedidoFts?.[
                                                            index
                                                          ]?.entregas?.[
                                                            indexPedido
                                                          ],
                                                          'quantidade'
                                                        )
                                                          ? getErrorStateEnrollment(
                                                              errors
                                                                .pedidoFts?.[
                                                                index
                                                              ]?.entregas?.[
                                                                indexPedido
                                                              ],
                                                              'quantidade'
                                                            )
                                                          : null
                                                      }
                                                      onChange={(event) => {
                                                        if (
                                                          handleChangeQuantity(
                                                            Number(values.pedidoFts[
                                                              index
                                                            ].quantidadeTotal ?? 0),
                                                            Number(onlyNumbersOrPoint(
                                                              event.target.value
                                                            ) ?? 0),
                                                            Number(values.pedidoFts[
                                                              index
                                                            ].loteMinimo ?? 0),
                                                            values.pedidoFts?.[
                                                              index
                                                            ]?.entregas
                                                          )
                                                        ) {
                                                          setFieldValue(
                                                            `pedidoFts.${index}.entregas.${indexPedido}.quantidade`,
                                                            onlyNumbersOrPoint(
                                                              event.target.value
                                                            )
                                                          );
                                                        } else {
                                                          setFieldError(`pedidoFts.${index}.entregas.${indexPedido}.quantidade`, 'A quantidade por data não pode ser maior que a quantidade total.')
                                                          setFieldValue(
                                                            `pedidoFts.${index}.entregas.${indexPedido}.quantidade`,
                                                            ''
                                                          );
                                                        }
                                                        setInput(event.target.value)
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={4}>
                                                    <LocalizationProvider
                                                      dateAdapter={
                                                        AdapterDateFns
                                                      }
                                                      locale={ptBR}
                                                    >
                                                      <DatePicker
                                                        views={[
                                                          'year',
                                                          'month',
                                                          'day'
                                                        ]}
                                                        label="Data"
                                                        minDate={new Date()}
                                                        inputFormat="dd/MM/yyyy"
                                                        value={
                                                          new Date(
                                                            entrega.data
                                                          ) ?? null
                                                        }
                                                        onChange={(value) => {
                                                          setFieldValue(
                                                            `pedidoFts.${index}.entregas.${indexPedido}.data`,
                                                            value
                                                          );
                                                        }}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            InputProps={{
                                                              startAdornment: (
                                                                <Box
                                                                  paddingRight={
                                                                    2
                                                                  }
                                                                >
                                                                  {
                                                                    params
                                                                      ?.InputProps
                                                                      ?.endAdornment
                                                                  }
                                                                </Box>
                                                              )
                                                            }}
                                                            className="w-100"
                                                            variant="standard"
                                                            autoComplete="off"
                                                            required
                                                            helperText={moment(entrega.data).isBefore(new Date()) ? 'Data inválida.' : ''}
                                                          />
                                                        )}
                                                      />
                                                    </LocalizationProvider>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid item xs={1}>
                                                <Button
                                                  style={{
                                                    marginRight: '10px',
                                                    backgroundColor: values.pedidoFts?.[index]
                                                    ?.entregas?.length <= 1 ? '#ccc' : '#EE207A',
                                                    color: '#FFFFFF',
                                                    textTransform: 'capitalize'
                                                  }}
                                                  onClick={() => {
                                                    setFieldValue(
                                                      `pedidoFts.${index}.entregas`,
                                                      values.pedidoFts[
                                                        index
                                                      ]?.entregas?.filter(
                                                        (_, cIndex) =>
                                                          cIndex !== indexPedido
                                                      )
                                                    );
                                                    // arrayHelpers.remove(
                                                    //   indexPedido
                                                    // );
                                                  }}
                                                  color="error"
                                                  variant="contained"
                                                  disabled={
                                                    values.pedidoFts?.[index]
                                                      ?.entregas?.length <= 1
                                                  }
                                                >
                                                  <Clear />
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          )
                                        )}

                                        <Button
                                          style={{
                                            marginRight: '10px',
                                            backgroundColor: '#FFFFFF',
                                            color: '#A8A8A8',
                                            border: '1px solid #A8A8A8',
                                            textTransform: 'inherit'
                                          }}
                                          onClick={() => {
                                            setFieldValue(
                                              `pedidoFts.${index}.entregas`,
                                              [
                                                ...values.pedidoFts[index]
                                                  ?.entregas,
                                                {
                                                  quantidade: input,
                                                  data: ''
                                                }
                                              ]
                                            );
                                            // arrayHelpers.push({
                                            //   quantidade: '',
                                            //   data: ''
                                            // });
                                          }}
                                          color="success"
                                          variant="contained"
                                        >
                                          <Add /> Adicionar quantidade
                                        </Button>
                                      </Grid>
                                    </>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      )}
                    />

                    <Grid item md={12} className="text-right">
                      <Button
                        style={{
                          marginRight: '10px',
                          backgroundColor: '#EE207A',
                          border: '1px solid #EE207A',
                          color: '#FFFF',
                          textTransform: 'inherit'
                        }}
                        variant="contained"
                        onClick={closeModal}
                        disabled={loading}
                      >
                        Cancelar
                      </Button>
                      <Button
                        style={{
                          marginRight: '10px',
                          backgroundColor: true ? '#4060e6' : '#A8A8A8',
                          color: '#FFFFFF',
                          textTransform: 'inherit'
                        }}
                        type="submit"
                        color="success"
                        variant="contained"
                        disabled={loading}
                      >
                        {loading ? 'Replanejando...' : 'Replanejar'}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            {
            /*
              <Typography fontWeight="bold" sx={{ mt: 2 }}>
                Quantidades solicitadas
              </Typography>
              <DivRow style={{ marginTop: '-20px' }}>
                <Grid
                  container
                  spacing={3}
                  mt={0}
                  style={{ maxWidth: '800px' }}
                >
                  {!props.data?.entregas?.length && (
                    <Grid item xs={12}>
                      <Typography sx={{ mt: 2 }}>
                        Nenhuma quantidade solicitada.
                      </Typography>
                    </Grid>
                  )}
                  {props.data?.entregas?.map((entrega) => (
                    <React.Fragment key={entrega?._id}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Quantidade solicitada"
                          value={entrega?.quantidade}
                          variant="standard"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptBR}
                        >
                          <DatePicker
                            views={['year', 'month', 'day']}
                            label="Mês e ano"
                            inputFormat="dd/MM/yyyy"
                            value={moment(entrega.data)}
                            onChange={(value) => {
                              // setFieldValue('date', value)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  startAdornment: (
                                    <Box paddingRight={2}>
                                      {params?.InputProps?.endAdornment}
                                    </Box>
                                  )
                                }}
                                className="w-100"
                                variant="standard"
                                autoComplete="off"
                                required
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </React.Fragment>
                  ))}

                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Button
                        style={{
                          marginRight: '10px',
                          backgroundColor: '#FFFFFF',
                          color: '#A8A8A8',
                          border: '1px solid #A8A8A8',
                          textTransform: 'inherit'
                        }}
                        onClick={() => {
                          // handleApprovePedidoFt(props.data?._id);
                        }}
                        color="success"
                        variant="contained"
                      >
                        Adicionar quantidade
                      </Button>

                      <Button
                        style={{
                          marginRight: '10px',
                          backgroundColor: '#EE207A',
                          color: '#FFFFFF',
                          textTransform: 'capitalize'
                        }}
                        onClick={() => {
                          // handleReprovePedidoFt(props.data?._id);
                        }}
                        color="error"
                        variant="contained"
                      >
                        Remover quantidade
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </DivRow> */
              }
          </>

          {
          /*
          <ButtonRow
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end'
            }}
          >
            <Button
              style={{
                marginRight: '10px',
                backgroundColor: '#FFFF',
                border: '1px solid #EE207A',
                color: '#EE207A',
                textTransform: 'inherit'
              }}
              variant="contained"
              onClick={closeModal}
            >
              Cancelar
            </Button>
            <Button
              style={{
                marginRight: '10px',
                backgroundColor: true ? '#1AB1B0' : '#A8A8A8',
                color: '#FFFFFF',
                textTransform: 'inherit'
              }}
              onClick={() => {
                alertSwalCancel(() => {
                  console.log('replanejar e suspender a ordem anterior');
                });
                // handleGenerateOrder();
              }}
              color="success"
              variant="contained"
              // disabled={pedido?.isBlockSellOrder}
            >
              Replanejar
            </Button>
          </ButtonRow>
          */
          }
        </DialogContent>
      </DialogStyle>
    </>
  );
};

export default ModalReplanejarPedido;
