import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AlertStatus from './AlertStatus'
import MoreMenu from '../../../components/view/MoreMenu'
import {
  deleteProfile,
  getProfile
} from '../../../services/profile/ProfileService'
import ProfileType from '../../../types/profiles/ProfileType'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TablePagination } from '@mui/material'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    height: 73
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: 73
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

interface Props {
  filter: any
}

export default function TableUsers({ filter }: Props) {
  const [dados, setDados] = React.useState<ProfileType[]>([])
  const [page, setPage] = React.useState(0)

  const [dataSource, setDataSource] = React.useState<any[]>([])

  React.useEffect(() => {
    listProfiles()
  }, [])

  const listProfiles = async () => {
    try {
      const response = await getProfile()
      setDados(response.data)
      setDataSource(response.data)
      return response
    } catch (error: any) {
      console.log('erro', error.response.data)
    }
  }

  React.useEffect(() => {
    if (filter?.name) {
      const searchResult = dataSource.filter((row) => {
        if (row?.name?.toUpperCase().includes(filter?.name.toUpperCase())) {
          return true
        }

        return false
      })

      setPage(0)
      setDados(searchResult)
    } else {
      setDados(dataSource)
    }
  }, [filter?.name])

  const navigate = useNavigate()
  const edit = (row: ProfileType) => {
    navigate('/home/profile-form', { state: { row: row, disabled: false } })
  }

  const delet = async (id: string) => {
    try {
      const { data } = await deleteProfile(id)
      if (!data) {
        toast.success('Perfil inativado!')
        setTimeout(() => {
          listProfiles()
        }, 2500)
      } else {
        toast.error('Erro ao inativar Perfil')
      }
    } catch (error: any) {
      console.log('erro', error.response.data)
    }
  }

  return (
    <TableContainer style={{ marginTop: '30px' }} component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nome do Perfil</StyledTableCell>
            <StyledTableCell>Data de Cadastro</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <Can
              module={MODULES.PERFIL}
              permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
            >
              <StyledTableCell align="right">Ações</StyledTableCell>
            </Can>
          </TableRow>
        </TableHead>
        <TableBody>
          {dados.slice(page * 10, page * 10 + 10).map((row) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell>{row.name}</StyledTableCell>
              <StyledTableCell>
                {moment(String(row.createdAt)).format('DD/MM/YYYY')}
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ width: ['auto', '10%'] }}>
                <AlertStatus status={row.status} />
              </StyledTableCell>
              <Can
                module={MODULES.PERFIL}
                permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
              >
                <StyledTableCell align="right" sx={{ width: ['auto', '8%'] }}>
                  <MoreMenu
                    module={MODULES.PERFIL}
                    isValidationPermissions
                    edit={() => edit(row)}
                    // view={() => view(row)}
                    delete={
                      row.status
                        ? async () => delet(String(row._id))
                        : undefined
                    }
                  />
                </StyledTableCell>
              </Can>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10]}
        rowsPerPage={10}
        component="div"
        count={dados.length}
        page={page}
        onPageChange={(event: unknown, newPage: number) => {
          setPage(newPage)
        }}
      />
      {/* <GenericDialog allowBack open={openDialog} close={() => setOpenDialog(false)} content={<ProfileForm state={stateDialog} />} /> */}
    </TableContainer>
  )
}
