import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../models/IUser'

interface UserState {
  user: IUser | null
}
const initialState: UserState = {
  user: null
}

const userslice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    _addUser (state, action: PayloadAction<any>) {
      state.user = action.payload
    }

  }
})
export const userReducer = userslice.reducer
export const { _addUser } = userslice.actions
