import React, { useRef, useState } from 'react'
import { SalesReportTable } from '../components/SalesReportTable'
import {
  Box,
  Tab,
  Tabs,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField
} from '@mui/material'
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Button from 'components/form/Button'
import { ptBR } from 'date-fns/locale'
// import moment from 'moment'
// import { useQuery } from 'react-query'
// import { getCountNewClientsSalesReport } from 'services/report/SalesReport'
import { Clear, SearchOutlined } from '@mui/icons-material'
import { Header } from '../components/Header'
import { SalesReportNewClientTable } from '../components/SalesReportNewClientTable'

function a11yProps(index: number) {
  return {
    id: `stock-tab-${index}`,
    'aria-controls': `stock-tabpanel-${index}`
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`stock-tabpanel-${index}`}
      aria-labelledby={`stock-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0, paddingTop: 3 }}>{children}</Box>}
    </div>
  )
}

export const SalesReportContainer: React.FC = () => {
  const [value, setValue] = React.useState(0)

  const inputSearchRef = useRef<HTMLInputElement>(null)
  const [selectDate, setSelectDate] = React.useState<Date | null>(new Date())

  const [searchValue, setSearchValue] = useState('')
  const [search, setSearch] = useState('')

  // const {
  //   data: dataNewClient,
  //   refetch: refetchNewClients,
  //   isFetching: isFetchingNewClient
  // } = useQuery(
  //   'report-sales-report-new-clients',
  //   async () =>
  //     getCountNewClientsSalesReport(
  //       moment(selectDate).format('M'),
  //       moment(selectDate).format('YYYY')
  //     ),
  //   {
  //     refetchOnWindowFocus: false
  //   }
  // )

  // useEffect(() => {
  //   refetchNewClients()
  // }, [selectDate])

  const handleClearSearch = () => {
    setSearch('')
    setSearchValue('')
    // setOffset(0)
    if (inputSearchRef?.current?.value) {
      inputSearchRef.current.value = ''
    }
    // refetch()
  }

  const handleSearch = () => {
    if (!searchValue) {
      setSearch('')
      return
    }

    setSearch(searchValue)

    setTimeout(() => {
      // refetchNewClients()
    }, 300)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <Grid container spacing={3} mt={0} sx={{ marginLeft: 0 }}>
        <Grid xs={4}>
          <Header title="Relatório de Cliente" />
        </Grid>
        <Grid item xs={8}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '10px',
              marginRight: '25px'
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                views={['year', 'month']}
                label="Mês e Ano"
                inputFormat="MMMM yyyy"
                value={selectDate}
                onChange={(newValue) => {
                  setSelectDate(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" helperText={null} />
                )}
              />
            </LocalizationProvider>
            <LoadingButton
              sx={{
                // backgroundColor: '#4060E6',
                width: '122px',
                height: '40px',
                marginLeft: 2
              }}
              variant="contained"
              // loading={status === 'loading'}
              // disabled={status === 'loading'}
              // onClick={async () => refetch()}
            >
              {'Pesquisar'}
            </LoadingButton>
          </div>
        </Grid>
        {/* <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ marginRight: 3 }}
          >
            <Stack
              spacing={1}
              justifyContent="center"
              sx={{
                border: '1px solid #EEE',
                borderRadius: 1,
                padding: 2,
                minWidth: 140,
                minHeight: 120
              }}
            >
              {!isFetchingNewClient && (
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    fontWeight="bold"
                  >
                    Novos Clientes
                  </Typography>
                  <Typography
                    color="primary"
                    component="span"
                    variant="h5"
                    fontWeight="bold"
                  >
                    {dataNewClient ?? 0}
                  </Typography>
                  <Typography component="span" variant="body2">
                    {selectDate ? moment(selectDate).format('[em] MMMM') : ''}
                  </Typography>
                </>
              )}
              {isFetchingNewClient && (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress size={18} />
                </Box>
              )}
            </Stack>
          </Stack>
        </Grid> */}
        <Grid item xs={3} sx={{ paddingLeft: '0 !important' }}>
          <FormControl variant="standard" className="w-100">
            <InputLabel htmlFor="field-search">Pesquisar</InputLabel>
            <Input
              id="field-search"
              autoComplete="off"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              // onKeyUp={(event) => returnedFunction(event)}
              // onKeyPress={(event) => returnedFunction(event)}
              // onClick={(event) => returnedFunction(event)}
              inputRef={inputSearchRef}
              endAdornment={
                <InputAdornment position="end">
                  {/* <SearchOutlined /> */}
                  <Button
                    mold="primary"
                    type="button"
                    style={{
                      padding: 0,
                      paddingLeft: 8,
                      paddingRight: 8,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    onClick={handleSearch}
                  >
                    <SearchOutlined />
                  </Button>

                  {(!!search?.length ||
                    !!inputSearchRef?.current?.value?.length) && (
                    <Button
                      mold="red"
                      type="button"
                      style={{
                        padding: 0,
                        paddingLeft: 8,
                        paddingRight: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 4
                      }}
                      onClick={handleClearSearch}
                    >
                      <Clear />
                    </Button>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 3, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="relator-cliente"
        >
          <Tab label="NOVOS CLIENTES" {...a11yProps(0)} />
          <Tab label="TODOS" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <SalesReportNewClientTable
          filterDate={selectDate}
          filterSearch={search}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SalesReportTable filterDate={selectDate} filterSearch={search} />
      </TabPanel>
    </>
  )
}
