import React, { useState } from 'react'
import { Box, FormControlLabel, FormGroup, Switch, TextField } from '@mui/material'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import ModulesReportRealizedDay from './components/ModulesReportRealizedDay'
// import TextField from '@mui/material/TextField'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import moment from 'moment'
import 'moment/locale/pt-br'
import MultiDatePicker from 'react-multi-date-picker'
import ptBR from 'date-fns/esm/locale/pt-BR/index.js'
import TableReportStock from './components/TableReportStock'
import ReportRevenues from '../reportRevenues/ReportRevenues'
import { DatePicker, LoadingButton } from '@mui/lab'
import ModulesReportProduction from './metaProduction/ModulesReportProduction'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import ModulesEstoque from './estoque/ModulesEstoque'

const ReportRealizedDay: React.FC = () => {
  const [value, setValue] = useState('1')
  const [valueDate, setValueDate] = useState<any | null>(new Date())
  const [newDate, setNewDate] = useState<any | null>(new Date())
  const [valorData, setValorData] = useState<any>([])
  const [toggleTab, setToggleTab] = useState<boolean>(true)
  const [valueClick, setValueClick] = useState<any>([])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

  const handleDaysPicker = (days) => {
    const filteredDays: any[] = []
    days.forEach(day => {
      let different = 0
      filteredDays.forEach(fd => {
        if (moment.unix(fd.unix).format('MM') !== moment.unix(day.unix).format('MM')) {
          different++
        }
      })
      if (different === 0) {
        filteredDays.push(day)
      }
    })
    setValorData(filteredDays)
  }

  const CustomComponent = (props: any) => {
    React.useEffect(() => {
    }, [props])

    return (
      <div>
        <TextField
          style={{ width: '200px' }}
          id="standard-basic"
          label="Período"
          autoComplete="off"
          variant="standard"
          value={props.value.length > 1 ? `${props.value[0]} à ${props.value[props.value.length - 1]}` : props.value[0]}
          onClick={() => props.openCalendar()}
        />
      </div>
    )
  }

  const getClickData = () => {
    setValueClick(valorData)
  }

  const getNewDate = () => {
    setNewDate(valueDate)
  }

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Relatórios', url: '/home/report' },
          { text: 'Realizado Dia' }
        ]}
      />
        <h4>Relatório Realizado dia: {moment(valueDate).locale('pt-br').format('MMMM YYYY')}</h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '10px',
            marginRight: '25px'
          }}
        >
          <FormGroup>
            <FormControlLabel control={<Switch onClick={() => setToggleTab(!toggleTab)} />} label="Selecionar Periodo" />
          </FormGroup>
          <div style={{ marginRight: '10px' }} hidden={!toggleTab}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                mask='__/__/____'
                label="Data"
                value={valueDate}
                onChange={(newValue) => { setValueDate(newValue) }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div hidden={!toggleTab} style={{ marginLeft: '10px' }}>
          <LoadingButton
            style={{
              backgroundColor: '#4060E6',
              borderRadius: '2px',
              width: '122px',
              height: '35px',
              color: 'white',
              marginTop: '10px'
            }}
            variant="outlined"
            onClick={() => getNewDate()}
          >
            {'Pesquisar'}
          </LoadingButton>
          </div>
          <div hidden={toggleTab}>
            <MultiDatePicker
              hideWeekDays
              value={valorData}
              onChange={(e) => { handleDaysPicker(e) }}
              format="DD/MM/YYYY"
              multiple
              months={months}
              sort
              render={<CustomComponent />}
            />
          </div>
          <div hidden={toggleTab} style={{ marginLeft: '10px' }}>
          <LoadingButton
            style={{
              backgroundColor: '#4060E6',
              borderRadius: '2px',
              width: '122px',
              height: '35px',
              color: 'white',
              marginTop: '10px'
            }}
            variant="outlined"
            onClick={() => getClickData()}
          >
            {'Pesquisar'}
          </LoadingButton>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Vendas" value="1" />
                <Tab label="Produção" value="2" />
                <Tab label="Variação" value="3" />
                {/* <Tab label="Estoque" value="4" /> */}
                <Tab label="Estoque" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1"><ModulesReportRealizedDay date={newDate} multipleDate={valueClick} /></TabPanel>
            <TabPanel value="2"><ModulesReportProduction date={newDate} multipleDate={valueClick}/></TabPanel>
            <TabPanel value="3"><ReportRevenues date={newDate} multipleDate={valueClick}/></TabPanel>
            <TabPanel value="4"><TableReportStock date={newDate} /></TabPanel>
            <TabPanel value='5'><ModulesEstoque date={newDate} multipleDate={valueClick}/></TabPanel>
          </TabContext>
        </div>
      </Box>
    </>
  )
}

export default ReportRealizedDay
