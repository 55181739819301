import { FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'

interface Props {
  value: string
  onChange: (value: string) => void
}

export const SelectPriorityDeliveryMap: React.FC<Props> = ({ value, onChange }) => {
  return (
    <>
      <FormControl fullWidth>
        <Select
          value={value}
          onChange={(event) => onChange(event.target.value)}
          size="small"
        >
          <MenuItem value={'CRITICO'}>Crítico</MenuItem>
          <MenuItem value={'PRIORIDADE1'}>
            Prioridade 1
          </MenuItem>
          <MenuItem value={'PRIORIDADE2'}>
            Prioridade 2
          </MenuItem>
          <MenuItem value={'PRIORIDADE3'}>
            Prioridade 3
          </MenuItem>
        </Select>
      </FormControl>
    </>
  )
}
