import { httpClient } from 'config/httpClient'
import { ValuesFormCustomerControlType } from 'types/customerControl/FormCustomerControlType'
import PageableType from 'types/PageableType'
import CustomerControlType from '../../types/customerControl/CustomerControlType'

const getCustomer = async (): Promise<PageableType<CustomerControlType>> => {
  const { data } = await httpClient.get('/company')
  return data as PageableType<CustomerControlType>
}

const getCustomerPrevisions = async (): Promise<PageableType<CustomerControlType>> => {
  const { data } = await httpClient.get('/company/previsions')
  return data as PageableType<CustomerControlType>
}

const getOneCustomer = async (id: string): Promise<any[]> => {
  const { data } = await httpClient.get('/company/' + id)
  return data as any[]
}

const updateOrCreate = async (
  data: ValuesFormCustomerControlType,
  id?: number
) => {
  return httpClient({
    data,
    url: '/company',
    method: id ? 'PUT' : 'POST'
  })
}

export const createClient = async (dadosForm: ValuesFormCustomerControlType) => {
  const headers = {
    'Content-Type': 'multpart/form-data'
  }
  return await httpClient.post('/company', dadosForm, { headers })
}

export const updateClient = async (id: string, dadosForm: ValuesFormCustomerControlType) => {
  const headers = {
    'Content-Type': 'multpart/form-data'
  }
  return await httpClient.put(`/company/${id}`, dadosForm, { headers })
}

export const deleteClient = async (id: string) => {
  return await httpClient.delete(`/company/${id}`)
}

export { getCustomer, updateOrCreate, getOneCustomer, getCustomerPrevisions }
