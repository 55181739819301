// import axios from 'axios'
import { httpClient, httpLN } from 'config/httpClient'
import moment from 'moment'

export const getReportMonthly = async (
  mes: string,
  ano: string
): Promise<any> => {
  const { data } = await httpLN.get(
    `/faturamentos/cliente?mes=${mes}&ano=${ano}`
  )
  console.log('getReportMonthly =>', data)
  return data as any[]
}

export const getReportPedidosMensal = async (date: string): Promise<any> => {
  const { data } = await httpLN.get(`pedidos/dia-acumulado?data=${date}`)
  return data as any[]
}

export const getReportPedidosDiaKg = async (date: string, type: string): Promise<any> => {
  const { data } = await httpLN.get(`pedidos/kg-dia?data=${date}&tipo=${type}`)
  console.log('getReportPedidosDiaKg =>', data)
  return data as any[]
}

export const getReportMonthlyLocal = async (
  mes: string,
  ano: string
): Promise<any> => {
  const { data } = await httpClient.get(`/previsions/report/client?mes=${mes}&ano=${ano}`)
  console.log('getReportMonthlyLocal =>', data)
  return data as any[]
}

export const getReportMonthlyDays = async (
  mes: string,
  ano: string
): Promise<any> => {
  const { data } = await httpLN.get(`/pedidos/cliente?mes=${mes}&ano=${ano}`)
  console.log('getReportMonthlyDays =>', data)
  return data as any[]
}

export const getReportMonthlyStock = async (
  mes: string,
  ano: string
): Promise<any> => {
  const { data } = await httpLN.get(`/estoque/mensal?mes=${mes}&ano=${ano}`)
  console.log('getReportMonthlyStock =>', data)
  return data as any[]
}

export const getReportMonthlyLocalItem = async (
  mes: string,
  ano: string
): Promise<any> => {
  // const { data } = await httpClient.get(`/ft/items?month=${mes}&year=${ano}`)
  // return data as any[]
  const { data } = await httpLN.get(`/pedidos/cliente-cod-items?mes=${mes}&ano=${ano}`)
  console.log('getReportMonthlyLocalItem =>', data)
  return data as any[]
}

export const getReportMonthlyItem = async (
  mes: string,
  ano: string
): Promise<any> => {
  const dataSelecionada = moment(`${mes}/${ano}`, 'MM/YYYY')
  const dataInicio = dataSelecionada.startOf('month').format('DD/MM/YYYY')
  const dataFim = dataSelecionada.endOf('month').format('DD/MM/YYYY')
  const { data } = await httpLN.get(`/faturamentos/item-dia?dataInicio=${dataInicio}&dataFim=${dataFim}`)
  console.log('getReportMonthlyItem =>', data)
  return data as any[]
}
