/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-unused-vars */
export function getBase64(file: File, cb: any) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (error) {
    console.log('Error: ', error)
  }
}
export const getPromiseBase64 = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    // let fileInfo;
    let baseURL = ''
    // Make new FileReader
    const reader = new FileReader()

    // Convert the file to base64 text
    reader.readAsDataURL(file)

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      // console.log('Called', reader);
      baseURL = reader.result?.toString() ?? ''
      // console.log(baseURL);
      resolve(baseURL)
    }
    // console.log(fileInfo);
  })
}
