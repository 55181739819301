import { Button, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { StyledDropZone } from 'react-drop-zone'
import ImportantView from 'components/view/ImportantView'
import { Add, Remove } from '@mui/icons-material'

export interface FileType {
  url?: string
  file?: File
  _id?: string
}
interface Props {
  urls?: FileType[]
  disabled?: boolean
  onChange?: (files: FileType[]) => void
}

const maxLimitUploadFiles = 3

export function UploadDocsPreFT({
  urls = [],
  disabled = false,
  onChange
}: Props) {
  const [files, setFiles] = useState(urls)

  useEffect(() => {
    onChange?.(files)
  }, [files])

  const handleSelectFile = (file: File, _id?: string) => {
    if (disabled) {
      return
    }

    if (
      !file?.type ||
      (!file?.type?.includes('image') &&
        !file?.type?.includes('application/pdf'))
    ) {
      toast.warn('Selecione uma imagem ou PDF.')
      return
    }

    console.log('_id', _id)
    if (_id) {
      setFiles((state) =>
        state.map((item) => (item?._id === _id ? { ...item, file } : item))
      )

      return
    }

    setFiles([...files, { _id: `${Date.now()}-${Math.random()}`, file }])
  }

  const handleAddFile = () => {
    if (files.length >= maxLimitUploadFiles) {
      return
    }

    setFiles((state) => [...state, { _id: `${Date.now()}-${Math.random()}` }])
  }

  const handleRemoveFile = (_id: string) => {
    setFiles((state) => state.filter((item) => item?._id !== _id))
  }

  console.log('files', files)

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">Anexar arquivos</Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={handleAddFile}
          disabled={files?.length >= maxLimitUploadFiles}
          size="small"
          sx={{ minWidth: 'auto' }}
        >
          <Add />
        </Button>
      </Stack>

      {files?.map((item, index) => (
        <Stack spacing={2} key={index}>
          {(item?.file ?? item?.url) && (
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleRemoveFile(item?._id ?? '')}
                  size="small"
                  sx={{ minWidth: 'auto' }}
                >
                  <Remove />
                </Button>
                <Typography component="span" variant="body2">
                  {item?.file?.name ?? ''}
                </Typography>
              </Stack>

              <Typography
                component="a"
                href={item?.file ? URL.createObjectURL(item.file) : item.url}
                target="_blank"
                variant="body2"
                color="primary"
                fontWeight="bold"
              >
                Visualizar arquivo
              </Typography>
            </Stack>
          )}
          <div
            style={{
              borderRadius: '8px',
              border: '0px solid red'
            }}
          >
            <StyledDropZone
              children={
                item?.file?.name
                  ? '✔ Arquivo anexado!'
                  : 'Selecione ou arraste seu arquivo aqui'
              }
              onDrop={(file, text) => handleSelectFile(file, item?._id ?? '')}
            />
          </div>
        </Stack>
      ))}
      {!!files?.length && (
        <ImportantView
          style={{ marginTop: '20px' }}
          title="Importante"
          content="Tamanho máximo 2mb"
        />
      )}
    </Stack>
  )
}
