import { Icon } from '@mui/material'
import React from 'react'

function IconAndTitleView(props: any) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginRight: '14px', fontSize: '12px' }}>
      <Icon sx={{ fontSize: '18px !important', marginRight: '2px' }}>{props?.icon}</Icon>
      {props.title}
    </div>
  )
}

export default IconAndTitleView
