
import { styled } from '@mui/material/styles'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'

export const Tab = styled(TabUnstyled)`
    color: black;
    cursor: pointer;
    font-size: 0.815rem;
    font-weight: 500;
    background-color: transparent;
    width: auto;
    padding: 8px 16px;
    border: none;
    border-radius: 0px;
    display: flex;
    background-color: #FAFAFA;
    box-shadow: 0px 1px 0px #E8E8E8;

    &:focus {
      border-radius: 0px;
      outline: 2px solid white;
      outline-offset: 2px;
    }

    &.${tabUnstyledClasses.selected} {
      background-color: #4060E3;
      color: white;
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `

export const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `

export const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: white;
    border-radius: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: space-between;
  `
