import Swal from 'sweetalert2'
import './style.css'

const alertSuccess = (title: string, content: string, methodConfirm?: () => void) => {
  Swal.fire({
    width: 600,
    customClass: {
      actions: 'confirm_actions',
      confirmButton: 'success_confirm_button',
      htmlContainer: 'success_html'
    },
    html:
      `
      <div class="success_html">
        <div>
          <span style='color: #72CE2B;' class="material-icons">check_circle_outlined</span>
        </div>
        <div>
          <b>${title}</b>
          <p>${content}</p>
        </div>
      </div>
    `,
    focusConfirm: false,
    confirmButtonText: 'Ok',
    confirmButtonColor: '#0075E7'
  }).then((result) => {
    if (result.isConfirmed && methodConfirm) {
      methodConfirm()
    }
  })
}

export default alertSuccess
