import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import CustomButton from 'components/buttons/CustomButton'
import { httpClient } from 'config/httpClient'
import { getPortfolioPapers } from 'services/portfolio/PortfolioService'
import { PortfolioPaperType } from 'types/portfolio/PortfolioPaperType'
import { onlyNumbers } from 'helpers/stringHelper'
import { CheckboxDimension } from '../components/CheckboxDimension'

interface modalView {
  open: boolean
  close: () => void
  refetch: () => void
}

const FormWaveType = (props: modalView) => {
  const { open, close, refetch } = props

  const [loading, setLoading] = useState(false)
  const [papers, setPapers] = useState<PortfolioPaperType[]>([])

  const closeModal = () => close()

  const { errors, touched, values, setFieldValue, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        column: '',
        composition: '',
        description: '',
        dimensions: '',
        grammage: '',
        mullen: '',
        nomenclature: '',
        name: '',
        obs: '',
        specification: ''
      },
      // validationSchema: {},
      onSubmit(values) {
        setLoading(true)
        console.log('values: ', values)

      httpClient.post('products/plates', values).then(() => {
        toast.success('Salvo com sucesso')

        refetch()
        handleClose()
      }).catch((error: any) => {
        console.log('Error: ', error)
        toast.error(error?.response?.data?.message || 'Erro ao salvar')
      }).finally(() => {
        setLoading(false)
      })
      }
    })

    useEffect(() => {
      getPortfolioPapers()
        .then((data) => {
          setPapers(data)
        })
        .catch(() => console.log('Erro ao carregar papéis'))

      return () => {
        setPapers([])
      }
    }, [])

  const handleClose = () => {
    closeModal()
    resetForm()
  }

  const handleChangeIntegerNumber = (field: string) => (event: any) => {
    const newValue = onlyNumbers(event.target.value)
    setFieldValue(field, newValue ? Math.round(Number(newValue)) : '')
  }

  const handleChangeNumber = (field: string) => (event: any) => {
    setFieldValue(field, event.target.value ? Number(event.target.value) : '')
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      fullWidth
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{ minWidth: 'auto', padding: 1, marginRight: 2 }}
            >
              <ArrowBack />
            </Button>

            <span>Nova onda</span>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography fontWeight="bold">Tipo de onda</Typography>

          <Box marginTop={3}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.name && touched.name)}
                  value={values.name}
                  variant="standard"
                  fullWidth
                  autoComplete="off"
                  label="Nome da onda"
                  helperText={errors.name && touched.name ? errors.name : null}
                  onChange={handleChange('name')}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.nomenclature && touched.nomenclature)}
                  value={values.nomenclature}
                  variant="standard"
                  fullWidth
                  autoComplete="off"
                  label="Nomeclatura"
                  helperText={
                    errors.nomenclature && touched.nomenclature
                      ? errors.nomenclature
                      : null
                  }
                  onChange={handleChange('nomenclature')}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant="standard"
                  sx={{ width: '100%' }}
                  error={!!(errors.specification && touched.specification)}
                >
                  <InputLabel id="select-specification-label"
                  required>
                  Especificação papel
                  </InputLabel>
                  <Select
                    labelId="select-specification-label"
                    id='select-specification'
                    label="Especificação papel"
                    value={values?.specification}
                    // onChange={handleChange('specification')}
                    onChange={(e) => {
                      setFieldValue('specification', e.target.value)
                    }}
                    required
                  >
                    {papers.map((item: any) => {
                      return (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText>
                    {errors.specification && touched.specification
                      ? 'Campo Obrigatório'
                      : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={Boolean(errors.composition && touched.composition)}
                  value={values.composition}
                  variant="standard"
                  fullWidth
                  autoComplete="off"
                  label="Composição do Papel"
                  helperText={
                    errors.composition && touched.composition
                      ? errors.composition
                      : null
                  }
                  onChange={handleChange('composition')}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(errors.grammage && touched.grammage)}
                  value={values.grammage}
                  variant="standard"
                  fullWidth
                  autoComplete='off'
                  label="Gramatura (g/m2 +- 10%)"
                  type='number'
                  helperText={
                    errors.grammage && touched.grammage ? errors.grammage : null
                  }
                  onChange={handleChangeIntegerNumber('grammage')}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(errors.mullen && touched.mullen)}
                  value={values.mullen}
                  variant="standard"
                  fullWidth
                  autoComplete='off'
                  label="Muller (Kgf/cm2)"
                  type='number'
                  helperText={
                    errors.mullen && touched.mullen ? errors.mullen : null
                  }
                  onChange={handleChangeNumber('mullen')}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(errors.column && touched.column)}
                  value={values.column}
                  variant="standard"
                  fullWidth
                  autoComplete='off'
                  label="Coluna (Kgf/cm)"
                  type='number'
                  helperText={
                    errors.column && touched.column ? errors.column : null
                  }
                  onChange={handleChangeNumber('column')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                {/* <TextField
                  error={Boolean(errors.dimensions && touched.dimensions)}
                  value={values.dimensions}
                  variant="standard"
                  fullWidth
                  autoComplete="no"
                  label="Dimensões"
                  type='number'
                  helperText={
                    errors.dimensions && touched.dimensions
                      ? errors.dimensions
                      : null
                  }
                  onChange={handleChangeIntegerNumber('dimensions')}
                  required
                /> */}
                <CheckboxDimension defaultValue={values.dimensions} onChange={async (value) => setFieldValue('dimensions', value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(errors.description && touched.description)}
                  value={values.description}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  label="Descrição"
                  helperText={
                    errors.description && touched.description
                      ? errors.description
                      : null
                  }
                  onChange={handleChange('description')}
                  multiline
                  minRows={3}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(errors.obs && touched.obs)}
                  value={values.obs}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  label="Observações"
                  multiline
                  minRows={3}
                  helperText={errors.obs && touched.obs ? errors.obs : null}
                  onChange={handleChange('obs')}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Grid item md={12} className="text-right">
            <CustomButton
              mold="red"
              type="button"
              onClick={handleClose}
              style={{ marginRight: '10px' }}
              disabled={loading}
            >
              Cancelar
            </CustomButton>
            <CustomButton
              mold="primary"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              Salvar
            </CustomButton>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default FormWaveType
