const removeAcento = (item: any): string =>
  String(item)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

const onlyNumbers = (text: string): string => text.replace(/\D/gi, '')

const onlyNumbersOrPoint = (text: string): string => text.replace(/[^0-9.]/gi, '')

const onlyNumbersOrVirgula = (text: string): string => text.replace(/[^0-9,]/gi, '')

export { onlyNumbers, onlyNumbersOrPoint, removeAcento, onlyNumbersOrVirgula }
