import React from 'react'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Link, useNavigate } from 'react-router-dom'
import coil from '../../../../assets/img/bobina.png'
import sheet from '../../../../assets/img/chapa.png'
import caixa from '../../../../assets/img/caixa.png'
import styled from 'styled-components'
import { Breadcrumbs } from '@mui/material'
import Linkk from '@mui/material/Link'
import Button from 'components/form/Button'

const Title = styled('p')`
  text-align: center;
  font: normal normal bold 28px/33px Rubik;
  letter-spacing: 0px;
  color: #7f85f9;
  opacity: 1;
`
const CardHeader = styled('div')`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  width: 240px;
  height: 210px;
`

const Products: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Linkk
            underline="hover"
            color="inherit"
            onClick={() => {
              navigate('/home/company')
            }}
          >
            Empresa
          </Linkk>
          <Linkk
            underline="hover"
            color="inherit"
            onClick={() => {
              navigate('/home/portfolio')
            }}
          >
            Portfólio PCE
          </Linkk>
          <Linkk
            underline="hover"
            color="text.primary"
            onClick={() => {
              navigate('/home/portfolio/products')
            }}
            aria-current="page"
          >
            Selecionar Produto
          </Linkk>
        </Breadcrumbs>
      </div>
      <h4 style={{ marginTop: '10px' }}>Selecione o tipo de produto</h4>
      <Box sx={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            marginTop: '25px'
          }}
        >
          <Link
            style={{
              textDecoration: 'none',
              color: 'black',
              marginTop: '15px'
            }}
            to="/home/portfolio/products/formCoil"
          >
            <CardHeader>
              <CardContent>
                <Typography variant="h5" component="div">
                  <img
                    style={{
                      width: 'auto',
                      maxWidth: '190px',
                      display: 'flex',
                      alignSelf: 'center',
                      paddingInline: '30px',
                      marginLeft: '30px'
                    }}
                    src={coil}
                  />
                </Typography>
                <Title>Bobinas e Simples</Title>
              </CardContent>
            </CardHeader>
          </Link>

          <Link
            style={{
              textDecoration: 'none',
              color: 'black',
              marginTop: '15px'
            }}
            to="/home/portfolio/products/formChapas"
          >
            <CardHeader style={{ marginLeft: '20px' }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  <img
                    style={{
                      width: 'auto',
                      maxWidth: '190px',
                      display: 'flex',
                      alignSelf: 'center',
                      paddingInline: '30px'
                    }}
                    src={sheet}
                  />
                </Typography>
                <Title style={{ marginTop: '30px' }}>Chapas</Title>
              </CardContent>
            </CardHeader>
          </Link>

          <Link
            style={{
              textDecoration: 'none',
              color: 'black',
              marginTop: '15px'
            }}
            to="/home/portfolio/products/formCaixas"
          >
            <CardHeader style={{ marginLeft: '20px' }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  <img
                    style={{
                      width: 'auto',
                      maxWidth: '190px',
                      display: 'flex',
                      alignSelf: 'center',
                      paddingInline: '30px'
                    }}
                    src={caixa}
                  />
                </Typography>
                <Title style={{ marginTop: '30px' }}>Caixas</Title>
              </CardContent>
            </CardHeader>
          </Link>

        </div>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          height: 200,
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}
      >
        <Button
          mold="primary"
          type="submit"
          loading={false}
          disabled={false}
          onClick={() => navigate('/home/portfolio')}
        >
          Voltar
        </Button>
      </Box>
    </>
  )
}

export default Products
