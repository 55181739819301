import { Box, Breadcrumbs, Card, CardContent, FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material'
import Button from 'components/form/Button'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { validationSchema } from 'types/validation/ValidationManagementControl'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import { httpClient } from 'config/httpClient'
import moment from 'moment'
import { ptBR } from 'date-fns/locale'

const FormProductionGoals: React.FC = (props: any) => {
  const [kg, setKg] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [date, setDate] = useState<Date | null>(new Date())
  const [products, setProducts] = useState<any[]>([])
  const { state }: any = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(false)

    httpClient.get('products/productions').then(data => {
      setProducts(data.data)
    }).catch(() => toast.error('Erro ao listar produtos'))
  }, [])

  const {
    setFieldValue,
    errors,
    touched,
    values,
    // handleChange,
    handleSubmit
  } = useFormik({
    onSubmit: (values: any) => {
      const body = {
        month: moment(values.date).format('M'),
        year: moment(values.date).format('YYYY'),
        production: values.product,
        previsionKg: values.kg
      }
      httpClient.post('previsions/meta', body).then(value => {
        toast.success('Salvo com sucesso')
        navigate('/home/managementControl/production-goals')
      }).catch((e) => {
        if (e.response.data.message !== null && e.response.data.message !== undefined) {
          toast.error(e.response.data.message)
        } else {
          toast.error('Erro ao cadastrar previsão')
        }
      })
    },
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      date: date ?? state?.date,
      product: null,
      kg: kg !== 0 ? kg : state?.kg ?? state?.kg
    }
  })

  return (
    <>
      <Box sx={{ display: 'grid', gridTemplateRows: 'max-content auto' }}>
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="text.primary"
              onClick={() => {
                navigate('/home/managementControl')
              }}
            >
              Gerencial
            </Link>
            <Link
              underline="hover"
              color="text.primary"
              onClick={() => {
                navigate('/home/managementControl/production-goals')
              }}
            >
              Metas de Produção
            </Link>
            <Typography >Previsão</Typography>
          </Breadcrumbs>
        </div>
        <div style={{ marginTop: '18px', fontSize: '16px' }}>

        </div>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid item md={8} style={{ marginTop: '10px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DatePicker
                        views={['year', 'month']}
                        label="Mês e Ano"
                        // minDate={moment(new Date()).add(-30, 'days').format()}
                        value={values.date}
                        onChange={(newValue) => {
                          setDate(newValue)
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      error={!!(errors.kg && touched.kg)}
                      style={{ width: '100%' }}
                      id="2"
                      label="Peso Kg"
                      variant="standard"
                      type="number"
                      value={values.kg}
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      onChange={(e) => setKg(Number(e.target.value))}
                      helperText={errors.kg && touched.kg ? 'Campo deve ser maior que zero.' : ''}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl variant="standard" sx={{ width: '100%' }} error={!!(errors.product && touched.product)}>
                      <InputLabel id="demo-simple-select-label">Selecione o Produto</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Cliente"
                        value={values.product}
                        onChange={(event: any) => {
                          setFieldValue('product', event.target.value)
                        }}
                      >{products.map((item: any) => {
                        return (
                          <MenuItem value={item._id}>{item.name}</MenuItem>
                        )
                      })}
                      </Select>
                      <FormHelperText>{errors.product && touched.product ? 'Campo obrigatório.' : ''}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} className='text-right' style={{ alignSelf: 'flex-end', justifySelf: 'self-end', marginTop: '40px' }}>
                <Button
                  mold='red'
                  type='button'
                  onClick={() => navigate('/home/managementControl/production-goals')}
                  style={{ marginRight: '10px' }}
                  disabled={false}
                >
                  Cancelar
                </Button>
                <Button mold='primary' type='submit' loading={false} disabled={false}>
                  {loading ? 'CARREGANDO...' : 'SALVAR'}
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default FormProductionGoals
