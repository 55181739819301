/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField
} from '@mui/material'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import {
  getClient,
  getClientPrevisions
} from 'services/customerControl/CustomerControlServices'
import CustomerControlType from 'types/customerControl/CustomerControlType'
import PageableType from 'types/PageableType'
import debounce from 'lodash.debounce'
import { Clear, Search, SearchOutlined } from '@mui/icons-material'
import Button from 'components/form/Button'

interface Props {
  onChange: (c: CustomerControlType) => void
  value?: CustomerControlType
  currentValue?: CustomerControlType
  children?: ReactNode
}

export default function AutocompleteClient(props: Props) {
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const inputSearchCodRef = useRef<HTMLInputElement>(null)

  const [search, setSearch] = useState('')
  const [searchCod, setSearchCod] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [searchCodValue, setSearchCodValue] = useState('')

  const [rows, setRows] = useState<CustomerControlType[]>([])
  const [limit] = useState(20)

  const [selectedValue, setSelectedValue] = useState<CustomerControlType>()

  const { isFetching, refetch } = useQuery(
    ['clients', search, searchCod],
    async () => {
      // meta
      return getClient({ page: 0, search, searchCod, limit })
    },
    {
      onSuccess(data: PageableType<CustomerControlType>) {
        setRows(data.docs)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 5
    }
  )

  const handleClearSearch = () => {
    setSearch('')
    setSearchCod('')
    setSearchValue('')
    setSearchCodValue('')

    if (inputSearchRef?.current?.value) {
      inputSearchRef.current.value = ''
    }
    if (inputSearchCodRef?.current?.value) {
      inputSearchCodRef.current.value = ''
    }

    setTimeout(() => {
      refetch()
    }, 300)
  }

  const handleSearch = () => {
    setSearch(searchValue)
    setSearchCod(searchCodValue)

    setTimeout(() => {
      refetch()
    }, 300)
  }

  const getIes = (client: any) => {
    if (!client?.ies?.length) return client?.name ?? ''

    let ies = ''
    for (const ie of client.ies) {
      ies += ie?.codeLn ? `[${ie?.codeLn}] ` : ''
    }

    return `${ies}${client?.name}`
  }

  return (
    <Stack spacing={3}>
      <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
      <Stack direction="row" spacing={2} maxWidth={'100%'}>
        <TextField
          label="Pesquisar por Cliente"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          inputRef={inputSearchRef}
          variant="standard"
          fullWidth
        />
        <TextField
          label="Pesquisar por Cod. Parceiro"
          value={searchCodValue}
          onChange={(e) => setSearchCodValue(e.target.value)}
          inputRef={inputSearchCodRef}
          variant="standard"
          fullWidth
        />
        <Button
          mold="primary"
          type="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={handleSearch}
          disabled={isFetching}
        >
          <SearchOutlined />
        </Button>

        {(!!search?.length ||
          !!searchCod?.length ||
          !!inputSearchRef?.current?.value?.length ||
          !!inputSearchCodRef?.current?.value?.length) && (
          <Button
            mold="red"
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 4
            }}
            onClick={handleClearSearch}
            disabled={isFetching}
          >
            <Clear />
          </Button>
        )}
      </Stack></Grid></Grid>

      <Box>
        {isFetching && (
          <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
          <Stack justifyContent={'center'} alignItems="center">
            <CircularProgress size={24} />
          </Stack>
          </Grid>
          </Grid>
        )}

        {!isFetching && (
          <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
              <Autocomplete
                noOptionsText="Sem opções"
                loading={isFetching}
                getOptionLabel={(option) =>
                  option.isBlocked || !option.isActive
                    ? `${option.name} - [BLOQUEADO]`
                    : getIes(option)
                }
                id="combo-box-clients"
                options={rows.map((row) => ({ ...row, label: row.name }))}
                onChange={(
                  event: React.SyntheticEvent<Element, Event>,
                  value: CustomerControlType | null
                ) => {
                  props.onChange(value!)
                  setSelectedValue(value!)
                }}
                getOptionDisabled={(option) => option.isBlocked || !option.isActive}
                defaultValue={props.currentValue}
                value={selectedValue}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Selecionar cliente"
                    fullWidth
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} sm={4}>
              {props?.children}
              </Grid>
          </Grid>
        )}
      </Box>
    </Stack>
  )
}
