import React from 'react'
import GenericDialog from 'components/genericDialog/GenericDialog'
import RequestForProposalsType, {
  ProposalType
} from 'types/Report/RequestForProposalsType'
import { Button, WrapperAccordion } from './styles'
import { ArrowBack, ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'
import {
  StyledTableRow,
  StyledTableCell
} from 'pages/reportControl/proposalForDirection/components/table.styles'
import { FormatPrice } from 'utils/FormatPrice'
import moment from 'moment'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  proposal: RequestForProposalsType | null
}

const ViewProposal: React.FC<Props> = ({ open, setOpen, proposal }) => {
  const budget = proposal?.orcamento.at(0)
  const proposals = budget?.propostas ?? []

  const getProposalDetails = (item: ProposalType) => {
    return [
      {
        description: 'Valor total',
        value: FormatPrice(item.price)
      },
      {
        description: 'Gramatura',
        value: item.gramature
      },
      {
        description: 'Medida interna',
        value: item.internalMeasures
      },
      {
        description: 'Preço',
        value: FormatPrice(item.price)
      },
      {
        description: 'Frete',
        value: FormatPrice(item.shipping)
      },
      {
        description: 'Lote mínimo',
        value: item.minimumLot
      },
      {
        description: 'Condição de pagamento',
        value: item.paymentCondition
      },
      {
        description: 'Taxa de financiamento',
        value: FormatPrice(item.financingRate)
      },
      {
        description: 'ICMS',
        value: FormatPrice(item.icms)
      },
      {
        description: 'Validade da proposta',
        value: `${item.validity} dias`
      },
      {
        description: 'Prazo de entrega',
        value: `${item.deadlineDeliverDays} dias`
      }
    ]
  }

  const getStylesChip = (status: string) => {
    const styles = {
      color: '#CE6C2B',
      background: '#FFF0D8',
      borderColor: '#CE6C2B'
    }

    if (/aprovado|aprovada/gi.test(status)) {
      styles.color = '#72CE2B'
      styles.background = '#EBFFD8'
      styles.borderColor = '#72CE2B'
    }

    if (/reprovado|reprovada/gi.test(status)) {
      styles.color = '#EE207A'
      styles.background = '#FFDDEC'
      styles.borderColor = '#EE207A'
    }

    return styles
  }

  const closeModal = () => setOpen(false)

  return (
    <GenericDialog
      widthRoot={700}
      open={open}
      close={closeModal}
      content={
        <>
          <Button variant="contained" onClick={closeModal}>
            <ArrowBack />
          </Button>
          <WrapperAccordion>
            {proposals.map((item, index) => (
              <Accordion
                key={`accordion_proposal_key_${index}`}
                sx={{
                  borderRadius: '4px',
                  padding: '0.5rem 0',
                  boxShadow: '0px 3px 6px #00000029'
                }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Chip
                    label={item.status}
                    variant="outlined"
                    size="small"
                    sx={{ ...getStylesChip(item.status) }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer elevation={0} component={Paper}>
                    <Table>
                      <TableBody>
                        {getProposalDetails(item).map((detail, indexDetail) => (
                          <StyledTableRow
                            key={`detail_proposal_${indexDetail}`}
                          >
                            <StyledTableCell
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr'
                              }}
                            >
                              <strong>{detail.description}</strong>
                              <p>{detail.value}</p>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {!!item?.statusHistory?.length && (
                    <Stack sx={{ marginTop: 3 }}>
                      <Typography
                        component="strong"
                        variant="body1"
                        fontWeight="bold"
                        color="primary"
                        textAlign="center"
                      >
                        Histórico
                      </Typography>

                      <TableContainer elevation={0} component={Paper}>
                        <Table>
                          <TableBody>
                            {item?.statusHistory?.map(
                              (history, indexHistory) => (
                                <StyledTableRow
                                  key={`history_proposal_${indexHistory}`}
                                >
                                  <StyledTableCell
                                    style={{
                                      display: 'grid',
                                      gridTemplateColumns: '1fr 1fr'
                                    }}
                                  >
                                    <div>
                                      <Chip
                                        label={history.status}
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                          ...getStylesChip(history?.status)
                                        }}
                                      />
                                    </div>
                                    <p>
                                      {history?.date
                                        ? moment(history.date).format(
                                            'DD/MM/YYYY [às] HH:mm'
                                          )
                                        : ''}
                                    </p>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  )}

                  {!item?.statusHistory?.length && (
                    <Stack sx={{ marginTop: 3 }}>
                      <Typography
                        component="strong"
                        variant="body2"
                        fontWeight="bold"
                        textAlign="center"
                      >
                        Nenhum histórico encontrado para essa proposta.
                      </Typography>
                    </Stack>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </WrapperAccordion>
        </>
      }
    />
  )
}

export default ViewProposal
