import React from 'react'
import { TableCellProps } from '@mui/material/TableCell'
import { StyledTableCell } from './table.styles'

interface Props extends TableCellProps {
  label?: string
}

export const TableCell: React.FC<Props> = ({ label, children, ...rest }) => {
  return (
    <StyledTableCell {...rest}>
      {label}
      {children}
    </StyledTableCell>
  )
}
