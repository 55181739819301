import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ReportVolumeMes } from 'types/Report/ReportVolume'
import { Box, Button, CircularProgress, Icon, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getReportVolume } from 'services/report/ReportVolume'
import { getReportDevolutionMonthly } from 'services/report/ReportConsolidated'
import { toBRL, formatValue } from 'utils/formatString'
import { TipoDevolucao, TipoProduto } from 'utils/enums'

interface Props {
  date: string
}

const TableReportVolume: React.FC<Props> = (props) => {
  const [dados, setDados] = React.useState<any>([])
  const [meses, setMeses] = React.useState<ReportVolumeMes[]>([])
  const [dadosArray, setDadosArray] = React.useState<any>([])
  const mes = moment(props.date).format('M')
  const date = moment(props.date).format('DD/MM/YYYY')
  const navigate = useNavigate()
  const stickyStyle = {
    position: 'sticky',
    left: 0,
    zIndex: 3,
    background: '#FFF'
  }

  const requestVolume = useQuery(['total-volume', date],
    async () => {
      return getReportVolume(date)
    },
    {
      onSuccess: (data) => {
        devolutionsReq.refetch()
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const devolutionsReq = useQuery(['total-product-devolution-volume', date],
    async () => {
      return getReportDevolutionMonthly(date)
    },
    {
      onSuccess: (data) => {
        handleValorTable(requestVolume.data.response)
        handlePeriodo()
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const handleValorTable = (valorData: any) => {
    const valor: any[] = [
      {
        text: 'Caixas',
        type: TipoProduto.CAIXA,
        ingoneType: false
      },
      {
        text: 'Chapas',
        type: TipoProduto.CHAPA,
        ingoneType: false
      },
      {
        text: 'Papel',
        type: TipoProduto.PAPEL,
        ingoneType: false
      },
      {
        text: 'Devolução Caixa',
        type: TipoDevolucao.CAIXA,
        ingoneType: false
      },
      {
        text: 'Devolução Chapa',
        type: TipoDevolucao.CHAPA,
        ingoneType: false
      },
      {
        text: 'Devolução Bobinas',
        type: TipoDevolucao.PAPEL,
        ingoneType: false
      },
      /* {
        text: 'Outros',
        type: '0004',
        ingoneType: false
      }, */
      {
        text: 'Total',
        type: 'total',
        ingoneType: true
      }
    ]
    setDados(valor)

    const listaDados: any = []
    valorData.forEach((element) => {
      listaDados.push(...element.dados)
    })
    setDadosArray(listaDados)
  }

  const handlePeriodo = () => {
    const mesesValue = [
      {
        text: 'Janeiro',
        value: 1
      },
      {
        text: 'Fevereiro',
        value: 2
      },
      {
        text: 'Março',
        value: 3
      },
      {
        text: 'Abril',
        value: 4
      },
      {
        text: 'Maio',
        value: 5
      },
      {
        text: 'Junho',
        value: 6
      },
      {
        text: 'Julho',
        value: 7
      },
      {
        text: 'Agosto',
        value: 8
      },
      {
        text: 'Setembro',
        value: 9
      },
      {
        text: 'Outubro',
        value: 10
      },
      {
        text: 'Novembro',
        value: 11
      },
      {
        text: 'Dezembro',
        value: 12
      }
    ]
    const meses: any[] = []
    let i = 0
    while (i < Number(mes)) {
      meses.push(mesesValue[i])
      i++
    }
    setMeses(meses)
  }

  const getRowByPeriodoKG = (type: any, periodo: any, ingoneType: boolean) => {
    const resultFilter = dadosArray.filter((item) => Number(item.periodo) === periodo && (item.type === type || ingoneType))
    return resultFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.kg)
    }, 0)
  }

  const getRowByPeriodoRS = (type: any, periodo: any, ingoneType: boolean) => {
    const resultFilter = dadosArray.filter((item) => Number(item.periodo) === periodo && (item.type === type || ingoneType))
    return resultFilter.reduce((acumulador: number, item) => {
      return acumulador + Number(item.rs)
    }, 0)
  }

  const getResult = (valorKg, valorRs) => {
    const resultado = Number(valorRs) / Number(valorKg)
    if (Number.isNaN(resultado)) {
      return 0
    } else {
      return resultado
    }
  }

  const getTotalDevolutionByMonth = (month, type) => {
    // eslint-disable-next-line eqeqeq, @typescript-eslint/restrict-plus-operands
    const ok = devolutionsReq.data.response.filter(f => f.nfmonth == month && (f.categ + 'd') == type)
      .reduce((a, b) => (
        { kg: Number(a.kg ?? 0) + Number(b.kg ?? 0), preco: Number(a.preco ?? 0) + Number(b.preco ?? 0) }), { kg: 0, preco: 0 })
    return ok
  }

  // const getTotalDevolution = (type) => {
  //   // eslint-disable-next-line eqeqeq, @typescript-eslint/restrict-plus-operands
  //   const ok = devolutionsReq.data.response.filter(f => (f.categ + 'd') == type)
  //     .reduce((a, b) => ({ preco: (parseFloat(a.preco ?? 0) + parseFloat(b.preco ?? 0)) }), 0)
  //   return ok
  // }

  return (
    <>
      {(requestVolume.isLoading || requestVolume.isFetching || devolutionsReq.isFetching) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <CircularProgress />
        </Box>
      )}
      {(requestVolume.isError || devolutionsReq.isError) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
        </Box>
      )}

      {(requestVolume.isSuccess && !requestVolume.isError && !requestVolume.isFetching && !devolutionsReq.isFetching) && (
        <>
          <Paper sx={{ width: '80vw' }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ ...stickyStyle, background: '#fafafa' }}>
                      Produtos
                    </TableCell>
                    {meses.map((mes) => (
                      <TableCell align="center" colSpan={2} key={`${mes.text}`} sx={{ background: '#fafafa' }}>
                        {mes.text}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell key='cliente_carne' style={{ width: '20%' }} sx={stickyStyle}>
                    </TableCell>
                    {meses.map((mes, index) => (
                      <>
                        <TableCell
                          key={`kg:-${index}-${mes.text}`}
                          align={'left'}
                        >
                          KG
                        </TableCell>
                        <TableCell
                          key={`rs/kg:-${index}-${mes.text}`}
                          align={'left'}
                        >
                          R$/KG
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>

                  {dados
                    .map((row, index) => {
                      if (row.type.includes('d')) {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={`${index}-${row.text}`}>
                            <TableCell sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 2,
                              background: '#FFF'
                            }}>
                              {row.text}
                            </TableCell>
                            {meses.map((mes, index) => {
                              return (
                                <>
                                  <TableCell align={'left'} key={`kg:${index}-${mes.value}`}>
                                    {formatValue(-1 * getTotalDevolutionByMonth(mes.value, row.type).kg)}
                                  </TableCell>
                                  <TableCell align={'left'} key={`rs/kg:${index}-${mes.value}`}>
                                    <Typography variant="body2" component="span" noWrap>
                                      {
                                        toBRL(
                                          getTotalDevolutionByMonth(mes.value, row.type).preco > 0
                                            ? -1 * (getTotalDevolutionByMonth(mes.value, row.type).preco / getTotalDevolutionByMonth(mes.value, row.type).kg)
                                            : 0
                                          , { noBRL: true }
                                        )
                                      }
                                    </Typography>
                                  </TableCell>
                                </>
                              )
                            })}
                          </TableRow>
                        )
                      } else {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.text}>
                            <TableCell sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 2,
                              background: '#FFF'
                            }}>
                              {row.text}
                            </TableCell>
                            {meses.map((mes) => {
                              let valorKg = getRowByPeriodoKG(row.type, mes.value, row.ingoneType)
                              let valorRs = getRowByPeriodoRS(row.type, mes.value, row.ingoneType)
                              let valorRsKg = getResult(valorKg, valorRs)
                              if (row.type === 'total') {
                                valorKg = valorKg -
                                  getTotalDevolutionByMonth(mes.value, TipoDevolucao.CAIXA).kg -
                                  getTotalDevolutionByMonth(mes.value, TipoDevolucao.CHAPA).kg -
                                  getTotalDevolutionByMonth(mes.value, TipoDevolucao.PAPEL).kg

                                valorRs = valorRs -
                                  getTotalDevolutionByMonth(mes.value, TipoDevolucao.CAIXA).preco -
                                  getTotalDevolutionByMonth(mes.value, TipoDevolucao.CHAPA).preco -
                                  getTotalDevolutionByMonth(mes.value, TipoDevolucao.PAPEL).preco

                                valorRsKg = getResult(valorKg, valorRs)
                              }
                              return (
                                <>
                                  <TableCell align={'left'} key={`kg:${mes.value}`}>
                                    {formatValue(valorKg ?? 0)}
                                  </TableCell>
                                  <TableCell align={'left'} key={`resultado:${mes.value}`}>
                                    <Typography variant="body2" component="span" noWrap>
                                      {toBRL(valorRsKg ?? 0, { noBRL: true })}
                                    </Typography>
                                  </TableCell>
                                </>
                              )
                            })}
                          </TableRow>
                        )
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              style={{
                backgroundColor: '#4060E6',
                borderRadius: '2px',
                width: '122px',
                color: 'white'
              }}
              onClick={() => navigate('/home/report')}
            >
              Voltar
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default TableReportVolume
