import * as React from 'react'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { Container, FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, Snackbar } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useState } from 'react'
import { httpClient } from '../../../config/httpClient'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../core/store'
import { useNavigate } from 'react-router-dom'
import { _changeLogin } from '../slices/login.slice'
import { Form, Formik } from 'formik'
import { validationChangePasswordSchema } from '../validations/validationChangePasswordSchema'

export interface SimpleDialogProps {
  open: boolean
  onClose: (value: string) => void
}

export default function FirstAccessDialog(props: SimpleDialogProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loginData } = useSelector((state: RootState) => state.login)

  const [openSnack, setOpenSnack] = useState(false)

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  // First input
  const [valuesFirstInput, setValuesFirstInput] = useState({
    password: '',
    showPassword: false
  })

  const handleClickShowPassword = () => {
    setValuesFirstInput({
      ...valuesFirstInput,
      showPassword: !valuesFirstInput.showPassword
    })
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  // Second input
  const [valuesConfirm, setValuesConfirm] = useState({
    password: '',
    showPassword: false
  })

  const handleClickShowPasswordConfirm = () => {
    setValuesConfirm({
      ...valuesConfirm,
      showPassword: !valuesConfirm.showPassword
    })
  }

  const handleMouseDownPasswordConfirm = (event: any) => {
    event.preventDefault()
  }

  const handleChangePass = async (values: any) => {
    console.log('DATA', loginData)
    const tokenData = loginData.tokenData
    const accessToken = loginData.accessToken
    dispatch(_changeLogin({ tokenData, accessToken, logged: false, email: loginData.email, picture: '' }))

    if (values.password === values.confirmPassword) {
      try {
        console.log('rd', tokenData)
        await httpClient.patch(`/users/${tokenData.sub}`, {
          password: values.password,
          confirm: values.confirmpassword
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        dispatch(_changeLogin({ tokenData, accessToken, logged: false, email: loginData.email, picture: '' }))
        navigate('/login')
        props.onClose('')
      } catch (e) {
        console.log('E', e)
        setOpenSnack(true)
      }
    } else {
      setOpenSnack(true)
    }
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Altere sua senha</DialogTitle>
      <Container style={{ width: '330px', marginBottom: '30px', display: 'flex', justifyContent: 'right', flexDirection: 'column' }}>
        {/* <div>Por favor informe uma nova senha</div> */}
        <Formik
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          validationSchema={validationChangePasswordSchema}
          onSubmit={async (values) => {
            console.log('OK', values.password, values.confirmPassword)
            if (values.password === values.confirmPassword) {
              handleChangePass(values)
            } else {
              setOpenSnack(true)
            }
          }}
        >
          {
            ({
              values, errors, touched, handleChange, setFieldValue
            }) => (<Form style={{ display: 'contents' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl variant='standard'>
                  <InputLabel htmlFor='standard-adornment-password'>Digite uma nova senha</InputLabel>
                  <Input
                    id='standard-adornment-password'
                    type={valuesFirstInput.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    error={Boolean(errors.password && touched.password)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {valuesFirstInput.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error={Boolean(errors.password && touched.password)}>
                    {(errors.password && touched.password) ? errors.password : ''}
                  </FormHelperText>
                </FormControl>

                <FormControl style={{ marginTop: '12px' }} variant='standard'>
                  <InputLabel htmlFor='standard-adornment-password'>Digite a nova senha novamente</InputLabel>
                  <Input
                    id='standard-adornment-password'
                    type={valuesConfirm.showPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onChange={handleChange('confirmPassword')}
                    error={Boolean(values.password !== values.confirmPassword)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPasswordConfirm}
                        >
                          {valuesConfirm.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error={Boolean(values.password !== values.confirmPassword)}>
                    {(Boolean(values.password !== values.confirmPassword) && touched.confirmPassword) ? errors.confirmPassword : ''}
                  </FormHelperText>
                </FormControl>
              </div>
              {/* <Button onClick={async () => await handleChangePass()} variant='contained' disableElevation style={{ backgroundColor: '#4060E6', marginTop: '20px', alignSelf: 'flex-end' }}>
          Confirmar
        </Button> */}
              <Button type='submit' variant='contained' disableElevation style={{ backgroundColor: '#4060E6', marginTop: '20px', alignSelf: 'flex-end' }}>
                Confirmar
              </Button>
            </Form>
            )}
        </Formik>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={handleCloseSnack}
        autoHideDuration={2000}
        message='Senhas inválidas ou não conferem'
        key='top center'
      />
    </Dialog>
  )
}
