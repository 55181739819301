import { httpClient, httpLN } from '../../config/httpClient'

export const postXML = async (xml: string) => {
  console.log('postXML BDLN node => xml', xml)
  const { data } = await httpLN.post<any>('/order/xml', {
    xmlString: xml
  })
  return data
}

export const getSiglas = async () => {
  const { data } = await httpLN.get<any>('/order/siglas')
  return data
}

export const getSequencial = async (sigla: string) => {
  const { data } = await httpLN.get<any>('/order/sequencial?sigla=' + sigla)
  return data
}

export const postSalesOrder = async (salesOrder: any) => {
  console.log('postSalesOrder MONGO => salesOrder', salesOrder)
  const { data } = await httpClient.post<any>('/order/sales-order', salesOrder)
  return data
}

export const getSalesOrderSequence = async () => {
  const { data } = await httpClient.get<any>('/order/sales-order/find-sequencial')
  return data
}

export const getSalesOrderTrans = async () => {
  const { data } = await httpLN.get<any>('/order/transportadoras')
  return data
}

export const getClientData = async (codClient) => {
  const { data } = await httpLN.get<any>(`/order/dadosClientes?codClient=${codClient}`)
  return data
}

export const getTipoOV = async () => {
  const { data } = await httpLN.get<any>('/order/tipoOV')
  return data
}

export const postCancellOrderProductioNotification = async () => {
  const { data } = await httpClient.post<any>('/order/cancel-order')
  return data
}

export const getClientLimitCredit = async (codeClient: string) => {
  const { data } = await httpLN.get<any>(`/clientes/cliente_credito?codParceiro=${codeClient}`)
  return data
}

export const getSiglaXml = async (ov: string) => {
  const { data } = await httpLN.get<any>(
    `order/siglaXml?codOV=${ov}`
  )
  return data
}
