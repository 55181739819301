/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Typography,
  Button,
  TextField
} from '@mui/material'
import { httpClient } from 'config/httpClient'
import { ptBR } from 'date-fns/locale'
import { useFormik } from 'formik'
import { onlyNumbers, onlyNumbersOrPoint } from 'helpers/stringHelper'
import moment from 'moment'
import { toast } from 'react-toastify'
import { EstoqueType } from '../Estoque'

interface Props {
  open: boolean
  item?: EstoqueType
  onClose: () => void
  onCloseSuccess: () => void
}

export function ModalCreateEstoque({
  open,
  item,
  onClose,
  onCloseSuccess
}: Props) {
  const { errors, touched, resetForm, values, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      onSubmit: async (values: any) => {
        const body = {
          ...values,
          data: moment(values.data).format('YYYY-MM-DD')
        }

        try {
          if (item?._id) {
            await httpClient.put(`/estoques/${item._id}`, body)
            toast.success('Dados atualizado com sucesso!')
            onCloseSuccess()
            return
          }

          await httpClient.post('/estoques', body)
          resetForm()
          toast.success('Dados cadastrado com sucesso!')
          onCloseSuccess()
        } catch (err: any) {
          toast.error(err?.response?.data?.message)
        }
      },
      enableReinitialize: true,
      // validationSchema: validationSchemaMaterial,
      initialValues: {
        data: item?.data ?? null,
        produto: item?.produto ?? '',
        entradaMes: item?.entradaMes ?? '',
        kg: item?.kg ?? '',
        rs: item?.rs ?? ''
      }
    })

  return (
    <Dialog
      open={open}
      // onClose={}
      fullWidth
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button color="primary" onClick={onClose} variant="contained">
            <ArrowBack fontSize="small" />
          </Button>

          <Typography component="strong">Novo estoque</Typography>
        </Stack>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <TextField
                name="produto"
                label="Produto"
                value={values?.produto}
                onChange={async (event) =>
                  setFieldValue('produto', event?.target?.value?.toUpperCase())
                }
                fullWidth
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={5}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  mask="__/__/____"
                  label="Data"
                  value={values?.data}
                  onChange={(newValue) => {
                    setFieldValue('data', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      fullWidth
                      {...params}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="kg"
                label="KG"
                type="number"
                value={values?.kg}
                onChange={(event) => {
                  setFieldValue('kg', onlyNumbers(event?.target?.value))
                }}
                fullWidth
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="rs"
                label="R$"
                type="number"
                value={values?.rs}
                onChange={(event) => {
                  setFieldValue('rs', onlyNumbersOrPoint(event?.target?.value))
                }}
                fullWidth
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="rskg"
                label="R$/KG"
                value={
                  values?.kg && values?.rs
                    ? (
                        Number(values?.rs ?? 0) / Number(values?.kg ?? 0)
                      ).toLocaleString('pt-br', {
                        maximumFractionDigits: 2
                      })
                    : 0
                }
                fullWidth
                variant="standard"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="entradaMes"
                label="Entrada mês KG"
                value={values?.entradaMes}
                onChange={(event) => {
                  setFieldValue(
                    'entradaMes',
                    onlyNumbers(event?.target?.value)
                  )
                }}
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: 2, paddingX: 3 }}>
          <Button color="error" onClick={onClose} variant="contained">
            Cancelar
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
