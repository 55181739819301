import React from 'react'
import { TableHead, TableRow } from '@mui/material'
import { StyledTableHeadCell } from './table.styles'

interface Props {
  countClients?: number
}

export const ReportProposalForDirectionTableHead: React.FC<Props> = ({
  countClients
}) => {
  return (
    <TableHead>
      <TableRow>
        <StyledTableHeadCell>Código FT</StyledTableHeadCell>
        <StyledTableHeadCell>Emitido em</StyledTableHeadCell>
        <StyledTableHeadCell align="left">Nome da Empresa</StyledTableHeadCell>
        <StyledTableHeadCell align="left">Vendedor</StyledTableHeadCell>
        <StyledTableHeadCell align="left">Preço por Kg</StyledTableHeadCell>
        <StyledTableHeadCell align="left">Preço pelo valor da NF</StyledTableHeadCell>
        <StyledTableHeadCell align="left">Aprovar/Reprovar</StyledTableHeadCell>
        <StyledTableHeadCell align="left">Ações</StyledTableHeadCell>
      </TableRow>
    </TableHead>
  )
}
