import { AssignmentTurnedIn } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Icon,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import { Can } from 'components/Permission/Can'
import MoreMenu from 'components/view/MoreMenu'
import StatusViewFT from 'components/view/StatusViewFT'
import { ActionButton } from 'components/view/styles/style-more-menu'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getFTs, getFTsByPreFTTrimbox, getPreFTS } from 'services/ft/FTService'
import { ModalFtProposalClient } from '../components/ModalFtProposalClient'
import { ModalFtSampleClient } from '../components/ModalFtSampleClient'
import { SynchronizeStatusFt } from '../components/SynchronizeStatusFT'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const auditRoute = 'audit'
const ftType = 'Desenvolvimento'
const productType = 'Chapa'

function FTDevTableSamples(props: any) {
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)
  const [originalData, setOriginalData] = React.useState<any[]>([])
  const [rows, setRows] = React.useState<any[]>([])
  const [trimboxParams, setTrimboxParams] = React.useState<any>({ prefts: [] })
  const [page, setPage] = React.useState(0)

  const [openModalFtProposalClient, setOpenModalFtProposalClient] =
    useState(false)
  const [openModalFtSampleClient, setOpenModalFtSampleClient] = useState(false)
  const [selectFt, setSelectFt] = useState<any | null>(null)

  const navigate = useNavigate()

  const ftTrimboxReq = useQuery(
    ['ft-trimbox', trimboxParams],
    async () => {
      return getFTsByPreFTTrimbox(ftType, trimboxParams.prefts)
    },
    {
      onSuccess: (data) => {
        try {
          if (data) {
            if (data.length > 0) {
              setRows(data)
              setOriginalData(data)
            }
          }
        } catch (e) {}
        ftReq.refetch()
      },
      refetchOnWindowFocus: false
    }
  )

  const ftReq = useQuery(
    ['fts', null],
    async () => {
      return getFTs()
    },
    {
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const preftReq = useQuery(
    ['prefts', null],
    async () => {
      return getPreFTS(productType)
    },
    {
      onSuccess: (data) => {
        setTrimboxParams({ ...trimboxParams, prefts: data })
      },
      refetchOnWindowFocus: false
    }
  )

  React.useEffect(() => {
    if (props.search.length > 0) {
      setPage(0)
      const result = originalData.filter((item) => {
        const preft = preftReq.data?.find((ft) => ft._id === item.codERP)
        const filterSearch =
          String(item.codConjunto).includes(props.search) ||
          preft?.client?.name
            ?.toUpperCase()
            ?.includes(props.search?.toUpperCase()) ||
          preft?.seller?.user?.name
            ?.toUpperCase()
            ?.includes(props.search?.toUpperCase()) ||
          preft?._id?.toUpperCase()?.includes(props.search?.toUpperCase())

        if (props.date) {
          return (
            filterSearch &&
            moment(props.date).format('YYYY-MM-DD') ===
              moment(item.dataCriacao).format('YYYY-MM-DD')
          )
        }

        return filterSearch
      })

      setRows(result ?? [])
    } else if (props.date) {
      setPage(0)
      const result = originalData.filter((item) => {
        return (
          moment(props.date).format('YYYY-MM-DD') ===
          moment(item.dataCriacao).format('YYYY-MM-DD')
        )
      })

      setRows(result ?? [])
    } else {
      setPage(0)
      setRows(originalData)
    }
  }, [props.search, props.date])

  const showClientName = (codPreFT) => {
    const preft = preftReq.data?.find((ft) => ft._id === codPreFT)
    if (preft !== null) {
      return preft?.client?.name ?? '-'
    } else {
      return '-'
    }
  }

  const showSellerName = (codPreFT) => {
    const preft = preftReq.data?.find((ft) => ft._id === codPreFT)
    if (preft !== null) {
      return preft?.seller?.user?.name ?? '-'
    } else {
      return '-'
    }
  }

  const isLoadingAnyQuery = [ftTrimboxReq, ftReq, preftReq].some(
    (query) => query.isLoading || query.isFetching
  )
  const hasErrorInAnyQuery = [ftTrimboxReq, ftReq, preftReq].some(
    (query) => query.isError
  )
  const allQueriesSuccess = (
    ftTrimboxReq.isSuccess && !ftTrimboxReq.isError && !ftTrimboxReq.isFetching && ftReq.isSuccess && !ftReq.isError && !ftReq.isFetching && preftReq.isSuccess && !preftReq.isError && !preftReq.isFetching
  )

  let result: any

  if (isLoadingAnyQuery) {
    result = (
      <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          style={{
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          {loading
? (
            <>
              <CircularProgress variant="determinate" value={progress} />
              <p style={{ color: 'green', marginTop: '8px' }}>Consultando Trimbox: {progress}%</p>
            </>
          )
: (
          (ftTrimboxReq === null || ftTrimboxReq === undefined) ||
          (ftReq === null || ftReq === undefined)
? (
            <p style={{ color: 'red', marginTop: '8px' }}>
              Não houve resposta do sistema Trimbox. Inconsistências técnicas na comunicação no momento.
            </p>
          )
: (
            <p>Espere um momento, por favor!</p>
          )
)
        }
      </Box>
    )
  }

  if (hasErrorInAnyQuery) {
    result = (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }
  console.log('FTDevTablePlate=>rows', rows)
  if (allQueriesSuccess) {
    result = (
      <div>
        <TableContainer style={{ marginTop: '30px' }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Código FT</StyledTableCell>
                <StyledTableCell align="left">Descrição</StyledTableCell>
                <StyledTableCell align="left">Código PRÉ FT</StyledTableCell>
                <StyledTableCell align="left">Emitido em</StyledTableCell>
                <StyledTableCell align="left">Nome da Empresa</StyledTableCell>
                <StyledTableCell align="left">Vendedor</StyledTableCell>
                <StyledTableCell align="center">Dados da FT</StyledTableCell>
                <StyledTableCell align="center">Orçamento</StyledTableCell>
                <StyledTableCell align="center">Proposta</StyledTableCell>
                {/* <StyledTableCell align='center'>Desenho</StyledTableCell> */}
                <Can
                  module={MODULES.FT}
                  permissions={[PERMISSIONS.UPDATE]}
                ><StyledTableCell align="left">Auditar</StyledTableCell></Can>
                <Can
                  module={MODULES.FT}
                  permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                >
                  <StyledTableCell align="left">Ações</StyledTableCell>
                </Can>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.slice(page * 10, page * 10 + 10)?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">
                    {row.codConjunto}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.referencia ?? ''}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.codERP}</StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(row.dataCriacao).format('DD/MM/YYYY HH:mm')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {showClientName(row.codERP)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {showSellerName(row.codERP)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <StatusViewFT
                      disabled={
                        !ftReq.data?.find(
                          (ft) => ft.itemReference === row.codConjunto
                        )
                      }
                      status={
                        ftReq.data?.find(
                          (ft) => ft.itemReference === row.codConjunto
                        )
                          ? 'CADASTRADO'
                          : 'PENDENTE'
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <StatusViewFT
                      disabled={
                        !ftReq.data?.find(
                          (ft) => ft.itemReference === row.codConjunto
                        )?.existsBudget
                      }
                      status={
                        ftReq.data?.find(
                          (ft) => ft.itemReference === row.codConjunto
                        )?.existsBudget
                          ? 'CADASTRADO'
                          : 'PENDENTE'
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <StatusViewFT
                      disabled={
                        !ftReq.data?.find(
                          (ft) => ft.itemReference === row.codConjunto
                        )?.existsProposal
                      }
                      status={
                        ftReq.data?.find(
                          (ft) => ft.itemReference === row.codConjunto
                        )?.existsProposal
                          ? 'CADASTRADO'
                          : 'PENDENTE'
                      }
                    />
                  </StyledTableCell>
                  {/* <StyledTableCell align='center'>
                      <StatusViewFT disabled={!ftReq.data?.find(ft => ft.itemReference === row.codConjunto)?.existsAmostra} status={ftReq.data?.find(ft => ft.itemReference === row.codConjunto)?.existsAmostra ? 'CADASTRADO' : 'PENDENTE'} />
                    </StyledTableCell> */}
                    <Can
                    module={MODULES.FT}
                    permissions={[PERMISSIONS.UPDATE]}
                  >
                  <StyledTableCell align="left">
                    <ActionButton
                      aria-label="audit"
                      onClick={() => {
                        navigate(`${auditRoute}?tab=dev&type=plate`, {
                          state: {
                            ftTrimbox: row,
                            ft: ftReq.data?.find(
                              (ft) => ft.itemReference === row.codConjunto
                            ),
                            preft: preftReq.data?.find(
                              (preft) => preft._id === row.codERP
                            ),
                            status: ftType,
                            type: productType
                          }
                        })
                      }}
                    >
                      <AssignmentTurnedIn />
                    </ActionButton>
                  </StyledTableCell>
                  </Can>
                  <Can
                    module={MODULES.FT}
                    permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                  >
                    <StyledTableCell align="left">
                      <MoreMenu
                        proposal={() => {
                          setSelectFt({
                            ...row,
                            ...ftReq.data?.find(
                              (ft) => ft.itemReference === row.codConjunto
                            ),
                            preFT: preftReq.data?.find(
                              (preft) => preft._id === row.codERP
                            )
                          })
                          setOpenModalFtProposalClient(true)
                        }}
                        // sample={() => {
                        //   setSelectFt({ ...row, ...ftReq.data?.find(ft => ft.itemReference === row.codConjunto), preFT: preftReq.data?.find(preft => preft._id === row.codERP) })
                        //   setOpenModalFtSampleClient(true)
                        // }}
                        audit={() => {
                          navigate(`${auditRoute}?tab=dev&type=plate`, {
                            state: {
                              ftTrimbox: row,
                              ft: ftReq.data?.find(
                                (ft) => ft.itemReference === row.codConjunto
                              ),
                              preft: preftReq.data?.find(
                                (preft) => preft._id === row.codERP
                              ),
                              status: ftType,
                              type: productType
                            }
                          })
                        }}
                      >
                        <SynchronizeStatusFt
                          cod={
                            ftReq.data?.find(
                              (ft) => ft.itemReference === row.codConjunto
                            )?._id
                          }
                        />
                      </MoreMenu>
                    </StyledTableCell>
                  </Can>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
            component="div"
            count={rows.length}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        </TableContainer>
        {/* {openDialog && <GenericDialog widthRoot={1150} style={{ minHeight: '400px', minWidth: '1100px', maxWidth: 'none' }}
          open={openDialog}
          close={() => setOpenDialog(false)}
          content={<FTAuditBox form={formData} closeDialog={handleCloseDialog} />} />} */}

        {selectFt && (
          <ModalFtProposalClient
            open={openModalFtProposalClient}
            onClose={() => {
              setOpenModalFtProposalClient(false)
              setSelectFt(null)
            }}
            ft={selectFt}
          />
        )}

        {selectFt && (
          <ModalFtSampleClient
            open={openModalFtSampleClient}
            onClose={() => {
              setOpenModalFtSampleClient(false)
              setSelectFt(null)
            }}
            ft={selectFt}
          />
        )}
      </div>
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress < 100) {
            return prevProgress + 1
        } else {
            setLoading(false)
            clearInterval(interval)
            return prevProgress
        }
      })
    }, 1500)

    return () => {
        clearInterval(interval)
    }
  }, [])

  return result
}

export default FTDevTableSamples
