import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getCodeParceirosByClientType } from 'services/report/DeliveryMapService'
import { LoadingDeliveryMap } from '../components/LoadingDeliveryMap'
import { ShowErrorDeliveryMap } from '../components/ShowErrorDeliveryMap'
import Wallet from './Wallet'

interface Props {
  date?: Date
}

const WalletContainer: React.FC<Props> = (props) => {
  const [dataValues, setDataValues] = useState<any[]>([])

  const { isError, isLoading, isFetching } = useQuery(
    ['deliveryMap-WalletContainer'],
    async () => {
      return getCodeParceirosByClientType('CARTEIRA')
    },
    {
      onSuccess: async (data) => {
        setDataValues(data ?? [])
      },
      onError: (error) => {
        console.log('error', error)
      },
      refetchOnWindowFocus: false
    }
  )

  return (
    <>
      <LoadingDeliveryMap isLoading={isLoading || isFetching} />

      <ShowErrorDeliveryMap showError={isError} />

      {!isLoading && !isFetching && (
        <Wallet date={props?.date} codeParceiros={dataValues ?? []} />
      )}
    </>
  )
}

export default WalletContainer
