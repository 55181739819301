/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable space-before-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getTargetProduction } from 'services/report/ReportBilling'
import { IdentifierClient } from 'types/Report/Report'
import { DataProduction, IdentifierClientProduction, IdentifierInformativeProduction } from 'types/Report/ProductionReportType'
import { getReportRH } from 'services/report/ReportRH'
import { formatValue } from 'utils/formatString'
import { calcularIndiceRefugo } from 'utils/commonValues'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FAFAFA',
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

interface Props {
  date: string
  multipleDate?: any
}
const TableTargetProduction: React.FC<Props> = (props) => {
  const mes = moment(props.date).format('M')
  const ano = moment(props.date).format('YYYY')
  const [dataValues, setDataValues] = useState<any[]>([])
  const indiceRefugo = calcularIndiceRefugo(props.date)
  const [valorRow] = useState<any[]>([
    {
      text: 'Mês Kg',
      value: IdentifierInformativeProduction.MES
    },
    {
      text: 'Meta dia Kg',
      value: IdentifierInformativeProduction.META_DIA
    },
    {
      text: 'Acumulado Kg',
      value: IdentifierInformativeProduction.ACUMULADO
    }
  ])

  const queryMultiple = () => {
    const req1 = useQuery(['target-production', ano, mes],
      async () => {
        return getTargetProduction(ano, mes)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )

    const req2 = useQuery(['days-planejed', props.date],
      async () => {
        return getReportRH(props.date)
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    )
    return [req1, req2]
  }

  const [req1, req2] = queryMultiple()

  useEffect(() => {
    if (!req1.isFetching && !req2.isFetching) {
      parseData(req1.data[0]?.previsions ?? [], req2.data)
    }
  }, [req1.isFetching, req2.isFetching])

  const parseData = (data, dataDaysPlanned) => {
    const plannedDaysCaixa = dataDaysPlanned.find(v => v.production.name === 'Caixa')?.days?.length ?? 0
    const plannedDaysChapa = dataDaysPlanned.find(v => v.production.name === 'Chapa')?.days?.length ?? 0
    const plannedDaysImpressora = plannedDaysCaixa
    const plannedDaysBobina = dataDaysPlanned.find(v => v.production.name === 'Bobina')?.days?.length ?? 0

    const automaticWorkDaysCaixa = dataDaysPlanned.find(v => v.production.name === 'Caixa')?.automaticWorksDays?.length ?? 0
    const automaticWorkDaysChapa = dataDaysPlanned.find(v => v.production.name === 'Chapa')?.automaticWorksDays?.length ?? 0
    const automaticWorkDaysImpressora = automaticWorkDaysCaixa
    const automaticWorkDaysBobina = dataDaysPlanned.find(v => v.production.name === 'Bobina')?.automaticWorksDays?.length ?? 0
    console.log({ indiceRefugo })

    if (data.length) {
      const monthKgBox = data.map(v => v.product === '623cf565692e6d4c8fe20136' ? v.pesoKg : 0)?.reduce((a, b) => a + b)
      const montKgChapa = data.map(v => v.product === '62321aa4fe24f24bc319b536' ? v.pesoKg : 0)?.reduce((a, b) => a + b)
      const monthKgPapel = data.map(v => v.product === '62321a4dfe24f24bc319b535' ? v.pesoKg : 0)?.reduce((a, b) => a + b)
      const rows = [
        {
          product: 'Caixa',
          monthKg: monthKgBox,
          dayKg: monthKgBox / plannedDaysCaixa,
          accumulated: (monthKgBox / plannedDaysCaixa) * automaticWorkDaysCaixa
        },
        {
          product: 'Chapa',
          monthKg: monthKgBox + montKgChapa,
          dayKg: (monthKgBox + montKgChapa) / plannedDaysChapa,
          accumulated: ((monthKgBox + montKgChapa) / plannedDaysChapa) * automaticWorkDaysChapa
        },
        {
          product: 'Impressoras',
          monthKg: monthKgBox,
          dayKg: monthKgBox / plannedDaysImpressora,
          accumulated: (monthKgBox / plannedDaysImpressora) * automaticWorkDaysImpressora
        },
        {
          product: 'Papel',
          monthKg: (monthKgBox + montKgChapa + monthKgPapel) + ((monthKgBox + montKgChapa) * indiceRefugo),
          dayKg: ((monthKgBox + montKgChapa + monthKgPapel) + ((monthKgBox + montKgChapa) * indiceRefugo)) / plannedDaysBobina,
          accumulated: (((monthKgBox + montKgChapa + monthKgPapel) + ((monthKgBox + montKgChapa) * indiceRefugo)) / plannedDaysBobina) * automaticWorkDaysBobina
        },
        {
          product: 'Total',
          monthKg: (monthKgBox + montKgChapa) + (monthKgBox + montKgChapa + monthKgPapel) + ((monthKgBox + montKgChapa) * indiceRefugo),
          dayKg:
            (monthKgBox / plannedDaysCaixa) +
            ((monthKgBox + montKgChapa) / plannedDaysChapa) +
            (((monthKgBox + montKgChapa + monthKgPapel) + ((monthKgBox + montKgChapa) * indiceRefugo)) / plannedDaysBobina) +
            (monthKgBox / plannedDaysImpressora),
          accumulated:
            ((monthKgBox / plannedDaysCaixa) * automaticWorkDaysCaixa) +
            (((monthKgBox + montKgChapa) / plannedDaysChapa) * automaticWorkDaysChapa) +
            ((monthKgBox / plannedDaysImpressora) * automaticWorkDaysImpressora) +
            ((((monthKgBox + montKgChapa + monthKgPapel) + ((monthKgBox + montKgChapa) * indiceRefugo)) / plannedDaysBobina) * automaticWorkDaysBobina)
        }
      ]
      setDataValues(rows)
    }
  }

  return (
    <TableContainer component={Paper} sx={{ height: 'min-content', width: 'min-content' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Produto</StyledTableCell>
            {valorRow.map((item, index) => {
              return (
                <StyledTableCell align="center" colSpan={1} key={index}>
                  {item.text}
                </StyledTableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataValues.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <StyledTableCell>{row.product}</StyledTableCell>
                <StyledTableCell align='center'>{formatValue(row.monthKg)}</StyledTableCell>
                <StyledTableCell align='center'>{formatValue(Number(row.dayKg).toFixed(0))}</StyledTableCell>
                <StyledTableCell align='center'>{formatValue(Number(row.accumulated).toFixed(0))}</StyledTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableTargetProduction
