/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { InfoOutlined } from '@mui/icons-material'
import ModulesProfile from './components/ModulesProfile'
import { httpClient } from '../../config/httpClient'
import { useState } from 'react'

import { useFormik } from 'formik'
import InputFile from 'components/form/Input/InputFile'
import { toast } from 'react-toastify'
import { createUser, updateUser } from 'services/user/UserService'
import { validationSchema } from 'types/validation/ValidationUser'
import FormUsertype from 'types/user/FormUserType'
import ModulesType from 'types/modules/ModulesType'
import AccordeonModules from './components/AcorrdeonModules'
import alertSwalCustom from 'components/Alerts/ModalCustom'
import { useSelector } from 'react-redux'
import { RootState } from 'core/store'
import { getSiglas } from 'services/orderService/OrderService'
import { useQuery } from 'react-query'

export default function UserForm() {
  const navigate = useNavigate()
  const { state }: any = useLocation()
  const { loginData } = useSelector((state: RootState) => state.login)
  const isLoggedUser = loginData?.email === state?.row?.email
  console.log(isLoggedUser)

  const [profileTypes, setProfileTypes] = useState<any[]>([])
  const [siglas, setSiglas] = useState<any[]>([])
  const [openSnack, setOpenSnack] = useState(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  React.useEffect(() => {
    loadProfiles()
    // loadModules()
  }, [])

  const loadProfiles = async () => {
    const response = await httpClient.get('/profiles')
    setProfileTypes(response.data)
  }

  const siglasReq = useQuery(
    'siglas',
    async () => {
      return getSiglas()
    },
    {
      onSuccess: (data) => {
        let result: any[] = []
        if (data.result) {
          result = data.result
          if (result.find((v: any) => v.sigla === 'MNE') === undefined) {
            const mneArray = [{ sigla: 'MNE' }]
            result = mneArray.concat(result)
          }
          setSiglas(result)
        }
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const updatePicture = async (blob) => {
    try {
      const formData = new FormData()
      formData.append('file', blob)
      await httpClient.patch('/users/photo/' + String(values._id), formData)
      toast.success('Foto salva com sucesso!')
    } catch (error: any) {
      if (error.response.data.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Erro ao alterar foto')
      }
    }
  }

  const { setFieldValue, errors, touched, values, handleChange, handleSubmit } =
    useFormik({
      onSubmit: async (values: FormUsertype) => {
        // if (
        //   !state?.row?._id &&
        //   (values.file === null ||
        //     values.file === undefined ||
        //     values.file === '')
        // ) {
        //   toast.error('Adicione uma foto')
        //   return false
        // }
        if (!state) {
          const formData = new FormData()
          formData.append('name', values.name)
          if (values?.file) {
            formData.append('file', values.file)
          }
          formData.append('email', values.email)
          formData.append('profile', values.profile)
          formData.append('sigla', values.sigla ?? 'MNE')
          formData.append(
            'nick',
            (String(values.nick).length > 0 ? values.nick : '_') ?? ''
          )
          formData.append('occupation', values.role)
          formData.append('departament', values.sector)
          formData.append(
            'modules',
            JSON.stringify({ modules: values.modules })
          )
          const dadosForm = formData as unknown as FormUsertype

          setLoading(true)
          try {
            await createUser(dadosForm)
            toast.success('Usuario salvo com sucesso!')
            setTimeout(() => {
              navigate('/home/registration')
            }, 2500)
          } catch (error: any) {
            if (error.response.data.message) {
              toast.error(error.response.data.message)
            } else {
              toast.error('Erro ao salvar informações do usuario')
            }
          } finally {
            setLoading(false)
          }
        } else {
          setLoading(true)
          console.log('MODULES', values.modules)
          try {
            const dadosBody: any = {
              name: values.name,
              email: values.email,
              profile: values.profile,
              occupation: values.role,
              departament: values.sector,
              nick: values.nick ?? '_',
              sigla: values.sigla,
              modules: values.modules
            }
            await updateUser(String(values._id), dadosBody)
            toast.success('Dados editados com sucesso!')
            navigate('/home/registration')
            // setTimeout(() => {
            //   navigate('/home/registration')
            // }, 2500)
          } catch (error: any) {
            console.log('erro', error.response.data)
            if (error.response.data.message) {
              toast.error(error.response.data.message)
            } else {
              toast.error('Erro ao editar informações do usuario')
            }
          } finally {
            setLoading(false)
          }
        }
      },
      enableReinitialize: true,
      validationSchema,
      initialValues: {
        name: state?.row?.name ?? '',
        email: state?.row?.email ?? '',
        sector: state?.row?.departament ?? '',
        profile: state?.row?.profile?._id ?? '',
        modules: state?.row?.modules ?? [],
        role: state?.row?.occupation ?? '',
        nick: state?.row?.nick ?? '',
        file: state?.row?.url ?? '',
        sigla: state?.row?.sigla ?? 'MNE',
        _id: state?.row?._id ?? ''
      }
    })

  if (siglasReq.isFetching) {
    return (
      <Box sx={{ display: 'flex' }}
        style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <form onSubmit={handleSubmit}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                onClick={() => {
                  navigate('/home/registration')
                }}
              >
                Usuários
              </Link>
              <Typography color="text.primary">Cadastro</Typography>
            </Breadcrumbs>

            <div
              style={{
                marginTop: '10px',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '20px'
              }}
            >
              Dados do usuário
            </div>

            <span style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  marginRight: '30px'
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    alignSelf: 'center',
                    marginRight: '40px'
                  }}
                >
                  <InputFile
                    getFile={async (file, blob) =>
                      state ? updatePicture(blob) : setFieldValue('file', blob)
                    }
                    preview={values.file}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px'
                  }}
                >
                  <InfoOutlined color="primary" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '10px'
                    }}
                  >
                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Importante
                    </div>
                    <div style={{ fontSize: '12px' }}>
                      Tamanho máximo 2mb, 256x256 px.
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              >
                <TextField
                  error={!!(errors.name && touched.name)}
                  id="standard-basic"
                  label="Nome completo"
                  variant="standard"
                  value={values.name}
                  onChange={handleChange('name')}
                  helperText={
                    errors.name && touched.name ? 'Campo Obrigatório' : ''
                  }
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  style={{ marginTop: '6px' }}
                  id="standard-basic"
                  label="Seu e-mail"
                  autoComplete="off"
                  type="email"
                  variant="standard"
                  value={values.email}
                  onChange={handleChange('email')}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  autoComplete="off"
                  error={!!(errors.sector && touched.sector)}
                  style={{ marginTop: '6px' }}
                  id="standard-basic"
                  label="Setor"
                  variant="standard"
                  value={values.sector}
                  onChange={handleChange('sector')}
                  helperText={
                    errors.sector && touched.sector ? 'Campo Obrigatório' : ''
                  }
                />
                <TextField
                  autoComplete="off"
                  error={!!(errors.nick && touched.nick)}
                  style={{ marginTop: '6px' }}
                  id="standard-basic"
                  label="Apelido"
                  variant="standard"
                  value={values.nick}
                  onChange={handleChange('nick')}
                  helperText={
                    errors.nick && touched.nick ? 'Campo Obrigatório' : ''
                  }
                />
                <div style={{ display: 'flex', flex: 1, marginTop: '6px' }}>
                  <FormControl
                    variant="standard"
                    sx={{ width: '100%' }}
                    error={!!(errors.sigla && touched.sigla)}
                  >
                    <InputLabel id="demo-simple-select-label">Sigla</InputLabel>
                    <Select
                      autoComplete="off"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sigla"
                      value={values.sigla}
                      onChange={(event: SelectChangeEvent<string>) => {
                        setFieldValue('sigla', event.target.value)
                      }}
                    >
                      {siglas
                        .map((v: any) => {
                          return (
                            <MenuItem value={v.sigla}>
                              {v.sigla}
                            </MenuItem>
                          )
                        })}
                    </Select>
                    <FormHelperText>
                      {errors.sigla && touched.sigla
                        ? 'Campo Obrigatório'
                        : ''}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </span>

            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flex: 1, marginRight: '30px' }}>
                <FormControl
                  variant="standard"
                  sx={{ width: '80%', left: 20 }}
                  error={!!(errors.profile && touched.profile)}
                >
                  <InputLabel id="demo-simple-select-label">Perfil</InputLabel>
                  <Select
                    autoComplete="off"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Perfil"
                    value={values.profile}
                    onChange={(event: SelectChangeEvent<string>) => {
                      event.target.value === '62164914667023c3cc443579'
                        ? alertSwalCustom(() => {
                          setFieldValue('profile', event.target.value)
                        }, 'Por motivos de segurança, o perfil vendedor não poderá ser alterado. Deseja confirmar a seleção?')
                        : setFieldValue('profile', event.target.value)
                    }}
                  >
                    {profileTypes
                      .filter((p) => p.status)
                      .map((item: any) => {
                        return (
                          <MenuItem value={item._id} disabled={isLoggedUser}>
                            {item.name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                  <FormHelperText>
                    {errors.profile && touched.profile
                      ? 'Campo Obrigatório'
                      : ''}
                  </FormHelperText>
                </FormControl>
              </div>

              <div style={{ display: 'flex', flex: 1 }}>
                <TextField
                  error={!!(errors.role && touched.role)}
                  style={{ width: '100%' }}
                  id="standard-basic"
                  label="Função"
                  variant="standard"
                  value={values.role}
                  onChange={handleChange('role')}
                  helperText={
                    errors.role && touched.role ? 'Campo Obrigatório' : ''
                  }
                />
              </div>
            </span>

            <div style={{ marginTop: '30px', fontSize: '16px' }}>
              Selecione os módulos de acesso desse usuário
            </div>
            <AccordeonModules
              modules={state?.row?.modules ?? []}
              disabled={isLoggedUser}
              onChange={(modules) => {
                // console.log(modules)
                setFieldValue('modules', modules)
              }}
            />
          </div>
          <div style={{ display: 'flex', flex: 1 }}></div>
        </span>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            style={{
              marginRight: '10px',
              backgroundColor: '#EE207A',
              borderRadius: '2px',
              width: '122px',
              color: 'white'
            }}
            onClick={() => navigate('/home/registration')}
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: '#4060E6',
              borderRadius: '2px',
              width: '122px',
              color: 'white'
            }}
            type="submit"
          >
            {loading ? 'CARREGANDO...' : 'SALVAR'}
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnack}
          onClose={handleCloseSnack}
          autoHideDuration={2000}
          key="top center"
        />
      </form>
    </Box>
  )
}
