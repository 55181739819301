import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    :root{
        --background: white;
        --red: #e52e4d;
        --blue: #5429cc;
        --blue-light: #6933ff;
        --text-title: #363f5f;
        --text-body: #969cb3;
        --shape: #ffffff;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    html{
        @media (max-width: 1080px){
            font-size: 93.75%;
        }
        @media (max-width: 720px){
            font-size: 87.5%;
        }
    }
    body{
        background: var(--background);
        -webkit-font-font-smooth: antialiased;
    }
    body, input, textarea, button {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
    }
    h1, h2, h3, h4, h5, h6{
        font-weight: 600;
    }

    button{
        cursor: pointer;
    }
    [disabled]{
        opacity: 0.6;
        cursor: not-allowed;
    }

    .w-100 {
        width: 100%;
    }

    .text-right {
        text-align: right;
    }

    .d-inline-block {
        display: inline-block;
    }

    .d-none {
        display: none !important;
    }

    /* .MuiCardHeader-title {
        font: normal normal bold 16px/19px Roboto;
        font-family: Roboto;
        font-size: 1rem;
        color: #7F85F9;
        padding: 0px 0px 5px 0px;
    } */

    .MuiCardHeader-action{
        margin-top: -10px
    }

    ::-webkit-file-upload-button {
      display: none;
    }

    .css-12fx8pg {
        width: 100%;
        font-family: IBM Plex Sans,sans-serif;
        font-size: 0.875rem;
    }

    // .css-dwuj3p-MuiTableCell-root {
    //     width: 1%;
    // }

    .item_breadcrumbs {
        text-decoration: none;
        /* font-family: "Poppins"; */
      }

  .Mui-disabled {
    color: #777777 !important;
  }


  .css-1ss2ei0-MuiContainer-root {
    @media (max-width: 500px){
        max-width: 450px;
    }

    @media (max-width:  1937px){
        max-width:  95%;
    }
}
    .MuiBox-root {
        @media (min-width: 1200px) {
            max-width: 2000px;
    }
}
`
