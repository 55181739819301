/* eslint-disable @typescript-eslint/no-unused-vars */
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Stack, Tab } from '@mui/material'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import React, { useState } from 'react'
import ActiveFT from './sampleDevFT/activeFT/ActiveFT'
import DisabledFT from './DisabledFT'
import SampleFT from './sampleDevFT/sampleFT/SampleFT'
import DevFT from './sampleDevFT/devFT/DevFT'
import PreFT from './preFT/PreFT'
import { Add, Calculate } from '@mui/icons-material'
import { Link, useLocation } from 'react-router-dom'
import FTMnemosine from './sampleDevFT/devFT/FTMnemosine'

const tabs = {
  pre: '1',
  dev: '2',
  simple: '3',
  active: '4',
  inactive: '5',
  mnemosine: '6'
}

function FT() {
  const { search: searchLocation } = useLocation()
  console.log(searchLocation)
  const type = searchLocation?.replace('?', '')?.split('&')?.find((item) => item.includes('tab'))?.replace('tab=', '')
  console.log(type)

  const [value, setValue] = React.useState(tabs[type ?? 'pre'])
  // const [search, setSearch] = React.useState('')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  // const handleSearch = (query) => {
  //   console.log('search', query)
  //   setSearch(query)
  // }

  return (
    <Can module={MODULES.FT} permissions={[PERMISSIONS.READ]}>
      <div>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          {/* <FTHeader search={handleSearch} /> */}

          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to="/home/ft/portfolio"
            sx={{
              borderRadius: '2px',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }}
          >
            Nova Pré FT
          </Button>

          <Button
            variant="outlined"
            startIcon={<Calculate />}
            component={Link}
            to="/home/calculator"
            sx={{
              borderRadius: '2px',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }}
          >
            Calculadora
          </Button>

        </Stack>
        <div style={{ marginTop: 30 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Pré FT" value="1" />
                <Tab label="FT Desenvolvimento" value="2" />
                <Tab label="FT Amostra" value="3" />
                <Tab label="FT Ativo" value="4" />
                <Tab label="FT Desativada" value="5" />
                <Tab label="FT Mnemosine" value="6" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <PreFT />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0 }}>
              <DevFT />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0 }}>
              <SampleFT />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: 0 }}>
              <ActiveFT />
            </TabPanel>
            <TabPanel value="5" sx={{ padding: 0 }}>
              <DisabledFT />
            </TabPanel>
            <TabPanel value="6" sx={{ padding: 0 }}>
              <FTMnemosine />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </Can>
  )
}

export default FT
