/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Formik, Form, FieldArray, FormikErrors } from 'formik'
import Button from 'components/form/Button'
import Add from '@mui/icons-material/Add'
import {
  Grid,
  TextField,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import styled from 'styled-components'
import ClearIcon from '@mui/icons-material/Clear'
import { useLocation } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { RepresentativeType } from 'types/customerControl/FormCustomerControlType'
import { toast } from 'react-toastify'
import {
  createClientRepresentatives,
  deleteClientRepresentative
} from 'services/customerControl/CustomerControlServices'
import { validationRepresentativeSchema } from '../validations/validationRepresentativeSchema'
import { httpClient } from 'config/httpClient'

const TitleSection = styled('p')`
  font: normal normal bold 16px/22px Roboto;
  letter-spacing: 0px;
  color: #262626;
`

const ButtonAdd = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  border: none;
  text-align: center;
  font: normal normal normal 12px/22px Roboto;
  letter-spacing: 0px;
  color: #8a8a8a;

  &:hover {
    background: #f5f5f5;
  }
`

const ButtonCancel = styled.button`
  border-radius: 2px;
  border: none;
  background: #f5222d;
  color: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Props {
  goBack: () => void
  nextStep: () => void
}

const FormCustomerRepresentatives: React.FC<Props> = ({ goBack, nextStep }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { state }: any = useLocation()
  const [representativesClient, setRepresentativesClient] = useState<
    RepresentativeType[]
  >([])

  useEffect(() => {
    if (state?.client?._id) {
      setLoading(true)
      httpClient
        .get(`company/${state.client._id}/collaborators`)
        .then((response) => {
          setRepresentativesClient(
            response.data?.length
              ? response.data
              : [
                  {
                    name: '',
                    phone: '',
                    email: '',
                    isActive: true
                  }
                ]
          )
        })
        .catch((error) => {
          toast.error(
            error.response.data.message ?? 'Erro ao buscar os representantes'
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [state?.client?._id])

  const checkErrorRepresentatives = (item: unknown, name: string) => {
    const error = item as FormikErrors<{ [key: string]: string }>
    return Boolean(error?.[name])
  }

  const checkErrorTouchedRepresentatives = (item: unknown, name: string) => {
    const error = item as FormikErrors<{ [key: string]: string }>
    return Boolean(error?.[name])
  }

  const getErrorRepresentatives = (item: unknown, name: string) => {
    const error = item as FormikErrors<{ [key: string]: string }>
    return error?.[name] ? error?.[name] : ''
  }

  const handleDeleteRepresentative = async (
    callback: (() => void) | undefined,
    idC: string | undefined
  ) => {
    console.log(idC)
    if (!callback) return

    if (!idC) {
      callback()
      return
    }

    try {
      await deleteClientRepresentative(state?.client?._id, idC)
      callback()
    } catch (error: any) {
      toast.error(error?.response.data?.message)
      return false
    }
  }

  if (loading && !representativesClient?.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
        width="100%"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      {!!representativesClient?.length && (
        <Formik
          initialValues={{
            _id: state?.client?._id ?? '',
            representatives: representativesClient
          }}
          validationSchema={validationRepresentativeSchema}
          onSubmit={async (values) => {
            if (!state?.client?._id) {
              toast.error(
                'Erro cliente não encontrado para salvar os dados dos representantes'
              )
              return
            }

            const hasDuplicatedRepresentative = values?.representatives?.filter((representative) => {
              return (
                values?.representatives?.filter((representative2) => {
                  return (
                    representative?.name?.toLowerCase() ===
                    representative2?.name?.toLowerCase()
                  )
                }).length > 1
              )
            })
            if (hasDuplicatedRepresentative?.length) {
              toast.error('Não é permitido repetir representante. O nome está duplicado')
              return
            }

            const hasDuplicatedRepresentativeEmail = values?.representatives?.filter((representative) => {
              return (
                values?.representatives?.filter((representative2) => {
                  return (
                    representative?.email?.toLowerCase() ===
                    representative2?.email?.toLowerCase()
                  )
                }).length > 1
              )
            })
            if (hasDuplicatedRepresentativeEmail?.length) {
              toast.error('Não é permitido repetir representante. O e-mail está duplicado')
              return
            }

            const hasDuplicatedRepresentativePhone = values?.representatives?.filter((representative) => {
              return (
                values?.representatives?.filter((representative2) => {
                  return (
                    representative?.phone ===
                    representative2?.phone
                  )
                }).length > 1
              )
            })
            if (hasDuplicatedRepresentativePhone?.length) {
              toast.error('Não é permitido repetir representante. O telefone está duplicado')
              return
            }

            console.log(hasDuplicatedRepresentative)

            console.log(values)

            const formData = values?.representatives?.map((item: any) => {
              if (item?._id) {
                return item
              }

              return {
                name: item.name,
                phone: item.phone,
                email: item.email,
                tag: 'create'
              }
            })

            console.log(values?.representatives, formData)

            try {
              setLoading(true)
              await createClientRepresentatives(
                state.client?._id,
                formData
              )
            } catch (error: any) {
              const replace = 'User validation failed: email: '
              const replaceTwo = 'Collaborator validation failed: email: '
              toast.error(error?.response?.data?.message?.replaceAll(replace, '')?.replaceAll(replaceTwo, ''))
              return
            } finally {
              setLoading(false)
            }

            toast.success('Representantes salvo com sucesso!')
            nextStep()
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Grid container spacing={4}>
                <FieldArray
                  name="representatives"
                  validateOnChange={false}
                  render={(arrayHelpers) => (
                    <>
                      <Grid item xs={12}>
                        <TitleSection style={{ marginBottom: '10px' }}>
                          Representantes
                        </TitleSection>
                        {values.representatives.map((representative, index) => (
                          <Grid
                            container
                            spacing={3}
                            key={`key_representative_${index}`}
                            style={{ marginBottom: '30px' }}
                          >
                            <Grid item xs={3}>
                              <TextField
                                error={
                                  checkErrorRepresentatives(
                                    errors.representatives?.[index],
                                    'name'
                                  ) &&
                                  checkErrorTouchedRepresentatives(
                                    touched.representatives?.[index],
                                    'name'
                                  )
                                }
                                value={representative.name}
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                label="Nome"
                                required
                                helperText={
                                  checkErrorRepresentatives(
                                    errors.representatives?.[index],
                                    'name'
                                  ) &&
                                  checkErrorTouchedRepresentatives(
                                    touched.representatives?.[index],
                                    'name'
                                  )
                                    ? getErrorRepresentatives(
                                        errors.representatives?.[index],
                                        'name'
                                      )
                                    : null
                                }
                                onChange={handleChange(
                                  `representatives.${index}.name`
                                )}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <InputMask
                                mask="(99) 99999-9999"
                                disabled={false}
                                maskChar=""
                                value={representative.phone}
                                onChange={handleChange(
                                  `representatives.${index}.phone`
                                )}
                              >
                                {() => (
                                  <TextField
                                    error={
                                      checkErrorRepresentatives(
                                        errors.representatives?.[index],
                                        'phone'
                                      ) &&
                                      checkErrorTouchedRepresentatives(
                                        touched.representatives?.[index],
                                        'phone'
                                      )
                                    }
                                    variant="standard"
                                    fullWidth
                                    autoComplete="off"
                                    label="Telefone"
                                    required
                                    helperText={
                                      checkErrorRepresentatives(
                                        errors.representatives?.[index],
                                        'phone'
                                      ) &&
                                      checkErrorTouchedRepresentatives(
                                        touched.representatives?.[index],
                                        'phone'
                                      )
                                        ? getErrorRepresentatives(
                                            errors.representatives?.[index],
                                            'phone'
                                          )
                                        : null
                                    }
                                  />
                                )}
                              </InputMask>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                value={representative.email}
                                autoComplete="off"
                                error={
                                  checkErrorRepresentatives(
                                    errors.representatives?.[index],
                                    'email'
                                  ) &&
                                  checkErrorTouchedRepresentatives(
                                    touched.representatives?.[index],
                                    'email'
                                  )
                                }
                                type="email"
                                fullWidth
                                variant="standard"
                                onChange={async (event) =>
                                  setFieldValue(
                                    `representatives.${index}.email`,
                                    event?.target?.value?.toLocaleLowerCase()
                                  )
                                }
                                label="E-mail"
                                required
                                helperText={
                                  checkErrorRepresentatives(
                                    errors.representatives?.[index],
                                    'email'
                                  ) &&
                                  checkErrorTouchedRepresentatives(
                                    touched.representatives?.[index],
                                    'email'
                                  )
                                    ? getErrorRepresentatives(
                                        errors.representatives?.[index],
                                        'email'
                                      )
                                    : null
                                }
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <FormControl variant="standard" fullWidth>
                                <InputLabel
                                  id={`representatives.${index}.isActive`}
                                >
                                  Status
                                </InputLabel>
                                <Select
                                  labelId={`representatives.${index}.isActive`}
                                  id={`representatives.${index}.isActive-id`}
                                  value={
                                    representative?.isActive
                                      ? 'Ativo'
                                      : 'Inativo'
                                  }
                                  label="Status"
                                  onChange={(event) => {
                                    console.log(representative?.isActive, `representatives.${index}.isActive`,
                                    event?.target?.value === 'Ativo')
                                    setFieldValue(
                                      `representatives.${index}.isActive`,
                                      event?.target?.value === 'Ativo'
                                    )
                                  }}
                                  required
                                >
                                  <MenuItem
                                    value="Ativo"
                                  >
                                    Ativo
                                  </MenuItem>
                                  {representative?._id && (
                                    <MenuItem
                                      value="Inativo"
                                    >
                                      Inativo
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={1}>
                              <ButtonCancel
                                type="button"
                                // disabled={
                                //   values.representatives?.length <= 1 ||
                                //   !!representative?._id
                                // }
                                onClick={() => {
                                  console.log(representative?._id)
                                  handleDeleteRepresentative(
                                    () => arrayHelpers.remove(index),
                                    representative?._id
                                  )
                                }}
                              >
                                <ClearIcon />
                              </ButtonCancel>
                            </Grid>
                          </Grid>
                        ))}

                        <ButtonAdd
                          type="button"
                          style={{ marginTop: '30px' }}
                          onClick={() =>
                            arrayHelpers.push({
                              name: '',
                              phone: '',
                              email: '',
                              isActive: true
                            })
                          }
                        >
                          <Add /> Adicionar Representante
                        </ButtonAdd>
                      </Grid>
                    </>
                  )}
                />

                <Grid item md={12} className="text-right">
                  <Button
                    type="button"
                    mold="primary"
                    onClick={goBack}
                    style={{ marginRight: '10px' }}
                    disabled={loading}
                  >
                    Voltar
                  </Button>
                  <Button
                    mold="primary"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default FormCustomerRepresentatives
