import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { validationSchema } from '../../types/validation/ValidationProfile'
import { createProfile, updateProfile } from '../../services/profile/ProfileService'
import FormProfileType from '../../types/profiles/FormProfileType'
import { toast } from 'react-toastify'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'

export default function ProfileForm(props: any) {
  const navigate = useNavigate()

  const { state }: any = useLocation()

  const [loading, setLoading] = React.useState<boolean>(false)

  // React.useEffect(() => {
  //   setState(props.state)
  // }, [props.state])

  const {
    setFieldValue,
    errors,
    touched,
    values,
    handleChange,
    handleSubmit
  } = useFormik({
    onSubmit: async (values: FormProfileType) => {
      const valuesForm: FormProfileType = {
        name: values.name
      }
      if (!state) {
        setLoading(true)
        try {
          const { data } = await createProfile(valuesForm)
          if (data) {
            toast.success('Perfil salvo com sucesso!')
            setTimeout(() => {
              navigate('/home/profile-control')
            }, 2500)
          } else {
            toast.error('Erro ao salvar perfil')
          }
        } catch (error: any) {
          console.log('erro', error.response.data)
        } finally {
          setLoading(false)
        }
      } else {
        setLoading(true)
        const valuesFormEdit: FormProfileType = {
          name: values.name,
          status: values.status === 1
        }
        try {
          const { data } = await updateProfile(String(values._id), valuesFormEdit)
          if (!data) {
            toast.success('Perfil editado com sucesso!')
            setTimeout(() => {
              navigate('/home/profile-control')
            }, 2500)
          } else {
            toast.error('Erro ao salvar Perfil')
          }
        } catch (error: any) {
          console.log('erro', error.response.data)
        } finally {
          setLoading(false)
        }
      }
    },
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      name: state?.row?.name ?? '',
      disabled: state?.disabled,
      _id: state?.row?._id ?? '',
      status: state?.row?.status ? 1 : 2

    }
  })

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form onSubmit={handleSubmit}>
          {
            values.disabled
              ? <></>
              : <BreadcrumbsComponent
                breadcrumbs={[
                  { text: 'Perfis', url: '/home/profile-control' },
                  { text: 'Cadastro' }
                ]}
              />
          }

          <div style={{ marginTop: '10px', fontSize: '16px' }}>
            Informações do perfill {values.disabled}
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
              error={!!(errors.name && touched.name)}
              id="standard-basic"
              label="Nome"
              variant="standard"
              disabled={values.disabled}
              value={values.name}
              onChange={handleChange('name')}
              helperText={errors.name && touched.name ? 'Campo Obrigatorio' : ''} />

            {state
              ? <FormControl variant="standard" sx={{ minWidth: 150, left: 20 }}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  disabled={values.disabled}
                  value={values.disabled ? values.status === 1 ? 'Ativo' : 'Inativo' : values.status}
                  onChange={(event: SelectChangeEvent<number>) => {
                    setFieldValue('status', event.target.value)
                  }}
                >
                  {
                    values.disabled
                      ? []
                      : [<MenuItem value={1}>Ativo</MenuItem>, <MenuItem value={2}>Inativo</MenuItem>]
                  }

                </Select>
              </FormControl>
              : ''}
          </div>
          <div style={{ marginTop: '34%', fontSize: '16px', display: values.disabled ? 'none' : '' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{
                  marginRight: '10px',
                  backgroundColor: '#EE207A',
                  borderRadius: '2px',
                  width: '122px',
                  color: 'white'
                }}
                onClick={() => navigate('/home/profile-control')}
              >
                Cancelar
              </Button>
              <Button
                style={{
                  backgroundColor: '#4060E6',
                  borderRadius: '2px',
                  width: '122px',
                  color: 'white'
                }}
                type="submit"
                disabled={values.disabled}
              >
                {loading ? 'CARREGANDO...' : 'SALVAR'}
              </Button>
            </div>
          </div>
        </form>
      </Box>
    </>
  )
}
