import { Box } from '@mui/material'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import React from 'react'
import FormManagementControl from './components/FormManagementControl'

const ProvisionManagementControl: React.FC = () => {
  return (
    <>
      <BreadcrumbsComponent
        breadcrumbs={[
          { text: 'Gerencial', url: '/home/managementControl' },
          { text: 'Previsões', url: '/home/managementControl/management' },
          { text: 'Novas previsões' }
        ]}
      />

      <Box style={{ marginTop: '20px' }}>
        <FormManagementControl />
      </Box>
    </>
  )
}

export default ProvisionManagementControl
