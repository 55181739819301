/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import Button from 'components/form/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { PortfolioPaperType } from 'types/portfolio/PortfolioPaperType'
import {
  getAllCodBoxes,
  getCardboard,
  getCompositions,
  getFechamentos,
  getPortfolioBoxes,
  getPortfolioPapers,
  getPortfolioPlates,
  postFormularioCaixa,
  postPortfolioBoxes,
  postPortfolioBoxesForm,
  putFormularioCaixa,
  putPortfolioBoxes,
  putPortfolioBoxesForm
} from 'services/portfolio/PortfolioService'
import { PortfolioPlateType } from 'types/portfolio/PortfolioPlateType'
import { useFormik } from 'formik'
import { httpClient } from 'config/httpClient'
import { toast } from 'react-toastify'
import { onlyNumbers, onlyNumbersOrPoint } from 'helpers/stringHelper'
import { useMutation, useQueryClient } from 'react-query'
import { validationBoxType } from '../validations/validationBoxType'

import './scrollbar.css'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const breadcrumbs = [
  { text: 'Empresa', url: '/home/company' },
  { text: 'Portfólio PCE', url: '/home/portfolio' },
  { text: 'Selecionar Produto', url: '/home/portfolio/products' },
  { text: 'Formulário de Produto' }
]

function Section({ title, children }: { title: string, children: ReactNode }) {
  return (
    <>
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <h4>{title}</h4>
      </Grid>
      {children}
    </>
  )
}

const FormCaixas: React.FC = (props: any) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [boxes, setBoxes] = useState<any[]>([])
  const [composicao, setComposicao] = useState<any[]>([])
  const [fechamentos, setFechamentos] = useState<any[]>([])
  const [codBoxes, setCodBoxes] = useState<any[]>([])

  const [cores, setCores] = useState<any[]>([])

  // const { editable, row } = props
  const { editable, row }: any = useLocation().state ?? {
    editable: false,
    row: {}
  }

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(
    putFormularioCaixa,
    {
      onSuccess: (data) => {
        console.log(data)
        toast.success('Editado com sucesso')
        navigate('/home/portfolio/2')
      },
      onError: (e: any) => {
        if (e.response) {
          toast.error(e.response.data.message)
        } else {
          toast.error('Não foi possível cadastrar')
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('box-form')
      }
    }
  )

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    postFormularioCaixa,
    {
      onSuccess: (data) => {
        console.log(data)
        toast.success('Cadastrado com sucesso')
        navigate('/home/portfolio/products')
      },
      onError: (e: any) => {
        if (e.response) {
          toast.error(e.response.data.message)
        } else {
          toast.error('Não foi possível cadastrar')
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('box-form')
      }
    }
  )

  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    onSubmit: (values: any) => {
      if (editable) {
        mutateEdit({
          ...values,
          _id: row._id
        })
      } else {
        mutateCreate(values)
      }
    },
    enableReinitialize: true,
    // validationSchema: validationBoxType,
    initialValues: {
      Referencia: row?.Referencia ?? '',
      LarguraInterna: row?.LarguraInterna ?? undefined, // number
      ComprimentoInterno: row?.ComprimentoInterno ?? undefined, // number
      AlturaInterna: row?.AlturaInterna ?? undefined, // number
      Composicao: {
        composicao: row?.Composicao?.composicao ?? '',
        onda: row?.Composicao?.onda ?? '',
        onda1: row?.Composicao?.onda1 ?? '',
        onda2: row?.Composicao?.onda2 ?? '',
        onda3: row?.Composicao?.onda3 ?? '',
        onda4: row?.Composicao?.onda4 ?? '',
        onda5: row?.Composicao?.onda5 ?? '',
        papel1: row?.Composicao?.papel1 ?? '',
        papel2: row?.Composicao?.papel2 ?? '',
        papel3: row?.Composicao?.papel3 ?? '',
        papel4: row?.Composicao?.papel4 ?? '',
        papel5: row?.Composicao?.papel5 ?? '',
        nomeComercial: row?.Composicao?.nomeComercial ?? '',
        mullen: row?.Composicao?.mullen ?? undefined, // number
        crushEnd: row?.Composicao?.crushEnd ?? undefined, // number
        gramatura: row?.Composicao?.gramatura ?? undefined, // number
        crush: row?.Composicao?.crush ?? undefined, // number
        preco: row?.Composicao?.preco ?? undefined, // number
        coluna: row?.Composicao?.coluna ?? undefined, // number
        espessura: row?.Composicao?.espessura ?? undefined // number
      },
      Fechamento: {
        fechamento: row?.Fechamento?.fechamento ?? '',
        descricao: row?.Fechamento?.descricao ?? '',
        qtdEspessuras: row?.Fechamento?.qtdEspessuras ?? undefined // number
      },
      boxType: row?.boxType?._id ?? '',
      codTypebox: row?.codTypebox?._id ?? '',
      Paletizado: row?.Paletizado ?? undefined, // boolean
      Amarrado: row?.Amarrado ?? undefined, // boolean
      ExigeLaudo: row?.ExigeLaudo ?? undefined, // boolean
      Obs1: row?.Obs1 ?? '',
      PaletePadrao: row?.PaletePadrao ?? undefined, // boolean
      PaletePadraoLargura: row?.PaletePadraoLargura ?? '',
      PaletePadraoComprimento: row?.PaletePadraoComprimento ?? '',
      PaleteReforcado: row?.PaleteReforcado ?? undefined, // boolean
      PaleteReforcadoQuantidadeFita: row?.PaleteReforcadoQuantidadeFita ?? '',
      PaleteReforcadoQuantidadeLastro:
        row?.PaleteReforcadoQuantidadeLastro ?? '',
      AmarradoPalete: row?.AmarradoPalete ?? undefined, // boolean
      AmarradoPaleteQtd: row?.AmarradoPaleteQtd ?? '',
      Alcas: row?.Alcas ?? false, // boolean
      Furo: row?.Furo ?? false, // boolean
      AlcasValue: row?.AlcasValue ?? '',
      FuroValue: row?.FuroValue ?? '',
      Amostra: row?.Amostra ?? undefined, // boolean
      AmostraQtd: row?.AmostraQtd ?? '',
      Impressoes: row?.Impressoes ?? undefined, // boolean
      ImpressoesQtd: row?.ImpressoesQtd ?? '',
      ImpressoesCor: row?.ImpressoesCor ?? {
        cor1: '',
        cor2: '',
        cor3: '',
        cor4: '',
        cor5: '',
        cor6: '',
        cor7: '',
        cor8: '',
        cor9: '',
        cor10: ''
      },
      Tara: row?.Tara ?? undefined, // boolean
      Batido: row?.Batido ?? undefined, // boolean
      Definicao: row?.Definicao ?? '',
      NovoProduto: {
        BasedaNaFTD: row?.NovoProduto?.BasedaNaFTD ?? undefined, // boolean
        DesnehoEspecificacao:
          row?.NovoProduto?.DesnehoEspecificacao ?? undefined, // boolean
        AmostraMecanica: row?.NovoProduto?.AmostraMecanica ?? undefined, // boolean
        ProdutoDoCliente: row?.NovoProduto?.ProdutoDoCliente ?? undefined, // boolean
        ProdutoSemelhante: row?.NovoProduto?.ProdutoSemelhante ?? undefined, // boolean
        Outros: row?.NovoProduto?.Outros ?? undefined, // boolean
        OutrosObs: row?.NovoProduto?.OutrosObs ?? ''
      } // boolean
    }
  })

  const handleAddCor = () => {
    if (cores.length >= 10) return

    setCores((state) => [...state, { name: `cor${cores.length + 1}` }])
  }

  const handleRemoveCor = async (name: string) => {
    setCores((state) => state?.filter((cor) => cor.name !== name))
    await setFieldValue(`ImpressoesCor.${name}`, '')
  }

  const loadCor = useCallback(() => {
    const colors: any[] = []
    if (row?.ImpressoesCor?.cor1) {
      colors.push({ name: 'cor1' })
    }
    if (row?.ImpressoesCor?.cor2) {
      colors.push({ name: 'cor2' })
    }
    if (row?.ImpressoesCor?.cor3) {
      colors.push({ name: 'cor3' })
    }
    if (row?.ImpressoesCor?.cor4) {
      colors.push({ name: 'cor4' })
    }
    if (row?.ImpressoesCor?.cor5) {
      colors.push({ name: 'cor5' })
    }
    if (row?.ImpressoesCor?.cor6) {
      colors.push({ name: 'cor6' })
    }
    if (row?.ImpressoesCor?.cor7) {
      colors.push({ name: 'cor7' })
    }
    if (row?.ImpressoesCor?.cor8) {
      colors.push({ name: 'cor8' })
    }
    if (row?.ImpressoesCor?.cor9) {
      colors.push({ name: 'cor9' })
    }
    if (row?.ImpressoesCor?.cor10) {
      colors.push({ name: 'cor10' })
    }

    setCores(colors)
  }, [row?.ImpressoesCor])

  const loadData = useCallback(() => {
    getCompositions()
      .then((data) => {
        console.log('TRIMBOX Composição', data)
        setComposicao(data ?? [])
      })
      .catch((error) => {
        console.log('Error:', error)
      })
    getFechamentos()
      .then((data) => {
        console.log('TRIMBOX Fechamento', data)
        setFechamentos(data ?? [])
      })
      .catch((error) => {
        console.log('Error:', error)
      })
    getAllCodBoxes()
      .then((data) => {
        console.log('COD Caixa', data)
        setCodBoxes(data ?? [])
      })
      .catch((error) => {
        console.log('Error:', error)
      })
    getPortfolioBoxes()
      .then((data) => {
        console.log('Tipos Caixa', data)
        setBoxes(data ?? [])
      })
      .catch((error) => {
        console.log('Error:', error)
      })
  }, [])

  useEffect(() => {
    loadCor()
    loadData()

    return () => {
      setComposicao([])
      setFechamentos([])
      setBoxes([])
      setCodBoxes([])
    }
  }, [loadData])

  const handleReference = useCallback(
    ({ codTypebox, Composicao }) => {
      let box = ''

      if (codTypebox?.length) {
        box = codBoxes.find((box: any) => box._id === codTypebox)?.cod ?? ''
      }

      void setFieldValue(
        'Referencia',
        `${box} ${Composicao?.nomeComercial ?? ''}`
      )
    },
    [codBoxes]
  )

  useEffect(() => {
    console.log(values?.codTypebox)
    handleReference({
      codTypebox: values?.codTypebox,
      Composicao: values?.Composicao
    })
  }, [values?.codTypebox, values?.Composicao, codBoxes?.length])

  const handleChangeIntegerNumber = (field: string) => (event: any) => {
    const newValue = onlyNumbers(event.target.value)
    setFieldValue(field, newValue ? Math.round(Number(newValue)) : '')
  }

  const handleChangeNumber = (field: string) => (event: any) => {
    const newValue = onlyNumbersOrPoint(event.target.value)
    setFieldValue(field, newValue ? Number(newValue) : '')
  }

  return (
    <>
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box marginTop={2} marginBottom={2}>
          <Typography fontSize={20} fontWeight="500" component="h2">
            Formulário Caixa
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="standard-basic"
              label="Solicitado Por"
              variant="standard"
              fullWidth
              autoComplete="off"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="standard-basic"
              label="Representante"
              variant="standard"
              fullWidth
              autoComplete="off"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-basic"
              label="Referência"
              variant="standard"
              value={values.Referencia}
              fullWidth
              autoComplete="off"
              disabled
            />
          </Grid>

          <Section title="Especificações">
            <Grid item xs={12} md={6}>
              <Autocomplete
                noOptionsText='Sem opções'
                disablePortal
                options={
                  composicao.map((item) => ({
                    label: item.composicao,
                    ...item
                  })) ?? []
                }
                fullWidth
                getOptionLabel={(option) => option.composicao}
                value={values?.Composicao}
                defaultValue={composicao?.find(
                  (item) => item.composicao === values?.Composicao?.composicao
                )}
                onChange={(e, composicaoItem: any) => {
                  console.log('composicao', composicaoItem)
                  setFieldValue('Composicao', composicaoItem)
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Composição"
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <InputLabel id="boxType" required>
                  Tipo de caixa
                </InputLabel>
                <Select
                  labelId="boxType"
                  label="Tipo de caixa"
                  value={values?.boxType}
                  onChange={(e) => {
                    console.log(
                      'Tipo de caixa',
                      boxes?.find((box) => box._id === e.target.value)
                    )
                    setFieldValue('boxType', e.target.value)
                  }}
                  MenuProps={MenuProps}
                >
                  {boxes.map((item: any) => {
                    return (
                      <MenuItem
                        key={item._id}
                        value={item._id}
                        disabled={false}
                      >
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <InputLabel id="codTypebox" required>
                  Cod. Caixa
                </InputLabel>
                <Select
                  labelId="codTypebox"
                  label="Cod. Caixa"
                  value={values?.codTypebox}
                  onChange={(e) => {
                    console.log(
                      'Cod. de caixa',
                      codBoxes?.find((box) => box._id === e.target.value)
                    )
                    setFieldValue('codTypebox', e.target.value)
                  }}
                  MenuProps={MenuProps}
                  required
                >
                  {codBoxes.map((item: any) => {
                    return (
                      <MenuItem key={item._id} value={item._id}>
                        {item.cod}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    label="Tipo de Onda"
                    variant="standard"
                    value={values?.Composicao?.onda ?? ''}
                    fullWidth
                    disabled
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Papel"
                    variant="standard"
                    value={`${
                      values?.Composicao?.papel1
                        ? values?.Composicao?.papel1 + ','
                        : ''
                    }${
                      values?.Composicao?.papel2
                        ? values?.Composicao?.papel2 + ','
                        : ''
                    }${
                      values?.Composicao?.papel3
                        ? values?.Composicao?.papel3 + ','
                        : ''
                    }${
                      values?.Composicao?.papel4
                        ? values?.Composicao?.papel4 + ','
                        : ''
                    }${
                      values?.Composicao?.papel5
                        ? values?.Composicao?.papel5 + ','
                        : ''
                    }`.slice(0, -1)}
                    fullWidth
                    disabled
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Nome Comercial"
                    variant="standard"
                    value={values?.Composicao?.nomeComercial ?? ''}
                    fullWidth
                    disabled
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Section>

          <Section title="Dimensões">
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                label="Largura Interna"
                variant="standard"
                value={values.LarguraInterna}
                error={Boolean(errors.LarguraInterna && touched.LarguraInterna)}
                helperText={
                  (errors.LarguraInterna && touched.LarguraInterna
                    ? errors.LarguraInterna
                    : null) as React.ReactNode
                }
                onChange={handleChangeNumber('LarguraInterna')}
                autoComplete="off"
                fullWidth
                required
              />
            </Grid>
            <Grid item alignSelf="flex-end">
              +
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                label="Comprimento Interno"
                variant="standard"
                value={values.ComprimentoInterno}
                error={Boolean(
                  errors.ComprimentoInterno && touched.ComprimentoInterno
                )}
                helperText={
                  (errors.ComprimentoInterno && touched.ComprimentoInterno
                    ? errors.ComprimentoInterno
                    : null) as React.ReactNode
                }
                onChange={handleChangeNumber('ComprimentoInterno')}
                autoComplete="off"
                fullWidth
                required
              />
            </Grid>
            <Grid item alignSelf="flex-end">
              +
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                label="Altura Interna"
                variant="standard"
                value={values.AlturaInterna}
                error={Boolean(errors.AlturaInterna && touched.AlturaInterna)}
                helperText={
                  (errors.AlturaInterna && touched.AlturaInterna
                    ? errors.AlturaInterna
                    : null) as React.ReactNode
                }
                onChange={handleChangeNumber('AlturaInterna')}
                autoComplete="off"
                fullWidth
                required
              />
            </Grid>
            <Grid
              item
              md={1}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end'
                }}
              >
                =
              </Box>
            </Grid>
            <Grid item xs={6} md={1}>
              <TextField
                type="number"
                label="Total (GRM)"
                variant="standard"
                value={
                  Number(values?.LarguraInterna ?? 0) +
                  Number(values?.ComprimentoInterno ?? 0) +
                  Number(values?.AlturaInterna ?? 0)
                }
                autoComplete="off"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <InputLabel id="select-paper-fechamento" required>
                  Fechamento
                </InputLabel>
                <Select
                  labelId="select-paper-fechamento"
                  label="Papel"
                  value={values?.Fechamento?.fechamento}
                  onChange={(e) => {
                    setFieldValue(
                      'Fechamento',
                      fechamentos?.find(
                        (item) => item.fechamento === e.target.value
                      )
                    )
                  }}
                  MenuProps={MenuProps}
                  required
                >
                  {fechamentos.map((item: any) => {
                    return (
                      <MenuItem key={item.fechamento} value={item.fechamento}>
                        {item?.fechamento}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Section>

          <Section title="Informações Adicionais">
            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="Paletizado">Paletizado</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Paletizado"
                  name="Paletizado"
                  value={values.Paletizado}
                  onChange={(_, value) => {
                    setFieldValue('Paletizado', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="Amarrado">Amarrado</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Amarrado"
                  name="Amarrado"
                  value={values.Amarrado}
                  onChange={(_, value) => {
                    void setFieldValue('Amarrado', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="ExigeLaudo">Laudo</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="ExigeLaudo"
                  name="ExigeLaudo"
                  value={values.ExigeLaudo}
                  onChange={(_, value) => {
                    void setFieldValue('ExigeLaudo', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                label="Observação"
                variant="outlined"
                value={values.Obs1}
                error={Boolean(errors?.Obs1 && touched?.Obs1)}
                helperText={(errors?.Obs1 && touched?.Obs1 ? errors?.Obs1 : null) as React.ReactNode}
                onChange={handleChange('Obs1')}
                autoComplete="off"
                fullWidth
                multiline
                minRows={3}
              />
            </Grid>
          </Section>

          <Section title="Informações Cliente">
            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="PaletePadrao">Palete Padrão</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="PaletePadrao"
                  name="PaletePadrao"
                  value={values.PaletePadrao}
                  onChange={(_, value) => {
                    setFieldValue('PaletePadrao', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              {values.PaletePadrao && (
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    <TextField
                      type="number"
                      label="Largura"
                      variant="standard"
                      value={values.PaletePadraoLargura}
                      error={Boolean(
                        errors?.PaletePadraoLargura &&
                          touched?.PaletePadraoLargura
                      )}
                      helperText={
                        (errors?.PaletePadraoLargura &&
                        touched?.PaletePadraoLargura
                          ? errors?.PaletePadraoLargura
                          : null) as React.ReactNode
                      }
                      onChange={handleChangeNumber('PaletePadraoLargura')}
                      autoComplete="off"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      type="number"
                      label="Comprimento"
                      variant="standard"
                      value={values.PaletePadraoComprimento}
                      error={Boolean(
                        errors?.PaletePadraoComprimento &&
                          touched?.PaletePadraoComprimento
                      )}
                      helperText={
                        (errors?.PaletePadraoComprimento &&
                        touched?.PaletePadraoComprimento
                          ? errors?.PaletePadraoComprimento
                          : null) as React.ReactNode
                      }
                      onChange={handleChangeNumber('PaletePadraoComprimento')}
                      autoComplete="off"
                      fullWidth
                      required
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="PaleteReforcado">Palete Reforçado</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="PaleteReforcado"
                  name="PaleteReforcado"
                  value={values.PaleteReforcado}
                  onChange={(_, value) => {
                    setFieldValue('PaleteReforcado', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              {values.PaleteReforcado && (
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    <TextField
                      type="number"
                      label="Quantidade de fita"
                      variant="standard"
                      value={values.PaleteReforcadoQuantidadeFita}
                      error={Boolean(
                        errors?.PaleteReforcadoQuantidadeFita &&
                          touched?.PaleteReforcadoQuantidadeFita
                      )}
                      helperText={
                        (errors?.PaleteReforcadoQuantidadeFita &&
                        touched?.PaleteReforcadoQuantidadeFita
                          ? errors?.PaleteReforcadoQuantidadeFita
                          : null) as React.ReactNode
                      }
                      onChange={handleChangeIntegerNumber(
                        'PaleteReforcadoQuantidadeFita'
                      )}
                      autoComplete="off"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      type="number"
                      label="Quantidade lastro do palete"
                      variant="standard"
                      value={values.PaleteReforcadoQuantidadeLastro}
                      error={Boolean(
                        errors?.PaleteReforcadoQuantidadeLastro &&
                          touched?.PaleteReforcadoQuantidadeLastro
                      )}
                      helperText={
                        (errors?.PaleteReforcadoQuantidadeLastro &&
                        touched?.PaleteReforcadoQuantidadeLastro
                          ? errors?.PaleteReforcadoQuantidadeLastro
                          : null) as React.ReactNode
                      }
                      onChange={handleChangeIntegerNumber(
                        'PaleteReforcadoQuantidadeLastro'
                      )}
                      autoComplete="off"
                      fullWidth
                      required
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="AmarradoPalete">Amarrado no Palete</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="AmarradoPalete"
                  name="AmarradoPalete"
                  value={values.AmarradoPalete}
                  onChange={(_, value) => {
                    setFieldValue('AmarradoPalete', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              {values.AmarradoPalete && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      label="Qtd Amarrado/palete"
                      variant="standard"
                      value={values.AmarradoPaleteQtd}
                      error={Boolean(
                        errors?.AmarradoPaleteQtd && touched?.AmarradoPaleteQtd
                      )}
                      helperText={
                        (errors?.AmarradoPaleteQtd && touched?.AmarradoPaleteQtd
                          ? errors?.AmarradoPaleteQtd
                          : null) as React.ReactNode
                      }
                      onChange={handleChangeIntegerNumber('AmarradoPaleteQtd')}
                      autoComplete="off"
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="Alcas">Alças</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Alcas"
                  name="Alcas"
                  value={values.Alcas}
                  onChange={(_, value) => {
                    setFieldValue('Alcas', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              {values.Alcas && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Alças por Caixas"
                      variant="standard"
                      value={values.AlcasValue}
                      error={Boolean(
                        errors?.AlcasValue &&
                          touched?.AlcasValue
                      )}
                      helperText={
                        (errors?.AlcasValue &&
                        touched?.AlcasValue
                          ? errors?.AlcasValue
                          : null) as React.ReactNode
                      }
                      onChange={handleChange(
                        'AlcasValue'
                      )}
                      autoComplete="off"
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="Furo">Furo</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Furo"
                  name="Furo"
                  value={values.Furo}
                  onChange={(_, value) => {
                    setFieldValue('Furo', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              {values.Furo && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Furos por Caixas"
                      variant="standard"
                      value={values.FuroValue}
                      error={Boolean(
                        errors?.FuroValue &&
                          touched?.FuroValue
                      )}
                      helperText={
                        (errors?.FuroValue &&
                        touched?.FuroValue
                          ? errors?.FuroValue
                          : null) as React.ReactNode
                      }
                      onChange={handleChange(
                        'FuroValue'
                      )}
                      autoComplete="off"
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="Amostra">Amostra</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Amostra"
                  name="Amostra"
                  value={values.Amostra}
                  onChange={(_, value) => {
                    setFieldValue('Amostra', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              {values.Amostra && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      label="Quantidade de Amostras"
                      variant="standard"
                      value={values.AmostraQtd}
                      error={Boolean(errors?.AmostraQtd && touched?.AmostraQtd)}
                      helperText={
                        (errors?.AmostraQtd && touched?.AmostraQtd
                          ? errors?.AmostraQtd
                          : null) as React.ReactNode
                      }
                      onChange={handleChangeIntegerNumber('AmostraQtd')}
                      autoComplete="off"
                      fullWidth
                      required
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="Impressoes">Impressões</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Impressoes"
                  name="Impressoes"
                  value={values.Impressoes}
                  onChange={(_, value) => {
                    setFieldValue('Impressoes', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              {values.Impressoes && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      label="Quantidade de Impressões"
                      variant="standard"
                      value={values.ImpressoesQtd}
                      error={Boolean(
                        errors?.ImpressoesQtd && touched?.ImpressoesQtd
                      )}
                      helperText={
                        (errors?.ImpressoesQtd && touched?.ImpressoesQtd
                          ? errors?.ImpressoesQtd
                          : null) as React.ReactNode
                      }
                      onChange={handleChangeIntegerNumber('ImpressoesQtd')}
                      autoComplete="off"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography variant="body2">Cor</Typography>

                      <Button
                        type="button"
                        mold="primary"
                        onClick={handleAddCor}
                        style={{ padding: '4px' }}
                      >
                        <AddIcon fontSize="small" />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                      {cores?.map((cor, i) => (
                        <Box sx={{ width: 100, display: 'flex' }}>
                          <TextField
                            type="text"
                            variant="standard"
                            value={values.ImpressoesCor?.[cor?.name]}
                            onChange={handleChange(
                              `ImpressoesCor.${cor?.name}`
                            )}
                            autoComplete="off"
                            fullWidth
                            size="small"
                            required
                          />
                          <Button
                            type="button"
                            mold="red"
                            onClick={async () => handleRemoveCor(cor?.name)}
                            style={{
                              padding: '4px',
                              color: '#ee207a',
                              backgroundColor: 'transparent'
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="Tara">Tara</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Tara"
                  name="Tara"
                  value={values.Tara}
                  onChange={(_, value) => {
                    setFieldValue('Tara', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl>
                <FormLabel id="Batido">Batido</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Batido"
                  name="Batido"
                  value={values.Batido}
                  onChange={(_, value) => {
                    setFieldValue('Batido', value === 'true')
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Section>

          <Section title="Planejamento do P&D">
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="Definicao">Definição</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Definicao"
                  name="Definicao"
                  value={values.Definicao}
                  onChange={(_, value) => {
                    setFieldValue('Definicao', value)
                  }}
                >
                  <FormControlLabel
                    value="ItemParaDesenvolver"
                    control={<Radio />}
                    label="Item para desenvolver"
                  />
                  <FormControlLabel
                    value="ItemParaProjetarEDesenvolver"
                    control={<Radio />}
                    label="Item para Projetar e Desenvolver"
                  />
                  <FormControlLabel
                    value="ItemComProjetosSimilares"
                    control={<Radio />}
                    label="Item com projetos similares"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="NovoProduto">Novo Produto</FormLabel>
              </FormControl>
            </Grid>
            <Grid item xs={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.NovoProduto.BasedaNaFTD}
                    onChange={async (_, value) =>
                      setFieldValue('NovoProduto.BasedaNaFTD', value)
                    }
                  />
                }
                label="Baseada na FTD"
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.NovoProduto.DesnehoEspecificacao}
                    onChange={async (_, value) =>
                      setFieldValue('NovoProduto.DesnehoEspecificacao', value)
                    }
                  />
                }
                label="Desenho/Especificação"
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.NovoProduto.AmostraMecanica}
                    onChange={async (_, value) =>
                      setFieldValue('NovoProduto.AmostraMecanica', value)
                    }
                  />
                }
                label="Amostra Mecânica"
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.NovoProduto.ProdutoDoCliente}
                    onChange={async (_, value) =>
                      setFieldValue('NovoProduto.ProdutoDoCliente', value)
                    }
                  />
                }
                label="Produto do Cliente"
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.NovoProduto.ProdutoSemelhante}
                    onChange={async (_, value) =>
                      setFieldValue('NovoProduto.ProdutoSemelhante', value)
                    }
                  />
                }
                label="Produto semelhante"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.NovoProduto.Outros}
                        onChange={async (_, value) =>
                          setFieldValue('NovoProduto.Outros', value)
                        }
                      />
                    }
                    label="Outros"
                  />
                </Grid>
                {values?.NovoProduto?.Outros === true && (
                  <Grid item xs={11}>
                    <TextField
                      type="text"
                      placeholder="Observação"
                      variant="outlined"
                      value={values.NovoProduto?.OutrosObs}
                      error={Boolean(
                        errors?.NovoProduto?.OutrosObs &&
                          touched?.NovoProduto?.OutrosObs
                      )}
                      helperText={
                        (errors?.NovoProduto?.OutrosObs &&
                        touched?.NovoProduto?.OutrosObs
                          ? errors?.NovoProduto?.OutrosObs
                          : null) as React.ReactNode
                      }
                      onChange={handleChange('NovoProduto.OutrosObs')}
                      autoComplete="off"
                      fullWidth
                      multiline
                      disabled={false}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Section>
        </Grid>

        <Grid
          item
          md={8}
          className="text-right"
          style={{
            alignSelf: 'flex-end',
            justifySelf: 'self-end',
            marginTop: '2%'
          }}
        >
          <Button
            mold="red"
            type="button"
            onClick={() =>
              editable
                ? navigate('/home/portfolio/2')
                : navigate('/home/portfolio/products')
            }
            style={{ marginRight: '10px' }}
            disabled={isLoadingCreate || isLoadingEdit}
          >
            Cancelar
          </Button>
          <Button
            mold="primary"
            type="submit"
            loading={isLoadingCreate || isLoadingEdit}
            disabled={isLoadingCreate || isLoadingEdit}
          >
            Salvar
          </Button>
        </Grid>
      </Box>
    </>
  )
}

export default FormCaixas
