import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import FormWaveType from './FormWaveType'
import CustomButton from 'components/buttons/CustomButton'
import { getPortfolioPlates } from 'services/portfolio/PortfolioService'
import { StyledTableCell, StyledTableRow } from '../components/table.styles'
import MoreMenu from 'components/view/MoreMenu'
import { PortfolioPlateType } from 'types/portfolio/PortfolioPlateType'
import { httpClient } from 'config/httpClient'

import './style.css'
import { useQuery } from 'react-query'
import FormEditWaveType from './FormEditWaveType'

interface modalView {
  open: boolean
  close: () => void
}

const ModalWaveType = (props: modalView) => {
  const { open, close } = props

  const [originalRows, setOriginalRows] = useState<PortfolioPlateType[]>([])
  const [rows, setRows] = useState<PortfolioPlateType[]>([])

  const [page, setPage] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const [openModalEdit, setOpenModalEdit] = useState<any | null>(null)

  const { data, status, refetch, isFetching } = useQuery(
    'plates-modal',
    getPortfolioPlates,
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    setPage(0)
    setOriginalRows(data ?? [])
    setRows(data ?? [])
  }, [data?.length, isFetching])

  const closeModal = () => close()

  const changeSearch = (search: string) => {
    if (search.length === 0) {
      setPage(0)
      setRows(originalRows)
    } else {
      const searchResult = originalRows.filter(
        (row) =>
          row.name.toUpperCase().includes(search.toUpperCase()) ||
          row.nomenclature.toUpperCase().includes(search.toUpperCase()) ||
          row.description.toUpperCase().includes(search.toUpperCase())
      )
      setRows(searchResult)
    }
  }

  const handleRefetch = () => {
    setPage(0)
    refetch()
  }

  const handleStatus = (id: string) => {
    httpClient
      .patch(`products/plates/status/${id}`)
      .then(() => {
        handleRefetch()
      })
      .catch((error) => console.log(error))
  }

  const handleEdit = (row: any) => {
    setOpenModalEdit(row)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tipo de onda
          <Grid
            item
            xs={12}
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '15px'
            }}
          >
            <Button
              variant="outlined"
              style={{ height: '30px' }}
              onClick={() => setOpenModal(true)}
            >
              + Nova onda
            </Button>

            <FormControl variant="standard">
              <InputLabel htmlFor="field-search">Pesquisar</InputLabel>
              <Input
                id="field-search"
                defaultValue=""
                type="search"
                onChange={(event) => changeSearch(event.target.value.trim())}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchOutlined />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {status !== 'loading' && (
            <TableContainer style={{ marginTop: '30px' }}>
              <Table sx={{ minWidth: '100%' }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Tipo de papel</StyledTableCell>
                    <StyledTableCell align="left">Nome da onda</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="right">Ações</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.slice(page * 5, page * 5 + 5)?.map((row) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.nomenclature}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <FormGroup>
                          <FormControlLabel
                            control={<Switch checked={row.isActive} />}
                            label={row.isActive ? 'Ativo' : 'Inativo'}
                            onChange={() => handleStatus(row._id)}
                          />
                        </FormGroup>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <MoreMenu
                          edit={() => handleEdit(row)}
                          // delete={() => handleStatus(row._id)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5]}
                rowsPerPage={5}
                component="div"
                count={rows.length}
                page={page}
                onPageChange={(event: unknown, newPage: number) => {
                  setPage(newPage)
                }}
              />
            </TableContainer>
          )}

          {status === 'loading' && (
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={200}
                        width="100%"
                      >
                        <CircularProgress />
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <CustomButton
            mold="primary"
            type="button"
            onClick={closeModal}
            autoFocus
          >
            Voltar
          </CustomButton>
        </DialogActions>
      </Dialog>

      <FormWaveType
        open={openModal}
        close={() => setOpenModal(false)}
        refetch={handleRefetch}
      />

      {openModalEdit?._id && (
        <FormEditWaveType
          open={!!openModalEdit?._id}
          data={openModalEdit}
          close={() => setOpenModalEdit(null)}
          refetch={handleRefetch}
        />
      )}
    </>
  )
}

export default ModalWaveType
