export const MODULES = {
  DASHBOARD: 'DASHBOARD',
  PERFIL: 'PERFIL',
  USUÁRIOS: 'USUÁRIOS',
  CLIENTES: 'CLIENTES',
  EMPRESA: 'EMPRESA',
  GERENCIAL: 'GERENCIAL',
  FT: 'FT',
  PEDIDOS: 'PEDIDOS',
  RELATÓRIOS: 'RELATÓRIOS',
  ALERTAS: 'ALERTAS'
}
