import React from 'react'

import { ButtonHeader } from './styles/style-header-view'

// ICONS
import AddIcon from '@mui/icons-material/Add'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

// components
import LightTooltip from '../tooltips/LightTooltip'

interface Props {
  register?: () => void
  configuration?: () => void
}

const HeaderView: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr repeat(2, minmax(auto, max-content))',
        gap: '15px'
      }}
    >
      <LightTooltip title="Cadastrar" placement="top" onClick={props.register}>
        <ButtonHeader style={{ justifySelf: 'start' }}>
          <AddIcon />
        </ButtonHeader>
      </LightTooltip>
      {props?.configuration && (
        <LightTooltip title="Configurações" placement="top">
          <ButtonHeader onClick={props.configuration}>
            <SettingsOutlinedIcon />
          </ButtonHeader>
        </LightTooltip>
      )}
    </div>
  )
}

export default HeaderView
