import * as React from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import TableUsers from './components/TableUsers'
import { useNavigate } from 'react-router-dom'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import HeaderView from 'components/view/HeaderView'

export default function ProfileControl() {
  const [filters, setFilters] = React.useState<any>({})
  const navigate = useNavigate()

  return (
    <Can module={MODULES.PERFIL} permissions={[PERMISSIONS.READ]}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1
          }}
        >
          {/* <div>
            <Link
              style={{ textDecoration: 'none', color: 'black' }}
              to="/home/profile-form"
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <AddCircleOutlined />
              </IconButton>
            </Link>
          </div> */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
            <div>
              <HeaderView register={() => navigate('/home/profile-form')} />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">
              Pesquisar por perfil
            </InputLabel>
            <Input
              id="input-with-icon-adornment"
              autoComplete="off"
              type="search"
              onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              endAdornment={
                <InputAdornment position="end">
                  <SearchOutlined />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

        <TableUsers filter={filters} />
      </Box>
    </Can>
  )
}
