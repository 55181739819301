import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Paper, Tab, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { Can } from 'components/Permission/Can'
import MoreMenu from 'components/view/MoreMenu'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { StyledTableCell, StyledTableRow } from 'pages/company/portfolio/components/table.styles'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { deleteMaterial, getMaterial } from 'services/material/MaterialService'

function MaterialTable(props: any) {
  const [rows, setRows] = React.useState<any[]>([])
  const [value, setValue] = React.useState('1')

  useEffect(() => {
    materialReq.refetch()
  }, [props.refetch])

  const materialReq = useQuery(['material', null],
    async () => {
      return getMaterial()
    },
    {
      onSuccess: (data) => {
        console.log('data material', data)
        setRows(data)
      },
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  const { mutate: mutateDel } = useMutation(deleteMaterial, {
    onSuccess: data => {
      toast.success('Deletado com sucesso')
      materialReq.refetch()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível deletar')
      }
    }
  })

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Produto" value="1" />
            <Tab label="Acabamentos" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <TableContainer style={{ marginTop: '30px', maxHeight: 440 }} component={Paper}>
            <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Material</StyledTableCell>
                  <StyledTableCell align="center">Nomenclatura</StyledTableCell>
                  <StyledTableCell align="center">R$/Kg</StyledTableCell>
                  <Can
                    module={MODULES.GERENCIAL}
                    permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                  >
                    <StyledTableCell align="right">Ações</StyledTableCell>
                  </Can>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.filter(r => (r.category === 0)).map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">{row.material}</StyledTableCell>
                    <StyledTableCell align="center">{row.nomenclature}</StyledTableCell>
                    <StyledTableCell align="center">R$ {row.value}</StyledTableCell>
                    <Can
                      module={MODULES.GERENCIAL}
                      permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                    >
                      <StyledTableCell align="right">
                      <MoreMenu
                        module={MODULES.GERENCIAL}
                        isValidationPermissions
                        edit={async () => { props.edit(row) }}
                        delete={async () => { mutateDel(row._id) }}
                      />
                    </StyledTableCell>
                    </Can>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        <TabPanel value="2">
          <TableContainer style={{ marginTop: '30px' }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Material</StyledTableCell>
                  <StyledTableCell align="center">Nomenclatura</StyledTableCell>
                  <StyledTableCell align="center">R$/Kg</StyledTableCell>
                  <Can
                      module={MODULES.GERENCIAL}
                      permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                    >
                    <StyledTableCell align="right">Ações</StyledTableCell>
                  </Can>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.filter(r => (r.category === 1)).map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">{row.material}</StyledTableCell>
                    <StyledTableCell align="center">{row.nomenclature}</StyledTableCell>
                    <StyledTableCell align="center">R$ {Number(row.value ?? 0).toLocaleString('pt-br')}</StyledTableCell>
                    <Can
                      module={MODULES.GERENCIAL}
                      permissions={[PERMISSIONS.DELETE, PERMISSIONS.UPDATE]}
                    >
                      <StyledTableCell align="right">
                      <MoreMenu
                        module={MODULES.GERENCIAL}
                        isValidationPermissions
                        edit={async () => { props.edit(row) }}
                      />
                    </StyledTableCell>
                    </Can>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </TabContext>
    </div>
  )
}

export default MaterialTable
