import React from 'react'
import { Formik, Form } from 'formik'
import Button from 'components/form/Button'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import {
  Grid,
  TextField,
  FormControlLabel,
  Box,
  Typography,
  Stack,
  Checkbox
} from '@mui/material'
import { toast } from 'react-toastify'
import moment from 'moment'

interface Props {
  onClose: () => void
  ref?: any
}

export const Audit: React.FC<Props> = ({ onClose }) => {
  const state: any = {}
  return (
    <Stack spacing={3} paddingY={3} paddingX={6}>
      <Box
        borderLeft="20px solid #4060E6"
        boxShadow="0 3px 6px #00000029"
        minHeight={74}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
      >
        <Typography
          fontSize="20px"
          fontWeight="500"
          component="strong"
          sx={{ paddingY: 1, paddingX: 2 }}
        >
          Resumo
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          sx={{ paddingY: 1, paddingX: 2 }}
        >
          <PermIdentityIcon />
          <Typography component="span" variant="body1" marginLeft={1}>
            Emitido por: John Doe
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ paddingY: 1, paddingX: 2 }}
        >
          <CalendarTodayOutlinedIcon />
          <Typography component="span" variant="body1" marginLeft={1}>
            {moment(state?.client?.createdAt).format('DD/MM/YYYY')}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ paddingY: 1, paddingX: 2 }}
        >
          <AccessTimeOutlinedIcon />
          <Typography component="span" variant="body1" marginLeft={1}>
            {moment(state?.client?.createdAt).format('HH:mm')}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          sx={{ paddingY: 1, paddingX: 2 }}
        >
          <CalendarTodayOutlinedIcon />
          <Typography component="span" variant="body1" marginLeft={1}>
            Para: {state?.client?.name}
          </Typography>
        </Box>
      </Box>

      <Typography fontWeight="500" component="strong">
        Dados da FT
      </Typography>
      <Formik
        initialValues={{
          _id: state?.client?._id ?? '',
          file: state?.client?.url ?? '',
          cnpj: state?.client?.cnpj ?? '',
          name: state?.client?.name ?? '',
          fantasy_name: state?.client?.fantasy_name ?? '',
          suframa_registration: state?.client?.suframa_registration ?? '',
          ir: state?.client?.ir ?? '',
          contractual_changes: state?.client?.contractual_changes ?? '',
          email_nfe: state?.client?.nfe ?? '',
          email_contact: state?.client?.email_contact ?? '',
          email_financial: state?.client?.email_financial ?? '',
          address: {
            zipcode: state?.client?.address?.zipcode ?? '',
            public_place: state?.client?.address?.publicPlace ?? '',
            number: state?.client?.address?.number ?? undefined,
            district: state?.client?.address?.district ?? '',
            state: state?.client?.address?.state ?? '',
            city: state?.client?.address?.city ?? ''
          }
        }}
        // validationSchema={validationRepresentativeSchema}
        onSubmit={async (values) => {
          console.log(values)
          // const body = values?.representatives

          try {
            // setLoading(true)
            // await updateClient(String(values._id), body)

            toast.success('Aprovado com sucesso!')

            onClose()
          } catch (error) {
            console.log('Error: ', error)
            toast.error('Erro ao aprovar')
            // eslint-disable-next-line no-unreachable
          } finally {
            // setLoading(false)
          }
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(errors.name && touched.name)}
                    value={values.name}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="FT"
                    helperText={
                      (errors.name && touched.name ? errors.name : null) as React.ReactNode
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      errors.address?.public_place &&
                        touched.address?.public_place
                    )}
                    value={values.address?.public_place}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Cliente"
                    helperText={
                      (errors.address?.public_place &&
                      touched.address?.public_place
                        ? errors.address?.public_place
                        : null) as React.ReactNode
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    error={Boolean(
                      errors.address?.number && touched.address?.number
                    )}
                    value={values.address?.number}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Referência"
                    helperText={
                      (errors.address?.number && touched.address?.number
                        ? errors.address?.number
                        : null) as React.ReactNode
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(
                      errors.address?.district && touched.address?.district
                    )}
                    value={values.address?.district}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Ordem de produção"
                    helperText={
                      (errors.address?.district && touched.address?.district
                        ? errors.address?.district
                        : null) as React.ReactNode
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      errors.address?.city && touched.address?.city
                    )}
                    value={values.address?.city}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Ordem de venda"
                    helperText={
                      (errors.address?.city && touched.address?.city
                        ? errors.address?.city
                        : null) as React.ReactNode
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography fontWeight="500" component="strong">
                    Previsão de entrega
                  </Typography>
                </Grid>
                <Grid item xs={4} md={3} style={{ marginTop: '25px' }}>
                  <FormControlLabel
                    control={<Checkbox checked={values._id} disabled />}
                    label="Lucro real"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={4} md={3} style={{ marginTop: '25px' }}>
                  <FormControlLabel
                    control={<Checkbox checked={values._id} disabled />}
                    label="Lucro presumido"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={4} md={3} style={{ marginTop: '25px' }}>
                  <FormControlLabel
                    control={<Checkbox checked={values._id} disabled />}
                    label="Lucro simples"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={4} md={3} style={{ marginTop: '25px' }}>
                  <FormControlLabel
                    control={<Checkbox checked={values._id} disabled />}
                    label="Lucro arbitrado e Sem Fins Lucrativos"
                    disabled={false}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                      <Typography fontWeight="500" component="strong">
                        Geolocalização
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      ..
                    </Grid> */}
              </Grid>
            </Box>

            <Stack
              direction="row"
              spacing={3}
              paddingTop={4}
              justifyContent="flex-end"
            >
              <Button
                type="button"
                mold="red"
                onClick={() => onClose()}
              >
                Cancelar
              </Button>
              <Button
                mold="red"
                // onClick={handleOpenAuditReject}
                type="button"
              >
                Reprovar
              </Button>
              <Button mold="green" type="submit">
                Aprovar
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  )
}
