/* eslint-disable @typescript-eslint/no-unused-vars */
import { Clear, PermIdentity, SearchOutlined } from '@mui/icons-material'
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import Button from 'components/form/Button'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { Status } from 'components/Status'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getClientsSalesReport } from 'services/report/SalesReport'
import { getStockLN } from 'services/report/Stock'
import { LoadingTable } from './LoadingTable'
import { MovementExitTable } from './MovementExitTable'
import { MovementInputTable } from './MovementInputTable'
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableRow
} from './table.styles'
import { TableActionButtons } from './TableActionButtons'

function a11yProps(index: number) {
  return {
    id: `stock-tab-${index}`,
    'aria-controls': `stock-tabpanel-${index}`
  }
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`stock-tabpanel-${index}`}
      aria-labelledby={`stock-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}
interface Props {
  selectDate: Date | null
}

export const MovementTable: React.FC<Props> = ({ selectDate }) => {
  const [value, setValue] = React.useState(0)

  console.log('RELATORIO DE Movimentation', moment(selectDate).format('M'))

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ marginTop: 3, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="ENTRADA"
            {...a11yProps(0)}
            sx={{
              '&.Mui-selected': { backgroundColor: '#1976d2', color: '#FFF' }
            }}
          />
          <Tab
            label="SAÍDA"
            {...a11yProps(1)}
            sx={{
              '&.Mui-selected': { backgroundColor: '#1976d2', color: '#FFF' }
            }}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <MovementInputTable
          selectDate={moment(selectDate).format('YYYY-MM-DD')}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MovementExitTable
          selectDate={moment(selectDate).format('YYYY-MM-DD')}
        />
      </TabPanel>
    </>
  )
}
