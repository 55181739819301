/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  usePDF,
  Image
} from '@react-pdf/renderer'
import { Stack } from '@mui/material'
import Button from 'components/form/Button'
import moment from 'moment'
import logo from 'assets/img/logo.png'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
})

const MyDoc = (props: any) => {
  return props.myDoc
}

interface Props {
  onClose: () => void
  proposal: any
  ft: any
  budgetData?: any
}

export function PropostaPdf({ proposal, ft, budgetData, ...props }: Props) {
  console.log('budgetData', budgetData)
  const MyDoc2 = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }}
          >
            <View style={{ marginBottom: 16 }}>
              <Image src={logo} style={{
                width: 100,
                height: 100,
                objectFit: 'contain',
                objectPosition: 'center'
              }} />
            </View>
            <Text
              style={{
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: 900,
                textDecoration: 'underline',
                marginBottom: 24
              }}
            >
              Proposta de Venda
            </Text>
            <Text
              style={{ fontSize: '12px', textAlign: 'left', marginBottom: 24 }}
            >
              Manaus, {moment().format('DD [de] MMMM [de] YYYY')}.
            </Text>
            <Text
              style={{
                fontSize: '12px',
                textAlign: 'left',
                marginBottom: 24
              }}
            >
              Para: {proposal?.client}.
            </Text>
            <Text
              style={{
                fontSize: '12px',
                textAlign: 'left',
                marginBottom: 48
              }}
            >
              Agradecemos antecipadamente a consulta, informamos o preço e as
              demais condições para fornecimento do (s) item (ns):
            </Text>

            <View
              style={{
                width: '100%'
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    width: 80
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>FT</Text>
                </View>
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    width: 140
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>Referência</Text>
                </View>
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    textAlign: 'center',
                    width: 80
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>Preço</Text>
                </View>
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    textAlign: 'center',
                    width: 100
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>Gramatura</Text>
                </View>
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    textAlign: 'center',
                    width: 100
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>Medida Interna</Text>
                </View>
                <View
                  break
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    textAlign: 'center',
                    width: 60
                  }}
                >
                  <Text break style={{ fontSize: '11px' }}>
                    Lote Mínimo
                  </Text>
                </View>
              </View>
              {/* Tbody */}
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: 48
                }}
              >
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    width: 80
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>{ft?.itemReference}</Text>
                </View>
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    width: 140
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>
                    {proposal?.reference}
                  </Text>
                </View>
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    textAlign: 'center',
                    width: 80
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>
                    {proposal?.price?.toFixed(2)?.replace('.', ',') ?? '0,00'}
                  </Text>
                </View>
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    textAlign: 'center',
                    width: 100
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>
                    {proposal?.showGrammage ? proposal?.gramature : ''}
                  </Text>
                </View>
                <View
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    textAlign: 'center',
                    width: 100
                  }}
                >
                  <Text style={{ fontSize: '11px' }}>
                    {ft?.preFT?.preftbox?.internalMeasures?.diameter || ft?.preFT?.preftbox?.internalMeasures?.diameter === 0 ? `${ft?.preFT?.preftbox?.internalMeasures?.diameter} X ` : ''}
                    {ft?.preFT?.preftbox?.internalMeasures?.width || ft?.preFT?.preftbox?.internalMeasures?.width === 0 ? `${ft?.preFT?.preftbox?.internalMeasures?.width} X ` : ''}
                    {ft?.preFT?.preftbox?.internalMeasures?.height}

                    {(ft?.preFT?.preftplate?.ComprimentoInterno || ft?.preFT?.preftplate?.ComprimentoInterno === 0) || (ft?.preFT?.formulariochapa?.ComprimentoInterno || ft?.preFT?.formulariochapa?.ComprimentoInterno === 0) ? `${ft?.preFT?.preftplate?.ComprimentoInterno || ft?.preFT?.formulariochapa?.ComprimentoInterno} X ` : ''}
                    {(ft?.preFT?.preftplate?.LarguraInterna || ft?.preFT?.preftplate?.LarguraInterna === 0) || (ft?.preFT?.formulariochapa?.LarguraInterna || 0) ? `${ft?.preFT?.preftplate?.LarguraInterna || ft?.preFT?.formulariochapa?.LarguraInterna} X ` : ''}
                    {ft?.preFT?.preftplate?.AlturaInterna ?? ft?.preFT?.formulariochapa?.AlturaInterna}
                  </Text>
                </View>
                <View
                  break
                  style={{
                    border: '1px solid #000',
                    padding: 8,
                    textAlign: 'center',
                    width: 60
                  }}
                >
                  <Text break style={{ fontSize: '11px' }}>
                    {proposal?.minimumLot}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  fontWeight: 500,
                  marginBottom: 0
                }}
              >
                Frete:
              </Text>
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  marginLeft: 8,
                  marginBottom: 0
                }}
              >
                {proposal?.typeOfFreight}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  fontWeight: 500,
                  marginBottom: 0
                }}
              >
                Cidade:
              </Text>
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  marginLeft: 8,
                  marginBottom: 0
                }}
              >
                {budgetData?.cidadeDestino}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  fontWeight: 500,
                  marginBottom: 0
                }}
              >
                Condição de pagamento:
              </Text>
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  marginLeft: 8,
                  marginBottom: 0
                }}
              >
                {proposal?.paymentCondition}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  fontWeight: 500,
                  marginBottom: 0
                }}
              >
                Prazo de entrega:
              </Text>
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  marginLeft: 8,
                  marginBottom: 0
                }}
              >
                {`${proposal?.deadlineDeliverDays} dias úteis`}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  fontWeight: 500,
                  marginBottom: 0
                }}
              >
                As ordens de fabricação poderão ser consideradas completas com
                10% a mais ou a menos da quantidade solicitada.
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  fontWeight: 500,
                  marginBottom: 0
                }}
              >
                ICMS:
              </Text>
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  marginLeft: 8,
                  marginBottom: 0
                }}
              >
                {proposal?.icms ?? 'INDEFERIDO'}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  fontWeight: 500,
                  marginBottom: 0
                }}
              >
                Validade da proposta:
              </Text>
              <Text
                style={{
                  fontSize: '11px',
                  textAlign: 'left',
                  marginLeft: 8,
                  marginBottom: 0
                }}
              >
                {`${proposal?.validity} dias`}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )

  const [instance, updateInstance] = usePDF({ document: MyDoc2 })

  return (
    <>
      <PDFViewer
        style={{
          width: '100%',
          minHeight: 400
        }}
      >
        <MyDoc myDoc={MyDoc2} />
      </PDFViewer>

      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        sx={{ marginTop: 3 }}
      >
        <Button onClick={props.onClose}>Voltar</Button>

        <a href={instance.url ?? ''} download={`${proposal?._id}-proposta.pdf`}>
          <Button mold="primary" onClick={props.onClose}>
            Baixar PDF
          </Button>
        </a>
      </Stack>
    </>
  )
}
