/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import { Box, FormHelperText, TextField, Typography } from '@mui/material'
import alertSuccess from 'components/Alerts/ModalSucess'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import ImportantView from 'components/view/ImportantView'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import { StyledDropZone } from 'react-drop-zone'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postArtSample } from 'services/ft/FTService'
import { validationSampleFT } from 'types/validation/ValidationSampleFT'

interface Props {
  confirm: () => void
  close: () => void
  ftId: string
}

function ArtSampleForm(props: Props) {
  const { errors, touched, values, handleSubmit, setFieldValue } = useFormik({
    onSubmit: (inputValues: any) => {
      console.log('valores da amostra', inputValues)
      const sampleForm: any = {
        comment: inputValues.comment,
        url: inputValues.url,
        status: inputValues.status,
        ft: props.ftId
      }
      mutateCreate(sampleForm)
    },
    enableReinitialize: true,
    validationSchema: validationSampleFT,
    initialValues: {
      comment: '',
      url: '',
      status: 'AGUARDANDO APROVAÇÃO DA ARTE',
      ft: props.ftId
    }
  })

  const { mutate: mutateCreate } = useMutation(postArtSample, {
    onSuccess: (data) => {
      console.log('amostra criada', data)
      alertSuccess(
        'Arte enviada com sucesso!',
        'O Cliente poderá analisar a arte.',
        () => {
          props.confirm()
        }
      )
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ??
          'Não foi possível criar a arte'
      )
    }
  })

  const getBase64 = (file) => {
    if (
      !file?.type ||
      !file?.type?.includes('image')
      // (!file?.type?.includes('image') &&
      //   !file?.type?.includes('application/pdf'))
    ) {
      toast.warn('Selecione uma imagem.')
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const base64 = reader.result
      setFieldValue('url', base64)
      console.log('base64: ', base64)
    }
    reader.onerror = function (error) {
      console.log('Error file read: ', error)
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip
          title="Voltar"
          placement="top"
          onClick={() => {
            props.close()
          }}
        >
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>
          Nova arte
        </div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              width: '100%',
              marginRight: '30px',
              marginTop: '20px'
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px',
                  width: '100%'
                }}
              >
                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 600 }}>
                  <FormControl variant="standard" sx={{ m: 1, marginRight: 2, minWidth: 120, flexGrow: 1 }}>
                    <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Age"
                      value={values.status}
                      onChange={(e) => { setFieldValue('status', e.target.value) }}
                    >
                      <MenuItem value={'Enviado'}>Enviado</MenuItem>
                      <MenuItem value={'Em produção'}>Em produção</MenuItem>
                      <MenuItem value={'Em trânsito'}>Em trânsito</MenuItem>
                    </Select>
                  </FormControl>
                </div> */}
                <div
                  style={{
                    borderRadius: '8px',
                    border:
                      errors.url && touched.url
                        ? '1px solid red'
                        : '0px solid red'
                  }}
                >
                  <StyledDropZone
                    children={
                      values.url !== ''
                        ? '✔ Arquivo anexado!'
                        : 'Clique e selecione ou arraste seu arquivo aqui e solte aqui'
                    }
                    onDrop={(file, text) => getBase64(file)}
                  />
                </div>
                <FormHelperText error={Boolean(errors.url && touched.url)}>
                  {errors.url && touched.url ? errors.url : ''}
                </FormHelperText>

                <ImportantView
                  style={{ marginTop: '20px' }}
                  title="Importante"
                  content="Tamanho máximo 2mb"
                />
                <TextField
                  style={{ marginTop: '20px', width: '100%' }}
                  id="outlined-multiline-static"
                  label="Observações"
                  multiline
                  rows={3}
                  onChange={(e) => {
                    setFieldValue('comment', e.target.value)
                  }}
                  error={Boolean(errors.comment && touched.comment)}
                  helperText={
                    errors.comment && touched.comment ? errors.comment : null
                  }
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type="button"
              mold="red"
              onClick={() => {
                props.close()
              }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold="primary"
              type="submit"
            >
              ENVIAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default ArtSampleForm
