import React from 'react'
import { styled } from '@mui/material/styles'
import { Table, Box, CircularProgress } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: 73
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

interface Props {
  loading: boolean
  tableHead?: React.ReactNode
}

const LoadingTableCustomer: React.FC<Props> = ({ loading, tableHead }) => {
  if (!loading) return <></>

  return (
    <>
      <TableContainer component={Paper} style={{ marginTop: '30px' }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {tableHead}

          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={9}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={200}
                  width="100%"
                >
                  <CircularProgress />
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default LoadingTableCustomer
