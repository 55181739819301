import * as yup from 'yup'
import { onlyNumbers } from 'helpers/stringHelper'

const validationInformationSchema = yup.object().shape({
  pf: yup.object().shape({
    cpf: yup
      .string()
      .required('Preencha este campo')
      .test('length-cpf', 'O comprimento mínimo é 11', (value) => {
        const length = onlyNumbers(value ?? '').length
        return length > 10
      })
  }),
  name: yup.string().required('Preencha este campo')
  // cep: yup
  //   .string()
  //   .typeError('você deve especificar um número')
  //   .required('Preencha este campo'),
  // public_place: yup.string().required('Preencha este campo'),
  // number: yup
  //   .number()
  //   .typeError('você deve especificar um número')
  //   .required('Preencha este campo'),
  // district: yup.string().required('Preencha este campo'),
  // state: yup.string().required('Preencha este campo'),
  // city: yup.string().required('Preencha este campo')
})

export { validationInformationSchema }
