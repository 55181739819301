/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import { Box, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, TextField } from '@mui/material'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import { onlyNumbersOrVirgula } from 'helpers/stringHelper'
import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postMaterial, putMaterial } from 'services/material/MaterialService'
import { MaterialType } from 'types/material/MaterialType'
import { validationSchemaMaterial } from 'types/validation/ValidationMaterial'

function MaterialForm(props: any) {
  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(postMaterial, {
    onSuccess: data => {
      toast.success('Cadastrado com sucesso')
      props.close()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível cadastrar')
      }
    }
  })

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(putMaterial, {
    onSuccess: data => {
      toast.success('Editar com sucesso')
      props.close()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível editar')
      }
    }
  })

  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik({
    onSubmit: (values: MaterialType) => {
      if (props.edit) {
        const valuesForm: MaterialType = {
          material: values.material,
          nomenclature: values.nomenclature,
          value: values.value,
          category: values.category,
          isActive: true,
          _id: props.edit._id
        }
        mutateEdit(valuesForm)
      } else {
        const valuesForm: MaterialType = {
          material: values.material,
          nomenclature: values.nomenclature,
          value: values.value,
          category: values.category,
          isActive: true
        }
        mutateCreate(valuesForm)
      }
    },
    enableReinitialize: true,
    validationSchema: validationSchemaMaterial,
    initialValues: {
      material: props.edit?.material ?? '',
      nomenclature: props.edit?.nomenclature ?? '',
      value: props.edit?.value ?? 0,
      category: props.edit?.category ?? 0
    }
  })

  React.useEffect(() => {
    console.log('ok', errors)
  }, [])

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip title='Voltar' placement='top' onClick={() => { props.close() }}>
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>Novo material</div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginRight: '30px',
            marginTop: '20px'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px'
                }}
              >
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={values.material}
                        onChange={handleChange('material')}
                        error={Boolean(errors.material && touched.material)}
                        helperText={(errors.material && touched.material) ? errors.material : null}
                        id='standard-basic'
                        label='Material'
                        variant='standard'
                        fullWidth
                        disabled={props.edit?.category === 1}
                        style={{ color: '#A8A8A8', marginTop: '16px' }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={values.nomenclature}
                        onChange={handleChange('nomenclature')}
                        error={Boolean(errors.nomenclature && touched.nomenclature)}
                        helperText={(errors.nomenclature && touched.nomenclature) ? errors.nomenclature : null}
                        id='standard-basic'
                        label='Nomenclatura'
                        variant='standard'
                        fullWidth
                        disabled={props.edit?.category === 1}
                        style={{ color: '#A8A8A8', marginTop: '16px' }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth style={{ marginTop: '12px' }} variant='standard'>
                        <InputLabel htmlFor='standard-adornment-password'>Valor</InputLabel>
                        <Input
                          id='input-value'
                          value={values.value}
                          type='number'
                          onChange={(e) => {
                            if (Number(e.target.value ?? 0) < 0) {
                              setFieldValue('value', 0)
                            } else {
                              setFieldValue('value', e.target.value)
                              // setFieldValue('value', onlyNumbersOrVirgula(e.target.value))
                            }
                          }}
                          error={Boolean(errors.value && touched.value)}
                          startAdornment={
                            <InputAdornment position='end'>
                              R$
                            </InputAdornment>
                          }
                        />
                        <FormHelperText error={Boolean(errors.value && touched.value)}>
                          {(errors.value && touched.value) ? errors.value : null}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type='button'
              mold='red'
              onClick={() => { props.close() }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold='primary'
              type="submit"
              disabled={isLoadingCreate || isLoadingEdit}
            >
              SALVAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default MaterialForm
