/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { _changePage } from '../slices/home.slice'
import MaterialValuesCalculate from './components/MaterialValuesCalculate'
import { FTBudgetType } from 'types/ft/FTBudgetType'
import GenericDialog from 'components/genericDialog/GenericDialog'
import BudgetCalculate from './components/BudgetCalculate'
import { useNavigate } from 'react-router-dom'

export function CalculatorPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openBudgetModal, setOpenBudgetModal] = useState(true)
  const [budgetData, setBudgetData] = useState<FTBudgetType>()

  useEffect(() => {
    dispatch(
      _changePage({
        name: 'Calculadora',
        description: '',
        icon: 'calculate',
        route: 'calculator',
        cod: 'MT'
      })
    )
  }, [])

  const handleConfirmBudget = (values) => {
    setOpenBudgetModal(false)
    setBudgetData(values)
  }

  const handleGoBack = () => navigate('/home/ft')

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'space-between'}>
        <Typography fontWeight="bold">Calculadora - Orçamento</Typography>
        <Button onClick={handleGoBack}>Voltar</Button>
      </Stack>

      {budgetData && (
        <BudgetCalculate
          setBudgetId={(id) => {
            // setBudgetId(id)
            console.log(id)
          }}
          confirm={(values) => console.log(values)}
          ftTrimboxData={undefined}
          preft={undefined}
          budgetData={budgetData}
          ft={undefined}
        />
      )}

      {openBudgetModal && (
        <GenericDialog
          open={openBudgetModal}
          close={() => setOpenBudgetModal(false)}
          content={
            <MaterialValuesCalculate
              confirm={(values) => handleConfirmBudget(values)}
              close={() => setOpenBudgetModal(false)}
            />
          }
        />
      )}
    </>
  )
}
