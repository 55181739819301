import { AddOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import 'react-drop-zone/dist/styles.css'
import { useQuery } from 'react-query'
import { getAmostras } from 'services/ft/FTService'
import GenericDialog from 'components/genericDialog/GenericDialog'
import SampleShowContent from '../components/SampleShowContent'
import DrawSampleForm from '../components/DrawSampleForm'

function DrawSampleBoxStep(props: any) {
  const [expandedElement, setExpandedElement] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [openFormDialog, setOpenFormDialog] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const handleChangeExpandPropose = (idPropose) => {
    console.log('propose', idPropose)
    if (expandedElement === idPropose) {
      idPropose = -1
    }
    setExpandedElement(idPropose)
  }

  const amostraReq = useQuery(
    'amostras-desenho',
    async () => {
      return getAmostras(props.ft._id)
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 10
    }
  )

  if (amostraReq.isFetching) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div>
      <Box>
        <div style={{ width: 750, marginTop: '30px' }}>
          {amostraReq.data
            ?.filter((a) => a.draw)
            .map((amostra, i) => (
              <div style={{ marginTop: '5px' }}>
                <Accordion
                  expanded={expandedElement === i}
                  onChange={() => handleChangeExpandPropose(i)}
                  style={{ width: 700 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panelbh-header"
                  >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                      <div style={{ fontWeight: 'bold', marginRight: 10 }}>
                        Desenho
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: 600
                      }}
                    >
                      <TextField
                        style={{ width: '320px', marginRight: '10px' }}
                        id="standard-basic"
                        label="Status do desenho"
                        variant="standard"
                        defaultValue={amostra.status}
                        disabled={true}
                      />
                      <Box>
                        {amostra.url?.includes('.pdf')
? (
                          <Box
                            component="a"
                            href={amostra.url}
                            target="_blank"
                            sx={{ textDecoration: 'none' }}
                          >
                            <Button variant="contained">Visualizar PDF</Button>
                          </Box>
                        )
: (
                          <Button
                            variant="contained"
                            onClick={() => {
                              setImageUrl(amostra.url)
                              setOpenDialog(true)
                            }}
                          >
                            Visualizar foto
                          </Button>
                        )}
                      </Box>
                    </div>
                    <TextField
                      style={{ marginTop: '20px', width: '100%' }}
                      id="outlined-multiline-static"
                      label="Observações"
                      multiline
                      rows={3}
                      defaultValue={amostra.comment ?? ''}
                      disabled
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}

          {!amostraReq.data?.find(
            (a) =>
              a.status.includes('Aprovado') ||
              a.status.includes('aprovado') ||
              a.status.includes('APROVADO') ||
              a.status.includes('PENDENTE') ||
              a.status.includes('Enviado')
          ) && (
            <Paper style={{ width: 'fit-content', marginTop: 30 }}>
              <Button
                onClick={() => {
                  setOpenFormDialog(true)
                }}
                variant="text"
                startIcon={<AddOutlined />}
              >
                Novo desenho
              </Button>
            </Paper>
          )}
        </div>
      </Box>
      <GenericDialog
        allowBack
        open={openDialog}
        close={() => setOpenDialog(false)}
        content={<SampleShowContent image={imageUrl} />}
      />

      <GenericDialog
        widthRoot={670}
        allowBack={false}
        open={openFormDialog}
        close={() => setOpenFormDialog(false)}
        content={
          <DrawSampleForm
            ftId={props.ft._id}
            close={() => setOpenFormDialog(false)}
            confirm={() => {
              setOpenFormDialog(false)
              amostraReq.refetch()
            }}
          />
        }
      />
    </div>
  )
}

export default DrawSampleBoxStep
