import React, { useState } from 'react'
import { Box, CircularProgress, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { getReportBalanceStock } from 'services/report/ReportStockService'
import { useQuery } from 'react-query'
import moment from 'moment'
import { StockBalance } from 'types/Report/Report'

interface Props {
  date: string
  multipleDate?: any
}

const TableBalanceStock: React.FC<Props> = (props) => {
  const date = moment(props.date).format('DD/MM/YYYY')
  const [dataValues, setDataValues] = useState<StockBalance[]>([])

  const { data: result, isError, isLoading, isSuccess, isFetching } = useQuery(['balance-stock', date],
    async () => {
      return getReportBalanceStock(date, props.multipleDate)
    },
    {
      onSuccess: (data) => {
        console.log('Balance', result)
        setDataValues(data)
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false
    }
  )
  return (
    <>
      {(isLoading || isFetching) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <CircularProgress />
        </Box>
      )}
      {isError && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
        </Box>
      )}

      {(isSuccess && !isError && !isFetching) && (
        <>
          <Paper>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: '100%' }} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Nome
                    </TableCell>
                    <TableCell>
                      Kg
                    </TableCell>
                    <TableCell>
                      Quantidade
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataValues.map((item: StockBalance) => {
                    return (
                      <TableRow>
                        <>
                          <TableCell>
                            {item.tipo}
                          </TableCell>
                          <TableCell>
                            {(item.kg ?? 0).toLocaleString('pt-BR')}
                          </TableCell>
                          <TableCell>
                            {(item.qtd ?? 0).toLocaleString('pt-BR')}
                          </TableCell>
                        </>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  )
}

export default TableBalanceStock
