/* eslint-disable */
import { Box, CircularProgress, Icon, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import alertSwalCancel from 'components/Alerts/ModalCancel'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { Can } from 'components/Permission/Can'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import MoreMenu from 'components/view/MoreMenu'
import StatusViewGeneric from 'components/view/StatusViewGeneric'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { getOrdersProductionByFTTrimbox } from 'services/ft/FTService'
import { postCancellOrderProductioNotification } from 'services/orderService/OrderService'
import { ViewOption } from 'types/alerts/AlertsType'
import ShowDataOrdersProductions from './ShowDataOrdersProductions'

function TableAuditOrdersProduction(props: any) {
  const [rows, setRows] = React.useState<any[]>([])
  const [op, setOP] = React.useState()
  const [page, setPage] = React.useState(0)
  const [openDialog, setOpenDialog] = React.useState(false)

  const OPReq = useQuery(['orders-production-by-ft', null],
    async () => {
      return getOrdersProductionByFTTrimbox(props.codConjunto)
    },
    {
      onSuccess: (data) => {
        console.log('dados ops trimbox', data)
        if (data === '') {
          data = []
        }
        setRows(data)
      },
      refetchOnWindowFocus: false
    }
  )

  const cancellOP = useQuery(['orders-production-cancell', null],
    async () => {
      return postCancellOrderProductioNotification()
    },
    {
      onSuccess: (data) => {
        toast.success('Cancelado com sucesso!')
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  // if (OPReq.isFetching) {
  //   return (
  //     <Box sx={{ display: 'flex' }}
  //       style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
  //       <CircularProgress />
  //     </Box>
  //   )
  // }

  // if (OPReq.isError) {
  //   return (
  //     <Box sx={{ display: 'flex' }}
  //       style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
  //       <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
  //       <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
  //     </Box>
  //   )
  // }

  const optionsView: ViewOption[] = [
    {
      status: 'Ativo',
      color: '#5f7aea',
      bgColor: '#edf0ff'
    },
    {
      status: 'Não confirmado',
      color: '#ce6c2b',
      bgColor: '#fff1d7'
    },
    {
      status: 'Parcial Expedido',
      color: '#ce6c2b',
      bgColor: '#fff1d7'
    },
    {
      status: 'Cancelado',
      color: '#EE207A',
      bgColor: '#FFDDEC'
    },
    {
      status: 'Expedido',
      color: '#72CE2B',
      bgColor: '#EBFFD8'
    }
  ]

  return (
    <div>
      <div>Ordens de Produção - FT {props.codConjunto}</div>
      <TableContainer style={{ marginTop: '30px' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'>Cliente</StyledTableCell>
              <StyledTableCell align='left'>Código Item</StyledTableCell>
              <StyledTableCell align='left'>Descrição</StyledTableCell>
              <StyledTableCell align='left'>Fluxo Produção</StyledTableCell>
              <StyledTableCell align='left'>Quantidade Pedida</StyledTableCell>
              <StyledTableCell align='left'>Data Criação</StyledTableCell>
              <StyledTableCell align='left'>Status</StyledTableCell>
              <Can
                module={MODULES.FT}
                permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
              >
                <StyledTableCell align='left'>Ações</StyledTableCell>
              </Can>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows?.slice(page * 5, page * 5 + 5)?.map(row =>
                <StyledTableRow key={0}>
                  <StyledTableCell align='left'>{row.cliente}</StyledTableCell>
                  <StyledTableCell align='left'>{row.codigoInterno}</StyledTableCell>
                  <StyledTableCell align='left'>{row.descricao}</StyledTableCell>
                  <StyledTableCell align='left'>{row.fluxoProducao}</StyledTableCell>
                  <StyledTableCell align='left'>{row.quantidadePedida}</StyledTableCell>
                  <StyledTableCell align='left'>{moment(row.dataCriacao).format('DD/MM/YYY \\à\\s HH:mm:ss')}</StyledTableCell>
                  <StyledTableCell align='left'>
                    <StatusViewGeneric options={optionsView} status={
                      row.statusPedido === 0 ? 'Não confirmado' :
                        row.statusPedido === 1 ? 'Ativo' :
                          row.statusPedido === 2 ? 'Parcial Expedido' :
                            row.statusPedido === 3 ? 'Expedido' :
                              row.statusPedido === 4 ? 'Cancelado' :
                                row.statusPedido} />
                  </StyledTableCell>
                  <Can
                    module={MODULES.FT}
                    permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                  >
                    <StyledTableCell align='left'>
                      <MoreMenu
                        audit={() => {
                          setOP(row)
                          setOpenDialog(true)
                        }}
                        inactive={() => alertSwalCancel(() => { cancellOP.refetch() })}
                      />
                    </StyledTableCell>
                  </Can>
                </StyledTableRow>
              )
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          rowsPerPage={10}
          component="div"
          count={rows.length}
          page={page}
          onPageChange={(event: unknown, newPage: number) => { setPage(newPage) }}
        />
      </TableContainer>

      {openDialog && <GenericDialog
        open={openDialog}
        close={() => setOpenDialog(false)}
        content={<ShowDataOrdersProductions op={op} />} />}
    </div>
  )
}

export default TableAuditOrdersProduction
