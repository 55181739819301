import * as React from 'react'
import Box from '@mui/material/Box'
import TableItems from '../components/TableItems'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { Grid, TextField } from '@mui/material'
import ItemsPanel from './ItemsPanel'
import { useDeliveryMapToday } from 'services/deliveryMap/DeliveryMapService'

interface Props {
  date?: Date
}

const GeneralItems: React.FC<Props> = (props) => {
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(1)
  const machine = [
    { code: 'AM', description: 'AMARRADEIRA' },
    { code: 'CM', description: 'COLADEIRA MANUAL' },
    { code: 'COL', description: 'COLADEIRA' },
    { code: 'COL SP', description: 'COL SP' },
    { code: 'COL-2', description: 'COLADEIRA-2' },
    { code: 'CV', description: 'CORTE VINCO' },
    { code: 'CVSP', description: 'CVSP' },
    { code: 'DIG SP', description: 'IMP-DIGITAL' },
    { code: 'GR', description: 'GRAMPEADEIRA' },
    { code: 'GR2', description: 'GRAMPEADEIRA' },
    { code: 'GR3', description: 'GRAMPEADEIRA' },
    { code: 'GR4', description: 'GRAMPEADEIRA' },
    { code: 'GR5', description: 'GRAMPEADEIRA' },
    { code: 'GR6', description: 'GRAMPEADEIRA' },
    { code: 'IW', description: 'WARD 1' },
    { code: 'IW2', description: 'JUMBO' },
    { code: 'IW3', description: 'WARD III' },
    { code: 'IW4', description: 'WARDIV' },
    { code: 'IW5', description: 'SUNRISE' },
    { code: 'IW6', description: 'WAM' },
    { code: 'LP', description: 'LIMPEZA' },
    { code: 'VC', description: 'VINCADEIRA' }
  ]

  const monkey = [
    { ft: '000000021', cliente: 'ARUANÃ TRANSPORTES LTDA', tipo: 'KANBAN', machine: 'AM' },
    { ft: '000000030', cliente: 'R M G PEREIRA E CIA LTDA', tipo: 'CARTEIRA', machine: 'CM' },
    { ft: '000000064', cliente: 'FLAFER REPRESENTACOES LTDA', tipo: 'CARTEIRA', machine: 'COL' },
    { ft: '000000066', cliente: 'F BARBOSA SANTOS COMERCIO DE MAQUIN', tipo: 'CARTEIRA', machine: 'COL SP' },
    { ft: '000000087', cliente: 'W R COLCHOES E ELETRODOMESTICOS', tipo: 'CARTEIRA', machine: 'COL-2' },
    { ft: '000000098', cliente: 'DULAR - ELETRO MOVEIS LTDA', tipo: 'CARTEIRA', machine: 'CV' },
    { ft: '000000094', cliente: 'ELETRO JUNIOR LTDA', tipo: 'KANBAN', machine: 'CVSP' },
    { ft: '000000091', cliente: 'CENCOSUD BRASIL COMERCIAL LTDA', tipo: 'KANBAN', machine: 'DIG SP' },
    { ft: '000000108', cliente: 'ELETROMOVEIS MATA LTDA', tipo: 'KANBAN', machine: 'GR' },
    { ft: '000000104', cliente: 'COMERCIAL LABEGALINI LTDA', tipo: 'KANBAN', machine: 'GR2' },
    { ft: '000000122', cliente: 'BAZZOLAO ELETROMOVEIS LTDA', tipo: 'KANBAN', machine: 'GR3' },
    { ft: '000000121', cliente: 'BAZZOLAO ELETROMOVEIS LTDA', tipo: 'KANBAN', machine: 'GR4' },
    { ft: '000000129', cliente: 'COMERCIAL SUPERAUDIO LTDA', tipo: 'KANBAN', machine: 'GR5' },
    { ft: '000000134', cliente: 'DISMOVEL - DISTR ATAC MOV ELETRODOM', tipo: 'KANBAN', machine: 'GR6' },
    { ft: '000000150', cliente: 'JULIO ALCIO FARIAS DA ROSA - ME', tipo: 'KANBAN', machine: 'IW' },
    { ft: '000000180', cliente: 'RAPIDAO COMETA LOGISTICA TRANSP S A', tipo: 'KANBAN', machine: 'IW2' },
    { ft: '000000203', cliente: 'CARMELITO MAGAZINE LTDA - EPP', tipo: 'KANBAN', machine: 'IW3' },
    { ft: '000000207', cliente: 'DIGIBRAS INDUSTRIA DO BRASIL S/A', tipo: 'KANBAN', machine: 'IW4' },
    { ft: '000000224', cliente: 'RCA DA AMAZ IND E COM DE COMPONENTE', tipo: 'KANBAN', machine: 'IW5' },
    { ft: '000000239', cliente: 'DAN-TECH DA AMAZONIA IND.COM.LTDA', tipo: 'KANBAN', machine: 'IW6' },
    { ft: '000000260', cliente: 'MAGAZINE LILIANI S/A', tipo: 'KANBAN', machine: 'LP' },
    { ft: '000001436', cliente: 'Emily II e Alícia Transportes ME', tipo: 'KANBAN', machine: 'VC' },
    { ft: '000000001', cliente: 'PLACIBRAS DA AMAZONIA LTDA', tipo: 'CARTEIRA', machine: 'IW2' },
    { ft: '000000050', cliente: 'CREDIMOVEIS NOVOLAR LTDA', tipo: 'CARTEIRA', machine: 'EXP' },
    { ft: '000000096', cliente: 'LIDER ELETRO MOVEIS LTDA', tipo: 'CARTEIRA', machine: 'EXP' },
    { ft: '000000101', cliente: 'MICROSYS INFORMATICA ELETROELETR LT', tipo: 'CARTEIRA', machine: 'COL-2' },
    { ft: '000000102', cliente: 'LOJAS EDMIL S A', tipo: 'CARTEIRA', machine: 'AM' },
    { ft: '000000088', cliente: 'F M DE SOUZA', tipo: 'CARTEIRA', machine: 'EXP' },
    { ft: '000000120', cliente: 'ANTONIO CLAUDINO DA SILVA', tipo: 'CARTEIRA', machine: 'COL-2' },
    { ft: '000000155', cliente: 'MARCIA CRISTINA LOPES LEVORATO & CI', tipo: 'CARTEIRA', machine: 'AM' },
    { ft: '000000169', cliente: 'O S DO NASCIMENTO', tipo: 'CARTEIRA', machine: 'AM' },
    { ft: '000000191', cliente: 'BAZZOLAO ELETROMOVEIS LTDA', tipo: 'KANBAN', machine: 'COL-2' },
    { ft: '000000194', cliente: 'POLAR REPRESENTACOES LTDA', tipo: 'KANBAN', machine: 'EXP' },
    { ft: '000000201', cliente: 'MARLON CORREA DE FARIA - ME', tipo: 'KANBAN', machine: 'COL-2' },
    { ft: '000000210', cliente: 'BEM BOM MOVEIS LTDA ME', tipo: 'KANBAN', machine: 'GR4' },
    { ft: '000000241', cliente: 'ITD Transportes Ltda', tipo: 'KANBAN', machine: 'COL-2' },
    { ft: '000000255', cliente: 'FONSECA E ALCANTARA LTDA', tipo: 'CARTEIRA', machine: 'GR4' }
  ]
  const formattedDate = props?.date ? new Date(props.date).toISOString().split('T')[0] : ''
  const { data, isLoading, isError } = useDeliveryMapToday({
    page,
    offset: '0',
    limit: '10',
    search,
    startDate: formattedDate,
    endDate: formattedDate
  })
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }
  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  return (
    <Can
      module={MODULES.USUÁRIOS}
      permissions={[PERMISSIONS.READ]}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} container alignItems="center" justifyContent="center">
            <Box
              component="section"
              sx={{ p: 2, textAlign: 'justify' }}
            >
              <ItemsPanel data={monkey} legend={machine} />
            </Box>
          </Grid>
        </Grid>
        <Box component="form" onSubmit={handleSearchSubmit} sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Pesquisar por 'FT' ou 'Nome do Cliente' ou 'Código do Cliente'"
            value={search}
            onChange={handleSearchChange}
          />
        </Box>
        <TableItems
          data={data?.response}
          setSearch={setSearch}
          loading={isLoading}
          error={isError}
          page={page}
          setPage={setPage}
          count={data?.totalCount}
        />
      </Box>
    </Can>
  )
}

export default GeneralItems
