/* eslint-disable no-prototype-builtins */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/table/table.styles'
import CustomButton from 'components/buttons/CustomButton'
import moment from 'moment-timezone'

interface Props {
  open: boolean
  data: any[]
  close: () => void
  refetch?: () => void
}

const TableFinPJ: React.FC<Props> = (props) => {
  const closeModal = () => props.close()

  const [FinPJ] = useState<any>(props.data['DV-Relato']?.RelatoB49C[0]?.AnotacoesFinanceirasPJ[0])

  const [dataValues, setDataValues] = useState<any[]>([])

  useEffect(() => {
    const loadedData = [FinPJ].every(loading => loading)
    if (loadedData) {
      dateFormat(FinPJ)
    }
  }, [])

  const dateFormat = (data) => {
    const objFormated = [{
      quantidade: data.hasOwnProperty('Anotacoes') ? data.Anotacoes.QuantidadeTotal : '',
      valorTotal: data.hasOwnProperty('Anotacoes') ? data.Anotacoes.ValorTotal : '',
      quantidadeTotalSPC: data.hasOwnProperty('AnotacoesSPC') ? data.AnotacoesSPC.QuantidadeTotalSPC : '',
      valorTotalSPC: data.hasOwnProperty('AnotacoesSPC') ? data.AnotacoesSPC.ValorTotalSPC : '',
      dataPrimeiraOcorrenciaDividasVencidas: data.hasOwnProperty('DividaVencida') ? moment(data.DividaVencida.DataPrimeiraOcorrenciaDividasVencidas).format('DD/MM/YYYY') : '',
      dataUltimaOcorrenciaDividasVencidas: data.hasOwnProperty('DividaVencida') ? moment(data.DividaVencida.DataUltimaOcorrenciaDividasVencidas).format('DD/MM/YYYY') : '',
      quantidadeTotalDividasVencidas: data.hasOwnProperty('DividaVencida') ? data.DividaVencida.QuantidadeTotalDividasVencidas : '',
      dataPrimeiraOcorrenciaPefin: data.hasOwnProperty('DividaVencida') ? moment(data.Pefin.DataPrimeiraOcorrenciaPefin).format('DD/MM/YYYY') : '',
      dataUltimaOcorrenciaPefin: data.hasOwnProperty('DividaVencida') ? moment(data.Pefin.DataUltimaOcorrenciaPefin).format('DD/MM/YYYY') : '',
      quantidadeTotalPefin: data.hasOwnProperty('Pefin') ? data.Pefin.QuantidadeTotalPefin : '',
      valorTotalPefin: data.hasOwnProperty('Pefin') ? data.Pefin.ValorTotalPefin : '',
      valorUltimaOcorrenciaPefin: data.hasOwnProperty('Pefin') ? data.Pefin.ValorUltimaOcorrenciaPefin : '',
      faturamentoPresumido: data.hasOwnProperty('RendaPresumida') ? data.RendaPresumida.FaturamentoPresumido : ''
    }]
    setDataValues(objFormated)
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={closeModal}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {[
                      'Data primeira dívida vencida',
                      'Data primeira ocorrência Prefin',
                      'Data última dívida vencida',
                      'Data última ocorrência Pefin',
                      'Faturamento presumido',
                      'Qtd',
                      'Qtd total dívidas vencidas',
                      'Qtd total Pefin',
                      'Qtd total spc',
                      'Valor total',
                      'Valor total Pefin',
                      'Valor total spc',
                      'Valor última ocorrência Pefin'
                      ].map((item) => (
                        <StyledTableCell key={item} align="left">{item}</StyledTableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FinPJ === undefined
                    ?
                    <StyledTableRow>
                      <StyledTableCell colSpan={13}>
                        <Box
                          sx={{
                            display: 'flex',
                            height: 200,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Typography component="span" variant="body2">
                            Nenhum Registro Encontrado
                          </Typography>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                    :
                    dataValues?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row?.dataPrimeiraOcorrenciaDividasVencidas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.dataPrimeiraOcorrenciaPefin}</StyledTableCell>
                        <StyledTableCell align="left">{row?.dataUltimaOcorrenciaDividasVencidas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.dataUltimaOcorrenciaPefin}</StyledTableCell>
                        <StyledTableCell align="left">{row?.faturamentoPresumido}</StyledTableCell>
                        <StyledTableCell align="left">{row?.quantidade}</StyledTableCell>
                        <StyledTableCell align="left">{row?.quantidadeTotalDividasVencidas}</StyledTableCell>
                        <StyledTableCell align="left">{row?.quantidadeTotalPefin}</StyledTableCell>
                        <StyledTableCell align="left">{row?.quantidadeTotalSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.valorTotal}</StyledTableCell>
                        <StyledTableCell align="left">{row?.valorTotalPefin}</StyledTableCell>
                        <StyledTableCell align="left">{row?.valorTotalSPC}</StyledTableCell>
                        <StyledTableCell align="left">{row?.valorTotalPefin}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <CustomButton
            mold="primary"
            type="button"
            onClick={closeModal}
            autoFocus
          >
            Voltar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TableFinPJ
