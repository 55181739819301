import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AddOutlined, CachedOutlined } from '@mui/icons-material'
import { useQuery } from 'react-query'
import { getBudget, getProposals } from 'services/ft/FTService'
import moment from 'moment'

function ProposalBoxStep(props: any) {
  let { budgetId } = props
  const [expandedElement, setExpandedElement] = useState(-1)

  const handleChangeExpandPropose = (idPropose) => {
    console.log('propose', idPropose)
    if (expandedElement === idPropose) {
      idPropose = -1
    }
    setExpandedElement(idPropose)
  }

  const proposalsReq = useQuery(
    ['proposals', null],
    async () => {
      return getProposals(budgetId ?? '')
    },
    {
      onSuccess: (data) => {
        console.log('e tal', data)
        if (data !== undefined && data?.length > 0) {
          props.setExistsProposal(true)
        } else {
          props.setExistsProposal(false)
        }
        console.log('dados da ft mnemosine', data)
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const budgetReq = useQuery(
    ['budget-req', null],
    async () => {
      return getBudget(props.ft._id)
    },
    {
      onSuccess: (data) => {
        budgetId = data[0]?._id
        proposalsReq.refetch()
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  useEffect(() => {
    console.log('[Proposal] : [', budgetId ?? '')
    if (!budgetId) {
      budgetReq.refetch()
    } else {
      proposalsReq.refetch()
    }
  }, [])

  const reload = (
    <Box
      sx={{ display: 'flex' }}
      style={{
        justifyContent: 'right',
        marginTop: '18px',
        marginRight: '10px'
      }}
    >
      <IconButton color="primary" aria-label="sincronizar">
        <CachedOutlined
          onClick={() => {
            console.log(budgetId)
            if (!budgetId) {
              budgetReq.refetch()
            } else {
              proposalsReq.refetch()
            }
          }}
        />
      </IconButton>
    </Box>
  )

  if (proposalsReq.isFetching || budgetReq.isFetching) {
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px',
          width: '100%'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (proposalsReq.isError || budgetReq.isError) {
    if (proposalsReq.error instanceof Object) {
      const error: any = proposalsReq.error
      if (error.response.status === 404) {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {reload}
            <div
              style={{
                justifyContent: 'center',
                width: '100%',
                marginTop: '124px',
                marginRight: '88px',
                textAlign: 'center'
              }}
            >
              <h4>
                Não há propostas para exibir, registre uma para registrar uma
                amostra.
              </h4>
            </div>
          </Box>
        )
      }
    }
    return (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }

  return (
    <div>
      <Box>
        {reload}
        <div style={{ width: 750, flexDirection: 'column' }}>
          {proposalsReq.data?.map((proposal, i) => (
            <div style={{ marginTop: '5px' }}>
              <Accordion
                expanded={expandedElement === i}
                onChange={() => handleChangeExpandPropose(i)}
                style={{ width: 700 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panelbh-header"
                >
                  <Typography sx={{ width: '100%', flexShrink: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ fontWeight: 'bold', marginRight: 10 }}>
                        Proposta
                      </div>
                      <div>
                        Enviada em{' '}
                        {moment(proposal.createdAt).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: 600
                    }}
                  >
                    <TextField
                      style={{ width: '320px', marginRight: '10px' }}
                      id="standard-basic"
                      label="Status da proposta"
                      variant="standard"
                      defaultValue={proposal.status}
                      disabled={true}
                    />
                    <Box>
                      <Button
                        onClick={() => {
                          props.setViewProposal(proposal)
                        }}
                        variant="contained"
                      >
                        Visualizar proposta
                      </Button>
                    </Box>
                  </div>
                  <TextField
                    style={{ marginTop: '20px', width: '100%' }}
                    id="outlined-multiline-static"
                    label="Observações"
                    defaultValue={proposal.observation}
                    multiline
                    rows={3}
                    disabled
                  />
                  {proposal?.comment?.length && (
                    <TextField
                      style={{ marginTop: '20px', width: '100%' }}
                      id="outlined-multiline-static"
                      label="Comentário"
                      defaultValue={proposal.comment}
                      multiline
                      rows={3}
                      disabled
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}

          {!proposalsReq.data?.find(
            (a) =>
              a.status.includes('EM ANÁLISE') ||
              a.status.includes('PENDENTE') ||
              a.status.includes('APROVADA')
          ) && (
            <Paper style={{ width: 'fit-content', marginTop: 30 }}>
              <Button
                variant="text"
                onClick={() => {
                  props.back()
                }}
                startIcon={<AddOutlined />}
              >
                Nova proposta
              </Button>
            </Paper>
          )}
        </div>
      </Box>
    </div>
  )
}

export default ProposalBoxStep
