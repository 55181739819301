/* eslint-disable @typescript-eslint/no-unused-vars */
import { SearchOutlined } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField
} from '@mui/material'
import { ptBR } from 'date-fns/locale'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'

interface Props {
  handleFilter: (values: any) => void
}

export const Filters: React.FC<Props> = ({ handleFilter }) => {
  const inputSearchRef = useRef<HTMLInputElement>(null)

  const [search, setSearch] = useState('')
  const [startDate, setStartDate] = useState<any | null>(null)
  const [endDate, setEndDate] = useState<any | null>(null)

  useEffect(() => {
    handleFilter({ search, startDate, endDate })
  }, [search, startDate, endDate])

  const debounce = useCallback((func: any, wait: number): any => {
    let timeout: NodeJS.Timeout

    return function executedFunction(...args: number[]) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }, [])

  const returnedFunction = debounce((event: any) => {
    if (event?.target?.value) {
      setSearch(event.target.value)
      console.log(event.target.value)
    } else {
      setSearch('')
      console.log('CLEAR')
    }
  }, 800)

  return (
    <Grid container spacing={3} mt={0}>
      <Grid item xs={3}>
        <FormControl variant="standard" className="w-100">
          <InputLabel htmlFor="field-search">Pesquisar</InputLabel>
          <Input
            id="field-search"
            autoComplete="off"
            type="search"
            defaultValue=""
            onKeyUp={(event) => returnedFunction(event)}
            onKeyPress={(event) => returnedFunction(event)}
            onClick={(event) => returnedFunction(event)}
            ref={inputSearchRef}
            endAdornment={
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>

      <Grid item xs={3}>
        <Box sx={{ paddingLeft: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              // views={['day', 'month', 'year']}
              label="Data início"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={(value) => {
                console.log('date', value)
                setStartDate(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="select-start-date"
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <Box paddingRight={1} marginLeft="-40px">
                        {params?.InputProps?.endAdornment}
                      </Box>
                    ),
                    endAdornment: (
                      <Box sx={{ opacity: startDate ? 1 : 0 }}>
                        <IconButton
                          size="small"
                          onClick={() => setStartDate(null)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    )
                  }}
                  className="w-100"
                  variant="standard"
                  required
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box sx={{ paddingLeft: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              label="Data fim"
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={(value) => {
                console.log('date', value)
                setEndDate(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="select-start-date"
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <Box paddingRight={1} marginLeft="-40px">
                        {params?.InputProps?.endAdornment}
                      </Box>
                    ),
                    endAdornment: (
                      <Box sx={{ opacity: endDate ? 1 : 0 }}>
                        <IconButton
                          size="small"
                          onClick={() => setEndDate(null)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    )
                  }}
                  className="w-100"
                  variant="standard"
                  required
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Grid>
    </Grid>
  )
}
