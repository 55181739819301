/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TablePagination,
  TextField,
  Typography
} from '@mui/material'
import { Add, ArrowRight, ChevronRight, Delete } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { httpClient, httpLN } from 'config/httpClient'
import { Form, Formik, useFormik } from 'formik'
import { onlyNumbers } from 'helpers/stringHelper'
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab'
import {
  getCollaborators,
  getIe
} from 'services/customerControl/CustomerControlServices'
import { useNavigate } from 'react-router-dom'
import {
  getAllCodBoxes,
  getCompositions,
  getFechamentos,
  getPortfolioBoxes
} from 'services/portfolio/PortfolioService'
import ImportantView from 'components/view/ImportantView'
import { StyledDropZone } from 'react-drop-zone'
import { FileType, UploadDocsPreFT } from './UploadDocsPreFT'
import { getPromiseBase64 } from 'utils/base64'

interface Props {
  openModal: boolean
  item: any
  seller: any
  clients: any[]
  handleCloseModal: () => void
}

export function ModalPreFTPortfolioBox({
  openModal,
  item,
  seller,
  clients,
  handleCloseModal
}: Props) {
  console.log('🚀 ~ file: ModalPreFTPortfolioBox.tsx ~ line 60 ~ item', item)
  const navigate = useNavigate()

  const [loadingItems, setLoadingItems] = useState(true)

  const [papers, setPapers] = useState<any[]>([])
  const [boxes, setBoxes] = useState<any[]>([])
  const [plates, setPlates] = useState<any[]>([])
  // const [cardboard, setCardboard] = useState<any>()
  const [composicao, setComposicao] = useState<any[]>([])
  const [fechamentos, setFechamentos] = useState<any[]>([])
  const [codBoxes, setCodBoxes] = useState<any[]>([])

  const [loadingFechamento, setLoadingFechamento] = useState(true)
  const [collaborators, setCollaborators] = useState<any[]>([])
  const [loadingCollaborators, setLoadingCollaborators] = useState(false)
  const [company, setCompany] = useState<any>()
  const [loadingCompany, setLoadingCompany] = useState(true)
  const [ies, setIes] = useState<any[]>([])
  const [loadingIes, setLoadingIes] = useState(false)

  const [cores, setCores] = useState<any[]>([])

  const [selectedFile, setSelectedFile] = useState<File>()
  const [uploadFiles, setUploadFiles] = useState<any[]>([])

  const fetchAll = useCallback(async () => {
    getCompositions()
      .then((data) => {
        // console.log('TRIMBOX Composição', data)
        setComposicao(data ?? [])
      })
      .catch((error) => {
        console.warn('Error:', error)
      })
    getFechamentos()
      .then((data) => {
        // console.log('TRIMBOX Fechamento', data)
        setFechamentos(data ?? [])
      })
      .catch((error) => {
        console.log('Error:', error)
      })
      .finally(() => {
        setLoadingFechamento(false)
      })
    getAllCodBoxes()
      .then((data) => {
        // console.log('COD Caixa', data)
        setCodBoxes(data ?? [])
      })
      .catch((error) => {
        console.log('Error:', error)
      })
    getPortfolioBoxes()
      .then((data) => {
        // console.log('Tipos Caixa', data)
        setBoxes(data ?? [])
      })
      .catch((error) => {
        console.log('Error:', error)
      })

    // await Promise.all([getBoxes(), getPappers(), getPlates()])
    //   .then(response => {
    //     setBoxes(response[0].data)
    //     setPapers(response[1].data)
    //     setPlates(response[2].data)
    //   })
    //   .catch(error => {
    //     Al.alert(error?.response?.data?.message)
    //   })
  }, [])

  useEffect(() => {
    void fetchAll()

    return () => {
      setBoxes([])
      setPapers([])
      setPlates([])
    }
  }, [])

  const handleLoadIes = useCallback(async (companyId) => {
    getIe(companyId)
      .then((response) => {
        setIes(response?.data ?? [])
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }, [])

  const handleLoadCollaborator = useCallback(async (companyId) => {
    setLoadingCollaborators(true)
    getCollaborators(companyId)
      .then((response) => {
        setCollaborators(response?.data ?? [])
        setLoadingCollaborators(false)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
        setLoadingCollaborators(false)
      })
  }, [])

  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    resetForm
  } = useFormik({
    onSubmit: async (dataValues: any) => {
      if (!dataValues?.collaborator) {
        toast.error('Você precisa selecionar um representante.')
        return
      }
      console.log('dataValues', dataValues)

      try {
        const response = await httpClient.post('/prefts', dataValues)

        // if (response.data?._id && selectedFile) {
        //   const formData = new FormData()

        //   formData.append('file', selectedFile)

        //   const headers = {
        //     'Content-Type': 'multpart/form-data'
        //   }

        //   await httpClient.put(`/prefts/${response.data._id}/docs`, formData, {
        //     headers
        //   })
        // }

        if (response.data?._id && uploadFiles?.length) {
          await httpClient.put(`/prefts/${response.data._id}/multi-docs`, {
            files: uploadFiles
          })
        }

        toast.success('Solicitação de caixa criada com sucesso.')

        navigate('/home/ft')
      } catch (error: any) {
        toast.error(error?.response?.data?.message)
      }
    },
    // validationSchema: validationFormFtBoxSchema,
    enableReinitialize: true,
    initialValues: {
      qtd: '',
      status: 'NOVO',
      type: 'CAIXA',
      client: null,
      ie: null,
      collaborator: null,
      seller: seller?._id ?? null,
      formplate: null,
      formpaper: null,
      formulariocaixa: item?._id ?? null,
      preftpaper: null,
      preftbox: {
        paper: '', // Kraft/Kraft, Kraft/Testline, Testline/Testline, Testline/Miolo, Miolo/Miolo, Branco/testline, Branco/Branco
        reference: '',
        amount: {
          previsionTotal: '',
          monthConsumption: ''
        },
        planningRD: {
          definition: {
            justToDo: item?.Definicao === 'ItemParaDesenvolver' ?? false,
            projectAndDo:
              item?.Definicao === 'ItemParaProjetarEDesenvolver' ?? false,
            withSimiliarProject:
              item?.Definicao === 'ItemComProjetosSimilares' ?? false
          },
          newProduct: {
            basedFTD: item?.NovoProduto?.BasedaNaFTD ?? false,
            basedFTDValue: item?.NovoProduto?.basedFTDValue ?? '',
            drawingOrSpecification:
              item?.NovoProduto?.DesnehoEspecificacao ?? false,
            mechanicalSample: item?.NovoProduto?.AmostraMecanica ?? false,
            productInCustomer: item?.NovoProduto?.ProdutoDoCliente ?? false,
            similarProduct: item?.NovoProduto?.ProdutoSemelhante ?? false,
            other: item?.NovoProduto?.OutrosObs ?? ''
          }
        },
        specifications: {
          description: item?.Referencia ?? '',
          Composicao: item?.Composicao ?? undefined,
          boxType: item?.boxType?._id ?? undefined,
          codTypebox: item?.codTypebox?._id ?? ''
        },
        internalMeasures: {
          width: item?.LarguraInterna ?? '',
          height: item?.AlturaInterna ?? '',
          weight: '',
          diameter: item?.ComprimentoInterno ?? '',
          grammage: 0,
          // grammage:
          //   Number(item?.LarguraInterna ?? 0) +
          //     Number(item?.ComprimentoInterno ?? 0) +
          //     Number(item?.AlturaInterna ?? 0) ?? '',
          closure: '',
          Fechamento: item?.Fechamento ?? undefined,
          prints: item?.ImpressoesQtd ?? '',
          printsColors: item?.ImpressoesCor ?? {
            cor1: '',
            cor2: '',
            cor3: '',
            cor4: '',
            cor5: '',
            cor6: '',
            cor7: '',
            cor8: '',
            cor9: '',
            cor10: ''
          }
        },
        extraData: item?.Obs1 ?? '',
        tied: !item?.AmarradoPalete ?? false,
        tiedPallet: item?.AmarradoPalete ?? false,
        handlesOrHole: item?.AlcasFuro ?? true,
        handles: item?.Alcas ?? false,
        handlesValue: item?.AlcasValue ?? '',
        hole: item?.Furo ?? false,
        holeValue: item?.FuroValue ?? '',
        reinforcedPallet: item?.PaleteReforcado ?? true,
        beatenOrPalletized: item?.Batido === false ?? undefined,
        tare: item?.Tara ?? false,
        piecesOrTied: '',
        tiedOrPallet: item?.AmarradoPaleteQtd ?? '',
        handlesAndHoles: item?.AlcasFuroQtdPorCaixa ?? '',
        amountTape: item?.PaleteReforcadoQuantidadeFita ?? '',
        amountPalletBallast: item?.PaleteReforcadoQuantidadeLastro ?? '',
        needSample: item?.Amostra ?? false,
        amountSample: item?.AmostraQtd ?? '',
        reportNF: false
      }
    }
  })

  const handleAddCor = () => {
    if (cores.length >= 10) return

    setCores((state) => [
      ...state,
      { name: `cor${cores.length + 1}`, value: '' }
    ])
  }

  const handleRemoveCor = async () => {
    setCores((state) => state?.filter((_, index) => index + 1 < cores?.length))
  }

  const handleChangeColor = (name: string, value: string) => {
    setCores((state) =>
      state?.map((cor) => (cor?.name === name ? { ...cor, value } : cor))
    )
  }

  const loadCor = useCallback(() => {
    const colors: any[] = []
    if (item?.ImpressoesCor?.cor1) {
      colors.push({ name: 'cor1', value: item?.ImpressoesCor?.cor1 ?? '' })
    }
    if (item?.ImpressoesCor?.cor2) {
      colors.push({ name: 'cor2', value: item?.ImpressoesCor?.cor2 ?? '' })
    }
    if (item?.ImpressoesCor?.cor3) {
      colors.push({ name: 'cor3', value: item?.ImpressoesCor?.cor3 ?? '' })
    }
    if (item?.ImpressoesCor?.cor4) {
      colors.push({ name: 'cor4', value: item?.ImpressoesCor?.cor4 ?? '' })
    }
    if (item?.ImpressoesCor?.cor5) {
      colors.push({ name: 'cor5', value: item?.ImpressoesCor?.cor5 ?? '' })
    }
    if (item?.ImpressoesCor?.cor6) {
      colors.push({ name: 'cor6', value: item?.ImpressoesCor?.cor6 ?? '' })
    }
    if (item?.ImpressoesCor?.cor7) {
      colors.push({ name: 'cor7', value: item?.ImpressoesCor?.cor7 ?? '' })
    }
    if (item?.ImpressoesCor?.cor8) {
      colors.push({ name: 'cor8', value: item?.ImpressoesCor?.cor8 ?? '' })
    }
    if (item?.ImpressoesCor?.cor9) {
      colors.push({ name: 'cor9', value: item?.ImpressoesCor?.cor9 ?? '' })
    }
    if (item?.ImpressoesCor?.cor10) {
      colors.push({ name: 'cor10', value: item?.ImpressoesCor?.cor10 ?? '' })
    }

    setCores(colors)
  }, [item?.ImpressoesCor])

  useEffect(() => {
    loadCor()
  }, [])

  useEffect(() => {
    void setFieldValue('preftbox.internalMeasures.printsColors', {
      cor1: cores[0]?.value ?? '',
      cor2: cores[1]?.value ?? '',
      cor3: cores[2]?.value ?? '',
      cor4: cores[3]?.value ?? '',
      cor5: cores[4]?.value ?? '',
      cor6: cores[5]?.value ?? '',
      cor7: cores[6]?.value ?? '',
      cor8: cores[7]?.value ?? '',
      cor9: cores[8]?.value ?? '',
      cor10: cores[9]?.value ?? ''
    })
  }, [cores])

  const handleReference = useCallback(
    ({ codTypebox, Composicao }) => {
      let box = ''

      if (codTypebox?.length) {
        box = codBoxes?.find((box: any) => box._id === codTypebox)?.cod ?? ''
      }

      void setFieldValue(
        'preftbox.reference',
        `${box} ${Composicao?.nomeComercial ?? ''}`
      )
    },
    [codBoxes]
  )

  useEffect(() => {
    handleReference({
      codTypebox: values?.preftbox?.specifications?.codTypebox,
      Composicao: values?.preftbox?.specifications?.Composicao
    })
  }, [
    values?.preftbox?.specifications?.codTypebox,
    values?.preftbox?.specifications?.Composicao,
    codBoxes?.length
  ])

  // useEffect(() => {
  //   void setFieldValue(
  //     'preftbox.internalMeasures.grammage',
  //     Number(values?.preftbox?.internalMeasures?.width ?? 0) +
  //       Number(values?.preftbox?.internalMeasures?.height ?? 0) +
  //       Number(values?.preftbox?.internalMeasures?.diameter ?? 0)
  //   )
  // }, [
  //   values?.preftbox?.internalMeasures?.diameter,
  //   values?.preftbox?.internalMeasures?.height,
  //   values?.preftbox?.internalMeasures?.width
  // ])

  const getPaper = (data: string[]) => {
    let paper = ''
    data?.forEach((item) => {
      if (item?.length) {
        paper = `${paper}${item},`
      }
    })
    return paper.slice(0, -1)
  }

  const handleSelectFile = (file: File) => {
    if (isSubmitting) {
      return
    }

    if (
      !file?.type ||
      (!file?.type?.includes('image') &&
        !file?.type?.includes('application/pdf'))
    ) {
      toast.warn('Selecione uma imagem ou PDF.')
      return
    }

    setSelectedFile(file)
  }

  const handleFiles = async (files: FileType[]) => {
    const filesInBase64: any[] = []

    for (const item of files) {
      if (item?.file) {
        const fileBase64 = await getPromiseBase64(item.file)

        filesInBase64.push({
          ...item,
          file: fileBase64
        })
      } else {
        filesInBase64.push(item)
      }
    }

    setUploadFiles(filesInBase64)
  }

  return (
    <Dialog
      open={openModal}
      // onClose={handleCloseModal}
      fullWidth
      maxWidth="md"
      aria-labelledby="PreFTPortfolioBox"
      aria-describedby="PreFTPortfolioBox-description"
    >
      <DialogTitle id="PreFTPortfolioBox">Pré-FT Caixa</DialogTitle>
      <Stack component="form" onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="body2" component="span">
            Você pode editar as especificações do produto de acordo com a sua
            necessidade
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                noOptionsText="Sem opções"
                loading={isSubmitting}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                getOptionLabel={(option) =>
                  option.isBlocked || !option.isActive
                    ? `${option.name} - [BLOQUEADO]`
                    : option.name
                }
                id="combo-box-clients"
                options={clients.map((row) => ({
                  ...row,
                  label: row.name,
                  id: row._id
                }))}
                onChange={(_, selectedValue: any | null) => {
                  void setFieldValue('client', selectedValue?._id)
                  void handleLoadCollaborator(selectedValue?._id)
                  void setFieldValue('collaborator', null)
                  setCollaborators([])
                  setCompany(selectedValue)
                  if (selectedValue?.pj?.cnpj) {
                    void handleLoadIes(selectedValue?._id)
                  } else {
                    setIes([])
                    void setFieldValue('ie', null)
                  }
                }}
                getOptionDisabled={(option) =>
                  option.isBlocked || !option.isActive
                }
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Solicitador por"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                disabled
                fullWidth
                id="standard-basic"
                label="Referência"
                value={values?.preftbox?.reference?.toUpperCase()}
              />
            </Grid>

            {!!company?.pj?.cnpj && (
              <Grid item xs={6}>
                <Autocomplete
                  noOptionsText="Sem opções"
                  loading={isSubmitting}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => option?.codeLn}
                  id="combo-box-ie"
                  options={ies.map((row) => ({
                    ...row,
                    label: row.name,
                    id: row._id
                  }))}
                  onChange={(_, selectedValue: any | null) => {
                    void setFieldValue('ie', selectedValue?._id)
                  }}
                  getOptionDisabled={(option) =>
                    option.isBlocked || !option.isActive
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Parceiro de negócio"
                      required
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <Autocomplete
                noOptionsText="Sem opções"
                loading={isSubmitting}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                getOptionLabel={(option) => option?.name}
                id="combo-box-collaborator"
                options={collaborators.map((row) => ({
                  ...row,
                  label: row.name,
                  id: row._id
                }))}
                onChange={(_, selectedValue: any | null) => {
                  void setFieldValue('collaborator', selectedValue?._id)
                }}
                getOptionDisabled={(option) => !option.isActive}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Representante"
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                Quantidade
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Quantidade total"
                value={values.qtd}
                onChange={async (e) =>
                  setFieldValue('qtd', onlyNumbers(e.target.value ?? ''))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Un</InputAdornment>
                  )
                }}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Previsão total"
                value={values?.preftbox?.amount?.previsionTotal}
                onChange={async (e) =>
                  setFieldValue(
                    'preftbox.amount.previsionTotal',
                    onlyNumbers(e.target.value ?? '')
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Un</InputAdornment>
                  )
                }}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Consumo do mês"
                value={values?.preftbox?.amount?.monthConsumption}
                onChange={async (e) =>
                  setFieldValue(
                    'preftbox.amount.monthConsumption',
                    onlyNumbers(e.target.value ?? '')
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Un</InputAdornment>
                  )
                }}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                Planejamento do P&D
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="preftbox-paper">
                  Papel
                </FormLabel>
                <RadioGroup
                  aria-labelledby="preftbox-paper"
                  defaultValue=""
                  name="preftbox-paper"
                  onChange={(_, value) => {
                    void setFieldValue(
                      'preftbox.paper',
                      value
                    )
                  }}
                >
                  <FormControlLabel
                    value="Kraft/Kraft"
                    control={<Radio />}
                    label="Kraft/Kraft"
                  />
                  <FormControlLabel
                    value="Kraft/Testline"
                    control={<Radio />}
                    label="Kraft/Testline"
                  />
                  <FormControlLabel
                    value="Testline/Testline"
                    control={<Radio />}
                    label="Testline/Testline"
                  />
                  <FormControlLabel
                    value="Testline/Miolo"
                    control={<Radio />}
                    label="Testline/Miolo"
                  />
                  <FormControlLabel
                    value="Miolo/Miolo"
                    control={<Radio />}
                    label="Miolo/Miolo"
                  />
                  <FormControlLabel
                    value="Branco/testline"
                    control={<Radio />}
                    label="Branco/testline"
                  />
                  <FormControlLabel
                    value="Branco/Branco"
                    control={<Radio />}
                    label="Branco/Branco"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Definição
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    if (value === 'justToDo') {
                      void setFieldValue(
                        'preftbox.planningRD.definition.justToDo',
                        true
                      )
                      void setFieldValue(
                        'preftbox.planningRD.definition.projectAndDo',
                        false
                      )
                      void setFieldValue(
                        'preftbox.planningRD.definition.withSimiliarProject',
                        false
                      )
                    } else if (value === 'projectAndDo') {
                      void setFieldValue(
                        'preftbox.planningRD.definition.justToDo',
                        false
                      )
                      void setFieldValue(
                        'preftbox.planningRD.definition.projectAndDo',
                        true
                      )
                      void setFieldValue(
                        'preftbox.planningRD.definition.withSimiliarProject',
                        false
                      )
                    } else {
                      void setFieldValue(
                        'preftbox.planningRD.definition.justToDo',
                        false
                      )
                      void setFieldValue(
                        'preftbox.planningRD.definition.projectAndDo',
                        false
                      )
                      void setFieldValue(
                        'preftbox.planningRD.definition.withSimiliarProject',
                        true
                      )
                    }
                  }}
                >
                  <FormControlLabel
                    value="justToDo"
                    control={<Radio />}
                    label="Item apenas para desenvolver"
                  />
                  <FormControlLabel
                    value="projectAndDo"
                    control={<Radio />}
                    label="Item para Projetar e Desenvolver"
                  />
                  <FormControlLabel
                    value="withSimiliarProject"
                    control={<Radio />}
                    label="Item com projetos similares"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                Novo produto
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.preftbox.planningRD.newProduct.basedFTD}
                    />
                  }
                  onChange={(_, checked) => {
                    setFieldValue(
                      'preftbox.planningRD.newProduct.basedFTD',
                      checked
                    )
                  }}
                  label="Baseada na FTD"
                />
                {values?.preftbox.planningRD.newProduct.basedFTD && (
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      autoComplete="off"
                      id="standard-basic"
                      label="Baseada na FTD"
                      value={
                        values?.preftbox?.planningRD?.newProduct?.basedFTDValue
                      }
                      onChange={async (e) =>
                        setFieldValue(
                          'preftbox.planningRD.newProduct.basedFTDValue',
                          e.target.value
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        )
                      }}
                      required
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        values?.preftbox.planningRD.newProduct
                          .drawingOrSpecification
                      }
                    />
                  }
                  onChange={(_, checked) => {
                    setFieldValue(
                      'preftbox.planningRD.newProduct.drawingOrSpecification',
                      checked
                    )
                  }}
                  label="Desenho/Especificação"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        values?.preftbox.planningRD.newProduct.mechanicalSample
                      }
                    />
                  }
                  onChange={(_, checked) => {
                    setFieldValue(
                      'preftbox.planningRD.newProduct.mechanicalSample',
                      checked
                    )
                  }}
                  label="Amostra Mecânica"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        values?.preftbox.planningRD.newProduct.productInCustomer
                      }
                    />
                  }
                  onChange={(_, checked) => {
                    setFieldValue(
                      'preftbox.planningRD.newProduct.productInCustomer',
                      checked
                    )
                  }}
                  label="Produto do cliente"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        values?.preftbox.planningRD.newProduct.similarProduct
                      }
                    />
                  }
                  onChange={(_, checked) => {
                    setFieldValue(
                      'preftbox.planningRD.newProduct.similarProduct',
                      checked
                    )
                  }}
                  label="Projeto Semelhante"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !!values?.preftbox.planningRD.newProduct.other?.length
                      }
                    />
                  }
                  onChange={(_, checked) => {
                    setFieldValue(
                      'preftbox.planningRD.newProduct.other',
                      !values?.preftbox.planningRD.newProduct.other?.length
                        ? 'Outros'
                        : ''
                    )
                  }}
                  label="Outros"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                placeholder="Outros"
                value={values?.preftbox.planningRD.newProduct.other}
                onChange={handleChange('preftbox.planningRD.newProduct.other')}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                Especificações
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Código / Referência"
                value={values?.preftbox.specifications.description}
                onChange={handleChange('preftbox.specifications.description')}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Composição"
                value={
                  values?.preftbox?.specifications?.Composicao?.composicao ?? ''
                }
              />
              {/* {!!composicao?.length && (
                <Autocomplete
                  noOptionsText="Sem opções"
                  loading={isSubmitting}
                  isOptionEqualToValue={(option, value) =>
                    option.composicao === value.composicao
                  }
                  getOptionLabel={(option) => option.composicao}
                  options={composicao}
                  onChange={(_, selectedValue: any | null) => {
                    void setFieldValue(
                      'preftbox.specifications.Composicao',
                      selectedValue
                    )
                  }}
                  defaultValue={composicao?.find(
                    (item) =>
                      item.composicao ===
                      values?.preftbox?.specifications?.Composicao?.composicao
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Composição"
                    />
                  )}
                />
              )} */}
            </Grid>

            {/* <Grid item xs={6}>
              {!!boxes?.length && (
                <Autocomplete
                  noOptionsText="Sem opções"
                  loading={isSubmitting}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => option.name}
                  id="combo-box-boxType"
                  options={boxes}
                  onChange={(_, selectedValue: any | null) => {
                    void setFieldValue(
                      'preftbox.specifications.boxType',
                      selectedValue._id
                    )
                  }}
                  defaultValue={boxes?.find(
                    (box) =>
                      box._id === values?.preftbox?.specifications?.boxType
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Tipo de caixa"
                    />
                  )}
                />
              )}
            </Grid> */}

            <Grid item xs={3}>
              {!!codBoxes?.length && (
                <Autocomplete
                  noOptionsText="Sem opções"
                  loading={isSubmitting}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => option.cod}
                  id="combo-box-codBoxes"
                  options={codBoxes}
                  onChange={(_, selectedValue: any | null) => {
                    void setFieldValue(
                      'preftbox.specifications.codTypebox',
                      selectedValue._id
                    )
                  }}
                  defaultValue={codBoxes?.find(
                    (item) =>
                      item._id === values?.preftbox?.specifications?.codTypebox
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Cod. Caixa"
                      required
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={3}>
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Papel"
                value={
                  getPaper([
                    values?.preftbox?.specifications?.Composicao?.papel1,
                    values?.preftbox?.specifications?.Composicao?.papel2,
                    values?.preftbox?.specifications?.Composicao?.papel3,
                    values?.preftbox?.specifications?.Composicao?.papel4,
                    values?.preftbox?.specifications?.Composicao?.papel5
                  ]) ?? ''
                }
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Tipo de onda"
                value={values?.preftbox?.specifications?.Composicao?.onda ?? ''}
              />
            </Grid>

            <Grid item xs={3}>
              {/* <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Nome Comercial"
                value={
                  values?.preftbox?.specifications?.Composicao?.nomeComercial ??
                  ''
                }
              /> */}
              {!!composicao?.length && (
                <Autocomplete
                  noOptionsText="Sem opções"
                  loading={isSubmitting}
                  isOptionEqualToValue={(option, value) =>
                    option.nomeComercial === value.nomeComercial
                  }
                  getOptionLabel={(option) => option.nomeComercial}
                  options={composicao}
                  onChange={(_, selectedValue: any | null) => {
                    void setFieldValue(
                      'preftbox.specifications.Composicao',
                      selectedValue
                    )
                  }}
                  defaultValue={composicao?.find(
                    (item) =>
                      item.nomeComercial ===
                      values?.preftbox?.specifications?.Composicao
                        ?.nomeComercial
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Nome Comercial"
                      required
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                Medidas internas
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Comprimento"
                value={values.preftbox?.internalMeasures?.diameter}
                onChange={async (e) =>
                  setFieldValue(
                    'preftbox.internalMeasures.diameter',
                    onlyNumbers(e.target.value ?? '')
                  )
                }
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Largura"
                value={values.preftbox?.internalMeasures?.width}
                onChange={async (e) =>
                  setFieldValue(
                    'preftbox.internalMeasures.width',
                    onlyNumbers(e.target.value ?? '')
                  )
                }
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Altura"
                value={values.preftbox?.internalMeasures?.height}
                onChange={async (e) =>
                  setFieldValue(
                    'preftbox.internalMeasures.height',
                    onlyNumbers(e.target.value ?? '')
                  )
                }
                required
              />
            </Grid>

            <Grid item xs={12}>
              {!!fechamentos?.length && !loadingFechamento && (
                <Autocomplete
                  noOptionsText="Sem opções"
                  loading={isSubmitting}
                  isOptionEqualToValue={(option, value) =>
                    option.fechamento === value.fechamento
                  }
                  getOptionLabel={(option) => option.fechamento}
                  id="combo-box-fechamento"
                  options={fechamentos}
                  onChange={(_, selectedValue: any | null) => {
                    void setFieldValue(
                      'preftbox.internalMeasures.Fechamento',
                      selectedValue
                    )
                  }}
                  defaultValue={fechamentos?.find(
                    (item) =>
                      item.fechamento ===
                      values?.preftbox?.internalMeasures?.Fechamento?.fechamento
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Fechamento"
                      required
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Quantidade de impressões"
                value={values?.preftbox.internalMeasures.prints}
                onChange={async (e) =>
                  setFieldValue(
                    'preftbox.internalMeasures.prints',
                    onlyNumbers(e.target.value ?? '')
                  )
                }
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">Un</InputAdornment>
                //   )
                // }}
                required
              />
            </Grid>
            {!!values.preftbox.internalMeasures.prints && (
              <>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography variant="body2">Cor</Typography>

                        <Button
                          type="button"
                          color="primary"
                          onClick={handleAddCor}
                          sx={{ padding: '4px' }}
                        >
                          <Add fontSize="small" />
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {cores?.map((cor, i) => (
                          <Box key={i} sx={{ width: 200, display: 'flex' }}>
                            <TextField
                              type="text"
                              variant="standard"
                              value={cor?.value}
                              onChange={(e) =>
                                handleChangeColor(cor?.name, e.target.value)
                              }
                              autoComplete="off"
                              fullWidth
                              size="small"
                              required
                            />
                            <Button
                              type="button"
                              color="error"
                              onClick={handleRemoveCor}
                              sx={{
                                padding: '4px',
                                color: '#ee207a',
                                backgroundColor: 'transparent'
                              }}
                            >
                              <Delete fontSize="small" />
                            </Button>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                id="standard-basic"
                label="Dados Adicionais"
                value={values?.preftbox.extraData}
                onChange={async (e) =>
                  setFieldValue('preftbox.extraData', e.target.value)
                }
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">Un</InputAdornment>
                //   )
                // }}
                multiline
                minRows={3}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                Gramatura Capa Interna
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Amarrado ou arrumado no palete
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={values.preftbox.tied ? 'tied' : 'tiedPallet'}
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    if (value === 'tied') {
                      void setFieldValue('preftbox.tied', true)
                      void setFieldValue('preftbox.tiedPallet', false)
                    } else {
                      void setFieldValue('preftbox.tied', false)
                      void setFieldValue('preftbox.tiedPallet', true)
                    }
                  }}
                >
                  <FormControlLabel
                    value="tied"
                    control={<Radio />}
                    label="Amarrado"
                  />
                  <FormControlLabel
                    value="tiedPallet"
                    control={<Radio />}
                    label="Arrumado no palete"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {values?.preftbox.tied && (
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="standard-basic"
                  label="Pçs/amarrado"
                  value={values.preftbox.piecesOrTied}
                  onChange={async (e) =>
                    setFieldValue(
                      'preftbox.piecesOrTied',
                      onlyNumbers(e.target.value ?? '')
                    )
                  }
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">Un</InputAdornment>
                  //   )
                  // }}
                  required
                />
              </Grid>
            )}

            {values?.preftbox.tiedPallet && (
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="standard-basic"
                  label="Amarrado/Palete"
                  value={values.preftbox.tiedOrPallet}
                  onChange={async (e) =>
                    setFieldValue(
                      'preftbox.tiedOrPallet',
                      onlyNumbers(e.target.value ?? '')
                    )
                  }
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">Un</InputAdornment>
                  //   )
                  // }}
                  required
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Alças
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={values.preftbox.handles ? 'sim' : 'nao'}
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    if (value === 'sim') {
                      void setFieldValue('preftbox.handles', true)
                    } else {
                      void setFieldValue('preftbox.handles', false)
                    }
                  }}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {values?.preftbox.handles && (
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="standard-basic"
                  label="Alças"
                  value={values?.preftbox.handlesValue}
                  onChange={async (e) =>
                    setFieldValue(
                      'preftbox.handlesValue',
                      e.target.value
                      // onlyNumbers(e.target.value ?? '')
                    )
                  }
                  required
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Furo
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={values.preftbox.hole ? 'sim' : 'nao'}
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    if (value === 'sim') {
                      void setFieldValue('preftbox.hole', true)
                    } else {
                      void setFieldValue('preftbox.hole', false)
                    }
                  }}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {values?.preftbox.hole && (
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="standard-basic"
                  label="Furo"
                  value={values?.preftbox.holeValue}
                  onChange={async (e) =>
                    setFieldValue(
                      'preftbox.holeValue',
                      e.target.value
                      // onlyNumbers(e.target.value ?? '')
                    )
                  }
                  required
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography component="span" variant="body1">
                Informações adicionais
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Palete reforçado
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={
                    values?.preftbox.reinforcedPallet ? 'sim' : 'nao'
                  }
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    if (value === 'sim') {
                      void setFieldValue('preftbox.reinforcedPallet', true)
                    } else {
                      void setFieldValue('preftbox.reinforcedPallet', false)
                    }
                  }}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {values?.preftbox.reinforcedPallet && (
              <>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    fullWidth
                    id="standard-basic"
                    label="Quantidade de fita"
                    value={values?.preftbox.amountTape}
                    onChange={async (e) =>
                      setFieldValue(
                        'preftbox.amountTape',
                        onlyNumbers(e.target.value ?? '')
                      )
                    }
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">Un</InputAdornment>
                    //   )
                    // }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    fullWidth
                    id="standard-basic"
                    label="Quantidade lastro do palete"
                    value={values?.preftbox.amountPalletBallast}
                    onChange={async (e) =>
                      setFieldValue(
                        'preftbox.amountPalletBallast',
                        onlyNumbers(e.target.value ?? '')
                      )
                    }
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">Un</InputAdornment>
                    //   )
                    // }}
                    required
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Paletizado/Batido
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={
                    values?.preftbox.beatenOrPalletized ? 'sim' : 'nao'
                  }
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    if (value === 'sim') {
                      void setFieldValue('preftbox.beatenOrPalletized', true)
                    } else {
                      void setFieldValue('preftbox.beatenOrPalletized', false)
                    }
                  }}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Paletizado"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Batido"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Deseja uma amostra do projeto?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={values?.preftbox.needSample ? 'sim' : 'nao'}
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    if (value === 'sim') {
                      void setFieldValue('preftbox.needSample', true)
                    } else {
                      void setFieldValue('preftbox.needSample', false)
                    }
                  }}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {values?.preftbox.needSample && (
              <>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    fullWidth
                    id="standard-basic"
                    label="Quantidade de amostras"
                    value={values.preftbox.amountSample}
                    onChange={async (e) =>
                      setFieldValue(
                        'preftbox.amountSample',
                        onlyNumbers(
                          Number(e.target.value) <= 10
                            ? e.target.value
                            : values.preftbox.amountSample ?? ''
                        )
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Un</InputAdornment>
                      )
                    }}
                    helperText="No máximo 10"
                    required
                  />
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Deseja obter um laudo para NF?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={values?.preftbox.reportNF ? 'sim' : 'nao'}
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    if (value === 'sim') {
                      void setFieldValue('preftbox.reportNF', true)
                    } else {
                      void setFieldValue('preftbox.reportNF', false)
                    }
                  }}
                >
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <UploadDocsPreFT
                urls={[]}
                disabled={isSubmitting}
                onChange={async (files) => handleFiles(files)}
              />
            </Grid>
            {/*
            <Grid item xs={12}>
              <Typography>Anexar arquivo</Typography>
            </Grid>
            {selectedFile && (
              <Grid item xs={12}>
                <Typography
                  component="a"
                  href={URL.createObjectURL(selectedFile)}
                  target="_blank"
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                >
                  Visualizar arquivo
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  marginRight: '30px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1px',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        borderRadius: '8px',
                        border: false ? '1px solid red' : '0px solid red'
                      }}
                    >
                      <StyledDropZone
                        children={
                          selectedFile
                            ? '✔ Arquivo anexado!'
                            : 'Selecione ou arraste seu arquivo aqui'
                        }
                        onDrop={(file, text) => handleSelectFile(file)}
                      />
                    </div>
                    <ImportantView
                      style={{ marginTop: '20px' }}
                      title="Importante"
                      content="Tamanho máximo 2mb"
                    />
                  </div>
                </div>
              </div>
            </Grid> */}
          </Grid>
        </DialogContent>

        <DialogActions sx={{ padding: 3 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseModal}
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Solicitar projeto
          </LoadingButton>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}
