import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Box, Stack, TextField, Typography } from '@mui/material'
import Button from 'components/form/Button'
import { ptBR } from 'date-fns/locale'
import React, { useState } from 'react'

interface Props {
  title: string
  selectedDate: (date: any) => void
}

export const Header: React.FC<Props> = ({ title, selectedDate }) => {
  const [date, setDate] = useState<any | null>(null)

  const handleSelectDate = () => {
    if (!date) {
      document.getElementById('select-year')?.focus()
      return
    }

    selectedDate(date)
  }
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography component="strong" variant="body1" fontWeight="bold">
        {title}
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <DatePicker
            views={['year', 'month']}
            label="Ano e Mês"
            inputFormat="MM/yyyy"
            value={date}
            onChange={(value) => {
              console.log('date', value)
              setDate(value)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="select-year"
                autoFocus
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <Box paddingRight={1} marginLeft="-40px">
                      {params?.InputProps?.endAdornment}
                    </Box>
                  )
                }}
                className="w-100"
                variant="standard"
                required
              />
            )}
          />
        </LocalizationProvider>

        <Button type="button" mold="primary" onClick={handleSelectDate}>
          Gerar
        </Button>
      </Stack>
    </Stack>
  )
}
