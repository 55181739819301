import styled from 'styled-components'

const ButtonHeader = styled.button`
  borderradius: 2px;
  border: none;
  background: #4060e6;
  color: #fff;
  padding: 4px;
  width: 32px;
  height: 32px;
  line-height: 1;
`

const BodyMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(36px, auto));
  place-items: center;
  padding-left: 5px;
  padding-right: 5px;

  & img {
    padding: 5px 10px;
  }

  & img:hover {
    background-color: #f5f5f5;
  }
`

export { ButtonHeader, BodyMenu }
