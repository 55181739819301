/* eslint-disable operator-linebreak */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useState } from 'react'
import { AccordionDetails, AccordionSummary, Box, Chip, CircularProgress, FormControlLabel, FormGroup, Grid, Stack, Step, StepIconProps, StepLabel, Stepper, Switch, TextField, Typography, Button as MuiButton } from '@mui/material'
import { Form, Formik } from 'formik'
import MuiAccordion from '@mui/material/Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BoxAlingCheckbox, ButtonRow, CardContentChieldren, CardFather, DivAlingCard, DivAlingItensCard, DivRow, DivStepper, LabelCheckbox, LabelText, RetangleView } from '../styles/dialogMap.styles'
import IconAndTitleView from 'components/view/IconAndTitleView'
import moment from 'moment'
import CustomButton from 'components/buttons/CustomButton'
import { onlyNumbers } from 'helpers/stringHelper'
import AddIcon from '@mui/icons-material/Add'
import { httpClient } from 'config/httpClient'
import { toast } from 'react-toastify'
import { QontoConnector, QontoStepIconRoot } from '../styles/stepper.styles'
import { getPalletsOrderProduction } from 'services/report/DeliveryMapService'

interface AccordionProps {
  title: string
  status?: string
  isExpanded: boolean
  onToggleExpanded?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => void
  children: ReactNode
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  status,
  isExpanded,
  onToggleExpanded,
  children
}) => {
  return (
    <MuiAccordion expanded={isExpanded} onChange={onToggleExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${title}-content`}
        id={`panel${title}-header`}
      >
        <Box display="flex" alignItems="center">
          <Typography component="strong" fontWeight="bold">
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  )
}

interface Props {
  state: any
  orders: any
}

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-circle-after" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

const FormInitalValues: React.FC<Props> = (props) => {
  const [toogleTab, setToogleTab] = useState<boolean>(true)
  const [toogleTab2, setTtoogleTab2] = useState<boolean>(true)
  const [expanded, setExpanded] = useState<any | false>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [palletsData, setPalletsData] = useState<any>()
  const [ordemVazia] = useState<any[]>([
    {
      requestedAmount: 0,
      deliveredAmount: 0,
      balance: 0
    }
  ])

  const { state, orders } = props

  const expandedHandle = (panel: any, item: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
    handlePallets(item)
  }

  const handlePallets = async (data: any) => {
    try {
      if (state.row._id) {
        const response = await getPalletsOrderProduction(state.row._id
          , String(data.production_order))
        setPalletsData(response)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const factoryArray = () => ({
    requestedAmount: 0,
    deliveredAmount: 0,
    balance: 0
  })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        productionFlow: [],
        exit: false,
        reposition: false,
        billingDate: new Date(),
        netWeight: '',
        dispatchedWeight: 0,
        requesteWeight: 0,
        board: '',
        deliveryAmountConfirmation: '',
        deliveryTime: '',
        exitTime: '',
        startTime: '',
        observation: '',
        observationPCE: '',
        deliveryPrevision: '',
        balance: ordemVazia,
        reference: '',
        production_order: '',
        invoiced_quantity: '',
        sales_order: '',
        billing_date_ov: '',
        nf: '',
        pallet_quantity: palletsData,
        entry_into_stock: '',
        index: '',
        requested_quantity: '',
        idFT: state.row?._id
      }}
      // validationSchema={validationItemDelivery}
      onSubmit={async values => {
        console.log('values', values)
        httpClient.post('/itemDeliveryMap', values).then(() => {
          toast.success('Dados registrado com sucesso')
          location.reload()
        }).catch((error: any) => {
          console.log('Error: ', error)
          toast.error(error?.response?.data?.message ?? 'Erro ao Registrar Dados')
        }).finally(() => {
          setLoading(false)
        })
      }}
    >
      {({ errors, touched, handleChange, setValues, handleBlur, handleSubmit, values, setFieldValue }) => (
        <Form>
          {orders.map((item, index) => (
            <Box style={{ marginTop: 3 }}>
              <Accordion
                title={`Ordem: ${item.ordemProducao}`}
                isExpanded={expanded === index}
                onToggleExpanded={expandedHandle(index, item)}
              >
                <AccordionDetails>
                  <DivAlingCard>
                    <RetangleView />
                    <CardFather>
                      <CardContentChieldren>
                        <DivAlingItensCard>
                          <IconAndTitleView icon='assignment_outline' title={`FT ${(state.row?._id ?? '').substring(0, 8)}`} />
                          <IconAndTitleView icon='content_paste' title={`Produto: ${state.row?.preFT?.type ?? ''}`} />
                          <IconAndTitleView icon='person_outline' title={`Para: ${state.row?.preFT?.client.name ?? ''}`} />
                          <IconAndTitleView icon='person_outline' title={`Faturado: ${item?.quantidadeFaturada}`} />
                          <IconAndTitleView icon='calendar_today' title={moment(item?.dataEntregaOV).format('DD/MM/YYYY')} />
                          <IconAndTitleView icon='schedule' title={moment(item?.dataEntregaOV).format('HH:mm')} />
                          <IconAndTitleView icon='assignment_outline' title={`NF ${item?.nf}`} />
                        </DivAlingItensCard>
                      </CardContentChieldren>
                    </CardFather>
                  </DivAlingCard>
                  <LabelText>Mapa de entrega</LabelText>
                  <h5 style={{ marginTop: '5px' }}>Fluxo de Produção</h5>
                  <DivStepper>
                    {!item.productionFlow.length
                      ?
                      <h4>Essa ordem não possui fluxo</h4>
                      :
                      <Box sx={{ width: '40%', marginLeft: '-45px' }}>
                        <Stack sx={{ width: '100%' }} spacing={4}>
                          <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
                            {item.productionFlow.split('/').map((label) => (
                              <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </Stack>
                      </Box>
                    }
                  </DivStepper>
                  <BoxAlingCheckbox>
                    <Grid container columns={16}>
                      <Grid item xs={3}>
                        <LabelCheckbox>Confirmação de saida</LabelCheckbox>
                        <FormGroup>
                          <FormControlLabel control={<Switch onClick={() => setToogleTab(!values.exit)} checked={values.exit} onChange={handleChange('exit')} />} label={values.exit ? 'Sim' : 'Inativo'} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={2}>
                        <LabelCheckbox>Reposição?</LabelCheckbox>
                        <FormGroup>
                          <FormControlLabel control={<Switch onClick={() => setTtoogleTab2(!values.reposition)} checked={values.reposition} onChange={handleChange('reposition')} />} label={values.reposition ? 'Sim' : 'Inativo'} />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={2}>
                        <LabelCheckbox>Lote Piloto?</LabelCheckbox>
                        <FormGroup>
                          <p style={{ marginTop: '5px' }}>{state.row?.preFT?.client.pilotLot ? 'Sim' : 'Não'}</p>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </BoxAlingCheckbox>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Dados da FT</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="FT"
                            variant="standard"
                            value={state.row?._id ?? ''}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Cliente"
                            variant="standard"
                            value={state.row?.preFT?.client.name ?? ''}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Rêferencia"
                            variant="standard"
                            value={item?.reference}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Ordem de produção"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={item?.ordemProducao}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Ordem de venda"
                            variant="standard"
                            value={'Null'}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </DivRow>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Faturamento antecipado</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
                        <Grid item xs={4}>
                          <TextField
                            error={!!(errors.billingDate && touched.billingDate)}
                            fullWidth
                            id=""
                            label="Data de faturamento"
                            variant="standard"
                            type='date'
                            InputLabelProps={{ shrink: true }}
                            value={moment(values.billingDate).format('YYYY-MM-DD')}
                            onChange={handleChange('billingDate')}
                            helperText={errors.billingDate && touched.billingDate ? 'Campo Obrigatorio' : ''}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id=""
                            label="Hórario de partida"
                            variant="standard"
                            type='time'
                            InputLabelProps={{ shrink: true }}
                            value={values.deliveryPrevision}
                            onChange={handleChange('deliveryPrevision')}
                          />
                        </Grid>
                      </Grid>
                    </DivRow>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Saldo de ordem</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Quantidade solicitada na OV"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={item?.quantidadeSolicitada}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Quantidade faturada"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={item.quantidadeFaturada}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Data do faturamento"
                            variant="standard"
                            value={moment(item?.dataEntregaOV).format('DD/MM/YYYY')}
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="NF"
                            autoComplete='off'
                            variant="standard"
                            value={item.nf}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={4} style={{ marginBottom: '30px' }}>
                            {values.balance?.map((saldoOrdem, index) => (
                              <>
                                <Grid item xs={4}>
                                  <TextField
                                    fullWidth
                                    type={'number'}
                                    id="standard-basic"
                                    label="Quantidade solicitada"
                                    variant="standard"
                                    autoComplete='off'
                                    value={saldoOrdem.requestedAmount}
                                    onChange={handleChange(`balance.${index}.requestedAmount`)} />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    fullWidth
                                    type={'number'}
                                    id="standard-basic"
                                    label="Quantidade entregue"
                                    variant="standard"
                                    autoComplete='off'
                                    value={saldoOrdem.deliveredAmount}
                                    onChange={(e) => {
                                      const qtdSoli = saldoOrdem.requestedAmount
                                      const newValue = onlyNumbers(e.target.value)
                                      setFieldValue(`balance.${index}.deliveredAmount`, newValue ? Math.round(Number(newValue)) : '')
                                      const sum = Number(qtdSoli) - Number(newValue)
                                      setFieldValue(`balance.${index}.balance`, sum)
                                    }} />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    fullWidth
                                    id="standard-basic"
                                    label="Saldo"
                                    autoComplete='off'
                                    variant="standard"
                                    value={saldoOrdem.balance}
                                    disabled
                                  />
                                </Grid>
                              </>
                            ))}
                          </Grid>
                          <Grid item xs={6}>
                            <MuiButton
                              variant="outlined"
                              size="large"
                              startIcon={<AddIcon />}
                              onClick={() => setFieldValue('balance', [...values.balance, factoryArray()])}
                              sx={{
                                border: 'none',
                                textTransform: 'inherit',
                                boxShadow: '0px 3px 6px #00000029',
                                color: '#8A8A8A',
                                '&:hover': {
                                  border: 'none'
                                }
                              }}
                            >
                              Nova solicitação de quantidade
                            </MuiButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </DivRow>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Outras informções</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Placa"
                            variant="standard"
                            autoComplete='off'
                            value={values.board}
                            onChange={handleChange('board')}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Confirmação de quantidade envida"
                            variant="standard"
                            autoComplete='off'
                            value={values.deliveryAmountConfirmation}
                            onChange={handleChange('deliveryAmountConfirmation')}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Paletizado/Batido"
                            variant="standard"
                            autoComplete='off'
                            value={''}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Quantidade de palete"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={values.pallet_quantity}
                            autoComplete='off'
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Entrada no estoque"
                            variant="standard"
                            value={values.entry_into_stock}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Peso líquido"
                            variant="standard"
                            autoComplete='off'
                            type={'number'}
                            value={values.netWeight}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              const fat = item.quantidadeFaturada
                              const newValue = onlyNumbers(e.target.value)
                              setFieldValue('netWeight', newValue ? Math.round(Number(newValue)) : '')
                              const sum = Number(fat) * Number(newValue)
                              setFieldValue('dispatchedWeight', sum)
                              const soli = Number(values.balance[0].requestedAmount) * Number(newValue)
                              setFieldValue('requesteWeight', soli)
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type={'number'}
                            id="standard-basic"
                            label="Peso solicitado (kg)"
                            variant="standard"
                            autoComplete='off'
                            value={values.requesteWeight}
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Peso expedido (Kg)"
                            autoComplete='off'
                            value={isNaN(values.dispatchedWeight) ? 0 : values.dispatchedWeight}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Pallets"
                            autoComplete='off'
                            variant="standard"
                            // InputLabelProps={{ shrink: true }}
                            // value={values.pallet_quantity}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Fórmula"
                            autoComplete='off'
                            variant="standard"
                            value={'Null'}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </DivRow>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Horários</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid container spacing={3} mt={0} style={{ width: '800px' }}>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id=""
                            type='time'
                            label="Horario de entrega"
                            variant="standard"
                            value={values.deliveryTime}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange('deliveryTime')}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            type='time'
                            label="Hórarrio de saída"
                            variant="standard"
                            value={values.exitTime}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange('exitTime')}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            type='time'
                            label="Hórarrio de partida"
                            variant="standard"
                            value={values.startTime}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange('startTime')}
                          />
                        </Grid>
                      </Grid>
                    </DivRow>
                    <div>
                      <Grid container spacing={3} mt={0} style={{ width: '100%' }}>
                        <Grid item xs={12}>
                          <TextField
                            className="w-100"
                            id="outlined-multiline-static"
                            label="Observações"
                            fullWidth
                            multiline
                            autoComplete='off'
                            rows={3}
                            InputLabelProps={{ shrink: true }}
                            value={values.observation}
                            onChange={handleChange('observation')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className="w-100"
                            id="outlined-multiline-static"
                            label="Observações PCE/Expedição"
                            fullWidth
                            multiline
                            autoComplete='off'
                            rows={3}
                            InputLabelProps={{ shrink: true }}
                            value={values.observationPCE}
                            onChange={handleChange('observationPCE')}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <ButtonRow style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: 30 }}>
                    <CustomButton
                      mold="primary"
                      type="submit"
                      style={{ marginRight: '21px' }}
                      loading={loading}
                      disabled={loading}
                      onClick={() => {
                        setFieldValue('nf', item?.nf)
                        setFieldValue('index', item?.index)
                        setFieldValue('production_order', item?.ordemProducao)
                        setFieldValue('invoiced_quantity', item?.quantidadeFaturada)
                        setFieldValue('requested_quantity', item?.quantidadeSolicitada)
                        setFieldValue('billing_date_ov', item?.dataEntregaOV)
                        setFieldValue('productionFlow', item?.productionFlow.split('/'))
                      }}
                    >
                      Salvar
                    </CustomButton>
                  </ButtonRow>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Form>
      )}
    </Formik>
  )
}

export default FormInitalValues
