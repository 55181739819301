import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import AsyncStorage from '@react-native-community/async-storage'
import { combineReducers } from 'redux'
import { userReducer } from '../../pages/user/slices/user.slice'
import { homeReducer } from '../../pages/home/slices/home.slice'
import { loginReducer } from '../../pages/login/slices/login.slice'
import { userFormReducer } from '../../pages/userForm/slices/userForm.slice'

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['user', 'login', 'home']
}

const reducers = combineReducers({
  user: userReducer,
  home: homeReducer,
  login: loginReducer,
  userForm: userFormReducer
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store: any = configureStore({
  reducer: persistedReducer
})

const persistor = persistStore(store)

export {
  store,
  persistor
}
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
