/* eslint-disable space-before-blocks */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Icon,
  IconButton,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useQuery } from 'react-query'
import { getReportConsumo, getReportContaContabil } from 'services/report/ReportBilling'
import { contaSalario, contaDespesa, dimensao } from 'constants/constConsumo'
import { toBRL } from 'utils/formatString'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

interface Props {
  date: string
  req: any
}

function createDataObra(
  col1,
  col2,
  col3,
  col4,
  col5,
  col6,
  col7Param
) {
  const col7Data = col7Param.map((item) => ({
    conta: 0,
    desc: item.describeAccount,
    emp: '',
    porc: '',
    lanca: 0,
    prev: 0,
    result: 0
  }))

  return {
    col1,
    col2,
    col3,
    col4,
    col5,
    col6,
    col7: col7Data
  }
}

function Row(props: { row: ReturnType<typeof createDataObra>, mongo: any, date: any }) {
  const { row, mongo, date } = props
  let fragment: any
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = React.useState(false)
  const [totalPrev, setTotalPrev] = useState(0)
  const [totalReal, setTotalReal] = useState(0)
  const [dataReq1, setDataReq1] = useState<any>([])
  const [backgroundColor, setBackgroundColor] = React.useState('white')
  const toggleBackgroundColor = () => {
    setBackgroundColor((prevColor) => prevColor === '#4060E3' ? 'white' : '#4060E3')
  }
  console.log('Row => row', row)
  console.log('Row => mongo', mongo)
  const fetchReportContaContabil = async (date, selectedCodes) => {
    return await getReportContaContabil(date, selectedCodes)
  }
  const handleQueryError = (error) => {
    console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamente', error)
  }
  const reqContabil = useQuery(['conta-report', date, mongo], async () => {
    const selectedCodes = mongo?.items?.map((code) => code?.account) || []
    return await fetchReportContaContabil(date, selectedCodes)
  }, {
    onSuccess: (data) => {
      console.log('TablePMBody => reqContabil =>', data)
      setDataReq1(data)
    },
    onError: handleQueryError,
    refetchOnWindowFocus: false
  })

  const allQueriesSuccess = (reqContabil.isSuccess && !reqContabil.isError && !reqContabil.isFetching)
  const isLoadingAnyQuery = (reqContabil.isLoading || reqContabil.isFetching)

  if (isLoadingAnyQuery) {
    fragment = (
      <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
          style={{
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          {loading
? (
            <>
              <p style={{ color: 'blue', marginTop: '8px', textAlign: 'center' }}>Carregando... {progress}%</p>
            </>
          )
: (
          (reqContabil === null || reqContabil === undefined)
? (
            <p style={{ color: 'red', marginTop: '8px', textAlign: 'center' }}>
              Não houve resposta do sistema LN. Inconsistências técnicas na comunicação no momento.
            </p>
          )
: (
            <p style={{ textAlign: 'center' }}>Requisição bem-sucedida!</p>
          )
)
        }
      </Box>
    )
  }

  if (reqContabil.isError) {
    fragment = (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }

  if (allQueriesSuccess) {
    fragment = (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, width: '100%' }} style={{ backgroundColor }} >
          <StyledTableCell>
            <IconButton
              style={{ fontWeight: 'bold', fontSize: 'larger', color: backgroundColor === '#4060E3' ? 'white' : 'black' }}
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open)
                toggleBackgroundColor()
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: 'larger', color: backgroundColor === '#4060E3' ? 'white' : 'black' }}>
            {row.col1}
          </StyledTableCell>
          <StyledTableCell align="center">{row.col2}</StyledTableCell>
          <StyledTableCell align="center">{row.col3}</StyledTableCell>
          <StyledTableCell align="center">{row.col4}</StyledTableCell>
          <StyledTableCell align="center">{row.col5}</StyledTableCell>
          {
          open
          ? (
            <>
                <StyledTableCell align="center">{row.col4}</StyledTableCell>
                <StyledTableCell align="center">{row.col5}</StyledTableCell>

            </>
          )
          : (
            <>
              <StyledTableCell align="center" style={{ fontWeight: 'bold', fontSize: 'larger' }}>
              {totalPrev.toLocaleString('pt-br')}
              </StyledTableCell>

              <StyledTableCell align="center" style={{ fontWeight: 'bold', fontSize: 'larger' }}>
              {totalReal.toLocaleString('pt-br')}
              </StyledTableCell>
            </>
          )
          }
        </TableRow>

        <TableRow>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell >{mongo?.type === 'INFLAÇÃO' ? null : 'CONTA'}</StyledTableCell >
                      <StyledTableCell >{mongo?.type === 'INFLAÇÃO' ? 'MATÉRIA PRIMA APLICADA' : 'DESC. CONTAS CONTÁBEIS'}</StyledTableCell >
                      <StyledTableCell > </StyledTableCell >
                      <StyledTableCell style={{ textAlign: 'center' }}>
                      <span>Previsão de Inflação</span>
                        <Chip
                          label={
                            <>
                              {mongo?.percentage}%
                            </>
                          }
                          variant="outlined"
                          style={{
                            color: '#ffcf01',
                            background: '#fffbf0',
                            borderColor: '#ffcf01',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: 'center' }}>
                        <span style={{ color: 'red' }}>Lançar</span>
                        <Chip
                          label={
                            <>
                              {toBRL(mongo?.launchValue)}
                            </>
                          }
                          variant="outlined"
                          style={{
                            color: '#ff414b',
                            background: '#ffd8d8',
                            borderColor: '#ff414b',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <span>PREVISTO</span>
                          <span>Valores</span>
                        </div>
                      </StyledTableCell >
                      <StyledTableCell >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <span>REALIZADO</span>
                          <span>Valores</span>
                        </div>
                      </StyledTableCell >
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mongo?.items?.length === 0 || mongo?.items === null || mongo?.items === undefined
  ? (
                      <TableRow>
                        <StyledTableCell colSpan={8}>
                          <Typography variant="body1">Nenhum item cadastrado.</Typography>
                        </StyledTableCell>
                      </TableRow>
                    )
  : (
                      <>
                        {mongo?.items?.map((item, index) => (
                          <StyledTableRow key={item?._id}>
                            <StyledTableCell component="th" scope="row">
                              {item?.account === '0' ? null : item?.account}
                            </StyledTableCell>
                            <StyledTableCell>{item?.describeAccount}</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="right">{item?.launchPercentage.toFixed(2).toLocaleString('pt-br')}%</StyledTableCell>
                            <StyledTableCell align="right">
                            {(
                                Math.floor(
                                  (Number(item?.launchPercentage) / 100) * Number(mongo?.launchValue) +
                                    (((Number(item?.launchPercentage) / 100) * Number(mongo?.launchValue)) *
                                      (Number(mongo?.percentage) / 100))
                                )
                              ).toLocaleString('pt-br')
                            }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {(
                                isNaN(Math.floor(dataReq1?.data?.response?.find((elem) => elem.conta === item?.account)?.result))
                                ? 0
                                : Math.floor(dataReq1?.data?.response?.find((elem) => elem.conta === item?.account)?.result)
                              ).toLocaleString('pt-br')
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                        <StyledTableRow style={{
                                color: '#414cff',
                                background: '#d8ffff',
                                borderColor: '#414cff',
                                fontWeight: 'bold',
                                fontSize: '14px'
                              }}>
                          <StyledTableCell component="th" scope="row" colSpan={4} />
                          <StyledTableCell component="th" scope="row" align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            TOTAL
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="right" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                          {totalPrev.toLocaleString('pt-br')}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="right" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                          {totalReal.toLocaleString('pt-br')}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell >
        </TableRow>
      </React.Fragment>
    )
  }

  useEffect(() => {
    let sumP = 0
    let sumR = 0

    mongo?.items.forEach((item) => {
      const calculatedValue = Math.floor(
        (Number(item?.launchPercentage) / 100) * Number(mongo?.launchValue) +
        (((Number(item?.launchPercentage) / 100) * Number(mongo?.launchValue)) * (Number(mongo?.percentage) / 100))
      )
      const result = dataReq1?.data?.response?.find((elem) => elem.conta === item.account)?.result
      const valueToAdd = isNaN(result) ? 0 : Number(result)
      sumR += valueToAdd
      sumP += calculatedValue
    })
    setTotalPrev(Math.floor(sumP))
    setTotalReal(Math.floor(sumR))

    if (allQueriesSuccess) {
      setLoading(false)
      setProgress(100)
      return
    }

    if (progress < 100) {
      const interval = setInterval(() => {
        setProgress(prevProgress => {
          const newProgress = prevProgress + 5
          if (newProgress >= 100) {
            setLoading(false)
            clearInterval(interval)
            return 100
          }
          return newProgress
        })
      }, 3000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [progress, allQueriesSuccess])

  return fragment
}

const TableAdmBody: React.FC<Props> = (props) => {
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)

  const reqConsumo = useQuery(['consumo-report', props.date], async () => {
    return await getReportConsumo(props.date, contaSalario, contaDespesa, dimensao)
  }, {
    onSuccess: (data) => {
      console.log('TablePMBody=>req3=>getReportConsumo =>', data)
    },
    onError: (error) => {
      console.log(
        'Ops! Algo deu errado, Verifique sua conexão e tente novamnete',
        error
      )
    },
    refetchOnWindowFocus: false
  })
  const isLoadingAnyQuery = (reqConsumo.isLoading || reqConsumo.isFetching)
  const allQueriesSuccess = (
    reqConsumo.isSuccess &&
    !reqConsumo.isError &&
    !reqConsumo.isFetching
  )

  let result: any

  if (isLoadingAnyQuery) {
    result = (
      <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          style={{
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          {loading
? (
            <>
              <CircularProgress variant="determinate" value={progress} />
              <strong style={{ color: 'green', marginTop: '8px' }}>Consultando LN {progress}%</strong>
            </>
          )
: (
          (reqConsumo === null || reqConsumo === undefined)
? (
            <p style={{ color: 'red', marginTop: '8px' }}>
              Não houve resposta do sistema LN. Inconsistências técnicas na comunicação no momento.
            </p>
          )
: (
            <p>Requisição bem-sucedida!</p>
          )
)
        }
      </Box>
    )
  }

  if (reqConsumo.isError) {
    result = (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }

  if (allQueriesSuccess) {
    console.log('materias de papel =>', props.req)

    const rows = props.req.map(category => createDataObra(category.type, '', '', '', '', '', category.items))

    console.log('itens de categoria =>', rows)
    const combinedData = props.req.map((category, index) => ({
      ...category,
      rows: rows[index]
    }))
    console.log('combinedData', combinedData)

    result = (
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: '100%' }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ fontWeight: 'bold', fontSize: 'larger', wordBreak: 'break-word' }}>
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell ></StyledTableCell >
                <StyledTableCell align="center" >Previsto</StyledTableCell >
                <StyledTableCell align="center" >Realizado</StyledTableCell >
              </StyledTableRow>
            </TableHead>
            <TableBody>
            {combinedData.map((bloco) => (
              <Row key={bloco._id} row={bloco.rows} mongo={bloco} date={props.date}/>
            ))}
              <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ fontWeight: 'bold', fontSize: 'larger', wordBreak: 'break-word' }}>
                <StyledTableCell style={{ wordBreak: 'break-word' }}></StyledTableCell >
                <StyledTableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                TOTAL DESPESAS
                </StyledTableCell >
                <StyledTableCell align="right" style={{ wordBreak: 'break-word' }}></StyledTableCell >
                <StyledTableCell align="right"></StyledTableCell >
                <StyledTableCell align="right" style={{ wordBreak: 'break-word' }}></StyledTableCell >
                <StyledTableCell align="right"></StyledTableCell >
                <StyledTableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>{Math.floor(0).toLocaleString('pt-br')}</StyledTableCell>
                <StyledTableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px', wordBreak: 'break-word' }}>{Math.floor(0).toLocaleString('pt-br')}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }

  useEffect(() => {
    if (allQueriesSuccess) {
      setLoading(false)
      setProgress(100)
      return
    }

    if (progress < 100) {
      const interval = setInterval(() => {
        setProgress(prevProgress => {
          const newProgress = prevProgress + 5
          if (newProgress >= 100) {
            setLoading(false)
            clearInterval(interval)
            return 100
          }
          return newProgress
        })
      }, 3000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [progress, allQueriesSuccess])

  return result
}

export default TableAdmBody
