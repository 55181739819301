import React, { useCallback } from 'react'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'

interface Props {
  label?: string
  onSearch?: (value: string) => void
  onClear?: () => void
}

const InputSearch: React.FC<Props> = ({
  label,
  onSearch = () => {},
  onClear = () => {}
}) => {
  const debounce = useCallback((func: any, wait: number): any => {
    let timeout: NodeJS.Timeout

    return function executedFunction(...args: number[]) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }, [])

  const returnedFunction = debounce((event: any) => {
    if (event?.target?.value) {
      onSearch(event.target.value)
    } else {
      console.log('CLEAR')
      onClear()
    }
  }, 400)

  return (
    <>
      <FormControl variant="standard" className="w-100">
        {label && <InputLabel htmlFor="field-search">{label}</InputLabel>}
        <Input
          id="field-search"
          autoComplete="off"
          type="text"
          defaultValue=""
          onKeyUp={(event) => returnedFunction(event)}
          onKeyPress={(event) => returnedFunction(event)}
          // onClick={(event) => returnedFunction(event)}
          endAdornment={
            <InputAdornment position="end">
              <SearchOutlined />
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  )
}

export default InputSearch
