import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material'
import styled from 'styled-components'

interface modalView {
  open: boolean
  close: () => void
  content?: any
  allowBack?: boolean
  style?: any
  widthRoot?: number
}

const GenericDialog = (props: modalView) => {
  const { open, close, content } = props
  const closeModal = () => close()

  const DialogStyled = styled(Dialog)`
  .MuiDialog-paper {
    width: ${props.widthRoot ? props.widthRoot : '600'}px;
    max-width: ${props.widthRoot ? 'none' : '600px'};
  }
  `

  return (
    <div style={props.style}>
      <DialogStyled
        open={open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={props.style}>
          {content}
        </DialogContent>
        {
          props.allowBack
            ? <DialogActions>
              <Button style={{
                marginRight: '10px',
                backgroundColor: '#4060E6',
                borderRadius: '2px',
                width: '122px',
                color: 'white'
              }} onClick={closeModal} autoFocus>Voltar</Button>
            </DialogActions>
            : <></>
        }

      </DialogStyled>
    </div>
  )
}

export default GenericDialog
