import React from 'react'
import { SearchOutlined } from '@mui/icons-material'
import { FormControl, Input, InputAdornment } from '@mui/material'

interface Props {
  placeholder?: string
  defaultSearch?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

export const ReportSellerSearch: React.FC<Props> = ({
  placeholder,
  defaultSearch = undefined,
  onChange = () => {}
}) => {
  return (
    <FormControl variant="standard" className="w-100">
      <Input
        id="field-search"
        autoComplete="off"
        placeholder={placeholder}
        type="search"
        value={defaultSearch}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <SearchOutlined />
          </InputAdornment>
        }
      />
    </FormControl>
  )
}
