/* eslint-disable @typescript-eslint/no-unused-vars */
import { Clear, Close, SearchOutlined } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  Box,
  Button as MuiButton,
  CircularProgress,
  FormControl,
  Icon,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { Stack } from '@mui/system'
import Button from 'components/form/Button'
import { Can } from 'components/Permission/Can'
import MoreMenu from 'components/view/MoreMenu'
import { ptBR } from 'date-fns/locale'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getFTsMnemosine } from 'services/ft/FTService'
import { ModalFtProposalClient } from '../components/ModalFtProposalClient'
import { ModalFtSampleClient } from '../components/ModalFtSampleClient'
import { SynchronizeStatusFTByCodFT } from '../components/SynchronizeStatusFTByCodFT'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

function FTMnemosine(props: any) {
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const [page, setPage] = React.useState(0)
  const [search, setSearch] = React.useState('')
  const [date, setDate] = React.useState<string | null>(null)
  const [searchDate, setSearchDate] = React.useState<string>('')

  const [openModalFtProposalClient, setOpenModalFtProposalClient] =
    useState(false)
  const [openModalFtSampleClient, setOpenModalFtSampleClient] = useState(false)
  const [selectFt, setSelectFt] = useState<any | null>(null)
  const [searchValue, setSearchValue] = React.useState('')

  const ftReq = useQuery(
    ['fts-mnemosine', page, search, searchDate],
    async () => {
      return getFTsMnemosine({ page: page + 1, search, date: searchDate ? moment(searchDate).format('YYYY-MM-DD') : '' })
    },
    {
      refetchOnWindowFocus: false
    }
  )
  console.log('FTMnemosine=>ftReq', ftReq)
  const handleSearch = () => {
    setSearch(searchValue)
    setSearchDate(date ?? '')
    ftReq.refetch()
  }

  const handleClearSearch = () => {
    setSearch('')
    setSearchValue('')
    setSearchDate('')
    setDate(null)
    if (inputSearchRef?.current?.value) {
      inputSearchRef.current.value = ''
    }
  }

  const isLoadingAnyQuery = [ftReq].some(
    (query) => query.isLoading || query.isFetching
  )
  const hasErrorInAnyQuery = [ftReq].some(
    (query) => query.isError
  )
  const allQueriesSuccess = (
    ftReq.isSuccess && !ftReq.isError && !ftReq.isFetching
  )

  let result: any

  if (isLoadingAnyQuery) {
    result = (
      <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          style={{
            marginTop: '124px',
            marginRight: '88px'
          }}
        >
          {loading
? (
            <>
              <CircularProgress variant="determinate" value={progress} />
              <p style={{ color: 'green', marginTop: '8px' }}>Aguarde: {progress}%</p>
            </>
          )
: (
          (ftReq === null || ftReq === undefined)
? (
            <p style={{ color: 'red', marginTop: '8px' }}>
              Não houve resposta do sistema. Inconsistências técnicas na comunicação no momento.
            </p>
          )
: (
            <p>Espere um momento, por favor!</p>
          )
)
        }
      </Box>
    )
  }

  if (hasErrorInAnyQuery) {
    result = (
      <Box
        sx={{ display: 'flex' }}
        style={{
          justifyContent: 'center',
          marginTop: '124px',
          marginRight: '88px'
        }}
      >
        <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
        <Icon style={{ marginLeft: '10px' }}>
          signal_wifi_statusbar_connected_no_internet_4
        </Icon>
      </Box>
    )
  }

  if (allQueriesSuccess) {
    result = (
      <div>
        <Stack direction={'row'} spacing={3} sx={{ paddingTop: 2 }}>
          <FormControl variant="standard" className="w-100">
            <InputLabel htmlFor="field-search">Pesquisar</InputLabel>
            <Input
              id="field-search"
              autoComplete="off"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              inputRef={inputSearchRef}
              disabled={ftReq.isFetching}
            />
          </FormControl>

          <Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                views={['year', 'month', 'day']}
                label="Filtrar por data"
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={(value) => {
                  console.log('date', value)
                  setDate(value)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      startAdornment: (
                        <Box paddingRight={2}>
                          {params?.InputProps?.endAdornment}
                        </Box>
                      ),
                      endAdornment: (
                        <Box>
                          {date && (
                            <MuiButton size="small" onClick={() => setDate(null)}>
                              <Close fontSize="small" />
                            </MuiButton>
                          )}
                        </Box>
                      )
                    }}
                    className="w-100"
                    variant="standard"
                    autoComplete="off"
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>

          <Button mold='primary' onClick={handleSearch}>Pesquisar</Button>
          {(!!searchValue?.length ||
                    !!inputSearchRef?.current?.value?.length || date) && (
                    <Button
                      mold="red"
                      type="button"
                      style={{
                        padding: 0,
                        paddingLeft: 8,
                        paddingRight: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 4
                      }}
                      onClick={handleClearSearch}
                      disabled={ftReq.isFetching}
                    >
                      Limpar filtro
                    </Button>
                  )}
        </Stack>

        <TableContainer style={{ marginTop: '30px' }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Código FT</StyledTableCell>
                <StyledTableCell align="left">Código PRÉ FT</StyledTableCell>
                <StyledTableCell align="left">Emitido em</StyledTableCell>
                <StyledTableCell align="left">Nome da Empresa</StyledTableCell>
                <StyledTableCell align="left">Vendedor</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <Can
                  module={MODULES.FT}
                  permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                >
                  <StyledTableCell align="left">Ações</StyledTableCell>
                </Can>
              </TableRow>
            </TableHead>
            <TableBody>
              {ftReq?.data?.data?.fts?.map((row, index) => (
                <StyledTableRow
                  key={index}
                  onClick={() => console.log('row', row)}
                >
                  <StyledTableCell align="left">
                    {row.itemReference}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.preFT?._id}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography component="span" noWrap variant="body2">
                      {moment(row?.createdAt).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.preFT?.client?.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.preFT?.seller?.user?.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row?.status}</StyledTableCell>
                  <Can
                    module={MODULES.FT}
                    permissions={[PERMISSIONS.UPDATE, PERMISSIONS.DELETE]}
                  >
                    <StyledTableCell align="left">
                      <MoreMenu module={MODULES.FT} isValidationPermissions>
                        <SynchronizeStatusFTByCodFT cod={row?.itemReference} />
                      </MoreMenu>
                    </StyledTableCell>
                  </Can>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20]}
            rowsPerPage={20}
            component="div"
            count={ftReq?.data?.data?.total ?? 0}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              console.log(newPage)
              setPage(newPage)
            }}
          />
        </TableContainer>
        {/* {openDialog && <GenericDialog widthRoot={1150} style={{ minHeight: '400px', minWidth: '1100px', maxWidth: 'none' }}
          open={openDialog}
          close={() => setOpenDialog(false)}
          content={<FTAuditBox form={formData} closeDialog={handleCloseDialog} />} />} */}

        {selectFt && (
          <ModalFtProposalClient
            open={openModalFtProposalClient}
            onClose={() => {
              setOpenModalFtProposalClient(false)
              setSelectFt(null)
            }}
            ft={selectFt}
          />
        )}

        {selectFt && (
          <ModalFtSampleClient
            open={openModalFtSampleClient}
            onClose={() => {
              setOpenModalFtSampleClient(false)
              setSelectFt(null)
            }}
            ft={selectFt}
          />
        )}
      </div>
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress < 100) {
            return prevProgress + 1
        } else {
            setLoading(false)
            clearInterval(interval)
            return prevProgress
        }
      })
    }, 1500)

    return () => {
        clearInterval(interval)
    }
  }, [])

  return result
}

export default FTMnemosine
