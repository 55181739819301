import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PageDetails } from '../models/PageDetails'

interface PageState {
  page: PageDetails | null
}

const initialState: PageState = {
  page: {
    name: 'Dashboard',
    description: '',
    icon: 'poll',
    route: 'dashboard',
    cod: 'MD'
  }
}

const homeSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    _changePage (state, action: PayloadAction<any>) {
      state.page = action.payload
    }
  }
})

export const homeReducer = homeSlice.reducer
export const { _changePage } = homeSlice.actions
