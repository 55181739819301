import React from 'react'
import { Filters } from '../Filters'
import { PurchaseOrdersModule } from '../PurchaseOrderModule/PurchaseOrdersModule'
import { SalesOrdersModule } from '../PurchaseOrderModule/SalesOrdersModule'
import { SuspendedModule } from '../PurchaseOrderModule/SuspendedModule'
import { TabPanel } from '../TabPanel'
import { Tabs } from '../Tabs'

const tabs = ['Pedidos de compra', 'Ordens de venda', 'Suspensos']

export const PurchaseOrderContainer: React.FC = () => {
  const [valueTab, setValueTab] = React.useState(0)

  return (
    <>
      <Filters
        handleFilter={(values) => console.log('FILTER PURCHASE ORDER', values)}
      />

      <Tabs
        tabs={tabs}
        value={valueTab}
        onValue={(newValue) => setValueTab(newValue)}
      >
        <TabPanel
          index={0}
          value={valueTab}
          children={<PurchaseOrdersModule />}
        />
        <TabPanel index={1} value={valueTab} children={<SalesOrdersModule />} />
        <TabPanel index={2} value={valueTab} children={<SuspendedModule />} />
      </Tabs>
    </>
  )
}
