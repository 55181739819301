import { ArrowBack } from '@mui/icons-material'
import { Box, Grid, TextField } from '@mui/material'
import Button from 'components/form/Button'
import LightTooltip from 'components/tooltips/LightTooltip'
import { ButtonHeader } from 'components/view/styles/style-header-view'
import { useFormik } from 'formik'
import { onlyNumbers } from 'helpers/stringHelper'
import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postTaxes, putTaxes } from 'services/material/MaterialService'
import { TaxType } from 'types/material/TaxType'
import { validationSchemaTax } from 'types/validation/ValidationTax'

function TaxesForm(props: any) {
  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(postTaxes, {
    onSuccess: data => {
      toast.success('Cadastrado com sucesso')
      props.close()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível cadastrar')
      }
    }
  })

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(putTaxes, {
    onSuccess: data => {
      toast.success('Editar com sucesso')
      props.close()
    },
    onError: (e: any) => {
      if (e.response) {
        toast.error(e.response.data.message)
      } else {
        toast.error('Não foi possível editar')
      }
    }
  })

  const {
    errors,
    touched,
    setFieldValue,
    values,
    handleChange,
    handleSubmit
  } = useFormik({
    onSubmit: (values: TaxType) => {
      if (props.edit) {
        const valuesForm: TaxType = {
          days: values.days,
          tax: values.tax,
          isActive: true,
          _id: props.edit._id
        }
        mutateEdit(valuesForm)
      } else {
        const valuesForm: TaxType = {
          days: values.days,
          tax: values.tax,
          isActive: true
        }
        mutateCreate(valuesForm)
      }
    },
    enableReinitialize: true,
    validationSchema: validationSchemaTax,
    initialValues: {
      days: props.edit?.days ?? 0,
      tax: props.edit?.tax ?? 0
    }
  })

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LightTooltip title='Voltar' placement='top' onClick={() => { props.close() }}>
          <ButtonHeader style={{ justifySelf: 'start' }}>
            <ArrowBack />
          </ButtonHeader>
        </LightTooltip>
        <div style={{ margin: '5px', marginLeft: '10px', color: 'black' }}>Nova taxa financeira</div>
      </div>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginRight: '30px',
            marginTop: '20px'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px'
                }}
              >
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        type='number'
                        value={values.days}
                        // onChange={handleChange('days')}
                        onChange={(event) => { void setFieldValue('days', onlyNumbers(event?.target?.value)) }}
                        error={Boolean(errors.days && touched.days)}
                        helperText={(errors.days && touched.days) ? errors.days : null}
                        id='standard-basic'
                        label='Pagamento em dias'
                        variant='standard'
                        fullWidth
                        style={{ color: '#A8A8A8', marginTop: '16px' }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        type='number'
                        value={values.tax}
                        onChange={handleChange('tax')}
                        error={Boolean(errors.tax && touched.tax)}
                        helperText={(errors.tax && touched.tax) ? errors.tax : null}
                        id='standard-basic'
                        label='Taxa financeira'
                        variant='standard'
                        fullWidth
                        style={{ color: '#A8A8A8', marginTop: '16px' }} />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '30px'
            }}
          >
            <Button
              type='button'
              mold='red'
              onClick={() => { props.close() }}
            >
              CANCELAR
            </Button>
            <Button
              style={{
                marginLeft: '10px'
              }}
              mold='primary'
              type="submit"
              disabled={isLoadingCreate || isLoadingEdit}
            >
              SALVAR
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}

export default TaxesForm
