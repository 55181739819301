/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Clear, PermIdentity, SearchOutlined } from '@mui/icons-material'
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import Button from 'components/form/Button'
import GenericDialog from 'components/genericDialog/GenericDialog'
import { Status } from 'components/Status'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getClientsSalesReport } from 'services/report/SalesReport'
import {
  getStockLN,
  getStockMovimentacaoEntradaLN,
  getStockMovimentacaoSaidaLN
} from 'services/report/Stock'
import { LoadingTable } from './LoadingTable'
import {
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableRow
} from './table.styles'
import { TableActionButtons } from './TableActionButtons'

function a11yProps(index: number) {
  return {
    id: `stock-tab-${index}`,
    'aria-controls': `stock-tabpanel-${index}`
  }
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`stock-tabpanel-${index}`}
      aria-labelledby={`stock-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}
interface Props {
  selectDate?: any | null
}

export const MovementInputTable: React.FC<Props> = ({ selectDate }) => {
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const [select, setSelect] = useState<any>()
  const [page, setPage] = useState<any>(1)
  const [openModal, setOpenModal] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const [dataByMonth, setDataByMonth] = useState<any[]>([])
  const [dataByMonthTotal, setDataByMonthTotal] = useState<any>({
    quantidade: 0,
    valor: 0,
    rskg: 0
  })

  const [dataBySelectedDate, setDataBySelectedDate] = useState<any[]>([])
  const [dataBySelectedDateTotal, setDataBySelectedDateTotal] = useState<any>({
    quantidade: 0,
    valor: 0,
    rskg: 0
  })

  const { data, status, refetch, isFetching } = useQuery(
    'report-estoque-movimentacao-entrada',
    async () =>
      getStockMovimentacaoEntradaLN(
        moment(selectDate).format('YYYY-MM') + '-01',
        `${moment(selectDate).format('YYYY-MM')}-${moment(selectDate)
          .endOf('month')
          .format('DD')}`
      ),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    refetch()
  }, [selectDate])

  const formatDataByMonth = useCallback((items: any[]) => {
    let newData: any[] = []

    const totais = {
      quantidade: 0,
      valor: 0,
      rskg: 0
    }

    for (const item of items) {
      const findItem = newData.find(
        (currentItem) => currentItem?.item === item?.item
      )

      if (findItem) {
        const parseData = newData.map((currentItem) =>
          currentItem.item === item?.item
            ? {
                ...currentItem,
                quantidade:
                  Number(currentItem.quantidade) + Number(item.quantidade),
                valor: Number(currentItem.valor) + Number(item.valor),
                rskg: Number(currentItem.rskg) + Number(item.rskg)
              }
            : currentItem
        )

        newData = parseData
      } else {
        newData.push(item)
      }

      totais.quantidade = totais.quantidade + Number(item.quantidade)
      totais.rskg = totais.rskg + Number(item.rskg)
      totais.valor = totais.valor + Number(item.valor)
    }

    setDataByMonth(newData)
    setDataByMonthTotal(totais)
  }, [])

  const formatDataBySelectDay = useCallback(
    (items: any[]) => {
      setLoading(true)
      setDataBySelectedDate([])
      setDataBySelectedDateTotal({
        quantidade: 0,
        valor: 0,
        rskg: 0
      })

      let newData: any[] = []

      const totais = {
        quantidade: 0,
        valor: 0,
        rskg: 0
      }

      const date = moment(selectDate).format('YYYY-MM-DD')

      for (const item of items) {
        const itemDate = moment(item?.date).format('YYYY-MM-DD')
        if (date === itemDate) {
          const findItem = newData.find(
            (currentItem) => currentItem?.item === item?.item
          )

          if (findItem) {
            const parseData = newData.map((currentItem) =>
              currentItem.item === item?.item
                ? {
                    ...currentItem,
                    quantidade:
                      Number(currentItem.quantidade) + Number(item.quantidade),
                    valor: Number(currentItem.valor) + Number(item.valor),
                    rskg: Number(currentItem.rskg) + Number(item.rskg)
                  }
                : currentItem
            )

            newData = parseData
          } else {
            newData.push(item)
          }

          totais.quantidade = totais.quantidade + Number(item.quantidade)
          totais.rskg = totais.rskg + Number(item.rskg)
          totais.valor = totais.valor + Number(item.valor)
        }
      }

      setDataBySelectedDate(newData)
      setDataBySelectedDateTotal(totais)
      setLoading(false)
    },
    [selectDate]
  )

  useEffect(() => {
    if (data?.result?.length) {
      setPage(0)
      formatDataByMonth(data?.result)
      formatDataBySelectDay(data?.result)
    } else {
      setDataByMonth([])
      setDataByMonth([])
      setDataByMonthTotal({
        quantidade: 0,
        valor: 0,
        rskg: 0
      })
      setDataBySelectedDateTotal({
        quantidade: 0,
        valor: 0,
        rskg: 0
      })
    }
  }, [data, data?.result, selectDate])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(0)
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ marginTop: 3, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Mês"
            {...a11yProps(0)}
            sx={{
              flex: 1,
              '&.Mui-selected': { backgroundColor: '#1976d2', color: '#FFF' }
            }}
          />
          <Tab
            label="Dia"
            {...a11yProps(1)}
            sx={{
              flex: 1,
              '&.Mui-selected': { backgroundColor: '#1976d2', color: '#FFF' }
            }}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableHeadCell align="left">ITENS</StyledTableHeadCell>
                <StyledTableHeadCell align="left">KG</StyledTableHeadCell>
                <StyledTableHeadCell align="left">R$</StyledTableHeadCell>
                <StyledTableHeadCell align="left">R$/KG</StyledTableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!dataByMonth?.length && status !== 'loading' && !isFetching && (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={7}>
                    Nenhum dado encontrado.
                  </StyledTableCell>
                </StyledTableRow>
              )}

              {(status === 'loading' || isFetching) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>
                    <LoadingTable />
                  </StyledTableCell>
                </StyledTableRow>
              )}

              {!!dataByMonth?.length &&
                !isFetching &&
                dataByMonth
                  ?.slice(page * 10, page * 10 + 10)
                  .map((row, index) => (
                    <StyledTableRow key={`${index}-${row?.item}-month`}>
                      <StyledTableCell align="left">
                        <Typography component="span" variant="body2">
                          {row?.item}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography component="span" variant="body2" noWrap>
                          {Number(Number(row?.quantidade)?.toFixed(0))?.toLocaleString('pt-br')}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography component="span" variant="body2" noWrap>
                          R$ {Number(row?.valor ?? 0).toLocaleString('pt-br')}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography component="span" variant="body2" noWrap>
                          R${' '}
                          {row?.quantidade && Number(row?.valor ?? 0)
                            ? (
                                Number(row?.valor ?? 0) /
                                Number(row?.quantidade ?? 1)
                              ).toLocaleString('pt-br')
                            : 0}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>

            {!!dataByMonth?.length && !isFetching && (
              <TableFooter>
                <StyledTableRow>
                  <StyledTableCell align="left">
                    <Typography
                      component="span"
                      variant="body2"
                      fontWeight="bold"
                    >
                      Total
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography
                      component="span"
                      variant="body2"
                      noWrap
                      fontWeight="bold"
                    >
                      {Number(dataByMonthTotal?.quantidade ?? 0)?.toFixed(0)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography
                      component="span"
                      variant="body2"
                      noWrap
                      fontWeight="bold"
                    >
                      R${' '}
                      {Number(dataByMonthTotal?.valor ?? 0).toLocaleString(
                        'pt-br'
                      )}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography
                      component="span"
                      variant="body2"
                      noWrap
                      fontWeight="bold"
                    >
                      R${' '}
                      {dataByMonthTotal?.quantidade &&
                      Number(dataByMonthTotal?.valor ?? 0)
                        ? (
                            Number(dataByMonthTotal?.valor ?? 0) /
                            Number(dataByMonthTotal?.quantidade ?? 1)
                          ).toLocaleString('pt-br')
                        : 0}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter>
            )}
          </Table>

          <TablePagination
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
            component="div"
            count={dataByMonth?.length ?? 0}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        </TableContainer>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableHeadCell align="left">ITENS</StyledTableHeadCell>
                <StyledTableHeadCell align="left">KG</StyledTableHeadCell>
                <StyledTableHeadCell align="left">R$</StyledTableHeadCell>
                <StyledTableHeadCell align="left">R$/KG</StyledTableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!dataBySelectedDate?.length && status !== 'loading' && !loading && (
                <StyledTableRow>
                  <StyledTableCell align="center" colSpan={7}>
                    Nenhum dado encontrado.
                  </StyledTableCell>
                </StyledTableRow>
              )}

              {(status === 'loading' || loading || isFetching) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>
                    <LoadingTable />
                  </StyledTableCell>
                </StyledTableRow>
              )}

              {!!dataBySelectedDate?.length &&
                !loading &&
                !isFetching &&
                dataBySelectedDate
                  ?.slice(page * 10, page * 10 + 10)
                  .map((row, index) => (
                    <StyledTableRow key={`${index}-${row?.item}-day`}>
                      <StyledTableCell align="left">
                        <Typography component="span" variant="body2">
                          {row?.item}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography component="span" variant="body2" noWrap>
                          {Number(Number(row?.quantidade)?.toFixed(0))?.toLocaleString('pt-br')}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography component="span" variant="body2" noWrap>
                          R$ {Number(row?.valor ?? 0).toLocaleString('pt-br')}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography component="span" variant="body2" noWrap>
                          R${' '}
                          {row?.quantidade && Number(row?.valor ?? 0)
                            ? (
                                Number(row?.valor ?? 0) /
                                Number(row?.quantidade ?? 1)
                              ).toLocaleString('pt-br')
                            : 0}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>

            {!!dataBySelectedDate?.length && !loading && !isFetching && (
              <TableFooter>
                <StyledTableRow>
                  <StyledTableCell align="left">
                    <Typography
                      component="span"
                      variant="body2"
                      fontWeight="bold"
                    >
                      Total
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography
                      component="span"
                      variant="body2"
                      noWrap
                      fontWeight="bold"
                    >
                      {Number(
                        dataBySelectedDateTotal?.quantidade ?? 0
                      )?.toFixed(0)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography
                      component="span"
                      variant="body2"
                      noWrap
                      fontWeight="bold"
                    >
                      R${' '}
                      {Number(
                        dataBySelectedDateTotal?.valor ?? 0
                      ).toLocaleString('pt-br')}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography
                      component="span"
                      variant="body2"
                      noWrap
                      fontWeight="bold"
                    >
                      R${' '}
                      {dataBySelectedDateTotal?.quantidade &&
                      Number(dataBySelectedDateTotal?.valor ?? 0)
                        ? (
                            Number(dataBySelectedDateTotal?.valor ?? 0) /
                            Number(dataBySelectedDateTotal?.quantidade ?? 1)
                          ).toLocaleString('pt-br')
                        : 0}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter>
            )}
          </Table>

          <TablePagination
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
            component="div"
            count={dataBySelectedDate?.length ?? 0}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage)
            }}
          />
        </TableContainer>
      </TabPanel>
    </>
  )
}
