/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, CircularProgress, Icon } from '@mui/material'
import { useQuery } from 'react-query'
import { getReport, getReportDevolucoes } from 'services/report/ReportBilling'
import moment from 'moment'
import { formatValue, toBRL } from 'utils/formatString'
import { TipoProduto } from 'utils/enums'

interface Props {
  date: string
  faturamento: any
  previsao: any
  valorOutros: any
  multipleDate?: any
}

const TableReportDevolution: React.FC<Props> = (props) => {
  // const [filters, setFilters] = useState<any>({})
  const date = moment(props.date).format('DD/MM/YYYY')
  const [valorRow] = useState<any[]>([
    {
      text: 'Devoluções',
      value: 4
    }
  ])

  const [dataValues, setDataValues] = useState<any[]>([])

  const devolucaoReq = useQuery(['devolucao-req', date, props.multipleDate],
    async () => {
      return getReportDevolucoes(date, props.multipleDate)
    },
    {
      onSuccess: (data) => {
        faturamentoReq.refetch()
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false
    }
  )

  const faturamentoReq = useQuery(['faturamento-devolucao-req', date, props.multipleDate],
    async () => {
      return getReport(date, props.multipleDate)
    },
    {
      onSuccess: (data) => {
        getDataReport(devolucaoReq.data.response, data.response)
      },
      onError: (error) => {
        console.log('Ops! Algo deu errado, Verifique sua conexão e tente novamnete', error)
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  const getDataReport = (data: any[], faturamentoData: any[]) => {
    const caixa = data.find(v => v.type === TipoProduto.CAIXA)
    const chapa = data.find(v => v.type === TipoProduto.CHAPA)
    const papel = data.find(v => v.type === TipoProduto.PAPEL)

    const totalAcumulado = faturamentoData
      .filter(v => v.tipo === '2')
      .map(m => m.dados[0])
      .reduce((a, b) => ({ kg: a.kg + b.kg, rs: a.rs + b.rs }))

    const values: any[] = [
      {
        tipo: 'Devolução Caixa',
        kg: 0 - Number(caixa?.kg ?? 0),
        rs: 0 - Number(caixa?.rs ?? 0),
        rsKg: -1 * (caixa?.rs ? Number(caixa?.rs ?? 0) / Number(caixa?.kg ?? 0) : 0)
      },
      {
        tipo: 'Devolução Chapa',
        kg: 0 - Number(chapa?.kg ?? 0),
        rs: 0 - Number(chapa?.rs ?? 0),
        rsKg: -1 * (chapa?.rs ? Number(chapa?.rs ?? 0) / Number(chapa?.kg) ?? 0 : 0)
      },
      {
        tipo: 'Devolução Papel',
        kg: 0 - Number(papel?.kg ?? 0),
        rs: 0 - Number(papel?.rs ?? 0),
        rsKg: -1 * (papel?.rs ? Number(papel?.rs ?? 0) / Number(papel?.kg ?? 0) ?? 0 : 0)
      }
    ]

    // calcular antes de inserir valor de outros
    const kgTotalDevolucoes = values.map(m => m.kg).reduce((a, b) => a + b)
    const rsTotalDevolucoes = values.map(m => m.rs).reduce((a, b) => a + b)
    values.push({
      tipo: 'Total devoluções',
      kg: kgTotalDevolucoes,
      rs: rsTotalDevolucoes,
      rsKg: -1 * (rsTotalDevolucoes / kgTotalDevolucoes)
    })
    console.log({ totalAcumulado })

    const kgTotalFaturado = totalAcumulado.kg + kgTotalDevolucoes
    const rsTotalFaturado = totalAcumulado.rs + props.valorOutros + rsTotalDevolucoes
    values.push({
      tipo: 'Total faturado',
      kg: kgTotalFaturado,
      rs: rsTotalFaturado,
      rsKg: rsTotalFaturado / kgTotalFaturado
    })

    console.log('values ai', values)
    setDataValues(values)
  }

  return (
    <>
      {(devolucaoReq.isLoading || devolucaoReq.isFetching || faturamentoReq.isFetching) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <CircularProgress />
        </Box>
      )}
      {(devolucaoReq.isError || faturamentoReq.isError) && (
        <Box sx={{ display: 'flex' }}
          style={{ justifyContent: 'center', marginTop: '124px', marginRight: '88px' }}>
          <h4>Ops! Algo deu errado, Verifique sua conexão e tente novamente</h4>
          <Icon style={{ marginLeft: '10px' }}>signal_wifi_statusbar_connected_no_internet_4</Icon>
        </Box>
      )}

      {(!faturamentoReq.isFetching && !devolucaoReq.isFetching) && (
        <>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: '100%' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: '15%' }}></TableCell>
                    <TableCell align="center" style={{ width: '30%' }}></TableCell>
                    {valorRow.map((item) => {
                      return (
                        <TableCell align="center" style={{ width: '29%' }} colSpan={3}>
                          {item.text}
                        </TableCell>
                      )
                    })}
                    <TableCell align={'center'} style={{ width: '28%' }}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell key='cliente_carne'>
                      Produto
                      {/* <TextField
                        id="standard-basic"
                        label="Pesquisar Produto"
                        variant="standard"
                        style={{ width: '100%' }}
                        onChange={(e) => setFilters({ ...filters, cliente: e.target.value })} /> */}
                    </TableCell>
                    {valorRow.map((item, index) => (
                      <>
                        <TableCell key={item.text} align={'center'} style={{ width: '26%' }}></TableCell>
                        <TableCell
                          key={item.text}
                          align={'center'}
                        >
                          KG
                        </TableCell>
                        <TableCell
                          key={item.text}
                          align={'center'}
                        >
                          R$
                        </TableCell>
                        <TableCell
                          key={item.text}
                          align={'center'}
                          style={{ display: (item.value === 3 && index === 2) ? 'none' : 'table-cell' }}
                        >
                          R$/KG
                        </TableCell>
                        <TableCell key={item.text} align={'center'} style={{ width: '19%' }}></TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataValues
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.client}>
                          <TableCell key='nome_tipo'>
                            {row.tipo}
                          </TableCell>
                          <TableCell align={'center'}></TableCell>
                          <TableCell align={'center'}>
                            {formatValue(row.kg)}
                          </TableCell>
                          <TableCell align={'center'}>
                            {toBRL(row.rs, { noBRL: true })}
                          </TableCell>
                          <TableCell align={'center'}>
                            {toBRL(row.rsKg, { noBRL: true })}
                          </TableCell>
                          <TableCell align={'center'}></TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  )
}

export default TableReportDevolution
