/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Button as MuiButton,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import moment from 'moment';
import IconAndTitleView from 'components/view/IconAndTitleView';
import {
  BoxAlingCheckbox,
  ButtonRow,
  CardContentChieldren,
  CardFather,
  DialogStyle,
  DivAlingCard,
  DivAlingItensCard,
  DivRow,
  DivStepper,
  LabelCheckbox,
  LabelText,
  RetangleView
} from '../styles/dialogMap.styles';
import Stack from '@mui/material/Stack';
import { StepIconProps } from '@mui/material/StepIcon';
import { QontoConnector, QontoStepIconRoot } from '../styles/stepper.styles';
import { FieldArray, Form, Formik, FormikErrors, useFormik } from 'formik';
import { onlyNumbers } from 'helpers/stringHelper';
import AddIcon from '@mui/icons-material/Add';
import DeliveryMapType from 'types/Report/DeliveryMapType';
import { apiTrimbox, httpClient, httpLN, httpTrimbox } from 'config/httpClient';
import { toast } from 'react-toastify';
import CustomButton from 'components/buttons/CustomButton';
import { useQuery } from 'react-query';
import {
  getOrderServiceByItem,
  getPalletsOrderProduction
} from 'services/report/DeliveryMapService';
import styled from 'styled-components';
import OrderType from 'types/deliveryMap/OrderType';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import DatePicker from '@mui/lab/DatePicker';
import { LoadingButton } from '@mui/lab';
import { Remove } from '@mui/icons-material';
import { getFacaTrimbox } from 'services/ft/FTService';

const encrypt = (text: string) => {
  const encrypted: string = window.btoa(apiTrimbox + text);
  return encrypted.replaceAll('/', '---');
};

interface Props {
  open: boolean
  close: () => void
  data?: any
  refetch: () => void
}

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-circle-after" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const ModalDeliveryMap: React.FC<Props> = (props) => {
  const [toogleTab, setToogleTab] = useState<boolean>(true);
  const [toogleTab2, setTtoogleTab2] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataOrder, setDataOrder] = useState<OrderType[]>([]);
  const [ordem] = useState<any[]>(props.data?.deliveryMap?.balance);
  const [selectedOrder, setSelectdOrdem] = useState<OrderType>();
  const [newDate, setNewDate] = useState<any | null>(new Date());
  const [ordemVazia] = useState<any[]>([
    {
      requestedAmount: 0,
      deliveredAmount: 0,
      balance: 0
    }
  ]);

  const closeModal = () => props.close();
  const handleRefetch = () => props.refetch();

  console.log('DELIVERY MAP', props?.data);

  const reqTrimboxFt = useQuery(
    ['ft-trimbox', null],
    async () => {
      return getFacaTrimbox(props.data?.item);
    },
    {
      refetchOnWindowFocus: false
    }
  );
  // console.log(props.data?.item, 'trimbox', reqTrimboxFt.data);

  const orderProducaoLN = useQuery(
    ['op-ln', props?.data?.orno, props?.data?.linha],
    async () => {
      return httpLN.get(
        `order/orderProducaoByOrderVenda?orno=${props?.data?.orno}&linha=${props?.data?.linha}`
      );
    },
    {
      refetchOnWindowFocus: false
    }
  );
  console.log(props.data?.item, 'orderProducaoLN', orderProducaoLN.data);

  const ordersProducaoTrimbox = useQuery(
    ['ops-trimbox', props.data?.item],
    async () => {
      const url = encrypt(`ft/orderProductionByFT?cod=${props.data?.item}`);
      return httpClient.get(`ft/access-trimbox/get/${url}`);
    },
    {
      refetchOnWindowFocus: false
    }
  );
  // console.log(
  //   props.data?.item,
  //   'ordersProducaoTrimbox',
  //   ordersProducaoTrimbox.data?.data
  // );

  const findDadosOrderProducaoTrimbox = !!orderProducaoLN.data?.data?.result
    ?.length
    ? ordersProducaoTrimbox.data?.data?.find((item) =>
        item?.pedido?.includes(orderProducaoLN.data?.data?.result)
      )
    : undefined;
  console.log('findDadosOrderProducaoTrimbox', findDadosOrderProducaoTrimbox);

  const paletizadoTrimbox = useQuery(
    ['paletizado-trimbox', props.data?.item],
    async () => {
      const url = encrypt(`ft/searchFT?cod=${props.data?.item}`);
      return httpClient.get(`ft/access-trimbox/get/${url}`);
    },
    {
      refetchOnWindowFocus: false
    }
  );
  // console.log('paletizadoTrimbox', paletizadoTrimbox.data?.data);

  const queryMultiple = () => {
    const req1 = useQuery(
      ['order-service', newDate],
      async () => {
        return httpClient.get(`/itemDeliveryMap/${props?.data?.id}`);
      },
      {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5
      }
    );

    return [req1];
  };

  const [req1] = queryMultiple();

  useEffect(() => {
    if (!req1.isLoading) {
      handleFormatData(req1.data);
    }
  }, [req1.isLoading]);

  const handleFormatData = (dataLN: any) => {
    setDataOrder(dataLN);
    // setPallet(dataTrimBox)
  };

  const { errors, touched, values, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        _id: props.data?.deliveryMap?._id ?? '',
        id: props.data?.id ?? '',
        productionFlow:
          props.data?.op ?? props.data?.ordensProducao
            ? [
                props.data?.op?.maquina1 ??
                  props.data?.ordensProducao?.maquina1,
                props.data?.op?.maquina2 ??
                  props.data?.ordensProducao?.maquina2,
                props.data?.op?.maquina3 ??
                  props.data?.ordensProducao?.maquina3,
                props.data?.op?.maquina4 ??
                  props.data?.ordensProducao?.maquina4
              ]
            : props.data?.deliveryMap?.productionFlow,
        exit: props.data?.deliveryMap?.exit ?? false,
        reposition: props.data?.deliveryMap?.reposition ?? false,
        ft: props.data?.item ?? '',
        client:
          props.data?.codClient ??
          props.data?.op?.razaoSocial ??
          props.data?.deliveryMap?.client ??
          props.data?.preFT?.client?.name ??
          '',
        // lotPilot: props.data?.preFT?.client.pilotLot,
        product: props.data?.preFT?.type ?? '',
        cliente: props.data?.collaborator?.name ?? '',
        ordemProducao: props.data?.op,
        ordemVenda: props.data?.linhaFaturarOV,
        qtdFaturada: props.data?.quantidadeFaturada,
        billingDate: props.data?.deliveryMap?.billingDate ?? null,
        diferencaEnviada: 'LN',
        qtdPallet: props.data?.pallet ?? 0,
        entredaEstoque: props.data?.entradaEstoque,
        netWeight: props.data?.deliveryMap?.netWeight ?? '',
        dispatchedWeight:
          Number(Number(props.data.quantidadeFaturada).toFixed(0)) *
            Number(props.data?.peso ?? 0) ??
          props.data?.deliveryMap?.dispatchedWeight ??
          0,
        requesteWeight:
          Number(props.data?.peso ?? 0) ??
          props.data?.deliveryMap?.requesteWeight ??
          0,
        board: props.data?.deliveryMap?.board ?? '',
        deliveryAmountConfirmation:
          props.data?.deliveryMap?.deliveryAmountConfirmation ?? '',
        pallets:
          props.data?.pallet ?? props.data?.deliveryMap?.pallet_quantity ?? 0,
        formula: '',
        deliveryTime: props.data?.deliveryMap?.deliveryTime ?? '',
        exitTime: props.data?.deliveryMap?.exitTime ?? '',
        startTime: props.data?.deliveryMap?.startTime ?? '',
        observation: props.data?.deliveryMap?.observation ?? null,
        observationPCE: props.data?.deliveryMap?.observationPCE ?? '',
        deliveryPrevision: props.data?.deliveryMap?.deliveryPrevision ?? '',
        dataFaturamentoLN: props.data?.dataFaturamento,
        qtdSolicitadaOV: props.data?.quantidadeSolicitada,
        balance: props.data?.deliveryMap?.balance?.length
          ? props.data?.deliveryMap?.balance
          : [
              {
                requestedAmount: 0,
                deliveredAmount: 0,
                balance: props?.data?.saldo ?? 0
              }
            ],
        paletizado: '',
        peso: '',
        reference:
          props.data?.referencia ?? props.data?.deliveryMap?.reference ?? '',
        production_order:
          props.data?.orderProducao ??
          props.data?.op?.pedido ??
          props.data?.deliveryMap?.production_order ??
          '',
        invoiced_quantity:
        props.data?.quantidadeFaturada ? Number(props.data?.quantidadeFaturada).toFixed(0) : (props.data?.deliveryMap?.invoiced_quantity ??
          '-'),
        sales_order: props.data?.deliveryMap?.sales_order,
        ov_quantity: props.data?.quantidadeSolicitada
          ? Number(props.data?.quantidadeSolicitada).toFixed(0)
          : '',
        billing_date_ov:
          props.data?.dataFaturamento ??
          props.data?.deliveryMap?.billing_date_ov,
        nf: props.data?.nf ?? props.data?.deliveryMap?.nf,
        pilotLot: props.data?.deliveryMap?.pilotLot ?? false,
        pallet_quantity:
          props.data?.pallet ?? props.data?.deliveryMap?.pallet_quantity ?? 0,
        entry_into_stock: props.data?.deliveryMap?.entry_into_stock
      },
      onSubmit(values) {
        const body = {
          id: props.data?.id ?? '',
          productionFlow: values.productionFlow,
          exit: values.exit,
          reposition: values.reposition,
          billingDate: moment(values.billingDate).format('DD/MM/YYYY'),
          netWeight: values.netWeight,
          dispatchedWeight: values.dispatchedWeight,
          requesteWeight: values.requesteWeight,
          deliveryAmountConfirmation: values.deliveryAmountConfirmation,
          deliveryTime: values.deliveryTime,
          exitTime: values.exitTime,
          startTime: values.startTime,
          observation: values.observation,
          deliveryPrevision: values.deliveryPrevision,
          balance: values.balance,
          board: values.board,
          observationPCE: values.observationPCE,
          production_order:
            values.production_order ?? selectedOrder?.ordemProducao,
          invoiced_quantity:
            values.invoiced_quantity ?? selectedOrder?.quantidadeFaturada,
          ov_quantity:
            values.ov_quantity ?? selectedOrder?.quantidadeSolicitada,
          nf: values.nf ?? selectedOrder?.nf,
          billing_date_ov:
            values.billing_date_ov ??
            moment(selectedOrder?.dataEntregaOV).format('DD/MM/YYYY'),
          reference: values.reference ?? selectedOrder?.reference,
          pallet_quantity: values.pallet_quantity,
          entry_into_stock: values.entry_into_stock,
          idFT: props.data?.ft,
          client: values.client,
          pilotLot: values.pilotLot,
          priority: props.data?.deliveryMap?.priority
        };
        if (props.data?.deliveryMap?.id) {
          console.log('valuesss: ', body);
          httpClient
            .put(`/itemDeliveryMap/update-item/${values?.id}`, body)
            .then(() => {
              toast.success('Dados alterado com sucesso');
              closeModal();
              handleRefetch();
            })
            .catch((error: any) => {
              console.log('Error: ', error);
              toast.error(
                error?.response?.data?.message ?? 'Erro ao Alterar Dados'
              );
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          console.log('values: ', body);
          httpClient
            .post('/itemDeliveryMap', body)
            .then(() => {
              toast.success('Dados registrado com sucesso');
              closeModal();
              handleRefetch();
            })
            .catch((error: any) => {
              console.log('Error: ', error);
              toast.error(
                error?.response?.data?.message ?? 'Erro ao Registrar Dados'
              );
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    });

  const handlePushSolicitacao = () => {
    setFieldValue('balance', [
      ...values.balance,
      {
        requestedAmount: 0,
        deliveredAmount: 0,
        balance: 0
      }
    ]);
  };

  const handleRemoveSolicitacao = () => {
    const lastIndex = values.balance?.length - 1;
    setFieldValue(
      'balance',
      values.balance?.filter(
        (balance, currentIndex) => currentIndex !== lastIndex
      )
    );
  };

  console.log('props.data', props.data);
  return (
    <>
      <Dialog
        open={props.open}
        onClose={closeModal}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {(req1.isLoading ||
          paletizadoTrimbox.isLoading ||
          ordersProducaoTrimbox.isLoading ||
          orderProducaoLN.isLoading) && (
          <Stack justifyContent="center" alignItems="center" height={200}>
            <CircularProgress />
            <div>Consultando Dados</div>
          </Stack>
        )}
        {req1.isError && (
          <>
            <Box
              sx={{
                display: 'flex',
                height: 200,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography component="span" variant="body2">
                Error ao listar, tente novamente
              </Typography>
            </Box>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginRight: '35px',
                marginBottom: '10px'
              }}
            >
              <Button variant="contained" disableElevation onClick={closeModal}>
                Voltar
              </Button>
            </div>
          </>
        )}

        {!req1.isLoading &&
          !paletizadoTrimbox.isLoading &&
          !ordersProducaoTrimbox.isLoading &&
          !orderProducaoLN.isLoading && (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="alert-dialog-title">
                <DivAlingCard>
                  <RetangleView />
                  <CardFather>
                    <CardContentChieldren>
                      <DivAlingItensCard>
                        <IconAndTitleView
                          icon="assignment_outline"
                          title={`FT ${values.ft.substring(0, 8)}`}
                        />
                        <IconAndTitleView
                          icon="content_paste"
                          title={`Produto: ${values.product ?? ''}`}
                        />
                        <IconAndTitleView
                          icon="person_outline"
                          title={`Para: ${
                            values?.ordemProducao?.razaoSocial ??
                            values.client ??
                            ''
                          }`}
                        />
                        <IconAndTitleView
                          icon="person_outline"
                          title={'Faturado em:'}
                        />
                        <IconAndTitleView
                          icon="calendar_today"
                          title={
                            moment(values.billing_date_ov).format(
                              'DD/MM/YYYY'
                            ) ??
                            moment(selectedOrder?.dataEntregaOV).format(
                              'DD/MM/YYYY'
                            )
                          }
                        />
                        <IconAndTitleView
                          icon="schedule"
                          title={
                            moment(values.billing_date_ov).format('HH:mm') ??
                            moment(selectedOrder?.dataEntregaOV).format('HH:mm')
                          }
                        />
                        <IconAndTitleView
                          icon="assignment_outline"
                          title={`NF ${values.nf ?? selectedOrder?.nf}`}
                        />
                      </DivAlingItensCard>
                    </CardContentChieldren>
                  </CardFather>
                </DivAlingCard>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <LabelText>Mapa de entrega</LabelText>
                    <h4 style={{ marginTop: '5px' }}>Fluxo de Produção</h4>
                    {!orderProducaoLN?.data?.data?.result?.length && (
                      <p>Ordem de produção não encontrada no LN</p>
                    )}
                    {!!orderProducaoLN?.data?.data?.result?.length &&
                      !ordersProducaoTrimbox.data?.data?.length && (
                        <p>Ordem de produção não encontrada no Trimbox</p>
                      )}
                    <Stack>
                      {findDadosOrderProducaoTrimbox?.maquina1?.length && (
                        <Typography>
                          Passou em{' '}
                          <strong>
                            {findDadosOrderProducaoTrimbox?.maquina1}
                          </strong>
                        </Typography>
                      )}
                      {findDadosOrderProducaoTrimbox?.maquina2?.length && (
                        <Typography>
                          Passou em{' '}
                          <strong>
                            {findDadosOrderProducaoTrimbox?.maquina2}
                          </strong>
                        </Typography>
                      )}
                      {findDadosOrderProducaoTrimbox?.maquina3?.length && (
                        <Typography>
                          Passou em{' '}
                          <strong>
                            {findDadosOrderProducaoTrimbox?.maquina3}
                          </strong>
                        </Typography>
                      )}
                      {findDadosOrderProducaoTrimbox?.maquina4?.length && (
                        <Typography>
                          Passou em{' '}
                          <strong>
                            {findDadosOrderProducaoTrimbox?.maquina4}
                          </strong>
                        </Typography>
                      )}
                      {findDadosOrderProducaoTrimbox?.maquina5?.length && (
                        <Typography>
                          Passou em{' '}
                          <strong>
                            {findDadosOrderProducaoTrimbox?.maquina5}
                          </strong>
                        </Typography>
                      )}
                      {findDadosOrderProducaoTrimbox?.maquina6?.length && (
                        <Typography>
                          Passou em{' '}
                          <strong>
                            {findDadosOrderProducaoTrimbox?.maquina6}
                          </strong>
                        </Typography>
                      )}
                      {findDadosOrderProducaoTrimbox?.maquina7?.length && (
                        <Typography>
                          Passou em{' '}
                          <strong>
                            {findDadosOrderProducaoTrimbox?.maquina7}
                          </strong>
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                  <BoxAlingCheckbox>
                    <Grid container columns={16}>
                      <Grid item xs={3}>
                        <LabelCheckbox>Confirmação de saida</LabelCheckbox>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onClick={() => setToogleTab(!values.exit)}
                                checked={values.exit}
                                onChange={handleChange('exit')}
                              />
                            }
                            label={values.exit ? 'Sim' : 'Inativo'}
                          />
                        </FormGroup>
                      </Grid>
                      {/* <Grid item xs={2}>
                      <LabelCheckbox>Reposição?</LabelCheckbox>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              onClick={() => setTtoogleTab2(!values.reposition)}
                              checked={values.reposition}
                              onChange={handleChange('reposition')}
                            />
                          }
                          label={values.reposition ? 'Sim' : 'Inativo'}
                        />
                      </FormGroup>
                    </Grid> */}
                      {/* <Grid item xs={2}>
                      <LabelCheckbox>Lote Piloto?</LabelCheckbox>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              onClick={() => setTtoogleTab2(!values.pilotLot)}
                              checked={values.pilotLot}
                              onChange={handleChange('pilotLot')}
                            />
                          }
                          label={values.pilotLot ? 'Sim' : 'Inativo'}
                        />
                      </FormGroup>
                    </Grid> */}
                    </Grid>
                  </BoxAlingCheckbox>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Dados da FT</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid
                        container
                        spacing={3}
                        mt={0}
                        style={{ width: '800px' }}
                      >
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="FT"
                            variant="standard"
                            value={values.ft}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Cliente"
                            variant="standard"
                            value={
                              values?.ordemProducao?.razaoSocial ??
                              values.client
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Rêferencia"
                            variant="standard"
                            value={
                              props?.data?.reference ??
                              values.reference ??
                              values?.ordemProducao?.referencia
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Ordem de produção"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={
                              !!orderProducaoLN?.data?.data?.result?.length
                                ? orderProducaoLN?.data?.data?.result
                                : 'Não encontrada no LN'
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Ordem de venda"
                            variant="standard"
                            value={values?.ordemVenda}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </DivRow>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Faturamento antecipado</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid
                        container
                        spacing={3}
                        mt={0}
                        style={{ width: '800px' }}
                      >
                        <Grid item xs={4}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptBR}
                          >
                            <DatePicker
                              label="Data de faturamento"
                              value={values.billingDate || null}
                              onChange={(newValue) => {
                                setFieldValue('billingDate', newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={null}
                                  variant="standard"
                                />
                              )}
                            />
                          </LocalizationProvider>
                          {/* <TextField
                          error={!!(errors.billingDate && touched.billingDate)}
                          fullWidth
                          id=""
                          label="Data de faturamento"
                          variant="standard"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          value={moment(values.billingDate).format(
                            'YYYY-MM-DD'
                          )}
                          onChange={handleChange('billingDate')}
                          helperText={
                            errors.billingDate && touched.billingDate
                              ? 'Campo Obrigatorio'
                              : ''
                          }
                        /> */}
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id=""
                            label="Hórario de partida"
                            variant="standard"
                            type="time"
                            InputLabelProps={{ shrink: true }}
                            value={values.deliveryPrevision}
                            onChange={handleChange('deliveryPrevision')}
                          />
                        </Grid>
                      </Grid>
                    </DivRow>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Saldo de ordem</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid
                        container
                        spacing={3}
                        mt={0}
                        style={{ width: '800px' }}
                      >
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Quantidade solicitada na OV"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={
                              values.ov_quantity ??
                              selectedOrder?.quantidadeSolicitada
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Quantidade faturada"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={values.invoiced_quantity}
                            disabled
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Saldo"
                            autoComplete="off"
                            variant="standard"
                            value={props?.data?.saldo}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Data do faturamento"
                            variant="standard"
                            value={
                              values.billing_date_ov
                                ? moment(values.billing_date_ov).format(
                                    'DD/MM/YYYY HH:mm'
                                  )
                                : ''
                            }
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="NF"
                            autoComplete="off"
                            variant="standard"
                            value={values.nf ?? selectedOrder?.nf}
                            disabled
                          />
                        </Grid>
                        {/* <Grid item xs={12}>
                          <Grid
                            container
                            spacing={4}
                            style={{ marginBottom: '30px' }}
                          >
                            {values.balance?.map((saldoOrdem, index) => (
                              <React.Fragment key={index}>
                                <Grid item xs={4}>
                                  <TextField
                                    fullWidth
                                    type={'number'}
                                    id="standard-basic"
                                    label="Quantidade solicitada"
                                    variant="standard"
                                    autoComplete="off"
                                    value={saldoOrdem.requestedAmount}
                                    // onChange={handleChange(
                                    //   `balance.${index}.requestedAmount`
                                    // )}
                                    // disabled
                                    onChange={(e) => {
                                      const qtdDelivered =
                                        saldoOrdem.deliveredAmount;
                                      const newValue = onlyNumbers(
                                        e.target.value
                                      );
                                      setFieldValue(
                                        `balance.${index}.requestedAmount`,
                                        newValue
                                          ? Math.round(Number(newValue))
                                          : ''
                                      );
                                      const sum =
                                        Number(newValue) - Number(qtdDelivered);
                                      setFieldValue(
                                        `balance.${index}.balance`,
                                        sum
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    fullWidth
                                    type={'number'}
                                    id="standard-basic"
                                    label="Quantidade entregue"
                                    variant="standard"
                                    autoComplete="off"
                                    value={saldoOrdem.deliveredAmount}
                                    // disabled
                                    onChange={(e) => {
                                      const qtdSoli =
                                        saldoOrdem.requestedAmount;
                                      const newValue = onlyNumbers(
                                        e.target.value
                                      );
                                      setFieldValue(
                                        `balance.${index}.deliveredAmount`,
                                        newValue
                                          ? Math.round(Number(newValue))
                                          : ''
                                      );
                                      const sum =
                                        Number(qtdSoli) - Number(newValue);
                                      setFieldValue(
                                        `balance.${index}.balance`,
                                        sum
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    fullWidth
                                    id="standard-basic"
                                    label="Saldo"
                                    autoComplete="off"
                                    variant="standard"
                                    value={
                                      (values.ov_quantity ??
                                        selectedOrder?.quantidadeSolicitada) &&
                                      values.invoiced_quantity
                                        ? Number(
                                            values.ov_quantity ??
                                              selectedOrder?.quantidadeSolicitada
                                          ) -
                                          Number(values.invoiced_quantity) -
                                          (values.balance?.reduce(
                                            (acc, currentItem) => {
                                              return (acc += Number(
                                                currentItem?.deliveredAmount ??
                                                  0
                                              ));
                                            },
                                            0
                                          ) ?? 0)
                                        : 0
                                    }
                                    disabled
                                  />
                                </Grid>
                              </React.Fragment>
                            ))}
                          </Grid>
                          <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                              <MuiButton
                                variant="outlined"
                                size="large"
                                startIcon={<Remove />}
                                onClick={() => handleRemoveSolicitacao()}
                                sx={{
                                  border: 'none',
                                  textTransform: 'inherit',
                                  boxShadow: '0px 3px 6px #00000029',
                                  color: '#EE207A',
                                  '&:hover': {
                                    border: 'none'
                                  }
                                }}
                              >
                                Remover solicitação de quantidade
                              </MuiButton>

                              <MuiButton
                                variant="outlined"
                                size="large"
                                startIcon={<AddIcon />}
                                onClick={() => handlePushSolicitacao()}
                                sx={{
                                  border: 'none',
                                  textTransform: 'inherit',
                                  boxShadow: '0px 3px 6px #00000029',
                                  color: '#8A8A8A',
                                  '&:hover': {
                                    border: 'none'
                                  }
                                }}
                              >
                                Nova solicitação de quantidade
                              </MuiButton>
                            </Stack>
                          </Grid>
                        </Grid> */}
                      </Grid>
                    </DivRow>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Outras informções</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid
                        container
                        spacing={3}
                        mt={0}
                        style={{ width: '800px' }}
                      >
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Placa"
                            variant="standard"
                            autoComplete="off"
                            value={values.board}
                            onChange={handleChange('board')}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Confirmação de quantidade envida"
                            variant="standard"
                            autoComplete="off"
                            value={values.deliveryAmountConfirmation}
                            onChange={handleChange(
                              'deliveryAmountConfirmation'
                            )}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Paletizado/Batido"
                            variant="standard"
                            autoComplete="off"
                            // eslint-disable-next-line eqeqeq
                            value={
                              paletizadoTrimbox.data?.data?.paletizado === 1
                                ? 'Paletizado'
                                : paletizadoTrimbox.data?.data?.paletizado === 0
                                ? 'Batido'
                                : ' '
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Peças por palete"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={
                              paletizadoTrimbox.data?.data?.pecasPorPalete ?? 0
                            }
                            autoComplete="off"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Estoque disponível"
                            variant="standard"
                            value={
                              props?.data?.estoqueDisponivel
                                ? Number(
                                    props?.data?.estoqueDisponivel
                                  )?.toFixed(0)
                                : ''
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Peso líquido"
                            variant="standard"
                            autoComplete="off"
                            type={'number'}
                            value={Number(props.data?.peso ?? 0)}
                            InputLabelProps={{ shrink: true }}
                            disabled
                            // onChange={(e) => {
                            //   // const fat = values.qtdFaturada;
                            //   const newValue = onlyNumbers(e.target.value);
                            //   setFieldValue(
                            //     'netWeight',
                            //     newValue ? Math.round(Number(newValue)) : ''
                            //   );
                            //   // const sum = Number(fat) * Number(newValue);
                            //   // setFieldValue('dispatchedWeight', sum);
                            //   // const soli =
                            //   //   Number(values.balance[0].requestedAmount) *
                            //   //   Number(newValue);
                            //   // setFieldValue('requesteWeight', soli);
                            // }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Peso solicitado (kg)"
                            variant="standard"
                            autoComplete="off"
                            value={
                              isNaN(
                                Math.ceil(
                                  Number(props.data?.peso ?? 0) *
                                    Number(
                                      props.data?.quantidadeSolicitada ?? 0
                                    )
                                )
                              )
                                ? 0
                                : Math.ceil(
                                    Number(props.data?.peso ?? 0) *
                                      Number(
                                        props.data?.quantidadeSolicitada ?? 0
                                      )
                                  ).toLocaleString()
                            }
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            label="Peso expedido (Kg)"
                            autoComplete="off"
                            value={
                              isNaN(
                                Math.ceil(
                                  Number(props.data?.peso ?? 0) *
                                    Number(props.data?.quantidadeFaturada ?? 0)
                                )
                              )
                                ? 0
                                : Math.ceil(
                                    Number(props.data?.peso ?? 0) *
                                      Number(
                                        props.data?.quantidadeFaturada ?? 0
                                      )
                                  ).toLocaleString()
                            }
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            disabled
                          />
                        </Grid>
                        {/* <Grid item xs={3}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Pallets"
                          autoComplete="off"
                          variant="standard"
                          // InputLabelProps={{ shrink: true }}
                          value={values.pallet_quantity}
                          disabled
                        />
                      </Grid> */}
                        {/* <Grid item xs={3}>
                        <TextField
                          fullWidth
                          id="standard-basic"
                          label="Fórmula"
                          autoComplete="off"
                          variant="standard"
                          value={values?.formula ?? ''}
                          disabled
                        />
                      </Grid> */}
                      </Grid>
                    </DivRow>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <LabelText>Horários</LabelText>
                    <DivRow style={{ marginTop: '-20px' }}>
                      <Grid
                        container
                        spacing={3}
                        mt={0}
                        style={{ width: '800px' }}
                      >
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id=""
                            type="time"
                            label="Horario de entrega"
                            variant="standard"
                            value={values.deliveryTime}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange('deliveryTime')}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            type="time"
                            label="Hórarrio de saída"
                            variant="standard"
                            value={values.exitTime}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange('exitTime')}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id="standard-basic"
                            type="time"
                            label="Hórarrio de partida"
                            variant="standard"
                            value={values.startTime}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange('startTime')}
                          />
                        </Grid>
                      </Grid>
                    </DivRow>
                    <div>
                      <Grid
                        container
                        spacing={3}
                        mt={0}
                        style={{ width: '100%' }}
                      >
                        <Grid item xs={12}>
                          <TextField
                            className="w-100"
                            id="outlined-multiline-static"
                            label="Observações"
                            fullWidth
                            multiline
                            autoComplete="off"
                            rows={3}
                            InputLabelProps={{ shrink: true }}
                            value={values.observation}
                            // value={values.observation ?? paletizadoTrimbox.data?.data?.descObs}
                            onChange={handleChange('observation')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className="w-100"
                            id="outlined-multiline-static"
                            label="Observações PCE/Expedição"
                            fullWidth
                            multiline
                            autoComplete="off"
                            rows={3}
                            InputLabelProps={{ shrink: true }}
                            value={values.observationPCE}
                            onChange={handleChange('observationPCE')}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <ButtonRow
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      marginTop: 30
                    }}
                  >
                    {/* <Button style={{ marginRight: '21px', backgroundColor: '#EE207A', color: '#FFFFFF' }} onClick={closeModal}>Cancelar</Button>
                        <Button style={{ marginRight: '21px', backgroundColor: '#4060E6', color: '#FFFFFF' }} type="submit">Salvar</Button> */}
                    <CustomButton
                      mold="red"
                      type="button"
                      onClick={closeModal}
                      style={{ marginRight: '21px' }}
                      disabled={loading}
                    >
                      Cancelar
                    </CustomButton>
                    <CustomButton
                      mold="primary"
                      type="submit"
                      style={{ marginRight: '21px' }}
                      loading={loading}
                      disabled={loading}
                    >
                      Salvar
                    </CustomButton>
                  </ButtonRow>
                </DialogContentText>
              </DialogContent>
            </form>
          )}
      </Dialog>
    </>
  );
};

export default ModalDeliveryMap;
