import { httpClient } from '../../config/httpClient'

export const getMaterial = async () => {
  const { data } = await httpClient.get<any>('/material/material')
  return data
}

export const getTaxes = async () => {
  const { data } = await httpClient.get<any>('/material/taxes')
  return data
}

export const postMaterial = async (material: any) => {
  const { data } = await httpClient.post<any>('/material/material', material)
  return data
}

export const postTaxes = async (taxes: any) => {
  const { data } = await httpClient.post<any>('/material/taxes', taxes)
  return data
}

export const putMaterial = async (material: any) => {
  const { data } = await httpClient.put(`/material/material/${material._id}`, material)
  return data
}

export const putTaxes = async (taxes: any) => {
  const { data } = await httpClient.put(`/material/taxes/${taxes._id}`, taxes)
  return data
}

export const deleteMaterial = async (id: string) => {
  const { data } = await httpClient.delete(`/material/material/${id}`)
  return data
}

export const deleteTaxes = async (id: string) => {
  const { data } = await httpClient.delete(`/material/taxes/${id}`)
  return data
}
