import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material'

interface Props {
  value: string
  onChange: (event: any, child: React.ReactNode) => void
}

export function SelectProductType({ value, onChange }: Props) {
  return (
    <Stack sx={{ marginTop: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="product-type-select-label">Tipo</InputLabel>
        <Select
          labelId="product-type-select-label"
          id="product-type-select"
          value={value}
          label="Age"
          onChange={onChange}
        >
          {/* <MenuItem value={'BOBINA'}>Bobina</MenuItem> */}
          <MenuItem value={'CHAPA'}>Chapa</MenuItem>
          <MenuItem value={'CAIXA'}>Caixa</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  )
}
