import { httpLN } from 'config/httpClient'
import moment from 'moment'
import { StockBalance } from 'types/Report/Report'

export const getReportBalanceStock = async (date: string, dataRanger?: any): Promise<StockBalance[]> => {
  let url = ''
  if (!dataRanger.length) {
    url = `estoque/saldo?data=${date}`
  } else {
    const inicio = dataRanger.map(v => (moment.unix(v.unix).format('DD/MM/YYYY')))
    const fim = inicio[inicio.length - 1]
    url = `estoque/saldo?dataInicio=${inicio[0]}&dataFim=${fim}`
  }
  const { data } = await httpLN.get(url)
  return data.result as StockBalance[]
}
