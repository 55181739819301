import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { Link, useNavigate } from 'react-router-dom'
import { CardHeader, CssBaseline, Grid, IconButton } from '@mui/material'
import { AddCircleOutlined, DeleteOutlineOutlined, ModeEdit } from '@mui/icons-material'
import MoreMenu from '../../components/view/MoreMenu'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FaqType from '../../types/faq/FaqType'
import { deleteFaq, getFaq } from '../../services/faq/FaqService'
import { toast } from 'react-toastify'
import alertSwal from '../../components/Alerts/ModalDelete'
import GenericDialog from 'components/genericDialog/GenericDialog'
import ViewContent from './whoWeAre/ViewContent'
import { ActionButton } from 'components/view/styles/style-more-menu'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'

function TitleComponent({ title, onClick }: { title: string, onClick?: () => void }) {
  return (
    <Box
      component="button"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        padding: 0,
        color: 'inherit',
        paddingRight: '60%',
        backgroundColor: 'transparent',
        border: 'none'
      }}
    >
      <Typography
        variant="body2"
        component="strong"
        fontWeight="bold"
      >
        {title}
      </Typography>
    </Box>
  )
}

const Company: React.FC = () => {
  const [dados, setDados] = React.useState<FaqType[]>([])
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const [openDialog, setOpenDialog] = React.useState<boolean>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  React.useEffect(() => {
    listFaq()
  }, [])

  const listFaq = async () => {
    try {
      const response = await getFaq()
      setDados(response.data)
      return response
    } catch (error: any) {
      console.log('erro', error.response.data)
    }
  }

  const navigate = useNavigate()
  const edit = (row: FaqType) => {
    navigate('/home/faq', { state: { row: row } })
  }

  const methodDelete = async (id: string) => {
    try {
      const { data } = await deleteFaq(id)
      if (!data) {
        toast.success('Dado excluido com sucesso!')
        setTimeout(() => {
          listFaq()
        }, 2500)
      } else {
        toast.error('Erro ao excluir')
      }
    } catch (error: any) {
      console.log('erro', error.response.data)
    }
  }

  return (
    <Can
      module={MODULES.EMPRESA}
      permissions={[PERMISSIONS.READ]}
    >
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CssBaseline />
        <h4>Selecione o que deseja visualizar</h4>
        <Card
          sx={{ marginTop: 5, width: 500, height: 50 }}
          style={{ borderRadius: '8px' }}
        >
          <CardHeader
            action={
              <MoreMenu
                // view={() => setOpenDialog(true)}
                edit={() => navigate('/home/edit-content-company')}
              />
            }
            title={<TitleComponent title="Quem Somos" onClick={() => setOpenDialog(true)} />}
          />
        </Card>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <Card
            sx={{ marginTop: 1, width: 500, height: 50 }}
            style={{ borderRadius: '8px' }}
          >
            <CardHeader title={
              <Link
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  marginTop: '15px'
                }}
                to="/home/portfolio"
              >
                <TitleComponent title="Portfólio PCE" onClick={() => setOpenDialog(true)} />
              </Link>
            } />
          </Card>
        </div>
        <div style={{ marginTop: '100px' }}>
          <h4>Cadastrar Dúvidas Frequentes</h4>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
            marginTop: '5px'
          }}
        >
          <div>
            <Link
              style={{ textDecoration: 'none', color: 'black' }}
              to="/home/faq"
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <AddCircleOutlined />
              </IconButton>
            </Link>
          </div>
        </div>
        {dados.map((item: FaqType) => {
          return (
            <div style={{ marginTop: '5px' }}>
              <Accordion
                expanded={expanded === item.question}
                onChange={handleChange(item.question)}
                style={{ width: '1100px' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panelbh-header"
                >
                  <Grid container xs={10}>
                    <Typography>
                      {item.question}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center'
                    }}
                  >
                    <Can
                      module={MODULES.EMPRESA}
                      permissions={[PERMISSIONS.UPDATE]}
                    >
                    <Grid style={{ marginRight: '5px' }}>
                      <ActionButton aria-label="edit" onClick={() => edit(item)}>
                        <ModeEdit />
                      </ActionButton>
                    </Grid>
                    </Can>
                    <Can
                      module={MODULES.EMPRESA}
                      permissions={[PERMISSIONS.DELETE]}
                    >
                    <Grid style={{ marginRight: '20px' }}>
                      <ActionButton color="#ee207a" aria-label="delete" onClick={async () =>
                        alertSwal(() => {
                          methodDelete(String(item._id))
                        })
                      }>
                        <DeleteOutlineOutlined />
                      </ActionButton>
                    </Grid>
                    </Can>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )
        })}
        <GenericDialog
          allowBack
          open={openDialog}
          close={() => setOpenDialog(false)}
          content={<ViewContent />}
        />
      </Box>
    </>
    </Can>
  )
}

export default Company
